import SelectFieldComponent from '@pretto/bricks/components/form/SelectField'

import PropTypes from 'prop-types'

export const SelectField = ({ onChange, ...props }) => {
  const handleChange = ({ value }) => onChange(value)

  return <SelectFieldComponent {...props} onChange={handleChange} format="form" />
}

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
