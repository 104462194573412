import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { Message } from '@pretto/app/src/onboarding/components/fields/Message/Message'

import PropTypes from 'prop-types'

export const BlocMessage = ({ content, data }) => {
  const children = funcToItem(content, { data })
  const { advisor } = useUser()

  return <Message avatarSrc={advisor?.mediumPicturePath}>{children}</Message>
}

BlocMessage.propTypes = {
  content: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
