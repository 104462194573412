import { breakpoints } from '@pretto/bricks/components/layout'
import { css } from 'styled-components'

export const focusedIndex = css`
  outline: 1px dashed ${({ theme }) => theme.legacy.colors.primary1.default};
`

export const overlay = css`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(80)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    backdrop-filter: blur(14px);
  }
`

export const boxShadow = (zDepth = 1) => {
  switch (zDepth) {
    case 0:
      return css`
        box-shadow: none;
      `
    case 1:
      return css`
        box-shadow: 0 2px 10px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
      `
    case 2:
      return css`
        box-shadow: 0 4px 20px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(15)};
      `
    case 3:
      return css`
        box-shadow: 0 6px 30px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(15)};
      `

    default:
      break
  }
}
