import { FieldsValues, MatchType } from '@pretto/app/src/purchaseOffer/config/types'

const MATCH: Record<MatchType, string> = {
  hasCobuyer: 'extra.hasComortgagor',
  price: 'purchase.property_price',
}

export const prefill = (data: Pick<FieldsValues, 'hasCobuyer' | 'price'>): string => {
  const params = Object.entries(data).reduce((previous: string[], [key, field]) => {
    if (field === undefined) {
      return previous
    }

    if (key in MATCH) {
      const match = MATCH[key as MatchType]

      return [...previous, `prefill[${match}]=${field.value}`]
    }

    return previous
  }, [])

  return params.join('&')
}
