export const getIsPriceSubmitted = projectQuery => {
  const purchasePriceIsField =
    projectQuery.data?.project?.purchase?.property_price ||
    projectQuery.data?.project?.purchase?.build_price ||
    projectQuery.data?.project?.purchase?.land_price

  const renegociationPriceIsField = projectQuery.data?.project?.renegotiation?.estimated_value

  if (purchasePriceIsField || renegociationPriceIsField) {
    return true
  }

  return false
}
