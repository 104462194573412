import { getIsActive } from '@pretto/app/src/SharedContainers/Header/lib/getIsActive'

export const formatNavigationItem = ({ navigationItemsList, pathname, trackAction }) =>
  navigationItemsList.map(item => ({
    ...item,
    ...(item?.tracking && {
      onClick: () => {
        trackAction(item.tracking.label, item.tracking.params)
      },
    }),
    isActive: getIsActive({ itemUrl: item.href, pathname }),
  }))
