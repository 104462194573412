import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'
import { useRateAlert } from '@pretto/app/src/lib/subscriptions'
import { useTracking } from '@pretto/app/src/lib/tracking'

const TRACKING_OPTIONS = {
  newsletter_subscribe_source: 'prelead_dashboard',
  newsletter_subscribe_type: 'rate',
}

const ServiceRate = () => {
  const trackAction = useTracking()
  const { trackFeature } = useTrackFeatureCard()

  const trackClick = () => {
    trackFeature('newsletter_rate')
  }
  const trackSubscribe = () => {
    trackAction('NEWSLETTER_SUBSCRIBED', TRACKING_OPTIONS)
  }
  const trackUnsubscribe = () => {
    trackAction('NEWSLETTER_UNSUBSCRIBED', TRACKING_OPTIONS)
  }
  const { isSubscribed, onToggle } = useRateAlert({ trackClick, trackSubscribe, trackUnsubscribe })

  const props = {
    data: {
      isToggled: isSubscribed,
      onToggle,
    },
    slug: 'rateAlert',
  }

  return <ServiceCard {...props} />
}

export default ServiceRate
