import CenterGridTemplate from '@pretto/bricks/shared/templates/CenterGridTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const LoginPage = ({ alert, connectionCardProps, noAccountProps: { text, signupUrl }, title }) => {
  return (
    <CenterGridTemplate alert={alert}>
      <S.Header>
        <S.Title>{title}</S.Title>
      </S.Header>
      <S.Content>
        <S.GoogleOrEmail {...connectionCardProps} />
        <S.NoAccount href={signupUrl}>{text}</S.NoAccount>
        <S.Image />
      </S.Content>
    </CenterGridTemplate>
  )
}

LoginPage.propTypes = {
  alert: PropTypes.node,
  connectionCardProps: PropTypes.object,
  noAccountProps: PropTypes.shape({
    signupUrl: PropTypes.string,
    text: PropTypes.string,
  }),
  title: PropTypes.string,
}

export default LoginPage
