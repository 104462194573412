import { breakpoints, g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { DefaultMessage, FollowTooltip } from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'

import { QuestionMarkCircleBold, TimesCircleBold } from '@pretto/picto'

import styled from 'styled-components'

import { FileItemProps, FileItemState } from '../../FileItem'

export const Tail: React.FC<React.HTMLAttributes<HTMLSpanElement> & FileItemProps> = props => {
  switch (props.state) {
    case FileItemState.Error:
      return (
        <ButtonRetry onClick={props.onRetry} type="button">
          Réessayer
        </ButtonRetry>
      )

    case FileItemState.Removing:
      return <LabelIdle>Suppression...</LabelIdle>

    case FileItemState.Stale:
      return (
        <LabelStale>
          Envoyé
          <FollowTooltip
            messageComponent={LabelStaleFollowTooltipMessageComponent}
            message={
              <>
                Par sécurité, les documents envoyés ne peuvent pas être consultés.
                {props.helpUrl && (
                  <>
                    {' '}
                    <Link href={props.helpUrl} onClick={props.onHelp}>
                      En savoir plus
                    </Link>
                  </>
                )}
              </>
            }
          >
            <LabelStaleIcon />
          </FollowTooltip>
        </LabelStale>
      )

    case FileItemState.Uploaded:
      return (
        <ButtonDelete aria-label="Supprimer" onClick={props.onDelete} type="button">
          <TimesCircleBold />
        </ButtonDelete>
      )

    case FileItemState.Uploading:
      return <LabelUploading>Téléchargement...</LabelUploading>

    default:
      return null
  }
}

const ButtonDelete = styled.button`
  appearance: none;
  color: ${({ theme }) => theme.colors.neutral2};
  cursor: pointer;

  svg {
    display: block;
  }
`

const ButtonRetry = styled.button`
  ${({ theme }) => theme.typos.linkUnderline};
  appearance: none;
  color: ${({ theme }) => theme.colors.error1};
  cursor: pointer;
`

const LabelIdle = styled.span`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
`

const LabelUploading = styled(LabelIdle)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: inline-flex;
  }
`

const LabelStale = styled.span`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
  display: inline-flex;
  gap: ${g(1)};
`

const LabelStaleFollowTooltipMessageComponent = styled(DefaultMessage)`
  ${({ theme }) => theme.typos.body4};
  text-align: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(28)};
  }

  a {
    text-decoration: underline;
  }
`

const LabelStaleIcon = styled(QuestionMarkCircleBold)`
  display: block;
`
