import { useScoreZeroMinute } from '@pretto/app/src/Simulation/lib/useScoreZeroMinute'
import { getTypeOfLead } from '@pretto/app/src/lib/getTypeOfLead'
import { CapacityData } from '@pretto/app/src/types/Capacity'
import { TrackAction } from '@pretto/app/src/types/Tracking'

interface GetScoreProps {
  data: CapacityData
  trackAction: TrackAction
}

export const getScore = async ({ data, trackAction }: GetScoreProps) => {
  const { capacity } = data || {}
  if (!capacity || !capacity.length) {
    return 0
  }

  const { appointmentAllowed, pushy } = await useScoreZeroMinute()

  const typeOfLead = getTypeOfLead({ appointmentAllowed, pushy })

  trackAction('POTENTIAL_TYPE_OF_LEAD_ZERO_MINUTE', {
    type: typeOfLead,
  })

  return pushy
}
