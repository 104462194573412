import { ENTER } from '@pretto/bricks/core/constants/keycodes'

import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Bridge = ({ children, href, onClick, className }) => {
  const handleClick = (event, ...args) => {
    if (!href) {
      onClick(event, ...args)
      return
    }
    const windowtype = href.startsWith('http') ? '_blank' : '_self'
    window.open(href, windowtype)
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER) handleClick()
  }

  return (
    <Component tabIndex="0" onClick={handleClick} onKeyDown={handleKeyDown} className={className}>
      {children}
    </Component>
  )
}

Bridge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const Component = styled.span`
  cursor: pointer;
`
