import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Tag from '@pretto/bricks/components/information/Tag'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Transition } from 'react-transition-group'

import * as C from './SponsorshipShareLink.module.css'

export default class SponsorshipShareLink extends Component {
  state = {
    copied: false,
  }
  handleClick = () => {
    this.props.onClick()
    this.setState({ copied: true })
    this.timeout = setTimeout(() => {
      this.setState({ copied: false })
    }, 2000)
  }
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  render() {
    const { link } = this.props
    return (
      <div className={C.sponsorshipShareLink} onClick={this.handleClick}>
        <Transition
          in={this.state.copied}
          timeout={{
            enter: 10,
            exit: 300,
          }}
          unmountOnExit
        >
          {state => (
            <div
              className={classnames(C.sponsorshipShareLinkCopied, {
                [C.sponsorshipShareLinkCopiedVisible]: state === 'entered',
              })}
            >
              <Tag variant="primary-1">Copié !</Tag>
            </div>
          )}
        </Transition>
        <div className={C.sponsorshipShareLinkIcon}>
          <Emoji>:point_right:</Emoji>
        </div>
        <div className={C.sponsorshipShareLinkText}>{link}</div>
      </div>
    )
  }
}

SponsorshipShareLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
}
