import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold, Picto as PictoType, TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

import { TextFieldProps } from './TextField'

type PictoSlug = 'default' | 'clear' | 'warning' | 'error'

interface InputProps {
  $size: TextFieldProps['size']
  $state: TextFieldProps['state']
  $placeholderAsLabel: TextFieldProps['placeholderAsLabel']
  $pictoSlug: PictoSlug
  $isSuffixActive: boolean
  $suffixWidth: number
  $theme: TextFieldProps['theme']
}
interface LabelMaskProps {
  $isLabel: boolean
}
interface LabelProps {
  $size: TextFieldProps['size']
  $isLabel: boolean
}
interface PictoProps {
  $slug: PictoSlug
  $picto?: PictoType
}
interface MessageProps {
  $pictoSlug: PictoSlug
}
export const Container = styled.div``
export const Input = styled.div`
  position: relative;
`
export const TextField = styled.input<InputProps>`
  ${typo.inputBook16};
  -moz-appearance: textfield;
  margin: 0;
  border: 1px solid;

  width: 100%;
  border-radius: ${g(1)};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral2};
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme, $placeholderAsLabel }) => ($placeholderAsLabel ? 'transparent' : theme.colors.neutral2)};
  }

  ${({ theme, $theme, value }) => {
    switch ($theme) {
      default:
      case 'default':
        return css`
          background-color: ${value ? theme.colors.white : theme.colors.neutral5};

          &:hover,
          ${Input}:hover & {
            border-color: ${theme.colors.neutral2};
          }

          &:focus {
            background-color: ${theme.colors.white};
          }
        `
      case 'color':
        return css`
          background-color: ${theme.colors.white};
          border-color: ${value ? theme.colors.neutral3 : theme.colors.white};

          &:hover,
          ${Input}:hover & {
            border-color: ${theme.colors.neutral2};
          }

          &:focus {
            border-color: ${theme.colors.neutral2};
          }
        `
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      default:
      case 'medium':
        return css`
          height: ${g(6)};
          padding: ${g(1)} ${g(2)};
        `
      case 'large':
        return css`
          height: ${g(8)};
          padding: ${g(2)};
        `
    }
  }}

  ${({ theme, $state, value }) => {
    switch ($state) {
      default:
      case 'default':
        return css`
          border-color: ${value ? theme.colors.neutral3 : theme.colors.neutral4};
        `
      case 'warning':
        return css`
          &,
          &:hover,
          &:focus {
            border-color: ${theme.colors.warning1};
          }
        `
      case 'error':
        return css`
          &,
          &:hover,
          &:focus {
            border-color: ${theme.colors.error1};
          }
        `
    }
  }}
  
  ${({ $pictoSlug, $isSuffixActive, $suffixWidth }) => {
    if ($isSuffixActive && $suffixWidth > 0) {
      return css`
        padding-right: ${g($suffixWidth / 8 + 3)};
      `
    }

    switch ($pictoSlug) {
      default:
      case 'default':
        return css`
          padding-right: ${g(7)};
        `

      case 'clear':
      case 'warning':
      case 'error':
        return css`
          padding-right: ${g(6)};
        `
    }
  }}
`

const ratio = 12 / 16

export const Label = styled.div<LabelProps>`
  ${typo.inputBook16};
  position: absolute;
  color: ${({ theme }) => theme.colors.neutral2};
  transition: transform 150ms ease-in-out;
  transform: translateY(0) scale(1);
  transform-origin: 0 0;
  left: ${g(2)};

  ${({ $size, $isLabel }) => {
    switch ($size) {
      default:
      case 'medium':
        return css`
          top: ${g(1)};

          ${TextField}:focus + && {
            transform: translateY(${g(-2.5)}) scale(${ratio});
          }

          ${$isLabel &&
          css`
            transform: translateY(${g(-2.5)}) scale(${ratio});
          `}
        `
      case 'large':
        return css`
          top: ${g(2)};

          ${TextField}:focus + && {
            transform: translateY(${g(-3.5)}) scale(${ratio});
          }

          ${$isLabel &&
          css`
            transform: translateY(${g(-3.5)}) scale(${ratio});
          `}
        `
    }
  }}
`

export const LabelMask = styled.div<LabelMaskProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: -1;
  opacity: 0;

  ${({ $isLabel }) =>
    $isLabel &&
    css`
      opacity: 1;
    `}

  ${TextField}:focus + ${Label} & {
    opacity: 1;
  }
`

const PICTO: Omit<Record<PictoSlug, PictoType>, 'default'> = {
  clear: TimesBold,
  warning: ExclamationMarkTriangleBold,
  error: ExclamationMarkCircleBold,
}
export const Picto = styled.svg.attrs<PictoProps>(({ $picto, $slug }) => {
  if ($picto && $slug === 'default') {
    return { as: $picto }
  }
  const picto = {
    ...PICTO,
    default: $picto,
  }
  return {
    as: picto[$slug],
  }
})<PictoProps>`
  position: absolute;
  right: ${g(2)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${({ $slug }) => {
    switch ($slug) {
      default:
      case 'default':
        return css`
          width: ${g(3)};
          height: ${g(3)};
        `
      case 'clear':
        return css`
          width: ${g(2)};
          height: ${g(2)};
          cursor: pointer;
          color: ${({ theme }) => theme.colors.neutral2};
        `
      case 'warning':
        return css`
          width: ${g(2)};
          height: ${g(2)};
          color: ${({ theme }) => theme.colors.warning1};
        `
      case 'error':
        return css`
          width: ${g(2)};
          height: ${g(2)};
          color: ${({ theme }) => theme.colors.error1};
        `
    }
  }}
`
export const Suffix = styled.span`
  display: block;
  position: absolute;
  right: ${g(2)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: ${({ theme }) => theme.colors.neutral2};

  ${Input}:focus-within & {
    color: ${({ theme }) => theme.colors.neutral1};
  }
`
export const Message = styled.div<MessageProps>`
  ${typo.caption12};
  margin-top: ${g(1)};

  a,
  button {
    color: inherit;
    cursor: pointer;
    font: inherit;
    text-decoration: underline;
  }

  ${({ theme, $pictoSlug }) => {
    switch ($pictoSlug) {
      case 'warning':
        return css`
          color: ${theme.colors.warning1};
        `
      case 'error':
        return css`
          color: ${theme.colors.error1};
        `
      default:
        break
    }
  }}
`
