import { SituationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { Housing } from '@pretto/app/src/types/gateway/enums'
import type { Maybe } from '@pretto/app/src/types/gateway/schema'

export const mapSituationType = (housing: Maybe<Housing>): SituationType | null => {
  switch (housing) {
    case Housing.Owner:
      return SituationType.Owner
    case Housing.Tenant:
      return SituationType.Tenant
    case Housing.Sheltered:
      return SituationType.Sheltered
    case Housing.StaffHoused:
      return SituationType.StaffHoused
    case Housing.Unspecified:
    default:
      return null
  }
}
