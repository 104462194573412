import { Picto } from '@pretto/picto'

import { ComponentPropsWithoutRef, ElementType, forwardRef, MouseEventHandler, Ref } from 'react'

import * as S from './MenuItem.styles'

export type ButtonOrLinkProps =
  | { href?: never; onClick: MouseEventHandler<HTMLDivElement> }
  | { href: string; onClick?: never }

export interface MenuItemProps {
  color?: string
  isActive?: boolean
  label: string
  picto: Picto
}

export const MenuItem = forwardRef(
  <T extends ElementType = 'div'>(
    props: MenuItemProps & ButtonOrLinkProps & ComponentPropsWithoutRef<T>,
    ref: Ref<HTMLDivElement>
  ) => {
    const { color = 'neutral1', href, isActive = false, isUnavailable = false, label, onClick, picto, ...rest } = props

    return (
      <S.MenuItem
        $isUnavailable={isUnavailable}
        $color={color}
        $isActive={isActive}
        href={href}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {picto && <S.Picto $isUnavailable={isUnavailable} as={picto} />}

        {label && (
          <S.Label $isUnavailable={isUnavailable} $isActive={isActive}>
            {label}
          </S.Label>
        )}
      </S.MenuItem>
    )
  }
)

MenuItem.displayName = 'MenuItem'
