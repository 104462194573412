import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { ExclamationMarkTriangleBold, Picto as PictoType, TimesBold } from '@pretto/picto'

import Cleave from 'cleave.js/react'
import styled, { css } from 'styled-components'

import { DateFieldProps } from './DateField'

interface InputProps {
  $size: DateFieldProps['size']
  $state: DateFieldProps['state']
  $isLabelActive: boolean
  $pictoSlug: PictoSlug
}

interface MessageProps {
  $pictoSlug: PictoSlug
}
type PictoSlug = 'default' | 'error' | 'clear'
interface PictoProps {
  $slug: PictoSlug
  $picto?: PictoType
}
interface LabelProps {
  $size: DateFieldProps['size']
  $isLabel: boolean
  $state: DateFieldProps['state']
}
interface LabelMaskProps {
  $isLabel: boolean
}

export const Container = styled.div``
export const Input = styled.div`
  position: relative;
`

export const DateField = styled(Cleave)<InputProps>`
  ${typo.inputBook16};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(2)};
  border-radius: ${g(1)};
  background-color: ${({ theme, value }) => (value ? theme.colors.white : theme.colors.neutral4)};
  caret-color: ${({ theme }) => theme.colors.neutral2};
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral2};
    background-color: ${({ theme }) => theme.colors.white};
    outline: 0;
  }

  &:hover {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.neutral2};
  }

  ${({ $isLabelActive }) => {
    if ($isLabelActive) {
      return css`
        &::placeholder {
          color: transparent;
        }
        &:focus::placeholder {
          color: ${({ theme }) => theme.colors.neutral3};
        }
      `
    }
  }}

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ${({ $size }) => {
    switch ($size) {
      default:
      case 'medium':
        return css`
          height: ${g(6)};
          padding: ${g(1)} ${g(2)};
        `
      case 'large':
        return css`
          height: ${g(8)};
          padding: ${g(2)};
        `
    }
  }}

  ${({ theme, $state, value }) => {
    switch ($state) {
      default:
      case 'default':
        return css`
          border-color: ${value ? theme.colors.neutral3 : theme.colors.neutral4};
        `
      case 'error':
        return css`
          &,
          &:hover,
          &:focus {
            border-color: ${theme.colors.error1};
          }
        `
    }
  }}
`

const ratio = 12 / 16

export const Label = styled.div<LabelProps>`
  ${typo.inputBook16};
  position: absolute;
  color: ${({ theme }) => theme.colors.neutral2};
  transition: transform 150ms ease-in-out;
  transform: translateY(0) scale(1);
  transform-origin: 0 0;
  left: ${g(2)};

  ${({ $size, $isLabel }) => {
    switch ($size) {
      default:
      case 'medium':
        return css`
          top: ${g(1)};

          ${DateField}:focus + && {
            transform: translateY(${g(-2.5)}) scale(${ratio});
          }

          ${$isLabel &&
          css`
            transform: translateY(${g(-2.5)}) scale(${ratio});
          `}
        `
      case 'large':
        return css`
          top: ${g(2)};

          ${DateField}:focus + && {
            transform: translateY(${g(-3.5)}) scale(${ratio});
          }

          ${$isLabel &&
          css`
            transform: translateY(${g(-3.5)}) scale(${ratio});
          `}
        `
    }
  }}

  ${({ $state, theme }) => {
    if ($state === 'error') {
      return css`
        color: ${theme.colors.error1};
      `
    }
  }}
`

export const LabelMask = styled.div<LabelMaskProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: -1;
  opacity: 0;

  ${({ $isLabel }) =>
    $isLabel &&
    css`
      opacity: 1;
    `}

  ${DateField}:focus + ${Label} & {
    opacity: 1;
  }
`

const PICTO: Omit<Record<PictoSlug, PictoType>, 'default'> = {
  clear: TimesBold,
  error: ExclamationMarkTriangleBold,
}

export const Picto = styled.svg.attrs<PictoProps>(({ $picto, $slug }) => {
  if ($picto && $slug === 'default') {
    return { as: $picto }
  }
  const picto = {
    ...PICTO,
    default: $picto,
  }
  return {
    as: picto[$slug],
  }
})<PictoProps>`
  position: absolute;
  right: ${g(2)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${({ $slug }) => {
    switch ($slug) {
      default:
      case 'default':
        return css`
          width: ${g(3)};
          height: ${g(3)};
        `
      case 'error':
        return css`
          width: ${g(2)};
          height: ${g(2)};
          color: ${({ theme }) => theme.colors.error1};
        `
      case 'clear':
        return css`
          width: ${g(2)};
          height: ${g(2)};
          cursor: pointer;
          color: ${({ theme }) => theme.colors.neutral2};
        `
    }
  }}
`

export const Message = styled.div<MessageProps>`
  ${typo.caption12};
  margin-top: ${g(1)};

  ${({ theme, $pictoSlug }) => {
    switch ($pictoSlug) {
      case 'error':
        return css`
          color: ${theme.colors.error1};
        `
      default:
        break
    }
  }}
`
