import { breakpoints, g } from '@pretto/bricks/components/layout'

import { CheckHandwrite } from '@pretto/picto'

import styled, { css } from 'styled-components'

import type { State } from './Step'

interface BulletProps {
  $iconState: State
}

export const Bullet = styled.div<BulletProps>`
  align-items: center;
  background-color: ${({ $iconState, theme }) => {
    if ($iconState === 'pending') {
      return theme.colors.primary1
    }
    if ($iconState === 'idle') {
      return theme.colors.neutral3
    }

    return theme.colors.neutral2
  }};
  border-radius: ${g(1, 3)};
  box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.neutral4};
  display: flex;
  flex-shrink: 0;
  height: ${g(3, -2)};
  margin: ${g(1 / 2)};
  justify-content: center;
  width: ${g(3, -2)};
  z-index: 1;
`

export const BulletIcon = styled(CheckHandwrite)`
  fill: ${({ theme }) => theme.colors.white};
  height: ${g(2)};
  width: ${g(2)};
`

interface StepState {
  $stepState: State
}

export const Card = styled.div<StepState>`
  background-color: ${({ $stepState, theme }) =>
    $stepState === 'pending' ? theme.colors.primary1 : theme.colors.white};
  border-radius: ${g(1)};
  color: ${({ $stepState, theme }) => ($stepState === 'pending' ? theme.colors.white : theme.colors.neutral3)};
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: ${g(1)};
  padding: ${g(2)} ${g(2)} ${g(3)};
  z-index: 2;
`

export const CardTitle = styled.h2<StepState>`
  ${({ theme }) => theme.typos.heading5};

  ${({ $stepState, theme }) =>
    $stepState === 'done' &&
    css`
      color: ${theme.colors.neutral2};
    `};
`

export const CardContent = styled.div<StepState>`
  ${({ theme }) => theme.typos.caption};

  ${({ $stepState, theme }) =>
    $stepState === 'done' &&
    css`
      color: ${theme.colors.neutral1};
    `};
`

export const Step = styled.div`
  display: flex;
  gap: ${g(2)};
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`
