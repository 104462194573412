import { g } from '@pretto/bricks/components/layout'

import { ResizableField, ResizableFieldRef } from '@pretto/zen/reveal/atoms/textfields/ResizableField/ResizableField'

import { useRef, useState } from 'react'
import styled from 'styled-components'

export interface RowNumberProps {
  label: string
  onChange: (value: number) => void
  value: number
}

export const RowNumber: React.FC<RowNumberProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>> = ({
  label,
  onChange,
  value,
  ...props
}) => {
  const resizableFieldRef = useRef<ResizableFieldRef>(null)

  const [fieldValue, setFieldValue] = useState(value.toString())

  const handleChange = (value: string) => {
    setFieldValue(value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    onChange(parseInt(fieldValue, 10))

    resizableFieldRef.current?.blur()
  }

  return (
    <Row {...props}>
      <span>{label}</span>

      <Value>
        <form onSubmit={handleSubmit}>
          <ResizableField
            inputProps={{ placeholder: (10000).toLocaleString('fr'), type: 'integer' }}
            onChange={handleChange}
            ref={resizableFieldRef}
            submittable
            value={fieldValue}
          />
        </form>

        <span>€</span>
      </Value>
    </Row>
  )
}

const Row = styled.section`
  ${({ theme }) => theme.typos.body4};
  align-items: center;
  display: flex;
  gap: ${g(2)};
`

const Value = styled.span`
  align-items: center;
  display: flex;
  gap: ${g(1 / 2)};
  margin-left: auto;
  min-height: ${g(6, 2)};
`
