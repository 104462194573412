import { OPTIONS } from '@pretto/bricks/app/maturity/components/MaturityUpdateTemplate/constants'
import Link from '@pretto/bricks/website/utility/Link'

import { EBOOK_URL } from '@pretto/config-front/constants'

import qs from 'qs'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'

const getResultPageProps = (typology, campaign, maturity, history) => {
  const details = {
    prelead: {
      nurturing: {
        under_study: {
          buttonTitle: 'Finaliser mon inscription',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        search_started: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        offer_made: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, finalisez votre inscription sur votre espace client.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        offer_accepted: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations pour cette offre d’achat ! Finalisez votre inscription pour être recontacté rapidement par un Expert Pretto et constituer votre dossier de financement.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Finalisez votre inscription pour être recontacté rapidement par un Expert Pretto.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        project_lost: {
          buttonTitle: 'Simuler mes économies',
          description:
            'Félicitations pour votre prêt ! Nos équipes peuvent vous accompagner dans la négociation de votre assurance emprunteur et vous faire économiser 10,000€ en moyenne.',
          onClick: () =>
            window.open(
              'https://pretto-fr.typeform.com/to/gNaE9ohR?utm_source=pretto&utm_medium=referral&utm_campaign=maturity-update-nurturing',
              '_blank'
            ),
        },
      },
      onboarding: {
        under_study: {
          buttonTitle: 'Finaliser mon inscription',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        search_started: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        offer_made: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, finalisez votre inscription sur votre espace client.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        offer_accepted: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations pour cette offre d’achat ! Finalisez votre inscription pour être recontacté rapidement par un Expert Pretto et constituer votre dossier de financement.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Finaliser mon inscription',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Finalisez votre inscription pour être recontacté rapidement par un Expert Pretto.',
          onClick: () => history.push('/simulation/subscribe?origin=dashboard'),
        },
      },
    },
    piste: {
      nurturing: {
        under_study: {
          buttonTitle: 'Retour à l’Espace Client',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/'),
        },
        search_started: {
          buttonTitle: 'Retour à l’Espace Client',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/'),
        },
        offer_made: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, prenez rendez-vous avec votre Conseiller Pretto.',
          onClick: () => history.push('/'),
        },
        offer_accepted: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour cette offre d’achat ! Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Conseiller Pretto pour constituer votre dossier.',
          onClick: () => history.push('/'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Conseiller Pretto pour constituer votre dossier.',
          onClick: () => history.push('/'),
        },
        project_lost: {
          buttonTitle: 'Simuler mes économies',
          description:
            'Félicitations pour votre prêt ! Nos équipes peuvent vous accompagner dans la négociation de votre assurance emprunteur et vous faire économiser 10,000€ en moyenne.',
          onClick: () =>
            window.open(
              'https://pretto-fr.typeform.com/to/gNaE9ohR?utm_source=pretto&utm_medium=referral&utm_campaign=maturity-update-nurturing',
              '_blank'
            ),
        },
      },
      onboarding: {
        under_study: {
          buttonTitle: 'Retour à l’Espace Client',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/'),
        },
        search_started: {
          buttonTitle: 'Retour à l’Espace Client',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/'),
        },
        offer_made: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, prenez rendez-vous dès maintenant avec un Conseiller Pretto.',
          onClick: () => history.push('/'),
        },
        offer_accepted: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour cette offre d’achat ! Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Conseiller Pretto pour constituer votre dossier.',
          onClick: () => history.push('/'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Conseiller Pretto pour constituer votre dossier.',
          onClick: () => history.push('/'),
        },
      },
    },
    lead: {
      nurturing: {
        under_study: {
          buttonTitle: 'Retour à l’Espace Client',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/'),
        },
        search_started: {
          buttonTitle: 'Retour à l’Espace Client',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/'),
        },
        offer_made: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, prenez rendez-vous dès maintenant avec votre Expert Pretto.',
          onClick: () => history.push('/booking/appointment'),
        },
        offer_accepted: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour cette offre d’achat ! Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Expert Pretto.',
          onClick: () => history.push('/booking/appointment'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous avec votre Expert Pretto dès maintenant pour constituer votre dossier.',
          onClick: () => history.push('/booking/appointment'),
        },
        project_lost: {
          buttonTitle: 'Simuler mes économies',
          description:
            'Félicitations pour votre prêt ! Nos équipes peuvent vous accompagner dans la négociation de votre assurance emprunteur et vous faire économiser 10,000€ en moyenne.',
          onClick: () =>
            window.open(
              'https://pretto-fr.typeform.com/to/gNaE9ohR?utm_source=pretto&utm_medium=referral&utm_campaign=maturity-update-nurturing',
              '_blank'
            ),
        },
      },
      onboarding: {
        under_study: {
          buttonTitle: 'Retour à l’Espace Client',
          description: (
            <>
              Maintenant que vous avez avancé sur votre projet, on imagine que vous devez vous poser mille questions sur
              l’achat immobilier. Pour vous aider à y voir plus clair, nous avons créé pour vous{' '}
              <Link href={EBOOK_URL}>le guide de l’acheteur</Link>.
            </>
          ),
          onClick: () => history.push('/'),
        },
        search_started: {
          buttonTitle: 'Retour à l’Espace Client',
          description:
            'Maintenant que vous êtes en recherche active, distinguez-vous des autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, complétez votre profil sur votre espace client.',
          onClick: () => history.push('/'),
        },
        offer_made: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations, vous avez trouvé la perle rare ! Faites la différence avec les autres acquéreurs en présentant une attestation de financement. Pour l’obtenir, prenez rendez-vous dès maintenant avec votre Expert Pretto.',
          onClick: () => history.push('/booking/appointment'),
        },
        offer_accepted: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour cette offre d’achat ! Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous dès maintenant avec votre Expert Pretto.',
          onClick: () => history.push('/booking/appointment'),
        },
        sale_agreement_signed: {
          buttonTitle: 'Prendre rendez-vous',
          description:
            'Félicitations pour la signature de votre compromis ! 💚 Le compte à rebours est lancé pour trouver le financement de votre projet. Prenez rendez-vous avec votre Expert Pretto dès maintenant pour constituer votre dossier.',
          onClick: () => history.push('/booking/appointment'),
        },
      },
    },
  }

  return details[typology][campaign][maturity]
}

const useMaturityUpdateController = ({ isLoading, onClick, projectStatus, isLostHidden }) => {
  const location = useLocation()
  const history = useHistory()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { campaign = 'onboarding', currmaturity = 'search_started', email, projectid, sig, typology = 'piste' } = params
  const [maturity, setMaturity] = useState(projectStatus || currmaturity)
  const [date, setDate] = useState('')
  const [error, setError] = useState(false)

  const handleRadioChange = maturity => {
    setMaturity(maturity)
  }

  const handleDateChange = date => {
    if (date.length !== 10) setError(true)
    else {
      setError(false)
    }
    setDate(date)
  }

  const handleSubmit = () => {
    if (!maturity || isLoading) return
    if (maturity === 'sale_agreement_signed' && !date) return setError(true)
    onClick(maturity, date, email, projectid, sig, typology)
  }

  const radioProps = {
    name: 'radio',
    onChange: handleRadioChange,
    options: isLostHidden ? OPTIONS.slice(0, -1) : OPTIONS,
    valueSelected: maturity,
  }

  const inputProps = {
    inputProps: {
      placeholder: 'JJ/MM/AAAA',
      type: 'date',
    },
    message: 'Veuillez indiquer la date de signature de votre compromis',
    onChange: handleDateChange,
    state: (error && 'error') || undefined,
    value: date,
  }

  const resultPageProps = getResultPageProps(typology, campaign, maturity, history)

  return { resultPageProps, handleSubmit, inputProps, isErrored: error, maturity, radioProps }
}

export default useMaturityUpdateController
