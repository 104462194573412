import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CATEGORY = {
  analysis: 'Amélioration de votre expérience',
  auth: 'Inscription et authentification',
  clientRelation: 'Relation client et suivi de dossier',
  hosting: 'Infrastructure technique et hébergement',
  marketing: 'Marketing et partenariats',
  storage: 'Stockage et traitement des pièces justificatives',
}
const DATA = {
  cookies: 'Cookies',
  documents: 'Pièces justificatives',
  email: 'Email',
  firstName: 'Prénom',
  ip: 'Adresse IP',
  lastName: 'Nom',
  meta: 'Metadonnées sur le projet (type de bien, utilisation)',
  nav: 'Données de navigation',
  phone: 'Numéro de tel',
  project: 'Détails du projet',
}
const PrivacyInformation = ({ category, data }) => (
  <div>
    <S.Category>
      <SubHeading>{CATEGORY[category]}</SubHeading>
    </S.Category>
    <div>
      Données collectées :{` ${data.map(elem => DATA[elem]).join(', ')}. `}
      {category === 'clientRelation' && (
        <span>
          De plus, nous sommes susceptibles d'enregistrer des conversations téléphoniques dans le but d'améliorer votre
          expérience client ainsi que la formation de nos conseillers.
        </span>
      )}
    </div>
  </div>
)

PrivacyInformation.propTypes = {
  category: PropTypes.oneOf(Object.keys(CATEGORY)).isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(DATA))).isRequired,
}

export default memo(PrivacyInformation)
