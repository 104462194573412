export const addAlpha = (hexadecimalColor: string, alpha: number | string): string => {
  if (!/^#[a-fA-F0-9]{6}$/.test(hexadecimalColor)) {
    throw new Error('addAlpha does not support non full hexadecimal color value (eg. #ff0044)')
  }

  if (typeof alpha === 'string' && !alpha.startsWith('var')) {
    throw new Error('alpha argument must be a css variable')
  }

  if (typeof alpha === 'number' && (alpha < 0 || alpha > 1)) {
    throw new Error('alpha argument must be positive and inferior or equal to 1')
  }

  const red = parseInt(hexadecimalColor.slice(1, 3), 16)
  const green = parseInt(hexadecimalColor.slice(3, 5), 16)
  const blue = parseInt(hexadecimalColor.slice(5, 7), 16)

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}
