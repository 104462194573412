import { Message as MessageComponent, States } from '@pretto/zen/reveal/atoms/message/Message'
import { ButtonInline as ButtonComponent } from '@pretto/zen/reveal/atoms/buttons/buttonInline/ButtonInline'
import CardComponent from '@pretto/bricks/components/cards/Card'
import TagComponent from '@pretto/bricks/components/information/Tag'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Button = styled(ButtonComponent).attrs({ })``

export const MessageInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const InfoMessage = styled(MessageComponent).attrs({ state: States.Info })`
  align-items: center;
  margin-bottom: ${g(3)};
`

export const WarningMessage = styled(MessageComponent).attrs({ state: States.Warning })`
  align-items: center;
  margin-bottom: ${g(3)};
`

export const Card = styled(CardComponent).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(3)};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const Icon = styled.svg`
  margin-right: ${g(1)};
`

export const Infos = styled.div`
  display: grid;
  grid-gap: ${g(3)};
  grid-template-columns: 1fr;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 35% 65%;
  }
`

export const Localisations = styled.div`
  margin-bottom: ${g(3)};
`

export const Tag = styled(TagComponent).attrs({ size: "large", textVariant: "primary1", variant: "neutral-1-l" })`
  padding: ${g(1)} ${g(2)};
  height: auto;
  line-height: inherit;
  margin-left: ${g(1)};
`