import range from 'lodash/range'
import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Dots = ({ activeVariant, inactiveVariant, isActive, number, onClick, position }) => (
  <S.Dots $position={position}>
    {range(number).map(index => {
      const handleClick = () => {
        onClick(index)
      }

      return (
        <S.Dot
          $activeVariant={activeVariant}
          $inactiveVariant={inactiveVariant}
          $isActive={isActive === index}
          key={index}
          onClick={handleClick}
        />
      )
    })}
  </S.Dots>
)

Dots.defaultProps = {
  activeVariant: 'accent1',
  inactiveVariant: 'accent1Disabled',
  isActive: 0,
  onClick: () => {},
  position: 'center',
}

Dots.propTypes = {
  /** Color variant of active dot. */
  activeVariant: PropTypes.string,
  /** Color variant of inactive dots. */
  inactiveVariant: PropTypes.string,
  /** What dot isActive. Starting at 0. */
  isActive: PropTypes.number,
  /** Number of dots to display. */
  number: PropTypes.number.isRequired,
  /** Event triggered whenever a dot is being clicked. */
  onClick: PropTypes.func,
  /** Position of dots. */
  position: PropTypes.oneOf(['center', 'left', 'right']),
}

export default memo(Dots)
