import FormField from '@pretto/bricks/components/layout/FormField'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import * as F from '@pretto/app-core/config/fields'
import { isFormDisabled } from '@pretto/app-core/lib/isFormDisabled'

import { ExclamationMarkTriangle } from '@pretto/picto'

import defaultTo from 'lodash/defaultTo'

const getFieldForType = (type, field, props, ...args) => {
  switch (type) {
    case 'address':
    case 'city':
    case 'country':
      return <F.PlaceField {...props} type={type} />

    case 'bank':
      return <F.BankField {...props} />

    case 'banks':
      return <F.BanksField {...props} />

    case 'banksfull':
      return <F.BanksFields {...props} />

    case 'completelist':
      return (
        <F.CompleteListField
          {...props}
          renderFields={(values, handleChange) => renderFields(field.fields, handleChange, values, ...args)}
        />
      )

    case 'date':
      return <F.DateField {...props} />

    case 'decimal':
    case 'numberfield':
      return <F.NumberField {...props} type={field.type} />

    case 'list':
      return (
        <F.ListField
          {...props}
          isFormDisabled={(values, errors) => isFormDisabled(field.fields, values, errors, ...args)}
          renderFields={(values, handleChange) => renderFields(field.fields, handleChange, values, ...args)}
        />
      )

    case 'income':
      return <F.IncomeField {...props} />

    case 'radio':
      return <F.RadioField {...props} />

    case 'range':
      return <F.RangeField {...props} />

    case 'rating':
      return <F.RatingField {...props} />

    case 'select':
      return <F.SelectField {...props} />

    case 'textarea':
    case 'textfield':
    case 'tel':
    case 'email':
      return <F.TextField {...props} value={typeof props.value !== 'string' ? '' : props.value} />

    case 'error':
      return (
        <Banner picto={ExclamationMarkTriangle} type={'error'}>
          {field.children}
        </Banner>
      )

    default:
      return 'Invalid field type'
  }
}

export const renderFields = (fields, handleChange, values, ...args) =>
  fields.reduce((previous, field) => {
    const condition = funcToItem(defaultTo(field.condition, true), values, ...args)

    if (!condition) {
      return previous
    }

    const label = funcToItem(field.label, values, ...args)
    const type = funcToItem(field.type, values, ...args)

    const props = {
      ...funcToItem(field.fieldProps || {}, values, ...args),
      onChange: handleChange.bind(null, field.name),
      value: values[field.name],
    }

    const component = getFieldForType(type, field, props, ...args)

    return [
      ...previous,
      {
        component: <FormField label={label}>{component}</FormField>,
        key: field.key || field.name,
      },
    ]
  }, [])
