import FormField from '@pretto/bricks/components/layout/FormField'
import FormList from '@pretto/bricks/components/layout/FormList'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { BanksField } from '@pretto/app-core/components/BanksField'

import BANKS from '@pretto/config/banks.json'

import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { TransitionGroup } from 'react-transition-group'

export const BanksFields = ({ groupTitle, onChange, value }) => {
  const [values, setValues] = useState([])

  const handleChange = values => {
    setValues(values)

    const hasError = !values.every(
      value1 =>
        BANKS.some(({ label }) => label === value1) ||
        values.some(value2 => BANKS.some(({ group, label }) => group === value1 && label === value2))
    )

    const slugs = BANKS.filter(bank => values.some(value => bank.label === value)).map(({ value }) => value)

    onChange(slugs, hasError)
  }

  const banks = uniq([
    ...Object.keys(
      groupBy(
        BANKS.filter(bank => value.some(value => bank.value === value) && bank.group),
        'group'
      )
    ),
    ...BANKS.filter(bank => value.some(value => bank.value === value)).map(({ label }) => label),
    ...values.filter(bank => BANKS.some(({ group }) => group === bank)),
  ])

  const groups = sortBy(
    uniq([
      ...banks.filter(bank => BANKS.some(({ group }) => group === bank)),
      ...values.filter(bank => BANKS.some(({ group }) => group === bank)),
    ])
  )

  return (
    <TransitionGroup>
      <FormList
        fields={[
          {
            component: <BanksField onChange={handleChange} value={banks} />,
            key: 0,
          },
          ...groups.map(group => ({
            component: (
              <FormField label={funcToItem(groupTitle, group)}>
                <BanksField group={group} onChange={handleChange} value={banks} />
              </FormField>
            ),
            key: group,
          })),
        ]}
      />
    </TransitionGroup>
  )
}

BanksFields.defaultProps = {
  value: [],
}

BanksFields.propTypes = {
  groupTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
}
