import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronLeftBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Button = styled.div.attrs(({ $isDisabled, href }) => ({
  as: href ? Link : 'button',
  disabled: !href && $isDisabled ? true : null,
  tabIndex: $isDisabled ? -1 : 0,
}))`
  ${typo.linkUnderline12};
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ $isDisabled, theme }) => ($isDisabled ? theme.colors.neutral3 : theme.colors.neutral1)};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.colors.neutral2};
      }
    `};
`
export const Arrow = styled(ChevronLeftBold)`
  height: ${g(2)};
  width: ${g(2)};
  margin-right: ${g(1)};
`
