import { useBreakpoint } from '@pretto/bricks/assets/utility'

import { AgendaAdvisor } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import {
  formatShortDateEN,
  formatShortDateFR,
} from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/timeFormatter'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR_LABELS, type AdvisorLabelsType } from '@pretto/app/src/config/advisor'

import { upperFirst } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import * as S from './Calendar.styles'
import { Footer } from './components/Footer/Footer'
import { Slots } from './components/Slots/Slots'

export interface Slot {
  label: string
  utcDate: string
  advisor: AgendaAdvisor
  kind: string
}

export interface Day {
  date: string
  slots: Slot[]
}

interface DialogSaveProps {
  advisorLabel: AdvisorLabelsType
  days: Day[]
  onBooked: (slot?: Slot) => void
  onTrackNext: () => void
}

export const Calendar: React.FC<DialogSaveProps> = ({
  advisorLabel = ADVISOR_LABELS.expert,
  days,
  onBooked,
  onTrackNext,
}) => {
  const [offset, setOffset] = useState<number>(0)
  const [isCalendarDisabled, setIsCalendarDisabled] = useState<boolean>(false)
  const { isMobile } = useBreakpoint()
  const { isEnglishUser } = useUser()

  const shownDays = isMobile ? 1 : 3

  const decrementOffset = () => {
    setOffset(previous => previous - 1)
  }

  const incrementOffset = () => {
    onTrackNext()
    setOffset(previous => previous + 1)
  }

  const handleBooked = (selectedSlot?: Slot): void => {
    setIsCalendarDisabled(true)
    onBooked(selectedSlot)
  }

  const daysToShow = useMemo(() => days.slice(offset, offset + shownDays), [days, offset])

  const isSomeVisioEnabled = daysToShow.some(({ slots }) => slots.some(({ advisor }) => advisor.isVisio))

  const formatShortDate = isEnglishUser ? formatShortDateEN : formatShortDateFR

  return (
    <>
      <S.Header>
        <Trans i18nKey="mutualizedAgenda.description" values={{ advisorLabel: upperFirst(advisorLabel) }} />
      </S.Header>
      <S.Calendar $isDisabled={isCalendarDisabled}>
        {offset > 0 && <S.Previous onClick={decrementOffset} />}
        {offset < days.length - shownDays && <S.Next onClick={incrementOffset} />}
        <S.HeroContainer>
          {daysToShow.map(({ date }) => (
            <S.Hero key={`hero-${date}`}>{formatShortDate(date)}</S.Hero>
          ))}
        </S.HeroContainer>
        <S.SlotsContainer>
          {daysToShow.map(({ date, slots }) => (
            <Slots key={`slots-${date}`} date={date} slots={slots} onBooked={handleBooked} />
          ))}
        </S.SlotsContainer>

        <Footer isVisioEnabled={isSomeVisioEnabled} />
      </S.Calendar>
    </>
  )
}
