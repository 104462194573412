import url from 'url'

const DEFAULT_CONTENT =
  'Estimez votre budget et obtenez le meilleur taux avec Pretto, le seul courtier 100% en ligne, simple et transparent.'

interface Label {
  banner: string
  logo: string
  logoMargin?: {
    top?: string
    bottom?: string
  }
  partners: {
    content: string
    logo: string
  }
  path?: string
  style: {
    background: string
    color: string
  }
}

export const partners = {
  bankin: {
    banner: "Pretto et Bankin' vous accompagnent pour trouver le meilleur prêt immobilier.",
    logo: 'v1652103482/zen/static-assets/logos/partners/bankin.png',
    partners: {
      content: "Pretto et Bankin' vous accompagnent pour trouver le meilleur prêt immobilier",
      logo: 'whitelabel/bankin2.svg',
    },
    style: {
      background: '#50baff',
      color: '#ffffff',
    },
  },
  bienici: {
    banner: "Partenaire de bien'ici, Pretto négocie votre crédit immobilier au meilleur taux.",
    logo: 'v1652103482/zen/static-assets/logos/partners/bienici.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'c_scale,h_30/v1621519413/whitelabel/bienici.png',
    },
    style: {
      background: '#ffbc24',
      color: '#ffffff',
    },
  },
  credigo: {
    banner: 'Partenaire de Crédigo, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103482/zen/static-assets/logos/partners/credigo.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/credigo.svg',
    },
    style: {
      background: '#e4146b',
      color: '#ffffff',
    },
  },
  epremio: {
    banner: 'Partenaire de E-prémio, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103482/zen/static-assets/logos/partners/e-premio.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/epremio.svg',
    },
    style: {
      background: '#39a8e0',
      color: '#ffffff',
    },
  },
  'figaro-immobilier': {
    banner: 'Partenaire du Figaro Immobilier, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1680188064/zen/static-assets/logos/partners/figaro-proprietes.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1680188064/zen/static-assets/logos/partners/figaro-proprietes.svg',
    },
    path: 'https://immobilier.lefigaro.fr/',
    style: {
      background: '#DEAB38',
      color: '#ffffff',
    },
  },
  homki: {
    banner: 'Partenaire de Homki, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1621492418/whitelabel/homki.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1621492418/whitelabel/homki.svg',
    },
    style: {
      background: '#202648',
      color: '#ffffff',
    },
  },
  hosman: {
    banner: "Partenaire d'Hosman, Pretto négocie votre crédit immobilier au meilleur taux.",
    logo: 'v1652103482/zen/static-assets/logos/partners/hosman.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1603206455/whitelabel/hosman.svg',
    },
    style: {
      background: '#232f60',
      color: '#ffffff',
    },
  },
  iad: {
    banner: 'Partenaire de IAD, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1669279859/zen/static-assets/logos/partners/iad.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1669279954/whitelabel/iad.svg',
    },
    path: 'https://www.iadfrance.fr/',
    style: {
      background: '#0e6e94',
      color: '#ffffff',
    },
  },
  'immo-pop': {
    banner: 'Partenaire de Immo-pop, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103482/zen/static-assets/logos/partners/immopop.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1580201899/whitelabel/immo-pop.svg',
    },
    style: {
      background: '#e04438',
      color: '#ffffff',
    },
  },
  'immobilier-danger': {
    banner: 'Partenaire de Immobilier Danger, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: '/v1666773853/zen/static-assets/logos/partners/immo-danger2.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: '/v1666713793/whitelabel/immo-danger.svg',
    },
    path: 'https://www.immobilier-danger.com/',
    style: {
      background: '#303a49',
      color: '#ffffff',
    },
  },
  immobilierfrance: {
    banner: 'Partenaire de Immobilier France, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1603206455/whitelabel/immobilier-france.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1603206455/whitelabel/immobilier-france.svg',
    },
    style: {
      background: '#70b10a',
      color: '#ffffff',
    },
  },
  immoneuf: {
    banner: 'Partenaire de Immoneuf, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1704792641/zen/static-assets/logos/partners/immoneuf.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1704792430/whitelabel/immoneuf.svg',
    },
    path: 'https://www.immoneuf.com/',
    style: {
      background: '#5eae46',
      color: '#ffffff',
    },
  },
  jump: {
    banner: 'Jump, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1651756904/whitelabel/Jump.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1651756904/whitelabel/Jump.svg',
    },
    style: {
      background: '#030035',
      color: '#ffffff',
    },
  },
  lesfurets: {
    banner: 'Partenaire de Lesfurets, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1667901692/zen/static-assets/logos/partners/les-furets.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1667901853/whitelabel/les-furets.svg',
    },
    path: 'https://www.lesfurets.com/',
    style: {
      background: '#13acf7',
      color: '#ffffff',
    },
  },
  liberkeys: {
    banner: 'Partenaire de Liberkeys, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103483/zen/static-assets/logos/partners/liberkeys.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/liberkeys1.svg',
    },
    style: {
      background: '#ff556a',
      color: '#ffffff',
    },
  },
  lydia: {
    banner: 'Partenaire de Lydia, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103483/zen/static-assets/logos/partners/lydia.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/lydia.svg',
    },
    style: {
      background: '#007eff',
      color: '#ffffff',
    },
  },
  myexpat: {
    banner: 'Partenaire de MyExpat, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'whitelabel/myexpat.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/myexpat.svg',
    },
    style: {
      background: '#93c649',
      color: '#ffffff',
    },
  },
  mynotary: {
    banner: 'Pretto et MyNotary vous accompagnent pour trouver le meilleur prêt immobilier.',
    logo: 'whitelabel/mynotary.svg',
    partners: {
      content: 'Pretto et MyNotary vous accompagnent pour trouver le meilleur prêt immobilier',
      logo: 'whitelabel/mynotary.svg',
    },
    style: {
      background: '#28b1b3',
      color: '#ffffff',
    },
  },
  nomadhomes: {
    banner: 'Partenaire de Nomad Homes, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1617868403/whitelabel/nomad.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1617868403/whitelabel/nomad.svg',
    },
    style: {
      background: '#0b0d11',
      color: '#ffffff',
    },
  },
  ooinvestir: {
    banner: 'Partenaire de Ooinvestir, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103483/zen/static-assets/logos/partners/ooinvestir.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1624888685/whitelabel/ooinvestir.svg',
    },
    style: {
      background: '#00989b',
      color: '#ffffff',
    },
  },
  papernest: {
    banner: 'Partenaire de papernest, Pretto négocie pour vous le meilleur taux immobilier.',
    logo: 'v1652103483/zen/static-assets/logos/partners/papernest.png',
    partners: {
      content:
        'Parce que vous êtes sur le point d’acheter le logement de vos rêves, nous vous accompagnons tout au long de votre projet. Trouvez le meilleur prêt immobilier avec Pretto, et déménagez gratuitement tous vos contrats en une seule fois avec papernest.',
      logo: 'whitelabel/papernest.svg',
    },
    style: {
      background: '#5a52ff',
      color: '#ffffff',
    },
  },
  paruvendu: {
    banner: 'Partenaire de ParuVendu, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1702386764/whitelabel/paruvendu.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1702386764/whitelabel/paruvendu.svg',
    },
    style: {
      background: '#231F20',
      color: '#ffffff',
    },
  },
  proprioo: {
    banner: 'Partenaire de Proprioo, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'whitelabel/proprioo.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/proprioo.svg',
    },
    style: {
      background: '#00d49c',
      color: '#ffffff',
    },
  },
  'reassurez-moi': {
    banner: 'Partenaire de Réassurez-moi, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1702376214/whitelabel/reassurez-moi.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1702376214/whitelabel/reassurez-moi.svg',
    },
    style: {
      background: '#023B79',
      color: '#ffffff',
    },
  },
  seloger: {
    banner: 'Partenaire de SeLoger, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1709738228/zen/static-assets/logos/partners/SE_LOGER.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1709738228/zen/static-assets/logos/partners/SE_LOGER.svg',
    },
    style: {
      background: '#E4080B',
      color: '#ffffff',
    },
  },
  solutis: {
    banner: 'Partenaire de Solutis, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103483/zen/static-assets/logos/partners/solutis.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/solutis.svg',
    },
    style: {
      background: '#0056a6',
      color: '#ffffff',
    },
  },
  swile: {
    banner: 'Partenaire de Swile, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1652103484/zen/static-assets/logos/partners/swile.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1615539515/whitelabel/swile.svg',
    },
    style: {
      background: '#633fd3',
      color: '#ffffff',
    },
  },
  vianova: {
    banner: 'Partenaire de Vianova, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1580201899/whitelabel/vianova.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1580201899/whitelabel/vianova.svg',
    },
    style: {
      background: '#092c60',
      color: '#ffffff',
    },
  },
  welmo: {
    banner: 'Partenaire de Welmo, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1698059039/zen/static-assets/logos/partners/welmo.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'whitelabel/welmo.svg',
    },
    style: {
      background: '#00496f',
      color: '#ffffff',
    },
  },
  xolo: {
    banner: 'Partenaire de Xolo, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1655298157/zen/static-assets/logos/partners/xolo.png',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1655302553/whitelabel/xolo.svg',
    },
    style: {
      background: '#ff4506',
      color: '#ffffff',
    },
  },
  'younited-credit': {
    banner: 'Partenaire de Younited Credit, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1682604358/zen/static-assets/logos/partners/younited-credit.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1682604358/zen/static-assets/logos/partners/younited-credit.svg',
    },
    style: {
      background: '#C7A2FE',
      color: '#ffffff',
    },
  },
  effy: {
    banner: 'Partenaire de Effy, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1721314504/zen/static-assets/logos/partners/effy.svg',
    logoMargin: {
      top: '12px',
    },
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1721314504/zen/static-assets/logos/partners/effy.svg',
    },
    style: {
      background: '#0a2e4f',
      color: '#ffffff',
    },
  },
  immonot: {
    banner: 'Partenaire de Immonot, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1732829549/zen/static-assets/logos/partners/log_immonot.svg',
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1732829549/zen/static-assets/logos/partners/log_immonot.svg',
    },
    path: 'https://www.immonot.com/',
    style: {
      background: '#C91862',
      color: '#ffffff',
    },
  },
  wedivorce: {
    banner: 'Partenaire de WeDivorce, Pretto négocie votre crédit immobilier au meilleur taux.',
    logo: 'v1721996802/zen/static-assets/logos/partners/WeDivorce.svg',
    logoMargin: {
      bottom: '8px',
    },
    partners: {
      content: DEFAULT_CONTENT,
      logo: 'v1721996802/zen/static-assets/logos/partners/WeDivorce.svg',
    },
    style: {
      background: '#5D8A74',
      color: '#ffffff',
    },
  },
} as const satisfies { [K: string]: Label }

export const getContextPartner = () => {
  const match = window.location.hostname.match(
    new RegExp(`^(${Object.keys(partners).join('|')})\\.${url.parse(process.env.CLIENT_HOST).hostname}$`)
  )

  if (!match) {
    return null
  }

  const [, subdomain] = match

  return { ...partners[subdomain as keyof typeof partners], subdomain }
}

export const isPartnerDomain = () => getContextPartner() !== null

// legacy
export const isWhiteLabel = isPartnerDomain
export const whiteLabels = partners
export const whiteLabel = () => getContextPartner() ?? {}
export default whiteLabel
