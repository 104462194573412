import { breakpoints } from '@pretto/bricks/components/layout'

import { Benefit, Unit } from '@pretto/app/src/Capacity/views/ResultPage/types/benefit'

import styled from 'styled-components'

export const formatBenefit = ({ value, unit }: Benefit) => {
  switch (unit) {
    case Unit.Percentage:
      return `+ ${value.toLocaleString('fr')} %`

    case Unit.Price:
    default: {
      return <Value $rest={value.toLocaleString('fr').slice(-3)}>+ {(value / 1000).toLocaleString('fr')}</Value>
    }
  }
}

interface ValueProps {
  $rest: string
}

const Value = styled.span<ValueProps>`
  &:after {
    content: ' k€';
  }

  @media screen and (min-width: ${breakpoints.mobileM}) {
    &:after {
      content: ' ${({ $rest }) => $rest} €';
    }
  }
`
