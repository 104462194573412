import { useClickOutside } from '@pretto/bricks/assets/utility/useClickOutside'

import { useEffect, useRef } from 'react'

import * as S from './Dropdown.styles'

export interface DropdownProps {
  children: React.ReactNode
  label?: React.ReactNode
  defaultLabel?: React.ReactNode
  size?: 'small' | 'medium'
  isOpen: boolean
  onOpen: React.MouseEventHandler<HTMLElement>
  onClose: React.MouseEventHandler<HTMLDivElement>
  LabelComponent?: React.ReactNode
  ContainerComponent?: React.ReactNode
}

export const Dropdown = ({
  children,
  defaultLabel,
  label,
  isOpen,
  onOpen,
  onClose,
  size = 'medium',
  LabelComponent,
  ContainerComponent,
  ...props
}: DropdownProps) => {
  const ref = useClickOutside(onClose)
  const contentRef = useRef<HTMLDivElement>(null)

  const isSelected = !!label

  useEffect(() => {
    contentRef.current?.scrollTo(0, 0)
  }, [isOpen])

  return (
    <S.DropdownContainer {...props}>
      <S.Label
        aria-expanded={isOpen}
        onClick={onOpen}
        $isSelected={isSelected}
        $size={size}
        $component={LabelComponent}
      >
        <S.Text>{label || defaultLabel}</S.Text>
        <S.Arrow $isOpen={isOpen} />
        {isOpen && <S.LabelOverlay />}
      </S.Label>

      <S.Container $isOpen={isOpen} $size={size} $component={ContainerComponent} ref={ref}>
        <S.Overlay $isOpen={isOpen} onClick={onClose} />
        <S.Content $isOpen={isOpen} ref={contentRef}>
          {children}
        </S.Content>
      </S.Container>
    </S.DropdownContainer>
  )
}
