import type { FieldState } from '@pretto/zen/reveal/types/Field'

import { t } from '@pretto/app/src/lib/i18n'

// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /^[a-zA-Z0-9][a-z0-9\.!#\$%&'`\*\+\-\/=\^_\{\}\|~]*@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z0-9\-]+$/

export const validateEmail = (email: string) => REGEX_EMAIL.test(email.toLowerCase())

export const tooltipEmail = (email: string): { state: FieldState; message?: string } => {
  if (validateEmail(email) || email === '') {
    return {
      state: 'default',
    }
  }

  return {
    state: 'error',
    message: t('sentences.invalidEmail'),
  }
}
