import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import Image from '@pretto/bricks/website/shared/components/Image'

import { ButtonInline } from '@pretto/zen/reveal/atoms/buttons/buttonInline/ButtonInline'
import { PrettoLogotype as PrettoLogotypeComponent } from '@pretto/zen/reveal/logos/PrettoLogotype/PrettoLogotype'

import styled from 'styled-components'

export const PartnerContainer = styled.div`
  align-items: center;
  border: 1px solid #5076f6;
  border-radius: ${g(1)};
  background-color: #f2f2ff;
  display: flex;
  flex-direction: column;
  padding: ${g(3)};
`

export const Logo = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: ${g(3)};
  margin-bottom: ${g(2)};
`

export const PartnerLogo = styled(Image).attrs(({ path }) => ({
  path,
  options: {
    width: ng(10),
    height: ng(3),
  },
}))`
  margin-right: ${g(1)};
`

export const PrettoLogotype = styled(PrettoLogotypeComponent)`
  width: ${g(10)};
  height: ${g(3)};
  margin-left: ${g(1)};
`

export const PartnerDescription = styled.p`
  &&& {
    margin-bottom: ${g(1)};
    text-align: center;
  }
`

export const Cta = styled(ButtonInline)`
  margin-bottom: ${g(3)};
`

export const PartnerRTBContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media only screen and (min-width: ${breakpoints.mobileL}) {
    flex-direction: row;
  }
`

export const PartnerRTBItem = styled.div`
  display: flex;
  flex: 1;
  gap: ${g(2)};
  padding-top: ${g(1 / 2)};
`

export const PartnerRTBPicto = styled.div`
  align-items: center;
  background-color: #5076f6;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-shrink: 0;
  height: ${g(4)};
  justify-content: center;
  margin-top: ${g(-1 / 2)};
  padding: ${g(1)};
  width: ${g(4)};

  svg {
    display: block;
  }
`
