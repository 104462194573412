import { g, ng } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

const MARGIN_FACTOR = 0.25

const SIZES = {
  large: 4,
  medium: 3,
  small: 2,
}

const getColorForScore = ({ $score }) => {
  if ($score <= 1) return '#FF3722'
  if ($score <= 2) return '#FF8622'
  if ($score <= 3) return '#FFCE00'
  if ($score <= 4) return '#73CF11'

  return '#00B67A'
}

export const Star = styled.svg(
  ({ $size }) => css`
    height: ${g(SIZES[$size])};
    width: ${g(SIZES[$size])};

    && + && {
      margin-left: ${g(SIZES[$size] * MARGIN_FACTOR)};
    }
  `
)

export const Stars = styled.div`
  position: relative;
`

export const StarsActive = styled.div`
  bottom: 0;
  clip-path: inset(
    0
      ${({ $score, $size }) =>
        (5 - $score) * ng(SIZES[$size]) + ng(SIZES[$size] * MARGIN_FACTOR) * (5 - Math.ceil($score))}px
      0 0
  );
  color: ${getColorForScore};
  display: flex;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  top: 0;
`
export const StarsIdle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  clip-path: inset(
    0 0 0 ${({ $score, $size }) => $score * ng(SIZES[$size]) + ng(SIZES[$size] * MARGIN_FACTOR) * Math.floor($score)}px
  );
  display: flex;
  flex-wrap: wrap;
  min-width: 100px;
`
