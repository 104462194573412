import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-gap: ${g(1)};
`
export const Button = styled.div`
  width: ${g(20)};
  height: ${g(6)};
  border-radius: ${g(5)};
  overflow: hidden;
`

export const Title = styled.div`
  width: ${g(40)};
  height: ${g(4)};
  margin-bottom: ${g(3)};
`