import BookingSummaryPageComponent from '@pretto/bricks/app/booking/pages/BookingSummaryPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import temporal from '@pretto/bricks/core/utility/temporal'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { getOffTimezone } from '@pretto/app/src/Booking/lib/getOffTimezone'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { getBookingDateTime } from '@pretto/app/src/lib/getBookingDateTime'

import { makeReference, useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Redirect } from 'react-router'

import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { BOOKING, CANCEL_APPOINTMENT } from '../../../apollo'
import duration from '../../../lib/duration'
import { useTracking } from '../../../lib/tracking'
import { getIfIsR3Appointment } from '../../lib/getIfIsR3Appointment'

const BookingSummaryPage = ({ history }) => {
  const {
    advisor: { name: advisorName, role: advisorRole, visioUrl },
    mandateStatus,
    next_booking,
    status,
    user: { name: userName },
    isReferred,
    isVisioEnabled,
  } = useUser()
  const { notifyLegacy } = useNotifications()

  const { data, loading } = useQuery(BOOKING)

  const bookingDateTime = next_booking ? getBookingDateTime(next_booking) : null
  const nextBookingDuration = bookingDateTime?.duration ? bookingDateTime.duration : null
  const isR3 = getIfIsR3Appointment({
    bookingDuration: nextBookingDuration,
    projectStatus: status,
    mandateSigned: mandateStatus?.signed ?? false,
  })

  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT, {
    update: (
      cache,
      {
        data: {
          cancel_appointment: { success },
        },
      }
    ) => {
      if (!success) {
        return
      }

      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          next_booking() {
            return null
          },
        },
      })
    },
  })

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isMutating, setIsMutating] = useState(false)

  const trackAction = useTracking()

  const handleCancel = () => {
    trackAction('BOOKING_SUMMARY_BUTTON_CLICKED', { booking_summary_button_type: 'cancel' })
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => setIsDialogOpen(false)

  const handleReschedule = () =>
    trackAction('BOOKING_SUMMARY_BUTTON_CLICKED', { booking_summary_button_type: 'reschedule' })

  const handleConfirm = async () => {
    setIsMutating(true)

    await cancelAppointment()

    trackAction('BOOKING_APPOINTMENT_CANCELED')

    history.push('/')

    notifyLegacy('Rendez-vous annulé', 'Votre rendez-vous est annulé.')
  }

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  if (!data.next_booking) {
    return <Redirect to="/booking/appointment" />
  }

  const { end, start, isVisio } = data.next_booking

  const timezone = getOffTimezone()

  const date = `${temporal(start).format('dddd LL [à] HH[h]mm')}${timezone ? ' (heure locale)' : ''}`
  const offset = temporal(end).diff(start, 'minutes')

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <BookingSummaryPageComponent
        advisorName={advisorName}
        advisorRole={advisorRole}
        date={date}
        duration={duration(offset).toString()}
        isDialogOpen={isDialogOpen}
        isNow={bookingDateTime?.isNow || false}
        isVisioEnabled={isVisioEnabled}
        isVisio={isVisio}
        isMutating={isMutating}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onDialogClose={handleDialogClose}
        onReschedule={(isReferred || !isR3) && handleReschedule}
        timezone={timezone}
        userName={userName}
        visioUrl={visioUrl}
        visioLabel={visioUrl?.replace('https://', '')}
      />
    </>
  )
}

BookingSummaryPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default BookingSummaryPage
