import * as ZenTypo from '@pretto/bricks/core/typography'

export const isNumber = ZenTypo.isNumber

export const heading1 = ZenTypo.heading80

export const heading2 = ZenTypo.heading64

export const heading3 = ZenTypo.heading48

export const heading4 = ZenTypo.heading32

export const heading5 = ZenTypo.heading16

export const heading6 = ZenTypo.headline16

export const body1 = ZenTypo.numbers48

export const body2 = ZenTypo.numbers32

export const body3 = ZenTypo.textLarge24

export const body4 = ZenTypo.bodyBook16

export const body4Underline = ZenTypo.bodyBookUnderline16

export const body4Medium = ZenTypo.bodyMedium16

export const body4Bold = ZenTypo.bodyBold16

export const button = ZenTypo.button18

export const buttonUnderline = ZenTypo.buttonUnderline18

export const caption = ZenTypo.caption12

export const captionUnderline = ZenTypo.captionUnderline12

export const link = ZenTypo.link12

export const linkUnderline = ZenTypo.linkUnderline12
