import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import * as S from './styles'

const COLORS = {
  success: {
    backgroundColor: 'primary4',
    color: 'primary1',
  },
}

const SnackBar = ({ children, icon, in: inProp, status, ...props }) => {
  const { backgroundColor, color } = COLORS[status]
  const [isIn, setIsIn] = useState(inProp)

  useEffect(() => {
    setIsIn(inProp)
  }, [inProp])

  const handleClose = () => {
    setIsIn(false)
  }

  return (
    <S.Container $backgroundColor={backgroundColor} {...props}>
      <CollapsibleRow animateIn in={isIn}>
        <Content size="small">
          <S.Content>
            <S.Icon>
              <Icon name={icon} variant={color} />
            </S.Icon>
            <S.Text $color={color}>{children}</S.Text>
            <S.Close $fill={color} onClick={handleClose} />
          </S.Content>
        </Content>
      </CollapsibleRow>
    </S.Container>
  )
}

SnackBar.defaultProps = {
  icon: 'check-circle',
  status: 'success',
}

SnackBar.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string,
  in: PropTypes.bool.isRequired,
  status: PropTypes.string,
}

export default SnackBar
