import InputRadioComponent from '@pretto/bricks/components/form/InputRadio'
import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const InputRadioGroup = styled.form`
  display: flex;
  flex-direction: ${({ $format }) => ($format === 'inline' ? 'row' : 'column')};
`

export const InputRadio = styled(InputRadioComponent)`
  ${({ $format }) => {
    if ($format === 'inline') {
      return css`
        & + && {
          margin-left: ${g(2)};
        }
      `
    }

    return css`
      & + && {
        margin-top: ${g(2)};
      }
    `
  }}
`
