import SponsorshipShareLink from '@pretto/bricks/app/sponsorship/components/SponsorshipShareLink'
import SponsorshipSocialLinks from '@pretto/bricks/app/sponsorship/components/SponsorshipSocialLinks'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const SponsorshipShareOptions = ({ links, onClick, link }) => (
  <Card format="line" variant="neutral-1-20">
    <S.Content>
      <Content>
        <S.Title>
          <SubHeading>Partagez votre lien de parrainage</SubHeading>
        </S.Title>

        <Text size="small" variant="neutral-1-60">
          Par mail, SMS ou sur les réseaux sociaux...
        </Text>
      </Content>
    </S.Content>

    <Content>
      <S.Sharer>
        <S.Link>
          <SponsorshipShareLink onClick={onClick} link={link} />
        </S.Link>

        <SponsorshipSocialLinks links={links} size="small" />
      </S.Sharer>
    </Content>
  </Card>
)

SponsorshipShareOptions.propTypes = {
  link: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(SponsorshipShareOptions)
