import { g } from '@pretto/bricks/components/layout'
import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { FieldProps } from '@pretto/zen/reveal/types/Field'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold } from '@pretto/picto'

import { ChangeEvent, useEffect, useRef, useState } from 'react'

import * as S from './SentenceFieldSimple.styles'

export interface SentenceFieldSimpleProps extends FieldProps {
  suffix?: React.ReactNode
}

const PATTERN_WIDTH = 4

const PICTO = {
  error: ExclamationMarkCircleBold,
  warning: ExclamationMarkTriangleBold,
}

export const SentenceFieldSimple = ({
  inputProps,
  onChange,
  message,
  suffix,
  state = 'default',
  value,
  ...props
}: SentenceFieldSimpleProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>) => {
  const hiddenRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [inputWidth, setInputWidth] = useState(0)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }

  useEffect(() => {
    const width = roundNumber(hiddenRef.current?.offsetWidth, PATTERN_WIDTH, Math.ceil)

    setInputWidth(width)
  }, [value, inputProps?.placeholder])

  const handleClickSuffix = () => {
    inputRef.current?.focus()
  }

  return (
    <>
      <S.Container {...props}>
        <S.Component $patternWidth={PATTERN_WIDTH} style={{ width: inputWidth }} $state={state}>
          <S.Input {...inputProps} ref={inputRef} value={value} onChange={handleChange} $state={state} />
        </S.Component>

        {suffix && <S.Suffix onClick={handleClickSuffix}> {suffix}</S.Suffix>}

        {state !== 'default' &&
          (message ? (
            <S.Tooltip content={message} maxWidth={g(35)} position="right">
              <S.Picto as={PICTO[state]} $state={state} />
            </S.Tooltip>
          ) : (
            <S.Picto as={PICTO[state]} $state={state} />
          ))}
      </S.Container>

      <S.HiddenComponent ref={hiddenRef} $state={state} $patternWidth={PATTERN_WIDTH}>
        <S.HiddenInput $state={state}>{(value && value.toString()) || inputProps?.placeholder}</S.HiddenInput>
      </S.HiddenComponent>
    </>
  )
}
