import { funcToItem } from '@pretto/bricks/core/utility/funcToItem'

import { getValue } from '@pretto/app/src/lib/object'
import { PURCHASE, RENEGOTIATION } from '@pretto/app/src/overview/config/graph'
import { DETAILS, SHORT_DETAILS } from '@pretto/app/src/overview/config/labels'
import { VARIANTS } from '@pretto/app/src/overview/config/variants'
import type { Graph } from '@pretto/app/src/overview/types/graph'
import type { Row } from '@pretto/app/src/overview/types/row'

export const formatGraph = (
  structure: typeof PURCHASE | typeof RENEGOTIATION,
  projectRows: Row[],
  hasContribution: boolean
): Graph[][] =>
  structure.map(rules =>
    rules.reduce<Graph[]>((previous, rule) => {
      switch (rule.type) {
        case 'sum': {
          if (projectRows.every(({ path }) => rule.keys.every(key => key !== path))) {
            return previous
          }

          const combinedTitle = rule.keys
            .filter(key => projectRows.some(({ path }) => path === key))
            .map(key => SHORT_DETAILS[key])
            .join(' + ')

          const value = rule.keys.reduce(
            (previous, key) => previous + (projectRows.find(({ path }) => path === key)?.rawValue ?? 0),
            0
          )

          const expandedTitle = `${rule.title} : ${combinedTitle}`
          const paths = [...rule.keys]
          const title = rule.title
          const variant = funcToItem(VARIANTS[rule.keys[0]], hasContribution)

          return [...previous, { expandedTitle, paths, title, value, variant }]
        }

        default:
        case 'single': {
          const row = projectRows.find(({ path }) => path === rule.key)

          if (!row) {
            return previous
          }

          const expandedTitle = DETAILS[rule.key]
          const paths = [rule.key]
          const title = getValue(rule, 'title') ?? DETAILS[rule.key]
          const value = row.rawValue ?? 0
          const variant = funcToItem(VARIANTS[rule.key], hasContribution)

          return [...previous, { expandedTitle, paths, title, value, variant }]
        }
      }
    }, [])
  )
