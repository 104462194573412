import sortBy from 'lodash/sortBy'

import getFoundableResults from './getFoundableResults'

/*
Return all results for which the loanDuration is equal to a given duration
The new array is sorted by payment amount before being returned
*/
const filterResultsByDuration = (results, duration) =>
  sortBy(
    getFoundableResults(results).filter(result => result.financing.loanDuration === duration),
    result => result.financing.creditCosts
  )

export default filterResultsByDuration
