import ApplicationFormSection from '@pretto/bricks/app/application/components/ApplicationFormSection'
import {
  ContentContainer,
  LeftContainer,
} from '@pretto/bricks/app/application/pages/ApplicationIntroductionPage/ApplicationIntroduction.styles'
import Button from '@pretto/bricks/components/buttons/Button'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Content from '@pretto/bricks/components/layout/Content'
import { Grid } from '@pretto/bricks/components/layout/Grid'

import PropTypes from 'prop-types'

import * as S from './styles'

const Form = ({
  documentsHref,
  documentsStatusBySection,
  error,
  hasNextPage,
  isButtonVisible = true,
  isLoading = false,
  isOnboarding = false,
  onSubmit,
  sections,
  title,
}) => (
  <S.Form>
    {sections.map((section, index) => (
      <S.Section key={index}>
        <ApplicationFormSection pageTitle={title} {...section} />

        {index < sections.length - 1 && (
          <S.Divider>
            <Divider />
          </S.Divider>
        )}
      </S.Section>
    ))}

    {error && (
      <S.Alert>
        <Card format="small" variant="error">
          <Content size="small">{error}</Content>
        </Card>
      </S.Alert>
    )}

    {(isOnboarding || isButtonVisible) && (
      <S.Button>
        <Button isArrowShown={hasNextPage} isLoading={isLoading} onClick={onSubmit}>
          {hasNextPage ? 'Continuer' : 'Enregistrer'}
        </Button>
      </S.Button>
    )}

    {!['complete', 'incomplete'].includes(documentsStatusBySection) && !isOnboarding && documentsHref && (
      <S.FileMissing href={documentsHref} />
    )}
  </S.Form>
)

Form.propTypes = {
  /** Document location */
  documentsHref: PropTypes.string.isRequired,
  /** Status of the document category */
  documentsStatusBySection: PropTypes.oneOf(['idle', 'invalid', 'complete', 'incomplete']),
  /** Error message. Appears on top of the page. */
  error: PropTypes.node,
  /** Whether or not there is a following page. */
  hasNextPage: PropTypes.bool,
  /** Update in progress */
  isButtonVisible: PropTypes.bool.isRequired,
  /** Update in progress */
  isLoading: PropTypes.bool,
  /** Allow to know if the view is use for the onboarding flow */
  isOnboarding: PropTypes.bool,
  /** Event handler to be triggered when submit button is pressed. */
  onSubmit: PropTypes.func.isRequired,
  /** Array of ApplicationFormSection props <code>[ApplicationFormSection](/#!/ApplicationFormSection)</code> component. */
  sections: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

const ApplicationForm = ({ isNewIntroUI, onGifClick, ...formProps }) => {
  return isNewIntroUI ? (
    <S.Main>
      <ContentContainer>
        <LeftContainer onClick={onGifClick}></LeftContainer>
        <S.Right>
          <S.ModalTitle>{formProps.title}</S.ModalTitle>
          <S.ModalForm>
            <Form {...formProps} />
          </S.ModalForm>
        </S.Right>
      </ContentContainer>
    </S.Main>
  ) : (
    <Grid>
      <S.Wrapper>
        <S.GridTitle>{formProps.title}</S.GridTitle>
        <S.Form>
          <Form {...formProps} />
        </S.Form>
      </S.Wrapper>
    </Grid>
  )
}

ApplicationForm.propTypes = {
  ...Form.propTypes,
  isNewIntroUI: PropTypes.bool,
  onGifClick: PropTypes.func,
}
export default ApplicationForm
