import { getUtmFlagsLastClicked } from '@pretto/app/src/lib/helpers'
import { BING_ADS_SLUG, GOOGLE_ADS_SLUG } from '@pretto/app/src/lib/tracking'

import { formatAbTests } from './formatAbTests'
import { formatGAUserID } from './formatGAUserID'
import { formatTrackingId } from './formatTrackingId'

export const getUtms = async () =>
  [
    ...Object.entries(getUtmFlagsLastClicked()).map(([key, val]) => `${key}=${encodeURIComponent(val)}`),
    formatTrackingId(GOOGLE_ADS_SLUG),
    formatTrackingId(BING_ADS_SLUG),
    await formatGAUserID(),
    ...formatAbTests(),
  ]
    .filter(Boolean)
    .join('&')
