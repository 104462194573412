import { addFilter, applyFilter, applyFilters, applyOmitFilter } from '@pretto/app-core/lib/filters'

export const avoidedBanks = {
  id: 'avoid_banks',
  decoder: applyFilters([
    applyFilter(
      'isAvoidedBanks',
      (value, values, { project: { solicited_banks } }) =>
        solicited_banks.filter(({ blocked }) => blocked).length > 0 ? true : null,
      false
    ),
    applyFilter(
      'avoidedBanks',
      (value, values, { project: { solicited_banks } }) =>
        solicited_banks.filter(({ blocked }) => blocked).map(({ bank_slug }) => bank_slug),
      false
    ),
  ]),
  encoder: applyFilters([
    addFilter('project.solicited_banks', (values, { project: { solicited_banks } }) => {
      if (!values.isAvoidedBanks) {
        return solicited_banks.map(bank => ({ ...bank, blocked: null }))
      }

      return values.avoidedBanks.reduce(
        (previous, slug) => {
          if (previous.some(({ bank_slug }) => bank_slug === slug)) {
            return previous
          }
          return [...previous, { bank_slug: slug, blocked: true }]
        },
        solicited_banks.map(bank => {
          if (!values.avoidedBanks.includes(bank.bank_slug)) {
            return { ...bank, blocked: null }
          }
          return { ...bank, blocked: true }
        })
      )
    }),
    applyOmitFilter('isAvoidedBanks', 'avoidedBanks'),
  ]),
  fields: [
    {
      type: 'boolean',
      name: 'isAvoidedBanks',
      label: 'Souhaitez-vous éviter des banques ?',
      jumpToNext({ value }) {
        return value === false
      },
    },
    {
      type: 'message',
      name: 'disclaimer',
      content:
        'Si vous ne souhaitez pas prendre votre crédit auprès d’une certaine banque, je respecterai cette préférence.',
      condition({ userContext }) {
        return !userContext.isLowPotential
      },
    },
    {
      type: 'banks',
      name: 'avoidedBanks',
      label: 'Lesquelles ?',
      condition({ values }) {
        return values.isAvoidedBanks
      },
    },
  ],
  jumps: 'heard_about',
  track({ values }) {
    return [
      'ONBOARDING_AVOIDED_BANKS_CLICKED',
      { onboarding_avoided_banks_value: !values.isAvoidedBanks ? false : values.avoidedBanks },
    ]
  },
}
