import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'
import FlagRow from '@pretto/bricks/shared/components/FlagRow'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import * as S from './styles'

const ScoreDetails = ({ description, malus, onSubscribe, showButton }) => (
  <div>
    <Content>
      <S.Section>
        <Text>{nl2br(description)}</Text>
      </S.Section>

      {malus.length > 0 && (
        <S.Section>
          {malus.map(flagProps => (
            <S.Flag key={flagProps.code}>
              <FlagRow {...flagProps} neutral />
            </S.Flag>
          ))}
        </S.Section>
      )}

      {showButton && (
        <S.Button>
          <ButtonLegacy onClick={onSubscribe} variant="accent-1">
            S'inscrire
          </ButtonLegacy>
        </S.Button>
      )}
    </Content>
  </div>
)

ScoreDetails.defaultProps = {
  showButton: true,
}

ScoreDetails.propTypes = {
  /** Description of the score. */
  description: PropTypes.string.isRequired,
  /** List of malus. */
  malus: PropTypes.array,
  /** onClick event. */
  onSubscribe: PropTypes.func.isRequired,
  /** Whether the subsribe button should be shown. */
  showButton: PropTypes.bool,
}

export default ScoreDetails
