import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getDocumentsForDocumentsParameters } from '@pretto/app-core/application/lib/getDocumentsForDocumentsParameters'
import { getDocumentsParametersForStep } from '@pretto/app-core/application/lib/getDocumentsParametersForStep'

export const getDocumentsStatusForStep = (step, ...args) => {
  const documentsParameters = getDocumentsParametersForStep(step, ...args)
  const documents = getDocumentsForDocumentsParameters(documentsParameters, ...args)

  if (documents.length === 0) {
    return 'incomplete'
  }

  const hasInvalidDocuments = documents.some(({ document_status }) => convertStatusSlug(document_status) === 'invalid')
  const isDocumentsComplete = documents.every(({ document_status }) => convertStatusSlug(document_status) === 'sent')

  if (hasInvalidDocuments) {
    return 'invalid'
  }

  if (isDocumentsComplete) {
    return 'complete'
  }

  return 'idle'
}
