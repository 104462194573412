import { g } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { createElement, memo } from 'react'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'

const createStates = (transitionIn, transitionOut) => ({
  entering: transitionIn,
  entered: transitionIn,
  exiting: transitionOut,
  exited: transitionOut,
})

const drawerLeftIn = { transform: 'translateX(0)' }
const drawerLeftOut = { transform: 'translateX(-100%)' }
const drawerLeftStates = createStates(drawerLeftIn, drawerLeftOut)

const drawerLeft = (state, duration) => css`
  height: 100%;
  transform: ${drawerLeftStates[state].transform};
  transition: ${`transform ${duration}ms ease-in-out`};
  will-change: transform;
`

const drawerRightIn = { transform: 'translateX(0)' }
const drawerRightOut = { transform: 'translateX(100%)' }
const drawerRightStates = createStates(drawerRightIn, drawerRightOut)

const drawerRight = (state, duration) => css`
  height: 100%;
  transform: ${drawerRightStates[state].transform};
  transition: ${`transform ${duration}ms ease-in-out`};
  will-change: transform;
`

const drawerOverlayIn = { opacity: 1 }
const drawerOverlayOut = { opacity: 0 }
const drawerOverlayStates = createStates(drawerOverlayIn, drawerOverlayOut)

const drawerOverlay = (state, duration) => css`
  opacity: ${drawerOverlayStates[state].opacity};
  transition: ${`opacity ${duration}ms ease-in-out`};
  will-change: opacity;
`

const dropdownIn = { opacity: 1, transform: 'translateY(0)' }
const dropdownOut = { opacity: 0, transform: `translateY(${g(-2)})` }
const dropdownStates = createStates(dropdownIn, dropdownOut)

const dropdown = (state, duration) => css`
  opacity: ${dropdownStates[state].opacity};
  transform: ${dropdownStates[state].transform};
  transition: ${`all ${duration}ms ease-in-out`};
`

const notificationIn = { opacity: 1, maxHeight: g(15), marginBottom: g(2) }
const notificationOut = { opacity: 0, maxHeight: 0, marginBottom: 0 }
const notificationStates = createStates(notificationIn, notificationOut)

const notification = (state, duration) => css`
  transition: ${`all ${duration}ms ease-in-out`};
  opacity: ${notificationStates[state].opacity};
  max-height: ${notificationStates[state].maxHeight};
  margin-bottom: ${notificationStates[state].marginBottom};
`

const opacityIn = { opacity: 1 }
const opacityOut = { opacity: 0 }
const opacityStates = createStates(opacityIn, opacityOut)

const opacity = (state, duration) => css`
  transition: ${`opacity ${duration}ms ease-in-out`};
  opacity: ${opacityStates[state].opacity};
`

const TRANSITIONS = { drawerLeft, drawerOverlay, drawerRight, dropdown, notification, opacity }

const AnimatedTransition = styled(({ children, element, ...props }) => createElement(element, props, children))`
  ${({ transitionKind, state, duration }) => TRANSITIONS[transitionKind](state, duration)}
`

const TransitionController = ({ children, duration, element, in: inProps, kind, onEnter, unmountOnExit, ...props }) => {
  const handleEnter = (node, appearing) => {
    onEnter(node, appearing)
    node && node.offsetTop // eslint-disable-line no-unused-expressions
  }

  return (
    <Transition {...props} in={inProps} onEnter={handleEnter} timeout={duration} unmountOnExit={unmountOnExit}>
      {state => (
        <AnimatedTransition transitionKind={kind} state={state} duration={duration} element={element}>
          {children}
        </AnimatedTransition>
      )}
    </Transition>
  )
}

TransitionController.defaultProps = {
  element: 'div',
  onEnter: () => {},
  unmountOnExit: true,
}

TransitionController.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.object]),
  in: PropTypes.bool.isRequired,
  kind: PropTypes.oneOf(Object.keys(TRANSITIONS)).isRequired,
  onEnter: PropTypes.func,
  unmountOnExit: PropTypes.bool,
}

export default memo(TransitionController)
