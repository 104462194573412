import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/v2/lib/placeholders'
import { Months, OccupationOthersStatus, OccupationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

interface OthersSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const OthersSentence: React.FC<OthersSentenceProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors, setContext } = context
  const mainMortgagor = mortgagors?.[0] ?? {}
  const [debouncedStartYear] = useDebounce(mainMortgagor.startYear, 1000)
  const clonedMortgagors = structuredClone(mortgagors)

  if (!clonedMortgagors || !clonedMortgagors.length) {
    return null
  }

  useEffect(() => {
    if (!Object.values(OccupationOthersStatus).includes(mainMortgagor.status as OccupationOthersStatus)) {
      clonedMortgagors[0].status = undefined
    }
    clonedMortgagors[0].trialPeriod = undefined
    clonedMortgagors[0].executiveRole = undefined
    setContext({ mortgagors: clonedMortgagors })
  }, [])

  const handleYearChange = (value: string) => {
    clonedMortgagors[0].startYear = value
    setContext({ mortgagors: clonedMortgagors })
  }

  return (
    <>
      {mainMortgagor.occupationType !== OccupationType.Medical && (
        <>
          {t('sentences.situation.others.sentence')}
          <Prompt
            onClick={() => {
              onSetActive(1)
            }}
            isFilled={!!mainMortgagor.status}
          >
            {t(
              `sentences.situation.others.values.${
                (mainMortgagor.status as OccupationOthersStatus) || OccupationOthersStatus.SelfEmployed
              }`
            )}
          </Prompt>
        </>
      )}
      {((activeField > 1 && mainMortgagor.status !== OccupationOthersStatus.Retired) ||
        (activeField === 1 && mortgagors?.[0].occupationType === OccupationType.Medical)) && (
        <>
          {t('sentences.situation.since')}
          <Prompt onClick={() => onSetActive(2)} isFilled={!!mainMortgagor.startMonth}>
            {t(`sentences.months.${(mainMortgagor.startMonth as Months) || '0'}`)}
          </Prompt>
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'occupationSectorOthersSince' },
            }}
            inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(context), type: 'date' }}
            message={t('sentences.situation.error')}
            onChange={handleYearChange}
            state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
            value={mainMortgagor.startYear || ''}
          />
        </>
      )}
    </>
  )
}
