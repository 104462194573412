import * as helpers from '@pretto/bricks/components/helpers'
import SvgIcon from '@pretto/bricks/components/iconography/SvgIcon'
import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled, { css } from 'styled-components'

const getColorsCss = (theme, $color) => {
  return $color === 'white'
    ? css`
        border: 1px solid ${theme.colors.neutral3};
        background-color: ${theme.colors.white};
      `
    : css`
        border: none;
        background-color: #5186ec;
        color: ${theme.colors.white};
      `
}

const getIconCss = (theme, $color) =>
  $color === 'blue' &&
  css`
    background-color: ${theme.colors.white};
    width: ${g(5)};
    height: ${g(5)};
    padding: ${g(1)};
  `

export const Button = styled(BaseTypo).attrs({ forwardedAs: 'button', typo: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${g(8)};
  width: ${g(49)};
  padding-left: ${g(1.5)};
  padding-right: ${g(3)};
  border-radius: ${g(1)};
  user-select: none;
  ${({ theme, $color }) => getColorsCss(theme, $color)};

  &:focus,
  &:hover {
    ${helpers.boxShadow('hover')};
  }
`
export const Icon = styled(SvgIcon).attrs({ name: 'google' })`
  display: block;
  margin-right: ${g(2)};
  flex-shrink: 0;
  ${({ theme, $color }) => getIconCss(theme, $color)};
`
