import { ExpatriatePage } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Expatriate/Containers/ExpatriatePage'
import { SectorPage } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/Containers/SectorPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ComortgagorOccupationController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/occupation-comortgagor/sector')}
        component={SectorPage}
      />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase)/occupation-comortgagor/expatriate')}
        component={ExpatriatePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
