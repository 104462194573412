import InputCheckboxComponent from '@pretto/bricks/components/form/InputCheckbox'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'

import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${g(3)} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(3)};
    padding-bottom: ${g(1)};
  }
`

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${g(1)};
  row-gap: ${g(1)};
  margin-bottom: ${g(3)};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding: 0 ${g(15)} 0 ${g(10)};
    margin-bottom: ${g(1)};
  }
`

export const TwoColumns = styled.div`
  ${column(2)};
`

export const NameContainer = styled(TwoColumns)`
  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(1)};
  }
`

export const ContactSentence = styled(TwoColumns)`
  margin-top: ${g(1)};
`

export const Divider = styled(DividerComponent)`
  margin: ${g(2)} 0;
`

export const CheckBoxContainer = styled.div`
  display: flex;
`

export const AgreementCheckbox = styled(InputCheckboxComponent)`
  margin-right: ${g(2)};
`

export const Label = styled.label`
  ${typo.caption12};
  cursor: pointer;
`

export const LegalTerms = styled(TwoColumns)`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
`
