import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Placeholder from '@pretto/bricks/components/loading/Placeholder'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import range from 'lodash/range'
import PropTypes from 'prop-types'

import * as S from './styles'

const MINIMUM_NUMBER_OF_HIGHLIGHTS = 3

const DashboardHighlights = ({ highlights, highlightVariant, isLoading, ...props }) => (
  <S.Highlights {...props}>
    {(highlights ?? range(MINIMUM_NUMBER_OF_HIGHLIGHTS)).map((highlight, index) => (
      <S.Highlight key={index}>
        <Card format={['small-radius', 'line']} variant="neutral-1-30">
          <Content size="x-small">
            {isLoading ? (
              <>
                <S.PlaceholderTitle>
                  <Placeholder color={highlightVariant} />
                </S.PlaceholderTitle>

                <S.PlaceholderContent>
                  <Placeholder />
                </S.PlaceholderContent>
              </>
            ) : (
              <S.HighlightContent>
                <Text size="small" variant={highlightVariant}>
                  {highlight.title}
                </Text>

                <SubHeading>{highlight.value}</SubHeading>
              </S.HighlightContent>
            )}
          </Content>
        </Card>
      </S.Highlight>
    ))}
  </S.Highlights>
)

DashboardHighlights.defaultProps = {
  highlightVariant: 'neutral-1-60',
  isLoading: false,
}

DashboardHighlights.propTypes = {
  /** Array of highlights (title + value). */
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /** Color to use for every highlight's title. */
  highlightVariant: PropTypes.string,
  /** Whether or not the data is loading and the highlights placeholder should be shown */
  isLoading: PropTypes.bool,
}

export default DashboardHighlights
