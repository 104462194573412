import { g } from '@pretto/bricks/components/layout'

import {
  NegotiationStepBankTimeline,
  NegotiationStepBankTimelineProps,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepBankTimeline/NegotiationStepBankTimeline'
import {
  NegotiationStepSimple,
  NegotiationStepSimpleProps,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepSimple/NegotiationStepSimple'

import styled from 'styled-components'

export interface NegotiationStepBankContainerProps {
  negotiationStepSimpleProps: NegotiationStepSimpleProps
  banks: NegotiationStepBankTimelineProps[]
}

export const NegotiationStepBankContainer: React.FC<NegotiationStepBankContainerProps> = ({
  negotiationStepSimpleProps,
  banks,
  ...props
}) => {
  return (
    <div {...props}>
      <NegotiationStepSimple {...negotiationStepSimpleProps} />

      <Banks>
        {banks.map((timeline, i) => (
          <Timeline key={i}>
            <NegotiationStepBankTimeline {...timeline} />
          </Timeline>
        ))}
      </Banks>
    </div>
  )
}

const Banks = styled.ul`
  margin-top: ${g(1)};
`

const Timeline = styled.li`
  & + & {
    margin-top: ${g(1)};
  }
`
