import Avatar from '@pretto/bricks/components/images/Avatar'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { getExpertVacation } from '@pretto/app/src/lib/getExpertVacation'
import { getIsAdvisorUnavailable } from '@pretto/app/src/lib/getIsAdvisorUnavailable'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { ExclamationMarkCircle, TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'

import List from '../../SharedContainers/List'

import * as S from './styles'

const getMessageByMaturity = (maturity, onClick) => {
  const maturities = {
    default: (
      <S.BannerChildrenContainer>
        Pendant l’absence de votre expert, vous pouvez nous contacter à l’adresse{' '}
        <a href="mailto:hello@pretto.fr" onClick={onClick}>
          hello@pretto.fr.
        </a>
      </S.BannerChildrenContainer>
    ),
  }

  return maturities[maturity] || maturities.default
}

const AdvisorDialog = ({ advisor, avatarSrc, isOpen, lists, onClose }) => {
  const { maturity = 'default' } = useUser()
  const { holidays_end_date, holidays_start_date, name } = advisor
  const trackAction = useTracking()
  const isVacationExpert = getIsAdvisorUnavailable({ startDate: holidays_start_date, endDate: holidays_end_date })

  const { formattedStartDate, formattedEndDate } = getExpertVacation({
    startDate: holidays_start_date,
    endDate: holidays_end_date,
  })

  const isSingleDay = formattedStartDate && formattedEndDate && formattedStartDate === formattedEndDate

  const onClick = () => trackAction('ADVISOR_PROFILE_ALERT_EMAIL_CLICKED', { maturity })

  return (
    <S.Dialog>
      <Dialog isOpen={isOpen} onRequestClose={onClose}>
        <DialogTemplate
          navbarProps={{
            iconLeft: TimesBold,
            iconLeftAction: onClose,
            title: advisor.role === 'expert' ? 'Votre expert' : 'Votre conseiller',
          }}
        >
          <>
            <S.Advisor>
              <S.AdvisorAvatar>
                <Mobile>
                  <Avatar src={avatarSrc} isWithOverlay={isVacationExpert} isWithWarningIcon={isVacationExpert} />
                </Mobile>

                <Desktop>
                  <Avatar
                    size="large"
                    src={avatarSrc}
                    isWithOverlay={isVacationExpert}
                    isWithWarningIcon={isVacationExpert}
                  />
                </Desktop>
              </S.AdvisorAvatar>

              <S.ExpertContainer>
                {!isVacationExpert && (
                  <S.Title>{advisor.role === 'expert' ? 'Votre expert Pretto' : 'Votre conseiller Pretto'}</S.Title>
                )}
                {isVacationExpert && (
                  <S.HolidaysDate>
                    {isSingleDay ? (
                      <>Absent le {formattedStartDate}</>
                    ) : (
                      <>
                        Absent du {formattedStartDate} au {formattedEndDate}
                      </>
                    )}
                  </S.HolidaysDate>
                )}
                <S.Name>{name || 'Pretto'}</S.Name>
              </S.ExpertContainer>
            </S.Advisor>

            <S.BannerContainer>
              {isVacationExpert && (
                <Banner type="warning" picto={ExclamationMarkCircle}>
                  {getMessageByMaturity(maturity, onClick)}
                </Banner>
              )}
            </S.BannerContainer>

            {lists.length > 0 && (
              <S.ListContainers $backgroundColor={lists[lists.length - 1].variant}>
                {lists.map((listProps, index) => (
                  <List {...listProps} key={index} onClose={onClose} />
                ))}
              </S.ListContainers>
            )}
          </>
        </DialogTemplate>
      </Dialog>
    </S.Dialog>
  )
}

AdvisorDialog.propTypes = {
  advisor: PropTypes.object.isRequired,
  avatarSrc: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lists: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AdvisorDialog
