import { BlockInfoProps } from '@pretto/bricks/app/simulator/components/ResultCardMain/children/BlockInfo'

import { getBlockInfoByTypology } from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoByTypology'
import { Typology } from '@pretto/app/src/User/types/Typology'
import { Maturity } from '@pretto/app/src/config/maturity'
import { t } from '@pretto/app/src/lib/i18n'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

export interface BookingInfos {
  name?: string
  start?: string
  isLoading: boolean
}

export type getBlockInfoParams = {
  maturity: Maturity
  typology: Typology
  bookingInfos: BookingInfos
  historyPush: (href: string) => void
  onOpenMutualizedAgenda: () => void
}

export interface LoadingProps {
  isLoading: boolean
}

export const Date = styled.span`
  font-weight: 700;
`

export const getBookedBlockProps = (
  { start, name }: Pick<BookingInfos, 'name' | 'start'>,
  historyPush: (href: string) => void
): BlockInfoProps => ({
  imgPath: 'v1637685716/zen/static-assets/illustrations/660x660/frame-people-call.svg',
  title: t('simulation.appointmentConfirmed.title'),
  description: (
    <Trans
      i18nKey="simulation.appointmentConfirmed.text"
      values={{ name, start }}
      components={{
        Date: <Date />,
      }}
    />
  ),
  cta: {
    text: t('simulation.appointmentConfirmed.cta'),
    onClick: () => historyPush('/'),
  },
})

export const getBlockInfoByMaturity = ({
  maturity,
  typology,
  bookingInfos,
  historyPush,
  onOpenMutualizedAgenda,
}: getBlockInfoParams): BlockInfoProps | LoadingProps => {
  if (bookingInfos.start || bookingInfos.name) {
    return getBookedBlockProps(bookingInfos, historyPush)
  }
  switch (maturity) {
    case Maturity.search_started:
      return {
        imgPath: 'v1637683586/zen/static-assets/illustrations/660x660/womanHoldingAsign.svg',
        title: 'Faites de la concurrence un lointain souvenir',
        description:
          'Faites la différence avec l’attestation de financement Pretto au moment de faire votre offre d’achat.',
        checkList: ['Gratuite', 'Sans engagement', 'Rapide (48h)'],
        cta: {
          text: 'Obtenir mon attestation',
          onClick: () => historyPush('/simulation/subscribe'),
        },
      }
    case Maturity.offer_made:
    case Maturity.offer_accepted:
    case Maturity.sale_agreement_signed:
      return getBlockInfoByTypology({ typology, bookingInfos, historyPush, onOpenMutualizedAgenda })
    case Maturity.under_study:
    default:
      return {
        imgPath: 'v1637683586/zen/static-assets/illustrations/660x660/womanHoldingAsign.svg',
        title: 'Dans les starting blocks de votre projet',
        description:
          'L’attestation de finançement Pretto est votre meilleure alliée pour prouver votre solvabilité et rassurer les vendeurs.',
        checkList: ['Gratuite', 'Sans engagement', 'Rapide (48h)'],
        cta: {
          text: 'Obtenir mon attestation',
          onClick: () => historyPush('/simulation/subscribe'),
        },
      }
  }
}
