import TipBubbleComponent from '@pretto/bricks/app/sentences/components/TipBubble'
import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import ButtonInlineComponent from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Grid = styled.div`
  ${grid()};
`

export const ServerErrorPage = styled.div`
  ${column([2, 4])}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${g(7, 2)} 0 ${g(7, 2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])}
    margin: ${g(13)} 0 ${g(13)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])}
    margin: ${g(16)} 0 ${g(16)} 0;
  }
`

export const Illustraton = styled(Image).attrs({
  alt: 'Oh, mince !',
  options: {
    crop: 'scale',
    height: ng(35),
    width: ng(35),
  },
  path: 'v1637683132/zen/static-assets/illustrations/660x660/twoPeopleInFrontOfMachine.svg',
})`
  height: ${g(22)};
  width: ${g(22)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(30)};
    width: ${g(30)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: ${g(35)};
    width: ${g(35)};
  }
`

export const Title = styled.div`
  ${typo.heading48};
  margin-top: ${g(6)};
`

export const Text = styled.div`
  ${typo.bodyMedium16};
  margin-top: ${g(2)};
  text-align: center;
`

export const Button = styled(ButtonComponent).attrs({ isSecondary: true })`
  margin-top: ${g(3)};
`

export const ButtonInline = styled(ButtonInlineComponent).attrs({ isSecondary: true })`
  margin-top: ${g(4)};
`

export const TipBubble = styled(TipBubbleComponent)`
  margin-top: ${g(8)};
`

export const Copy = styled.button`
  ${typo.bodyBook16};
  text-decoration: underline;
  text-underline-offset: ${g(1, -6)};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary1};
`
