import { OthersChoices } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/Containers/SectorPage/Others/choices'
import { OthersSentence } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/Containers/SectorPage/Others/sentence'
import { checkSeniorityYearIsFilled } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/checkSeniorityYearIsFilled'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import {
  isCDD as isMortgagorCSS,
  isEarlyBusinessOwner as isMortgagorEarlyBusinessOwner,
  isOnTrial as isMortgagorOnTrial,
} from '@pretto/app/src/Sentences/v2/lib/contract'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { isCoMortgagorOccupationFilled } from '@pretto/app/src/Sentences/v2/lib/validators/context'
import { OccupationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import { PrivateChoices } from './Private/choices'
import { PrivateSentence } from './Private/sentence'
import { PublicChoices } from './Public/choices'
import { PublicSentence } from './Public/sentence'

export const SectorPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}, {}]
  const comortgagor = mortgagors?.[1] ?? {}

  const isOnTrial = isMortgagorOnTrial(comortgagor)
  const isCDD = isMortgagorCSS(comortgagor)
  const isEarlyBusinessOwner = isMortgagorEarlyBusinessOwner(comortgagor)

  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && isCoMortgagorOccupationFilled(context)))

  const mapSectorValues: MapToChoices<OccupationType[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: comortgagor.occupationType === value,
      onClick: () => {
        clonedMortgagors[1].occupationType = value
        setContext({ mortgagors: clonedMortgagors })
        setActiveField(1)
      },
      label: t(`sentences.situation.sector.values.${value}`),
    }))
  }

  const handleActiveField = (field: number) => {
    setActiveField(field)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sector-comortgagor' } }}>
      <Title>{t('sentences.situation.comortgagor.title')}</Title>
      <div>
        {t('sentences.situation.comortgagor.sector.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!comortgagor?.occupationType}
        >
          {t(`sentences.situation.sector.values.${comortgagor.occupationType || OccupationType.Private}`)}
        </Prompt>
        {activeField > 0 && comortgagor.occupationType === OccupationType.Private && (
          <PrivateSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 && comortgagor.occupationType === OccupationType.Public && (
          <PublicSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 &&
          (comortgagor?.occupationType === OccupationType.Others ||
            comortgagor?.occupationType === OccupationType.Medical) && (
            <OthersSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSectorValues(Object.values(OccupationType))}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationType' },
          }}
        />
      )}
      {activeField > 0 && comortgagor.occupationType === OccupationType.Private && (
        <PrivateChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 && comortgagor.occupationType === OccupationType.Public && (
        <PublicChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 &&
        (comortgagor?.occupationType === OccupationType.Others ||
          comortgagor?.occupationType === OccupationType.Medical) && (
          <OthersChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {isOnTrial && <Message>{t('sentences.tips.occupation.trialPeriod.comortgagor')}</Message>}
      {isCDD && <Message>{t('sentences.tips.occupation.shortTerm.comortgagor')}</Message>}
      {isEarlyBusinessOwner && <Message>{t('sentences.tips.occupation.earlyBusinessOwner.comortgagor')}</Message>}
      {isCoMortgagorOccupationFilled(context) && checkSeniorityYearIsFilled(comortgagor) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
