import { temporal } from '@pretto/bricks/core/utility/temporal'

export const toShortISO = (date: Date): string => date.toISOString().substring(0, 10)

export const fromFormattedToShortISO = (date: string): string => {
  const [day, month, year] = date.replace(/\s/g, '').split('/')
  return `${year}-${month}-${day}`
}

export const inputDateFormat = 'DD / MM / YYYY'
export const placeholeDateFormat = 'JJ / MM / AAAA'

export const isLessThan = (date1: Date, date2: Date): boolean => date1.getTime() < date2.getTime()
export const isMoreThan = (date1: Date, date2: Date): boolean => date1.getTime() > date2.getTime()

export const isDateValid = (date: Date): boolean => temporal(date).isValid()
