import Responsive from '@pretto/bricks/components/utility/Responsive'

import * as S from './OptionButtons.styles'

interface ValueProps {
  caption: string
  value: string
}

export interface OptionButtonsProps {
  className?: string
  inputLabel: string
  inputValue: string
  isDoubleline?: boolean
  isSelected: boolean
  onClick: (inputValue: OptionButtonsProps['inputValue']) => void
  firstValueProps: ValueProps
  secondValueProps: ValueProps
}

export const OptionButtons = ({
  className,
  firstValueProps,
  secondValueProps,
  inputLabel,
  inputValue,
  isDoubleline,
  isSelected,
  onClick,
  ...props
}: OptionButtonsProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Space'].includes(event.code)) {
      event.preventDefault()
      onClick(inputValue)
    }
  }

  const handleChange = () => {
    onClick(inputValue)
  }

  return (
    <S.Container
      $isSelected={isSelected}
      className={`inputRadioAncestor ${className}`}
      onClick={handleChange}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      {...props}
    >
      <S.Input label={inputLabel} value={inputValue} isChecked={isSelected} onChange={handleChange} />
      <S.FirstValue>
        <div>{firstValueProps.value}</div>
        {isDoubleline && <S.Caption>{firstValueProps.caption}</S.Caption>}
      </S.FirstValue>
      {isDoubleline && <Responsive max="mobileL"> / </Responsive>}
      <S.SecondValue $isLineThrough={!isDoubleline}>
        <div>{secondValueProps.value}</div>
        {isDoubleline && <S.Caption>{secondValueProps.caption}</S.Caption>}
      </S.SecondValue>
    </S.Container>
  )
}
