import BlockInfoPrettoSearch from '@pretto/bricks/app/pre-lead/components/BlockInfoPrettoSearch'
import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { Filter, Marker } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const SectionSearchEmpty = ({ href, mobileOs, onEdit, onStore }) => {
  const handleClickEdit = origin => () => {
    onEdit?.(origin)
  }

  const handleClickStore = platform => {
    onStore?.(platform)
  }

  return (
    <PreLeadProjectCardTemplate onEdit={handleClickEdit('card_button')} type="propertySearchDefault" href={href}>
      <S.Infos>
        <S.Card>
          <Responsive min="tablet">
            <S.Description>
              Pretto scanne en temps réel des dizaines de sites immo. Vous recevez en une seule alerte les annonces qui
              correspondent à vos critères et à votre capacité d’achat.
            </S.Description>
          </Responsive>
          <S.Localisation>
            <S.Icon as={Marker} />
            Localisation
          </S.Localisation>
          <S.Filters>
            <S.Icon as={Filter} />
            Critères
          </S.Filters>
          <ButtonInline isSecondary href={href} onClick={handleClickEdit('setup_button')}>
            Paramétrer
          </ButtonInline>
        </S.Card>
        <BlockInfoPrettoSearch mobileOs={mobileOs} onClickStore={handleClickStore} />
      </S.Infos>
    </PreLeadProjectCardTemplate>
  )
}

SectionSearchEmpty.propTypes = {
  href: PropTypes.string,
  mobileOs: PropTypes.string,
  onEdit: PropTypes.func,
  onStore: PropTypes.func,
}

export default SectionSearchEmpty
