import ButtonBackComponent from '@pretto/bricks/components/buttons/ButtonBack'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const Modal = styled.div`
  padding: ${g(3)};
`
export const Illustration = styled(IllustrationDeprecated).attrs({ name: 'CharacterLaptop' })`
  width: ${g(15)};
  margin-top: ${g(3)};
  margin-bottom: ${g(3)};
  margin-left: auto;
  margin-right: auto;
`
export const Title = styled.div`
  ${typo.heading48};
  text-align: center;
`
export const Warning = styled.p`
  margin-top: ${g(2)};
  text-align: center;
`
export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${g(2)};
`
export const Field = styled.div`
  grid-column-end: span 2;
`
export const ButtonBack = styled(ButtonBackComponent)`
  grid-row-start: 2;
  grid-column-start: 1;
`
export const CGU = styled.div`
  ${typo.caption12};
  margin-top: ${g(2)};
`

export const CGULink = styled(Link)`
  text-decoration: underline;
`
