import temporal from '@pretto/bricks/core/utility/temporal'

import { proprify } from '@pretto/app-core/lib/proprify'

import BANKS from '@pretto/config/banks.json'

import libphonenumber from 'google-libphonenumber'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import omit from 'lodash/omit'

const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const addFilter = (path, filterFunction) => (values, data) => ({
  ...values,
  [path]: filterFunction(values, data),
})

export const applyFilter = (path, filterFunction, skipValueIfNull) =>
  applyAndRenameFilter(path, path, filterFunction, skipValueIfNull)

export const applyFilters = filters => (values, data) =>
  filters.reduce((previous, filter) => filter(previous, data), values)

export const applyAndRenameFilter =
  (fromPath, toPath, filterFunction, skipValueIfNull = true) =>
  (values, data) => {
    const value = values[fromPath]

    if (!(fromPath in values) || (isNil(value) && skipValueIfNull)) {
      return values
    }

    return { ...omit(values, fromPath), [toPath]: filterFunction(value, values, data) }
  }

export const applyOmitFilter =
  (...names) =>
  values =>
    omit(values, ...names)

// Filters
export const formatDecodeBanks = selectedBanks => [
  // Add bank groups corresponding to all specified regional banks
  ...Object.keys(
    groupBy(
      BANKS.filter(bank => selectedBanks.some(value => bank.value === value) && bank.group),
      'group'
    )
  ),
  // Add rest of the banks which do not have groups
  ...map(
    BANKS.filter(bank => selectedBanks.some(value => bank.value === value)),
    'label'
  ),
]

export const formatEncodeBanks = selectedBanks =>
  selectedBanks.reduce((previous, bank) => {
    const currentBank = BANKS.find(({ label }) => label === bank)

    if (!currentBank) {
      return previous
    }

    return [...previous, currentBank.value]
  }, [])

export const formatDecodeAddress = ({ city, country, street, zipcode }) => {
  if (!street && !city && !zipcode && !!country) {
    return country
  }

  if (city && country && street && zipcode) return { city, country, street, zipcode }
}

export const formatDecodeDate = date => (date === null ? null : temporal(date).format('DD / MM / YYYY'))
export const formatEncodeDate = date => temporal(date, 'DD / MM / YYYY').format('YYYY-MM-DD')

export const formatEncodeEmail = email => email.toLowerCase()

export const formatDecodeInteger = integer => integer?.toLocaleString('fr', { maximumFractionDigits: 0 })
export const formatEncodeInteger = integer => parseInt(integer, 10)

export const formatDecodeNumber = number => number?.toLocaleString('fr', { maximumFractionDigits: 2 })
export const formatEncodeNumber = number => parseFloat(number)

export const formatDecodePhone = phone => {
  try {
    const parsedPhone = phoneNumberUtil.parse(phone, 'FR')

    if (parsedPhone.getCountryCode() === 33) {
      return phoneNumberUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.NATIONAL)
    }

    return phoneNumberUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.INTERNATIONAL)
  } catch (error) {
    return ''
  }
}
export const formatEncodePhone = phone => {
  const parsedPhone = phoneNumberUtil.parse(phone, 'FR')

  return phoneNumberUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.E164)
}

export const formatEncodeProprify = proprify

export const formatEncodeLocalisation = localisation => {
  if (typeof localisation === 'object') return localisation

  return {
    city: '',
    country: localisation,
    street: '',
    zipcode: '',
  }
}
