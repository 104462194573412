import { toShortISO } from '@pretto/app/src/Sentences/v2/lib/date'
import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import { MaturityType, SimulationType, SituationType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { Maturity } from '@pretto/app/src/types/gateway/enums'
import type { Bridge, Project, Purchase } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'
import pipe from 'ramda/src/pipe'

const mapMaturity = (maturityType: MaturityType): Maturity => {
  switch (maturityType) {
    case MaturityType.SaleAgreementSigned:
      return Maturity.SaleAgreementSigned
    case MaturityType.OfferAccepted:
      return Maturity.OfferAccepted
    case MaturityType.OfferMade:
      return Maturity.OfferMade
    case MaturityType.SearchStarted:
      return Maturity.SearchStarted
    case MaturityType.UnderStudy:
      return Maturity.UnderStudy
    default:
      return Maturity.Unspecified
  }
}

const mapPurchaseAttributes: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const purchase: Purchase = payload.purchase ?? {}

  if (context.maturityType)
    purchase.maturity =
      context.simulationType === SimulationType.Purchase || context.simulationType === SimulationType.Capacity
        ? mapMaturity(context.maturityType)
        : null
  if (context.maturityType === MaturityType.SaleAgreementSigned && context.agreementSignedDate)
    purchase.agreement_sign_date = toShortISO(context.agreementSignedDate as Date)
  if (context.usage === Usage.Rental) purchase.expected_rental_income = context.expectedRentalIncome
  if (context.simulationType === SimulationType.Purchase) {
    if (!isNil(context.propertyPrice)) purchase.property_price = context.propertyPrice
    if (!isNil(context.landPrice)) purchase.land_price = context.landPrice
    if (!isNil(context.constructionPrice)) purchase.build_price = context.constructionPrice
  }

  if (context.agreementSignedDate) purchase.agreement_sign_date = toShortISO(context.agreementSignedDate)

  if (context.simulationType !== SimulationType.Renegotiation) updatedPayload.purchase = purchase

  return {
    context,
    payload: updatedPayload,
  }
}

const mapBridge: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const purchase: Purchase = payload.purchase ?? {}

  if (
    context.usage === Usage.Primary &&
    (context.mortgagors?.[0]?.situationType === SituationType.Owner ||
      context.mortgagors?.[1]?.situationType === SituationType.Owner) &&
    context.ownPropertyForSale
  ) {
    const bridge: Bridge = {
      end_date: !context.ownPropertyPaidOff ? toShortISO(context.ownPropertyEndDate as Date) : null,
      estimated_value: context.ownPropertyEstimatedPrice,
      rate: !context.ownPropertyPaidOff ? context.ownPropertyRate : null,
      remaining_principal: !context.ownPropertyPaidOff ? context.ownPropertyRemainingPrincipal : null,
    }

    purchase.bridge = bridge
  } else {
    purchase.bridge = null
  }

  if (context.simulationType !== SimulationType.Renegotiation) updatedPayload.purchase = purchase

  return {
    context,
    payload: updatedPayload,
  }
}

const createPurchase: ComposableMapper = ({ context, payload }) => {
  // Always clone to prevent mutation
  // structuredClone is available in all modern browsers
  const updatedPayload: Project = structuredClone(payload)
  const clonedPurchase: Purchase = payload.purchase ? structuredClone(payload.purchase) : {}

  // Add default values here if some are mandatory
  const purchase = {
    ...clonedPurchase,
  }

  if (context.simulationType !== SimulationType.Renegotiation) updatedPayload.purchase = purchase

  return {
    context,
    payload: updatedPayload,
  }
}

export const mapPurchase: ComposableMapper = pipe(createPurchase, mapPurchaseAttributes, mapBridge)
