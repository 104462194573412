import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import { IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'
import { UNLINK_ACCOUNT } from '@pretto/app/src/apollo'

import { useMutation } from '@apollo/client'
import { createContext, ReactNode, useState } from 'react'

export enum STATUS {
  default = 'default',
  newProject = 'newProject',
  prettoSearch = 'prettoSearch',
}

export type ProjectModalContextType = {
  status: STATUS
  unlinkAccount: () => void
  setToDefault: () => void
  setToNewProject: () => void
  setToPrettoSearch: () => void
  propertySearchData: IPropertySearchData | null
}

export const ProjectModalContext = createContext<ProjectModalContextType>({
  setToDefault: () => undefined,
  setToNewProject: () => undefined,
  setToPrettoSearch: () => undefined,
  status: STATUS.default,
  propertySearchData: null,
  unlinkAccount: () => undefined,
})

interface Props {
  children: ReactNode
}

export const ProjectModalProvider = ({ children }: Props) => {
  const { data: propertySearchData } = usePropertySearch()

  const [status, setStatus] = useState<STATUS>(STATUS.default)
  const [unlinkAccount] = useMutation(UNLINK_ACCOUNT)

  const handleUnlink = async () => {
    await unlinkAccount()

    window.location.reload()
  }

  const setToDefault = () => {
    setStatus(STATUS.default)
  }

  const setToNewProject = () => {
    setStatus(STATUS.newProject)
  }

  const setToPrettoSearch = () => {
    setStatus(STATUS.prettoSearch)
  }

  const context = {
    propertySearchData,
    setToDefault,
    setToNewProject,
    setToPrettoSearch,
    status,
    unlinkAccount: handleUnlink,
  }

  return <ProjectModalContext.Provider value={context}>{children}</ProjectModalContext.Provider>
}
