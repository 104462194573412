import Cookies from 'js-cookie'

type NetlifyAB = { nf_ab?: string }

export const getNetlifyAB = (): NetlifyAB => {
  const nf_ab: string | undefined = Cookies.get('nf_ab')

  if (!nf_ab || !/^[\d.]+$/.test(nf_ab)) {
    return {}
  }

  return { nf_ab }
}
