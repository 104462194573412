export const parseDestination = usage => {
  switch(usage) {
    case 'primary_residence':
      return 'Résidence principale' 
    case 'secondary_residence':
      return 'Résidence secondaire'
    case 'rental_investment':
      return 'Investissement locatif'
    default:
      return ''
  }
}