import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { ControlsContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/ControlsContext'
import { ModalModify as ModalModifyComponent } from '@pretto/app/src/Capacity/components/modals/ModalModify'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { Pen } from '@pretto/picto'

import { useContext } from 'react'

export const ModalModify: React.FC = () => {
  const { typology } = useUser()
  const { isLoggedIn } = useAuth()

  const { contributionRow, controls, isDialogOpen, onCloseDialog } = useContext(ControlsContext)

  const simulationDetailsProps = {
    editableFieldsProps: {
      fields: contributionRow,
    },
    controls,
  }

  const cta =
    typology !== 'client' ? (
      <ButtonFeature href="/project/capacity/introduction" label="Modifier plus d’infos" picto={Pen} />
    ) : null

  return (
    <ModalModifyComponent
      simulationDetailsProps={simulationDetailsProps}
      isDialogOpen={isDialogOpen}
      onClose={onCloseDialog}
      onValidate={onCloseDialog}
      cta={cta}
      isLoggedIn={isLoggedIn}
    />
  )
}
