import PropTypes from 'prop-types'

import * as S from './styles'

const NavItem = ({ href, isActive, label, picto, ...props }) => (
  <S.NavItem $isActive={isActive} href={href} {...props}>
    {picto && <S.NavigationPicto as={picto} />}
    {label && <S.Label $isActive={isActive}> {label}</S.Label>}
  </S.NavItem>
)

NavItem.propTypes = {
  /** url to redirect to */
  href: PropTypes.string.isRequired,
  /** is the item active */
  isActive: PropTypes.bool,
  /** label of the button */
  label: PropTypes.string,
  /** picto of the button */
  picto: PropTypes.elementType,
}

export default NavItem
