import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CreditCapability = ({ compareHref, onCompareClick }) => (
  <Card variant="accent-1-l">
    <Content>
      <S.Header>
        <S.Emoji>
          <Emoji size="medium">:robot:</Emoji>
        </S.Emoji>

        <SubHeading>Votre projet est finançable par Pretto</SubHeading>
      </S.Header>

      <S.Description>
        <Text size="small" variant="neutral-1">
          Utilisez le simulateur Pretto pour comparer les offres et les taux.
        </Text>
      </S.Description>

      <ButtonSquared href={compareHref} onClick={onCompareClick} variant="accent-1">
        Comparer les taux
      </ButtonSquared>
    </Content>
  </Card>
)

CreditCapability.defaultProps = {
  onCompare: () => {},
}

CreditCapability.propTypes = {
  /** Href of the compare button. */
  compareHref: PropTypes.string.isRequired,
  /** Event handler for when compare button is clicked. */
  onCompareClick: PropTypes.func,
}

export default memo(CreditCapability)
