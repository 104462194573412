import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { Contact } from '@pretto/app/src/dashboard/components/Contact/Contact'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Analysis: React.FC<CardProps> = ({ defaultComponent: Component }) => {
  const {
    user: { email },
  } = useUser()

  const description = (
    <>
      Vous souhaitez commencer un nouveau projet ?
      <br />
      Contactez le service client pour votre prochain projet avec Pretto !
    </>
  )

  return (
    <Component description={description} title="Heureux de vous revoir">
      <Contact subject={`Demande pour un nouveau projet avec Pretto (compte associé : ${encodeURIComponent(email)})`} />
    </Component>
  )
}

export const config: Config = {
  component: Analysis,
  name: 'contact',
}
