import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MaturityModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MaturityModalQuery = { __typename?: 'Query', deal?: { __typename?: 'Deal', lastMaturityUpdateDate?: string | null } | null, project?: { __typename?: 'Project', purchase?: { __typename?: 'Purchase', maturity?: Types.Maturity | null } | null } | null };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export type SimulationResultFragment = { __typename?: 'Result', bank?: { __typename?: 'Bank', allowFlexiblePayments?: boolean | null, allowLoanTransfer?: boolean | null, hasPrepaymentCharge?: boolean | null, id?: string | null, kind?: Types.BankingKind | null, criterias: { __typename?: 'BankCriterias', scoringIra: number, scoringInsurance?: number | null, scoringModularity: number, scoringReportDeadline: number, scoringAverageResponseTime?: number | null, scoringRse?: number | null, scoringDigitization?: number | null } } | null, financing?: { __typename?: 'Financing', applicationFees?: number | null, contributionRate?: number | null, creditCosts?: number | null, guarantyFees?: number | null, penaltyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, remainingLifeAmount?: number | null, savings?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, monthlyDetailedWeightedIncomes?: { __typename?: 'DetailedIncome', expected_rental_income?: number | null, other?: number | null, rental_income?: number | null, wages?: Array<number> | null } | null, ecoPtz?: { __typename?: 'FinancingEcoPTZ', amount?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null, weightedIncomes?: { __typename?: 'FinancingIncome', yearly?: number | null } | null } | null, flags?: Array<{ __typename?: 'ResultFlag', code?: string | null, severity?: Types.SeverityEnum | null }> | null, scoreIndebtednessRate?: { __typename?: 'ScoreIndebtednessRate', kind?: string | null, value?: number | null } | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const SimulationResultFragmentDoc = gql`
    fragment SimulationResult on Result {
  bank {
    allowFlexiblePayments
    allowLoanTransfer
    criterias {
      scoringIra
      scoringInsurance
      scoringModularity
      scoringReportDeadline
      scoringAverageResponseTime
      scoringRse
      scoringDigitization
    }
    hasPrepaymentCharge
    id
    kind
  }
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    contributionRate
    creditCosts
    monthlyDetailedWeightedIncomes {
      expected_rental_income
      other
      rental_income
      wages
    }
    guarantyFees
    penaltyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ecoPtz {
      amount
    }
    ptz {
      amount
      duration
      franchise
    }
    remainingLifeAmount
    savings
    weightedIncomes {
      yearly
    }
  }
  flags {
    code
    severity
  }
  scoreIndebtednessRate {
    kind
    value
  }
}
    `;
export const MaturityModalDocument = gql`
    query MaturityModal {
  deal {
    lastMaturityUpdateDate
  }
  project {
    purchase {
      maturity
    }
  }
}
    `;

/**
 * __useMaturityModalQuery__
 *
 * To run a query within a React component, call `useMaturityModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaturityModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaturityModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaturityModalQuery(baseOptions?: Apollo.QueryHookOptions<MaturityModalQuery, MaturityModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaturityModalQuery, MaturityModalQueryVariables>(MaturityModalDocument, options);
      }
export function useMaturityModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaturityModalQuery, MaturityModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaturityModalQuery, MaturityModalQueryVariables>(MaturityModalDocument, options);
        }
export type MaturityModalQueryHookResult = ReturnType<typeof useMaturityModalQuery>;
export type MaturityModalLazyQueryHookResult = ReturnType<typeof useMaturityModalLazyQuery>;
export type MaturityModalQueryResult = Apollo.QueryResult<MaturityModalQuery, MaturityModalQueryVariables>;