import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import * as typo from '@pretto/bricks/core/typography'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { BannerFolder } from '@pretto/zen/dashboard/bannersCta/BannerFolder/BannerFolder'
import { FolderCard } from '@pretto/zen/dashboard/folders/FolderCard/FolderCard'
import { FolderDocumentsCard } from '@pretto/zen/dashboard/folders/FolderDocumentsCard/FolderDocumentsCard'
import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import styled from 'styled-components'

export const StyledFolderCard = styled(FolderCard)`
  margin-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: calc(((100% - ${g(3)}) / 2));
    margin-top: ${g(0)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    width: calc(((100% - ${g(6)}) / 3));
    margin-top: ${g(0)};
  }
`

export const StyledFolderDocumentsCard = styled(FolderDocumentsCard)`
  margin-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: ${({ $cardNumber }) => ($cardNumber % 2 === 0 ? '100%' : `calc((100% - ${g(3)}) / 2)`)};
    margin-top: ${g(0)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    width: calc((100% / 3) * 2 - ${g(1)});
    margin-top: ${g(0)};
  }
`

export const CardSectionTitle = styled(BaseTypo).attrs({ typo: 'heading32' })`
  margin-bottom: ${g(2)};
`

export const Cards = styled.div`
  padding-bottom: ${g(8)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${g(3)};
    padding-bottom: 0px;
  }
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.neutral4};
  ${grid()};
  ${column(14)}
  padding: ${g(6)} 0;
`

export const ImageContent = styled.div`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 2])}
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 2])}
  }
`

export const Image = styled.div`
  height: ${g(17)};
  width: ${g(17)};
  background-image: url(${getCloudinaryUrl(`v1637684805/zen/static-assets/illustrations/660x660/folderBook.svg`)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, -1])}
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 8])}
  }
`

export const HeaderTitle = styled(BaseTypo).attrs({ typo: 'heading48' })``

export const HeaderDescription = styled(BaseTypo).attrs({ typo: 'bodyBook16' })`
  margin-top: ${g(1)};
`

export const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  padding-bottom: ${g(5)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    padding-bottom: ${g(7)};
  }
`

export const PageTitle = styled(BaseTypo).attrs({ typo: 'heading32' })`
  margin-top: ${g(3)};
`

export const PageDescription = styled(BaseTypo).attrs({ typo: 'bodyBook16' })`
  margin-top: ${g(1)};
  margin-bottom: ${g(2)};
`

export const StyledBanner = styled(Banner)`
  margin-bottom: ${g(4)};
`

export const StyledBannerFolder = styled(BannerFolder)`
  margin-bottom: ${g(4)};
`

export const Main = styled.div`
  ${grid()};
  ${column(14)}

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(5)};
  }
`

export const MainContent = styled.div`
  ${column([2, -2])}

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])}
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])}
  }
`

export const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${g(3)};
`

export const Description = styled.p`
  ${typo.caption12};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.bodyBook16};
  }
`
