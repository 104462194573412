/* eslint-disable max-lines */
const translation = {
  sentences: {
    next: 'Next',
    back: 'Back',
    nextWithoutContribution: 'Continue without downpayment',
    nextWithSmallContribution: 'Continue with this downpayment',
    understood: 'Understood!',
    skip: 'Skip this step',
    suspiciousDate: 'The date seems far off, please check your entry.',
    invalidDate: 'Invalid date format. Please enter dates in the format: DD / MM / YYYY (example: 14 / 09 / 1992).',
    creditTooLong: 'You can not indicate credits that end in more than 100 years from now.',
    paidCredits: 'You do not need to indicate credits that have already been repaid.',
    invalidEmail: 'Your email address is invalid',
    months: {
      '0': 'January',
      '1': 'February',
      '2': 'March',
      '3': 'April',
      '4': 'May',
      '5': 'June',
      '6': 'July',
      '7': 'August',
      '8': 'September',
      '9': 'October',
      '10': 'November',
      '11': 'December',
    },
    maturity: {
      default: {
        values: {
          underStudy: 'are thinking of buying',
          searchStarted: 'are actively searching a property',
          offerMade: 'have made an offer for a property',
          offerAccepted: 'have an accepted offer',
          saleAgreementSigned: 'have signed a sale agreement',
        },
      },
      index: {
        description: 'How far along are you in your real estate project?',
        title: 'Welcome to Pretto',
        others: {
          description: 'I want to renegotiate my mortgage, buy out a share in a property, consolidate my loans, etc.',
          title: 'I am already the owner of the property in question',
        },
        reassurance: {
          image: {
            alt: 'Pretto team',
          },
          items: [
            { description: 'Our brokers share their knowledge and defend your interests.', title: 'Transparency' },
            {
              description:
                'We handle all projects (First-time buyers, bridge loans, non-residents, real estate companies (SCI)...).',
              title: 'Expertise',
            },
            { description: 'Stay in touch with your expert (video, SMS, phone).', title: 'Commitment' },
          ],
          title: 'Pretto guarantees you...',
        },
        values: {
          underStudy: {
            description: "My purchase project is not defined yet and I haven't started visits",
            title: 'I am thinking of buying',
          },
          searchStarted: {
            description: 'I am regularly looking at listings and have started or planned visits',
            title: 'I am actively searching',
          },
          offerMade: {
            description: '',
            title: 'I have made an offer on a property',
          },
          offerAccepted: {
            description: '',
            title: 'I have an accepted offer',
          },
          saleAgreementSigned: {
            description: '',
            title: 'I have signed a sale agreement ("compromis")',
          },
        },
      },
      propertyPrice: {
        sentence: 'Do you know the price of the property you want to buy?',
        values: {
          no: 'Not yet',
          yes: 'Yes',
        },
      },
      type: 'You',
      agreementSignedDate: 'on the',
    },
    introduction: {
      title: 'Your project',
      structure: {
        error: {
          nonSolo:
            'Pretto does not yet support buyout projects involving multiple parties or in a real estate company (SCI).',
        },
        sentence: {
          buyout: 'You wish to buy out a share in a property',
          purchase: 'You wish to buy a property',
          renegotiation: 'You wish to renegotiate a mortgage loan',
        },
        values: {
          solo: 'by yourself',
          duo: 'with your partner',
          sci: 'in a real estate company (SCI)',
        },
      },
      sci: {
        type: 'This is a real estate company subject to the',
        error: {
          sciType:
            'Unfortunately, Pretto does not yet support financing real estate projects for companies subject to corporate tax.',
          sciAssociation:
            'Unfortunately, Pretto does not yet support financing real estate projects for companies subject to income tax with more than 2 partners.',
        },
        values: {
          is: 'corporate tax (IS)',
          ir: 'income tax (IR)',
        },
      },
      sciAssociation: {
        sentence: ['It is made up of', 'partners, including you.'],
        values: {
          simple: '2',
          complex: 'more than 2',
        },
      },
      propertyUsage: {
        sentence: {
          buyout: 'The buyout concerns',
          purchase: 'You want to buy',
          renegotiation: 'It is',
        },
        warning:
          'Given the specific nature of your project, we invite you to provide your contact details to one of our specialized experts who will study the feasibility of your project with you by phone soon.',
        values: {
          primary: 'your main residence',
          secondary: 'a second home',
          rental: 'a rental property',
          pro: 'a professional or commercial project',
          mixed: 'a mixed-use project',
        },
      },
      propertyLocalisation: {
        sentence: 'in',
      },
      propertyType: {
        sentence: 'It is',
        values: {
          old: 'a pre-owned property',
          new: 'a newly built property',
          vefa: 'a sale before completion (VEFA)',
          construction: 'a construction',
          landConstruction: 'a land and construction',
        },
      },
      others: {
        sentence: 'You want to',
        values: {
          buyout: 'buy out a share in a property',
          renegotiation: 'renegotiate or consolidate your loans',
        },
      },
    },
    lodging: {
      comortgagor: {
        title: 'Their situation',
        situation: {
          sentence: 'Your co-borrower',
          values: {
            tenant: 'rent their home',
            owner: 'own their home',
            sheltered: 'is hosted for free',
            staffHoused: 'lives in company housing',
          },
        },
        rent: {
          sentences: ['and their rent including charges is', '€ per month'],
        },
        forSale: {
          sentences: ['and', 'sell their property to finance your new purchase'],
          values: {
            yes: 'wishes to',
            no: 'does not wish to',
          },
        },
        address: {
          sentence: 'Your co-borrower lives',
        },
        age: {
          sentences: [', your co-borrower is', 'years old'],
          error: 'Please specify an age between 18 and 99 years.',
        },
      },
      title: 'Your situation',
      age: {
        sentences: ['You are', 'years old'],
        error: 'Please specify an age between 18 and 99 years.',
      },
      children: {
        sentences: [' and you', ''],
        values: [
          'have no dependent children',
          'have one dependent child',
          'have two dependent children',
          'have three dependent children',
          'have four dependent children',
          'have five dependent children',
          'have six dependent children',
          'have seven dependent children',
          'have eight dependent children',
          'have nine dependent children',
          'have ten dependent children',
        ],
      },
      liveTogether: {
        sentences: ['You', 'together'],
        values: {
          yes: 'live',
          no: 'do not live',
        },
      },
      address: {
        info: 'You do not live in France: all amounts you will indicate must be entered in euros. Therefore, remember to convert foreign currencies into euros at the current exchange rate.',
        sentence: ['You live', 'in', 'in'],
        values: {
          france: 'in France',
          abroad: 'abroad',
        },
      },
      situation: {
        sentence: 'You',
        values: {
          tenant: 'rent your home',
          owner: 'own your home',
          sheltered: 'are hosted for free',
          staffHoused: 'live in company housing',
        },
      },
      rent: {
        sentences: ['and your rent is', '€ per month'],
      },
      forSale: {
        sentences: ['and you', 'sell your property to finance your new purchase'],
        values: {
          yes: 'wish to',
          no: 'do not wish to',
        },
      },
      countryPlaceholder: 'The United Kingdom',
    },
    situation: {
      comortgagor: {
        title: 'Their professional situation',
        sector: {
          sentence: 'Your co-borrower is',
        },
        expatriate: {
          sentences: ['Your co-borrower', 'the expatriate status'],
          values: {
            yes: 'has',
            no: 'does not have',
          },
        },
      },
      title: 'Your professional situation',
      sector: {
        sentence: 'You are',
        values: {
          private: 'employed in the private sector',
          public: 'employed in the public sector',
          others: 'self-employed',
          medical: 'a medical professional',
        },
      },
      expatriate: {
        sentences: ['You', 'the expatriate status'],
        values: {
          yes: 'have',
          no: 'do not have',
        },
      },
      contractType: {
        values: {
          longTerm: 'permanent contract (CDI)',
          shortTerm: 'temporary contract (CDD)',
        },
      },
      since: 'since',
      in: 'in a',
      error: 'The date seems far off, please check your entry.',
      private: {
        longTerm: {
          executive: {
            values: {
              executive: 'as an executive ("cadre")',
              nonExecutive: 'as a non-executive ("non cadre")',
            },
          },
          trial: {
            values: {
              nonTrial: 'past probation period',
              trial: 'in probation period',
            },
          },
        },
      },
      public: {
        sentence: 'with a status of',
        values: {
          tenure: 'permanent',
          contractor: 'contractual',
          intern: 'intern',
        },
      },
      others: {
        sentence: 'more specifically',
        values: {
          selfEmployed: 'self-employed or freelancer',
          retired: 'retired',
          businessOwner: 'business owner',
          lawyer: 'legal professional',
          doctor: 'medical and paramedical professional',
          liberal: 'non-medical, non-paramedical, non-legal professional',
          jobless: 'unemployed',
          trader: 'retailer',
          farmer: 'farmer',
          parentalLeave: 'on parental leave',
          craftsman: 'craftsman',
          student: 'student',
          salariedDirector: 'salaried business owner',
          interim: 'temporary worker',
          casualShowBusinessWorker: 'temporary show business worker',
          portageSalarial: 'wage portage',
          jobseeker: 'job seeker',
        },
      },
    },
    income: {
      comortgagor: {
        title: 'Their income',
        salary: {
          sentence: ['Your co-borrower receives a fixed', 'salary of'],
          info: [
            'We need your co-borrower’s salary ',
            'net before taxes',
            '. In France, you would find it on their pay slip under the label « net à payer avant impôt sur le revenu ».',
          ],
        },
        bonus: {
          sentence: ['Your co-borrower', 'of', '€ (on average over the last 3 years)'],
          values: {
            yes: 'receive bonuses',
            no: 'does not receive bonuses',
          },
        },
        otherIncome: {
          sentence: [
            'On average over the last three years, the',
            'income of your co-borrower has been ',
            '(including dividends) ',
            ' of',
          ],
        },
        revenue: {
          title: 'Their additional income',
        },
      },
      title: 'Your income',
      salary: {
        sentence: ['You receive a fixed', 'salary of'],
        info: [
          'We need your salary ',
          'net before taxes',
          '. In France, you would find it on your pay slip under the heading « net à payer avant impôt sur le revenu ».',
        ],
      },
      bonus: {
        sentence: ['You', 'of', '€ (on average over the last 3 years)'],
        values: {
          yes: 'receive bonuses',
          no: 'do not receive bonuses',
        },
        period: {
          year: 'annually',
          month: 'monthly',
        },
        type: {
          net: 'net before taxes',
          gross: 'gross',
        },
      },
      otherIncome: {
        sentence: ['On average over the last three years, your', 'income has been', '(including dividends)', ' of'],
      },
      period: {
        year: 'annual',
        month: 'monthly',
      },
      type: {
        net: 'net before taxes',
        gross: 'gross',
      },
      revenue: {
        title: 'Your additional income',
        subtitle: 'Income',
        addValue: 'Add an additional income source',
        sentences: ['for an amount of', '€ per month'],
        values: {
          rent: 'Rental income',
          childSupport: 'Child support',
          professionalBenefits: 'Self-employment income',
          familyAllowances: 'Allowances',
          expatriationBonus: 'Expatriation allowances',
        },
      },
    },
    sale: {
      title: 'Your property for sale',
      estimatedPrice: {
        sentences: ['Your property is valued at', '€'],
      },
      paidOff: {
        sentences: ['You', 'finished paying your mortgage'],
        values: {
          yes: 'have',
          no: 'have not',
        },
      },
      remainingMortgage: {
        sentences: ['You have', '€ left to repay', ', at a rate of', '%', '. Your mortgage ends on'],
      },
    },
    ptz: {
      title: 'Your zero-rate loan (PTZ)',
      beenOwner: {
        sentences: ['You', 'owned your home in the last two years'],
        values: {
          yes: 'have',
          no: 'have not',
        },
      },
      fiscalIncomeNm2: {
        sentences: ['Your French reference fiscal income in {{year}} amounted to', '€'],
      },
    },
    charge: {
      title: 'Your expenses',
      sentences: ['You', 'Your co-borrower', 'other credits in progress.'],
      credits: {
        addValue: 'Add a credit',
        sentences: [
          'with a monthly payment of',
          '€ per month',
          ' which ends on',
          'on the property subject to the buyout (remaining balance',
          '€)',
        ],
        values: {
          mortgage: 'Mortgage',
          consumer: 'Consumer credit',
          car: 'Car credit',
          student: 'Student loan',
          LOA: 'Lease with Purchase Option (LOA, or leasing)',
          other: 'Other type of credit',
        },
        subtitle: {
          purchase: 'Credit',
          renegotiation: 'Your mortgage to be refinanced',
        },
      },
      childSupport: {
        sentences: ['You', 'child support', ' which amounts to', '€ per month'],
        values: {
          yes: 'pay',
          no: 'do not pay',
        },
        comortgagor: {
          sentence: 'Your co-borrower',
          values: {
            yes: 'pays',
            no: 'does not pay',
          },
        },
      },
    },
    property: {
      title: 'Your future property',
      dpe: {
        sentence: 'The DPE (Energy Performance Diagnostic) of your future property is',
        values: {
          A: 'Class A',
          B: 'Class B',
          C: 'Class C',
          D: 'Class D',
          E: 'Class E',
          F: 'Class F',
          G: 'Class G',
          NA: 'Unknown',
        },
      },
      good: {
        sentences: ['The price of the property is', '€ (agency fees included)'],
      },
      construction: {
        sentences: ['The cost of construction is', '€'],
      },
      landConstruction: {
        sentences: ['The cost of the land - agency fees included - is', ', and the cost of construction is', '€'],
      },
      works: {
        sentences: ['You', 'finance a renovation project', ' for an amount of', '€'],
        values: {
          yes: 'wish to',
          no: 'do not wish to',
        },
      },
      ecoPtz: {
        sentences: ['The renovations you are considering', 'energy renovation work.'],
        values: {
          yes: 'include',
          no: 'do not include',
        },
      },
      expectedRental: {
        sentences: ['The expected rent is', '€ per month'],
      },
    },
    propertyRenegotiation: {
      title: 'Your property',
      estimatedPrice: {
        sentences: ['You estimate that the current price of the property is', '€'],
      },
    },
    loan: {
      title: 'Your mortgage',
      bank: {
        sentences: ['The loan you want to renegotiate was taken out with', '. Your borrower insurance is'],
        values: {
          bankGroupInsurance: 'outsourced insurance (assurance déléguée)',
          delegatedInsurance: 'the bank’s insurance',
        },
      },
      payment: {
        sentences: {
          bankGroupInsurance: ['The monthly insurance-included installment of your loan is', '€'],
          delegatedInsurance: [
            'The installment of your loan is',
            '€',
            ' and your average borrower insurance installment is',
            '€',
          ],
        },
      },
      rate: {
        sentences: ['The interest rate of this loan is', '%'],
      },
      dateDuration: {
        sentences: ['You signed this loan in', 'for a duration of', 'years'],
        date: {
          error: 'You cannot renegotiate a loan that has not started yet.',
        },
        duration: {
          error: 'You cannot renegotiate a loan that ends in less than 5 years.',
        },
      },
    },
    contribution: {
      title: 'Your downpayment',
      contribution: {
        sentences: ['In addition to the resale, to ', 'To ', 'finance your project you have a downpayment of', '€'],
      },
      savings: {
        sentences: ['After this purchase, you will have', '€ remaining savings.'],
      },
    },
    competition: {
      title: 'Your mortgage application',
      hasCompetition: {
        sentences: ['You', ' and your co-borrower', 'received a proposal from a bank.'],
        values: {
          yes: 'have already',
          no: 'have not',
        },
      },
      offer: {
        sentences: [
          'The best offer you have comes from',
          '. They offered an interest rate (excluding insurance) of',
          '%',
          ' for a duration of',
          'years',
        ],
      },
      bankPlaceholder: 'Bank',
    },
    rateAlert: {
      title: 'Your personalized rate',
      sentence:
        'Rates change constantly! We track them continuously for each profile and can alert you when an opportunity for a more attractive rate appears at one of our partner banks. (The alert will be in French.)',
      wantsRateAlert: {
        sentences: ['And you want', 'and receive your alerts at this email address:'],
        values: {
          yes: 'to be alerted',
          no: 'not to be alerted',
        },
      },
      emailRateAlert: {
        sentence: 'Your email address is',
        disclaimer: ['By signing up for the Pretto rate alert, you accept our', 'terms and conditions of use'],
      },
    },
    propertySearch: {
      title: 'Your property search',
      localisations: {
        sentence: 'You are monitoring listings in',
        addCity: 'Add a town',
      },
      frequency: {
        sentence: 'And wish',
        values: {
          real_time: 'to be notified in real time',
          daily: 'to be notified once a day',
          weekly: 'to be notified once a week',
          never: 'not to be notified',
        },
      },
      criteriaEmail: {
        disclaimer: ['By signing up for Pretto alerts, you accept our', 'terms and conditions of use.'],
        sentences: [
          'You are looking for a property with a surface area between',
          'sqm',
          ' and',
          'sqm',
          ', with at least',
          '.',
          'You want to receive your alerts (in French) at this email address:',
          '.',
        ],
        values: {
          '1': '1 room or more',
          '2': '2 rooms or more',
          '3': '3 rooms or more',
          '4': '4 rooms or more',
          '5': '5 rooms or more',
        },
      },
    },
    tips: {
      project: {
        purchase:
          'Take a few minutes to describe your project: it’s simple, quick, and without commitment. The more complete you are in describing your situation, the more precise our comparison of the best rates for you will be!',
        renegotiation:
          'Take a few minutes to describe your project: it’s simple, quick, and without commitment. The more complete you are in describing your situation, the more precise our comparison of the best rates for you will be!',
        capacity:
          'Take a few minutes to describe your project: it’s simple, quick, and without commitment. The more complete you are in describing your situation, the more precise our estimate of your borrowing capacity will be!',
      },
      income: {
        salary: {
          mortgagor:
            'With income tax deducted at source in France, we need your salary <strong>net before taxes</strong>. You will find it on your pay slip under the mention « net à payer avant impôt sur le revenu ».',
          comortgagor:
            'With income tax deducted at source in France, we need the salary of your co-borrower <strong>net before taxes</strong>. You will find it on your pay slip under the mention « net à payer avant impôt sur le revenu ».',
        },
        bonus: {
          mortgagor:
            'The bank calculates the average of the bonus you have received over the last three years. Do not overestimate it: you might misjudge your housing budget.',
          comortgagor:
            'The bank calculates the average of the bonus your co-borrower has received over the last three years. Do not overestimate it: you might misjudge your housing budget.',
        },
        businessOwner: {
          mortgagor:
            'You are a business owner, to calculate your income we invite you to average your declared income on your income tax returns over the last three years, you may include dividends in this average.',
          comortgagor:
            'Your co-borrower is a business owner, to calculate their income we invite you to average their declared income on their income tax returns over the last three years, you may include dividends in this average.',
        },
      },
      contribution: {
        nonResident:
          'You are a non-resident: banks require that your downpayment covers both the fees and 20% of the property’s price, i.e., <strong>{{minContribution}} €</strong>.',
        nonResidentCapacity:
          'You are a non-resident: banks require that your downpayment covers both the fees and 20% of the property’s price.',
        construction:
          'It is recommended that your downpayment covers at least the fees of your project and 10% of the construction price, i.e., <strong>{{minContribution}} €</strong>. You can sometimes contribute less, but keep in mind that the higher your downpayment, the easier getting your mortgage will be.',
        landConstruction:
          'It is recommended that your downpayment covers at least the fees of your project and 10% of the land and construction price, i.e., <strong>{{minContribution}} €</strong>. You can sometimes contribute less, but keep in mind that the higher your downpayment, the easier getting your mortgage will be.',
        purchase:
          'It is recommended that your downpayment covers at least equal the fees of your project, i.e., <strong>{{minContribution}} €</strong>. You can sometimes contribute less, but keep in mind that the higher your downpayment, the easier getting your mortgage will be.',
        capacity:
          'The higher your downpayment, the more you can borrow and the easier getting your mortgage will be. Of course, you can change your downpayment at any time.',
        withoutContribution: [
          'Are you sure you want to continue without a downpayment?',
          'Without a downpayment, getting a mortgage might be impossible.',
        ],
        smallContribution: 'Are you sure you want to continue with this downpayment?',
      },
      nonResident: {
        currency:
          'You do not live in France: all amounts you will indicate must be entered in euros. Please convert foreign currencies into euros at the current exchange rate.',
        savings:
          'You are a non-resident: banks require at least residual savings of 10% of the borrowed amount, which is approximately <strong>{{minimumSavings}} €</strong>.',
        rentalInvestment:
          'You do not live in France and you are making a rental investment: your rate and the minimum downpayment required by the bank will be higher than for a resident. The result of this simulation will take this into account.',
      },
      boomer:
        'We recommend you choose a mortgage duration that allows you to repay it before you turn 75: you will have access to more banks and your mortgage insurance will be less expensive.',
      occupation: {
        trialPeriod: {
          mortgagor:
            'Your income will not be taken into account during the probation period. You can wait or, if possible, negotiate with your employer to confirm your permanent contract (CDI).',
          comortgagor:
            'The income of your co-borrower during the probation period will not be considered. You can wait or, if possible, negotiate with their employer to confirm their permanent contract (CDI).',
        },
        shortTerm: {
          mortgagor:
            'Your income on a temporary contract (CDD) will not be taken into account by banks when calculating your borrowing capacity.',
          comortgagor:
            'The income on a temporary contract (CDD) of your co-borrower will not be taken into account by banks when calculating your borrowing capacity.',
        },
        earlyBusinessOwner: {
          mortgagor:
            'Your income as a business owner will not be taken into account by banks if you started your activity less than 3 years ago.',
          comortgagor:
            'The income of your co-borrower as a business owner will not be taken into account by banks if they started their activity less than 3 years ago.',
        },
      },
      credits:
        'We optimize your debt ratio to keep it acceptable by banks. Therefore, indicate the credits that you will continue to repay at the same time as your new mortgage.',
      hasWorks:
        'A mortgage can only finance renovation work that you cannot take with you. For example, you can finance a partition wall, plumbing, or flooring...',
      forSale:
        'Banks may grant you a bridge loan. It is an advance on the sale of your property on which you only pay the interest. This allows you to limit your debt while giving you time to find a buyer.',
      dpe: 'Where can you find this information? The DPE class must normally appear in the real estate advertisement of your future home.',
      renegotiationLoan:
        'If the loan you are renegotiating consists of several lines, indicate here your total monthly payment. Your Pretto expert will refine it with you later.',
      ecoPtz: `Energy renovation work (for example, thermal insulation of your roof, changing windows, or the heating system) can be financed by banks using an eco-PTZ. It is a zero-rate loan with a maximum amount of ${(50000).toLocaleString(
        'fr'
      )} €. This amount varies depending on the renovation work performed.`,
      buyout:
        'The balance payment corresponds to the share you are buying back from the people with whom you share ownership of the property. The amount of the balance payment will be specified by your notary, but you can also calculate it (<Link href="https://faq.pretto.fr/fr/articles/1833026#comment_connaitre_le_montant_de_la_soulte_a_racheter">learn more</Link>).',
    },
    buyout: {
      title: 'Your buyout',
      buyout: {
        sentences: [
          'You',
          'an outstanding loan on this property.',
          'On which remains',
          '€ to be repaid.',
          'The amount of the property share you want to buy out is',
          '€.',
        ],
        values: {
          yes: 'have',
          no: 'don’t have',
        },
      },
    },
  },
  timeline: {
    sections: {
      introduction: {
        title: 'Introduction',
        steps: {
          project: 'Your project',
        },
      },
      mortgagor: {
        title: 'Your information',
        steps: {
          situationPersonal: 'Your situation',
          loan: 'Your loan',
          situationProfessional: 'Your professional situation',
          incomes: 'Your income',
          sale: 'Your property for sale',
          ptz: 'Your zero-rate loan',
        },
      },
      comortgagor: {
        title: 'Your co-borrower',
        steps: {
          situationPersonal: 'Their situation',
          situationProfessional: 'Their professional situation',
          incomes: 'Their income',
        },
      },
      end: {
        steps: {
          buyout: 'Your buyout',
          charges: 'Your expenses',
          credits: 'Your credits',
          good: 'Your property',
          contribution: 'Your downpayment',
          competition: 'Your mortgage application',
          propertySearch: 'Your property search',
          rateAlert: 'Your personalized rate',
        },
      },
    },
  },
} as const

export default translation
