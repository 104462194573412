import EditableRow from '@pretto/bricks/app/simulator/components/EditableRow'
import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Row = styled(EditableRow)`
  & + & {
    margin-top: ${g(1)};
  }
`
export const Button = styled(ButtonComponent)`
  margin-top: ${g(3)};
  width: 100%;
`
