import Responsive from '@pretto/bricks/components/utility/Responsive'

import { ChevronRightBold } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const DashboardAvailableBookingCard = ({ children, description, onActionClick, onIconClick, actionUrl, ...props }) => (
  <S.Card {...props}>
    <S.Title>
      <Responsive max="tablet">
        <S.TitleText>Prenez RDV</S.TitleText>
      </Responsive>
      <Responsive min="tablet">
        <S.TitleText>Prenez rendez-vous</S.TitleText>
      </Responsive>
      <ChevronRightBold onClick={onIconClick} />
    </S.Title>
    <S.Description>{description}</S.Description>
    {children}
    <S.SeeMore onClick={onActionClick} href={actionUrl}>
      Plus de créneaux
    </S.SeeMore>
  </S.Card>
)

DashboardAvailableBookingCard.propTypes = {
  actionUrl: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  onIconClick: PropTypes.func,
}

export default DashboardAvailableBookingCard
