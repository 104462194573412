import PropTypes from 'prop-types'

import * as S from './styles'

const ListTips = ({ tips }) => (
  <S.ListContainer>
    {tips.map(({ icon, text }, index) => (
      <S.Tip key={index}>
        <S.Picto $picto={icon} />
        <S.Text>{text}</S.Text>
      </S.Tip>
    ))}
  </S.ListContainer>
)

ListTips.propTypes = {
  tips: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ListTips
