import { getIsHotMaturity } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/getIsHotMaturity'

import * as Sentry from '@sentry/react'

import { ScoringContext } from './ScoringContext'
import { useScoringQuery } from './scoring.gateway.graphql'

interface ScoringProviderProps {
  children: React.ReactElement
}

export const ScoringProvider: React.FC<ScoringProviderProps> = ({ children }) => {
  const { data, loading, error } = useScoringQuery({ fetchPolicy: 'network-only' })

  if (loading) {
    return null
  }

  if (error) {
    Sentry.captureException(error)
  }

  const context = {
    isHighPotential: getIsHotMaturity(data?.project?.purchase?.maturity || ''),
  }

  return <ScoringContext.Provider value={context}>{children}</ScoringContext.Provider>
}
