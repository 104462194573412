import { g } from '@pretto/bricks/components/layout'
import BubbleChatComponent from '@pretto/bricks/shared/components/BubbleChat'

import styled, { css } from 'styled-components'

export const BubbleChat = styled(BubbleChatComponent)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const BubbleContent = styled.div`
  display: flex;
  gap: ${g(2)};

  ${({ $state, theme }) => {
    switch ($state) {
      case 'warning':
        return css`
          color: ${theme.colors.warning1};
        `

      case 'info':
      default:
        return css`
          color: ${theme.colors.neutral1};
        `
    }
  }}
`

export const Picto = styled.svg`
  display: block;
  height: ${g(3)};
  flex-shrink: 0;
`

export const Paragraph = styled.p`
  margin-bottom: ${g(3)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const ComorgtgagorButton = styled.div`
  display: flex;
  justify-content: center;
`
