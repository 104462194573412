import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { ChevronRightBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { forwardRef, Fragment } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const BaseInput = forwardRef(
  (
    {
      inputProps,
      isButtonDisabled,
      isButtonLoading,
      isMessageTips,
      isSingle,
      message,
      onChange,
      onSubmit,
      picto,
      size,
      state,
      value,
      buttonPicto,
      placeholderAsLabel,
      ...props
    },
    ref
  ) => {
    const handleChange = (event, ...args) => {
      onChange(event.currentTarget.value, event, ...args)
    }

    const handleSubmit = (event, ...args) => {
      event.preventDefault()
      onSubmit(event, ...args)
    }

    return (
      <>
        <S.InputContainer {...props} $size={size} $isSingle={isSingle} $onSubmit={handleSubmit} $state={state}>
          <S.Input
            {...inputProps}
            $state={state}
            $size={size}
            $placeholderAsLabel={placeholderAsLabel}
            $isWithPicto={!!picto}
            onChange={handleChange}
            ref={ref}
            value={value}
          />
          <TransitionGroup component={Fragment}>
            {state && (
              <Transition
                key={state}
                unmountOnExit
                timeout={{
                  enter: 10,
                  exit: 300,
                }}
              >
                {transitionState => (
                  <S.AlertPicto
                    $state={state}
                    $isEntered={transitionState === 'entered'}
                    $isSingle={isSingle}
                    $isWithPicto={!!picto}
                  />
                )}
              </Transition>
            )}
          </TransitionGroup>
          {picto && <S.Picto as={picto} />}
          {isSingle && (
            <S.Button $state={state} $size={size} disabled={isButtonDisabled || isButtonLoading}>
              <S.ButtonPicto as={buttonPicto} $isLoading={isButtonLoading} />
            </S.Button>
          )}
        </S.InputContainer>
        <TransitionGroup component={Fragment}>
          {state && message && (
            <CollapsibleRow key={message}>
              {isMessageTips ? (
                <S.ErrorBubble $state={state}>
                  <S.ErrorMessage $state={state}>{message}</S.ErrorMessage>
                </S.ErrorBubble>
              ) : (
                <S.Message $state={state}>{message}</S.Message>
              )}
            </CollapsibleRow>
          )}
        </TransitionGroup>
      </>
    )
  }
)

BaseInput.propTypes = {
  /** Props of input tag. */
  inputProps: PropTypes.object,
  /** Whether button is disabled. Available when `isSingle` is `true`. */
  isButtonDisabled: PropTypes.bool,
  /** Whether button is loading. Available when `isSingle` is `true`. */
  isButtonLoading: PropTypes.bool,
  /** Wether the error message is displayed on a line or in a bubble */
  isMessageTips: PropTypes.bool,
  /** If the input is the only one in the form, this prop should be set to `true`. A button will appear inside the input. */
  isSingle: PropTypes.bool,
  /** Information about the input. Useful when in error or warning state. */
  message: PropTypes.node,
  /** onChange function that has the input `value` as a parameter. */
  onChange: PropTypes.func.isRequired,
  /** Submit function of the input, tobe used only when `isSingle` is set to `true`. */
  onSubmit: PropTypes.func,
  /** Picto element of input. */
  picto: PropTypes.element,
  /** Size of input */
  size: PropTypes.oneOf(['big', 'small']),
  /** State of input. */
  state: PropTypes.oneOf(['error', 'warning']),
  /** Value of input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Picto used for the button when `isSingle` is set to `true`. */
  buttonPicto: PropTypes.element,
  placeholderAsLabel: PropTypes.bool,
}

BaseInput.defaultProps = {
  size: 'big',
  buttonPicto: ChevronRightBold,
}

BaseInput.displayName = 'BaseInput'

export default BaseInput
