import { useState } from 'react'

import BlockInfoPrettoSearch from '@pretto/bricks/app/pre-lead/components/BlockInfoPrettoSearch'
import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import { Filter, Marker } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'
import { filterNorms, getCities, getMinMaxSentence, parsePropertyCondition, parsePropertyKind } from './utils'
import { States } from '@pretto/zen/reveal/atoms/message/Message'

export const EMPTY_VALUE = '-'

const SectionSearch = ({
  href,
  localisations,
  mobileOs,
  onEdit,
  onStore,
  onReactivate,
  priceMax,
  priceMin,
  propertyCondition,
  propertyKind,
  rooms,
  surfaceMax,
  surfaceMin,
  deactivated,
}) => {
  const [reactivationSuccess, setReactivationSuccess] = useState(false)

  const price = getMinMaxSentence(
    priceMin && formatNumber(priceMin, { suffix: '€' }),
    priceMax && formatNumber(priceMax, { suffix: '€' })
  )
  const surface = getMinMaxSentence(
    surfaceMin && formatNumber(surfaceMin, { suffix: 'm²' }),
    surfaceMax && formatNumber(surfaceMax, { suffix: 'm²' })
  )
  const roomsValue = (
    <>
      <strong>{rooms}+</strong> pièces
    </>
  )
  const kind = parsePropertyKind(propertyKind)
  const condition = parsePropertyCondition(propertyCondition)

  const norms = filterNorms([kind, surface, condition, roomsValue, price])

  const [cities, rest] = getCities(localisations)

  const handleClickEdit = () => {
    onEdit?.('card_button')
  }

  const handleClickStore = platform => {
    onStore?.(platform)
  }

  const handleClickActivateAlert = async () => {
    await onReactivate()
    setReactivationSuccess(true)
  }

  const AlertDeactivatedMessage = () => (
    <S.WarningMessage>
      <S.MessageInnerContainer>
        Votre alerte a été désactivée.
        <S.Button onClick={handleClickActivateAlert}>
          Réactiver mon alerte
        </S.Button>
      </S.MessageInnerContainer>
    </S.WarningMessage>
  );

  const AlertReactivatedMessage = () => (
    <S.InfoMessage state={States.Info}>
      Alerte réactivée avec succès.
    </S.InfoMessage>
  );

  const isDeactivated = deactivated && !reactivationSuccess;
  const isReactivated = deactivated && reactivationSuccess;

  return (
    <PreLeadProjectCardTemplate href={href} onEdit={handleClickEdit} type="propertySearch">
      <>
        {isDeactivated && <AlertDeactivatedMessage />}
        {isReactivated && <AlertReactivatedMessage />}
      </>
      <S.Infos>
        <S.Card>
          <S.Header>
            <S.Icon as={Marker} />
            Localisation
          </S.Header>
          <S.Localisations>
            {cities.map(city => (
              <S.Tag key={city}>{city}</S.Tag>
            ))}
            {rest > 0 ? (
              <S.Tag>
                +{rest} {rest === 1 ? 'localité' : 'localités'}
              </S.Tag>
            ) : null}
          </S.Localisations>
          <S.Header>
            <S.Icon as={Filter} />
            Critères
          </S.Header>
          {norms.map((norm, index) => (
            <div key={index}>- {norm}</div>
          ))}
        </S.Card>
        <BlockInfoPrettoSearch mobileOs={mobileOs} onClickStore={handleClickStore} />
      </S.Infos>
    </PreLeadProjectCardTemplate>
  )
}

SectionSearch.propTypes = {
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  /** Liste of cities to be displayed. */
  localisations: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Mobile OS (or nothing on desktop) */
  mobileOs: PropTypes.string,
  /** Function triggered when edit icon is clicked. */
  onEdit: PropTypes.func,
  /** Function triggered when store buttons are clicked. */
  onStore: PropTypes.func,
  /** Maximum price of searched property. */
  priceMax: PropTypes.number,
  /** Minimum price of searched property. */
  priceMin: PropTypes.number,
  /** old or new property */
  propertyCondition: PropTypes.string,
  /** flat or house property */
  propertyKind: PropTypes.string,
  /** Minimum number of rooms requested for searched property. */
  rooms: PropTypes.number,
  /** Maximum surface of searched property. */
  surfaceMax: PropTypes.number,
  /** Minium surface of searched property. */
  surfaceMin: PropTypes.number,
  /** Activity status for the alert  */
  deactivated: PropTypes.bool,
  /** Callback to reactivate an alert */
  onReactivate: PropTypes.func
}

export default SectionSearch
