import scoringFlagsFR from '@pretto/config/scoring_flags.json'

import compileComments from './compileComments'

const SEVERITY_ORDER = {
  block: 0,
  warn: 1,
}

const getMalusFromFlags = (flags, data, { scoringFlags = scoringFlagsFR, locale = 'fr' } = {}) => {
  const newflags = [...flags]

  return newflags
    .sort((flagA, flagB) => SEVERITY_ORDER[flagA.severity] - SEVERITY_ORDER[flagB.severity])
    .reduce((previous, flag) => {
      const flagConfig = scoringFlags.find(({ code }) => code === flag.code)

      if (!flagConfig) {
        return previous
      }

      return [
        ...previous,
        {
          ...flag,
          ...flagConfig,
          comments: compileComments(flagConfig.comments, data, locale),
        },
      ]
    }, [])
}

export default getMalusFromFlags
