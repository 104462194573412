import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import { renderSentence } from '@pretto/bricks/app/simulator/pages/SimulationBookingPage'
import PreviousNextNavBar from '@pretto/bricks/components/bars/PreviousNextNavBar'
import { Grid } from '@pretto/bricks/components/layout/Grid'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'

import { useResize } from '@pretto/zen/reveal/lib/useResize'

import { Times, UserCircle } from '@pretto/picto'

import PropTypes from 'prop-types'
import { useRef } from 'react'

import * as S from './styles'

const SubscribeSentencesPage = ({
  bubbleDescription,
  consentProps,
  onToggleTimeline,
  disclaimer,
  error,
  fieldErrors,
  isDeliveryVisible,
  isSignedUp,
  isNextVisible,
  isTimelineOpen,
  isTimelineVisible,
  lines,
  link,
  onChange,
  onSubmit,
  values,
  footerProps,
  onGoogleClick,
  sidebarContent,
  progress,
  whiteLabel: { partners, style },
}) => {
  const navbarRef = useRef()
  const { width } = useResize(navbarRef)

  const fieldErrorsTitles = fieldErrors.filter(({ type }) => type === 'field').map(({ data }) => data.title)

  const { showGoogle, ...navProps } = footerProps || {}

  const googleBtnComponent = showGoogle && (
    <S.GoogleConnect onClick={onGoogleClick}>{width < 453 ? 'Continuer' : 'Continuer avec Google'}</S.GoogleConnect>
  )

  const { label, isShown: isConsentShown, ...consentCheckboxProps } = consentProps

  return (
    <LayoutStickyFooter>
      <Grid>
        <Responsive min="laptop">
          <S.Sidebar>
            <S.SidebarContent>{sidebarContent}</S.SidebarContent>
            {isDeliveryVisible && <S.Delivery />}
          </S.Sidebar>
        </Responsive>
        <S.Main>
          {isTimelineVisible && (
            <Responsive max="laptop">
              <S.ProgressBar onClick={onToggleTimeline} progress={progress} />
              <Dialog isOpen={isTimelineOpen} variant="neutral-1">
                <S.DialogContainer>
                  <S.DialogClose>
                    <Times onClick={onToggleTimeline} />
                  </S.DialogClose>
                  {sidebarContent}
                </S.DialogContainer>
                <S.DialogFooter>
                  <S.ButtonLogin href="/login" label="Se connecter" picto={UserCircle} />
                </S.DialogFooter>
              </Dialog>
            </Responsive>
          )}
          <S.TitleContainer>
            <S.Title>{isSignedUp ? 'Complétez vos informations' : 'Votre espace personnel Pretto'}</S.Title>
            {partners ? (
              <S.Partners logo={partners.logo} style={style}>
                {partners.content}
              </S.Partners>
            ) : (
              bubbleDescription && <S.Bubble>{bubbleDescription}</S.Bubble>
            )}
          </S.TitleContainer>
          {lines.map((line, index) => {
            switch (line.type) {
              case 'sentence':
                return <S.Sentence key={index}>{renderSentence(line, onChange, values, fieldErrors)}</S.Sentence>

              default:
                return null
            }
          })}
          {fieldErrorsTitles.length > 0 && (
            <S.FieldErrors>
              Oops,{' '}
              {[
                ...(fieldErrorsTitles.length > 1 ? [fieldErrorsTitles.slice(0, -1).join(', ')] : []),
                fieldErrorsTitles.slice(-1),
              ].join(' et ')}{' '}
              ne semble{fieldErrorsTitles.length > 1 ? 'nt' : ''} pas valide{fieldErrorsTitles.length > 1 ? 's' : ''}.
              Veuillez vérifier votre saisie.
            </S.FieldErrors>
          )}
          {error && (
            <S.Error>
              <S.Warning />
              {getAuthErrorMessage(error, link)}
            </S.Error>
          )}
          {isConsentShown && (
            <S.Consent>
              <S.Divider />
              <S.CheckBoxContainer>
                <S.AgreementCheckbox {...consentCheckboxProps} />
                <S.Label htmlFor={consentCheckboxProps.id}>{label}</S.Label>
              </S.CheckBoxContainer>
            </S.Consent>
          )}
          {disclaimer && <S.Disclaimer>{disclaimer}</S.Disclaimer>}
          {isNextVisible && <S.NextButton onClick={onSubmit}>Continuer</S.NextButton>}
          {isDeliveryVisible && (
            <Responsive max="tablet">
              <S.DeliveryBottom />
            </Responsive>
          )}
        </S.Main>
      </Grid>

      {!!footerProps && (
        <PreviousNextNavBar ref={navbarRef} btnNextComponent={googleBtnComponent} {...navProps}></PreviousNextNavBar>
      )}
    </LayoutStickyFooter>
  )
}

SubscribeSentencesPage.propTypes = {
  bubbleDescription: PropTypes.element,
  consentProps: PropTypes.shape({
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    isError: PropTypes.bool,
    isConsentShown: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
  }),
  disclaimer: PropTypes.string,
  error: PropTypes.string,
  fieldErrors: PropTypes.array,
  footerProps: PropTypes.object,
  isSuggestionText: PropTypes.bool,
  isDeliveryVisible: PropTypes.bool,
  isNextVisible: PropTypes.bool,
  isSignedUp: PropTypes.bool,
  isTimelineOpen: PropTypes.bool,
  isTimelineVisible: PropTypes.bool,
  lines: PropTypes.array,
  link: PropTypes.element,
  onChange: PropTypes.func,
  onGoogleClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onToggleTimeline: PropTypes.func,
  progress: PropTypes.number,
  sidebarContent: PropTypes.element,
  values: PropTypes.object,
  whiteLabel: PropTypes.shape({
    partners: PropTypes.shape({
      content: PropTypes.string,
      logo: PropTypes.string,
    }),
    style: PropTypes.string,
  }),
}

export default SubscribeSentencesPage
