import styled from 'styled-components'

import { Baseline } from './Baseline'
import { Favicon } from './Favicon'
import { Icon } from './Icon'

export interface LogoPrettoProps {
  type: 'baseline' | 'icon' | 'favicon'
}

const COMPONENT = {
  baseline: Baseline,
  favicon: Favicon,
  icon: Icon,
}

export const LogoPretto: React.FC<LogoPrettoProps> = ({ type, ...props }) => <Container {...props} $type={type} />

interface ContainerProps {
  $type: LogoPrettoProps['type']
}

const Container = styled.svg.attrs(({ $type }: ContainerProps) => ({
  as: COMPONENT[$type],
}))<ContainerProps>`
  display: block;
  fill: ${({ theme }) => theme.colors.neutral1};
  max-width: 100%;
`
