import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Card from '@pretto/bricks/components/cards/Card'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import { EBOOK_URL } from '@pretto/config-front/constants'

import PropTypes from 'prop-types'

import * as S from './styles'

const CONFIG = {
  faq: {
    content: 'Tout comprendre sur le fonctionnement de Pretto. On répond à vos questions les plus courantes.',
    cta: 'Consulter',
    path: 'https://faq.pretto.fr/',
    title: 'FAQ',
  },
  guide: {
    content: 'Soyez prêt pour votre premier achat immobilier. Tous nos conseils sont réunis dans un ebook gratuit !',
    cta: 'Télécharger',
    path: EBOOK_URL,
    title: 'Le Guide de l’acheteur',
  },
}

const CapacityMarketingCard = ({ onClick, slug }) => {
  const { content, cta, path, title } = CONFIG[slug]
  return (
    <Card format="line" variant="neutral-1-20">
      <S.CapacityMarketingCard slug={slug}>
        <S.Decoration slug={slug} />
        <S.Content>
          <SubHeading>{title}</SubHeading>
          <S.Text>
            <Text size="small">{content}</Text>
          </S.Text>
          <ButtonInline isSecondary href={path} onClick={onClick}>
            {cta}
          </ButtonInline>
        </S.Content>
      </S.CapacityMarketingCard>
    </Card>
  )
}

CapacityMarketingCard.propTypes = {
  /** Function triggered button is clicked. Useful for tracking or other extra click action. */
  onClick: PropTypes.func,
  /** Slug of card, used to set its content. */
  slug: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
}

export default CapacityMarketingCard
