import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'

import { mapMortgagor } from './mortgagor'

export const mapMortgagors: ComposableMapper = ({ context, payload }) => {
  const updatedContext = structuredClone(context)

  if (payload.profile?.mortgagors?.length) {
    updatedContext.mortgagors = payload.profile?.mortgagors.map(mortgagor => mapMortgagor(mortgagor)) || []
  }

  return {
    context: updatedContext,
    payload,
  }
}
