import { validateEmail } from '@pretto/bricks/core/utility/form/validateEmail'

import { SentenceFieldSimple } from '@pretto/zen/atoms/sentenceFields/SentenceFieldSimple/SentenceFieldSimple'
import { TextField } from '@pretto/zen/atoms/textFields/TextField/TextField'
import { TextFieldDefault } from '@pretto/zen/reveal/atoms/textfields/TextFieldDefault/TextFieldDefault'

import { t } from '@pretto/app/src/lib/i18n'

import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { suggestEmail } from './suggestEmail'
import { UseField, useFieldController } from './useFieldController'
import type { Validator } from './validate'

const embeddedValidators: Validator[] = [
  value => ({
    condition: !validateEmail(value),
    isDebounced: true,
    props: {
      message: t('form.invalidEmail'),
      messageKey: 'invalid_email',
      state: 'error',
    },
  }),
]

export const useEmailField: UseField = ({
  defaultValue = '',
  inputProps,
  type = 'block',
  validators = [],
  ...props
}) => {
  const [value, setValue] = useState(defaultValue)

  const { stateProps, ...fieldControls } = useFieldController(value, inputProps, [
    ...validators,
    ...embeddedValidators,

    value => {
      const suggestion = suggestEmail(value)

      const handleCorrect = () => {
        if (!suggestion) {
          return
        }

        setValue(suggestion)
      }

      return {
        condition: suggestion !== null,
        isDebounced: true,
        props: {
          message: (
            <Trans
              i18nKey="form.rectifyEmail"
              values={{ suggestion }}
              components={{ CorrectButton: <button onClick={handleCorrect} type="button" /> }}
            />
          ),
          messageKey: 'email_suggestion',
          state: 'warning',
        },
      }
    },
  ])

  const handleChange = (value: string) => {
    setValue(value)
  }

  const fieldProps = {
    ...props,
    ...stateProps,
    inputProps: {
      ...inputProps,
      // format must be force to undefined to avoid type error, because it can only exist for type date
      format: undefined,
      type: 'email',
    },
    onChange: handleChange,
    value,
  }

  const component = useMemo(() => {
    switch (type) {
      case 'block_deprecated':
        return <TextField {...fieldProps} />

      case 'inline':
        return <SentenceFieldSimple {...fieldProps} />

      case 'block':
      default:
        return <TextFieldDefault {...fieldProps} />
    }
  }, [fieldProps])

  return { ...fieldControls, component, setValue, value }
}
