import temporal from '@pretto/bricks/core/utility/temporal'

import { DateField } from '@pretto/zen/atoms/textFields/DateField/DateField'

import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

const TODAY = temporal()
const GAP = 200
const MIN_DATE = TODAY.subtract(GAP, 'y')
const MAX_DATE = TODAY.add(GAP, 'y')
const DEBOUNCE_RATE = 500

export const BlocDateField = ({ name, value, error, onUpdateValue }) => {
  const [debouncedValue] = useDebounce(value, DEBOUNCE_RATE)

  useEffect(() => {
    const filledDate = temporal(debouncedValue)
    const invalidDate = filledDate.isBefore(MIN_DATE) || filledDate.isAfter(MAX_DATE)

    onUpdateValue(name, debouncedValue, invalidDate)
  }, [debouncedValue])

  const handleChange = value => {
    onUpdateValue(name, value)
  }

  return (
    <DateField
      onChange={handleChange}
      value={value}
      state={error ? 'error' : 'default'}
      message={error ? 'La date que vous avez saisie n’est pas correcte' : null}
    />
  )
}

BlocDateField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.boolean,
  onUpdateValue: PropTypes.func.isRequired,
}
