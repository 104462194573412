import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
`
export const Emoji = styled.div`
  margin-right: ${g(3)};
`
export const Points = styled.div`
  margin-top: ${g(5)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const Elem = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: initial;
    flex: 1 1 33.33%;
    margin-right: ${g(5)};
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`
export const Check = styled.div`
  display: flex;
  flex: 0 0 ${g(4)};
  align-items: center;
  justify-content: center;
  margin-right: ${g(2)};
  margin-bottom: ${g(2)};
  border-radius: ${g(4)};
  background: ${({ theme }) => theme.legacy.colors.accent1.default};
  width: ${g(4)};
  height: ${g(4)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 0 0 ${g(5)};
    border-radius: ${g(5)};
    width: ${g(5)};
    height: ${g(5)};
  }
`
