import isEqual from 'lodash/isEqual'

export const diffValues = (oldValues, values) =>
  Object.keys(values).reduce((previous, keyValue) => {
    const oldValue = oldValues[keyValue]
    const value = values[keyValue]

    if (isEqual(oldValue, value)) {
      return previous
    }

    return { ...previous, [keyValue]: value }
  }, {})
