import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Times } from '@pretto/picto'

import styled from 'styled-components'

interface ModalHeaderProps {
  onClose: () => void
  subtitle?: string
  title: string
}

export const ModalHeader = ({ onClose, subtitle, title }: ModalHeaderProps) => {
  return (
    <MainContainer>
      <TitleContainer>
        <Row>
          <Title>{title}</Title>
          <IconContainer onClick={onClose}>
            <Times />
          </IconContainer>
        </Row>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
      </TitleContainer>
    </MainContainer>
  )
}

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${g(4)} ${g(4)} ${g(1)} ${g(4)};
`

const TitleContainer = styled.div`
  flex-basis: 100%;
`

const Title = styled.h1`
  margin-bottom: ${g(1)};
  margin-right: ${g(7, 4)};
  ${typo.heading32};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading48};
    margin-right: 0px;
  }
`

const SubTitle = styled.h3`
  margin-bottom: ${g(1)};
  ${typo.bodyBook16};
`

const IconContainer = styled.div`
  cursor: pointer;
  margin-top: ${g(0)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(2)};
  }
`
