import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { Spinner } from '@pretto/zen/atoms/loaders/Spinner/Spinner'

import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'
import { BANKS_NUMBER } from '@pretto/app/src/config/reassurance'
import { FullLayout } from '@pretto/app/src/onboarding/components/Layout/FullLayout'
import { NavigationButtons } from '@pretto/app/src/onboarding/components/NavigationButtons/NavigationButtons'
import { Shape } from '@pretto/app/src/onboarding/components/Shape/Shape'
import { ShapeMask } from '@pretto/app/src/onboarding/components/ShapeMask/ShapeMask'
import { CheckHandwrite } from '@pretto/picto'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const TIPS = [
  [
    'Fins négociateurs',
    `Pour vous obtenir le meilleur taux, ils vont comparer plus de ${BANKS_NUMBER.toString()}+ banques !`,
  ],
  ['Payés au succès', 'On ne vous facturera que si nous avons mené votre projet à son terme.'],
  ['à votre écoute', 'Par téléphone, par email, par visio, votre expert est là pour vous !'],
]

export const Congratulations = ({
  advisorLabel = ADVISOR_LABELS.expert,
  isBookingAvailable,
  isLoading,
  isNextLoading,
  onNext,
  onPrevious,
}) => (
  <FullLayout type={isBookingAvailable ? 'tight' : 'loose'}>
    {isLoading ? (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    ) : (
      <>
        <HappyAvatarContainer>
          <HappyAvatar />
          <ShapeBackground />
          <Mask />
        </HappyAvatarContainer>

        <Title>Félicitations !</Title>
        <Message>
          {isBookingAvailable ? (
            <>
              Votre espace client est fin prêt ! Il est maintenant temps d’échanger de vive voix avec votre{' '}
              {advisorLabel}. Il vous présentera <strong>les prochaines étapes</strong> et pourra répondre à toutes vos
              questions !
            </>
          ) : (
            <>
              Votre espace client est fin prêt !
              <br />
              Il est maintenant temps de <strong>constituer votre dossier</strong> !
            </>
          )}
        </Message>
        {isBookingAvailable && (
          <>
            <TipsTitle>
              Chez Pretto, <strong>nos experts</strong> sont :
            </TipsTitle>

            <Bullets>
              {TIPS.map(([label, text]) => (
                <Tip key={label}>
                  <Tick>
                    <CheckHandwrite />
                  </Tick>
                  <TipLabel>{label}</TipLabel>
                  <TipText>{text}</TipText>
                </Tip>
              ))}
            </Bullets>
          </>
        )}
      </>
    )}

    <Footer onPrevious={onPrevious} onNext={onNext} isNextActive isNextLoading={isNextLoading} />
  </FullLayout>
)

Congratulations.propTypes = {
  advisorLabel: PropTypes.string,
  isBookingAvailable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNextLoading: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
}
const HappyAvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`
const MASK_ID = 'onboarding-congratulations-mask'
const HappyAvatar = styled(Illustration).attrs({
  path: 'v1652183175/zen/static-assets/illustrations/660x660/ExpressionGirl-excited.svg',
  width: ng(12),
  height: ng(12),
})`
  width: ${g(12)};
  height: ${g(12)};
  clip-path: url(${`#${MASK_ID}`});
  z-index: 1;
`
const ShapeBackground = styled(Shape)`
  width: ${g(12)};
  height: ${g(12)};
  fill: ${({ theme }) => theme.colors.primary4};
  position: absolute;
  bottom: 4px;
  transform: translateX(-2px);
`
const Mask = styled(ShapeMask).attrs({ id: MASK_ID })`
  width: ${g(12)};
  height: ${g(12)};
  position: absolute;
`
const Title = styled.h1`
  ${typo.heading32};
  margin-top: ${g(3)};
`
const Message = styled.p`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(2)};
    margin-right: ${g(2)};
  }
`
const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
`
const Bullets = styled.ul`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: space-evenly;
    margin-top: ${g(3)};
  }
`
const Tip = styled.li`
  max-width: ${g(33)};
  margin: ${g(3)} auto 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(2)};
  }
`
const Tick = styled.div`
  width: ${g(4)};
  height: ${g(4)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary4};
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`
const TipsTitle = styled.div`
  margin-top: ${g(3)};
`
const TipLabel = styled.div`
  ${typo.link12};
  margin-top: ${g(2)};
`
const TipText = styled.div`
  ${typo.caption12};
  margin-top: ${g(1)};
`
export const Footer = styled(NavigationButtons)`
  ${column([1, 6])};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 10])};
  }
`
