import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'

import styled from 'styled-components'

export interface FileSectionTitleProps {
  subtitle?: string
  title: string
}

export const FileSectionTitle = ({ subtitle, title, ...props }: FileSectionTitleProps) => (
  <>
    <Container {...props}>
      <Title>{title}</Title>
      {subtitle && <p>{subtitle}</p>}
    </Container>

    <Divider />
  </>
)

/* eslint-disable no-magic-numbers */
const Container = styled.div`
  padding: ${g(1)} ${g(2)};
`

const Title = styled.div`
  ${typo.heading16};
`
