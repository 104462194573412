import OverviewPageComponent from '@pretto/bricks/app/project/pages/OverviewPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { OVERVIEW } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'
import * as GRAPH from '@pretto/app/src/overview/config/graph'
import { LOAN_TITLE, PROJECT_TITLE } from '@pretto/app/src/overview/config/labels'
import * as ROWS from '@pretto/app/src/overview/config/rows'
import { formatGraph } from '@pretto/app/src/overview/lib/formatGraph'
import { formatLoanRows } from '@pretto/app/src/overview/lib/formatLoanRows'
import { formatProjectRows } from '@pretto/app/src/overview/lib/formatProjectRows'
import { groupRows } from '@pretto/app/src/overview/lib/groupRows'

import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

export const OverviewPage: React.FC = () => {
  const { onOpen } = useAdvisor()

  const history = useHistory()

  const { data, loading } = useQuery(OVERVIEW)

  const trackAction = useTracking()

  const { isProjectEditable, user } = useUser()

  const handleCompare = () => {
    trackAction('OVERVIEW_EDIT_ICON_CLICKED', { overview_edit_icon_origin: 'button_compare' })
  }

  const handleEdit = (slug: string) => {
    trackAction('OVERVIEW_EDIT_ICON_CLICKED', { overview_edit_icon_origin: `row_${slug}` })

    history.push('/simulation')
  }

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  const projectKind = user.projectKind === 'purchase' ? 'purchase' : 'renegotiation'

  const defaultProps = {
    creditCapabilityProps: {
      compareHref: '/simulation',
      onCompareClick: handleCompare,
    },
    goodUsage: data.project.good.usage,
    isProjectEditable,
    loanTitle: LOAN_TITLE[projectKind],
    onContactClick: onOpen,
    title: PROJECT_TITLE[projectKind],
  }

  if (data.best_result === null) {
    const props = {
      ...defaultProps,
      graphProps: [],
      hasSimulation: false,
      loanValues: [],
      projectValues: [],
    }

    return (
      <>
        <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
        <OverviewPageComponent {...props} />
      </>
    )
  }

  const hasContribution = get(data, 'best_result.financing.contribution')

  const loanRows = formatLoanRows(data, handleEdit)

  const projectRows = formatProjectRows(
    projectKind === 'purchase' ? ROWS.PURCHASE : ROWS.RENEGOTIATION,
    data,
    hasContribution,
    handleEdit
  )

  const graph = formatGraph(
    projectKind === 'purchase' ? GRAPH.PURCHASE : GRAPH.RENEGOTIATION,
    projectRows,
    hasContribution
  )

  const props = {
    ...defaultProps,
    graphProps: { values: graph },
    hasSimulation: true,
    loanValues: loanRows,
    projectValues: groupRows(projectRows),
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <OverviewPageComponent {...props} />
    </>
  )
}
