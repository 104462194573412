import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`
export const LogoContainer = styled.div`
  text-align: center;
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 35%;
    padding: 0 ${g(3)} 0 0;
  }
`
export const Logo = styled(BaseImage).attrs({ alt: 'logo partner' })`
  max-width: 100%;
`
export const Text = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 65%;
  }
`
