import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const SectionWidget = ({ links }) => (
  <S.CardContainer>
    <S.Container>
      <S.LeftChoices>
        <S.Title>Votre projet</S.Title>
        <S.SubTitle>Que souhaitez-vous faire ?</S.SubTitle>
        <S.Buttons>
          {links.map(({ href, picto, label }, i) => (
            <S.CTA key={i} href={href} picto={picto} label={label} backgroundVariant="neutral4" />
          ))}
        </S.Buttons>
      </S.LeftChoices>
      <Responsive min="tablet">
        <IllustrationDeprecated name="man_bath_window" />
      </Responsive>
      <Responsive max="tablet">
        <S.MobileImg name="man_bath" />
      </Responsive>
    </S.Container>
  </S.CardContainer>
)

SectionWidget.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.string.isRequired,
      purchase: PropTypes.string.isRequired,
      renegotiation: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default SectionWidget
