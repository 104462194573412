import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'
import { FullLayout } from '@pretto/app/src/onboarding/components/Layout/FullLayout'

import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'

import * as S from './Introduction.styles'

export const Introduction = ({
  avatarSrc,
  advisorLabel = ADVISOR_LABELS.expert,
  advisorName,
  bio,
  name,
  onCtaClick,
}) => {
  const [isBioOpen, setIsBioOpen] = useState(false)

  const handleToggleBio = () => {
    setIsBioOpen(previousIsBioOpen => !previousIsBioOpen)
  }

  const avatarPath = `w_192,h_192${avatarSrc || '/v1637684805/zen/static-assets/illustrations/660x660/headBulbe.svg'}`

  const { greetings, description } = useMemo(
    () => ({
      description: (
        <>
          <S.Description>
            Je suis <strong>{advisorName}</strong>, votre {advisorLabel} Pretto. Je vais vous accompagner tout au long
            de votre projet immobilier !
          </S.Description>
          <S.Description>
            Construisons ensemble votre <strong>Espace client</strong> en quelques clics.
          </S.Description>
        </>
      ),
      greetings: 'Enchanté',
    }),
    [(advisorLabel, advisorName)]
  )

  return (
    <FullLayout type={!bio ? 'loose' : 'tight'}>
      <>
        <S.Picture path={avatarPath} />

        <S.Greetings>
          {greetings} {name} !
        </S.Greetings>

        {description}

        {bio && (
          <>
            <S.ToggleDetails onClick={handleToggleBio}>
              <S.DetailsIcon $isOpen={isBioOpen} />À propos de mon {advisorLabel}
            </S.ToggleDetails>

            <CollapsibleRow appear in={isBioOpen}>
              <S.Bio>{bio}</S.Bio>
            </CollapsibleRow>
          </>
        )}
      </>

      <S.Footer>
        <S.Cta onClick={onCtaClick}>C’est parti !</S.Cta>
      </S.Footer>
    </FullLayout>
  )
}

Introduction.propTypes = {
  advisorName: PropTypes.string,
  advisorLabel: PropTypes.string,
  avatarSrc: PropTypes.string,
  bio: PropTypes.string,
  isLowPotential: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onCtaClick: PropTypes.func.isRequired,
}
