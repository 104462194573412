import SelectField from '@pretto/bricks/components/form/SelectField'
import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  color: ${({ theme }) => theme.colors.error1};

  ${typo.caption12};
  margin-top: ${g(1)};

  a,
  button {
    color: inherit;
    cursor: pointer;
    font: inherit;
    text-decoration: underline;
  }
`

const useSelectField = (config = {}) => {
  const [value, setValue] = useState(config.defaultValue)

  useEffect(() => {
    setValue(config.defaultValue)
  }, [config.defaultValue])

  const handleChange = ({ value }) => {
    setValue(value)
  }

  const props = {
    ...config,
    iconVariant: config.variant,
    onChange: handleChange,
    value,
    variant: config.variant,
  }
  const component = (
    <Container>
      <SelectField {...props} />
      {config.state === 'error' && <Message>{config.message}</Message>}
    </Container>
  )

  return { component, setValue: handleChange, value }
}

export default useSelectField
