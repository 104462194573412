import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import * as S from './styles'

const HOVER_ZDEPTH = 2

const ListOption = ({ children, onClick, isSelected, value }) => {
  const [isHover, setIsHover] = useState(false)

  const handleHover = isHover => setIsHover(isHover)

  return (
    <S.ListOption onClick={onClick.bind(null, value)}>
      <Card
        format={['line', 'large-border']}
        onHover={handleHover}
        variant={isHover || isSelected ? 'primary-1' : 'neutral-1-20'}
        zDepth={isHover ? HOVER_ZDEPTH : 0}
      >
        <Text variant={isSelected ? 'primary-1' : 'inherit'}>
          <Content size="small">
            <S.Content>
              {children}

              {isSelected && (
                <S.Check>
                  <Icon name="check-2" variant="primary-1" />
                </S.Check>
              )}
            </S.Content>
          </Content>
        </Text>
      </Card>
    </S.ListOption>
  )
}

ListOption.defaultProps = {
  isSelected: false,
  onClick: () => {},
}

ListOption.propTypes = {
  /** Option descendant. */
  children: PropTypes.node,
  /** Defines whether or not the option should be checked. */
  isSelected: PropTypes.bool,
  /** Triggered when the option is pressed. */
  onClick: PropTypes.func,
  /** Value of the current option. */
  value: PropTypes.any.isRequired,
}

export default memo(ListOption)
