import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { Form as FormComponent, FormProps } from '@pretto/app/src/SignUp/components/Form/Form'
import { FAQCard } from '@pretto/app/src/components/FAQCard/FAQCard'
import { GuideCard } from '@pretto/app/src/components/GuideCard/GuideCard'
import { t } from '@pretto/app/src/lib/i18n'
import { CheckBold } from '@pretto/picto'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

interface NonFinancingSignupProps {
  formProps: FormProps
  onDownloadClick?: () => void
  onSeeClick?: () => void
}

export const NonFinancingSignup: React.FC<NonFinancingSignupProps> = ({ formProps, onDownloadClick, onSeeClick }) => (
  <ThemeProvider designSystem="reveal">
    <View>
      <Content>
        <ContentHeader>
          <Title>{t('nonFinancingSignupPage.title')}</Title>
          <Description>
            <Trans i18nKey="nonFinancingSignupPage.message" components={{ Strong: <strong /> }} />
          </Description>
          <Promises>
            {[t('nonFinancingSignupPage.free'), t('nonFinancingSignupPage.noCommitment')].map(promise => (
              <PromiseItem key={promise}>
                <PromiseBullet>
                  <PromiseCheck />
                </PromiseBullet>

                {promise}
              </PromiseItem>
            ))}
          </Promises>
        </ContentHeader>
      </Content>

      <Form {...formProps} title={t('nonFinancingSignupPage.bookAppointment')} />

      <Footer>
        <FAQCard onSeeClick={onSeeClick} />
        <GuideCard onDownloadClick={onDownloadClick} />
      </Footer>
    </View>
  </ThemeProvider>
)

const Content = styled.main`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
  }
`

const ContentHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`

const Footer = styled.footer`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    gap: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    display: grid;
    gap: ${g(3)};
    grid-template-columns: repeat(2, 1fr);
  }
`

const Form = styled(FormComponent)`
  ${column([2, 4])};
  border-radius: ${g(1)};
  box-shadow: 0 ${g(1)} ${g(4)} 0 ${({ theme }) => addAlpha(theme.colors.neutral1, 0.08)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([6, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([8, 5])};
  }
`

const Promises = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${g(3)};
`

const PromiseBullet = styled.span`
  align-items: center;
  background-color: ${({ theme }) => addAlpha(theme.colors.neutral4, 0.6)};
  border-radius: ${g(2)};
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  height: ${g(4)};
  width: ${g(4)};
`

const PromiseCheck = styled(CheckBold)`
  width: ${g(1, 4)};
`

const PromiseItem = styled.li`
  ${({ theme }) => theme.typos.body4};
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  gap: ${g(1)};
  white-space: nowrap;
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const View = styled.div`
  ${grid()};
  padding-bottom: ${g(4)};
  row-gap: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(4)};
    padding-top: ${g(1)};
    row-gap: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(5)};
    row-gap: ${g(12)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-bottom: ${g(7)};
    row-gap: ${g(13)};
  }
`
