import BookingAppointmentPageComponent from '@pretto/bricks/app/booking/pages/BookingAppointmentPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import BookingWidget from '@pretto/app/src/Booking/Containers/BookingWidget'
import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { BOOKING } from '@pretto/app/src/apollo'
import duration from '@pretto/app/src/lib/duration'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { getBookingDuration } from '../../lib/getBookingDuration'

const BookingAppointmentPage = ({ history }) => {
  const { notifyLegacy } = useNotifications()

  const { hasFeatureAccess } = useUser()

  const { data, loading } = useQuery(BOOKING)
  const trackAction = useTracking()

  if (hasFeatureAccess(['NO_APPOINTMENT'])) {
    history.push('/')
  }

  const handleBooked = (duration, startTime, appointmentKind, availabilities) => {
    const slotsCount = availabilities.map(({ count, daysSinceToday }) => ({ count, daysSinceToday }))

    trackAction('BOOKING_APPOINTMENT_BOOKED', {
      booking_appointment_duration: duration,
      booking_appointment_kind: appointmentKind,
      booking_appointment_rescheduled: data.next_booking !== null,
      booking_appointment_slots_count: slotsCount,
    })

    history.push('/')

    notifyLegacy(
      `Rendez-vous ${!data.next_booking ? 'confirmé' : 'déplacé'}`,
      !data.next_booking
        ? 'Votre rendez-vous est confirmé. Vous allez recevoir un email de confirmation. Pensez à le noter dans votre agenda !'
        : 'Votre rendez-vous est déplacé. Vous allez recevoir un mail pour vous confirmer ce nouveau créneau.',

      { delay: 5000 }
    )
  }

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <BookingAppointmentPageComponent
        duration={duration(getBookingDuration(data.deal.appointmentKind).meetDuration).toString()}
        isRescheduling={data.next_booking !== null}
      >
        <BookingWidget onBooked={handleBooked} />
      </BookingAppointmentPageComponent>
    </>
  )
}

BookingAppointmentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default BookingAppointmentPage
