import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled, { css } from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const MainValue = styled(BaseTypo).attrs({ typo: 'heading3' })`
  color: ${({ theme }) => theme.colors.neutral1};
  margin-bottom: ${g(1.75)};
  margin-right: ${g(4)};
`

export const Infos = styled.div`
  margin-bottom: ${g(3)};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Info = styled.div`
  display: flex;
  align-items: baseline;
`

export const Label = styled(BaseTypo).attrs({ typo: 'caption' })`
  margin-right: ${g(0.5)};
`

export const InfoValue = styled(BaseTypo).attrs({ isNumber: true })`
  ${({ $color, theme }) =>
    $color &&
    css`
      color: ${theme.colors[$color]};
    `}
`
