import { g } from '@pretto/bricks/components/layout'

import { ButtonOutline } from '@pretto/zen/atoms/buttons/ButtonOutline/ButtonOutline'

import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const getState = ({ noSelection, isSelected, value }) => {
  if (noSelection) return 'default'

  if (isSelected === value) return 'active'

  return 'inactive'
}

const OPTIONS = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false },
]

export const Boolean = ({ isSelected, onSelect }) => {
  const noSelection = isNil(isSelected)

  return (
    <Container>
      {OPTIONS.map(({ label, value }) => (
        <Option key={label} state={getState({ noSelection, isSelected, value })} onClick={onSelect.bind(null, value)}>
          {label}
        </Option>
      ))}
    </Container>
  )
}

Boolean.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${g(2)};
`

const Option = styled(ButtonOutline)`
  cursor: pointer;
  padding: ${g(2.5)} ${g(3)};
  text-align: center;
`
