import type { ComposableMapper, EcoProject } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import { isEligibleToEcoPtz } from '@pretto/app/src/Sentences/v2/lib/validators/context'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { ProjectType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { Dpe, Usage as PayloadUsage, PropertyKind } from '@pretto/app/src/types/gateway/enums'
import type { Localisation } from '@pretto/app/src/types/gateway/schema'

const mapPropertyKind = (projectType: ProjectType | undefined | null): PropertyKind | null => {
  switch (projectType) {
    case ProjectType.Construction:
      return PropertyKind.Construction
    case ProjectType.LandConstruction:
      return PropertyKind.LandConstruction
    case ProjectType.New:
      return PropertyKind.New
    case ProjectType.Old:
      return PropertyKind.Old
    case ProjectType.VEFA:
      return PropertyKind.Vefa
    default:
      return null
  }
}

const mapUsage = (usage: Usage | undefined | null): PayloadUsage | null => {
  switch (usage) {
    case Usage.Primary:
      return PayloadUsage.PrimaryResidence
    case Usage.Rental:
      return PayloadUsage.RentalInvestment
    case Usage.Secondary:
      return PayloadUsage.SecondaryResidence
    default:
      return null
  }
}

const mapDpe = (dpe: Dpe | undefined): Dpe | null => {
  switch (dpe) {
    case Dpe.A:
    case Dpe.B:
    case Dpe.C:
    case Dpe.D:
    case Dpe.E:
    case Dpe.F:
    case Dpe.G:
    case Dpe.Na:
      return dpe

    default:
      return null
  }
}

const mapLocalisation = (context: ProjectContext): Localisation => {
  const { propertyCity, propertyCountry, propertyZipcode } = context
  return {
    city: propertyCity,
    country: propertyCountry || 'fr',
    zipcode: propertyZipcode,
  }
}

export const mapProperty: ComposableMapper<EcoProject> = ({ context, payload }) => {
  const updatedPayload = structuredClone(payload)

  updatedPayload.good = {
    dpe: mapDpe(context.dpe),
    property_kind: mapPropertyKind(context.projectType),
    usage: mapUsage(context.usage),
    localisation: mapLocalisation(context),
  }

  updatedPayload.eco_works =
    isEligibleToEcoPtz(context) && context.hasEcoPtz === true
      ? {
          global_renovation: false,
          insulation_roofs: true,
        }
      : null

  return {
    context,
    payload: updatedPayload,
  }
}
