// deprecated
// use instead: @pretto/app/src/lib/form/useFieldController
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { t } from '@pretto/app/src/lib/i18n'

import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

const DEBOUNCE_RATE = 1000

const validatorsToStateProps = (validators, value, debouncedValue, ...args) => {
  const allValidators = [
    ...validators,
    (value, isCheckRequested, { required = false }) => ({
      condition: isCheckRequested && required && (value?.trim() ?? '') === '',
      message: t('form.mandatoryField'),
      state: 'error',
    }),
  ]

  const computedValidators = allValidators.map(validator => funcToItem(validator, value, ...args))

  const prioritizedValidators = [
    ...computedValidators.filter(({ state }) => state === 'error'),
    ...computedValidators.filter(({ state }) => state === 'warning'),
  ]

  const stateProps =
    prioritizedValidators.find(({ condition = false, isDebounced = false }) =>
      isDebounced ? debouncedValue && condition : condition
    ) ?? {}

  const { state } = prioritizedValidators.find(({ condition = false }) => condition) ?? {}

  return { isValid: state !== 'error', stateProps }
}

const useFieldController = (value, { inputProps = {}, validators = [] } = {}) => {
  const [debouncedValue] = useDebounce(value, DEBOUNCE_RATE)
  const [reason, setReason] = useState(null)
  const [isCheckRequested, setIsCheckRequested] = useState(false)

  useEffect(() => {
    setReason(null)
  }, [value])
  const invalidate = reason => {
    setReason(reason)
  }
  const check = () => {
    setIsCheckRequested(true)

    return validatorsToStateProps(validators, value, debouncedValue, true, inputProps).isValid
  }

  const { isValid, stateProps } = validatorsToStateProps(
    validators,
    value,
    debouncedValue,
    isCheckRequested,
    inputProps,
    reason
  )

  return {
    check,
    error: !isValid,
    invalidate,
    stateProps,
  }
}

export default useFieldController
