import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Image = styled.img`
  display: block;
  width: 100%;
`

export const SvgIcon = styled.div`
  width: ${g(2.5)};
  height: ${g(2.5)};
`
