/* eslint-disable max-lines */
import { gql } from '@apollo/client'

export const Location = gql`
  fragment Location on Localisation {
    city
    country
    street
    zipcode
  }
`

const IncomeDetails = gql`
  fragment IncomeDetails on Income {
    amount
    period
    taxes
  }
`

export const Profile = gql`
  fragment Profile on Project {
    id
    profile {
      first_time_buyer
      children
      fiscal_income_nm2
      live_together
      mortgagors {
        id
        rent
        child_support
        birthdate
        expatriate_status
        salary {
          ...IncomeDetails
        }
        bonus {
          ...IncomeDetails
        }
        contract
        housing
        age
        address {
          ...Location
        }
        incomes {
          id
          amount
          kind
          property_id
          name
        }
        job {
          start_date
        }
      }
      credits {
        bridge
        end_date
        id
        kind
        payment
        property_id
        title
      }
      savings {
        amount
        id
        kind
        owner
      }
    }
  }
  ${Location}
  ${IncomeDetails}
`
const Good = gql`
  fragment Good on Project {
    good {
      dpe
      localisation {
        ...Location
      }
      property_kind
      usage
    }
  }
  ${Location}
`

const Purchase = gql`
  fragment Purchase on Project {
    purchase {
      maturity
      agreement_sign_date
      build_price
      expected_rental_income
      land_price
      property_price
      works_price
      bridge {
        end_date
        estimated_value
        rate
        remaining_principal
      }
      specific_amounts {
        amount
        label
      }
    }
  }
`

const Renegotiation = gql`
  fragment Renegotiation on Project {
    renegotiation {
      bank
      end_date
      estimated_value
      remaining_principal
      rate
    }
  }
`

export const Project = {
  fragments: {
    profile: Profile,
    good: Good,
    purchase: Purchase,
    renegotiation: Renegotiation,
  },
}

const simulationResult = gql`
  fragment SimulationResult on Result {
    bank {
      allowFlexiblePayments
      allowLoanTransfer
      criterias {
        scoringIra
        scoringInsurance
        scoringModularity
        scoringReportDeadline
        scoringAverageResponseTime
        scoringRse
        scoringDigitization
      }
      hasPrepaymentCharge
      id
      kind
    }
    financing {
      applicationFees
      bridge {
        remaining
        value
      }
      contributionRate
      creditCosts
      monthlyDetailedWeightedIncomes {
        expected_rental_income
        other
        rental_income
        wages
      }
      guarantyFees
      penaltyFees
      interests
      loanAmount
      loanDuration
      mainLoansRate
      payment
      paymentAfterBridge
      ecoPtz {
        amount
      }
      ptz {
        amount
        duration
        franchise
      }
      remainingLifeAmount
      savings
      weightedIncomes {
        yearly
      }
    }
    flags {
      code
      severity
    }
    scoreIndebtednessRate {
      kind
      value
    }
  }
`

const optimizedResult = gql`
  fragment OptimizedResult on Result {
    financing {
      loanDuration
      mainLoansRate
      payment
    }
    project {
      contribution
    }
  }
`

const resultProject = gql`
  fragment ResultProject on Project {
    contribution
    facts {
      delegation_insurance_rate
      detailed_charges {
        child_support
        credits
        other_charges
        rent
      }
      insurance_rate
      max_indebtedness_rate {
        max
        min
      }
      min_remaining_life_amount
      notary_fees
      raw_incomes
      works_share
    }
    good {
      localisation {
        country
        zipcode
      }
      property_kind
      usage
    }
    profile {
      live_together
      children
      credits {
        kind
      }
      facts {
        max_age
        total_savings
      }
      mortgagors {
        address {
          city
          country
          street
          zipcode
        }
        age
        contract
        facts {
          min_seniority
          non_fundable_reason
          seniority
        }
        housing
      }
    }
    project_kind
    purchase {
      build_price
      furniture_price
      land_price
      property_price
      works_price
      maturity
    }
    request {
      duration
    }
    renegotiation {
      facts {
        remaining_duration
        remaining_interests
      }
      remaining_principal
      works_amount
    }
    solicited_banks {
      id
      rate
    }
  }
`

export const Financing = {
  fragments: {
    optimizedResult,
    resultProject,
    simulationResult,
  },
}
