import { searchArray } from '@pretto/app-core/lib/searchArray'

import { Banks } from '@pretto/app/src/onboarding/components/fields/Banks/Banks'
import BANKS from '@pretto/config/banks.json'

import PropTypes from 'prop-types'
import { useState } from 'react'

export const BlocBanks = ({ value, name, onUpdateValue }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const selectedBanks = value ?? []

  const filteredBanks = BANKS.filter(bank => !selectedBanks.includes(bank?.value))
  const selectedFormattedBanks = BANKS.filter(bank => selectedBanks.includes(bank?.value))
  const results = search === '' ? filteredBanks : searchArray(search, filteredBanks, 'label')

  const otherBank = BANKS.find(({ value }) => value === 'autre')
  const resultsWithOtherBank = selectedBanks.includes('autre')
    ? results
    : results.filter(bank => bank.value !== 'autre').concat(otherBank)

  const handleClear = () => {
    setSearch('')
  }
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleChangeValue = value => {
    const selection = [...selectedBanks, value]
    onUpdateValue(name, selection)
    setIsOpen(false)
  }
  const handleChangeSearch = value => {
    setSearch(value)
  }

  const handleRemoveSelected = key => {
    const selection = selectedBanks.filter(bank => bank !== key)

    onUpdateValue(name, selection)
  }

  const props = {
    onOpen: handleOpen,
    selectedBanks: selectedFormattedBanks,
    onRemove: handleRemoveSelected,
    isOpen,
    onClose: handleClose,
    onChangeSearch: handleChangeSearch,
    search,
    onClear: handleClear,
    banks: resultsWithOtherBank,
    onClickBank: handleChangeValue,
  }

  return <Banks {...props} />
}

BlocBanks.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
}
