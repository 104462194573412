import BANKS from '@pretto/config/banks.json'

export const getBankLogo = ({ slug, format = 'regular' } = {}) => {
  try {
    const bank = BANKS.find(({ value }) => value.includes(slug))

    return bank.logo[format]
  } catch (error) {
    if (slug) {
      throw new TypeError(`There is no picture for "${slug}" bank in getBankLogo()`)
    }

    throw new TypeError(`getBankLogo() doesn't have the expected parameters`)
  }
}
