import Responsive from '@pretto/bricks/components/utility/Responsive'

import { SwitchToggle } from '@pretto/zen/atoms/selectors/SwitchToggle/SwitchToggle'

import { Picto } from '@pretto/picto'

import { KeyboardEvent, MouseEvent } from 'react'

import * as S from './FolderCard.styles'

type ProgressType = {
  current: number
  target: number
}

export interface FolderCardProps {
  description: string
  href: string
  isActive?: boolean
  isSwitched?: boolean
  onToggle?: (title: string) => void
  picto: Picto
  progress: ProgressType
  title: string
}

export const FolderCard = ({
  description,
  href,
  isActive = true,
  isSwitched = false,
  onToggle,
  picto,
  progress,
  title,
  ...props
}: FolderCardProps) => {
  const handleToggle = (event: KeyboardEvent | MouseEvent) => {
    event.preventDefault()
    onToggle!(title)
  }

  const isCompleted = progress.target > 0 && progress.current === progress.target

  return (
    <S.MainContainer $isActive={isSwitched || isActive} href={href} {...props}>
      <S.HeaderContainer>
        <S.HeaderBodyContainer>
          <Responsive max={'tablet'}>
            <S.Row>
              <S.Picto $picto={picto} />
              <S.Title>{title}</S.Title>
            </S.Row>
          </Responsive>

          <Responsive min={'tablet'}>
            <S.SwitchToggleContainer>
              <S.Picto $picto={picto} />
              {!isActive && onToggle && (
                <SwitchToggle
                  id={title}
                  isToggled={isSwitched}
                  label={isSwitched ? 'Oui' : 'Non'}
                  onToggle={handleToggle}
                />
              )}
            </S.SwitchToggleContainer>
            <S.Title>{title}</S.Title>
          </Responsive>

          <S.Description>{description}</S.Description>
        </S.HeaderBodyContainer>

        <Responsive max={'tablet'}>
          {!isActive && (
            <>
              <SwitchToggle id={title} isToggled={isSwitched} onToggle={handleToggle} />
            </>
          )}
        </Responsive>
      </S.HeaderContainer>

      {(isActive || isSwitched) && (
        <S.Footer>
          <S.StyledStatus $isCompleted={isCompleted}>{isCompleted ? 'Complet' : 'Incomplet'}</S.StyledStatus>

          <S.ProgressContainer>
            <S.ProgressValue>{`${
              progress.target === 0 ? 0 : Math.floor((progress.current / progress.target) * 100)
            }%`}</S.ProgressValue>

            <S.StyledProgressLegacy
              format="rounded"
              max={progress.target}
              value={progress.current}
              variant="primary-1"
            />
          </S.ProgressContainer>
        </S.Footer>
      )}
    </S.MainContainer>
  )
}
