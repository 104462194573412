import styled from 'styled-components'

import { breakpoints, g } from '@pretto/bricks/components/layout'
import FormList from '@pretto/bricks/components/layout/FormList'

export const Description = styled.div`
  margin-bottom: ${g(4)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(6)};
  }
`

export const Helper = styled.div`
  margin-bottom: ${g(4)};
`

export const HelperButton = styled.div`
  margin-top: ${g(2)};
`

export const Section = styled.section`
  position: relative;
`

export const SectionTitle = styled.div`
  margin-bottom: ${({ hasShortSpacing }) => g(hasShortSpacing ? 2 : 4)};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${({ hasShortSpacing }) => g(hasShortSpacing ? 2 : 6)};
  }
`

export const StyledFormList = styled(FormList)`
  @media only screen and (max-width: ${breakpoints.tablet}) {
    input {
      width: 100%;
    }
  }
`
