import { PROGRESS, PROJECT_KIND, STATUS } from './config'

export const getStatus = ({
  isProjectAvailable,
  isPropertyFound,
  isValidProject,
  progress,
  projectKind,
  propertySearch,
}) => {
  if (!projectKind) {
    return undefined
  }

  if (projectKind === PROJECT_KIND.renegotiation) {
    if (!isProjectAvailable) {
      return STATUS.renegotiationDefault
    }

    if (isValidProject) {
      return STATUS.renegotiationSuccess
    }

    return STATUS.renegotiationError
  }

  if (projectKind === PROJECT_KIND.purchase) {
    if (propertySearch.data && !isProjectAvailable && progress === PROGRESS.searching) {
      return STATUS.noSimuWithSearchAlert
    }

    if (isValidProject) {
      if (progress === PROGRESS.found) {
        return isPropertyFound ? STATUS.purchaseSuccess : STATUS.transitionFound
      }

      return STATUS.budgetSuccess
    }

    return isPropertyFound ? STATUS.purchaseError : STATUS.budgetError
  }

  return STATUS.noSimuNoSearchAlert
}
