import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'
import styled, { css } from 'styled-components'

const MIN_GUTTER_WIDTH = 6
const SIDEBAR_WIDTH_SMALL = 50
const SIDEBAR_WIDTH_REGULAR = 60

export const Page = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: grid;
    grid-template-columns: ${g(MIN_GUTTER_WIDTH)} 1fr ${g(SIDEBAR_WIDTH_SMALL)} ${g(MIN_GUTTER_WIDTH)};
    min-height: 100%;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: minmax(${g(MIN_GUTTER_WIDTH)}, 1fr) auto ${g(SIDEBAR_WIDTH_REGULAR)} minmax(
        ${g(MIN_GUTTER_WIDTH)},
        1fr
      );
  }
`
export const GutterLeft = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1;
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
`
export const GutterRight = styled.div`
  grid-column: 3 / span 1;
  grid-row: 1;
`
export const Wrapper = styled(W)`
  padding: ${g(3)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
    grid-column: 2 / span 2;
    grid-row-start: 1;
    display: grid;
    grid-template-columns: 1fr ${g(SIDEBAR_WIDTH_SMALL)};
    grid-template-rows: auto 1fr;
    grid-row-gap: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr ${g(SIDEBAR_WIDTH_REGULAR)};
  }
`
export const Steps = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column-start: 2;
    grid-row-start: 1;
    padding: ${g(4)} 0 0 ${g(3)};
    align-self: start;
    justify-self: start;
    position: relative;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${g(4)};
  }
`
export const TagContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
`
export const TagContent = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: ${g(1)};
`
export const TagIcon = styled.div`
  font-size: ${g(1.5)};
`
export const Sidebar = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column-start: 2;
    grid-row-start: 2;
    padding-left: ${g(3)};
    padding-bottom: ${g(3)};
    position: relative;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${g(4)};
  }
`
export const CloseIcon = styled.div`
  transition: all 300ms ease-in-out;
  opacity: 0;
  padding: ${g(1)};
  position: absolute;
  transform: scale(0.5);

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
      transition-delay: 300ms;
    `}
`
export const TagTransition = styled.div`
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: translateX(${g(3)});

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
      opacity: 1;
      transition-delay: 300ms;
    `}
`
export const TimelineTransition = styled.div`
  transition: all 300ms ease-in-out;
  opacity: 0;
  padding: ${g(1)};
  position: absolute;
  transform: translateY(${g(3)});
  left: ${g(3)};
  right: 0;
  top: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    left: ${g(4)};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
      transition-delay: 300ms;
    `}
`
export const SidebarTransition = styled.div`
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: translateY(${g(3)});

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
      transition-delay: 300ms;
    `}
`
export const Main = styled.div`
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0 ${g(3)} ${g(5)} 0;
    grid-row-start: 2;
    grid-column-start: 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: ${g(4)};
    width: calc(${breakpoints.desktop} - ${g(SIDEBAR_WIDTH_REGULAR + MIN_GUTTER_WIDTH * 2)});
  }
`
export const Title = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
    grid-column-start: 1;
    grid-row-start: 1;
    padding: ${g(4)} ${g(3)} 0 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: ${g(4)};
  }
`
export const Background = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -2;
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
`
