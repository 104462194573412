import PropTypes from 'prop-types'
import { createRef, PureComponent } from 'react'

const copyNodeStyle = (sourceNode, targetNode) => {
  const computedStyle = window.getComputedStyle(sourceNode)
  Array.from(computedStyle).forEach(function (key) {
    return targetNode.style.setProperty(
      key,
      computedStyle.getPropertyValue(key),
      computedStyle.getPropertyPriority(key)
    )
  })
}

const calculateWithForValue = (value, fieldRef) => {
  const hiddenInput = document.createElement('span')
  hiddenInput.className = 'numberfield--input'
  hiddenInput.textContent = value

  document.body.appendChild(hiddenInput)
  let width = hiddenInput.offsetWidth + 16
  if (fieldRef) {
    copyNodeStyle(fieldRef.current.firstChild, hiddenInput)
    hiddenInput.style.setProperty('display', 'inline-block')
    hiddenInput.style.setProperty('width', 'auto')
    width = hiddenInput.offsetWidth
  }
  document.body.removeChild(hiddenInput)

  return width
}

export default class ResizableField extends PureComponent {
  static defaultProps = {
    updateWidth: true,
  }

  static propTypes = {
    children: PropTypes.func.isRequired,
    fieldRef: PropTypes.object,
    inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    updateWidth: PropTypes.bool,
  }

  ref = createRef()

  state = {
    width: null,
  }

  componentDidMount() {
    if (this.props.updateWidth) {
      this.updateWidth(this.props.inputValue)
    }
  }

  componentDidUpdate(prevProps) {
    const { inputValue, updateWidth } = this.props

    if (prevProps.inputValue !== inputValue && updateWidth) {
      this.updateWidth(inputValue)
    }
  }

  updateWidth(value) {
    this.setState({ width: calculateWithForValue(value, this.props.fieldRef) })
  }

  render() {
    return this.props.children(this.state.width)
  }
}
