import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

export const getHeaderProps = status => {
  switch (status) {
    case 'search':
    case 'constitution':
    case 'validation':
      return {
        goBackProps: GO_BACK.folder,
        navigationItemsList: [NAV_ITEMS.faq],
      }
    case 'analysis':
    case 'confirmation':
    case 'negotiation':
    case 'agreement':
    case 'appointment':
    case 'signature':
    case 'end':
      return {
        navigationItemsList: [NAV_ITEMS.dashboard, NAV_ITEMS.documents, NAV_ITEMS.faq],
      }
    default:
      return {
        goBackProps: GO_BACK.home,
        navigationItemsList: [NAV_ITEMS.faq],
      }
  }
}
