import PropTypes from 'prop-types'

import * as S from './styles'

const Row = ({ label, value, ...props }) => (
  <S.Row {...props}>
    <S.Label>{label}</S.Label>
    <S.Value>{value}</S.Value>
  </S.Row>
)

Row.propTypes = {
  /** Node of children. */
  label: PropTypes.node.isRequired,
  /** Node of children. */
  value: PropTypes.node.isRequired,
}
export default Row
