import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { ModalHeader } from '@pretto/zen/main/modals/ModalHeader/ModalHeader'

import { IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import {
  ProjectModalContext,
  ProjectModalContextType,
  STATUS,
} from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import { deleteAlert } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext, useEffect, useState } from 'react'

import * as S from './PrettoSearchModal.styles'
import { formatData } from './lib/formatData'

export interface PrettoSearchModalProps {
  onClose: () => void
  data: Pick<
    IPropertySearchData,
    | 'id'
    | 'localisations'
    | 'property_kind'
    | 'area_min'
    | 'area_max'
    | 'rooms'
    | 'property_condition'
    | 'price_min'
    | 'price_max'
  >
}

export const PrettoSearchModal: React.FC<PrettoSearchModalProps> = ({ onClose, data }) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isKeepLoading, setIsKeepLoading] = useState(false)
  const { status, unlinkAccount, propertySearchData } = useContext<ProjectModalContextType>(ProjectModalContext)
  const { status: userStatus, lostReason } = useUser()
  const trackAction = useTracking()

  const isOpen = status === STATUS.prettoSearch
  useEffect(() => {
    if (isOpen) {
      if (userStatus === 'end') {
        trackAction('WIN_ACTIVE_ALERT_IMMO_POP_UP_VIEWED')
      }

      if (lostReason) {
        trackAction('LOST_ACTIVE_ALERT_IMMO_POP_UP_VIEWED', {
          lostReason,
          dashboardOptions: userStatus,
        })
      }
    }
  }, [isOpen])

  const handleKeep = async () => {
    if (userStatus === 'end') {
      trackAction('WIN_ACTIVE_ALERT_IMMO_KEPT')
    }

    if (lostReason) {
      trackAction('LOST_ACTIVE_ALERT_IMMO_KEPT', {
        lostReason,
        dashboardOptions: userStatus,
      })
    }

    setIsKeepLoading(true)
    await unlinkAccount()
  }

  const handleDelete = async () => {
    if (userStatus === 'end') {
      trackAction('WIN_ACTIVE_ALERT_IMMO_DELETED')
    }

    if (lostReason) {
      trackAction('LOST_ACTIVE_ALERT_IMMO_DELETED', {
        lostReason,
        dashboardOptions: userStatus,
      })
    }

    setIsDeleteLoading(true)

    await deleteAlert(propertySearchData.id)

    await unlinkAccount()
  }

  const infos = formatData(data)

  return (
    <Dialog isOpen={status === STATUS.prettoSearch} onRequestClose={onClose}>
      <ModalHeader title="Votre recherche de bien Pretto Search" onClose={onClose} />
      <S.Content>
        <S.Description>
          Vous avez une recherche de bien activée sur notre service Pretto Search. Que souhaitez-vous faire ?
        </S.Description>
        <S.Infos>
          {infos.map(({ picto, title, content }) => (
            <S.InfoContainer key={title}>
              <S.InfoTitle>
                <S.Icon $picto={picto} />
                {title}
              </S.InfoTitle>
              <S.InfoContent>{content}</S.InfoContent>
            </S.InfoContainer>
          ))}
        </S.Infos>
        <Button isLoading={isDeleteLoading} onClick={handleDelete}>
          Supprimer
        </Button>
        <Button onClick={handleKeep} isLoading={isKeepLoading} isSecondary>
          Conserver
        </Button>
      </S.Content>
    </Dialog>
  )
}
