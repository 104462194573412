import { Edge, Graph, Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'
import * as v from '@pretto/app/src/Sentences/v2/lib/validators/context'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

// Introduction
const structureRoute = new Route('structure', '/project/purchase/introduction/structure')
const propertyUsageRoute = new Route('propertyUsage', '/project/purchase/introduction/propertyUsage')
const propertyTypeRoute = new Route('propertyType', '/project/purchase/introduction/propertyType')
const sciTypeRoute = new Route('sciType', '/project/purchase/introduction/sciType')
const sciAssociationRoute = new Route('sciAssociation', '/project/purchase/introduction/sciAssociation')

export const purchaseGraph = new Graph('purchaseGraph', structureRoute)

purchaseGraph.addRoute(propertyUsageRoute)
purchaseGraph.addRoute(propertyTypeRoute)
purchaseGraph.addRoute(sciTypeRoute)
purchaseGraph.addRoute(sciAssociationRoute)

purchaseGraph.addEdge(new Edge(structureRoute, sciTypeRoute, v.isSCI))
purchaseGraph.addEdge(new Edge(sciTypeRoute, sciAssociationRoute, v.isIR))
purchaseGraph.addEdge(new Edge(sciAssociationRoute, propertyUsageRoute, v.isAssociationSimple))
purchaseGraph.addEdge(new Edge(structureRoute, propertyUsageRoute, allPass([v.hasStructure, v.isNotSCI])))
purchaseGraph.addEdge(new Edge(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route('ageChildren', '/project/purchase/lodging/ageChildren')
const liveTogetherRoute = new Route('liveTogether', '/project/purchase/lodging/liveTogether')
const addressRoute = new Route('address', '/project/purchase/lodging/address')
const situationRoute = new Route('situation', '/project/purchase/lodging/situation')

purchaseGraph.addRoute(ageChildrenRoute)
purchaseGraph.addRoute(liveTogetherRoute)
purchaseGraph.addRoute(addressRoute)
purchaseGraph.addRoute(situationRoute)

purchaseGraph.addEdge(new Edge(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

purchaseGraph.addEdge(
  new Edge(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

purchaseGraph.addEdge(new Edge(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo])))
purchaseGraph.addEdge(new Edge(liveTogetherRoute, addressRoute, v.hasLiveTogetherCompleted))
purchaseGraph.addEdge(new Edge(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route('expatriate', '/project/purchase/occupation/expatriate')
const sectorRoute = new Route('sector', '/project/purchase/occupation/sector')

purchaseGraph.addRoute(expatriateRoute)
purchaseGraph.addRoute(sectorRoute)

purchaseGraph.addEdge(new Edge(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled])))
purchaseGraph.addEdge(new Edge(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale])))

// Income
const salaryRoute = new Route('salary', '/project/purchase/income/salary')
const bonusRoute = new Route('bonus', '/project/purchase/income/bonus')
const revenueRoute = new Route('revenue', '/project/purchase/income/revenue')

purchaseGraph.addRoute(salaryRoute)
purchaseGraph.addRoute(bonusRoute)
purchaseGraph.addRoute(revenueRoute)

purchaseGraph.addEdge(new Edge(expatriateRoute, salaryRoute, v.isExpatriateFilled))
purchaseGraph.addEdge(new Edge(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled])))
purchaseGraph.addEdge(new Edge(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
purchaseGraph.addEdge(new Edge(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
purchaseGraph.addEdge(new Edge(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route('comortgagorAddress', '/project/purchase/lodging-comortgagor/address')
const comortgagorSituationRoute = new Route('comortgagorSituation', '/project/purchase/lodging-comortgagor/situation')

purchaseGraph.addRoute(comortgagorAddressRoute)
purchaseGraph.addRoute(comortgagorSituationRoute)

purchaseGraph.addEdge(
  new Edge(revenueRoute, comortgagorAddressRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether]))
)

purchaseGraph.addEdge(new Edge(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress))

// Comortgagor Occupation
const comortgagorExpatriateRoute = new Route(
  'comortgagorExpatriate',
  '/project/purchase/occupation-comortgagor/expatriate'
)

const comortgagorSectorRoute = new Route('comortgagorSector', '/project/purchase/occupation-comortgagor/sector')

purchaseGraph.addRoute(comortgagorExpatriateRoute)
purchaseGraph.addRoute(comortgagorSectorRoute)

purchaseGraph.addEdge(
  new Edge(
    comortgagorSectorRoute,
    comortgagorExpatriateRoute,
    anyPass([
      allPass([v.isInternational, v.doesLiveTogether]),
      allPass([v.doesNotLiveTogether, v.isComortgagorInternational]),
    ])
  )
)

purchaseGraph.addEdge(
  new Edge(revenueRoute, comortgagorSectorRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether]))
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route('comortgagorSalary', '/project/purchase/income-comortgagor/salary')
const comortgagorBonusRoute = new Route('comortgagorBonus', '/project/purchase/income-comortgagor/bonus')
const comortgagorRevenueRoute = new Route('comortgagorRevenue', '/project/purchase/income-comortgagor/revenue')

purchaseGraph.addRoute(comortgagorSalaryRoute)
purchaseGraph.addRoute(comortgagorBonusRoute)
purchaseGraph.addRoute(comortgagorRevenueRoute)

purchaseGraph.addEdge(new Edge(comortgagorExpatriateRoute, comortgagorSalaryRoute, v.isCoMortgagorExpatriateFilled))

purchaseGraph.addEdge(
  new Edge(
    comortgagorSectorRoute,
    comortgagorSalaryRoute,
    allPass([
      anyPass([
        allPass([v.doesLiveTogether, v.isNotInternational]),
        allPass([v.doesNotLiveTogether, v.isComortgagorNotInternational]),
      ]),
      v.isCoMortgagorOccupationFilled,
    ])
  )
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

purchaseGraph.addEdge(new Edge(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled))

// Sale
const estimatedPriceRoute = new Route('estimatedPrice', '/project/purchase/sale/estimatedPrice')
const paidOffRoute = new Route('paidOff', '/project/purchase/sale/paidOff')
const remainingMortgageRoute = new Route('remainingMortgage', '/project/purchase/sale/remainingMortgage')

purchaseGraph.addRoute(estimatedPriceRoute)
purchaseGraph.addRoute(paidOffRoute)
purchaseGraph.addRoute(remainingMortgageRoute)

purchaseGraph.addEdge(
  new Edge(revenueRoute, estimatedPriceRoute, allPass([v.hasAnyForSale, v.isRevenueFilled, v.isForSale, v.isSolo]))
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorRevenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isForSale, v.isCoMortgagorRevenueFilled])
  )
)

purchaseGraph.addEdge(new Edge(estimatedPriceRoute, paidOffRoute, v.hasEstimatedPrice))
purchaseGraph.addEdge(new Edge(paidOffRoute, remainingMortgageRoute, v.isNotPaidOff))

// Charges
const creditsRoute = new Route('credits', '/project/purchase/credits/credits')
const childSupportRoute = new Route('childSupport', '/project/purchase/credits/childSupport')

const comortgagorChildSupportRoute = new Route(
  'comortgagorChildSupport',
  '/project/purchase/credits/comortgagorChildSupport'
)

purchaseGraph.addRoute(creditsRoute)
purchaseGraph.addRoute(childSupportRoute)
purchaseGraph.addRoute(comortgagorChildSupportRoute)

purchaseGraph.addEdge(new Edge(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo, v.isNotForSale])))
purchaseGraph.addEdge(new Edge(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
purchaseGraph.addEdge(new Edge(paidOffRoute, creditsRoute, v.isPaidOff))
purchaseGraph.addEdge(new Edge(remainingMortgageRoute, creditsRoute, v.hasRemainingMortgage))
purchaseGraph.addEdge(new Edge(creditsRoute, childSupportRoute, v.isCreditsFilled))

purchaseGraph.addEdge(
  new Edge(childSupportRoute, comortgagorChildSupportRoute, allPass([v.isNotSolo, v.isChildSupportFilled]))
)

// Property
const goodRoute = new Route('good', '/project/purchase/property/good')
const constructionRoute = new Route('construction', '/project/purchase/property/construction')
const landConstructionRoute = new Route('landConstruction', '/project/purchase/property/landConstruction')
const worksRoute = new Route('works', '/project/purchase/property/works')
const ecoPtzRoute = new Route('ecoPtz', '/project/purchase/property/ecoPtz')
const expectedRentalRoute = new Route('expectedRental', '/project/purchase/property/expectedRental')
const dpeRoute = new Route('dpe', '/project/purchase/property/dpe')

purchaseGraph.addRoute(goodRoute)
purchaseGraph.addRoute(constructionRoute)
purchaseGraph.addRoute(landConstructionRoute)
purchaseGraph.addRoute(dpeRoute)
purchaseGraph.addRoute(expectedRentalRoute)
purchaseGraph.addRoute(worksRoute)
purchaseGraph.addRoute(ecoPtzRoute)

purchaseGraph.addEdge(
  new Edge(childSupportRoute, goodRoute, allPass([v.isOldNewOrVEFA, v.isSolo, v.isChildSupportFilled]))
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorChildSupportRoute,
    goodRoute,
    allPass([v.isOldNewOrVEFA, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge(childSupportRoute, constructionRoute, allPass([v.isPropertyConstruction, v.isSolo, v.isChildSupportFilled]))
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorChildSupportRoute,
    constructionRoute,
    allPass([v.isPropertyConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge(
    childSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isSolo, v.isChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge(
    comortgagorChildSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(new Edge(goodRoute, dpeRoute, v.hasGood))

purchaseGraph.addEdge(
  new Edge(
    constructionRoute,
    expectedRentalRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.isRentalUsage])
  )
)

purchaseGraph.addEdge(new Edge(landConstructionRoute, expectedRentalRoute, allPass([v.hasLandPrice, v.isRentalUsage])))

purchaseGraph.addEdge(
  new Edge(dpeRoute, expectedRentalRoute, allPass([v.hasDpe, v.isOldNewOrVEFA, v.hasPropertyPrice, v.isRentalUsage]))
)

purchaseGraph.addEdge(
  new Edge(dpeRoute, worksRoute, allPass([v.hasDpe, v.isNotRentalUsage, v.hasPropertyPrice, v.isPropertyOld]))
)

purchaseGraph.addEdge(
  new Edge(
    expectedRentalRoute,
    worksRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasGood, v.hasPropertyPrice, v.isPropertyOld])
  )
)

purchaseGraph.addEdge(new Edge(worksRoute, ecoPtzRoute, allPass([v.isWorksFilled, v.isEligibleToEcoPtz])))

// PTZ
const beenOwnerRoute = new Route('beenOwner', '/project/purchase/ptz/beenOwner')
const fiscalIncomeNm2Route = new Route('fiscalIncomeNm2', '/project/purchase/ptz/fiscalIncomeNm2')

purchaseGraph.addRoute(beenOwnerRoute)
purchaseGraph.addRoute(fiscalIncomeNm2Route)

purchaseGraph.addEdge(
  new Edge(constructionRoute, beenOwnerRoute, allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasPTZ]))
)

purchaseGraph.addEdge(
  new Edge(landConstructionRoute, beenOwnerRoute, allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasPTZ]))
)

purchaseGraph.addEdge(new Edge(dpeRoute, beenOwnerRoute, allPass([v.hasDpe, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge(worksRoute, beenOwnerRoute, allPass([v.isPropertyOld, v.isWorksFilled, v.hasPTZ, v.isNotEligibleToEcoPtz]))
)

purchaseGraph.addEdge(new Edge(ecoPtzRoute, beenOwnerRoute, allPass([v.isEcoPtzFilled, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge(
    expectedRentalRoute,
    beenOwnerRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasPTZ])
  )
)

purchaseGraph.addEdge(new Edge(beenOwnerRoute, fiscalIncomeNm2Route, v.isFirstTimeBuyer))

// Contribution
const contributionRoute = new Route('contribution', '/project/purchase/contribution/contribution')
const savingsRoute = new Route('savings', '/project/purchase/contribution/savings')

purchaseGraph.addRoute(contributionRoute)
purchaseGraph.addRoute(savingsRoute)

purchaseGraph.addEdge(
  new Edge(
    constructionRoute,
    contributionRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge(
    landConstructionRoute,
    contributionRoute,
    allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(new Edge(dpeRoute, contributionRoute, allPass([v.hasDpe, v.isPropertyNewOrVEFA, v.hasNotPTZ])))

purchaseGraph.addEdge(
  new Edge(
    expectedRentalRoute,
    contributionRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge(worksRoute, contributionRoute, allPass([v.isWorksFilled, v.hasNotPTZ, v.isNotEligibleToEcoPtz]))
)

purchaseGraph.addEdge(new Edge(ecoPtzRoute, contributionRoute, allPass([v.isEcoPtzFilled, v.hasNotPTZ])))
purchaseGraph.addEdge(new Edge(beenOwnerRoute, contributionRoute, v.isNotFirstTimeBuyer))
purchaseGraph.addEdge(new Edge(fiscalIncomeNm2Route, contributionRoute, v.hasFiscalIncomeNM2))
purchaseGraph.addEdge(new Edge(contributionRoute, savingsRoute, v.isContributionFilled))

// Competition
const hasCompetitionRoute = new Route('hasCompetition', '/project/purchase/competition/hasCompetition')
const offerRoute = new Route('offer', '/project/purchase/competition/offer')

purchaseGraph.addRoute(hasCompetitionRoute)
purchaseGraph.addRoute(offerRoute)

purchaseGraph.addEdge(new Edge(savingsRoute, hasCompetitionRoute, allPass([v.isSavingsFilled])))
purchaseGraph.addEdge(new Edge(hasCompetitionRoute, offerRoute, v.hasCompetition))

// Rate alert
const wantsRateAlertRoute = new Route('wantsRateAlert', '/project/purchase/rate-alert/wantsRateAlert')

purchaseGraph.addRoute(wantsRateAlertRoute)

purchaseGraph.addEdge(
  new Edge(
    hasCompetitionRoute,
    wantsRateAlertRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isVisibleRateAlert])
  )
)

purchaseGraph.addEdge(
  new Edge(
    offerRoute,
    wantsRateAlertRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isVisibleRateAlert])
  )
)

// Simulation
const simulationRoute = new Route('simulation', '/simulation')

purchaseGraph.addRoute(simulationRoute)

purchaseGraph.addEdge(
  new Edge(
    hasCompetitionRoute,
    simulationRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isRateAlertFilled])
  )
)

purchaseGraph.addEdge(
  new Edge(
    offerRoute,
    simulationRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isRateAlertFilled])
  )
)

purchaseGraph.addEdge(new Edge(wantsRateAlertRoute, simulationRoute, v.isRateAlertFilled))
