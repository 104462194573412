import { g, tablet } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const HeadingBridge = styled.div`
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  color: ${({ theme, $color }) => ($color === 'inherit' ? 'inherit' : theme.legacy.colors[$color])};
  transition: all 500ms ease-in-out;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: ${g(3)};
          letter-spacing: 0.2px;
          line-height: ${g(4)};

          @media only screen and (min-width: ${tablet.min}) {
            font-size: ${g(3)};
            line-height: ${g(4)};
          }
        `
      default:
      case 'medium':
        return css`
          font-size: ${g(3)};
          letter-spacing: 0.2px;
          line-height: ${g(4)};

          @media only screen and (min-width: ${tablet.min}) {
            font-size: ${g(4)};
            letter-spacing: 0.3px;
            line-height: ${g(5)};
          }
        `
      case 'large':
        return css`
          font-size: ${g(4)};
          letter-spacing: 0.3px;
          line-height: ${g(5)};

          @media only screen and (min-width: ${tablet.min}) {
            font-size: ${g(5)};
            line-height: ${g(6)};
          }
        `
      case 'X-large':
        return css`
          font-size: ${g(5)};
          letter-spacing: 0.32px;
          line-height: ${g(8)};

          @media only screen and (min-width: ${tablet.min}) {
            font-size: ${g(8)};
            letter-spacing: 0.48px;
            line-height: ${g(10)};
          }
        `
    }
  }}

  ${({ $format }) =>
    $format === 'center' &&
    css`
      display: block;
      text-align: center;
    `}
`
