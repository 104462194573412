export interface Error {
  key: ErrorType
  message: React.ReactNode
}

export enum ErrorType {
  AccountAlreadyExists = 'account_already_exists',
  AccountExists = 'account_exists',
  AgreementRequired = 'agreement_required',
  ServerError = 'server_error',
}
