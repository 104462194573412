import { g, tablet } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const DeprecatedBrowserBar = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.warning.light};
  color: ${({ theme }) => theme.legacy.colors.warning.default};
  padding: ${g(2)} 0;
`

export const DeprecatedBrowserBarContent = styled.div`
  text-align: center;

  @media only screen and (min-width: ${tablet.min}) {
    align-items: center;
    display: flex;
    text-align: left;
  }
`

export const Emoji = styled.span`
  display: inline;
  margin-right: ${g(1)};
`
