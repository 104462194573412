import PropTypes from 'prop-types'
import { Children, memo } from 'react'

import * as S from './styles'

const ListOptions = ({ children }) => (
  <div>
    {Children.map(children, (child, index) => (
      <S.Option key={index}>{child}</S.Option>
    ))}
  </div>
)

ListOptions.propTypes = {
  /** List of option. */
  children: PropTypes.array.isRequired,
}

export default memo(ListOptions)
