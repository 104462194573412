export const email = typology => ({
  inputProps: {
    disabled: typology !== 'prospect',
    placeholder: 'Adresse email',
    required: true,
  },
  size: 'small',
})

export const firstName = {
  inputProps: {
    autoFocus: true,
    placeholder: 'Prénom',
    required: true,
  },
  size: 'small',
}

export const lastName = {
  inputProps: {
    placeholder: 'Nom',
    required: true,
  },
  size: 'small',
}

export const phone = {
  inputProps: {
    placeholder: 'Numéro de téléphone',
    required: true,
  },
  size: 'small',
}
