import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'

import { memo } from 'react'

import * as S from './styles'

const PrivacyDelete = () => (
  <Card>
    <Content>
      <S.Content>
        <S.Emoji>
          <Icon name="file-1" variant="primary-1" />
        </S.Emoji>
        <div>
          <S.Paragraph>
            Vous souhaitez avoir accès à vos données ou faire une demande de suppression ? Vous pouvez nous contacter
            sur{' '}
            <S.Link href="mailto:privacy@pretto.fr" target="blank">
              privacy@pretto.fr
            </S.Link>
            .
          </S.Paragraph>
          <S.Paragraph>Ou par courrier : Pretto, 42 Rue de Paradis, 75010 Paris</S.Paragraph>
        </div>
      </S.Content>
    </Content>
  </Card>
)

export default memo(PrivacyDelete)
