import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'

import getFoundableResults from './getFoundableResults'

/*
Three steps function that returns a array of loanDuration from a given result list:
1. Extracting the loanDuration of every result into a new array
2. Removing duplicates from the newly created array of loanDuration
3. Sorting loanDurations by numerical order
*/
const getLoanDurationsFromResults = results => sortBy(uniq(map(getFoundableResults(results), 'financing.loanDuration')))

export default getLoanDurationsFromResults
