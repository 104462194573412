import { breakpoints, g } from '@pretto/bricks/components/layout'

import {
  DefaultMessage,
  FollowTooltip as FollowTooltipComponent,
} from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { formatNumber } from '@pretto/app/src/Capacity/lib/formatNumber'
import { InfoCircleBold } from '@pretto/picto'

import React from 'react'
import styled, { css } from 'styled-components'

import { Symbol as SymbolComponent } from './components/Symbol/Symbol'
import { Unit as UnitComponent } from './components/Unit/Unit'

export enum CellHighlight {
  Light,
  None,
  Strong,
}

export enum CellType {
  Addition,
  Deduction,
  Entry,
  Multiplication,
  Neutral,
  Subtotal,
  Total,
}

export enum Unit {
  None,
  Payment,
  Price,
}

export interface TableRow {
  highlight?: CellHighlight
  info?: string | null
  label: string
  tooltip?: React.ReactNode
  type: CellType
  unit?: Unit
  value: number
}

export interface CalcTableProps {
  rows: TableRow[]
}

export const CalcTable: React.FC<CalcTableProps & React.HTMLAttributes<HTMLUListElement>> = ({ rows, ...props }) => (
  <Table {...props} role="table" aria-label="Détails du calcul">
    {rows.map(({ highlight = CellHighlight.None, info, label, tooltip, type, unit = Unit.Price, value }, index) => (
      <Row $highlight={highlight} key={index} role="row">
        <Symbol $highlight={highlight} type={type} />

        <Label>
          <span role="cell">{label}</span>
          {info && <Info>{info}</Info>}
        </Label>

        {tooltip && (
          <FollowTooltip messageComponent={ResizedFollowTooltipComponent} message={tooltip}>
            <Tooltip $highlight={highlight} />
          </FollowTooltip>
        )}

        <Value $highlight={highlight} role="cell">
          <span>{formatNumber(value)}</span>
          <UnitComponent unit={unit} />
        </Value>
      </Row>
    ))}
  </Table>
)

interface HighlightProps {
  $highlight: CellHighlight
}

const FollowTooltip = styled(FollowTooltipComponent)``

const Info = styled.span`
  ${({ theme }) => theme.typos.caption};
  margin-left: ${g(1 / 2)};
`

const Label = styled.span`
  ${transition({ propertyName: 'opacity' })};
  opacity: var(--opacity);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ResizedFollowTooltipComponent = styled(DefaultMessage)`
  z-index: 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(38)};
  }
`

const Symbol = styled(SymbolComponent)<HighlightProps>`
  ${transition({ propertyName: 'opacity' })};
  flex-shrink: 0;
  opacity: var(--opacity);

  ${({ $highlight, theme }) => {
    switch ($highlight) {
      case CellHighlight.Light:
        return css`
          color: ${theme.colors.primary2};
        `

      case CellHighlight.Strong:
        return css`
          color: ${theme.colors.primary1};
        `

      default:
        return null
    }
  }}
`

const Table = styled.ul`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

const Tooltip = styled(InfoCircleBold)<HighlightProps>`
  color: ${({ $highlight, theme }) =>
    $highlight === CellHighlight.Strong ? theme.colors.primary1Disabled : theme.colors.neutral3};
  display: block;
`

const Value = styled.span<HighlightProps>`
  ${({ theme }) => theme.typos.body4Bold};
  ${transition({ propertyName: 'opacity' })};
  align-items: center;
  display: flex;
  flex-shrink: 0;
  gap: ${g(1 / 2)};
  opacity: var(--opacity);
  margin-left: auto;
  white-space: nowrap;

  ${({ $highlight, theme }) => {
    switch ($highlight) {
      case CellHighlight.Light:
      case CellHighlight.Strong:
        return css`
          color: ${theme.colors.primary1};
        `

      default:
        return null
    }
  }}
`

const Row = styled.li<HighlightProps>`
  ${transition({ propertyName: 'background-color' })};
  align-items: center;
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  gap: ${g(1)};
  padding: 0 ${g(1)};
  --opacity: 1;

  &&:has(~ * ${FollowTooltip} ${Tooltip}:hover),
  &&:has(~ * ${FollowTooltip}:focus ${Tooltip}),
  &&:has(${Tooltip}:hover) ~ *,
  &&:has(${FollowTooltip}:focus ${Tooltip}) ~ * {
    --opacity: 0.4;
  }

  ${({ $highlight }) => {
    switch ($highlight) {
      case CellHighlight.Light:
      case CellHighlight.Strong:
        return css`
          border-radius: ${g(1)};
          padding: ${g(1)};
        `

      default:
        return null
    }
  }}

  ${({ $highlight, theme }) => {
    switch ($highlight) {
      case CellHighlight.Light:
        return css`
          background-color: ${addAlpha(theme.colors.neutral5, 'var(--opacity)')};
        `

      case CellHighlight.Strong:
        return css`
          background-color: ${addAlpha(theme.colors.primary4, 'var(--opacity)')};
        `

      default:
        return null
    }
  }}
`
