import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Greetings from '@pretto/bricks/components/utility/Greetings'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import PropTypes from 'prop-types'

import { Steps } from './components/Steps/Steps'
import * as S from './styles'

const DashboardPage = ({ activeIndex, cards, reviewsProps, services, steps, username }) => {
  const grettingsTitleTypo = useBreakpointToValue('heading32', { tablet: 'heading48' })

  return (
    <S.Page $hasPaddingBottom={!reviewsProps}>
      <S.Header>
        <Responsive min="tablet">
          <Wrapper full="tablet">
            <BaseTypo typo={grettingsTitleTypo}>
              <Greetings /> {username}&nbsp;!
            </BaseTypo>
          </Wrapper>
        </Responsive>
      </S.Header>

      <Wrapper full="tablet">
        <S.Main>
          {cards.map((card, index) => (
            <S.Card key={index}>{card}</S.Card>
          ))}
        </S.Main>
      </Wrapper>
      {steps && !!steps.length && <Steps steps={steps} activeIndex={activeIndex} />}
      <Wrapper full="tablet">
        <Responsive min="tablet">
          <S.ServicesTitle>Pretto à votre service !</S.ServicesTitle>
          <S.Services>{services}</S.Services>
        </Responsive>
        <Responsive max="tablet">
          <S.ServicesMobile title="Pretto à votre service !">{services}</S.ServicesMobile>
        </Responsive>
      </Wrapper>
      {!!reviewsProps && (
        <ThemeProvider designSystem="reveal">
          <S.AvisBloc {...reviewsProps} />
        </ThemeProvider>
      )}
    </S.Page>
  )
}

DashboardPage.propTypes = {
  /** index of the last dashboard status */
  activeIndex: PropTypes.number.isRequired,
  /** List of cards to display. */
  cards: PropTypes.array.isRequired,
  /** Props of component [`DashboardReviews`](/#!/DashboardReviews). */
  reviewsProps: PropTypes.object,
  /** slugs of service cards */
  services: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** List of all dashboard status */
  steps: PropTypes.array.isRequired,
  /** Username to stay polite. */
  username: PropTypes.string.isRequired,
}

export default DashboardPage
