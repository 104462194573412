import PrivacyInformation from '@pretto/bricks/app/privacy/components/PrivacyInformation'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const REASONS = [
  {
    category: 'marketing',
    data: ['cookies', 'ip', 'nav'],
    services: ['segment', 'gtm', 'adwords', 'facebook', 'bankin'],
  },
  {
    category: 'analysis',
    data: ['cookies', 'ip', 'nav', 'meta'],
    services: ['hotjar', 'mixpanel', 'analytics'],
  },
  {
    category: 'hosting',
    data: ['firstName', 'lastName', 'email', 'ip', 'nav', 'project', 'documents'],
    services: ['heroku', 'algolia', 'zapier'],
  },
  {
    category: 'clientRelation',
    data: ['firstName', 'lastName', 'email', 'phone', 'meta'],
    services: ['pipedrive', 'intercom', 'aircall'],
  },
  {
    category: 'storage',
    data: ['lastName', 'documents'],
    services: ['box', 'hyperlex'],
  },
  {
    category: 'auth',
    data: ['cookies', 'email'],
    services: ['auth0'],
  },
]
const SITE_URL = 'https://www.pretto.fr'
const PrivacyInformations = ({ onCguClick, onLegalMentionsClick, onPrivacyClick }) => {
  const links = [
    {
      content: 'nos cgu',
      onClick: onCguClick,
      path: '/cgu/',
    },
    {
      content: 'nos mentions légales',
      onClick: onLegalMentionsClick,
      path: '/mentions-legales/',
    },
    {
      content: 'notre politique de confidentialité',
      onClick: onPrivacyClick,
      path: '/privacy/',
    },
  ]

  return (
    <div>
      <S.Disclaimer>
        <S.Paragraph>
          La mission de Pretto est de négocier pour vous le meilleur prêt immobilier. Dans ce cadre, vous allez être
          amené à nous communiquer des informations détaillées sur votre situation financière et votre projet. Ces
          informations nous permettent d’identifier la ou les banques les plus adaptées à votre projet et de présenter
          un dossier complet et de qualité afin d’obtenir un taux préférentiel. Nous ne vous demandons pas d’information
          qui n’est pas directement utile à cette mission.
        </S.Paragraph>
        <S.Paragraph>
          En parallèle des informations nécessaires à l’étude de votre financement, Pretto collecte des Données
          Personnelles pour les raisons suivantes :
        </S.Paragraph>
      </S.Disclaimer>
      <S.Reasons>
        {REASONS.map(reason => (
          <S.Item key={reason.category}>
            <PrivacyInformation {...reason} />
          </S.Item>
        ))}
      </S.Reasons>
      <S.Links>
        Vous pouvez consulter :{' '}
        {links.map(({ content, onClick, path }, i) => (
          <Fragment key={i}>
            <Link href={`${SITE_URL}${path}`} onClick={onClick}>
              {content}
            </Link>
            {i < links.length - 1 ? ', ' : '.'}
          </Fragment>
        ))}
      </S.Links>
    </div>
  )
}

PrivacyInformations.propTypes = {
  /** Clicked on the CGU link. */
  onCguClick: PropTypes.func,
  /** Clicked on the Legal mentions link. */
  onLegalMentionsClick: PropTypes.func,
  /** Clicked on the Privacy link. */
  onPrivacyClick: PropTypes.func,
}

export default memo(PrivacyInformations)
