import input from '@pretto/bricks/assets/utility/extended-styles/Input'
import { focusedIndex } from '@pretto/bricks/assets/utility/extended-styles/generic'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Checkboxes = styled.div`
  margin-top: ${g(3)};
`

export const Checkbox = styled.div``

export const CheckboxContent = styled.div`
  ${Checkbox} + ${Checkbox} & {
    padding-top: ${g(3)};
  }
`

export const DialogHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20).default};
  box-shadow: 0 2px 10px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(20).default};
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const DialogHeaderClose = styled.span`
  color: ${({ theme }) => theme.legacy.colors.neutral1.default};
  cursor: pointer;
  font-size: ${g(2)};
  padding: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
`

export const DialogHeaderSearchField = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${g(1)};
    padding-top: 0;
  }

  .textfield {
    input {
      /* fix for mobile */
      padding: 0;
      /* ----------------- */
      caret-color: ${({ theme }) => theme.legacy.colors.accent1.default};
    }
  }
`

export const Field = styled.div`
  display: inline;
  position: relative;
`

export const FakeFieldInput = styled.input`
  height: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1px;
`

export const FakeField = styled.div`
  ${input};
  border-bottom-style: solid;
  cursor: pointer;
  font-weight: 600;

  ${FakeFieldInput}:focus + & {
    ${focusedIndex}
  }

  ${({ format, theme }) =>
    format === 'default' &&
    css`
      color: ${theme.legacy.colors.primary1.default};
    `}

  ${({ isEmpty, theme }) =>
    isEmpty &&
    css`
      border-bottom-style: dashed;
      color: ${theme.legacy.colors.neutral1.fade(30).default};
      font-weight: 400;
    `}

  ${({ format }) => {
    switch (format) {
      case 'form':
        return css`
          & {
            align-items: center;
            border-bottom-style: solid;
            display: flex;
            font-weight: normal;
            height: ${g(6)};
            justify-content: space-between;
            line-height: ${g(6)};
            overflow: hidden;
            padding: 0 ${g(1)};
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        `

      case 'line':
        return css`
          & {
            display: block;
            border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
            font-weight: initial;
            height: ${g(5)};
            line-height: ${g(4.75)};
            border-radius: ${g(1)};
            padding: 0 ${g(2)};
          }
        `

      default:
        break
    }
  }}

${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 14px;
        `

      default:
        break
    }
  }}
`

export const FakeFieldIcon = styled.span`
  flex-shrink: 0;
  margin-left: ${g(1)};
`

export const FakeFieldValue = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ListCheckbox = styled(InputCheckbox)`
  margin-right: ${g(2)};
`

export const ListItem = styled.div`
  align-items: center;
  display: flex;
  margin: ${g(-2)};
  padding: ${g(2)};

  &:focus {
    outline: 0;

    ${({ isFocused }) =>
      isFocused &&
      css`
        background-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(10).default};
      `}
  }

  &:hover {
    background-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(10).default};
  }
`

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${g(-1)};
  margin-right: ${g(-1)};
`

export const Tag = styled.div`
  margin-bottom: ${g(1)};
  margin-right: ${g(1)};
`
