import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import * as S from './styles'

const InputRadioGroup = ({ format, name, inputProps, onChange, options, valueSelected, ...props }) => {
  const [valueSelectedState, setValueSelectedState] = useState(valueSelected)

  useEffect(() => {
    setValueSelectedState(valueSelected)
  }, [valueSelected])

  const handleChange = value => {
    setValueSelectedState(value)
    onChange(value)
  }

  return (
    <S.InputRadioGroup $format={format} role="radiogroup" {...props}>
      {options.map(opt => {
        const isChecked = valueSelectedState === opt.value
        return (
          <S.InputRadio
            $format={format}
            isChecked={isChecked}
            key={opt.id}
            name={name}
            onChange={handleChange}
            {...opt}
            {...inputProps}
          />
        )
      })}
    </S.InputRadioGroup>
  )
}

InputRadioGroup.propTypes = {
  /** Format style of RadioButton.<br /> Formats are: <code>default</code>, <code>inline</code>. */
  format: PropTypes.string,
  /** Props of the input : name, others... */
  inputProps: PropTypes.object,
  /** Name of RadioButton elements. All children will have it. */
  name: PropTypes.string.isRequired,
  /** Triggered when the RadioButton changes. */
  onChange: PropTypes.func,
  /** Array of RadioButton components. */
  options: PropTypes.array.isRequired,
  /** Current selected value of InputRadioGroup. */
  valueSelected: PropTypes.any,
}

InputRadioGroup.defaultProps = {
  format: 'default',
  name: '',
  onChange: () => {},
  options: [],
  valueSelected: '',
}

export default InputRadioGroup
