import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import temporal from '@pretto/bricks/core/utility/temporal'

import capitalize from 'lodash/capitalize'

export const formatGroupTitle = ({ month, year }, kind, title) => {
  if (kind === 'impots' && year) {
    return `${parseInt(year, 10) + 1} sur les revenus de ${year}`
  }

  if (kind === 'other') {
    return title
  }

  const date = temporal()

  return [
    ({ month }) => month && date.month(month - 1).format('MMMM'),
    ({ year }) => year && date.year(year).format('YYYY'),
  ]
    .map(format => capitalize(funcToItem(format, { month, year })))
    .filter(Boolean)
    .join(' ')
}
