import { useEffect, useMemo, useState } from 'react'

import { isGreaterThan } from './responsive'
import { useResize } from './useResize'

interface Options {
  active?: boolean
  container: React.RefObject<HTMLElement>
  numberOfSlides: number
  slideQuerySelector?: string
  threshold?: number
}

export const useCarousel = ({ active = true, container, numberOfSlides, slideQuerySelector = '.review' }: Options) => {
  const size = useResize()

  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [intersections, setIntersections] = useState<boolean[]>([])

  const getElementByIndex = (index: number) =>
    container.current?.querySelector(`${slideQuerySelector}:nth-child(${index + 1})`)

  const getIsIndexVisible = (index: number): boolean => intersections[index]

  const numberOfSlidesInVisibleArea = useMemo(() => {
    if (!active) {
      return null
    }

    const element = getElementByIndex(0)
    const parentElement = element?.parentElement

    if (!container.current || !element || !(element instanceof HTMLElement) || !parentElement) {
      return null
    }

    if (isGreaterThan('desktop')) {
      return calcMaximumNumberOfSlides(element, container.current, parentElement)
    }

    return null
  }, [active, numberOfSlides, size])

  useEffect(() => {
    if (!active) {
      return
    }

    const intersections: boolean[] = new Array(numberOfSlides).fill(false)

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(({ isIntersecting, target }) => {
          const index = Array.prototype.slice.call(target.parentNode?.children).indexOf(target)

          if (index < 0 || !container.current || !(target instanceof HTMLElement)) {
            return
          }

          intersections[index] = isIntersecting

          const firstIndex = intersections.findIndex(value => value === true)
          const lastIndex = intersections.findLastIndex(value => value === true)

          if (firstIndex < 0 || lastIndex < 0) {
            return
          }

          const numberOfVisibleSlides = lastIndex - firstIndex + 1

          const centerIndex = findCenterIndex(
            firstIndex,
            lastIndex,
            numberOfVisibleSlides,
            numberOfSlidesInVisibleArea ?? numberOfVisibleSlides
          )

          const currentPageIndex = Math.max(0, Math.min(numberOfSlides - 1, centerIndex))

          setCurrentPageIndex(currentPageIndex)
          setIntersections([...intersections])
        })
      },
      { root: container.current, threshold: 1 }
    )

    container.current?.querySelectorAll(slideQuerySelector)?.forEach(node => {
      observer.observe(node)
    })

    return () => {
      observer.disconnect()
    }
  }, [active, numberOfSlides, numberOfSlidesInVisibleArea])

  const scrollBy = (x: number) => {
    container.current?.scrollBy({ left: x })
  }

  const scrollToElement = (element: Element) => {
    const styles = window.getComputedStyle(element)
    const inline = formatScrollInlineValue(styles.scrollSnapAlign)

    if (!inline) {
      return false
    }

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline,
    })

    return true
  }

  const scrollToIndex = (index: number) => {
    const element = getElementByIndex(index)

    if (!element) {
      return false
    }

    return scrollToElement(element)
  }

  const scrollTowardsIndex = (index: number) => {
    if (index === currentPageIndex) {
      return
    }

    if (index < currentPageIndex) {
      return scrollUntilPrevious()
    }

    scrollUntilNext()
  }

  const scrollUntilNext = (index: number = currentPageIndex + 1) => {
    const element = getElementByIndex(index)

    if (!element || scrollToElement(element) || element.nextSibling === null) {
      return
    }

    scrollUntilNext(index + 1)
  }

  const scrollUntilPrevious = (index: number = currentPageIndex - 1) => {
    const element = getElementByIndex(index)

    if (!element || scrollToElement(element) || element.previousSibling === null) {
      return
    }

    scrollUntilNext(index - 1)
  }

  return {
    currentPageIndex,
    getIsIndexVisible,
    scrollBy,
    scrollToElement,
    scrollToIndex,
    scrollTowardsIndex,
    scrollUntilNext,
    scrollUntilPrevious,
  }
}

const calcMaximumNumberOfSlides = (element: HTMLElement, containerElement: HTMLElement, parentElement: HTMLElement) => {
  const containerWidth = containerElement.offsetWidth
  const slideWidth = element.offsetWidth
  const parentGap = getElementColumnGap(parentElement)

  return Math.floor((containerWidth / 2 - slideWidth / 2) / (slideWidth + parentGap)) * 2 + 1
}

const findCenterIndex = (
  firstIndex: number,
  lastIndex: number,
  numberOfVisibleSlides: number,
  numberOfPossibleVisibleSlides: number
): number => {
  const numberOfEmptySlots = numberOfPossibleVisibleSlides - numberOfVisibleSlides

  if (firstIndex === 0 && numberOfEmptySlots > 0) {
    const compensatedFirstIndex = firstIndex - numberOfEmptySlots

    return Math.floor(compensatedFirstIndex + (lastIndex - compensatedFirstIndex) / 2)
  }

  const compensatedLastIndex = lastIndex + numberOfEmptySlots

  return Math.floor(firstIndex + (compensatedLastIndex - firstIndex) / 2)
}

const formatScrollInlineValue = (propertyValue: string): ScrollLogicalPosition | null => {
  if (propertyValue === 'center' || propertyValue === 'end' || propertyValue === 'start') {
    return propertyValue
  }

  return null
}

const getElementColumnGap = (element: HTMLElement) => {
  const styles = window.getComputedStyle(element)
  const columnGap = parseInt(styles.columnGap, 10)

  if (isNaN(columnGap)) {
    return 0
  }

  return columnGap
}
