import SummaryChanges from '@pretto/bricks/app/simulator/components/SummaryChanges'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './ValidateSimulationPage.module.css'

export default class ValidateSimulationPage extends PureComponent {
  static propTypes = {
    currentProject: PropTypes.object,
    newProject: PropTypes.object,
    bubbleChat: PropTypes.node,
  }

  render() {
    const { currentProject, newProject, bubbleChat } = this.props
    return (
      <Wrapper>
        <div className={C.validateSimulationPage}>
          <div className={C.validateSimulationPageBubble}>{bubbleChat}</div>
          <div className={C.validateSimulationPageTitle}>
            <SubHeading>Choisissez le projet que vous voulez enregistrer</SubHeading>
          </div>
          <div className={C.validateSimulationPageSummaries}>
            <div
              className={classnames(
                C.validateSimulationPageSummariesSummary,
                C.validateSimulationPageSummariesSummaryNew
              )}
            >
              <SummaryChanges type="new" {...newProject} />
            </div>
            <div className={C.validateSimulationPageSummariesSummary}>
              <SummaryChanges type="current" {...currentProject} />
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}
