import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import range from 'lodash/range'

import { fade } from '@pretto/bricks/components/colors'

export const Color = {
  create(color, ...names) {
    return {
      add(color, variant) {
        this.variants[variant] = color

        return this
      },

      compile() {
        return Object.entries(this.variants).reduce((previous, [variant, color]) => {
          if (!['dark', 'fade', 'light', 'opposite'].includes(variant)) {
            return previous
          }

          if (typeof color === 'object') {
            return { ...previous, [variant]: color.compile() }
          }

          return { ...previous, [variant]: color }
        }, this.compileDefault())
      },

      compileDefault() {
        return {
          default: color,

          toString() {
            return this.default
          },
        }
      },

      dark(color) {
        return this.add(color, 'dark')
      },

      fade(renamer = value => value, opposite = color) {
        range(10, 100, 10).forEach(index => {
          this.add(Color.create(fade(color, index), renamer(index)).opposite(funcToItem(opposite, index)), index)
        })

        return this.add(alpha => {
          return Color.create(fade(color, alpha)).compileDefault()
        }, 'fade')
      },

      light(color) {
        return this.add(color, 'light')
      },

      names,

      opposite(color) {
        return this.add(color, 'opposite')
      },

      register(add) {
        Object.entries(this.variants).forEach(([, color]) => {
          add(color)
        })
      },

      variants: {},
    }
  },
}

export const Colors = {
  init() {
    return {
      add(color) {
        this.colors.push(color)

        color.register(color => {
          this.colors.push(color)
        })
      },

      colors: [],

      compile() {
        return this.colors.reduce(
          (previous, { names = [], ...color }) => ({
            ...previous,
            ...names.reduce((previous, name) => ({ ...previous, [name]: color.compile() }), {}),
          }),
          {}
        )
      },

      fromName(name) {
        return {
          compile: () => {
            return this.colors.find(({ names = [] }) => names.includes(name)).compileDefault()
          },
        }
      },
    }
  },
}
