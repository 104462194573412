import { validateEmail } from '@pretto/bricks/core/utility/form/validateEmail'
import { validatePhone } from '@pretto/bricks/core/utility/form/validatePhone'
import Link from '@pretto/bricks/website/utility/Link'

import * as filters from '@pretto/app-core/lib/filters'

import { ChalkboardTeacher, FileCheck, Lock, Pen, Phone } from '@pretto/picto'

export const formProspect = {
  content: [
    {
      data: {
        components: [
          'Vous souhaitez vous identifier avec ',
          {
            name: 'identify',
            props: {
              options: [
                { label: 'votre adresse email', value: 'email' },
                { label: 'votre compte Google', value: 'google' },
              ],
              placeholder: 'votre adresse email',
            },
            type: 'select',
          },
          '.',
        ],
      },
      type: 'sentence',
    },
    {
      condition: (authContext, userContext, { identify }) => identify === 'email',
      data: {
        components: [
          'Votre email ',
          ({ isLoggedIn }, { user }) =>
            isLoggedIn
              ? user.email
              : {
                  name: 'email',
                  props: {
                    placeholder: 'jean.dujardin@gmail.com',
                  },
                  type: 'email',
                  validation: {
                    test: value => value && validateEmail(value),
                    title: "l'adresse email",
                  },
                },
          '.',
        ],
      },
      type: 'sentence',
    },
  ],
  disclaimer: (authContext, userContext, { identify }) =>
    !!identify && (
      <>
        En continuant vous créez un compte Pretto et acceptez nos{' '}
        <Link href="https://www.pretto.fr/cgu/">conditions d’utilisation</Link> et notre{' '}
        <Link href="https://www.pretto.fr/privacy/">politique de confidentialité</Link>.
      </>
    ),
}

export const formPrelead = {
  content: [
    {
      data: {
        components: [
          'Vous vous appelez ',
          ({ isLoggedIn }, { user }) =>
            (isLoggedIn && user.firstName) || {
              name: 'firstName',
              props: {
                placeholder: 'Prénom',
              },
              type: 'text',
            },
          ({ isLoggedIn }, { user }) =>
            (isLoggedIn && user.lastName) || {
              name: 'lastName',
              props: {
                placeholder: 'Nom',
              },
              type: 'text',
            },
          '.',
        ],
      },
      type: 'sentence',
    },
    {
      data: {
        components: [
          'Votre numéro de téléphone : ',
          ({ isLoggedIn }, { user }) =>
            (isLoggedIn && filters.formatDecodePhone(user.phone)) || {
              name: 'phone',
              props: {
                placeholder: '07 12 34 56 78',
              },
              type: 'phone',
              validation: {
                test: value => validatePhone(value),
                title: 'le numéro de téléphone',
              },
            },
          ' * .',
        ],
      },
      type: 'sentence',
    },
  ],
  disclaimer:
    '* Pas d’inquiétude ! Vos données personnelles sont protégées et utilisées seulement pour votre recherche de prêt.',
}

export const getListSidebar = projectKind => {
  switch (projectKind) {
    case 'capacity':
      return [
        {
          icon: ChalkboardTeacher,
          text: 'Affinez votre projet avec votre Expert crédit dédié et bénéficiez de conseils personnalisés.',
        },
        {
          icon: FileCheck,
          text: 'Rassurez vendeur ou agent sur votre capacité à trouver un financement grâce à l’attestation Pretto.',
        },
        {
          icon: Lock,
          text: 'Complétez votre dossier en ligne, facilement et en toute sécurité depuis votre espace perso.',
        },
      ]
    case 'purchase':
    default:
      return [
        {
          icon: Phone,
          text: 'Affinez votre projet avec votre Expert crédit dédié et bénéficiez de conseils personnalisés.',
        },
        {
          icon: Pen,
          text: 'Explorez vos options de financement et mettez à jour votre simulation à votre rythme.',
        },
        {
          icon: Lock,
          text: 'Complétez votre dossier en ligne, facilement et en toute sécurité depuis votre espace perso.',
        },
      ]
  }
}
