import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'
import { BlocPretto as BlocPrettoComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/BlocPretto/BlocPretto'

import { useBlocPrettoProps } from './lib/useBlocPrettoProps'

export const BlocPretto: React.FC<NavigationProps> = (props: NavigationProps) => {
  const blocPrettoProps = useBlocPrettoProps(props)

  return <BlocPrettoComponent {...blocPrettoProps} />
}
