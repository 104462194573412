import TipBubble from '@pretto/bricks/app/sentences/components/TipBubble'

import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Message = ({ children, avatarSrc }) => <Bubble avatarSrc={avatarSrc}>{children}</Bubble>

Message.propTypes = {
  children: PropTypes.node.isRequired,
  avatarSrc: PropTypes.string,
}

const Bubble = styled(TipBubble)`
  margin: 0;
`
