import { ResizableField } from '@pretto/app/src/Sentences/v2/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { formatFloat } from '@pretto/app/src/Sentences/v2/lib/float'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { Bank, useBankField } from '@pretto/app/src/Sentences/v2/lib/useBankField'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import BANKS from '@pretto/config/banks.json'

import { useState } from 'react'

export const OfferPage: React.FC = () => {
  const context = useSentences()
  const { competitionBank, competitionDuration, competitionRate, setContext } = context
  const { fieldProps: bankFieldProps, results: bankResults } = useBankField(
    BANKS.find(elem => elem.value === competitionBank)?.label || ''
  )
  const [displayedRate, setDisplayedRate] = useState<string>(competitionRate?.toString() || '')

  const getDefaultActiveField = () => {
    if (competitionDuration) {
      return 2
    }
    if (competitionRate) {
      return 1
    }
    if (competitionBank) {
      return 0
    }
  }
  const [activeField, setActiveField] = useActiveField(getDefaultActiveField())

  const mapBankValues: MapToChoices<Bank[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: competitionBank === value,
      onClick: () => {
        bankFieldProps.onChange(label)
        setContext({ competitionBank: value })
      },
      label,
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'offer' } }}>
      <Title>{t('sentences.competition.title')}</Title>
      <div>
        {t('sentences.competition.offer.sentences.0')}
        <ResizableField
          {...bankFieldProps}
          clearable
          inputProps={{ placeholder: t('sentences.competition.bankPlaceholder'), spellCheck: false }}
          onClick={() => {
            setActiveField(0)
          }}
        />
        {activeField > 0 && (
          <>
            {t('sentences.competition.offer.sentences.1')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'competitionRate' } }}
              inputProps={{ placeholder: '4,2', type: 'number' }}
              onChange={(value: string) => {
                setActiveField(2)
                const rate = formatFloat(value)
                setDisplayedRate(rate)
                setContext({ competitionRate: parseFloat(rate) })
              }}
              onClick={() => {
                setActiveField(1)
              }}
              value={displayedRate}
            />
            {t('sentences.competition.offer.sentences.2')}
          </>
        )}
        {activeField > 1 && (
          <>
            {t('sentences.competition.offer.sentences.3')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'competitionDuration' } }}
              inputProps={{ maxLength: 2, placeholder: '20', type: 'integer' }}
              onChange={(value: string) => {
                setContext({ competitionDuration: parseInt(value) })
              }}
              onClick={() => {
                setActiveField(2)
              }}
              value={competitionDuration?.toString() || ''}
            />
            {t('sentences.competition.offer.sentences.4')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapBankValues(bankResults)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'competitionBank' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}
      {competitionDuration && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
