import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const BlockInfoPrettoSearch = ({ mobileOs, onClickStore }) => {
  const handleClick = platform => () => {
    onClickStore(platform)
  }

  return (
    <S.AppAd>
      <S.Container>
        <S.Aggregator>
          <S.Headline>Toutes vos annonces dans une seule app&nbsp;!</S.Headline>
          <Responsive min="laptop">
            <S.AggregText>
              L’agrégateur d’annonces immobilières qui rend plus simple et efficace votre recherche de bien.
            </S.AggregText>
            <S.ButtonApp onClick={handleClick('app_store')} slug="AppStore" />
            <S.ButtonApp onClick={handleClick('google_play_store')} slug="GooglePlayStore" />
          </Responsive>
          <Responsive max="laptop">
            <S.AggregText>Téléchargez l’application Pretto Search</S.AggregText>
            <S.ImgContainer>
              {mobileOs === 'iOS' ? (
                <S.ButtonApp onClick={handleClick('app_store')} slug="AppStore" />
              ) : (
                <S.ButtonApp onClick={handleClick('google_play_store')} slug="GooglePlayStore" />
              )}
              <S.Phone $width={12} />
            </S.ImgContainer>
          </Responsive>
        </S.Aggregator>
        <Responsive min="laptop">
          <S.Phone $width={23} />
        </Responsive>
      </S.Container>
    </S.AppAd>
  )
}

BlockInfoPrettoSearch.propTypes = {
  mobileOs: PropTypes.string,
  onClickStore: PropTypes.func.isRequired,
}

export default BlockInfoPrettoSearch
