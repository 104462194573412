import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

const handleStyleGrabbing = css`
  cursor: grabbing;
`

const handleStyleNeutral = css`
  border-radius: ${g(3, 4)};
  cursor: grab;
  height: ${g(3, 4)};
  width: ${g(3, 4)};

  -webkit-appearance: none;
`

export const InputControl = styled.input`
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  pointer-events: all;
  position: absolute;
  top: 0;
  width: 100%;

  -webkit-appearance: none;

  input[type='range']&::-moz-range-thumb {
    ${handleStyleNeutral}
  }

  input[type='range']&::-ms-thumb {
    ${handleStyleNeutral}
  }

  input[type='range']&::-webkit-slider-thumb {
    ${handleStyleNeutral}

    -webkit-appearance: none;
  }

  input[type='range']&:active::-moz-range-thumb {
    ${handleStyleGrabbing}
  }

  input[type='range']&:active::-ms-thumb {
    ${handleStyleGrabbing}
  }

  input[type='range']&:active::-webkit-slider-thumb {
    ${handleStyleGrabbing}
  }
`

export const Slider = styled.div`
  box-sizing: border-box;
  height: ${g(3, 4)};
  padding-top: ${g(1, 4)};
  position: relative;
`

export const SliderContainer = styled.div`
  pointer-events: none;
  position: relative;
`

export const ProgressMask = styled.div`
  background-color: ${({ $railVariant, theme }) => theme.colors[$railVariant]};
  border-radius: ${g(1 / 2)};
  height: ${g(1 / 2)};
  overflow: hidden;
  transform-origin: left center;
`

export const Progress = styled(ProgressMask)`
  background-color: ${({ $color, theme }) => $color ?? theme.colors.primary2};
  border-radius: 0;
`

export const Handle = styled.div`
  ${handleStyleNeutral}
  background-color: white;
  border: ${g(1 / 2)} solid ${({ $color, theme }) => $color ?? theme.colors.primary2};
  box-sizing: border-box;
  position: absolute;
  top: 0;
  transition: box-shadow 400ms ease-out;
  z-index: 1;

  ${InputControl}:active + ${Slider},
  ${InputControl}:focus + ${Slider} & {
    box-shadow: 0 0 ${g(2, 4)} rgba(0, 0, 0, 0.2);
  }
`

export const Tooltip = styled.div`
  pointer-events: none;
  position: absolute;
  top: ${g(5)};
`
