import { g } from '@pretto/bricks/components/layout'

import { Divider } from '@pretto/app/src/Sentences/v2/components/Styled/Divider/Divider'
import { TrackedButtonChoice } from '@pretto/app/src/Sentences/v2/containers/TrackedButtonChoice/TrackedButtonChoice'
import { TrackingProps } from '@pretto/app/src/Sentences/v2/lib/tracking/types/event'

import styled from 'styled-components'

export interface ChoiceProps {
  key: string
  isSelected: boolean
  onClick: () => void
  label: string
}

interface ChoicesProps extends TrackingProps {
  choices: ChoiceProps[]
  onChoiceClick?: (key: string) => void
}

const Buttons = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: ${g(3)};
  padding-bottom: ${g(1)};
  margin-bottom: ${g(2)};

  & > * + * {
    margin-top: ${g(2)};
  }
`

const Button = styled(TrackedButtonChoice)`
  &:first-letter {
    text-transform: capitalize;
  }
`

export const Choices: React.FC<ChoicesProps> = ({
  choices,
  defaultEventTrackingType,
  events,
  onChoiceClick,
  ...props
}) => {
  return (
    <>
      <Divider />
      <Buttons {...props}>
        {choices.map(({ key, isSelected, onClick, label }) => {
          const handleClick = () => {
            onClick()
            if (onChoiceClick) onChoiceClick(key)
          }

          return (
            <Button
              defaultEventTrackingType={defaultEventTrackingType}
              events={events}
              key={key}
              isSelected={isSelected}
              onClick={handleClick}
            >
              {label}
            </Button>
          )
        })}
      </Buttons>
    </>
  )
}
