import PropTypes from 'prop-types'

import * as S from './styles'

const ENTER_KEY_CODE = 13
const SPACE_KEY_CODE = 32
const LEFT = 'left'
const RIGHT = 'right'

const TopNav = ({ iconLeft, iconLeftAction, iconRight, iconRightAction, isBorderRounded, style, title, ...props }) => {
  const validateKeyCode = keyCode => [ENTER_KEY_CODE, SPACE_KEY_CODE].includes(keyCode)

  const handleKeyDownLeft = e => {
    if (validateKeyCode(e.keyCode)) {
      e.preventDefault()
      iconLeftAction(e)
    }
  }

  const handleKeyDownRight = e => {
    if (validateKeyCode(e.keyCode)) {
      e.preventDefault()
      iconRightAction(e)
    }
  }

  return (
    <S.Container style={style} {...props}>
      {iconLeft && (
        <S.PictoWrapper
          $isBorderRounded={isBorderRounded}
          $side={LEFT}
          onClick={iconLeftAction}
          onKeyDown={handleKeyDownLeft}
        >
          <S.Picto $picto={iconLeft} />
        </S.PictoWrapper>
      )}
      <S.Title>{title}</S.Title>
      {iconRight && (
        <S.PictoWrapper
          $isBorderRounded={isBorderRounded}
          $side={RIGHT}
          onClick={iconRightAction}
          onKeyDown={handleKeyDownRight}
        >
          <S.Picto $picto={iconRight} />
        </S.PictoWrapper>
      )}
    </S.Container>
  )
}

TopNav.propTypes = {
  /** Left icon. */
  iconLeft: PropTypes.node,
  /** Left icon action. */
  iconLeftAction: PropTypes.func,
  /** Right icon. */
  iconRight: PropTypes.node,
  /** Right icon action. */
  iconRightAction: PropTypes.func,
  /** Specify if the TopNav top border is rounded */
  isBorderRounded: PropTypes.bool,
  /** Custom styles **/
  style: PropTypes.object,
  /** Title of TopNav. Should be a string or a node. */
  title: PropTypes.node,
}
TopNav.defaultProps = {
  iconLeftAction: () => {},
  iconRightAction: () => {},
}
export default TopNav
