import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { Profile, Project } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

export const mapPTZ: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const profile: Profile = structuredClone(updatedPayload.profile) ?? {}

  if (!isNil(context.firstTimeBuyer)) profile.first_time_buyer = context.firstTimeBuyer
  if (!isNil(context.fiscalIncomeNM2)) profile.fiscal_income_nm2 = context.fiscalIncomeNM2

  updatedPayload.profile = profile

  return {
    context,
    payload: updatedPayload,
  }
}
