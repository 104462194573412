import ButtonIline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import BookingWidgetController from '@pretto/app/src/Booking/Containers/BookingWidget'

import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${grid()};
  }
`
export const MainSection = styled.section`
  ${grid()};
  padding: ${g(2)} 0 ${g(4)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} 0 ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 5])};
    grid-row-start: 1;
    position: relative;
    z-index: 1;
    display: block;
    margin-left: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
    display: grid;
    grid-column-gap: ${g(3)};
    grid-template-columns: repeat(5, 1fr);
  }
`
export const MainBackgroundFill = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 6])};
    display: block;
    grid-row: 1;
    background-color: ${({ theme }) => theme.colors.white};
  }
`
export const BookingSection = styled.section`
  ${grid()};
  padding: ${g(4)} 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 7])};
    grid-row-start: 1;
    display: block;
  }
`

export const MainContainer = styled.div`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    grid-column: 2 / span 4;
  }
`
export const BookingContainer = styled.div`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, -3])};
  }
`
export const MainIllustration = styled(Image).attrs({
  path: '/v1637683149/zen/static-assets/illustrations/660x660/peopleMakingAgreement.svg',
  options: {
    width: 204,
    height: 204,
  },
})`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    width: 156px;
    height: 156px;
    grid-column-end: span 3;
    justify-self: center;
    align-self: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: 204px;
    height: 204px;
  }
`
export const MainContent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column-end: span 5;
  }
`
export const Title = styled.div`
  ${typo.heading32};
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${typo.heading48};
    margin-top: ${g(5)};
  }
`
export const IllustrationHeart = styled(Image).attrs({
  path: '/v1661415070/zen/static-assets/illustrations/165x165/Heart2.svg',
  options: {
    height: 40,
    width: 40,
  },
})`
  margin-left: ${g(1)};
  height: 40px;
  width: 40px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const Paragraph = styled.p`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    & + & {
      margin-top: ${g(3)};
    }
  }
`
export const BookingTitle = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    ${typo.heading32};
  }
`
export const BookingWidget = styled(BookingWidgetController)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`

export const ButtonSkip = styled(ButtonIline).attrs({ isSecondary: true })`
  text-align: center;
  margin-top: ${g(4)};
`
