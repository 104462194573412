import React, { useEffect, useState } from 'react'

export const useResize = (ref?: React.RefObject<HTMLElement>) => {
  const [state, setState] = useState({
    height: typeof window === 'undefined' || ref ? null : window.innerHeight,
    width: typeof window === 'undefined' || ref ? null : window.innerWidth,
  })

  const onResize = () => {
    setState(({ height, width }) => {
      if (!ref) {
        return {
          height: window.innerHeight,
          width: window.innerWidth,
        }
      }

      return {
        height: ref.current?.offsetHeight ?? height,
        width: ref.current?.offsetWidth ?? width,
      }
    })
  }

  useEffect(() => {
    window.addEventListener('resize', onResize, false)

    onResize()

    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return state
}
