import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, Grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${column([1, 6])};
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    display: grid;
    grid-template: auto / auto 1fr auto;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

export const List = styled.ul`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  will-change: scroll-position;

  &::-webkit-scrollbar {
    display: none;
    width: 0px !important;
  }
`

export const ListItem = styled(BaseTypo).attrs({ forwardedAs: 'li', typo: 'link12' })`
  position: relative;
  white-space: nowrap;

  &:after,
  ${List}:hover &:after {
    background-color: ${({ theme, $activeColor }) => ($activeColor ? $activeColor : theme.colors.primary4)};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    right: 0;
    pointer-events: none;
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms ease-out;
    will-change: transform;
  }

  &:after {
    ${({ $isActive }) =>
      $isActive &&
      css`
        transform: scaleX(1);
      `}
  }

  ${List}:hover &:hover:after {
    transform: scaleX(1);
  }
`

export const ListItemButton = styled.button`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.primary1};
  bottom: 0;
  color: inherit;
  display: none;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 200ms ease-out;
  will-change: opacity;
  z-index: 1;

  &:enabled {
    cursor: pointer;
    pointer-events: auto;
  }

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`

export const ListItemButtonMask = styled.span`
  display: flex;
  height: ${g(2)};
  overflow: hidden;
  width: ${g(2)};
`

export const ListItemButtonLeft = styled(ListItemButton)`
  left: 0;
  padding-left: ${g(5)};
  padding-right: ${g(3)};
`

export const ListItemButtonRight = styled(ListItemButton)`
  right: 0;
  padding-left: ${g(3)};
  padding-right: ${g(5)};
`

export const ListItemButtonChevron = styled.svg`
  flex-shrink: 0;
  transition: transform 200ms ease-out;
  will-change: transform;

  ${ListItemButtonLeft}:hover & {
    transform: translateX(${g(-2)});
  }

  ${ListItemButtonRight} & {
    transform: translateX(${g(-2)});
  }

  ${ListItemButtonRight}:hover & {
    transform: translateX(0);
  }
`

export const ListItemLink = styled(Link)`
  display: block;
  line-height: ${g(8)};
  padding-left: ${g(5)};
  padding-right: ${g(5)};
  transition: opacity 200ms ease-out;
  will-change: opacity;

  ${({ $isOpaqueLarge }) =>
    $isOpaqueLarge &&
    css`
      opacity: 0.6;
    `}

  ${({ $isOpaqueShrunk }) =>
    $isOpaqueShrunk &&
    css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        opacity: 0.6;
      }
    `}
`

export const Nav = styled(Grid).attrs({ forwardedAs: 'nav' })`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
`
