import Button from '@pretto/bricks/components/buttons/Button'
import ButtonSquare from '@pretto/bricks/components/buttons/ButtonSquare'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import styled, { css } from 'styled-components'

export interface BannerFolderProps {
  description: string
  documentProgress: { current: string; target: string }
  href?: string
  imageUrl?: string
  title: string
}

const BannerFolderView = ({ description, documentProgress, href, title, ...props }: BannerFolderProps) => (
  <MainContainer {...props} href={href}>
    <TitleDescriptionContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </TitleDescriptionContainer>

    {documentProgress && (
      <Row>
        <DocumentProgressContainer>
          <Title $noMargin>{documentProgress.current}/</Title>
          <DocumentProgressTarget>{documentProgress.target}</DocumentProgressTarget>
        </DocumentProgressContainer>

        <ButtonSquare variant={'secondary'} href={href} size="small" />
      </Row>
    )}

    {!documentProgress && (
      <Button isSecondary isArrowShown={false} href={href}>
        Télécharger
      </Button>
    )}
  </MainContainer>
)

const BannerFolderPhoneView = ({
  // TODO: Either remove unneeded variable or disable eslint rule (if used for cherry-picking)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  description,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  documentProgress,
  href,
  imageUrl,
  title,
  ...props
}: BannerFolderProps) => (
  <MainContainer {...props} href={href}>
    <StyledImage path={imageUrl} />
    <Title>{title}</Title>
    <StyledButtonSquare variant={'neutral'} href={href} size="small" />
  </MainContainer>
)

export const BannerFolder = (props: BannerFolderProps) => {
  return useBreakpointToValue(<BannerFolderPhoneView {...props} />, { tablet: <BannerFolderView {...props} /> })
}

/* eslint-disable no-magic-numbers */
interface TitleProps {
  $noMargin?: boolean
}

const RowStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MainContainer = styled(Link)`
  ${RowStyle};
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: 8px;
  justify-content: space-between;
  padding: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
`

const Row = styled.div`
  ${RowStyle};
`

const TitleDescriptionContainer = styled.div`
  max-width: 80%;
`

const Title = styled.h4<TitleProps>`
  ${typo.headline16};
  margin-bottom: ${({ $noMargin }) => ($noMargin ? g(0) : g(1))};
`

const Description = styled.p`
  ${typo.bodyBook16};
`

const StyledImage = styled(Image)`
  margin-right: ${g(2)};
  height: ${g(9)};
  width: ${g(9)};
`

const DocumentProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${g(3)};
`

const DocumentProgressTarget = styled.p`
  ${typo.bodyMedium16};
  margin-right: ${g(1)};
`

const StyledButtonSquare = styled(ButtonSquare)`
  background-color: transparent;
`
