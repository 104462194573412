import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { ErrorMessage } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { SciAssociation } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const SciAssociationPage: React.FC = () => {
  const context = useSentences()
  const { setContext, sciAssociation } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && sciAssociation))

  const saveSciAssociation = (value: SciAssociation) => {
    setContext({ sciAssociation: value })
    if (value !== SciAssociation.Complex) return goToNextRoute()
  }

  const mapSciAssociationValues: MapToChoices<SciAssociation[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: sciAssociation === value,
      onClick: () => saveSciAssociation(value),
      label: t(`sentences.introduction.sciAssociation.values.${value}`),
      value,
    }))
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sciAssociation' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.sciAssociation.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!sciAssociation}>
          {t(`sentences.introduction.sciAssociation.values.${sciAssociation || 'simple'}`)}
        </Prompt>
        {t('sentences.introduction.sciAssociation.sentence.1')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSciAssociationValues(Object.values(SciAssociation))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'sciAssociation' } }}
        />
      )}
      {sciAssociation === SciAssociation.Complex && (
        <ErrorMessage>{t(`sentences.introduction.sci.error.sciAssociation`)}</ErrorMessage>
      )}
      {sciAssociation === SciAssociation.Simple && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
