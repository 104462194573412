import { useBreakpoint } from '@pretto/bricks/assets/utility'
import Divider from '@pretto/bricks/components/dividers/Divider'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'

import ScoreBanner from './children/ScoreBanner'
import * as S from './styles'
import { details } from './utils'

const ToggleDetails = ({ onClick, cardData, isDetailsOpen, detailsText, projectProps, scoreFundability }) => {
  const { breakpoint } = useBreakpoint()
  const isWiderThanTablet = ['desktop', 'laptop'].includes(breakpoint)

  const renderList = useMemo(
    () =>
      details({ ...projectProps, ...cardData }).map(({ title, rows }, index) => (
        <Fragment key={index}>
          <S.DetailsTitle>{title}</S.DetailsTitle>
          {rows.map(({ label, picto, value, color, tooltip }, i) => (
            <S.Row key={i}>
              <S.Icon $picto={picto} />
              <S.Label>{label}</S.Label>
              {tooltip && isWiderThanTablet && (
                <S.Tooltip content={tooltip} maxWidth="344px">
                  <S.PictoInfo />
                </S.Tooltip>
              )}
              <S.DetailsValue $color={color}>{value}</S.DetailsValue>
            </S.Row>
          ))}
        </Fragment>
      )),
    [projectProps, cardData]
  )

  return (
    <>
      <S.ToggleDetails onClick={onClick}>
        <S.DetailsIcon $isOpen={isDetailsOpen} />
        {detailsText}
      </S.ToggleDetails>
      <CollapsibleRow appear in={isDetailsOpen} parentComponent={S.ResultCardDetails}>
        <S.DetailsWrapper>
          <Divider />
          {!!scoreFundability && scoreFundability.onClickScoreSeeMore && (
            <ScoreBanner {...scoreFundability} onClick={e => e.stopPropagation()} />
          )}
          <S.DetailsContent>{renderList}</S.DetailsContent>
        </S.DetailsWrapper>
      </CollapsibleRow>
    </>
  )
}

ToggleDetails.propTypes = {
  /** Information data passed to the card. */
  cardData: PropTypes.object.isRequired,
  cta: PropTypes.object,
  detailsText: PropTypes.string.isRequired,
  isCtaVisible: PropTypes.bool,
  isDetailsOpen: PropTypes.bool,
  onClick: PropTypes.func,
  projectProps: PropTypes.object.isRequired,
  scoreFundability: PropTypes.object,
}

export default ToggleDetails
