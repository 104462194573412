import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronRightBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

const getColors = theme => {
  switch (theme.variant) {
    default:
    case 'primary':
      return {
        bgColor: theme.designSystem === 'zen' ? 'accent1' : 'neutral1',
        fill: 'white',
      }
    case 'secondary':
      return {
        bgColor: 'neutral1',
        fill: 'white',
      }
    case 'neutral':
      return {
        bgColor: 'neutral4',
        fill: 'neutral1',
      }
  }
}

export const ButtonSquare = styled.button.attrs(({ href }) => ({ as: href ? Link : 'button' }))`
  align-items: center;
  background-color: ${({ theme }) => theme.colors[getColors(theme).bgColor]};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  transition: all 200ms ease-out;

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          border-radius: ${g(1 / 2)};
          height: ${g(4)};
          width: ${g(4)};
        `

      case 'medium':
        return css`
          border-radius: ${g(1 / 2)};
          height: ${g(6)};
          width: ${g(6)};
        `

      case 'big':
      default:
        return css`
          border-radius: ${g(1)};
          height: ${g(8)};
          width: ${g(8)};
        `
    }
  }}

  &:focus,
  &:hover {
    outline: 0px transparent;
  }
`

export const Picto = styled(ChevronRightBold)`
  color: ${({ theme }) => theme.colors[getColors(theme).fill]};
  height: ${g(2)};
  width: ${g(2)};
`
