import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import prettoInformations from '@pretto/config/pretto_informations.json'

import { memo } from 'react'

import * as S from './styles'

const CONTACT = [
  {
    address: `Finspot SAS - ${prettoInformations.address.number} ${prettoInformations.address.street} - ${prettoInformations.address.zipcode} ${prettoInformations.address.city}`,
    title: 'Propriétaire et Responsable du traitement',
  },
  {
    address: (
      <S.Link href="mailto:privacy@pretto.fr" target="blank">
        privacy@pretto.fr
      </S.Link>
    ),
    title: 'Courriel de contact du Propriétaire',
  },
]
const PrivacyContact = () => (
  <S.Contact>
    {CONTACT.map(({ title, address }, index) => (
      <S.Item key={index}>
        <S.Title>
          <SubHeading>{title}</SubHeading>
        </S.Title>
        <S.Address>{address}</S.Address>
      </S.Item>
    ))}
  </S.Contact>
)

export default memo(PrivacyContact)
