import BookingAppointmentSummary from '@pretto/bricks/app/booking/components/BookingAppointmentSummary'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const BookingEmbeddedPage = ({ appointmentSummaryProps, children }) => (
  <S.Page>
    {appointmentSummaryProps && (
      <Fragment>
        <BookingAppointmentSummary {...appointmentSummaryProps} />

        <S.RescheduleTitle>
          <SubHeading>Ou déplacer :</SubHeading>
        </S.RescheduleTitle>
      </Fragment>
    )}

    {children}
  </S.Page>
)

BookingEmbeddedPage.propTypes = {
  /** Props of <code>[BookingAppointmentSummary](/#!/BookingAppointmentSummary)</code> component. */
  appointmentSummaryProps: PropTypes.object,
  /** Descendants. */
  children: PropTypes.node,
}

export default memo(BookingEmbeddedPage)
