import temporal from '@pretto/bricks/core/utility/temporal'

import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getMortgagorTruncatedName } from '@pretto/app-core/application/lib/getMortgagorTruncatedName'

import BANKS from '@pretto/config/banks.json'
import DOCS from '@pretto/config/docs.json'

import capitalize from 'lodash/capitalize'
import orderBy from 'lodash/orderBy'

interface Document {
  groupIndex: number
  groupSubtitle: string | null
  groupTitle: string
  href: string
  date: string
  mortgagorIndex: string
  mortgagorName: string
  nextHref: string | null
  previousHref: string | null
  status: string
  title: string
}

export const getSortedDocuments = <
  T extends {
    document_status: string
    kind: keyof typeof DOCS
    meta?: {
      bank?: string | null
      month?: number | null
      mortgagor?: number | null
      year?: number | null
    } | null
    slug: string
    title: string
  },
  U extends { accounts: Array<{ firstName: string; lastName: string }> },
>(
  documents: T[],
  urlFormatter: (slug: string) => string,
  userContext: U,
  defaultPreviousDocumentHref: string | null = null,
  defaultNextDocumentHref: string | null = null
): Document[] => {
  const sortedDocuments = orderBy(
    documents.map(({ document_status, kind, meta, slug, title }) => ({
      get date() {
        const month = meta?.month
        const year = meta?.year

        if (typeof month === 'number' && typeof year === 'number') {
          return `${year.toString()}/${month.toString().padStart(2, '0')}`
        }

        if (typeof year === 'number') {
          return year.toString()
        }

        return ''
      },
      get groupIndex() {
        if (kind === 'other') {
          return 1
        }

        return 0
      },
      get groupMortgagorIndex() {
        if (this.groupTitle) {
          return this.mortgagorIndex
        }

        return ''
      },
      get groupSubtitle() {
        if (kind === 'other') {
          return DOCS.other.comments
        }

        return null
      },
      get groupTitle() {
        const bank = meta?.bank
        const month = meta?.month
        const year = meta?.year

        if (kind === 'other') {
          return DOCS.other.title
        }

        if (bank) {
          const bankLabel = BANKS.find(({ value }) => value === bank)?.label

          return `${DOCS[kind].name} - ${bankLabel}`
        }

        if (month || year) {
          return DOCS[kind].name
        }

        return ''
      },
      href: urlFormatter(slug),
      kind,
      get mortgagorIndex() {
        const mortgagor = meta?.mortgagor

        if (!mortgagor) {
          return ''
        }

        return mortgagor.toString()
      },
      mortgagorName: getMortgagorTruncatedName(meta, userContext.accounts),
      get nextHref() {
        if (documents.length < 2 || kind === 'other') {
          return null
        }

        const nextIndex = sortedDocuments.indexOf(this) + 1

        if (nextIndex > sortedDocuments.length - 1) {
          return defaultNextDocumentHref ?? null
        }

        const nextDocument = sortedDocuments[nextIndex]

        if (nextDocument.kind !== 'other') {
          return nextDocument.href
        }

        return null
      },
      get previousHref() {
        if (documents.length < 2 || kind === 'other') {
          return null
        }

        const previousIndex = sortedDocuments.indexOf(this) - 1

        if (previousIndex < 0) {
          return defaultPreviousDocumentHref ?? null
        }

        const previousDocument = sortedDocuments[previousIndex]

        if (previousDocument.kind !== 'other') {
          return previousDocument.href
        }

        return sortedDocuments[previousIndex].href
      },
      slug,
      status: convertStatusSlug(document_status),
      get title() {
        const month = meta?.month
        const year = meta?.year

        if (kind === 'impots' && year) {
          return `${year + 1} sur les revenus de ${year}`
        }

        if (month && year) {
          const writtenMonth = temporal()
            .month(month - 1)
            .format('MMMM')

          return capitalize(`${writtenMonth} ${year}`)
        }

        return title
      },
    })),
    ['groupIndex', 'groupTitle', 'groupMortgagorIndex', 'date', 'title', 'mortgagorIndex'],
    ['asc', 'asc', 'asc', 'desc', 'asc', 'asc']
  )

  return sortedDocuments
}
