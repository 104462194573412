import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${g(1)};

  & > * + * {
    margin-top: ${g(2)};
  }
`

export const Button = styled(ButtonFeature)`
  background-color: transparent;
`
