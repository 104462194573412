import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from './styles'

const Estimations = ({ onCguClick, onLegalMentionsClick, onPrivacyClick, onMentionsObligClick, ...props }) => (
  <S.Information {...props}>
    Offre non contractuelle : meilleures estimations Pretto sur la base des données déclaratives fournies, à confirmer
    par une étude complète des justificatifs.
    <br />
    Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
    <br />
    Pretto est une marque de la société FINSPOT. Retrouvez nos{' '}
    <Link href="https://www.pretto.fr/mentions-legales/" onClick={onLegalMentionsClick}>
      Mentions légales
    </Link>
    , nos{' '}
    <Link href="https://www.pretto.fr/mentions-obligatoires/" onClick={onMentionsObligClick}>
      Mentions Obligatoires
    </Link>
    , nos{' '}
    <Link href="https://www.pretto.fr/cgu/" onClick={onCguClick}>
      Conditions Générales d'Utilisation
    </Link>{' '}
    et notre{' '}
    <Link href="/privacy" onClick={onPrivacyClick} target="blank">
      Politique de Confidentialité
    </Link>
    .
  </S.Information>
)

Estimations.propTypes = {
  /** Clicked on the CGU link. */
  onCguClick: PropTypes.func,
  /** Clicked on the Legal mentions link. */
  onLegalMentionsClick: PropTypes.func,
  /** Clicked on the Mentions Oblig link. */
  onMentionsObligClick: PropTypes.func,
  /** Clicked on the Privacy link. */
  onPrivacyClick: PropTypes.func,
}

export default Estimations
