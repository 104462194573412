import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import AlertsFormPage from '@pretto/app/src/PropertySearch/Containers/AlertsFormPage'
import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import { mainSchema } from '@pretto/app/src/PropertySearch/config/schema'
import { CREATE_ACCOUNT } from '@pretto/app/src/apollo'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { createAlert, updateEmailFrequency } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'

const decodeValuesWithSections = ({ sections }) =>
  sections.reduce(
    (previous, { fields }) => ({
      ...previous,
      ...fields.reduce((previous, { defaultValue, name }) => {
        if (!name) {
          return previous
        }

        return { ...previous, [name]: defaultValue ?? null }
      }, {}),
    }),
    {}
  )

const AlertsCreatePage = ({ history, location }) => {
  const { isLoggedIn } = useAuth()

  const [createAccount] = useMutation(CREATE_ACCOUNT)

  const { flushCache } = usePropertySearch()

  const trackAction = useTracking()

  const handleSubmit = async data => {
    const { email, ...payload } = omit(data, 'via_email')

    let response

    if (isLoggedIn) {
      response = await createAlert(payload)
      await updateEmailFrequency(payload.frequency)
      flushCache()
    } else {
      const authToken = await createNewAccount(email)
      response = await createAlert(payload, authToken)
      await updateEmailFrequency(payload.frequency, authToken)
    }

    history.push(`/property-search/alerts/${response.id}/edit?created=true`)

    trackAction('PROPERTY_SEARCH_ALERT_CREATED', { id: response.id })
  }

  const createNewAccount = async email => {
    const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
    const attributionCookieLastClick = getAttributionCookieLastClickValue()
    const visitorId = await getVisitorId()

    const responseCreateAccount = await createAccount({
      variables: {
        attributionCookieFirstClick,
        attributionCookieLastClick,
        email,
        source: 'app_property_search_alert',
        visitorId,
      },
    })

    const { error, token: accessToken } = responseCreateAccount.data.create_account

    if (error) {
      throw new Error(error)
    }

    trackAction('SIGNED_UP', {
      signup_assign_project: false,
      signup_kind: 'property_search_alert',
      signup_newsletter_immo: false,
      signup_newsletter_taux: false,
      signup_property_search_alert: true,
    })
    return accessToken
  }

  const pageProps = {
    submitLabel: 'Créer mon alerte',
    submitLabelCondensed: 'Créer mon alerte',
  }

  const { email = null } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const decodedValues = decodeValuesWithSections(mainSchema)
  const defaultValues = email && !isLoggedIn ? { ...decodedValues, email } : decodedValues

  return <AlertsFormPage defaultValues={defaultValues} onSubmit={handleSubmit} pageProps={pageProps} />
}

AlertsCreatePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default AlertsCreatePage
