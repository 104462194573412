import LinkComponent from '@pretto/bricks/website/utility/Link'

import { ResizableField } from '@pretto/app/src/Sentences/v2/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { WarningMessage } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/v2/lib/useCityField'
import { SimulationType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { municipalitySearch } from '@pretto/places'

import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const Link = styled(LinkComponent)`
  text-decoration: underline;
`

export const PropertyUsagePage: React.FC = () => {
  const context = useSentences()
  const { setContext, propertyZipcode, usage, propertyCity, simulationType } = context
  const { nextRoute } = useRoutesGraph()
  const defaultValue = propertyCity && propertyZipcode ? `${propertyCity} (${propertyZipcode})` : ''
  const { fieldProps: cityFieldProps, results: cityResults } = useCityField(defaultValue)
  const [visibleCities, setVisibleCities] = useState(false)
  const [activeField, setActiveField] = useActiveField(
    propertyCity && propertyZipcode ? 1 : 0,
    Boolean(nextRoute && usage && propertyCity && propertyZipcode)
  )

  useEffect(() => {
    if (propertyZipcode && !propertyCity) {
      municipalitySearch.get(propertyZipcode, { limit: 1 }).then(results => {
        if (!Array.isArray(results) || !results[0] || !results[0].value) {
          return
        }
        const value = results[0].value

        setContext({
          propertyCity: value.city,
          propertyZipcode: value.zipcode,
          propertySearchLocalisations: [
            {
              city: value.city,
              zipcode: value.zipcode,
            },
          ],
        })
        cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
      })
    }
  }, [])

  const handleClickOnCity = (value: CityResult['value']) => {
    cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
    setVisibleCities(false)
    setContext({
      propertyCity: value.city,
      propertyZipcode: value.zipcode,
      propertySearchLocalisations: [
        {
          city: value.city,
          zipcode: value.zipcode,
        },
      ],
    })
  }

  const mapUsageValues: MapToChoices<Usage[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: usage === value,
      onClick: () => {
        setContext({ usage: value })
        if (value === Usage.Primary || value === Usage.Secondary || value === Usage.Rental) {
          setActiveField(1)
          setVisibleCities(true)
        }
      },
      label: t(`sentences.introduction.propertyUsage.values.${value}`),
    }))
  }

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected: propertyCity === value.city && propertyZipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  const propertyUsageSentenceType =
    simulationType &&
    [SimulationType.Buyout, SimulationType.Purchase, SimulationType.Renegotiation].includes(simulationType)
      ? simulationType
      : SimulationType.Purchase

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'propertyUsage' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t(`sentences.introduction.propertyUsage.sentence.${propertyUsageSentenceType}`)}
        <Prompt isFilled={!!usage} onClick={() => setActiveField(0)}>
          {t(`sentences.introduction.propertyUsage.values.${usage || 'primary'}`)}
        </Prompt>
        {activeField > 0 && (
          <>
            {t('sentences.introduction.propertyLocalisation.sentence')}
            <ResizableField
              {...cityFieldProps}
              clearable
              inputProps={{ autoFocus: activeField === 1, placeholder: 'Paris' }}
              onClick={() => {
                setVisibleCities(true)
                setActiveField(1)
              }}
            />
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <>
          <Choices
            choices={mapUsageValues(
              Object.values(
                simulationType === SimulationType.Buyout || simulationType === SimulationType.Renegotiation
                  ? [Usage.Primary, Usage.Secondary, Usage.Rental]
                  : Usage
              )
            )}
            events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'usage' } }}
          />
          {[Usage.Mixed, Usage.Pro].includes(usage as Usage) && (
            <WarningMessage>
              {t('sentences.introduction.propertyUsage.warning')}{' '}
              <Link href="https://pretto-fr.typeform.com/to/IZWjTwD6">Remplir le formulaire.</Link>
            </WarningMessage>
          )}
        </>
      )}
      {visibleCities && activeField === 1 && (
        <Choices
          choices={mapCityValues(cityResults)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyCity' } }}
        />
      )}
      {activeField >= 1 && usage && propertyCity && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
