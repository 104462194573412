import EditableRows from '@pretto/bricks/app/simulator/components/EditableRows'
import SimulationControls from '@pretto/bricks/app/simulator/components/SimulationControls'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { MagicWand } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const SimulationSidebar = ({ editableRowsProps, controls, onOptimize, projectKind, ...props }) => (
  <S.Content {...props}>
    <BaseTypo typo="heading3">Modifier</BaseTypo>
    <SimulationControls controls={controls} />
    <EditableRows {...editableRowsProps} />
    {projectKind === 'purchase' && <S.Button label="Optimisation automatique" onClick={onOptimize} picto={MagicWand} />}
  </S.Content>
)

SimulationSidebar.propTypes = {
  /** Props of <code>[EditableRows](/#!/EditableRows)</code> component. */
  editableRowsProps: PropTypes.object,
  /** Props of <code>[InformationRows](/#!/InformationRows)</code> component. */
  informationRowsProps: PropTypes.object,
  /** onClick function triggered when Button is clicked. */
  onOptimize: PropTypes.func,
  projectKind: PropTypes.oneOf(['capacity', 'purchase', 'renegotiation']),
  /** Props of <code>[SimulationControls](/#!/SimulationControls)</code> component. */
  controls: PropTypes.array,
}

export default memo(SimulationSidebar)
