import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

const ServicePurchaseOffer = () => {
  const {
    user: { email },
  } = useUser()
  const { trackFeature } = useTrackFeatureCard()

  const handleClick = () => {
    trackFeature('PURCHASE_OFFER_DASHBOARD_PRELEAD_CLICKED')
  }

  const props = {
    data: {
      email: encodeURIComponent(email),
      onClick: handleClick,
    },
    slug: 'purchaseOffer',
  }

  return <ServiceCard {...props} />
}

export default ServicePurchaseOffer
