import InputRadioGroup from '@pretto/bricks/components/form/InputRadioGroup'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import SectionWidget from './SectionWidget'
import * as S from './styles'

const SectionProject = ({
  bubbleText,
  onChangeProgress,
  onChangeProject,
  progress,
  projectKind,
  projectOptions,
  radioProps,
  widgetLinks,
  ...props
}) => {
  if (projectKind === 'default') {
    return <SectionWidget links={widgetLinks} />
  }

  return (
    <S.CardContainer {...props}>
      <S.Container>
        <S.Title>Votre projet</S.Title>
        <S.ProjectType>
          Type de projet:{' '}
          <S.Select value={projectKind} options={projectOptions} onChange={onChangeProject} size="large" />
        </S.ProjectType>
      </S.Container>
      <S.Container>
        <S.Left>
          <S.Bubble>
            <S.Renaud />
            <S.Progress>{nl2br(bubbleText)}</S.Progress>
          </S.Bubble>
          {radioProps && <InputRadioGroup {...radioProps} />}
        </S.Left>
        <S.Right>
          <IllustrationDeprecated name="man_bath" />
        </S.Right>
      </S.Container>
    </S.CardContainer>
  )
}

SectionProject.propTypes = {
  /** text to display in bubble */
  bubbleText: PropTypes.string,
  /** function to call when we change the progress radio */
  onChangeProgress: PropTypes.func,
  /** Function to call when we change the project select field */
  onChangeProject: PropTypes.func,
  /** buying step of the user (searching, found) */
  progress: PropTypes.string,
  /** whether it's a purchase or renegotiation */
  projectKind: PropTypes.string,
  /** array of objects to use for selectField */
  projectOptions: PropTypes.array,
  /** props to use for radio buttons */
  radioProps: PropTypes.object,
  /** widget links */
  widgetLinks: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.string.isRequired,
      purchase: PropTypes.string.isRequired,
      renegotiation: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default SectionProject
