// deprecated
// use instead: @pretto/app/src/lib/form/usePhoneField
import BaseInput from '@pretto/bricks/components/form/BaseInput'
import { validatePhone } from '@pretto/bricks/core/utility/form/validatePhone'

import { formatDecodePhone } from '@pretto/app-core/lib/filters'

import { t } from '@pretto/app/src/lib/i18n'

import { useState } from 'react'

import useFieldController from './useFieldController'

const validators = [
  value => ({
    condition: value && !validatePhone(value),
    isDebounced: true,
    message: t('form.invalidPhone'),
    state: 'error',
  }),
]

const usePhoneField = ({ defaultValue = '', inputProps, size, ...rootProps } = {}) => {
  const [value, setValue] = useState(formatDecodePhone(defaultValue))

  const { stateProps, ...fieldControls } = useFieldController(value, { inputProps, validators })

  const handleChange = value => {
    setValue(value)
  }

  const props = {
    size,
    ...rootProps,
    ...stateProps,
    inputProps: {
      ...inputProps,
      type: 'tel',
    },
    onChange: handleChange,
    value,
  }

  const component = <BaseInput {...props} />

  return { ...fieldControls, component, setValue, value }
}

export default usePhoneField
