import { createContext } from 'react'

interface ScoringContextProps {
  isHighPotential: boolean
}

const defaultContext: ScoringContextProps = {
  isHighPotential: false,
}

export const ScoringContext = createContext<ScoringContextProps>(defaultContext)
