import Sentence from '@pretto/bricks/app/sentences/components/Sentence'
import SwipeAction from '@pretto/bricks/app/sentences/components/SwipeAction'
import Icon from '@pretto/bricks/components/iconography/Icon'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './RemovableSentence.module.css'

export default class RemovableSentence extends PureComponent {
  static propTypes = {
    onRemove: PropTypes.func,
    sentenceProps: PropTypes.object,
    position: PropTypes.number,
  }
  render() {
    const { onRemove, sentenceProps, position } = this.props
    const mobileContent = (
      <div className={C.removableSentence}>
        <div className={C.removableSentenceRow}>
          <div className={C.removableSentenceRowLabel}>{position}</div>
          {onRemove && (
            <div onClick={onRemove} className={C.removableSentenceRowValue}>
              <Icon name="cross" />
            </div>
          )}
        </div>
        <Sentence {...sentenceProps} />
      </div>
    )
    return (
      <div>
        <Mobile>
          {onRemove ? <SwipeAction onClickAction={onRemove}>{mobileContent}</SwipeAction> : mobileContent}
        </Mobile>
        <Desktop>
          <div className={C.removableSentence}>
            <div className={C.removableSentenceContent}>
              <div className={C.removableSentenceContentPosition}>{position}</div>
              <div className={C.removableSentenceContentSentence}>
                <Sentence {...sentenceProps} />
              </div>
              {onRemove && (
                <div className={C.removableSentenceContentIcon} onClick={onRemove}>
                  <Icon name="cross" />
                </div>
              )}
            </div>
          </div>
        </Desktop>
      </div>
    )
  }
}
