import NumberField from '@pretto/bricks/components/form/NumberField'

import clamp from 'lodash/clamp'
import PropTypes from 'prop-types'

export const RangeField = ({ onChange, range, ...props }) => {
  const handleChange = value => onChange(clamp(value, range.min, range.max))

  return <NumberField {...props} format="form" onChange={handleChange} updateWidth={false} />
}

RangeField.propTypes = {
  onChange: PropTypes.func,
  range: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }),
  value: PropTypes.number,
}
