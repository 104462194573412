import TextArea from '@pretto/bricks/components/form/TextArea'

import PropTypes from 'prop-types'

export const BlocTextArea = ({ name, value, onUpdateValue }) => {
  const handleChange = value => {
    onUpdateValue(name, value)
  }

  return <TextArea onChange={handleChange} value={value ?? ''} />
}

BlocTextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
}
