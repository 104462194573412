import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'

export const isFieldValid = value => {
  if (isArray(value) && value.length < 1) {
    return false
  }

  if (typeof value === 'string' && value.length < 1) {
    return false
  }

  if (isObject(value)) {
    return Object.values(value).every(isFieldValid)
  }

  if (isBoolean(value)) {
    return value
  }

  return !isNil(value)
}
