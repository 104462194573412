import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import BlocSave from '@pretto/bricks/app/simulator/components/BlocSave'
import { LanguageInfo } from '@pretto/bricks/app/simulator/components/LanguageInfo/LanguageInfo'
import ScoreDetails from '@pretto/bricks/app/simulator/components/ScoreDetails'
import PreviousNextNavBar from '@pretto/bricks/components/bars/PreviousNextNavBar'
import { Grid } from '@pretto/bricks/components/layout/Grid'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo } from 'react'

import DialogSave from './modals/DialogSave'
import ModalModify from './modals/ModalModify'
import * as S from './styles'

const SimulationPage = ({
  contentComponent,
  cta,
  emailFieldComponent,
  estimationsProps,
  footerProps,
  isAppointmentAvailable,
  isDialogOpen,
  isLanguageInfoOpen,
  isLoggedIn,
  isSaveOpen,
  isScoreDetailsOpen,
  onCguClick,
  onClose,
  onCloseLanguageInfo,
  onCloseSave,
  onCloseScoreDetails,
  onGoogleConnect,
  onPrivacyClick,
  onValidate,
  scoreDetailsProps,
  simulationContentProps,
  simulationSidebarProps,
  simulationDetailsProps,
  typology,
}) => {
  const modalModifyProps = {
    cta,
    isDialogOpen,
    isLoggedIn,
    onClose,
    onValidate,
    simulationDetailsProps,
  }

  const saveProps = {
    emailFieldComponent,
    isSaveOpen,
    onCguClick,
    onCloseSave,
    onGoogleConnect,
    onPrivacyClick,
  }

  return (
    <LayoutStickyFooter>
      <Grid>
        <Responsive min="laptop">
          <S.Sidebar>
            <S.SidebarInputs {...simulationSidebarProps} />
            {typology === 'prospect' && (
              <S.SideContainer>
                <BlocSave
                  emailFieldComponent={emailFieldComponent}
                  onCguClick={onCguClick}
                  onGoogleConnect={onGoogleConnect}
                  onPrivacyClick={onPrivacyClick}
                />
              </S.SideContainer>
            )}
            {typology === 'preLead' && isAppointmentAvailable && (
              <S.SideContainer>
                <S.Headline>Un expert vous accompagne</S.Headline>
                <S.CTA isSecondary href="/simulation/subscribe?kind=appointment_result_page">
                  Prendre RDV (gratuit)
                </S.CTA>
              </S.SideContainer>
            )}
            <S.LegalText {...estimationsProps} />
          </S.Sidebar>
        </Responsive>
        {contentComponent || <S.Content {...simulationContentProps} />}
        <ModalModify {...modalModifyProps} />
        <DialogSave {...saveProps} />
        {!!scoreDetailsProps && (
          <Dialog isOpen={isScoreDetailsOpen} onRequestClose={onCloseScoreDetails}>
            <DialogTemplate
              navbarProps={{
                iconLeft: TimesBold,
                iconLeftAction: onCloseScoreDetails,
                title: `Votre projet est ${scoreDetailsProps.label}`,
              }}
            >
              <ScoreDetails {...scoreDetailsProps} />
            </DialogTemplate>
          </Dialog>
        )}
        <Dialog isOpen={isLanguageInfoOpen} onRequestClose={onCloseLanguageInfo}>
          <LanguageInfo onClose={onCloseLanguageInfo} onConfirm={onCloseLanguageInfo} />
        </Dialog>
      </Grid>

      {!!footerProps && <PreviousNextNavBar {...footerProps} />}
    </LayoutStickyFooter>
  )
}

SimulationPage.propTypes = {
  /** Alternative content component. */
  contentComponent: PropTypes.node,
  cta: PropTypes.object,
  /** Props of <code> [PreviousNextNavBar](/#!/PreviousNextNavBar)</code> component */
  dialogOption: PropTypes.object,
  emailFieldComponent: PropTypes.node,
  footerProps: PropTypes.object,
  isAppointmentAvailable: PropTypes.bool,
  /** Dialog with <code>SimulationDetails</code> component. */
  isDialogOpen: PropTypes.bool,
  /** LoggedIn state of user. */
  isLoggedIn: PropTypes.bool,
  isSaveOpen: PropTypes.bool,
  isScoreDetailsOpen: PropTypes.bool,
  onCguClick: PropTypes.func,
  /** onClick function triggered when cross icon is clicked. */
  onClose: PropTypes.func,
  onCloseSave: PropTypes.func,
  onCloseScoreDetails: PropTypes.func,
  onGoogleConnect: PropTypes.func,
  /** Validating the dialog simulaiton. */
  onPrivacyClick: PropTypes.func,
  onValidate: PropTypes.func,
  scoreDetailsProps: PropTypes.object,
  /** Props of <code>[SimulationContent](/#!/SimulationContent)</code> component. */
  simulationContentProps: PropTypes.object,
  /** Props of <code>[SimulationDetails](/#!/SimulationDetails)</code> component. */
  simulationDetailsProps: PropTypes.object,
  /** Props of <code>[SimulationSidebar](/#!/SimulationSidebar)</code> component. */
  simulationSidebarProps: PropTypes.object,
  typology: PropTypes.oneOf(['prospect', 'preLead', 'client']),
}
export default memo(SimulationPage)
