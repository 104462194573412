import Button from '@pretto/bricks/components/buttons/Button'

import * as S from './Modal.styles'

export interface ModalProps {
  emailComponent: React.ReactChild
  isButtonDisabled: boolean
  isButtonLoading: boolean
  onClickBack: (e: React.MouseEvent<HTMLElement>) => void
  onClickCgu: () => void
  onClickContinue: (e: React.FormEvent<HTMLFormElement>) => void
  onClickPrivacy: () => void
}

export const Modal = ({
  emailComponent,
  isButtonDisabled,
  isButtonLoading,
  onClickBack,
  onClickCgu,
  onClickContinue,
  onClickPrivacy,
}: ModalProps) => (
  <S.Modal>
    <S.Illustration />

    <S.Title>Créer mon offre d’achat</S.Title>

    <S.Warning>
      Attention, <strong>votre adresse email apparaîtra sur votre offre d’achat</strong>.
      <br />
      Assurez-vous qu’elle ne comporte pas d’erreur avant de continuer :
    </S.Warning>

    <S.Form onSubmit={onClickContinue}>
      <S.Field>{emailComponent}</S.Field>

      <Button type="submit" isArrowShown isDisabled={isButtonDisabled} isLoading={isButtonLoading}>
        Continuer
      </Button>
      <S.ButtonBack onClick={onClickBack} isArrowVisible>
        Retour
      </S.ButtonBack>
    </S.Form>

    <S.CGU>
      En continuant, vous acceptez nos{' '}
      <S.CGULink href="https://www.pretto.fr/cgu/" onClick={onClickCgu}>
        conditions d’utilisation
      </S.CGULink>{' '}
      et notre{' '}
      <S.CGULink href="/privacy" onClick={onClickPrivacy} target="blank">
        politique de confidentialité.
      </S.CGULink>
    </S.CGU>
  </S.Modal>
)
