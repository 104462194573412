import { Edge, Graph, Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'
import * as v from '@pretto/app/src/Sentences/v2/lib/validators/context'

import { allPass } from 'ramda'

// Introduction
const structureRoute = new Route('structure', '/project/buyout/introduction/structure')
const propertyUsageRoute = new Route('propertyUsage', '/project/buyout/introduction/propertyUsage')
const propertyTypeRoute = new Route('propertyType', '/project/buyout/introduction/propertyType')

export const buyoutGraph = new Graph('buyoutGraph', structureRoute)

buyoutGraph.addRoute(propertyUsageRoute)
buyoutGraph.addRoute(propertyTypeRoute)

buyoutGraph.addEdge(new Edge(structureRoute, propertyUsageRoute, v.isSolo))
buyoutGraph.addEdge(new Edge(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route('ageChildren', '/project/buyout/lodging/ageChildren')
const addressRoute = new Route('address', '/project/buyout/lodging/address')
const situationRoute = new Route('situation', '/project/buyout/lodging/situation')

buyoutGraph.addRoute(ageChildrenRoute)
buyoutGraph.addRoute(addressRoute)
buyoutGraph.addRoute(situationRoute)

buyoutGraph.addEdge(new Edge(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

buyoutGraph.addEdge(new Edge(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber])))
buyoutGraph.addEdge(new Edge(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route('expatriate', '/project/buyout/occupation/expatriate')
const sectorRoute = new Route('sector', '/project/buyout/occupation/sector')

buyoutGraph.addRoute(expatriateRoute)
buyoutGraph.addRoute(sectorRoute)

buyoutGraph.addEdge(new Edge(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled])))
buyoutGraph.addEdge(new Edge(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent])))

// Income
const salaryRoute = new Route('salary', '/project/buyout/income/salary')
const bonusRoute = new Route('bonus', '/project/buyout/income/bonus')
const revenueRoute = new Route('revenue', '/project/buyout/income/revenue')

buyoutGraph.addRoute(salaryRoute)
buyoutGraph.addRoute(bonusRoute)
buyoutGraph.addRoute(revenueRoute)

buyoutGraph.addEdge(new Edge(expatriateRoute, salaryRoute, v.isExpatriateFilled))
buyoutGraph.addEdge(new Edge(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled])))
buyoutGraph.addEdge(new Edge(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
buyoutGraph.addEdge(new Edge(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
buyoutGraph.addEdge(new Edge(bonusRoute, revenueRoute, v.isBonusFilled))

// Buyout
const buyoutRoute = new Route('buyout', '/project/buyout/buyout/buyout')

buyoutGraph.addRoute(buyoutRoute)

buyoutGraph.addEdge(new Edge(revenueRoute, buyoutRoute, v.isRevenueFilled))

// Charges
const creditsRoute = new Route('credits', '/project/buyout/credits/credits')
const childSupportRoute = new Route('childSupport', '/project/buyout/credits/childSupport')

buyoutGraph.addRoute(creditsRoute)
buyoutGraph.addRoute(childSupportRoute)

buyoutGraph.addEdge(new Edge(buyoutRoute, creditsRoute, v.isBuyoutFilled))
buyoutGraph.addEdge(new Edge(creditsRoute, childSupportRoute, v.isCreditsFilled))

// Contribution
const contributionRoute = new Route('contribution', '/project/buyout/contribution/contribution')
const savingsRoute = new Route('savings', '/project/buyout/contribution/savings')

buyoutGraph.addRoute(contributionRoute)
buyoutGraph.addRoute(savingsRoute)

buyoutGraph.addEdge(new Edge(childSupportRoute, contributionRoute, v.hasNotPTZ))
buyoutGraph.addEdge(new Edge(contributionRoute, savingsRoute, v.isContributionFilled))

// Simulation
const simulationRoute = new Route('simulation', '/simulation')

buyoutGraph.addRoute(simulationRoute)

buyoutGraph.addEdge(new Edge(savingsRoute, simulationRoute, v.isSavingsFilled))
