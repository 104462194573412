import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Message = styled.div`
  margin-bottom: ${g(1)};

  &:last-child {
    margin-bottom: 0;
  }
`
export const Content = styled.div`
  height: 100%;
  border-radius: ${g(1)};
  overflow: hidden;
`
export const Alert = styled.div`
  margin-top: ${g(5)};
`
