import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const TimelineProgressBar = ({ backgroundColor, color, max, value, ...props }) => (
  <S.Progress $completed={value === max} $backgroundColor={backgroundColor} {...props}>
    <S.Content max={max} value={value} $color={color} />
  </S.Progress>
)

TimelineProgressBar.defaultProps = {
  backgroundColor: 'neutral4',
  color: 'accent1',
  max: 2,
  value: 0,
}

TimelineProgressBar.propTypes = {
  /** color of background. */
  backgroundColor: PropTypes.string,
  /** color of Progress bar. */
  color: PropTypes.string,
  /** Maximum value of Progress. */
  max: PropTypes.number,
  /** Current value of Progress. */
  value: PropTypes.number,
}

export default memo(TimelineProgressBar)
