import { g } from '@pretto/bricks/components/layout'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold, InfoCircleBold, Picto } from '@pretto/picto'

import styled from 'styled-components'

export enum States {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Message = 'message',
}

const styles = {
  [States.Error]: {
    color: 'error1',
    backgroundColor: 'error2',
    picto: ExclamationMarkCircleBold,
    pictoFill: 'error1',
  },
  [States.Warning]: {
    color: 'warning1',
    backgroundColor: 'warning2',
    picto: ExclamationMarkTriangleBold,
    pictoFill: 'warning1',
  },
  [States.Info]: {
    color: 'neutral1',
    backgroundColor: 'neutral4',
    picto: InfoCircleBold,
    pictoFill: 'primary1',
  },
  [States.Message]: {
    color: 'neutral1',
    backgroundColor: 'accent3',
    picto: ExclamationMarkCircleBold,
    pictoFill: 'accent1',
  },
}

interface ContainerProps {
  $color: string
  $backgroundColor: string
}

const Container = styled.div<ContainerProps>`
  padding: ${g(2)};
  display: flex;
  border-radius: ${g(1)};
  background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
  color: ${({ theme, $color }) => theme.colors[$color]};
  ${({ theme }) => theme.typos.body4};
`

interface PictoProps {
  $picto: Picto
  $fill: string
}

const Icon = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  margin-right: ${g(2)};
  flex-shrink: 0;
  color: ${({ theme, $fill }) => theme.colors[$fill]};
  fill: ${({ theme, $fill }) => theme.colors[$fill]};
  height: ${g(3)};
`

export interface MessageProps {
  state?: States
  html?: string
  children?: React.ReactNode
}

export const Message: React.FC<MessageProps> = ({ children, state = States.Info, html, ...props }) => {
  const { color, backgroundColor, picto, pictoFill } = styles[state]

  return (
    <Container $color={color} $backgroundColor={backgroundColor} {...props}>
      <Icon $picto={picto} $fill={pictoFill} />
      {html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : <div>{children}</div>}
    </Container>
  )
}
