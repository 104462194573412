import { useBreakpoint } from '@pretto/bricks/assets/utility'
import NavItem from '@pretto/bricks/components/bars/NavBar/components/NavItem'

import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import * as S from './styles'

const HOME_URL = '/'
const DEFAULT_GO_BACK_LABEL = ' Retour'

const NavBar = ({
  accountVariant,
  backgroundColor,
  goBackProps,
  isAccountFocused,
  isMenuOpen,
  isMobileHidden,
  loginButtonLabel,
  mode,
  navigationItemsList,
  onAccountClick,
  onAccountKeyDown,
  partnerData,
  user,
  ...props
}) => {
  const accountRef = useRef()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    if (isAccountFocused) {
      accountRef.current.focus()
    }
  }, [isMenuOpen])

  return (
    <S.NavBar $backgroundColor={backgroundColor} $isMobileHidden={isMobileHidden} $mode={mode} {...props}>
      {goBackProps && mode === 'default' && (
        <S.ButtonBackInline
          href={goBackProps.href}
          onClick={goBackProps.onGoBackClick}
          label={goBackProps.label || DEFAULT_GO_BACK_LABEL}
        />
      )}

      {!goBackProps && mode === 'default' && (
        <S.LogosContainer>
          <S.LogoPrettoContainer href={HOME_URL}>
            <S.LogoPretto $isIcon={isMobile && !!partnerData?.logo} data-testid="mainLogo" />
          </S.LogoPrettoContainer>
          {partnerData?.logo && (
            <>
              <S.CrossPicto />
              <S.PartnerLogoContainer $margin={partnerData.logoMargin} href={partnerData.path || HOME_URL}>
                <S.PartnerLogo path={partnerData.logo} />
              </S.PartnerLogoContainer>
            </>
          )}
        </S.LogosContainer>
      )}

      {mode === 'default' && (
        <S.Navigation>
          {navigationItemsList.map(({ id, ...item }) => {
            return <NavItem key={id} {...item} />
          })}
        </S.Navigation>
      )}

      {accountVariant && (
        <S.NavAccount
          $mode={mode}
          accountVariant={accountVariant}
          aria-controls="menu"
          aria-expanded={isMenuOpen}
          aria-haspopup="true"
          id="menubutton"
          loginButtonLabel={loginButtonLabel}
          onClick={onAccountClick}
          onKeyDown={onAccountKeyDown}
          ref={accountRef}
          user={user}
        />
      )}
    </S.NavBar>
  )
}

NavBar.propTypes = {
  /** Color of the background */
  backgroundColor: PropTypes.string,
  /** Variant of the account block */
  accountVariant: PropTypes.oneOf(['loggedIn', 'signIn', 'signUp', 'onlyInitial']),
  /** Props of Go back button */
  goBackProps: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    onGoBackClick: PropTypes.func,
  }),
  mode: PropTypes.oneOf(['default', 'compact']),
  /** Is account block focused ? */
  isAccountFocused: PropTypes.bool.isRequired,
  /** Is menu open ? */
  isMenuOpen: PropTypes.bool,
  /** Is the nav bar hidden on mobiles ? */
  isMobileHidden: PropTypes.bool,
  /** List of the items of the navigation */
  navigationItemsList: PropTypes.array,
  /** Triggered when click on account block */
  onAccountClick: PropTypes.func,
  /** Triggered when keydonw on account block */
  onAccountKeyDown: PropTypes.func,
  /** Informations about partner */
  partnerData: PropTypes.object,
  /** User informations */
  user: PropTypes.object,
  /** Override the login button label for English version */
  loginButtonLabel: PropTypes.string,
}

NavBar.defaultProps = {
  mode: 'default',
  navigationItemsList: [],
}

export default NavBar
