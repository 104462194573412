import * as S from './MenuItemExpert.styles'

export interface MenuItemExpertProps {
  name: string
  picture: string
  vacation?: { startDate: string | null; endDate: string | null }
}

export const MenuItemExpert = ({ vacation, name, picture, ...props }: MenuItemExpertProps) => {
  const startDate = vacation?.startDate ?? null
  const endDate = vacation?.endDate ?? null
  const isUnavailable = !!(startDate && endDate)
  const isSingleDay = startDate && endDate && startDate === endDate

  return (
    <S.MenuItemExpertContainer $isUnavailable={isUnavailable} {...props}>
      <S.MenuItemExpert>
        <S.AvatarContainer>
          <S.Avatar src={picture} isWithOverlay={isUnavailable} isWithWarningIcon={isUnavailable} />
        </S.AvatarContainer>
        <S.Text>
          <S.Title>Votre Expert</S.Title>
          <S.Name>{name}</S.Name>
        </S.Text>
      </S.MenuItemExpert>

      {isUnavailable && (
        <S.VacationContainer>
          {isSingleDay ? (
            <>Absent le {startDate}</>
          ) : (
            <>
              Absent du {startDate} au {endDate}
            </>
          )}
        </S.VacationContainer>
      )}
    </S.MenuItemExpertContainer>
  )
}
