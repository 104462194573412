import { caption12 } from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

export const CGU: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = props => (
  <Container {...props}>
    <Trans
      i18nKey="pushy.cgu.acceptTerms"
      components={{
        CguLink: <Link href="https://www.pretto.fr/cgu/" />,
        PrivacyLink: <Link href="/privacy" target="blank" />,
      }}
    />
  </Container>
)

export const Container = styled.p`
  ${caption12};
  color: ${({ theme }) => theme.colors.neutral2};

  a {
    text-decoration: underline;
  }
`
