import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Tag = styled.div`
  margin-bottom: ${g(2)};
  max-width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(2)};
  }
`

export const Tags = styled.div`
  margin-bottom: ${g(-1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    margin-right: ${g(-1)};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: ${g(5)};

  > button {
    background-color: transparent;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: center;
    align-items: center;
  }
`
