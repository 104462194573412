import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import Prompt from '@pretto/bricks/shared/components/Prompt'

export const NavigationPrompt = ({ when = false, onContinueClick }) => {
  const unblock = useRef(null)

  const history = useHistory()

  const [isOpened, setIsOpened] = useState(false)
  const [navigationState, setNavigationState] = useState(null)

  const getRouteAction = (isContinueAction = false) => {
    switch (navigationState.action) {
      case 'POP':
        history.goBack()
        break

      case 'PUSH':
        history.push(navigationState.pathname, { isContinueAction })
        break

      case 'REPLACE':
        history.replace(navigationState.pathname, { isContinueAction })
        break
      default:
    }
  }

  useEffect(() => {
    unblock.current = history.block((location, action) => {
      setNavigationState({ action, pathname: location.pathname + location.search })
      setIsOpened(when)

      return !when
    })

    return () => {
      unblock.current?.()
    }
  }, [history, when])

  const handleCancel = () => {
    setIsOpened(false)
  }

  const handleLeave = useCallback(
    isContinueAction => {
      unblock.current?.()
      getRouteAction(isContinueAction)
    },
    [history, navigationState]
  )

  const handleConfirm = () => {
    handleLeave(true)
    onContinueClick()
  }

  return (
    <Prompt
      buttonTitle={'Enregistrer les modifications'}
      description={
        'Attention vous n’avez pas sauvegardé vos informations. En continuant votre navigation vous perdrez vos modifications. Que souhaitez-vous faire ?'
      }
      isOpen={isOpened}
      linkTitle={'Continuer sans enregistrer'}
      onContinueClick={handleConfirm}
      onClose={handleCancel}
      onCancelClick={handleLeave}
      title={'Enregistrez vos modifications'}
    />
  )
}

NavigationPrompt.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  when: PropTypes.bool.isRequired,
}
