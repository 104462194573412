import { Redirect } from 'react-router-dom'

import { usePropertySearch } from '../PropertySearchProvider'

const AlertsDispatcher = () => {
  const { data } = usePropertySearch()

  if (!data) {
    return <Redirect to="/property-search/alerts/create" />
  }

  return <Redirect to={`/property-search/alerts/${data.id}/edit`} />
}

export default AlertsDispatcher
