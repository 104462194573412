import Confirm from '@pretto/bricks/app/confirm/pages/ConfirmPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import AdvisorAvatar from '../../../SharedContainers/AdvisorAvatar'
import Header from '../../../SharedContainers/Header'
import { CONFIRM, SUBMIT_PROJECT } from '../../../apollo'
import { MONTHS_IN_YEAR } from '../../../lib/constants'
import { useTracking } from '../../../lib/tracking'
import { COMPLETE_PERCENTAGE, CONFIG, DISCLAIMERS, NOTIFICATIONS, SUMMARY_LABELS, TITLES } from '../../config/submit'
import { useApplication } from '../ApplicationContext'

const LOAN_VALUES = ['project.request.duration', 'analysis.payment']

const formatValuesWithLocale = (...values) => `${values.map(value => value.toLocaleString('fr')).join(' - ')} €`

const PURCHASE_VALUES = [
  'project.purchase.property_price',
  'project.purchase.works_price',
  'project.purchase.land_price',
  'project.purchase.build_price',
  'project.purchase.furniture_price',
  'analysis.bridge.remaining',
  'project.facts.notary_fees',
  'analysis.guarantyFees',
  'brokerage_fees.amount',
  'project.facts.agency_fees',
  'project.contribution',
  'analysis.bridge.value',
  'analysis.loanAmount',
  'analysis.ptz.amount',
]

const RENEGOTIATION_VALUES = [
  'project.renegotiation.remaining_principal',
  'analysis.repurchase_fees',
  'project.renegotiation.works_amount',
  'analysis.guarantyFees',
  'brokerage_fees.amount',
  'project.contribution',
  'analysis.loanAmount',
]

const PAYMENT_RATIO = 1.02
const PAYMENT_ROUND = 10

const ApplicationSubmitPage = ({ history }) => {
  const client = useApolloClient()
  const { completionPercentage } = useApplication()
  const { data, loading } = useQuery(CONFIRM)
  const [submitProject] = useMutation(SUBMIT_PROJECT)
  const [mutating, setMutating] = useState(false)
  const { notifyLegacy } = useNotifications()
  const { isImpersonated } = useAuth()
  const trackAction = useTracking()

  const getValues = (values, data) =>
    values.reduce((previous, path) => {
      const value = get(data, path)

      if (!value) {
        return previous
      }

      const title = SUMMARY_LABELS[path]
      const slug = path.split('.').pop()

      switch (path) {
        case 'project.request.duration': {
          const duration = value / MONTHS_IN_YEAR

          if (Math.floor(duration) === duration) {
            return [...previous, { onAction: handleEdit(slug), title, value: `${duration} ans` }]
          }

          return [...previous, { onAction: handleEdit(slug), title, value: `${value} mois` }]
        }

        case 'analysis.payment': {
          const fromValue = Math.floor(value / PAYMENT_ROUND) * PAYMENT_ROUND
          const toValue = Math.ceil((value * PAYMENT_RATIO) / PAYMENT_ROUND) * PAYMENT_ROUND

          return [
            ...previous,
            {
              onAction: handleEdit(slug),
              title,
              value: formatValuesWithLocale(fromValue, toValue),
            },
          ]
        }

        case 'project.contribution':
        case 'project.renegotiation.remaining_principal':
        case 'project.purchase.property_price':
        case 'project.purchase.works_price':
        case 'project.purchase.land_price':
        case 'project.purchase.build_price':
          return [...previous, { onAction: handleEdit(slug), title, value: formatValuesWithLocale(value) }]

        case 'project.facts.notary_fees':
        case 'project.facts.agency_fees':
          return [...previous, { rightSpaced: true, title, value: formatValuesWithLocale(Math.floor(value)) }]

        default:
          return [...previous, { rightSpaced: true, title, value: formatValuesWithLocale(value) }]
      }
    }, [])

  const handleEdit = slug => () => {
    trackAction('APPLICATION_SUBMIT_EDIT_ICON_CLICKED', { event_origin: `row_${slug}` })

    history.push('/simulation')
  }

  const handleConfirm = () => {
    setMutating(true)

    submitProject({ update: updateAfterQuery })
  }

  const updateAfterQuery = async (cache, response) => {
    const isComplete = completionPercentage === COMPLETE_PERCENTAGE

    if (response.data.submit_project.success) {
      await invalidateCache(client)

      notifyLegacy(NOTIFICATIONS.title, NOTIFICATIONS.content[isComplete ? 'complete' : 'incomplete'])

      trackAction('APPLICATION_SUBMIT_BUTTON_CLICKED')

      const path = isComplete ? '/' : '/application/documents?status=idle'

      history.push(path)
    }
  }

  const confirmPageProps = () => {
    const {
      good: { usage },
      project_kind: projectKind,
    } = data.project

    const projectValues = getValues(projectKind === 'purchase' ? PURCHASE_VALUES : RENEGOTIATION_VALUES, data)
    const loanValues = getValues(LOAN_VALUES, data)
    const { loanTitle, projectTitle } = TITLES[projectKind]
    const config = {
      ...CONFIG,
      loanTitle,
      projectTitle,
    }
    const disclaimers = DISCLAIMERS(completionPercentage)

    return {
      avatarProps: { src: <AdvisorAvatar size="small" /> },
      config,
      disclaimers,
      isSubmittable: !isImpersonated,
      isSubmitting: mutating,
      loanValues,
      onConfirm: handleConfirm,
      projectValues,
      showConditions: usage !== 'rental_investment',
    }
  }

  if (loading) return <SpinnerLegacy overlay />

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <Confirm {...confirmPageProps()} />
    </>
  )
}
ApplicationSubmitPage.propTypes = {
  // router
  history: PropTypes.object,
}

export default ApplicationSubmitPage
