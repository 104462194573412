import PropTypes from 'prop-types'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const MIN_TEXTAREA_HEIGHT = 80
const BORDER_HEIGHT = 2
const MIN_HEIGHT = MIN_TEXTAREA_HEIGHT + BORDER_HEIGHT

const TextArea = ({ fieldProps, isBackgroundTransparent, limit, onChange, value, ...props }) => {
  const [height, setHeight] = useState(MIN_HEIGHT)
  const textareaRef = useRef()

  useEffect(() => {
    const elementHeight = textareaRef.current.scrollHeight + BORDER_HEIGHT

    if (elementHeight !== height) {
      setHeight(elementHeight)
    }
  }, [value])

  const handleChange = (event, ...args) => {
    onChange(event.currentTarget.value, event, ...args)
  }

  const numberChars = value?.length ?? 0
  const isLimitExceeded = numberChars > limit

  return (
    <S.Container {...props}>
      <S.Textarea
        {...fieldProps}
        ref={textareaRef}
        style={{ height }}
        $isLimitExceeded={isLimitExceeded}
        $isBackgroundTransparent={isBackgroundTransparent}
        onChange={handleChange}
        type="textarea"
        value={value}
      />
      <S.Limit>
        <S.Char $isLimitExceeded={isLimitExceeded}>
          {numberChars} / {limit}
        </S.Char>
        <TransitionGroup component={Fragment}>
          {isLimitExceeded && (
            <Transition
              unmountOnExit
              timeout={{
                enter: 10,
                exit: 300,
              }}
            >
              {transitionState => <S.AlertPicto $isEntered={transitionState === 'entered'} />}
            </Transition>
          )}
        </TransitionGroup>
      </S.Limit>
    </S.Container>
  )
}

TextArea.defaultProps = {
  limit: 600,
}

TextArea.propTypes = {
  /** Props of `textarea` tag. */
  fieldProps: PropTypes.object,
  /** Whether background should be transparent or not. */
  isBackgroundTransparent: PropTypes.bool,
  /** Limit number for textarea. Only visual. */
  limit: PropTypes.number,
  /** onChange function for textarea. */
  onChange: PropTypes.func.isRequired,
  /** Value of textarea. */
  value: PropTypes.string.isRequired,
}

export default TextArea
