import { Picto } from '@pretto/picto'

import { MouseEvent } from 'react'

import * as S from './ButtonLink.styles'

export interface ButtonLinkProps {
  children: string
  href?: string
  onClick?: (event: MouseEvent) => void
  picto?: Picto
}

export const ButtonLink = ({ children, href, onClick, picto, ...props }: ButtonLinkProps) => (
  <S.ButtonLink href={href} onClick={onClick} {...props}>
    {picto && <S.Picto $picto={picto} />}
    <S.Anchor>{children}</S.Anchor>
  </S.ButtonLink>
)
