import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { useEmailField, useTextField } from '@pretto/app/src/Form/lib/useField'
import Front from '@pretto/app/src/Helpers/frontchat'
import AdvisorAvatar from '@pretto/app/src/SharedContainers/AdvisorAvatar'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { SEPA, SIGN_SEPA } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'
import * as FIELDS from '@pretto/app/src/mandate/lib/fields'
import { MandateSepa } from '@pretto/app/src/mandate/views/MandateSepa/MandateSepa'

import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const SepaPage = ({ next, templateProps }) => {
  const { loading, data } = useQuery(SEPA)
  const [sepaStatus, setSepaStatus] = useState(null)
  const [sepaReference, setSepaReference] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [signSepa] = useMutation(SIGN_SEPA)
  const { hasComortgagor, user } = useUser()
  const { replace } = useHistory()

  const firstName = useTextField({ ...FIELDS.firstName, defaultValue: user.firstName })
  const lastName = useTextField({ ...FIELDS.lastName, defaultValue: user.lastName })
  const iban = useTextField(FIELDS.iban)
  const email = useEmailField({ ...FIELDS.email, defaultValue: user.email })

  const trackAction = useTracking()

  useEffect(() => {
    if (!loading && data.deal.sepaStatus.signed) {
      replace('/')
    }
  }, [loading])

  const fields = {
    email,
    firstName,
    iban,
    lastName,
  }
  const fieldsComponents = Object.entries(fields).reduce(
    (previous, [key, { component, value }]) => ({ ...previous, [key]: value, [`${key}Component`]: component }),
    {}
  )

  const fieldsList = Object.values(fields)
  const fieldsErrors = fieldsList.map(({ error }) => error)
  const values = fieldsList.map(({ value }) => value)

  const anyError = fieldsErrors.filter(Boolean).length > 0
  const anyEmpty = values.some(value => !value)

  const isSubmitDisabled = anyEmpty || anyError

  const handleError = () => {
    Front('show')
  }

  const handleValidate = () => {
    setSepaStatus(null)
    trackAction('SEPA_VALIDATION_CLICKED')
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    setSepaStatus('LOADING')
    trackAction('SEPA_AUTHORIZATION_SUBMITTED')

    const {
      data: {
        sign_sepa: { error, reference, success },
      },
    } = await signSepa({
      variables: {
        email: email.value,
        firstName: firstName.value,
        iban: iban.value.trim(),
        lastName: lastName.value,
      },
    })

    if (success) {
      setSepaStatus('SUCCESS')
      setSepaReference(reference)
      trackAction('SEPA_RESPONSE_VIEWED')
    }

    if (error) setSepaStatus(error)

    const responseStatus = success ? 'success' : 'error'
    trackAction('SEPA_RESPONSE_VIEWED', { sepa_response_value: responseStatus })
  }

  const sepaPageProps = {
    ...fieldsComponents,
    avatarProps: { src: <AdvisorAvatar size="small" /> },
    creditorId: process.env.GOCARDLESS_CREDITOR_ID,
    hasComortgagor,
    isSubmitDisabled,
    isSubmitting,
    onError: handleError,
    onRedirect: next,
    onSubmit: handleSubmit,
    onValidate: handleValidate,
    reference: sepaReference,
    sepaStatus,
    templateProps,
  }

  if (loading) return <SpinnerLegacy overlay />

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <MandateSepa {...sepaPageProps} />
    </>
  )
}
SepaPage.propTypes = {
  next: PropTypes.func.isRequired,
  templateProps: PropTypes.object.isRequired,
}

export default SepaPage
