import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'

import styled from 'styled-components'

export const Button = styled(ButtonFeature)`
  display: inline-flex;
  vertical-align: middle;
`

export const Block = styled.div`
  display: inline-block;
`
