import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { Dropdown as DropdownComponent } from '@pretto/zen/atoms/dropdowns/Dropdown/Dropdown'

import { CheckBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { Fragment } from 'react'
import styled, { css } from 'styled-components'

export const DropdownFilter = ({ filters, id = 'filters', isOpen, label, onClose, onOpen, ...props }) => (
  <Dropdown
    label={<DropdownLabel>{label}</DropdownLabel>}
    onOpen={onOpen}
    onClose={onClose}
    isOpen={isOpen}
    ContainerComponent={ContainerComponent}
    {...props}
  >
    <DropdownContent>
      <Section>
        <Title>Afficher</Title>

        {filters.map((items, sectionIndex) => (
          <Fragment key={sectionIndex}>
            {sectionIndex > 0 && <Divider />}
            <FilterList>
              {items.map(({ isSelected, label, onSelect, picto: PictoComponent, progress, type }, itemIndex) => {
                const handleSelect = () => {
                  onSelect()
                }

                if (type === 'checkbox') {
                  const handleChange = () => {
                    onSelect()
                  }

                  return (
                    <InputCheckbox
                      key={itemIndex}
                      id={`${id}-${sectionIndex}-${itemIndex}`}
                      label={label}
                      isChecked={isSelected}
                      onChange={handleChange}
                    />
                  )
                }

                return (
                  <FilterItem key={itemIndex} onClick={handleSelect} $isSelected={isSelected}>
                    <div>
                      {label}{' '}
                      {progress && (
                        <DocumentsCount>
                          ({Array.isArray(progress) ? `${progress[0]}/${progress[1]}` : progress})
                        </DocumentsCount>
                      )}
                    </div>

                    {(isSelected || PictoComponent) && (
                      <Picto $type={type} as={PictoComponent ? PictoComponent : CheckBold} />
                    )}
                  </FilterItem>
                )
              })}
            </FilterList>
          </Fragment>
        ))}
      </Section>
    </DropdownContent>
  </Dropdown>
)

DropdownFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        isSelected: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired,
        picto: PropTypes.any,
        progress: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        type: PropTypes.oneOf(['checkbox', 'default', 'error']),
      })
    )
  ).isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
}

const DocumentsCount = styled.span`
  ${typo.caption12};
`
const FilterList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: 0 ${g(2)};
`
const FilterItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${typo.bodyBold16};
      color: ${({ theme }) => theme.colors.primary1};
    `}
`
const ContainerComponent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(54)};
  }
`
const Dropdown = styled(DropdownComponent)``
const DropdownLabel = styled.div``
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: ${g(2)} 0;
`
const DropdownContent = styled.div`
  padding-bottom: ${g(11)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
  }
`
const Title = styled.div`
  ${typo.heading16}
  padding: 0 ${g(2)};
`
const Picto = styled.svg`
  margin-left: ${g(2)};
  color: ${({ $type, theme }) => ($type === 'error' ? theme.colors.error1 : theme.colors.primary1)};
`
