import ActionCards from '@pretto/bricks/shared/components/ActionCards'
import DefaultTemplate from '@pretto/bricks/shared/templates/DefaultTemplate'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const ReduceAmbition = ({ data }) => {
  const { t } = useTranslation()

  return (
    <DefaultTemplate
      title={t('arrangeCreditsPage.adjustProject')}
      description={t('arrangeCreditsPage.adjustProjectMessage')}
    >
      <S.Cards>
        <ActionCards data={data} hasOptions />
      </S.Cards>
    </DefaultTemplate>
  )
}

ReduceAmbition.propTypes = {
  data: PropTypes.array,
}

export default ReduceAmbition
