import { TimelineProps, TimelineSection, TimelineStep } from '@pretto/zen/reveal/simulator/timeline/Timeline/Timeline'

import { sections as config } from '@pretto/app/src/Sentences/v2/components/Timeline/config/sections'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'

import findLastIndex from 'lodash/findLastIndex'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'

type Accumulator<T> = T & {
  isPreviouslyAccessible: boolean
}

type UseSections = () => { progress: number; sections: TimelineProps['sections'] }

export const useSections: UseSections = () => {
  const { pathname } = useLocation()

  const { graph } = useRoutesGraph()

  const context = useSentences()

  const { sections } = config.reduce<Accumulator<TimelineProps>>(
    ({ isPreviouslyAccessible, sections }, { steps = [], ...initialSection }) => {
      const { isPreviouslyAccessible: isAccessible, ...section } = steps.reduce<Accumulator<TimelineSection>>(
        (
          { steps = [], ...previous },
          { accessibleRoutesPattern, activeRoutesPattern, isVisible = () => true, label }
        ) => {
          if (!isVisible(context)) {
            return { ...previous, steps }
          }

          const route = accessibleRoutesPattern.reduce<null | Route>((previous, routePattern) => {
            if (previous) {
              return previous
            }

            const route = graph.findRoutesByMatch(routePattern).find(route => graph.isNodeAccessible(route, context))

            if (!route) {
              return null
            }

            return route
          }, null)

          const isAccessible = previous.isPreviouslyAccessible && route !== null
          const isActive = isAccessible && activeRoutesPattern.some(path => matchPath(pathname, { path }) !== null)

          const href = !isAccessible ? undefined : route.path
          const type = !isAccessible ? 'inactive' : isActive ? 'active' : 'done'

          return {
            ...previous,
            isPreviouslyAccessible: isAccessible,
            steps: [...steps, { href, label, type }],
          }
        },
        {
          ...initialSection,
          steps: [],
          isPreviouslyAccessible,
        }
      )

      if (!section.steps?.length) {
        return { isPreviouslyAccessible, sections }
      }

      return {
        isPreviouslyAccessible: isAccessible,
        sections: [...sections, section],
      }
    },
    {
      sections: [],
      isPreviouslyAccessible: true,
    }
  )

  const steps = sections.reduce<TimelineStep[]>((previous, { steps = [] }) => [...previous, ...steps], [])
  const lastIndex = findLastIndex(steps, ({ type }) => type === 'done' || type === 'active')
  const progress = (Math.max(0, lastIndex) / steps.length) * 100

  return { progress, sections }
}
