import * as PropTypes from 'prop-types'

import * as S from './styles'

const FormCheckboxes = ({ children }) => children.map((child, index) => <S.Checkbox key={index}>{child}</S.Checkbox>)

FormCheckboxes.propTypes = {
  /** Checkboxes. */
  children: PropTypes.array.isRequired,
}

export default FormCheckboxes
