import PropTypes from 'prop-types'

import * as S from './styles'

const TextDivider = ({ label, ...props }) => <S.Separator {...props}>{label}</S.Separator>

TextDivider.propTypes = {
  /** text to display */
  label: PropTypes.string.isRequired,
}

export default TextDivider
