import { formatToEuro } from './formatValue'
import { t } from '@pretto/app/src/lib/i18n'

const formatInfos = infos =>
  infos.map(({ label, value }) => ({
    label,
    value: formatToEuro(value),
  }))

export const getPurchaseInfos = ({
  property_price: propertyPrice,
  land_price: landPrice,
  build_price: buildPrice,
  works_price: worksPrice,
}) =>
  [
    propertyPrice && {
      label: t('simulation.editor.propertyPrice'),
      value: propertyPrice,
    },
    landPrice && {
      label: t('simulation.editor.landPrice'),
      value: landPrice,
    },
    buildPrice && {
      label: t('simulation.editor.buildPrice'),
      value: buildPrice,
    },
    worksPrice && {
      label: t('simulation.editor.worksPrice'),
      value: worksPrice,
    },
  ].filter(Boolean)

export const getInfos = ({
  contribution,
  profile: {
    facts: { total_savings },
  },
  purchase,
}) => {
  const infos = [
    ...getPurchaseInfos(purchase),
    {
      label: t('simulation.editor.contribution'),
      value: contribution,
    },
    {
      label: t('simulation.editor.remainingSavings'),
      value: total_savings - contribution,
    },
  ]

  return formatInfos(infos)
}
