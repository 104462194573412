import DashboardHighlightsCard from '@pretto/bricks/app/dashboard/components/DashboardHighlightsCard'
import DashboardSecondaryCard from '@pretto/bricks/app/dashboard/components/DashboardSecondaryCard'
import PropTypes from 'prop-types'
import { createElement } from 'react'

const DashboardOverviewCard = ({ hasHighlights, ...props }) =>
  createElement(hasHighlights ? DashboardHighlightsCard : DashboardSecondaryCard, {
    highlightVariant: 'neutral2',
    title: 'Votre projet',
    ...props,
  })

DashboardOverviewCard.propTypes = {
  /** Whether or not the property has been found. */
  hasHighlights: PropTypes.bool.isRequired,
}

export default DashboardOverviewCard
