import { compileString } from '@pretto/bricks/core/utility/string/compileString'

import { LOAN_AMOUNT_MINIMUM_FACTOR, MAX_AGE, MONTHS_IN_YEAR, PERCENT_FACTOR } from '@pretto/app/src/lib/constants'
import { t } from '@pretto/app/src/lib/i18n'
import fundability from '@pretto/config/fundability.json'

import deburr from 'lodash/deburr'

import computeYears from './computeYears'

const computeContract = (contract, type = 'singular') => {
  const contractTranslationKey = `noFinancingPage.comments.contracts.${contract}`

  return t(contractTranslationKey, { count: type === 'singular' ? 1 : 2 })
}

const computeJoint = (suffix, [seperatedPrefix, joinedPrefix]) => {
  if (/^[aeiouy]/.test(deburr(suffix.toLowerCase()))) {
    return `${joinedPrefix}${suffix}`
  }

  return `${seperatedPrefix} ${suffix}`
}

const compileComment = (string, data, locale) => {
  return compileString(string, data, (value, key) => {
    switch (key) {
      case 'age':
        return Math.max(0, MAX_AGE - data.project.profile.facts.max_age)

      case 'contractsSeniorities':
        return computeJoint(
          data.project.profile.mortgagors
            .reduce((previous, { contract, facts: { non_fundable_reason } }) => {
              if (non_fundable_reason !== 'seniority' || previous.includes(contract)) {
                return previous
              }

              return [...previous, contract]
            }, [])
            .map(computeContract)
            .join(t('noFinancingPage.comments.contracts.joinContracts')),
          [
            t('noFinancingPage.comments.contracts.contractPrefixWordLong'),
            t('noFinancingPage.comments.contracts.contractPrefixWordShort'),
          ]
        )

      case 'creditsCount':
        return data.project.profile.credits.filter(({ kind }) => fundability.consumer_credits.includes(kind)).length

      case 'detailedCharges':
        return compileComment(t('noFinancingPage.comments.chargesAccount'), data)

      case 'detailedIncomes':
        if (data.financing.weightedIncomes.yearly === 0) {
          return compileComment(t('noFinancingPage.comments.noIncome'), data)
        }
        return compileComment(t('noFinancingPage.comments.incomeAccount'), data)

      case 'indebtednessCalc':
        switch (data.scoreIndebtednessRate.kind) {
          case 'after_bridge':
            return compileComment(t('noFinancingPage.comments.indebtednessAfterBridge'), data)

          case 'differential':
            return compileComment(t('noFinancingPage.comments.indebtednessDifferential'), data)

          case 'standard':
            return compileComment(t('noFinancingPage.comments.indebtednessStandard'), data)

          default:
            return ''
        }

      case 'min_seniority':
      case 'seniority':
        return computeYears(
          data.project.profile.mortgagors.reduce((previous, { facts: { non_fundable_reason, ...facts } }) => {
            const value = facts[key]

            if (non_fundable_reason !== 'seniority' || previous.includes(value)) {
              return previous
            }

            return [...previous, value]
          }, [])
        )

      case 'nonFundableContracts':
        return data.project.profile.mortgagors
          .reduce((previous, { contract, facts: { non_fundable_reason } }) => {
            if (non_fundable_reason !== 'contract' || previous.includes(contract)) {
              return previous
            }

            return [...previous, contract]
          }, [])
          .map(computeContract)
          .join(t('noFinancingPage.comments.contracts.contractJoinWord'))

      case 'mortgagorIncomes':
        if (data.project.profile.mortgagors.length === 1) {
          return compileComment(t('noFinancingPage.comments.mortgagorIncomeSingle'), data)
        }
        return compileComment(t('noFinancingPage.comments.mortgagorIncomeJoint'), data)

      case 'monthlyIncome':
        return Math.round(data.financing.weightedIncomes.yearly / MONTHS_IN_YEAR).toLocaleString(locale)

      case 'nonResidentContracts':
        return data.project.profile.mortgagors
          .reduce((previous, { contract }) => {
            if (!fundability.non_resident_unsettled_contracts.includes(contract) || previous.includes(contract)) {
              return previous
            }

            return [...previous, contract]
          }, [])
          .map(contract => computeContract(contract, 'plural'))
          .join(t('noFinancingPage.comments.contracts.contractJoinWord'))

      case 'financing.contributionRate':
      case 'project.facts.max_indebtedness_rate.min':
      case 'project.facts.max_indebtedness_rate.max':
      case 'project.facts.works_share':
      case 'scoreIndebtednessRate.value':
        return (value * PERCENT_FACTOR).toLocaleString(locale)

      case 'project.facts.notary_fees':
        return Math.floor(value).toLocaleString(locale)

      case 'recommendedSavings':
        return (
          Math.round((data.financing.loanAmount * LOAN_AMOUNT_MINIMUM_FACTOR) / PERCENT_FACTOR) * PERCENT_FACTOR
        ).toLocaleString(locale)

      case 'remainingLifeAmount':
        return Math.max(0, data.financing.remainingLifeAmount)

      case 'rentIncomes':
        return (
          data.financing.monthlyDetailedWeightedIncomes.expected_rental_income +
          data.financing.monthlyDetailedWeightedIncomes.rental_income
        ).toLocaleString(locale)

      case 'savingsAfterContribution':
        return (data.project.profile.facts.total_savings - data.project.contribution).toLocaleString(locale)

      case 'totalCharges':
        return (
          data.project.facts.detailed_charges.child_support +
          data.project.facts.detailed_charges.credits +
          data.project.facts.detailed_charges.other_charges +
          data.project.facts.detailed_charges.rent
        ).toLocaleString(locale)

      case 'totalIncomes':
        return (
          data.financing.monthlyDetailedWeightedIncomes.expected_rental_income +
          data.financing.monthlyDetailedWeightedIncomes.other +
          data.financing.monthlyDetailedWeightedIncomes.rental_income +
          data.financing.monthlyDetailedWeightedIncomes.wages.reduce((wageA, wageB) => wageA + wageB)
        ).toLocaleString(locale)

      case 'unsettledContracts':
        return data.project.profile.mortgagors
          .reduce((previous, { contract }) => {
            if (!fundability.unsettled_contracts.includes(contract) || previous.includes(contract)) {
              return previous
            }

            return [...previous, contract]
          }, [])
          .map(computeContract)
          .join(t('noFinancingPage.comments.contracts.contractJoinWord'))

      case 'works_price':
        if (data.project.project_kind === 'purchase') {
          return data.project.purchase.works_price.toLocaleString(locale)
        }

        return data.project.renegotiation.works_amount.toLocaleString(locale)

      default:
        return value.toLocaleString(locale)
    }
  })
}

const compileComments = (comments, data, locale = 'fr') =>
  Object.entries(comments).reduce(
    (previous, [type, comment]) => ({ ...previous, [type]: compileComment(comment || '', data, locale) }),
    {}
  )

export default compileComments
