import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'

import * as S from './styles'

const FormSection = ({ title, children }) => (
  <S.Section>
    {title && (
      <S.Title>
        <SubHeading>{title}</SubHeading>
      </S.Title>
    )}

    <div>{children}</div>
  </S.Section>
)

FormSection.propTypes = {
  /** Descendants. */
  children: PropTypes.node.isRequired,
  /** Title of the section. */
  title: PropTypes.string,
}

export default FormSection
