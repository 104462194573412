import Button from '@pretto/bricks/components/buttons/Button'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import BubbleChat from '@pretto/bricks/shared/components/BubbleChat'

import PropTypes from 'prop-types'

import * as S from './styles'

const MandateIntroductionPage = ({ avatarProps, onContinue }) => (
  <S.Page>
    <Wrapper>
      <S.BubbleContainer>
        <BubbleChat avatarProps={avatarProps}>
          <S.Paragraph>
            Nous sommes prêts à lancer les négociations avec les banques ! Votre dossier est prêt, il ne reste plus qu’à
            signer votre mandat Pretto de recherche de financement.
          </S.Paragraph>
          <S.Paragraph>
            Vous devez vérifier une dernière fois les informations de votre projet avant de signer votre mandat.
          </S.Paragraph>
        </BubbleChat>
      </S.BubbleContainer>
      <S.ButtonContainer>
        <Button onClick={onContinue}>Vérifier mon projet</Button>
      </S.ButtonContainer>
    </Wrapper>
  </S.Page>
)

MandateIntroductionPage.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Function triggered when user clicks the button. */
  onContinue: PropTypes.func.isRequired,
}

export default MandateIntroductionPage
