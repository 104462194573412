import RatingScale from '@pretto/bricks/components/form/RatingScale'

import PropTypes from 'prop-types'

export const RatingField = props => <RatingScale {...props} />

RatingField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
}
