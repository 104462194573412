import PropTypes from 'prop-types'
import { Fragment, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const CollapsibleRow = ({
  animateIn,
  childComponent: Child,
  children,
  in: inProps,
  parentComponent: Parent,
  transitionProps,
  ...props
}) => {
  const [height, setHeight] = useState(inProps && 'appear' in props ? 'auto' : 0)

  const childRef = useRef(null)

  return (
    <Transition
      appear={animateIn}
      in={inProps}
      onEntering={() => setHeight(childRef.current.clientHeight)}
      onEntered={() => setHeight('auto')}
      onExit={() => setHeight(childRef.current.clientHeight)}
      onExiting={node => {
        node.offsetTop // eslint-disable-line no-unused-expressions
        setHeight(0)
      }}
      timeout={500}
      unmountOnExit
      {...transitionProps}
    >
      <Parent>
        <S.RowTransition isCollapsed={height === 0} style={{ height }} {...props}>
          <div ref={childRef}>
            <Child>{children}</Child>
          </div>
        </S.RowTransition>
      </Parent>
    </Transition>
  )
}

CollapsibleRow.defaultProps = {
  animateIn: false,
  childComponent: Fragment,
  in: false,
  parentComponent: Fragment,
}

CollapsibleRow.propTypes = {
  /** Whether or not the row should animated in when being mounted. */
  animateIn: PropTypes.bool,
  /** Child node wrapper. */
  childComponent: PropTypes.elementType,
  /** Row content. */
  children: PropTypes.node.isRequired,
  /** Whether or not the row should collapse. */
  in: PropTypes.bool,
  /** Parent node wrapper. */
  parentComponent: PropTypes.elementType,
  /** Transition props. */
  transitionProps: PropTypes.object,
}

export default CollapsibleRow
