import { g } from '@pretto/bricks/components/layout'

import { CheckHandwrite } from '@pretto/picto'

import styled from 'styled-components'

export const InputCheckbox = styled.div`
  display: flex;
  align-items: top;
  min-height: ${g(3)};
`
export const Checkbox = styled.div`
  flex-shrink: 0;
  position: relative;
  height: ${g(3)};
  width: ${g(3)};
  border: 2px solid
    ${({ $isChecked, $isError, theme }) =>
      $isChecked ? theme.colors.primary1 : $isError ? theme.colors.error1 : theme.colors.neutral3};
  border-radius: ${g(1 / 2)};
  transition: border-color 200ms ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary1};
  }

  &:focus-within {
    outline: transparent;

    &::before {
      content: '';
      position: absolute;
      border-radius: inherit;
      top: -3px;
      left: -3px;
      bottom: -3px;
      right: -3px;
      border: 1px dashed ${({ theme }) => theme.colors.accent1};
    }
  }
`
export const Input = styled.input`
  cursor: pointer;
  height: 100%;
  position: absolute;
  margin: 0;
  opacity: 0;
  width: 100%;
`
export const Picto = styled(CheckHandwrite)`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  ${Input}:checked + & {
    opacity: 1;
  }
`
export const Label = styled.label`
  cursor: pointer;
  display: block;
  margin-left: ${g(2)};
`
