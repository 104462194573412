import PropTypes from 'prop-types'

import * as S from './styles'

const ButtonInline = ({ children, isDisabled, isSecondary, onClick, ...props }) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
    }
    onClick(event, ...args)
  }

  return (
    <S.Button $isDisabled={isDisabled} $isSecondary={isSecondary} onClick={handleClick} {...props}>
      {children}
      <S.Arrow />
    </S.Button>
  )
}

ButtonInline.defaultProps = {
  onClick: () => {},
}

ButtonInline.propTypes = {
  /** Text of button */
  children: PropTypes.node.isRequired,
  /** Disabled state. */
  isDisabled: PropTypes.bool,
  /** Whether it is a secondary button or not. Styles (colors) will be applied accordingly. */
  isSecondary: PropTypes.bool,
  /** onClick Function. */
  onClick: PropTypes.func,
}

export default ButtonInline
