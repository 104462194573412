import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Avatar = styled.div`
  margin-right: ${g(2)};
`

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: ${g(2)};
`

export const Icon = styled.div`
  margin-right: ${g(2)};
`

export const Status = styled.div`
  align-items: center;
  display: flex;
`
