import Button from '@pretto/bricks/components/buttons/Button'
import ButtonGoogleConnect from '@pretto/bricks/components/buttons/ButtonGoogleConnect'
import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { ShieldCheck } from '@pretto/picto'

import styled from 'styled-components'

export const GoogleConnect = styled(ButtonGoogleConnect).attrs({ color: 'blue' })`
  width: 100%;
`

export const TextConnect = styled.div`
  ${typo.bodyBook16};
  text-align: center;
  margin: ${g(2)} auto;
`

export const ConnectCTA = styled(Button)`
  margin-top: ${g(2)};
  width: 100%;
  margin-bottom: ${g(3)};
`

export const Info = styled.div`
  display: flex;
`

export const Icon = styled(ShieldCheck)`
  flex-shrink: 0;
  width: ${g(3)};
  height: ${g(3)};
  margin-right: ${g(2)};
`

export const Caption = styled.span`
  ${typo.caption12};
`
