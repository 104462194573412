/* eslint-disable no-console */

export const colors = {
  accent1: '#ff784e',
  get accent1Disabled() {
    if (typeof window !== 'undefined') {
      console.error('DEPRECATED')
    }
    return '#ff784e'
  },
  get accent1Hover() {
    if (typeof window !== 'undefined') {
      console.error('DEPRECATED')
    }
    return '#ff784e'
  },
  accent2: '#fcac8a',
  accent3: '#ffd3bb',
  accent4: '#ffe9dd',
  error1: '#c70808',
  error2: '#ffd0d0',
  neutral1: '#050505',
  neutral1Disabled: '#828282',
  neutral1Hover: '#1f1f1f',
  neutral2: '#847E74',
  neutral3: '#e1dbd1',
  neutral4: '#f1ebe2',
  get neutral4Hover() {
    if (typeof window !== 'undefined') {
      console.error('DEPRECATED')
    }
    return '#f1ebe2'
  },
  neutral5: '#faf7f2',
  overlay: 'rgba(5, 5, 5, 0.5)',
  primary1: '#006855',
  primary1Disabled: '#7fb3aa',
  primary2: '#0c806b',
  primary3: '#a5f1a4',
  primary4: '#d2f8d1',
  warning1: '#e3631b',
  warning2: '#ffe5b1',
  white: '#ffffff',
  facebook: '#4267b2',
  linkedin: '#3b5998',
  trustpilot: '#00b67a',
  twitter: '#1da1f2',
  whatsapp: '#25d366',
}
