import * as helpers from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Card = styled.div`
  border-radius: ${g(1)};
  cursor: pointer;
  transition: all 150ms ease-in;

  &:hover {
    ${helpers.boxShadow(1)}
  }
`

export const Content = styled.div`
  align-item: center;
  display: flex;
`

export const Label = styled.div`
  margin-left: ${g(2)};
`
