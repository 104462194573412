export const email = {
  inputProps: {
    id: 'email',
    placeholder: 'Adresse email',
  },
}

export const firstName = {
  inputProps: {
    id: 'firstName',
    placeholder: 'Prénom',
  },
}

export const iban = {
  inputProps: {
    id: 'iban',
    placeholder: 'FR76 BARC 2000 0055 7799 11',
  },
}

export const lastName = {
  inputProps: {
    id: 'lastName',
    placeholder: 'Nom',
  },
}
