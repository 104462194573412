import Icon from '@pretto/bricks/components/iconography/Icon'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'
import { memo } from 'react'

import * as C from './SponsorshipSocialButton.module.css'

const SOCIAL_BUTTONS = ['facebook', 'linkedin', 'twitter', 'whatsapp']
const SponsorshipSocialButton = ({ kind, onClick, size }) => {
  return (
    <div
      className={classnames(
        C.sponsorshipSocialButton,
        C[`sponsorshipSocialButton${pascalcase(kind ?? '')}`],
        C[`sponsorshipSocialButton${pascalcase(size ?? '')}`]
      )}
      onClick={onClick}
    >
      <div>
        <Icon name={kind} variant="white" />
      </div>
    </div>
  )
}
SponsorshipSocialButton.propTypes = {
  kind: PropTypes.oneOf(SOCIAL_BUTTONS).isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
}
SponsorshipSocialButton.defaultProps = {
  size: 'medium',
}
export default memo(SponsorshipSocialButton)
