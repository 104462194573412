import type { FieldProps } from '@pretto/zen/reveal/types/Field'

import { municipalitySearch } from '@pretto/places'

import { useEffect, useState } from 'react'

export type Result = municipalitySearch.MunicipalitySearchResult

type UseCityField = (defaultValue?: string) => {
  fieldProps: FieldProps
  results: Result[]
}

export const useCityField: UseCityField = defaultValue => {
  const [searchValue, setSearchValue] = useState(defaultValue ?? '')
  const [results, setResults] = useState<Result[]>([])

  useEffect(() => {
    if (searchValue === '') {
      setResults([])
      return
    }

    municipalitySearch.get(searchValue, { limit: 50 }).then(results => {
      if (!Array.isArray(results)) {
        return
      }

      setResults(results)
    })
  }, [searchValue])

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  return {
    fieldProps: {
      onChange: handleChange,
      value: searchValue,
    },
    results,
  }
}
