import { ERROR_TYPES, getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'

import { useEmailField } from '@pretto/app/src/Form/lib/useField'
import { useTracking } from '@pretto/app/src/lib/tracking'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { SaveContext } from './SaveContext'

interface SignUpProps {
  assignment: string
  email: string
  source: string
}

interface SaveProviderProps {
  onSignUp: (props: SignUpProps) => Promise<unknown>
  children: React.ReactElement
}

export const SaveProvider: React.FC<SaveProviderProps> = ({ onSignUp, children }) => {
  const [isSaveOpen, setIsSaveOpen] = useState<boolean>(false)

  const trackAction = useTracking()

  const handleOpenSave = () => {
    trackAction('SIMULATION_RESULT_SAVE_BUTTON_CLICKED')
    setIsSaveOpen(true)
  }

  const handleCloseSave = () => {
    setIsSaveOpen(false)
  }

  const signUp = async () => {
    try {
      setIsButtonLoading(true)
      const error = await onSignUp({
        assignment: 'project',
        email: emailValue,
        source: 'subscribe',
      })

      if (error) {
        invalidate(error)
        return
      }
    } catch (error) {
      invalidate('server_error')
    } finally {
      setIsButtonLoading(false)
    }
  }

  const {
    component: emailFieldComponent,
    value: emailValue,
    setIsButtonLoading,
    invalidate,
  } = useEmailField({
    inputProps: {
      placeholder: 'Email',
      required: true,
    },
    isSingle: true,
    onSubmit: signUp,
    validators: [
      (value: string, isCheckRequested: boolean, inputProps: unknown, reason: string) => ({
        condition: ERROR_TYPES.includes(reason),
        message: getAuthErrorMessage(reason, <Link to="/login">ici</Link>),
        state: 'warning',
      }),
    ],
  })

  const context = {
    emailFieldComponent,
    isSaveOpen,
    onOpenSave: handleOpenSave,
    onCloseSave: handleCloseSave,
  }

  return <SaveContext.Provider value={context}>{children}</SaveContext.Provider>
}
