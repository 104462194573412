import { breakpoints, g } from '@pretto/bricks/components/layout'

import { Message } from '@pretto/zen/reveal/atoms/message/Message'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { CalcTable, CalcTableProps } from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'
import { TimesBold } from '@pretto/picto'

import styled from 'styled-components'

export interface DialogContentProps {
  calcTableProps: CalcTableProps
  disclaimer: string
  onClose: () => void
}

export const DialogContent: React.FC<DialogContentProps & React.HTMLAttributes<HTMLDivElement>> = ({
  calcTableProps,
  disclaimer,
  onClose,
  ...props
}) => {
  const button = useBreakpointToValue<React.ReactNode>(<TimesBold />, { tablet: 'Fermer' })

  return (
    <Component {...props}>
      <Header>
        <Title>Détails du calcul</Title>
        <CloseButton aria-label="Fermer" onClick={onClose} type="button">
          {button}
        </CloseButton>
      </Header>

      <CalcTable {...calcTableProps} />
      <Message>{disclaimer}</Message>
    </Component>
  )
}

const CloseButton = styled.button`
  ${({ theme }) => theme.typos.linkUnderline};
  align-items: center;
  appearance: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: ${g(-1)} ${g(-1)} 0 0;
  padding: 0;
  height: ${g(4)};
  width: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: auto;
    margin: 0;
    width: auto;
  }
`

const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(3)};
  padding: 0 ${g(2, 4)} ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(4)};
    padding: 0 ${g(4)} ${g(4)};
  }
`

const Header = styled.header`
  align-items: flex-start;
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
  backdrop-filter: blur(${g(3)});
  display: flex;
  gap: ${g(3)};
  justify-content: space-between;
  margin: 0 ${g(-2, -4)};
  margin-bottom: ${g(-2)};
  padding: ${g(2)} ${g(2, 4)};
  position: sticky;
  top: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(-3)};
    margin-bottom: ${g(-3)};
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: 0 ${g(-4)};
    margin-bottom: ${g(-4)};
    padding: ${g(4)};
  }
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin-bottom: ${g(1)};
  }
`
