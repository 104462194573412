import * as filters from '@pretto/app-core/lib/filters'

import type { PushyContainerProps } from '@pretto/app/src/Simulation/types/Pushy'
import { Pushy as PushyView } from '@pretto/app/src/Simulation/views/pushy/Pushy'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useEmailField } from '@pretto/app/src/lib/form/useEmailField'
import { usePhoneField } from '@pretto/app/src/lib/form/usePhoneField'
import { useTextField } from '@pretto/app/src/lib/form/useTextField'
import { t } from '@pretto/app/src/lib/i18n'

import { usePushy } from './lib/usePushy'

export const Pushy: React.FC<PushyContainerProps> = props => {
  const { typology, user } = useUser()

  const {
    check: emailFieldCheck,
    component: emailFieldComponent,
    value: email,
  } = useEmailField({
    defaultValue: user?.email,
    inputProps: {
      disabled: typology !== 'prospect',
      placeholder: t('pushy.placeholder.email'),
      required: true,
    },
    placeholderAsLabel: true,
    size: 'large',
    type: 'block_deprecated',
  })

  const {
    check: firstNameFieldCheck,
    component: firstNameFieldComponent,
    value: firstName,
  } = useTextField({
    defaultValue: user?.firstName,
    inputProps: {
      autoFocus: true,
      placeholder: t('pushy.placeholder.firstName'),
      required: true,
    },
    placeholderAsLabel: true,
    size: 'large',
    type: 'block_deprecated',
  })

  const {
    check: lastNameFieldCheck,
    component: lastNameFieldComponent,
    value: lastName,
  } = useTextField({
    defaultValue: user?.lastName,
    inputProps: {
      placeholder: t('pushy.placeholder.lastName'),
      required: true,
    },
    placeholderAsLabel: true,
    size: 'large',
    type: 'block_deprecated',
  })

  const {
    check: phoneFieldCheck,
    component: phoneFieldComponent,
    value: phone,
  } = usePhoneField({
    defaultValue: user?.phone,
    inputProps: {
      placeholder: t('pushy.placeholder.phoneNumber'),
      required: true,
    },
    placeholderAsLabel: true,
    size: 'large',
    type: 'block_deprecated',
  })

  const { props: pushyProps, register } = usePushy(props)

  const handleSubmit = () => {
    const isValid = [emailFieldCheck(), firstNameFieldCheck(), lastNameFieldCheck(), phoneFieldCheck()].every(Boolean)

    if (!isValid) {
      register(null)
      return
    }

    const values = {
      email: filters.formatEncodeEmail(email),
      firstName: filters.formatEncodeProprify(firstName),
      lastName: filters.formatEncodeProprify(lastName),
      phone: filters.formatEncodePhone(phone),
    }

    register(values)
  }

  const viewProps = {
    ...pushyProps,
    fields: {
      firstName: firstNameFieldComponent,
      lastName: lastNameFieldComponent,
      email: emailFieldComponent,
      phone: phoneFieldComponent,
    },
    onSubmit: handleSubmit,
  }

  return <PushyView {...viewProps} />
}
