import { g } from '@pretto/bricks/components/layout'

import styled, { keyframes } from 'styled-components'

const glow = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
  
    to {
      opacity: 0;
      transform: scale(3);
    }
  `

export const StepCurrent: React.FC = props => (
  <CurrentContainer {...props}>
    <CurrentDot />
    <CurrentBackground />
  </CurrentContainer>
)

const CurrentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${g(3)};
  height: ${g(3)};
`
const CurrentDot = styled.div`
  width: ${g(1)};
  height: ${g(1)};
  border-radius: ${g(1)};
  border: 2px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary3};
  position: relative;
  z-index: 1;
  box-sizing: content-box;
`
const CurrentBackground = styled.div`
  animation: ${glow} 2s infinite;
  background: ${({ theme }) => theme.colors.primary3};
  border-radius: 100%;
  position: absolute;
  bottom: ${g(1)};
  left: ${g(1)};
  right: ${g(1)};
  top: ${g(1)};
`
