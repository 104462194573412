import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'

import styled from 'styled-components'

export const BlockOnboarding = props => (
  <Container {...props}>
    <TextContent>
      <Title>Prochaine Étape</Title>
      <Text>
        Votre espace sécurisé est presque prêt ! Laissez-vous guider pour le personnaliser grâce à quelques questions !
      </Text>
    </TextContent>
    <ButtonOnboarding href="/onboarding" isSecondary>
      Suivant
    </ButtonOnboarding>
  </Container>
)

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    display: flex;
  }
`

const Title = styled.h1`
  ${typo.headline16}
`
const Text = styled.p`
  margin-top: ${g(2)};
`
const TextContent = styled.div`
  margin-right: ${g(4)};
`
const ButtonOnboarding = styled(Button)`
  width: 100%;
  margin-top: ${g(2)};
  @media screen and (min-width: ${breakpoints.mobileL}) {
    display: inline-flex;
    width: auto;
  }
`
