/* globals analytics */
import { getAttributionCookieLastClickValue, handleAttributionCookie } from '@pretto/app-core/lib/attributionCookie'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { SCORE_FLAGS } from '@pretto/app/src/apollo'
import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'

import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as helpers from '../../../Auth/lib/helpers'
import Front from '../../../Helpers/frontchat'
import { analyticsDefaultPayload } from '../../config/config'
import { isEnglishUser } from '../../lib/i18n'
import { getNetlifyAB } from '../../lib/netlify'
import { USER_PRE_LEAD } from '../../queries/queries'
import { UserProvider } from '../UserProvider'

const TYPOLOGY = 'preLead'

export const UserProviderPreLead = ({ children }) => {
  const { data, loading, refetch } = useQuery(USER_PRE_LEAD, { fetchPolicy: 'network-only' })
  const scoreZeroMinute = useQuery(SCORE_FLAGS, {
    variables: { attributionCookieLastClick: getAttributionCookieLastClickValue() },
  })

  const { i18n } = useTranslation()

  const isPageLoading = useLoading(loading)

  useEffect(() => {
    handleAttributionCookie({ isClient: false })
  }, [])

  useEffect(() => {
    if (loading) {
      return
    }

    const trackedProjectData = data.project !== null ? { project_id: data.project_id } : {}

    const payload = {
      ...analyticsDefaultPayload,
      ...trackedProjectData,
      connected: true,
      displayLang: isEnglishUser(i18n.language) ? 'en' : 'fr',
      typology: TYPOLOGY,
    }

    const accountId = helpers.getAccountId()

    analytics.alias(accountId)
    analytics.identify(accountId, payload)

    const netlifyAB = getNetlifyAB()

    Sentry.setUser({
      ...netlifyAB,
      ...trackedProjectData,
      id: helpers.getAccountId(),
      email: helpers.getUserEmail(),
      segment: 'prelead',
    })

    Front('update', {
      ...(!!account.firstName && !!account.lastName ? { name: `${account.firstName} ${account.lastName}` } : {}),
      customFields: { ...netlifyAB, ...trackedProjectData },
      email: helpers.getUserEmail(),
      userHash: helpers.getFrontHMAC(),
    })
  }, [loading])

  if (isPageLoading) {
    return null
  }

  const { account: activeAccount, advisor, project, referrer } = data

  const account = {
    ...activeAccount,
    activeIndex: 0,
    get isPropertyFound() {
      if (project === null) {
        return false
      }

      const { build_price, property_price } = project.purchase

      if (build_price === null && property_price === null) {
        return false
      }

      return true
    },
  }

  const hasFeatureAccess = featuresName => {
    return featuresName.some(name => (account.featureAccess ?? []).includes(name))
  }

  const value = {
    accounts: [account],
    advisor: {
      ...(advisor && { label: ADVISOR_LABELS[advisor.role] }),
      ...advisor,
    },
    hasFeatureAccess,
    isEnglishUser: isEnglishUser(i18n.language),
    isProjectAvailable: project !== null,
    isReferred: !!referrer?.id,
    project,
    projectID: data?.project_id,
    refetchUser: refetch,
    scoreZeroMinute: scoreZeroMinute.data?.scoreFlags,

    typology: TYPOLOGY,
    user: account,
  }

  return <UserProvider value={value}>{children}</UserProvider>
}

UserProviderPreLead.propTypes = {
  children: PropTypes.node.isRequired,
}
