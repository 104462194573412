import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import { TYPOLOGY } from '../../queries/queries'
import { UserProviderClient } from '../UserProviderClient'
import { UserProviderPreLead } from '../UserProviderPreLead'
import { UserProviderProspect } from '../UserProviderProspect'

const UserContext = createContext()

const UserProviderController = ({ children }) => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn) {
    return <UserProviderProspect>{children}</UserProviderProspect>
  }

  return <UserProviderControllerLoggedIn>{children}</UserProviderControllerLoggedIn>
}

UserProviderController.propTypes = {
  children: PropTypes.node.isRequired,
}

const UserProviderControllerLoggedIn = ({ children }) => {
  const { data, loading } = useQuery(TYPOLOGY)

  useLoading(loading)

  if (loading) {
    return null
  }

  switch (data.account.typology) {
    case 'client':
      return <UserProviderClient>{children}</UserProviderClient>

    case 'pre_lead':
      return <UserProviderPreLead>{children}</UserProviderPreLead>

    default:
      return null
  }
}

UserProviderControllerLoggedIn.propTypes = {
  children: PropTypes.node.isRequired,
}

export const UserProvider = UserContext.Provider

export const useUser = () => useContext(UserContext)

export default UserProviderController
