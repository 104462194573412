import { temporal } from '@pretto/bricks/core/utility/temporal'

import { AgendaAdvisor } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import { Day } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Calendar/Calendar'

interface RawSlot {
  start: string
  advisor: AgendaAdvisor
  kind: string
}

export const orderSlotsByDate = (slots: RawSlot[]): Day[] => {
  return slots.reduce<Day[]>((previous, current) => {
    const currentDate = temporal(current.start).format('YYYY-MM-DD')
    const dateAndSlots = previous.find(({ date }: Day): boolean => date === currentDate)

    if (dateAndSlots) {
      dateAndSlots.slots.push({
        label: temporal(current.start).format('HH:mm'),
        utcDate: current.start,
        advisor: current.advisor,
        kind: current.kind,
      })
    } else {
      previous.push({
        date: currentDate,
        slots: [
          {
            label: temporal(current.start).format('HH:mm'),
            utcDate: current.start,
            advisor: current.advisor,
            kind: current.kind,
          },
        ],
      })
    }

    return previous
  }, [])
}
