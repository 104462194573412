import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import styled, { css } from 'styled-components'

export const NavigationPicto = styled.div`
  flex-shrink: 0;
`
export const Label = styled.div`
  ${typo.bodyBook16};
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.white};
  transition: border-color 250ms ease-in-out;

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral1};
    `};
`
export const NavItem = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${g(2)};
  padding: 0 ${g(2)};
  cursor: pointer;
  flex-shrink: 0;
  background-color: inherit;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral4};
  }

  &:hover ${Label} {
    border-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.neutral1 : theme.colors.neutral4)};
  }
`
