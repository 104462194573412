import * as helpers from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'
import { ChevronRightBold } from '@pretto/picto'
import styled, { css } from 'styled-components'

export const Button = styled.div.attrs(({ href }) => ({ as: href ? Link : 'button' }))`
  width: 459px;
  max-width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${g(2)} ${g(3)};
  border-radius: ${g(1)};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutral3};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.neutral1};
  transition: border-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral2};
    ${helpers.boxShadow('card')}
  }
`

export const Content = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
export const PictoContainer = styled.div`
  width: ${g(5)};
  height: ${g(5)};
  margin-right: ${g(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ $backgroundVariant }) =>
    $backgroundVariant &&
    css`
      background-color: ${({ theme }) => theme.colors[$backgroundVariant]};
    `}
`
export const Icon = styled.img.attrs(({ $picto }) => ({ as: $picto }))`
  width: ${g(3)};
  height: ${g(3)};
`

export const Arrow = styled(ChevronRightBold)``
