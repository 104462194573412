import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getAccountId, getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useSignUpForm, Values } from '@pretto/app/src/SignUp/lib/useSignUpForm'
import { useSetDisplayLanguageMutation } from '@pretto/app/src/Simulation/Containers/pushy/lib/displayLanguage.gateway.graphql'
import { NonFinancingSignup as NonFinancingSignupView } from '@pretto/app/src/Simulation/views/nonFinancingSignup/NonFinancingSignup'
import { useSubscribe } from '@pretto/app/src/Subscribe/lib/useSubscribe'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import * as queries from '@pretto/app/src/apollo'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import pick from 'lodash/pick'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

interface NonFinancingSignupProps {
  defaultValues: Partial<Values>
}

export const NonFinancingSignup: React.FC<NonFinancingSignupProps> = ({ defaultValues }) => {
  const { signIn } = useAuth()

  const { push } = useHistory()

  const [assignProject] = useMutation(queries.ASSIGN_PROJECT)
  const [createAccount] = useMutation(queries.CREATE_ACCOUNT)
  const [updateAccount] = useMutation(queries.UPDATE_ACCOUNT)
  const [setDisplayLanguage] = useSetDisplayLanguageMutation()

  const authToken = useRef(getAuthToken())

  const [isSigningIn, setIsSigningIn] = useState(false)

  const { apply } = useSubscribe()

  const trackAction = useTracking()

  const { projectID, typology, isEnglishUser } = useUser()

  useEffect(() => {
    trackAction('NONFINANCINGSIGNUP_PAGE_VIEWED')
  }, [])

  const handleClickCgu = () => {
    trackAction('NONFINANCINGSIGNUP_PAGE_LINKS_CLICKED', { nonfinancingsignup_page_links_clicked: 'cgu' })
  }

  const handleClickPrivacy = () => {
    trackAction('NONFINANCINGSIGNUP_PAGE_LINKS_CLICKED', { nonfinancingsignup_page_links_clicked: 'privacy' })
  }

  const handleDownloadClick = () => {
    trackAction('NONFINANCINGSIGNUP_PAGE_LINKS_CLICKED', { nonfinancingsignup_page_links_clicked: 'faq' })
  }

  const handleSeeClick = () => {
    trackAction('NONFINANCINGSIGNUP_PAGE_LINKS_CLICKED', { nonfinancingsignup_page_links_clicked: 'guide' })
  }

  const handleSubmit = async (values: Values) => {
    setIsSigningIn(true)

    if (typology === 'prospect') {
      const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
      const attributionCookieLastClick = getAttributionCookieLastClickValue()
      const visitorId = await getVisitorId()

      const {
        data: {
          create_account: { token: authorizationToken, error: createAccountError },
        },
      } = await createAccount({
        variables: {
          attributionCookieFirstClick,
          attributionCookieLastClick,
          email: values.email,
          source: 'non_financing',
          visitorId,
        },
      })

      authToken.current = authorizationToken

      if (createAccountError) {
        setError(createAccountError)
        setIsSigningIn(false)
        return
      }

      trackAction('SIGNED_UP', {
        signup_assign_project: true,
        signup_kind: 'non_financing_kind',
        signup_newsletter_immo: false,
        signup_newsletter_taux: false,
        signup_property_search_alert: false,
      })
    }

    const context = { headers: { authorization: `Bearer ${authToken.current}` } }
    const accountId = getAccountId(authToken.current)

    await updateAccount({
      context,
      variables: {
        id: accountId,
        payload: {
          first_name: values.firstName,
          last_name: values.lastName,
        },
      },
    })

    await setDisplayLanguage({
      context,
      variables: {
        input: { displayLanguage: isEnglishUser ? 'en' : 'fr' },
      },
    })

    if (typology === 'prospect') {
      await assignProject({ context, variables: { id: projectID } })
    }

    await apply(pick(values, 'firstName', 'lastName', 'phone'), 'purchase', context)

    await signIn({ accessToken: authToken.current }, () => {
      push('/')
    })
  }

  const { formProps, setError } = useSignUpForm({
    defaultValues,
    onCguClick: handleClickCgu,
    onPrivacyClick: handleClickPrivacy,
    onSubmit: handleSubmit,
  })

  return (
    <ThemeProvider designSystem="reveal">
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <NonFinancingSignupView
        formProps={{ ...formProps, isLoading: isSigningIn }}
        onDownloadClick={handleDownloadClick}
        onSeeClick={handleSeeClick}
      />
    </ThemeProvider>
  )
}
