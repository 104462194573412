import { ButtonProps } from '@pretto/zen/atoms/buttons/Button/Button'

import { Picto } from '@pretto/picto'

import * as S from './DashboardNewProjectCard.styles'

interface Item {
  picto: Picto
  label: string
  caption?: string
}

export interface DashboardNewProjectCardProps {
  description: React.ReactNode
  title: string
  projectTypes: Array<Item>
  buttonProps: ButtonProps
}

export const DashboardNewProjectCard = ({
  title,
  description,
  projectTypes,
  buttonProps,
  ...props
}: DashboardNewProjectCardProps) => {
  return (
    <S.Card {...props}>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.ProjectTypes>
        {projectTypes.map(({ picto, label, caption }) => (
          <S.ProjectItem key={label}>
            <S.Picto $picto={picto} />
            <S.ItemDescription>
              <S.Label>{label}</S.Label>
              <S.Caption>{caption}</S.Caption>
            </S.ItemDescription>
          </S.ProjectItem>
        ))}
      </S.ProjectTypes>
      <S.Cta {...buttonProps} />
    </S.Card>
  )
}
