import type { FieldState } from '@pretto/zen/reveal/types/Field'

import { t } from '@pretto/app/src/lib/i18n'

export interface StateProps {
  message: React.ReactNode
  messageKey: string
  state: FieldState
}

export type Validator = (
  value: string,
  isCheckRequested: boolean,
  inputProps: React.InputHTMLAttributes<HTMLInputElement>,
  invalidatingReason: string | null
) => {
  condition: boolean
  isDebounced?: boolean
  props: StateProps
}

type Validate = (
  validators: Validator[],
  value: string,
  debouncedValue: string,
  isCheckRequested: boolean,
  inputProps: React.InputHTMLAttributes<HTMLInputElement>,
  invalidatingReason: string | null
) => { isValid: boolean; stateProps: StateProps | null }

export const validate: Validate = (
  validators,
  value,
  debouncedValue,
  isCheckRequested,
  inputProps,
  invalidatingReason
) => {
  const mergedValidators: Validator[] = [
    ...validators,
    (value, isCheckRequested, { required = false }) => ({
      condition: isCheckRequested && required && value.trim() === '',
      props: {
        message: t('form.mandatoryField'),
        messageKey: 'required_field',
        state: 'error',
      },
    }),
  ]

  const computedValidators = mergedValidators.map(validator =>
    validator(value, isCheckRequested, inputProps, invalidatingReason)
  )

  const prioritizedValidators = [
    ...computedValidators.filter(({ props }) => props.state === 'error'),
    ...computedValidators.filter(({ props }) => props.state === 'warning'),
  ]

  const stateProps =
    prioritizedValidators.find(({ condition, isDebounced = false }) =>
      isDebounced ? debouncedValue && condition : condition
    )?.props ?? null

  const isValid = prioritizedValidators.find(({ condition = false }) => condition)?.props.state !== 'error'

  return { isValid, stateProps }
}
