import BookingWidget from '@pretto/app/src/Booking/Containers/BookingWidget'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'

export const BlocCalendar = ({ next }) => {
  const trackAction = useTracking()

  const handleBooking = () => {
    trackAction('ONBOARDING_CONTACT_OPTION_CLICKED', { onboarding_contact_option_value: 'make_appointment' })
  }
  const handleBooked = () => {
    trackAction('ONBOARDING_APPOINTMENT_BOOKED')
    next()
  }

  const props = {
    maxRows: 2,
    offsetSizes: { tablet: 4 },
    hideDuration: true,
    onBooked: handleBooked,
    onBooking: handleBooking,
    isHeaderRowSticky: false,
  }

  return <BookingWidget {...props} />
}

BlocCalendar.propTypes = {
  next: PropTypes.func.isRequired,
}
