import TrustpilotStars from '@pretto/bricks/components/iconography/TrustpilotStars'
import { ng } from '@pretto/bricks/components/layout'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import temporal from '@pretto/bricks/core/utility/temporal'

import { ArrowButton } from '@pretto/zen/reveal/atoms/buttons/arrowButton/ArrowButton'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'
import { useCarousel } from '@pretto/zen/reveal/lib/useCarousel'
import { TrustpilotRateProps } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import clamp from 'lodash/clamp'
import { useEffect, useRef } from 'react'

import * as S from './AvisBloc.styles'

interface Advisor {
  imagePath: string
  name: string
}

interface Consumer {
  displayName: string
}

interface Review {
  consumer: Consumer
  createdAt: string
  stars: number
  text: string
  title: string
}

export interface AvisBlocProps {
  advisor?: Advisor
  description: React.ReactNode
  hat?: string
  reviews: Review[]
  title: string
  trustpilotRateProps: TrustpilotRateProps
}

export const AvisBloc: React.FC<AvisBlocProps & React.HTMLAttributes<HTMLDivElement>> = ({
  advisor,
  description,
  hat,
  reviews,
  title,
  trustpilotRateProps,
  ...props
}) => {
  const initialScrollPosition = useBreakpointToValue(0, {
    tablet: ng(36 * clamp(reviews.length - 1, 0, 1)),
    laptop: ng(50 * clamp(reviews.length - 2, 0, 2)),
  })

  const refScrollingContainer = useRef<HTMLDivElement>(null)

  const {
    currentPageIndex,
    getIsIndexVisible,
    scrollBy,
    scrollToIndex,
    scrollTowardsIndex,
    scrollUntilNext,
    scrollUntilPrevious,
  } = useCarousel({
    container: refScrollingContainer,
    numberOfSlides: reviews.length,
  })

  useEffect(() => {
    scrollBy(initialScrollPosition)
  }, [])

  const handleDotClick = (index: number) => {
    scrollToIndex(index)
  }

  const handleNext = () => {
    scrollUntilNext()
  }

  const handlePrevious = () => {
    scrollUntilPrevious()
  }

  return (
    <S.Bloc {...props}>
      <S.BlocHeader>
        {advisor && <S.BlocImage alt={advisor.name} path={advisor.imagePath} />}
        {!advisor && hat && <S.BlocHat>{hat}</S.BlocHat>}

        <S.BlocTitle>{title}</S.BlocTitle>
        <S.BlocDescription>{description}</S.BlocDescription>
      </S.BlocHeader>

      <S.Scroll ref={refScrollingContainer}>
        <S.Carousel $numberOfCards={reviews.length}>
          {reviews.map(({ consumer, createdAt, stars, text, title }, index) => {
            const handleClick = () => {
              if (!getIsIndexVisible(index)) {
                scrollTowardsIndex(index)
              }
            }

            return (
              <S.Review $isOutOfBounds={!getIsIndexVisible(index)} className="review" onClick={handleClick} key={index}>
                <S.Quote />

                <S.Main>
                  <S.Title>{title}</S.Title>
                  <S.Comment>{text}</S.Comment>

                  <S.Reviewer>
                    <TrustpilotStars score={stars} size="small" />
                    <S.ReviewerAuthor>{consumer.displayName}</S.ReviewerAuthor>
                    <ResponsiveSSR as={S.ReviewerDate} min="laptop">
                       - {temporal(createdAt).from(temporal())}
                    </ResponsiveSSR>
                  </S.Reviewer>
                </S.Main>
              </S.Review>
            )
          })}
        </S.Carousel>
      </S.Scroll>

      {reviews.length > 1 && (
        <S.BlocFooter>
          <S.BlocFooterNavigation>
            <ArrowButton direction="left" onClick={handlePrevious} />
            <ArrowButton direction="right" onClick={handleNext} />
            {/* waiting for chromium to fix the bug that affects scrollIntoView to stop when a DOM repaint is triggered
            <ArrowButton disabled={currentPageIndex === 0} direction="left" onClick={handlePrevious} />
            <ArrowButton disabled={currentPageIndex === reviews.length - 1} direction="right" onClick={handleNext} /> */}
          </S.BlocFooterNavigation>

          <S.BlocFooterDots>
            <S.PaginationDots
              onClick={handleDotClick}
              currentIndex={currentPageIndex}
              length={reviews.length}
              visibleLength={Math.min(3, reviews.length)}
            />
          </S.BlocFooterDots>

          <div>
            <S.TrustpilotScore {...trustpilotRateProps} />
          </div>
        </S.BlocFooter>
      )}
    </S.Bloc>
  )
}
