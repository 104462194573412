import { config as agreement } from '@pretto/app/src/dashboard/containers/cards/agreement'
import { config as analysis } from '@pretto/app/src/dashboard/containers/cards/analysis'
import { config as application } from '@pretto/app/src/dashboard/containers/cards/application'
import { config as appointment } from '@pretto/app/src/dashboard/containers/cards/appointment'
import { config as booking } from '@pretto/app/src/dashboard/containers/cards/booking'
import { config as capacity } from '@pretto/app/src/dashboard/containers/cards/capacity'
import { config as certificate } from '@pretto/app/src/dashboard/containers/cards/certificate'
import { config as contact } from '@pretto/app/src/dashboard/containers/cards/contact'
import { config as documents } from '@pretto/app/src/dashboard/containers/cards/documents'
import { config as end } from '@pretto/app/src/dashboard/containers/cards/end'
import { config as keepProject } from '@pretto/app/src/dashboard/containers/cards/keepProject'
import { config as mandate } from '@pretto/app/src/dashboard/containers/cards/mandate'
import { config as maturity } from '@pretto/app/src/dashboard/containers/cards/maturity'
import { config as negotiationTransparency } from '@pretto/app/src/dashboard/containers/cards/negotiationTransparency'
import { config as newProject } from '@pretto/app/src/dashboard/containers/cards/newProject'
import { config as overview } from '@pretto/app/src/dashboard/containers/cards/overview'
import { config as projectDetails } from '@pretto/app/src/dashboard/containers/cards/projectDetails'
import { config as propertySearch } from '@pretto/app/src/dashboard/containers/cards/propertySearch'
import { config as signature } from '@pretto/app/src/dashboard/containers/cards/signature'
import { config as signed } from '@pretto/app/src/dashboard/containers/cards/signed'
import { config as sponsorship } from '@pretto/app/src/dashboard/containers/cards/sponsorship'
import { config as submit } from '@pretto/app/src/dashboard/containers/cards/submit'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { StatusEnum } from '@pretto/app/src/types/gateway/enums'

export const cardsByStatus: Record<StatusEnum, Config[]> = {
  // not supported
  [StatusEnum.ApplicationReady]: [],
  [StatusEnum.ApplicationSent]: [],
  [StatusEnum.Lost]: [],
  // supported
  [StatusEnum.Search]: [certificate, application, booking, overview, maturity, capacity, propertySearch],
  [StatusEnum.Constitution]: [application, certificate, booking, overview, maturity],
  [StatusEnum.Validation]: [submit, certificate, application, booking, overview, maturity],
  [StatusEnum.Analysis]: [analysis, certificate, booking, documents, maturity],
  [StatusEnum.Confirmation]: [mandate, documents, booking, maturity],
  [StatusEnum.Negotiation]: [negotiationTransparency, documents, booking, projectDetails],
  [StatusEnum.Agreement]: [agreement, documents, booking, sponsorship],
  [StatusEnum.Appointment]: [appointment, agreement, documents, booking, sponsorship],
  [StatusEnum.Signature]: [signature, agreement, booking, documents, sponsorship],
  [StatusEnum.Signed]: [signed, agreement, booking, documents, sponsorship],
  [StatusEnum.End]: [end, newProject],
  [StatusEnum.LostHardClient]: [contact, sponsorship],
  [StatusEnum.LostOneChoice]: [newProject, sponsorship],
  [StatusEnum.LostDoubleChoice]: [keepProject, newProject, sponsorship],
}
