import temporal from '@pretto/bricks/core/utility/temporal'

interface LastMandateDateParams {
  mortgagorMandateSignDate?: string | null
  comortgagorMandateSignDate?: string | null
}

export const getLastMandateDate = ({ mortgagorMandateSignDate, comortgagorMandateSignDate }: LastMandateDateParams) => {
  if (!!mortgagorMandateSignDate && !!comortgagorMandateSignDate) {
    return temporal.max(temporal(mortgagorMandateSignDate), temporal(comortgagorMandateSignDate))
  }

  if (!mortgagorMandateSignDate && !!comortgagorMandateSignDate) {
    return temporal(comortgagorMandateSignDate)
  }

  return temporal(mortgagorMandateSignDate)
}
