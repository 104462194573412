import PreLeadDashboardPage from '@pretto/bricks/app/pre-lead/pages/PreLeadDashboard'

import useBudgetProjectCard from '@pretto/app/src/PreLead/Containers/ProjectCards/Budget'
import { PropertySearch as PropertySearchProjectCard } from '@pretto/app/src/PreLead/Containers/ProjectCards/PropertySearch'
import usePurchaseProjectCard from '@pretto/app/src/PreLead/Containers/ProjectCards/Purchase'
import useRenegotiationProjectCard from '@pretto/app/src/PreLead/Containers/ProjectCards/Renegotiation'
import MaturityUpdateDialog from '@pretto/app/src/Project/Containers/MaturityUpdateDialog'
import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { Calculator, Coins } from '@pretto/picto'

import { createContext, useContext, useEffect, useState } from 'react'

import { BANNER_CONFIGS, PROGRESS, PROJECT_KIND } from './utils/config'
import { getStatus } from './utils/getStatus'
import { initBubbleText } from './utils/initBubbleText'
import { initProjectCards } from './utils/initProjectCards'
import { initServiceCards } from './utils/initServiceCards'
import { validProject } from './utils/validProject'

export const PreLeadContext = createContext(null)

const PreLeadPage = () => {
  const propertySearch = usePropertySearch()

  const {
    isProjectAvailable,
    project,
    user: { isPropertyFound },
  } = useUser()

  const { onChangeProjectKind, projectKind } = useContext(PreLeadContext)

  const trackAction = useTracking()

  const projectStatus = project?.purchase?.maturity
  const projectPropertyPrice = project?.purchase?.property_price

  const getProjectStatus = () => {
    if (!!projectStatus && ['under_study', 'search_started'].includes(projectStatus)) {
      return PROGRESS.searching
    }

    if ((!!projectStatus && !['under_study', 'search_started'].includes(projectStatus)) || !!projectPropertyPrice) {
      return PROGRESS.found
    }

    return PROGRESS.searching
  }

  const [progress, setProgress] = useState(getProjectStatus())

  const [isOverlayOpen, setIsOverlayOpen] = useState(false)

  const onChangeProgress = value => {
    trackAction('PRELEAD_PROJECT_MATURITY_CHANGED', {
      maturity: value,
    })
    setProgress(value)

    if (projectStatus !== 'sale_agreement_signed') {
      setIsOverlayOpen(true)
    }
  }

  useEffect(() => {
    setProgress(getProjectStatus)
  }, [isOverlayOpen, projectStatus])

  const BudgetProjectCard = useBudgetProjectCard()
  const PurchaseProjectCard = usePurchaseProjectCard()
  const RenegotiationProjectCard = useRenegotiationProjectCard()

  const projects = [BudgetProjectCard, PropertySearchProjectCard, PurchaseProjectCard, RenegotiationProjectCard]

  const cards = initProjectCards(projects, projectKind, progress, propertySearch)

  const isFundable = BudgetProjectCard?.data?.capacity?.length > 0

  const isSimulationOK = !!PurchaseProjectCard?.data?.simulation

  const isValidProject = validProject({
    isFundable,
    isProjectAvailable,
    isPropertyFound,
    isSimulationOK,
    progress,
    projectKind,
    RenegotiationProjectCard,
  })

  const services = initServiceCards({ isPropertyFound, isValidProject, progress, projectKind, propertySearch })
  const status = getStatus({
    isProjectAvailable,
    isPropertyFound,
    isValidProject,
    progress,
    projectKind,
    propertySearch,
  })

  useEffect(() => {
    if (BudgetProjectCard.isLoaded && PurchaseProjectCard.isLoaded && RenegotiationProjectCard.isLoaded && status) {
      trackAction('PRELEAD_DASHBOARD_PAGE_VIEWED', {
        simu_kind: status,
      })
    }
  }, [BudgetProjectCard.isLoaded, PurchaseProjectCard.isLoaded, RenegotiationProjectCard.isLoaded, status])

  const props = {
    bannerProps: BANNER_CONFIGS[status],
    bubbleText: initBubbleText({
      isProjectAvailable,
      isPropertyFound,
      isValidProject,
      progress,
      projectKind,
    }),
    cards,
    isOverlayOpen,
    onChangeProgress,
    onChangeProjectKind,
    progress,
    projectKind,
    radioProps:
      projectKind === PROJECT_KIND.purchase
        ? {
            name: 'isSearching',
            onChange: onChangeProgress,
            options: [
              {
                id: PROGRESS.searching,
                label: (
                  <>
                    Vous <strong>recherchez</strong> encore votre futur bien
                  </>
                ),
                value: PROGRESS.searching,
              },
              {
                id: PROGRESS.found,
                label: (
                  <>
                    Vous avez <strong>trouvé</strong> un bien qui vous plait !
                  </>
                ),
                value: PROGRESS.found,
              },
            ],
            valueSelected: progress,
          }
        : null,
    services,
    widgetLinks: [
      {
        href: '/project',
        label: 'Simulez votre prêt',
        picto: Calculator,
      },
      {
        href: '/project/introduction/others',
        label: 'Simulez votre rachat',
        picto: Coins,
      },
    ],
  }

  const handleOverlayClose = () => {
    setIsOverlayOpen(false)
  }

  return (
    <>
      {project?.purchase && (
        <MaturityUpdateDialog
          isOpen={isOverlayOpen}
          onClose={handleOverlayClose}
          projectStatus={projectStatus}
          locationEvent={'prelead_radio_maturity_changed'}
        />
      )}
      <PreLeadDashboardPage {...props} />
    </>
  )
}

const PreLeadPageContainer = () => {
  const { data } = usePropertySearch()

  const trackAction = useTracking()

  const { project } = useUser()

  const [projectKind, setProjectKind] = useState(project?.project_kind ?? 'default')

  const handleChangeProjectKind = ({ value }) => {
    trackAction('PRELEAD_PROJECT_TYPE_CHANGED', {
      project_type: value,
    })
    setProjectKind(value)
  }

  const onTrackProjectCard = (kind, state) => {
    trackAction('PRELEAD_PROJECT_CARD_CLICKED', { prelead_project_card_kind: kind, prelead_project_card_state: state })
  }

  const onTrackFeatureCard = kind => {
    trackAction('PRELEAD_FEATURE_CARD_CLICKED', { prelead_feature_card_kind: kind })
  }

  const context = {
    onChangeProjectKind: handleChangeProjectKind,
    onTrackFeatureCard,
    onTrackProjectCard,
    projectKind,
  }
  return (
    <PreLeadContext.Provider value={context}>
      <Header navigationItemsList={[NAV_ITEMS.faq]} menu="preLead" />
      <PreLeadPage />
    </PreLeadContext.Provider>
  )
}

export default PreLeadPageContainer
