import TrustpilotStarsComponent from '@pretto/bricks/components/iconography/TrustpilotStars'
import { g } from '@pretto/bricks/components/layout'
import LinkComponent from '@pretto/bricks/website/utility/Link'

import styled, { css } from 'styled-components'

import { Alignment } from './TrustpilotRate'
import { TrustpilotLogo as TrustpilotLogoComponent } from './components/TrustpilotLogo'

interface AlignmentProps {
  $alignment: Alignment
}

export const Component = styled.div<AlignmentProps>`
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.neutral1};
  column-gap: ${g(2)};
  display: grid;
  justify-content: flex-start;
  justify-items: center;
  row-gap: ${g(1)};

  ${({ $alignment }) => {
    switch ($alignment) {
      case 'stack':
        return css`
          grid-template-areas:
            'logo'
            'stars'
            'numbers';
        `
        return

      case 'twolines':
        return css`
          grid-template-areas:
            'logo logo'
            'stars numbers';
        `
        return

      case 'inline':
      default:
        return css`
          grid-template-areas: 'logo stars numbers';
        `
    }
  }}
`

export const Count = styled(LinkComponent)`
  ${({ theme }) => theme.typos.isNumber};
  text-decoration: underline;
`

export const Logo = styled(TrustpilotLogoComponent)<AlignmentProps>`
  grid-area: logo;
  height: ${g(4)};
  width: auto;

  ${({ $alignment }) =>
    $alignment === 'stack' &&
    css`
      margin-bottom: ${g(1)};
    `};

  ${({ $alignment }) =>
    $alignment === 'twolines' &&
    css`
      margin-left: ${g(-4)};
    `};
`

export const SchemaName = styled.div`
  display: none;
`

export const Score = styled.div`
  ${({ theme }) => theme.typos.body4Bold};
`

export const ScoreContainer = styled.div`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  grid-area: numbers;
`

export const TrustpilotStars = styled(TrustpilotStarsComponent)`
  grid-area: stars;
`
