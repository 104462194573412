import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import { Desktop } from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import { InfoCircle, TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const ListItem = ({ content, isErrored = false, onDelete, onEdit, title }) => (
  <S.ListItem>
    <Card format="line" variant="neutral-1-20">
      <S.ListItemHeader>
        <Content size="x-small" variant="neutral-1">
          <S.ListItemHeaderContentActions>
            <S.ListItemHeaderContentAction>
              <SubHeading size="small">{title}</SubHeading>
            </S.ListItemHeaderContentAction>

            <S.ListItemHeaderContentActions>
              {onEdit && (
                <S.ListItemHeaderContentActions withCursor onClick={onEdit}>
                  <S.ListItemHeaderContentAction>
                    <Desktop>Modifier</Desktop>
                  </S.ListItemHeaderContentAction>

                  <S.ListItemHeaderContentAction>
                    <Icon name="pen" />
                  </S.ListItemHeaderContentAction>
                </S.ListItemHeaderContentActions>
              )}

              {onDelete && (
                <S.ListItemHeaderContentAction withCursor onClick={onDelete}>
                  <Icon name="cross" />
                </S.ListItemHeaderContentAction>
              )}
            </S.ListItemHeaderContentActions>
          </S.ListItemHeaderContentActions>
        </Content>
      </S.ListItemHeader>

      <Content>
        {content}

        {isErrored && onEdit && (
          <S.FormErrorContainer>
            <Banner picto={InfoCircle} type={'warning'}>
              Il manque des informations pour "{title}". <button onClick={onEdit}>Compléter</button>
            </Banner>
          </S.FormErrorContainer>
        )}
      </Content>
    </Card>
  </S.ListItem>
)

ListItem.propTypes = {
  content: PropTypes.node,
  isErrored: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string,
}

const ApplicationListField = ({
  addLabel,
  dialogTitle,
  disclaimer,
  fields,
  isDialogOpen,
  isDialogSubmitDisabled,
  items,
  onAdd,
  onDialogClose,
  onDialogValidate,
}) => (
  <div>
    {!!disclaimer && (
      <CollapsibleRow appear in={items.length === 0} parentComponent={S.ListItemParent}>
        <S.ListDisclaimer>
          <Content>
            <S.ListDisclaimerContent>
              <S.ListDisclaimerEmoji>
                <Emoji>{disclaimer.emoji}</Emoji>
              </S.ListDisclaimerEmoji>

              <div>
                <div>
                  <Text size="small">{disclaimer.title}</Text>
                </div>

                <div>
                  <Text size="small">{disclaimer.description}</Text>
                </div>
              </div>
            </S.ListDisclaimerContent>
          </Content>
        </S.ListDisclaimer>
      </CollapsibleRow>
    )}

    <TransitionGroup component={Fragment}>
      {items.map(({ id, ...item }) => (
        <CollapsibleRow key={id} parentComponent={S.ListItemParent}>
          <ListItem {...item} />
        </CollapsibleRow>
      ))}
    </TransitionGroup>

    <S.ListItemParent>
      <S.ListItem>
        <S.Add onClick={onAdd}>
          <Content>
            <S.AddContent>
              <S.AddButton>
                <ButtonLegacy format="circle" iconProps={{ name: 'add' }} size="x-small" />
              </S.AddButton>

              <div>{addLabel}</div>
            </S.AddContent>
          </Content>
        </S.Add>
      </S.ListItem>
    </S.ListItemParent>

    <Dialog isOpen={isDialogOpen} onRequestClose={onDialogClose}>
      <DialogTemplate
        buttonProps={{
          children: 'Valider',
          disabled: isDialogSubmitDisabled,
          onClick: onDialogValidate,
        }}
        navbarProps={{
          iconLeft: TimesBold,
          iconLeftAction: onDialogClose,
          title: dialogTitle,
        }}
      >
        <Content>
          {fields.map(({ component, key }) => (
            <S.Field key={key}>{component}</S.Field>
          ))}
        </Content>
      </DialogTemplate>
    </Dialog>
  </div>
)

ApplicationListField.propTypes = {
  /** Label to be used for the list add button. */
  addLabel: PropTypes.string.isRequired,
  /** Title of the dialog. */
  dialogTitle: PropTypes.string.isRequired,
  /** Disclaimer to be displayed when no items. */
  disclaimer: PropTypes.shape({
    description: PropTypes.string.isRequired,
    emoji: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  /** Array of fields to be used in the dialog. */
  fields: PropTypes.array.isRequired,
  /** Whether or not the dialog is opened. */
  isDialogOpen: PropTypes.bool.isRequired,
  /** Whether or not the dialog submit button is disabled. */
  isDialogSubmitDisabled: PropTypes.bool.isRequired,
  /** Items of the list. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      onDelete: PropTypes.func,
      onEdit: PropTypes.func,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  /** Event triggered whenever the add button is pressed. */
  onAdd: PropTypes.func.isRequired,
  /** Event triggered whenever the dialog close action is triggered. */
  onDialogClose: PropTypes.func.isRequired,
  /** Event triggered whenever the dialog submit button is pressed. */
  onDialogValidate: PropTypes.func.isRequired,
}

export default ApplicationListField
