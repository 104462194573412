import LinkComponent from '@pretto/bricks/website/utility/Link'

import { trackAction } from '@pretto/app/src/lib/tracking'

import styled from 'styled-components'

export const KnowMore = () => {
  const handleTrackLink = () => {
    trackAction('LOGIN_FAQ_CLICKED')
  }

  return (
    <Link href="https://faq.pretto.fr/fr/articles/837186" onClick={handleTrackLink}>
      en savoir plus
    </Link>
  )
}

const Link = styled(LinkComponent)`
  text-decoration: underline;
`
