import { breakpoints } from '@pretto/bricks/components/layout'

import type { Optional } from '@pretto/app/src/types/Optional'

import styled from 'styled-components'

import { Criterias, CriteriasProps } from './components/Criterias/Criterias'
import { Result, ResultProps } from './components/Result/Result'

export type BlocResultatProps = Optional<CriteriasProps & ResultProps, 'isDisclaimed' | 'isSecondary'>

export const BlocResultat: React.FC<BlocResultatProps> = ({
  bankCriterias,
  bankIndex,
  buttonLabel,
  details,
  isDisclaimed = true,
  isSecondary = false,
  offerCriterias,
  onButtonClick,
  onDetailsButtonClick,
  payment,
  ...props
}) => (
  <Bloc {...props}>
    <Result
      bankIndex={bankIndex}
      buttonLabel={buttonLabel}
      details={details}
      isDisclaimed={isDisclaimed}
      isSecondary={isSecondary}
      onButtonClick={onButtonClick}
      onDetailsButtonClick={onDetailsButtonClick}
      payment={payment}
    />

    <Criterias bankCriterias={bankCriterias} isDisclaimed={isDisclaimed} offerCriterias={offerCriterias} />
  </Bloc>
)

const Bloc = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    flex-direction: row;
  }
`
