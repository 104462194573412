import Icon from '@pretto/bricks/components/iconography/Icon'
import Text from '@pretto/bricks/components/typography/Text'

import Cleave from 'cleave.js/react'
import PropTypes from 'prop-types'
import { forwardRef, memo, useEffect, useState } from 'react'

import * as S from './styles'

const NumberField = forwardRef(
  (
    {
      errorText,
      format,
      icon,
      inputProps,
      onChange,
      options,
      placeholder,
      size,
      suffix,
      type,
      value,
      variant,
      ...props
    },
    ref
  ) => {
    const [cleave, setCleave] = useState(null)
    const [formattedValue, setFormattedValue] = useState('')

    useEffect(() => {
      cleave?.setRawValue(value ?? '')
    }, [cleave, value])

    const defaultOptions = {
      delimiter: ' ',
      numeral: true,
      numeralDecimalMark: ',',
      numeralDecimalScale: type === 'decimal' ? Infinity : 0,
      numeralPositiveOnly: true,
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
    }

    const handleChange = event => {
      const formattedValue = event.target.value
      const rawValue = event.target.rawValue

      const parsedRawValue = type === 'decimal' ? parseFloat(rawValue) : parseInt(rawValue, 10)
      const processedRawValue = isNaN(parsedRawValue) ? null : parsedRawValue

      if (processedRawValue !== value) {
        onChange(processedRawValue)
      }

      setFormattedValue(formattedValue)
    }

    const handleInit = cleave => {
      setCleave(cleave)
    }

    return (
      <S.NumberField format={format} isValid={value !== ''} size={size} variant={variant}>
        {format === 'sentence' && <pre>{formattedValue === '' ? placeholder : formattedValue}</pre>}

        <Cleave
          {...inputProps}
          {...props}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          htmlRef={ref}
          onInit={handleInit}
          onChange={handleChange}
          options={mergedOptions}
          placeholder={placeholder}
          spellCheck="false"
          value=" "
        />

        {suffix && <S.Suffix variant={variant}>{suffix}</S.Suffix>}

        {icon && (
          <S.Icon>
            <Icon name={icon} variant={variant} />
          </S.Icon>
        )}

        {!!errorText && (
          <S.Error>
            <Text size="x-small" variant="error">
              {errorText}
            </Text>
          </S.Error>
        )}
      </S.NumberField>
    )
  }
)

NumberField.propTypes = {
  /** Error text behind field. Shown as soon as it is set. */
  errorText: PropTypes.node,
  /** Format of input. */
  format: PropTypes.oneOf(['display', 'editable-input', 'form', 'form-field', 'large', 'sentence', 'textfield']),
  /** Icon of field. */
  icon: PropTypes.string,
  /** html input props */
  inputProps: PropTypes.object,
  /** Triggered when input value is changed. */
  onChange: PropTypes.func.isRequired,
  /** Options for format (cf. Cleave). [See options](https://github.com/nosir/cleave.js/blob/master/doc/options.md). */
  options: PropTypes.object,
  /** Placeholder of NumberField. */
  placeholder: PropTypes.string,
  /** Size of input. */
  size: PropTypes.string,
  /** Suffix placed inside field on the right side. */
  suffix: PropTypes.node,
  /** Either decimal or number, but both will be rendered as text by Cleave. */
  type: PropTypes.oneOf(['decimal', 'number']),
  /** Value of NumberField. */
  value: PropTypes.any,
  /** Variant of input. */
  variant: PropTypes.string,
}

NumberField.defaultProps = {
  errorText: '',
  format: 'sentence',
  placeholder: '',
  type: 'number',
  variant: 'primary-1',
}

NumberField.displayName = 'NumberField'

export default memo(NumberField)
