import { sponsorshipBlockInfosProps } from '@pretto/app/src/dashboard/config/sponsorshipBlockInfosProps'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const End: React.FC<CardProps> = ({ defaultComponent: Component }) => (
  <Component
    blockInfos={sponsorshipBlockInfosProps}
    description="Votre projet immobilier avec Pretto s’est finalisé."
    title="Ce projet est terminé"
  />
)

export const config: Config = {
  component: End,
  name: 'end',
}
