import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import CardComponent from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

interface PictoProps {
  $picto: PictoType
}

export const Card = styled(CardComponent)`
  padding: ${g(3)} ${g(2.5)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }
`

export const Title = styled.h1`
  ${typo.heading32};
  margin-bottom: ${g(1)};
`

export const Description = styled.h2`
  ${typo.bodyBook16};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`

export const ProjectCards = styled.div`
  margin: ${g(2)} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(3)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: row;
  }

`

export const ProjectCard = styled.div`
  display: flex;
  & + & {
    margin-top: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: column;
    flex-basis: 100%;
    
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
    padding: ${g(2)};
    
    & + & {
      margin-left: ${g(3)};
      margin-top: 0;
    }
  }
`

export const ProjectCardHeader = styled.div`
  display: flex;
  ${typo.caption12};
  
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.bodyBook16};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(2)};
  }
`

export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  flex-shrink: 0;
  margin-right: ${g(2)};
`

export const Value = styled.p`
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${typo.heading16};
    color: ${({ theme }) => theme.colors.primary1};
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const Cta = styled(ButtonComponent).attrs({ isSecondary: true })`
  width: 100%;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    margin-bottom: 0;
    margin-right: ${g(3)};
  }
`
