import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Card = styled.div`
  & + & {
    margin-top: ${g(2)};
  }
`

export const CardTitle = styled.div`
  margin-bottom: ${g(2)};
`

export const ContactButton = styled.div`
  margin-top: ${g(2)};
`

export const Container = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`

export const Divider = styled.div`
  margin: ${g(3)} 0;
`

export const Group = styled.div`
  position: relative;
  z-index: 0;

  & + & {
    margin-top: ${g(2)};
  }
`

export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  min-height: 100%;
  padding: ${g(2)} 0 ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} ${g(3)};
  }
`

export const Row = styled.div`
  & + & {
    margin-top: ${g(2)};
  }
`

export const RowHighlight = styled.span`
  background-color: ${({ theme, variant }) => theme.legacy.colors[variant]};
  bottom: ${g(-1)};
  content: '';
  display: block;
  left: ${g(-7 / 2)};
  opacity: 0;
  position: absolute;
  right: ${g(-7 / 2)};
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  top: ${g(-1)};
  z-index: -1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    transform: scale(1.05);
    transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      opacity: 1;

      @media screen and (min-width: ${breakpoints.tablet}) {
        transform: scale(1);
      }
    `}
`

export const Sidebar = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 0 0 ${100 / 3}%;
    margin-left: ${g(3)};
  }
`

export const Title = styled.div`
  margin-bottom: ${g(4)};
`
