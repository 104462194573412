import { ReactNode } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './CalendarSlot.styles'

export interface CalendarSlotProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode
  isDisabled?: boolean
  isLoading?: boolean
}

export const CalendarSlot = ({ children, isDisabled = false, isLoading = false, ...props }: CalendarSlotProps) => (
  <S.Slot {...props} $isEmpty={!children} $isDisabled={isDisabled} $isLoading={isLoading}>
    <Transition in={isLoading} timeout={{ enter: S.TRANSITION_DELAY, exit: S.TRANSITION_DURATION_OUT }} unmountOnExit>
      {state => <S.Spinner $isLoading={state === 'entered'} />}
    </Transition>

    {children && <S.Label $isLoading={isLoading}>{children}</S.Label>}
  </S.Slot>
)
