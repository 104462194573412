import { breakpoints, g } from '@pretto/bricks/components/layout'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { CheckBold, ExclamationMarkCircleBold, TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export type Type = 'success' | 'error'

export interface SnackbarProps {
  isDismissible?: boolean
  onDismiss?: () => void
  type: Type
}

export const Snackbar: React.FC<SnackbarProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  isDismissible = false,
  onDismiss,
  type,
  ...props
}) => (
  <Container {...props} $type={type}>
    <Picto as={type === 'success' ? CheckBold : ExclamationMarkCircleBold} />
    <Label>{children}</Label>

    {isDismissible && (
      <CloseButton aria-label="Fermer" onClick={onDismiss} type="button">
        <TimesBold />
      </CloseButton>
    )}
  </Container>
)

const CloseButton = styled.button`
  align-items: center;
  appearance: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: ${g(3)};
  justify-content: center;
  margin-left: ${g(-1)};
  margin-right: ${g(-3 / 2)};
  margin-top: ${g(-1 / 2)};
  width: ${g(3)};
`

interface ContainerProps {
  $type: Type
}

const Container = styled.div<ContainerProps>`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  gap: ${g(2)};
  padding: ${g(2)} ${g(3)} ${g(2)} ${g(2)};

  a,
  button {
    appearance: none;
    border: none;
    color: inherit;
    font: inherit;
    text-decoration: underline;
  }

  button:enabled {
    cursor: pointer;
  }

  strong {
    ${({ theme }) => theme.typos.body4Medium};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    box-shadow: 0 ${g(1 / 2)} ${g(2)} 0 ${({ theme }) => addAlpha(theme.colors.neutral1, 0.08)};
    border-radius: ${g(1)};
  }

  ${({ $type, theme }) => {
    switch ($type) {
      case 'error':
        return css`
          background-color: ${theme.colors.error2};
          color: ${theme.colors.error1};
        `

      case 'success':
      default:
        return css`
          background-color: ${theme.colors.primary4};
          color: ${theme.colors.primary1};
        `
    }
  }}
`

const Label = styled.span`
  flex-grow: 1;
`

const Picto = styled.svg`
  flex-shrink: 0;
  height: ${g(3)};
`
