import SentencesComponent from '@pretto/bricks/app/sentences/components/SentencesGroup'
import DividerComponent from '@pretto/bricks/components/dividers/Divider'
import { breakpoints, g } from '@pretto/bricks/components/layout'

import { BlockSentencesProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/BlockSentences/BlockSentences'

import styled from 'styled-components'

interface PictoProps {
  $picto: BlockSentencesProps['picto']
}

export const SentencesLine = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
`
export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  flex: 0 0 ${g(3)};
  width: ${g(3)};
  height: ${g(3)};
  margin-left: ${g(3)};
  margin-top: 6px;
  margin-right: ${g(-1)};
`
export const Block = styled.div`
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding-top: ${g(2)};
`
export const Divider = styled(DividerComponent)`
  width: auto;
  margin: ${g(1)} ${g(2)} ${g(3)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(3)};
    margin-left: ${g(3)};
  }
`
export const Sentences = styled(SentencesComponent)`
  margin: 0 ${g(2)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-grow: 1;

    & + & {
      margin-left: ${g(5)};
    }
  }
`
