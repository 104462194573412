import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect } from 'react'

import { useAuth } from '../AuthProvider'

const ImpersonatePage = ({ history, location }) => {
  const { signIn, refetch } = useAuth()

  useEffect(() => {
    const accessToken = location.hash.slice(1)
    const redirect = qs.parse(location.search, { ignoreQueryPrefix: true })?.redirect ?? '/'

    signIn({ accessToken }, async () => {
      await refetch()
      history.replace(redirect)
    })
  }, [])

  return <SpinnerLegacy overlay />
}

ImpersonatePage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default ImpersonatePage
