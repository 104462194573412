import ReduceAmbition from '@pretto/bricks/app/reduce-ambition/pages/ReduceAmbition'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { t } from '@pretto/app/src/lib/i18n'

import PropTypes from 'prop-types'
import { useEffect } from 'react'

import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'

export const OptionsPage = ({ onModify, onView, options }) => {
  const { typology } = useUser()

  useEffect(() => {
    onView()
  }, [])

  const data = [
    ...options,
    {
      action: onModify,
      buttonLabel: t('reduceAmbitionPage.optionsPage.edit'),
      children: t('reduceAmbitionPage.optionsPage.message'),
      format: 'simple',
      title:
        typology === 'client'
          ? t('reduceAmbitionPage.optionsPage.editFolder')
          : t('reduceAmbitionPage.optionsPage.editProfile'),
    },
  ]

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <ReduceAmbition data={data} />
    </>
  )
}

OptionsPage.defaultProps = {
  onView: () => {},
}

OptionsPage.propTypes = {
  onModify: PropTypes.func.isRequired,
  onView: PropTypes.func,
  options: PropTypes.array.isRequired,
}
