interface ComputeNumberSelectionStart {
  inputType: string
  selectionEnd: number
  rawValue: string
  stringValue: string
  thousandSeparator: string
}

export const computeNumberSelectionStart = ({
  inputType,
  selectionEnd,
  rawValue,
  stringValue,
  thousandSeparator,
}: ComputeNumberSelectionStart): number => {
  const selectionStart = stringValue.length - rawValue.substring(selectionEnd).length

  if (inputType === 'deleteContentBackward' && stringValue.substring(0, selectionStart).endsWith(thousandSeparator)) {
    return Math.max(0, selectionStart - 1)
  }

  return Math.max(0, selectionStart)
}
