import MaturityPageTemplate from '@pretto/bricks/app/maturity/components/MaturityPageTemplate'
import FormList from '@pretto/bricks/components/layout/FormList'
import FormSection from '@pretto/bricks/components/layout/FormSection'
import FormTemplate from '@pretto/bricks/components/layout/FormTemplate'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { pages } from '@pretto/app/src/Project/config/leadingSource'
import usePropertyController, { defaultSchema } from '@pretto/app/src/Project/lib/usePropertyController'
import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { Maturity } from '@pretto/app/src/config/maturity'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useLeadingSourceQuery } from './leadingSource.gateway.graphql'

interface PageProps {
  match: {
    params: {
      option: Exclude<Maturity, Maturity.search_started | Maturity.under_study | Maturity.unspecified>
    }
  }
}

interface SectionProps {
  fields: {
    component: React.ReactElement
    key: string
  }[]
  title: string
}

export const MaturityPropertyLeadingSourcePage: React.FC<PageProps> = ({ match }) => {
  const { data, loading } = useLeadingSourceQuery()
  const maturity = match.params.option
  const schema = pages.find(({ value }) => value === maturity) || defaultSchema

  const { push } = useHistory()

  const [updateProject] = useMutation(UPDATE_PROJECT)

  const { getPayload, isDisabled, sections, values } = usePropertyController(schema, data)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const trackAction = useTracking()

  const handleSubmit = async () => {
    setIsSubmitting(true)

    const { lead, project } = getPayload()

    if (project) {
      await updateProject({ variables: { project: JSON.stringify(project) } })
    }

    trackAction('PROPERTY_LEADING_SOURCE_UPDATED', {
      ...lead,
      property_leading_source_maturity: maturity,
      ...(maturity === 'sale_agreement_signed'
        ? { property_leading_source_notary_declared: values.notary_known === 'true' }
        : {}),
    })

    push('/')
  }

  if (loading || isSubmitting) {
    return <SpinnerLegacy overlay />
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <MaturityPageTemplate
        description="C'est noté ! Nous aimerions savoir quels autres services ou intermédiaires contribuent à ce projet d'achat."
        title="Vos interlocuteurs"
      >
        <FormTemplate isDisabled={isDisabled} onSubmit={handleSubmit} submitLabel="Mettre à jour">
          {sections.map(({ fields, title }: SectionProps, index: number) => (
            <FormSection key={index} title={title}>
              <FormList fields={fields} />
            </FormSection>
          ))}
        </FormTemplate>
      </MaturityPageTemplate>
    </>
  )
}
