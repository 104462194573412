import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Container = styled.div`
  & + & {
    margin-top: ${g(2)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: ${g(3)};
    }
  }
`
