/* eslint-disable sort-keys */
import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { ChevronDownBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

import { DropdownProps } from './Dropdown'

const TRANSLATION_Y = {
  small: 4,
  medium: 6,
}

interface SizeProps {
  $size: NonNullable<DropdownProps['size']>
}

interface ComponentWithOpenProps {
  $isOpen: DropdownProps['isOpen']
}

interface LabelProps extends SizeProps {
  $isSelected: boolean
  $component: DropdownProps['LabelComponent']
}

interface ContainerProps extends ComponentWithOpenProps, SizeProps {
  $component: DropdownProps['ContainerComponent']
}

export const Label = styled.label.attrs<LabelProps>(({ $component }) => $component && { as: $component })<LabelProps>`
  ${typo.inputBook16};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral2};
  background-color: ${({ theme }) => theme.colors.neutral4};
  border: 1px solid transparent;
  transition: border-color 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral2};
  }

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          border-radius: ${g(0.5)};
          padding: 0 ${g(1)};
          height: ${g(4)};
        `
      case 'medium':
        return css`
          border-radius: ${g(1)};
          padding: ${g(1)} ${g(2)};
          height: ${g(6)};
        `

      default:
        break
    }
  }}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.colors.neutral1};
      border-color: ${({ theme }) => theme.colors.neutral3};
      background-color: ${({ theme }) => theme.colors.white};

      &:hover {
        border-color: ${({ theme }) => theme.colors.neutral2};
      }
    `}
`
export const LabelOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`
export const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const Arrow = styled(ChevronDownBold)<ComponentWithOpenProps>`
  transition: transform 300ms ease-in-out;
  margin-left: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral1};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotateZ(180deg);
    `}
`
export const DropdownContainer = styled.div`
  position: relative;
`
export const Container = styled.div.attrs<ContainerProps>(
  ({ $component }) => $component && { as: $component }
)<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 400ms ease-in-out;
  z-index: 100;
  display: inline-block;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      visibility: visible;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    overflow: initial;
    bottom: unset;
    transform: translateY(0);
    opacity: 0;
    ${helpers.boxShadow('card')};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${g(1)};
    transition: all 400ms ease-in-out;

    ${({ $isOpen, $size }) => {
      const translationY = TRANSLATION_Y[$size]

      if ($isOpen) {
        return css`
          transform: translateY(${g(translationY)});
          opacity: 1;
          transition:
            transform 400ms ease-in-out,
            opacity 400ms ease-in-out;
        `
      }
    }}
  }
`
export const Overlay = styled.div<ComponentWithOpenProps>`
  background-color: ${({ theme }) => theme.colors.overlay};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const Content = styled.div<ComponentWithOpenProps>`
  border-radius: ${g(1)} ${g(1)} 0 0;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 75vh;
  z-index: 120;
  transform: translateY(100%);
  transition: transform 400ms ease-in-out;
  overflow: auto;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: translateY(0);
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: initial;
    transform: translateY(0);
    border-radius: inherit;
    height: unset;
  }
`
