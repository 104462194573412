export enum SimulationType {
  Buyout = 'buyout',
  Capacity = 'capacity',
  Purchase = 'purchase',
  Renegotiation = 'renegotiation',
}

export enum CreditType {
  Mortgage = 'mortgage',
  Consumer = 'consumer',
  Car = 'car',
  Student = 'student',
  LOA = 'LOA',
  Other = 'other',
}

export enum IncomePeriod {
  Year = 'year',
  Month = 'month',
}

export enum IncomeType {
  Net = 'net',
  Gross = 'gross',
}

export enum LocationType {
  MetropolitanFrance = 'france',
  World = 'abroad',
}

export enum MaturityType {
  UnderStudy = 'underStudy',
  SearchStarted = 'searchStarted',
  OfferMade = 'offerMade',
  OfferAccepted = 'offerAccepted',
  SaleAgreementSigned = 'saleAgreementSigned',
}

export enum OccupationContractType {
  LongTerm = 'longTerm',
  ShortTerm = 'shortTerm',
}

export enum OccupationOthersStatus {
  SelfEmployed = 'selfEmployed',
  Retired = 'retired',
  BusinessOwner = 'businessOwner',
  Lawyer = 'lawyer',
  Liberal = 'liberal',
  Jobless = 'jobless',
  Trader = 'trader',
  Farmer = 'farmer',
  ParentalLeave = 'parentalLeave',
  Craftsman = 'craftsman',
  Student = 'student',
  SalariedDirector = 'salariedDirector',
  Interim = 'interim',
  CasualShowBusinessWorker = 'casualShowBusinessWorker',
  PortageSalarial = 'portageSalarial',
  Jobseeker = 'jobseeker',
}

export enum OccupationPublicStatus {
  Tenure = 'tenure',
  Contractor = 'contractor',
  Intern = 'intern',
}

export enum OccupationType {
  Private = 'private',
  Public = 'public',
  Others = 'others',
  Medical = 'medical',
}

export enum OccupationPrivateStatus {
  Executive = 'executive',
  NonExecutive = 'nonExecutive',
}

export enum OccupationPrivateTrial {
  NonTrial = 'nonTrial',
  Trial = 'trial',
}

export enum ProjectType {
  Old = 'old',
  New = 'new',
  VEFA = 'vefa',
  Construction = 'construction',
  LandConstruction = 'landConstruction',
}

export enum RevenueType {
  Rent = 'rent',
  ChildSupport = 'childSupport',
  ProfessionalBenefits = 'professionalBenefits',
  FamilyAllowances = 'familyAllowances',
  ExpatriationBonus = 'expatriationBonus',
}

export enum SituationType {
  Tenant = 'tenant',
  Owner = 'owner',
  Sheltered = 'sheltered',
  StaffHoused = 'staffHoused',
}

export enum Structure {
  Solo = 'solo',
  Duo = 'duo',
  SCI = 'sci',
}

export enum SciType {
  IS = 'is',
  IR = 'ir',
}

export enum SciAssociation {
  Simple = 'simple',
  Complex = 'complex',
}

export enum Usage {
  Primary = 'primary',
  Secondary = 'secondary',
  Rental = 'rental',
  Pro = 'pro',
  Mixed = 'mixed',
}

export enum SavingsOwner {
  Both = 'both',
  Mortgagor = 'mortgagor',
  Comortgagor = 'comortgagor',
}

export enum Months {
  January = '0',
  February = '1',
  March = '2',
  April = '3',
  May = '4',
  June = '5',
  July = '6',
  August = '7',
  September = '8',
  October = '9',
  November = '10',
  December = '11',
}

export enum FrequencyPropertySearch {
  RealTime = 'real_time',
  Daily = 'daily',
  Weekly = 'weekly',
  Never = 'never',
}

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export enum PropertySearchRooms {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
}

export enum InsuranceType {
  BankGroupInsurance = 'bankGroupInsurance',
  DelegatedInsurance = 'delegatedInsurance',
}
