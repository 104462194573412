import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const DialogConainer = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${g(24)};
  }
`

export const ButtonInline = styled.button`
  color: inherit;
  cursor: pointer;
  font: inherit;
  text-decoration: underline;
`

export const Legal = styled.div`
  line-height: 1;
  margin-top: ${g(1)};
`

export const SuccessParagraph = styled.div`
  margin: ${g(2)} 0;
`
export const Paragraph = styled.p`
  margin-bottom: ${g(3)};

  &:last-child {
    margin-bottom: 0;
  }
`
export const Cancel = styled.span`
  color: ${({ theme }) => theme.legacy.colors.accent1.default};
  cursor: pointer;
  text-decoration: underline;
`
