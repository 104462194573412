import SectionSearch from '@pretto/bricks/app/pre-lead/components/SectionSearch'
import SectionSearchEmpty from '@pretto/bricks/app/pre-lead/components/SectionSearch/Empty'
import { detectMobileOS } from '@pretto/bricks/core/utility/detectMobileOS'

import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import { reactivateAlert } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'

type Origin = 'card_button' | 'setup_button'
type Platform = 'app_store' | 'google_play_store'
type Status = 'alert' | 'no_alert'

interface Localisation {
  city: string
  zipcode: string
}

export interface OptionsEdit {
  origin: Origin
  status: Status
}

export interface OptionsStore {
  platform: Platform
  status: Status
}

interface PropertySearchCardProps {
  onTrackEdit?: (options: OptionsEdit) => void
  onTrackStore?: (options: OptionsStore) => void
}

export const PropertySearchCard: React.FC<PropertySearchCardProps> = ({ onTrackEdit, onTrackStore }) => {
  const { data } = usePropertySearch()

  const status = !data ? 'no_alert' : 'alert'

  const handleEdit = (origin: Origin) => {
    onTrackEdit?.({ origin, status })
  }

  const handleStore = (platform: Platform) => {
    onTrackStore?.({ platform, status })
  }

  const handleReactivate = async () => {
    await reactivateAlert(data.id)
  }

  if (!data) {
    return (
      <SectionSearchEmpty
        mobileOs={detectMobileOS()}
        onEdit={handleEdit}
        onStore={handleStore}
        href="/property-search/alerts"
      />
    )
  }

  const { activity_status, area_max, area_min, localisations, price_max, price_min, property_condition, property_kind, rooms } = data

  const props = {
    href: '/property-search/alerts',
    localisations: localisations.map(({ city, zipcode }: Localisation) => `${city} (${zipcode})`),
    mobileOs: detectMobileOS(),
    onEdit: handleEdit,
    onStore: handleStore,
    onReactivate: handleReactivate,
    deactivated: activity_status !== 'ACTIVE',
    priceMax: price_max,
    priceMin: price_min,
    propertyCondition: property_condition,
    propertyKind: property_kind,
    rooms,
    surfaceMax: area_max,
    surfaceMin: area_min,
  }

  return <SectionSearch {...props} />
}
