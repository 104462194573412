import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import { SavingsOwner } from '@pretto/app/src/Sentences/v2/types/enums'
import { Owner, SavingKind } from '@pretto/app/src/types/gateway/enums'
import type { Profile, Project, Saving } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

const mapOwner = (owner: SavingsOwner | undefined | null): Owner => {
  switch (owner) {
    case SavingsOwner.Comortgagor:
      return Owner.Comortgagor
    case SavingsOwner.Mortgagor:
      return Owner.Mortgagor
    case SavingsOwner.Both:
    default:
      return Owner.Both
  }
}

export const mapSavings: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const profile: Profile = updatedPayload.profile ?? {}
  profile.savings = []
  const amount: number | undefined =
    !isNil(context.savings) || !isNil(context.contribution)
      ? (context.savings ?? 0) + (context.contribution ?? 0)
      : undefined

  const saving: Saving = {
    amount,
    kind: SavingKind.CurrentAccount,
    owner: mapOwner(context.savingsOwner),
  }
  if (context.savingsId) saving.id = context.savingsId
  profile.savings.push(saving)

  return {
    context,
    payload: updatedPayload,
  }
}
