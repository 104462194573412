import { g } from '@pretto/bricks/components/layout'
import { css } from 'styled-components'

const WEIGHT = {
  book: 400,
  medium: 500,
  bold: 700,
  black: 900,
}

export const BaseTypoStyles = ({ isRN = false }) => css`
  ${({ $typo }) => {
    switch ($typo) {
      case 'button18':
      case 'button':
        return css`
          font-size: ${g(2, 2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.bold};
          `};
        `
      case 'link12':
      case 'link':
        return css`
          font-size: ${g(1.5)};
          line-height: ${g(2)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.medium};
            letter-spacing: 0.05em;
          `};
          text-transform: uppercase;
        `
      case 'inputBook16':
      case 'input':
        return css`
          font-size: ${g(2)};
          line-height: ${g(4)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.book};
          `};
        `
      case 'heading64':
      case 'heading1':
        return css`
          font-size: ${g(8)};
          line-height: ${g(9)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
          `};
        `
      case 'heading48':
      case 'heading2':
        return css`
          font-size: ${g(6)};
          line-height: ${g(7)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
          `};
        `
      case 'heading32':
      case 'heading3':
        return css`
          font-size: ${g(4)};
          line-height: ${g(5)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
            letter-spacing: -0.025em;
          `};
        `
      case 'heading16':
      case 'heading4':
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
            letter-spacing: -0.025em;
          `};
        `
      case 'headline16':
      case 'headline':
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          text-transform: uppercase;
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.medium};
            letter-spacing: 0.05em;
          `};
        `
      case 'numbers32':
        return css`
          font-size: ${g(4)};
          line-height: ${g(5)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
          `};
        `
      case 'textBig40':
      case 'textBig':
        return css`
          font-size: ${g(5)};
          line-height: ${g(7)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.medium};
          `};
        `
      case 'textLarge24':
      case 'textLarge':
        return css`
          font-size: ${g(3)};
          line-height: ${g(4)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.medium};
          `};
        `
      case 'bodyBlack':
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.black};
          `};
        `
      case 'bodyBold16':
      case 'bodyBold':
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.bold};
          `};
        `
      case 'bodyMedium16':
      case 'bodyMedium':
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.medium};
            b,
            strong {
              font-weight: ${WEIGHT.bold};
            }
          `};
        `
      case 'bodyBook16':
      case 'bodyBook':
      default:
        return css`
          font-size: ${g(2)};
          line-height: ${g(3)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.book};
            b,
            strong {
              font-weight: ${WEIGHT.medium};
            }
          `};
        `
      case 'caption12':
      case 'caption':
        return css`
          font-size: ${g(1.5)};
          line-height: ${g(2)};
          ${!isRN &&
          css`
            font-weight: ${WEIGHT.book};
            b,
            strong {
              font-weight: ${WEIGHT.medium};
            }
          `};
        `
    }
  }}
`

export const BaseTypoFamily = css`
  ${({ $typo }) => {
    switch ($typo) {
      case 'button18':
      case 'button':
        return css`
          font-family: 'MaisonNeue-Bold';
        `
      case 'link12':
      case 'link':
        return css`
          font-family: 'MaisonNeue-Medium';
        `
      case 'inputBook16':
      case 'input':
        return css`
          font-family: 'MaisonNeue-Book';
        `
      case 'heading64':
      case 'heading1':
        return css`
          font-family: 'MaisonNeue-Black';
        `
      case 'heading48':
      case 'heading2':
        return css`
          font-family: 'MaisonNeue-Black';
        `
      case 'heading32':
      case 'heading3':
        return css`
          font-family: 'MaisonNeue-Black';
        `
      case 'heading16':
      case 'heading4':
        return css`
          font-family: 'MaisonNeue-Black';
        `
      case 'headline16':
      case 'headline':
        return css`
          font-family: 'MaisonNeue-Medium';
        `
      case 'textBig40':
      case 'textBig':
        return css`
          font-family: 'MaisonNeue-Medium';
        `
      case 'textLarge24':
      case 'textLarge':
        return css`
          font-family: 'MaisonNeue-Medium';
        `
      case 'bodyBlack':
        return css`
          font-family: 'MaisonNeue-Black';
        `
      case 'bodyBold16':
      case 'bodyBold':
        return css`
          font-family: 'MaisonNeue-Bold';
        `
      case 'bodyMedium16':
      case 'bodyMedium':
        return css`
          font-family: 'MaisonNeue-Medium';
        `
      case 'bodyBook16':
      case 'bodyBook':
      default:
        return css`
          font-family: 'MaisonNeue-Book';
        `
      case 'caption12':
      case 'caption':
        return css`
          font-family: 'MaisonNeue-Book';
        `
    }
  }}
`
