import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import styled from 'styled-components'

export const GuideCard = ({ onGuideClick }) => (
  <Card>
    <Content>
      <CardContent>
        <CardTtitle>
          <SubHeading>Le Guide de l’acheteur</SubHeading>
        </CardTtitle>

        <CardText>
          <Text size="small">
            Soyez prêt pour votre premier achat immobilier. Tous nos conseils sont réunis dans un ebook gratuit !
          </Text>
        </CardText>

        <CardButton>
          <ButtonSquared
            href="https://www.pretto.fr/guide/"
            onClick={onGuideClick}
            textVariant="accent-1"
            variant="accent-1-l"
          >
            Consulter
          </ButtonSquared>
        </CardButton>
      </CardContent>
    </Content>
  </Card>
)

GuideCard.propTypes = {
  onGuideClick: PropTypes.func,
}

const Card = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1569916155/bricks/static-assets/book_cover-thumbnail.png'),
    linear-gradient(
      to bottom,
      ${({ theme }) => theme.legacy.colors.primary2.light} 0%,
      ${({ theme }) => theme.legacy.colors.primary2.light} 100%
    );
  background-position:
    center ${g(2)},
    left top;
  background-repeat: no-repeat;
  background-size:
    ${g(14)},
    100% ${g(10)};
  border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
  border-left-width: 0;
  border-right-width: 0;
  padding-top: ${g(17)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1569916155/bricks/static-assets/book_cover-thumbnail.png');
    background-position: ${g(3)} ${g(3)};
    background-size: ${g(14)} auto;
    border-left-width: 1px;
    border-radius: ${g(1)};
    border-right-width: 1px;
    display: grid;
    min-height: ${g(28)};
    padding-left: ${g(17)};
    padding-top: 0;
  }
`

const CardButton = styled.div`
  margin-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-end;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const CardText = styled.div`
  flex-grow: 1;
`

const CardTtitle = styled.div`
  margin-bottom: ${g(1)};
`
