import { g } from '@pretto/bricks/components/layout'

import { ResizableField as ResizableFieldComponent } from '@pretto/zen/reveal/atoms/textfields/ResizableField/ResizableField'

import styled from 'styled-components'

// TODO: when focus height 48px, when blur height 40px
export const ResizableField = styled(ResizableFieldComponent)`
  margin: ${g(0.5)};
`
