import PreLeadCardRows from '@pretto/bricks/app/pre-lead/components/PreLeadCardRows'
import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'

import * as S from './styles'

const SectionBudget = ({ contribution, duration, href, onEdit, payment, price, range }) => {
  const items = [
    { label: 'Prix du bien', value: price },
    { label: 'Durée du prêt', value: formatNumber(duration, { suffix: 'ans' }) },
    { label: 'Mensualités', value: formatNumber(payment, { suffix: '€ / mois' }) },
    { label: 'Apport', value: formatNumber(contribution, { suffix: '€' }) },
  ]

  return (
    <PreLeadProjectCardTemplate onEdit={onEdit} type="capacity" href={href}>
      <S.Infos>
        <S.BudgetCard>
          <S.Header>
            <S.Coins />
            Votre budget maximum se situe entre :
          </S.Header>
          <S.MinMax>
            {range[0]} et {range[1]}
          </S.MinMax>
          <S.Description>
            Il s’agit d’une <strong>estimation du prix du bien que vous pouvez acheter,</strong> frais inclus (travaux,
            frais de notaire, frais de garantie etc.)
          </S.Description>
        </S.BudgetCard>
        <Responsive min="mobileL">
          <S.Details>
            <S.Header>
              <S.Icon />
              Exemple
            </S.Header>
            <PreLeadCardRows items={items} />
            <S.SeeMore isSecondary href="/capacity">
              Lire plus
            </S.SeeMore>
          </S.Details>
        </Responsive>
      </S.Infos>
      <S.BlockInfoCertificate
        status="certificate"
        href="/simulation/subscribe?kind=dashboard_certificate&origin=dashboard"
      />
    </PreLeadProjectCardTemplate>
  )
}

SectionBudget.propTypes = {
  /** Contribution of project. */
  contribution: PropTypes.number.isRequired,
  /** Duration of loan in years. */
  duration: PropTypes.number.isRequired,
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  /** Function triggered when edit icon is clicked. */
  onEdit: PropTypes.func.isRequired,
  /** Payment of capacity. */
  payment: PropTypes.number.isRequired,
  /** price used as an example */
  price: PropTypes.number.isRequired,
  /** min and max of capacity. */
  range: PropTypes.array.isRequired,
}

export default SectionBudget
