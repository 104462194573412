import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'

import { t } from '@pretto/app/src/lib/i18n'
import { Pen } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './Sidebar.styles'

export const Sidebar = ({ onModify, infos }) => {
  return (
    <S.Container>
      <S.InformationBloc>
        <S.Content>
          <S.Title>{t('simulation.editor.title')}</S.Title>
          <S.Infos>
            {infos.map(({ label, value }, index) => (
              <S.Info key={`${label}-${index}`}>
                <S.Label>{label}</S.Label>
                <S.Value>{value}</S.Value>
              </S.Info>
            ))}
          </S.Infos>
          <ButtonFeature onClick={onModify} picto={Pen} label={t('simulation.editor.edit')} />
        </S.Content>
        <S.ContainerIllustration>
          <S.Illustration />
        </S.ContainerIllustration>
      </S.InformationBloc>
    </S.Container>
  )
}

Sidebar.propTypes = {
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onModify: PropTypes.func.isRequired,
}
