import Icon from '@pretto/bricks/components/iconography/Icon'
import Avatar from '@pretto/bricks/components/images/Avatar'
import Tag from '@pretto/bricks/components/information/Tag'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles.js'

const TAG = {
  joining: {
    content: 'Non inscrit',
    variant: 'neutral-1-l',
  },
  lost: {
    content: 'Dossier non finalisé',
    variant: 'neutral-1-l',
  },
  ongoing: {
    content: 'Dossier en cours',
    textVariant: 'primary-1',
    variant: 'primary-1-l',
  },
  toContact: {
    content: 'Non inscrit',
    variant: 'neutral-1-l',
  },
  won: {
    content: 'Validé',
    icon: 'star',
    textVariant: 'white',
    variant: 'accent-3',
  },
}

const SponsorshipSponsorCard = ({ name, addTime, status }) => {
  const { content, icon, textVariant, variant } = TAG[status]

  const initial = name.slice(0, 1).toLowerCase()
  const avatar = initial.match(/[a-z]/i) ? initial : 'z'

  const src = `bricks/avatars/avatar-${avatar}.jpg`

  return (
    <div>
      <S.Content>
        <S.Avatar>
          <Avatar size="x-small" src={src} />
        </S.Avatar>

        <div>
          <SubHeading>{name}</SubHeading>
          <Text size="x-small" variant="neutral-1-40">
            {addTime || '-'}
          </Text>
        </div>
      </S.Content>

      <S.Status>
        {icon && (
          <S.Icon>
            <Icon name={icon} variant={variant} />
          </S.Icon>
        )}

        <Tag variant={variant}>
          <Text size="small" variant={textVariant}>
            {content}
          </Text>
        </Tag>
      </S.Status>
    </div>
  )
}

SponsorshipSponsorCard.propTypes = {
  addTime: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.keys(TAG)).isRequired,
}

export default memo(SponsorshipSponsorCard)
