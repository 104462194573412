import { funcToItem } from '@pretto/bricks/core/utility/formatters'

export const getSectionWithFieldName = (name, sections, values) =>
  sections.reduce((previous, currentSection) => {
    const section = funcToItem(currentSection, values)
    const hasField = section.fields.some(field => field.name === name)

    if (!hasField) {
      return previous
    }

    return section
  }, null)
