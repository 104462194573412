import { AgendaAdvisor } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import {
  Calendar as CalendarComponent,
  Slot,
} from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Calendar/Calendar'
import { orderSlotsByDate } from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/orderSlotsByDate'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

interface rawSlot {
  start: string
  advisor: AgendaAdvisor
  kind: string
}

interface CalendarProps {
  slots: rawSlot[]
  onBooked: (slot?: Slot) => void
}

export const Calendar: React.FC<CalendarProps> = ({ slots, onBooked }) => {
  const days = orderSlotsByDate(slots)
  const trackAction = useTracking()
  const { advisor } = useUser()

  const handleTrackNext = () => {
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_NEXT_DAYS_CLICKED')
  }

  return (
    <CalendarComponent advisorLabel={advisor?.label} days={days} onBooked={onBooked} onTrackNext={handleTrackNext} />
  )
}
