import RegroupCredits from '@pretto/bricks/app/simulator/components/RegroupCredits'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import { Desktop } from '@pretto/bricks/components/utility/Responsive'
import CardList from '@pretto/bricks/shared/components/CardList'
import FlagRow from '@pretto/bricks/shared/components/FlagRow'

import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import * as S from './styles'

const NoFinancingPage = ({
  footerComponents,
  isBlocked,
  malus,
  onRegroupCreditsClick,
  onRestore,
  projectId,
  regroupCredits,
}) => {
  const { t } = useTranslation()

  const introductionContent = regroupCredits
    ? t('noFinancingPage.noRealEstateOffer')
    : t('noFinancingPage.noFinancingOffer')

  const malusContent = <Trans i18nKey="noFinancingPage.reasonsMessageShort" count={malus.length} />

  return (
    <S.Page>
      <Wrapper>
        <Desktop>
          <S.Title>
            <Heading size="large">{t('noFinancingPage.sorry')}</Heading>
          </S.Title>
        </Desktop>

        <S.Introduction>
          {introductionContent} {!regroupCredits && malusContent}
        </S.Introduction>
      </Wrapper>

      <Wrapper full>
        {isBlocked && (
          <S.Restore>
            <Card variant="accent-1-l">
              <Content>
                <S.RestoreDescription>{t('noFinancingPage.restoreMessage')}</S.RestoreDescription>

                <ButtonLegacy onClick={onRestore} variant="accent-1">
                  {t('noFinancingPage.restoreCta')}
                </ButtonLegacy>
              </Content>
            </Card>
          </S.Restore>
        )}

        {regroupCredits && (
          <S.RegroupCredits>
            <RegroupCredits onClick={onRegroupCreditsClick} projectId={projectId} />
          </S.RegroupCredits>
        )}

        {malus.length > 0 && (
          <>
            {regroupCredits && (
              <S.MalusIntroduction>
                <Trans i18nKey="noFinancingPage.reasonsMessageLong" count={malus.length} />
              </S.MalusIntroduction>
            )}

            <S.Malus>
              <CardList variant="neutral-1-20">
                {malus.map((flagProps, index) => (
                  <Content key={index}>
                    <FlagRow {...flagProps} />
                  </Content>
                ))}
              </CardList>
            </S.Malus>
          </>
        )}

        {!isBlocked && footerComponents.length > 0 && <S.More>{footerComponents}</S.More>}
      </Wrapper>
    </S.Page>
  )
}

NoFinancingPage.defaultProps = {
  footerComponents: [],
  isBlocked: false,
  malus: [],
}

NoFinancingPage.propTypes = {
  /** Components to be displayed at the page bottom. */
  footerComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  /** Whether or not the simulation can't be saved but may be restored to its previous state. */
  isBlocked: PropTypes.bool,
  /** List of malus with <code>[FlagRow](/#!/FlagRow)</code> props. */
  malus: PropTypes.array,
  /** Triggered when FAQ button is pressed. */
  /** Triggered when regroup credits button is pressed. */
  onRegroupCreditsClick: PropTypes.func,
  /** Triggered when restore button is pressed. */
  onRestore: PropTypes.func,
  /** ID of current project to identify the regroup credits request. */
  projectId: PropTypes.string.isRequired,
  /** Whether the user can regroup his credits or not. */
  regroupCredits: PropTypes.bool,
}

export default NoFinancingPage
