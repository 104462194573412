import SnackBar from '@pretto/bricks/components/bars/SnackBar'
import Button from '@pretto/bricks/components/buttons/Button'
import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import StoreButton from '@pretto/bricks/components/buttons/StoreButton'
import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Avatar from '@pretto/bricks/components/images/Avatar'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { prettoSearch } from '@pretto/bricks/core/config'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'
import Link from '@pretto/bricks/website/utility/Link'

import { Message, States } from '@pretto/zen/reveal/atoms/message/Message'

import { ExclamationMarkTriangle, InfoCircle, TimesBold, Trash } from '@pretto/picto'

import PropTypes from 'prop-types'
import { Fragment, useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const objColors = {
  error: 'error2',
  warning: 'warning2',
}

const UnsubscribeBlock = ({ onUnsubscribe }) => (
  <Card format="line" variant="neutral-1-20">
    <Content>
      <S.UnsubscribeContent>
        <S.UnsubscribeTitle>
          <SubHeading>Vous ne souhaitez plus recevoir d’annonces ?</SubHeading>
        </S.UnsubscribeTitle>

        <Responsive max="tablet">
          <ButtonSquared onClick={onUnsubscribe} variant="error-l" textVariant="error">
            Me désabonner
          </ButtonSquared>
        </Responsive>

        <Responsive min="tablet">
          <ButtonSquared onClick={onUnsubscribe} variant="white" textVariant="error">
            Me désabonner
          </ButtonSquared>
        </Responsive>
      </S.UnsubscribeContent>
    </Content>
  </Card>
)

UnsubscribeBlock.propTypes = {
  onUnsubscribe: PropTypes.func.isRequired,
}

export const DashboardPage = ({
  formSections,
  isDialogOpen,
  isEditable,
  isLoading,
  isLoggedIn,
  isSubmitDisabled,
  isUnsubscribeDisabled,
  isUnsubscribeLoading,
  notifications,
  onDialogClose,
  onSubmit,
  onUnsubscribe,
  onUnsubscribeSubmit,
  os,
  submitLabel,
  submitLabelCondensed,
  unsubscribeSections,
  alertTitles = [],
}) => {
  const [editorHeaderText, setEditorHeaderText] = useState('')

  useEffect(() => {
    if (isEditable && alertTitles.length > 1) {
      setEditorHeaderText(`Paramétrez votre alerte "${alertTitles[0]}"`)
    } else if (isEditable) {
      setEditorHeaderText('Paramétrez votre alerte')
    } else {
      setEditorHeaderText('Créer votre alerte')
    }
  }, [])

  return (
    <>
      {notifications.map(({ id, ...props }) => (
        <SnackBar key={id} {...props} />
      ))}

      {os && (
        <Link href={os === 'iOS' ? prettoSearch.appStore.url : prettoSearch.googlePlayStore.url}>
          <Wrapper>
            <S.DownloadBannerContent>
              <S.AppIcon>
                <img
                  alt=""
                  src="https://res.cloudinary.com/pretto-fr/image/upload/v1587656791/bricks/static-assets/logo_full-shite.svg"
                />
              </S.AppIcon>

              <div>
                <Text size="small">Téléchargez l'application !</Text>
              </div>

              <S.DownloadBannerIcon>
                <Icon name="arrow-right" />
              </S.DownloadBannerIcon>
            </S.DownloadBannerContent>
          </Wrapper>
        </Link>
      )}

      <S.Page>
        <S.Header>
          <Wrapper>
            <S.Row>
              <S.Column>
                <Responsive min="tablet">
                  <S.Title>
                    <BaseTypo typo={'heading48'}>Votre recherche de bien</BaseTypo>
                  </S.Title>
                </Responsive>

                <BaseTypo typo={'bodyBook16'}>
                  Définissez vos critères de recherche pour recevoir des annonces qui correspondent au bien de vos
                  rêves. Vous pouvez modifier vos critères à tout moment.
                </BaseTypo>
              </S.Column>
            </S.Row>
          </Wrapper>
        </S.Header>

        <Wrapper>
          <S.Row>
            <S.Column>
              {alertTitles.length > 1 && (
                <S.Block>
                  <Message state={States.Info}>
                    Rendez-vous sur l'application mobile Pretto Search pour gérer vos autres alertes
                    <ul>
                      {alertTitles.slice(1).map((title, index) => (
                        <li key={`${index}${title}`}>{`- ${title}`}</li>
                      ))}
                    </ul>
                  </Message>
                </S.Block>
              )}
              <S.Block>
                <Card format="line" variant="neutral-1-20">
                  <Content>
                    <S.SubTitle>
                      <S.Heading size="small">{editorHeaderText}</S.Heading>
                    </S.SubTitle>

                    {formSections.map(({ fields, flags = [], footer, title, picto }, index) => (
                      <S.SubBlock key={index}>
                        <Card format="line" overflowVisible variant="neutral-1-20">
                          <Content>
                            <div id={`section-${index + 1}`}>
                              {title && (
                                <S.SectionHeader>
                                  {picto && <S.Icon $picto={picto} />}
                                  <BaseTypo typo={'textLarge24'}>{title}</BaseTypo>
                                </S.SectionHeader>
                              )}

                              <TransitionGroup>
                                {fields.map(({ component, key }) => (
                                  <CollapsibleRow key={key} parentComponent={S.Field}>
                                    <S.FieldContent>{component}</S.FieldContent>
                                  </CollapsibleRow>
                                ))}

                                {flags.map(({ children, key, type }) => {
                                  if (!children) return null

                                  return (
                                    <CollapsibleRow key={key} parentComponent={S.Field}>
                                      <S.FieldContent>
                                        <Card variant={objColors[type]}>
                                          <Content size="small">
                                            <S.Flag $type={type}>
                                              <S.FlagBadge>
                                                {type === 'warning' && <InfoCircle />}
                                                {type !== 'warning' && <ExclamationMarkTriangle />}
                                              </S.FlagBadge>

                                              <BaseTypo typo={'bodyMedium16'}>{children}</BaseTypo>
                                            </S.Flag>
                                          </Content>
                                        </Card>
                                      </S.FieldContent>
                                    </CollapsibleRow>
                                  )
                                })}

                                {footer && <S.SectionFooter>{footer}</S.SectionFooter>}
                              </TransitionGroup>
                            </div>
                          </Content>
                        </Card>
                      </S.SubBlock>
                    ))}

                    <Responsive min="tablet">
                      <S.Action>
                        <Button disabled={isSubmitDisabled} isLoading={isLoading} onClick={onSubmit}>
                          {submitLabel}
                        </Button>
                      </S.Action>
                    </Responsive>

                    {isEditable && (
                      <S.DeleteAlertContainer>
                        <S.ButtonFeature picto={Trash} onClick={onUnsubscribe} label={'Supprimer mon alerte'} />
                      </S.DeleteAlertContainer>
                    )}
                  </Content>
                </Card>
              </S.Block>
            </S.Column>

            <S.ColumnSmall>
              <S.Block>
                <Card format="line" variant="neutral-1-20">
                  <S.StoreBackground>
                    <Content>
                      <S.MoreTitle>
                        <SubHeading>Téléchargez l’application !</SubHeading>
                      </S.MoreTitle>

                      <S.MoreDescription>
                        <Text size="small" variant="neutral-1-60">
                          Toutes les annonces immobilières dans une seule et même appli !
                        </Text>
                      </S.MoreDescription>

                      <S.StoreBadges>
                        {Object.values(prettoSearch).map(({ slug }) => (
                          <S.StoreBadge key={slug}>
                            <StoreButton slug={slug} />
                          </S.StoreBadge>
                        ))}
                      </S.StoreBadges>
                    </Content>
                  </S.StoreBackground>
                </Card>
              </S.Block>

              {!isLoggedIn && (
                <S.Block>
                  <Card variant="accent-1-l">
                    <Content>
                      <S.MoreTitle>
                        <strong>Vous avez trouvé votre bien ?</strong>
                      </S.MoreTitle>

                      <S.MoreDescription>
                        <Text size="small">
                          Nos experts vous conseillent.
                          <br />
                          Contactez-nous et obtenez <strong>gratuitement</strong> votre attestation de financement !
                        </Text>
                      </S.MoreDescription>

                      <S.MoreFooter>
                        <S.InlineAvatar>
                          <Avatar isBackgroundTransparent size="x-small" src="v1568277413/team/Valentin.jpg" />
                        </S.InlineAvatar>

                        <S.InlineAvatar>
                          <Avatar isBackgroundTransparent size="x-small" src="v1554821406/team/henri.jpg" />
                        </S.InlineAvatar>

                        <S.InlineAvatar>
                          <Avatar isBackgroundTransparent size="x-small" src="v1573665459/team/Helene.jpg" />
                        </S.InlineAvatar>
                      </S.MoreFooter>

                      <S.MoreAction>
                        <ButtonSquared
                          href="https://app.pretto.fr/project/purchase/introduction"
                          target="_blank"
                          variant="accent-1"
                        >
                          Prendre rdv avec un expert
                        </ButtonSquared>
                      </S.MoreAction>
                    </Content>
                  </Card>
                </S.Block>
              )}
            </S.ColumnSmall>
          </S.Row>
        </Wrapper>

        <Responsive max="tablet">
          <S.Footer>
            <S.Button disabled={isSubmitDisabled} isLoading={isLoading} onClick={onSubmit}>
              {submitLabelCondensed}
            </S.Button>
          </S.Footer>
        </Responsive>
      </S.Page>

      <Dialog isOpen={isDialogOpen} onRequestClose={onDialogClose}>
        <DialogTemplate
          navbarProps={{
            iconLeft: TimesBold,
            iconLeftAction: onDialogClose,
            title: 'Vous nous quittez déjà ?',
          }}
        >
          <Content>
            <S.UnsubscribeDialogDescription>
              Pourquoi souhaitez-vous supprimer votre alerte ?
            </S.UnsubscribeDialogDescription>

            <S.UnsubscribeDialogForm>
              {unsubscribeSections.map(({ fields }, index) => (
                <Fragment key={index}>
                  {fields.map(({ component, key }) => (
                    <S.Field key={key}>
                      <S.FieldContent>{component}</S.FieldContent>
                    </S.Field>
                  ))}
                </Fragment>
              ))}
            </S.UnsubscribeDialogForm>

            <S.UnsubscribeDialogActions>
              <S.UnsubscribeDialogAction>
                <ButtonSquared
                  borderVariant="neutral-1-20"
                  disabled={isUnsubscribeDisabled}
                  full
                  isLoading={isUnsubscribeLoading}
                  onClick={onUnsubscribeSubmit}
                  textVariant="error"
                  variant="white"
                >
                  Supprimer l'alerte
                </ButtonSquared>
              </S.UnsubscribeDialogAction>

              <S.UnsubscribeDialogAction>
                <ButtonSquared full onClick={onDialogClose}>
                  Annuler
                </ButtonSquared>
              </S.UnsubscribeDialogAction>
            </S.UnsubscribeDialogActions>
          </Content>
        </DialogTemplate>
      </Dialog>
    </>
  )
}

DashboardPage.defaultProps = {
  isLoggedIn: false,
  notifications: [],
  unsubscribeSections: [],
}

DashboardPage.propTypes = {
  /** Alert type to be displayed. */
  alertType: PropTypes.oneOf(['created', 'error', 'modified']),
  /** List of form sections. */
  formSections: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.array,
      flags: PropTypes.arrayOf(
        PropTypes.shape({
          children: PropTypes.node,
          type: PropTypes.oneOf(['warning', 'error']),
        })
      ),
      footer: PropTypes.node,
      title: PropTypes.string,
    })
  ),
  /** Whether or not the dialog should ne opened. */
  isDialogOpen: PropTypes.bool,
  /** Whether or not the user is editing existing content. */
  isEditable: PropTypes.bool,
  /** Whether or not the page is currently being saved. */
  isLoading: PropTypes.bool.isRequired,
  /** Whether or not the user is being logged in. */
  isLoggedIn: PropTypes.bool,
  /** Whether or not it should be possible to submit the form. */
  isSubmitDisabled: PropTypes.bool.isRequired,
  /** Whether or not it should be possible to submit the unsubscribe form. */
  isUnsubscribeDisabled: PropTypes.bool,
  /** Whether or not it is unsubscribing. */
  isUnsubscribeLoading: PropTypes.bool,
  /** List of notifications. */
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  /** Event triggered whenever the dialog should close. */
  onDialogClose: PropTypes.func,
  /** Event triggered whenever the save button is being pressed. */
  onSubmit: PropTypes.func.isRequired,
  /** Event triggered whenever the unsubscribe button is being pressed. */
  onUnsubscribe: PropTypes.func,
  /** Event triggered whenever the unsubscribe button is being pressed. */
  onUnsubscribeSubmit: PropTypes.func,
  /** Mobile operating system type. */
  os: PropTypes.oneOf(['Android', 'iOS']),
  /** Label of the submit button on desktop layout. */
  submitLabel: PropTypes.string.isRequired,
  /** Label of the submit button on mobile layout. */
  submitLabelCondensed: PropTypes.string.isRequired,
  /** List of form sections to be used in the unsubscribe dialog. */
  unsubscribeSections: PropTypes.array,
}

export default DashboardPage
