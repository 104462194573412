import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Text from '@pretto/bricks/components/typography/Text'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const CTA = 'Poursuivre vers mon espace Pretto'

const SubscribeCompletedPage = ({ href }) => (
  <S.Page>
    <Wrapper>
      <S.Emoji>
        <Emoji size="display">:file_folder:</Emoji>
      </S.Emoji>
      <S.Title>
        <Heading>Merci !</Heading>
      </S.Title>
      <S.Paragraph>
        <Text>
          C'est un pas de géant pour votre projet immobilier <Emoji verticalAlign="sub">:)</Emoji> Discutez avec un
          expert crédit sans engagement en prenant rendez-vous dès maintenant.
        </Text>
      </S.Paragraph>
      <Responsive max="tablet">
        <S.Cta href={href} target="blank">
          {CTA}
        </S.Cta>
      </Responsive>
      <Responsive min="tablet">
        <ButtonLegacy format="line" variant="accent-1" href={href} target="blank">
          {CTA}
        </ButtonLegacy>
      </Responsive>
    </Wrapper>
  </S.Page>
)

SubscribeCompletedPage.propTypes = {
  /** Destination for the CTA click. */
  href: PropTypes.string.isRequired,
}

export default SubscribeCompletedPage
