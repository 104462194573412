import type { Account, Signature } from '@pretto/app/src/dashboard/types/signature'

import findIndex from 'lodash/findIndex'

export const getSignDate = (accounts: Account[], signatures: Signature[], mine = true): string | null => {
  const index = findIndex(accounts, ({ isMine }) => isMine)
  const mortgagorIndex = mine ? index : 1 - index
  const signature = signatures[mortgagorIndex]

  if (!signature) {
    return null
  }

  return signature.signedAt ?? null
}
