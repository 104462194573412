import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { Project } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'
import pipe from 'ramda/src/pipe'

import { mapCredit } from './credits'
import { mapMortgagors } from './mortgagors'
import { mapSavings } from './savings'

export const createProfile: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  updatedPayload.profile = {
    children: context.children,
  }
  if (!isNil(context.liveTogether)) updatedPayload.profile.live_together = context.liveTogether
  if (context.credits && context.credits.length) updatedPayload.profile.credits = context.credits.map(mapCredit)
  return {
    context,
    payload: updatedPayload,
  }
}

export const mapProfile = pipe(createProfile, mapMortgagors, mapSavings)
