import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Container = styled.div`
  ${column(6)};
  margin-top: ${g(4)};
  text-align: center;
`

export const Image = styled(ImageComponent).attrs({
  options: {
    width: 320,
  },
  path: 'v1637684803/zen/static-assets/illustrations/660x660/envelopeAndLock.svg',
})`
  width: 192px;
  display: block;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 320px;
  }
`

export const Title = styled.h1`
  ${typo.heading32};
  margin-top: ${g(2)};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${typo.heading48};
  }
`

export const Paragraph = styled.p`
  & + & {
    margin-top: ${g(3)};
  }
`

export const Footer = styled.div`
  margin-top: ${g(4)};
  ${typo.bodyBook16};
`

export const SendAgain = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
