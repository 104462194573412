import ButtonCard from '@pretto/bricks/components/buttons/ButtonCard'
import Card from '@pretto/bricks/components/cards/Card'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardContainer = styled(Card).attrs({ format: 'line', variant: 'neutral3' })`
  padding: ${g(4)};
`

export const CTA = styled(ButtonCard)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const LeftChoices = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: 50%;
    max-width: ${g(58)};
  }
`

export const MobileImg = styled(IllustrationDeprecated)`
  margin-top: ${g(3)};
`

export const SubTitle = styled(BaseTypo).attrs({ typo: 'heading4' })`
  margin-bottom: ${g(3)};
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading3' })`
  margin-bottom: ${g(2)};
`
