import CardComponent from '@pretto/bricks/components/cards/Card'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const ResultCardDetails = styled.div``

export const Card = styled(CardComponent)`
  padding: ${g(2)};
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
`

export const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-wrap: initial;
  }
`

export const Fundability = styled(BaseTypo).attrs({ typo: 'bodyBold' })`
  color: ${({ $color, theme }) => theme.colors[$color]};
`
