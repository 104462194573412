import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronLeftBold } from '@pretto/picto'

import styled from 'styled-components'

import { ButtonBackProps } from './ButtonBack'

const TRANSITION_DELAY = 175
const TRANSITION_DURATION_IN = 200
const TRANSITION_DURATION_OUT = 150

interface ButtonAttrsProps {
  theme: { colors: IPalette }
  href?: ButtonBackProps['href']
}

export const Arrow = styled(ChevronLeftBold)`
  align-self: center;
  flex-shrink: 0;
  margin-right: ${g(2)};
  transform: translateX(0);
  transition: transform 0.5s ease;
`

export const Button = styled.button.attrs<ButtonAttrsProps>(({ href }) => ({
  forwardedAs: href ? Link : 'button',
}))<ButtonAttrsProps>`
  ${typo.button18}
  background-color: ${({ theme }) => theme.colors.neutral4};
  cursor: pointer;
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 ${g(3)};
  height: ${g(8)};
  transition:
    background-color ${TRANSITION_DURATION_OUT}ms ease-out,
    ${TRANSITION_DURATION_OUT}ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral4Hover};
  }

  &:hover ${Arrow} {
    transform: translateX(${g(-1, 2)});
  }
`

export const Label = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity ${TRANSITION_DURATION_IN}ms ease-out ${TRANSITION_DELAY}ms;
  white-space: nowrap;
`
