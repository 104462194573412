export const palette = {
  primary1: '#006855',
  primary1Disabled: '#006855',
  primary2: '#0C806B',
  primary3: '#A5F1A4',
  primary4: '#D2F8D1',
  accent1: '#FC5C63',
  accent1Hover: '#FC5057',
  accent1Disabled: '#FFACB3',
  accent2: '#FC847C',
  accent3: '#FFAA98',
  neutral1: '#050505',
  neutral1Hover: '#1F1F1F',
  neutral1Disabled: '#D3D3D3',
  neutral2: '#8C8C8C',
  neutral3: '#D3D3D3',
  neutral4: '#F5F5F5',
  neutral5: '#faf7f2',
  neutral4Hover: '#F0F0F0',
  overlay: 'rgba(5, 5, 5, 0.7)',
  white: '#FFFFFF',
  warning1: '#E3631B',
  warning2: '#FFE5B1',
  error1: '#C70808',
  error2: '#FFD0D0',
  facebook: '#4267B2',
  linkedin: '#3B5998',
  trustpilot: '#00B67A',
  twitter: '#1DA1F2',
  whatsapp: '#25D366',
}
