import NavBar from '@pretto/bricks/components/bars/TopNav'
import Divider from '@pretto/bricks/components/dividers/Divider'

import PropTypes from 'prop-types'

import * as S from './styles'

const DialogTemplate = ({
  buttonProps,
  children,
  hideDivider,
  isButtonFull,
  isButtonSticky,
  navbarProps,
  size,
  ...props
}) => (
  <S.Container $size={size} {...props}>
    {navbarProps && (
      <S.NavBarContainer>
        <NavBar {...navbarProps} isBorderRounded />
        {!hideDivider && <Divider />}
      </S.NavBarContainer>
    )}

    {children}

    {buttonProps && (
      <S.ButtonContainer $isSticky={isButtonSticky}>
        <S.Button $full={isButtonFull} {...buttonProps} />
      </S.ButtonContainer>
    )}
  </S.Container>
)

DialogTemplate.propTypes = {
  buttonProps: PropTypes.object,
  children: PropTypes.node,
  hideDivider: PropTypes.bool,
  isButtonFull: PropTypes.bool,
  isButtonSticky: PropTypes.bool,
  navbarProps: PropTypes.object,
  /** Should be use to force size of modal. */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

export default DialogTemplate
