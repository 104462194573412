export const getCenterWindowCoordinates = ({ height, width }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const clientHeight = document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height
  const clientWidth = document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width

  const innerHeight = window.innerHeight ? window.innerHeight : clientHeight
  const innerWidth = window.innerWidth ? window.innerWidth : clientWidth

  const systemZoom = innerWidth / window.screen.availWidth

  const left = (innerWidth - width) / 2 / systemZoom + dualScreenLeft
  const top = (innerHeight - height) / 2 / systemZoom + dualScreenTop

  return { left, top }
}
