import NavAccountComponent from '@pretto/bricks/components/bars/NavBar/components/NavAccount'
import ButtonBackInlineComponent from '@pretto/bricks/components/buttons/ButtonBackInline'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import Image from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import { LogoPretto as LogoPrettoComponent } from '@pretto/zen/images/LogoPretto/LogoPretto'
import { PrettoLogotype as PrettoLogotypeComponent } from '@pretto/zen/reveal/logos/PrettoLogotype/PrettoLogotype'

import { TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const MOBILE_NAVBAR_HEIGHT = g(9)
export const DESKTOP_NAVBAR_HEIGHT = g(11)

export const NavBar = styled.div`
  display: ${({ $isMobileHidden }) => ($isMobileHidden ? 'none' : 'flex')};
  align-items: center;
  height: ${MOBILE_NAVBAR_HEIGHT};

  ${({ $backgroundColor, theme }) =>
    $backgroundColor &&
    css`
      background-color: ${theme.colors[$backgroundColor]};
    `}

  ${({ $mode, theme }) =>
    $mode === 'default' &&
    css`
      border-bottom: solid 1px ${theme.colors.neutral3};
    `}

  ${({ $mode }) =>
    $mode === 'compact' &&
    css`
      float: right;
      margin-left: ${g(-9)};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${DESKTOP_NAVBAR_HEIGHT};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
  }
`
export const LogosContainer = styled.div`
  margin: 0 ${g(0, 4)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(3)};
  }
`
export const LogoPrettoContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;
`
export const LogoPretto = styled.svg.attrs(({ $isIcon }) =>
  $isIcon ? { as: LogoPrettoComponent, type: 'icon' } : { as: PrettoLogotypeComponent }
)`
  height: ${({ $isIcon }) => g($isIcon ? 4 : 3)};
  margin: 0 ${g(2)};
  width: auto;
`
export const CrossPicto = styled(TimesBold)`
  height: ${g(2)};
  width: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral1};
`
export const PartnerLogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;

  ${({ $margin }) =>
    $margin &&
    css`
      margin-top: ${$margin?.top || 0};
      margin-bottom: ${$margin?.bottom || 0};
    `}
`
export const PartnerLogo = styled(Image).attrs({ alt: 'logo partenaire', options: { crop: 'scale', height: ng(4) } })`
  display: flex;
  align-items: center;
  height: ${g(4)};
  margin: 0 ${g(2)};
`
export const ButtonBackInline = styled(ButtonBackInlineComponent)`
  height: 100%;
  padding: 0 ${g(2)};
  margin: 0 ${g(0, 4)};
`
export const Navigation = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    margin-right: ${g(3)};
  }
`
export const NavAccount = styled(NavAccountComponent)`
  margin-left: auto;

  ${({ $mode, theme }) =>
    $mode === 'default' &&
    css`
      border-left: solid 1px ${theme.colors.neutral3};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }
`
