import { YesNo } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapLiveTogetherValues: MapToChoices<YesNo[]> = (values, context) => {
  if (!context) return []
  const { liveTogether, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected:
      !isNil(liveTogether) && ((liveTogether && value === YesNo.Yes) || (!liveTogether && value === YesNo.No)),
    onClick: () => {
      setContext({ liveTogether: value === YesNo.Yes })
    },
    label: t(`sentences.lodging.liveTogether.values.${value as 'yes' | 'no'}`),
  }))
}
