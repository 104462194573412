import { getBookingDuration } from './getBookingDuration'

interface Params {
  bookingDuration: string | null
  projectStatus: string
  mandateSigned?: boolean
}

const allowedProjectStatusForR3 = ['analysis', 'confirmation']

export const getIfIsR3Appointment = ({ bookingDuration, projectStatus, mandateSigned }: Params): boolean => {
  if (mandateSigned || bookingDuration !== `${getBookingDuration('r3').meetDuration} minutes`) {
    return false
  }

  if (!allowedProjectStatusForR3.includes(projectStatus)) return false

  return true
}
