import { breakpoints, g } from '@pretto/bricks/components/layout'

import { useScrollIntersection } from '@pretto/app/src/onboarding/components/Layout/Layout'

import styled, { css } from 'styled-components'

type Scheme = 'green' | 'white'

interface ModalFooterProps {
  scheme?: Scheme
}

export const ModalFooter: React.FC<ModalFooterProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  scheme = 'green',
  ...props
}) => {
  const { isGlobalOverflowing, isLocalOverflowing } = useScrollIntersection()

  return (
    <Container
      $isGlobalOverflowing={isGlobalOverflowing}
      $isLocalOverflowing={isLocalOverflowing}
      $scheme={scheme}
      {...props}
    >
      {children}
    </Container>
  )
}

interface ContainerProps {
  $isGlobalOverflowing: boolean
  $isLocalOverflowing: boolean
  $scheme: Scheme
}

export const Container = styled.div<ContainerProps>`
  border: 1px solid transparent;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: ${g(2)} ${g(2, 4)} ${g(2)};
  transition: all 250ms ease-in-out;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }

  ${({ $isGlobalOverflowing, $isLocalOverflowing, $scheme }) => {
    switch ($scheme) {
      case 'white':
        return css`
          ${$isGlobalOverflowing &&
          css`
            background-color: ${({ theme }) => theme.colors.white};
            border-top-color: ${({ theme }) => theme.colors.neutral3};
          `}

          ${$isLocalOverflowing &&
          css`
            @media screen and (min-width: ${breakpoints.tablet}) {
              background-color: ${({ theme }) => theme.colors.white};
              border-top-color: ${({ theme }) => theme.colors.neutral3};
            }
          `}
        `

      case 'green':
      default:
        return css`
          ${$isGlobalOverflowing &&
          css`
            background-color: ${({ theme }) => theme.colors.primary1};
            border-top-color: ${({ theme }) => theme.colors.primary2};
          `}

          ${$isLocalOverflowing &&
          css`
            @media screen and (min-width: ${breakpoints.tablet}) {
              background-color: ${({ theme }) => theme.colors.primary1};
              border-top-color: ${({ theme }) => theme.colors.primary2};
            }
          `}
        `
    }
  }}
`
