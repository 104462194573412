import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Heading from '@pretto/bricks/components/typography/Heading'

import { memo } from 'react'

import * as S from './styles'

const POINTS = [
  'Nous ne transmettons aucune information aux banques sans votre accord.',
  'Nous ne vendons et ne transmettons vos informations à aucun partenaire commercial sans votre accord.',
  'Vous pouvez accéder à vos données et demander leur suppression à tout moment.',
]
const PrivacyPoints = () => (
  <div>
    <S.Title>
      <S.Emoji>
        <Emoji size="medium">:lock:</Emoji>
      </S.Emoji>
      <Heading>Vos données personnelles</Heading>
    </S.Title>
    <div>Chez Pretto, nous sommes soucieux de la protection de vos données personnelles.</div>
    <S.Points>
      {POINTS.map((point, i) => (
        <S.Elem key={i}>
          <S.Check>
            <Icon name="check-2" variant="white" />
          </S.Check>
          <div>{point}</div>
        </S.Elem>
      ))}
    </S.Points>
  </div>
)

export default memo(PrivacyPoints)
