import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Row = styled.div`
  margin-bottom: ${g(1)};
`

export const Tag = styled.div`
  margin-bottom: ${g(3)};
`

export const Values = styled.div`
  margin-bottom: ${g(2)};
`
