import { ng } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { useRef } from 'react'

import * as S from './ButtonSquare.styles'

export type Scheme = 'green' | 'black'

export type Size = 'big' | 'small'

export type ButtonSquareProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  href?: string
  disabled?: boolean
  loading?: boolean
  scheme?: Scheme
  size?: Size
}

export const ButtonSquare: React.FC<ButtonSquareProps> = ({
  disabled = false,
  loading = false,
  onClick,
  scheme = 'green',
  size = 'big',
  ...props
}) => {
  const { current: id } = useRef(random())

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    onClick?.(event)
  }

  const stateProps = {
    $isDisabled: disabled || loading,
    $isLoading: loading,
  }

  return (
    <S.Container $scheme={scheme} $size={size} {...stateProps} {...props} onClick={handleClick}>
      <S.Arrow {...stateProps} $size={size} viewBox={`0 0 ${ng(3)} ${ng(3)}`}>
        <defs>
          <S.ArrowSymbol forwardedAs="symbol" id={id} />
        </defs>

        <S.ArrowInstance1 {...stateProps} xlinkHref={`#${id}`} x={ng(-2)} y="0" />
        <S.ArrowInstance2 {...stateProps} xlinkHref={`#${id}`} x="0" y="0" />
      </S.Arrow>
      <S.Loader {...stateProps} size={size === 'small' ? 'medium' : 'large'} />
    </S.Container>
  )
}
