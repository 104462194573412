import temporal from '@pretto/bricks/core/utility/temporal'

import { InsuranceType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'

const MONTHS_IN_YEAR = 12

const matchRate = (usage: Usage, age: number): number => {
  switch (usage) {
    case Usage.Primary:
    case Usage.Secondary: {
      if (age < 46) return 0.26
      if (age < 56) return 0.37
      if (age < 66) return 0.48

      return 0.53
    }
    case Usage.Rental: {
      if (age < 46) return 0.22
      if (age < 56) return 0.3
      if (age < 66) return 0.39
      return 0.45
    }

    default:
      return 0
  }
}

const formatRate = (rate: number) => rate / (MONTHS_IN_YEAR * 100)

const getRate = (usage: Usage, age: number): number => formatRate(matchRate(usage, age))

const getRemainingDuration = (start: string, duration: number) => {
  const now = temporal()
  const day = now.date()
  const month = now.month() + 1
  const startDate = temporal(`${start}-${month}-${day}`)
  const endDate = startDate.add(duration, 'y')
  const remainingDuration = endDate.diff(now, 'M') + 1

  return {
    endDate: endDate.format('YYYY-MM-DD'),
    remainingDuration,
  }
}

interface RemainingParams {
  filledDuration: number
  filledInsurancePayment: number
  filledPayment: number
  filledPaymentWithoutInsurance: number
  filledRate: number
  filledStartYear: number
  mortgagors: Mortgagor[]
  type: InsuranceType
  usage: Usage
}

export const calcRemainingPrincipal = ({
  filledDuration,
  filledInsurancePayment,
  filledPayment,
  filledPaymentWithoutInsurance,
  filledRate,
  filledStartYear,
  mortgagors,
  type,
  usage,
}: RemainingParams) => {
  const duration = filledDuration * MONTHS_IN_YEAR
  const rate = filledRate / (MONTHS_IN_YEAR * 100)

  const getBankGroupInsuranceOptions = () => {
    const insuranceRate = mortgagors.reduce((previous, { age }) => {
      const formatedAge = Number(age)
      return previous + getRate(usage, formatedAge)
    }, 0)

    const insurancePayment = (filledPayment / (insuranceRate + rate / (1 - (1 + rate) ** -duration))) * insuranceRate

    return {
      insurancePayment,
      insuranceRate,
      loanPayment: filledPayment - insurancePayment,
      payment: filledPayment,
    }
  }
  const delegatedInsuranceOptions = {
    insurancePayment: filledInsurancePayment,
    insuranceRate: 0,
    loanPayment: filledPaymentWithoutInsurance,
    payment: filledPaymentWithoutInsurance,
  }

  const options = {
    bankGroupInsurance: getBankGroupInsuranceOptions(),
    delegatedInsurance: delegatedInsuranceOptions,
  }

  const option = options[type]

  if (!option) {
    return null
  }

  const { insuranceRate, payment } = option
  const principal = payment / (insuranceRate + rate / (1 - (1 + rate) ** -duration))

  const { endDate, remainingDuration } = getRemainingDuration(filledStartYear.toString(), filledDuration)
  const paidDuration = duration - remainingDuration

  const { insurancePayment, loanPayment } = option

  const remainingPrincipal =
    principal * (1 + rate) ** paidDuration - loanPayment * (((1 + rate) ** paidDuration - 1) / rate)

  return {
    endDate,
    insurancePayment,
    remainingPrincipal,
    loanPayment,
  }
}
