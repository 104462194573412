import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Chips from '@pretto/bricks/components/chips/Chips'
import useAutoComplete from '@pretto/bricks/components/form/AutoComplete/useAutoComplete'

import PropTypes from 'prop-types'

import * as S from './styles'

const TagsField = ({ idleLabel, onBlur, tags, ...props }) => {
  const { component, open } = useAutoComplete({ ...props, tags, type: 'tags' })

  const handleClick = open

  return (
    <>
      {tags.length === 0 && (
        <ButtonSquared onBlur={onBlur} onClick={handleClick}>
          {idleLabel}
        </ButtonSquared>
      )}

      {tags.length > 0 && (
        <S.Tags>
          {tags.map((tag, index) => (
            <S.Tag key={index}>
              <Chips {...tag} />
            </S.Tag>
          ))}

          <S.ButtonContainer>
            <ButtonFeature onClick={handleClick} label={'Ajouter'} />
          </S.ButtonContainer>
        </S.Tags>
      )}

      {component}
    </>
  )
}

TagsField.defaultProps = {
  idleLabel: 'Ajouter',
}

TagsField.propTypes = {
  /** Button label when no tags are provided. */
  idleLabel: PropTypes.string,
  /** Event triggered whenever the add button is being blurred. */
  onBlur: PropTypes.func,
  /** List of tags. */
  tags: PropTypes.arrayOf(
    PropTypes.shape({ children: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired })
  ).isRequired,
}

export default TagsField
