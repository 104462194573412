import { g } from '@pretto/bricks/components/layout'

import { TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Container = styled.div<{ $isEditable: boolean }>`
  padding: ${g(2)};
  border-radius: ${g(1)};
  ${({ $isEditable, theme }) =>
    $isEditable
      ? css`
          border: 1px solid ${theme.colors.neutral2};
        `
      : css`
          background-color: ${theme.colors.neutral5};
        `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.body4Bold}
`

export const Cross = styled(TimesBold)`
  cursor: pointer;
`
