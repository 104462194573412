import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'

import * as S from './styles'

const SponsorshipInvite = ({ onClick }) => (
  <S.Card onClick={onClick}>
    <Card format="line" variant="neutral-1-20">
      <Content>
        <S.Content>
          <Icon name="add" />

          <S.Label>
            <SubHeading>Inviter un proche</SubHeading>
          </S.Label>
        </S.Content>
      </Content>
    </Card>
  </S.Card>
)

SponsorshipInvite.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default SponsorshipInvite
