import isNil from 'lodash/isNil'

export const getMortgagorFullname = <M extends { mortgagor?: number | null }, T extends { name: string }>(
  meta: M | undefined | null,
  accounts: [T] | [T, T]
): [string] | [string, string] => {
  const names = accounts.map(({ name }) => name)
  const mortgagorIndex = meta?.mortgagor

  if (!isNil(mortgagorIndex) && names[mortgagorIndex]) {
    return [names[mortgagorIndex]]
  }

  return names as ReturnType<typeof getMortgagorFullname>
}
