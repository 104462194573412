import DOCS from '@pretto/config/docs.json'
import uniqBy from 'lodash/uniqBy'

export const getDocs = ({ docs, type }, isUnique = false) => {
  const docsByType = docs.filter(({ kind }) => DOCS[kind][type] && kind !== 'other')
  const uploadedDocs = docsByType.filter(
    ({ document_status }) => document_status === 'valid' || document_status === 'pending'
  )

  const maxDocs = (isUnique ? uniqBy(docsByType, 'kind') : docsByType).length
  const numberUploaded = (isUnique ? uniqBy(uploadedDocs, 'kind') : uploadedDocs).length

  return { maxDocs, numberUploaded }
}
