import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Container = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: ${g(70)};
  }

  a {
    color: ${({ theme }) => theme.legacy.colors.accent1.default};
  }
`
export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    background: ${({ theme }) => theme.legacy.colors.white.default};
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
    margin-bottom: ${g(3)};
    padding: ${g(3)};
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(4)};
`

export const Fields = styled.form`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
    display: grid;
    grid-gap: ${g(3)} ${g(2)};
    grid-template-columns: repeat(2, 1fr);
  }
`
export const Field = styled.div`
  margin-top: ${g(3)};

  &:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 1 / span 2;
    margin-top: 0;
  }
`
export const FirstName = styled(Field)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 1 / span 1;
  }
`
export const LastName = styled(Field)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 1;
  }
`
export const SubmitButton = styled.div`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    grid-column: 1 / span 2;
    justify-content: center;
  }
`
export const Divider = styled.div`
  margin: ${g(3)} 0;
`
export const GoCardlessLogos = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`
