import BlockInfoCTA from '@pretto/bricks/app/pre-lead/components/BlockInfoCTA'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Card from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { Eye, Coins as PictoCoins } from '@pretto/picto'

import styled from 'styled-components'

export const BlockInfoCertificate = styled(BlockInfoCTA)`
  background-color: ${({ theme }) => theme.colors.neutral4};
`

export const BudgetCard = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(3)};
`

export const Coins = styled(PictoCoins)`
  margin-right: ${g(1)};
`

export const Description = styled(BaseTypo)`
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Details = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  & > div {
    display: flex;
    flex-direction: column;
    padding: ${g(3)};
    height: 100%;
  }
`

export const Header = styled(BaseTypo)`
  display: flex;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const Icon = styled(Eye)`
  margin-right: ${g(1)};
`

export const Infos = styled.div`
  display: grid;
  grid-gap: ${g(3)};
  grid-template-columns: 1fr;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const MinMax = styled(BaseTypo).attrs({ typo: 'heading3' })`
  margin-bottom: ${g(2)};
`

export const Row = styled.div`
  margin-bottom: ${g(1)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const SeeMore = styled(ButtonInline)`
  align-self: flex-start;
  margin-top: ${g(2)};
`
