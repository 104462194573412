import PropTypes from 'prop-types'
import { memo } from 'react'
import { ThemeProvider } from 'styled-components'

import * as S from './styles'

const ButtonSquare = ({ size, variant, ...props }) => {
  return (
    <ThemeProvider theme={{ variant }}>
      <S.ButtonSquare {...props} $size={size}>
        <S.Picto />
      </S.ButtonSquare>
    </ThemeProvider>
  )
}

ButtonSquare.defaultProps = {
  size: 'big',
  variant: 'primary',
}

ButtonSquare.propTypes = {
  /** Aspect size of the button. */
  size: PropTypes.oneOf(['big', 'small']),
  /** kind of button we use */
  variant: PropTypes.oneOf(['primary', 'secondary', 'neutral']),
}

export default memo(ButtonSquare)
