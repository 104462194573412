import BlockInfoCTA from '@pretto/bricks/app/pre-lead/components/BlockInfoCTA'
import PreLeadCardRows from '@pretto/bricks/app/pre-lead/components/PreLeadCardRows'
import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'

import * as S from './styles'

const SectionRenegotiation = ({
  currentPayment,
  currentRate,
  estimatedValue,
  savings,
  duration,
  isAppointmentAvailable,
  href,
  onEdit,
  payment,
  rate,
  remainingPrincipal,
}) => {
  const currentLoanItems = [
    { label: 'Mensualités', value: formatNumber(currentPayment, { decimals: 0, suffix: '€ / mois' }) },
    { label: 'Capital restant dû', value: formatNumber(remainingPrincipal, { decimals: 0, suffix: '€' }) },
    { label: 'Valeur estimée du bien', value: formatNumber(estimatedValue, { suffix: '€' }) },
  ]

  const isSavings = savings >= 0
  const labelSavings = isSavings ? 'Économies réalisées' : 'Surcoût'

  const newLoanItems = [
    {
      label: labelSavings,
      value: formatNumber(Math.abs(savings), { suffix: '€' }),
      status: isSavings ? 'success' : 'error',
    },
    { label: 'Mensualités', value: formatNumber(payment, { decimals: 0, suffix: '€ / mois' }) },
    { label: 'Durée d’emprunt', value: formatNumber(duration, { suffix: 'ans' }) },
  ]

  return (
    <PreLeadProjectCardTemplate isEditable onEdit={onEdit} type="renegotiation" href={href}>
      <S.Infos>
        <S.RateCard>
          <S.Header>
            <S.House />
            Votre prêt actuel
          </S.Header>
          <S.Rate>{formatNumber(currentRate, { suffix: '%' })}</S.Rate>
          <PreLeadCardRows items={currentLoanItems} />
        </S.RateCard>
        <S.RateCard>
          <S.Header>
            <S.Chart />
            Votre nouveau prêt
          </S.Header>
          <S.Rate>{formatNumber(rate, { suffix: '%' })}</S.Rate>
          <PreLeadCardRows items={newLoanItems} />
        </S.RateCard>
      </S.Infos>
      {isAppointmentAvailable && (
        <BlockInfoCTA status="appointment" href="/simulation/subscribe?kind=dashboard_appointment&origin=dashboard" />
      )}
    </PreLeadProjectCardTemplate>
  )
}

SectionRenegotiation.propTypes = {
  /** Payment of current loan */
  currentPayment: PropTypes.number.isRequired,
  /** Rate of the current loan */
  currentRate: PropTypes.number.isRequired,
  /** Duration of new estimated loan. */
  duration: PropTypes.number.isRequired,
  /** Estimated value of the house */
  estimatedValue: PropTypes.number.isRequired,
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  isAppointmentAvailable: PropTypes.bool.isRequired,
  /** Edit function triggered on card click. */
  onEdit: PropTypes.func.isRequired,
  /** Payment of estimated loan. */
  payment: PropTypes.number.isRequired,
  /** Rate showed for the project. */
  rate: PropTypes.number.isRequired,
  /** Outstanding capital */
  remainingPrincipal: PropTypes.number.isRequired,
  /** Savings made. */
  savings: PropTypes.number.isRequired,
}

export default SectionRenegotiation
