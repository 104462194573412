import TipBubble from '@pretto/bricks/app/sentences/components/TipBubble'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'

import PropTypes from 'prop-types'

import * as S from './ApplicationIntroduction.styles'

const ApplicationIntroductionPage = ({ onContinue, onTrack, user }) => {
  const handleGifClick = () => onTrack('APPLICATION_INTRODUCTION_GIF_CLICKED')

  return (
    <S.MainContainer>
      <S.ContentContainer>
        <Responsive min="tablet">
          <S.LeftContainer onClick={handleGifClick}></S.LeftContainer>
        </Responsive>

        <S.RightContainer>
          <div>
            <S.Title>Accédez à votre dossier</S.Title>

            <TipBubble>
              <p>
                {user.firstName}, Il ne vous reste plus qu’un pas à franchir avant de transmettre vos documents pour
                vérifier vos informations. Vos données sont sécurisées et ne seront transmises aux banques qu’avec votre
                accord !
              </p>
            </TipBubble>

            <S.StyledBanner>
              Veillez à compléter votre dossier de manière exhaustive afin d’appuyer sa validité auprès d’un maximum de
              banques.
            </S.StyledBanner>
          </div>

          <S.FooterContainer>
            <Button isArrowShown onClick={onContinue}>
              C’est parti !
            </Button>
          </S.FooterContainer>
        </S.RightContainer>
      </S.ContentContainer>
    </S.MainContainer>
  )
}

ApplicationIntroductionPage.propTypes = {
  /** Event triggered whenever the start button is pressed. */
  onContinue: PropTypes.func.isRequired,
  onTrack: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default ApplicationIntroductionPage
