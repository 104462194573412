import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

import * as helpers from '@pretto/bricks/components/helpers'
import SecondNav from '@pretto/bricks/website/about/components/SecondNav'

export const Nav = styled(SecondNav).attrs(({ theme }) => ({ activeColor: theme.colors.primary2 }))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.neutral1};
  ${helpers.boxShadow('card')};
  position: relative;
  z-index: 1;
`

export const MainContainer = styled.div`
  ${({ $isSplited }) =>
    $isSplited &&
    css`
      @media only screen and (min-width: ${breakpoints.laptop}) {
        display: grid;
        grid-template-columns: ${g(40)} 1fr;
      }
    `}
`
