import { ARROW_LEFT, ARROW_RIGHT } from '@pretto/bricks/core/constants/keycodes'
import { mapValueInRange } from '@pretto/bricks/core/utility/math'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import clamp from 'lodash/clamp'
import PropTypes from 'prop-types'
import { cloneElement, useRef, useState } from 'react'

import { getApproximatedValue } from './lib/getApproximatedValue'
import { getArrangedMarks } from './lib/getArrangedMarks'
import * as S from './styles'

const Slider = ({
  autoFocus,
  color,
  marks,
  max,
  min,
  onAfterChange,
  onBeforeChange,
  onChange,
  railVariant,
  step,
  tooltipComponent,
  value,
  ...props
}) => {
  const shouldTriggerAfterChange = useRef(false)
  const shouldTriggerChange = useRef(true)
  const shouldTriggerRelease = useRef(false)

  const [rawValue, setRawValue] = useState(getApproximatedValue(value, marks, min, max))
  const [ticker, setTicker] = useState(0)

  useUpdateEffect(() => {
    const approximatedValue = getApproximatedValue(rawValue, marks, min, max)

    if (shouldTriggerChange.current) {
      onChange(approximatedValue)
    }

    if (shouldTriggerAfterChange.current) {
      onAfterChange(approximatedValue)

      shouldTriggerAfterChange.current = false
    }

    shouldTriggerChange.current = true
  }, [rawValue, ticker])

  useUpdateEffect(() => {
    shouldTriggerChange.current = false

    setRawValue(value)
  }, [value])

  const handleChange = event => {
    shouldTriggerRelease.current = true

    setRawValue(event.currentTarget.value)
  }

  const handleGrab = () => {
    onBeforeChange(value)
  }

  const handleKeyDown = event => {
    onBeforeChange(value)

    if (!marks) {
      shouldTriggerAfterChange.current = true

      return
    }

    const approximatedValue = getApproximatedValue(rawValue, marks, min, max)
    const arrangedMarks = getArrangedMarks(marks, min, max)
    const markIndex = arrangedMarks.indexOf(approximatedValue)

    switch (event.keyCode) {
      case ARROW_LEFT: {
        event.preventDefault()

        shouldTriggerAfterChange.current = true

        const rawValue = arrangedMarks[markIndex - 1] ?? min

        setRawValue(rawValue)

        break
      }

      case ARROW_RIGHT: {
        event.preventDefault()

        shouldTriggerAfterChange.current = true

        const rawValue = arrangedMarks[markIndex + 1] ?? max

        setRawValue(rawValue)

        break
      }

      default:
        break
    }
  }

  const handleRelease = () => {
    if (!shouldTriggerRelease.current) {
      return
    }

    shouldTriggerAfterChange.current = true
    shouldTriggerRelease.current = false

    setRawValue(value)
    setTicker(ticker => ticker + 1)
  }

  const scaleX = clamp(mapValueInRange(value, min, max, 0, 1), 0, 1)
  const transformX = clamp(mapValueInRange(value, min, max, 0, 100), 0, 100)

  return (
    <S.SliderContainer {...props}>
      <S.InputControl
        autoFocus={autoFocus}
        max={max}
        min={min}
        onBlur={handleRelease}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleGrab}
        onMouseDown={handleGrab}
        onMouseUp={handleRelease}
        onTouchEnd={handleRelease}
        onTouchStart={handleGrab}
        step={step}
        type="range"
        value={rawValue}
      />

      <S.Slider>
        <S.ProgressMask $railVariant={railVariant}>
          <S.Progress $color={color} style={{ transform: `scaleX(${scaleX})` }} />
        </S.ProgressMask>

        <S.Handle $color={color} style={{ left: `${transformX}%`, transform: `translateX(${-transformX}%)` }} />

        {tooltipComponent && (
          <S.Tooltip style={{ left: `${transformX}%`, transform: `translateX(${-transformX}%)` }}>
            {cloneElement(tooltipComponent, { value })}
          </S.Tooltip>
        )}
      </S.Slider>
    </S.SliderContainer>
  )
}

Slider.defaultProps = {
  autoFocus: false,
  max: 100,
  min: 0,
  onAfterChange: () => {},
  onBeforeChange: () => {},
  railVariant: 'neutral4',
  step: 1,
}

Slider.propTypes = {
  /** Whether or not the slider should get default focus. */
  autoFocus: PropTypes.bool,
  /** Color of the handler and the progressBar */
  color: PropTypes.string,
  /** Defines granular custom incremental steps when the progress is not linear. */
  marks: PropTypes.array,
  /** Maximum range value of the slider. */
  max: PropTypes.number,
  /** Minimum range value of the slider. */
  min: PropTypes.number,
  /** Triggered after a value change occured. */
  onAfterChange: PropTypes.func,
  /** Triggered before any change of value occures. */
  onBeforeChange: PropTypes.func,
  /** Triggered whenever a change of value occured. */
  onChange: PropTypes.func.isRequired,
  /** DEPRECATED: Variant color of the rail. */
  railVariant: PropTypes.string,
  /** Incremental step of the range. */
  step: PropTypes.number,
  /** Component to be used as a tooltip. */
  tooltipComponent: PropTypes.node,
  /** Current value of the slider. */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default Slider
