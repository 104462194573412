import { mapPaidOffValues } from '@pretto/app/src/Sentences/v2/Sale/PaidOff/lib/mappers/mapPaidOffValues'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const PaidOffPage: React.FC = () => {
  const context = useSentences()
  const { ownPropertyPaidOff, setContext } = context
  const { goToNextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, !isNil(ownPropertyPaidOff))

  const handleChoiceClick = () => {
    if (ownPropertyPaidOff) {
      goToNextRoute()
      return
    }

    setContext({ ownPropertyEndDate: undefined, ownPropertyRemainingPrincipal: undefined, ownPropertyRate: undefined })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'paidOff' } }}>
      <Title>{t('sentences.sale.title')}</Title>
      <div>
        {t('sentences.sale.paidOff.sentences.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(ownPropertyPaidOff)}>
          {t(`sentences.sale.paidOff.values.${!isNil(ownPropertyPaidOff) && ownPropertyPaidOff ? 'yes' : 'no'}`)}
        </Prompt>
        {t('sentences.sale.paidOff.sentences.1')}.
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapPaidOffValues(Object.values(YesNo), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'paidOff' } }}
          onChoiceClick={handleChoiceClick}
        />
      )}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
