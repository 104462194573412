import { Filter, Marker, Picto } from '@pretto/picto'
import { CityInfo, IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'

export interface Info {
  picto: Picto
  title: string
  content: string
}

enum PROPERTY_KIND {
  both = 'Appartement ou maison',
  flat = 'Appartement',
  house = 'Maison'
}

enum PROPERTY_CONDITION {
  both = 'Ancien ou neuf',
  old = 'Ancien',
  new = 'Neuf'
}

interface minMaxParams {
  min: number
  max: number
  suffix: string
}

const getFormattedCities = (localisations: Array<CityInfo>): string => {
  if (localisations.length < 3) {
    return localisations.map(({ city, zipcode }) => `${city} (${zipcode})`).join(',')
  }

  const shownCities = localisations.slice(0, 2).map(({ city, zipcode }) => `${city} (${zipcode})`)
  const shownCitiesStr = shownCities.join(',')
  const plusCities = localisations.length - shownCities.length

  return `${shownCitiesStr}, +${plusCities} localités`
}

const getMinMax = ({ min, max, suffix } : minMaxParams): string | boolean => {
  if (min && max) {
    return `entre ${min}${suffix} et ${max}${suffix}`
  }

  if (min) {
    return `de plus de ${min}${suffix}`
  }

  if (max) {
    return `de moins de ${max}${suffix}`
  }

  return false
}

const getCriteria = ({ property_kind, area_min, area_max, rooms, property_condition, price_min, price_max}:Pick<
  IPropertySearchData,
  | 'property_kind'
  | 'area_min'
  | 'area_max'
  | 'rooms'
  | 'property_condition'
  | 'price_min'
  | 'price_max'
>): string => {
  const propertyKind = PROPERTY_KIND[property_kind]
  const surface = getMinMax({ min: area_min, max: area_max, suffix: 'm²' })
  const roomsStr = `${rooms}+ pièces`
  const propertyCondition = PROPERTY_CONDITION[property_condition]
  const price = getMinMax({ min: price_min, max: price_max, suffix: ' €' })

  const criteria = [propertyKind, surface, roomsStr, propertyCondition, price].filter(Boolean).join(', ')

  return criteria
}

export const formatData = ({ localisations, property_kind, area_min, area_max, rooms, property_condition, price_min, price_max }:Pick<
  IPropertySearchData,
  | 'localisations'
  | 'property_kind'
  | 'area_min'
  | 'area_max'
  | 'rooms'
  | 'property_condition'
  | 'price_min'
  | 'price_max'
>): Array<Info> => (
  [{
    picto: Marker,
    title: 'Localisation',
    content: getFormattedCities(localisations)
  }, {
    picto: Filter,
    title: 'Critères',
    content: getCriteria({ property_kind, area_min, area_max, rooms, property_condition, price_min, price_max })
  }]
)