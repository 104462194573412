import Responsive from '@pretto/bricks/components/utility/Responsive'

import { ButtonProps } from '@pretto/zen/atoms/buttons/Button/Button'

import { Picto } from '@pretto/picto'

import * as S from './DashboardKeepProjectCard.styles'

export interface ProjectCard {
  picto: Picto
  label: string
  value: string
}

export interface DashboardKeepProjectCardProps {
  description: React.ReactNode
  title: string
  cards: Array<ProjectCard>
  ctaProps: ButtonProps
}

export const DashboardKeepProjectCard = ({
  title,
  description,
  cards,
  ctaProps,
  ...props
}: DashboardKeepProjectCardProps) => {
  return (
    <S.Card {...props}>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.ProjectCards>
        {cards.map(({ picto, label, value }) => (
          <S.ProjectCard key={label}>
            <S.ProjectCardHeader>
              <S.Picto $picto={picto} />
              <Responsive min="laptop">{label}</Responsive>
            </S.ProjectCardHeader>
            <S.Value>{value}</S.Value>
          </S.ProjectCard>
        ))}
      </S.ProjectCards>
      <S.Buttons>
        <S.Cta {...ctaProps} />
      </S.Buttons>
    </S.Card>
  )
}
