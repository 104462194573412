import { diffValues } from '@pretto/app-core/lib/diffValues'
import { encodeValues } from '@pretto/app-core/lib/encodeValues'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import isEmpty from 'lodash/isEmpty'

export const encodeSectionsValues = async (encoder = values => values, sections, values, defaultValues, data) => {
  const differedValues = diffValues(defaultValues, values)

  if (isEmpty(differedValues)) {
    return []
  }

  const encodedSectionsValues = await sections.reduce(async (previous, currentSection) => {
    const previousValues = await previous
    const section = funcToItem(currentSection, {})
    const sectionValues = section.fields.reduce((previous, { name }) => {
      if (!(name in differedValues)) {
        return previous
      }

      return { ...previous, [name]: differedValues[name] }
    }, {})

    const encodedSectionValues = encodeValues(await encoder(sectionValues, data.project, values))

    if (isEmpty(encodedSectionValues)) {
      return [...previousValues, null]
    }

    return [
      ...previousValues,
      {
        mutation: section.mutation,
        values: encodedSectionValues,
      },
    ]
  }, Promise.resolve([]))

  return encodedSectionsValues
}
