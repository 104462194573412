import { EmailField } from '../Fields/EmailField'
import { NameField } from '../Fields/NameField'
import { PhoneField } from '../Fields/PhoneField'

const defaultInputProps = {
  autoFocus: true,
}

const mortgagorFirstName = {
  component: NameField,
  defaultValue: ({ user }) => user.firstName,
  inputProps: {
    ...defaultInputProps,
    placeholder: 'Votre prénom',
  },
  label: 'Prénom',
  name: 'first_name',
  type: 'name',
}

const mortgagorLastName = {
  component: NameField,
  defaultValue: ({ user }) => user.lastName,
  inputProps: {
    ...defaultInputProps,
    placeholder: 'Votre nom',
  },
  label: 'Nom de famille',
  name: 'last_name',
  type: 'name',
}

const mortgagorPhone = {
  component: PhoneField,
  defaultValue: ({ user }) => user.phone,
  inputProps: {
    ...defaultInputProps,
    placeholder: 'Votre numéro de téléphone',
  },
  label: 'Téléphone',
  name: 'phone',
  type: 'phone',
}

const mortgagorEmail = {
  component: EmailField,
  defaultValue: ({ user }) => user.email,
  inputProps: {
    ...defaultInputProps,
    placeholder: 'Votre email',
  },
  isEditDisabled: true,
  caption: 'L’adresse mail n’est pas modifiable depuis l’espace client, veuillez contacter le support.',
  label: 'Email',
  name: 'email',
  type: 'name',
}

const comortgagorFirstName = {
  ...mortgagorFirstName,
  defaultValue: ({ accounts }) => accounts.find(({ isMine }) => !isMine).firstName,
  inputProps: {
    ...mortgagorFirstName.inputProps,
    placeholder: 'Son prénom',
  },
}

const comortgagorLastName = {
  ...mortgagorLastName,
  defaultValue: ({ accounts }) => accounts.find(({ isMine }) => !isMine).lastName,
  inputProps: {
    ...mortgagorLastName.inputProps,
    placeholder: 'Son nom',
  },
}

const comortgagorPhone = {
  ...mortgagorPhone,
  defaultValue: ({ accounts }) => accounts.find(({ isMine }) => !isMine).phone,
  inputProps: {
    ...mortgagorPhone.inputProps,
    placeholder: 'Son numéro de téléphone',
  },
}

const comortgagorEmail = {
  ...mortgagorEmail,
  defaultValue: ({ accounts }) => accounts.find(({ isMine }) => !isMine).email,
  inputProps: {
    ...defaultInputProps,
    placeholder: 'Son email',
  },
}

export const sections = [
  {
    accountId: ({ user }) => user.id,
    fields: ({ typology }) => {
      const defaultFields = [mortgagorFirstName, mortgagorLastName, mortgagorEmail]

      if (typology === 'preLead') {
        return defaultFields
      }

      return [...defaultFields, mortgagorPhone]
    },
    id: 'mortgagor',
    title: 'Vos informations',
  },
  {
    accountId: ({ accounts }) => accounts.find(({ isMine }) => !isMine).id,
    condition: ({ hasComortgagor }, comortgagorAccount) => hasComortgagor && comortgagorAccount,
    fields: [comortgagorFirstName, comortgagorLastName, comortgagorPhone, comortgagorEmail],
    id: 'comortgagor',
    title: 'Les informations de votre co-emprunteur',
  },
]
