import { Matrice } from '@pretto/app/src/Simulation/Containers/ResultsPage/config/bankCriteriasMatrices'

export const formatBankCriteria = (matrice: Matrice, value: number) => ({
  label: matrice[value as 1 | 2 | 3 | 4 | 5],
  get state() {
    if (value > 3) {
      return 'success'
    }

    if (value > 1) {
      return 'warning'
    }

    return 'error'
  },
})
