import Menu from '@pretto/app/src/SharedContainers/Header/containers/Menu'
import NavBar from '@pretto/app/src/SharedContainers/Header/containers/NavBar'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

import PropTypes from 'prop-types'
import { useState } from 'react'

const Header = ({
  accountVariant,
  backgroundColor,
  goBackProps,
  isMobileHidden,
  isPartnerLogoDisabled,
  navigationItemsList,
  mode,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [accountKeyDown, setAccountKeyDown] = useState(null)
  const [accountKeyDownChecker, setAccountKeyDownChecker] = useState(0)
  const [isAccountFocused, setIsAccountFocused] = useState(false)
  const [menuFocusIndex, setMenuFocusIndex] = useState(null)
  const { typology } = useUser()

  const defineAccountKeyDown = value => {
    setAccountKeyDown(value)
    setAccountKeyDownChecker(accountKeyDownChecker => accountKeyDownChecker + 1)
  }
  const defineMenuFocusIndex = value => {
    setMenuFocusIndex(value)
  }
  const focusAccount = () => {
    setIsAccountFocused(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onOpen = () => {
    switch (typology) {
      case 'client':
        setIsOpen(true)
        break
      case 'preLead':
        if (accountVariant !== 'onlyInitial') {
          setIsOpen(true)
        }
        break
      default:
        break
    }
  }

  const focusProps = {
    accountKeyDown,
    accountKeyDownChecker,
    defineAccountKeyDown,
    defineMenuFocusIndex,
    focusAccount,
    isAccountFocused,
    menuFocusIndex,
  }

  const menuProps = {
    isOpen,
    onClose,
    onOpen,
  }

  return (
    <>
      <NavBar
        accountVariant={accountVariant}
        backgroundColor={backgroundColor}
        focusProps={focusProps}
        goBackProps={goBackProps}
        isMobileHidden={isMobileHidden}
        isPartnerLogoDisabled={isPartnerLogoDisabled}
        menuProps={menuProps}
        mode={mode}
        navigationItemsList={navigationItemsList}
      />
      <Menu menuProps={menuProps} focusProps={focusProps} />
    </>
  )
}

Header.propTypes = {
  /** Color of the background */
  backgroundColor: PropTypes.string,
  /** Variant of the account bloc */
  accountVariant: PropTypes.oneOf(['loggedIn', 'signIn', 'signUp', 'onlyInitial']),
  /** Optionnal props of Go Back button */
  goBackProps: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
  /** Is the nav bar hidden on mobiles ? */
  isMobileHidden: PropTypes.bool,
  /** Is partner logo disabled on the page, when it can be ? */
  isPartnerLogoDisabled: PropTypes.bool,
  /** List of the navigation items */
  navigationItemsList: PropTypes.array,
  mode: PropTypes.oneOf(['default', 'compact']),
}

Header.defaultProps = {
  navigationItemsList: [],
}

export default Header
