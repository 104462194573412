import PrivacyActions from '@pretto/bricks/app/privacy/components/PrivacyActions'
import PrivacyContact from '@pretto/bricks/app/privacy/components/PrivacyContact'
import PrivacyDelete from '@pretto/bricks/app/privacy/components/PrivacyDelete'
import PrivacyInformations from '@pretto/bricks/app/privacy/components/PrivacyInformations'
import PrivacyPoints from '@pretto/bricks/app/privacy/components/PrivacyPoints'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PrivacyPage = ({ privacyActionsProps, privacyInformationsProps }) => (
  <Wrapper>
    <S.Page>
      <Content>
        <S.Points>
          <PrivacyPoints />
        </S.Points>
        {!privacyActionsProps && (
          <S.Delete>
            <PrivacyDelete />
          </S.Delete>
        )}
        <PrivacyInformations {...privacyInformationsProps} />
        <Divider />
        <S.Contact>
          <PrivacyContact />
        </S.Contact>
        {privacyActionsProps && (
          <S.Actions>
            <PrivacyActions {...privacyActionsProps} />
          </S.Actions>
        )}
      </Content>
    </S.Page>
  </Wrapper>
)
PrivacyPage.propTypes = {
  privacyActionsProps: PropTypes.object,
  privacyInformationsProps: PropTypes.object,
}

export default memo(PrivacyPage)
