export const ANDROID = 'Android'

export const IOS = 'iOS'

export const detectMobileOS = () => {
  const userAgent = typeof window !== 'undefined' && (navigator.userAgent || navigator.vendor || window.opera)

  if (!userAgent) return null

  if (/android/i.test(userAgent)) {
    return ANDROID
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS
  }

  return null
}
