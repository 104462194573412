import sponsorshipParams from '@pretto/config/sponsorship_params.json'

export const sponsorshipBlockInfosProps = {
  title: `Parrainez vos proches, gagnez ${sponsorshipParams.bounty} € !`,
  content: `Vos amis, collègues ou famille ont un projet immobilier ? Faites-leur bénéficier de ${sponsorshipParams.discount} € de réduction sur les frais Pretto, et recevez ${sponsorshipParams.bounty} € pour chaque parrainage réussi.`,
  image: 'v1637683136/zen/static-assets/illustrations/660x660/woman-sitting.svg',
  buttonProps: {
    children: 'Parrainer',
    href: '/sponsorship',
  },
}
