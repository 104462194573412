import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { deleteAlert, fetchAlerts, updateAlert, updateEmailFrequency } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'

import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import { getAuthToken } from '../../../Auth/lib/helpers'
import NotFoundPage from '../../../Errors/Containers/NotFoundPage'
import { useTracking } from '../../../lib/tracking'
import { mainSchema, unsubscribeSchema } from '../../config/schema'
import { renderSections } from '../../lib/renderForm'
import resetValuesWithSections from '../../lib/resetValuesWithSections'
import AlertsFormPage from '../AlertsFormPage'
import { usePropertySearch } from '../PropertySearchProvider'

const getAuthHeader = () => ({ Authorization: `Bearer ${getAuthToken()}` })

const decodeValuesWithSections = ({ sections }, data) =>
  sections.reduce(
    (previous, { fields }) => ({
      ...previous,
      ...fields.reduce((previous, { fieldProps, name, type }) => {
        if (!name) {
          return previous
        }

        const value = data[name]

        switch (type) {
          case 'checkbox':
            return { ...previous, [name]: value === true }

          case 'checkboxes': {
            if (
              (['property_kind', 'property_condition'].includes(name) && value === 'both') ||
              (name === 'balcony' && value) ||
              (name === 'basement' && value) ||
              (name === 'elevator' && value) ||
              (name === 'has_furniture' && value) ||
              (name === 'not_on_ground_floor' && value) ||
              (name === 'parking_space' && value)
            ) {
              return { ...previous, [name]: fieldProps.options.map(({ value }) => value) }
            }

            return { ...previous, [name]: [value] }
          }

          default:
            return { ...previous, [name]: value ?? null }
        }
      }, {}),
    }),
    {}
  )

const AlertsEditPage = ({ history, location, match }) => {
  const authContext = useAuth()

  const { flushCache } = usePropertySearch()

  const [defaultData, setDefaultData] = useState(null)
  const [defaultValues, setDefaultValues] = useState(null)
  const [error, setError] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isUnsubscribeLoading, setIsUnsubscribeLoading] = useState(false)
  const [unsubscribeValues, setUnsubscribeValues] = useState({})
  const [alertTitles, setAlertTitles] = useState(null)

  const trackAction = useTracking()

  const query = qs.parse(location.search, { ignoreQueryPrefix: true })
  const isCreated = query.created === 'true'

  useEffect(() => {
    ;(async () => {
      try {
        const alerts = await fetchAlerts()

        if (alerts.length === 0) {
          throw new Error('no alerts')
        }

        const alert = alerts[0]
        setDefaultData(alert)
        setAlertTitles(alerts.map(({ title }) => title))

        const decodedValues = decodeValuesWithSections(mainSchema, alert)

        decodedValues.via_email = alert.frequency !== 'none'

        const values = resetValuesWithSections(mainSchema, decodedValues, true, authContext)

        setDefaultValues(values)
        setIsLoading(false)
      } catch (error) {
        setError(true)
      }
    })()
  }, [])

  useEffect(() => {
    if (isLoading || !isCreated) {
      return
    }

    history.replace(`/property-search/alerts/${match.params.id}/edit`)
  }, [isLoading])

  const handleDialogClose = () => {
    if (isUnsubscribeLoading) {
      return
    }

    setIsDialogOpen(false)
  }

  const handleSubmit = async (data, done) => {
    const emailFrequency = data.via_email === true ? data.frequency : 'none'

    const payload = {
      ...defaultData,
      ...omit(data, 'via_email'),
      title: alertTitles[0],
    }

    await Promise.all([updateAlert(defaultData.id, payload), updateEmailFrequency(emailFrequency)])

    trackAction('PROPERTY_SEARCH_ALERT_EDITED', { id: payload.id })

    flushCache()

    done()
  }

  const handleVisit = () => {}

  const handleUnsubscribe = () => {
    setUnsubscribeValues({})
    setIsDialogOpen(true)
  }

  const handleUnsubscribeSubmit = async () => {
    setIsUnsubscribeLoading(true)

    await deleteAlert(defaultData.id)

    trackAction('PROPERTY_SEARCH_ALERT_UNSUBSCRIBED', { reason: unsubscribeValues.reason })

    flushCache()

    history.push('/')
  }

  const handleUnsubscribeValuesChange = (name, value) => {
    if (isUnsubscribeLoading) {
      return
    }

    setUnsubscribeValues(values => ({ ...values, [name]: value }))
  }

  if (error) {
    return <NotFoundPage />
  }

  if (isLoading) {
    return <SpinnerLegacy overlay />
  }

  const context = {
    onUnsubscribe: handleUnsubscribe,
  }

  const isUnsubscribeDisabled = !unsubscribeValues.reason

  const unsubscribeSections = renderSections(
    unsubscribeSchema.sections,
    handleUnsubscribeValuesChange,
    handleVisit,
    unsubscribeValues,
    unsubscribeValues
  )

  const pageProps = {
    isDialogOpen,
    isUnsubscribeDisabled,
    isUnsubscribeLoading,
    onDialogClose: handleDialogClose,
    onUnsubscribe: handleUnsubscribe,
    onUnsubscribeSubmit: handleUnsubscribeSubmit,
    submitLabel: 'Sauvegarder les modifications',
    submitLabelCondensed: 'Sauvegarder',
    unsubscribeSections,
    alertTitles,
  }

  return (
    <AlertsFormPage
      context={context}
      defaultValues={defaultValues}
      isCreated={isCreated}
      isEditable
      onSubmit={handleSubmit}
      pageProps={pageProps}
    />
  )
}

AlertsEditPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default AlertsEditPage
