import dealValue from '../../../lib/dealValue'
import { setItem } from '../../../../config/itemStorage'

export const computeDealValue = (result, brokerageFees) => {
  if (!result) {
    return null
  }

  const value = dealValue(result.financing.loanAmount, brokerageFees)

  setItem('current_deal_value', value)

  return value
}

export const getValue = (source, key, defaultValue = null) => source[key] ?? defaultValue
