import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import { Check, TimesCircleBold } from '@pretto/picto'

import styled from 'styled-components'

interface NpsSnackbarProps {
  onClose: () => void
}

export const NpsSnackbar: React.FC<NpsSnackbarProps> = ({ onClose, ...props }) => (
  <Banner picto={Check} type="success" {...props}>
    <Content>
      <span>Votre avis a été pris en compte</span>
      <Close onClick={onClose} />
    </Content>
  </Banner>
)

const Content = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Close = styled(TimesCircleBold)`
  cursor: pointer;
`
