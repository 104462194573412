import DashboardCardAlert from '@pretto/bricks/app/dashboard/components/DashboardCardAlert'
import TimelineProgressBar from '@pretto/bricks/app/simulator/components/TimelineProgressBar'
import Button from '@pretto/bricks/components/buttons/Button'
import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { BlockInfo } from '@pretto/zen/main/blockInfos/BlockInfo/BlockInfo'

import styled from 'styled-components'

export const Card = styled.div`
  padding: ${g(3)} ${g(2.5)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    padding: ${g(4)};
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'column-reverse' : 'column')};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    flex-direction: row;
    align-items: center;
  }
`

export const FolderTitle = styled(BaseTypo).attrs({ typo: 'heading32' })`
  margin-bottom: ${g(1)};
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-right: ${g(2)};
    margin-bottom: 0;
  }
`

export const Progress = styled.div`
  flex-grow: 1;
  max-width: ${g(34)};
  display: flex;
  align-items: center;
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: auto;
    margin-bottom: 0;
  }
`

export const Bar = styled(TimelineProgressBar).attrs({ color: 'primary2' })`
  margin-left: ${g(1)};
  height: ${g(1)};
`

export const Description = styled(BaseTypo)`
  margin-top: ${g(1)};
  max-width: ${g(84)};
`

export const CTA = styled(Button)`
  width: 100%;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    width: auto;
  }
`

export const Certificate = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  margin-top: ${g(3)};
`

export const CertificateText = styled.div`
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
    padding-right: 0;
  }
`

export const CertificateTitle = styled(BaseTypo).attrs({ typo: 'headline16' })`
  margin-bottom: ${g(2)};
`

export const Image = styled(Illustration).attrs(({ $path }) => ({ options: { width: 115, height: 115 }, path: $path }))`
  align-self: center;
  flex: 1 1 auto;
  margin: 0 ${g(6)};
`

export const TagLabel = styled(BaseTypo).attrs({ typo: 'bodyMedium' })`
  background-color: ${({ theme }) => theme.colors.primary4};
  color: ${({ theme }) => theme.colors.primary1};
  padding: ${g(1)} ${g(2)};
  border-radius: ${g(0.5)};
  margin-bottom: ${g(1)};
  align-self: flex-start;

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: auto;
    margin-bottom: 0;
  }
`

export const Block = styled(BlockInfo)`
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`

export const AlertMessage = styled(DashboardCardAlert)`
  margin-top: ${g(3)};
`
