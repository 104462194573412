import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import ButtonFeatureComponent from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const Action = styled.div`
  text-align: center;
  padding-top: ${g(4)};
`

export const AppIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.legacy.colors.primary1.default};
  border-radius: ${g(1)};
  display: flex;
  margin-right: ${g(1)};
  padding-left: ${g(1 / 2)};
  padding-right: ${g(1 / 2)};
  height: ${g(5)};
  width: ${g(5)};

  img {
    display: block;
    width: 100%;
  }
`

export const Block = styled.div`
  margin: 0 ${g(-2)};
  position: relative;

  > a {
    outline: 0px transparent;
  }

  & + & {
    margin-top: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`

export const Column = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  padding-left: ${g(1)};
  padding-right: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: ${(100 / 3) * 2}%;
    max-width: ${(100 / 3) * 2}%;
  }
`

export const ColumnSmall = styled(Column)`
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: ${g(2)};
  padding-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: ${100 / 3}%;
    max-width: ${100 / 3}%;
    padding-bottom: 0;
    padding-top: 0;
  }
`

export const DownloadBannerContent = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: ${g(1)};
  padding-top: ${g(1)};
`

export const DownloadBannerIcon = styled.div`
  margin-left: auto;
`

export const Email = styled.div`
  margin-bottom: ${g(1)};
`

export const Field = styled.div``

export const FieldContent = styled.div`
  ${Field} + ${Field} & {
    padding-top: ${g(2)};
  }
`

export const Flag = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, $type }) => ($type === 'warning' ? theme.colors.warning1 : theme.colors.error1)};

  a,
  button {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    text-align: inherit;
    text-decoration: underline;

    &:focus {
      outline: 1px dashed currentColor;
    }
  }
`

export const FlagBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${g(2)};
`

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  border-top: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20).default};
  bottom: 0;
  padding: ${g(2)};
  position: sticky;
  z-index: 10;
`

export const Button = styled(ButtonComponent)`
  width: 100%;
`

export const Header = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
    padding: ${g(4)} 0;
  }
`

export const InlineAvatar = styled.div`
  & + & {
    margin-left: ${g(-1)};
  }
`

export const InlineLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.legacy.colors.accent1.default};
    text-decoration: underline;

    &:focus {
      outline: 1px dashed ${({ theme }) => theme.legacy.colors.accent1.default};
    }
  }
`

export const MoreAction = styled.div`
  margin-top: ${g(2)};
`

export const MoreDescription = styled.div`
  margin-bottom: ${g(2)};
`

export const MoreFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MoreTitle = styled.div`
  margin-bottom: ${g(1)};
  padding-right: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${g(5)};
  }
`

export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    margin-bottom: ${g(10)};
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${g(-1)};
  margin-right: ${g(-1)};
`

export const SectionFooter = styled.footer`
  margin-top: ${g(2)};
`

export const SectionHeader = styled.header`
  margin-bottom: ${g(3)};
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }
`

export const Icon = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  margin-right: ${g(2)};
`

export const StoreBackground = styled.div`
  background-image: url('${getCloudinaryUrl('v1594303337/bricks/static-assets/decorations/angle-top-left.svg')}'),
    url('${getCloudinaryUrl('v1594303337/bricks/static-assets/decorations/angle-bottom-right.svg')}');
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: 40% auto, 50% 100%;
`

export const StoreBadge = styled.div`
  height: ${g(5)};
  margin-bottom: ${g(1)};
  margin-right: ${g(2)};

  img {
    display: block;
    height: 100%;
  }
`

export const StoreBadges = styled(MoreFooter)`
  margin-bottom: ${g(-1)};
  margin-right: ${g(-2)};
`

export const SubBlock = styled.div`
  & + & {
    margin-top: ${g(2)};
  }
`

export const SubTitle = styled.div`
  margin-bottom: ${g(3)};
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const Heading = styled(BaseTypo).attrs({ typo: 'heading32' })``

export const Title = styled.div`
  margin-bottom: ${g(1)};
`

export const UnsubscribeContent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

export const UnsubscribeDialogAction = styled.div`
  & + & {
    margin-top: ${g(2)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-left: ${g(3)};
      margin-top: 0;
    }
  }
`

export const UnsubscribeDialogActions = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const UnsubscribeDialogDescription = styled.div`
  margin-bottom: ${g(3)};
`

export const UnsubscribeDialogForm = styled.div`
  margin-bottom: ${g(4)};
`

export const UnsubscribeTitle = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const DeleteAlertContainer = styled(Action)`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(1)};
`

export const ButtonFeature = styled(ButtonFeatureComponent)`
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
`
