import { Trustpilot } from '@pretto/picto'

import range from 'lodash/range'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useTheme } from 'styled-components'

import * as S from './styles'

const NUMBER_OF_STARS = 5

const TrustpilotStars = ({ score, size, ...props }) => {
  const theme = useTheme()

  return (
    <S.Stars {...props}>
      {[S.StarsIdle, S.StarsActive].map((Component, index) => (
        <Component {...props} key={index} $score={score} $size={size}>
          {range(NUMBER_OF_STARS).map(index => (
            <S.Star $size={size} key={index} viewBox="0 0 16 16">
              <rect fill={theme.colors.white} x={2} y={2} width={12} height={12} />
              <Trustpilot />
            </S.Star>
          ))}
        </Component>
      ))}
    </S.Stars>
  )
}

TrustpilotStars.defaultProps = {
  size: 'medium',
}

TrustpilotStars.propTypes = {
  /** Trustpilot score. */
  score: PropTypes.number.isRequired,
  /** Rendered size. */
  size: PropTypes.oneOf(['medium', 'large', 'small']),
}

export default memo(TrustpilotStars)
