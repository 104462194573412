import { breakpoints, g } from '@pretto/bricks/components/layout'

import { CheckBold } from '@pretto/picto'

import React from 'react'
import styled from 'styled-components'

interface ListItem {
  key: string
  message: React.ReactNode
}

interface ListProps {
  items: ListItem[]
  title: string
  type: 'check' | 'index'
}

const Bullet = styled.div`
  ${({ theme }) => theme.typos.heading6};
  border-radius: ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral5};
  width: ${g(4)};
  height: ${g(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(3)};
  }
`

const Message = styled.p`
  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(1 / 2)};
  }

  & > strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`

const Steps = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(1)};
  }
`

const Step = styled.div`
  align-items: center;
  display: flex;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    align-items: flex-start;
  }
`

const Title = styled.h3`
  ${({ theme }) => theme.typos.heading5};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading4};
  }
`

export const List: React.FC<ListProps & React.HTMLAttributes<HTMLDivElement>> = ({ items, title, type, ...props }) => (
  <Container {...props}>
    <Title>{title}</Title>

    <Steps>
      {items.map(({ message, key }, index) => (
        <Step key={key}>
          <Bullet>
            {type === 'check' && <CheckBold />}
            {type === 'index' && index + 1}
          </Bullet>

          <Message>{message}</Message>
        </Step>
      ))}
    </Steps>
  </Container>
)
