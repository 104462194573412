import PreLeadPlaceholderCard from '@pretto/bricks/app/pre-lead/components/PreLeadPlaceholderCard'
import SectionProjectEmpty from '@pretto/bricks/app/pre-lead/components/SectionProjectEmpty'
import SectionProjectError from '@pretto/bricks/app/pre-lead/components/SectionProjectError'
import SectionRenegotiation from '@pretto/bricks/app/pre-lead/components/SectionRenegotiation'

import { CARDS } from '@pretto/app/src/PreLead/Containers/ProjectCards/config/cards'

import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useContext } from 'react'

import { useUser } from '../../../User/Containers/UserProvider'
import { PreLeadContext } from '../PreLeadPage'

const CARD_KIND = 'renegotiation'

const QUERY = gql`
  query PreLeadRenegotiationCard {
    simulation: best_result {
      financing {
        loanDuration
        mainLoansRate
        payment
        savings
      }
      project {
        renegotiation {
          facts {
            payment
          }
          estimated_value
          remaining_principal
          rate
        }
      }
    }
  }
`
const RenegotiationCard = ({ data }) => {
  const { onTrackProjectCard } = useContext(PreLeadContext)
  const { hasFeatureAccess } = useUser()

  if (!data.simulation) {
    const handleClickRenegotiationError = () => {
      onTrackProjectCard(CARD_KIND, 'error')
    }

    const props = {
      href: '/project/renegotiation/introduction',
      onEdit: handleClickRenegotiationError,
      seeMoreHref: '/simulation',
      text: `Aucune offre de financement ne correspond à votre dernière simulation. Nous n'avons pas trouvé de meilleur crédit ou le montant qu'il vous reste à rembourser est trop faible.`,
      type: 'renegotiation',
    }

    return <SectionProjectError {...props} />
  }

  const {
    financing: { loanDuration, mainLoansRate, payment, savings },
    project: {
      renegotiation: {
        facts: { payment: currentPayment },
        rate: currentRate,
        estimated_value: estimatedValue,
        remaining_principal: remainingPrincipal,
      },
    },
  } = data.simulation

  const handleClickRenegotiation = () => {
    onTrackProjectCard(CARD_KIND, 'success')
  }

  const props = {
    currentPayment,
    currentRate,
    duration: loanDuration / 12,
    estimatedValue,
    href: '/project/renegotiation/introduction',
    isAppointmentAvailable: !hasFeatureAccess(['NO_APPOINTMENT']),
    onEdit: handleClickRenegotiation,
    payment,
    rate: mainLoansRate,
    remainingPrincipal,
    savings,
  }

  return <SectionRenegotiation {...props} />
}

RenegotiationCard.propTypes = {
  data: PropTypes.shape({
    simulation: PropTypes.object,
  }),
}

const useRenegotiationProjectCard = props => {
  const { data, loading } = useQuery(QUERY)

  const { isProjectAvailable, project } = useUser()
  const { onTrackProjectCard } = useContext(PreLeadContext)

  if (isProjectAvailable && project?.project_kind === 'renegotiation') {
    if (loading) {
      return {
        component: PreLeadPlaceholderCard,
      }
    }

    const renegotiationCardProps = {
      ...props,
      data,
    }

    return {
      component: RenegotiationCard,
      data,
      isLoaded: true,
      props: renegotiationCardProps,
    }
  }

  const handleClickRenegotiation = () => {
    onTrackProjectCard(CARD_KIND, 'default')
  }

  const type = 'renegotiation'

  return {
    component: SectionProjectEmpty,
    isLoaded: true,
    props: {
      ...props,
      cards: CARDS[type],
      href: '/project/renegotiation/introduction',
      onEdit: handleClickRenegotiation,
      type,
    },
  }
}

export default useRenegotiationProjectCard
