import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const ListContainer = styled.ul``

export const Tip = styled.li`
  display: flex;

  & + & {
    margin-top: ${g(4)};
  }
`

export const Picto = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  flex-shrink: 0;
  margin-right: ${g(2)};
`

export const Text = styled.div``
