import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { Layout } from '@pretto/app/src/onboarding/components/Layout/Layout'

import styled from 'styled-components'

interface FullLayoutProps {
  children: [React.ReactNode, React.ReactNode]
  type?: 'loose' | 'tight'
}

export const FullLayout: React.FC<FullLayoutProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  type = 'tight',
}) => (
  <Container>
    <Content $type={type}>{children[0]}</Content>

    {children[1]}
  </Container>
)

const Container = styled(Layout)`
  ${grid(4)};
  background-color: ${({ theme }) => theme.colors.primary1};
  background-image: url('${getCloudinaryUrl(
    'v1654775307/zen/static-assets/illustrations/scenery/city-wall-lineal-primary2.svg',
    { width: 736, height: 192 }
  )}');
  background-size: 736px 192px;
  background-repeat: no-repeat;
  background-position: center bottom;
  grid-template-rows: 1fr auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid(6, { isLeftAligned: true, isRightAligned: true })};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

interface ContentProps {
  $type: NonNullable<FullLayoutProps['type']>
}

export const Content = styled.div<ContentProps>`
  ${column([2, 4])};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 6])};
    padding: ${g(10)} ${g(7)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 10])};
    padding: ${({ $type }) => g($type === 'loose' ? 10 : 3)} ${g(7)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${({ $type }) => g($type === 'loose' ? 10 : 3)} ${g(32)} ${g(3)};
  }
`
