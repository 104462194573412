import { PartnerBlockProps } from '@pretto/zen/dashboard/uploadModal/PartnerBlock/PartnerBlock'

interface Props extends Omit<PartnerBlockProps, 'onCTAClick'> {
  name: string
}
export const getPartnerProps = (title: string): Props | null => {
  switch (title) {
    case 'Estimation du bien':
      return {
        name: 'Hosman',
        cta: {
          label: 'Obtenir une estimation Hosman',
          labelMobile: 'Estimation par Hosman',
          href: 'https://www.hosman.co/estimation/estimation-kind?utm_source=pretto&utm_medium=referral',
        },
        logo: 'v1652103482/zen/static-assets/logos/partners/hosman.png',
        description: (
          <>
            Pretto vous recommande <strong>Hosman</strong>, l’agence immobilière innovante, pour obtenir une estimation
            de la valeur de votre bien grâce à leur expertise du marché et leur data immobilière.
          </>
        ),
        rtb: ['Estimation gratuite en 48h', 'Analyse de prix ultra-détaillée', 'Experts locaux'],
      }
    default:
      return null
  }
}
