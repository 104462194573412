import DashboardSecondaryCard from '@pretto/bricks/app/dashboard/components/DashboardSecondaryCard'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Card from '@pretto/bricks/components/cards/Card'
import Avatar from '@pretto/bricks/components/images/Avatar'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import { Calendar, Camera, ChevronRightBold } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const DashboardBookingCard = ({
  advisorAvatarOnClick,
  advisorAvatarSrc,
  advisorName,
  advisorRole,
  date,
  duration,
  isNow,
  isVisio,
  isVisioEnabled,
  timezone,
  visioLabel,
  visioUrl,
  ...props
}) => {
  const handleAvatarClick = event => {
    event.preventDefault()
    event.stopPropagation()

    advisorAvatarOnClick()
  }

  const handleClickVisio = event => {
    event.stopPropagation()
  }

  const expertOrSdr = advisorRole === 'sdr' ? 'conseiller' : 'expert'

  const description = isNow
    ? `Votre rendez-vous a debuté, rejoignez votre ${expertOrSdr}.`
    : date
    ? `Vous avez un rendez-vous programmé avec votre ${expertOrSdr}.`
    : 'Affinez votre projet et suivez son avancement.'

  return (
    <DashboardSecondaryCard
      description={description}
      icon={ChevronRightBold}
      isInProgress={isNow}
      title={date ? 'Votre rendez-vous' : 'Prenez rendez-vous'}
      {...props}
    >
      <Content>
        <S.Summary>
          <Card format="line" variant="neutral-1-20">
            <Content>
              <S.Title>
                <S.TitleIcon>{isVisioEnabled && isVisio ? <Camera /> : <Calendar />}</S.TitleIcon>

                {date && (
                  <S.Row>
                    <span>{isNow ? 'Rendez-vous du :' : 'Prochain rendez-vous :'}</span>{' '}
                    <SubHeading size={'small'}>{`${date}${timezone ? ' (heure locale)' : ''}`}</SubHeading>
                  </S.Row>
                )}
                {!date && <SubHeading size={'small'}>Votre {expertOrSdr} est à l'écoute</SubHeading>}
              </S.Title>

              <S.Details>
                <S.DetailAvatar>
                  <S.AdvisorAvatar>
                    <Avatar onClick={handleAvatarClick} size="x-small" src={advisorAvatarSrc} />
                  </S.AdvisorAvatar>

                  <div>
                    <Text size="small" variant="neutral-1-60">
                      {expertOrSdr}
                    </Text>

                    {isVisioEnabled ? (
                      <div>
                        <strong>Par {isVisio ? 'visio' : 'téléphone'}</strong> avec {advisorName}
                      </div>
                    ) : (
                      <div>Par téléphone avec {advisorName}</div>
                    )}
                  </div>
                </S.DetailAvatar>

                <S.DetailDuration>
                  <Text size="small" variant="neutral-1-60">
                    Durée
                  </Text>

                  <div>{duration}</div>
                </S.DetailDuration>

                {isVisioEnabled && isVisio && (
                  <S.VisioDetails>
                    Lien de votre rendez-vous visio :{' '}
                    <S.VisioLink onClick={handleClickVisio} href={visioUrl}>
                      {visioLabel}
                    </S.VisioLink>
                  </S.VisioDetails>
                )}
              </S.Details>
            </Content>
          </Card>
        </S.Summary>

        {!isNow && <ButtonInline>{'Modifier'}</ButtonInline>}
      </Content>
    </DashboardSecondaryCard>
  )
}

DashboardBookingCard.propTypes = {
  /** Event triggered whenever the advisor avatar is being pressed. */
  advisorAvatarOnClick: PropTypes.func.isRequired,
  /** Avatar source of the advisor. */
  advisorAvatarSrc: PropTypes.string.isRequired,
  /** Fullname of the advisor. */
  advisorName: PropTypes.string.isRequired,
  /** Role of the advisor. */
  advisorRole: PropTypes.oneOf(['expert', 'sdr']).isRequired,
  /** Date of the booking appointment */
  date: PropTypes.string.isRequired,
  /** Duration of the bookappointment if it does exist. Defaults to duration for status. */
  duration: PropTypes.string.isRequired,
  /** Whether the appointment is now. */
  isNow: PropTypes.bool,
  /** Whether displayed infos are partial or complete */
  isRestrictedInfos: PropTypes.bool,
  /** Whether visio is use to replace phone. */
  isVisio: PropTypes.bool,
  /** Whether visio conference support is enabled. */
  isVisioEnabled: PropTypes.bool,
  /** Visio link label. */
  visioLabel: PropTypes.string,
  /** Visio link specific for advisor. */
  visioUrl: PropTypes.string,
  /** For when a timezone has to be mentioned. */
  timezone: PropTypes.string,
}

export default DashboardBookingCard
