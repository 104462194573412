import PropTypes from 'prop-types'

import * as S from './styles'

const Divider = ({ isLarge, opacity, variant, ...props }) => (
  <S.Divider {...props} alpha={opacity} isLarge={isLarge} variant={variant} />
)

Divider.defaultProps = {
  isLarge: false,
  opacity: '1',
  variant: 'neutral-1-20',
}

Divider.propTypes = {
  /** Whether or not the divider is large. */
  isLarge: PropTypes.bool,
  /** Divider opacity. */
  opacity: PropTypes.string,
  /** Divider color. */
  variant: PropTypes.string,
}

export default Divider
