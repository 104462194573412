import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Times } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const DialogSave = ({ emailFieldComponent, isSaveOpen, onCguClick, onCloseSave, onGoogleConnect, onPrivacyClick }) => (
  <Dialog isOpen={isSaveOpen}>
    <S.DialogContainer>
      <S.Header>
        <S.Title>Sauvegardez votre simulation !</S.Title>
        <Times onClick={onCloseSave} />
      </S.Header>
      <S.GoogleConnect onClick={onGoogleConnect} />
      <S.Divider>— ou —</S.Divider>
      {emailFieldComponent}
      <S.CGU>
        En continuant, vous acceptez nos{' '}
        <S.CGULink href="https://www.pretto.fr/cgu/" onClick={onCguClick}>
          conditions d'utilisation
        </S.CGULink>{' '}
        et notre{' '}
        <S.CGULink href="/privacy" onClick={onPrivacyClick} target="blank">
          politique de confidentialité.
        </S.CGULink>{' '}
      </S.CGU>
    </S.DialogContainer>
  </Dialog>
)

DialogSave.propTypes = {
  emailFieldComponent: PropTypes.node,
  isSaveOpen: PropTypes.bool,
  onCguClick: PropTypes.func,
  /** onClick function triggered when cross icon is clicked. */
  onCloseSave: PropTypes.func,
  onGoogleConnect: PropTypes.func,
  /** Validating the dialog simulaiton. */
  onPrivacyClick: PropTypes.func,
}
export default DialogSave
