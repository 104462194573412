import { g } from '@pretto/bricks/components/layout'

import {
  TimelineStep as TimelineStepComponent,
  TimelineStepProps,
} from '@pretto/zen/reveal/simulator/timeline/TimelineStep/TimelineStep'

import styled from 'styled-components'

export type TimelineStep = Pick<TimelineStepProps, 'label' | 'href' | 'onLabelClick' | 'type'>

export interface TimelineSection {
  steps?: TimelineStep[]
  title?: string
}

export interface TimelineProps {
  sections: TimelineSection[]
  onSectionClick?: () => void
}

export const Timeline: React.FC<TimelineProps & React.HTMLAttributes<HTMLDivElement>> = ({
  sections,
  onSectionClick,
  ...props
}) => (
  <nav role="menu" {...props}>
    {sections.map(({ steps = [], title }) => (
      <ul key={title} role="presentation">
        {title && (
          <Title $isInactive={steps.every(step => step.type === 'inactive')}>
            <p>{title}</p>
          </Title>
        )}

        {steps.map(({ label, onLabelClick, ...props }, index) => {
          const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (onLabelClick) {
              onLabelClick(e)
            }
            if (onSectionClick) {
              onSectionClick()
            }
          }

          return (
            <TimelineStepComponent
              {...props}
              onLabelClick={handleClick}
              key={label}
              label={label}
              nextTimelineStepType={steps[index + 1]?.type}
              position={
                steps.length === 1 ? 'orphan' : index === 0 ? 'first' : index === steps.length - 1 ? 'last' : 'middle'
              }
              previousTimelineStepType={steps[index - 1]?.type}
            />
          )
        })}
      </ul>
    ))}
  </nav>
)

interface TitleProps {
  $isInactive: boolean
}

const Title = styled.li<TitleProps>`
  ${({ theme }) => theme.typos.heading5};
  color: ${({ $isInactive, theme }) => ($isInactive ? theme.colors.neutral2 : theme.colors.neutral1)};
  padding: ${g(1)} 0;
`
