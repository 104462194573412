import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { DataContext, ROUND_TO } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import { useTracking } from '@pretto/app/src/lib/tracking'
import type { Control } from '@pretto/app/src/types/Capacity'
import { KeypressEventHandler } from '@pretto/app/src/types/Events'

import React, { useContext, useState } from 'react'

import { ControlsContext } from './ControlsContext'

const MONTHS_PER_YEAR = 12

const round = (value: number) => roundNumber(value, ROUND_TO, Math.floor)

interface ControlsProviderProps {
  children: React.ReactElement
}

export const ControlsProvider: React.FC<ControlsProviderProps> = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const {
    data,
    payment,
    contribution,
    maxPayment,
    selectedLoanDuration,
    refreshCapacity,
    handleChangePayment,
    handleChangeContribution,
    handleChangeDuration,
  } = useContext(DataContext)

  const trackAction = useTracking()

  const handleOpenDialog = () => {
    trackAction('CAPACITY_RESULT_MODIFY_BUTTON_CLICKED')
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => setIsDialogOpen(false)

  const handleAfterSlide = () => {
    refreshCapacity('monthly_payment')
  }

  const handleValidateContribution = () => {
    refreshCapacity('contribution')
  }

  const handleKeyPress: KeypressEventHandler = e => {
    if (e.key === 'Enter') {
      handleValidateContribution()
    }
  }

  const contributionProps = {
    onBlur: handleValidateContribution,
    onChange: handleChangeContribution,
    onKeyPress: handleKeyPress,
    value: contribution,
  }

  const contributionRow = [
    {
      label: 'Votre apport',
      numberFieldProps: contributionProps,
    },
  ]

  const controls: Control[] = [
    {
      format: 'currency',
      label: 'Mensualité',
      props: {
        max: round(maxPayment),
        min: 200,
        onAfterChange: handleAfterSlide,
        onChange: handleChangePayment,
        step: 25,
        value: payment,
        variant: 'primary-1',
      },
      type: 'slider',
    },
  ]

  if (data?.capacity?.length) {
    controls.unshift({
      format: 'duration',
      label: 'Durée d’emprunt',
      props: {
        onChange: handleChangeDuration,
        options: data.capacity.map(({ financing: { loanDuration } }: { financing: { loanDuration: number } }) => ({
          label: `${+loanDuration / MONTHS_PER_YEAR} ans`,
          value: loanDuration,
        })),
        value: selectedLoanDuration || 0,
      },
      type: 'segmentedControl',
    })
  }

  const context = {
    isDialogOpen,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
    controls,
    contributionRow,
    onKeyPressValidation: handleKeyPress,
  }

  return <ControlsContext.Provider value={context}>{children}</ControlsContext.Provider>
}
