import type { FieldProps } from '@pretto/zen/reveal/types/Field'

import BANKS from '@pretto/config/banks.json'

import fuzzysort from 'fuzzysort'
import latinize from 'latinize'
import { useState } from 'react'

export type Bank = (typeof BANKS)[number]

type UseBankField = (defaultValue: string) => {
  fieldProps: FieldProps
  results: Bank[]
}

const targets = BANKS.map(({ label }) => fuzzysort.prepare(latinize(label)))

export const useBankField: UseBankField = defaultValue => {
  const [searchValue, setSearchValue] = useState(defaultValue || '')

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const results =
    searchValue === ''
      ? []
      : (fuzzysort
          .go(latinize(searchValue), targets)
          .map(({ target }) => BANKS.find(({ label }) => latinize(label) === target)) as Bank[])

  return {
    fieldProps: {
      onChange: handleChange,
      value: searchValue,
    },
    results,
  }
}
