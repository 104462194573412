import { ButtonProps } from '@pretto/zen/atoms/buttons/Button/Button'

import * as S from './BlockInfo.styles'

export interface BlockInfoProps {
  title: string
  content: React.ReactNode
  image?: string
  buttonProps?: ButtonProps
}

export const BlockInfo = ({ title, content, image, buttonProps, ...props }: BlockInfoProps) => (
  <S.Container {...props}>
    <div>
      <S.Title>{title}</S.Title>

      <div>{content}</div>

      {!!buttonProps && <S.Cta {...buttonProps} isSecondary />}
    </div>

    {!!image && <S.Illu path={image} />}
  </S.Container>
)
