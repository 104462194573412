import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled from 'styled-components'

export interface FileIconProps {
  extension: string
  isActive?: boolean
}

export const FileIcon: React.FC<FileIconProps & React.HTMLAttributes<HTMLSpanElement>> = ({
  extension,
  isActive = false,
  ...props
}) => (
  <Icon {...props} $isActive={isActive}>
    {extension}
  </Icon>
)

interface IconProp {
  $isActive: boolean
}

const Icon = styled.span<IconProp>`
  ${({ theme }) => theme.typos.caption};
  ${transition({ propertyName: 'background-color' }, { propertyName: 'color' })}
  align-items: center;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.neutral4 : theme.colors.neutral5)};
  border-radius: ${g(1)};
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.neutral1 : theme.colors.neutral2)};
  display: inline-flex;
  height: ${g(6)};
  justify-content: center;
  text-transform: uppercase;
  width: ${g(6)};
`
