import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const ContainerProgress = styled.div`
  display: flex;
  align-items: center;
`

export const Timeline = styled.div`
  width: 100%;
  margin-right: ${g(2)};
`
