import Button from '@pretto/bricks/components/buttons/Button'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Icon from '@pretto/bricks/components/iconography/Icon'
import ProgressLegacy from '@pretto/bricks/components/information/ProgressLegacy'
import Text from '@pretto/bricks/components/typography/Text'

import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { createElement, memo } from 'react'

import * as S from './styles'

const PERCENT_FACTOR = 100

const DashboardCardFooter = ({ actionLabel, isPrimary, progressValue, ...props }) => (
  <S.Footer {...props}>
    {actionLabel && createElement(Button, { isSecondary: !isPrimary }, actionLabel)}

    {!isNil(progressValue) && (
      <S.Progress>
        {progressValue === PERCENT_FACTOR && (
          <S.Icon>
            <Icon name="check-2" variant="primary-1" />
          </S.Icon>
        )}
        <Text size="small" variant={progressValue === PERCENT_FACTOR ? 'primary-1' : 'inherit'}>
          {createElement(progressValue === PERCENT_FACTOR ? 'strong' : 'span', null, `${progressValue} %`)}
        </Text>
        <S.Bar>
          <ProgressLegacy format="rounded" max={PERCENT_FACTOR} value={progressValue} variant="primary-1" />
        </S.Bar>
      </S.Progress>
    )}
  </S.Footer>
)

DashboardCardFooter.defaultProps = {
  isPrimary: true,
}

DashboardCardFooter.propTypes = {
  /** Label of the primary action button. */
  actionLabel: PropTypes.string,
  /** Defines the button component. True for ButtonLegacy, false for ButtonSquared. */
  isPrimary: PropTypes.bool,
  /** Value of the progress. Defines whether or not the progress bar should be displayed. */
  progressValue: PropTypes.number,
}

export default memo(DashboardCardFooter)
