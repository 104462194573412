import { getAttributionCookieFirstClickValue } from '@pretto/app-core/lib/attributionCookie'

import { getType } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/getType'
import * as types from '@pretto/app/src/Simulation/lib/types'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { BIG_SIMULATION, UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { getItem, setItem } from '@pretto/app/src/config/itemStorage'
import { MINIMUM_LOADING_DURATION } from '@pretto/app/src/config/simulation'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useApolloClient } from '@apollo/client'
import set from 'lodash/set'
import PropTypes from 'prop-types'
import { createContext, useEffect, useRef, useState } from 'react'

export const SimulationContext = createContext()

export const SimulationProvider = ({ children }) => {
  const client = useApolloClient()

  const [projectChanges, setProjectChanges] = useState({})
  const [data, setData] = useState(null)
  const [initialData, setInitialData] = useState(null)
  const [type, setType] = useState(getItem(types.SIMULATION_STATE_TYPE))
  const [isLoading, setIsLoading] = useState(true)
  const [isInteractive, setIsInteractive] = useState(false)

  const { isProjectEditable = true, typology } = useUser()

  const timeToInteractionTimeout = useRef(null)
  const shouldSkipNextSimulation = useRef(false)
  const simulationMedium = useRef('page_load')

  const trackAction = useTracking()

  useEffect(() => {
    if (!isProjectEditable) {
      return
    }

    simulate(projectChanges)
  }, [projectChanges])

  useEffect(() => {
    if (!type) {
      return
    }
    setItem(types.SIMULATION_STATE_TYPE, type)
  }, [type])

  const trackSimulationRequest = ({ good: { usage }, project_kind: projectKind }, dealValue = null) => {
    const trackingOptions = {
      simulation_results_attribution: getAttributionCookieFirstClickValue(),
      simulation_results_kind: projectKind,
      simulation_results_medium: simulationMedium.current,
      simulation_results_usage: usage,
      simulation_results_value: dealValue,
    }

    trackAction('SIMULATION_RESULTS_REQUESTED', trackingOptions)
  }

  const load = () => {
    setIsInteractive(false)
    setIsLoading(true)

    clearTimeout(timeToInteractionTimeout.current)

    timeToInteractionTimeout.current = setTimeout(() => {
      setIsInteractive(true)
    }, MINIMUM_LOADING_DURATION)
  }

  const handleSimulationMedium = medium => {
    simulationMedium.current = medium
  }

  const persistChanges = async ({ request, ...projectChanges }) => {
    const variables = { project: JSON.stringify(projectChanges) }

    await client.mutate({
      mutation: UPDATE_PROJECT,
      variables,
    })
  }

  const simulate = async projectChanges => {
    if (shouldSkipNextSimulation.current) {
      shouldSkipNextSimulation.current = false
      return
    }

    load()

    if (typology !== 'client') {
      await persistChanges(projectChanges)
    }

    const variables = { override: JSON.stringify(projectChanges) }

    const {
      data: {
        big_simulation: { data, __typename },
      },
    } = await client.query({ query: BIG_SIMULATION, fetchPolicy: 'no-cache', variables })

    const { project } = data
    trackSimulationRequest(project)

    if (__typename !== types.SIMULATION) {
      setData(data)
      setType(__typename)
      setIsLoading(false)
      return
    }
    const { typeOfLead, typePage } = await getType(typology)

    if (typeOfLead) {
      trackAction('POTENTIAL_TYPE_OF_LEAD_ZERO_MINUTE', {
        type: typeOfLead,
      })
    }

    setData(data)
    setInitialData(initialData => initialData ?? data)
    setType(typePage)
    setIsLoading(false)
  }

  const handleDurationChange = (duration, callback = () => {}) => {
    shouldSkipNextSimulation.current = true
    setProjectChanges(projectChanges => ({ ...projectChanges, request: { duration } }))
    callback(duration)
  }

  const handleProjectChange = changes => {
    setProjectChanges(projectChanges =>
      Object.entries(changes).reduce((previous, [key, value]) => set(previous, key, value), { ...projectChanges })
    )
  }

  const handleOptimizeChoose = ({ contribution, duration }) => {
    setProjectChanges(projectChanges => ({ ...projectChanges, contribution, request: { duration } }))
  }

  const resetProjectChanges = () => {
    setProjectChanges({})
  }

  const handleProjectChanges = changes => {
    setProjectChanges(projectChanges => ({ ...projectChanges, ...changes }))
  }

  const handleProjectChangesWithPurchase = newPurchase => {
    const {
      project: { purchase: currentPurchase },
    } = data

    setProjectChanges(({ purchase = {}, ...currentProjectChanges }) => ({
      ...currentProjectChanges,
      purchase: Object.entries(newPurchase).reduce((previous, [key, newValue]) => {
        const currentValue = currentPurchase[key]

        if (newValue === null || currentValue === newValue) {
          return previous
        }

        return { ...previous, [key]: newValue }
      }, purchase),
    }))
  }

  const goToResults = () => {
    setType(types.SIMULATION)
  }

  const value = {
    projectChanges,
    data,
    type,
    isLoading,
    isInteractive,
    initialData,
    onSimulationMedium: handleSimulationMedium,
    simulationMedium,
    onTrackSimulationRequest: trackSimulationRequest,
    resetProjectChanges,
    resultsPage: {
      onDurationChange: handleDurationChange,
      onProjectChange: handleProjectChange,
      goToResults,
    },
    optionsPage: {
      onProjectChanges: handleProjectChanges,
      onProjectChangesWithPurchase: handleProjectChangesWithPurchase,
    },
    optimizePage: {
      onOptimizeChoose: handleOptimizeChoose,
    },
  }

  return <SimulationContext.Provider value={value}>{children}</SimulationContext.Provider>
}

SimulationProvider.propTypes = {
  children: PropTypes.node,
}
