/* globals analytics */
import { handleAttributionCookie } from '@pretto/app-core/lib/attributionCookie'

import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getVisitorUUID } from '../../../Auth/lib/helpers'
import Front from '../../../Helpers/frontchat'
import { isEnglishUser } from '../../../User/lib/i18n'
import { analyticsDefaultPayload } from '../../config/config'
import { getNetlifyAB } from '../../lib/netlify'
import { UserProvider } from '../UserProvider'

const TYPOLOGY = 'prospect'

export const UserProviderProspect = ({ children }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const projectID = getVisitorUUID()

    analytics.identify({
      ...analyticsDefaultPayload,
      connected: false,
      displayLang: isEnglishUser(i18n.language) ? 'en' : 'fr',
      project_id: projectID,
    })

    const netlifyAB = getNetlifyAB()

    Sentry.setUser({
      id: projectID,
      segment: 'Visitor',
      ...netlifyAB,
    })

    Front('update', { customFields: { ...netlifyAB, project_id: projectID }, email: projectID })

    handleAttributionCookie({ isClient: false })
  }, [])

  const hasFeatureAccess = () => false

  const projectID = getVisitorUUID()

  const value = {
    hasFeatureAccess,
    isEnglishUser: isEnglishUser(i18n.language),
    projectID,
    typology: TYPOLOGY,
  }

  return <UserProvider value={value}>{children}</UserProvider>
}

UserProviderProspect.propTypes = {
  children: PropTypes.node.isRequired,
}
