import { random } from '@pretto/bricks/core/utility/hash'

import { Context } from '@pretto/app-core/loading/containers/context'

import { useContext, useEffect } from 'react'

export const useLoading = <T extends string>(isLoading: boolean, type?: T): boolean => {
  const { del, set } = useContext(Context)

  useEffect(() => {
    const id = random()

    set(id, isLoading, type)

    return () => {
      del(id)
    }
  }, [isLoading, type])

  return isLoading
}
