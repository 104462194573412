import PropTypes from 'prop-types'

import * as S from './styles'

const Content = ({ value, infos }) => (
  <S.Main>
    <S.MainValue>{value}</S.MainValue>
    <S.Infos>
      {infos.map(({ label, value, color }, index) => (
        <S.Info key={index}>
          <S.Label>{label} :</S.Label>
          <S.InfoValue $color={color}>{value}</S.InfoValue>
        </S.Info>
      ))}
    </S.Infos>
  </S.Main>
)

Content.propTypes = {
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
}

export default Content
