import { g } from '@pretto/bricks/components/layout'
import BaseComponent from '@pretto/bricks/components/utility/BaseComponent'
import styled, { css } from 'styled-components'

export const Text = styled(BaseComponent)`
  font-family: ${({ theme }) => theme.font}, sans-serif;
  display: inline;
  transition: all 500ms ease-in-out;
  letter-spacing: 0;

  color: ${({ theme, variant }) => theme.colors[variant] || theme.legacy.colors[variant] || 'inherit'};

  ${({ size }) => {
    switch (size) {
      case 'x-small':
        return css`
          line-height: ${g(2.5)};
          font-size: ${g(1.5)};
        `
      case 'small':
        return css`
          line-height: ${g(2.75)};
          font-size: ${g(1.75)};
        `
      case 'medium':
        return css`
          line-height: ${g(3)};
          font-size: ${g(2)};
        `
      case 'document':
        return css`
          line-height: ${g(1.75)};
          font-size: ${g(1.25)};
        `
      default:
        return css``
    }
  }}

  ${({ format }) => {
    switch (format) {
      case 'center':
        return css`
          text-align: center;
        `
      case 'bold':
        return css`
          font-weight: bold;
        `
      case 'underline':
        return css`
          text-decoration: underline;
        `
      default:
        return css``
    }
  }}
`
