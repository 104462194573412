export interface DescriptionProps {
  isSingular?: boolean
  partnerName: string
}

export const DESCRIPTION_TYPE_1: React.FC<DescriptionProps> = ({ isSingular = true, partnerName }) => (
  <>
    Vous avez un projet d’acquisition ? <strong>{partnerName}</strong> {isSingular ? 'a' : 'ont'} choisi Pretto pour
    estimer votre futur <strong>prêt</strong> !
  </>
)

export const DESCRIPTION_TYPE_2: React.FC<DescriptionProps> = ({ isSingular = true, partnerName }) => (
  <>
    Vous avez un projet d’acquisition ? <strong>{partnerName}</strong> {isSingular ? 'a' : 'ont'} choisi Pretto pour
    estimer vos futures mensualités !
  </>
)

export const DESCRIPTION_TYPE_3: React.FC<DescriptionProps> = ({ isSingular = true, partnerName }) => (
  <>
    Vous avez un projet immobilier ? <strong>{partnerName}</strong> {isSingular ? 'a' : 'ont'} choisi Pretto pour vous
    aider à obtenir <strong>le prêt le plus adapté</strong> à votre situation !
  </>
)
