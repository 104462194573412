import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { storeContext } from '@pretto/app/src/Sentences/v2/lib/localStorage'

import { useEffect } from 'react'

export const LocalStorage: React.FC = () => {
  // Context is destructured to remove setContext
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setContext, ...context } = useSentences()

  useEffect(() => {
    storeContext(context)
  }, [context])

  return null
}
