import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

export const SentencesHeader: React.FC = () => {
  const { typology } = useUser()

  const headerProps = {
    isMobileHidden: true,
    navigationItemsList: [NAV_ITEMS.faq],
    accountVariant: typology === 'preLead' ? 'onlyInitial' : null,
  }

  return <Header {...headerProps} />
}
