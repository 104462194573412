import Backdrop from '@pretto/bricks/components/overlays/Backdrop'
import Transition from '@pretto/bricks/components/utility/Transition'

import PropTypes from 'prop-types'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import * as S from './styles'

const DURATION = 500

const Drawer = forwardRef(({ children, from, hasOverlay, isOpen, onClickOutside, overlayStyle, ...props }, ref) => (
  <S.Drawer isOpen={isOpen} layout={from} {...props}>
    {hasOverlay && (
      <Transition in={isOpen} duration={DURATION} kind="drawerOverlay">
        <Backdrop onClick={onClickOutside} />
      </Transition>
    )}

    <Transition in={isOpen} duration={DURATION} kind="drawerRight">
      <S.Content ref={ref}>{children}</S.Content>
    </Transition>
  </S.Drawer>
))

Drawer.displayName = 'Drawer'

const DrawerController = ({ isOpen, onClickOutside, ...props }) => {
  const [container, setContainer] = useState(null)
  const drawerElement = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    setContainer(document.body)

    return () => document.removeEventListener('click', handleClickOutside, true)
  })

  const handleClickOutside = event => {
    if (!drawerElement.current || (!drawerElement.current.contains(event.target) && isOpen)) {
      onClickOutside()
    }
  }

  if (!container) {
    return null
  }

  return createPortal(
    <Drawer isOpen={isOpen} onClickOutside={onClickOutside} ref={drawerElement} {...props} />,
    container
  )
}

DrawerController.defaultProps = {
  from: 'left',
  hasOverlay: false,
  onClickOutside: () => {},
}

DrawerController.propTypes = {
  /** Content of Drawer. */
  children: PropTypes.node.isRequired,
  /** isOpen state for Drawer. */
  isOpen: PropTypes.bool.isRequired,
  /** Direction from which Drawer opens. */
  from: PropTypes.oneOf(['left', 'right']),
  /** Whether there is an overlay or not. */
  hasOverlay: PropTypes.bool,
  /** onClickOutside function. Useful for closing drawer by clicking outside it. */
  onClickOutside: PropTypes.func,
}

export default DrawerController
