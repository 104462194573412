import { funcToItem } from '@pretto/bricks/core/utility/formatters'

export const getFieldErrorsFromComponents = (components, values) => {
  return components.reduce((previous, component) => {
    if (!component || typeof component === 'string') {
      return previous
    }

    if (Array.isArray(component)) {
      return [...previous, ...getFieldErrorsFromComponents(component, values)]
    }

    const value = values[component.name]

    if (!component.validation || !value) {
      return previous
    }

    const test = component.validation.test?.(value)

    if (test) {
      return previous
    }

    return [
      ...previous,
      {
        data: {
          name: component.name,
          title: component.validation.title,
        },
        type: 'field',
      },
    ]
  }, [])
}

export const testComponentsCompletion = (components, values) => {
  return components.every(component => {
    if (!component || typeof component === 'string') {
      return true
    }

    if (Array.isArray(component)) {
      return testComponentsCompletion(component, values)
    }

    const value = values[component.name]

    return component.validation?.test?.(value) ?? (value !== null && value !== undefined)
  })
}

export const handleSentencesForm = ({ form, params, debouncedValues, values }) =>
  form.content.reduce(
    (previous, line) => {
      const { fieldErrors, isCompleted, isSkipped, lines } = previous

      if (!isCompleted || isSkipped) {
        return previous
      }

      const condition = funcToItem(line.condition ?? true, ...params)

      if (!condition) {
        return { ...previous, isSkipped: true }
      }

      switch (line.type) {
        case 'messages': {
          const messages = line.data.messages.map(message => funcToItem(message, ...params))

          return { ...previous, lines: [...lines, { ...line, data: { messages } }] }
        }

        case 'sentence': {
          const components = line.data.components.map(component => funcToItem(component, ...params))

          const computedFieldErrors = getFieldErrorsFromComponents(components, debouncedValues)
          const isCompleted = testComponentsCompletion(components, values)

          return {
            ...previous,
            fieldErrors: [...fieldErrors, ...computedFieldErrors],
            isCompleted,
            lines: [...lines, { ...line, data: { components } }],
          }
        }

        default:
          return { ...previous, lines: [...lines, line] }
      }
    },
    { fieldErrors: [], isCompleted: true, isSkipped: false, lines: [] }
  )

export const getLongSteps = (pages, lastFilledIndex) =>
  pages.reduce((previous, current, index) => {
    const isTitle = !current.name
    const title = current.timelineTitle || current.title

    if (isTitle) {
      return [...previous, { category: { index, title }, subSteps: [] }]
    }

    const href = index <= lastFilledIndex ? current.name : null
    if (!previous.length) {
      return [{ subSteps: [{ href, index, title }] }]
    }

    previous[previous.length - 1].subSteps.push({ href, index, title })
    return previous
  }, [])
