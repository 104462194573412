import * as helpers from '@pretto/bricks/components/helpers'
import { mobile, tablet } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${helpers.boxShadow(2)};
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  height: 100%;
  min-height: 100%;
  overflow: auto;
  position: relative;
`

export const Drawer = styled.div`
  bottom: 0;
  max-width: ${mobile.min};
  position: fixed;
  top: 0;
  transition: visibility 0s linear 500ms;
  visibility: hidden;
  width: 75%;
  z-index: 999;

  @media screen and (min-width: ${tablet.min}) {
    max-width: ${mobile.min};
    width: 33.33%;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: visibility 0s linear 0s;
      visibility: visible;
    `}

  ${({ layout }) => {
    switch (layout) {
      case 'left':
        return css`
          left: 0;
        `

      case 'right':
        return css`
          right: 0;
        `

      default:
        return ''
    }
  }}
`
