export const heardAbout = {
  id: 'heard_about',
  fields: [
    {
      type: 'options',
      name: 'origin',
      label: 'Comment avez-vous entendu parler de Pretto ?',
      options: [
        { label: 'Recommandation d’amis ou de famille', value: 'referral_friends_family' },
        {
          label: 'Recommandation de professionnels de l’immobilier (agents immobiliers, chasseurs, notaires)',
          value: 'referral_real_estate_professional',
        },
        { label: 'Site d’annonces immobilières (SeLoger, Leboncoin, ...)', value: 'real_estate_platform' },
        { label: 'Recherche en ligne (Google, Bing, ...)', value: 'search_engine' },
        { label: 'Application mobile Pretto Search', value: 'pretto_search' },
        { label: 'Réseaux sociaux (Instagram, TikTok, LinkedIn, ...)', value: 'social_network' },
        { label: 'Publicité en ligne (bannières, vidéos, ...)', value: 'advertising_online' },
        { label: 'Publicité (radio, TV)', value: 'advertising_traditional' },
        { label: 'Presse (journaux, magazines, ...)', value: 'press' },
        { label: 'Autre site partenaire', value: 'other' },
      ],
      jumpToNext: true,
    },
  ],
  jumps({ values }) {
    if (values.origin === 'referral_friends_family') {
      return `heard_about_comment?origin=${values.origin}`
    }

    return 'congratulations'
  },
  track({ values }) {
    return ['ONBOARDING_HEARD_ABOUT_DECLARED', { onboarding_heard_about_declared_value: values.origin }]
  },
}
