import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as S from './styles'

const OPERATION_SIGN = {
  '+': 'operation-plus',
  '=': 'operation-equal',
  '-': 'operation-minus',
}

const ProjectRow = ({
  actionIcon,
  description,
  emoji,
  isPushed,
  leftSpaced,
  onAction,
  rightSpaced,
  sign,
  title,
  value,
  variant,
}) => {
  const isLeftSpaced = leftSpaced || sign || emoji
  return (
    <S.ProjectRow isPushed={isPushed} variant={variant}>
      <S.Row emoji={emoji} leftSpaced={leftSpaced || sign} rightSpaced={rightSpaced || onAction}>
        {isLeftSpaced && (
          <S.SideLeft>
            {emoji ? (
              <Emoji size="medium">{emoji}</Emoji>
            ) : (
              sign && <Icon name={OPERATION_SIGN[sign]} variant={sign === '=' ? variant || 'primary-1' : 'inherit'} />
            )}
          </S.SideLeft>
        )}

        {isPushed ? <SubHeading variant={variant || 'accent-1'}>{title}</SubHeading> : <Text>{title}</Text>}

        <S.Value>
          <SubHeading variant={variant}>{value}</SubHeading>
        </S.Value>

        {(rightSpaced || onAction) && (
          <S.SideRight>
            {onAction && (
              <div onClick={onAction}>
                <Icon name={actionIcon} variant="primary-1" />
              </div>
            )}
          </S.SideRight>
        )}

        {description && (
          <S.Description start={isLeftSpaced ? 2 : 1}>
            <Text size="small" variant="neutral-1-60">
              {description}
            </Text>
          </S.Description>
        )}
      </S.Row>
    </S.ProjectRow>
  )
}

ProjectRow.propTypes = {
  /** Icon that appears when onAction is set. */
  actionIcon: PropTypes.string,
  /** Description, appears below the title on the left side. */
  description: PropTypes.node,
  /** Emoji to be used instead of sign. */
  emoji: PropTypes.string,
  /** If row is pushed, apply special styles. */
  isPushed: PropTypes.bool,
  /** Whether or not the row should have an offset on the left side if leftSpaced is not set. */
  leftSpaced: PropTypes.bool,
  /** Event handler when the action button is pressed. This causes the row to have an offset on the rigth side even though rightSpaced is not set. */
  onAction: PropTypes.func,
  /** Whether or not the row should have an offset on the rigth side if rightSpaced is not set. */
  rightSpaced: PropTypes.bool,
  /** Defines the math sign which should appear on the left. This causes the row to have an offset on the left side even though leftSpaced is not set.*/
  sign: PropTypes.oneOf(Object.keys(OPERATION_SIGN)),
  /** Title, appears on the left side. */
  title: PropTypes.string.isRequired,
  /** Value, appears on the right side. */
  value: PropTypes.string.isRequired,
  /** Variant color of row */
  variant: PropTypes.string,
}

ProjectRow.defaultProps = {
  actionIcon: 'pen',
  leftSpaced: false,
  rightSpaced: false,
}

export default ProjectRow
