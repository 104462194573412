import temporal from '@pretto/bricks/core/utility/temporal'

import { SET_PROJECT_HORIZON_DATE } from '@pretto/app/src/apollo'

const horizonDateFromValue = integerString => temporal().add(Number(integerString), 'month').format('YYYY-MM-DD')

const options = [
  { label: 'Dès que possible', value: '0' },
  { label: 'Dans 1 mois à 3 mois', value: '1' },
  { label: 'Dans 3 mois à 6 mois', value: '3' },
  { label: 'Dans 6 mois à 9 mois', value: '6' },
  { label: 'Dans plus de 9 mois', value: '9' },
]

export const projectHorizon = {
  id: 'project_horizon',
  fields: [
    {
      label: "Quand prévoyez-vous d'acheter ?",
      type: 'options',
      name: 'projectHorizonDate',
      options,
      track({ value }) {
        return [
          'ONBOARDING_PROJECT_HORIZON_CLICKED',
          {
            onboarding_project_horizon_value: horizonDateFromValue(value),
            onboarding_project_horizon_label: options
              .find(({ value: v }) => v === value)
              .label.toLowerCase()
              .replace(/\s+/g, '_'),
          },
        ]
      },
    },
  ],
  jumps: 'avoid_banks',
  mutation({ values }) {
    return {
      mutation: SET_PROJECT_HORIZON_DATE,
      variables: {
        input: {
          date: horizonDateFromValue(values.projectHorizonDate),
        },
      },
    }
  },
}
