import {
  NegotiationModal as Modal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const NegotiationModal: React.FC<NegotiationModalProps> = props => (
  <Modal {...props}>
    <p>
      <strong>À ce stade, la banque et l’organisme de garantie ont donné leur feu vert. Bravo 🤩 !</strong>
    </p>

    <p>
      Votre expert vous contactera prochainement pour prendre un rendez-vous et vous détailler la ou les offres
      obtenues.
    </p>
  </Modal>
)
