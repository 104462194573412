import {
  NegotiationModal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const SentModal: React.FC<NegotiationModalProps> = props => (
  <NegotiationModal {...props}>
    <p>
      <strong>Top départ ! 📮</strong>
    </p>

    <p>Toutes les conditions ont été réunies et votre dossier est envoyé à la banque présélectionnée avec vous.</p>
  </NegotiationModal>
)
