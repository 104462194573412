/* eslint-disable @typescript-eslint/ban-types */
/**
 * The function takes one or more arguments, and there are 2 cases:
 * 1. the first argument is of type function, then this function is called with the rest of the arguments
 * as parameters and the result if returned
 * 2. in all other cases, it returns the first argument as is  */
export function funcToItem<T, P extends readonly unknown[]>(
  itemOrFunctionItem: T | ((...args: P & {}) => T),
  ...args: P
): T {
  if (itemOrFunctionItem instanceof Function) {
    return itemOrFunctionItem(...args)
  }

  return itemOrFunctionItem
}
