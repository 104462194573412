import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { Housing, ProjectKind, Usage } from '@pretto/app/src/types/gateway/enums'
import { Bridge, Maybe } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

export const isBridgeEmpty = (bridge: Bridge): boolean =>
  Object.values(bridge).every(value => value === 'Bridge' || isNil(value))

export const mapPaidOff = (remainingPrincipal?: Maybe<number>): boolean => isNil(remainingPrincipal)

export const mapEstimatedPrice = (estimatedPrice?: Maybe<number>): number | undefined => estimatedPrice ?? undefined

export const mapRate = (rate?: Maybe<number>): number | undefined => rate ?? undefined

export const mapRemainingPrincipal = (remainingPrincipal?: Maybe<number>): number | undefined =>
  remainingPrincipal ?? undefined

export const mapEndDate = (date?: Maybe<string>): Date | undefined => (date ? new Date(date) : undefined)

export const mapBridge: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  updatedContext.ownPropertyEstimatedPrice = undefined
  updatedContext.ownPropertyPaidOff = undefined
  updatedContext.ownPropertyEndDate = undefined
  updatedContext.ownPropertyRate = undefined
  updatedContext.ownPropertyRemainingPrincipal = undefined
  updatedContext.ownPropertyForSale = undefined

  if (payload?.project_kind === ProjectKind.Renegotiation) {
    return {
      payload,
      context: updatedContext,
    }
  }

  if (
    payload?.good?.usage === Usage.PrimaryResidence &&
    (payload?.profile?.mortgagors?.[0]?.housing === Housing.Owner ||
      payload?.profile?.mortgagors?.[1]?.housing === Housing.Owner)
  ) {
    updatedContext.ownPropertyForSale = false

    if (!!payload.purchase?.bridge && !isBridgeEmpty(payload.purchase.bridge)) {
      const bridge: Bridge = structuredClone(payload.purchase.bridge)

      updatedContext.ownPropertyEstimatedPrice = mapEstimatedPrice(bridge.estimated_value)
      updatedContext.ownPropertyPaidOff = mapPaidOff(bridge.remaining_principal)
      updatedContext.ownPropertyEndDate = mapEndDate(bridge.end_date)
      updatedContext.ownPropertyRate = mapRate(bridge.rate)
      updatedContext.ownPropertyRemainingPrincipal = mapRemainingPrincipal(bridge.remaining_principal)
      updatedContext.ownPropertyForSale = true
    }
  }

  return {
    payload,
    context: updatedContext,
  }
}
