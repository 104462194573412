import { formatPlural } from '@pretto/bricks/core/utility/formatters'

import { MINUTES_PER_HOUR } from './constants'

class Duration {
  constructor(minutes) {
    this._minutes = minutes
  }

  toString() {
    const hours = Math.floor(this._minutes / MINUTES_PER_HOUR)
    const minutes = this._minutes % MINUTES_PER_HOUR

    return [hours && formatPlural(hours, 'heure'), minutes && formatPlural(minutes, 'minute')].filter(Boolean).join(' ')
  }
}

const duration = minutes => new Duration(minutes)

export default duration
