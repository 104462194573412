import { g, ng } from '@pretto/bricks/components/layout'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import { headline16 } from '@pretto/bricks/core/typography'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'
import Image from '@pretto/bricks/website/shared/components/Image'

import {
  NegotiationStepBank,
  NegotiationStepBankProps,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepBank/NegotiationStepBank'

import { ChevronDownBold } from '@pretto/picto'

import { useState } from 'react'
import styled, { css } from 'styled-components'

export interface NegotiationStepBankTimelineProps {
  bankPicturePath: string
  bankName: string
  onToggle?: (isExpanded: boolean) => void
  steps: NegotiationStepBankProps[]
  isDefaultExpanded?: boolean
}

export const NegotiationStepBankTimeline: React.FC<NegotiationStepBankTimelineProps> = ({
  bankPicturePath,
  bankName,
  steps,
  isDefaultExpanded = true,
  onToggle,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)

  useUpdateEffect(() => {
    onToggle?.(isExpanded)
  }, [isExpanded])

  const handleToggleSteps = () => {
    setIsExpanded(previousIsExpanded => !previousIsExpanded)
  }

  return (
    <Container {...props}>
      <Title onClick={handleToggleSteps}>
        <LogoBank path={bankPicturePath} />

        <BankName>{bankName}</BankName>

        <Arrow $isExpanded={isExpanded} />
      </Title>

      <CollapsibleRow in={isExpanded} appear={isDefaultExpanded}>
        <Steps>
          {steps.map((step, i) => (
            <NegotiationStepBank key={i} {...step} isLineShown={i < steps.length - 1} />
          ))}
        </Steps>
      </CollapsibleRow>
    </Container>
  )
}

const Container = styled.div`
  padding: ${g(2)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
`
const Title = styled.div`
  display: flex;
  gap: ${g(1, 2)};
  align-items: center;
  cursor: pointer;
`
const LogoBank = styled(Image).attrs({
  options: { height: ng(5), width: ng(5) },
})`
  display: block;
  flex: 0 0 ${g(5)};
  width: ${g(5)};
  height: ${g(5)};
  border-radius: ${g(5)};
  border: 1px solid ${({ theme }) => theme.colors.neutral4};
`
const BankName = styled.div`
  ${headline16};
  flex: 1 1 auto;
`
const Arrow = styled(ChevronDownBold)<{ $isExpanded: boolean }>`
  flex: 0 0 ${g(2)};
  width: ${g(2)};
  height: ${g(2)};
  transition: transform 200ms ease-in-out;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      transform: rotateZ(-180deg);
    `}
`
const Steps = styled.div`
  padding-top: ${g(2)};
`
