import { EstimatedPricePage } from '@pretto/app/src/Sentences/v2/PropertyRenegotiation/EstimatedPrice/Containers/EstimatedPricePage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const PropertyRenegotiationController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/renegotiation/property-renegotiation/estimatedPrice')}
        component={EstimatedPricePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
