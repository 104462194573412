import { breakpoints, g } from '@pretto/bricks/components/layout'
import { bodyBook16, textLarge24 } from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Times, UserCircle } from '@pretto/picto'

import styled, { css } from 'styled-components'

interface ContainerProps {
  $isLogin?: boolean
}

export const MenuHead = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  box-sizing: border-box;
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral3};
  height: ${g(9)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(11)};
  }
`
export const Container = styled(Link)<ContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;

  ${({ $isLogin }) =>
    $isLogin
      ? css`
      padding:  ${g(3)} ${g(2, 4)}};
    
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding: ${g(4)};
      }`
      : css`
      padding:  ${g(2)} ${g(2, 4)}};

      @media screen and (min-width: ${breakpoints.tablet}) {
        padding: ${g(3)};`}
`
export const Avatar = styled.div`
  ${textLarge24};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary2};
  border-radius: 100%;
  height: ${g(5)};
  width: ${g(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`
export const Label = styled.div`
  ${bodyBook16};
  margin-left: ${g(1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const PictoContainer = styled.button`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Picto = styled(Times)`
  height: ${g(3)};
  width: ${g(3)};
  margin: ${g(3)} ${g(2, 4)};
  color: ${({ theme }) => theme.colors.neutral1};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(4)} ${g(3)};
  }
`
export const UserPicto = styled(UserCircle)`
  height: ${g(3)};
  width: ${g(3)};
  color: ${({ theme }) => theme.colors.neutral1};
`
