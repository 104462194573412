import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import CollapsibleBox from '@pretto/bricks/components/boxes/CollapsibleBox'
import Button from '@pretto/bricks/components/buttons/Button'
import Divider from '@pretto/bricks/components/dividers/Divider'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import Skeleton from './skeleton'
import * as S from './styles'
import { chooseCorrectSmiley } from './utils'

const SectionBudgetError = ({ href, malus, onEdit }) => {
  return (
    <PreLeadProjectCardTemplate isEditable onEdit={onEdit} type="capacity" href={href}>
      <BaseTypo>Votre capacité d'emprunt est insuffisante</BaseTypo>
      {malus ? (
        <S.Malus>
          {malus.map(({ title, severity, comments, source }, index) => {
            const icon = chooseCorrectSmiley(severity)
            return (
              <CollapsibleBox key={index} icon={icon} title={title} zDepth={0}>
                <Divider />
                <S.Details>{nl2br(comments[severity])}</S.Details>
                {source && <S.SeeMore href={source}>En savoir plus</S.SeeMore>}
                <Divider />
              </CollapsibleBox>
            )
          })}
        </S.Malus>
      ) : (
        <Skeleton />
      )}
      <Button href={href}>Modifier votre profil</Button>
    </PreLeadProjectCardTemplate>
  )
}

SectionBudgetError.propTypes = {
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  /** Different malus to display */
  malus: PropTypes.array,
  /** Function triggered when edit icon is clicked. */
  onEdit: PropTypes.func.isRequired,
}

export default SectionBudgetError
