import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'

import { ModalFooter } from '@pretto/app/src/onboarding/components/ModalFooter/ModalFooter'
import { ChevronDownBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Picture = styled(Image)`
  width: ${g(12)};
  height: ${g(12)};
  border-radius: ${g(12)};
  margin: 0 auto ${g(3)};
`
export const Greetings = styled.h1`
  ${typo.heading32};
  margin-bottom: ${g(2)};
`
export const Description = styled.div`
  ${typo.bodyBook16};

  margin-bottom: ${g(2)};
`
export const ToggleDetails = styled.div`
  ${typo.heading16};
  text-align: center;
  margin-top: ${g(4)};
`
export const DetailsIcon = styled(ChevronDownBold)`
  margin-right: ${g(1)};
  transition: all 300ms ease-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotateZ(180deg);
    `};
`
export const Bio = styled.div`
  padding-top: ${g(2)};
  padding-bottom: ${g(4)};
`
export const Footer = styled(ModalFooter)`
  ${column([1, 6])};
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 10])};
  }
`
export const Cta = styled(Button).attrs({ isSecondary: true })`
  width: ${g(49)};
  max-width: 100%;
`
