import { RowStruct } from '@pretto/app/src/overview/types/row'

export const FEES = [
  'project.facts.agency_fees',
  'best_result.financing.guarantyFees',
  'best_result.financing.applicationFees',
  'project.facts.notary_fees',
  'brokerage_fees.amount',
] as const satisfies RowStruct

export const LOAN = [
  'best_result.financing.loanDuration',
  'best_result.financing.payment',
  'best_result.financing.creditCosts',
  'best_result.financing.savings',
] as const satisfies RowStruct

export const PURCHASE = [
  'project.purchase.property_price',
  'project.purchase.works_price',
  'project.purchase.land_price',
  'project.purchase.build_price',
  'project.purchase.furniture_price',
  'best_result.financing.bridge.remaining',
  'project.facts.notary_fees',
  'best_result.financing.guarantyFees',
  'best_result.financing.applicationFees',
  'project.facts.agency_fees',
  'brokerage_fees.amount',
  'best_result.financing.contribution',
  'best_result.financing.bridge.value',
  'best_result.financing.loanAmount',
  'best_result.financing.ptz.amount',
] as const satisfies RowStruct

export const RENEGOTIATION = [
  'project.renegotiation.remaining_principal',
  'best_result.financing.penaltyFees',
  'project.renegotiation.works_amount',
  'best_result.financing.guarantyFees',
  'best_result.financing.applicationFees',
  'best_result.financing.loanAmount',
  'brokerage_fees.amount',
  'best_result.financing.contribution',
] as const satisfies RowStruct
