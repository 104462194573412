import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CheckEmailDialog = ({ email, hasError, isLoading, onSubmit }) => (
  <>
    {hasError && (
      <Content variant="error-l">
        <S.Safety>
          <S.Emoji>
            <Emoji>:o</Emoji>
          </S.Emoji>

          <Text variant="error" size="small">
            <strong>Une erreur est survenue.</strong> Veuillez nous contacter !
          </Text>
        </S.Safety>
      </Content>
    )}

    <Content>
      <div>
        Avant de signer votre mandat, nous avons besoin que vous nous confirmiez votre adresse email{' '}
        <strong>{email}</strong>.
      </div>

      <S.Action>
        <ButtonLegacy disabled={isLoading} format="full" isLoading={isLoading} onClick={onSubmit} variant="accent-1">
          Continuer
        </ButtonLegacy>
      </S.Action>
    </Content>

    <Content variant="accent-4">
      <S.Safety>
        <S.Emoji>
          <Emoji>:lock:</Emoji>
        </S.Emoji>

        <Text variant="neutral-1" size="small">
          <strong>Pour votre sécurité,</strong> Pretto n’utilise pas de mot de passe. Vous recevrez un lien de connexion
          par email.
        </Text>
      </S.Safety>
    </Content>
  </>
)

CheckEmailDialog.defaultProps = {
  hasError: false,
  isLoading: false,
}

CheckEmailDialog.propTypes = {
  /** Current user email address. */
  email: PropTypes.string.isRequired,
  /** Whether or not the connection has failed. */
  hasError: PropTypes.bool,
  /** Whether or the user is being connecting. */
  isLoading: PropTypes.bool,
  /** Event triggered whenever the submit button is being pressed. */
  onSubmit: PropTypes.func.isRequired,
}

export default memo(CheckEmailDialog)
