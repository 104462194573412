import ResultCards from '@pretto/bricks/app/simulator/components/ResultCards'
import TimelineSidebar from '@pretto/bricks/app/timeline/components/TimelineSidebar'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Spinner from '@pretto/bricks/components/loading/Spinner'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { House, Times, Tune, UserCircle } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'

import * as S from './styles'

const ALERT_MESSAGE = {
  goodRates: `Vous avez déjà un bon taux : renégocier serait peu rentable.`,
  noSavings: `Vous avez déjà un très bon taux : renégocier ne serait pas rentable.`,
}

const SimulationContent = ({
  blockRateDoesntCountProps,
  date,
  footerInfosProps,
  isLoading,
  isLoggedIn,
  isTimelineOpen,
  isTimelineVisible,
  onCloseTimeline,
  onOpen,
  onOpenTimeline,
  onSimulateInsurance,
  projectKind,
  repurchaseAlert,
  resultCardsProps,
  secondActionProps,
  steps,
  isOnboardingBlockVisible,
  ...props
}) => {
  const title = useMemo(() => {
    switch (projectKind) {
      case 'capacity':
        return 'Votre budget'
      case 'renegotiation':
        return 'Comparez les offres !'
      case 'purchase':
      default:
        return (
          <>
            Félicitations,
            <br />
            vous êtes finançable !
          </>
        )
    }
  }, [projectKind])

  const getFooter = footerInfosProps => {
    if (!footerInfosProps) {
      return null
    }

    if (footerInfosProps?.count) {
      return <S.Trustpilot {...footerInfosProps} />
    }
  }

  return (
    <div {...props}>
      {repurchaseAlert?.isShown && (
        <S.Alert>
          <S.AlertMessage>
            <SubHeading>
              <Emoji verticalAlign="text-bottom">:money_with_wings:</Emoji> {ALERT_MESSAGE[repurchaseAlert.kind]}
            </SubHeading>
          </S.AlertMessage>
          <Card variant="accent-1-l">
            <Content>
              <Text>
                Avez-vous pensé à changer d’assurance emprunteur ? En moyenne, les utilisateurs avec des projets
                similaires ont économisé 
                <strong>{repurchaseAlert.insuranceSavings.toLocaleString('fr')} €</strong> avec notre partenaire.
              </Text>
              <S.AlertButton>
                <ButtonLegacy
                  href="https://pretto-fr.typeform.com/to/gNaE9ohR"
                  variant="accent-1"
                  onClick={onSimulateInsurance}
                >
                  Simuler mon assurance
                </ButtonLegacy>
              </S.AlertButton>
            </Content>
          </Card>
        </S.Alert>
      )}
      <Responsive max="laptop">
        {isTimelineVisible && <S.Timeline onClick={onOpenTimeline} progress={0.95} />}
        <Dialog isOpen={isTimelineOpen} variant="neutral-1">
          <S.DialogContainer>
            <S.DialogClose>
              <Times onClick={onCloseTimeline} />
            </S.DialogClose>
            <TimelineSidebar steps={steps} />
          </S.DialogContainer>
          <S.DialogFooter>
            {isLoggedIn ? (
              <S.ButtonLogin href="/" label="Tableau de Bord" picto={House} />
            ) : (
              <S.ButtonLogin href="/login" label="Se connecter" picto={UserCircle} />
            )}
          </S.DialogFooter>
        </Dialog>
      </Responsive>
      <S.TitleDate>
        {title}
        {date && <S.StyledStatus>taux au {date}</S.StyledStatus>}
      </S.TitleDate>
      <Responsive max="laptop">
        <S.Actions>
          <S.Action onClick={onOpen}>
            <S.Icon picto={Tune} />
            Modifier
          </S.Action>
          {secondActionProps && (
            <S.Action onClick={secondActionProps.onClick}>
              <S.Icon picto={secondActionProps.picto} />
              {secondActionProps.text}
            </S.Action>
          )}
        </S.Actions>
      </Responsive>
      {isLoading ? (
        <S.PlaceholdingAmount>
          <Spinner />
        </S.PlaceholdingAmount>
      ) : resultCardsProps?.cards ? (
        <>
          {blockRateDoesntCountProps && <S.BlockRateDoesntCount {...blockRateDoesntCountProps} />}
          {isOnboardingBlockVisible && <S.BlockOnboarding />}
          <ResultCards {...resultCardsProps} />
        </>
      ) : (
        <S.Error>
          <S.ErrorTitle>
            <S.ErrorEmoji>
              <Emoji size="medium">:open_mouth:</Emoji>
            </S.ErrorEmoji>

            <SubHeading size="large">Oups !</SubHeading>
          </S.ErrorTitle>

          <S.ErrorDescription>Nous ne pouvons pas vous proposer de budget pour ces critères.</S.ErrorDescription>
        </S.Error>
      )}
      {getFooter(footerInfosProps)}
    </div>
  )
}
SimulationContent.propTypes = {
  blockRateDoesntCountProps: PropTypes.object,
  date: PropTypes.string,
  isOnboardingBlockVisible: PropTypes.bool,
  footerInfosProps: PropTypes.object,
  isLoading: PropTypes.bool,
  /** Is user logged in */
  isLoggedIn: PropTypes.bool,
  /** Is timeline open (mobile) */
  isTimelineOpen: PropTypes.bool,
  /** is timeline visible (mobile) */
  isTimelineVisible: PropTypes.bool,
  /** function to call when timeline is closed */
  onCloseTimeline: PropTypes.func,
  /** function to call when we open dialog (modify) */
  onOpen: PropTypes.func,
  onOpenTimeline: PropTypes.func,
  /** Insurance CTA click. */
  onSimulateInsurance: PropTypes.func,
  /** Alert pushing to subscribe insurance. */
  projectKind: PropTypes.oneOf(['capacity', 'purchase', 'renegotiation']),
  repurchaseAlert: PropTypes.shape({
    /** Amount of possible savings if user subcribes an insurance. */
    insuranceSavings: PropTypes.number,
    /** Whether if message is shown or not. */
    isShown: PropTypes.bool,
    /** Kind of alert. Defines the message */
    kind: PropTypes.oneOf(Object.keys(ALERT_MESSAGE)),
  }),
  /** Props of <code>[ResultCards](/#!/ResultCards)</code> component. */
  resultCardsProps: PropTypes.object,
  secondActionProps: PropTypes.shape({
    onClick: PropTypes.func,
    picto: PropTypes.string,
    text: PropTypes.string,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default memo(SimulationContent)
