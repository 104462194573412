import Emoji from '@pretto/bricks/components/iconography/Emoji'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'

import * as C from './NotFound.module.css'

const NotFound = () => (
  <div>
    <div className={C.notFoundTitle}>
      <span className={C.notFoundTitleNumber}>4</span>
      <Mobile>
        <Emoji size="large">:o</Emoji>
      </Mobile>
      <Desktop>
        <Emoji size="x-large">:o</Emoji>
      </Desktop>
      <span className={C.notFoundTitleNumber}>4</span>
    </div>
    <div className={C.notFoundContent}>La page que vous cherchez semble introuvable.</div>
    <div>
      <div className={C.notFoundLinksTitle}>Voici quelques liens utiles</div>
      <div>
        <div className={C.notFoundLinksListItem}>
          <a href="https://faq.pretto.fr/" target="blank">
            FAQ
          </a>
        </div>
        <div className={C.notFoundLinksListItem}>
          <a href="https://app.pretto.fr/login">Espace client</a>
        </div>
        <div className={C.notFoundLinksListItem}>
          <a href="https://www.pretto.fr/contact" target="blank">
            Contact
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default NotFound
