import { prettoSearch } from '@pretto/bricks/core/config'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from './styles'

const OPTIONS = {
  AppStore: prettoSearch.appStore,
  GooglePlayStore: prettoSearch.googlePlayStore,
}

const StoreButton = ({ onClick, slug, ...props }) => {
  const { badgeSlug, label, url } = OPTIONS[slug]

  return (
    <Link {...props} href={url} onClick={onClick}>
      <S.Button alt={`Téléchargez l'application sur ${label}`} path={badgeSlug} />
    </Link>
  )
}

StoreButton.defaultProps = {
  onClick: () => {},
}

StoreButton.propTypes = {
  /** onClick function. */
  onClick: PropTypes.func,
  /** SLug of button. */
  slug: PropTypes.oneOf(Object.keys(OPTIONS)).isRequired,
}

export default StoreButton
