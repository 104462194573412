import { getProgressForStep } from '@pretto/app-core/application/lib/getProgressForStep'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

export const getProgress = (forms, ...args) => {
  const steps = Object.values(forms).filter(({ condition = true }) => funcToItem(condition, ...args))

  return steps.reduce(
    (previous, step) => {
      const progress = getProgressForStep(step, ...args)

      return { current: previous.current + progress.current, target: previous.target + progress.target }
    },
    { current: 0, target: 0 }
  )
}
