import Responsive from '@pretto/bricks/components/utility/Responsive'

import { Bell } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const OPTIONS = [
  { label: 'achat immobilier', value: 'purchase' },
  { label: 'renégociation de prêt', value: 'renegotiation' },
]

const PreLeadDashboardPage = ({
  bannerProps,
  bubbleText,
  cards,
  onChangeProgress,
  onChangeProjectKind,
  projectKind,
  progress,
  radioProps,
  services,
  widgetLinks,
}) => {
  return (
    <S.Content>
      {bannerProps && <S.NextStep picto={Bell} title="Étape suivante" {...bannerProps} />}
      <S.Main>
        <S.Project
          bubbleText={bubbleText}
          projectKind={projectKind}
          projectOptions={OPTIONS}
          onChangeProject={onChangeProjectKind}
          progress={progress}
          onChangeProgress={onChangeProgress}
          radioProps={radioProps}
          widgetLinks={widgetLinks}
        />
        {cards && (
          <S.ProjectSection>
            <S.ProjectCards items={cards.length}>
              {cards.map((card, index) => (
                <S.CardContainer key={index}>{card}</S.CardContainer>
              ))}
            </S.ProjectCards>
          </S.ProjectSection>
        )}
        <Responsive min="tablet">
          <S.Divider label="Découvrez aussi" variant="neutral-1-l" />
        </Responsive>
        <S.ServiceCards>{services}</S.ServiceCards>
        <S.MobileServiceCards title="Pretto à votre service !">{services}</S.MobileServiceCards>
      </S.Main>
    </S.Content>
  )
}

PreLeadDashboardPage.propTypes = {
  /** props passed to banner component */
  bannerProps: PropTypes.object,
  /** text to display in bubble */
  bubbleText: PropTypes.string,
  /** Array of project cards to be displayed. */
  cards: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** prelead has enough capacity */
  isFundable: PropTypes.bool,
  onChangeProgress: PropTypes.func,
  /** Function used to switch between project kind (purchase / renegotiation). */
  onChangeProjectKind: PropTypes.func.isRequired,
  progress: PropTypes.string,
  /** Kind of project. It can be empty, `purchase` or `renegotiation`. */
  projectKind: PropTypes.string,
  radioProps: PropTypes.object,
  /** List of available services. */
  services: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** widget links */
  widgetLinks: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.string.isRequired,
      purchase: PropTypes.string.isRequired,
      renegotiation: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default PreLeadDashboardPage
