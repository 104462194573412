import PropTypes from 'prop-types'

import * as S from './styles'

const ServerErrorPage = ({ buttonInlineProps, buttonProps, isHasedUrl, onCopyClick, text, title, ...props }) => (
  <S.Grid {...props}>
    <S.ServerErrorPage>
      <S.Illustraton />
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
      <S.Button href={buttonProps.href}>{buttonProps.label}</S.Button>
      <S.ButtonInline onClick={buttonInlineProps.onClick}>{buttonInlineProps.label}</S.ButtonInline>
      {isHasedUrl && (
        <S.TipBubble>
          Pour nous aider à résoudre ce mystère, vous pouvez <S.Copy onClick={onCopyClick}>copier ce lien</S.Copy> et
          nous le transmettre dans le chat.
        </S.TipBubble>
      )}
    </S.ServerErrorPage>
  </S.Grid>
)

ServerErrorPage.propTypes = {
  buttonInlineProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  buttonProps: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  isHasedUrl: PropTypes.bool.isRequired,
  onCopyClick: PropTypes.func.isRequired,
  text: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ServerErrorPage
