import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'
import {
  BlocPrettoProps,
  LegalSource,
} from '@pretto/app/src/Capacity/views/ResultPage/components/BlocPretto/BlocPretto'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'

export const useBlocPrettoProps = ({ onContinue, ...props }: NavigationProps): BlocPrettoProps => {
  const { next_booking } = useUser()

  const trackAction = useTracking()

  const trustpilotData = useTrustpilotData()

  return {
    ...props,
    description: !next_booking
      ? 'De la recherche de votre bien à l’obtention de votre prêt, Pretto vous accompagne avec expertise, implication et transparence.'
      : 'Pour vous assurer un accompagnement optimal et sur mesure, les équipes Pretto ont besoin de savoir régulièrement si vous avez avancé dans votre projet.',
    isActionable: !next_booking,
    onContinue(event) {
      onContinue?.(event, 'appointment')
    },
    onLegalClick(source: LegalSource) {
      trackAction('CAPACITY_RESULTS_LEGAL_LINKS_CLICKED', { capacity_results_legal_link: source })
    },
    title: !next_booking ? 'Faisons les présentations !' : 'Pretto vous accompagne à toutes les étapes de votre achat',
    trustpilotRateProps: trustpilotData,
  }
}
