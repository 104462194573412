import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'

import * as S from './styles'
import { TYPES } from './types'

const BaseTypo = forwardRef(({ children, typo, isNumber, ...props }, ref) => (
  <S.BaseTypo $typo={typo} $isNumber={isNumber} {...props} ref={ref}>
    {children}
  </S.BaseTypo>
))

BaseTypo.propTypes = {
  /** Children of component. */
  children: PropTypes.node,
  /** Whether it is showed as a number or not. */
  isNumber: PropTypes.bool,
  /** Defines the base style of typo */
  typo: PropTypes.oneOf(TYPES),
  /** Text color. */
  variant: PropTypes.string,
}

BaseTypo.displayName = 'BaseTypo'

export default memo(BaseTypo)
