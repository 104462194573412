import { getFieldConditionWithName } from '@pretto/app-core/lib/getFieldConditionWithName'
import { getFieldWithName } from '@pretto/app-core/lib/getFieldWithName'

export const resetValuesForDisabledFields = (values, sections, ...args) =>
  Object.keys(values).reduce((previous, keyValue) => {
    const correspondingFieldCondition = getFieldConditionWithName(keyValue, sections, values, ...args)

    if (!correspondingFieldCondition) {
      return { ...previous, [keyValue]: getFieldWithName(keyValue, sections, values).type === 'banks' ? [] : null }
    }

    return { ...previous, [keyValue]: values[keyValue] }
  }, {})
