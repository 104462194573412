export const withProps = <L, K = L>(
  defaultProps: L,
  propsList: (Partial<K> & { condition: boolean })[]
): L | (L & K) => {
  const truthyProps = propsList.find(({ condition }) => condition)

  if (!truthyProps) {
    return defaultProps
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { condition, ...props } = truthyProps

  return { ...defaultProps, ...props }
}
