import { SIMULATION_DURATION } from '@pretto/app/src/config/reassurance'

export const CARDS = {
  calculateRate: {
    cta: 'Calculez votre taux',
    isEditable: false,
  },
  capacity: {
    cta: 'Calculez votre budget',
    description: `Simulez votre capacité d’achat avec notre simulateur et obtenez le résultat le plus fiable du marché en ${SIMULATION_DURATION.toString()} minutes.`,
  },
  propertySearch: {
    cta: 'Créer mon alerte',
    description: 'Paramétrez la recherche immo Pretto.',
  },
  renegotiation: {
    cta: 'Simulez votre prêt',
    description: `Simulez votre rachat ou renégociation de prêt avec notre simulateur et obtenez le résultat le plus fiable du marché en ${SIMULATION_DURATION.toString()} minutes.`,
  },
  simulation: {
    cta: 'Simuler mon prêt',
    description: 'Comparer les taux immo pour mon projet.',
  },
}
