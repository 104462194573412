import { EMPTY_VALUE } from './index'

const NUMBER_SHOWN_CITIES = 3

export const getCities = localisations => [
  localisations.slice(0, NUMBER_SHOWN_CITIES),
  localisations.length - NUMBER_SHOWN_CITIES,
]

export const getMinMaxSentence = (min, max) => {
  const textMin = <strong>{min}</strong>
  const textMax = <strong>{max}</strong>
  if (min && max)
    return (
      <>
        Entre {textMin} et {textMax}
      </>
    )

  if (min) return <>plus de {textMin}</>

  if (max) return <>moins de {textMax}</>

  return EMPTY_VALUE
}

export const filterNorms = norms => norms.filter(norm => Boolean(norm))

export const parsePropertyKind = propertyKind => {
  switch (propertyKind) {
    case 'both':
      return (
        <>
          <strong>Appartement</strong> ou <strong>Maison</strong>
        </>
      )
    case 'flat':
      return <strong>Appartement</strong>
    case 'house':
      return <strong>Maison</strong>
    default:
      return ''
  }
}

export const parsePropertyCondition = propertyCondition => {
  switch (propertyCondition) {
    case 'both':
      return (
        <>
          <strong>Neuf</strong> ou <strong>Ancien</strong>
        </>
      )
    case 'old':
      return <strong>Ancien</strong>
    case 'new':
      return <strong>Neuf</strong>
    default:
      return ''
  }
}
