import { getSignDate } from '@pretto/app/src/dashboard/lib/getSignDate'
import { getMandateSuccessData } from '@pretto/app/src/mandate/lib/getMandateSuccessData'

import { getComortgagorName } from './getComortgagorName'
import { getVariant } from './getVariant'

export const getMandateSuccessVariant = ({ accounts, activeIndex, advisor, data, hasComortgagor, onContinue }) => {
  const isRepresentative = advisor.role !== 'expert'

  const comortgagorSignDate = hasComortgagor && getSignDate(accounts, data.deal.mandateStatus.signatures, false)

  const hasNextBooking = data.next_booking !== null
  const comortgagor = getComortgagorName({ accounts, activeIndex, hasComortgagor })
  const variant = getVariant({ isRepresentative, hasComortgagor, hasNextBooking, comortgagorSignDate })

  const successPageData = getMandateSuccessData({ comortgagor, onContinue, variant })

  return successPageData
}
