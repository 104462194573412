import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Button from '@pretto/bricks/components/buttons/Button'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { Times } from '@pretto/picto'

export const Container = styled.div`
  padding: ${g(4)};
  padding-bottom: ${g(7)};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })`
  margin-bottom: ${g(2)};
`

export const HeaderIcon = styled(Times)`
  height: ${g(3)};
  width: ${g(3)};
  cursor: pointer;
`

export const Description = styled(BaseTypo).attrs({ typo: 'bodyBook16' })`
  margin-bottom: ${g(3)};
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${g(4)};
`

export const StyledButtonFeature = styled(ButtonFeature)`
  background-color: ${({ theme }) => theme.colors.white};
`
