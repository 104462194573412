import { g, tablet } from '@pretto/bricks/components/layout'
import { bodyMedium16, textLarge24 } from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Advisor = styled.div`
  align-items: center;
  display: flex;
  padding: ${g(2)};

  @media screen and (min-width: ${tablet.min}) {
    padding: ${g(2)} ${g(4)} ${g(0)};
  }
`

export const AdvisorAvatar = styled.div`
  position: relative;
  margin-right: ${g(2)};
`

export const Dialog = styled.div`
  @media screen and (min-width: ${tablet.min}) {
    max-width: 400px;
  }
`

export const ExpertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Title = styled.h3`
  ${bodyMedium16};
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Name = styled.h3`
  ${textLarge24};
`

export const HolidaysDate = styled.h3`
  ${bodyMedium16};
  color: ${({ theme }) => theme.colors.warning1};
`

export const BannerContainer = styled.div`
  padding: ${g(0)} ${g(2)} ${g(3)};

  @media screen and (min-width: ${tablet.min}) {
    padding: ${g(2)} ${g(4)};
  }
`

export const ListContainers = styled.div`
  background-color: ${({ $backgroundColor, theme }) => theme.colors[$backgroundColor] || theme.colors.white};
  flex-grow: 1;
`

export const BannerChildrenContainer = styled.div`
  a {
    text-decoration: underline;
  }
`
