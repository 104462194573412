import filterResultsByDuration from '../../../lib/filterResultsByDuration'
import getDefaultDurationFromResults from '../../../lib/getDefaultDurationFromResults'
import getLoanDurationsFromResults from '../../../lib/getLoanDurationsFromResults'

export const getBestResultFromResults = (results, duration) => {
  const filteredResults = filterResultsByDuration(results, duration)

  if (filteredResults.length === 0) {
    return null
  }

  return filteredResults[0]
}

export const getDurationFromResults = (results, project, projectChanges) => {
  const defaultDuration = getDefaultDurationFromResults(results, project)
  const durations = getLoanDurationsFromResults(results)

  const currentDuration = projectChanges.request?.duration ?? defaultDuration
  const minimumDuration = durations[0]

  const duration = Math.max(currentDuration, minimumDuration)

  return duration
}
