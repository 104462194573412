import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'

import * as C from './Content.module.css'

const Content = ({ children, variant, size, format }) => (
  <div
    className={classnames(
      C[`atomContent${pascalcase(size ?? '')}`],
      {
        [C[`atomContent${pascalcase(variant ?? '')}`]]: variant,
        [C[`atomContent${pascalcase(format ?? '')}`]]: typeof format === 'string',
      },
      Array.isArray(format) ? format.map(format => C[`atomContent${pascalcase(format ?? '')}`]) : null
    )}
  >
    {children}
  </div>
)

Content.propTypes = {
  /** Children of content */
  children: PropTypes.node.isRequired,
  /** Backgroud variant of content */
  variant: PropTypes.string,
  /** Format style of content */
  format: PropTypes.string,
  /** Padding style of content */
  size: PropTypes.oneOf(['responsive', 'small', 'x-small']),
}

Content.defaultProps = {
  size: 'responsive',
}

export default Content
