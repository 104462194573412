import { PROGRESS, PROJECT_KIND } from './config'

export const initBubbleText = ({ isValidProject, progress, isProjectAvailable, isPropertyFound, projectKind }) => {
  switch (projectKind) {
    case PROJECT_KIND.purchase:
      if (progress === PROGRESS.searching || !isProjectAvailable) {
        return `Bonjour !
        Où en êtes-vous dans votre recherche de bien ?`
      }

      if (!isValidProject) {
        return `Nous vous conseillons d'ajuster les paramètres de votre projet pour réduire votre endettement.`
      }

      if (isPropertyFound) {
        return 'Bravo ! Votre projet semble bien avancé. Passez à l’étape suivante pour valider votre projet.'
      }

      return 'Votre projet se précise ? Pensez à mettre à jour le prix de votre bien.'
    case PROJECT_KIND.renegotiation:
      return [
        'En tant que courtier en crédit immobilier, Pretto vous indique s’il est préférable de ',
        <strong key="renegocier">renégocier votre prêt</strong>,
        ' avec votre banque ou bien de faire ',
        <strong key="racheter">racheter votre prêt</strong>,
        ' par une banque concurrente.',
      ]
    default:
      return ''
  }
}
