export const matchStatus = status => {
  switch (status) {
    case 'idle':
      return 'empty'

    case 'invalid':
      return 'error'

    default:
      return status
  }
}

export const formatDocuments = documents =>
  documents.map(({ href, mortgagor, status, title }) => ({
    href,
    label: title,
    status: matchStatus(status),
    userName: mortgagor,
  }))

export const formatSection = ({ documents, ...section }) => ({
  ...section,
  items: formatDocuments(documents),
})
