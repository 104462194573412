import { getArrangedMarks } from './getArrangedMarks'

export const getApproximatedValue = (value, marks, min, max) => {
  if (!marks) {
    return Math.max(min, Math.min(max, value))
  }

  const arrangedMarks = getArrangedMarks(marks, min, max)

  const approximatedValue = arrangedMarks.reduce((previous, mark) => {
    if (value >= previous + (mark - previous) / 2) {
      return mark
    }

    return previous
  })

  return approximatedValue
}
