import { getR3 } from '../../Auth/lib/helpers'
import { DEFAULT_BUFFER_DURATION, DEFAULT_DURATION, DURATION_TYPES, PRE_BUFFER_TYPES } from '../config/duration'

type AppointmentKind = keyof typeof DURATION_TYPES

export const getBookingDuration = (appointmentKind: AppointmentKind) => {
  const kind = getR3() ? 'r3' : appointmentKind
  const bufferDuration = PRE_BUFFER_TYPES[kind] ?? DEFAULT_BUFFER_DURATION
  const meetDuration = DURATION_TYPES[kind] ?? DEFAULT_DURATION

  return { bufferDuration, kind, meetDuration }
}
