import { COMMON_LABEL } from '@pretto/app-core/application/config/constants'
import { withOwner } from '@pretto/app-core/application/lib/withOwner'
import * as filters from '@pretto/app-core/lib/filters'

import { Crown } from '@pretto/picto'

// Options
const ownerOptions = mortgagors => [
  { label: mortgagors[0].name, value: 'mortgagor' },
  { label: mortgagors[1].name, value: 'comortgagor' },
  { label: COMMON_LABEL, value: 'both' },
]

const savingsOptions = [
  { label: 'Compte courant & Livrets', value: 'current_account' },
  { label: 'Assurance vie', value: 'life_insurance' },
  { label: 'PEL / CEL', value: 'ELP' },
  { label: 'Épargne salariale', value: 'employee_savings' },
  { label: 'Actions (PEA, Compte titres)', value: 'stock' },
  { label: 'Autres', value: 'other' },
]

// Fields Savings
const savingsAmount = {
  fieldProps: {
    placeholder: '0',
    suffix: '€',
  },
  label: 'Montant',
  name: 'amount',
  type: 'numberfield',
}

const savingsKind = {
  fieldProps: {
    options: savingsOptions,
  },
  label: 'Support',
  name: 'kind',
  type: 'select',
}

const savingsOwner = {
  condition: (values, { profile: { mortgagors } }) => mortgagors.length > 1,
  fieldProps: (values, project, { accounts }) => ({
    options: ownerOptions(accounts),
  }),
  label: 'Propriétaire',
  name: 'owner',
  type: 'select',
}

const savings = {
  fieldProps: (values, project, { accounts }) => ({
    disclaimer: {
      description: "Si vous n'avez aucune épargne, cette étape est facultative.",
      emoji: ':moneybag:',
      title: 'Rien à déclarer ? ',
    },
    formatContent: ({ amount, owner }) =>
      withOwner(() => {
        if (!amount) {
          return 'Aucun montant renseigné'
        }

        return `Montant : ${amount.toLocaleString('fr')} €`
      })(owner, accounts),
    formatTitle: ({ kind }, index) => {
      const option = savingsOptions.find(({ value }) => value === kind)

      if (!option) {
        return index + 1
      }

      return option.label
    },
  }),
  fields: [savingsAmount, savingsKind, savingsOwner],
  name: `profile.savings`,
  type: 'list',
}

// Fields Properties
const propertyLocation = {
  fieldProps: {
    placeholder: 'Où est situé ce bien ?',
    searchfieldPlaceholder: 'Tapez une adresse',
  },
  label: 'Localisation',
  name: 'location',
  type: 'address',
}

const propertyPurchaseYear = {
  fieldProps: {
    options: {
      delimiter: '',
      numeral: true,
      numeralIntegerScale: 4,
      stripLeadingZeroes: true,
    },
    placeholder: '2000',
  },
  label: "Année d'achat",
  name: 'purchase_year',
  type: 'numberfield',
}

const propertyPurchasePrice = {
  fieldProps: {
    placeholder: '0',
    suffix: '€',
  },
  label: "Prix d'achat",
  name: 'purchase_price',
  type: 'numberfield',
}

const propertyValue = {
  fieldProps: {
    placeholder: '0',
    suffix: '€',
  },
  label: 'Valeur actuelle',
  name: 'estimated_value',
  type: 'numberfield',
}

const properties = {
  fieldProps: (values, project, { accounts }) => ({
    disclaimer: {
      description: "Si vous n'avez aucun bien, cette étape est facultative.",
      emoji: ':house_with_garden:',
      title: 'Rien à déclarer ?',
    },
    formatContent: ({ location, owner }) =>
      withOwner(() => {
        if (!location) {
          return 'Aucune adresse correspondante'
        }

        return location.street
      })(owner, accounts),
    formatTitle: (property, index) => {
      return property.name || `Bien ${index + 1}`
    },
  }),
  fields: [propertyLocation, propertyPurchaseYear, propertyPurchasePrice, propertyValue],
  name: `profile.properties`,
  type: 'list',
}

// Sections
const savingsSection = {
  description:
    'Ajoutez une ligne par compte épargne pour mettre en valeur votre patrimoine financier auprès des banques.',
  fields: [savings],
  helper:
    "Pretto présente aux banques une vision complète de votre patrimoine financier et immobilier.\n\nCela ne veut pas dire que nous vous demanderons d'augmenter votre apport ! Au contraire, nous pourrons mettre en évidence les atouts de votre dossier.",
  title: 'Votre épargne',
}

const estateSection = {
  description: 'Ajoutez vos biens afin de nous permettre de connaitre votre patrimoine immobilier.',
  fields: [properties],
  title: 'Votre patrimoine',
}

// Pages
const defaultPage = {
  decoder: values => ({
    ...values,
    'profile.properties': values['profile.properties']?.map(property => ({
      ...property,
      location: filters.formatDecodeAddress(property.location),
    })),
  }),
  sections: [savingsSection, estateSection],
  title: 'Épargne & Patrimoine',
}

const step = {
  documentsParameters: {
    category: 'estate',
  },
  pages: [defaultPage],
  sideBarParameters: {
    label: 'Épargne & Patrimoine',
    picto: Crown,
    sideBarOrder: 5,
    url: '/application/estate',
  },
}

export default step
