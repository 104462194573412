import { numbers16 } from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

import { Rate } from '../components/Rate/Rate'

export const formatValue = (value: string | boolean | number): string | JSX.Element => {
  if (typeof value === 'boolean') {
    return <EntryValue $type="boolean">{value ? 'Oui' : 'Non'}</EntryValue>
  }

  if (typeof value === 'number') {
    return <Rate rate={value} />
  }

  return <EntryValue $type="value">{value}</EntryValue>
}

interface EntryValueProps {
  $type: 'boolean' | 'value'
}

const EntryValue = styled.span<EntryValueProps>`
  ${({ $type }) => {
    switch ($type) {
      case 'boolean':
        return css`
          text-transform: uppercase;
        `

      case 'value':
      default:
        return css`
          ${numbers16};
        `
    }
  }}
`
