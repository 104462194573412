import { g } from '@pretto/bricks/components/layout'

import { ChevronLeftBold, ChevronRightBold } from '@pretto/picto'

import range from 'lodash/range'
import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'

import * as S from './styles'

const SecondNav = ({ items, activeColor, isNavigationHidden, ...props }) => {
  const refItems = useRef([])
  const refScrollingContainer = useRef(null)

  const defaultItemsIsIntersecting = range(items.length).fill(true)

  const [itemsIsIntersectingLarge, setItemsIsIntersectingLarge] = useState(defaultItemsIsIntersecting)
  const [itemsIsIntersectingShrunk, setItemsIsIntersectingShrunk] = useState(defaultItemsIsIntersecting)

  useEffect(() => {
    const activeIndex = items.findIndex(({ isActive }) => isActive)
    const activeElement = refItems.current[activeIndex]

    refScrollingContainer.current.scrollLeft = activeElement?.offsetLeft ?? 0

    const defaultIntersectionObserverOptions = {
      root: refScrollingContainer.current,
      threshold: 0.99,
    }

    const observerLarge = new IntersectionObserver(handleObserveLarge, defaultIntersectionObserverOptions)

    const observerShrunk = new IntersectionObserver(handleObserveShrunk, {
      ...defaultIntersectionObserverOptions,
      rootMargin: `0px ${g(-10)}`,
    })

    refItems.current.forEach(node => observerLarge.observe(node))
    refItems.current.slice(1, -1).forEach(node => observerShrunk.observe(node))

    return () => {
      observerLarge.disconnect()
      observerShrunk.disconnect()
    }
  }, [])

  const handleNext = () => {
    refScrollingContainer.current.scrollLeft += refScrollingContainer.current.offsetWidth
  }

  const handleObserveLarge = entries => {
    setItemsIsIntersectingLarge(setItemsIsIntersecting(entries))
  }

  const handleObserveShrunk = entries => {
    setItemsIsIntersectingShrunk(setItemsIsIntersecting(entries))
  }

  const handlePrevious = () => {
    refScrollingContainer.current.scrollLeft -= refScrollingContainer.current.offsetWidth
  }

  const setItemsIsIntersecting = entries => itemsIsIntersecting =>
    itemsIsIntersecting.map((itemIsIntersecting, index) => {
      const entry = entries.find(({ target }) => target === refItems.current[index])

      if (!entry) {
        return itemIsIntersecting
      }

      return entry.isIntersecting
    })

  return (
    <S.Nav {...props}>
      <S.Container>
        {!isNavigationHidden && (
          <S.ListItemButtonLeft
            $isVisible={!itemsIsIntersectingLarge[0]}
            disabled={itemsIsIntersectingLarge[0]}
            onClick={handlePrevious}
          >
            <S.ListItemButtonMask>
              <S.ListItemButtonChevron as={ChevronLeftBold} />
              <S.ListItemButtonChevron as={ChevronLeftBold} />
            </S.ListItemButtonMask>
          </S.ListItemButtonLeft>
        )}

        <S.List ref={refScrollingContainer}>
          {items.map(({ isActive, label, url }, index) => {
            const handleRef = node => {
              refItems.current[index] = node
            }

            return (
              <S.ListItem $isActive={isActive} $activeColor={activeColor} key={index} ref={handleRef}>
                <S.ListItemLink
                  $isOpaqueLarge={!itemsIsIntersectingLarge[index]}
                  $isOpaqueShrunk={!itemsIsIntersectingShrunk[index]}
                  href={url}
                >
                  {label}
                </S.ListItemLink>
              </S.ListItem>
            )
          })}
        </S.List>

        {!isNavigationHidden && (
          <S.ListItemButtonRight
            $isVisible={!itemsIsIntersectingLarge[itemsIsIntersectingLarge.length - 1]}
            disabled={itemsIsIntersectingLarge[itemsIsIntersectingLarge.length - 1]}
            onClick={handleNext}
          >
            <S.ListItemButtonMask>
              <S.ListItemButtonChevron as={ChevronRightBold} />
              <S.ListItemButtonChevron as={ChevronRightBold} />
            </S.ListItemButtonMask>
          </S.ListItemButtonRight>
        )}
      </S.Container>
    </S.Nav>
  )
}

SecondNav.propTypes = {
  activeColor: PropTypes.string,
  /** Hides navigation buttons when set to `true`. */
  isNavigationHidden: PropTypes.bool,
  /** List of navigation entries. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default memo(SecondNav)
