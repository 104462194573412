import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'

import { useState } from 'react'

// This is an experiment on how to make useActiveField easier
// to use in complex form, needs to be tested further before
// being propagated to others pages
export const useActiveField = (
  indexes: (context: ProjectContext) => boolean[]
): {
  activeField: number
  isFilled: boolean
  previousActiveField: number
  setActiveField: React.Dispatch<React.SetStateAction<number>>
  refreshField: (context: Partial<ProjectContext>) => void
} => {
  const { nextRoute } = useRoutesGraph()

  const context = useSentences()

  const getValue = (context: ProjectContext) => {
    const value = indexes(context).findIndex(Boolean)

    return value < 0 ? Infinity : value
  }

  const isFilled = (context: ProjectContext) => {
    return nextRoute !== undefined && getValue(context) === Infinity
  }

  const [activeField, setActiveField] = useState<number>(isFilled(context) ? Infinity : getValue(context) || 0)
  const [previousActiveField, setPreviousActiveField] = useState<number>(activeField)

  const refreshField = (partials: Partial<ProjectContext>) => {
    updateField(getValue({ ...context, ...partials }))
  }

  const updateField: React.Dispatch<React.SetStateAction<number>> = (...args) => {
    setPreviousActiveField(activeField)
    setActiveField(...args)
  }

  return {
    activeField,
    isFilled: isFilled(context),
    previousActiveField,
    refreshField,
    setActiveField: updateField,
  }
}
