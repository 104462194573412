export const MANDATE_SUCCESS_VARIANTS = {
  representative: {
    firstButtonProps: {
      children: 'Tableau de bord',
      href: '/',
    },
    illustration: '/v1637683149/zen/static-assets/illustrations/660x660/peopleMakingAgreement.svg',
    texts: [
      <>
        À partir de maintenant, Pretto va pouvoir contacter les banques afin de <strong>négocier</strong> les meilleures
        conditions possibles pour ce projet.
      </>,
    ],
    title: 'Félicitations !',
  },
  completed: {
    firstButtonProps: ({ onContinue }) => ({
      children: 'Prendre RDV',
      onClick: onContinue,
    }),
    secondButtonProps: {
      children: 'Tableau de bord',
      href: '/',
    },
    illustration: '/v1637683149/zen/static-assets/illustrations/660x660/peopleMakingAgreement.svg',
    texts: [
      <>
        Votre expert peut à présent envoyer votre dossier aux banques et <strong>négocier</strong> pour vous{' '}
        <strong>la meilleure offre</strong> pour votre projet !
      </>,
      <>
        Suivez <strong>l’avancement</strong> de votre dossier, plannifiez votre prochain RDV.
      </>,
    ],
    title: 'Félicitations !',
  },
  bookedAndSign: {
    firstButtonProps: {
      children: 'Tableau de bord',
      href: '/',
    },
    illustration: '/v1637683149/zen/static-assets/illustrations/660x660/peopleMakingAgreement.svg',
    texts: [
      <>
        Votre expert peut à présent envoyer votre dossier aux banques et <strong>négocier</strong> pour vous{' '}
        <strong>la meilleure offre</strong> pour votre projet !
      </>,
    ],
    title: 'Félicitations !',
  },
  waitingComortgagor: {
    firstButtonProps: {
      children: 'Tableau de bord',
      href: '/',
    },
    illustration: '/v1637685712/zen/static-assets/illustrations/660x660/frame-man-winking2.svg',
    secondButtonProps: {
      children: 'Déconnexion',
      href: '/logout',
    },
    texts: ({ comortgagor }) => [
      <>
        <strong>{comortgagor}</strong> doit encore signer son mandat depuis son Espace Personnel pour que nous puissions
        démarrer les négociations avec les banques.`
      </>,
    ],
    title: 'Vous y êtes presque !',
  },
}
