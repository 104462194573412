import { breakpoints, g } from '@pretto/bricks/components/layout'
import { caption12 } from '@pretto/bricks/core/typography'

import { Status as Tag } from '@pretto/zen/atoms/infos/Status/Status'

import styled, { css } from 'styled-components'

export enum Status {
  Done = 'done',
  Current = 'current',
}

const TAG: Record<Status, string> = {
  [Status.Done]: 'Terminé',
  [Status.Current]: 'En cours',
}

export interface NegotiationStepSimpleProps {
  title: string
  status: Status
  label?: string
}

export const NegotiationStepSimple: React.FC<NegotiationStepSimpleProps> = ({ status, title, label, ...props }) => (
  <Step {...props}>
    <StyledTag $status={status}>{TAG[status]}</StyledTag>

    <div>
      {label && <>{label} : </>}
      {title}
    </div>
  </Step>
)

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${g(1, 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }
`

const StyledTag = styled(Tag)<{ $status: Status }>`
  ${caption12};
  font-weight: 500;
  padding: 2px ${g(1)};

  ${({ $status }) =>
    $status === 'current' &&
    css`
      color: ${({ theme }) => theme.colors.neutral1};
      background-color: ${({ theme }) => theme.colors.neutral4};
    `}
`
