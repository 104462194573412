import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Svg = styled.svg`
  display: block;
  max-width: 100%;
  width: ${g(15)};
`
export const PathText = styled.path`
  fill: ${({ theme, $variant }) => theme.colors[$variant]};
`
export const PathStar = styled.path`
  fill: ${({ theme }) => theme.colors.trustpilot};
`
