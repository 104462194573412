import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { useAuth } from '../AuthProvider'

const LogoutPage = ({ history }) => {
  const { signOut } = useAuth()

  useEffect(() => {
    signOut(handleLogout)
  }, [])

  const handleLogout = () => {
    history.replace('/login')
  }

  return null
}

LogoutPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default LogoutPage
