import CenterGridTemplate from '@pretto/bricks/shared/templates/CenterGridTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const EmailSentPage = ({ messages, onSendAgain, title }) => (
  <CenterGridTemplate>
    <S.Container>
      <S.Image />
      <S.Title>{title}</S.Title>
      {messages.map((message, i) => (
        <S.Paragraph key={i}>{message}</S.Paragraph>
      ))}
      {onSendAgain && (
        <S.Footer>
          Vous n’avez rien reçu ? <S.SendAgain onClick={onSendAgain}>Renvoyez un email</S.SendAgain>
        </S.Footer>
      )}
    </S.Container>
  </CenterGridTemplate>
)

EmailSentPage.propTypes = {
  messages: PropTypes.array.isRequired,
  onSendAgain: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default EmailSentPage
