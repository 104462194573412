import { breakpoints, g } from '@pretto/bricks/components/layout'

import { ArrowButtonLabel } from '@pretto/zen/reveal/atoms/buttons/arrowButtonLabel/ArrowButtonLabel'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'
import { Banner as BannerComponent } from '@pretto/zen/reveal/main/banners/banner/Banner'

import { ChevronUpBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Banner = styled(BannerComponent)`
  margin-bottom: ${g(1)};
`

export const ButtonClose = styled.button`
  appearance: none;
  ${({ theme }) => theme.typos.linkUnderline};
  color: inherit;
  cursor: pointer;
  float: right;
  margin-left: ${g(2)};
`

export const Content = styled.div`
  ${({ theme }) => theme.typos.body4};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(3)};
  padding: ${g(4)} ${g(2, 4)} ${g(5)};
  position: relative;

  // TODO override dialog old css
  p:not(:last-child) {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)} ${g(4)} ${g(5)};
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

export const DoList = styled.ul``

export const DoListItem = styled.li`
  &:before {
    content: '•';
    margin-right: ${g(1)};
  }
`

interface DragZoneProps {
  $isDragging: boolean
}

export const DragZone = styled.div<DragZoneProps>`
  ${transition({ propertyName: 'opacity' })};
  backdrop-filter: blur(25px);
  background-color: ${({ theme }) => addAlpha(theme.colors.neutral4, 0.5)};
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  padding: ${g(3)};
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      opacity: 1;
      pointer-events: auto;
    `};
`

export const DragZoneContent = styled.div`
  ${({ theme }) => theme.typos.body4Bold};
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.colors.primary1};
  border-radius: ${g(1)};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${g(2)};
`

export const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

export const FilesTitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Footer = styled.footer`
  align-items: center;
  display: flex;
`

export const FooterArrowButtonLabelNext = styled(ArrowButtonLabel)`
  margin-left: auto;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

export const More = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: ${g(2)} ${g(3)} ${g(3)};
`

export const MoreButton = styled.button`
  ${({ theme }) => theme.typos.body4Underline};
  align-self: flex-start;
  align-items: center;
  appearance: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;
  display: inline-flex;
  font: inherit;
  gap: ${g(1)};
`

interface MoreButtonArrowProps {
  $isMoreOpened: boolean
}

export const MoreButtonArrow = styled(ChevronUpBold)<MoreButtonArrowProps>`
  transform: scaleY(${({ $isMoreOpened }) => ($isMoreOpened ? 1 : -1)});
`

export const MoreChildComponent = styled.div`
  padding-top: ${g(2)};
`

interface MoreTitleProps {
  $type?: 'error' | 'success'
}

export const MoreTitle = styled.div<MoreTitleProps>`
  ${({ theme }) => theme.typos.body4Bold};
  ${({ $type }) => {
    switch ($type) {
      case 'error':
        return css`
          color: ${({ theme }) => theme.colors.error1};
        `

      case 'success':
        return css`
          color: ${({ theme }) => theme.colors.primary1};
        `

      default:
        return null
    }
  }}
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};
`
