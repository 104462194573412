import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Description = styled.div`
  grid-column: ${({ start }) => start} / span 2;
  grid-row-start: 2;
`
export const Side = styled.div`
  height: ${g(3)};
  display: flex;
  align-items: center;
`
export const SideLeft = styled(Side)`
  font-size: ${g(1.5)};
`
export const SideRight = styled(Side)``

export const ProjectRow = styled.div`
  ${({ variant }) =>
    variant &&
    css`
      color: ${({ theme }) => theme.legacy.colors[variant]};
    `}

  ${({ isPushed }) =>
    isPushed &&
    css`
      color: ${({ theme }) => theme.legacy.colors.accent1.default};
    `}
`
export const Row = styled.div`
  display: grid;
  grid-column-gap: ${g(2)};
  width: 100%;
  align-items: flex-start;

  ${({ emoji, leftSpaced, rightSpaced }) =>
    css`
      grid-template-columns: ${leftSpaced ? g(2) : emoji && 'auto'} 1fr auto ${rightSpaced && g(2)};
    `}
`
export const Value = styled.div`
  text-align: right;
`
