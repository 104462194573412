import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import { isPartner, PARTNERS } from '@pretto/app/src/projectChoice/lib/isPartner'
import { ProjectPartnerChoice as ProjectPartnerChoiceView } from '@pretto/app/src/projectChoice/views/ProjectPartnerChoice/ProjectPartnerChoice'

import { useLocation } from 'react-router-dom'

import { getContextPartner } from '../../config/whiteLabel'
import { getUtmFlagsLastClicked } from '../../lib/helpers'

export const ProjectPartnerChoice: React.FC = () => {
  const { search } = useLocation()

  const trackAction = useTracking()

  const trustpilotRateProps = useTrustpilotData()

  const handleAction = () => {
    trackAction('PROJECT_CHOICE_CLICKED', { project_choice_kind: 'generic', project_choice_source: 'default' })
  }

  const { utm_campaign: partnerSlug } = getUtmFlagsLastClicked<{ utm_campaign: keyof typeof PARTNERS }>()
  const partnerName = getContextPartner()?.subdomain ?? partnerSlug

  if (!isPartner(partnerName)) {
    throw new Error()
  }

  const { descriptionComponent: Description, descriptionProps } = PARTNERS[partnerName]

  return (
    <ThemeProvider designSystem="reveal">
      <Header navigationItemsList={[NAV_ITEMS.faq]} backgroundColor="neutral4" />
      <ProjectPartnerChoiceView
        actionHref={`/project${search}`}
        description={<Description {...descriptionProps} />}
        onAction={handleAction}
        trustpilotRateProps={trustpilotRateProps}
      />
    </ThemeProvider>
  )
}
