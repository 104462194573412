import InputCheckboxComponent from '@pretto/bricks/components/form/InputCheckbox'
import { g } from '@pretto/bricks/components/layout'

import type { Choice } from '@pretto/app/src/Simulation/types/Pushy'

import styled from 'styled-components'

interface AgreementProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  choices: Choice[]
}

export const Agreement: React.FC<AgreementProps> = ({ choices, ...props }) => (
  <Container {...props}>
    {choices.map(({ id, isChecked, isError, label, onChange }) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked)
      }

      return (
        <Row key={id}>
          <Checkbox id={id} isChecked={isChecked} isError={isError} onChange={handleChange} />
          <Label htmlFor={id}>{label}</Label>
        </Row>
      )
    })}
  </Container>
)

const Checkbox = styled(InputCheckboxComponent)`
  flex-shrink: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Label = styled.label`
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  gap: ${g(2)};
`
