import dayjs from 'dayjs'

export const checkSeniorityYearIsValid = (seniority: string | null | undefined): boolean => {
  const currentYear = dayjs().year()

  const maxSeniority = currentYear - 100
  const minSeniority = currentYear + 1

  if (seniority && !!parseInt(seniority)) {
    return parseInt(seniority) >= maxSeniority && parseInt(seniority) <= minSeniority
  }

  return false
}
