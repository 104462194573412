import { g } from '@pretto/bricks/components/layout'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'

import { ModalContent } from '@pretto/zen/main/modals/ModalContent/ModalContent'

import styled from 'styled-components'

export const Content = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > * + * {
    margin-top: ${g(2)};
  }
`

export const Description = styled.div``

export const Cancel = styled(ButtonFeature)`
  align-self: center;
`
