import type { PrimaryButtonFixProps } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { ButtonNext as ButtonNextComponent } from '@pretto/app/src/Sentences/v2/components/ButtonNext/ButtonNext'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { mapContextToPayload } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/mapContextToPayload/mapContextToPayload'
import { useTracking } from '@pretto/app/src/Sentences/v2/lib/tracking/useTracking'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { UPDATE_PROJECT } from '@pretto/app/src/apollo'
import type { DistributiveOmit } from '@pretto/app/src/lib/ts/DistributiveOmit'

import { useApolloClient } from '@apollo/client'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

export const ButtonNext: React.FC<DistributiveOmit<PrimaryButtonFixProps, 'href'>> = ({ onClick, ...props }) => {
  const client = useApolloClient()

  const { push } = useHistory()

  const { graph, nextRoute } = useRoutesGraph()

  const context = useSentences()

  const [isLoading, setIsLoading] = useState(false)

  const trackAction = useTracking()

  const { typology } = useUser()

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
    // why use event.currentTarget.getAttribute('href') instead of
    // just event.currentTarget.href, because href will be the full url resolved
    // by the browser (absolute) whereas getAttribute('href') will return
    // the value given in the html (relative)
    const href = event.currentTarget.getAttribute('href')

    if (graph.endsBy(nextRoute)) {
      event.preventDefault()

      setIsLoading(true)

      trackAction({
        eventName: EventName.SimulationCompleted,
        eventPayload: { simulationType: context.simulationType },
      })

      await client.mutate({
        mutation: UPDATE_PROJECT,
        variables: { project: JSON.stringify(mapContextToPayload(context)) },
      })

      if (typology === 'preLead') {
        await invalidateCache(client)
      }
    }

    onClick?.(event)

    if (!onClick && href) {
      push(href)
    }
  }

  if (!nextRoute) {
    return null
  }

  return (
    <ButtonNextComponent
      {...props}
      href={nextRoute.path}
      loading={isLoading}
      disabled={isLoading}
      onClick={handleClick}
    />
  )
}
