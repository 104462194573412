import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ICONS = {
  cloud: 'v1600345342/bricks/static-assets/icons/cloud.svg',
  google: 'v1592924611/bricks/static-assets/icons/google.svg',
}

const SvgIcon = ({ name, ...props }) => (
  <S.SvgIcon {...props}>
    <S.Image alt="" loading="lazy" src={getCloudinaryUrl(ICONS[name])} />
  </S.SvgIcon>
)

SvgIcon.propTypes = {
  /** Name of asset. You can see the [assets list](https://cloudinary.com/console/c-ecef9c757cd277072532d150ce459c/media_library/folders/39b1c4c103c4335d58fbfa2057edba16) from cloudinary. */
  name: PropTypes.string.isRequired,
}

export default memo(SvgIcon)
