import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import { useTracking } from '@pretto/app/src/lib/tracking'

import url from 'url'

const Agreement: React.FC<CardProps> = ({ dashboardData, defaultComponent: Component }) => {
  const trackAction = useTracking()

  const agreementUrl = ensureNotNil(dashboardData?.deal?.agreementUrl)

  const actionUrl = url.resolve(process.env.API_PRETTO_HOST, agreementUrl)

  const handleActionClick = () => {
    trackAction('DASHBOARD_AGREEMENT_DOWNLOADED')
  }

  if (dashboardData.deal?.status !== 'agreement') {
    return (
      <Component
        actionLabel="Télécharger"
        actionUrl={actionUrl}
        description="Vos offres de prêt négociées par votre Expert sont toujours disponibles. Vous trouverez dans ce document le récapitulatif avec toutes les options."
        onActionClick={handleActionClick}
        title="Rappel de vos offres de prêt"
      />
    )
  }

  return (
    <Component
      actionLabel="Télécharger"
      actionUrl={actionUrl}
      description="Bonne nouvelle ! Vos offres de prêt négociées par votre Expert sont disponibles. Vous trouverez dans ce document le récapitulatif avec toutes les options, pour que vous puissiez comparer et choisir celle qui vous correspond le mieux."
      onActionClick={handleActionClick}
      tag="Nouveau !"
      title="Vos offres de prêt"
    />
  )
}

export const config: Config = {
  component: Agreement,
  condition: dashboardData => !!dashboardData?.deal?.agreementUrl,
  name: 'agreement',
}
