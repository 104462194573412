import min from 'lodash/min'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import some from 'lodash/some'

import { MONTHS_IN_YEAR } from '../../lib/constants'
import getLoanDurationsFromResults from './getLoanDurationsFromResults'
import { getScoreIndex } from './getScoreDetails'
import filterResultsByDuration from './filterResultsByDuration'

/*
Return the default loan duration from a simulation data object
This information was initially part of the API Engine V1 but has been removed due to
the structure of the new response
The calculation of this duration has been delegated to the front end application
Here is how it is calculated:

1. If data.project.request.duration does exist and if at least one loanDuration from a
result contained in data.simulation matches that duration, then the default
duration is data.project.request.duration, otherwise:

2. In case of a renegotiation:
Return the greatest loanDuration of all results contained in data.simulation for which
savings are greater than 0 or if no results matches this condition, then return
the longest loanDuration that does not exceeds data.remaining_duration

3. In case of a purchase:
The duration from the credit with the least credit costs for which the score matches
the easiest stage of fundability
*/

const getDefaultDurationFromResults = (results, project) => {
  if (results.length === 0) {
    return null
  }

  if (project.request.duration) {
    const roundedDuration = Math.ceil(project.request.duration / MONTHS_IN_YEAR) * MONTHS_IN_YEAR

    if (filterResultsByDuration(results, roundedDuration).length > 0) {
      return roundedDuration
    }
  }

  switch (project.project_kind) {
    case 'purchase': {
      // Lowest possible score index for given results
      const lowestScoreIndex = min(
        results.map(({ financing: { loanDuration }, flags }) => {
          const sameDurationResults = filterResultsByDuration(results, loanDuration)
          const scoreIndex = getScoreIndex(flags, sameDurationResults.length)

          return scoreIndex
        })
      )

      // Simulation results that matches this lowest score index
      const mostFundableSimulationResults = results.filter(({ financing: { loanDuration }, flags }) => {
        const sameDurationResults = filterResultsByDuration(results, loanDuration)
        const scoreIndex = getScoreIndex(flags, sameDurationResults.length)

        return scoreIndex === lowestScoreIndex
      })

      // The simulation result with the lowest credit costs out of those most fundable simulation results
      const result = minBy(mostFundableSimulationResults, ({ financing: { creditCosts } }) => creditCosts)

      return result.financing.loanDuration
    }

    case 'renegotiation': {
      // First choice : same duration w/ savings
      // Second choice : first shorter duration w/ savings (can use longest, there are always more savings the shorter the loan)
      // Third choice : same duration w/o savings
      // Last choice : longest duration w/o savings
      // => overall : optimize by priority on [savings, optimal duration, longest duration]

      const durations = getLoanDurationsFromResults(results)

      const optimalDuration =
        Math.floor(project.renegotiation.facts.remaining_duration / MONTHS_IN_YEAR) * MONTHS_IN_YEAR

      // Leveraging the fact that, in JS, true > false
      return maxBy(durations, duration => [
        some(results, result => result.financing.loanDuration === duration && result.financing.savings > 0),
        duration === optimalDuration,
        duration,
      ])
    }

    default:
      return null
  }
}

export default getDefaultDurationFromResults
