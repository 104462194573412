import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold } from '@pretto/picto'

import autosize from 'autosize'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

type State = 'default' | 'warning' | 'error'
export interface TextAreaProps {
  isEmailAllowed?: boolean
  isUrlAllowed?: boolean
  isCharacterCountActive?: boolean
  onChange: (value: string, state: State) => void
  label?: string
  maxLength?: number
  placeholder?: string
  state?: State
  value: string
  theme?: 'default' | 'color'
}

type AlertColor = null | 'warning1' | 'error1'

const getStateColor = (state: State): AlertColor => {
  if (state === 'warning') return 'warning1'
  if (state === 'error') return 'error1'
  return null
}

export const TextArea = ({
  isEmailAllowed = false,
  isUrlAllowed = false,
  isCharacterCountActive = true,
  onChange,
  label = 'Description',
  maxLength = 600,
  placeholder,
  state = 'default',
  theme = 'default',
  value = '',
  ...props
}: TextAreaProps) => {
  const timeout = useRef<null | ReturnType<typeof setTimeout>>(null)
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const [status, setStatus] = useState({ state: state || 'default', message: '' })

  const stateColor = getStateColor(status.state)

  useEffect(() => {
    if (textAreaRef.current) {
      autosize(textAreaRef.current)
    }
  }, [value])

  useEffect(() => {
    const ref = textAreaRef.current

    setStatus({ state: 'default', message: '' })
    const url =
      /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9-]+([.]{1}[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?/gim
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gim

    if (value.length === maxLength) {
      setStatus({ state: 'warning', message: '' })
      return
    }

    if (value.length > maxLength && ref) {
      setStatus({ state: 'error', message: '' })

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        ref.setSelectionRange(maxLength, value.length)
      }, 800)
    }

    if (!isUrlAllowed && url.exec(value)) {
      setStatus({ state: 'error', message: 'Les liens ne sont pas autorisés' })
    }

    if (!isEmailAllowed && emailRegex.exec(value)) {
      setStatus({ state: 'error', message: 'Les adresses emails ne sont pas autorisées' })
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value, status.state)
  }

  return (
    <>
      <TextAreaContainer {...props} $isFill={!!value} $borderColor={stateColor} $theme={theme}>
        <TextAreaInput
          ref={textAreaRef}
          id="textarea"
          onChange={handleChange}
          placeholder={placeholder}
          $isOnError={status.state === 'error'}
        />
        {label && (
          <Label htmlFor="textarea" $isPlaceholderPresent={!!placeholder}>
            {label}
          </Label>
        )}
      </TextAreaContainer>

      {(status.message || isCharacterCountActive) && (
        <LineCountContainer $color={stateColor}>
          <ErrorMessage>{status.message}</ErrorMessage>
          {isCharacterCountActive && (
            <Row $color={stateColor}>
              <LineCounterValue>{`${value.length} / ${maxLength}`}</LineCounterValue>
              {status.state === 'warning' && <ExclamationMarkTriangleBold />}
              {status.state === 'error' && <ExclamationMarkCircleBold />}
            </Row>
          )}
        </LineCountContainer>
      )}
    </>
  )
}

interface TextAreaContainerProps {
  $isFill: boolean
  $borderColor: AlertColor
  $theme: TextAreaProps['theme']
}

interface LabelProps {
  $isPlaceholderPresent: boolean
}

interface ColorsProps {
  $color: AlertColor
}

interface TextAreaInputProps {
  $isOnError: boolean
}

const ActiveLabelStyle = css`
  transform: translate(14px, -10px);
  color: ${({ theme }) => theme.colors.neutral2};
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  ${typo.caption12};
  padding-left: 2px;
  padding-right: 2px;
`

const TextAreaContainer = styled.div<TextAreaContainerProps>`
  position: relative;
  width: 100%;
  height: auto;
  min-height: ${g(13)};
  background-color: ${({
    theme: {
      colors: { neutral4, white },
    },
    $theme,
  }) => ($theme === 'color' ? white : neutral4)};
  border: 1px solid ${({ theme, $borderColor }) => theme.colors[$borderColor ? $borderColor : 'neutral3']};
  border-radius: ${g(1)};
  transition: background-color 0.25s;
  padding-top: ${g(2)};
  padding-bottom: ${g(2)};
  padding-left: ${g(3)};
  padding-right: ${g(2)};

  &:focus-within {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme, $borderColor }) => theme.colors[$borderColor ? $borderColor : 'neutral2']};
  }

  &:focus-within > label {
    ${ActiveLabelStyle}
  }

  ${({ $isFill, $borderColor }) =>
    $isFill &&
    css`
      border-color: ${({ theme }) => theme.colors[$borderColor ? $borderColor : 'neutral2']};
      background-color: ${({ theme }) => theme.colors.white};

      > label {
        transform: translate(24px, -14px);
        color: ${({ theme }) => theme.colors.neutral2};
        background-color: ${({ theme }) => theme.colors.white};
        display: block;
        ${ActiveLabelStyle}
      }
    `}
`

const TextAreaInput = styled.textarea<TextAreaInputProps>`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  background-color: transparent;
  border: none;
  resize: none;
  ${typo.bodyMedium16};

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }

  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }

  &:focus {
    outline: 0;
  }

  ${({ $isOnError }) =>
    $isOnError &&
    css`
      &::selection {
        background-color: ${({ theme }) => theme.colors.error2};
      }
    `}
`

const Label = styled.label<LabelProps>`
  height: fit-content;
  width: fit-content;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.neutral3};
  background-color: ${({ theme }) => theme.colors.neutral4};
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  transform: translate(24px, 16px);
  transition: transform 0.25s, background-color 0.25s, color 0.25s, font-size 0.25s, font-weight 0.25s;
  ${typo.bodyMedium16};
  display: ${({ $isPlaceholderPresent }) => ($isPlaceholderPresent ? 'none' : 'block')};
`

const LineCountContainer = styled.div<ColorsProps>`
  width: 100%;
  padding-top: ${g(1)};
  text-align: right;
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $color }) => theme.colors[$color ? $color : 'neutral2']};
`

const ErrorMessage = styled.p`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.error1};
  ${typo.caption12};
`

const Row = styled.div<ColorsProps>`
  display: flex;
  flex-direction: row;
  gap: ${g(1)};
  color: ${({ theme, $color }) => theme.colors[$color ? $color : 'neutral2']};
`

const LineCounterValue = styled.p`
  align-self: flex-end;
  ${typo.caption12};
`
