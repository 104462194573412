import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { createElement } from 'react'
import { useTheme } from 'styled-components'

import * as S from './styles'

const List = ({ items, onClose, textVariant, title, variant, ...props }) => {
  const theme = useTheme()

  return (
    <S.List textVariant={textVariant} variant={variant} {...props}>
      {title && (
        <S.ListTitle>
          <strong>{title}</strong>
        </S.ListTitle>
      )}

      {items.map((item, index) => {
        const containerProps = { onClick: item.isUnavailable ? null : onClose }
        const itemsProps = { ...(item.itemProps || {}) }
        const element = createElement(
          item.component || Link,
          item.component ? itemsProps : { ...itemsProps, href: item.href },
          item.icon && item.title && (
            <S.ListItem $isUnavailable={item.isUnavailable}>
              {createElement(item.icon.name, {
                fill: item.isUnavailable ? theme.colors.neutral2 : theme.colors[textVariant],
              })}
              <S.TitleContainer>
                <S.ListItemTitle $isUnavailable={item.isUnavailable}>{item.title}</S.ListItemTitle>
                {!item.isUnavailable && item.subTitle && <S.Subtitle>{item.subTitle}</S.Subtitle>}
                {item.isUnavailable && <S.Subtitle>indisponible pour le moment</S.Subtitle>}
              </S.TitleContainer>
            </S.ListItem>
          )
        )

        return (
          <div key={index} {...containerProps}>
            {element}
          </div>
        )
      })}
    </S.List>
  )
}

List.propTypes = {
  isUnavailable: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.any,
      href: PropTypes.string,
      icon: PropTypes.object,
      titlt: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func,
  textVariant: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
}

export default List
