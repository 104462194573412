import CreditCapability from '@pretto/bricks/app/project/components/CreditCapability'
import Graph from '@pretto/bricks/app/project/components/Graph'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'
import ProjectRow from '@pretto/bricks/shared/components/ProjectRow'

import flatten from 'lodash/flatten'
import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

const OverviewPage = ({
  creditCapabilityProps,
  goodUsage,
  graphProps,
  hasSimulation,
  isProjectEditable,
  loanTitle,
  loanValues,
  onContactClick,
  projectValues,
  title,
}) => {
  const [selectedValue, setSelectedValue] = useState(null)

  const handleSelectValue = selectedValue => {
    setSelectedValue(selectedValue)
  }

  const handleUnselectValue = () => {
    setSelectedValue(null)
  }

  return (
    <S.Page>
      <Wrapper full="tablet">
        <Desktop>
          <S.Title>
            <Heading>{title}</Heading>
          </S.Title>
        </Desktop>

        <S.Container>
          <div>
            <S.Card>
              <Card format="line" overflowVisible>
                <Content>
                  <div>
                    {projectValues.length > 0
                      ? 'Voici le détail de votre projet dans sa version actuelle. Vous y trouverez le détail du calcul de ce que vous empruntez à la banque.'
                      : "Il nous manque des informations ou votre projet n'est pas réalisable dans sa forme actuelle. Vous pouvez contacter votre expert pour en discuter ensemble."}

                    {projectValues.length === 0 && (
                      <S.ContactButton>
                        <ButtonLegacy onClick={onContactClick} variant="accent-1">
                          Contacter mon expert
                        </ButtonLegacy>
                      </S.ContactButton>
                    )}
                  </div>

                  {projectValues.length > 0 && (
                    <>
                      <S.Divider>
                        <Divider />
                      </S.Divider>

                      <div>
                        {projectValues.map((group, index) => (
                          <S.Group key={index}>
                            {group.map(({ path, variant, onAction, ...props }, index) => (
                              <S.Row key={index}>
                                <S.RowHighlight
                                  isHighlighted={selectedValue !== null && selectedValue.paths.includes(path)}
                                  variant={variant.replace(/^([\w]+-[\d]).*/, '$1-l')}
                                />

                                <ProjectRow
                                  {...props}
                                  onAction={isProjectEditable ? onAction : null}
                                  leftSpaced
                                  rightSpaced={
                                    isProjectEditable && flatten(projectValues).some(value => !!value.onAction)
                                  }
                                />
                              </S.Row>
                            ))}
                          </S.Group>
                        ))}
                      </div>

                      <S.Divider>
                        <Divider />
                      </S.Divider>

                      <div>
                        <Graph
                          {...graphProps}
                          onSelectValue={handleSelectValue}
                          onUnselectValue={handleUnselectValue}
                          selectedValue={selectedValue}
                        />
                      </div>
                    </>
                  )}
                </Content>
              </Card>
            </S.Card>

            {hasSimulation && isProjectEditable && (
              <Mobile>
                <S.Card>
                  <CreditCapability {...creditCapabilityProps} />
                </S.Card>
              </Mobile>
            )}

            {loanValues.length > 0 && (
              <S.Card>
                <Card format="line">
                  <Content>
                    <S.CardTitle>
                      <SubHeading size="large">{loanTitle}</SubHeading>
                    </S.CardTitle>

                    <div>
                      {loanValues.map(({ onAction, ...props }, index) => (
                        <S.Row key={index}>
                          <ProjectRow
                            {...props}
                            onAction={isProjectEditable ? onAction : null}
                            rightSpaced={isProjectEditable}
                          />
                        </S.Row>
                      ))}
                    </div>
                  </Content>
                </Card>
              </S.Card>
            )}

            {goodUsage !== 'rental_investment' && projectValues.length > 0 && (
              <S.Card>
                <Card format="line">
                  <Content>
                    <S.CardTitle>
                      <SubHeading size="large">Conditions</SubHeading>
                    </S.CardTitle>

                    <div>
                      Vous devrez domicilier vos revenus dans la banque qui vous accordera le financement pour votre
                      projet.
                    </div>
                  </Content>
                </Card>
              </S.Card>
            )}
          </div>

          {hasSimulation && isProjectEditable && (
            <Desktop>
              <S.Sidebar>
                <CreditCapability {...creditCapabilityProps} />
              </S.Sidebar>
            </Desktop>
          )}
        </S.Container>
      </Wrapper>
    </S.Page>
  )
}

OverviewPage.propTypes = {
  /** Props of [`CreditCapability`](/#!/CreditCapability) element. */
  creditCapabilityProps: PropTypes.object.isRequired,
  /** How the good will be used. For hints display. */
  goodUsage: PropTypes.oneOf(['primary_residence', 'rental_investment', 'secondary_residence']).isRequired,
  /** Props of [`Graph`](/#!/Graph) element. */
  graphProps: PropTypes.object.isRequired,
  /** Whether or not a simulation is available. */
  hasSimulation: PropTypes.bool.isRequired,
  /** Whether project is editable or not. Some elements depends on that prop. */
  isProjectEditable: PropTypes.bool,
  /** Loan title. */
  loanTitle: PropTypes.string.isRequired,
  /** Loan data. */
  loanValues: PropTypes.array.isRequired,
  /** Event triggered whenever the contact button is being pressed. */
  onContactClick: PropTypes.func.isRequired,
  /** Project data. */
  projectValues: PropTypes.array.isRequired,
  /** Page title. */
  title: PropTypes.string.isRequired,
}

export default OverviewPage
