import { Income } from '@pretto/app/src/Sentences/v2/types/compensation'
import { IncomePeriod } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapBonusPeriodValues: MapToChoices<IncomePeriod[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]

  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[0]?.bonus?.period === value,
    onClick: () => {
      clonedMortgagors[0].bonus = {
        ...clonedMortgagors[0].bonus,
        period: value,
      } as Income
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.income.bonus.period.${value}`),
  }))
}
