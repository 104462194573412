import styled from 'styled-components'

import { g, tablet } from '@pretto/bricks/components/layout'

export const ListItemHeaderContentActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ListItemHeaderContentAction = styled.div`
  cursor: pointer;
`

export const Field = styled.div`
  & + & {
    margin-top: ${g(3)};

    @media only screen and (min-width: ${tablet.min}) {
      margin-top: ${g(4)};
    }
  }
`

export const ListItemParent = styled.div``

export const ListItem = styled.div`
  margin: 0 ${g(-2)};

  @media screen and (min-width: ${tablet.min}) {
    margin: 0;
  }

  ${ListItemParent} + ${ListItemParent} & {
    padding-top: ${g(2)};
  }
`

export const ListItemHeader = styled.div`
  border-radius: 7px 7px 0 0;
  color: ${({ theme }) => theme.legacy.colors.white.default};

  @media screen and (min-width: ${tablet.min}) {
    overflow: hidden;
  }
`
