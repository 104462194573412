import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Icon = ({ name, variant, loading, ...props }) =>
  loading ? <SpinnerLegacy size="small" variant={variant} /> : <S.Icon {...props} $name={name} variant={variant} />

Icon.propTypes = {
  /** Should the icon be replaced by a spinner. False by default **/
  loading: PropTypes.bool,
  /** Icon name. Class of icon is going to be <code>.icon .icon-name</code>*/
  name: PropTypes.string.isRequired,
  /** Variant color. Inherits color by default. */
  variant: PropTypes.string,
}

Icon.defaultProps = {
  loading: false,
  variant: 'inherit',
}

export default memo(Icon)
