import { ng } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { useRef } from 'react'
import styled from 'styled-components'

export const controllingClassName = random()

export const DocumentIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = props => {
  const { current: id } = useRef(random())

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={ng(8)} height={ng(8)} viewBox={`0 0 ${ng(8)} ${ng(8)}`} {...props}>
      <defs>
        <Sheet
          id={id}
          d="M0,1.36C0,.61,.61,0,1.36,0H22.72c.32,0,.63,.11,.88,.32l3.16,2.67h0s3.36,2.89,3.36,2.89c.3,.26,.48,.64,.48,1.04v30.32c0,.75-.61,1.36-1.36,1.36H1.36c-.75,0-1.36-.61-1.36-1.36V1.36Z"
        />
      </defs>

      <Docs>
        <use xlinkHref={`#${id}`} />
        <Marker d="M22.93,10.69h-3.19l-1.41-2.44c-.07-.15-.22-.15-.3-.15h-4.15c-.15,0-.3,.07-.3,.15l-1.41,2.44h-3.19c-1.11,0-2,.89-2,2v7.78c0,1.11,.89,2,2,2h14c1.11,0,2-.89,2-2v-7.85c-.07-1.04-.96-1.93-2.07-1.93Zm-6.96,9.41c-2.15,0-3.85-1.7-3.85-3.85s1.7-3.85,3.85-3.85,3.85,1.7,3.85,3.85-1.7,3.85-3.85,3.85Z" />
        <Title d="M10.37,32.99h-1.41v-1.06h1.41c.19,0,.35-.03,.47-.1,.12-.07,.21-.16,.26-.27,.06-.12,.09-.25,.09-.39,0-.16-.03-.3-.09-.43-.05-.13-.14-.24-.26-.32-.12-.08-.28-.12-.47-.12h-.9v4.63h-1.37v-5.69h2.27c.46,0,.85,.08,1.18,.25,.33,.17,.58,.4,.76,.69,.18,.29,.27,.62,.27,.99s-.09,.69-.27,.96c-.18,.27-.43,.49-.76,.64-.33,.15-.72,.23-1.18,.23Zm7.85-3.76v5.69h-1.37l-2.01-3.54v3.54h-1.37v-5.69h1.37l2.01,3.54v-3.54h1.37Zm5.68,2.65v2.34c-.1,.11-.24,.22-.45,.34-.2,.12-.45,.22-.75,.31-.3,.09-.66,.13-1.07,.13-.38,0-.72-.06-1.03-.18-.31-.12-.57-.3-.79-.54-.22-.24-.39-.53-.51-.87-.12-.34-.18-.74-.18-1.18v-.32c0-.44,.06-.84,.18-1.18,.12-.34,.29-.63,.5-.87,.22-.24,.48-.42,.77-.54,.3-.12,.62-.18,.98-.18,.52,0,.95,.08,1.28,.25,.33,.16,.58,.39,.75,.68,.17,.29,.27,.62,.31,1h-1.32c-.03-.19-.08-.35-.15-.48-.07-.13-.17-.23-.3-.3-.13-.07-.3-.11-.52-.11-.16,0-.31,.04-.45,.11-.13,.07-.25,.18-.34,.32-.09,.14-.17,.32-.22,.53-.05,.21-.07,.46-.07,.75v.32c0,.28,.02,.53,.07,.75,.05,.21,.12,.39,.22,.54,.1,.14,.22,.25,.38,.32,.15,.07,.33,.11,.54,.11,.15,0,.27-.01,.38-.04,.1-.03,.19-.06,.26-.09,.07-.04,.12-.08,.16-.11v-.88h-.95v-.94h2.32Z" />
      </Docs>

      <Jpg>
        <use xlinkHref={`#${id}`} />
        <Marker d="M6.92,18.9l-2.72,5.26c-.18,.35,.07,.76,.46,.76H25.96c.39,0,.64-.42,.46-.76l-7.29-14.08c-.19-.37-.71-.38-.92-.02l-7.09,12.31c-.19,.33-.67,.35-.89,.03l-2.42-3.55c-.22-.33-.71-.3-.9,.05Zm3.68-10.98c0,1.88-1.52,3.4-3.4,3.4-1.88,0-3.4-1.52-3.4-3.4,0-1.88,1.52-3.4,3.4-3.4,1.88,0,3.4,1.52,3.4,3.4Z" />
        <Title d="M10.36,32.43v-3.9s1.37,0,1.37,0v3.9c0,.38-.09,.71-.26,.99-.17,.28-.41,.49-.7,.65-.29,.15-.62,.23-.99,.23-.38,0-.72-.06-1.02-.19-.29-.13-.53-.32-.7-.59-.17-.27-.25-.6-.25-1.02h1.38c0,.19,.02,.34,.07,.45,.04,.11,.11,.18,.2,.23,.09,.04,.2,.06,.32,.06,.12,0,.23-.03,.32-.09,.09-.07,.16-.16,.2-.28,.05-.12,.07-.27,.07-.44Zm6.61-2.96c-.18-.29-.43-.52-.77-.69-.32-.17-.72-.25-1.17-.25h-2.27v5.69h1.37v-1.93h.9c.45,0,.85-.08,1.17-.23,.34-.15,.59-.37,.77-.64,.17-.27,.26-.59,.26-.96s-.09-.7-.26-.99Zm-1.21,1.39c-.06,.12-.14,.21-.26,.27-.12,.07-.28,.1-.47,.1h-.9v-1.64h.9c.19,0,.35,.04,.47,.12s.2,.19,.26,.32c.06,.14,.08,.28,.08,.44,0,.14-.02,.27-.08,.39Zm7.02,.32v2.34c-.1,.11-.24,.22-.45,.34-.2,.12-.45,.22-.75,.31-.3,.09-.66,.13-1.07,.13-.38,0-.72-.06-1.03-.18-.31-.12-.57-.3-.79-.54-.22-.24-.39-.53-.51-.87-.12-.34-.18-.74-.18-1.18v-.32c0-.44,.06-.84,.18-1.18,.12-.34,.29-.63,.5-.87,.22-.24,.48-.42,.77-.54,.3-.12,.62-.18,.98-.18,.52,0,.95,.08,1.28,.25,.33,.16,.58,.39,.75,.68,.17,.29,.27,.62,.31,1h-1.32c-.03-.19-.08-.35-.15-.48-.07-.13-.17-.23-.3-.3-.13-.07-.3-.11-.52-.11-.16,0-.31,.04-.45,.11-.13,.07-.25,.18-.34,.32-.09,.14-.17,.32-.22,.53-.05,.21-.07,.46-.07,.75v.32c0,.28,.02,.53,.07,.75,.05,.21,.12,.39,.22,.54,.1,.14,.22,.25,.38,.32,.15,.07,.33,.11,.54,.11,.15,0,.27-.01,.37-.04,.1-.03,.19-.06,.26-.09,.07-.04,.12-.08,.16-.11v-.88s-.95,0-.95,0v-.94s2.32,0,2.32,0Z" />
      </Jpg>

      <Pdf>
        <use xlinkHref={`#${id}`} />
        <Marker d="M25.61,16.57c-1.62-1.68-6.03-.99-7.08-.87-1.55-1.49-2.61-3.29-2.98-3.91,.56-1.68,.93-3.36,.99-5.16,0-1.55-.62-3.23-2.36-3.23-.62,0-1.18,.37-1.49,.87-.75,1.3-.43,3.91,.75,6.59-.68,1.93-1.3,3.79-3.04,7.08-1.8,.75-5.59,2.49-5.9,4.35-.12,.56,.06,1.12,.5,1.55,.43,.37,.99,.56,1.55,.56,2.3,0,4.54-3.17,6.09-5.84,1.3-.43,3.36-1.06,5.41-1.43,2.42,2.11,4.54,2.42,5.65,2.42,1.49,0,2.05-.62,2.24-1.18,.31-.62,.12-1.3-.31-1.8Zm-1.55,1.06c-.06,.43-.62,.87-1.62,.62-1.18-.31-2.24-.87-3.17-1.62,.81-.12,2.61-.31,3.91-.06,.5,.12,.99,.44,.87,1.06ZM13.68,4.83c.12-.19,.31-.31,.5-.31,.56,0,.68,.68,.68,1.24-.06,1.3-.31,2.61-.75,3.85-.93-2.49-.75-4.22-.43-4.78Zm-.12,12.05c.5-.99,1.18-2.73,1.43-3.48,.56,.93,1.49,2.05,1.99,2.55,0,.06-1.93,.43-3.42,.93Zm-3.67,2.49c-1.43,2.36-2.92,3.85-3.73,3.85-.12,0-.25-.06-.37-.12-.19-.12-.25-.31-.19-.56,.19-.87,1.8-2.05,4.29-3.17Z" />
        <Title d="M11.75,29.55c-.18-.3-.43-.53-.76-.69-.33-.17-.72-.25-1.18-.25h-2.27v5.69h1.37v-1.93h.9c.46,0,.85-.08,1.18-.23s.58-.37,.76-.64c.17-.27,.26-.59,.26-.96s-.09-.71-.26-.99Zm-1.21,1.39c-.05,.11-.14,.2-.26,.27-.12,.06-.28,.1-.47,.1h-.9v-1.64h.9c.19,0,.35,.04,.47,.12,.12,.08,.21,.19,.26,.32,.06,.13,.09,.28,.09,.44,0,.14-.03,.27-.09,.39Zm6.64-.71c-.13-.33-.31-.62-.55-.86-.23-.24-.51-.43-.83-.56-.32-.14-.68-.2-1.06-.2h-1.83v5.69h1.85c.38,0,.73-.07,1.05-.2s.59-.32,.83-.56c.23-.24,.41-.53,.54-.87,.13-.33,.2-.7,.2-1.1v-.24c0-.4-.07-.77-.2-1.1Zm-1.2,1.34c0,.37-.05,.67-.15,.92-.09,.25-.23,.44-.41,.56-.18,.13-.4,.19-.66,.19h-.48v-3.57h.46c.2,0,.38,.03,.53,.1,.16,.07,.29,.18,.39,.32,.11,.14,.18,.31,.24,.52,.05,.2,.08,.44,.08,.72v.24Zm3.64-1.9v1.31h2.21v1.05h-2.21v2.27h-1.37v-5.69h3.81v1.06h-2.44Z" />
      </Pdf>
    </svg>
  )
}

const Marker = styled.path`
  fill: currentColor;
`

const Sheet = styled.path`
  fill: ${({ theme }) => theme.colors.white};
  stroke: currentColor;
  stroke-width: 1.4px;
`

const Title = styled.path`
  fill: ${({ theme }) => theme.colors.neutral1};
`

const Docs = styled.g`
  ${transition({ propertyName: 'transform' })};
  color: ${({ theme }) => theme.colors.accent2};
  transform: translate(16.7px, 6.7px);

  .${controllingClassName}:focus &&,
  .${controllingClassName}:hover && {
    transform: translate(0.7px, 0.7px);
  }
`

const Jpg = styled.g`
  ${transition({ propertyName: 'transform' })};
  color: ${({ theme }) => theme.colors.primary1};
  transform: translate(32.84px, 10.67px) rotate(5deg);

  .${controllingClassName}:focus &&,
  .${controllingClassName}:hover && {
    transform: rotate(0) translate(16.7px, 12.7px);
  }
`

const Pdf = styled.g`
  ${transition({ propertyName: 'transform' })};
  color: ${({ theme }) => theme.colors.error1};
  transform: translate(0.97px, 21.41px) rotate(-15deg);

  .${controllingClassName}:focus &&,
  .${controllingClassName}:hover && {
    transform: rotate(0) translate(32.7px, 24.7px);
  }
`
