import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const InformationSection = styled.div`
  border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.light};
  border-radius: 4px;
  padding: ${g(1)} ${g(2)};
  margin-top: ${g(2)};
  margin-bottom: ${g(2)};

  ${({ isEditable, theme }) =>
    isEditable &&
    css`
      border-color: ${theme.legacy.colors.accent1.default};
    `}
`
export const Rows = styled.div`
  display: grid;
  grid-template-columns: ${g(15)} 1fr;
  align-items: flex-start;
  overflow: auto;
`
