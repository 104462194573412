import Summary from '@pretto/bricks/app/confirm/components/Summary'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import Container from '@pretto/bricks/components/layout/Container'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import BubbleChat from '@pretto/bricks/shared/components/BubbleChat'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import * as S from './styles'

const ConfirmPage = ({
  avatarProps,
  config,
  disclaimers,
  errorMessage,
  isSubmittable,
  isSubmitting,
  loanValues,
  onConfirm,
  projectValues,
  showConditions,
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheck = () => setIsChecked(isChecked => !isChecked)
  const withCheckbox = !!config.checkboxLabel

  return (
    <Wrapper>
      <Container full align="left">
        <S.Container>
          <S.Header>
            {config.messages.map((message, i) => (
              <S.Message key={i}>
                <BubbleChat avatarProps={avatarProps} hideAvatar={i > 0}>
                  {message}
                </BubbleChat>
              </S.Message>
            ))}
          </S.Header>

          <S.Wrapper>
            <S.Detail>
              <Summary title={config.projectTitle} values={projectValues} />
            </S.Detail>

            <S.Detail>
              <Summary title={config.loanTitle} values={loanValues} />
            </S.Detail>

            {showConditions && (
              <S.Detail>
                <Card format="line" variant="neutral-1-20">
                  <Content>
                    <S.Title>
                      <SubHeading size="large">{config.conditionsTitle}</SubHeading>
                    </S.Title>

                    <div>{config.conditionsContent}</div>
                  </Content>
                </Card>
              </S.Detail>
            )}

            {disclaimers &&
              disclaimers.map((disclaimer, i) => (
                <S.Disclaimer key={i}>
                  <Card variant="accent-1-l">
                    <Content>{disclaimer}</Content>
                  </Card>
                </S.Disclaimer>
              ))}

            {withCheckbox && (
              <S.Check>
                <InputCheckbox id="confirm" isChecked={isChecked} label={config.checkboxLabel} onChange={handleCheck} />
              </S.Check>
            )}

            {errorMessage && (
              <S.Alert>
                <Card variant="error" format="line">
                  <Content variant="error-l">
                    <Text variant="error">{errorMessage}</Text>
                  </Content>
                </Card>
              </S.Alert>
            )}

            {isSubmittable && (
              <S.ButtonContainer>
                <ButtonLegacy onClick={onConfirm} disabled={withCheckbox && !isChecked}>
                  <S.ButtonText loading={isSubmitting}>{config.button}</S.ButtonText>
                  {isSubmitting && (
                    <S.ButtonSpinner>
                      <SpinnerLegacy size="small" variant="white" />
                    </S.ButtonSpinner>
                  )}
                </ButtonLegacy>
              </S.ButtonContainer>
            )}
          </S.Wrapper>
        </S.Container>
      </Container>
    </Wrapper>
  )
}

ConfirmPage.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Texts configuration. */
  config: PropTypes.shape({
    button: PropTypes.string,
    checkboxLabel: PropTypes.string,
    conditionsContent: PropTypes.string,
    conditionsTitle: PropTypes.string,
    loanTitle: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string),
    projectTitle: PropTypes.string.isRequired,
  }).isRequired,
  /** Disclaimer cards. */
  disclaimers: PropTypes.arrayOf(PropTypes.node),
  /** Error message */
  errorMessage: PropTypes.node,
  /** Hides submit button when not submittable. */
  isSubmittable: PropTypes.bool,
  /** Defines state of submit button. */
  isSubmitting: PropTypes.bool,
  /** Values shown under the second summary component. */
  loanValues: PropTypes.array,
  /** Event handler when confirming the project. */
  onConfirm: PropTypes.func.isRequired,
  /** Values shown under the first summary component. */
  projectValues: PropTypes.array,
  /** Whether conditions should be shown or not. */
  showConditions: PropTypes.bool,
}

ConfirmPage.defaultProps = {
  loanValues: [],
  projectValues: [],
}

export default memo(ConfirmPage)
