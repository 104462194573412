import docsConfig from '@pretto/config/docs.json'

export const getDocumentsForDocumentsParameters = (documentsParameters, data) =>
  data.docs.filter(({ kind, meta }) =>
    Object.entries(documentsParameters).reduce((previous, [key, value]) => {
      switch (key) {
        case 'category':
          return previous && docsConfig[kind].category === value

        case 'mortgagor':
          if (value === 'all' || data.project.profile.mortgagors.length === 1) {
            return previous
          }

          if (meta.mortgagor === null) {
            return previous && value === 'null'
          }

          return previous && meta.mortgagor.toString() === value.toString()

        default:
          return previous
      }
    }, true)
  )
