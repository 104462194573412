import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled, { css } from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`

export const BestOffer = styled(BaseTypo)`
  margin-bottom: ${g(1.75)};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    flex-direction: row;
    gap: ${g(7)};
    justify-content: space-between;
  }
`

export const MainValue = styled(BaseTypo).attrs({ typo: 'heading2', isNumber: true })`
  color: ${({ theme }) => theme.colors.primary2};
`

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Info = styled.div`
  display: flex;
  align-items: baseline;
`

export const Label = styled(BaseTypo).attrs({ typo: 'caption' })`
  margin-right: ${g(0.5)};
`

export const InfoValue = styled(BaseTypo).attrs(({ $isBold }) => ({
  isNumber: true,
  typo: $isBold ? 'bodyBold16' : 'bodyBook16',
}))`
  ${({ $color, theme }) =>
    $color &&
    css`
      color: ${theme.colors[$color]};
    `}
`
