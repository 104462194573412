import temporal from '@pretto/bricks/core/utility/temporal'

export const getDiffTimezone = (timezoneReference = 'Europe/Paris'): number => {
  const now = temporal().toISOString()

  const nowInCurrentTimezone = temporal.tz(now, temporal.tz.guess())
  const nowInTimezoneReference = temporal.tz(now, timezoneReference)

  return nowInTimezoneReference.diff(nowInCurrentTimezone, 'hour')
}

export const getOffTimezone = (timezoneReference = 'Europe/Paris'): string | null => {
  const diff = getDiffTimezone(timezoneReference)

  if (diff !== 0) {
    return temporal.tz.guess()
  }

  return null
}
