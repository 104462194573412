import styled from 'styled-components'

export interface FileOwnershipLabelProps {
  names: [string] | [string, string]
}

export const FileOwnershipLabel: React.FC<React.HTMLAttributes<HTMLSpanElement> & FileOwnershipLabelProps> = ({
  names,
  ...props
}) => (
  <Component {...props}>
    De : <Names>{names.join(' et ')}</Names>
  </Component>
)

const Component = styled.span`
  ${({ theme }) => theme.typos.body4};
  color: ${({ theme }) => theme.colors.neutral1};
`

const Names = styled.span`
  color: ${({ theme }) => theme.colors.neutral2};
`
