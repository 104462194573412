import temporal from '@pretto/bricks/core/utility/temporal'

import range from 'lodash/range'

export const populateCache = ({ availabilities, cache, fromDateTime, toDateTime }) => {
  const fromDateTimeStartOf = fromDateTime.startOf('day')
  const toDateTimeStartOf = toDateTime.startOf('day')

  const cacheStartDateTime = temporal.min(
    cache[0]?.value ? temporal(cache[0].value) : fromDateTimeStartOf,
    fromDateTimeStartOf
  )
  const cacheEndDateTime = temporal.max(
    cache[cache.length - 1]?.value ? temporal(cache[cache.length - 1].value) : toDateTimeStartOf,
    toDateTimeStartOf
  )

  const dDays = cacheEndDateTime.diff(cacheStartDateTime, 'day')

  return range(dDays + 1).map(index => {
    const currentDateTime = cacheStartDateTime.add(index, 'day')

    const availabilitiesForCurrentDateTime = availabilities.filter(({ start: value }) =>
      temporal(value).isSame(currentDateTime, 'day')
    )

    const cachedDay = cache.find(({ value }) => currentDateTime.isSame(temporal(value), 'day'))

    const slots = availabilitiesForCurrentDateTime
      .reduce((previous, { start: value }) => {
        if (previous.some(({ value: slotValue }) => slotValue === value)) {
          return previous
        }

        const availabilityDateTime = temporal(value)

        return [...previous, { label: availabilityDateTime.format('HH:mm'), value }]
      }, cachedDay?.slots ?? [])
      .sort(({ value: valueA }, { value: valueB }) => new Date(valueA) - new Date(valueB))

    const day = currentDateTime.format('dddd')
    const dayShort = currentDateTime.format('ddd')
    const date = currentDateTime.format('DD/MM')

    return {
      day,
      dayShort,
      date,
      slots,
      value: currentDateTime.toISOString(),
    }
  })
}
