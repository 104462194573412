import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { SecondaryButton as SecondaryButtonComponent } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { t } from '@pretto/app/src/lib/i18n'
import { EBOOK_URL } from '@pretto/config-front/constants'

import styled from 'styled-components'

export interface GuideCardProps {
  onDownloadClick?: () => void
}

export const GuideCard: React.FC<React.HTMLAttributes<HTMLDivElement> & GuideCardProps> = ({
  onDownloadClick,
  ...props
}) => (
  <Component {...props}>
    <Content>
      <Header>
        <Hat>{t('guideCard.discover')}</Hat>
        <Title>{t('guideCard.title')}</Title>
      </Header>

      <Description>{t('faqCard.description')}</Description>

      <DownloadButton href={EBOOK_URL} onClick={onDownloadClick}>
        {t('guideCard.download')}
      </DownloadButton>
    </Content>

    <Image alt={t('guideCard.imgAlt')} path="v1697543229/reveal/static-asssets/components/guidecard/cover-1.png" />
  </Component>
)

const Component = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(4)};
  justify-content: space-between;
  overflow: hidden;
  padding: ${g(2)} ${g(2)} 0 ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    padding: ${g(2)} ${g(3)} ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(3)} ${g(4)} ${g(4)};
  }
`

const Content = styled.main`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-grow: 1;
  }
`

const DownloadButton = styled(SecondaryButtonComponent).attrs({ scheme: 'black' })`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-start;
  }
`

const Hat = styled.header`
  ${({ theme }) => theme.typos.heading6};
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

const Image = styled(BaseImage).attrs({
  sizes: `(min-width: ${breakpoints.laptop}) ${g(21)}, ${g(28)}`,
  srcSetBreakpoints: [
    // laptop sizes in x1, x2 et x3
    ng(21),
    ng(21 * 2),
    ng(21 * 3),
    // mobile sizes in x1, x2 et x3
    ng(28),
    ng(28 * 2),
    ng(28 * 3),
  ],
})`
  border-radius: ${g(1 / 2)};
  box-shadow: ${g(1)} ${g(1)} 0 0 ${({ theme }) => addAlpha(theme.colors.neutral2, 0.4)};
  display: block;
  margin-bottom: ${g(-18)};
  width: ${g(28)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(-11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
    width: ${g(21)};
  }
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};
`
