import Icon from '@pretto/bricks/components/iconography/Icon'

import PropTypes from 'prop-types'

import * as S from './styles'

const Tag = ({ children, iconVariant, onClick, size, textVariant, variant, ...props }) => {
  const isClickable = onClick && size !== 'x-small'

  return (
    <S.Tag
      as={isClickable ? 'button' : 'span'}
      onClick={isClickable ? onClick : () => {}}
      size={size}
      textVariant={textVariant}
      variant={variant}
      {...props}
    >
      {children}
      {isClickable && (
        <S.Icon>
          <Icon name="cross-circle" variant={iconVariant || 'inherit'} />
        </S.Icon>
      )}
    </S.Tag>
  )
}

Tag.defaultProps = {
  size: 'medium',
  variant: 'neutral-1',
}

Tag.propTypes = {
  /** Tag content. */
  children: PropTypes.node.isRequired,
  /** Color variant to be used on the icon. */
  iconVariant: PropTypes.string,
  /** Event triggered whenever the tag is being pressed. */
  onClick: PropTypes.func,
  /** Tag size: <code>small</code>, <code>medium</code> or <code>large</code>. */
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  /** Tag text color variant. */
  textVariant: PropTypes.string,
  /** Tag background color variant. */
  variant: PropTypes.string,
}

export default Tag
