import { breakpoints, g } from '@pretto/bricks/components/layout'

import {
  ButtonSquare as ButtonSquareComponent,
  Scheme,
} from '@pretto/zen/reveal/atoms/buttons/buttonSquare/ButtonSquare'

import { Picto } from '@pretto/picto'

import styled from 'styled-components'

interface ChoiceItem {
  description: string
  key: string
  onClick: () => void
  picto: Picto
  scheme?: Scheme
  title: string
}

export interface ChoicesProps {
  items: ChoiceItem[]
}

export const Choices: React.FC<ChoicesProps & React.HTMLAttributes<HTMLUListElement>> = ({ items, ...props }) => (
  <Component {...props}>
    {items.map(({ description, key, onClick, picto: Picto, title, scheme = 'green' }) => {
      const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
        if (event.key === 'Enter') {
          onClick()
        }
      }

      return (
        <Choice key={key} onClick={onClick} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
          <ChoiceBullet>
            <Picto />
          </ChoiceBullet>

          <ChoiceContent>
            <p>
              <strong>{title}</strong>
            </p>

            {description !== '' && <ChoiceDescription>{description}</ChoiceDescription>}
          </ChoiceContent>

          <ButtonSquare size="small" scheme={scheme} tabIndex={-1} type="button" />
        </Choice>
      )
    })}
  </Component>
)

const ButtonSquare = styled(ButtonSquareComponent)`
  flex-shrink: 0;
`

const Choice = styled.li`
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  cursor: pointer;
  display: flex;
  gap: ${g(2)};
  padding: ${g(2)};

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`

const ChoiceBullet = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(3)};
  display: none;
  flex-shrink: 0;
  height: ${g(6)};
  justify-content: center;
  width: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`

const ChoiceContent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${g(1 / 2)};
`

const ChoiceDescription = styled.p`
  ${({ theme }) => theme.typos.caption};
`

const Component = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`
