import {
  MainContainer,
  RightContainer,
} from '@pretto/bricks/app/application/pages/ApplicationIntroductionPage/ApplicationIntroduction.styles'
import { breakpoints, g, tablet } from '@pretto/bricks/components/layout'
import { Container } from '@pretto/bricks/components/layout/Container/styles'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { FileMissing as FileMissingComponent } from '@pretto/zen/dashboard/files/FileMissing/FileMissing'

import styled from 'styled-components'

export const Main = styled(MainContainer)`
  overflow: auto;
`

export const Right = styled(RightContainer)`
  height: 100%;
  overflow: scroll;
`

export const Wrapper = styled.div`
  ${column([2, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

const Title = styled.h1`
  ${typo.heading32}
`

export const ModalTitle = styled(Title)`
  align-self: flex-start;
`

export const GridTitle = styled(Title)`
  margin-top: ${g(7)};
`

export const Alert = styled.div`
  margin-top: ${g(4)};

  a {
    text-decoration: underline;

    &:focus,
    &:hover {
      outline: 0px transparent;
      text-decoration: none;
    }
  }
`

export const Button = styled(Container)`
  padding-top: ${g(4)};
  text-align: center;

  @media only screen and (min-width: ${tablet.min}) {
    padding-top: ${g(6)};
  }
`

export const FileMissing = styled(FileMissingComponent)`
  margin-top: ${g(4)};
`

export const Divider = styled.div`
  margin-top: ${g(6)};
`

export const Form = styled.div`
  padding: ${g(2)} 0 ${g(5)};

  @media only screen and (min-width: ${tablet.min}) {
    padding: ${g(4)} 0 ${g(7)};
  }
`

export const ModalForm = styled(Form)`
  align-self: flex-start;
  width: 100%;
`

export const Section = styled.div`
  & + & {
    margin-top: ${g(4)};

    @media only screen and (min-width: ${tablet.min}) {
      margin-top: ${g(6)};
    }
  }
`
