import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { Project } from '@pretto/app/src/types/gateway/schema'

export const mapId: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)

  if (context.id) updatedPayload.id = context.id

  return {
    context,
    payload: updatedPayload,
  }
}
