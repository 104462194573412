import Color from 'color'

import definition, { createDefinition } from './definition'

export const colors = definition.compile()

export const compileColors = palette => {
  const definition = createDefinition(palette)
  return definition.compile()
}

export const variant = {
  dark({ variant }) {
    return colors[variant].dark.default
  },

  default({ variant }) {
    return colors[variant].default
  },

  light({ variant }) {
    return colors[variant].light.default
  },

  opposite({ variant }) {
    return colors[variant].opposite.default
  },
}
