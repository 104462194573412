import { breakpoints, g } from '@pretto/bricks/components/layout'
import { heading16 } from '@pretto/bricks/core/typography'
import * as typography from '@pretto/bricks/core/typography'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'
import {
  DefaultMessage,
  FollowTooltip as FollowTooltipComponent,
} from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'

import { Details as DetailsComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/Details/Details'
import { Payment as PaymentComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/Payment/Payment'
import { InfoCircleBold, Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

import { formatPicto } from './lib/formatPicto'
import { formatValue } from './lib/formatValue'

interface Details {
  label: string
  value: string
}

interface EntryInterface {
  label: string
  tooltip?: string
}

interface EntryCalculation extends EntryInterface {
  type: 'plus' | 'minus' | 'equal'
  value: string
}

interface EntryNote extends EntryInterface {
  value: number
}

interface EntryPicto extends EntryInterface {
  picto: PictoType
  value: string | boolean
}

export type EntryType = EntryPicto | EntryCalculation | EntryNote

type Section = {
  entries: EntryType[]
  title: string
}

export interface ModaleResultProps {
  buttonLabel: string
  details: Details[]
  onClick: () => void
  payment: string
  sections: Section[][]
}

export const ModaleResult: React.FC<ModaleResultProps> = ({ buttonLabel, details, onClick, payment, sections }) => (
  <div>
    <Bank>
      Banque 1<sup>*</sup> :
    </Bank>

    <Header>
      <PaymentComponent value={payment} />
      <DetailsComponent details={details} />
    </Header>

    {sections.map((row, index) => (
      <Row key={index}>
        {row.map(({ entries, title }) => (
          <div key={title}>
            <BlocTitle>{title}</BlocTitle>

            <Entries>
              {entries.map(entry => {
                const picto = formatPicto(entry)

                return (
                  <Entry key={entry.label}>
                    {picto && <Picto>{picto}</Picto>}

                    <Label>{entry.label}</Label>

                    {entry.tooltip && (
                      <FollowTooltip messageComponent={ResizedFollowTooltipComponent} message={entry.tooltip}>
                        <Tooltip />
                      </FollowTooltip>
                    )}

                    <Value>{formatValue(entry.value)}</Value>
                  </Entry>
                )
              })}
            </Entries>
          </div>
        ))}
      </Row>
    ))}

    <Footer>
      <Button isArrowShown onClick={onClick}>
        {buttonLabel}
      </Button>
    </Footer>
  </div>
)

const Bank = styled.div`
  ${typography.bodyMedium16};
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
  }
`

const BlocTitle = styled.div`
  ${heading16};
  margin-bottom: ${g(2)};
`

const Button = styled(ButtonComponent)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
  }
`

const Entries = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Entry = styled.li`
  gap: ${g(1)};
  display: flex;
`

const FollowTooltip = styled(FollowTooltipComponent)`
  align-self: flex-start;
  margin-top: ${g(1 / 2)};
`

const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  margin-top: ${g(-1)};
  padding: ${g(2)} 0;
  position: sticky;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  align-items: center;
  display: grid;
  margin-bottom: ${g(4)};
  row-gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: ${g(6)};
  }
`

const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Picto = styled.span`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${g(3)};
  justify-content: center;
  margin-right: ${g(1)};
  width: ${g(3)};
`

const ResizedFollowTooltipComponent = styled(DefaultMessage)`
  z-index: 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(38)};
  }
`

const Row = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  display: flex;
  flex-direction: column;
  padding: ${g(4)} 0;
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    column-gap: ${g(6)};
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const Tooltip = styled(InfoCircleBold)`
  color: ${({ theme }) => theme.colors.neutral3};
  display: block;
`

const Value = styled.span`
  margin-left: auto;
  flex-shrink: 0;
`
