import { getValue } from './value'

export const getEditableFields = ({
  contribution,
  isLoggedIn,
  property_kind,
  projectKind,
  purchase,
  projectChanges,
}) => {
  // Custom editable fields depending on the project and property kind
  let editableFields = []

  if (projectKind === 'purchase') {
    const { property_price, land_price, build_price, works_price } = purchase

    switch (property_kind) {
      case 'old':
        editableFields = [
          {
            key: 'propertyPrice',
            label: 'Prix du bien',
            path: 'purchase.property_price',
            value: getValue(projectChanges, 'purchase.property_price', property_price),
          },
        ]
        if (works_price !== null || isLoggedIn) {
          editableFields.push({
            key: 'worksPrice',
            label: 'Travaux',
            path: 'purchase.works_price',
            value: getValue(projectChanges, 'purchase.works_price', works_price || 0),
          })
        }
        break
      case 'new':
      case 'VEFA':
        editableFields = [
          {
            key: 'propertyPrice',
            label: 'Prix du bien',
            path: 'purchase.property_price',
            value: getValue(projectChanges, 'purchase.property_price', property_price),
          },
        ]
        break
      case 'construction':
        editableFields = [
          {
            key: 'buildPrice',
            label: 'Construction',
            path: 'purchase.build_price',
            value: getValue(projectChanges, 'purchase.build_price', build_price),
          },
        ]
        break
      case 'land_construction':
        editableFields = [
          {
            key: 'landPrice',
            label: 'Terrain',
            path: 'purchase.land_price',
            value: getValue(projectChanges, 'purchase.land_price', land_price),
          },
          {
            key: 'buildPrice',
            label: 'Construction',
            path: 'purchase.build_price',
            value: getValue(projectChanges, 'purchase.build_price', build_price),
          },
        ]
        break
      default:
        editableFields = []
    }
  }

  editableFields.push({
    key: 'contribution',
    label: 'Apport',
    path: 'contribution',
    value: getValue(projectChanges, 'contribution', contribution) || 0,
  })

  return editableFields
}
