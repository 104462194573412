import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Detail = styled.div`
  margin-bottom: ${g(2)};
`
export const CardTitle = styled.div`
  margin-bottom: ${g(3)};
`
export const Row = styled.div`
  margin-bottom: ${g(3)};

  &:last-child {
    margin-bottom: 0;
  }
`
export const Check = styled.div`
  margin-top: ${g(5)};
`
export const ButtonContainer = styled.div`
  margin-top: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }
`
