import { breakpoints } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Wrapper = ({ children, ...props }) => <S.Wrapper {...props}>{children}</S.Wrapper>

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  full: PropTypes.oneOf([...Object.keys(breakpoints), true]),
}

export default memo(Wrapper)
