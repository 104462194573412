import InviteFormPageComponent from '@pretto/bricks/app/invite/pages/InviteFormPage'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import * as filters from '@pretto/app-core/lib/filters'
import { getDefaultValues } from '@pretto/app-core/lib/getDefaultValues'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { renderFields } from '@pretto/app-core/lib/renderFields'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { INVITE_COMORTGAGOR } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'
import sectionSchema from '../../config/invitation'

const isSubmitDisabled = (errors, fields, values) =>
  fields.some(field => !isFieldValid(values[field.name])) || Object.values(errors).some(error => error === true)

const decoder = filters.applyFilters([filters.applyFilter('phone', filters.formatDecodePhone)])

const encoder = filters.applyFilters([
  filters.applyFilter('email', filters.formatEncodeEmail),
  filters.applyFilter('firstName', filters.formatEncodeProprify),
  filters.applyFilter('lastName', filters.formatEncodeProprify),
  filters.applyFilter('phone', filters.formatEncodePhone),
])

const InviteFormPage = ({ history }) => {
  const { refetch } = useAuth()

  const [inviteComortgagor] = useMutation(INVITE_COMORTGAGOR)

  const { notifyLegacy } = useNotifications()

  const [errors, setErrors] = useState({})
  const [mutationError, setMutationError] = useState(false)
  const [mutationLoading, setMutationLoading] = useState(false)
  const [values, setValues] = useState({})

  const isLoading = useLoading(!values || mutationLoading)

  const trackAction = useTracking()

  const userContext = useUser()

  useEffect(() => {
    if (userContext.comortgagorInvited) {
      history.replace('/')
    }

    init()
  }, [])

  const handleChange = (name, value, error) => {
    setErrors({ ...errors, [name]: funcToItem(error, userContext) })
    setValues({ ...values, [name]: value })
  }

  const handleMutation =
    values =>
    async (cache, { data }) => {
      if (data.invite_mortgagor.error) {
        setMutationError(true)
        setMutationLoading(false)

        trackAction('SHAREPROJECT_ERROR_RAISED', {
          shareproject_error_email: values.email,
          shareproject_error_source: 'dashboard',
          shareproject_error_type: data.invite_mortgagor.error,
        })

        return
      }

      await refetch()

      trackAction('SHAREPROJECT_INVITATION_SENT', {
        shareproject_invitation_email: values.email,
        shareproject_invitation_phoneNumber: values.phone,
        shareproject_invitation_source: 'dashboard',
      })

      notifyLegacy(
        'Invitation envoyée',
        'Votre co-emprunteur vient de recevoir un mail pour activer son compte Pretto.',
        { delay: 5000 }
      )

      history.push('/account')
    }

  const handleSubmit = async () => {
    const variables = await encoder(values)

    setMutationLoading(true)

    inviteComortgagor({
      update: handleMutation(variables),
      variables,
    })
  }

  const init = async () => {
    const comortgagorAccount = userContext.accounts.find(({ id }) => id !== userContext.user.id) ?? {}

    const defaultValues = getDefaultValues([sectionSchema], comortgagorAccount)
    const decodedValues = await decoder(defaultValues, comortgagorAccount)

    setValues(decodedValues)
  }

  if (isLoading) {
    return null
  }

  const sectionProps = {
    ...sectionSchema,
    fields: renderFields(sectionSchema.fields, handleChange, values),
  }

  return (
    <div>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <InviteFormPageComponent
        error={mutationError}
        isSubmitDisabled={isSubmitDisabled(errors, sectionSchema.fields, values)}
        onSubmit={handleSubmit}
        sectionProps={sectionProps}
      />
    </div>
  )
}

InviteFormPage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired, replace: PropTypes.func.isRequired }).isRequired,
}

export default InviteFormPage
