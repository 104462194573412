import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export interface CompletionPromptProps {
  isFilled: boolean
  children: React.ReactNode
  onClick?: () => void
}

interface ContainerProps {
  $isFilled: boolean
}

export const CompletionPrompt: React.FC<CompletionPromptProps> = ({
  children,
  isFilled = false,
  onClick,
  ...props
}) => (
  <Container $isFilled={isFilled} onClick={onClick} {...props}>
    {children}
  </Container>
)

const Container = styled.span<ContainerProps>`
  width: fit-content;
  border-radius: ${g(0.5)};
  padding: ${g(0.5)} ${g(1)};
  background-color: ${({ theme }) => theme.colors.neutral5};

  ${({ $isFilled, theme }) =>
    $isFilled
      ? css`
          color: ${theme.colors.primary1};
          ${theme.typos.body4Bold}
        `
      : css`
          color: ${theme.colors.neutral3};
          ${theme.typos.body4}
        `}
  line-height: ${g(5)};
`
