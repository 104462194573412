import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral1};
  background-color: ${({ theme }) => theme.colors.white};
  line-height: ${g(6)};
  height: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    line-height: ${g(8)};
    height: ${g(8)};
  }
`
export const Title = styled(BaseTypo).attrs({ typo: 'heading4' })`
  display: inline-block;
  vertical-align: middle;
  padding: ${g(1)} ${g(6)};
  font-size: ${g(2, 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(1)} ${g(8)};
    font-size: ${g(3, -2)};
  }
`
export const PictoWrapper = styled.button.attrs(({ $side }) => ({
  role: 'button',
  side: $side,
  tabIndex: 0,
}))`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
  height: ${g(6)};
  width: ${g(6)};

  ${({ $side }) => {
    if ($side === 'left') {
      return css`
        left: 0;
      `
    }
    if ($side === 'right') {
      return css`
        right: 0;
      `
    }
  }}

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(8)};
    width: ${g(8)};

    ${({ $isBorderRounded }) =>
      $isBorderRounded &&
      css`
        &:focus {
          &::before {
            top: 0;
            left: 0;

            ${({ $side }) => {
              if ($side === 'left') {
                return css`
                  border-top-left-radius: ${g(1)};
                `
              }
              if ($side === 'right') {
                return css`
                  border-top-right-radius: ${g(1)};
                `
              }
            }}
          }
        }
      `}
  }
`
export const Picto = styled.button.attrs(({ $picto }) => ({
  as: $picto,
}))`
  height: ${g(2, 2)};
  width: ${g(2, 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(2, 4)};
    width: ${g(2, 4)};
  }
`
