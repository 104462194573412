import PropTypes from 'prop-types'

import * as S from './styles'

const TimelineSidebar = ({ steps }) => (
  <S.TimelineContainer>
    {steps.map(({ href, onClick, title }, index) => (
      <S.TimelineLink key={index} href={href} onClick={onClick}>
        <S.ChevronLeft />
        {title}
      </S.TimelineLink>
    ))}
  </S.TimelineContainer>
)

TimelineSidebar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default TimelineSidebar
