import { DataContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import type { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'
import { Unit } from '@pretto/app/src/Capacity/views/ResultPage/types/benefit'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'

import { useContext } from 'react'

import { BlocSuggestionsLayoutProps } from '../../views/ResultPage/components/BlocSuggestionsLayout/BlocSuggestionsLayout'
import { SuggestionSlimProps } from '../../views/ResultPage/components/SuggestionSlim/SuggestionSlim'

import { getLoadingLabel } from './getLoadingLabel'
import { getSuggestionSlim } from './getSuggestion'
import { useSuggestions } from './useSuggestions'

export type SuggestionSlim = SuggestionSlimProps & {
  id: string
}

export const useBlocSuggestionsLoggedOutProps = ({
  onContinue,
  ...props
}: ResultPageProps): BlocSuggestionsLayoutProps => {
  const { data: capacityData, loadingResults: isCapacityLoading, selectedLoanDuration } = useContext(DataContext)

  const {
    data: suggestionsData,
    isLoading: isSuggestionsLoading,
    numberOfEligibleSuggestionsName,
    numberOfLoadedSuggestions,
  } = useSuggestions()

  const duration = ensureNotNil(selectedLoanDuration)
  const capacityResult = capacityData.capacity.find(({ financing }) => duration === financing.loanDuration)

  return {
    ctaTitle: props.isSuggestionsHidden ? "J'accède aux recommandations" : "J'accède aux détails",
    description:
      'Bonne nouvelle ! Nous avons identifié pour vous un ou plusieurs leviers qui vous permettraient d’augmenter votre capacité d’achat. Les équipes de Pretto sont là pour vous guider à travers ces recommandations.',
    ...props,
    isLoading: isCapacityLoading || isSuggestionsLoading,
    isSlimSuggestions: true,
    loadingLabel: isCapacityLoading
      ? getLoadingLabel(0, 0)
      : getLoadingLabel(numberOfEligibleSuggestionsName, numberOfLoadedSuggestions),
    get suggestions() {
      return (
        suggestionsData?.reduce<SuggestionSlim[]>((previous, { result: suggestionResult, type }) => {
          const suggestion = getSuggestionSlim(type)

          if (
            !suggestion ||
            !capacityResult ||
            suggestionResult.project.purchase.property_price < capacityResult.project.purchase.property_price + 2000
          ) {
            return previous
          }

          return [
            ...previous,
            {
              benefit: {
                unit: Unit.Price,
                value:
                  suggestionResult.project.purchase.property_price - capacityResult.project.purchase.property_price,
              },
              id: type.toString(),
              title: suggestion.title,
              value: suggestionResult.project.purchase.property_price,
            },
          ]
        }, []) ?? []
      )
    },
    onContinue(event) {
      onContinue?.(event, 'suggestions')
    },
    title: 'Augmentez votre pouvoir d’achat !',
  }
}
