import { createMortgagor } from '@pretto/app/src/Sentences/v2/lib/factories/mortgagor'
import type { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'
import type { Mortgagor as PayloadMortgagor } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

import { mapBonus, mapIncome, mapRevenues } from './compensation'
import { mapLocationType } from './location'
import {
  mapContractType,
  mapExecutiveRole,
  mapOccupationStatus,
  mapOccupationType,
  mapStartDate,
  mapTrialPeriod,
} from './occupation'
import { mapSituationType } from './situation'

export const mapMortgagor = (payloadMortgagor: PayloadMortgagor): Mortgagor => {
  const contextMortgagor: Mortgagor = {
    id: payloadMortgagor.id ?? null,
    age: payloadMortgagor.age ?? null,
    birthdate: payloadMortgagor.birthdate ? new Date(payloadMortgagor.birthdate) : undefined,
    expatriate: payloadMortgagor.expatriate_status ?? null,
    situationType: payloadMortgagor.housing ? mapSituationType(payloadMortgagor.housing) : null,
    rent: payloadMortgagor.rent ?? undefined,

    income: payloadMortgagor.salary ? mapIncome(payloadMortgagor.salary) : null,
    bonus: payloadMortgagor.bonus ? mapBonus(payloadMortgagor.bonus) : null,
    hasBonus: !!payloadMortgagor.bonus?.amount,
    revenues: payloadMortgagor.incomes ? mapRevenues(payloadMortgagor.incomes) : undefined,
    paysChildSupport: !isNil(payloadMortgagor.child_support),
    childSupport: payloadMortgagor.child_support ?? undefined,

    contractType: mapContractType(payloadMortgagor.contract),
    occupationType: mapOccupationType(payloadMortgagor.contract),
    executiveRole: mapExecutiveRole(payloadMortgagor.contract),
    startDate: mapStartDate(payloadMortgagor.job?.start_date),
    status: mapOccupationStatus(payloadMortgagor.contract),
    trialPeriod: mapTrialPeriod(payloadMortgagor.contract),

    locationType: payloadMortgagor.address?.country ? mapLocationType(payloadMortgagor.address.country) : null,
    country: payloadMortgagor.address?.country ?? null,
    address: payloadMortgagor.address?.street ?? undefined,
    city: payloadMortgagor.address?.city ?? undefined,
    zipcode: payloadMortgagor.address?.zipcode ?? undefined,
  }

  if (payloadMortgagor.job?.start_date)
    contextMortgagor.startMonth = new Date(payloadMortgagor.job.start_date).getMonth().toString()

  if (payloadMortgagor.job?.start_date)
    contextMortgagor.startYear = new Date(payloadMortgagor.job.start_date).getFullYear().toString()

  return createMortgagor(contextMortgagor)
}
