import capitalize from 'lodash/capitalize'

const REGEXES = {
  "d'": "d' *",
  'de la': 'de +la\\b',
  des: 'des\\b',
  de: 'de\\b',
  du: 'du\\b',
  '-': ' *- *',
  ' ': ' +',
}

const masterRegex = new RegExp(`${Object.values(REGEXES).join('|')}|[^\\s-]+`, 'gi')

export const proprify = name =>
  name.trim().replace(masterRegex, match => {
    const fragment = Object.keys(REGEXES).find(regex => new RegExp(`^${REGEXES[regex]}$`, 'i').test(match))

    if (fragment) {
      return fragment
    }

    return capitalize(match)
  })
