import { getFieldWithName } from '@pretto/app-core/lib/getFieldWithName'
import { getSectionWithFieldName } from '@pretto/app-core/lib/getSectionWithFieldName'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import defaultTo from 'lodash/defaultTo'

export const getFieldConditionWithName = (name, sections, values, ...args) => {
  const field = getFieldWithName(name, sections, values)

  if (!field) {
    return true
  }

  const section = getSectionWithFieldName(name, sections, values)

  const sectionCondition = funcToItem(defaultTo(section.condition, true), ...args)
  const fieldCondition = funcToItem(defaultTo(field.condition, true), values, ...args)

  return sectionCondition && fieldCondition
}
