import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

import { SnackbarProps } from './Snackbar'

interface ColorsProps {
  $backgroundColor: string
  $color: string
}

interface PictoProps {
  $picto: PictoType
  $status: NonNullable<SnackbarProps['status']>
}

export const Container = styled.div`
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 999;
  padding: ${g(1)};
  pointer-events: all;
  margin: auto;
  width: max-content;
`

export const Content = styled.div<ColorsProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: ${g(1)};
  padding: ${g(2)} ${g(4)} ${g(2)} ${g(2)};
  color: ${({ theme, $color }) => theme.colors[$color]};
  background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
`

export const Picto = styled.div.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  margin-right: ${({ $status }) => ($status === 'alert' ? g(2) : g(1))};
  flex-shrink: 0;
`

export const Text = styled.span`
  ${typo.bodyMedium16};
`
