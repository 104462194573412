export const VARIANTS = {
  'best_result.financing.applicationFees': 'accent-1',
  'best_result.financing.bridge.remaining': 'primary-1-60',
  'best_result.financing.bridge.value': (hasContribution: boolean) => (hasContribution ? 'accent-4-60' : 'accent-4-d'),
  'best_result.financing.contribution': 'accent-4-d',
  'best_result.financing.guarantyFees': 'accent-1',
  'best_result.financing.loanAmount': 'accent-4',
  'best_result.financing.penaltyFees': 'primary-1-60',
  'best_result.financing.ptz.amount': 'accent-4',
  'brokerage_fees.amount': 'accent-1',
  'project.facts.agency_fees': 'accent-1',
  'project.facts.notary_fees': 'accent-1',
  'project.purchase.build_price': 'primary-1',
  'project.purchase.furniture_price': 'primary-1-60',
  'project.purchase.property_price': 'primary-1',
  'project.purchase.land_price': 'primary-1-60',
  'project.purchase.works_price': 'primary-1-60',
  'project.renegotiation.remaining_principal': 'primary-1',
  'project.renegotiation.works_amount': 'primary-1-60',
}
