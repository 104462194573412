import PropTypes from 'prop-types'

import Dialog from '../../../components/overlays/Dialog'

import * as S from './styles'

const Prompt = ({ buttonTitle, description, isOpen, linkTitle, onContinueClick, onCancelClick, onClose, title }) => {
  const handleContinueClick = () => {
    onContinueClick()
    onClose()
  }

  const handleCancelClick = () => {
    onCancelClick()
    onClose()
  }

  return (
    <Dialog isOpen={isOpen} onRequestClose={onClose}>
      <S.Container>
        <S.Header>
          <S.Title>{title}</S.Title>
          <S.HeaderIcon onClick={onClose} />
        </S.Header>
        <S.Description>{description}</S.Description>

        <S.Footer>
          <S.StyledButton onClick={handleContinueClick}>{buttonTitle}</S.StyledButton>
          {linkTitle && <S.StyledButtonFeature label={linkTitle} onClick={handleCancelClick} />}
        </S.Footer>
      </S.Container>
    </Dialog>
  )
}

Prompt.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  linkTitle: PropTypes.string,
  onCancelClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default Prompt
