import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { CheckBold } from '@pretto/picto'

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  ${column([2, 4])}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])}
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
  }
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
    margin-bottom: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
  }
`

const ImageContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  margin: ${g(4)} 0;
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(4)};
    margin: ${g(2.5)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(6)};
    margin: 0;
  }
`

const Image = styled(BaseImage)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Reasons = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([5, 4])}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 5])};
    display: grid;
    align-self: center;
  }
`

const Reason = styled.div`
  display: grid;
  grid-template-columns: ${g(4)} 1fr;
  grid-template-rows: ${g(4)} 1fr;
  column-gap: ${g(3)};

  & + & {
    margin-top: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${grid(4, { isLeftAligned: true, isRightAligned: true })};
  }
`

const PictoContainer = styled.div`
  height: ${g(4)};
  width: ${g(4)};
  ${column(1)}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.neutral5};
  margin-right: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-right: 0;
    justify-self: flex-end;
  }
`

const Check = styled(CheckBold)`
  width: ${g(1.5)};
  height: ${g(1.5)};
`

const ReasonTitle = styled.h3`
  ${({ theme }) => theme.typos.heading5};
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 3])}
  }
`

const Message = styled.p`
  ${column([2, 1])}

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 3])}
  }
`

const REASONS = [
  {
    title: 'Transparence',
    message: 'Nos courtiers vous disent tout et défendent vos intérêts.',
  },
  {
    title: 'Expertise',
    message: 'Nous traitons tous les projets (Primo-accédant, prêts relais, non-résidents, SCI...).',
  },
  {
    title: 'Implication',
    message: 'Restez en contact avec votre expert (visio, sms, téléphone).',
  },
]

export const Rtb: React.FC = () => {
  return (
    <Container>
      <Title>Le crédit immo, comme ça devrait être</Title>
      <ImageContainer>
        <Image alt="Equipe pretto" path="v1689847769/team/all/team_pretto_app.jpg" />
      </ImageContainer>
      <Reasons>
        {REASONS.map(({ title, message }) => (
          <Reason key={title}>
            <PictoContainer>
              <Check />
            </PictoContainer>
            <ReasonTitle>{title}</ReasonTitle>
            <Message>{message}</Message>
          </Reason>
        ))}
      </Reasons>
    </Container>
  )
}
