const email = {
  fieldProps: {
    icon: 'mail',
    placeholder: 'bruce.wayne@gmail.com',
  },
  label: 'Adresse mail',
  name: 'email',
  required: true,
  type: 'email',
}

const firstname = {
  fieldProps: {
    placeholder: 'Bruce',
  },
  label: 'Prénom',
  name: 'firstname',
  required: true,
  type: 'textfield',
}

const lastname = {
  fieldProps: {
    placeholder: 'Wayne',
  },
  label: 'Nom',
  name: 'lastname',
  required: true,
  type: 'textfield',
}

const phone = {
  fieldProps: {
    icon: 'phone',
    placeholder: '07 12 34 56 78',
  },
  label: 'Téléphone',
  name: 'phone',
  required: true,
  type: 'tel',
}

const schema = [firstname, lastname, email, phone]

export default schema
