import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import CenterGridTemplate from '@pretto/bricks/shared/templates/CenterGridTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const LoginExpiredPage = ({ href }) => (
  <CenterGridTemplate>
    <S.Container>
      <S.Image />
      <S.Title>Ouuups !</S.Title>
      <S.Description>Il semblerait que votre lien de connexion soit expiré.</S.Description>
      <ButtonInline isSecondary href={href}>
        Se reconnecter
      </ButtonInline>
    </S.Container>
  </CenterGridTemplate>
)

LoginExpiredPage.propTypes = {
  href: PropTypes.string.isRequired,
}

export default LoginExpiredPage
