import { css, FlattenSimpleInterpolation } from 'styled-components'

export const defaultDurationInteger = 400
export const defaultDuration = `${defaultDurationInteger}ms`
export const defaultEasing = 'cubic-bezier(0.25, 1, 0.5, 1)'

interface TransitionOptions {
  delay?: string
  duration?: string
  easing?: string
  propertyName: string
}

const interpolate = ({
  propertyName,
  delay,
  duration = defaultDuration,
  easing = defaultEasing,
}: TransitionOptions): string => [propertyName, duration, easing, delay].filter(Boolean).join(' ')

export const transition = (...options: TransitionOptions[]): string | FlattenSimpleInterpolation => css`
  transition: ${options.map(interpolate).join(', ')};
`
