import { ArrowButton } from '@pretto/zen/reveal/atoms/buttons/arrowButton/ArrowButton'
import { Loader } from '@pretto/zen/reveal/atoms/loader/Loader'
import { useCarousel } from '@pretto/zen/reveal/lib/useCarousel'

import { SuggestionLarge } from '@pretto/app/src/Capacity/Containers/lib/useBlocSuggestionsLoggedInProps'
import { SuggestionSlim } from '@pretto/app/src/Capacity/Containers/lib/useBlocSuggestionsLoggedOutProps'
import type { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'

import { createElement, useRef } from 'react'

import * as S from './BlocSuggestionsLayout.styles'

interface BlocSuggestionsLayoutBaseProps extends ResultPageProps {
  ctaTitle: string
  description: string
  isLoading?: boolean
  loadingLabel?: string
  title: string
}

interface BlocSlimSuggestionLayoutProps {
  isSlimSuggestions: true
  suggestions: SuggestionSlim[]
}

interface BlocLargeSuggestionLayoutProps {
  isSlimSuggestions: false
  suggestions: SuggestionLarge[]
}

export type BlocSuggestionsLayoutProps = BlocSuggestionsLayoutBaseProps &
  (BlocSlimSuggestionLayoutProps | BlocLargeSuggestionLayoutProps)

export const BlocSuggestionsLayout: React.FC<BlocSuggestionsLayoutProps & React.HTMLAttributes<HTMLDivElement>> = ({
  continueHref,
  ctaTitle,
  description,
  isLoading = false,
  isSlimSuggestions,
  isSuggestionsHidden,
  loadingLabel,
  onContinue,
  suggestions,
  title,
  ...props
}) => {
  const refScrollingContainer = useRef<HTMLDivElement>(null)

  const suggestionComponent = isSlimSuggestions ? S.SuggestionSlim : S.SuggestionLarge

  const {
    currentPageIndex,
    getIsIndexVisible,
    scrollToIndex,
    scrollTowardsIndex,
    scrollUntilNext,
    scrollUntilPrevious,
  } = useCarousel({
    active: !isLoading,
    container: refScrollingContainer,
    numberOfSlides: suggestions.length,
  })

  const handleDotClick = (index: number) => {
    scrollToIndex(index)
  }

  const handleNext = () => {
    scrollUntilNext()
  }

  const handlePrevious = () => {
    scrollUntilPrevious()
  }

  const layoutProps = {
    $isSingle: suggestions.length === 1,
    $numberOfSlides: suggestions.length,
  }

  if (isLoading) {
    return (
      <S.LoadingComponent>
        <Loader color="primary1" />
        {loadingLabel && <S.LoadingLabel>{loadingLabel}</S.LoadingLabel>}
      </S.LoadingComponent>
    )
  }

  return (
    <S.Component {...props}>
      <S.Content {...layoutProps}>
        <S.Title>{title}</S.Title>

        <S.Description>{description}</S.Description>
      </S.Content>

      {!isSuggestionsHidden && (
        <>
          <S.Scroll {...layoutProps} ref={refScrollingContainer}>
            <S.Suggestions {...layoutProps}>
              {suggestions.map(({ id, ...props }, index) => {
                const handleClick = () => {
                  if (!getIsIndexVisible(index)) {
                    scrollTowardsIndex(index)
                  }
                }

                return createElement(suggestionComponent, {
                  ...props,
                  ...layoutProps,
                  $isOutOfBounds: !getIsIndexVisible(index),
                  className: 'review',
                  onClick: handleClick,
                  key: id,
                })
              })}
            </S.Suggestions>
          </S.Scroll>

          {suggestions.length > 1 && (
            <S.Navigation {...layoutProps}>
              <ArrowButton direction="left" onClick={handlePrevious} />
              <ArrowButton direction="right" onClick={handleNext} />
              {/* waiting for chromium to fix the bug that affects scrollIntoView to stop when a DOM repaint is triggered
          <ArrowButton disabled={currentPageIndex === 0} direction="left" onClick={handlePrevious} />
          <ArrowButton disabled={currentPageIndex === reviews.length - 1} direction="right" onClick={handleNext} /> */}
            </S.Navigation>
          )}

          {suggestions.length > 1 && (
            <S.PaginationDots
              {...layoutProps}
              onClick={handleDotClick}
              currentIndex={currentPageIndex}
              length={suggestions.length}
              visibleLength={Math.min(3, suggestions.length)}
            />
          )}
        </>
      )}

      <S.Action {...layoutProps}>
        <S.Button href={continueHref()} onClick={onContinue}>
          {ctaTitle}
        </S.Button>
      </S.Action>
    </S.Component>
  )
}
