import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'
import type { TrustpilotRateProps } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import * as S from './ProjectPartnerChoice.styles'

interface PartnerProps {
  actionHref: string
  description: React.ReactNode
  onAction?: () => void
  trustpilotRateProps: TrustpilotRateProps
}

export const ProjectPartnerChoice: React.FC<PartnerProps> = ({
  actionHref,
  description,
  onAction,
  trustpilotRateProps,
}) => {
  const contentHat = useBreakpointToValue('Votre prêt en seulement 3 étapes :', { laptop: 'Obtenez votre prêt en' })

  return (
    <S.View>
      <S.ImageContainer>
        <S.Image alt="" path="v1697619391/app/pages/partner-project-choice/clients.jpg" />
        <S.TrustpilotRateImage {...trustpilotRateProps} alignment="twolines" />
      </S.ImageContainer>

      <S.Header>
        <S.Titles>
          <S.Hat>Bienvenue chez Pretto</S.Hat>
          <S.Title>Simulez votre prêt immobilier</S.Title>
        </S.Titles>

        <S.HeaderActionBlock>
          <p>{description}</p>

          <S.HeaderActionButton href={actionHref} onClick={onAction}>
            Simuler mon prêt
          </S.HeaderActionButton>
        </S.HeaderActionBlock>
      </S.Header>

      <S.Content>
        <S.ContentTitles>
          <S.Hat>{contentHat}</S.Hat>
          <S.ContentTitle>seulement 3 étapes :</S.ContentTitle>
        </S.ContentTitles>

        <S.Steps>
          {[
            <>
              Remplissez les informations dans notre simulateur. Ça ne vous prendra que <strong>3 min</strong> !
            </>,
            'Vous obtenez une première estimation de vos mensualités et de votre taux.',
            'Prenez rendez-vous avec l’un de nos courtiers pour un accompagnement sur mesure.',
          ].map((description, index) => (
            <S.Step key={index}>
              <S.StepIndex>{(index + 1).toString().padStart(2, '0')}</S.StepIndex>
              <S.StepDescription>{description}</S.StepDescription>
            </S.Step>
          ))}
        </S.Steps>

        <S.TrustpilotRateFooter {...trustpilotRateProps} />
      </S.Content>
    </S.View>
  )
}
