import Button from '@pretto/bricks/components/buttons/Button'
import ButtonIline from '@pretto/bricks/components/buttons/ButtonInline'
import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const MandateSuccess = styled.div`
  ${grid()};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: ${g(5)};
`
export const Container = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])}
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])}
  }
`
export const Illustration = styled(IllustrationComponent).attrs({
  options: {
    height: 160,
    width: 160,
  },
})`
  margin-bottom: ${g(4)};
  height: 160px;
  width: 160px;
`

export const Tilte = styled.div`
  ${typo.heading48};
  margin-bottom: ${g(3)};
`
export const Text = styled.div`
  ${typo.bodyBook16};
  margin-bottom: ${g(3)};
  text-align: center;
`
export const FirstButton = styled(Button).attrs({ isArrowShown: true, isSecondary: true })`
  margin-bottom: ${g(4)};
`
export const SecondButton = styled(ButtonIline).attrs({ isSecondary: true })``
