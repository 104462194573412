import { frames } from './config/banks'
import * as constants from './config/constants'
import * as S from './styles'

const SpinnerSimulation = props => (
  <S.Stage {...props}>
    <S.Orbit $r={constants.ORBIT_SMALL_RADIUS} />
    <S.Orbit $r={constants.ORBIT_LARGE_RADIUS} />

    <Planet $a={50} $d={constants.ORBIT_SMALL_RADIUS} $f={frames.bred.frame} $m="reverse" />
    <Planet $a={165} $d={constants.ORBIT_SMALL_RADIUS} $f={frames.bp.frame} $m="reverse" />
    <Planet $a={220} $d={constants.ORBIT_SMALL_RADIUS} $f={frames.bnp.frame} $m="reverse" />
    <Planet $a={340} $d={constants.ORBIT_SMALL_RADIUS} $f={frames.caisse_epargne.frame} $m="reverse" />
    <Planet $a={40} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.credit_agricole.frame} />
    <Planet $a={95} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.smc.frame} />
    <Planet $a={170} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.credit_mutuel.frame} />
    <Planet $a={250} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.societe_generale.frame} />
    <Planet $a={275} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.banque_postale.frame} />
    <Planet $a={355} $d={constants.ORBIT_LARGE_RADIUS} $f={frames.lcl.frame} />

    <S.Sun />
  </S.Stage>
)

const Planet = props => (
  <S.PlanetRevolution {...props}>
    <S.PlanetTransformation {...props}>
      <S.PlanetBody {...props} />
    </S.PlanetTransformation>
  </S.PlanetRevolution>
)

export default SpinnerSimulation
