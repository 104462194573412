import ResultCardMain from '@pretto/bricks/app/simulator/components/ResultCardMain'
import ResultCardSecondary from '@pretto/bricks/app/simulator/components/ResultCardSecondary'

import PropTypes from 'prop-types'
import { createElement, PureComponent } from 'react'

import * as S from './styles'

export default class ResultCards extends PureComponent {
  static propTypes = {
    blockInfoProps: PropTypes.shape({
      imgPath: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
    /** Array of <code>[ResultCard](/#!/ResultCard)</code> props coponents. */
    cards: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAppointmentAvailable: PropTypes.bool,
    isOnboardingDone: PropTypes.bool,
    /** Function to call when click on card */
    onCardClick: PropTypes.func,
    /** Function to call when click on CTA in card */
    onCardCTAClick: PropTypes.func,
    /** Project infos */
    projectProps: PropTypes.shape({
      contribution: PropTypes.number,
      ProjectKind: PropTypes.string,
      purchasePrice: PropTypes.number,
    }),
    typology: PropTypes.oneOf(['prospect', 'preLead', 'client']),
  }

  render() {
    const {
      blockInfoProps,
      cards,
      isAppointmentAvailable,
      projectProps,
      onCardClick,
      onCardCTAClick,
      isOnboardingDone,
      typology,
    } = this.props

    return (
      <>
        {cards.map((card, index) => {
          const props = {
            ...card,
            isAppointmentAvailable,
          }

          return (
            <S.Container key={index}>
              {createElement(index === 0 ? ResultCardMain : ResultCardSecondary, {
                blockInfoProps,
                projectProps,
                ...props,
                isOnboardingDone,
                onCardClick,
                onCardCTAClick,
                typology,
              })}
            </S.Container>
          )
        })}
      </>
    )
  }
}
