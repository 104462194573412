import { overlay } from '@pretto/bricks/assets/utility/extended-styles/generic'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { SecondaryButton as SecondaryButtonCpmnt } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'

import styled, { css } from 'styled-components'

const TRANSITION_DELAY = '300ms'

export const Overlay = styled.div<{ $isOpen: boolean }>`
  ${overlay};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
  opacity: 0;
  visibility: hidden;
  transition: all ${TRANSITION_DELAY} ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const PopUp = styled.div<{ $isOpen: boolean }>`
  position: relative;
  padding: ${g(3)} ${g(2, 4)};
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  transform: translateY(${g(-6)});
  opacity: 0;
  visibility: hidden;
  transition: all ${TRANSITION_DELAY} ease-in-out;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    padding: ${g(4)};
    width: initial;
    height: initial;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    `}
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CloseButton = styled.button`
  margin-bottom: ${g(1)};
  align-self: flex-end;
  height: ${g(2)};
  width: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    cursor: pointer;
    position: absolute;
    top: ${g(4)};
    right: ${g(4)};
  }
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
    margin-bottom: ${g(3)};
  }
`

export const Title = styled.div`
  ${typo.heading32};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
  }
`

export const ExpertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const AdvisorPicture = styled(BaseImage).attrs({
  options: {
    crop: 'fill',
    gravity: 'face',
    height: ng(9),
    quality: 85,
    width: ng(9),
  },
})`
  border-radius: 50%;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

export const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: unset;
  }
`

export const BoldDescription = styled.p`
  display: inline;
  ${({ theme }) => theme.typos.body4Bold};
`

export const ActionsContainer = styled.div`
  margin-top: ${g(5)};
  display: flex;
  flex-direction: column;
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
    align-items: center;
    justify-content: center;
  }
`

export const SecondaryButton = styled(SecondaryButtonCpmnt)`
  align-self: center;
`

export const PhoneButton = styled.button`
  ${({ theme }) => theme.typos.body4Underline};
  display: flex;
  align-self: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.neutral1};
  height: ${g(3)};
`
