import ConsentManagerComponent from '@pretto/bricks/shared/components/ConsentManager'
import CookieBanner from '@pretto/bricks/shared/components/CookieBanner'

import { loadPreferences } from '@segment/consent-manager'
import { useState } from 'react'

const ConsentManager = () => {
  const cookieBarPropsAlreadyDisplayed = Object.keys(loadPreferences()).length > 0

  const [isCookieBannerActive, setIsCookieBannerActive] = useState(false)
  const [isHardClosed, setIsHardClosed] = useState(false)

  const [cookieBarProps, setCookieBarProps] = useState({
    onAcceptAllClick: () => setIsHardClosed(true),
    onParametersClick: () => setIsCookieBannerActive(true),
    onRefuseAllClick: () => setIsHardClosed(true),
    isVisible: true,
  })

  const onClose = () => setIsCookieBannerActive(false)
  const onShowCookieBanner = () => setIsCookieBannerActive(true)

  const getCookieBarProps = cookieProps => {
    setCookieBarProps(cookieProps)
  }

  return (
    <>
      <ConsentManagerComponent
        apiWriteKey={process.env.SEGMENT_API_KEY_APP}
        onShowCookieBanner={onShowCookieBanner}
        onClose={onClose}
        isCookieBannerActive={isCookieBannerActive}
        getCookieBarProps={getCookieBarProps}
      />
      {!isHardClosed && !cookieBarPropsAlreadyDisplayed && cookieBarProps.isVisible && (
        <CookieBanner {...cookieBarProps} />
      )}
    </>
  )
}

export default ConsentManager
