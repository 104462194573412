import { addFilter, applyFilter, applyFilters, applyOmitFilter } from '@pretto/app-core/lib/filters'

export const solicitedBroker = {
  id: 'sollicited_broker',
  decoder: applyFilters([
    applyFilter(
      'isSolicitedBroker',
      (value, values, { project: { solicited_brokers } }) => (solicited_brokers.length > 0 ? true : null),
      false
    ),
    applyFilter(
      'isMandateSigned',
      (value, values, { project: { solicited_brokers } }) =>
        solicited_brokers[0]?.status === 'mandate_signed' ? true : null,
      false
    ),
  ]),
  encoder: applyFilters([
    addFilter('project.solicited_brokers', (values, { project: { solicited_brokers } }) => {
      if (!values.isSolicitedBroker) {
        return solicited_brokers.map(broker => ({ ...broker, _delete: true }))
      }

      if (!values.isMandateSigned) {
        return [
          { name: 'other', ...solicited_brokers[0], duration: null, rate: null, status: null },
          ...solicited_brokers.slice(1),
        ]
      }

      return [{ name: 'other', ...solicited_brokers[0], status: 'mandate_signed' }, ...solicited_brokers.slice(1)]
    }),
    applyOmitFilter('isMandateSigned', 'isSolicitedBroker'),
  ]),
  fields: [
    {
      type: 'boolean',
      name: 'isSolicitedBroker',
      label: 'Avez-vous entamé des démarches avec un courtier ?',
      track({ value }) {
        return ['ONBOARDING_SOLLICITED_BROKER_CLICKED', { onboarding_sollicited_broker_value: value }]
      },
      jumpToNext({ value }) {
        return value === false
      },
    },
    {
      type: 'boolean',
      name: 'isMandateSigned',
      label: 'Avez-vous signé avec ce courtier ?',
      condition({ values }) {
        return values.isSolicitedBroker
      },
      track({ value }) {
        return ['ONBOARDING_MANDATE_CLICKED', { onboarding_mandate_value: value }]
      },
    },
  ],
  jumps({ values }) {
    if (values.isSolicitedBroker && values.isMandateSigned) {
      return 'sollicited_broker_success'
    }

    return 'avoid_banks'
  },
}
