// deprecated
// use instead: @pretto/app/src/lib/form/useTextField
import BaseInput from '@pretto/bricks/components/form/BaseInput'

import { useState } from 'react'

import useFieldController from './useFieldController'

const useTextField = ({ defaultValue = '', inputProps, ...rootProps } = {}) => {
  const [value, setValue] = useState(defaultValue)

  const { stateProps, ...fieldControls } = useFieldController(value, { inputProps })

  const handleChange = value => {
    setValue(value)
  }

  const props = {
    ...rootProps,
    ...stateProps,
    inputProps: {
      ...inputProps,
    },
    onChange: handleChange,
    value,
  }

  const component = <BaseInput {...props} />

  return { ...fieldControls, component, setValue, value }
}

export default useTextField
