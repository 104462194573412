import { PhoneNumberUtil } from 'google-libphonenumber'

const REGEX_PHONE = /^[\d-+() ]+$/

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export const validatePhone = (phone: string): boolean => {
  if (!REGEX_PHONE.test(phone)) {
    return false
  }

  try {
    const parsedPhone = phoneNumberUtil.parse(phone, 'FR')

    return phoneNumberUtil.isValidNumber(parsedPhone)
  } catch (error) {
    return false
  }
}
