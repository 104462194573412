import { EstimatedPricePage } from '@pretto/app/src/Sentences/v2/Sale/EstimatedPrice/Containers/EstimatedPricePage'
import { PaidOffPage } from '@pretto/app/src/Sentences/v2/Sale/PaidOff/Containers/PaidOffPage'
import { RemainingMortgagePage } from '@pretto/app/src/Sentences/v2/Sale/RemainingMortgage/Containers/RemainingMortgage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const SaleController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(capacity|purchase)/sale/estimatedPrice')}
        component={EstimatedPricePage}
      />

      <Route path={graph.matchRoute('/project/(capacity|purchase)/sale/paidOff')} component={PaidOffPage} />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase)/sale/remainingMortgage')}
        component={RemainingMortgagePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
