import { g } from '@pretto/bricks/components/layout'

import { RevenueBoxes } from '@pretto/app/src/Sentences/v2/Income/Revenue/Containers/RevenueBoxes/RevenueBoxes'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/v2/components/Styled/Divider/Divider'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/v2/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { createRevenue } from '@pretto/app/src/Sentences/v2/lib/factories/revenue'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'

import styled from 'styled-components'

const Description = styled.p`
  margin-bottom: ${g(2)};
`

export const RevenuePage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute))

  if (!mortgagors || !mortgagors.length) {
    return null
  }

  const descriptionString = [
    t('sentences.income.revenue.values.rent'),
    t('sentences.income.revenue.values.childSupport'),
    t('sentences.income.revenue.values.professionalBenefits'),
    t('sentences.income.revenue.values.familyAllowances'),
    t('sentences.income.revenue.values.expatriationBonus'),
  ].join(', ')

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'revenue' } }}>
      <Title>{t('sentences.income.revenue.title')}</Title>
      <Description>({descriptionString}).</Description>
      {!!mortgagors[0].revenues && (
        <RevenueBoxes
          activeField={activeField}
          onPromptClick={() => {
            setActiveField(0)
          }}
          onChoiceClick={() => {
            setActiveField(1)
          }}
        />
      )}
      <DividerWithMarginBottom />
      <TrackedButtonInline
        events={{ eventName: EventName.SimulationElementClicked, eventPayload: { element: 'revenueAddValue' } }}
        onClick={() => {
          setActiveField(0)
          // trouver un moyen de faire ouvrir le bon editableIndex
          setContext(({ mortgagors }) => ({
            mortgagors:
              mortgagors?.map(mortgagor => {
                if (mortgagor.id === mortgagors[0].id) {
                  return {
                    ...mortgagor,
                    revenues: [...(mortgagor.revenues ?? []), createRevenue()],
                  }
                }

                return mortgagor
              }) ?? [],
          }))
        }}
      >
        {t('sentences.income.revenue.addValue')}
      </TrackedButtonInline>
      <ButtonNext>{t(mortgagors?.[0].revenues?.length ? 'sentences.next' : 'sentences.skip')}</ButtonNext>
    </TrackedView>
  )
}
