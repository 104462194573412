import MaturityPageTemplate from '@pretto/bricks/app/maturity/components/MaturityPageTemplate'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import MaturityChoices from '@pretto/bricks/shared/components/MaturityChoices'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useApolloClient, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'

import Header from '../../../SharedContainers/Header'
import { UPDATE_PROJECT } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'
import { maturityValues } from '../../config/maturity'

export const MATURITY_EVENT = 'MATURITY_UPDATED'

const MaturityPage = ({ history }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const client = useApolloClient()
  const [updateProject] = useMutation(UPDATE_PROJECT)

  const trackAction = useTracking()

  const handleUpdate = async () => {
    await invalidateCache(client)
    history.push(`/`)
  }

  const handleClick = async option => {
    if (['search_started', 'under_study'].includes(option)) {
      setIsSubmitting(true)

      await updateProject({
        update: handleUpdate,
        variables: { project: JSON.stringify({ purchase: { maturity: option } }) },
      })

      trackAction(MATURITY_EVENT, {
        origin: 'dashboard_card',
        value: option,
      })

      return
    }

    history.push(`/maturity/property/${option}`)
  }

  if (isSubmitting) {
    return <SpinnerLegacy overlay />
  }

  const options = maturityValues.map(value => {
    return { onClick: handleClick, option: value }
  })

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <MaturityPageTemplate
        description="Mettre à jour votre projet permet de le faire avancer et d’adapter les conseils de Pretto à votre situation."
        title="Comment avance votre projet immobilier ?"
      >
        <MaturityChoices options={options} />
      </MaturityPageTemplate>
    </>
  )
}

MaturityPage.propTypes = {
  history: PropTypes.object,
}

export default MaturityPage
