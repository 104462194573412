import { Spinner } from '@pretto/zen/atoms/loaders/Spinner/Spinner'
import { Avatar } from '@pretto/zen/atoms/avatars/Avatar/Avatar'
import DialogComponent from '@pretto/bricks/components/overlays/Dialog'
import * as typo from '@pretto/bricks/core/typography'

import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as P from '@pretto/picto'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const Dialog = styled(DialogComponent)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${g(2.5)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
  }
`

export const ClosePicto = styled(P.Times)`
  position: absolute;
  top: ${g(3)};
  right: ${g(3)};
  cursor: pointer;
`

export const Title = styled.span`
  ${typo.heading32};
  margin-bottom: ${g(2)};
`

export const Description = styled.div`
  margin-bottom: ${g(1)};

  & > p + p {
    margin-top: ${g(2)};
  }
`

export const AgendaLink = styled(Link)`
  text-decoration: underline;
`

export const Advisor = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(2)};
`

export const Picture = styled(Avatar).attrs({ size: 'x-small' })`
  margin-right: ${g(2)};
`

export const Loader = styled(Spinner)`
  color: ${({ theme }) => theme.colors.primary2};
  margin-right: ${g(2)};
`

export const LoaderText = styled.span`
  color: ${({ theme }) => theme.colors.neutral2};
`

export const AdvisorTitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral2};
`

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  & > * + * {
    margin-top: ${g(2)};
  }
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    margin-top: ${g(2)};
    & > * + * {
      margin-top: 0;
      margin-left: ${g(3)};
    }
  }
`