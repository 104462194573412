import Divider from '@pretto/bricks/components/dividers/Divider'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import Tooltip from '@pretto/bricks/components/utility/Tooltip'
import { temporal } from '@pretto/bricks/core/utility/temporal'

import { Envelope, Phone } from '@pretto/picto'

import PropTypes from 'prop-types'
import React from 'react'

import * as S from './styles'

const TimelineAdvisor = ({ avatar, email, isVacationExpert, link, name, onCall, onOpen, role, holidays_end_date }) => {
  const formatedDate = isVacationExpert ? temporal(holidays_end_date).format('DD MMMM') : null

  return (
    <S.Advisor>
      <Divider />
      <Wrapper>
        <S.Wrapper>
          <S.TimelineAdvisorContainer>
            <S.AvatarContainer>{avatar}</S.AvatarContainer>

            <Responsive min="tablet">
              <S.Infos>
                <S.InfoTitle>{role !== 'expert' ? 'Votre conseiller Pretto' : 'Votre expert Pretto'}</S.InfoTitle>
                <S.ExpertName>{name}</S.ExpertName>
              </S.Infos>
            </Responsive>

            <S.Actions $isVisible={!isVacationExpert}>
              <Responsive max="tablet">
                <S.Icon onClick={onCall}>
                  <Phone />
                </S.Icon>
              </Responsive>
              <Responsive min="tablet">
                <Tooltip content="Contact">
                  <S.Icon onClick={onOpen}>
                    <Phone />
                  </S.Icon>
                </Tooltip>
              </Responsive>
              <Tooltip content="Email">
                <S.Icon>
                  <a href={`mailto:${email}`} target="blank">
                    <Envelope />
                  </a>
                </S.Icon>
              </Tooltip>
              {link && (
                <Tooltip content="Rendez-vous">
                  <S.Icon>{link}</S.Icon>
                </Tooltip>
              )}
            </S.Actions>

            {formatedDate && (
              <S.HolidaysContainer>
                <S.DateContainer>
                  Absent jusqu’au <strong>{formatedDate}</strong>
                </S.DateContainer>
                <S.MoreContainer onClick={onOpen.bind(null, { actionFrom: 'more_info' })}>
                  EN SAVOIR PLUS
                </S.MoreContainer>
              </S.HolidaysContainer>
            )}
          </S.TimelineAdvisorContainer>
        </S.Wrapper>
      </Wrapper>
    </S.Advisor>
  )
}

TimelineAdvisor.propTypes = {
  /** Advisor avatar component. */
  avatar: PropTypes.element,
  /** Advisor's email address. */
  email: PropTypes.string,
  /** End date for expert holiday. */
  holidays_end_date: PropTypes.string,
  isVacationExpert: PropTypes.bool,
  /** Booking link. */
  link: PropTypes.element,
  /** Advisor's full name. */
  name: PropTypes.string,
  /** Call function triggered when icon is clicked on mobile device. */
  onCall: PropTypes.func,
  /** Function triggered when phone icon is clicked. */
  onOpen: PropTypes.func,
  role: PropTypes.oneOf(['expert', 'sdr']).isRequired,
}
export default TimelineAdvisor
