import * as filters from '@pretto/app-core/lib/filters'
import { cloneElement } from 'react'

import { useField } from './Field'
import { useTextField } from '../../Form/lib/useField'

export const NameField = props => {
  const { Component, fieldProps } = useField(props)
  const { value, ...textFieldProps } = useTextField(fieldProps)

  const encodedValue = filters.formatEncodeProprify(value)

  return cloneElement(Component, { ...textFieldProps, initialValue: value, value: encodedValue })
}
