import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Times } from '@pretto/picto'

import React, { MouseEventHandler } from 'react'

import * as S from './styles'

interface DialogSaveProps {
  emailFieldComponent: React.ReactNode
  isSaveOpen: boolean
  onCguClick: MouseEventHandler<HTMLAnchorElement>
  onCloseSave: MouseEventHandler<SVGElement>
  onGoogleConnect: UnknownFunction
  onPrivacyClick: MouseEventHandler<HTMLAnchorElement>
}

export const DialogSave: React.FC<DialogSaveProps> = ({
  emailFieldComponent,
  isSaveOpen,
  onCguClick,
  onCloseSave,
  onGoogleConnect,
  onPrivacyClick,
}) => (
  <Dialog isOpen={isSaveOpen}>
    <S.DialogContainer>
      <S.Header>
        <S.Title>Sauvegardez votre simulation !</S.Title>
        <Times onClick={onCloseSave} />
      </S.Header>
      <S.GoogleConnect onClick={onGoogleConnect} />
      <S.Divider>— ou —</S.Divider>
      {emailFieldComponent}
      <S.CGU>
        En continuant, vous acceptez nos{' '}
        <S.CGULink href="https://www.pretto.fr/cgu/" onClick={onCguClick}>
          conditions d’utilisation
        </S.CGULink>{' '}
        et notre{' '}
        <S.CGULink href="/privacy" onClick={onPrivacyClick} target="blank">
          politique de confidentialité.
        </S.CGULink>{' '}
      </S.CGU>
    </S.DialogContainer>
  </Dialog>
)
