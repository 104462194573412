import { truncateName, truncateNames } from './truncateNames'

export const getMortgagorTruncatedName = <
  T extends { mortgagor?: number | null },
  U extends { firstName: string; lastName: string },
>(
  meta: T | undefined | null,
  accounts: U[]
) => {
  const names = accounts.map(({ firstName, lastName }) => ({ firstName, lastName }))

  const [mortgagor, comortgagor] = names

  if (!comortgagor) {
    return mortgagor.firstName
  }

  if (typeof meta?.mortgagor === 'number' && names[meta.mortgagor]) {
    return truncateName(names[meta.mortgagor])
  }

  return truncateNames(names)
}
