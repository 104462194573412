import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export interface FeedbackInputProps extends React.HTMLAttributes<HTMLButtonElement> {
  isActive?: boolean
}

export const FeedbackInput: React.FC<FeedbackInputProps> = ({ isActive = false, ...props }) => (
  <Input $isActive={isActive} type="button" {...props} />
)

const Input = styled.button<{ $isActive: boolean }>`
  border-radius: ${g(1)};
  padding: ${g(2)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${g(3)};
  line-height: ${g(3)};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.primary4};
      border-color: ${({ theme }) => theme.colors.primary1};
    `}
`
