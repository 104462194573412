import { createContext, PropsWithChildren, useContext, useState } from 'react'

interface TimelineContextInterface {
  isOpen: boolean
  toggle: () => void
  progress: number
  setProgress: React.Dispatch<React.SetStateAction<number>>
}

export const TimelineContext = createContext<TimelineContextInterface>({} as TimelineContextInterface)

export const TimelineContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const toggle = () => setIsOpen(prev => !prev)

  return (
    <TimelineContext.Provider
      value={{
        isOpen,
        toggle,
        progress,
        setProgress,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}

export const useTimeline = () => useContext(TimelineContext)
