import { applyAndRenameFilter, applyFilter, applyFilters } from '@pretto/app-core/lib/filters'

export const solicitedBrokerSuccess = {
  id: 'sollicited_broker_success',
  decoder: applyFilters([
    applyFilter(
      'isProposalMade',
      (value, values, { project: { solicited_brokers } }) =>
        typeof solicited_brokers[0]?.duration === 'number' || typeof solicited_brokers[0]?.rate === 'number'
          ? true
          : null,
      false
    ),
  ]),
  encoder: applyFilters([
    applyAndRenameFilter(
      'isProposalMade',
      'project.solicited_brokers',
      (value, values, { project: { solicited_brokers } }) => {
        if (!value) {
          return [{ ...solicited_brokers[0], duration: null, rate: null }, ...solicited_brokers.slice(1)]
        }

        return solicited_brokers
      }
    ),
  ]),
  fields: [
    {
      type: 'boolean',
      name: 'isProposalMade',
      label: 'Avez-vous reçu une proposition de la part de votre courtier ?',
      track({ value }) {
        return ['ONBOARDING_BROKER_SUCCESS_CLICKED', { onboarding_broker_success_value: value }]
      },
    },
  ],
  jumps({ values }) {
    if (values.isProposalMade) {
      return 'sollicited_broker_rates'
    }

    return 'avoid_banks'
  },
}
