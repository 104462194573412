import Card from '@pretto/bricks/components/cards/Card'
import IconComponent from '@pretto/bricks/components/iconography/Icon'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled from 'styled-components'

export const Content = styled.div`
  padding: ${g(2)};
  padding-top: 0px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
`

export const InternalCard = styled(Card).attrs({ variant: 'white' })`
  margin-bottom: ${g(3)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(3)};

  &:last-child {
    margin-bottom: 0px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${g(3)};
`

export const Icon = styled(IconComponent)`
  height: ${g(3)};
  width: ${g(3)};
  color: ${({ theme }) => theme.colors.neutral1};
`

export const Title = styled(BaseTypo).attrs({ typo: 'bodyBook16' })`
  margin-left: ${g(2)};
`

export const Picto = styled(Icon).attrs(({ $picto }) => ({ as: $picto }))``

export const DetailsContainer = styled.div`
  margin-bottom: ${g(3)};

  &:last-child {
    margin-bottom: 0px;
  }
`

export const PropertyTitle = styled(BaseTypo).attrs({ typo: 'bodyBook16' })`
  color: ${({ theme }) => theme.colors.neutral2};
`

export const ValueTitle = styled(BaseTypo).attrs({ typo: 'bodyBold16' })`
  color: ${({ theme }) => theme.colors.neutral1};
`
