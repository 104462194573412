import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { Advisor } from '@pretto/app/src/dashboard/views/components/Advisor/Advisor'

import { useState } from 'react'

import { ExpertAdvisorDialog } from '../../SharedContainers/AdvisorDialog'

export const ContactController: React.FC<{ isLink?: boolean }> = ({ isLink = false }) => {
  const { advisor, hasFeatureAccess } = useUser()

  const [isOpened, setIsOpened] = useState(false)

  const handleOpen = () => {
    setIsOpened(true)
  }

  const handleClose = () => {
    setIsOpened(false)
  }

  if (isLink) {
    return (
      <>
        <span onClick={handleOpen}>
          Demander l’attestation à mon {advisor.role === 'expert' ? 'expert' : 'conseiller'} Pretto
        </span>
        <ExpertAdvisorDialog advisor={advisor} isOpen={isOpened} onClose={handleClose} />
      </>
    )
  }

  if (hasFeatureAccess(['NO_APPOINTMENT'])) {
    return null
  }

  return <Advisor />
}
