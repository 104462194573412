import { BlockProps } from '@pretto/app/src/purchaseOffer/config/types'
import { Block } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/Block/Block'

import * as S from './Section.styles'

export interface SectionProps {
  title: string
  blocks: BlockProps[]
}

export const Section = ({ title, blocks }: SectionProps) => (
  <S.Section>
    <S.Title>{title}</S.Title>
    <S.Blocks>
      {blocks.map((blockProps, i) => (
        <Block key={i} {...blockProps} />
      ))}
    </S.Blocks>
  </S.Section>
)

Section.displayName = 'PurchaseOfferSection'
