import Button from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

export const BlockAppointment: React.FC = () => (
  <SideContainer>
    <Headline>Un expert vous accompagne</Headline>
    <Cta href="/simulation/subscribe?kind=appointment_result_page">Prendre RDV (gratuit)</Cta>
  </SideContainer>
)

const leftGrid = css`
  ${column([2, 4])};
  margin-right: ${g(3)};
`

const SideContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
  }
`

const Headline = styled.div`
  ${typo.headline16}
`

const Cta = styled(Button).attrs({ isSecondary: true })`
  margin-top: ${g(2)};
  width: 100%;
`
