import ApplicationCompleteListField from '@pretto/bricks/app/application/components/ApplicationCompleteListField'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export const CompleteListField = ({ formatTitle, onChange, renderFields, value }) => {
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState(value)

  useEffect(
    () =>
      onChange(
        values,
        Object.values(errors).some(error => error === true)
      ),
    [errors, values]
  )

  const handleChange = index => (name, value, error) => {
    setErrors(errors => ({ ...errors, [name]: error }))
    setValues(values => [...values.slice(0, index), { ...values[index], [name]: value }, ...values.slice(index + 1)])
  }

  const handleDelete = index => () =>
    setValues(values => [...values.slice(0, index), { ...values[index], _delete: true }, ...values.slice(index + 1)])

  const items = values.reduce((previous, { _delete, id, ...item }, index) => {
    if (_delete) {
      return previous
    }

    return [
      ...previous,
      {
        fields: renderFields(item, handleChange(index)),
        id,
        onDelete: handleDelete(index),
        title: formatTitle(item, index).toString(),
      },
    ]
  }, [])

  return <ApplicationCompleteListField items={items} />
}

CompleteListField.propTypes = {
  formatTitle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderFields: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
}
