import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import Placeholder from '@pretto/bricks/components/loading/Placeholder'
import { heading32 } from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const NegotiationPlaceholder: React.FC = () => (
  <Card>
    <Header>
      <Title>Suivi de votre dossier</Title>

      <P1 />
    </Header>

    <P2 />
    <P3 />
    <P4 />
  </Card>
)

const Card = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${g(3)};
  padding-bottom: ${g(3)};

  > * {
    ${column([2, -2])};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    padding: ${g(4)};
  }
`
const Header = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`
const Title = styled.div`
  ${heading32};
`
const BasePlaceholder = styled(Placeholder)`
  border-radius: ${g(1)};
`
const P1 = styled(BasePlaceholder)`
  height: ${g(2)};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(40)};
    margin-top: 0;
  }
`
const P2 = styled(BasePlaceholder)`
  margin-top: ${g(3)};
  height: ${g(3)};
`
const P3 = styled(P2)`
  margin-top: ${g(2)};
`
const P4 = styled(P3)`
  height: ${g(10)};
`
