import DashboardAvailableBookingCard from '@pretto/bricks/app/dashboard/components/DashboardAvailableBookingCard'
import { getPhoneOrVisio } from '@pretto/bricks/app/dashboard/components/DashboardAvailableBookingCard/lib/getPhoneOrVisio'
import DashboardBookingCardComponent from '@pretto/bricks/app/dashboard/components/DashboardBookingCard'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import BookingWidget from '@pretto/app/src/Booking/Containers/BookingWidget'
import { getOffTimezone } from '@pretto/app/src/Booking/lib/getOffTimezone'
import { getPicturePath } from '@pretto/app/src/Helpers/pictureUrl'
import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import { getBookingDateTime } from '@pretto/app/src/lib/getBookingDateTime'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useBookingCardQuery } from './booking.gateway.graphql'

const BOOKING_URL = '/booking'

const Booking: React.FC<CardProps> = ({ onActionClick }) => {
  const { onOpen } = useAdvisor()

  const history = useHistory()

  const { data, loading } = useBookingCardQuery()

  const { advisor, isVisioEnabled, isVisioMandatory } = useUser()

  const { notifyLegacy } = useNotifications()

  const trackAction = useTracking()

  useEffect(() => {
    if (data?.next_booking === null) {
      trackAction('BOOKING_WIDGET_DISPLAYED')
    }
  }, [data])

  const handleBooked = (
    duration: string,
    startTime: string,
    appointmentKind: string,
    availabilities: { count: number; daysSinceToday: number }[]
  ) => {
    const slotsCount = availabilities.map(({ count, daysSinceToday }) => ({ count, daysSinceToday }))

    trackAction('BOOKING_APPOINTMENT_BOOKED', {
      booking_appointment_duration: duration,
      booking_appointment_kind: appointmentKind,
      booking_appointment_rescheduled: data?.next_booking !== null,
      booking_appointment_slots_count: slotsCount,
    })

    notifyLegacy(
      'Rendez-vous confirmé',
      'Votre rendez-vous est confirmé. Vous allez recevoir un email de confirmation. Pensez à le noter dans votre agenda !',
      { delay: 5000 }
    )
  }

  if (loading) {
    return null
  }

  if (data?.next_booking) {
    const { date, duration, isNow } = getBookingDateTime(data.next_booking)
    const timezone = getOffTimezone()
    const advisorAvatarSrc = getPicturePath(advisor)

    return (
      <DashboardBookingCardComponent
        actionUrl={BOOKING_URL}
        date={date}
        duration={duration}
        advisorAvatarOnClick={onOpen}
        advisorAvatarSrc={advisorAvatarSrc}
        advisorName={advisor.name}
        advisorRole={advisor.role}
        isNow={isNow}
        isVisioEnabled={isVisioEnabled}
        isVisio={data.next_booking?.isVisio}
        timezone={timezone}
        visioUrl={advisor.visioUrl}
        visioLabel={advisor.visioUrl?.replace('https://', '')}
      />
    )
  }

  const handleIconClick = () => {
    onActionClick(BOOKING_URL)

    history.push(BOOKING_URL)
  }

  const description = `Choisissez un créneau pour échanger ${getPhoneOrVisio({
    isVisioEnabled,
    isVisioMandatory,
  })} avec votre ${advisor.role === 'expert' ? 'expert' : 'conseiller'} Pretto.`

  return (
    <DashboardAvailableBookingCard
      actionUrl={BOOKING_URL}
      description={description}
      onActionClick={onActionClick}
      onIconClick={handleIconClick}
    >
      <BookingWidget hideNavigation hideSeeMore maxRows={10} onBooked={handleBooked} />
    </DashboardAvailableBookingCard>
  )
}

export const config: Config = {
  condition: (dashboardData, { hasFeatureAccess, next_booking }) => {
    return !hasFeatureAccess(['NO_APPOINTMENT']) || !!next_booking?.start
  },
  component: Booking,
  name: 'booking',
}
