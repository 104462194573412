import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { TrustpilotRate as TrustpilotRateComponent } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import styled from 'styled-components'

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

export const Content = styled.main`
  ${column([1, 6])};
  ${grid(4)};
  background-color: ${({ theme }) => theme.colors.accent3};
  padding: ${g(5)} 0 ${g(6)};
  row-gap: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 10])};
    ${grid(8)};
    padding: ${g(5)} 0 ${g(7)};
    row-gap: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 14])};
    ${grid(12)};
    padding: ${g(11)} 0 ${g(14)};
    row-gap: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(12)} 0 ${g(15)};
  }
`

export const ContentTitle = styled.div`
  ${({ theme }) => theme.typos.heading4};
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
  }
`

export const ContentTitles = styled(Titles)`
  ${column([2, 4])};
  ${({ theme }) => theme.typos.heading4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
  }
`

export const Hat = styled.div`
  ${({ theme }) => theme.typos.heading6};
`

export const Header = styled.header`
  ${column([2, 4])};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(3)};
  margin-bottom: ${g(5)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([5, 5])};
    margin-bottom: 0;
    padding: ${g(3)} ${g(4)} ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 7])};
    padding: ${g(6)} ${g(7)} ${g(7)};
  }
`

export const HeaderActionBlock = styled.div`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
  }
`

export const HeaderActionButton = styled(PrimaryButtonFixComponent)``

export const Image = styled(ImageComponent).attrs({
  sizes: `(min-width: ${breakpoints.desktop}) ${g(62)}, (min-width: ${breakpoints.laptop}) 40vw, (min-width: ${
    breakpoints.tablet
  }) ${100 / 3}vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
  ],
})`
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export const ImageContainer = styled.div`
  ${column([1, 6])};
  height: ${g(35)};
  order: 3;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 3])};
    border-radius: ${g(1)};
    height: auto;
    order: 0;
    overflow: hidden;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 5])};
    height: auto;
  }
`

export const Steps = styled.ul`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    display: grid;
    gap: ${g(3)};
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const Step = styled.li`
  display: flex;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: ${g(1)};
  }
`

export const StepDescription = styled.p`
  ${({ theme }) => theme.typos.body4};
  padding-top: ${g(1)};
`

export const StepIndex = styled.div`
  ${({ theme }) => theme.typos.heading3};
  color: ${({ theme }) => theme.colors.accent2};
  flex-shrink: 0;
  height: ${g(4)};
  margin-top: ${g(-1, 1)};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading2};
    height: ${g(5, 2)};
    margin-top: ${g(-1, -2)};
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading3};
`

export const TrustpilotRateImage = styled(TrustpilotRateComponent)`
  background-image: linear-gradient(
    ${({ theme }) => addAlpha(theme.colors.neutral1, 0)},
    ${({ theme }) => theme.colors.neutral1}
  );
  bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  left: 0;
  padding: ${g(3)} 0 ${g(5)};
  position: absolute;
  right: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const TrustpilotRateFooter = styled(TrustpilotRateComponent)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    display: grid;
    justify-content: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const View = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(2)};
    row-gap: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(5)};
    row-gap: ${g(13)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    row-gap: ${g(14)};
  }
`
