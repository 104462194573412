import type { State } from '@pretto/bricks/app/dashboard/pages/DashboardPage/components/Steps/components/Step/Step'
import { g } from '@pretto/bricks/components/layout'
import { mod } from '@pretto/bricks/core/utility/math'

import { css } from 'styled-components'

import { calcColumnWithInItem } from './calcColumnWithInItem'
import { calcItemWidth } from './calcItemWidth'

const calcModSequencedIndex = (index: number, sequenceStartIndex: number, sequenceLength: number) =>
  mod(index - sequenceStartIndex, sequenceLength)

const calcStepIndex = (
  index: number,
  sequenceStartIndex: number,
  sequenceLength: number,
  numberOfColumnsInReversedRow: number
) => {
  const modSequencedIndex = calcModSequencedIndex(index, sequenceStartIndex, sequenceLength)
  const minIndexInSequence = index - modSequencedIndex
  const reverseOrder = 2 * minIndexInSequence - 1 + numberOfColumnsInReversedRow - index

  return index >= sequenceStartIndex && modSequencedIndex < numberOfColumnsInReversedRow ? reverseOrder : index
}

export const getStyleForStepIndex = (
  index: number,
  numberOfTotalSteps: number,
  stepState: State,
  sequenceStartIndex: number,
  sequenceLength: number,
  numberOfColumnsInReversedRow: number,
  numberOfTotalColumns: number
) => {
  const numberOfColumnsInRegularRow = sequenceLength - numberOfColumnsInReversedRow

  const modSequencedIndex = calcModSequencedIndex(index, sequenceStartIndex, sequenceLength)

  const isInInitialRow = index < sequenceStartIndex
  const isInReversedRow = !isInInitialRow && modSequencedIndex < numberOfColumnsInReversedRow
  const isInRegularRow = !isInInitialRow && modSequencedIndex >= numberOfColumnsInReversedRow

  const sequencedRowIndex = Math.floor((index + sequenceLength - sequenceStartIndex) / sequenceLength) * 2
  const rowIndex = isInReversedRow ? sequencedRowIndex - 1 : sequencedRowIndex

  const currentStepIndex = calcStepIndex(index, sequenceStartIndex, sequenceLength, numberOfColumnsInReversedRow)

  const maxStepIndex = calcStepIndex(
    numberOfTotalSteps,
    sequenceStartIndex,
    sequenceLength,
    numberOfColumnsInReversedRow
  )

  const isEvenRow = rowIndex % 2 === 0
  const isOddRow = rowIndex % 2 === 1

  const isFirstRow = rowIndex === 0
  const isLastStep = currentStepIndex === maxStepIndex

  const isRowFirstStep =
    (isInInitialRow && index === 1) ||
    (isInReversedRow && modSequencedIndex === numberOfColumnsInReversedRow - 1) ||
    (isInReversedRow && isLastStep) ||
    (isInRegularRow && modSequencedIndex === numberOfColumnsInReversedRow)

  const isRowLastStep =
    (isInInitialRow && index === sequenceStartIndex - 1) ||
    (isInReversedRow && modSequencedIndex === 0) ||
    (isInRegularRow && modSequencedIndex === sequenceLength - 1) ||
    (isInRegularRow && isLastStep)

  return css`
    &:nth-child(${index}) {
      flex-basis: calc(${calcItemWidth(numberOfTotalColumns - 1, numberOfColumnsInReversedRow, numberOfTotalColumns)});
      margin-left: 0;
      order: ${currentStepIndex};

      &:after,
      &:before {
        background-color: ${({ theme }) => theme.colors.neutral4};
        border-color: ${({ theme }) => theme.colors.neutral3};
        border-style: dashed;
        border-width: 0;
        content: none;
        top: ${g(1, 6)};

        ${stepState === 'done' &&
        css`
          border-color: ${({ theme }) => theme.colors.neutral2};
          border-style: solid;
        `}
      }

      &:after {
        background-color: ${({ theme }) => theme.colors.neutral4};
        border-top-width: ${g(1 / 4)};
        content: '';
        left: ${g(1, 7)};
        width: calc(100% + ${g(3)});
      }

      ${isInRegularRow &&
      css`
        flex-basis: calc(${calcItemWidth(numberOfTotalColumns - 2, numberOfColumnsInRegularRow, numberOfTotalColumns)});
      `}

      ${isInReversedRow &&
      (isLastStep || isRowFirstStep) &&
      (isLastStep || isRowFirstStep) &&
      css`
        margin-left: auto;
      `}

      ${isInRegularRow &&
      isRowFirstStep &&
      css`
        margin-left: calc(${calcItemWidth(numberOfTotalColumns, numberOfTotalColumns, numberOfTotalColumns)} + ${g(3)});
      `}

      ${stepState === 'pending' &&
      isInReversedRow &&
      css`
        &:after {
          border-color: ${({ theme }) => theme.colors.neutral2};
          border-style: solid;
        }
      `}

      ${isRowLastStep &&
      (isRowFirstStep || isLastStep) &&
      css`
        &:after {
          border-color: ${({ theme }) => theme.colors.neutral4};
          border-style: solid;
          width: 999em;
        }
      `}

      ${isInReversedRow &&
      isRowLastStep &&
      isRowFirstStep &&
      css`
        &:after {
          left: auto;
          right: calc(100% - ${g(1, 7)});
        }
      `}

      ${isRowLastStep &&
      !isRowFirstStep &&
      !isLastStep &&
      css`
        &:after {
          content: none;
        }
      `}

      ${isOddRow &&
      isRowFirstStep &&
      !isLastStep &&
      css`
        &:before {
          content: '';
          border-width: 0;
          border-bottom-width: ${g(1 / 4)};
          border-left-width: ${g(1 / 4)};
          border-top-width: ${g(1 / 4)};
          border-bottom-left-radius: 999em;
          border-top-left-radius: 999em;
          bottom: ${g(-5)};
          left: calc(-1 * (${calcColumnWithInItem(numberOfTotalColumns - 1, numberOfColumnsInReversedRow)}) - ${g(3)});
          right: ${g(-5, 1)};
        }
      `}

      ${isEvenRow &&
      isRowLastStep &&
      !isLastStep &&
      css`
        &:before {
          content: '';
          border-width: 0;
          border-bottom-width: ${g(1 / 4)};
          border-right-width: ${g(1 / 4)};
          border-top-width: ${g(1 / 4)};
          border-bottom-right-radius: 999em;
          border-top-right-radius: 999em;
          bottom: ${g(-5)};
          left: ${g(1, 7)};
          right: calc(-1 * (${calcColumnWithInItem(numberOfTotalColumns - 2, numberOfColumnsInRegularRow)}) - ${g(3)});
        }
      `}

      ${isEvenRow &&
      isRowLastStep &&
      isFirstRow &&
      css`
        &:before {
          right: calc(-1 * (${calcColumnWithInItem(numberOfTotalColumns - 1, numberOfColumnsInReversedRow)}) - ${g(3)});
        }
      `}
    }
  `
}
