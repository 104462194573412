import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'

import { fetchAlerts } from '../../../lib/prettoSearch/prettoSearchGraphqlApi'

const PropertySearchContext = createContext()

const PropertySearchProvider = ({ children }) => {
  const [cache, setCache] = useState(null)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useLoading(isLoading)

  useEffect(() => {
    if (!cache) {
      refetch()
    }

    return () => {
      setCache(null)
    }
  }, [])

  const flushCache = () => {
    setCache(null)
    setError(false)
  }

  const refetch = async () => {
    try {
      setIsLoading(true)

      const alerts = await fetchAlerts()

      if (alerts.length === 0) {
        throw new Error('no alerts')
      }

      setCache(alerts[0])
    } catch (error) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return null
  }

  const value = {
    get data() {
      if (cache) {
        return cache
      }

      if (!error) {
        refetch()
      }

      return null
    },
    error,
    flushCache,
  }

  return <PropertySearchContext.Provider value={value}>{children}</PropertySearchContext.Provider>
}

PropertySearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const usePropertySearch = () => useContext(PropertySearchContext)

export default PropertySearchProvider
