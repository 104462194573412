import PropTypes from 'prop-types'

import asciis from './asciis'
import * as S from './styles'

const getMatchingSlug = slug => {
  const ascii = asciis[slug]

  if (ascii) {
    return ascii
  }

  const match = slug.match(/^:([^\s]+):$/)

  if (match) {
    return match[1]
  }

  return slug
}

const Emoji = ({ children, size, verticalAlign, ...props }) => (
  <S.Emoji size={size} style={{ verticalAlign }} {...props}>
    <img
      alt=""
      src={`https://res.cloudinary.com/pretto-fr/image/upload/bricks/static-assets/emojione/${getMatchingSlug(
        children.toLowerCase()
      )}.svg`}
    />
  </S.Emoji>
)

Emoji.defaultProps = {
  size: 'small',
  verticalAlign: 'middle',
}

Emoji.propTypes = {
  /** Emoji type. */
  children: PropTypes.string.isRequired,
  /** Size of emoji. */
  size: PropTypes.oneOf(['large', 'medium', 'small', 'x-small', 'x-large', 'display']),
  /** Vertical alignment. */
  verticalAlign: PropTypes.string,
}

export default Emoji
