import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { colors } from '@pretto/zen/reveal/theme/colors'

import PropTypes from 'prop-types'

import * as S from './styles'

const ButtonCard = ({ backgroundVariant, href, label, isArrowShown, onClick, picto, ...props }) => (
  <S.Button href={href} onClick={onClick} {...props}>
    <S.Content>
      {picto && (
        <S.PictoContainer $backgroundVariant={backgroundVariant}>
          <S.Icon $picto={picto} />
        </S.PictoContainer>
      )}
      <BaseTypo typo="button18">{label}</BaseTypo>
    </S.Content>
    {(href || isArrowShown) && <S.Arrow />}
  </S.Button>
)

ButtonCard.propTypes = {
  backgroundVariant: PropTypes.oneOf(Object.keys(colors)),
  /** url to redirect to */
  href: PropTypes.string,
  /** force the visibility of arrow when there is no `href`. */
  isArrowShown: PropTypes.bool,
  /** text to display */
  label: PropTypes.string.isRequired,
  /** function called on click (usually for tracking) */
  onClick: PropTypes.func,
  /** Picto component used. Cf @pretto/picto */
  picto: PropTypes.func,
}

export default ButtonCard
