import { g } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled, { css } from 'styled-components'

const ACTIVITY_ICON_RATIO = 4
interface DiameterProps {
  $diameter: number
}
interface AvatarProps extends DiameterProps {
  $backgroundColor?: string
  $color?: string
  $isBackgroundTransparent: boolean
}

interface ImageProps {
  $color?: string
  $padding?: string
}

export const Avatar = styled.div<AvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  background-color: ${({ $backgroundColor, $isBackgroundTransparent, theme }) => {
    if ($isBackgroundTransparent) return 'transparent'
    if ($backgroundColor) return theme.colors[$backgroundColor]
    return 'inherit'
  }};
  overflow: hidden;

  ${({ $color, theme }) =>
    $color &&
    css`
      color: ${theme.colors[$color]};
    `};

  ${({ $diameter }) => css`
    width: ${`${$diameter}px`};
    height: ${`${$diameter}px`};
    line-height: ${`${$diameter}px`};
    font-size: ${`${$diameter}px`};
  `};
`

export const Image = styled(BaseImage).attrs(({ $diameter }) => ({
  options: {
    crop: 'fill',
    gravity: 'face',
    height: $diameter,
    quality: 85,
    width: $diameter,
  },
}))<ImageProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${`${$padding}px`};
    `};

  ${({ $color, theme }) =>
    $color &&
    css`
      color: ${theme.colors[$color]};
    `};
`

export const AvatarContainer = styled.div`
  position: relative;
`

export const ActivityIcon = styled.div<DiameterProps>`
  position: absolute;
  height: ${({ $diameter }) => $diameter / ACTIVITY_ICON_RATIO}px;
  width: ${({ $diameter }) => $diameter / ACTIVITY_ICON_RATIO}px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.warning1};
  bottom: 0px;
  right: 0px;
  z-index: 100;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.neutral1};
  opacity: 0.7;
  z-index: 1;
`
