import styled from 'styled-components'

export const Footer = styled.div`
  bottom: 0;
  position: sticky;
  z-index: 99;
`

export const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr auto;
  min-height: 100%;
`
