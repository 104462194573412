import Badge from '@pretto/bricks/components/information/Badge'
import Tag from '@pretto/bricks/components/information/Tag'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import nl2br from 'react-nl2br'

import * as S from './styles'

const FlagRow = ({ comments, neutral, severity, source, title }) => {
  const colorVariant = severity === 'block' ? 'error' : 'warning'
  const tagVariant = neutral ? 'neutral-1-l' : colorVariant
  const textVariant = neutral ? 'neutral-1' : colorVariant

  const { t } = useTranslation()

  return (
    <S.Flag>
      <S.Badge>
        {severity === 'block' && !neutral && <Badge icon="exclamation-mark" shape="square" variant="error" />}
        {severity === 'warn' && !neutral && <Badge icon="exclamation-mark" shape="triangle" variant="warning" />}
        {neutral && <Badge icon="exclamation-mark" shape="circle" variant="neutral-1" />}
      </S.Badge>

      <S.Content>
        <S.Title>
          <SubHeading variant={textVariant}>{title}</SubHeading>
        </S.Title>

        <Mobile>
          <S.Tag>
            <Tag size="small" variant={tagVariant}>
              {severity === 'block' ? t('noFinancingPage.blocking') : t('noFinancingPage.warning')}
            </Tag>
          </S.Tag>
        </Mobile>

        <div>
          <Text size="small">{nl2br(comments[severity])}</Text>
        </div>

        {source && (
          <S.Source>
            <Text size="small" variant="accent-1">
              <Link href={source}>{t('noFinancingPage.moreInfo')}</Link>
            </Text>
          </S.Source>
        )}
      </S.Content>

      <Desktop>
        <S.Tag>
          <Tag size="small" variant={tagVariant}>
            {severity === 'block' ? t('noFinancingPage.blocking') : t('noFinancingPage.warning')}
          </Tag>
        </S.Tag>
      </Desktop>
    </S.Flag>
  )
}

FlagRow.defaultProps = {
  neutral: false,
}

FlagRow.propTypes = {
  /** Flag comments. */
  comments: PropTypes.shape({ block: PropTypes.string, warn: PropTypes.string }).isRequired,
  /** Whether or the row should be displayed neutral. */
  neutral: PropTypes.bool,
  /** Severity of the flag. Defines the color palette of the row. */
  severity: PropTypes.oneOf(['block', 'warn']).isRequired,
  /** Link to be used to redirect the user to further information. */
  source: PropTypes.string,
  /** Title of the flag. */
  title: PropTypes.string.isRequired,
}

export default FlagRow
