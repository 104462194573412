import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Grid } from '@pretto/bricks/components/layout/Grid'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'
import temporal from '@pretto/bricks/core/utility/temporal'

import { Bubble } from '@pretto/app/src/Refer/Containers/ReferAppointment/components/Bubble'
import { Calendar } from '@pretto/app/src/Refer/Containers/ReferAppointment/components/Calendar'
import { ModalConfirmation } from '@pretto/app/src/Refer/Containers/ReferAppointment/components/ModalConfirmation'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import React, { memo, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const Page = styled(Grid)`
  padding-bottom: ${g(5)};
  padding-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(7)};
    padding-top: ${g(5)};
  }
`

interface ReferAppointmentProps {
  match: {
    params: {
      type: 'friend' | 'lead'
    }
  }
}

const ReferAppointment: React.FC<ReferAppointmentProps> = ({
  match: {
    params: { type },
  },
}) => {
  const trackAction = useTracking()
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState('')
  const history = useHistory()
  const { advisor, user } = useUser()

  const handleBooking = () => {
    trackAction('REFER_APPOINTMENT_BOOKING', { refer_appointment_type: type })
  }

  const handleBooked = (duration: unknown, date: string) => {
    trackAction('REFER_APPOINTMENT_BOOKED', { refer_appointment_type: type })
    const formattedDate = temporal(date).format('dddd LL [à] HH[h]mm')
    setDate(formattedDate)
    setIsOpen(true)
  }

  const handleClickSimulator = () => {
    trackAction('REFER_SKIP_INTO_SIMULATION')
    history.push('/project/choice')
  }

  const handleCloseDialog = () => {
    trackAction('REFER_DIALOG_CLOSED', { refer_appointment_type: type })
    history.push('/')
  }

  return (
    <ThemeProvider designSystem="reveal">
      <Header isPartnerLogoDisabled navigationItemsList={[NAV_ITEMS.faq]} />
      <Page>
        <Bubble advisor={advisor} user={user} />
        <Calendar onBooked={handleBooked} onBooking={handleBooking} onSkipIntoSimulation={handleClickSimulator} />
      </Page>
      <ModalConfirmation isOpen={isOpen} date={date} onCloseDialog={handleCloseDialog} advisorName={advisor.name} />
    </ThemeProvider>
  )
}

export default memo(ReferAppointment)
