import type { FormProps } from '@pretto/app/src/SignUp/components/Form/Form'
import * as F from '@pretto/app/src/SignUp/config/fields'
import { useEmailField } from '@pretto/app/src/lib/form/useEmailField'
import { useFieldController } from '@pretto/app/src/lib/form/useFieldController'
import { usePhoneField } from '@pretto/app/src/lib/form/usePhoneField'
import { useTextField } from '@pretto/app/src/lib/form/useTextField'
import { t } from '@pretto/app/src/lib/i18n'

import { Dispatch, SetStateAction, useState } from 'react'

export interface Values {
  email: string
  firstName: string
  lastName: string
  phone: string
}

type UseSignUpForm = ({
  defaultValues,
  onCguClick,
  onPrivacyClick,
  onSubmit,
}: {
  defaultValues?: Partial<Values>
  onCguClick: () => void
  onPrivacyClick: () => void
  onSubmit: (values: Values) => void
}) => { formProps: FormProps; setError: Dispatch<SetStateAction<string | null>> }

export const useSignUpForm: UseSignUpForm = ({ defaultValues, onCguClick, onPrivacyClick, onSubmit }) => {
  const [error, setError] = useState<string | null>(null)
  const [isConsentChecked, setIsConsentChecked] = useState(false)

  const {
    check: emailFieldCheck,
    component: emailComponent,
    value: email,
  } = useEmailField({
    ...F.email,
    defaultValue: defaultValues?.email,
  })

  const {
    check: firstNameFieldCheck,
    component: firstNameFieldComponent,
    value: firstName,
  } = useTextField({
    ...F.firstName,
    defaultValue: defaultValues?.firstName,
  })

  const {
    check: lastNameFieldCheck,
    component: lastNameFieldComponent,
    value: lastName,
  } = useTextField({
    ...F.lastName,
    defaultValue: defaultValues?.lastName,
  })

  const {
    check: phoneFieldCheck,
    component: phoneFieldComponent,
    value: phone,
  } = usePhoneField({
    ...F.phone,
    defaultValue: defaultValues?.phone,
  })

  const { stateProps, check: consentFieldCheck } = useFieldController(isConsentChecked.toString(), undefined, [
    (value, isCheckRequested) => ({
      condition: isCheckRequested && value === 'false',
      props: {
        message: t('signupForm.mandatoryFieldBooking'),
        messageKey: 'required_field',
        state: 'error',
      },
    }),
  ])

  const handleClickCgu = () => {
    onCguClick()
  }

  const handleConsentChange = () => {
    setIsConsentChecked(isConsentChecked => !isConsentChecked)
  }

  const handleClickPrivacy = () => {
    onPrivacyClick()
  }

  const handleSubmit = () => {
    const isValid = [
      emailFieldCheck(),
      firstNameFieldCheck(),
      lastNameFieldCheck(),
      phoneFieldCheck(),
      consentFieldCheck(),
    ].every(Boolean)

    if (!isValid) {
      return
    }

    onSubmit({ email, firstName, lastName, phone })
  }

  const formProps = {
    consentErrorMessage: stateProps?.message,
    consentErrorMessageKey: stateProps?.messageKey,
    error,
    fields: {
      firstName: firstNameFieldComponent,
      lastName: lastNameFieldComponent,
      email: emailComponent,
      phone: phoneFieldComponent,
    },
    isConsentChecked,
    onCguClick: handleClickCgu,
    onConsentChange: handleConsentChange,
    onPrivacyClick: handleClickPrivacy,
    onSubmit: handleSubmit,
  }

  return { formProps, setError }
}
