import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { useFieldFormat } from '@pretto/zen/reveal/lib/useFieldFormat'
import { FieldProps } from '@pretto/zen/reveal/types/Field'

import { Picto } from '@pretto/picto'

import { forwardRef, useEffect, useRef, useState } from 'react'

import * as S from './TextField.styles'

export interface TextFieldProps extends FieldProps {
  onClear?: () => void
  picto?: Picto
  suffix?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  placeholderAsLabel?: boolean
  theme?: 'default' | 'color'
}

export const TextField = forwardRef(
  (
    {
      inputProps = {},
      message,
      onChange,
      onClear,
      picto,
      placeholderAsLabel = false,
      size = 'medium',
      state = 'default',
      suffix,
      theme = 'default',
      value,
      ...props
    }: TextFieldProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>,
    ref
  ) => {
    const suffixRef = useRef<HTMLElement>(null)

    const fieldFormatProps = useFieldFormat({ inputProps, onChange, value }, ref)

    const [suffixWidth, setSuffixWidth] = useState<number>(0)

    const isClearActive = !!(state === 'default' && value && onClear && !picto && !suffix)
    const isElementWithSuffixVisible = !!suffixRef.current?.offsetParent

    useEffect(() => {
      if (suffixRef.current) {
        setSuffixWidth(suffixRef.current.offsetWidth)
      }
    }, [suffix, isElementWithSuffixVisible])

    const focusInput = () => {
      if (fieldFormatProps.ref && 'current' in fieldFormatProps.ref) {
        fieldFormatProps.ref.current?.focus()
      }
    }

    const handleClear = () => {
      onClear?.()

      focusInput()
    }

    const pictoSlug = isClearActive ? 'clear' : state

    const isLabel = fieldFormatProps.value !== ''
    const isMessageActive = !!message
    const isPictoActive = !!picto || pictoSlug !== 'default'
    const isSuffixActive = !!suffix && !isPictoActive

    return (
      <S.Container {...props}>
        <S.Input>
          <S.TextField
            $size={size}
            $placeholderAsLabel={placeholderAsLabel}
            $state={state}
            $pictoSlug={pictoSlug}
            $isSuffixActive={isSuffixActive}
            $suffixWidth={suffixWidth}
            $theme={theme}
            {...inputProps}
            {...fieldFormatProps}
          />

          {placeholderAsLabel && inputProps.placeholder && (
            <S.Label $size={size} $isLabel={isLabel} onClick={focusInput}>
              {inputProps.placeholder}
              <S.LabelMask $isLabel={isLabel} />
            </S.Label>
          )}

          {isPictoActive && (
            <S.Picto $slug={pictoSlug} $picto={picto} onClick={isClearActive ? handleClear : focusInput} />
          )}

          {isSuffixActive && (
            <S.Suffix ref={suffixRef} onClick={focusInput}>
              {suffix}
            </S.Suffix>
          )}
        </S.Input>

        <CollapsibleRow animateIn in={isMessageActive}>
          <S.Message $pictoSlug={pictoSlug}>{message}</S.Message>
        </CollapsibleRow>
      </S.Container>
    )
  }
)

TextField.displayName = 'TextField'
