import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading5};
  margin-top: ${g(4.5)};
  margin-bottom: ${g(1)};

  @media (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin-top: 0;
    margin-bottom: ${g(3)};
  }
`
