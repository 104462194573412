import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Card from '@pretto/bricks/components/cards/Card'
import Avatar from '@pretto/bricks/components/images/Avatar'
import { g } from '@pretto/bricks/components/layout'


import styled from 'styled-components'

export const Details = styled.div`
  padding-top: ${g(3)};
  margin-bottom: ${g(3)};
`

export const Icon = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${g(5)};
  width: ${g(5)};
  height: ${g(5)};
  margin-right: ${g(2)};
`

export const Malus = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(1)};
  margin-top: ${g(3)};
  margin-bottom: ${g(3)};
`


export const SeeMore = styled(ButtonInline)`
  margin-bottom: ${g(3)};
`
