import { REDUCE_AMBITION_TYPES } from '@pretto/app/src/Simulation/Containers/OptionsPage/config/reduceAmbition'
import { calculateProjectPrice } from '@pretto/app/src/Simulation/Containers/OptionsPage/lib/calculateProjectPrice'
import { formatToEuro } from '@pretto/app/src/Simulation/Containers/OptionsPage/lib/formatValue'
import { t } from '@pretto/app/src/lib/i18n'

export const getOptions = (options, project) =>
  [
    options[REDUCE_AMBITION_TYPES.contribution] && {
      firstValueProps: {
        value: formatToEuro(options[REDUCE_AMBITION_TYPES.contribution].project.contribution),
      },
      inputLabel: t('reduceAmbitionPage.bumpContribution.label'),
      inputLabelMobile: t('reduceAmbitionPage.bumpContribution.labelShort'),
      inputValue: REDUCE_AMBITION_TYPES.contribution,
      secondValueProps: { value: formatToEuro(project.contribution) },
    },
    options[REDUCE_AMBITION_TYPES.price] && {
      firstValueProps: {
        value: formatToEuro(options[REDUCE_AMBITION_TYPES.price].project.facts.price_before_fees),
      },
      inputLabel: t('reduceAmbitionPage.downsizeProject.label'),
      inputLabelMobile: t('reduceAmbitionPage.downsizeProject.labelShort'),
      inputValue: REDUCE_AMBITION_TYPES.price,
      secondValueProps: { value: formatToEuro(calculateProjectPrice(project.purchase)) },
    },
  ].filter(Boolean)
