import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Content = styled.div`
  border-radius: 0 ${g(1)} ${g(1)} 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 300ms ease-in-out;

  ${({ value, max }) =>
    css`
      transform: scaleX(${value / max});
      transform-origin: left center;
    `}

  ${({ theme, $color }) =>
    $color &&
    css`
      background-color: ${theme.colors[$color]};
    `}
`
export const Progress = styled.div`
  background: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
  border-radius: ${g(1)};
  overflow: hidden;
  position: relative;
  height: ${g(2)};
  width: 100%;

  ${({ $completed }) =>
    $completed &&
    css`
      ${Content} {
        border-radius: 0;
      }
    `}
`
