import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Avatar as AvatarComponent } from '@pretto/zen/atoms/avatars/Avatar/Avatar'

import styled, { css } from 'styled-components'

interface MenuItemExpertContainerProps {
  $isUnavailable: boolean
}

export const MenuItemExpertContainer = styled.div<MenuItemExpertContainerProps>`
  padding: ${g(2)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} ${g(3)};

    ${({ $isUnavailable }) =>
      $isUnavailable &&
      css`
        padding-bottom: 0px;
      `}
  }

  ${({ $isUnavailable }) =>
    $isUnavailable &&
    css`
      padding-bottom: 0px;
    `}
`

export const MenuItemExpert = styled.div`
  display: flex;
  align-items: center;
`
export const AvatarContainer = styled.div`
  width: ${g(5)};
  height: ${g(5)};
`

export const Avatar = styled(AvatarComponent)``

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: ${g(1)};
`
export const Title = styled.div`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
`
export const Name = styled.div`
  ${typo.bodyBold16}
`

export const VacationContainer = styled.div`
  ${typo.caption12};
  margin-top: ${g(1)};
  color: ${({ theme }) => theme.colors.warning1};
`
