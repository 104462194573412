import { renderSection } from '@pretto/app-core/lib/renderSection'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import defaultTo from 'lodash/defaultTo'

export const renderSections = (sections, handleChange, values, data, ...args) =>
  sections.reduce((previous, currentSection) => {
    const sectionProps = funcToItem(currentSection, values)
    const condition = funcToItem(defaultTo(sectionProps.condition, true), data.project, ...args)

    if (!condition) {
      return previous
    }

    const section = renderSection(sectionProps, handleChange, values, data, ...args)

    if (!section) {
      return previous
    }

    return [...previous, section]
  }, [])
