import { g, ng } from '@pretto/bricks/components/layout'
import styled, { css, keyframes } from 'styled-components'

interface SpinnerProps {
  backgroundColor?: string
  color?: string
}

export const Spinner = ({ backgroundColor = 'neutral4', color = 'primary1', ...props }: SpinnerProps) => (
  <SpinnerContainer $backgroundColor={backgroundColor} $color={color} {...props} />
)

/* eslint-disable no-magic-numbers */
const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`
interface SpinnerContainerProps {
  $backgroundColor: NonNullable<SpinnerProps['backgroundColor']>
  $color: NonNullable<SpinnerProps['color']>
}
const SpinnerContainer = styled.div<SpinnerContainerProps>`
  animation: ${load8} 1.1s infinite linear;
  border-radius: 50%;
  height: ${g(4)};
  overflow: hidden;
  position: relative;
  width: ${g(4)};

  &:before {
    border-radius: inherit;
    bottom: calc((${g(4)} - 100%) / ${ng(-1)});
    content: '';
    left: calc((${g(4)} - 100%) / ${ng(-1)});
    position: absolute;
    right: calc((${g(4)} - 100%) / ${ng(-1)});
    top: calc((${g(4)} - 100%) / ${ng(-1)});

    ${({ $backgroundColor, $color, theme }) => css`
      border: ${g(1 / 2)} solid ${theme.colors[$backgroundColor]};
      border-top-color: ${theme.colors[$color]};
    `}
  }
`
