import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import { round } from '@pretto/bricks/core/utility/math'

const AMOUNT_HIGH_FACTOR = 1.06
const AMOUNT_LOW_FACTOR = 0.97
const AMOUNT_ROUNDING_PARAMETER = -3

export const getRangeFromPrice = (price: number) => {
  const high = round(AMOUNT_HIGH_FACTOR * price, AMOUNT_ROUNDING_PARAMETER)
  const low = round(AMOUNT_LOW_FACTOR * price, AMOUNT_ROUNDING_PARAMETER)

  const range = [low, high].map(price => `${formatNumber(price / 1000)}k€`)

  return range
}
