import Content from '@pretto/bricks/components/layout/Content'

import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './AddSentence.module.css'

export default class AddSentence extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
  }
  render() {
    const { children, onClick } = this.props
    return (
      <div className={C.addSentence} onClick={onClick}>
        <div className={C.addSentenceIcon}>+</div>
        <div className={C.addSentenceContent}>
          <Content>{children}</Content>
        </div>
      </div>
    )
  }
}
