import styled, { css } from 'styled-components'

export const Loading = styled.span`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ButtonContent = styled.span``

export const Button = styled.div`
  ${({ isLoading }) =>
    isLoading &&
    css`
      position: relative;

      ${ButtonContent} {
        opacity: 0;
      }
    `}
`
