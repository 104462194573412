import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { formatSection } from '@pretto/app-core/application/lib/formatSection'
import { getDocumentsForFilters } from '@pretto/app-core/application/lib/getDocumentsForFilters'
import { getDocumentsGroups } from '@pretto/app-core/application/lib/getDocumentsGroups'
import { getDropdownLabel } from '@pretto/app-core/application/lib/getDropdownLabel'
import { getFilters } from '@pretto/app-core/application/lib/getFilters'
import { getSortedDocuments } from '@pretto/app-core/application/lib/getSortedDocuments'
import { Documents } from '@pretto/app-core/application/views/documents/Documents'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { getHeaderProps } from '@pretto/app/src/Application/Containers/ApplicationDocumentsPage/lib/getHeaderProps'
import { ApplicationDocumentUploadDialog } from '@pretto/app/src/Application/Containers/applicationDocumentUploadDialog'
import Header from '@pretto/app/src/SharedContainers/Header'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { schema } from './config/filters'

const ApplicationDocumentsPage = ({ history, location, match }) => {
  const { data, introductionUrl, isBlocked } = useApplication()

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }))

  const trackAction = useTracking()

  const documentParam = match.params?.document
  const isDialogOpen = (documentParam && data.docs.some(doc => doc.slug === documentParam)) ?? false

  const userContext = useUser()

  useEffect(() => {
    const filters = qs.parse(location.search, { ignoreQueryPrefix: true })

    setSelectedFilters(filters)
    handleClose()
  }, [location.search])

  useUpdateEffect(() => {
    if (selectedFilters.category) {
      trackAction('DOCUMENTS_FILTER_CHANGED', { documents_filter_value: selectedFilters.category })
    }
  }, [selectedFilters.category])

  useUpdateEffect(() => {
    if (selectedFilters.status === 'invalid') {
      trackAction('DOCUMENTS_FILTER_INVALID_CLICKED')
    }
  }, [selectedFilters.status])

  const handleClose = () => {
    setIsFiltersOpen(false)
  }

  const currentDocument = data.docs.find(document => document.slug === documentParam)

  if (documentParam && !currentDocument) {
    return <Redirect to="/application/documents" />
  }

  if (isBlocked) {
    return <Redirect to={introductionUrl} />
  }

  const hasFilledBanks = data.docs.find(({ kind }) => kind === 'releves_compte')

  if (selectedFilters?.category === 'appointment' && !hasFilledBanks) {
    return <Redirect to={`/application/banks?redirect=${encodeURI('/application/documents?category=appointment')}`} />
  }

  const applyQueryParameters = parameters => {
    // eslint-disable-next-line no-unused-vars
    const { status, ...existingParameters } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const search = qs.stringify({ ...existingParameters, ...parameters }, { addQueryPrefix: true })

    history.replace(`${location.pathname}${search}`)
  }

  const replaceQueryParameters = parameters => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const search = qs.stringify({ token, ...parameters }, { addQueryPrefix: true })

    history.replace(`${location.pathname}${search}`)
  }

  const handleCloseUploadDialog = () => {
    history.push({ pathname: '/application/documents', search: location.search })
  }

  const handleOpen = () => {
    setIsFiltersOpen(true)
  }

  const handleSelect = (parameters, shouldReplace = false) => {
    ;(shouldReplace ? replaceQueryParameters : applyQueryParameters)(parameters)
  }

  const handleToggleMissingDocs = () => {
    trackAction('DOCUMENTS_FILTER_MISSING_CHANGED', { documents_filter_missing_toggled: !isToggled })

    applyQueryParameters({ missing: isToggled ? '0' : '1' })
  }

  const documents = getDocumentsForFilters(selectedFilters, data)
  const formatter = (slug, search = location.search) => `/application/documents/${slug}${search}`

  const filters = getFilters(schema, selectedFilters, formatter, handleSelect, data, userContext)

  const dropdownLabel = getDropdownLabel(filters.slice(0, -1))
  const isToggled = selectedFilters.missing === '1'
  const selectedFilter = filters[0].find(({ isSelected }) => isSelected)

  const sortedDocuments = getSortedDocuments(
    documents,
    formatter,
    userContext,
    selectedFilter?.previousCategoryLastDocumentHref,
    selectedFilter?.nextCategoryFirstDocumentHref
  )

  const documentsGroups = getDocumentsGroups(sortedDocuments)
  const fileSections = documentsGroups.map(formatSection)

  const dropdownFilterProps = {
    filters,
    isOpen: isFiltersOpen,
    label: dropdownLabel,
    onClose: handleClose,
    onOpen: handleOpen,
    onSelect: handleSelect,
  }

  const headerProps = getHeaderProps(userContext.status)

  const uploadDialogProps = {
    getNextDocumentHref: slug =>
      sortedDocuments.find(currentDocument => currentDocument.slug === slug)?.nextHref ?? null,
    getPreviousDocumentHref: slug =>
      sortedDocuments.find(currentDocument => currentDocument.slug === slug)?.previousHref ?? null,
    onClose: handleCloseUploadDialog,
  }

  const viewProps = {
    dropdownFilterProps,
    fileSections,
    isDialogOpen,
    isListEmpty: documentsGroups.length === 0,
    isToggled,
    onCloseDialog: handleCloseUploadDialog,
    onToggle: handleToggleMissingDocs,
    onToggleFilters: handleOpen,
    queryParams: location.search,
  }

  return (
    <>
      <Header {...headerProps} />

      <Documents {...viewProps}>
        <ApplicationDocumentUploadDialog {...uploadDialogProps} />
      </Documents>
    </>
  )
}

ApplicationDocumentsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }),
}

ApplicationDocumentsPage.restrictionRedirect = ({ status }) => ['end', 'signature'].includes(status) && '/'

export default ApplicationDocumentsPage
