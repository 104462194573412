import regroupCredits from '@pretto/app/src/Simulation/lib/regroupCredits'
import { getMalusFromEligibilityResults, getMalusFromSimulationResults } from '@pretto/app/src/lib/simulationMalus'

import { ApolloClient } from '@apollo/client'

import { EligibilityDocument, EligibilityQuery } from './eligibility.gateway.graphql'
import {
  NonFinancingMinimumProjectDocument,
  NonFinancingMinimumProjectQuery,
} from './nonFinancingMinimumProject.gateway.graphql'
import { NonFinancingProjectDocument, NonFinancingProjectQuery } from './nonFinancingProject.gateway.graphql'
import { SimulationDocument, SimulationQuery } from './simulation.gateway.graphql'

const BASE_PROPERTY_PRICE = 50000

export const getNonFinancingData = async (
  client: ApolloClient<object>,
  isEnglishUser: boolean
): Promise<{ malus: unknown[]; regroupCredits: boolean }> => {
  // First, get the project minimum data to calc the property_price
  const nonFinancingMinimumProjectResponse = await client.query<NonFinancingMinimumProjectQuery>({
    query: NonFinancingMinimumProjectDocument,
  })

  const minimumProject = nonFinancingMinimumProjectResponse.data.project

  const bridgeEstimatedValue = minimumProject?.purchase?.bridge?.estimated_value ?? 0
  const bridgeRemainingPrincipal = minimumProject?.purchase?.bridge?.remaining_principal ?? 0
  const bridge = bridgeEstimatedValue - bridgeRemainingPrincipal
  const contribution = minimumProject?.contribution ?? 0

  const variables = {
    override: JSON.stringify({
      purchase: {
        property_price: BASE_PROPERTY_PRICE + contribution + bridge,
      },
    }),
  }

  // Then, get all project data
  const nonFinancingProjectResponse = await client.query<NonFinancingProjectQuery>({
    query: NonFinancingProjectDocument,
    variables,
  })

  const project = nonFinancingProjectResponse.data.project

  const isRegroupCreditsActive = regroupCredits(project)
  const locale = isEnglishUser ? 'en' : 'fr'

  // Then, check the eligibility
  const eligibilityResponse = await client.query<EligibilityQuery>({ query: EligibilityDocument, variables })

  const eligibility = eligibilityResponse.data.eligibility ?? []

  if (eligibility.length > 0) {
    const malus = getMalusFromEligibilityResults(eligibility, project, locale)

    return {
      malus,
      regroupCredits: isRegroupCreditsActive,
    }
  }

  // Finally, check the simulation
  const simulationResponse = await client.query<SimulationQuery>({ query: SimulationDocument, variables })

  const simulation = simulationResponse.data.simulation

  const malus = getMalusFromSimulationResults(simulation, project, locale)

  return {
    malus,
    regroupCredits: isRegroupCreditsActive,
  }
}
