import { g } from '@pretto/bricks/components/layout'

import { BuyoutCredit } from '@pretto/app/src/Sentences/v2/Charge/Credits/components/BuyoutCredit'
import { CreditBox as CreditBoxComponent } from '@pretto/app/src/Sentences/v2/Charge/Credits/components/CreditBox'
import { MedicalAd } from '@pretto/app/src/Sentences/v2/Charge/Credits/components/MedicalAd'
import { OwnPropertyCredit } from '@pretto/app/src/Sentences/v2/Charge/Credits/components/OwnPropertyCredit'
import { RenegotiationCredit } from '@pretto/app/src/Sentences/v2/Charge/Credits/components/RenegotiationCredit'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/v2/components/Styled/Divider/Divider'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/v2/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { handleCreditDateState } from '@pretto/app/src/Sentences/v2/lib/credits'
import { createCredit } from '@pretto/app/src/Sentences/v2/lib/factories/credit'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { hasOneMedical, isNotSolo } from '@pretto/app/src/Sentences/v2/lib/validators/context'
import type { Credit } from '@pretto/app/src/Sentences/v2/types/credit'
import { CreditType, SimulationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { getItem, setItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'

import { useState } from 'react'
import styled, { css } from 'styled-components'

const CreditBox = styled(CreditBoxComponent)<{ $hasMarginTop: boolean }>`
  ${({ $hasMarginTop }) =>
    $hasMarginTop &&
    css`
      margin-top: ${g(2)};
    `}
`

const INTERSTITIAL_MEDICAL = 'INTERSTITIAL_MEDICAL_VISIBILITY'

export const CreditsPage: React.FC = () => {
  const context = useSentences()
  const {
    buyoutCreditRemainingPrincipal,
    credits = [],
    hasBuyoutCredit,
    loanPayment,
    ownPropertyEndDate,
    ownPropertyRate,
    ownPropertyRemainingPrincipal,
    setContext,
    simulationType,
  } = context

  const hasBuyoutDeclaredCredit =
    simulationType === SimulationType.Buyout && hasBuyoutCredit && buyoutCreditRemainingPrincipal
  const hasOwnPropertyDeclaredCredit = ownPropertyRemainingPrincipal && ownPropertyEndDate && ownPropertyRate
  const hasRenegotiationDeclaredCredit = simulationType === SimulationType.Renegotiation && loanPayment

  const indexOffset = [hasBuyoutDeclaredCredit, hasOwnPropertyDeclaredCredit, hasRenegotiationDeclaredCredit].filter(
    Boolean
  ).length

  const [editableId, setEditableId] = useState<string | null>(null)

  const [activeField, setActiveField] = useActiveField(0, false)

  const eachCreditIsFilledCorrectly = credits
    .filter(credit => !credit._delete)
    .every(({ endDate }) => !!endDate && handleCreditDateState(endDate).state === 'default')

  const [isInterstitialShown, setIsInterstitialShown] = useState(
    !getItem(INTERSTITIAL_MEDICAL) && hasOneMedical(context)
  )

  if (isInterstitialShown) {
    const handleMedicalNext = () => {
      setIsInterstitialShown(false)
      setItem(INTERSTITIAL_MEDICAL, 'hidden')
    }
    return <MedicalAd onNextClick={handleMedicalNext} />
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'credits' } }}>
      <Title>{t('sentences.charge.title')}</Title>
      <div>
        <RenegotiationCredit />
        <OwnPropertyCredit />
        <BuyoutCredit />
        {credits &&
          credits
            .filter(credit => !credit._delete)
            .map((credit, index) => {
              const setCredit = (updatedCredit: Credit) => {
                setContext(({ credits }) => ({
                  credits:
                    credits?.map(currentCredit => {
                      if (currentCredit.id === credit.id) {
                        return updatedCredit
                      }

                      return currentCredit
                    }) ?? [],
                }))
              }

              const choices = Object.values(CreditType).map(value => {
                return {
                  key: value,
                  isSelected: credit.type === value,
                  onClick: () => {
                    setCredit({ ...credit, type: value })
                  },
                  label: t(`sentences.charge.credits.values.${value}`),
                }
              })

              return (
                <CreditBox
                  $hasMarginTop={indexOffset > 0}
                  key={credit.id}
                  activeField={activeField}
                  onChangeAmount={(value: string) => {
                    setCredit({ ...credit, amount: Number(value) })
                    setActiveField(2)
                  }}
                  onChangeDate={(value: Date) => {
                    setCredit({ ...credit, endDate: value })
                  }}
                  onPromptClick={() => {
                    setActiveField(0)
                  }}
                  onChoiceClick={() => {
                    setActiveField(1)
                  }}
                  onClick={() => {
                    setEditableId(credit.id)
                  }}
                  onRemove={() => {
                    setCredit({ id: credit.id, _delete: true })
                  }}
                  credit={credit}
                  index={index + indexOffset}
                  isEditable={editableId === credit.id}
                  choices={choices}
                />
              )
            })}
      </div>
      <DividerWithMarginBottom />
      <TrackedButtonInline
        events={{ eventName: EventName.SimulationElementClicked, eventPayload: { element: 'chargeCreditsAddValue' } }}
        onClick={() => {
          const credit = createCredit()
          setContext({ credits: [...credits, credit] })
          setEditableId(credit.id)
          setActiveField(0)
        }}
      >
        {t('sentences.charge.credits.addValue')}
      </TrackedButtonInline>
      <Message>{t('sentences.tips.credits', { context: isNotSolo(context) ? 'duo' : '' })}</Message>
      {eachCreditIsFilledCorrectly && <ButtonNext>{t(credits ? 'sentences.next' : 'sentences.skip')}</ButtonNext>}
    </TrackedView>
  )
}
