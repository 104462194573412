import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { getAttributionCookieFirstClickValue } from '@pretto/app-core/lib/attributionCookie'

import { handlePropertySearch } from '@pretto/app/src/Capacity/Containers/lib/handlePropertySearch'
import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import dealValue from '@pretto/app/src/Simulation/lib/dealValue'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import { useTracking } from '@pretto/app/src/lib/tracking'
import type { CapacityData } from '@pretto/app/src/types/Capacity'

import { useApolloClient } from '@apollo/client'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { DataContext, ROUND_TO } from './DataContext'
import { CapacityDocument } from './capacity.gateway.graphql'

interface DataProviderProps {
  children: React.ReactElement
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const history = useHistory()
  const { flushCache } = usePropertySearch()
  const client = useApolloClient()
  const location = useLocation()

  const trackAction = useTracking()

  const [loadingResults, setLoadingResults] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [capacityMedium, setCapacityMedium] = useState<string>('page_load')

  const [data, setData] = useState<CapacityData>({} as CapacityData)

  const [payment, setPayment] = useState<number>(0)
  const [selectedLoanDuration, setSelectedLoanDuration] = useState<number | null>(null)
  const [contribution, setContribution] = useState<number>(0)
  const [maxPayment, setMaxPayment] = useState<number>(0)

  useEffect(() => {
    if (!data || !data?.capacity?.length) {
      return
    }

    refreshDealValue()

    setSelectedLoanDuration((selectedLoanDuration: number | null) => {
      if (data.capacity.some(({ financing: { loanDuration } }) => loanDuration === selectedLoanDuration)) {
        return selectedLoanDuration
      }

      return data.capacity[data.capacity.length - 1].financing.loanDuration
    })
  }, [data])

  const initCapacity = async () => {
    const { data: newData } = (await client.query({ fetchPolicy: 'network-only', query: CapacityDocument })) as {
      data: CapacityData
    }

    const { contribution } = ensureNotNil(newData?.project)

    const maxPayment = roundNumber(
      newData?.capacity?.reduce((previous, { financing: { payment } }) => {
        if (payment > previous) return payment
        return previous
      }, 0),
      ROUND_TO,
      Math.ceil
    )

    setMaxPayment(maxPayment)

    const hasError = newData?.capacity.length === 0
    const { propertySearch } = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (propertySearch) {
      handlePropertySearch({
        propertySearch,
        hasError,
        newData,
        trackAction,
        flushCache,
        history,
      })
    }

    if (maxPayment === 0) {
      setError(true)
      return
    }

    setError(hasError)
    setMaxPayment(maxPayment)
    setData(newData)
    setPayment(maxPayment)
    setContribution(contribution || 0)

    return newData
  }

  const refreshCapacity = async (
    medium: string,
    { contribution: overrideContribution, payment: overridePayment }: { contribution?: number; payment?: number } = {
      contribution,
      payment,
    }
  ) => {
    setLoadingResults(true)
    trackAction('CAPACITY_RESULTS_REQUESTED', {
      capacity_results_attribution: getAttributionCookieFirstClickValue(),
      capacity_results_medium: capacityMedium,
    })

    const override = JSON.stringify({ contribution: overrideContribution })
    const variables = { override, payment: overridePayment }
    const { data } = (await client.query({ query: CapacityDocument, variables })) as { data: CapacityData }

    setCapacityMedium(medium)
    setData(data)
    setLoadingResults(false)
  }

  const refreshDealValue = () => {
    const capacity = data?.capacity
    const brokerageFees = data?.brokerage_fees.amount

    if (capacity?.length === 0) {
      return
    }

    const loanAmount = capacity?.[capacity?.length - 1].financing.loanAmount

    if (!loanAmount) {
      return
    }

    const value = dealValue(loanAmount, brokerageFees)
    setItem('current_deal_value', value)
  }

  const handleChangePayment = (payment: number) => {
    setPayment(payment)
  }

  const handleChangeContribution = (value: number) => {
    setContribution(value || 0)
  }

  const handleChangeDuration = (value: number) => {
    setSelectedLoanDuration(value)
  }

  const context = {
    loadingResults,
    data,
    payment,
    contribution,
    maxPayment,
    selectedLoanDuration,
    error,
    capacityMedium,
    initCapacity,
    refreshCapacity,
    handleChangePayment,
    handleChangeContribution,
    handleChangeDuration,
  }

  return <DataContext.Provider value={context}>{children}</DataContext.Provider>
}
