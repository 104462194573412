import temporal from '@pretto/bricks/core/utility/temporal'

import { formatGroupTitle } from '@pretto/app-core/application/lib/formatGroupTitle'
import * as filters from '@pretto/app-core/lib/filters'

import BANKS from '@pretto/config/banks.json'
import { House } from '@pretto/picto'

import omit from 'lodash/omit'

const REMAINING_PRINCIPAL_DELAY = 3

const date = temporal().add(REMAINING_PRINCIPAL_DELAY, 'M')
const month = date.month() + 1
const year = date.year()

// Fields
const localisation = {
  fieldProps: {
    placeholder: '3 Place Jean-Paul-Sartre-et-Simone-de-Beauvoir',
    searchfieldPlaceholder: 'Saisissez une adresse',
  },
  label: 'Adresse du bien',
  name: 'good.localisation',
  type: 'address',
}

const numberOfRooms = {
  fieldProps: {
    placeholder: '5',
    range: { max: 20, min: 1 },
  },
  label: 'Le nombre de pièces',
  name: 'good.number_of_rooms',
  type: 'range',
}

const surface = {
  fieldProps: {
    placeholder: '42',
    range: { max: 10000, min: 1 },
  },
  label: 'La surface habitable en m2',
  name: 'good.surface',
  type: 'range',
}

const bank = {
  label: 'La banque dans laquelle vous avez contracté le crédit à racheter',
  name: 'bank',
  type: 'bank',
}

const regionalBank = {
  condition: ({ bank }) => BANKS.some(({ group }) => group !== null && group === bank),
  fieldProps: ({ bank }) => ({
    group: bank,
  }),
  label: ({ bank }) => `${bank} : la caisse régionale dans laquelle vous avez contracté le crédit à racheter`,
  name: 'regional_bank',
  type: 'bank',
}

const rate = {
  fieldProps: {
    placeholder: '2.5',
    suffix: '%',
  },
  label: 'Taux du crédit',
  name: 'renegotiation.rate',
  type: 'decimal',
}

const remainingPrincipal = {
  fieldProps: {
    placeholder: '200 000',
    suffix: '€',
  },
  label: `Capital restant dû au mois de ${formatGroupTitle({ month, year })}`,
  name: 'renegotiation.remaining_principal',
  type: 'numberfield',
}

const endDate = {
  fieldProps: {
    placeholder: 'JJ / MM / AAAA',
  },
  label: 'Date de fin du remboursement',
  name: 'renegotiation.end_date',
  type: 'date',
}

// Sections
const propertySection = {
  fields: [localisation, numberOfRooms, surface],
  title: 'Informations sur le rachat',
}

const currentCreditSection = {
  fields: [bank, regionalBank, rate, remainingPrincipal, endDate],
  title: 'Votre crédit à racheter',
}

// Pages
const defaultPage = {
  decoder: filters.applyFilters([
    filters.applyFilter(
      'bank',
      (value, values, project) => {
        const bankName = project.renegotiation.bank
        const bank = BANKS.find(({ value }) => value === bankName)

        if (!bank) {
          return null
        }

        return bank.group || bank.label
      },
      false
    ),
    filters.applyFilter(
      'regional_bank',
      (value, values, project) => {
        const bankName = project.renegotiation.bank
        const bank = BANKS.find(({ value }) => value === bankName)

        if (!bank || !bank.group) {
          return null
        }

        return bank.label
      },
      false
    ),
    filters.applyFilter('good.localisation', filters.formatDecodeAddress),
    filters.applyFilter('renegotiation.end_date', filters.formatDecodeDate),
  ]),
  encoder: (differedValues, data) => {
    const bank = differedValues.regional_bank || differedValues.bank
    const filteredValues = omit(differedValues, 'bank', 'regional_bank')

    if (bank) {
      filteredValues[`renegotiation.bank`] = BANKS.find(({ label }) => label === bank).value
    }

    return filters.applyFilters([filters.applyFilter('renegotiation.end_date', filters.formatEncodeDate)])(
      filteredValues,
      data
    )
  },
  sections: [propertySection, currentCreditSection],
  title: 'Projet',
}

const step = {
  condition: ({ project: { project_kind } }) => project_kind === 'renegotiation',
  documentsParameters: {
    category: 'property',
  },
  pages: [defaultPage],
  sideBarParameters: {
    label: 'Projet',
    picto: House,
    sideBarOrder: 3,
    url: '/application/renegotiation',
  },
}

export default step
