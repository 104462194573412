import styled from 'styled-components'

export interface FileOwnershipLabelProps {
  label: React.ReactNode
}

export const FileOwnershipLabel = ({ label, ...props }: FileOwnershipLabelProps) => (
  <LabelContainer {...props}>
    <From>De : </From>
    <Label>{label}</Label>
  </LabelContainer>
)

const LabelContainer = styled.div`
  color: ${({ theme }) => theme.colors.neutral2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const From = styled.span`
  color: ${({ theme }) => theme.colors.neutral1};
`
const Label = styled.span``
