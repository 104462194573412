import { breakpoints, g, ng, numeralBreakpoints } from '@pretto/bricks/components/layout'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { t } from '@pretto/app/src/lib/i18n'

import styled from 'styled-components'

export interface FAQCardProps {
  onSeeClick?: () => void
}

export const FAQCard: React.FC<React.HTMLAttributes<HTMLDivElement> & FAQCardProps> = ({ onSeeClick, ...props }) => (
  <Component {...props}>
    <Content>
      <Header>
        <Hat>{t('faqCard.question')}</Hat>
        <Title>{t('faqCard.title')}</Title>
      </Header>

      <Description>{t('faqCard.description')}</Description>

      <SeeButton href="https://faq.pretto.fr/" onClick={onSeeClick}>
        {t('faqCard.goToFaq')}
      </SeeButton>
    </Content>

    <Responsive max="tablet">
      <ImageMobile alt="" path="v1697551345/reveal/static-asssets/components/faqcard/expert.jpg" />
    </Responsive>

    <Responsive max="laptop" min="tablet">
      <ImageTablet alt="" path="v1697551345/reveal/static-asssets/components/faqcard/expert.jpg" />
    </Responsive>

    <Responsive min="laptop">
      <ImageLaptop alt="" path="v1697551345/reveal/static-asssets/components/faqcard/expert.jpg" />
    </Responsive>
  </Component>
)

const Component = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(4)};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: ${g(2)} ${g(2)} 0 ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} 0 ${g(2)} ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(3)} ${g(4)} ${g(4)} 0;
  }
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-grow: 1;
  }
`

const Hat = styled.header`
  ${({ theme }) => theme.typos.heading6};
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

const ImageMobile = styled(BaseImage).attrs({
  sizes: `100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  border-bottom-left-radius: ${g(1)};
  border-bottom-right-radius: ${g(1)};
  display: block;
  height: ${g(28)};
  object-fit: cover;
  width: 100%;
`

const ImageTablet = styled(BaseImage).attrs({
  options: {
    aspectRatio: 1,
    crop: 'fill',
  },
  sizes: g(31),
  srcSetBreakpoints: [
    // tablet sizes in x1, x2 et x3
    ng(31),
    ng(31 * 2),
    ng(31 * 3),
  ],
})`
  aspect-ratio: 1;
  border-radius: 0;
  border-top-right-radius: ${g(1)};
  border-bottom-right-radius: ${g(1)};
  display: block;
  object-fit: cover;
  width: ${g(31)};
`

const ImageLaptop = styled(BaseImage).attrs({
  options: {
    aspectRatio: `${ng(31)}:${ng(34)}`,
    crop: 'fill',
  },
  sizes: g(28),
  srcSetBreakpoints: [
    // laptop sizes in x1, x2 et x3
    ng(28),
    ng(28 * 2),
    ng(28 * 3),
  ],
})`
  aspect-ratio: ${ng(31)} / ${ng(34)};
  border-top-left-radius: ${g(1)};
  border-bottom-left-radius: ${g(1)};
  display: block;
  min-height: 100%;
  object-fit: cover;
  width: ${g(31)};
`

const SeeButton = styled(PrimaryButtonFixComponent).attrs({ scheme: 'black' })`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-start;
  }
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};
`
