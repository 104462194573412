import AutoComplete from '@pretto/bricks/components/form/AutoComplete'

import { searchArray } from '@pretto/app-core/lib/searchArray'
import { sortBanks } from '@pretto/app-core/lib/sortBanks'

import BANKS from '@pretto/config/banks.json'

import groupBy from 'lodash/groupBy'
import PropTypes from 'prop-types'
import { useState } from 'react'

const groupBanks = group => {
  if (!group) {
    return groupBy(BANKS, bank => bank.group || bank.label)
  }

  return groupBy(
    BANKS.filter(bank => bank.group === group),
    'label'
  )
}

const listBanksFromGroup = groups =>
  Object.keys(groups)
    .map(label => ({
      label,
      value: label,
    }))
    .sort(sortBanks(false))

export const BankField = props => {
  const [searchValue, setSearchValue] = useState('')

  const handleClear = () => setSearchValue('')
  const handleSearch = value => setSearchValue(value.trim())
  const handleSelect = ({ value }) => props.onChange(value)

  const bankGroup = groupBanks(props.group)
  const bankList = listBanksFromGroup(bankGroup)

  const results = searchValue === '' ? bankList : searchArray(searchValue, bankList, 'label')
  const { label = '' } = bankList.find(bank => bank.value === props.value) || {}

  return (
    <AutoComplete
      {...props}
      format="form"
      icon="search"
      onAfterOpen={handleSearch}
      onClear={handleClear}
      onSearch={handleSearch}
      onSelect={handleSelect}
      results={results}
      searchValue={searchValue}
      selectedValue={label}
    />
  )
}

BankField.propTypes = {
  group: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
