import * as filters from '@pretto/app-core/lib/filters'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'

const notaryFields = [
  {
    condition: ({ notary_known }) => notary_known === 'true',
    fieldProps: (values, project, user, hasBeenSubmitted) => ({
      defaultErrorText:
        hasBeenSubmitted && !isFieldValid(values['project.purchase.notary.name']) ? 'Ce champ est obligatoire' : '',
      placeholder: 'Simone de Beauvoir',
    }),
    label: 'Nom',
    name: 'project.purchase.notary.name',
    type: 'textfield',
  },
  {
    condition: ({ notary_known }) => notary_known === 'true',
    fieldProps: (values, project, user, hasBeenSubmitted) => ({
      defaultErrorText:
        hasBeenSubmitted && !isFieldValid(values['project.purchase.notary.email']) ? 'Ce champ est obligatoire' : '',
      placeholder: 'simone.debeauvoir@gmail.com',
      type: 'email',
    }),
    label: 'Email',
    name: 'project.purchase.notary.email',
    type: 'email',
  },
  {
    condition: ({ notary_known }) => notary_known === 'true',
    fieldProps: (values, project, user, hasBeenSubmitted) => ({
      defaultErrorText:
        hasBeenSubmitted && !isFieldValid(values['project.purchase.notary.phone']) ? 'Ce champ est obligatoire' : '',
      icon: 'phone',
      placeholder: '07 12 34 56 78',
      type: 'tel',
    }),
    label: 'Numéro de téléphone',
    name: 'project.purchase.notary.phone',
    type: 'tel',
  },
]

const decoder = filters.applyFilters([
  filters.applyFilter(
    'notary_known',
    (value, values) => (notaryFields.every(({ name }) => !values[name]) ? null : 'true'),
    false
  ),
  filters.applyFilter('project.purchase.notary.phone', filters.formatDecodePhone),
])

const encoder = filters.applyFilters([
  filters.applyOmitFilter('notary_known'),
  filters.applyFilter('project.purchase.notary.email', filters.formatEncodeEmail),
  filters.applyFilter('project.purchase.notary.name', filters.formatEncodeProprify),
  filters.applyFilter('project.purchase.notary.phone', filters.formatEncodePhone),
])

const errorBanner = {
  condition: (values, project, user, hasBeenSubmitted, isPageErrored) => {
    return hasBeenSubmitted && isPageErrored
  },
  children: 'Des champs sont manquants ou invalides.',
  type: 'error',
}

const errorSection = {
  fields: [errorBanner],
}

export const pages = [
  {
    decoder,
    encoder,
    sections: [
      {
        fields: [
          {
            fieldProps: {
              options: [
                { id: 'w1t', label: 'Oui', value: 'true' },
                { id: 'w1f', label: 'Non', value: 'false' },
              ],
            },

            label: 'Connaissez-vous les coordonnées de votre notaire ?',
            name: 'notary_known',
            required: true,
            type: 'radio',
          },
          ...notaryFields,
        ],
        title: 'Coordonnées de votre notaire',
      },
      errorSection,
    ],
    value: 'sale_agreement_signed',
  },
]
