import DashboardPage from '@pretto/bricks/app/property-search/pages/DashboardPage'
import { ng, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { detectMobileOS } from '@pretto/bricks/core/utility/detectMobileOS'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'
import LinkComponent from '@pretto/bricks/website/utility/Link'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'
import { v4 as uuidv4 } from 'uuid'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import Header from '../../../SharedContainers/Header'
import { mainSchema } from '../../config/schema'
import { renderSections } from '../../lib/renderForm'
import resetValuesWithSections from '../../lib/resetValuesWithSections'

const DEFAULT_ERROR_MESSAGE = "Une erreur est survenue pendant l'enregistrement. Veuillez réessayer !"
const DEFAULT_ERROR_NOTIFICATION_OPTIONS = { icon: 'cross-circle-2', type: 'error' }

const Link = styled(LinkComponent)`
  text-decoration: underline;
`

const encodeValuesWithSections = ({ sections }, values, isEditable, ...args) =>
  sections.reduce(
    (previous, { fields }) => ({
      ...previous,
      ...fields.reduce((previous, { condition = true, encoder = value => value, name, type }) => {
        const fieldCondition = funcToItem(condition, values, isEditable, ...args)

        if (!fieldCondition) {
          return previous
        }

        const value = values[name]

        if (type === 'checkboxes' && name === 'ground_level') {
          return { ...previous, [name]: value.length > 0 ? 'excluded' : 'insensitive' }
        }

        if (type === 'checkboxes' && name === 'elevator') {
          return { ...previous, [name]: value.length > 0 }
        }

        if (type === 'checkboxes') {
          if (value.length > 1) {
            return { ...previous, [name]: 'both' }
          }

          return { ...previous, [name]: value[0] }
        }

        return { ...previous, [name]: encoder(value) }
      }, previous),
    }),
    {}
  )

const isSubmitDisabled = ({ sections }, values, ...args) =>
  sections.reduce(
    (previous, { fields }) =>
      previous ||
      fields.reduce((previous, { condition = true, flags = [], name, required = false, type }) => {
        const fieldCondition = funcToItem(condition, values, ...args)

        if (!fieldCondition) {
          return previous
        }

        const hasFlags = flags.reduce(
          (previous, { condition, type }) =>
            previous || (type === 'error' && funcToItem(condition, values[name], values, values)),
          false
        )

        if (hasFlags) {
          return true
        }

        if (['checkboxes', 'cities'].includes(type) && required && (values[name] ?? []).length === 0) {
          return true
        }

        return previous || (required && !values[name])
      }, previous),
    false
  )

const DEBOUNCE_RATE = 500
const NOTIFICATION_LIFESPAN = 10000

const AlertsFormPage = ({ context, defaultValues, isCreated, isEditable, onSubmit, pageProps }) => {
  const authContext = useAuth()

  const timeouts = useRef([])

  const [values, setValues] = useState(defaultValues)
  const [isMutating, setIsMutating] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [visitedFieldNames, setVisitedFieldNames] = useState([])

  const [debouncedValues] = useDebounce(values, DEBOUNCE_RATE)

  useEffect(() => {
    if (isCreated) {
      push(
        <>
          Votre alerte a bien été enregistrée ! <Link href="/login">Accédez à votre Tableau de Bord</Link>
        </>
      )
    }

    return () => {
      timeouts.current.forEach(timeout => {
        clearTimeout(timeout)
      })
    }
  }, [])

  useUpdateEffect(() => {
    if (values.via_email) {
      setValues(values => ({ ...values, frequency: 'real_time' }))
    }
  }, [values.via_email])

  const handleVisit = name => {
    setVisitedFieldNames(names => {
      if (names.includes(name)) {
        return names
      }

      return [...names, name]
    })
  }

  const handleSubmit = async () => {
    if (isDisabled) {
      const index = formSections.findIndex(({ flags }) => flags.length > 0)
      const sectionElement = document.getElementById(`section-${index + 1}`)

      if (!sectionElement) {
        return
      }

      const offset = window.innerWidth < numeralBreakpoints.tablet ? ng(2) : ng(3)
      const top = sectionElement.getBoundingClientRect().top + window.pageYOffset - offset

      window.scrollTo({ behavior: 'smooth', top })
      return
    }

    setIsMutating(true)

    const encodedValues = encodeValuesWithSections(mainSchema, values, isEditable, authContext)

    try {
      await onSubmit(encodedValues, () => {
        setIsMutating(false)

        push(
          <>
            Vos modifications ont bien été enregistrées ! <Link href="/login">Accédez à votre Tableau de Bord</Link>
          </>
        )
      })
    } catch (error) {
      setIsMutating(false)

      switch (error.message) {
        case 'account_already_exists':
          push(
            'Vous avez déjà un compte Pretto, on vous a reconnu ! Connectez-vous à votre espace client pour créer ou modifier votre alerte.',
            DEFAULT_ERROR_NOTIFICATION_OPTIONS
          )
          break

        default:
          push(DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_NOTIFICATION_OPTIONS)
      }
    }
  }

  const handleValuesChange = (name, value) => {
    setValues(values => resetValuesWithSections(mainSchema, { ...values, [name]: value }, isEditable, authContext))
  }

  const os = detectMobileOS()

  const push = (children, options) => {
    const id = uuidv4()

    setNotifications(notifications => [{ ...options, children, id, in: true }, ...notifications])

    timeouts.current = [
      ...timeouts.current,
      setTimeout(() => {
        setNotifications(notifications =>
          notifications.map(notification => {
            if (notification.id === id) {
              return { ...notification, in: false }
            }

            return notification
          })
        )
      }, NOTIFICATION_LIFESPAN),
    ]
  }

  const formSections = renderSections(
    mainSchema.sections,
    handleValuesChange,
    handleVisit,
    values,
    debouncedValues,
    isEditable,
    visitedFieldNames,
    context,
    authContext
  )

  const isDisabled = isSubmitDisabled(mainSchema, values, isEditable, authContext) || isEqual(defaultValues, values)

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <DashboardPage
        {...pageProps}
        formSections={formSections}
        isEditable={isEditable}
        isLoading={isMutating}
        isLoggedIn={authContext.isLoggedIn}
        isSubmitDisabled={isDisabled}
        notifications={notifications}
        onSubmit={handleSubmit}
        os={os}
      />
    </>
  )
}

AlertsFormPage.defaultProps = {
  context: {},
  isCreated: false,
  isEditable: false,
}

AlertsFormPage.propTypes = {
  context: PropTypes.object,
  defaultValues: PropTypes.object.isRequired,
  isCreated: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
}

export default AlertsFormPage
