import { Color, Colors } from './compilers'
import palette from '@pretto/bricks/components/colors'

export const createDefinition = palette => {
  const colors = Colors.init()
  colors.add(
    Color.create(palette.primary1.default, 'primary1', 'primary-1')
      .dark(Color.create(palette.primary1.dark, 'primary-1-d').opposite(colors.fromName('white')))
      .fade(
        alpha => `primary-1-${alpha}`,
        index => colors.fromName(index < 80 ? 'neutral1' : 'white')
      )
      .light(Color.create(palette.primary1.light, 'primary-1-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.primary2.default, 'primary2', 'primary-2')
      .dark(Color.create(palette.primary2.dark, 'primary-2-d').opposite(colors.fromName('white')))
      .fade(alpha => `primary-2-${alpha}`, colors.fromName('neutral1'))
      .light(Color.create(palette.primary2.light, 'primary-2-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('neutral1'))
  )

  colors.add(
    Color.create(palette.neutral1.default, 'neutral1', 'neutral-1')
      .dark(Color.create(palette.neutral1.dark, 'neutral-1-d').opposite(colors.fromName('white')))
      .fade(
        alpha => `neutral-1-${alpha}`,
        index => colors.fromName(index < 40 ? 'neutral1' : 'white')
      )
      .light(Color.create(palette.neutral1.light, 'neutral-1-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.accent1.default, 'accent1', 'accent-1')
      .dark(Color.create(palette.accent1.dark, 'accent-1-d').opposite(colors.fromName('white')))
      .fade(
        alpha => `accent-1-${alpha}`,
        index => colors.fromName(index < 60 ? 'neutral1' : 'white')
      )
      .light(Color.create(palette.accent1.light, 'accent-1-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.accent2.default, 'accent2', 'accent-2')
      .dark(Color.create(palette.accent2.dark, 'accent-2-d').opposite(colors.fromName('white')))
      .fade(alpha => `accent-2-${alpha}`, colors.fromName('neutral1'))
      .light(Color.create(palette.accent2.light, 'accent-2-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('neutral1'))
  )

  colors.add(
    Color.create(palette.accent3.default, 'accent3', 'accent-3')
      .dark(Color.create(palette.accent3.dark, 'accent-3-d').opposite(colors.fromName('white')))
      .fade(alpha => `accent-3-${alpha}`, colors.fromName('neutral1'))
      .light(Color.create(palette.accent3.light, 'accent-3-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('neutral1'))
  )

  colors.add(
    Color.create(palette.accent4.default, 'accent4', 'accent-4')
      .dark(Color.create(palette.accent4.dark, 'accent-4-d').opposite(colors.fromName('white')))
      .fade(
        alpha => `accent-4-${alpha}`,
        index => colors.fromName(index < 70 ? 'neutral1' : 'white')
      )
      .light(Color.create(palette.accent4.light, 'accent-4-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.accent5.default, 'accent5', 'accent-5')
      .dark(Color.create(palette.accent5.dark, 'accent-5-d').opposite(colors.fromName('white')))
      .fade(
        alpha => `accent-5-${alpha}`,
        index => colors.fromName(index < 50 ? 'neutral1' : 'white')
      )
      .light(Color.create(palette.accent5.light, 'accent-5-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.success, 'success')
      .dark(Color.create(palette['success-d'], 'success-d').opposite(colors.fromName('white')))
      .light(Color.create(palette['success-l'], 'success-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.warning, 'warning')
      .dark(Color.create(palette['warning-d'], 'warning-d').opposite(colors.fromName('white')))
      .light(Color.create(palette['warning-l'], 'warning-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(
    Color.create(palette.error, 'error')
      .dark(Color.create(palette['error-d'], 'error-d').opposite(colors.fromName('white')))
      .light(Color.create(palette['error-l'], 'error-l').opposite(colors.fromName('neutral1')))
      .opposite(colors.fromName('white'))
  )

  colors.add(Color.create(palette.facebook, 'facebook').opposite(colors.fromName('white')))
  colors.add(Color.create(palette.linkedin, 'linkedin').opposite(colors.fromName('white')))
  colors.add(Color.create(palette.trustpilot, 'trustpilot').opposite(colors.fromName('white')))
  colors.add(Color.create(palette.twitter, 'twitter').opposite(colors.fromName('white')))
  colors.add(Color.create(palette.whatsapp, 'whatsapp').opposite(colors.fromName('white')))

  colors.add(Color.create(palette.white, 'white').opposite(colors.fromName('neutral1')))
  colors.add(Color.create('#000000', 'black').fade())
  return colors
}

export default createDefinition(palette)
