import { DataContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import { Capacity } from '@pretto/app/src/types/Capacity'
import { SuggestionType } from '@pretto/app/src/types/gateway/enums'

import { useContext, useEffect, useState } from 'react'

import { useEligibleSuggestionsNameQuery } from './eligibleSuggestionsName.gateway.graphql'
import { useSuggestionQuery } from './suggestion.gateway.graphql'

export interface Suggestion {
  result: Capacity
  type: SuggestionType
}

export const useSuggestions = () => {
  const { data: capacityData, contribution, payment, selectedLoanDuration: duration } = useContext(DataContext)

  const [data, setData] = useState<Suggestion[] | null>(null)
  const [error, setError] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [numberOfEligibleSuggestionsName, setNumberOfEligibleSuggestionsName] = useState<number>(0)
  const [numberOfLoadedSuggestions, setNumberOfLoadedSuggestions] = useState<number>(0)

  const { refetch: refetchEligibleSuggestionsName } = useEligibleSuggestionsNameQuery({
    fetchPolicy: 'network-only',
    skip: true,
  })

  const { refetch: refetchSuggestion } = useSuggestionQuery({ fetchPolicy: 'network-only', skip: true })

  useEffect(() => {
    const result = capacityData.capacity.find(({ financing }) => duration === financing.loanDuration)

    if (!duration || !result) {
      return
    }

    refresh(result.project.purchase.property_price, contribution, duration, payment)
  }, [capacityData, duration])

  const fetchSuggestion = async (
    type: SuggestionType,
    initialPropertyPrice: number,
    contribution: number,
    duration: number,
    maxPayment: number
  ): Promise<Suggestion> => {
    const { data, error } = await refetchSuggestion({ initialPropertyPrice, contribution, duration, maxPayment, type })

    if (error || !data.suggestion) {
      throw new Error(`Unable to load suggestion of type: ${type}`)
    }

    return { result: data.suggestion as Capacity, type }
  }

  const fetchSuggestions = async (
    initialPropertyPrice: number,
    contribution: number,
    duration: number,
    maxPayment: number,
    onEligibleSuggestionsNameDone?: (eligibleSuggestionsName: SuggestionType[]) => void,
    onSuggestionDone?: () => void
  ): Promise<Suggestion[]> => {
    const { data, error } = await refetchEligibleSuggestionsName({
      initialPropertyPrice,
      contribution,
      duration,
      maxPayment,
    })

    if (error) {
      throw new Error('Unable to retrieve list of suggestions')
    }

    if (data.eligible_suggestions_name.length === 0) {
      throw new Error('List of suggestions of empty')
    }

    onEligibleSuggestionsNameDone?.(data.eligible_suggestions_name)

    const suggestions = await Promise.all(
      data.eligible_suggestions_name.map(type =>
        fetchSuggestion(type, initialPropertyPrice, contribution, duration, maxPayment).then(suggestion => {
          onSuggestionDone?.()
          return suggestion
        })
      )
    )

    return suggestions
  }

  const refresh = async (initialPropertyPrice: number, contribution: number, duration: number, maxPayment: number) => {
    setData(null)
    setError(null)
    setIsLoading(true)
    setNumberOfEligibleSuggestionsName(0)
    setNumberOfLoadedSuggestions(0)

    try {
      const data = await fetchSuggestions(
        initialPropertyPrice,
        contribution,
        duration,
        maxPayment,
        eligibleSuggestionsName => {
          setNumberOfEligibleSuggestionsName(eligibleSuggestionsName.length)
        },
        () => {
          setNumberOfLoadedSuggestions(numberOfLoadedSuggestions => numberOfLoadedSuggestions + 1)
        }
      )

      setData(data)
    } catch (error) {
      setError(true)
      setData(null)
    } finally {
      setIsLoading(false)
    }
  }

  return { data, error, isLoading, numberOfEligibleSuggestionsName, numberOfLoadedSuggestions }
}
