import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { VALIDATE_ONBOARDING } from '@pretto/app/src/apollo'
import { Congratulations } from '@pretto/app/src/onboarding/views/Congratulations/Congratulations'

import { gql, useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const BOOKING_CONGRATULATIONS = gql`
  query Booking {
    next_booking {
      end
      start
      isVisio
    }
    deal {
      id
      appointmentKind
      isLowPotential
    }
    project {
      purchase {
        maturity
      }
    }
  }
`

export const CongratulationsPage = () => {
  const [validateOnboarding] = useMutation(VALIDATE_ONBOARDING)
  const { advisor, scoreZeroMinute } = useUser()

  const { refetch } = useAuth()
  const [isNextLoading, setIsNextLoading] = useState(false)

  const { data, loading } = useQuery(BOOKING_CONGRATULATIONS)

  const isBookingAvailable = scoreZeroMinute?.appointmentAllowed && !data?.next_booking

  const { push, goBack } = useHistory()

  const handleClickPrevious = () => {
    goBack()
  }

  const handleClickNext = async () => {
    setIsNextLoading(true)
    await validateOnboarding()
    await refetch()
    setIsNextLoading(false)

    if (isBookingAvailable) {
      return push('/onboarding/appointment_type')
    }

    push('/')
  }

  const props = {
    advisorLabel: advisor?.label,
    isBookingAvailable,
    isLoading: loading,
    isNextLoading,
    onNext: handleClickNext,
    onPrevious: handleClickPrevious,
  }

  return <Congratulations {...props} />
}
