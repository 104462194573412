import StoreButton from '@pretto/bricks/components/buttons/StoreButton'
import Card from '@pretto/bricks/components/cards/Card'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import styled from 'styled-components'

export const Aggregator = styled.div`
  padding: ${g(3)};

  @media screen and (max-width: ${breakpoints.laptop}) {
    flex-basis: 100%;
  }
`

export const AggregText = styled(BaseTypo)`
  margin-bottom: ${g(2)};
`

export const AppAd = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  height: ${g(37)};
  padding: 0;
`

export const ButtonApp = styled(StoreButton)`
  & + & {
    margin-left: ${g(1.5)};
  }
`

export const Container = styled.div`
  display: flex;
`

export const Headline = styled(BaseTypo).attrs({ typo: 'headline' })`
  margin-bottom: ${g(2)};
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
`


export const Phone = styled.img.attrs(({ $width }) => ({
  src: getCloudinaryUrl('v1619091824/bricks/static-assets/phone-truncated-dark.png', { width: ng($width) })}
))`
  display: block;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-right: ${g(2)};
    margin-top: ${g(5)};
  }
`
