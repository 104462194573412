import { g } from '@pretto/bricks/components/layout'

import styled, { keyframes } from 'styled-components'

const reflect = keyframes`
  0% {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    transform: skewX(-30deg) translateX(${g(-15)});
  }
  50% {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    transform: skewX(-30deg) translateX(${g(3)});
  }
  75% {
    transform: skewX(-30deg) translateX(${g(20)});
  }
  100% {
    transform: skewX(-30deg) translateX(${g(20)});
  }
`

interface PlaceholderProps {
  $w: number
}

export const Placeholder = styled.span<PlaceholderProps>`
  background-color: ${({ theme }) => theme.colors.neutral3};
  border-radius: 2px;
  display: inline-block;
  height: ${g(4)};
  overflow: hidden;
  position: relative;
  width: ${({ $w }) => g($w)};

  &:before,
  &:after {
    // animation: ${reflect} both infinite 3s;
    background-color: ${({ theme }) => theme.colors.neutral4};
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    transform-origin: left bottom;
    transform: skewX(-30deg) translateX(${g(3)});
    width: ${g(3)};
  }

  &:after {
    left: ${g(7 / 2)};
    width: ${g(3 / 2)};
  }
`

export const PlaceholderSmall = styled(Placeholder)`
  height: ${g(1, 4)};

  &:before,
  &:after {
    width: ${g(1 / 2)};
  }

  &:after {
    left: ${g(1)};
    width: ${g(3 / 2)};
  }
`
