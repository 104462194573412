import NavigationLinkProvider from '@pretto/bricks/components/utility/NavigationLinkProvider'

import PropTypes from 'prop-types'
import { createContext, forwardRef, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Front from '../Helpers/frontchat'

const NavigationContext = createContext()

const NavigationLink = forwardRef(({ href, ...props }, ref) => <Link {...props} innerRef={ref} to={href} />)

NavigationLink.propTypes = {
  href: PropTypes.string.isRequired,
}

NavigationLink.displayName = 'NavigationLink'

const NavigationProvider = ({ children }) => {
  const history = useHistory()

  const location = useLocation()

  useEffect(
    () =>
      history.listen(() => {
        Front('navigate')
      }),
    []
  )

  return (
    <NavigationLinkProvider linkComponent={NavigationLink} location={location}>
      <NavigationContext.Provider value={null}>{children}</NavigationContext.Provider>
    </NavigationLinkProvider>
  )
}

NavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavigationProvider
