import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const DialogContent = styled.div`
  padding: ${g(2)} ${g(2)} 0 ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(3)} 0 ${g(3)};
  }
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  padding: ${g(3)} 0 ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
    padding: ${g(4)} 0 ${g(5)};
  }
`

export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  min-height: 100%;
  padding-bottom: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    padding-bottom: ${g(7)};
  }
`

export const Title = styled.div`
  margin-bottom: ${g(2)};
`
