import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

interface ChipProps {
  children: React.ReactNode
}

export const Chip: React.FC<ChipProps & React.HTMLAttributes<HTMLSpanElement>> = ({ children, ...props }) => (
  <Component {...props}>{children}</Component>
)

const Component = styled.span`
  ${({ theme }) => theme.typos.caption};
  background-color: ${({ theme }) => theme.colors.primary3};
  color: ${({ theme }) => theme.colors.primary1};
  border-radius: ${g(1 / 2)};
  padding: ${g(1 / 2)} ${g(1)};
  white-space: nowrap;
`
