/* eslint-disable max-lines */

export default [
  '01120',
  '01170',
  '01200',
  '01210',
  '01220',
  '01280',
  '01480',
  '01550',
  '01600',
  '01630',
  '01700',
  '01710',
  '02600',
  '06000',
  '06100',
  '06110',
  '06130',
  '06140',
  '06150',
  '06160',
  '06190',
  '06200',
  '06210',
  '06220',
  '06230',
  '06240',
  '06250',
  '06270',
  '06300',
  '06310',
  '06320',
  '06330',
  '06340',
  '06360',
  '06370',
  '06380',
  '06390',
  '06400',
  '06410',
  '06460',
  '06480',
  '06500',
  '06510',
  '06520',
  '06530',
  '06550',
  '06560',
  '06570',
  '06580',
  '06590',
  '06600',
  '06610',
  '06620',
  '06640',
  '06650',
  '06670',
  '06690',
  '06700',
  '06730',
  '06740',
  '06790',
  '06800',
  '06810',
  '06830',
  '06950',
  '13000',
  '13001',
  '13002',
  '13003',
  '13004',
  '13005',
  '13006',
  '13007',
  '13008',
  '13009',
  '13010',
  '13011',
  '13012',
  '13013',
  '13014',
  '13015',
  '13016',
  '13080',
  '13090',
  '13100',
  '13103',
  '13104',
  '13105',
  '13109',
  '13110',
  '13111',
  '13112',
  '13119',
  '13120',
  '13122',
  '13124',
  '13127',
  '13130',
  '13140',
  '13170',
  '13180',
  '13190',
  '13200',
  '13210',
  '13220',
  '13230',
  '13240',
  '13250',
  '13260',
  '13270',
  '13290',
  '13300',
  '13310',
  '13320',
  '13330',
  '13340',
  '13360',
  '13380',
  '13390',
  '13400',
  '13410',
  '13420',
  '13450',
  '13470',
  '13480',
  '13500',
  '13117',
  '13510',
  '13530',
  '13540',
  '13580',
  '13590',
  '13600',
  '13610',
  '13620',
  '13650',
  '13680',
  '13700',
  '13710',
  '13720',
  '13730',
  '13740',
  '13760',
  '13770',
  '13780',
  '13790',
  '13800',
  '13118',
  '13820',
  '13821',
  '13830',
  '13840',
  '13850',
  '13860',
  '13880',
  '13920',
  '13950',
  '13960',
  '14000',
  '14111',
  '14120',
  '14123',
  '14150',
  '14200',
  '14280',
  '14460',
  '14600',
  '14610',
  '14650',
  '14760',
  '14790',
  '14800',
  '17000',
  '17111',
  '17137',
  '17138',
  '17139',
  '17140',
  '17180',
  '17220',
  '17340',
  '17410',
  '17440',
  '17450',
  '17580',
  '17590',
  '17630',
  '17670',
  '17690',
  '17740',
  '17880',
  '17940',
  '20000',
  '20100',
  '20110',
  '20129',
  '20137',
  '20167',
  '20169',
  '20200',
  '20213',
  '20214',
  '20215',
  '20220',
  '20222',
  '20230',
  '20256',
  '20260',
  '20290',
  '20600',
  '20620',
  '21000',
  '21121',
  '21160',
  '21240',
  '21300',
  '21370',
  '21600',
  '21800',
  '21850',
  '27140',
  '27200',
  '27340',
  '27460',
  '27520',
  '27600',
  '27670',
  '27950',
  '28000',
  '28110',
  '28130',
  '28230',
  '28260',
  '28300',
  '28600',
  '28630',
  '30000',
  '30114',
  '30121',
  '30128',
  '30129',
  '30131',
  '30132',
  '30133',
  '30210',
  '30220',
  '30230',
  '30240',
  '30250',
  '30300',
  '30310',
  '30320',
  '30400',
  '30420',
  '30470',
  '30510',
  '30540',
  '30600',
  '30620',
  '30640',
  '30650',
  '30660',
  '30670',
  '30820',
  '30900',
  '30920',
  '30980',
  '31000',
  '31100',
  '31120',
  '31130',
  '31140',
  '31150',
  '31170',
  '31180',
  '31200',
  '31240',
  '31270',
  '31300',
  '31320',
  '31400',
  '31450',
  '31470',
  '31490',
  '31500',
  '31520',
  '31600',
  '31620',
  '31650',
  '31670',
  '31700',
  '31750',
  '31770',
  '31780',
  '31790',
  '31820',
  '31830',
  '31840',
  '31850',
  '31860',
  '31880',
  '33000',
  '33100',
  '33110',
  '33120',
  '33127',
  '33130',
  '33140',
  '33150',
  '33160',
  '33170',
  '33185',
  '33200',
  '33260',
  '33270',
  '33290',
  '33300',
  '33310',
  '33320',
  '33360',
  '33370',
  '33380',
  '33400',
  '33440',
  '33450',
  '33470',
  '33500',
  '33520',
  '33530',
  '33550',
  '33560',
  '33600',
  '33610',
  '33650',
  '33700',
  '33750',
  '33800',
  '33850',
  '33870',
  '33880',
  '33980',
  '34000',
  '34070',
  '34080',
  '34090',
  '34110',
  '34130',
  '34140',
  '34160',
  '34170',
  '34200',
  '34250',
  '34270',
  '34280',
  '34300',
  '34340',
  '34400',
  '34410',
  '34420',
  '34430',
  '34450',
  '34470',
  '34540',
  '34560',
  '34570',
  '34590',
  '34660',
  '34670',
  '34680',
  '34690',
  '34730',
  '34740',
  '34750',
  '34770',
  '34790',
  '34820',
  '34830',
  '34880',
  '34920',
  '34970',
  '34980',
  '34990',
  '35000',
  '35131',
  '35132',
  '35135',
  '35136',
  '35170',
  '35200',
  '35230',
  '35235',
  '35250',
  '35310',
  '35400',
  '35510',
  '35520',
  '35590',
  '35650',
  '35690',
  '35700',
  '35740',
  '35760',
  '35770',
  '35800',
  '35830',
  '35850',
  '37000',
  '37100',
  '37150',
  '37170',
  '37200',
  '37210',
  '37230',
  '37250',
  '37270',
  '37300',
  '37390',
  '37400',
  '37510',
  '37520',
  '37530',
  '37540',
  '37550',
  '37700',
  '38000',
  '38070',
  '38080',
  '38090',
  '38100',
  '38113',
  '38120',
  '38130',
  '38140',
  '38170',
  '38180',
  '38190',
  '38220',
  '38230',
  '38240',
  '38290',
  '38300',
  '38320',
  '38330',
  '38340',
  '38360',
  '38400',
  '38410',
  '38420',
  '38430',
  '38450',
  '38500',
  '38560',
  '38570',
  '38600',
  '38610',
  '38640',
  '38670',
  '38700',
  '38760',
  '38800',
  '38850',
  '38920',
  '38950',
  '40130',
  '40150',
  '40220',
  '40230',
  '40390',
  '40440',
  '40510',
  '40530',
  '40600',
  '44000',
  '44100',
  '44115',
  '44117',
  '44120',
  '44210',
  '44220',
  '44230',
  '44240',
  '44300',
  '44340',
  '44350',
  '44380',
  '44400',
  '44470',
  '44480',
  '44490',
  '44500',
  '44510',
  '44550',
  '44570',
  '44600',
  '44610',
  '44620',
  '44640',
  '44700',
  '44710',
  '44740',
  '44760',
  '44800',
  '44830',
  '44840',
  '44860',
  '44880',
  '44980',
  '45100',
  '45140',
  '45160',
  '45380',
  '45400',
  '45430',
  '45560',
  '45590',
  '45650',
  '45750',
  '45760',
  '45770',
  '45800',
  '51100',
  '51350',
  '51370',
  '51430',
  '51450',
  '51500',
  '54000',
  '54130',
  '54320',
  '54500',
  '54520',
  '54600',
  '56170',
  '56360',
  '57000',
  '57050',
  '57070',
  '57140',
  '57155',
  '57950',
  '59000',
  '59100',
  '59110',
  '59113',
  '59115',
  '59116',
  '59117',
  '59118',
  '59120',
  '59126',
  '59130',
  '59139',
  '59150',
  '59152',
  '59155',
  '59160',
  '59166',
  '59170',
  '59175',
  '59193',
  '59200',
  '59211',
  '59223',
  '59236',
  '59237',
  '59250',
  '59260',
  '59262',
  '59270',
  '59280',
  '59290',
  '59300',
  '59320',
  '59350',
  '59370',
  '59390',
  '59420',
  '59491',
  '59493',
  '59500',
  '59510',
  '59520',
  '59560',
  '59650',
  '59700',
  '59777',
  '59780',
  '59790',
  '59800',
  '59810',
  '59830',
  '59840',
  '59850',
  '59890',
  '59910',
  '59930',
  '59960',
  '60000',
  '60100',
  '60110',
  '60140',
  '60150',
  '60160',
  '60170',
  '60180',
  '60200',
  '60230',
  '60240',
  '60250',
  '60260',
  '60270',
  '60280',
  '60290',
  '60300',
  '60340',
  '60460',
  '60500',
  '60520',
  '60530',
  '60540',
  '60550',
  '60560',
  '60580',
  '60590',
  '60600',
  '60610',
  '60660',
  '60700',
  '60750',
  '60800',
  '60820',
  '60840',
  '60870',
  '60880',
  '60940',
  '62300',
  '62400',
  '63000',
  '63100',
  '63400',
  '64100',
  '64122',
  '64200',
  '64210',
  '64310',
  '64340',
  '64480',
  '64500',
  '64600',
  '64700',
  '64990',
  '66000',
  '66100',
  '66140',
  '66180',
  '66200',
  '66250',
  '66420',
  '66700',
  '66750',
  '67000',
  '67100',
  '67114',
  '67115',
  '67116',
  '67118',
  '67170',
  '67200',
  '67201',
  '67202',
  '67203',
  '67204',
  '67205',
  '67206',
  '67207',
  '67210',
  '67300',
  '67380',
  '67400',
  '67450',
  '67460',
  '67540',
  '67550',
  '67610',
  '67640',
  '67800',
  '67810',
  '67960',
  '68100',
  '68200',
  '68300',
  '68330',
  '69000',
  '69001',
  '69002',
  '69003',
  '69004',
  '69005',
  '69006',
  '69007',
  '69008',
  '69009',
  '69100',
  '69110',
  '69120',
  '69126',
  '69130',
  '69140',
  '69150',
  '69160',
  '69190',
  '69200',
  '69210',
  '69220',
  '69230',
  '69250',
  '69260',
  '69270',
  '69280',
  '69290',
  '69300',
  '69310',
  '69320',
  '69330',
  '69340',
  '69350',
  '69360',
  '69370',
  '69380',
  '69390',
  '69400',
  '69410',
  '69440',
  '69450',
  '69480',
  '69500',
  '69510',
  '69520',
  '69530',
  '69540',
  '69570',
  '69580',
  '69600',
  '69630',
  '69640',
  '69650',
  '69660',
  '69670',
  '69680',
  '69700',
  '69720',
  '69730',
  '69740',
  '69760',
  '69780',
  '69800',
  '69830',
  '69890',
  '69960',
  '69970',
  '73000',
  '73100',
  '73160',
  '73190',
  '73200',
  '73230',
  '73290',
  '73370',
  '73420',
  '73490',
  '73540',
  '73790',
  '73800',
  '74000',
  '74100',
  '74130',
  '74140',
  '74150',
  '74160',
  '74200',
  '74230',
  '74240',
  '74250',
  '74300',
  '74330',
  '74350',
  '74370',
  '74380',
  '74460',
  '74500',
  '74520',
  '74540',
  '74560',
  '74570',
  '74580',
  '74600',
  '74650',
  '74700',
  '74800',
  '74890',
  '74930',
  '74940',
  '74950',
  '74960',
  '74970',
  '75000',
  '75001',
  '75002',
  '75003',
  '75004',
  '75005',
  '75006',
  '75007',
  '75008',
  '75009',
  '75010',
  '75011',
  '75012',
  '75013',
  '75014',
  '75015',
  '75016',
  '75017',
  '75018',
  '75019',
  '75020',
  '76000',
  '76100',
  '76120',
  '76130',
  '76133',
  '76140',
  '76150',
  '76160',
  '76230',
  '76240',
  '76250',
  '76280',
  '76290',
  '76300',
  '76310',
  '76320',
  '76350',
  '76380',
  '76410',
  '76420',
  '76500',
  '76520',
  '76530',
  '76600',
  '76610',
  '76620',
  '76650',
  '76700',
  '76710',
  '76770',
  '76800',
  '76920',
  '76930',
  '76960',
  '77000',
  '77090',
  '77100',
  '77120',
  '77124',
  '77127',
  '77130',
  '77135',
  '77140',
  '77144',
  '77150',
  '77160',
  '77164',
  '77167',
  '77170',
  '77173',
  '77176',
  '77177',
  '77181',
  '77183',
  '77184',
  '77185',
  '77186',
  '77190',
  '77200',
  '77210',
  '77220',
  '77230',
  '77240',
  '77250',
  '77260',
  '77270',
  '77280',
  '77290',
  '77300',
  '77310',
  '77330',
  '77340',
  '77350',
  '77360',
  '77370',
  '77380',
  '77400',
  '77410',
  '77420',
  '77430',
  '77450',
  '77470',
  '77500',
  '77515',
  '77550',
  '77580',
  '77590',
  '77600',
  '77630',
  '77640',
  '77670',
  '77680',
  '77700',
  '77810',
  '77850',
  '77860',
  '77870',
  '77920',
  '77930',
  '77950',
  '78000',
  '78100',
  '78110',
  '78112',
  '78114',
  '78117',
  '78120',
  '78121',
  '78125',
  '78126',
  '78130',
  '78140',
  '78150',
  '78160',
  '78170',
  '78180',
  '78190',
  '78200',
  '78210',
  '78220',
  '78230',
  '78240',
  '78250',
  '78260',
  '78270',
  '78280',
  '78290',
  '78300',
  '78310',
  '78320',
  '78330',
  '78340',
  '78350',
  '78360',
  '78370',
  '78380',
  '78390',
  '78400',
  '78410',
  '78420',
  '78430',
  '78440',
  '78450',
  '78460',
  '78470',
  '78480',
  '78490',
  '78500',
  '78510',
  '78520',
  '78530',
  '78540',
  '78550',
  '78560',
  '78570',
  '78580',
  '78590',
  '78600',
  '78610',
  '78620',
  '78630',
  '78640',
  '78650',
  '78670',
  '78680',
  '78690',
  '78700',
  '78710',
  '78711',
  '78720',
  '78740',
  '78750',
  '78760',
  '78780',
  '78800',
  '78810',
  '78820',
  '78840',
  '78850',
  '78860',
  '78870',
  '78920',
  '78930',
  '78955',
  '78960',
  '78970',
  '78990',
  '80000',
  '80136',
  '80330',
  '80440',
  '80450',
  '80470',
  '80480',
  '83000',
  '83100',
  '83110',
  '83120',
  '83130',
  '83140',
  '83143',
  '83150',
  '83160',
  '83170',
  '83190',
  '83200',
  '83210',
  '83220',
  '83230',
  '83240',
  '83250',
  '83260',
  '83270',
  '83300',
  '83310',
  '83320',
  '83330',
  '83340',
  '83350',
  '83370',
  '83390',
  '83400',
  '83420',
  '83430',
  '83440',
  '83460',
  '83470',
  '83480',
  '83490',
  '83500',
  '83510',
  '83520',
  '83530',
  '83550',
  '83580',
  '83600',
  '83640',
  '83700',
  '83720',
  '83740',
  '83780',
  '83820',
  '83830',
  '83860',
  '83870',
  '83910',
  '83920',
  '83980',
  '83990',
  '84000',
  '84100',
  '84120',
  '84130',
  '84150',
  '84160',
  '84170',
  '84190',
  '84200',
  '84210',
  '84240',
  '84250',
  '84260',
  '84270',
  '84300',
  '84310',
  '84320',
  '84330',
  '84350',
  '84370',
  '84380',
  '84410',
  '84420',
  '84440',
  '84450',
  '84460',
  '84470',
  '84510',
  '84530',
  '84700',
  '84740',
  '84800',
  '84810',
  '84850',
  '84860',
  '84870',
  '85100',
  '85180',
  '85330',
  '85340',
  '85630',
  '85680',
  '85740',
  '85800',
  '87000',
  '87100',
  '87280',
  '91000',
  '91070',
  '91080',
  '91090',
  '91100',
  '91120',
  '91130',
  '91140',
  '91150',
  '91160',
  '91170',
  '91180',
  '91190',
  '91200',
  '91210',
  '91220',
  '91230',
  '91240',
  '91250',
  '91260',
  '91270',
  '91280',
  '91290',
  '91300',
  '91310',
  '91320',
  '91330',
  '91340',
  '91350',
  '91360',
  '91370',
  '91380',
  '91390',
  '91400',
  '91410',
  '91420',
  '91430',
  '91440',
  '91450',
  '91460',
  '91470',
  '91480',
  '91510',
  '91520',
  '91530',
  '91540',
  '91550',
  '91560',
  '91570',
  '91580',
  '91590',
  '91600',
  '91610',
  '91620',
  '91630',
  '91640',
  '91650',
  '91680',
  '91700',
  '91710',
  '91720',
  '91730',
  '91750',
  '91760',
  '91770',
  '91790',
  '91800',
  '91810',
  '91820',
  '91830',
  '91840',
  '91850',
  '91860',
  '91910',
  '91940',
  '92000',
  '92100',
  '92110',
  '92120',
  '92130',
  '92140',
  '92150',
  '92160',
  '92170',
  '92190',
  '92200',
  '92210',
  '92220',
  '92230',
  '92240',
  '92250',
  '92260',
  '92270',
  '92290',
  '92300',
  '92310',
  '92320',
  '92330',
  '92340',
  '92350',
  '92360',
  '92370',
  '92380',
  '92390',
  '92400',
  '92410',
  '92420',
  '92430',
  '92500',
  '92600',
  '92700',
  '92800',
  '93000',
  '93100',
  '93110',
  '93120',
  '93130',
  '93140',
  '93150',
  '93160',
  '93170',
  '93190',
  '93200',
  '93210',
  '93220',
  '93230',
  '93240',
  '93250',
  '93260',
  '93270',
  '93290',
  '93300',
  '93310',
  '93320',
  '93330',
  '93340',
  '93350',
  '93360',
  '93370',
  '93380',
  '93390',
  '93400',
  '93410',
  '93420',
  '93430',
  '93440',
  '93450',
  '93460',
  '93470',
  '93500',
  '93600',
  '93700',
  '93800',
  '94000',
  '94100',
  '94110',
  '94120',
  '94130',
  '94140',
  '94150',
  '94160',
  '94170',
  '94190',
  '94200',
  '94210',
  '94220',
  '94230',
  '94240',
  '94250',
  '94260',
  '94270',
  '94290',
  '94300',
  '94310',
  '94320',
  '94340',
  '94350',
  '94360',
  '94370',
  '94380',
  '94400',
  '94410',
  '94420',
  '94430',
  '94440',
  '94450',
  '94460',
  '94470',
  '94480',
  '94490',
  '94500',
  '94510',
  '94520',
  '94550',
  '94600',
  '94700',
  '94800',
  '94880',
  '95000',
  '95100',
  '95110',
  '95120',
  '95130',
  '95140',
  '95150',
  '95160',
  '95170',
  '95180',
  '95190',
  '95200',
  '95210',
  '95220',
  '95230',
  '95240',
  '95250',
  '95260',
  '95270',
  '95280',
  '95290',
  '95300',
  '95310',
  '95320',
  '95330',
  '95340',
  '95350',
  '95360',
  '95370',
  '95380',
  '95390',
  '95400',
  '95410',
  '95430',
  '95440',
  '95450',
  '95460',
  '95470',
  '95480',
  '95490',
  '95500',
  '95510',
  '95520',
  '95530',
  '95540',
  '95550',
  '95560',
  '95570',
  '95580',
  '95590',
  '95600',
  '95610',
  '95620',
  '95630',
  '95650',
  '95660',
  '95670',
  '95680',
  '95690',
  '95700',
  '95720',
  '95740',
  '95760',
  '95800',
  '95810',
  '95820',
  '95840',
  '95870',
  '95880',
  '97100',
  '97110',
  '97111',
  '97112',
  '97113',
  '97114',
  '97115',
  '97116',
  '97117',
  '97118',
  '97119',
  '97120',
  '97121',
  '97122',
  '97123',
  '97125',
  '97126',
  '97127',
  '97128',
  '97129',
  '97130',
  '97131',
  '97134',
  '97421',
  '97136',
  '97137',
  '97139',
  '97140',
  '97141',
  '97142',
  '97160',
  '97170',
  '97180',
  '97190',
  '97200',
  '97211',
  '97212',
  '97213',
  '97214',
  '97215',
  '97216',
  '97217',
  '97218',
  '97220',
  '97221',
  '97222',
  '97223',
  '97224',
  '97225',
  '97226',
  '97227',
  '97228',
  '97229',
  '97230',
  '97231',
  '97232',
  '97233',
  '97234',
  '97240',
  '97250',
  '97260',
  '97270',
  '97280',
  '97290',
  '97300',
  '97310',
  '97311',
  '97312',
  '97313',
  '97314',
  '97315',
  '97316',
  '97317',
  '97319',
  '97320',
  '97330',
  '97340',
  '97350',
  '97351',
  '97354',
  '97355',
  '97356',
  '97360',
  '97370',
  '97380',
  '97390',
  '97400',
  '97410',
  '97412',
  '97413',
  '97414',
  '97419',
  '97420',
  '97421',
  '97425',
  '97426',
  '97427',
  '97429',
  '97430',
  '97431',
  '97433',
  '97436',
  '97438',
  '97439',
  '97440',
  '97441',
  '97442',
  '97450',
  '97460',
  '97470',
  '97480',
  '97600',
  '97610',
  '97620',
  '97625',
  '97630',
  '97640',
  '97650',
  '97660',
  '97670',
  '97680',
]
