import RatingScale from '@pretto/bricks/components/form/RatingScale'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import Row from '@pretto/bricks/shared/components/Row'
import SliderRange from '@pretto/bricks/shared/components/SliderRange'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const LABEL = {
  currency: '€',
  duration: 'ans',
}

const SimulationControls = ({ controls }) => (
  <S.Controls>
    {controls.map(({ format, isDisabled, label, props, type }, index) => {
      if (isDisabled) {
        return (
          <S.Control key={index}>
            <Row
              label={
                <Text size="small" variant="neutral-1-60">
                  {label}
                </Text>
              }
              value={<SubHeading>{formatNumber(props.value, { suffix: LABEL[format] })}</SubHeading>}
            />
          </S.Control>
        )
      }

      switch (type) {
        case 'slider':
          return (
            <S.Control key={index}>
              <SliderRange sliderProps={{ ...props, railVariant: 'neutral3' }} title={label} type={format} />
            </S.Control>
          )

        case 'segmentedControl': {
          const handleChange = ({ value }) => {
            props.onChange(value)
          }

          return (
            <S.Control key={index}>
              <S.Label>
                <SubHeading>{label}</SubHeading>
              </S.Label>

              <RatingScale {...props} onChange={handleChange} />
            </S.Control>
          )
        }

        default:
          return null
      }
    })}
  </S.Controls>
)

SimulationControls.propTypes = {
  /** Array of controls. */
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      format: PropTypes.oneOf(['currency', 'duration']),
      isDisabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      props: PropTypes.shape({ onChange: PropTypes.func.isRequired, value: PropTypes.any.isRequired }).isRequired,
      type: PropTypes.oneOf(['slider', 'segmentedControl']).isRequired,
    })
  ).isRequired,
}

export default memo(SimulationControls)
