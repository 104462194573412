import MaturityPageTemplate from '@pretto/bricks/app/maturity/components/MaturityPageTemplate'
import FormList from '@pretto/bricks/components/layout/FormList'
import FormSection from '@pretto/bricks/components/layout/FormSection'
import FormTemplate from '@pretto/bricks/components/layout/FormTemplate'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import set from 'lodash/set'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Header from '../../../SharedContainers/Header'
import { PROPERTY, UPDATE_PROJECT } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'
import { pages as leadingSourcePages } from '../../config/leadingSource'
import { pages as propertyPages } from '../../config/property'
import usePropertyController from '../../lib/usePropertyController'

const MaturityPropertyPage = ({ data }) => {
  const { push } = useHistory()
  const { params } = useRouteMatch()

  const client = useApolloClient()
  const [updateProject] = useMutation(UPDATE_PROJECT)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const schema = propertyPages.find(({ value }) => value === params.option)
  const shouldGoToLeadingSource = leadingSourcePages.some(({ value }) => value === params.option)

  const { getPayload, isDisabled, sections } = usePropertyController(schema, data.project)
  const trackAction = useTracking()

  const handleSubmit = async () => {
    setIsSubmitting(true)

    const payload = getPayload()

    set(payload, 'purchase.maturity', params.option)

    await updateProject({ update: handleUpdate, variables: { project: JSON.stringify(payload) } })

    trackAction('DASHBOARD_MATURITY_UPDATED', { maturity_option_value: params.option })
  }

  const handleUpdate = async () => {
    await invalidateCache(client)

    if (shouldGoToLeadingSource) {
      push(`/maturity/property-leading-source/${params.option}`)
    } else {
      push('/')
    }
  }

  if (isSubmitting) {
    return <SpinnerLegacy overlay />
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <MaturityPageTemplate
        description="D’accord ! Donnez-nous plus d’informations sur votre bien pour mettre à jour votre projet :"
        title="Dites-nous en plus"
      >
        <FormTemplate
          isDisabled={isDisabled}
          onSubmit={handleSubmit}
          submitLabel={shouldGoToLeadingSource ? 'Continuer' : 'Mettre à jour'}
        >
          {sections.map(({ fields, title }, index) => (
            <FormSection key={index} title={title}>
              <FormList fields={fields} />
            </FormSection>
          ))}
        </FormTemplate>
      </MaturityPageTemplate>
    </>
  )
}

MaturityPropertyPage.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.object.isRequired,
  }).isRequired,
}

const MaturityPropertyPageController = () => {
  const { data, loading } = useQuery(PROPERTY)

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  return <MaturityPropertyPage data={data} />
}

export default MaturityPropertyPageController
