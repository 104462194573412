import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { SimulationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

import { Container, Header, Title } from './Styled'

export const BuyoutCredit: React.FC = () => {
  const context = useSentences()
  const { buyoutCreditRemainingPrincipal, hasBuyoutCredit, simulationType } = context

  if (simulationType !== SimulationType.Buyout || hasBuyoutCredit === false || isNil(buyoutCreditRemainingPrincipal)) {
    return null
  }

  return (
    <Container $isEditable={false}>
      <Header>
        <Title>{t(`sentences.charge.credits.subtitle.purchase`)} 1 :</Title>
      </Header>
      <Prompt isFilled>{t(`sentences.charge.credits.values.mortgage`)}</Prompt>
      {t(`sentences.charge.credits.sentences.3`)}
      <Prompt isFilled>{buyoutCreditRemainingPrincipal}</Prompt>
      {t(`sentences.charge.credits.sentences.4`)}
    </Container>
  )
}
