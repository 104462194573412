import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'
import Row from '@pretto/bricks/shared/components/Row'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'
import { Component } from 'react'

import * as C from './ActionCard.module.css'

class ActionCard extends Component {
  render() {
    const {
      buttonLabel,
      children,
      title,
      optionNumber,
      childrenPosition,
      emoji,
      pushedContent,
      data,
      action,
      format,
      onClickInformation,
      isLoggedIn,
    } = this.props

    const cardProps = {
      variant: 'white',
      format: 'full',
      zDepth: 2,
    }

    const simpleFormat = (
      <Card {...cardProps}>
        <Content size="small" format="center">
          <SubHeading>{title}</SubHeading>
        </Content>
        <Content>
          <Text size="small">{children}</Text>
          <div className={C.actionCardButton}>
            <ButtonLegacy
              label={buttonLabel || 'Choisir'}
              onClick={action}
              format={['full', 'line']}
              variant="primary-1"
            />
          </div>
        </Content>
      </Card>
    )

    const heading = (
      <div className={C.actionCardPushedContent}>
        {emoji && (
          <div className={C.actionCardPushedContentEmoji}>
            <Emoji size="medium" verticalAlign="center">
              {emoji}
            </Emoji>
          </div>
        )}
        <div className={C.actionCardPushedContentAmount}>{pushedContent}</div>
      </div>
    )
    return (
      <div className={classnames(C.actionCard, C[`actionCard${pascalcase(format ?? '')}`])}>
        {format === 'simple' ? (
          simpleFormat
        ) : (
          <Card {...cardProps}>
            <Content variant="primary-1" size="x-small" format="center">
              {optionNumber && (
                <div className={C.actionCardOptionNumber}>
                  <SubHeading size="small" variant="white" format="card-title">
                    Option {optionNumber}
                  </SubHeading>
                </div>
              )}
              <div className={C.actionCardTitle}>
                <SubHeading variant="white">{title}</SubHeading>
              </div>
            </Content>
            <Content>
              {childrenPosition === 'top' && format !== 'capacity' && (
                <div className={C.actionCardDescription}>
                  <Text size="small">{children}</Text>
                </div>
              )}
              <div>
                {format === 'capacity' && <div>Vous pouvez acheter</div>}
                <Mobile>
                  <Heading size="large">{heading}</Heading>
                </Mobile>
                <Desktop>
                  <Heading>{heading}</Heading>
                </Desktop>
              </div>
              {onClickInformation && (
                <div onClick={() => onClickInformation(this.props)} className={C.actionCardPushedContentIconContent}>
                  <Icon name="add" size="small" variant="primary-1" />
                  <div className={C.actionCardPushedContentIconContentText}>
                    <Text variant="primary-1">Détails du projet</Text>
                  </div>
                </div>
              )}
              {format === 'default' && (
                <div className={C.actionCardDivider}>
                  <Divider />
                </div>
              )}
              {format === 'button-middle' && (
                <div className={C.actionCardButton}>
                  <ButtonLegacy onClick={action} format="full" variant="accent-1">
                    {buttonLabel || 'Choisir'}
                  </ButtonLegacy>
                </div>
              )}
              {childrenPosition === 'bottom' && format !== 'capacity' && (
                <div className={C.actionCardDescription}>
                  <Text size="small">{children}</Text>
                </div>
              )}
              {format !== 'capacity' && (
                <div className={C.actionCardData}>
                  {data.map(row => (
                    <div key={row.label} className={C.actionCardDataRow}>
                      <Row
                        label={
                          <Text size="small" variant="neutral-1-60">
                            {row.label}
                          </Text>
                        }
                        value={<SubHeading>{row.value}</SubHeading>}
                      />
                    </div>
                  ))}
                </div>
              )}
              {format === 'default' && (
                <div className={C.actionCardButton}>
                  <ButtonLegacy label={buttonLabel || 'Choisir'} onClick={action} format="full" variant="accent-1" />
                </div>
              )}
              {format === 'capacity' && (
                <div className={C.actionCardButton}>
                  <ButtonLegacy onClick={action} format="full" variant="accent-1">
                    {buttonLabel || `${isLoggedIn ? 'Choisir' : 'Créer'} ce projet`}
                  </ButtonLegacy>
                </div>
              )}
            </Content>
          </Card>
        )}
      </div>
    )
  }
}

ActionCard.propTypes = {
  buttonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  childrenPosition: PropTypes.string,
  title: PropTypes.string.isRequired,
  optionNumber: PropTypes.number,
  emoji: PropTypes.string,
  pushedContent: PropTypes.string,
  data: PropTypes.array,
  action: PropTypes.func.isRequired,
  format: PropTypes.string,
  onClickInformation: PropTypes.func,
  isLoggedIn: PropTypes.bool,
}

ActionCard.defaultProps = {
  childrenPosition: 'top',
  format: 'default',
}

export default ActionCard
