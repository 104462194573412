import { SwitchToggle } from '@pretto/zen/atoms/selectors/SwitchToggle/SwitchToggle'

export interface ServiceCardSwitchProps {
  label: string
  onToggle: () => void
  value: boolean
}

export const ServiceCardSwitch = ({ value, onToggle, label, ...props }: ServiceCardSwitchProps) => (
  <SwitchToggle id="subscribe" isToggled={value} label={label} onToggle={onToggle} {...props} />
)
