import MandateTemplate from '@pretto/bricks/app/mandate/templates/MandateTemplate'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'
import BubbleChat from '@pretto/bricks/shared/components/BubbleChat'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const MandateSignaturePage = ({ avatarProps, errorMessage, messages, templateProps }) => {
  return (
    <MandateTemplate
      {...templateProps}
      sidebar={messages.map((message, i) => (
        <S.Message key={i}>
          <BubbleChat avatarProps={avatarProps} hideAvatar={i > 0}>
            {message}
          </BubbleChat>
        </S.Message>
      ))}
      title={
        <>
          <Emoji size="medium">:pencil:</Emoji> Signature de votre mandat
        </>
      }
    >
      {errorMessage && (
        <S.Alert>
          <Card variant="error" format="line">
            <Content variant="error-l">
              <Text variant="error">{errorMessage}</Text>
            </Content>
          </Card>
        </S.Alert>
      )}
      <S.Content id="helloSignModal" />
    </MandateTemplate>
  )
}

MandateSignaturePage.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Content of page. */
  children: PropTypes.node.isRequired,
  /** Error message */
  errorMessage: PropTypes.node,
  /** Messages */
  messages: PropTypes.array.isRequired,
  /** Properties of `MandateTemplate` bricks component. */
  templateProps: PropTypes.object,
}

export default memo(MandateSignaturePage)
