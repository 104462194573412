import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Container = ({ children, ...props }) => <S.Container {...props}>{children}</S.Container>

Container.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  full: PropTypes.bool,
}

export default memo(Container)
