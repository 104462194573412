import { g, ng } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { BulletBold } from '@pretto/picto'

import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

type Type = 'active' | 'done' | 'inactive'

export interface TimelineStepProps {
  href?: string
  label: string
  nextTimelineStepType?: Type
  onLabelClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  position: 'first' | 'middle' | 'last' | 'orphan'
  previousTimelineStepType?: Type
  type: Type
}

const HEIGHT = ng(5)
const WIDTH = ng(2)

const POINTS: Record<Type, [string, string]> = {
  active: [`${WIDTH / 2},0 ${WIDTH / 2},5`, `${WIDTH / 2},${HEIGHT - 5} ${WIDTH / 2},${HEIGHT}`],
  done: [`${WIDTH / 2},0 ${WIDTH / 2},11`, `${WIDTH / 2},${HEIGHT - 11} ${WIDTH / 2},${HEIGHT}`],
  inactive: [`${WIDTH / 2},0 ${WIDTH / 2},23`, `${WIDTH / 2},${HEIGHT - 23} ${WIDTH / 2},${HEIGHT}`],
}

export const TimelineStep: React.FC<TimelineStepProps & React.HTMLAttributes<HTMLLIElement>> = ({
  href,
  label,
  nextTimelineStepType,
  onLabelClick,
  position,
  previousTimelineStepType,
  type,
  ...props
}) => {
  const { current: initialType } = useRef(type)
  const animatersRef = useRef<Record<Type, [SVGAnimateElement | null, SVGAnimateElement | null]>>({
    active: [null, null],
    done: [null, null],
    inactive: [null, null],
  })

  useEffect(() => {
    animatersRef.current[type][0]?.beginElement()
    animatersRef.current[type][1]?.beginElement()
  }, [type])

  return (
    <ListItem {...props} $type={type}>
      <Halo $isActive={type === 'active'} />

      <Svg $isActive={type !== 'inactive'} as={BulletBold} />

      <Svg $isActive height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`} width={WIDTH}>
        {position !== 'first' && position !== 'orphan' && (
          <Line $type={type === 'inactive' ? type : previousTimelineStepType} points={POINTS[initialType][0]}>
            {Object.entries(POINTS).map(([type, [points]]) => (
              <animate
                attributeName="points"
                begin="indefinite"
                calcMode="spline"
                dur="400ms"
                end="indefinite"
                fill="freeze"
                key={type}
                keySplines="0.25 1 0.5 1"
                keyTimes="0; 1"
                ref={(node: SVGAnimateElement) => {
                  animatersRef.current[type as Type][0] = node
                }}
                to={points}
              />
            ))}
          </Line>
        )}

        {position !== 'last' && position !== 'orphan' && (
          <Line $type={type === 'inactive' ? type : nextTimelineStepType} points={POINTS[initialType][1]}>
            {Object.entries(POINTS).map(([type, [, points]]) => (
              <animate
                attributeName="points"
                begin="indefinite"
                calcMode="spline"
                dur="400ms"
                end="indefinite"
                fill="freeze"
                key={type}
                keySplines="0.25 1 0.5 1"
                keyTimes="0; 1"
                ref={(node: SVGAnimateElement) => {
                  animatersRef.current[type as Type][1] = node
                }}
                to={points}
              />
            ))}
          </Line>
        )}
      </Svg>

      {!href || type === 'inactive' ? (
        <span>{label}</span>
      ) : (
        <Link href={href} onClick={onLabelClick} role="menuitem">
          {label}
        </Link>
      )}
    </ListItem>
  )
}

interface ActiveProps {
  $isActive: boolean
}

const Halo = styled.span<ActiveProps>`
  ${transition({ propertyName: 'transform' })};
  background-color: ${({ theme }) => theme.colors.primary4};
  border-radius: ${g(3 / 2)};
  height: ${g(3)};
  grid-column: 1;
  grid-row: 1;
  margin-left: ${g(-1 / 2)};
  transform: scale(0);
  width: ${g(3)};
  z-index: 0;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: scale(1);
    `}
`

interface TypeProps {
  $type: Type
}

const Line = styled.polygon<Partial<TypeProps>>`
  ${transition({ propertyName: 'stroke' })};
  stroke: ${({ $type, theme }) => {
    switch ($type) {
      case 'inactive':
        return theme.colors.neutral2

      case 'active':
      case 'done':
      default:
        return theme.colors.primary2
    }
  }};
  stroke-linejoin: round;
  stroke-width: 2;
`

const ListItem = styled.li<TypeProps>`
  ${({ $type, theme }) => {
    switch ($type) {
      case 'active':
        return css`
          ${theme.typos.body4Bold}
          color: ${theme.colors.primary2};
        `

      case 'done':
        return css`
          ${theme.typos.body4}
          color: ${theme.colors.neutral1};
        `

      case 'inactive':
      default:
        return css`
          ${theme.typos.body4}
          color: ${theme.colors.neutral2};
        `
    }
  }}
  align-items: center;
  display: grid;
  grid-template-columns: ${g(2)} 1fr;
  gap: ${g(2)};
  justify-items: flex-start;
  padding-left: ${g(1)};
`

const Svg = styled.svg<ActiveProps>`
  ${transition({ propertyName: 'transform' })};
  display: block;
  fill: ${({ theme }) => theme.colors.primary2};
  grid-column: 1;
  grid-row: 1;
  transform: scale(0);
  z-index: 1;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: scale(1);
    `}
`
