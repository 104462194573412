import Card from '@pretto/bricks/components/cards/Card'
import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import { ExclamationMarkTriangle } from '@pretto/picto'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Description = styled(BaseTypo).attrs({ typo: 'bodyMedium' })`
  color: ${({ theme }) => theme.colors.error1};
`

export const ErrorBanner = styled(Card).attrs({ variant: 'error2' })`
  padding: ${g(2)};
`

export const Icon = styled.div`
  margin-right: ${g(2)};
  width: ${g(3)};
  height: ${g(3)};
`

export const SeeMore = styled(Link)`
  color: ${({ theme }) => theme.colors.error1};
  text-decoration: underline;
`

export const Warning = styled(ExclamationMarkTriangle)`
  fill: ${({ theme }) => theme.colors.error1};
`
