import type { GraphStruct } from '@pretto/app/src/overview/types/graph'

import { FEES } from './rows'

export const PURCHASE = [
  [
    { key: 'project.purchase.property_price', type: 'single' },
    { key: 'project.purchase.works_price', type: 'single' },
    { key: 'project.purchase.land_price', type: 'single' },
    { key: 'project.purchase.build_price', type: 'single' },
    { key: 'project.purchase.furniture_price', type: 'single' },
    { key: 'best_result.financing.bridge.remaining', type: 'single' },
    {
      keys: FEES,
      title: 'Frais',
      type: 'sum',
    },
  ],
  [
    { key: 'best_result.financing.loanAmount', type: 'single' },
    { key: 'best_result.financing.bridge.value', type: 'single' },
    { key: 'best_result.financing.contribution', type: 'single' },
  ],
] as const satisfies GraphStruct

export const RENEGOTIATION = [
  [
    { key: 'project.renegotiation.remaining_principal', type: 'single' },
    { key: 'best_result.financing.penaltyFees', title: 'IRA', type: 'single' },
    { key: 'project.renegotiation.works_amount', title: 'Travaux', type: 'single' },
    {
      keys: ['best_result.financing.guarantyFees', 'best_result.financing.applicationFees', 'brokerage_fees.amount'],
      title: 'Frais',
      type: 'sum',
    },
  ],
  [
    { key: 'best_result.financing.loanAmount', type: 'single' },
    { key: 'best_result.financing.contribution', type: 'single' },
  ],
] as const satisfies GraphStruct
