import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'
import { funcToItem } from '@pretto/bricks/core/utility/funcToItem'

import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Notifications } from './components/Notifications/Notifications'
import type { Notification, Options } from './types/types'

interface NotificationReturn {
  pop: () => void
}

interface INotificationsContext {
  notify: (
    message: Notification['message'] | ((notification: NotificationReturn) => Notification['message']),
    options?: Options
  ) => NotificationReturn
  notifyLegacy: (title: string, description: string, options?: Options) => NotificationReturn
}

const NotificationsContext = createContext<INotificationsContext>({
  notify() {
    return {
      pop() {
        // do nothing
      },
    }
  },
  notifyLegacy() {
    return {
      pop() {
        // do nothing
      },
    }
  },
})

const defaultOptions: Required<Options> = { delay: 3000, type: 'success' }

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const timeouts = useRef<Array<ReturnType<typeof setTimeout>>>([])

  useEffect(
    () => () => {
      timeouts.current.forEach(timeout => clearTimeout(timeout))
    },
    []
  )

  const notify = useCallback<INotificationsContext['notify']>((message, localOptions) => {
    const pop = () => {
      setNotifications(notifications => notifications.filter(({ id }) => id !== notification.id))
    }

    const options = {
      ...defaultOptions,
      ...localOptions,
    }

    const notification: Notification = {
      id: uuidv4(),
      isDismissible: options?.delay === null,
      message: funcToItem(message, { pop }),
      onDismiss: pop,
      type: options.type,
    }

    if (options?.delay !== null) {
      const timeout = setTimeout(pop, options.delay)

      timeouts.current = [...timeouts.current, timeout]
    }

    setNotifications(notifications => [...notifications, notification])

    return { pop }
  }, [])

  const notifyLegacy = useCallback<INotificationsContext['notifyLegacy']>(
    (title, description, localOptions) =>
      notify(
        <>
          <strong>{title}</strong>
          <br />
          {description}
        </>,
        localOptions
      ),
    []
  )

  return (
    <NotificationsContext.Provider value={{ notify, notifyLegacy }}>
      <ThemeProvider designSystem="reveal">
        <Notifications notifications={notifications} />
      </ThemeProvider>

      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => useContext(NotificationsContext)
