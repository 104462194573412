import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Page = styled.div`
  align-items: center;
  display: flex;
  margin: auto;
  max-width: ${breakpoints.tablet};
  min-height: 100vh;
  padding: ${g(2)} 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} 0;
  }
`
export const Emoji = styled.div`
  margin-bottom: ${g(3)};
`
export const Title = styled.div`
  margin-bottom: ${g(3)};
`
export const Paragraph = styled.div`
  margin-bottom: ${g(3)};
`
export const Cta = styled.a`
  text-decoration: underline;

  &,
  &:visited {
    color: ${({ theme }) => theme.legacy.colors.accent1.default};
  }
`
