import GoogleOrEmailComponent from '@pretto/bricks/app/shared/components/GoogleOrEmail'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Title = styled.div`
  ${typo.heading32};
  text-align: center;
  ${column(6)};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${typo.heading48};
  }
`

export const Content = styled.div`
  ${column([2, 4])};
`

export const GoogleOrEmail = styled(GoogleOrEmailComponent)`
  margin-bottom: ${g(2)};
`

export const NewsletterCheckbox = styled(InputCheckbox)`
  margin-bottom: ${g(6.5)};
`

export const Newsletter = styled.span`
  ${typo.caption12};
`

export const ButtonConnect = styled(ButtonInline).attrs({ isSecondary: true })`
  display: block;
  margin: auto;
  width: fit-content;
`

export const Image = styled(ImageComponent).attrs({
  options: {
    width: 280,
  },
  path: 'v1637683145/zen/static-assets/illustrations/660x660/manInBath.svg',
})`
  display: block;
  margin: 0 auto;
`
