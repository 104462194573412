import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

interface ToggledProps {
  $isToggled: boolean
}

export const SwitchToggleContainer = styled.div`
  display: flex;
`
export const Label = styled.label`
  margin-right: ${g(2)};
  cursor: pointer;
`
export const ButtonWrapper = styled.button<ToggledProps>`
  flex-shrink: 0;
  height: ${g(3)};
  width: ${g(5)};
  background-color: ${({ $isToggled, theme }) => ($isToggled ? theme.colors.primary2 : theme.colors.neutral3)};
  border-radius: ${g(2.5)};
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background-color 150ms ease-in-out;
`
export const Button = styled.div<ToggledProps>`
  height: ${g(2, 2)};
  width: ${g(2, 2)};
  margin: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: inherit;
  position: relative;
  transition: transform 150ms ease-in-out;
  z-index: 1;

  ${({ $isToggled }) =>
    $isToggled &&
    css`
      transform: translateX(${g(2)});
      border-color: ${({ theme }) => theme.colors.primary2};
    `}
`
