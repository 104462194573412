import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Tooltip as TooltipComponent } from '@pretto/zen/atoms/infos/Tooltip/Tooltip'

import { ChevronDownBold, EqualBold, InfoCircle, MinusBold, PlusBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const ToggleDetails = styled.div`
  ${typo.heading16};
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const ResultCardDetails = styled.div``

export const DetailsIcon = styled(ChevronDownBold)`
  margin-right: ${g(1)};
  transition: all 300ms ease-out;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotateZ(180deg);
    `};
`

export const DetailsWrapper = styled.div`
  padding-top: ${g(2)};
  padding-bottom: ${g(1)};
`

export const DetailsContent = styled.div`
  margin-top: ${g(2)};
`

export const DetailsTitle = styled.div`
  ${typo.heading16};
  margin: ${g(3)} 0;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const Icon = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  flex-shrink: 0;
  ${({ $picto }) =>
    [EqualBold, MinusBold, PlusBold].includes($picto)
      ? css`
          width: ${g(2)};
          height: ${g(2)};
          margin-right: ${g(3)};
        `
      : css`
          width: ${g(3)};
          height: ${g(3)};
          margin-right: ${g(2)};
        `}
`

export const Label = styled.span`
  margin-right: ${g(1, 4)};
`
export const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
`

export const PictoInfo = styled(InfoCircle)`
  width: ${g(2)};
  height: ${g(2)};
`

export const DetailsValue = styled.div`
  ${typo.isNumber};
  ${typo.bodyBook16};
  margin-left: auto;
  color: ${({ theme, $color }) => theme.colors[$color ?? 'black']};
`
