import * as filters from '@pretto/app-core/lib/filters'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'

import qs from 'qs'

import { ERRORS, MATCHING_NAMES } from '../../Account/config/labels'
import { INVITE_COMORTGAGOR, UPDATE_ACCOUNT } from '../../apollo'
import ApplicationIntroductionPage from '../Containers/ApplicationIntroductionPage'
import { ApplicationPropertyLeadingSourcePage } from '../Containers/ApplicationPropertyLeadingSourcePage/ApplicationPropertyLeadingSourcePage'

import { defaultPage as banksPage } from './banks'

const firstname = {
  fieldProps: (values, project, user, hasBeenSubmitted) => {
    return {
      defaultErrorText:
        hasBeenSubmitted && !isFieldValid(values['accounts[1].firstName']) ? 'Ce champ est obligatoire' : '',
      placeholder: 'Simone',
    }
  },
  label: 'Son prénom',
  name: 'accounts[1].firstName',
  type: 'textfield',
}

const lastname = {
  fieldProps: (values, project, user, hasBeenSubmitted) => ({
    defaultErrorText:
      hasBeenSubmitted && !isFieldValid(values['accounts[1].lastName']) ? 'Ce champ est obligatoire' : '',
    placeholder: 'de Beauvoir',
  }),
  label: 'Son nom',
  name: 'accounts[1].lastName',
  type: 'textfield',
}

const email = {
  condition: (values, data, { comortgagorInvited }) => !comortgagorInvited,
  fieldProps: (values, project, user, hasBeenSubmitted) => ({
    error: (value, project, { user: { email } }) => {
      return value.toLowerCase() === email.toLowerCase() && 'Les emails des emprunteurs ne peuvent pas être identiques'
    },
    icon: 'mail',
    placeholder: 'simone.debeauvoir@gmail.com',
    type: 'email',
    defaultErrorText: hasBeenSubmitted && !isFieldValid(values['accounts[1].email']) ? 'Ce champ est obligatoire' : '',
  }),
  label: 'Son mail',
  name: 'accounts[1].email',
  type: 'email',
}

const phone = {
  fieldProps: (values, project, user, hasBeenSubmitted) => ({
    icon: 'phone',
    placeholder: '07 12 34 56 78',
    type: 'tel',
    defaultErrorText: hasBeenSubmitted && !isFieldValid(values['accounts[1].phone']) ? 'Ce champ est obligatoire' : '',
  }),
  label: 'Son numéro de téléphone',
  name: 'accounts[1].phone',
  type: 'tel',
}

const errorBanner = {
  condition: (values, project, user, hasBeenSubmitted, isPageErrored) => {
    return hasBeenSubmitted && isPageErrored
  },
  children: 'Des champs sont manquants ou invalides.',
  type: 'error',
}

const errorSection = {
  fields: [errorBanner],
}

const comortgagorSection = {
  condition: (data, { comortgagorDeclared, hasComortgagor }) => hasComortgagor && !comortgagorDeclared,
  description: 'Présentez-nous la personne qui emprunte avec vous.',
  fields: [firstname, lastname, email, phone],
  helper:
    'Ces informations permettent à Pretto de vérifier l’identité de la personne avec qui vous empruntez.\n\nCes informations de contact doivent être différentes des vôtres.',
  mutation: (values, data, { accounts, comortgagorInvited }) => {
    if (comortgagorInvited) {
      return {
        mutation: UPDATE_ACCOUNT,
        variables: {
          id: accounts[1].id,
          payload: Object.entries(values.accounts[1]).reduce(
            (previous, [key, value]) => ({ ...previous, [MATCHING_NAMES[key] || key]: value }),
            {}
          ),
        },
      }
    }

    return {
      error: response => {
        const { error } = response.data.invite_mortgagor

        if (error) {
          return ERRORS[error.toUpperCase()].message
        }

        return null
      },
      mutation: INVITE_COMORTGAGOR,
      variables: values.accounts[1],
    }
  },
  title: 'Avec qui empruntez-vous ?',
}

// Pages
const firstPage = {
  component: ApplicationIntroductionPage,
  jumps: (project, context, search) => {
    if (context.hasComortgagor) {
      return `project${search}`
    }

    if (project.project_kind === 'purchase' && project.purchase?.maturity === 'sale_agreement_signed') {
      return 'notary'
    }

    return `banks${search}`
  },
  title: 'Introduction',
}

const secondPage = {
  decoder: filters.applyFilters([filters.applyFilter('accounts[1].phone', filters.formatDecodePhone)]),
  encoder: filters.applyFilters([
    filters.applyFilter('accounts[1].email', filters.formatEncodeEmail),
    filters.applyFilter('accounts[1].firstName', filters.formatEncodeProprify),
    filters.applyFilter('accounts[1].lastName', filters.formatEncodeProprify),
    filters.applyFilter('accounts[1].phone', filters.formatEncodePhone),
  ]),
  hasNextPage: true,
  id: 'project',
  jumps: (project, context, search) => {
    return project.project_kind === 'purchase' && project.purchase.maturity === 'sale_agreement_signed'
      ? `notary${search}`
      : `banks${search}`
  },
  optional: false,
  sections: [comortgagorSection, errorSection],
  title: 'Votre projet',
}

const thirdPage = {
  component: ApplicationPropertyLeadingSourcePage,
  id: 'notary',
  jumps: (project, context, search) => `banks${search}`,
  title: 'Notaire',
}

const fourthPage = {
  ...banksPage,
  id: 'banks',
  jumps: (project, context, search) => qs.parse(search, { ignoreQueryPrefix: true })?.redirect ?? '/application',
  optional: false,
}

const step = {
  condition: false,
  ignoreAccessRestriction: true,
  pages: [firstPage, secondPage, thirdPage, fourthPage],
}

export default step
