export const DEFAULT_BUFFER_DURATION = 15

export const DEFAULT_DURATION = 15

// It is mandatory that these values correspond to the values in api/app/appointment/book.rb#APPOINTMENT_DURATION
export const DURATION_TYPES = {
  adp: 15,
  r1: 30,
  r3: 30,
  certificate: 30,
  suivi: 15,
}

export const PRE_BUFFER_TYPES = {
  adp: 15,
  r1: 15,
  r3: 15,
  certificate: 15,
  suivi: 0,
}
