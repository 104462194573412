import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const BlocSave = ({ emailFieldComponent, onCguClick, onGoogleConnect, onPrivacyClick }) => {
  return (
    <>
      <BaseTypo typo="headline">Sauvegardez votre simulation !</BaseTypo>
      <Responsive max="desktop">
        <S.GoogleConnect onClick={onGoogleConnect}>Se connecter</S.GoogleConnect>
      </Responsive>
      <Responsive min="desktop">
        <S.GoogleConnect onClick={onGoogleConnect} />
      </Responsive>
      <S.Divider>— ou —</S.Divider>
      {emailFieldComponent}
      <S.CGU>
        En continuant, vous acceptez nos{' '}
        <S.CGULink href="https://www.pretto.fr/cgu/" onClick={onCguClick}>
          conditions d'utilisation
        </S.CGULink>{' '}
        et notre{' '}
        <S.CGULink href="/privacy" onClick={onPrivacyClick} target="blank">
          politique de confidentialité.
        </S.CGULink>
      </S.CGU>
    </>
  )
}

BlocSave.propTypes = {
  emailFieldComponent: PropTypes.node,
  onCguClick: PropTypes.func,
  /** onClick function triggered when cross icon is clicked. */
  onGoogleConnect: PropTypes.func,
  /** Validating the dialog simulaiton. */
  onPrivacyClick: PropTypes.func,
}
export default BlocSave
