import Text from '@pretto/bricks/components/typography/Text'
import EditableInput from '@pretto/bricks/shared/components/EditableInput'
import Row from '@pretto/bricks/shared/components/Row'

import PropTypes from 'prop-types'
import { Component } from 'react'

import * as C from './EditableRow.module.css'

export default class EditableRow extends Component {
  static propTypes = {
    /** Triggered when any element of the row is Clicked. It automatically gets focus on the field. */
    onClick: PropTypes.func,
    /** Label of the component. */
    label: PropTypes.string,
    /** Props of <code>[NumberField](/#!/NumberField)</code> component. */
    numberFieldProps: PropTypes.object,
  }
  handleClick = () => {
    this.input.numberfield.focus()
    this.props.onClick()
  }
  render() {
    const { label, numberFieldProps, ...props } = this.props
    return (
      <Row
        label={
          <span className={C.editableRowLabel} onClick={this.handleClick}>
            <Text size="small" variant="neutral-1-60">
              {label}
            </Text>
          </span>
        }
        value={
          <span className={C.editableRowValue}>
            <EditableInput numberFieldProps={numberFieldProps} ref={input => (this.input = input)} />
          </span>
        }
        {...props}
      />
    )
  }
}
