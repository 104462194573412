import {
  OptionsEdit,
  OptionsStore,
  PropertySearchCard,
} from '@pretto/app/src/SharedContainers/Dashboard/PropertySearchCard'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { useTracking } from '@pretto/app/src/lib/tracking'

const PropertySearch = () => {
  const trackAction = useTracking()

  const handleTrackEdit = ({ origin, status }: OptionsEdit) => {
    trackAction('PROPERTY_SEARCH_DASHBOARD_CARD_CLICKED', {
      property_search_dashboard_card_click_origin: origin,
      property_search_dashboard_card_status: status,
    })
  }

  const handleTrackStore = ({ platform, status }: OptionsStore) => {
    trackAction('PROPERTY_SEARCH_DASHBOARD_CARD_STORE_CLICKED', {
      property_search_dashboard_card_platform: platform,
      property_search_dashboard_card_status: status,
    })
  }

  return <PropertySearchCard onTrackEdit={handleTrackEdit} onTrackStore={handleTrackStore} />
}

export const config: Config = {
  component: PropertySearch,
  condition: (data, { hasFeatureAccess }) => !hasFeatureAccess(['BUYOUT']),
  name: 'propertySearch',
}
