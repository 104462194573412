import { g } from '@pretto/bricks/components/layout'
import Tooltip from '@pretto/bricks/components/utility/Tooltip'
import * as typo from '@pretto/bricks/core/typography'
import { btoa } from '@pretto/bricks/core/utility/btoa'

import styled from 'styled-components'

const getColor = ({ theme }) => {
  switch (theme.state) {
    case 'error':
      return theme.colors.error1

    case 'warning':
      return theme.colors.warning1

    case 'default':
    default:
      return theme.colors.primary1
  }
}

export const Suffix = styled.span`
  ${typo.bodyMedium16};
  white-space: nowrap;
`
export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  z-index: 0;
`
export const Picto = styled.svg`
  display: block;
  color: ${getColor};
`
export const Component = styled.div`
  display: flex;
  padding: 0 ${g(0.5)};
  background-image: url('data:image/svg+xml;base64,${props =>
    btoa(
      `<svg viewBox="0 0 ${props.$patternWidth} ${
        props.$patternWidth / 2
      }" xmlns="http://www.w3.org/2000/svg"><circle cx="1" cy="1" r="1" fill="${getColor(props)}"/></svg>`
    )}');
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 4px 2px;
  position: relative;

  &:focus-within::before,
  &:hover::before {
    position: absolute;
    content: '';
    top: 0;
    right: 3px;
    bottom: 1px;
    left: 1px;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.neutral4};
  }

  input {
    color: ${getColor};
    border: 0;
    padding: 0;
    background-color: transparent;
    caret-color: ${({ theme }) => theme.colors.primary1};
    width: 100%;

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral3};
    }

    &:focus {
      outline: 0;
    }

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`
export const Input = styled.input`
  ${typo.bodyMedium16};
  color: ${getColor};
  border: 0;
  padding: 0;
  background-color: transparent;
  caret-color: ${({ theme }) => theme.colors.primary1};
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }

  &:focus {
    outline: 0;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const HiddenComponent = styled(Component)`
  display: inline-flex;
  position: absolute;
  left: -99999px;
  visibility: hidden;
`
export const HiddenInput = styled(Input).attrs({ as: 'div' })`
  white-space: pre;
`
export const PictoContainer = styled.div.attrs(
  ({ content }) =>
    content && {
      as: Tooltip,
      position: 'top',
    }
)`
  margin-left: ${g(1)};
`
