import { temporal } from '@pretto/bricks/core/utility/temporal'

import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { inputDateFormat, toShortISO } from '@pretto/app/src/Sentences/v2/lib/date'
import { Credit } from '@pretto/app/src/Sentences/v2/types/credit'
import { CreditType, SimulationType, SituationType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { calcPayment } from '@pretto/app/src/lib/helpers'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

import { CreditBoxSummary } from './CreditBoxSummary'
import { Container, Header, Title } from './Styled'

const mapOwnPropertyToCredit = (amount: number, endDate: Date, rate: number): Credit => ({
  amount: calcPayment(amount, rate, toShortISO(endDate)),
  endDate,
  type: CreditType.Mortgage,
})

export const OwnPropertyCredit: React.FC = () => {
  const context = useSentences()
  const { ownPropertyEndDate, ownPropertyRemainingPrincipal, ownPropertyRate, ownPropertyForSale } = context

  if (
    (context.simulationType !== SimulationType.Capacity && context.simulationType !== SimulationType.Purchase) ||
    context.usage !== Usage.Primary ||
    (!isNil(context.mortgagors?.[0]?.situationType) &&
      context.mortgagors?.[0]?.situationType !== SituationType.Owner) ||
    (!isNil(context.mortgagors?.[1]?.situationType) &&
      context.mortgagors?.[1]?.situationType !== SituationType.Owner) ||
    !ownPropertyRemainingPrincipal ||
    !ownPropertyEndDate ||
    !ownPropertyRate ||
    !ownPropertyForSale
  ) {
    return null
  }

  return (
    <Container $isEditable={false}>
      <Header>
        <Title>{t(`sentences.charge.credits.subtitle.purchase`)} 1 :</Title>
      </Header>
      <Prompt isFilled> {t(`sentences.charge.credits.values.mortgage`)}</Prompt>
      <CreditBoxSummary
        credit={mapOwnPropertyToCredit(ownPropertyRemainingPrincipal, ownPropertyEndDate, ownPropertyRate)}
        date={temporal(ownPropertyEndDate).format(inputDateFormat || '')}
      />
    </Container>
  )
}
