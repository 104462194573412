export function btoa(str) {
  if (typeof window !== 'undefined') {
    return window.btoa(str)
  }

  try {
    let buffer

    if (str instanceof Buffer) {
      buffer = str
    } else {
      buffer = Buffer.from(str.toString(), 'binary')
    }

    return buffer.toString('base64')
  } catch (error) {
    console.error(error)
  }
}
