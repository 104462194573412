import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

const spacings = {
  default: `
    margin-left: ${g(2)};
    margin-right: ${g(2)};
  `,
  tablet: `
    margin-left: ${g(3)};
    margin-right: ${g(3)};
  `,
  laptop: `
    margin-left: ${g(6)};
    margin-right: ${g(6)};
  `,
  desktop: `
    max-width: ${breakpoints.desktop};
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${g(6)};
  `,
}

export const Wrapper = styled.div`
  ${props => {
    const targetBreakpoint = props.full === true ? 'laptop' : props.full

    return Object.entries(spacings).reduce((previous, [breakpoint, style]) => {
      if (
        targetBreakpoint &&
        Object.keys(spacings).indexOf(targetBreakpoint) > Object.keys(spacings).indexOf(breakpoint)
      ) {
        return previous
      }

      return [
        ...previous,
        ...(breakpoint === 'default'
          ? css`
              ${style}
            `
          : css`
              @media screen and (min-width: ${breakpoints[breakpoint]}) {
                ${style}
              }
            `),
      ]
    }, [])
  }}
`
