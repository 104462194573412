import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ProgressLegacy = ({ backgroundVariant, format, gradient, max, size, value, variant, ...props }) => (
  <S.Progress {...props} completed={value === max} format={format}>
    <S.Container size={size} variant={backgroundVariant}>
      <S.Content gradient={gradient} max={max} value={value} variant={variant} />
    </S.Container>
  </S.Progress>
)

ProgressLegacy.defaultProps = {
  backgroundVariant: 'neutral-1-20',
  format: 'default',
  max: 2,
  value: 0,
  variant: 'primary-1',
}

ProgressLegacy.propTypes = {
  /** Variant styles of Progress (background). */
  backgroundVariant: PropTypes.string,
  /** Format of Progress. */
  format: PropTypes.string,
  /** Multiple colors of progress. */
  gradient: PropTypes.array,
  /** Maximum value of Progress. */
  max: PropTypes.number,
  /** Show steps as text. */
  showSteps: PropTypes.bool,
  /** Thickness of progress bar. */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Current value of Progress. */
  value: PropTypes.number,
  /** Variant styles of Progress (bar). */
  variant: PropTypes.string,
}

export default memo(ProgressLegacy)
