import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import { Unavailable } from './styles'

const BookingUnavailable = ({ email, phoneE164, phoneNational }) => (
  <Unavailable>
    <Card variant="accent-1-l">
      <Content>
        <Text variant="accent-1">
          Désolé, nous n’avons pas trouvé de créneau de RDV avec votre expert. Nous vous invitons à l’appeler
          directement au <a href={`tel:${phoneE164}`}>{phoneNational}</a> ou à lui écrire à l’adresse{' '}
          <a href={`mailto:${email}`}>{email}</a>.
        </Text>
      </Content>
    </Card>
  </Unavailable>
)

BookingUnavailable.propTypes = {
  /** Advisor email. */
  email: PropTypes.string.isRequired,
  /** Advisor phone number in E164 format (ex. +33678910111). */
  phoneE164: PropTypes.string.isRequired,
  /** Advisor phone number in national format (ex. 06 78 91 01 11). */
  phoneNational: PropTypes.string.isRequired,
}

export default BookingUnavailable
