import SpinnerSimulation from '@pretto/bricks/components/loading/SpinnerSimulation'

import { t } from '@pretto/app/src/lib/i18n'

import * as S from './styles'

const SpinnerOverlay = () => (
  <S.Page>
    <SpinnerSimulation />
    <S.Text>{t('simulation.loader')}</S.Text>
  </S.Page>
)

export default SpinnerOverlay
