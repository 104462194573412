import { ContributionPage } from '@pretto/app/src/Sentences/v2/Contribution/Contribution/Containers/ContributionPage'
import { SavingsPage } from '@pretto/app/src/Sentences/v2/Contribution/Savings/Containers/SavingsPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ContributionController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase)/contribution/contribution')}
        component={ContributionPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase)/contribution/savings')}
        component={SavingsPage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
