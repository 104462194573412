import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import InputRadioGroup from '@pretto/bricks/components/form/InputRadioGroup'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Text from '@pretto/bricks/components/typography/Text'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as C from './PreferenceDialog.module.css'
import * as S from './styles'

const PreferenceDialog = ({
  advertising,
  advertisingDestinations,
  handleCancel,
  marketingAndAnalytics,
  marketingDestinations,
  onChange,
  onSave,
}) => {
  const handleSubmit = e => {
    e.preventDefault()

    if (marketingAndAnalytics === null || advertising === null) {
      return
    }
    onSave()
  }

  return (
    <Dialog isOpen={true} className={C.dialogContentMaxWidth}>
      <DialogTemplate
        navbarProps={{
          title: 'Paramètres des cookies',
        }}
      >
        <S.DialogContent>
          <form onSubmit={handleSubmit}>
            <S.Content>
              <p>
                Conformément à notre <Link href="https://www.pretto.fr/privacy/">politique de confidentialité</Link>,
                nous utilisons des cookies pour optimiser notre site et vous proposer la meilleure expérience possible.
                Vous pouvez paramétrer votre consentement à l’aide du tableau ci-dessous. Pour refuser l’une des
                catégories de traceurs, sélectionnez Non puis cliquez sur Confirmer.
              </p>
              <S.TableScroll>
                <S.Table>
                  <thead>
                    <S.Row>
                      <S.ColumnHeading scope="col">Autoriser</S.ColumnHeading>
                      <S.ColumnHeading scope="col">Catégorie</S.ColumnHeading>
                      <S.ColumnHeading scope="col">Description</S.ColumnHeading>
                      <S.ColumnHeading scope="col" showOnDesktop>
                        Outils
                      </S.ColumnHeading>
                    </S.Row>
                  </thead>
                  <tbody>
                    <S.Row>
                      <td>
                        <InputRadioGroup
                          format="inline"
                          name="marketingAndAnalytics"
                          options={[
                            { id: 'marketingAndAnalytics_yes', label: 'Oui', value: 'true' },
                            { id: 'marketingAndAnalytics_no', label: 'Non', value: 'false' },
                          ]}
                          valueSelected={String(marketingAndAnalytics === true)}
                          onChange={value => onChange('marketingAndAnalytics', value === 'true')}
                        />
                      </td>
                      <S.RowHeading scope="row">Statistiques et Audience</S.RowHeading>
                      <td>
                        <p>
                          Afin de comprendre comment les utilisateurs interagissent avec notre site, nous collectons des
                          données d’audience et des actions réalisées.
                        </p>
                      </td>
                      <S.Column>{marketingDestinations.map(d => d.name).join(', ')}</S.Column>
                    </S.Row>
                    <S.Row>
                      <td>
                        <InputRadioGroup
                          format="inline"
                          name="advertising"
                          options={[
                            { id: 'advertising_yes', label: 'Oui', value: 'true' },
                            { id: 'advertising_no', label: 'Non', value: 'false' },
                          ]}
                          valueSelected={String(advertising === true)}
                          onChange={value => onChange('advertising', value === 'true')}
                        />
                      </td>
                      <S.RowHeading scope="row">Annonces personnalisées</S.RowHeading>
                      <td>
                        <p>
                          Ces cookies nous permettent de vous proposer des annonces pertinentes et utiles en fonction
                          des pages que vous visitez sur notre site par exemple.
                        </p>
                      </td>
                      <S.Column>{advertisingDestinations.map(d => d.name).join(', ')}</S.Column>
                    </S.Row>
                    <S.Row>
                      <td>N/A</td>
                      <S.RowHeading scope="row">Nécessaires</S.RowHeading>
                      <td>
                        <p>
                          Les cookies nécessaires contribuent à rendre notre site utilisable en activant des fonctions
                          de base comme la navigation de page et l'accès aux zones sécurisées. Notre site ne peut pas
                          fonctionner correctement sans ces cookies
                        </p>
                      </td>
                      <S.Column />
                    </S.Row>
                  </tbody>
                </S.Table>
              </S.TableScroll>
            </S.Content>
            <S.ButtonsContainer>
              <S.CancelButton type="button" onClick={handleCancel} variant="accent-1">
                <Text variant="neutral-1" size="small">
                  Annuler
                </Text>
              </S.CancelButton>
              <ButtonSquared type="submit">Confirmer</ButtonSquared>
            </S.ButtonsContainer>
          </form>
        </S.DialogContent>
      </DialogTemplate>
    </Dialog>
  )
}

PreferenceDialog.propTypes = {
  advertising: PropTypes.bool,
  advertisingDestinations: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  marketingAndAnalytics: PropTypes.bool,
  marketingDestinations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default PreferenceDialog
