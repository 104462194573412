import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronDownBold, UserCircle } from '@pretto/picto'

import styled from 'styled-components'

export const Session = styled.div.attrs(({ href }) => (href ? { as: Link } : { as: 'button' }))`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 ${g(3)};
  flex-shrink: 0;
  cursor: pointer;
  background-color: inherit;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral4};
  }
`
export const MobileContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const DesktopContainer = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`
export const Initial = styled.div`
  ${typo.textLarge24};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${g(5)};
  width: ${g(5)};
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  flex-shrink: 0;
`
export const Name = styled.div`
  ${typo.bodyBook16};
  display: none;
  margin-left: ${g(1)};
  margin-right: ${g(1)};
  flex-shrink: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: inline;
  }
`
export const Picto = styled(ChevronDownBold)`
  height: ${g(2)};
  width: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral1};
  margin-left: ${g(1)};
`
export const LogIn = styled.div`
  ${typo.linkUnderline12};
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`
export const LoginPicto = styled(UserCircle)`
  height: ${g(3)};
  width: ${g(3)};
  margin-right: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
`
export const PictoMobile = styled.div`
  height: ${g(3)};
  width: ${g(3)};
  color: ${({ theme }) => theme.colors.neutral1};
`
