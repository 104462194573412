import MaturityUpdatePageTemplate from '@pretto/bricks/app/maturity/components/MaturityUpdateTemplate'
import temporal from '@pretto/bricks/core/utility/temporal'

import { MATURITY_EVENT } from '@pretto/app/src/Project/Containers/MaturityPage'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { removeItem, setItem } from '@pretto/app/src/config/itemStorage'
import {
  LAST_MATURITY_UPDATE_DATE,
  MATURITY_LOCAL_STORAGE,
} from '@pretto/app/src/dashboard/containers/maturity/MaturityModal'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { withApollo } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import { useState } from 'react'

import useMaturityUpdateController from '../../../Form/lib/useMaturityUpdateController'
import Header from '../../../SharedContainers/Header'

const STATUS_CODE_OK = 200

const MaturityUpdatePage = () => {
  const [status, setStatus] = useState('idle')
  const [loading, setLoading] = useState(false)
  const trackAction = useTracking()

  const handleSubmit = async (maturity, agreementSignDate, email, projectId, sig, typology) => {
    setLoading(true)
    setItem(LAST_MATURITY_UPDATE_DATE, temporal().format('YYYY-MM-DD'))
    removeItem(MATURITY_LOCAL_STORAGE)

    if (typology === 'prelead' && maturity === 'project_lost') {
      const project = {
        email,
        maturity,
        sig,
      }

      const result = await fetch(`https://api.pretto.fr/public/${projectId}/publish_lost_prelead`, {
        body: JSON.stringify(project),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      setStatus(result.status === STATUS_CODE_OK ? 'success' : 'error')
    } else if (maturity === 'project_lost') {
      const project = {
        email,
        lost_reason: maturity,
        sig,
      }

      const result = await fetch(`https://api.pretto.fr/public/${projectId}/lost_project`, {
        body: JSON.stringify(project),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      setStatus(result.status === STATUS_CODE_OK ? 'success' : 'error')
    } else {
      const project = {
        agreement_sign_date: agreementSignDate ? agreementSignDate : null,
        email,
        maturity,
        sig,
      }

      const result = await fetch(`https://api.pretto.fr/public/${projectId}/update_maturity`, {
        body: JSON.stringify(project),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      if (result.status === STATUS_CODE_OK) {
        setStatus('success')
        trackAction(MATURITY_EVENT, {
          origin: 'landing_page',
          value: maturity,
        })
      } else {
        setStatus('error')
      }
    }

    setLoading(false)
  }

  const {
    handleSubmit: onSubmit,
    inputProps,
    isErrored,
    maturity,
    radioProps,
    resultPageProps,
  } = useMaturityUpdateController({ isLoading: loading, onClick: handleSubmit })

  const handleRetry = () => {
    setStatus('idle')
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <MaturityUpdatePageTemplate
        isErrored={isErrored}
        isFormSolid
        inputProps={inputProps}
        onRetry={handleRetry}
        onSubmit={onSubmit}
        maturity={maturity}
        status={status}
        radioProps={radioProps}
        resultPageProps={resultPageProps}
      />
    </>
  )
}

MaturityUpdatePage.propTypes = {
  client: PropTypes.object,
}

export default withApollo(MaturityUpdatePage)
