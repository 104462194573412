import { Route } from '@pretto/app/src/Auth/Containers/Route/Route'
import CapacityPage from '@pretto/app/src/Capacity/Containers/CapacityPage'
import { ScoringProvider } from '@pretto/app/src/Scoring/contexts/ScoringContext/ScoringProvider'
import SimulationPage from '@pretto/app/src/Simulation/Containers/SimulationPage'
import Subscribe from '@pretto/app/src/Subscribe/Containers/SubscribePage'

import { Switch } from 'react-router'

export const ResultPageController = () => {
  return (
    <ScoringProvider>
      <Switch>
        <Route exact path="/capacity" component={CapacityPage} />
        <Route exact path="/simulation" component={SimulationPage} />
        <Route exact scope={['prospect', 'preLead']} path="/simulation/subscribe" component={Subscribe} />
      </Switch>
    </ScoringProvider>
  )
}
