import { g } from '@pretto/bricks/components/layout'

import { InputType } from '@pretto/app/src/purchaseOffer/config/types'

import styled, { css } from 'styled-components'

interface BlockProps {
  $type: InputType
}

export const Block = styled.div<BlockProps>`
  margin-top: ${g(3)};

  ${({ $type }) =>
    $type === 'checkbox' &&
    css`
      &:first-child {
        margin-top: ${g(2)};
      }
    `}
`
