import ApplicationIntroductionPageComponent from '@pretto/bricks/app/application/pages/ApplicationIntroductionPage'
import { useBreakpoint } from '@pretto/bricks/assets/utility'

import Front from '@pretto/app/src/Helpers/frontchat'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import { useEffect } from 'react'

const ApplicationIntroductionPage = ({ onTerminate }) => {
  const { user } = useUser()
  const trackAction = useTracking()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    trackAction('APPLICATION_INTRODUCTION_PAGE_LOADED')
  }, [])

  useEffect(() => {
    Front(isMobile ? 'hide' : 'show')
  }, [isMobile])

  const handleTracking = (name, properties) => {
    trackAction(name, properties)
  }

  const handleContinue = () => {
    onTerminate()
    trackAction('APPLICATION_INTRODUCTION_BUTTON_CLICKED')
  }

  return <ApplicationIntroductionPageComponent onContinue={handleContinue} onTrack={handleTracking} user={user} />
}

ApplicationIntroductionPage.propTypes = {
  onTerminate: PropTypes.func.isRequired,
}

export default ApplicationIntroductionPage
