import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { ArrowButtonLabel } from '@pretto/zen/reveal/atoms/buttons/arrowButtonLabel/ArrowButtonLabel'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { controllingClassName, DocumentIcon } from '@pretto/app-core/application/components/DocumentIcon/DocumentIcon'
import { FileItem, FileItemProps } from '@pretto/app-core/application/components/FileItem/FileItem'
import {
  FileOwnershipLabel,
  FileOwnershipLabelProps,
} from '@pretto/app-core/application/components/FileOwnershipLabel/FileOwnershipLabel'
import { UploadArea, UploadAreaProps } from '@pretto/app-core/application/components/UploadArea/UploadArea'

import { useState } from 'react'

import * as S from './UploadDialog.styles'

export type UploadDialogFile = FileItemProps & {
  id: string
}

export interface UploadDialogProps {
  description?: React.ReactNode | null
  errorMessage?: string | null
  explanation?: React.ReactNode | null
  fileOwnershipLabelProps: FileOwnershipLabelProps
  files: UploadDialogFile[]
  footerComponent?: React.ReactNode
  isDragging?: boolean
  nextHref?: string | null
  notToDo?: React.ReactNode[] | null
  onClose: () => void
  onMore?: () => void
  onNext?: () => void
  onPrevious?: () => void
  previousHref?: string | null
  title: string
  toDo?: React.ReactNode[] | null
  uploadAreaProps: UploadAreaProps
}

export const UploadDialog: React.FC<React.HTMLAttributes<HTMLDivElement> & UploadDialogProps> = ({
  description,
  errorMessage,
  explanation,
  fileOwnershipLabelProps,
  files,
  footerComponent,
  isDragging = false,
  nextHref,
  notToDo,
  onClose,
  onMore,
  onNext,
  onPrevious,
  previousHref,
  title,
  toDo,
  uploadAreaProps,
  ...props
}) => {
  const nextLabel = useBreakpointToValue('Suivant', { tablet: 'Document suivant' })
  const previousLabel = useBreakpointToValue('Précédent', { tablet: 'Document précédent' })

  const [isMoreOpened, setIsMoreOpened] = useState(false)

  const handleClickMoreButton = () => {
    setIsMoreOpened(isMoreOpened => !isMoreOpened)
    onMore?.()
  }

  return (
    <S.Content {...props}>
      <S.DragZone $isDragging={isDragging} className={controllingClassName}>
        <S.DragZoneContent>
          <DocumentIcon />
          <p>Glissez vos documents ici</p>
        </S.DragZoneContent>
      </S.DragZone>

      <S.Header>
        <div>
          <S.ButtonClose onClick={onClose} type="button">
            Terminer
          </S.ButtonClose>
          <S.Title>{title}</S.Title>
        </div>

        <S.Details>
          <FileOwnershipLabel {...fileOwnershipLabelProps} />
          {!!errorMessage && <S.Banner type="error">{errorMessage}</S.Banner>}
          {description}
          {(!!explanation || !!notToDo || !!toDo) && (
            <>
              <div>
                <S.MoreButton onClick={handleClickMoreButton} type="button">
                  Lire plus
                  <S.MoreButtonArrow $isMoreOpened={isMoreOpened} />
                </S.MoreButton>

                <CollapsibleRow childComponent={S.MoreChildComponent} in={isMoreOpened}>
                  <S.More>
                    {!!explanation && (
                      <div>
                        <S.MoreTitle>Ce document est obligatoire pour :</S.MoreTitle>
                        {explanation}
                      </div>
                    )}

                    {!!toDo && (
                      <div>
                        <S.MoreTitle $type="success">À faire :</S.MoreTitle>
                        <S.DoList>
                          {toDo.map((item, index) => (
                            <S.DoListItem key={index}>{item}</S.DoListItem>
                          ))}
                        </S.DoList>
                      </div>
                    )}

                    {!!notToDo && (
                      <div>
                        <S.MoreTitle $type="error">À ne pas faire :</S.MoreTitle>
                        <S.DoList>
                          {notToDo.map((item, index) => (
                            <S.DoListItem key={index}>{item}</S.DoListItem>
                          ))}
                        </S.DoList>
                      </div>
                    )}
                  </S.More>
                </CollapsibleRow>
              </div>
            </>
          )}
        </S.Details>
      </S.Header>

      <UploadArea {...uploadAreaProps} isFirst={files.length === 0} />

      {files.length > 0 && (
        <S.Files>
          <S.FilesTitle>Documents envoyés</S.FilesTitle>

          {files.map(({ id, ...fileItemProps }) => (
            <FileItem {...fileItemProps} key={id} />
          ))}
        </S.Files>
      )}

      {footerComponent}

      {(!!nextHref || !!previousHref) && (
        <S.Footer>
          {!!previousHref && (
            <ArrowButtonLabel direction="left" href={previousHref} onClick={onNext}>
              {previousLabel}
            </ArrowButtonLabel>
          )}

          {!!nextHref && (
            <S.FooterArrowButtonLabelNext direction="right" href={nextHref} onClick={onPrevious}>
              {nextLabel}
            </S.FooterArrowButtonLabelNext>
          )}
        </S.Footer>
      )}
    </S.Content>
  )
}
