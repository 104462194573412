import styled from 'styled-components'

const mediaQuery = ({ minWidth, maxWidth }) => {
  const min = minWidth && `(min-width: ${minWidth})`
  const max = maxWidth && `(max-width: ${maxWidth})`

  const stringQuery = [min, max].filter(Boolean).join(' and ')

  return `@media screen and ${stringQuery}`
}

export const Responsive = styled.span`
  display: none;

  ${mediaQuery} {
    display: initial;
  }
`
