import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { Graph, Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'

import { createContext, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface RoutesGraphContextInterface {
  graph: Graph
  indexNextRoute?: Route
  nextRoute?: Route
  previousRoute?: Route
  goToNextRoute: (search?: string) => void
  goToPreviousRoute: (search?: string) => void
  goToRoute: (path: string) => void
}

interface RoutesGraphContextProps {
  graph: Graph
}

const RoutesGraphContext = createContext<RoutesGraphContextInterface>({} as RoutesGraphContextInterface)

export const RoutesGraphContextProvider: React.FC<RoutesGraphContextProps> = ({ graph, children }) => {
  const { location, push, replace } = useHistory()

  const projectContext = useSentences()

  const currentRoute = graph.findRouteByPath(location.pathname) ?? graph.rootRoute

  useEffect(() => {
    if (currentRoute !== null && !graph.isNodeAccessible(currentRoute, projectContext)) {
      const previousRoute = findPreviousRoute(graph, currentRoute, projectContext)

      if (previousRoute) {
        replace(previousRoute.path)
      }
    }
  }, [currentRoute])

  const indexNextRoute = graph.rootRoute?.getNextRoute(projectContext)
  const nextRoute = currentRoute?.getNextRoute(projectContext)
  const previousRoute = currentRoute?.getPreviousRoute(projectContext)

  const value = {
    graph,
    indexNextRoute,
    nextRoute,
    previousRoute,
    async goToNextRoute(search: string = '') {
      const updatedProjectContext = await projectContext.waitForOngoingOperations()
      const nextRoute = currentRoute?.getNextRoute(updatedProjectContext)

      if (nextRoute) {
        push(`${nextRoute.path}${search}`)
      }
    },
    async goToPreviousRoute(search: string = '') {
      const updatedProjectContext = await projectContext.waitForOngoingOperations()
      const previousRoute = currentRoute?.getPreviousRoute(updatedProjectContext)

      if (previousRoute) {
        push(`${previousRoute.path}${search}`)
      }
    },
    goToRoute(path: string) {
      if (graph.findRouteByPath(path)) {
        push(path)
      }
    },
  }

  return <RoutesGraphContext.Provider value={value}>{children}</RoutesGraphContext.Provider>
}

const findPreviousRoute = (graph: Graph, route: Route, projectContext: ProjectContext) => {
  const previousRoute = route.getPreviousRoute(projectContext)

  if (previousRoute) {
    return previousRoute
  }

  const deepRoute = graph.getDeepRoute(projectContext)

  if (graph.endsBy(deepRoute)) {
    return deepRoute.getPreviousRoute(projectContext)
  }

  return deepRoute
}

export const useRoutesGraph = () => useContext(RoutesGraphContext)
