import { numeralBreakpoints } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { Component, memo } from 'react'
import ResponsiveComponent from 'react-responsive'

const Responsive = ({ children, min, max, ...componentProps }) => {
  const minWidth = numeralBreakpoints[min]
  const maxWidth = numeralBreakpoints[max] && numeralBreakpoints[max] - 1
  const props = {}
  if (minWidth) Object.assign(props, { minWidth })
  if (maxWidth) Object.assign(props, { maxWidth })
  return (
    <ResponsiveComponent {...props} {...componentProps}>
      {children}
    </ResponsiveComponent>
  )
}

Responsive.propTypes = {
  children: PropTypes.node.isRequired,
  min: PropTypes.oneOf(Object.keys(numeralBreakpoints)),
  max: PropTypes.oneOf(Object.keys(numeralBreakpoints)),
}

export default memo(Responsive)

// LEGACY
export class Mobile extends Component {
  render() {
    return <ResponsiveComponent maxWidth={767} {...this.props} />
  }
}
export class Tablet extends Component {
  render() {
    return <ResponsiveComponent minWidth={768} maxWidth={1023} {...this.props} />
  }
}
export class MobileTablet extends Component {
  render() {
    return <ResponsiveComponent maxWidth={1023} {...this.props} />
  }
}
export class Desktop extends Component {
  render() {
    return <ResponsiveComponent minWidth={768} {...this.props} />
  }
}

export class DesktopOnly extends Component {
  render() {
    return <ResponsiveComponent minWidth={1024} {...this.props} />
  }
}
