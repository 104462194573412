import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Times } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const ModalModify = ({ cta, isDialogOpen, isLoggedIn, onClose, onValidate, simulationDetailsProps }) => (
  <Dialog isOpen={isDialogOpen}>
    <S.DialogContainer>
      <S.Header>
        <S.Title>Modifier</S.Title>
        <Times onClick={onClose} />
      </S.Header>
      <S.Inputs {...simulationDetailsProps} isSaveDisabled={isLoggedIn} />
      <S.Footer>
        <S.Button onClick={onValidate}>Appliquer</S.Button>
        {cta && (
          <>
            <S.Divider>— ou —</S.Divider>
            <S.OptionCTA>{cta}</S.OptionCTA>
          </>
        )}
      </S.Footer>
    </S.DialogContainer>
  </Dialog>
)

ModalModify.propTypes = {
  cta: PropTypes.node,
  /** Dialog with <code>SimulationDetails</code> component. */
  isDialogOpen: PropTypes.bool,
  /** LoggedIn state of user. */
  isLoggedIn: PropTypes.bool,
  /** onClick function triggered when cross icon is clicked. */
  onClose: PropTypes.func,
  onValidate: PropTypes.func,
  /** Props of <code>[SimulationDetails](/#!/SimulationDetails)</code> component. */
  simulationDetailsProps: PropTypes.object,
}

export default ModalModify
