import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import type { EventPayload } from '@pretto/app/src/Sentences/v2/lib/tracking/types/event'
import { TRACK_EVENT } from '@pretto/app/src/Sentences/v2/repositories/tracking'
import client from '@pretto/app/src/api'

export const trackEventBackend = (eventName: EventName, projectId: string, payload: EventPayload) => {
  client.mutate({
    mutation: TRACK_EVENT,
    variables: {
      eventName,
      projectId,
      payload: JSON.stringify(payload),
    },
  })
}
