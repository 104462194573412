import Text from '@pretto/bricks/components/typography/Text'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'
import { getPathExtname } from '@pretto/bricks/core/utility/path/getPathExtname'

import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import React, { Fragment, memo } from 'react'

import * as S from './styles'

const markdownOptions = {
  createElement(type, { href }, ...children) {
    if (type === 'a') {
      return (
        <a href={href} rel="noreferrer noopener" target="_blank">
          {children}
        </a>
      )
    }

    return React.createElement(Fragment, null, ...children)
  },
  forceBlock: true,
}

const Image = ({ alt, caption, path, ...props }) => {
  const description = alt ?? caption ?? path
  const extname = getPathExtname(path)

  if (!caption) {
    return <Picture {...props} alt={description} extname={extname} path={path} />
  }

  return (
    <figure>
      <Picture {...props} alt={description} extname={extname} path={path} />

      {caption && (
        <S.Caption>
          <Text size="small" variant="neutral-1-60">
            <Markdown options={markdownOptions}>{caption}</Markdown>
          </Text>
        </S.Caption>
      )}
    </figure>
  )
}

Image.propTypes = {
  /** Alternative text of image. */
  alt: PropTypes.string,
  /** Text that goes below the image. */
  caption: PropTypes.string,
  /** Path of the image. */
  path: PropTypes.string.isRequired,
}

const Picture = ({ alt, extname, loading, options, path, sizes, srcSetBreakpoints, ...props }) => {
  const imageProps = {
    ...props,
    height: options.height,
    loading,
    src: getCloudinaryUrl(path, options),
    width: options.width,
  }

  if (extname !== '.svg') {
    return (
      <picture>
        <Source
          options={{ ...options, format: 'webp' }}
          path={path}
          sizes={sizes}
          srcSetBreakpoints={srcSetBreakpoints}
          type="image/webp"
        />

        <Source options={options} path={path} sizes={sizes} srcSetBreakpoints={srcSetBreakpoints} />

        <img alt={alt} {...imageProps} />
      </picture>
    )
  }

  return <img alt={alt} {...imageProps} />
}

Picture.defaultProps = {
  options: {},
  loading: 'lazy',
}

Picture.propTypes = {
  /** Alternative text of image. */
  alt: PropTypes.string.isRequired,
  /** Path extension. */
  extname: PropTypes.string.isRequired,
  /** img loading attribute. */
  loading: PropTypes.oneOf(['eager', 'lazy']),
  /** Image options (see getCloudinaryUrl API). */
  options: PropTypes.object,
  /** Path of the image. */
  path: PropTypes.string.isRequired,
  /** Image sizing conditions. */
  sizes: PropTypes.string,
  /** List of supported images width. */
  srcSetBreakpoints: PropTypes.arrayOf(PropTypes.number),
}

const Source = ({ options, path, sizes, srcSetBreakpoints, ...props }) => {
  if (srcSetBreakpoints) {
    const { height, width, ...nonDimensionalOptions } = options

    const srcSet = srcSetBreakpoints
      .map(breakpoint => `${getCloudinaryUrl(path, { ...nonDimensionalOptions, width: breakpoint })} ${breakpoint}w`)
      .join(', ')

    return <source {...props} sizes={sizes} srcSet={srcSet} />
  }

  if (options.height || options.width) {
    return (
      <source
        {...props}
        srcSet={`${getCloudinaryUrl(path, options)} 1x, ${getCloudinaryUrl(path, { ...options, dpr: 2 })} 2x`}
      />
    )
  }

  return <source {...props} srcSet={getCloudinaryUrl(path, options)} />
}

Source.propTypes = {
  /** Image options (see getCloudinaryUrl API). */
  options: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  /** Path of the image. */
  path: PropTypes.string.isRequired,
  /** Image sizing conditions. */
  sizes: PropTypes.string,
  /** List of supported images width. */
  srcSetBreakpoints: PropTypes.arrayOf(PropTypes.number),
}

export default memo(Image)
