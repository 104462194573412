import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import {
  ArrowDouble,
  BankNote,
  Burden,
  Calendar,
  Clock,
  Coins,
  Crane,
  EqualBold,
  HandCoin,
  House,
  LightningBolt,
  MinusBold,
  Percent,
  Plan,
  PlusBold,
  ShieldCheck,
  WorkingMan,
  ZeroPercent,
} from '@pretto/picto'

const renderProjectDetails = ({
  applicationFees,
  bridge,
  brokerageFees,
  buildPrice,
  contribution,
  guarantyFees,
  landPrice,
  loanAmount,
  notaryFees,
  propertyPrice,
  ptz,
  worksPrice,
}) =>
  [
    propertyPrice && {
      label: 'Prix du bien',
      picto: House,
      value: formatNumber(propertyPrice, { suffix: '€' }),
    },
    landPrice && {
      label: 'Prix du terrain',
      picto: Plan,
      value: formatNumber(landPrice, { suffix: '€' }),
    },
    buildPrice && {
      label: 'Prix de la construction',
      picto: Crane,
      value: formatNumber(buildPrice, { suffix: '€' }),
    },
    worksPrice && {
      label: 'Prix des travaux',
      picto: WorkingMan,
      value: formatNumber(worksPrice, { suffix: '€' }),
    },
    {
      label: 'Frais de notaire',
      picto: PlusBold,
      value: formatNumber(notaryFees, { decimals: 0, suffix: '€' }),
    },
    {
      label: 'Frais de garantie',
      picto: PlusBold,
      value: formatNumber(guarantyFees, { suffix: '€' }),
    },
    {
      label: 'Frais de courtage Pretto',
      picto: PlusBold,
      value: formatNumber(brokerageFees, { suffix: '€' }),
    },
    {
      label: 'Frais bancaires',
      picto: PlusBold,
      value: formatNumber(applicationFees, { suffix: '€' }),
    },
    {
      label: 'Apport',
      picto: MinusBold,
      value: formatNumber(contribution, { suffix: '€' }),
    },
    bridge && {
      label: 'Prêt relais',
      picto: MinusBold,
      value: formatNumber(bridge.value, { suffix: '€' }),
    },
    {
      label: 'Montant emprunté',
      picto: EqualBold,
      value: formatNumber(loanAmount, { suffix: '€' }),
    },
    ptz && {
      label: 'Dont prêt à taux zéro',
      picto: ZeroPercent,
      value: formatNumber(ptz.amount, { suffix: '€' }),
    },
  ].filter(Boolean)

const renderLoanDetails = ({ loanDuration, payment, rate, interests, indebtednessRate }) => [
  {
    label: 'Durée d’emprunt',
    picto: Clock,
    value: formatNumber(loanDuration / 12, { suffix: 'ans' }),
  },
  {
    label: 'Mensualité',
    picto: Calendar,
    value: formatNumber(payment, { suffix: '€ / mois' }),
  },
  {
    label: 'Taux d’intérêt',
    picto: Percent,
    value: formatNumber(rate, { decimals: 2, suffix: '%' }),
  },
  {
    label: 'Coût des intérêts',
    picto: Coins,
    value: formatNumber(interests, { suffix: '€' }),
  },
  {
    label: 'Endettement',
    picto: Burden,
    value: formatNumber(indebtednessRate * 100, { decimals: 0, suffix: '%' }),
  },
]

const renderSpecialDetails = ({ modularity, prepaymentCharge, transferability }) => [
  {
    label: 'Transférabilité du prêt',
    picto: ArrowDouble,
    value: renderBool(transferability),
    tooltip:
      'Transférer son prêt revient à garder son prêt aux conditions de départ pour l’achat d’un autre bien que celui initialement adossé au prêt. ',
  },
  {
    label: 'Modularité / report du prêt',
    picto: Calendar,
    value: renderBool(modularity),
    tooltip:
      'La modularité du crédit consiste à en aménager les mensualités à la hausse ou à la baisse, avec un taux qui reste fixe.',
  },
  {
    label: 'Exonération d’IRA',
    picto: LightningBolt,
    value: renderBool(prepaymentCharge),
    tooltip:
      'L’exonération d’IRA permet de s’acquitter des indemnités en cas de remboursement anticipé (« IRA ») du prêt.',
  },
  {
    label: 'Assurance emprunteur',
    picto: ShieldCheck,
    value: 'À définir',
    tooltip: 'En cas de force majeure, l’assurance emprunteur rembourse tout ou partie du crédit restant dû.',
  },
]

const renderBool = value => (value ? 'OUI' : 'NON')

const renderSavingsDetails = ({
  remainingInterests,
  interests,
  repurchaseFees,
  guarantyFees,
  applicationFees,
  brokerageFees,
  savings,
}) => [
  {
    label: 'Économies sur les intérêts',
    picto: HandCoin,
    value: formatNumber(remainingInterests - interests, { decimals: 0, suffix: '€' }),
  },
  {
    label: 'Pénalités de remboursement',
    picto: MinusBold,
    value: formatNumber(repurchaseFees, { suffix: '€' }),
  },
  {
    label: 'Frais de garantie',
    picto: MinusBold,
    value: formatNumber(guarantyFees, { suffix: '€' }),
  },
  {
    label: 'Frais bancaires',
    picto: MinusBold,
    value: formatNumber(applicationFees, { suffix: '€' }),
  },
  {
    label: 'Frais de courtage Pretto',
    picto: MinusBold,
    value: formatNumber(brokerageFees, { suffix: '€' }),
  },
  {
    color: savings >= 0 ? 'primary1' : 'error1',
    label: 'Total',
    picto: EqualBold,
    value: (
      <strong>
        {savings >= 0 ? '-' : '+'}
        {formatNumber(Math.abs(savings), { suffix: '€' })}
      </strong>
    ),
  },
]

const renderExampleDetails = ({
  propertyPrice,
  notaryFees,
  guarantyFees,
  brokerageFees,
  applicationFees,
  contribution,
  bridge,
  loanAmount,
  ptz,
}) =>
  [
    {
      label: 'Prix du bien',
      picto: House,
      value: formatNumber(propertyPrice, { suffix: '€' }),
    },
    {
      label: 'Frais de notaire',
      picto: PlusBold,
      value: formatNumber(notaryFees, { decimals: 0, suffix: '€' }),
    },
    {
      label: 'Frais de garantie',
      picto: PlusBold,
      value: formatNumber(guarantyFees, { suffix: '€' }),
    },
    {
      label: 'Frais de courtage Pretto',
      tooltip: 'Coût de l’accompagnement et du service de Pretto, selon le montant emprunté.',
      picto: PlusBold,
      value: formatNumber(brokerageFees, { suffix: '€' }),
    },
    {
      label: 'Frais bancaires',
      picto: PlusBold,
      value: formatNumber(applicationFees, { suffix: '€' }),
    },
    {
      label: 'Apport',
      picto: MinusBold,
      value: formatNumber(contribution, { suffix: '€' }),
    },
    bridge && {
      label: 'Prêt relais',
      picto: MinusBold,
      value: formatNumber(bridge.value, { suffix: '€' }),
    },
    {
      label: 'Montant emprunté',
      picto: EqualBold,
      value: formatNumber(loanAmount, { suffix: '€' }),
    },
    ptz && {
      label: 'Dont prêt à taux zéro',
      picto: ZeroPercent,
      value: formatNumber(ptz.amount, { suffix: '€' }),
    },
  ].filter(Boolean)

const renderLoanCapacityDetails = ({ loanDuration, payment, rate, interests }) => [
  {
    label: 'Durée d’emprunt',
    picto: Clock,
    value: formatNumber(loanDuration / 12, { suffix: 'ans' }),
  },
  {
    label: 'Mensualité',
    picto: Calendar,
    value: formatNumber(payment, { suffix: '€ / mois' }),
  },
  {
    label: 'Taux d’intérêt',
    picto: Percent,
    value: formatNumber(rate, { decimals: 2, suffix: '%' }),
  },
  {
    label: 'Coût des intérêts',
    picto: BankNote,
    value: formatNumber(interests, { suffix: '€' }),
  },
  {
    label: 'Assurance emprunteur',
    picto: ShieldCheck,
    value: 'À définir',
  },
]

export const details = ({ projectKind, ...props }) => {
  switch (projectKind) {
    case 'capacity':
      return [
        {
          rows: renderExampleDetails(props),
          title: 'Exemple de projet',
        },
        {
          rows: renderLoanCapacityDetails(props),
          title: 'Prêt',
        },
      ]
    case 'purchase':
      return [
        {
          rows: renderLoanDetails(props),
          title: 'Prêt',
        },
        {
          rows: renderProjectDetails(props),
          title: 'Projet',
        },
        {
          rows: renderSpecialDetails(props),
          title: 'Caractéristiques',
        },
      ].filter(Boolean)
    case 'renegotiation':
      return [
        {
          rows: renderSavingsDetails(props),
          title: 'Économies',
        },
        {
          rows: [
            {
              label: 'Mensualité',
              picto: Coins,
              value: formatNumber(props.payment, { suffix: '€ / mois' }),
            },
            ...renderSpecialDetails(props),
          ],
          title: 'Prêt',
        },
      ]
    default:
      return []
  }
}
