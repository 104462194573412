import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getQueryParameter } from '@pretto/app/src/Auth/lib/getQueryParameters'
import EmailSentPage from '@pretto/app/src/Auth/views/EmailSentPage'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import { memo } from 'react'

const TITLE = 'Un email est en chemin !'
const MISSING_EMAIL_TEXT = 'votre adresse'
const MESSAGES = email => [
  <>
    Nous avons envoyé un email à <strong>{email || MISSING_EMAIL_TEXT}</strong> pour vérifier votre identité. Il
    contient <strong>un lien</strong> pour vous connecter à votre espace sécurisé Pretto.
  </>,
]

const LoginConfirmedPage = () => {
  const { requestSignIn } = useAuth()
  const email = getQueryParameter('email')
  const props = {
    messages: MESSAGES(email),
    onSendAgain: () => {
      requestSignIn(email, {
        redirectUri: `${process.env.CLIENT_HOST}/login?redirect=${encodeURI(getQueryParameter('redirect', '/'))}`,
      })
    },
    title: TITLE,
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <EmailSentPage {...props} />
    </>
  )
}

LoginConfirmedPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default memo(LoginConfirmedPage)
