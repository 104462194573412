import ServerErrorPage from '@pretto/app/src/Errors/Containers/ServerErrorPage'

import * as Sentry from '@sentry/react'
import React from 'react'

interface Props {
  children?: React.ReactElement
}

interface State {
  error: Error | null
  errorInfo: React.ErrorInfo | null
  eventId: string | null
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      eventId: null,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtra('React.ErrorInfo', errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ error, errorInfo, eventId })
    })
  }

  render() {
    if (this.state.error) return <ServerErrorPage />
    return this.props.children
  }
}

export default ErrorBoundary
