import Card from '@pretto/bricks/components/cards/Card'
import SelectField from '@pretto/bricks/components/form/SelectField'
import { List } from '@pretto/bricks/components/form/SelectField/styles'
import Avatar from '@pretto/bricks/components/images/Avatar'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'


import styled from 'styled-components'

export const Bubble = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
`

export const CardContainer = styled(Card).attrs({ format: 'line', variant: 'neutral3' })`
  padding: ${g(4)};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const Left = styled.div`
  flex-basis: 50%;
  max-width: ${g(58)};
`

export const Progress = styled(Card).attrs({
  arrow: 'left',
  arrowPosition: { left: ng(-4) },
  arrowSize: 2,
})`
  padding: ${g(2)};
  margin-left: ${g(2)};
`

export const ProjectType = styled.div`
  text-align: left;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: right;
  }
`
export const Renaud = styled(Avatar).attrs({
  size: 'x-small',
  src: 'v1505739310/advisors/renaud/medium.png',
})`
  flex-shrink: 0;
`

export const Right = styled.div`
  margin: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`

export const Select = styled(SelectField)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${List} {
      left: ${g(-7)};
    }
  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading3' })`
  margin-bottom: ${g(2)};
`
