import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { isDateValid } from '@pretto/app/src/Sentences/v2/lib/date'
import type { Credit } from '@pretto/app/src/Sentences/v2/types/credit'
import { t } from '@pretto/app/src/lib/i18n'

interface CreditBoxSummaryProps {
  credit: Credit
  date?: string
}

export const CreditBoxSummary: React.FC<CreditBoxSummaryProps> = ({ credit, date }) => {
  return (
    <>
      {credit.amount && (
        <>
          {t('sentences.charge.credits.sentences.0')}
          <Prompt isFilled>{credit.amount}</Prompt>
          {t('sentences.charge.credits.sentences.1')}
        </>
      )}
      {credit.endDate && isDateValid(credit.endDate) && (
        <>
          {t('sentences.charge.credits.sentences.2')}
          <Prompt isFilled>{date}</Prompt>
        </>
      )}
    </>
  )
}
