import DateFieldComponent from '@pretto/bricks/components/form/DateField'

import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export const DateField = ({ onChange, ...props }) => {
  const [errorText, setErrorText] = useState('')
  const [debouncedErrorText] = useDebounce(errorText, 1000)

  const handleChange = value => {
    const errorText = !value || value.toString().length < 14 ? "La date que vous avez saisie n'est pas correcte" : ''

    onChange(value, errorText !== '')
    setErrorText(errorText)
  }

  return (
    <DateFieldComponent
      {...props}
      errorText={debouncedErrorText}
      format="form"
      onChange={handleChange}
      variant={debouncedErrorText !== '' ? 'error' : ''}
    />
  )
}

DateField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
