import input from '@pretto/bricks/assets/utility/extended-styles/Input'
import { boxShadow, focusedIndex, overlay } from '@pretto/bricks/assets/utility/extended-styles/generic'
import IconLegacySource from '@pretto/bricks/components/iconography/Icon'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

export const IconLegacy = styled(IconLegacySource)``

export const Label = styled.div`
  align-items: baseline;
  background-position: 100% center;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  cursor: pointer;
  display: inline;
  font-weight: 600;
  position: relative;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: inline-block;
  }

  ${({ isPlaceholder, theme }) =>
    isPlaceholder &&
    css`
      color: ${theme.legacy.colors.neutral1.fade(30)};
      font-weight: 400;
    `}
`
export const Item = styled.div`
  ${typo.bodyMedium16};
  align-items: center;
  box-sizing: content-box;
  color: ${({ theme }) => theme.legacy.colors.neutral1.default};
  display: flex;
  height: auto;
  justify-content: space-between;
  line-height: ${g(3)};
  min-height: ${g(3)};
  padding: ${g(1.5)} ${g(2)};

  ${({ isSelected, theme, variant }) =>
    isSelected &&
    css`
      color: ${theme.legacy.colors[variant]};
    `}
`
export const Icon = styled.span`
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(4)};
  }
`
export const LabelIcon = styled.span`
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  font-size: ${g(1)};

  ${IconLegacy} {
    display: inline;
  }
`
export const LabelText = styled.span`
  margin-right: ${g(2)};

  ${({ theme, variant }) =>
    variant &&
    css`
      color: ${theme.legacy.colors[variant]};
    `}
`
export const LabelFilter = styled.div``
export const Overlay = styled.div`
  ${overlay};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: ${g(2)};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
`
export const SelectField = styled.div`
  ${input};
  border-color: ${({ theme, variant }) => theme.legacy.colors[variant]};
  color: ${({ theme }) => theme.legacy.colors.primary1.default};

  &:focus {
    ${focusedIndex};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: inline-block;
    padding-top: 0;
    position: relative;
  }

  ${({ isValid }) =>
    isValid &&
    css`
      border-style: solid;
    `}

  ${({ format, isValid, theme, variant, state }) => {
    switch (format) {
      case 'form':
        return css`
          & {
            border-style: solid;
            display: block;
            height: ${g(6)};
            line-height: ${g(6)};
            padding-top: 0;

            ${Label} {
              display: flex;
              font-weight: initial;

              ${isValid &&
              css`
                color: ${theme.legacy.colors.neutral1.default};
              `}
            }

            ${LabelText} {
              flex-grow: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            ${List} {
              @media screen and (min-width: ${breakpoints.tablet}) {
                top: ${g(6)};
              }
              /* @media screen and (min-width: ${breakpoints.laptop}) {
                top: ${g(6)};
              } */
            }
          }
        `

      case 'block':
        return css`
          & {
            border-radius: ${g(0.5)};
            border: 1px solid ${theme.legacy.colors.neutral1.fade(20)};
            display: inline-block;
            font-size: 14px;
            height: ${g(6)};
            line-height: ${g(6)};
            padding: 0 ${g(2)} 0 ${g(1.5)};
            width: 100%;

            &:focus {
              outline: 0;
              border-color: ${theme.legacy.colors[variant]};
            }

            ${Item} {
              font-size: ${g(2)};

              span::first-letter {
                text-transform: uppercase;
              }
            }

            ${Label} {
              display: flex;
              font-weight: initial;
              height: ${g(6)};
            }

            ${LabelText} {
              color: inherit;
              flex-grow: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              ${isValid &&
              css`
                color: ${theme.legacy.colors[variant]};
              `}
            }

            ${LabelIcon} {
              color: ${theme.legacy.colors.neutral1.default};
            }

            ${List} {
              @media screen and (min-width: ${breakpoints.tablet}) {
                top: ${g(6)};
              }
            }
          }
        `

      case 'filter':
        return css`
          & {
            border-radius: ${g(0.5)};
            border: 1px solid ${theme.legacy.colors.neutral1.fade(20)};
            display: inline-block;
            font-size: 14px;
            height: ${g(5)};
            line-height: ${g(5)};
            padding: 0 ${g(2)} 0 0;
            width: 100%;

            ${Label} {
              color: ${theme.legacy.colors.neutral1.fade(40)};
              display: flex;
              font-weight: initial;
              height: ${ng(5) - 2}px;
              justify-content: space-between;
              line-height: ${ng(5) - 2}px;
            }

            ${LabelText} {
              color: ${theme.legacy.colors.neutral1.default};
              display: flex;
            }

            ${LabelFilter} {
              background: ${theme.legacy.colors.neutral1.light};
              border-radius: ${g(0.5)} 0 0 ${g(0.5)};
              margin-right: ${g(2)};
              padding: 0 ${g(2)};
              text-transform: uppercase;
            }

            ${LabelIcon} {
              color: ${theme.legacy.colors.neutral1.fade(40)};
            }

            ${Item} {
              font-size: ${g(2)};

              span::first-letter {
                text-transform: uppercase;
              }
            }

            ${List} {
              @media screen and (min-width: ${breakpoints.tablet}) {
                top: ${g(5)};
              }
            }
          }
        `

      case 'line':
        return css`
          & {
            display: block;
            border-radius: ${g(1)};
            border: 1px solid ${state === 'error' ? theme.colors.error1 : theme.legacy.colors.neutral1.fade(20)};
            height: ${g(6)};
            line-height: ${g(6)};
            padding: 0 ${g(2)} 0 ${g(1.5)};

            &:focus {
              outline: 0;
              border-color: ${theme.legacy.colors[variant]};
            }

            ${Label} {
              display: flex;
              ${({ isValid }) =>
                isValid &&
                css`
                  color: ${theme.legacy.colors.neutral1.default};
                  font-weight: 400;
                `}
            }

            ${LabelText} {
              flex-grow: 1;
            }

            ${LabelIcon} {
              ${IconLegacy} {
                color: ${theme.legacy.colors.neutral1.fade(60)};
              }
            }
          }
        `

      default:
        break
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          & {
            height: ${g(5)};
            line-height: ${g(5)};
            margin-left: ${g(1)};
            padding-left: 2px;

            &:focus {
              outline-width: 2px;
            }

            ${LabelIcon} {
              font-size: ${g(1.5)};
            }

            ${Icon} {
              font-size: ${g(2)};
            }

            ${List} {
              @media screen and (min-width: ${breakpoints.tablet}) {
                top: ${g(5.5)};
              }
              @media screen and (min-width: ${breakpoints.laptop}) {
                top: ${g(5)};
              }
            }
          }
        `

      default:
        break
    }
  }}
`
export const ListItem = styled.li`
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.tablet}) {
    cursor: pointer;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  }

  &:focus {
    background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
    outline: 0;
  }

  ${IconLegacy} {
    margin-left: ${g(2)};
  }
`
export const List = styled.div`
  ${boxShadow(2)};
  background: ${({ theme }) => theme.legacy.colors.white.default};
  border-radius: ${g(1)};
  list-style: none;
  overflow-y: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    left: ${g(-1)};
    margin-bottom: ${g(3)};
    max-height: ${ng(36) + 5}px;
    min-width: calc(100% + ${g(2)});
    position: absolute;
    top: ${g(4.5)};
    z-index: 999;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    top: ${g(4)};
  }
`

export const PrefixIcon = styled.div`
  align-self: center;
  font-size: ${g(2.5)};
  margin-right: ${g(1)};
`
