import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { TrustpilotRate as TrustpilotRateComponent } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import { CheckBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Action = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
  }
`

export const ButtonContainer = styled.div`
  justify-self: center;
`

export const Button = styled(PrimaryButtonFixComponent)`
  margin-bottom: ${g(3)};
  align-self: flex-start;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
  }
`

export const Component = styled.section`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.white};
  row-gap: ${g(4)};
  padding: ${g(4)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(6)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(10)} 0 ${g(5)};
    row-gap: ${g(6)};
  }
`

export const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
`

interface DividerProps {
  $isHidden: boolean
}

export const Divider = styled(DividerComponent)<DividerProps>`
  ${column([2, 4])};

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      height: 0;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const Header = styled.header`
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-top: ${g(-1)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 6])};
  }
`

export const Legals = styled.footer`
  ${({ theme }) => theme.typos.caption};
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-bottom: ${g(-2)};
  text-align: center;

  a {
    ${({ theme }) => theme.typos.captionUnderline};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    margin-bottom: ${g(-1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    margin-bottom: 0;
    margin-top: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`

export const Promises = styled.ul`
  justify-content: center;
  display: flex;
  gap: ${g(2)};
`

export const PromiseCheck = styled(CheckBold)`
  flex-shrink: 0;
`

export const PromiseItem = styled.li`
  ${({ theme }) => theme.typos.caption};
  display: flex;
  gap: ${g(1)};
  white-space: nowrap;
`

export const Title = styled.h2`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
  }
`

export const TrustpilotRateContainer = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 6])};
  }
`

export const TrustpilotRate = styled(TrustpilotRateComponent)`
  color: ${({ theme }) => theme.colors.neutral1};
  justify-content: center;
`
