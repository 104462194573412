import input from '@pretto/bricks/assets/utility/extended-styles/Input'
import { focusedIndex } from '@pretto/bricks/assets/utility/extended-styles/generic'
import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const Input = styled.input``
export const Suffix = styled.span`
  margin-left: ${g(2)};
  white-space: nowrap;
`

export const Icon = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: ${g(2)};
  bottom: 0;
  align-items: center;
`

export const NumberField = styled.span`
  ${({ format, theme, variant }) => {
    const base = css`
      ${input};
      border-bottom-color: ${theme.legacy.colors[variant]};
      display: inline-block;
      padding: 0;
      line-height: ${g(4)};
      letter-spacing: normal;
      @media screen and (min-width: ${breakpoints.tablet}) {
        display: inline-block;
        line-height: ${g(4)};
      }

      &:focus-within {
        ${focusedIndex};
      }

      ${Suffix} {
        color: ${theme.legacy.colors[variant]};
      }
    `

    const form = css`
      border-bottom-style: solid;
      padding-left: ${g(1)};
      width: 100%;
      height: ${g(6)};
      line-height: ${g(5.75)};
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding-left: ${g(1)};
        height: ${g(6)};
        line-height: ${g(5.75)};
      }

      input {
        background: transparent;
        text-align: left;
        color: ${theme.legacy.colors.neutral1.default};
        font-weight: normal;
      }
    `
    switch (format) {
      case 'sentence':
        return css`
          ${base}
          color: ${({ theme, variant }) => theme.legacy.colors[variant]};
          padding: 0 ${g(1)};
          position: relative;

          pre {
            visibility: hidden;
          }

          input {
            color: inherit;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }

          ${Suffix} {
            margin-left: ${g(1)};
          }
        `

      case 'textfield':
        return css`
          ${base};
          border-bottom-style: solid;
          width: 100px;

          input {
            width: 100%;
            text-align: right;
          }
        `
      case 'form':
        return css`
          ${base};
          ${form};
          display: flex;

          @media screen and (min-width: ${breakpoints.tablet}) {
            display: flex;
          }

          input {
            flex-grow: 1;
          }

          ${Suffix} {
            flex-shrink: 0;
          }
        `

      case 'editable-input':
        return css`
          ${base};
          ${form};

          input {
            font-weight: 600;
            padding-right: ${g(1)};
            width: 100% !important; /* overrides cleave js width */
          }
        `
      case 'form-field':
        return css`
          ${base};
          align-items: baseline;
          border-bottom-style: solid;
          color: ${theme.legacy.colors[variant]};
          display: flex;
          height: ${g(6)};
          line-height: ${g(6)};
          @media screen and (min-width: ${breakpoints.tablet}) {
            display: flex;
            height: ${g(6)};
            line-height: ${g(6)};
          }

          input {
            background: transparent;
            color: inherit;
            font: inherit;
            font-weight: 500;
            text-align: left;
            flex-grow: 1;
          }

          ${Suffix} {
            flex-shrink: 0;
          }
        `

      case 'large':
        return css`
          ${base};
          display: flex;
          position: relative;
          align-items: center;
          transition: border-color 150ms ease-in-out;
          border: 1px solid;
          border-radius: ${g(1)};
          padding: 0 ${g(2)};
          height: ${g(6)};
          border-color: ${theme.legacy.colors[variant]};
          @media screen and (min-width: ${breakpoints.tablet}) {
            display: flex;
            padding: 0 ${g(2)};
            height: ${g(6)};
          }

          input {
            flex-grow: 1;
            width: ${g(10)};
            text-align: left;
            color: ${theme.legacy.colors.neutral1.default};
          }

          &:focus-within {
            outline: 0;
            border: 1px solid ${theme.legacy.colors.neutral1.default};
          }

          ${Icon} {
            position: relative;
            right: 0;
            margin-left: ${g(2)};
          }
        `

      case 'line':
        return css`
          display: block;
          border: 1px solid;
          color: ${theme.legacy.colors.neutral1.fade(20)};
          border-radius: ${g(1)};
          padding: ${g(1)} ${g(2)};
          font-size: ${g(2)};

          ${({ size }) => {
            switch (size) {
              case 'small':
                return css`
                  font-size: 14px;
                `

              default:
                return null
            }
          }}
        `

      case 'button':
        return css`
          display: block;
          background: ${theme.legacy.colors.neutral1.light};
          border-radius: ${g(1)};
          text-align: center;
          border: 2px solid transparent;
          font-family: ${({ theme }) => theme.legacy.fonts.circular};

          input {
            font-family: inherit;
            font-size: ${g(2)};
            border: 0;
            background: transparent;
            outline: 0;
            padding: ${g(1)} 0;
            text-align: center;

            @media screen and (min-width: ${breakpoints.laptop}) {
              font-size: ${ng(2) + 2}px;
              height: ${g(6)};
              line-height: ${g(6)};
            }
          }

          &:focus-within {
            outline: 0;
            border-color: ${theme.legacy.colors.primary1.default};
          }

          ${Suffix} {
            margin-left: ${g(1)};

            @media screen and (min-width: ${breakpoints.laptop}) {
              font-size: ${ng(2) + 2}px;
            }
          }
        `

      case 'display':
        return css`
          font-size: ${g(5)};
          border: 1px dashed transparent;
          padding: ${g(0.5)};
          border-radius: ${g(0.5)};

          input {
            background: transparent;
            border: 0;
            border-bottom: 2px dashed ${theme.legacy.colors[variant]};
            color: ${theme.legacy.colors.neutral1.default};
            font-family: ${({ theme }) => theme.legacy.fonts.circular};
            font-size: inherit;
            outline: 0;
            padding: 0 ${g(0.5)};
            caret-color: ${theme.legacy.colors[variant]};
          }

          &:focus-within {
            border-color: ${helpers.fade(variant, 30)};
          }

          ${Suffix} {
            font-family: ${({ theme }) => theme.legacy.fonts.circular};
            color: ${theme.legacy.colors.neutral1.default};
            margin-left: ${g(1)};
          }

          @media screen and (min-width: ${breakpoints.laptop}) {
            font-size: ${g(7)};
          }
        `

      default:
        return base
    }
  }}
`

export const Error = styled.div``
