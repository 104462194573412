// extracted by mini-css-extract-plugin
export var actionCard = "eUxCGYEwJiiQFEb1Ib1O";
export var actionCardButton = "NQQcqa2quU9B4wwZdoDB";
export var actionCardButtonMiddle = "WJXlUzKJDHMkwQh36OIF";
export var actionCardCapacity = "e4JTyVD_DEU8bpSdkmlD";
export var actionCardData = "dNdvwaBVhtEDaGEbEYRY";
export var actionCardDataRow = "E3b8gNGjDa9dhkdE94mK";
export var actionCardDescription = "MkKws8lpDb5P0WqF7Q8m";
export var actionCardDivider = "bVRPCnE2eEL9jAE5rubH";
export var actionCardOptionNumber = "BS0BZ1frcB_6uFpJE6f6";
export var actionCardPushedContent = "scmoGFN5D4c1AjWhFemw";
export var actionCardPushedContentAmount = "oQ5DtebrAQoCDzZJF6b1";
export var actionCardPushedContentEmoji = "cqNgQrEYVZxfdePafyq2";
export var actionCardPushedContentIcon = "oCecXXPy5scZocb43rKh";
export var actionCardPushedContentIconContent = "ntpwcZ1IYo7ZaIFMBgxQ";
export var actionCardPushedContentIconContentText = "Iyjs8zQ0DkTy9XU7By1b";
export var actionCardTitle = "TbQOuQOyffhbhLtqsSAt";