import { decodeValuesForStep } from '@pretto/app-core/application/lib/decodeValuesForStep'
import { reduceField } from '@pretto/app-core/application/lib/reduceField'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { resetValuesForDisabledFields } from '@pretto/app-core/lib/resetValuesForDisabledFields'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

export const getProgressForPage = (page, data, ...args) => {
  const sections = funcToItem(page.sections, data, ...args)

  const decodedValues = decodeValuesForStep(page.decoder, sections, data)
  const values = resetValuesForDisabledFields(decodedValues, sections, data.project, ...args)

  return reduceField(
    sections,
    ({ current, target }, value) => ({
      current: current + (isFieldValid(value) ? 1 : 0),
      target: target + 1,
    }),
    { current: 0, target: 0 },
    values,
    data,
    ...args
  )
}
