import { getCurrentStepForId } from '@pretto/app-core/application/lib/getCurrentStepForId'
import { getProgressForStep } from '@pretto/app-core/application/lib/getProgressForStep'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import mapValues from 'lodash/mapValues'

const getPropsMapper =
  (forms, props, ...args) =>
  value => {
    const step = getCurrentStepForId(forms, props.id)

    return funcToItem(value, props, step, ...args)
  }

export const getCards = (schema, forms, switches, handleToggle, ...args) =>
  schema.reduce((previous, currentCard) => {
    const cardProps = funcToItem(currentCard, ...args)

    if (!cardProps) {
      return previous
    }

    const mappedProps = mapValues(cardProps, getPropsMapper(forms, {}, ...args))

    switch (mappedProps.cardType) {
      case 'bannerFolder':
      case 'block':
      case 'notice':
        return [...previous, mappedProps]

      default:
        return [
          ...previous,
          {
            ...mappedProps,
            ...mapValues(
              { progress: (props, ...args) => getProgressForStep(...args) },
              getPropsMapper(forms, mappedProps, ...args)
            ),
            isSwitched: !!switches[mappedProps.id],
            onToggle: handleToggle.bind(null, mappedProps.id),
          },
        ]
    }
  }, [])
