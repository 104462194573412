import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { Pen } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const CARDS = {
  calculateRate: {
    title: 'Votre taux',
  },
  capacity: {
    buttonText: 'Modifier votre simulation',
    title: 'Votre budget',
  },
  propertySearch: {
    buttonText: 'Gérer votre alerte immo',
    title: 'Votre recherche de bien',
  },
  propertySearchDefault: {
    buttonText: 'Créez votre alerte immo',
    title: 'Votre recherche de bien',
  },
  renegotiation: {
    buttonText: 'Modifier votre simulation',
    title: 'Votre simulation',
  },
  simulation: {
    buttonText: 'Modifier votre simulation',
    title: 'Votre taux',
  },
}

const PreLeadProjectCardTemplate = ({ children, error, href, isEditable, onEdit, type, ...props }) => {
  const { title, buttonText } = CARDS[type]

  return (
    <S.Container {...props}>
      <S.TitleContainer>
        <BaseTypo typo="heading3">{title}</BaseTypo>
        {isEditable && (
          <>
            <Responsive min="mobileL">
              <S.ButtonEdit onClick={onEdit} href={href}>
                {buttonText}
              </S.ButtonEdit>
            </Responsive>
            <Responsive max="mobileL">
              <S.Link onClick={onEdit} href={href}>
                <Pen />
              </S.Link>
            </Responsive>
          </>
        )}
      </S.TitleContainer>
      {children}
    </S.Container>
  )
}

PreLeadProjectCardTemplate.defaultProps = {
  isEditable: true,
}

PreLeadProjectCardTemplate.propTypes = {
  /** Main content of card. */
  children: PropTypes.node.isRequired,
  /** Whether there is an error or not. */
  error: PropTypes.bool,
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  /** Edit function triggered when edit icon is clicked. */
  onEdit: PropTypes.func,
  /** Type of card that defines its content. */
  type: PropTypes.oneOf(Object.keys(CARDS)).isRequired,
}

export default PreLeadProjectCardTemplate
