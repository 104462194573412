import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const SepaInformationSection = ({ fields, isEditable, title }) => (
  <S.InformationSection isEditable={isEditable}>
    <SubHeading size="small">{title}</SubHeading>
    <S.Rows>
      {fields.map(([label, value], index) => (
        <Fragment key={index}>
          <div>
            <Text size="x-small" variant="neutral-1-60">
              {label}
            </Text>
          </div>

          <div>
            <Text size="small">{value}</Text>
          </div>
        </Fragment>
      ))}
    </S.Rows>
  </S.InformationSection>
)
SepaInformationSection.propTypes = {
  /** Fields to be displayed where each element is an array as `[label, value]`. */
  fields: PropTypes.arrayOf(PropTypes.array).isRequired,
  /** Whether if section is editable or not. If true, style of card is different. */
  isEditable: PropTypes.bool,
  /** Title of Section. */
  title: PropTypes.string.isRequired,
}

export default SepaInformationSection
