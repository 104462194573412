import { breakpoints, g } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { css } from 'styled-components'

import * as config from './config/grid'
import { calculateColumnSpan } from './lib/calculateColumnSpan'
import { templateColumns } from './lib/templateColumns'
import * as S from './styles'

export const column = (position, isEndAbsolute = false) => {
  if (Array.isArray(position)) {
    const [start, end] = position

    return css`
      grid-column: ${start} / ${isEndAbsolute || end < 0 ? end : `span ${end}`};
    `
  }

  return css`
    grid-column-end: span ${position};
    grid-column-start: initial;
  `
}

export const grid = (numberOfColumns, { isLeftAligned, isRightAligned } = {}) => css`
  display: grid;
  column-gap: ${config.COLUMN_GAP.default}px;
  min-width: 0;

  ${templateColumns(
    numberOfColumns ?? config.NUMBER_OF_COLUMNS.default,
    calculateColumnSpan(
      numberOfColumns ?? config.NUMBER_OF_COLUMNS.default,
      config.COLUMN_GAP.default,
      isLeftAligned,
      isRightAligned
    ),
    isLeftAligned,
    isRightAligned
  )}

  @media screen and (min-width: ${breakpoints.tablet}) {
    column-gap: ${config.COLUMN_GAP.tablet}px;

    ${templateColumns(
      numberOfColumns ?? config.NUMBER_OF_COLUMNS.tablet,
      calculateColumnSpan(
        numberOfColumns ?? config.NUMBER_OF_COLUMNS.tablet,
        config.COLUMN_GAP.tablet,
        isLeftAligned,
        isRightAligned
      ),
      isLeftAligned,
      isRightAligned
    )}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${templateColumns(
      numberOfColumns ?? config.NUMBER_OF_COLUMNS.laptop,
      `minmax(0, ${g(10)})`,
      isLeftAligned,
      isRightAligned
    )}
  }
`

export const Column = ({ isEndAbsolute, position, ...props }) => (
  <S.Column {...props} $isEndAbsolute={isEndAbsolute} $position={position} />
)

Column.propTypes = {
  isEndAbsolute: PropTypes.bool,
  position: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
}

export const Grid = forwardRef(({ isLeftAligned, isRightAligned, numberOfColumns, ...props }, ref) => (
  <S.Grid
    {...props}
    ref={ref}
    $isLeftAligned={isLeftAligned}
    $isRightAligned={isRightAligned}
    $numberOfColumns={numberOfColumns}
  />
))

Grid.propTypes = {
  isLeftAligned: PropTypes.bool,
  isRightAligned: PropTypes.bool,
  numberOfColumns: PropTypes.number,
}

Grid.displayName = 'Grid'
