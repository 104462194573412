import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import { Route as DefaultRoute, Redirect, useLocation } from 'react-router-dom'

import { useUser } from '../../../User/Containers/UserProvider'
import { useAuth } from '../AuthProvider'

// Add Sentry instrumentation for routing
const SentryRoute = Sentry.withSentryRouting(DefaultRoute)

type Typology = 'prospect' | 'preLead' | 'client'

interface RouteProps {
  component: React.ComponentType & { restrictionRedirect?: () => string }
  disableConsent?: boolean
  exact?: boolean
  logged?: boolean
  onboardedOnly?: boolean
  scope?: Typology[]
  shouldScrollTop?: boolean
  path: string | string[]
}

export const Route: React.FC<RouteProps> = ({ component, onboardedOnly, scope, shouldScrollTop = true, ...props }) => {
  const { isLoggedIn, isImpersonated } = useAuth()
  const userContext = useUser()
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (!shouldScrollTop) {
      return
    }

    window.scrollTo(0, 0)
  }, [pathname])

  const isRouteAccessible = !scope || scope.includes(userContext.typology)
  const isRouteRestricted = !!component.restrictionRedirect

  if (!isRouteAccessible) {
    const redirect = `${pathname}${search}`
    const to = isLoggedIn ? '/' : `/login?redirect=${encodeURIComponent(redirect)}`
    return <Redirect to={to} />
  }

  if (!isImpersonated && userContext.typology === 'client' && !userContext.isOnboardingDone && onboardedOnly) {
    return <Redirect to="/onboarding" />
  }

  if (isRouteRestricted) {
    const restrictionRedirect = funcToItem(component.restrictionRedirect, userContext)
    if (restrictionRedirect) return <Redirect to={restrictionRedirect} />
  }

  return <SentryRoute component={component} {...props} />
}
