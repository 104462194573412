import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Spinner as SpinnerComponent } from '@pretto/zen/atoms/loaders/Spinner/Spinner'

import { ChevronRightBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

import { ButtonProps } from './Button'

export const TRANSITION_DELAY = 175
export const TRANSITION_DURATION_IN = 200
export const TRANSITION_DURATION_OUT = 150

type ConfigKey = 'primary' | 'secondary'

interface IConfig {
  bgColor: keyof IPalette
  color: keyof IPalette
  bgColorHover: keyof IPalette
}

export const COLOR: Record<ConfigKey, IConfig> = {
  primary: {
    bgColor: 'accent1',
    bgColorHover: 'accent1Hover',
    color: 'white',
  },
  secondary: {
    bgColor: 'neutral1',
    bgColorHover: 'neutral1Hover',
    color: 'white',
  },
}

interface ColorProps {
  theme: { colors: IPalette; designSystem: 'reveal' | 'zen' }
  $isSecondary: ButtonProps['isSecondary']
}

interface ComponentWithLoading {
  $isLoading: ButtonProps['isLoading']
}

interface ButtonStyledProps {
  href?: ButtonProps['href']
  $isSecondary: ButtonProps['isSecondary']
}

interface SpinnerIsSecondary {
  $isSecondary: ButtonProps['isSecondary']
}

interface SpinnerIsLoading extends SpinnerIsSecondary {
  $isLoading: ButtonProps['isLoading']
}

const getColorProps = ({ $isSecondary, theme }: ColorProps) => {
  const variant = $isSecondary ? 'secondary' : theme.designSystem === 'zen' ? 'primary' : 'secondary'
  const { bgColor, color, bgColorHover } = COLOR[variant]

  return css`
    color: ${theme.colors[color]};
    background-color: ${theme.colors[bgColor]};
    &:hover {
      background-color: ${theme.colors[bgColorHover]};
    }
  `
}

export const Arrow = styled(ChevronRightBold)<ComponentWithLoading>`
  align-self: center;
  flex-shrink: 0;
  margin-left: ${g(2)};
  transform: translateX(0);
  transition: transform 0.5s ease;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;
    `}
`

export const Button = styled.button.attrs<ButtonStyledProps>(({ href }) => ({
  as: href ? Link : 'button',
}))<ButtonStyledProps>`
  ${typo.button18}
  ${getColorProps}

  cursor: pointer;
  border-radius: ${g(1)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 ${g(3)};
  height: ${g(8)};
  transition:
    background-color ${TRANSITION_DURATION_OUT}ms ease-out,
    ${TRANSITION_DURATION_OUT}ms ease-out;

  &:hover ${Arrow} {
    transform: translateX(${g(1, -2)});
  }
`

export const Label = styled.span<ComponentWithLoading>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity ${TRANSITION_DURATION_IN}ms ease-out ${TRANSITION_DELAY}ms;
  white-space: nowrap;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;
    `}
`

export const Spinner = styled(SpinnerComponent).attrs<SpinnerIsSecondary>(({ $isSecondary }) => ({
  backgroundColor: $isSecondary ? 'neutral2' : 'accent2',
  color: 'white',
}))<SpinnerIsLoading>`
  bottom: 0;
  position: absolute;
  left: 0;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 1;
      transition: opacity ${TRANSITION_DURATION_IN}ms ease-out 0s;
    `}
`
