import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled, { css } from 'styled-components'

export const InputRadio = styled.div`
  position: relative;
  display: flex;
`
export const Label = styled(BaseTypo).attrs({ forwardedAs: 'label', typo: 'bodyBook' })`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: ${g(1)};
`
export const InputContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  height: ${g(3)};
  width: ${g(3)};
  border: 2px solid ${({ theme, $isChecked }) => ($isChecked ? theme.colors.primary2 : theme.colors.neutral3)};
  border-radius: 100%;
  transition: border-color 200ms ease-in-out;
  background-color: ${({ theme }) => theme.colors.white};

  .inputRadioAncestor:hover &,
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary2};
  }

  &:focus-within {
    outline: transparent;

    &::before {
      content: '';
      position: absolute;
      border-radius: inherit;
      top: -3px;
      left: -3px;
      bottom: -3px;
      right: -3px;
      border: 1px dashed ${({ theme }) => theme.colors.accent1};
    }
  }
`
export const Input = styled.input`
  cursor: pointer;
  position: absolute;
  height: ${g(3)};
  width: ${g(3)};
  margin: 0;
  opacity: 0;
`
export const InputOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${g(3)};
  width: ${g(3)};
  border: 7px solid ${({ theme }) => theme.colors.primary2};
  border-radius: 100%;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: -1;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      opacity: 1;
      z-index: 1;
    `}
`
