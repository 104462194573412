import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'
import type { FieldState } from '@pretto/zen/reveal/types/Field'

import styled, { css } from 'styled-components'

interface StateProps {
  $state: FieldState
}

export const ClearButton = styled.button`
  cursor: pointer;
  flex-shrink: 0;
`

export const Footer = styled.div`
  padding-top: ${g(1)};
`

export const Input = styled.input`
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  flex: 1;
  font: inherit;
  margin: 0;
  padding: 0 0 0 ${g(2)};
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral2};
    outline: 0;
  }

  &::placeholder {
    color: inherit;
  }
`

export const Message = styled.div<StateProps>`
  ${({ theme }) => theme.typos.caption};
  ${transition({ propertyName: 'color' })};

  a,
  button {
    color: inherit;
    cursor: pointer;
    font: inherit;
    text-decoration: underline;
  }

  ${({ $state, theme }) => {
    switch ($state) {
      case 'error':
        return css`
          color: ${theme.colors.error1};
        `

      case 'warning':
        return css`
          color: ${theme.colors.warning1};
        `

      default:
    }
  }}
`

export const Picto = styled.svg<StateProps>`
  display: block;
  flex-shrink: 0;

  ${({ $state, theme }) => {
    switch ($state) {
      case 'error':
        return css`
          color: ${theme.colors.error1};
        `

      case 'warning':
        return css`
          color: ${theme.colors.warning1};
        `

      case 'default':
      default:
        return css`
          color: ${theme.colors.neutral2};
        `
    }
  }}
`

export const Suffix = styled.span`
  flex-shrink: 0;
`

interface TextFieldProps extends StateProps {
  $isEmptyValue: boolean
}

export const TextField = styled.div<TextFieldProps>`
  ${({ theme }) => theme.typos.body4};
  ${transition({ propertyName: 'background-color' }, { propertyName: 'border-color' }, { propertyName: 'color' })};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-color: ${({ theme }) => theme.colors.neutral5};
  border-style: solid;
  border-width: 1px;
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral2};
  display: flex;
  gap: ${g(1)};
  height: ${g(6)};
  padding-right: ${g(2)};

  ${({ $isEmptyValue }) =>
    !$isEmptyValue &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.neutral3};
      color: ${({ theme }) => theme.colors.neutral1};
    `}

  ${({ $state, theme }) => {
    switch ($state) {
      case 'error':
        return css`
          border-color: ${theme.colors.error1};
        `

      case 'warning':
        return css`
          border-color: ${theme.colors.warning1};
        `

      default:
        return css`
          &:hover,
          &:focus-within {
            border-color: ${({ theme }) => theme.colors.neutral2};
          }
        `
    }
  }}
`
