/* global analytics */
import temporal from '@pretto/bricks/core/utility/temporal'

import Cookies from 'js-cookie'
import qs from 'qs'

const FLAGS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'] as const

export const copyToClipboard = (str: string) => window.navigator.clipboard.writeText(str)

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const RATE_DIVIDER = 1200
export const calcPayment = (crd: number, rate: number, endDate: string, dateFormat = 'YYYY-MM-DD') => {
  const r = rate / RATE_DIVIDER
  const d = temporal(endDate, dateFormat).diff(temporal(), 'month') + 1
  const p = rate === 0 ? crd / d : crd * (r / (1 - (1 + r) ** -d))
  return Math.round(p)
}

export const getTrackingId = (name: string) => {
  let id = qs.parse(window.location.search, { ignoreQueryPrefix: true })[name] as string
  if (!id) id = Cookies.get(name)
  else setCookie(name, id)
  return id
}

export const setCookie = (name: string, value: string, opts = {}) => {
  if (process.env.NODE_ENV === 'development') {
    Cookies.set(name, value)
    return
  }

  Cookies.set(name, value, { sameSite: 'none', secure: true, ...opts })
}

export const persistUtmFlagsLastClicked = () => {
  const urlParams = qs.parse(window.location.search.substring(1))

  FLAGS.forEach(flag => {
    const value = urlParams[flag] as string

    const flagLastClicked = `${flag}_last_clicked`

    const existing = Cookies.get(flagLastClicked)
    if (value && !existing) {
      // cookie should only last for the session
      setCookie(flagLastClicked, value)
    }
  })
}

export const getUtmFlagsLastClicked = <T = { [K in (typeof FLAGS)[number]]?: string }>(): T =>
  FLAGS.reduce<T>((object, flag) => {
    const flagLastClicked = `${flag}_last_clicked`
    const value = Cookies.get(flagLastClicked)

    if (!value) {
      return object
    }

    return { ...object, [flag]: value }
  }, {} as T)

export const getVisitorId = async () => {
  const visitorId = (await analytics?.user?.().anonymousId()) ?? null
  return visitorId
}

export const getRemainingDuration = (start: string, duration: number) => {
  const now = temporal()
  const day = now.date()
  const month = now.month() + 1
  const startDate = temporal(`${start}-${month}-${day}`)
  const endDate = startDate.add(duration, 'y')
  const remainingDuration = endDate.diff(now, 'M') + 1

  return {
    endDate: endDate.format('YYYY-MM-DD'),
    remainingDuration,
  }
}

export const isValueIndexAfter = <T>(array: Array<T>, value1: T, value2: T) =>
  array.indexOf(value1) > array.indexOf(value2)
