import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { EventHandler } from '@pretto/app/src/types/Events'
import { Times } from '@pretto/picto'

import type { MouseEventHandler } from 'react'

import * as S from './styles'

interface ModalModifyProps {
  cta: React.ReactNode
  isDialogOpen: boolean
  isLoggedIn: boolean
  onClose: MouseEventHandler<SVGElement>
  onValidate: EventHandler
  simulationDetailsProps: Record<string, unknown>
}

export const ModalModify: React.FC<ModalModifyProps> = ({
  cta,
  isDialogOpen,
  isLoggedIn,
  onClose,
  onValidate,
  simulationDetailsProps,
}) => (
  <Dialog isOpen={isDialogOpen}>
    <S.DialogContainer>
      <S.Header>
        <S.Title>Modifier</S.Title>
        <Times onClick={onClose} />
      </S.Header>
      <S.Inputs {...simulationDetailsProps} isSaveDisabled={isLoggedIn} />
      <S.Footer>
        <S.Button onClick={onValidate}>Appliquer</S.Button>
        {cta && (
          <>
            <S.Divider>— ou —</S.Divider>
            <S.OptionCTA>{cta}</S.OptionCTA>
          </>
        )}
      </S.Footer>
    </S.DialogContainer>
  </Dialog>
)
