/* eslint-disable max-lines */
import * as filters from '@pretto/app-core/lib/filters'

import { UserCircle } from '@pretto/picto'

import omit from 'lodash/omit'
import { v4 as uuidv4 } from 'uuid'

const applyHousingFilter = (index, data, values) => {
  if (index === 0 || !data.profile.live_together) {
    return []
  }

  return [
    ['profile.mortgagors[0].address', 'profile.mortgagors[1].address'],
    ['profile.mortgagors[0].housing', 'profile.mortgagors[1].housing'],
    ['profile.mortgagors[0].rent', 'profile.mortgagors[1].rent'],
  ].reduce(
    (previous, [destinationPath, originPath]) => {
      if (!(originPath in values)) {
        return previous
      }

      return [...previous, filters.applyFilter(destinationPath, () => values[originPath], false)]
    },
    [
      filters.applyFilter('profile.mortgagors[1].address', () => null, false),
      filters.applyFilter('profile.mortgagors[1].housing', () => null, false),
      filters.applyFilter('profile.mortgagors[1].rent', () => null, false),
    ]
  )
}

const getContractWithData = contract => {
  if (contract === 'fixed_term') {
    return contract
  }

  if (otherContracts.some(({ value }) => value === contract)) {
    return 'other'
  }

  if (permanentContracts.some(({ value }) => value === contract)) {
    return 'permanent'
  }

  if (publicContracts.some(({ value }) => value === contract)) {
    return 'public'
  }

  return null
}

const formatUnique =
  (condition, then, filterFunction = value => value) =>
  value =>
    condition ? filterFunction(then) : filterFunction(value)

// Options
export const otherContracts = [
  { label: 'Travailleur indépendant ou autoentrepreneur', value: 'self_employed' },
  { label: 'Retraité', value: 'retired' },
  { label: "Chef d'entreprise", value: 'business_owner' },
  { label: 'Professions libérales juridiques', value: 'lawyer' },
  { label: 'Professions libérales médicales et paramédicales', value: 'doctor' },
  { label: 'Professions libérales hors médical, paramédical et juridique', value: 'liberal' },
  { label: 'Sans profession', value: 'jobless' },
  { label: 'Commercant', value: 'trader' },
  { label: 'Agriculteur', value: 'farmer' },
  { label: 'Congé parental', value: 'parental_leave' },
  { label: 'Artisan', value: 'craftsman' },
  { label: 'Étudiant', value: 'student' },
  { label: 'Gérant salarié', value: 'salaried_director' },
  { label: 'Intérimaire', value: 'interim' },
  { label: 'Intermittent du spectacle', value: 'casual_show_business_worker' },
  { label: 'Portage salarial', value: 'portage_salarial' },
  { label: "Recherche d'emploi", value: 'jobseeker' },
  { label: 'Assistant maternel', value: 'childminder' },
]

const permanentContracts = [
  { label: "CDI cadre, hors période d'essai", value: 'permanent_executive' },
  { label: "CDI cadre, en période d'essai", value: 'permanent_executive_trial' },
  { label: "CDI non-cadre, hors période d'essai", value: 'permanent_non_executive' },
  { label: "CDI non-cadre, en période d'essai", value: 'permanent_non_executive_trial' },
]

const publicContracts = [
  { label: 'Titulaire', value: 'tenure' },
  { label: 'Contractuel en CDI', value: 'contractuel_cdi' },
  { label: 'Contractuel en CDD', value: 'contractuel_cdd' },
  { label: 'Stagiaire', value: 'internship' },
  { label: 'Militaire', value: 'military' },
]

const incomesOptions = [
  { label: 'Revenu locatif', value: 'rent' },
  { label: 'Pension alimentaire', value: 'child_support' },
  { label: 'Revenus autoentreprise', value: 'professional_benefits' },
  { label: 'Allocations', value: 'family_allowances' },
  { label: "Indemnités d'expatriation", value: 'expatriation_bonus' },
]

export const getPageSchemaForMortgagor = index => {
  // Fields
  const mortgagorBirthdate = {
    fieldProps: {
      placeholder: 'JJ / MM / AAAA',
    },
    label: 'Date de naissance',
    name: `profile.mortgagors[${index}].birthdate`,
    type: 'date',
  }

  const mortgagorNationality = {
    fieldProps: {
      placeholder: 'France',
      searchfieldPlaceholder: 'Saisissez un nom de pays',
    },
    label: 'Nationalité',
    name: `profile.mortgagors[${index}].nationality`,
    type: 'country',
  }

  const mortgagorBirthCountry = {
    fieldProps: {
      placeholder: 'France',
      searchfieldPlaceholder: 'Saisissez un pays',
    },
    label: 'Pays de naissance',
    name: 'birthcountry',
    type: 'country',
  }

  const mortgagorBirthCity = {
    condition: values => !!values.birthcountry,
    fieldProps: values => ({
      placeholder: values.birthcountry !== 'fr' ? 'Londres' : 'Paris 75016',
      searchfieldPlaceholder: 'Saisissez une ville',
    }),
    label: 'Ville de naissance',
    name: `birthcity`,
    type: values => (values.birthcountry !== 'fr' ? 'textfield' : 'city'),
  }

  const mortgagorHousing = {
    fieldProps: {
      options: [
        { label: 'Location', value: 'tenant' },
        { label: 'Propriétaire', value: 'owner' },
        { label: 'Hébergement gratuit', value: 'sheltered' },
        { label: 'Logement de fonction', value: 'staff_housed' },
      ],
    },
    label: "Type d'hébergement",
    name: `profile.mortgagors[${index}].housing`,
    type: 'select',
  }

  const mortgagorRent = {
    condition: values => ['staff_housed', 'tenant'].includes(values[`profile.mortgagors[${index}].housing`]),
    fieldProps: {
      placeholder: '800',
      suffix: '€',
    },
    label: 'Loyer',
    name: `profile.mortgagors[${index}].rent`,
    type: 'numberfield',
  }

  const mortgagorResidence = {
    fieldProps: {
      options: [
        { id: 'mr1t', label: 'Oui', value: 'true' },
        { id: 'mr1f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) => `${accounts[index].name} habite-t-il (elle) à l’étranger ?`,
    name: 'residence',
    type: 'radio',
  }

  const mortgagorAddress = {
    fieldProps: values => ({
      placeholder: values.residence === 'false' ? '103 Boulevard du Montparnasse' : 'Royaume-Uni',
      searchfieldPlaceholder: values.residence === 'false' ? 'Saisissez une adresse' : 'Saisissez un pays',
    }),
    label: values => (values.residence === 'false' ? 'Adresse actuelle' : 'Pays de résidence'),
    name: `profile.mortgagors[${index}].address`,
    type: values => (values.residence === 'false' ? 'address' : 'country'),
  }

  const mortgagorExpatriationStatus = {
    condition: ({ residence }) => residence === 'true',
    fieldProps: {
      options: [
        { id: 'ext', label: 'Oui', value: 'true' },
        { id: 'exf', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) => `${accounts[index].name} a-t-il (elle) le statut d'expatrié ?`,
    name: `profile.mortgagors[${index}].expatriate_status`,
    type: 'radio',
  }

  const mortgagorContract = {
    fieldProps: {
      options: [
        { label: 'CDI', value: 'permanent' },
        { label: 'CDD', value: 'fixed_term' },
        { label: 'Secteur public', value: 'public' },
        { label: 'Autre', value: 'other' },
      ],
    },
    label: 'Contrat de travail',
    name: 'contract',
    type: 'select',
  }

  const mortgagorExecutive = {
    condition: ({ contract }) => contract === 'permanent',
    fieldProps: {
      options: [
        { label: 'Cadre', value: 'executive' },
        { label: 'Non-cadre', value: 'non_executive' },
      ],
    },
    label: 'Précisez',
    name: 'executive',
    type: 'select',
  }

  const mortgagorTrial = {
    condition: ({ contract }) => contract === 'permanent',
    fieldProps: {
      options: [
        { id: 't1t', label: 'Oui', value: 'true' },
        { id: 't1f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) => `${accounts[index].name} est-il (elle) en période d'essai ? `,
    name: 'trial',
    type: 'radio',
  }

  const mortgagorPublicContract = {
    condition: ({ contract }) => contract === 'public',
    fieldProps: {
      options: publicContracts,
    },
    label: 'Précisez',
    name: 'public_contract',
    type: 'select',
  }

  const mortgagorOtherContract = {
    condition: ({ contract }) => contract === 'other',
    fieldProps: {
      options: otherContracts,
    },
    label: 'Précisez',
    name: 'other_contract',
    type: 'select',
  }

  const mortgagorJobTitle = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    fieldProps: {
      placeholder: 'Philosophe et écrivain',
    },
    label: 'Métier',
    name: `profile.mortgagors[${index}].job.title`,
    type: 'textfield',
  }

  const mortgagorJobEmployer = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    fieldProps: {
      placeholder: 'Gallimard',
    },
    label: "Nom de l'employeur",
    name: `profile.mortgagors[${index}].job.employer`,
    type: 'textfield',
  }

  const mortgagorJobAddress = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    fieldProps: {
      placeholder: '5 Rue Sébastien Bottin',
      searchfieldPlaceholder: 'Saisissez une adresse',
    },
    label: 'Adresse du lieu de travail',
    name: `profile.mortgagors[${index}].job.address`,
    type: 'address',
  }

  const mortgagorJobStartDate = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    fieldProps: {
      placeholder: 'JJ / MM / AAAA',
    },
    label: "Date d'embauche",
    name: `profile.mortgagors[${index}].job.start_date`,
    type: 'date',
  }

  const mortgagorMandate1 = {
    fieldProps: {
      options: [
        { id: 'm1t', label: 'Oui', value: 'true' },
        { id: 'm1f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) =>
      `${accounts[index].name} a-t-il (elle) déjà souscrit à un crédit immobilier ?`,
    name: `profile.mortgagors[${index}].mandate1`,
    type: 'radio',
  }

  const mortgagorMandate2 = {
    fieldProps: {
      options: [
        { id: 'm2t', label: 'Oui', value: 'true' },
        { id: 'm2f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) =>
      `Pour ${accounts[index].name}, le crédit représente-t-il un acte fondamental ?`,
    name: `profile.mortgagors[${index}].mandate2`,
    type: 'radio',
  }

  const mortgagorMandate3 = {
    fieldProps: {
      options: [
        { id: 'm3t', label: 'Oui', value: 'true' },
        { id: 'm3f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) =>
      `Pour ${accounts[index].name}, le crédit représente-t-il un acte de la vie courante ?`,
    name: `profile.mortgagors[${index}].mandate3`,
    type: 'radio',
  }

  const mortgagorMandate4 = {
    fieldProps: {
      options: [
        { id: 'm4t', label: 'Oui', value: 'true' },
        { id: 'm4f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) => `${accounts[index].name} sait-il (elle) ce qu’est une garantie de prêt ?`,
    name: `profile.mortgagors[${index}].mandate4`,
    type: 'radio',
  }

  const mortgagorMandate5 = {
    fieldProps: {
      options: [
        { id: 'm5t', label: 'Oui', value: 'true' },
        { id: 'm5f', label: 'Non', value: 'false' },
      ],
    },
    label: (values, data, { accounts }) =>
      `${accounts[index].name} connaît-il (elle) le rôle de l’assurance emprunteur ?`,
    name: `profile.mortgagors[${index}].mandate5`,
    type: 'radio',
  }

  const mortgagorMandate6 = {
    label: (values, data, { accounts }) =>
      `Comment ${accounts[index].name} évalue-t-il (elle) son niveau de connaissance des produits bancaires sur une échelle de 1 (très mauvaise) à 5 (très bonne) ?`,
    name: `profile.mortgagors[${index}].mandate6`,
    type: 'rating',
  }

  const salary = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    label: 'Salaire',
    name: `profile.mortgagors[${index}].salary`,
    type: 'income',
  }

  const bonus = {
    condition: ({ other_contract }) => other_contract !== 'jobless',
    label: 'Prime ou salaire variable',
    name: `profile.mortgagors[${index}].bonus`,
    optional: true,
    type: 'income',
  }

  const incomeKind = {
    fieldProps: {
      options: incomesOptions,
    },
    label: 'Type de revenu',
    name: 'kind',
    type: 'select',
  }

  const incomeAmount = {
    fieldProps: {
      placeholder: '1 800',
      suffix: '€',
    },
    label: 'Montant par mois',
    name: 'amount',
    type: 'numberfield',
  }

  const linkedProperty = {
    condition: ({ kind }) => kind === 'rent',
    fieldProps: (values, project) => {
      const mortgagorId = project.profile.mortgagors[index].id

      return {
        options: [
          ...(project?.profile?.properties
            ?.filter(property => property.owners_ids.includes(mortgagorId))
            ?.map(property => ({
              label: property.name,
              value: property.id,
            })) ?? []),
          { label: 'Créer un bien', value: 'new' },
        ],
      }
    },
    label: 'Bien associé',
    name: 'property_id',
    type: 'select',
  }

  const incomes = {
    fieldProps: {
      formatContent: ({ amount }) => {
        if (!amount) {
          return 'Aucune montant renseigné'
        }

        return `Montant : ${amount.toLocaleString('fr')} €`
      },
      formatTitle: ({ kind }, index) => {
        const option = incomesOptions.find(({ value }) => value === kind)

        if (!option) {
          return index + 1
        }

        return option.label
      },
    },
    fields: [incomeKind, incomeAmount, linkedProperty],
    label: ({ residence }) =>
      `Revenus additionnels (locatifs, autoentreprise, pension alimentaire, allocations${
        residence === 'true' ? ", indemnités d'expatriation)" : ')'
      }`,
    name: `profile.mortgagors[${index}].incomes`,
    optional: true,
    type: 'list',
  }

  // Sections
  const informationSection = {
    fields: [mortgagorBirthdate, mortgagorNationality, mortgagorBirthCountry, mortgagorBirthCity],
    title: (values, data, { accounts }) => `Les informations de ${accounts[index].name}`,
  }

  const housingSection = {
    fields: [mortgagorHousing, mortgagorRent, mortgagorResidence, mortgagorAddress],
    title: 'Adresse et logement',
  }

  const situationSection = {
    fields: [
      mortgagorExpatriationStatus,
      mortgagorContract,
      mortgagorExecutive,
      mortgagorTrial,
      mortgagorPublicContract,
      mortgagorOtherContract,
      mortgagorJobTitle,
      mortgagorJobEmployer,
      mortgagorJobAddress,
      mortgagorJobStartDate,
    ],
    title: 'Situation professionnelle',
  }

  const incomesSection = {
    fields: [salary, bonus, incomes],
    helper:
      "Pour calculer des primes ou un salaire variable, faites la moyenne sur vos trois dernières années d'activité.",
    title: 'Revenus',
  }

  const knowledgeSection = {
    fields: [
      mortgagorMandate1,
      mortgagorMandate2,
      mortgagorMandate3,
      mortgagorMandate4,
      mortgagorMandate5,
      mortgagorMandate6,
    ],
    title: (values, data, { accounts }) => `${accounts[index].name} et le crédit immobilier`,
  }

  // Pages
  const defaultPage = {
    decoder: (values, data) => {
      const baseContract = data.profile.mortgagors[index].contract
      const liveTogether = data.profile.live_together
      const mortgagor = data.profile.mortgagors[0]

      const contract = getContractWithData(baseContract)

      const other_contract = contract === 'other' ? baseContract : null
      const private_contract = contract === 'private' ? baseContract : null
      const public_contract = contract === 'public' ? baseContract : null

      const getResidenceDefaultValue = () => {
        const indexToCheck = liveTogether ? 0 : index
        return !!data.profile.mortgagors[indexToCheck].address &&
          !data.profile.mortgagors[indexToCheck].address.city &&
          !data.profile.mortgagors[indexToCheck].address.street &&
          data.project_kind !== 'renegotiation'
          ? 'true'
          : 'false'
      }

      const getBirthCountry = () => {
        return data.profile.mortgagors[index].birthplace?.country || null
      }

      const getBirthCity = () => {
        if (data.profile.mortgagors[index].birthplace?.country === 'fr') {
          return {
            country: 'fr',
            city: data.profile.mortgagors[index].birthplace?.city || null,
            zipcode: data.profile.mortgagors[index].birthplace?.zipcode || null,
          }
        }

        return data.profile.mortgagors[index].birthplace?.city || null
      }

      const executive =
        contract === 'permanent'
          ? ['permanent_executive', 'permanent_executive_trial'].includes(baseContract)
            ? 'executive'
            : 'non_executive'
          : null

      const trial =
        contract === 'permanent'
          ? ['permanent_executive_trial', 'permanent_non_executive_trial'].includes(baseContract).toString()
          : null

      return filters.applyFilters([
        filters.applyFilter(
          `profile.mortgagors[${index}].address`,
          formatUnique(liveTogether, mortgagor.address, filters.formatDecodeAddress),
          false
        ),
        filters.applyFilter(`profile.mortgagors[${index}].birthdate`, filters.formatDecodeDate),
        filters.applyFilter(
          `profile.mortgagors[${index}].housing`,
          formatUnique(liveTogether, mortgagor.housing),
          false
        ),
        filters.applyFilter(`profile.mortgagors[${index}].job.start_date`, filters.formatDecodeDate),
        filters.applyFilter(`profile.mortgagors[${index}].rent`, formatUnique(liveTogether, mortgagor.rent), false),
        filters.applyFilter('birthcountry', getBirthCountry, false),
        filters.applyFilter('birthcity', getBirthCity, false),
        filters.applyFilter('residence', getResidenceDefaultValue, false),
      ])(
        {
          ...values,
          contract,
          executive,
          other_contract,
          private_contract,
          public_contract,
          trial,
        },
        data
      )
    },
    encoder: (differedValues, data, values) => {
      const filteredValues = omit(differedValues, 'contract', 'executive', 'other_contract', 'public_contract', 'trial')
      const contract = {
        fixed_term: 'fixed_term',
        other: values.other_contract,
        permanent: ['permanent_', values.executive, values.trial === 'true' ? '_trial' : ''].join(''),
        public: values.public_contract,
      }[values.contract]

      if (contract !== data.profile.mortgagors[index].contract) {
        filteredValues[`profile.mortgagors[${index}].contract`] = contract
      }

      if (
        values.birthcountry !== data.profile.mortgagors[index].birthplace?.birthcountry ||
        values.birthcity !== data.profile.mortgagors[index].birthplace?.birthcity
      ) {
        if (values.birthcountry === 'fr') {
          filteredValues[`profile.mortgagors[${index}].birthplace`] = {
            zipcode: values.birthcity?.zipcode || null,
            city: values.birthcity?.city || null,
            country: 'fr',
          }
        }

        if (values.birthcountry !== 'fr') {
          filteredValues[`profile.mortgagors[${index}].birthplace`] = {
            zipcode: null,
            city: typeof values.birthcity === 'string' ? values.birthcity : null,
            country: values.birthcountry || null,
          }
        }
      }

      return filters.applyFilters([
        ...applyHousingFilter(index, data, differedValues),
        filters.applyOmitFilter('residence'),
        filters.applyOmitFilter('birthcountry'),
        filters.applyOmitFilter('birthcity'),
        filters.applyFilter(`profile.mortgagors[${index}].birthdate`, filters.formatEncodeDate),
        filters.applyFilter(`profile.mortgagors[${index}].job.start_date`, filters.formatEncodeDate),
        filters.applyFilter(`profile.mortgagors[${index}].address`, filters.formatEncodeLocalisation),
        filters.applyFilter(`profile.mortgagors[${index}].incomes`, value =>
          value.map(income =>
            Object.entries(income).reduce(
              (previous, [key, value]) => ({
                ...previous,
                get [key]() {
                  if (key === 'property_id' && value === 'new') {
                    return uuidv4()
                  }

                  return value
                },
              }),
              {}
            )
          )
        ),
      ])(filteredValues, data)
    },
    sections: [incomesSection, situationSection, informationSection, housingSection, knowledgeSection],
    title: (data, { accounts }) => accounts[index].name,
  }

  return [defaultPage]
}

const step = {
  documentsParameters: { category: 'general', mortgagor: 0 },
  pages: getPageSchemaForMortgagor(0),
  queryParams: 'category=general_0',
  sideBarParameters: {
    label: (data, { accounts }) => accounts[0].name,
    picto: UserCircle,
    sideBarOrder: 1,
    url: '/application/mortgagor',
  },
}

export default step
