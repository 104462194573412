import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Title = styled.div`
  margin: 0 ${g(2)} ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    margin-right: 0;
  }
`
export const Button = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(2)};
`
