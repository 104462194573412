import { Picto } from '@pretto/picto'

import * as S from './ServiceCardLink.styles'

export type LinksType = {
  href: string
  label: string
  picto: Picto
}

export interface ServiceCardLinkProps {
  email?: string | undefined
  links: LinksType[]
  onClick?: (event: MouseEvent) => void
}

export const ServiceCardLink = ({ email, links, onClick }: ServiceCardLinkProps) => (
  <>
    {links.map(({ href, label, picto }: LinksType) => (
      <S.Button
        href={email ? `${href}?email=${email}` : href}
        key={label}
        label={label}
        onClick={onClick}
        picto={picto}
      />
    ))}
  </>
)
