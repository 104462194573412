import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronLeftBold } from '@pretto/picto'

import styled from 'styled-components'

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TimelineLink = styled(BaseTypo).attrs(({ href }) => ({
  forwardedAs: !href ? 'button' : Link,
  typo: 'button',
}))`
  & + & {
    margin-top: ${g(3)};
  }
`

export const ChevronLeft = styled(ChevronLeftBold)`
  margin-right: ${g(2)};
`
