import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { ArrowLeft } from '@pretto/picto'

import styled, { css } from 'styled-components'

interface StateProps {
  $isDisabled: boolean
}

interface ContainerProps extends StateProps {
  href?: string
  tabIndex?: number
}

export const Container = styled.span.attrs<ContainerProps>(({ $isDisabled, href, tabIndex }) => ({
  as: !href ? 'button' : Link,
  disabled: !href ? $isDisabled : undefined,
  tabIndex: $isDisabled ? -1 : tabIndex,
}))<ContainerProps>`
  ${({ theme }) => theme.typos.button};
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral1};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  appearance: none;
  border: none;
  display: inline-flex;
  gap: ${g(2)};
  line-height: ${g(4)};
  padding: 0;
  ${transition({ propertyName: 'color' })};
  white-space: nowrap;

  ${({ $isDisabled, theme }) =>
    !$isDisabled &&
    css`
      &&:hover,
      &&:focus {
        color: ${theme.colors.neutral1Hover};
      }
    `}
`

export const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Arrow = styled.svg`
  flex-shrink: 0;
  height: ${g(4)};
  overflow: visible;
  width: ${g(4)};
`

export const ArrowInstance = styled.svg.attrs({ as: 'use' })<StateProps>`
  ${transition(
    { propertyName: 'opacity' },
    { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'transform' }
  )};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        transform: translateX(${g(-2)});
      }
    `}
`

export const ArrowInstance1 = styled(ArrowInstance)<StateProps>`
  opacity: 0;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 1;
      }
    `}
`

export const ArrowInstance2 = styled(ArrowInstance)<StateProps>`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 0;
      }
    `};
`

export const ArrowSymbol = styled(ArrowLeft)``
