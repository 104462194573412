import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ILLUSTRATIONS = {
  'Panic-3': 'v1623408081/bricks/static-assets/illustrations/Panic-3.svg',
  abacus: 'v1616412302/bricks/static-assets/illustrations/abacus.svg',
  book_house: 'v1613553118/bricks/static-assets/illustrations/book_house.svg',
  book: 'v1628081764/bricks/static-assets/illustrations/book.svg',
  buildings: 'v1610978068/bricks/static-assets/illustrations/buildings.svg',
  Calculette1: 'v1625059268/bricks/static-assets/illustrations/Calculette1.svg',
  Calculette2: 'v1625059269/bricks/static-assets/illustrations/Calculette2.svg',
  capa: 'v1627028307/bricks/static-assets/illustrations/capa.svg',
  CharacterLaptop: 'v1625059268/bricks/static-assets/illustrations/CharacterLaptop.svg',
  Coins1: 'v1623408081/bricks/static-assets/illustrations/Coins1.svg',
  Couple: 'v1622808009/bricks/static-assets/illustrations/Couple.svg',
  direction_signs: 'v1616166455/bricks/static-assets/illustrations/direction_signs.svg',
  Evaluate: 'v1622807789/bricks/static-assets/illustrations/Evaluate.svg',
  EvaluateLeft: 'v1629985481/bricks/static-assets/illustrations/EvaluateLeft.svg',
  expertWithPercent: 'v1666615143/zen/static-assets/illustrations/660x660/expertWithPercent.png',
  Girl_Bored: 'v1623225191/bricks/static-assets/illustrations/Girl_Bored.svg',
  GirlPercent: 'v1627286165/bricks/static-assets/illustrations/GirlPercent.svg',
  HidingEyes: 'v1623408081/bricks/static-assets/illustrations/HidingEyes.svg',
  house: 'v1619164627/bricks/static-assets/illustrations/house.svg',
  House2: 'v1622808010/bricks/static-assets/illustrations/House2.svg',
  LabelNeutral: 'v1623856234/bricks/static-assets/illustrations/LabelNeutral.svg',
  lightbulb_man: 'v1616071259/bricks/static-assets/illustrations/lightbulb_man.svg',
  man_bath_window: 'v1616595495/bricks/static-assets/illustrations/man_bath_window.svg',
  man_bath: 'v1616678949/bricks/static-assets/illustrations/man_bath.svg',
  man_bathing: 'v1611335121/bricks/static-assets/illustrations/man_bathing.svg',
  man_computer_wine_and_cat: 'v1609767941/bricks/static-assets/illustrations/man_computer_wine_and_cat.svg',
  man_expert: 'v1627036527/bricks/static-assets/illustrations/ExpertMan2.svg',
  man_hugging_house: 'v1616148140/bricks/static-assets/illustrations/man_hugging_house.svg',
  man_scratch_head: 'v1616410159/bricks/static-assets/illustrations/man_scratch_head.svg',
  man_scratching_head: 'v1618498458/bricks/static-assets/illustrations/man_scratching_head.svg',
  man_with_piggy_bank: 'v1624028055/bricks/static-assets/illustrations/man_with_piggy_bank.svg',
  man_with_stopwatch: 'v1609766076/bricks/static-assets/illustrations/man_with_stopwatch.svg',
  ManChair: 'v1625059269/bricks/static-assets/illustrations/ManChair.svg',
  ManMeasuringHouse: 'v1624542117/bricks/static-assets/illustrations/man_measuring_house.svg',
  men_chatting_with_beer: 'v1609767946/bricks/static-assets/illustrations/men_chatting_with_beer.svg',
  NotaryFees: 'v1627287025/bricks/static-assets/illustrations/NotaryFees.svg',
  oldManSittingAndPercents: 'v1637683147/zen/static-assets/illustrations/660x660/oldManSittingAndPercents.svg',
  people_chatting: 'v1611335121/bricks/static-assets/illustrations/people_chatting.svg',
  people_long_list: 'v1620377936/bricks/static-assets/illustrations/people_long_list.svg',
  people_phone: 'v1616423982/bricks/static-assets/illustrations/people_phone.svg',
  people_shaking_hands: 'v1611335121/bricks/static-assets/illustrations/people_shaking_hands.svg',
  percent: 'v1618836446/bricks/static-assets/illustrations/percent.svg',
  project: 'v1627028075/bricks/static-assets/illustrations/project.svg',
  sensei_key: 'v1617708565/bricks/static-assets/illustrations/sensei_key.svg',
  skating_with_dog_in_logo: 'v1610459903/bricks/static-assets/illustrations/skating_with_dog_in_logo.svg',
  skating_with_dog: 'v1609767937/bricks/static-assets/illustrations/skating_with_dog.svg',
  vase_cat: 'v1613398088/bricks/static-assets/illustrations/vase_cat.svg',
  woman_with_certificate: 'v1611335121/bricks/static-assets/illustrations/woman_with_certificate.svg',
  woman_with_certificate2: 'v1628080728/bricks/static-assets/illustrations/woman_with_certificate2.svg',
  zen_man_2: 'v1613406554/bricks/static-assets/illustrations/zen_man_2.svg',
  zen_man: 'v1610978071/bricks/static-assets/illustrations/zen_man.svg',
}

const IllustrationDeprecated = ({ alt, name, ...props }) => (
  <S.Illustration {...props} loading="lazy" src={getCloudinaryUrl(ILLUSTRATIONS[name])} alt={alt ?? name} />
)

IllustrationDeprecated.propTypes = {
  /** Alternative text for image. */
  alt: PropTypes.string,
  /** Name of asset. You can see the [assets list](https://cloudinary.com/console/c-ecef9c757cd277072532d150ce459c/media_library/folders/f5d56fc6923ee85d658a83f074cf321e) from cloudinary. */
  name: PropTypes.string.isRequired,
}

export default memo(IllustrationDeprecated)
