import Dialog from '@pretto/bricks/components/overlays/Dialog'

import * as S from './PurchaseOffer.styles'
import { Modal, ModalProps } from './components/Modal/Modal'
import { Section, SectionProps } from './components/Section/Section'

export interface PurchaseOfferProps {
  isButtonDisabled: boolean
  isButtonLoading: boolean
  isModalOpen: boolean
  isSnackbarOpen: boolean
  onCloseSnackbar: () => void
  onClickSnackbarButton: () => void
  onDownloadPDF: () => void
  onDownloadWord: () => void
  modalProps: ModalProps
  sections: SectionProps[]
}

export const PurchaseOffer = ({
  isButtonDisabled,
  isButtonLoading,
  isModalOpen,
  isSnackbarOpen,
  onCloseSnackbar,
  onClickSnackbarButton,
  onDownloadPDF,
  onDownloadWord,
  modalProps,
  sections,
}: PurchaseOfferProps) => (
  <S.Page>
    <Dialog isOpen={isModalOpen}>
      <Modal {...modalProps} />
    </Dialog>

    <S.Hero>
      <S.Title>Votre offre d’achat</S.Title>
      <S.Headline>
        Complétez les champs ci-dessous pour générer votre offre d’achat personnalisée au <strong>format PDF</strong>.
      </S.Headline>
    </S.Hero>

    <S.Main>
      {sections.map((section, i) => (
        <Section key={i} {...section} />
      ))}

      <S.ButtonWrapper>
        <S.ButtonPDF isDisabled={isButtonDisabled} onClick={onDownloadPDF} isLoading={isButtonLoading}>
          Je génère mon offre (.pdf)
        </S.ButtonPDF>
      </S.ButtonWrapper>
      <S.ButtonWrapper>
        <S.ButtonWord
          onClick={onDownloadWord}
          href="https://res.cloudinary.com/pretto-fr/raw/upload/v1630073876/website/document/offre-d-achat-v2.docx"
        >
          Je souhaite utiliser un doc Word
        </S.ButtonWord>
      </S.ButtonWrapper>
    </S.Main>

    <S.Snackbar $isOpen={isSnackbarOpen}>
      <S.SnackbarIllustration />
      <S.SnackbarTitle>Félicitations !</S.SnackbarTitle>
      <p>
        Vous semblez avoir trouvé le bien idéal ! Un doute sur <strong>le taux</strong> auquel vous pouvez prétendre ?
        Estimez votre <strong>prêt immobilier</strong> en un temps record grâce à notre simulateur.
      </p>

      <S.ButtonSnackbar onClick={onClickSnackbarButton} isSecondary>
        Simuler mon taux
      </S.ButtonSnackbar>
      <S.Close onClick={onCloseSnackbar} />
    </S.Snackbar>
  </S.Page>
)
