import { g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'
import { Spinner as SpinnerComponent } from '@pretto/zen/atoms/loaders/Spinner/Spinner'
import styled, { css } from 'styled-components'

export const TRANSITION_DELAY = 175
export const TRANSITION_DURATION_IN = 200
export const TRANSITION_DURATION_OUT = 150

interface LabelProps {
  $isLoading: boolean
}

export const Label = styled.span<LabelProps>`
  transition: opacity ${TRANSITION_DURATION_IN}ms ease-out ${TRANSITION_DELAY}ms;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;
    `}
`

interface SlotProps {
  $isDisabled: boolean
  $isEmpty: boolean
  $isLoading: boolean
}

export const Slot = styled.div.attrs(({ $isDisabled, $isEmpty, $isLoading }: SlotProps) => ({
  as: $isEmpty ? 'div' : 'button',
  disabled: !$isEmpty && ($isDisabled || $isLoading),
}))<SlotProps>`
  ${typography.numbers16};
  align-items: center;
  border-radius: ${g(1 / 2)};
  color: inherit;
  display: flex;
  justify-content: center;
  padding: ${g(1)};
  position: relative;
  transition: background-color ${TRANSITION_DURATION_IN}ms ease-out 0s, opacity ${TRANSITION_DURATION_IN}ms ease-out 0s;

  &:disabled {
    color: ${({ theme }) => theme.colors.neutral3};
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral4};

  }

  ${({ $isEmpty, $isLoading }) => {
    if ($isEmpty) {
      return css`
        color: ${({ theme }) => theme.colors.neutral2};

        &:after {
          content: '—';
          display: block;
        }
      `
    }

    if ($isLoading) {
      return css`
        background-color: ${({ theme }) => theme.colors.primary2};
      `
    }

    return css`
      background-color: ${({ theme }) => theme.colors.primary4};
      cursor: pointer;
      outline: 0px transparent;

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background-color: ${({ theme }) => theme.colors.primary3};
      }
    `
  }}
`

interface SpinnerProps {
  $isLoading: boolean
}

export const Spinner = styled(SpinnerComponent).attrs({ backgroundColor: 'primary1', color: 'primary4' })<SpinnerProps>`
  bottom: 0px;
  height: ${g(3)};
  left: 0px;
  margin: auto;
  opacity: 0;
  right: 0px;
  position: absolute;
  top: 0px;
  transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;
  width: ${g(3)};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 1;
      transition: opacity ${TRANSITION_DURATION_IN}ms ease-out 0s;
    `}
`
