import { productionContext } from '@pretto/app/src/config/netlify'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { History } from 'history'

// Use IIFE to define and setup a closure, prevents multiple init of Sentry
const initSentryIIFE = (() => {
  let isInitialized = false

  return (history: History): void => {
    if (!isInitialized) {
      Sentry.init({
        enabled: productionContext.includes(process.env.CONTEXT || 'dev') || Boolean(process.env.ENABLE_SENTRY),
        dsn: process.env.SENTRY_DSN_APP,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          }),
          new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        release: process.env.BUILD_ID ?? undefined,
        tracesSampleRate: 0.01,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.CONTEXT ?? undefined,
      })
      isInitialized = true
    }
  }
})()

// Return always the same instance
export const initSentry = initSentryIIFE
