import TimelineAdvisor from '@pretto/bricks/app/timeline/components/TimelineAdvisor'

import AdvisorAvatar from '@pretto/app/src/SharedContainers/AdvisorAvatar'
import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { getIsAdvisorUnavailable } from '@pretto/app/src/lib/getIsAdvisorUnavailable'
import { Calendar } from '@pretto/picto'

import { Link } from 'react-router-dom'

export const Advisor = () => {
  const { onOpen } = useAdvisor()

  const {
    advisor: { name, email, phone, holidays_end_date, holidays_start_date, role },
    hasFeatureAccess,
  } = useUser()

  const isVacationExpert = getIsAdvisorUnavailable({ startDate: holidays_start_date, endDate: holidays_end_date })

  const handleCall = () => {
    document.location.href = `tel:${phone}`
  }

  const AdvisorLink = !hasFeatureAccess(['NO_APPOINTMENT']) && (
    <Link to="/booking">
      <Calendar />
    </Link>
  )

  return (
    <TimelineAdvisor
      avatar={<AdvisorAvatar size="splitted" isWithWarningIcon={isVacationExpert} isWithOverlay={isVacationExpert} />}
      link={AdvisorLink}
      name={name}
      email={email}
      phone={phone}
      onOpen={onOpen}
      onCall={handleCall}
      isVacationExpert={isVacationExpert}
      holidays_end_date={holidays_end_date}
      role={role}
    />
  )
}
