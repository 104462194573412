import type { Credit } from '@pretto/app/src/Sentences/v2/types/credit'

import { v4 as uuidv4 } from 'uuid'

/**
 * @description Create a credit with default values
 * @param {Partial<Credit>} revenue
 * @returns {Credit}
 * @example
 * createCredit()
 * createCredit({ amount: 1000 })
 * createCredit({ type: 'salary' })
 */
export const createCredit = (credit?: Partial<Credit>): Credit => ({
  amount: null,
  type: null,
  endDate: null,
  ...credit,
  id: uuidv4(),
})
