import MandateTemplate from '@pretto/bricks/app/mandate/templates/MandateTemplate'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import BubbleChat from '@pretto/bricks/shared/components/BubbleChat'
import ProjectRow from '@pretto/bricks/shared/components/ProjectRow'

import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

const MandateConfirmationPage = ({
  avatarProps,
  creditCost,
  duration,
  isSubmittable,
  onConfirm,
  paymentMax,
  paymentMin,
  projectResults,
  projectValues,
  showConditions,
  templateProps,
  warningMessage,
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheck = () => setIsChecked(isChecked => !isChecked)

  return (
    <MandateTemplate
      {...templateProps}
      sidebar={<BubbleChat avatarProps={avatarProps}>{warningMessage}</BubbleChat>}
      title={
        <>
          <Emoji size="medium">:mag_right:</Emoji> Vérifiez votre projet
        </>
      }
    >
      <S.Detail>
        <Card format="line" variant="neutral-1-20">
          <Content>
            <S.CardTitle>
              <SubHeading>Détails des montants</SubHeading>
            </S.CardTitle>
            {projectValues.map((rowProps, i) => (
              <S.Row key={i}>
                <ProjectRow {...rowProps} />
              </S.Row>
            ))}
            <S.Row>
              <Divider variant="neutral-1-20" />
            </S.Row>
            {projectResults.map((rowProps, i) => (
              <S.Row key={i}>
                <ProjectRow {...rowProps} />
              </S.Row>
            ))}
          </Content>
        </Card>
      </S.Detail>

      <S.Detail>
        <Card format="line" variant="neutral-1-20">
          <Content>
            <S.CardTitle>
              <SubHeading>Caractéristiques du prêt</SubHeading>
            </S.CardTitle>
            <S.Row>
              <ProjectRow title="Durée" value={formatNumber(duration, { suffix: 'ans' })} />
            </S.Row>
            <S.Row>
              <ProjectRow
                title="Mensualité hors assurance"
                value={`${formatNumber(paymentMin)} - ${formatNumber(paymentMax, { suffix: '€ / mois' })}`}
              />
            </S.Row>
            <S.Row>
              <ProjectRow
                title="Coût du crédit estimé, hors assurance"
                value={formatNumber(creditCost, { suffix: '€' })}
              />
            </S.Row>
          </Content>
        </Card>
      </S.Detail>

      {showConditions && (
        <S.Detail>
          <Card format="line" variant="neutral-1-20">
            <Content>
              <S.CardTitle>
                <SubHeading size="large">Conditions d'obtention</SubHeading>
              </S.CardTitle>

              <div>
                Vous devrez domicilier vos revenus dans la banque qui vous accordera le financement de votre projet.
              </div>
            </Content>
          </Card>
        </S.Detail>
      )}

      <S.Check>
        <InputCheckbox
          id="confirm"
          isChecked={isChecked}
          label="Je certifie que ces informations sont correctes et je comprends qu'elles reflètent le financement que Pretto va négocier auprès des banques."
          onChange={handleCheck}
        />
      </S.Check>

      {isSubmittable && (
        <S.ButtonContainer>
          <ButtonLegacy onClick={onConfirm} disabled={!isChecked} size="large">
            <Responsive max="tablet">Confirmer</Responsive>
            <Responsive min="tablet">Confirmer mon projet</Responsive>
          </ButtonLegacy>
        </S.ButtonContainer>
      )}
    </MandateTemplate>
  )
}

MandateConfirmationPage.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Cost of credit without insurance. */
  creditCost: PropTypes.number.isRequired,
  /** Duration of loan, in years. */
  duration: PropTypes.number.isRequired,
  /** Hides submit button when not submittable. */
  isSubmittable: PropTypes.bool,
  /** Event handler when confirming the project. */
  onConfirm: PropTypes.func.isRequired,
  /** Maximum payment for loan. */
  paymentMax: PropTypes.number.isRequired,
  /** Minimum payment for loan. */
  paymentMin: PropTypes.number.isRequired,
  /** Details of results amounts, shown under `projectValues`. */
  projectResults: PropTypes.array.isRequired,
  /** Details of values amounts. */
  projectValues: PropTypes.array.isRequired,
  /** Whether conditions are visible. */
  showConditions: PropTypes.bool,
  /** Properties of `MandateTemplate` bricks component. */
  templateProps: PropTypes.object,
  warningMessage: PropTypes.string,
}

export default MandateConfirmationPage
