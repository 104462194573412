import * as S from './MandateSuccess.styles'

type ButtonProps = {
  children: string
  href: string
  onClick?: () => void
}

export interface MandateSuccessProps {
  firstButtonProps: ButtonProps
  illustration: string
  secondButtonProps?: ButtonProps
  texts: React.ReactNode[]
  title: string
}

export const MandateSuccess = ({
  firstButtonProps,
  illustration,
  secondButtonProps,
  texts,
  title,
}: MandateSuccessProps) => (
  <S.MandateSuccess>
    <S.Container>
      <S.Illustration path={illustration} />
      <S.Tilte>{title}</S.Tilte>
      {texts.map((text: React.ReactNode, i: number) => (
        <S.Text key={i}>{text}</S.Text>
      ))}
      {firstButtonProps && <S.FirstButton {...firstButtonProps} />}
      {secondButtonProps && <S.SecondButton {...secondButtonProps} />}
    </S.Container>
  </S.MandateSuccess>
)
