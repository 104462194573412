import client from '@pretto/app/src/api'
import { PROJECT } from '@pretto/app/src/apollo'
import { Project } from '@pretto/app/src/types/gateway/schema'

type ProjectResponse = {
  data?: {
    project: Project
  }
}

export const getProject = (projectId: string, token?: string): Promise<ProjectResponse> =>
  client.query({
    context: { headers: { authorization: token ? `Bearer ${token}` : `Visitor ${projectId}` } },
    fetchPolicy: 'network-only',
    query: PROJECT,
  })
