import { g } from '@pretto/bricks/components/layout'
import { bodyBook16, caption12, heading16, inputBook16 } from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

export const List = styled.div`
  background-color: ${({ theme, variant }) => theme.colors[variant || 'white']};

  ${({ textVariant, theme }) =>
    textVariant &&
    css`
      color: ${theme.colors[textVariant]};
    `}
`

export const ListItem = styled.div`
  align-items: center;
  cursor: ${({ $isUnavailable }) => ($isUnavailable ? 'default' : 'pointer')};
  display: flex;
  padding: ${g(0)} ${g(4)} ${g(3)};
`

export const ListTitle = styled.div`
  ${heading16};
  padding: ${g(3)} ${g(4)};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${g(2)};
`

export const ListItemTitle = styled.div`
  ${({ $isUnavailable }) => ($isUnavailable ? inputBook16 : bodyBook16)};
  color: ${({ theme, $isUnavailable }) => ($isUnavailable ? theme.colors.neutral2 : 'inherit')};
`

export const Subtitle = styled.div`
  ${caption12};
  color: ${({ theme }) => theme.colors.neutral2};
  padding-left: ${g(0)} ${g(4)};
`
