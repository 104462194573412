import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { validateEmail } from '@pretto/bricks/core/utility/form/validateEmail'

import * as filters from '@pretto/app-core/lib/filters'

import { Bell, Building, Coins, Key, MagnifyingGlassLeft, Marker, Plan, SquareMeter, Tree } from '@pretto/picto'

import range from 'lodash/range'

const MAXIMUM_NUMBER_OF_ROOMS = 6

export const mainSchema = {
  sections: [
    {
      fields: [
        {
          defaultValue: [],
          fieldProps: {
            idleLabel: 'Ajouter une ville',
            searchfieldPlaceholder: 'Rechercher par ville ou par code postal',
          },
          flags: [
            {
              children: 'Vous devez ajouter une ou plusieurs localités pour votre recherche.',
              condition: value => value.length === 0,
              type: 'error',
            },
          ],
          name: 'localisations',
          required: true,
          type: 'cities',
        },
      ],
      picto: Marker,
      title: 'Localisation',
    },
    {
      fields: [
        {
          fieldProps: {
            placeholder: '0',
            suffix: '€',
          },
          flags: [
            {
              children: 'Le prix minimum ne peut pas être supérieur au prix maximum.',
              condition: (_, __, { price_max, price_min }) => Number(price_min) > Number(price_max),
              type: 'error',
            },
          ],
          label: 'Prix minimum',
          name: 'price_min',
          type: 'price',
        },
        {
          fieldProps: {
            placeholder: '0',
            suffix: '€',
          },
          flags: [
            {
              condition: (_, __, { price_max, price_min }) => Number(price_min) > Number(price_max),
              type: 'error',
            },
            {
              children: 'Vous devez renseigner un prix maximum',
              condition: (_, __, { price_max }) => Number(price_max) < 1,
              type: 'error',
            },
          ],
          label: 'Prix maximum *',
          name: 'price_max',
          type: 'price',
        },
      ],
      footer: ({ isLoggedIn }) => (
        <ButtonInline href={isLoggedIn ? '/capacity' : '/project/capacity/introduction'}>
          Calculer mon budget
        </ButtonInline>
      ),
      picto: Coins,
      title: 'Budget',
    },
    {
      fields: [
        {
          fieldProps: {
            placeholder: '0',
            suffix: 'm²',
          },
          flags: [
            {
              children: 'Vous devez renseigner une surface minimum.',
              condition: (_, __, { area_min }) => Number(area_min) < 1,
              type: 'error',
            },
          ],
          label: 'Surface minimum *',
          name: 'area_min',
          type: 'area',
        },
        {
          fieldProps: {
            placeholder: '0',
            suffix: 'm²',
          },
          flags: [
            {
              children: 'La surface minimum ne peut pas être supérieure à la surface maximum.',
              condition: (_, __, { area_max, area_min }) => area_max && Number(area_min) > Number(area_max),
              key: 'area_range',
              type: 'error',
            },
          ],
          label: 'Surface maximum',
          name: 'area_max',
          type: 'area',
        },
      ],
      picto: SquareMeter,
      title: 'Surface',
    },
    {
      fields: [
        {
          fieldProps: {
            placeholder: '0',
            suffix: 'm²',
          },
          label: 'Surface minimum',
          name: 'min_land_surface',
          type: 'area',
        },
      ],
      picto: Tree,
      title: 'Terrain',
    },
    {
      fields: [
        {
          defaultValue: 1,
          fieldProps: {
            options: range(1, MAXIMUM_NUMBER_OF_ROOMS).map(index => {
              const label = `${index} pièce${index > 1 ? 's' : ''} et plus`

              return {
                label,
                selectedLabel: (
                  <>
                    <Responsive min="tablet">{label}</Responsive>
                    <Responsive max="tablet">{index}+</Responsive>
                  </>
                ),
                value: index,
              }
            }),
            placeholder: (
              <>
                <Responsive min="tablet">2 pièces et plus</Responsive>
                <Responsive max="tablet">2+</Responsive>
              </>
            ),
          },
          label: 'Nombre de pièces',
          name: 'rooms',
          type: 'select',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'elevator',
                label: 'Ascenseur',
              },
            ],
          },
          name: 'elevator',
          required: false,
          type: 'checkboxes',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'not_on_ground_floor',
                label: 'Hors RDC',
              },
            ],
          },
          name: 'not_on_ground_floor',
          required: false,
          type: 'checkboxes',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'basement',
                label: 'Cave / Box',
              },
            ],
          },
          name: 'basement',
          required: false,
          type: 'checkboxes',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'parking_space',
                label: 'Parking',
              },
            ],
          },
          name: 'parking_space',
          required: false,
          type: 'checkboxes',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'balcony',
                label: 'Balcon / Terrasse',
              },
            ],
          },
          name: 'balcony',
          required: false,
          type: 'checkboxes',
        },
        {
          defaultValue: [],
          fieldProps: {
            options: [
              {
                id: 'has_furniture',
                label: 'Meublé',
              },
            ],
          },
          name: 'has_furniture',
          required: false,
          type: 'checkboxes',
        },
      ],
      picto: Plan,
      title: 'Caractéristiques',
    },
    {
      fields: [
        {
          defaultValue: ['flat', 'house'],
          fieldProps: {
            options: [
              {
                id: 'flat',
                label: 'Appartement',
                value: 'flat',
              },
              {
                id: 'house',
                label: 'Maison',
                value: 'house',
              },
            ],
          },
          flags: [
            {
              children: 'Vous devez renseigner un type de bien.',
              condition: value => value.length === 0,
              type: 'error',
            },
          ],
          name: 'property_kind',
          required: true,
          type: 'checkboxes',
        },
      ],
      picto: Building,
      title: 'Type de bien',
    },
    {
      fields: [
        {
          defaultValue: ['new', 'old'],
          fieldProps: {
            options: [
              {
                id: 'old',
                label: 'Ancien',
                value: 'old',
              },
              {
                id: 'new',
                label: 'Neuf',
                value: 'new',
              },
            ],
          },
          flags: [
            {
              children: 'Vous devez renseigner un type de vente.',
              condition: value => value.length === 0,
              type: 'error',
            },
          ],
          name: 'property_condition',
          required: true,
          type: 'checkboxes',
        },
      ],
      picto: Key,
      title: 'Type de vente',
    },
    {
      fields: [
        {
          defaultValue: 'both',
          fieldProps: {
            options: [
              {
                id: 'both',
                label: 'Toutes les annonces',
                value: 'both',
              },
              {
                id: 'agency',
                label: 'Uniquement les agences',
                value: 'agency',
              },
              {
                id: 'individual',
                label: 'Uniquement les particuliers',
                value: 'individual',
              },
            ],
          },
          name: 'vendor',
          required: true,
          type: 'radio',
        },
      ],
      picto: MagnifyingGlassLeft,
      title: 'Source',
    },
    {
      fields: [
        {
          condition: (values, isEditable) => isEditable,
          flags: [
            {
              children: ({ onUnsubscribe }) => (
                <>
                  Vous ne recevrez plus d’annonces par email. Nous conservons vos critères de recherche si vous
                  souhaitez réactiver cette alerte plus tard. Vous pouvez aussi{' '}
                  <button onClick={onUnsubscribe}>supprimer votre alerte définitivement</button>.
                </>
              ),
              condition: value => value === false,
              type: 'warning',
            },
          ],
          label: 'Recevoir mes annonces par email',
          name: 'via_email',
          type: 'checkbox',
        },
        {
          condition: (values, isEditable, { isLoggedIn }) => !isEditable && !isLoggedIn,
          encoder: filters.formatEncodeEmail,
          fieldProps: {
            placeholder: 'bruce.wayne@gmail.com',
          },
          flags: [
            {
              children: 'Attention ! Vous devez indiquer une adresse email valide.',
              condition: (value, values, { email }) => !validateEmail(email),
              type: 'error',
            },
          ],
          label: 'Email',
          name: 'email',
          required: true,
          type: 'text',
        },
        {
          condition: ({ via_email }, isEditable) => !isEditable || via_email,
          defaultValue: 'real_time',
          fieldProps: {
            options: [
              {
                id: 'real_time',
                label: 'En temps réel',
                value: 'real_time',
              },
              {
                id: 'daily',
                label: '1 fois par jour',
                value: 'daily',
              },
              {
                id: 'weekly',
                label: '1 fois par semaine',
                value: 'weekly',
              },
            ],
          },
          label: 'Fréquence',
          name: 'frequency',
          required: true,
          type: 'radio',
        },
      ],
      picto: Bell,
      title: isEditable => (isEditable ? 'Email' : ''),
    },
  ],
}

export const unsubscribeSchema = {
  sections: [
    {
      fields: [
        {
          fieldProps: {
            options: [
              {
                id: 'found',
                label: "J'ai trouvé mon bien !",
                value: 'found',
              },
              {
                id: 'unsatisfied',
                label: 'Je ne suis pas satisfait du service',
                value: 'unsatisfied',
              },
              {
                id: 'pause',
                label: 'Je souhaite mettre en pause pour quelque temps',
                value: 'pause',
              },
              {
                id: 'other',
                label: 'Autre',
                value: 'other',
              },
            ],
          },
          name: 'reason',
          required: true,
          type: 'radio',
        },
      ],
    },
  ],
}
