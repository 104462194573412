import Icon from '@pretto/bricks/components/iconography/Icon'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import Link from '@pretto/bricks/website/utility/Link'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'
import { createElement, forwardRef, Fragment } from 'react'

import * as C from './ButtonLegacy.module.css'
import * as S from './styles'

const matchLoadingVariant = (textVariant, variant, format) => {
  if (textVariant) return textVariant

  if (['white', 'neutral-1-l'].includes(variant)) return 'neutral-1'

  const defaultVariant = textVariant || 'white'

  if (!format) return defaultVariant

  const formatAsArray = typeof format === 'string' ? [format] : format
  if (formatAsArray.includes('line')) return variant

  return defaultVariant
}

const getComponent = (href, props, textVariant, isLoading, content) => {
  const { format, variant } = props
  const children = (
    <S.Button isLoading={isLoading}>
      <S.ButtonContent
        className={classnames(C.button__text, { [C[`button__text${pascalcase(textVariant ?? '')}`]]: textVariant })}
      >
        {content}
      </S.ButtonContent>
      {isLoading && (
        <S.Loading>
          <SpinnerLegacy variant={matchLoadingVariant(textVariant, variant, format)} size="small" />
        </S.Loading>
      )}
    </S.Button>
  )

  if (!href) {
    return <div>{createElement('button', props, children)}</div>
  }

  if (/^\//.test(href)) {
    return <div>{createElement(Link, { ...props, href }, children)}</div>
  }

  if (href.indexOf('https://app.pretto.fr') === 0) {
    return (
      <Link {...props} href={href} rel="nofollow">
        {children}
      </Link>
    )
  }

  return <div>{createElement('a', { ...props, href, rel: 'noreferrer noopener', target: '_blank' }, children)}</div>
}

const ButtonLegacy = forwardRef(
  (
    {
      children,
      className,
      disabled,
      forceClick,
      format,
      href,
      icon,
      iconPosition,
      iconProps,
      iconSize,
      isLoading,
      label,
      onClick,
      scaleOnHover,
      size,
      target,
      textVariant,
      type,
      variant,
    },
    ref
  ) => {
    const handleClick = e => {
      if (!disabled || forceClick === true) {
        onClick(e)
      } else {
        e.preventDefault()
      }
    }

    const handleKeyDown = e => {
      if (e.keyCode === '13') handleClick()
    }

    const formats = Array.isArray(format) ? format : [format]
    const formatsClassnames = formats.filter(Boolean).map(format => C[`button${pascalcase(format ?? '')}`])

    const buttonClass = classnames(
      C.button,
      className,
      formatsClassnames,
      C[`button${pascalcase(size ?? '')}`],
      C[`button${pascalcase(variant ?? '')}`],
      {
        [C.buttonDisabled]: disabled,
        [C.buttonScaleOnHover]: scaleOnHover,
      }
    )
    const buttonProps = {
      className: buttonClass,
      disabled: disabled && !forceClick,
      format,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      ref,
      target,
      type,
      variant,
    }

    const content = children || label
    const iconContent = (icon !== null || !!iconProps) && <Icon className={C.icon} name={icon} {...iconProps} />

    const buildComponent = getComponent.bind(null, href, buttonProps, textVariant, isLoading)

    if (content === '') {
      return buildComponent(iconContent)
    }

    if (iconContent && iconPosition === 'left') {
      return buildComponent(
        <Fragment>
          <span className={classnames(C.button__iconLeft, C[`button__icon${pascalcase(iconSize ?? '')}`])}>
            {iconContent}
          </span>
          <span className={C.button__iconLabel}>{content}</span>
        </Fragment>
      )
    }

    if (iconContent && iconPosition === 'right') {
      return buildComponent(
        <Fragment>
          <span className={C.button__iconLabel}>{content}</span>
          <span className={classnames(C.button__iconRight, C[`button__icon${pascalcase(iconSize ?? '')}`])}>
            {iconContent}
          </span>
        </Fragment>
      )
    }

    return buildComponent(content)
  }
)

ButtonLegacy.propTypes = {
  /** Label of button. */
  children: PropTypes.node,
  /** @ignore Prop drilled for styling behavior. */
  className: PropTypes.string,
  /** Button disabled if set to <b>true</b>. */
  disabled: PropTypes.bool,
  /** Whether or not the onClick event should be triggered even though the button is disabled. */
  forceClick: PropTypes.bool,
  /** Format style of button. string if single format, array of strings if combined. <br />
   Formats are: <code>line</code>, <code>full</code>, <code>squared</code>, <code>old</code> (will be removed). */
  format: PropTypes.any,
  /** When specified, button will render a link. */
  href: PropTypes.string,
  /** @deprecated (should use iconProps instead) Icon name. Cf: [Icon](#icon) */
  icon: PropTypes.string,
  /** Position of icon: <code>left</code> or <code>right</code>. */
  iconPosition: PropTypes.string,
  /** Props of atom Icon. */
  iconProps: PropTypes.object,
  /** @deprecated (should use iconProps instead) Size of icon: <code>small</code>, <code>medium</code> or <code>large</code>. */
  iconSize: PropTypes.string,
  /** When `true`, displays a spinner instead of text content */
  isLoading: PropTypes.bool,
  /** @deprecated (should be children instead) Label of button. */
  label: PropTypes.string,
  /** Triggered on click. */
  onClick: PropTypes.func,
  /** If true, the button scales on hover */
  scaleOnHover: PropTypes.bool,
  /** Choose one of <b>x-small</b>, <b>small</b>, <b>medium</b>, or <b>large</b>. */
  size: PropTypes.string,
  /** Sets target of a link. */
  target: PropTypes.string,
  /** Variant colors of label. */
  textVariant: PropTypes.string,
  /** Type of button. Must be <code>submit</code> if used in form.*/
  type: PropTypes.string,
  /** Variant colors of button. */
  variant: PropTypes.string,
}

ButtonLegacy.defaultProps = {
  disabled: false,
  forceClick: false,
  format: '',
  icon: null,
  iconPosition: 'left',
  iconSize: 'medium',
  label: '',
  onClick: () => {},
  size: 'medium',
  textVariant: '',
  type: 'button',
  variant: 'primary-1',
}

ButtonLegacy.displayName = 'ButtonLegacy'

export default ButtonLegacy
