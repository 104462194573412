import { CardProps, CardTypeProperty } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard.types'

import { EBOOK_URL } from '@pretto/config-front/constants'
import { Download, Eye, File, MagnifyingGlassLeft, Pen, Phone, UserBook } from '@pretto/picto'

export const CARDS: Record<CardTypeProperty, CardProps> = {
  expert: {
    action: {
      links: [
        {
          href: '/simulation/subscribe?kind=dashboard_appointment&origin=dashboard',
          label: 'Consultation gratuite',
          picto: Phone,
        },
      ],
      type: 'link',
    },
    description: 'Affinez votre projet avec votre expert crédit dédié et bénéficiez de conseils personnalisés.',
    image: 'v1637685716/zen/static-assets/illustrations/660x660/frame-people-call.svg',
    title: 'Nos experts vous conseillent',
  },
  faq: {
    action: {
      links: [
        {
          href: 'https://faq.pretto.fr/',
          label: 'Consulter la faq',
          picto: Eye,
        },
      ],
      type: 'link',
    },
    description: 'Vous avez des interrogations sur le fonctionnement de Pretto ? La réponse se trouve sûrement ici.',
    image: 'v1637685714/zen/static-assets/illustrations/660x660/frame-man-wondering2.svg',
    title: 'Consultez notre FAQ',
  },
  guide: {
    action: {
      links: [
        {
          href: EBOOK_URL,
          label: 'Télécharger',
          picto: Download,
        },
        {
          href: 'https://www.pretto.fr/guide/',
          label: 'Consulter en ligne',
          picto: UserBook,
        },
      ],
      type: 'link',
    },
    description: 'Tout ce qu’il faut savoir pour maîtriser son projet.\r\nVotre nouveau livre de chevet !',
    image: 'v1637683141/zen/static-assets/illustrations/660x660/hikersAndPanel.svg',
    title: 'Le Guide de l’acheteur',
  },
  newsletter: {
    action: {
      label: 'S’abonner',
      type: 'switch',
    },
    description: '2 fois par mois, un concentré d’infos et de conseils pour éclairer votre achat immobilier.',
    image: 'v1637683131/zen/static-assets/illustrations/660x660/expert-airplane.svg',
    title: 'La Newsletter de Pretto',
  },
  prettoSearch: {
    action: {
      type: 'store',
    },
    description: 'L’agrégateur d’annonces immobilières qui rend plus simple et efficace votre recherche de bien.',
    image: 'v1637684216/zen/static-assets/illustrations/660x660/man-hugHouse.svg',
    title: 'Pretto Search',
  },
  propertySearch: {
    action: {
      links: [
        {
          href: 'https://app.pretto.fr/property-search/alerts',
          label: 'Gérer votre alerte',
          picto: Pen,
        },
      ],
      type: 'link',
    },
    description: 'Vous recherchez toujours le bien de vos rêves ? Gérez et paramétrez votre alerte immobilière.',
    image: 'v1637684214/zen/static-assets/illustrations/660x660/house-magnifyer.svg',
    title: 'Votre recherche de bien',
  },
  purchaseOffer: {
    action: {
      links: [
        {
          href: 'https://app.pretto.fr/purchase-offer',
          label: 'Créer mon offre d’achat',
          picto: File,
        },
      ],
      type: 'link',
    },
    description:
      'Générez facilement votre offre d’achat personnalisée, claire et complète. Votre botte secrète pour convaincre votre vendeur !',
    image: 'v1637683134/zen/static-assets/illustrations/660x660/twoPeoplewithShortList.svg',
    title: 'Créez votre offre d’achat\xA0!',
  },
  rateAlert: {
    action: {
      label: 'Suivre par email',
      type: 'switch',
    },
    description: 'Suivez l’évolution de votre taux par email pour acheter au meilleur moment !',
    image: 'v1637683147/zen/static-assets/illustrations/660x660/oldManSittingAndPercents.svg',
    title: 'Suivre l’évolution de votre taux',
  },
  renegotiation: {
    action: {
      links: [
        {
          href: 'https://pretto-fr.typeform.com/to/gNaE9ohR',
          label: 'Trouver une assurance',
          picto: MagnifyingGlassLeft,
        },
      ],
      type: 'link',
    },
    description: 'Le coût de l’assurance de prêt est l’un des coûts les plus importants de votre crédit.',
    image: 'v1637684800/zen/static-assets/illustrations/660x660/abacus.svg',
    title: 'Renégocier l’assurance de prêt',
  },
}
