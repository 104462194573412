import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { CheckBold } from '@pretto/picto'

import styled from 'styled-components'

export interface CheckboxProps {
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

const Input = styled.input`
  appearance: none;
  border: none;
  border-radius: ${g(1 / 2)};
  background: transparent;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    cursor: pointer;
  }
`

const Box = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1 / 2)};
  border: ${g(1 / 4)} solid ${({ theme }) => theme.colors.neutral3};
  display: inline-flex;
  justify-content: center;
  padding: ${g(1 / 4)};
  pointer-events: none;
  text-align: center;
  z-index: 0;
  ${transition({ propertyName: 'background-color' }, { propertyName: 'border' })};

  ${Input}:enabled:hover + &,
  ${Input}:enabled:focus + &,
  ${Input}:enabled:focus-visible + & {
    border-color: ${({ theme }) => theme.colors.primary2};
  }

  ${Input}:enabled:checked + & {
    background-color: ${({ theme }) => theme.colors.primary2};
    border-color: ${({ theme }) => theme.colors.primary2};
  }
`

const Check = styled(CheckBold)`
  width: ${g(2)};
  height: ${g(2)};
  color: ${({ theme }) => theme.colors.white};
`

const Container = styled.span`
  display: inline-flex;
  position: relative;
`

export const Checkbox: React.FC<CheckboxProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>> = ({
  className,
  isChecked = false,
  onChange,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.checked)
  }

  return (
    <Container className={className}>
      <Input type="checkbox" {...props} checked={isChecked} onChange={handleChange} />

      <Box>
        <Check />
      </Box>
    </Container>
  )
}
