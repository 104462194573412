import { useBreakpoint } from '@pretto/bricks/assets/utility'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { t } from '@pretto/app/src/lib/i18n'
import { TimesBold } from '@pretto/picto'

import { Trans } from 'react-i18next'

import * as S from './LanguageInfo.styles'

export interface LanguageInfoProps {
  onClose: () => void
  onConfirm: () => void
}

export const LanguageInfo: React.FC<LanguageInfoProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onClose,
  onConfirm,
  ...props
}) => {
  const { isMobile } = useBreakpoint()
  const button = useBreakpointToValue<React.ReactNode>(<TimesBold />, { tablet: t('languageInfo.close') })

  const getTranslationSetupLink = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.includes('chrome')) {
      return 'https://support.google.com/chrome/answer/173424?hl=en'
    } else if (userAgent.includes('firefox')) {
      return 'https://support.mozilla.org/en-US/kb/website-translation'
    } else if (userAgent.includes('safari')) {
      return 'https://support.apple.com/guide/safari/translate-a-webpage-ibrw646b2ca2/mac'
    } else if (userAgent.includes('edge')) {
      return 'https://support.microsoft.com/id-id/topic/use-microsoft-translator-in-microsoft-edge-browser-4ad1c6cb-01a4-4227-be9d-a81e127fcb0b'
    }
    return 'https://www.google.com/search?q=how+to+set+up+automatic+translation+in+browser'
  }

  return (
    <S.Component {...props}>
      <S.Header>
        <S.Title>{t('languageInfo.title')}</S.Title>
        <S.CloseButton aria-label={t('languageInfo.close')} onClick={onClose} type="button">
          {button}
        </S.CloseButton>
      </S.Header>
      {<Trans i18nKey="languageInfo.message" components={{ DocLink: <S.Link href={getTranslationSetupLink()} /> }} />}
      <S.Button onClick={onConfirm} type="button">
        {isMobile ? t('languageInfo.buttonShort') : t('languageInfo.buttonLong')}
      </S.Button>
    </S.Component>
  )
}
