import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: initial;
    ${({ $size }) =>
      $size === 'medium' &&
      css`
        height: 80vh;
      `};
  }
`
export const NavBarContainer = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 1;
  margin-top: auto;
  padding: ${g(2)};
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: inherit;
    padding: ${g(3)};

    ${({ $isSticky }) =>
      !$isSticky &&
      css`
        position: initial;
      `}
  }
`

export const Divider = styled.div`
  text-align: center;
  margin: ${g(1)} auto;
`

export const Button = styled(ButtonComponent)`
  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
    `}
`

export const OptionCTA = styled.div`
  text-align: center;
  margin-bottom: ${g(6.5)};
`
