import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'

import * as C from './SubMenu.module.css'

const SubMenu = ({ active, actions, variant, format }) => {
  return (
    <div className={C.subMenuWrapper}>
      <Wrapper>
        <div
          className={classnames(
            C.subMenu,
            C[`subMenu${pascalcase(variant ?? '')}`],
            C[`subMenu${pascalcase(format ?? '')}`]
          )}
        >
          {actions.map((action, index) => {
            const { label, onClick, icon } = action
            const buttonProps = { children: label, iconProps: icon && { name: icon, variant } }
            const isActive = active === index
            return (
              <div
                className={classnames(C.subMenuItem, { [C.subMenuItemActive]: isActive })}
                key={label}
                onClick={onClick}
              >
                <ButtonLegacy format="flat" {...buttonProps} />
              </div>
            )
          })}
        </div>
      </Wrapper>
    </div>
  )
}

SubMenu.propTypes = {
  /** Which action is active. You need to specify an index. */
  active: PropTypes.number,
  /** List of buttons. */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func,
      icon: PropTypes.string,
    })
  ).isRequired,
  /** Variant of label (hover) and icon (permanent). */
  variant: PropTypes.string,
  /** Format of items. */
  format: PropTypes.oneOf(['align-left', 'align-right', 'full']),
}

SubMenu.defaultProps = {
  variant: 'primary-1',
  format: 'full',
}

export default SubMenu
