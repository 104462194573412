export const isPropertyKeyIn = <T extends Record<string, unknown>>(object: T, key: PropertyKey): key is keyof T =>
  key in object

export const getValue = <
  T extends Record<string, unknown>,
  K extends PropertyKey,
  O extends K extends keyof T ? T[K] : undefined,
>(
  object: T,
  key: K
): O => (isPropertyKeyIn(object, key) ? object[key] : undefined) as O
