export const menuVersions = {
  leadAcceptedMenu: [
    { items: ['dashboard', 'documents'] },
    { items: ['account', 'subscriptions', 'sponsoring'] },
    { items: ['phone', 'email', 'appointment'], type: 'expert' },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadMenuHighPotential: [
    { items: ['simulation', 'dashboard', 'folder'] },
    { items: ['account', 'propertySearch', 'subscriptions', 'sponsoring'] },
    { items: ['phone', 'email', 'appointment'], type: 'expert' },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadMenuLowPotential: [
    { items: ['simulation', 'dashboard', 'folder'] },
    { items: ['account', 'propertySearch', 'subscriptions', 'sponsoring'] },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadMenuNoExpert: [
    { items: ['simulation', 'dashboard', 'folder'] },
    { items: ['account', 'propertySearch', 'subscriptions', 'sponsoring'] },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadNoOnboardingMenuNoExpert: [
    { items: ['simulation', 'dashboard'] },
    { items: ['account', 'propertySearch', 'subscriptions', 'sponsoring'] },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadNoOnboardingMenuHighPotential: [
    { items: ['simulation', 'dashboard'] },
    { items: ['account', 'propertySearch', 'subscriptions', 'sponsoring'] },
    { items: ['phone', 'email', 'appointment'], type: 'expert' },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
  leadSignatureMenu: [
    { items: ['dashboard'] },
    { items: ['account', 'subscriptions', 'sponsoring'] },
    { items: ['support'] },
    { items: ['legaleNotices', 'gtcs', 'faq', 'personnalData', 'disconnect'] },
  ],
  preLeadMenu: [
    { items: ['dashboard'] },
    { items: ['account', 'propertySearch', 'subscriptions'] },
    { items: ['guideBook'] },
    { items: ['legaleNotices', 'gtcs', 'faq', 'support', 'personnalData', 'disconnect'] },
  ],
}
