import { g } from '@pretto/bricks/components/layout'

import { CompletionPrompt } from '@pretto/zen/reveal/atoms/completionPrompt/CompletionPrompt'

import styled from 'styled-components'

export const Prompt = styled(CompletionPrompt)`
  display: inline-block;
  margin: 0 ${g(0.5)};
  line-height: ${g(3)};
`
