import PropTypes from 'prop-types'
import { createContext, forwardRef, useContext } from 'react'

const NavigationLink = forwardRef(({ children, ...props }, ref) => (
  <a {...props} ref={ref}>
    {children}
  </a>
))

NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
}

NavigationLink.displayName = 'NavigationLink'

export const NavigationLinkContext = createContext({ linkComponent: NavigationLink, location: null })

const NavigationLinkProvider = ({ children, ...props }) => (
  <NavigationLinkContext.Provider value={props}>{children}</NavigationLinkContext.Provider>
)

NavigationLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useNavigationLink = () => useContext(NavigationLinkContext)

export default NavigationLinkProvider
