import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'
import * as filters from '@pretto/app-core/lib/filters'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { useSubscribe } from '@pretto/app/src/Subscribe/lib/useSubscribe'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ASSIGN_PROJECT, CREATE_ACCOUNT, SUBSCRIBE_RATE_ALERT } from '@pretto/app/src/apollo'
import { EMPTY_FUNCTION } from '@pretto/app/src/lib/constants'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useNewsletter } from '@pretto/app/src/services/newsletter'

import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

const encoder = filters.applyFilters([
  filters.applyFilter('email', filters.formatEncodeEmail),
  filters.applyFilter('firstname', filters.formatEncodeProprify),
  filters.applyFilter('lastname', filters.formatEncodeProprify),
  filters.applyFilter('phone', filters.formatEncodePhone),
])

const encodeEmail = email => filters.formatEncodeEmail(email)

const Subscribe = ({ children }) => {
  const { refetch, handleSignIn, requestGoogleConnect, signIn } = useAuth()
  const { projectID } = useUser()
  const [createAccount] = useMutation(CREATE_ACCOUNT)
  const [assign] = useMutation(ASSIGN_PROJECT)
  const [subscribeRate] = useMutation(SUBSCRIBE_RATE_ALERT)

  const { subscribe } = useNewsletter()

  const { apply } = useSubscribe()

  const trackAction = useTracking()

  const handleApply = async (values, flow) => {
    const encodedValues = await encoder(values)

    await apply(encodedValues, flow)

    refetch()
  }

  const subscribeNewsletterImmo = async () => {
    const isSubscribed = await subscribe()

    if (isSubscribed) {
      trackAction('NEWSLETTER_SUBSCRIBED', { newsletter_subscribe_source: 'signup', newsletter_subscribe_type: 'immo' })
    }
  }

  const subscribeNewsletterRate = async () => {
    const response = await subscribeRate()
    const { error } = response.data.subscribe_rate_alert
    if (!error)
      trackAction('NEWSLETTER_SUBSCRIBED', { newsletter_subscribe_source: 'signup', newsletter_subscribe_type: 'rate' })
  }

  const assignProject = async () => {
    try {
      await assign({ variables: { id: projectID } })
    } catch (error) {
      return EMPTY_FUNCTION
    }
  }

  const assignFunctions = {
    project: assignProject,
  }

  const getAssignFunction = assignment => {
    if (assignment) return assignFunctions[assignment]

    return false
  }

  const handleSignUp = async ({
    assignment,
    email,
    kind,
    redirect = EMPTY_FUNCTION,
    source,
    subscribeNewsletter,
    subscribePropertySearchAlert,
    subscribeRateAlert,
  }) => {
    const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
    const attributionCookieLastClick = getAttributionCookieLastClickValue()
    const encodedEmail = encodeEmail(email)
    const visitorId = await getVisitorId()

    const variables = {
      attributionCookieFirstClick,
      attributionCookieLastClick,
      email: encodedEmail,
      source: `app_${source}`,
      visitorId,
    }

    const response = await createAccount({ variables })
    const { token, error } = response.data.create_account

    if (error) {
      return error
    }

    if (token) {
      trackAction('SIGNED_UP', {
        signup_assign_project: !!assignment,
        signup_kind: kind,
        signup_newsletter_immo: !!subscribeNewsletter,
        signup_newsletter_taux: !!subscribeRateAlert,
        signup_property_search_alert: !!subscribePropertySearchAlert,
      })

      const asyncFunctions = [
        getAssignFunction(assignment),
        subscribeNewsletter && subscribeNewsletterImmo,
        subscribeRateAlert && subscribeNewsletterRate,
      ]

      await signIn({ accessToken: token }, redirect, asyncFunctions)
    }
  }

  const handleGoogleConnect = ({ subscribeNewsletter, subscribeRateAlert }) => {
    requestGoogleConnect({
      projectID,
      subscribeNewsletter,
      subscribeRateAlert,
    })
  }

  return children({
    onApply: handleApply,
    handleSignIn,
    onGoogleConnect: handleGoogleConnect,
    onSignUp: handleSignUp,
    projectID,
  })
}

Subscribe.propTypes = {
  children: PropTypes.func.isRequired,
}

export default Subscribe
