import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'

import { useTracking } from '@pretto/app/src/lib/tracking'

import { DefaultContext } from '@apollo/client'

import { useApplyMutation } from './apply.gateway.graphql'
import { fetchCurrentValue } from './fetchCurrentValue'
import { getUtms } from './getUtms'

interface Values {
  firstName: string
  lastName: string
  phone: string
}

export const useSubscribe = () => {
  const [apply] = useApplyMutation()

  const trackAction = useTracking()

  return {
    async apply(values: Values, flow: 'capacity' | 'purchase' | 'renegotiation', context?: DefaultContext) {
      const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
      const attributionCookieLastClick = getAttributionCookieLastClickValue()
      const utm = await getUtms()

      const { data } = await apply({
        context,
        variables: {
          ...values,
          abTest: 'clients_flow_202211_maturity',
          attributionCookieFirstClick,
          attributionCookieLastClick,
          utm,
        },
      })

      if (data?.apply?.error) {
        throw new Error(data.apply.error)
      }

      const value = fetchCurrentValue()

      trackAction('SUBSCRIBE', { value, revenue: value, currency: 'EUR', subscribe_simulation_kind: flow })
    },
  }
}
