import * as filters from '@pretto/app-core/lib/filters'

import { TwoPeople } from '@pretto/picto'

import omit from 'lodash/omit'

// Config
const marriedTogetherLabels = {
  common_law_union: 'en union libre',
  married: 'mariés',
  PACS: 'pacsés',
}

const civilStatusOptions = [
  { label: 'Marié(e)', value: 'married' },
  { label: 'Divorcé(e)', value: 'divorced' },
  { label: 'PACSé(e)', value: 'PACS' },
  { label: 'En union libre', value: 'common_law_union' },
  { label: 'Célibataire', value: 'single' },
  { label: 'Veuf(ve)', value: 'widower' },
]

const maritalStatusOptions = [
  { label: 'Pas de contrat de mariage (communauté réduite aux acquêts)', value: 'no_contract' },
  { label: 'Participation aux acquêts', value: 'acquired_property' },
  { label: 'Séparation de biens', value: 'separated' },
  { label: 'Communauté universelle', value: 'universal' },
]

// Fields
const mortgagorCivilStatus = {
  fieldProps: {
    options: civilStatusOptions,
  },
  label: (values, data, { accounts }) => `Situation maritale de ${accounts[0].name}`,
  name: 'profile.mortgagors[0].civil_status',
  type: 'select',
}

const mortgagorMaritalStatus = {
  condition: values => values['profile.mortgagors[0].civil_status'] === 'married',
  fieldProps: {
    options: maritalStatusOptions,
  },
  label: 'Régime matrimonial',
  name: 'mortgagor_marital_status',
  type: 'select',
}

const marriedTogether = {
  condition: (values, data, { hasComortgagor }) =>
    hasComortgagor &&
    (values['profile.mortgagors[0].civil_status'] === 'married' ||
      values['profile.mortgagors[0].civil_status'] === 'PACS' ||
      values['profile.mortgagors[0].civil_status'] === 'common_law_union'),
  fieldProps: {
    options: [
      { id: 'm1t', label: 'Oui', value: 'true' },
      { id: 'm1f', label: 'Non', value: 'false' },
    ],
  },
  label: values =>
    `Les emprunteurs sont-ils ${marriedTogetherLabels[values['profile.mortgagors[0].civil_status']]} ensemble ?`,
  name: 'profile.married_together',
  type: 'radio',
}

const comortgagorCivilStatus = {
  condition: (values, data, { hasComortgagor }) =>
    hasComortgagor &&
    (values['profile.married_together'] === 'false' ||
      values['profile.mortgagors[0].civil_status'] === 'divorced' ||
      values['profile.mortgagors[0].civil_status'] === 'single' ||
      values['profile.mortgagors[0].civil_status'] === 'widower'),
  fieldProps: {
    options: civilStatusOptions,
  },
  label: (values, data, { accounts }) => `Situation maritale de ${accounts[1].name}`,
  name: 'profile.mortgagors[1].civil_status',
  type: 'select',
}

const comortgagorMaritalStatus = {
  condition: values =>
    values['profile.mortgagors[1].civil_status'] === 'married' &&
    (values['profile.married_together'] === 'false' ||
      ['divorced', 'single', 'widower'].indexOf(values['profile.mortgagors[0].civil_status']) >= 0),
  fieldProps: {
    options: maritalStatusOptions,
  },
  label: 'Régime matrimonial',
  name: 'comortgagor_marital_status',
  type: 'select',
}

const liveTogether = {
  condition: (values, data, { hasComortgagor }) => hasComortgagor,
  fieldProps: {
    options: [
      { id: 'l1t', label: 'Oui', value: 'true' },
      { id: 'l1f', label: 'Non', value: 'false' },
    ],
  },
  label: 'Les emprunteurs habitent-ils ensemble ?',
  name: 'profile.live_together',
  type: 'radio',
}

const children = {
  fieldProps: {
    placeholder: '0',
  },
  label: 'Nombre d’enfant(s) à charge',
  name: 'profile.children',
  type: 'numberfield',
}

// Sections
const generalSection = {
  fields: [
    mortgagorCivilStatus,
    mortgagorMaritalStatus,
    marriedTogether,
    comortgagorCivilStatus,
    comortgagorMaritalStatus,
    liveTogether,
    children,
  ],
  title: 'Votre situation',
}

// Pages
export const defaultPage = {
  decoder: filters.applyFilters([
    filters.applyFilter(
      'mortgagor_marital_status',
      (value, values) => {
        const civilStatus = values['profile.mortgagors[0].civil_status']
        return civilStatus && civilStatus.includes('married') ? civilStatus.replace('married_', '') : null
      },
      false
    ),
    filters.applyFilter('profile.mortgagors[0].civil_status', value =>
      value && value.includes('married') ? 'married' : value
    ),
    filters.applyFilter(
      'comortgagor_marital_status',
      (value, values) => {
        const civilStatus = values['profile.mortgagors[1].civil_status']
        return civilStatus && civilStatus.includes('married') ? civilStatus.replace('married_', '') : null
      },
      false
    ),
    filters.applyFilter('profile.mortgagors[1].civil_status', value =>
      value && value.includes('married') ? 'married' : value
    ),
  ]),
  encoder: (differedValues, data, values) =>
    filters.applyFilters([
      filters.applyFilter(`profile.mortgagors[0].civil_status`, value =>
        values.mortgagor_marital_status ? `married_${values.mortgagor_marital_status}` : value
      ),
      filters.applyFilter(`profile.mortgagors[1].civil_status`, value =>
        values.comortgagor_marital_status ? `married_${values.comortgagor_marital_status}` : value
      ),
    ])(omit(differedValues, ['mortgagor_marital_status', 'comortgagor_marital_status'])),
  sections: [generalSection],
  title: 'Foyer',
}

const step = {
  documentsParameters: { category: 'general' },
  pages: [defaultPage],
  sideBarParameters: {
    label: 'Foyer',
    picto: TwoPeople,
    sideBarOrder: 2,
    url: '/application/general',
  },
}

export default step
