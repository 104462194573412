import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Picto as PictoType } from '@pretto/picto'

import styled, { css } from 'styled-components'

export interface ButtonOutlineProps {
  children: string
  state?: 'default' | 'active' | 'inactive'
  picto?: PictoType
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ButtonOutline = ({ children, picto, onClick, state = 'default', ...props }: ButtonOutlineProps) => (
  <Button onClick={onClick} $state={state} {...props}>
    {picto && <Picto as={picto} />}

    <Label>{children}</Label>
  </Button>
)

interface ButtonProps {
  $state: ButtonOutlineProps['state']
}

const Picto = styled.svg`
  margin-bottom: ${g(2)};
`
const Button = styled.button<ButtonProps>`
  ${typo.bodyBook16};
  border: 0;
  color: ${({ theme }) => theme.colors.neutral1};
  appearance: none;
  cursor: pointer;
  border-radius: ${g(1)};
  padding: ${g(2)};
  outline: 1px solid ${({ theme }) => theme.colors.neutral3};
  background-color: ${({ theme }) => theme.colors.white};
  transition: outline 150ms ease-in-out, background-color 150ms ease-in-out;

  &:focus-visible {
    outline-style: dashed;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }

  ${({ theme, $state }) => {
    switch ($state) {
      default:
      case 'default':
        return css`
          &:hover {
            outline: 2px solid ${theme.colors.primary2};
          }
        `

      case 'active':
        return css`
          outline: 2px solid ${theme.colors.primary2};
        `

      case 'inactive':
        return css`
          color: ${theme.colors.neutral2};

          &:hover {
            color: ${theme.colors.neutral1};
          }
        `
    }
  }}
`

const Label = styled.div``
