import { g } from '@pretto/bricks/components/layout'
import Placeholder from '@pretto/bricks/components/loading/Placeholder'

import styled from 'styled-components'

import * as S from './styles'

const Circle = styled(Placeholder)`
  width: ${g(5)};
  height: ${g(5)};
  border-radius: 100%;
  margin-right: ${g(2)};
`

const Text = styled(Placeholder)`
  width: ${g(30)};
`

const Item = styled.div`
  display: flex;
  align-items: center;
`

const Skeleton = () => {
  return (
    <S.Malus>
      <Item>
        <Circle />
        <Text />
      </Item>
      <Item>
        <Circle />
        <Text />
      </Item>
    </S.Malus>
  )
}

export default Skeleton
