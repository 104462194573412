import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Text from '@pretto/bricks/components/typography/Text'
import { Desktop } from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as C from './DefaultTemplate.module.css'

const DefaultTemplate = ({ children, title, description }) => {
  return (
    <div className={C.templateDefault}>
      <Wrapper full>
        <header>
          <Desktop>
            <div className={C.templateDefaultHeading}>
              <Heading format="center">{title}</Heading>
            </div>
          </Desktop>
          <div className={C.templateDefaultDescription}>
            <Text format="center">{description}</Text>
          </div>
        </header>
        {children}
      </Wrapper>
    </div>
  )
}

DefaultTemplate.propTypes = {
  children: PropTypes.node,
  hasOptions: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default DefaultTemplate
