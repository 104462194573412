import DirectionsClientCard from '@pretto/bricks/shared/components/DirectionsClientCard'
import DirectionsSimulationCard from '@pretto/bricks/shared/components/DirectionsSimulationCard'
import AlertTemplate from '@pretto/bricks/shared/templates/AlertTemplate'

import PropTypes from 'prop-types'

import * as C from './DirectionsPage.module.css'

const DirectionsPage = ({ title, description, alert, alertKind, clientCardProps, simulationCardProps }) => {
  return (
    <div>
      <AlertTemplate title={title} description={description} alert={alert} alertKind={alertKind}>
        <div className={C.directionsPageCards}>
          <div className={C.directionsPageCard}>
            <DirectionsSimulationCard {...simulationCardProps} />
          </div>
          <div className={C.directionsPageCard}>
            <DirectionsClientCard {...clientCardProps} />
          </div>
        </div>
      </AlertTemplate>
    </div>
  )
}

DirectionsPage.propTypes = {
  description: PropTypes.node,
  title: PropTypes.string,
  alert: PropTypes.node,
  alertKind: PropTypes.string,
  clientCardProps: PropTypes.object,
  simulationCardProps: PropTypes.object,
}
DirectionsPage.defaultProps = {
  alertKind: 'error',
}

export default DirectionsPage
