import Directions from '@pretto/bricks/shared/pages/DirectionsPage'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'

import { noAccountPage } from '../../Auth/config'
import Header from '../../SharedContainers/Header'
import { sponsorshipPage } from '../../Sponsorship/config'

import { clientCard, simulationCard } from './config'

const PAGE_PROPS = {
  'no-account': noAccountPage,
  sponsorship: sponsorshipPage,
}
export default class DirectionsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    kind: PropTypes.oneOf(Object.keys(PAGE_PROPS)),
  }
  static defaultProps = {
    kind: 'no-account',
  }
  handleClickSimulation = () => this.props.history.push('/')
  handleClickClient = () => window.open('https://www.pretto.fr/contact/', '_blank')

  render() {
    const props = {
      ...PAGE_PROPS[this.props.kind],
      simulationCardProps: {
        ...simulationCard,
        onClick: this.handleClickSimulation,
      },
      clientCardProps: {
        ...clientCard,
        onClick: this.handleClickClient,
      },
    }
    return (
      <Fragment>
        <Header navigationItemsList={[NAV_ITEMS.faq]} />
        <Directions {...props} />
      </Fragment>
    )
  }
}
