import BookingEmbeddedPageComponent from '@pretto/bricks/app/booking/pages/BookingEmbeddedPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import temporal from '@pretto/bricks/core/utility/temporal'

import { useMutation, useQuery } from '@apollo/client'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'

import { useUser } from '../../../User/Containers/UserProvider'
import { BOOKING, CANCEL_APPOINTMENT } from '../../../apollo'
import duration from '../../../lib/duration'
import BookingWidget from '../BookingWidget'

const handleBooked = () => reload()

const reload = () => window.location.reload(false)

const BookingEmbeddedPage = () => {
  const {
    advisor: { name: advisorName },
  } = useUser()

  const [isMutating, setIsMutating] = useState(false)
  const [isCancelDisabled, setIsCancelDisabled] = useState(false)

  const { data, loading } = useQuery(BOOKING)
  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT)

  if (isMutating || loading) {
    return <SpinnerLegacy overlay />
  }

  const handleBooking = () => {
    setIsCancelDisabled(true)
  }

  const handleCancel = async () => {
    setIsMutating(true)
    await cancelAppointment()
    reload()
  }

  const { next_booking: appointment } = data
  const appointmentSummaryProps = appointment
    ? {
        advisorName,
        date: capitalize(temporal(appointment.start).format('dddd LL [à] HH[h]mm')),
        duration: duration(temporal(appointment.end).diff(appointment.start, 'minutes')).toString(),
        isCancelDisabled,
        onCancel: handleCancel,
      }
    : null

  return (
    <BookingEmbeddedPageComponent appointmentSummaryProps={appointmentSummaryProps}>
      <BookingWidget delayBeforeAppointment={[30, 'minute']} onBooked={handleBooked} onBooking={handleBooking} />
    </BookingEmbeddedPageComponent>
  )
}

export default BookingEmbeddedPage
