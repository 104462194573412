import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { SIMULATION_DURATION } from '@pretto/app/src/config/reassurance'

import styled from 'styled-components'

const Underline = styled(BaseTypo).attrs({ forwardedAs: 'span' })`
  text-decoration: underline;
`

export const PROGRESS = {
  found: 'found',
  searching: 'searching',
}

export const PROJECT_KIND = {
  purchase: 'purchase',
  renegotiation: 'renegotiation',
}

export const STATUS = {
  budgetDefault: 'budget_default',
  budgetError: 'budget_error',
  budgetSuccess: 'budget_success',
  noSimuNoSearchAlert: 'no_simu_no_search_alert',
  noSimuWithSearchAlert: 'no_simu_with_search_alert',
  purchaseDefault: 'purchase_default',
  purchaseError: 'purchase_error',
  purchaseSuccess: 'purchase_success',
  renegotiationDefault: 'renegotiationDefault',
  renegotiationError: 'renegotiation_error',
  renegotiationSuccess: 'renegotiation_success',
  // user calculated capacity and now declares he's found his house
  transitionFound: 'transitionFound',
}

export const STEPS_NAMES = {
  accountCreated: 'accountCreated',
  application: 'application',
  budget: 'budget',
  propertySearch: 'propertySearch',
  simulation: 'simulation',
}

export const BANNER_CONFIGS = {
  [STATUS.budgetSuccess]: {
    buttonText: 'Complétez vos informations',
    description: (
      <>
        Vérifiez vos informations avec un expert Pretto et <strong>obtenez gratuitement</strong> une{' '}
        <Underline>attestation de financement</Underline>.
      </>
    ),
    href: '/simulation/subscribe?kind=dashboard_next_step&origin=dashboard',
  },
  [STATUS.noSimuWithSearchAlert]: {
    buttonText: 'Calculez votre budget',
    description: `Estimez votre capacité d’achat en seulement ${SIMULATION_DURATION.toString()} minutes avec notre simulateur, le plus précis du marché !`,
    href: '/project/capacity/introduction',
    type: 'action',
  },
  [STATUS.purchaseSuccess]: {
    buttonText: 'Complétez vos informations',
    description:
      'Vérifiez vos informations avec un Expert Pretto pour valider votre projet immobilier gratuitement et sans engagement.',
    href: '/simulation/subscribe?kind=dashboard_next_step&origin=dashboard',
  },
  [STATUS.renegotiationDefault]: {
    buttonText: 'Simulez votre prêt',
    description: `Simulez votre rachat ou renégociation de prêt avec notre simulateur et obtenez le résultat le plus fiable du marché en ${SIMULATION_DURATION.toString()} minutes.`,
    href: '/project/renegotiation/introduction',
    type: 'action',
  },
  [STATUS.renegotiationSuccess]: {
    buttonText: 'Complétez vos informations',
    description:
      'Vérifiez vos informations avec un Expert Pretto pour valider votre projet de renégociation de prêt gratuitement et sans engagement.',
    href: '/simulation/subscribe?kind=dashboard_next_step&origin=dashboard',
  },
  [STATUS.transitionFound]: {
    buttonText: 'Calculez votre taux',
    description:
      'Indiquez le prix de votre bien pour obtenir une estimation de votre taux et comparer les offres de prêt.',
    // TODO: replace by /purchase/property/good when land, construction and good routes are merged
    href: '/project/purchase/introduction',
    type: 'action',
  },
}
