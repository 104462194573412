import PropTypes from 'prop-types'

import * as S from './styles'

const ButtonBackInline = ({ href, isDisabled, label, onClick, ...props }) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    onClick(event, ...args)
  }

  return (
    <S.Button $isDisabled={isDisabled} href={href} onClick={handleClick} {...props}>
      <S.Arrow />
      {label}
    </S.Button>
  )
}

ButtonBackInline.propTypes = {
  /** href of the button when it is a link. */
  href: PropTypes.string,
  /** Disabled state. */
  isDisabled: PropTypes.bool,
  /** Text of button */
  label: PropTypes.string.isRequired,
  /** onClick Function. */
  onClick: PropTypes.func,
}

ButtonBackInline.defaultProps = {
  isDisabled: false,
  onClick: () => {},
}

export default ButtonBackInline
