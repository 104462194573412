import * as S from './Step.styles'

export type State = 'done' | 'idle' | 'pending'

export interface StepProps {
  content?: string | null
  iconState: State
  stepState: State
  title: string
}

export const Step: React.FC<StepProps & React.HTMLAttributes<HTMLDivElement>> = ({
  iconState,
  stepState,
  content,
  title,
  ...props
}) => (
  <S.Step {...props}>
    <S.Bullet $iconState={iconState}>{iconState === 'done' && <S.BulletIcon />}</S.Bullet>

    <S.Card $stepState={stepState}>
      <S.CardTitle $stepState={stepState}>{title}</S.CardTitle>
      {content && <S.CardContent $stepState={stepState}>{content}</S.CardContent>}
    </S.Card>
  </S.Step>
)
