import { Edge, Graph, Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'
import * as v from '@pretto/app/src/Sentences/v2/lib/validators/context'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

// Introduction
const structureRoute = new Route('structure', '/project/capacity/introduction/structure')
const propertyUsageRoute = new Route('propertyUsage', '/project/capacity/introduction/propertyUsage')
const propertyTypeRoute = new Route('propertyType', '/project/capacity/introduction/propertyType')
const sciTypeRoute = new Route('sciType', '/project/capacity/introduction/sciType')
const sciAssociationRoute = new Route('sciAssociation', '/project/capacity/introduction/sciAssociation')

export const capacityGraph = new Graph('capacityGraph', structureRoute)

capacityGraph.addRoute(propertyUsageRoute)
capacityGraph.addRoute(propertyTypeRoute)
capacityGraph.addRoute(sciTypeRoute)
capacityGraph.addRoute(sciAssociationRoute)

capacityGraph.addEdge(new Edge(structureRoute, sciTypeRoute, v.isSCI))
capacityGraph.addEdge(new Edge(sciTypeRoute, sciAssociationRoute, v.isIR))
capacityGraph.addEdge(new Edge(sciAssociationRoute, propertyUsageRoute, v.isAssociationSimple))
capacityGraph.addEdge(new Edge(structureRoute, propertyUsageRoute, allPass([v.hasStructure, v.isNotSCI])))
capacityGraph.addEdge(new Edge(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route('ageChildren', '/project/capacity/lodging/ageChildren')
const liveTogetherRoute = new Route('liveTogether', '/project/capacity/lodging/liveTogether')
const addressRoute = new Route('address', '/project/capacity/lodging/address')
const situationRoute = new Route('situation', '/project/capacity/lodging/situation')

capacityGraph.addRoute(ageChildrenRoute)
capacityGraph.addRoute(liveTogetherRoute)
capacityGraph.addRoute(addressRoute)
capacityGraph.addRoute(situationRoute)

capacityGraph.addEdge(new Edge(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

capacityGraph.addEdge(
  new Edge(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

capacityGraph.addEdge(new Edge(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo])))
capacityGraph.addEdge(new Edge(liveTogetherRoute, addressRoute, v.hasLiveTogetherCompleted))
capacityGraph.addEdge(new Edge(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route('expatriate', '/project/capacity/occupation/expatriate')
const sectorRoute = new Route('sector', '/project/capacity/occupation/sector')

capacityGraph.addRoute(expatriateRoute)
capacityGraph.addRoute(sectorRoute)

capacityGraph.addEdge(new Edge(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled])))
capacityGraph.addEdge(new Edge(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale])))

// Income
const salaryRoute = new Route('salary', '/project/capacity/income/salary')
const bonusRoute = new Route('bonus', '/project/capacity/income/bonus')
const revenueRoute = new Route('revenue', '/project/capacity/income/revenue')

capacityGraph.addRoute(salaryRoute)
capacityGraph.addRoute(bonusRoute)
capacityGraph.addRoute(revenueRoute)

capacityGraph.addEdge(new Edge(expatriateRoute, salaryRoute, v.isExpatriateFilled))
capacityGraph.addEdge(new Edge(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled])))
capacityGraph.addEdge(new Edge(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
capacityGraph.addEdge(new Edge(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
capacityGraph.addEdge(new Edge(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route('comortgagorAddress', '/project/capacity/lodging-comortgagor/address')
const comortgagorSituationRoute = new Route('comortgagorSituation', '/project/capacity/lodging-comortgagor/situation')

capacityGraph.addRoute(comortgagorAddressRoute)
capacityGraph.addRoute(comortgagorSituationRoute)

capacityGraph.addEdge(
  new Edge(revenueRoute, comortgagorAddressRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether]))
)

capacityGraph.addEdge(new Edge(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress))

// Comortgagor Occupation
const comortgagorExpatriateRoute = new Route(
  'comortgagorExpatriate',
  '/project/capacity/occupation-comortgagor/expatriate'
)

const comortgagorSectorRoute = new Route('comortgagorSector', '/project/capacity/occupation-comortgagor/sector')

capacityGraph.addRoute(comortgagorExpatriateRoute)
capacityGraph.addRoute(comortgagorSectorRoute)

capacityGraph.addEdge(
  new Edge(
    comortgagorSectorRoute,
    comortgagorExpatriateRoute,
    anyPass([
      allPass([v.isInternational, v.doesLiveTogether]),
      allPass([v.doesNotLiveTogether, v.isComortgagorInternational]),
    ])
  )
)

capacityGraph.addEdge(
  new Edge(revenueRoute, comortgagorSectorRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether]))
)

capacityGraph.addEdge(
  new Edge(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route('comortgagorSalary', '/project/capacity/income-comortgagor/salary')
const comortgagorBonusRoute = new Route('comortgagorBonus', '/project/capacity/income-comortgagor/bonus')
const comortgagorRevenueRoute = new Route('comortgagorRevenue', '/project/capacity/income-comortgagor/revenue')

capacityGraph.addRoute(comortgagorSalaryRoute)
capacityGraph.addRoute(comortgagorBonusRoute)
capacityGraph.addRoute(comortgagorRevenueRoute)

capacityGraph.addEdge(new Edge(comortgagorExpatriateRoute, comortgagorSalaryRoute, v.isCoMortgagorExpatriateFilled))

capacityGraph.addEdge(
  new Edge(
    comortgagorSectorRoute,
    comortgagorSalaryRoute,
    allPass([
      anyPass([
        allPass([v.doesLiveTogether, v.isNotInternational]),
        allPass([v.doesNotLiveTogether, v.isComortgagorNotInternational]),
      ]),
      v.isCoMortgagorOccupationFilled,
    ])
  )
)

capacityGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

capacityGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

capacityGraph.addEdge(new Edge(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled))

// Sale
const estimatedPriceRoute = new Route('estimatedPrice', '/project/capacity/sale/estimatedPrice')
const paidOffRoute = new Route('paidOff', '/project/capacity/sale/paidOff')
const remainingMortgageRoute = new Route('remainingMortgage', '/project/capacity/sale/remainingMortgage')

capacityGraph.addRoute(estimatedPriceRoute)
capacityGraph.addRoute(paidOffRoute)
capacityGraph.addRoute(remainingMortgageRoute)

capacityGraph.addEdge(
  new Edge(revenueRoute, estimatedPriceRoute, allPass([v.hasAnyForSale, v.isRevenueFilled, v.isForSale, v.isSolo]))
)

capacityGraph.addEdge(
  new Edge(
    comortgagorRevenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isForSale, v.isCoMortgagorRevenueFilled])
  )
)

capacityGraph.addEdge(new Edge(estimatedPriceRoute, paidOffRoute, v.hasEstimatedPrice))
capacityGraph.addEdge(new Edge(paidOffRoute, remainingMortgageRoute, v.isNotPaidOff))

// Charges
const creditsRoute = new Route('credits', '/project/capacity/credits/credits')
const childSupportRoute = new Route('childSupport', '/project/capacity/credits/childSupport')

const comortgagorChildSupportRoute = new Route(
  'comortgagorChildSupport',
  '/project/capacity/credits/comortgagorChildSupport'
)

capacityGraph.addRoute(creditsRoute)
capacityGraph.addRoute(childSupportRoute)
capacityGraph.addRoute(comortgagorChildSupportRoute)

capacityGraph.addEdge(new Edge(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo, v.isNotForSale])))
capacityGraph.addEdge(new Edge(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
capacityGraph.addEdge(new Edge(paidOffRoute, creditsRoute, v.isPaidOff))
capacityGraph.addEdge(new Edge(remainingMortgageRoute, creditsRoute, v.hasRemainingMortgage))
capacityGraph.addEdge(new Edge(creditsRoute, childSupportRoute, v.isCreditsFilled))

capacityGraph.addEdge(
  new Edge(childSupportRoute, comortgagorChildSupportRoute, allPass([v.isNotSolo, v.isChildSupportFilled]))
)

// PTZ
const beenOwnerRoute = new Route('beenOwner', '/project/capacity/ptz/beenOwner')
const fiscalIncomeNm2Route = new Route('fiscalIncomeNm2', '/project/capacity/ptz/fiscalIncomeNm2')

capacityGraph.addRoute(beenOwnerRoute)
capacityGraph.addRoute(fiscalIncomeNm2Route)

capacityGraph.addEdge(
  new Edge(childSupportRoute, beenOwnerRoute, allPass([v.isSolo, v.isChildSupportFilled, v.hasPTZ]))
)

capacityGraph.addEdge(
  new Edge(
    comortgagorChildSupportRoute,
    beenOwnerRoute,
    allPass([v.isNotSolo, v.isCoMortgagorChildSupportFilled, v.hasPTZ])
  )
)

capacityGraph.addEdge(new Edge(beenOwnerRoute, fiscalIncomeNm2Route, v.isFirstTimeBuyer))

// Contribution
const contributionRoute = new Route('contribution', '/project/capacity/contribution/contribution')
const savingsRoute = new Route('savings', '/project/capacity/contribution/savings')

capacityGraph.addRoute(contributionRoute)
capacityGraph.addRoute(savingsRoute)

capacityGraph.addEdge(
  new Edge(childSupportRoute, contributionRoute, allPass([v.isSolo, v.isChildSupportFilled, v.hasNotPTZ]))
)

capacityGraph.addEdge(
  new Edge(
    comortgagorChildSupportRoute,
    contributionRoute,
    allPass([v.isNotSolo, v.isCoMortgagorChildSupportFilled, v.hasNotPTZ])
  )
)

capacityGraph.addEdge(new Edge(fiscalIncomeNm2Route, contributionRoute, v.hasFiscalIncomeNM2))
capacityGraph.addEdge(new Edge(contributionRoute, savingsRoute, v.isContributionFilled))

// Pretto Search
const introductionRoute = new Route('introduction', '/project/capacity/property-search/introduction')
const localisationsRoute = new Route('localisations', '/project/capacity/property-search/localisations')
const frequencyRoute = new Route('frequency', '/project/capacity/property-search/frequency')
const criteriaEmailRoute = new Route('criteriaEmail', '/project/capacity/property-search/criteriaEmail')

capacityGraph.addRoute(introductionRoute)
capacityGraph.addRoute(localisationsRoute)
capacityGraph.addRoute(frequencyRoute)
capacityGraph.addRoute(criteriaEmailRoute)

capacityGraph.addEdge(
  new Edge(savingsRoute, introductionRoute, allPass([v.isSavingsFilled, v.isVisiblePropertySearch]))
)

capacityGraph.addEdge(new Edge(introductionRoute, localisationsRoute))
capacityGraph.addEdge(new Edge(localisationsRoute, frequencyRoute, v.hasPropertySearchLocalisations))
capacityGraph.addEdge(new Edge(frequencyRoute, criteriaEmailRoute, v.isPropertySearchAlertWanted))

// Capacity
const capacityRoute = new Route('capacity', '/capacity')

capacityGraph.addRoute(capacityRoute)

capacityGraph.addEdge(new Edge(frequencyRoute, capacityRoute, v.isPropertySearchFilled))
capacityGraph.addEdge(new Edge(criteriaEmailRoute, capacityRoute, v.isPropertySearchFilled))
capacityGraph.addEdge(new Edge(savingsRoute, capacityRoute, allPass([v.isSavingsFilled, v.isPropertySearchFilled])))
