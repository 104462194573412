import PropTypes from 'prop-types'

import * as S from './styles'

const RangeContent = ({ range }) => (
  <S.MainValue>
    {range[0]} - {range[1]}
  </S.MainValue>
)

RangeContent.propTypes = {
  range: PropTypes.array,
}

export default RangeContent
