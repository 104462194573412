import { funcToItem } from '@pretto/bricks/core/utility/formatters'

const resetValuesWithSections = ({ sections }, values, ...args) =>
  sections.reduce(
    (previous, { fields }) => ({
      ...previous,
      ...fields.reduce((previous, { condition = true, name }) => {
        if (!name) {
          return previous
        }

        const fieldCondition = funcToItem(condition, values, ...args)

        if (!fieldCondition) {
          return { ...previous, [name]: null }
        }

        return { ...previous, [name]: values[name] }
      }, {}),
    }),
    {}
  )

export default resetValuesWithSections
