import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Dot = styled.span`
  border-radius: ${g(1 / 2)};
  bottom: 0;
  height: ${g(1)};
  left: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  transform-origin: left center;
  transition: background-color 400ms ease-out, transform 400ms ease-out;
  width: ${g(1)};
`

export const Dots = styled.div`
  position: relative;
`
