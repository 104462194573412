import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { PlusCircle } from '@pretto/picto'

import styled from 'styled-components'

export interface ButtonAddProps {
  children: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}
export const ButtonAdd = ({ children = 'test', onClick, ...props }: ButtonAddProps) => (
  <Container onClick={onClick} {...props}>
    <Label>{children}</Label>

    <Picto />
  </Container>
)

const Container = styled.button`
  ${typo.bodyBook16};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  height: ${g(8)};
  cursor: pointer;

  &:hover,
  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.neutral2};
  }

  &:focus-visible {
    outline: 0;
    border-style: dashed;
  }
`
const Label = styled.span``
const Picto = styled(PlusCircle)`
  cursor: pointer;
  margin-left: ${g(2)};
`
