import ErrorPage from '@pretto/bricks/shared/pages/ServerErrorPage'

import { useEffect, useRef } from 'react'

import Front from '../../../Helpers/frontchat'

const ServerErrorPage = () => {
  const timeout = useRef(null)

  useEffect(() => {
    timeout.current = setTimeout(() => {
      Front('open')
    }, 1000)

    return () => clearTimeout(timeout.current)
  }, [])

  const buttonInlineProps = {
    label: 'Rafraîchir la page',
    onClick: () => window.location.reload(),
  }

  const buttonProps = {
    href: 'https://app.pretto.fr',
    label: 'Revenir à la page d’accueil',
  }

  const hash = window.location.hash.substring(1)
  const isHasedUrl = !!hash

  const onCopyClick = () => navigator.clipboard.writeText(hash)

  const text = (
    <>
      Quelque chose ne s’est pas passé comme prévu.
      <br /> Notre équipe va résoudre le problème dès que possible.
    </>
  )

  const title = 'Oh, mince !'

  const props = {
    buttonInlineProps,
    buttonProps,
    isHasedUrl,
    onCopyClick,
    text,
    title,
  }

  return <ErrorPage {...props} />
}

export default ServerErrorPage
