import { breakpoints, g } from '@pretto/bricks/components/layout'

import { FileList, FileListProps } from '@pretto/zen/dashboard/files/FileList/FileList'
import { FileSectionTitle, FileSectionTitleProps } from '@pretto/zen/dashboard/files/FileSectionTitle/FileSectionTitle'

import styled from 'styled-components'

export interface FileSectionProps {
  subtitle?: FileSectionTitleProps['subtitle']
  title?: FileSectionTitleProps['title']
  items: FileListProps['items']
}

export const FileSection = ({ items, subtitle, title, ...props }: FileSectionProps) => (
  <Section {...props}>
    {title && <FileSectionTitle subtitle={subtitle} title={title} />}

    <FileList items={items} />
  </Section>
)

const Section = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }
`
