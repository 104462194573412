import styled, { css } from 'styled-components'

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  ${({ isLarge }) =>
    isLarge &&
    css`
      height: 2px;
    `}

  ${({ alpha, variant }) => css`
    background-color: ${({ theme }) => theme.legacy.colors[variant]};
    opacity: ${alpha};
  `}
`
