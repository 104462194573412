import { breakpoints, g } from '@pretto/bricks/components/layout'
import PlaceholderComponent from '@pretto/bricks/components/loading/Placeholder'
import Link from '@pretto/bricks/website/utility/Link'

import styled, { css } from 'styled-components'

export const Action = styled.div`
  & + & {
    margin-left: ${g(2)};
  }
`

export const Actions = styled.div`
  display: flex;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`

export const Details = styled.div`
  padding-left: ${g(4)};
`

export const VisioLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary1};
  text-decoration: underline;
`

export const Date = styled.div`
  display: flex;
  margin-bottom: ${g(1)};
`

export const DatePlaceholder = styled(PlaceholderComponent)`
  height: 26px;
  width: 70%;
`

export const Edit = styled.div`
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  flex: 0 0 100%;
  line-height: ${g(2)};
  margin-top: ${g(2)};
  padding-left: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: initial;
    margin-top: 0;
    padding-left: ${g(2)};
  }
`

export const Icon = styled.div`
  margin-right: ${g(2)};
  padding-top: ${g(0.5)};
`

export const Information = styled.div`
  flex-grow: 1;
`

export const Placeholder = styled(PlaceholderComponent)`
  height: ${g(2.5)};
  width: 30%;

  & + & {
    margin-top: ${g(1)};
    width: 35%;
  }
`

export const Summary = styled.div`
  ${({ isHoverable }) =>
    isHoverable &&
    css`
      cursor: pointer;
    `}
`
