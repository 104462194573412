import { formatValuesWithData } from '@pretto/bricks/core/utility/formatters'

import { getAccountId } from '@pretto/app/src/Auth/lib/helpers'
import { menuItems } from '@pretto/app/src/SharedContainers/Header/config/menuItems'
import { menuVersions } from '@pretto/app/src/SharedContainers/Header/config/menuVersions'
import { getIsActive } from '@pretto/app/src/SharedContainers/Header/lib/getIsActive'
import { menuSwitch } from '@pretto/app/src/SharedContainers/Header/lib/menuSwitch'

export const getMenu = ({
  advisor,
  data,
  hasExpert,
  hasFeatureAccess,
  isLowPotential,
  isOnboardingDone,
  isPriceSubmitted,
  onChat,
  pathname,
  status,
  typology,
}) => {
  const menuItemsFormatted = Object.entries(
    formatValuesWithData(menuItems, { advisor, data, getAccountId, hasFeatureAccess, onChat })
  ).reduce((acc, [key, value]) => {
    if (!value) return acc

    return { ...acc, [key]: value }
  }, {})

  const currentMenu = menuSwitch({ hasExpert, isLowPotential, isOnboardingDone, status, typology })

  return menuVersions[currentMenu].reduce((previous, submenu) => {
    const items = submenu.items
      .filter(item => item in menuItemsFormatted)
      .map(itemKey => {
        if (itemKey === 'simulation' && !isPriceSubmitted) {
          return null
        }
        const itemUrl = menuItemsFormatted[itemKey].href
        const isActive = getIsActive({ itemUrl, pathname })
        const item = menuItemsFormatted[itemKey]
        item.isActive = isActive
        return item
      })
      .filter(Boolean)
    const type = submenu.type === 'expert' ? 'expert' : null

    return [...previous, { items, type }]
  }, [])
}
