import { g } from '@pretto/bricks/components/layout'
import DrawerComponent from '@pretto/bricks/components/overlays/Drawer'
import { MenuHead as MenuHeadComponent } from '@pretto/zen/main/menus/MenuHead/MenuHead'
import { MenuItem as MenuItemComponent } from '@pretto/zen/main/menus/MenuItem/MenuItem'
import { MenuItemExpert as MenuItemExpertComponent } from '@pretto/zen/main/menus/MenuItemExpert/MenuItemExpert'

import styled from 'styled-components'

export const MenuContainer = styled(DrawerComponent)`
  width: 100%;
  z-index: 99999999;
`
export const Drawer = styled.div`
  height: 100vh;
  overflow: auto;
  min-width: ${g(40)};
`
export const MenuHead = styled(MenuHeadComponent)`
  margin-bottom: ${g(1)};
`
export const MenuItem = styled(MenuItemComponent)`
  margin-bottom: ${g(1)};
`
export const Divider = styled.div`
  margin: 0px ${g(3)} ${g(1)} ${g(3)};
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral3};
`
export const MenuItemExpert = styled(MenuItemExpertComponent)`
  margin-bottom: ${g(1)};
`
