import PropTypes from 'prop-types'

import * as S from './styles'

const FormField = ({ children, label, labelId }) => (
  <S.Field>
    {label && <S.Label htmlFor={labelId}>{label}</S.Label>}
    <div>{children}</div>
  </S.Field>
)

FormField.propTypes = {
  /** Children can be any Field component.*/
  children: PropTypes.node.isRequired,
  /** Label of Field. */
  label: PropTypes.string,
  /** ID of label for linking to field on on click. */
  labelId: PropTypes.string,
}

export default FormField
