import { CellType } from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'
import { BulletBold, CoinBold, EqualBold, MinusBold, PlusBold, TimesBold } from '@pretto/picto'

interface SymbolProps {
  type: CellType
}

export const Symbol: React.FC<SymbolProps> = ({ type, ...props }) => {
  switch (type) {
    case CellType.Addition:
      return <PlusBold {...props} />

    case CellType.Deduction:
      return <MinusBold {...props} />

    case CellType.Entry:
      return <CoinBold {...props} />

    case CellType.Multiplication:
      return <TimesBold {...props} />

    case CellType.Neutral:
      return <BulletBold {...props} />

    case CellType.Subtotal:
    case CellType.Total:
      return <EqualBold {...props} />

    default:
      return null
  }
}
