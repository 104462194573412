import { FieldCheckbox, Input } from '@pretto/app/src/purchaseOffer/config/types'

import { useState } from 'react'

export const useInputCheckbox = ({ props }: Input): FieldCheckbox => {
  const [isChecked, setIsChecked] = useState(false)

  const handleToggle = () => {
    setIsChecked(prev => !prev)
  }

  return {
    error: false,
    input: {
      props: {
        ...props,
        isChecked,
        onChange: handleToggle,
      },
      type: 'checkbox',
    },
    value: isChecked,
  }
}
