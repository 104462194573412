import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Tag from '@pretto/bricks/components/information/Tag'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import Row from '@pretto/bricks/shared/components/Row'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const LABELS = {
  contribution: 'Apport',
  duration: 'Durée',
  payment: 'Mensualités',
  'purchase.build_price': 'Prix de la construction',
  'purchase.land_price': 'Prix du terrain',
  'purchase.property_price': 'Prix du bien',
  'purchase.works_price': 'Travaux',
}

const TAGS = {
  current: { children: 'projet actuel', variant: 'neutral-1' },
  new: { children: 'nouveau projet', variant: 'accent-1' },
}

const SummaryChanges = ({ type, values, onClick }) => (
  <Card format="line" variant="neutral-1-20">
    <Content>
      <S.Tag>
        <Tag size="small" {...TAGS[type]} />
      </S.Tag>

      <S.Values>
        {values.map(item => {
          const { path, value } = item

          return (
            <S.Row key={path}>
              <Row
                label={
                  <Text size="small" variant="neutral-1-60">
                    {LABELS[path]}
                  </Text>
                }
                value={
                  <SubHeading>
                    {value.toLocaleString('fr')} {path === 'duration' ? 'ans' : '€'}
                  </SubHeading>
                }
              />
            </S.Row>
          )
        })}
      </S.Values>

      <ButtonLegacy format="full" onClick={onClick}>
        Enregistrer
      </ButtonLegacy>
    </Content>
  </Card>
)

SummaryChanges.propTypes = {
  /** Triggered when the <code>Button</code> is clicked. */
  onClick: PropTypes.func,
  /** Type of component. If set to <code>score</code>, value will be rendered as a tag, otherwise a formatted string. */
  type: PropTypes.oneOf(Object.keys(TAGS)).isRequired,
  /** Values of component. Renders Rows components.  */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
}

export default memo(SummaryChanges)
