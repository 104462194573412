import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Timeline as TimelineComponent } from '@pretto/zen/reveal/simulator/timeline/Timeline/Timeline'

import { useSections } from '@pretto/app/src/Sentences/v2/components/Timeline/lib/useSections'
import { useTimeline } from '@pretto/app/src/Sentences/v2/contexts/TimelineContext'
import { TimesBold } from '@pretto/picto'

import { useEffect } from 'react'
import styled from 'styled-components'

const LaptopTimeline = styled(TimelineComponent)``

const Modal = styled(Dialog)`
  ${grid(4)};
  grid-template-rows: auto 1fr;
  padding: ${g(3)} 0;
  background-color: ${({ theme }) => theme.colors.neutral5};
`

const Close = styled(TimesBold)`
  cursor: pointer;
  ${column([5, 1])};
  justify-self: end;
  width: ${g(3)};
  height: ${g(3)};
`

const MobileTimeline = styled(TimelineComponent)`
  height: 100%;
  ${column([2, 4])}
`

export const Timeline: React.FC = props => {
  const { breakpoint } = useBreakpoint()

  const { progress, sections } = useSections()

  const { isOpen, toggle, setProgress } = useTimeline()

  useEffect(() => {
    setProgress(progress)
  }, [progress])

  return (
    <>
      {breakpoint === 'laptop' || breakpoint === 'desktop' ? (
        <LaptopTimeline {...props} sections={sections} />
      ) : (
        <Modal isOpen={isOpen} onRequestClose={toggle}>
          <Close onClick={toggle} />
          <MobileTimeline sections={sections} onSectionClick={toggle} />
        </Modal>
      )}
    </>
  )
}
