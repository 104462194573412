import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Emoji = styled.span`
  margin-left: ${g(2)};
`

export const Highlight = styled.div`
  align-items: baseline;
  display: flex;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.legacy.colors.neutral1.fade(60)};
  margin-left: ${g(2)};
`
