import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getDocumentsForDocumentsParameters } from '@pretto/app-core/application/lib/getDocumentsForDocumentsParameters'
import { getDocumentsParametersForStep } from '@pretto/app-core/application/lib/getDocumentsParametersForStep'

export const getDocumentsProgressForStep = (step, ...args) => {
  const documentsParameters = getDocumentsParametersForStep(step, ...args)
  const documents = getDocumentsForDocumentsParameters(documentsParameters, ...args)

  const documentsSent = documents.filter(({ document_status }) => convertStatusSlug(document_status) === 'sent')

  return { current: documentsSent.length, target: documents.length }
}
