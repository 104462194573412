import temporal from '@pretto/bricks/core/utility/temporal'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import MaturityUpdateDialog from '@pretto/app/src/Project/Containers/MaturityUpdateDialog'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { getItem, removeItem, setItem } from '@pretto/app/src/config/itemStorage'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { Maturity, StatusEnum } from '@pretto/app/src/types/gateway/enums'

import { useEffect, useState } from 'react'

import { useMaturityModalQuery } from './maturityModal.gateway.graphql'

export const MATURITY_LOCAL_STORAGE = 'MATURITY_MODAL_OPEN'
export const LAST_MATURITY_UPDATE_DATE = 'LAST_MATURITY_UPDATE_DATE'

const finishedStatus = [
  StatusEnum.End,
  StatusEnum.Lost,
  StatusEnum.LostDoubleChoice,
  StatusEnum.LostOneChoice,
  StatusEnum.LostHardClient,
]

export const MaturityModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(getItem(MATURITY_LOCAL_STORAGE)))

  const { notifyLegacy } = useNotifications()
  const { status, lostReason } = useUser()
  const { data, loading } = useMaturityModalQuery()
  const trackAction = useTracking()

  useEffect(() => {
    if (
      !finishedStatus.includes(status) &&
      !lostReason &&
      data?.project?.purchase?.maturity !== Maturity.SaleAgreementSigned &&
      !getItem(MATURITY_LOCAL_STORAGE) &&
      temporal().diff(temporal(data?.deal?.lastMaturityUpdateDate), 'day') > 15 &&
      (!getItem(LAST_MATURITY_UPDATE_DATE) || temporal().diff(temporal(getItem(LAST_MATURITY_UPDATE_DATE)), 'day') > 15)
    ) {
      setIsOpen(true)
      setItem(MATURITY_LOCAL_STORAGE, 'reconnect')
    }
  }, [data])

  useEffect(() => {
    if (isOpen) {
      trackAction('MATURITY_MODAL_VIEWED')
    }
  }, [isOpen])

  useEffect(() => {
    const setMaturity = () => {
      setIsOpen(Boolean(getItem(MATURITY_LOCAL_STORAGE)))
    }

    window.addEventListener('storage', setMaturity)

    return () => {
      window.removeEventListener('storage', setMaturity)
    }
  }, [])

  if (loading) {
    return null
  }

  const handleClose = () => {
    setIsOpen(false)
    setItem(LAST_MATURITY_UPDATE_DATE, temporal().format('YYYY-MM-DD'))
    removeItem(MATURITY_LOCAL_STORAGE)
  }

  const handleSubmit = () => {
    setItem(LAST_MATURITY_UPDATE_DATE, temporal().format('YYYY-MM-DD'))
    removeItem(MATURITY_LOCAL_STORAGE)

    notifyLegacy('Mise à jour de votre projet', 'L’avancement de votre projet a été mis à jour.')
  }

  return (
    <MaturityUpdateDialog
      projectStatus={data?.project?.purchase?.maturity}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isLostHidden={true}
    />
  )
}
