import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import defaultTo from 'lodash/defaultTo'

export const reduceField = (sections, reducer, initialValue, values, data, ...args) =>
  sections.reduce((previous, currentSection) => {
    const sectionProps = funcToItem(currentSection, values)
    const condition = funcToItem(defaultTo(sectionProps.condition, true), data, ...args)

    if (!condition) {
      return previous
    }

    return sectionProps.fields.reduce((previous, field) => {
      const condition = funcToItem(defaultTo(field.condition, true), values, data, ...args)
      const optional = funcToItem(defaultTo(field.optional, false), values, data, ...args)

      if (!condition || optional) {
        return previous
      }

      if (field.type === 'list') {
        return field.fields.reduce((previous, subField) => {
          const condition = funcToItem(defaultTo(subField.condition, true), values, data.project)
          const optional = funcToItem(defaultTo(subField.optional, false), values, data.project)

          if (!condition || optional) {
            return previous
          }

          return values[field.name].reduce((previous, value) => reducer(previous, value[subField.name]), previous)
        }, previous)
      }

      return reducer(previous, values[field.name], field)
    }, previous)
  }, initialValue)
