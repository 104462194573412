import { useBreakpoint } from '@pretto/bricks/assets/utility'
import Link from '@pretto/bricks/website/utility/Link'

import { useEmailField } from '@pretto/app/src/Form/lib/useField'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import SignUpPageComponent from '@pretto/app/src/SignUp/views/signUp'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const CGULink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary1};
`

const LoginLink = styled(Link)`
  text-decoration: underline;
`

const MINIMUM_LOADING_TIME = 1500

const SignUpPage = ({ location, onGoogleConnect, onSignUp }) => {
  const { assign, kind } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { isMobile } = useBreakpoint()

  const [submitting, setSubmitting] = useState(false)
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false)
  const timeout = useRef(null)
  const [connectionError, setConnectionError] = useState(null)

  const trackAction = useTracking()

  const {
    component: emailComponent,
    error,
    value: email,
  } = useEmailField({
    inputProps: {
      autoFocus: !isMobile,
      placeholder: 'Adresse email',
      required: true,
    },
    onKeyDown: e => {
      if (error || !email) {
        return
      }

      if (e.keyCode === 13) {
        handleSubmit()
      }
    },
  })

  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  const handleSubmit = async () => {
    setSubmitting(true)
    timeout.current = setTimeout(() => setSubmitting(false), MINIMUM_LOADING_TIME)

    try {
      const error = await onSignUp({
        assignment: assign,
        email,
        kind,
        source: 'signup',
        subscribeNewsletter: isNewsletterChecked,
      })
      if (error) {
        setConnectionError(error)
        return
      }
    } catch (error) {
      setConnectionError('server_error')
    } finally {
      setSubmitting(false)
    }
  }

  const handleGoogleConnect = () => {
    onGoogleConnect({
      subscribeNewsletter: isNewsletterChecked,
    })
    trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'signup' })
  }

  const trackLegalLink = source => () => {
    trackAction('SIGNUP_LEGAL_LINKS_CLICKED', { subscribe_legal_link: source })
  }

  const handleToggleNewsletter = () => {
    setIsNewsletterChecked(previousState => !previousState)
  }

  const information = (
    <>
      En continuant, vous acceptez les{' '}
      <CGULink href="https://www.pretto.fr/cgu/" onClick={trackLegalLink('cgu')}>
        conditions d’utilisation
      </CGULink>
      , la{' '}
      <CGULink href="/privacy" onClick={trackLegalLink('privacy')} target="blank">
        politique de confidentialité
      </CGULink>{' '}
      et{' '}
      <CGULink href="/privacy" onClick={trackLegalLink('privacy')} target="blank">
        l’utilisation de cookies
      </CGULink>{' '}
      de Pretto.
    </>
  )

  const props = {
    connectionCardProps: {
      buttonText: 'Continuer',
      emailComponent,
      googleText: "S'inscrire avec Google",
      information,
      isButtonLoading: submitting,
      onGoogleConnect: handleGoogleConnect,
      onSubmit: handleSubmit,
      textConnect: '— Ou, avec votre email —',
    },
    connectionError,
    hrefConnect: '/login',
    isNewsletterChecked,
    link: <LoginLink href="/login">ici</LoginLink>,
    onNewsletterToggle: handleToggleNewsletter,
    title: 'Créer un compte Pretto',
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} menu="signup" />
      <SignUpPageComponent {...props} />
    </>
  )
}

SignUpPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onGoogleConnect: PropTypes.func,
  onSignUp: PropTypes.func,
}
const SignUp = props => (
  <SubscribeConsumer>{context => <SignUpPage {...props} {...context} isMobile />}</SubscribeConsumer>
)

export default SignUp
