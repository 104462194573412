import PrivacyPageComponent from '@pretto/bricks/app/privacy/pages/PrivacyPage'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import Header from '../../../SharedContainers/Header'
import { useTracking } from '../../../lib/tracking'

const PrivacyPage = () => {
  const { isLoggedIn } = useAuth()
  const trackAction = useTracking()

  const trackLegalLink = source => () => {
    trackAction('PRIVACY_LEGAL_LINKS_CLICKED', { privacy_legal_link: source })
  }

  const privacyPageProps = {
    privacyInformationsProps: {
      onCguClick: trackLegalLink('cgu'),
      onLegalMentionsClick: trackLegalLink('legal_mentions'),
      onPrivacyClick: trackLegalLink('privacy'),
    },
  }

  return (
    <div>
      <Header goBackProps={isLoggedIn ? GO_BACK.dashboard : null} navigationItemsList={[NAV_ITEMS.faq]} />
      <PrivacyPageComponent {...privacyPageProps} />
    </div>
  )
}

export default PrivacyPage
