import { Grid } from '@pretto/bricks/components/layout/Grid'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import * as S from './styles'

const CenterGridTemplate = ({ alert, children }) => {
  const [isShown, setIsShown] = useState(true)

  const timeout = useRef(null)
  useEffect(() => {
    if (alert) {
      setIsShown(true)
      timeout.current = setTimeout(() => {
        setIsShown(false)
      }, 5000)

      return () => clearTimeout(timeout.current)
    }
  }, [alert])

  return (
    <Grid>
      <S.Container>
        {!!alert && <S.Alert in={isShown}>{alert}</S.Alert>}
        {children}
      </S.Container>
    </Grid>
  )
}

CenterGridTemplate.propTypes = {
  alert: PropTypes.node,
  children: PropTypes.node,
}

export default CenterGridTemplate
