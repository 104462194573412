import { TRACK_EVENT } from '@pretto/app/src/apollo'

export const heardAboutComment = {
  id: 'heard_about_comment',
  fields: [
    {
      description:
        'Pour que nous puissions remercier la personne qui vous a recommandé Pretto, pouvez-vous nous donner son nom et son prénom ?',
      type: 'textarea',
      name: 'comment',
      label: 'Les amis de nos amis sont nos amis !',
    },
  ],
  jumps: 'congratulations',
  mutation({ values, userContext: { projectID } }) {
    return {
      mutation: TRACK_EVENT,
      variables: {
        eventName: 'onboarding:heard_about:commented',
        payload: JSON.stringify({ comment: values.comment }),
        projectId: projectID,
      },
    }
  },
  redirect({ params }) {
    if (params.origin !== 'referral_friends_family') {
      return 'heard_about'
    }

    return false
  },
}
