import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { Eye } from '@pretto/picto'

import styled, { css } from 'styled-components'

const ACTIVITY_ICON_RATIO = 4

const getImageOptionsFromProps = ({ $size }) => {
  switch ($size) {
    case 'miniature':
      return { height: ng(4), width: ng(4) }

    case 'x-small':
      return { height: ng(5), width: ng(5) }

    case 'small':
    case 'splitted':
      return { height: ng(6), width: ng(6) }

    case 'large':
      return { height: ng(8), width: ng(8) }

    case 'x-large':
      return { height: ng(10), width: ng(10) }

    case 'medium':
    default:
      return { height: ng(7), width: ng(7) }
  }
}

const getSizeStyleFromProps = ({ $size }) => {
  switch ($size) {
    case 'miniature':
      return css`
        width: ${g(4)};
        height: ${g(4)};
        line-height: ${g(4)};
      `

    case 'x-small':
      return css`
        width: ${g(5)};
        height: ${g(5)};
        line-height: ${g(5)};
      `

    case 'small':
      return css`
        width: ${g(4)};
        height: ${g(4)};
        line-height: ${g(4)};

        @media screen and (min-width: ${breakpoints.tablet}) {
          width: ${g(6)};
          height: ${g(6)};
          line-height: ${g(6)};
        }
      `

    case 'splitted':
      return css`
        width: ${g(6)};
        height: ${g(6)};
        line-height: ${g(6)};
      `

    case 'large':
      return css`
        width: ${g(8)};
        height: ${g(8)};
        line-height: ${g(8)};
      `

    case 'x-large':
      return css`
        font-size: ${g(5)};
        width: ${g(10)};
        height: ${g(10)};
        line-height: ${g(10)};
      `

    case 'medium':
    default:
      return css`
        width: ${g(7)};
        height: ${g(7)};
        line-height: ${g(7)};
      `
  }
}

const getActivityIconSize = ({ $size }) => {
  switch ($size) {
    case 'miniature':
      return css`
        width: ${g(4 / ACTIVITY_ICON_RATIO)};
        height: ${g(4 / ACTIVITY_ICON_RATIO)};
      `

    case 'x-small':
      return css`
        width: ${g(5 / ACTIVITY_ICON_RATIO)};
        height: ${g(5 / ACTIVITY_ICON_RATIO)};
      `

    case 'small':
      return css`
        width: ${g(4 / ACTIVITY_ICON_RATIO)};
        height: ${g(4 / ACTIVITY_ICON_RATIO)};

        @media screen and (min-width: ${breakpoints.tablet}) {
          width: ${g(6 / ACTIVITY_ICON_RATIO)};
          height: ${g(6 / ACTIVITY_ICON_RATIO)};
        }
      `

    case 'splitted':
      return css`
        width: ${g(6 / ACTIVITY_ICON_RATIO)};
        height: ${g(6 / ACTIVITY_ICON_RATIO)};
      `

    case 'large':
      return css`
        width: ${g(8 / ACTIVITY_ICON_RATIO)};
        height: ${g(8 / ACTIVITY_ICON_RATIO)};
      `

    case 'x-large':
      return css`
        width: ${g(10 / ACTIVITY_ICON_RATIO)};
        height: ${g(10 / ACTIVITY_ICON_RATIO)};
      `

    case 'medium':
    default:
      return css`
        width: ${g(7 / ACTIVITY_ICON_RATIO)};
        height: ${g(7 / ACTIVITY_ICON_RATIO)};
      `
  }
}

export const AvatarContainer = styled.div`
  position: relative;
`

export const ActivityIcon = styled.div`
  position: absolute;
  ${getActivityIconSize}
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.warning1};
  bottom: 0px;
  right: 0px;
  z-index: 100;
`

export const Avatar = styled.div.attrs(({ onClick }) => onClick && { role: 'button', tabIndex: 0 })`
  display: block;
  position: relative;
  font-size: ${g(2.5)};
  ${getSizeStyleFromProps};
  border-radius: 100%;
  text-align: center;
  color: ${({ $colorVariant, theme }) => theme.colors[$colorVariant]};
  background-color: ${({ $backgroundColorVariant, theme, $isBackgroundTransparent }) =>
    $isBackgroundTransparent ? 'transparent' : theme.colors[$backgroundColorVariant]};
  overflow: hidden;

  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;

      &:focus {
        outline: transparent;

        &::before {
          content: '';
          position: absolute;
          border-radius: inherit;
          top: -1px;
          left: -1px;
          bottom: -1px;
          right: -1px;
          border: 1px dashed ${({ theme }) => theme.colors.accent1};
        }
      }
    `}
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.neutral1};
  opacity: 0.7;
  z-index: 1;
`

export const Image = styled(BaseImage).attrs(props => ({
  options: { ...getImageOptionsFromProps(props), crop: 'fill', gravity: 'face', quality: 85 },
}))`
  width: 100%;
  height: 100%;
  line-height: 100%;

  ${({ $isClickable }) =>
    $isClickable &&
    css`
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    `}
`

export const PictoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral1};
    opacity: 0.7;
  }
`

export const Picto = styled(Eye)`
  color: ${({ theme }) => theme.colors.white};
`
