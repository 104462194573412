import { numeralBreakpoints } from '@pretto/bricks/components/layout'

type Breakpoint = keyof typeof numeralBreakpoints

export const getBreakpointForWindowWidth = (
  windowWidth: number,
  breakpoints: Breakpoint[] = Object.keys(numeralBreakpoints) as Breakpoint[]
): Breakpoint | null =>
  (
    Object.entries(numeralBreakpoints).sort(([, valueA], [, valueB]) => valueA - valueB) as Array<[Breakpoint, number]>
  ).reduce<Breakpoint | null>((previous, [breakpoint, value]) => {
    if (!breakpoints.includes(breakpoint) || windowWidth < value) {
      return previous
    }

    return breakpoint
  }, null)
