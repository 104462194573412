import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import * as S from './styles'

const PATTERN_WIDTH = 4

const getWidth = baseWidth => {
  // get remaining pixels to match pattern
  const alpha = baseWidth % PATTERN_WIDTH
  const rest = PATTERN_WIDTH - alpha
  return baseWidth + rest
}

const PICTO = {
  default: null,
  error: ExclamationMarkTriangleBold,
  warning: ExclamationMarkCircleBold,
}

const SentenceFieldSimple = ({ onChange, pictoMessage, state = 'default', inputProps, suffix, value, ...props }) => {
  const hiddenRef = useRef(null)
  const inputRef = useRef(null)
  const [inputWidth, setInputWidth] = useState(0)

  const handleChange = e => {
    const { value } = e.target
    onChange(value, e)
  }

  useEffect(() => {
    const width = getWidth(hiddenRef.current.offsetWidth)
    setInputWidth(width)
  }, [value])

  const handleClickSuffix = () => {
    inputRef.current.focus()
  }

  return (
    <ThemeProvider theme={{ state }}>
      <S.Container {...props}>
        <S.Component $patternWidth={PATTERN_WIDTH} style={{ width: inputWidth }}>
          <S.Input {...inputProps} ref={inputRef} value={value} onChange={handleChange} />
        </S.Component>
        {suffix && <S.Suffix onClick={handleClickSuffix}> {suffix}</S.Suffix>}

        {state !== 'default' && (
          <S.PictoContainer content={pictoMessage}>
            <S.Picto as={PICTO[state]} />
          </S.PictoContainer>
        )}
      </S.Container>
      <S.HiddenComponent ref={hiddenRef}>
        <S.HiddenInput>{value?.toString() || inputProps.placeholder}</S.HiddenInput>
      </S.HiddenComponent>
    </ThemeProvider>
  )
}

SentenceFieldSimple.defaultProps = {
  inputProps: {},
}

SentenceFieldSimple.propTypes = {
  /** Props of input tag. */
  inputProps: PropTypes.object,
  /** Triggered when value is changed. First argument of function is `value` */
  onChange: PropTypes.func.isRequired,
  /** There must be a picto in order for this to work. Message will be displayed as tooltip. */
  pictoMessage: PropTypes.node,
  /** State of field. Colors will change accordingly */
  state: PropTypes.oneOf(Object.keys(PICTO)),
  /** Suffix that will be next to the input. */
  suffix: PropTypes.string,
  /** Value of input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default SentenceFieldSimple
