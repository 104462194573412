import { UserCircle } from '@pretto/picto'

import { getPageSchemaForMortgagor } from './mortgagor'

const step = {
  condition: (data, { hasComortgagor }) => hasComortgagor,
  documentsParameters: { category: 'general', mortgagor: 1 },
  pages: getPageSchemaForMortgagor(1),
  queryParams: 'category=general_1',
  sideBarParameters: {
    label: (data, { accounts }) => accounts[1].name,
    picto: UserCircle,
    sideBarOrder: 1.5,
    url: '/application/comortgagor',
  },
}

export default step
