import { BlockTextarea, InputDefault } from '@pretto/app/src/purchaseOffer/config/types'
import { Pen } from '@pretto/picto'

// --FIELDS --
export const PRICE: InputDefault<number> = {
  props: {
    placeholder: '200 000',
  },
  type: 'number',
}

// -- BLOCKS --
export const CONDITION_BLOCK: BlockTextarea = {
  props: {
    isOptionnal: true,
    label: 'Conditions suspensives : ',
    picto: Pen,
    textareaProps: {
      fieldProps: {
        placeholder: 'Exemple : obtention du prêt immobilier, revente de votre logement actuel, etc.',
      },
    },
    tooltip: `Détaillez la ou les conditions suspensives particulières que vous souhaitez prévoir dans votre offre d'achat.`,
  },
}
