import { t } from '@pretto/app/src/lib/i18n'
import { CheckBold } from '@pretto/picto'

import * as S from './Reassurance.styles'

export const Reassurance: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <S.Component {...props}>
    <S.ImageContainer>
      <S.Image
        alt={t('sentences.maturity.index.reassurance.image.alt')}
        path="v1689847769/team/all/team_pretto_app.jpg"
      />
    </S.ImageContainer>

    <S.Content>
      <S.Title>{t('sentences.maturity.index.reassurance.title')}</S.Title>

      <S.Items>
        {([0, 1, 2] as const).map(index => (
          <S.Item key={index}>
            <S.Bullet>
              <CheckBold />
            </S.Bullet>

            <S.Value>
              <S.ValueTitle>{t(`sentences.maturity.index.reassurance.items.${index}.title`)}</S.ValueTitle>
              <S.ValueDescription>
                {t(`sentences.maturity.index.reassurance.items.${index}.description`)}
              </S.ValueDescription>
            </S.Value>
          </S.Item>
        ))}
      </S.Items>
    </S.Content>
  </S.Component>
)
