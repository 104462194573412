import AccountPageComponent from '@pretto/bricks/app/account/pages/AccountPage'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useState } from 'react'

import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { sections as sectionsSchema } from '../../config/sections'

const AccountPage = () => {
  const [activeFieldName, setActiveFieldName] = useState(null)

  const userContext = useUser()

  const comortgagorAccount = userContext.accounts.find(({ email = null, isMine }) => email !== null && !isMine)

  const handleCancel = () => {
    setActiveFieldName(null)
  }

  const handleEdit = name => {
    setActiveFieldName(name)
  }

  const sections = sectionsSchema.reduce((previous, { accountId, condition = true, fields, id, ...section }) => {
    const sectionCondition = funcToItem(condition, userContext, comortgagorAccount)

    if (!sectionCondition) {
      return previous
    }

    const sectionAccountId = funcToItem(accountId, userContext, comortgagorAccount)
    const sectionFields = funcToItem(fields, userContext, comortgagorAccount)

    const fieldsComponent = sectionFields.map(({ component: FieldComponent, defaultValue, name, ...field }) => {
      const fieldDefaultValue = funcToItem(defaultValue, userContext, comortgagorAccount) ?? ''

      return (
        <FieldComponent
          key={id}
          accountId={sectionAccountId}
          isEditDisabled={activeFieldName !== null && activeFieldName !== name}
          defaultValue={fieldDefaultValue}
          name={name}
          onCancel={handleCancel}
          onEdit={handleEdit.bind(null, name)}
          section={id}
          {...field}
        />
      )
    })

    return [
      ...previous,
      {
        ...section,
        fieldsComponent,
      },
    ]
  }, [])

  const props = {
    sections,
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <AccountPageComponent {...props} />
    </>
  )
}

export default AccountPage
