import { g } from '@pretto/bricks/components/layout'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'

import { Chip as ChipComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/Chip/Chip'
import { formatBenefit } from '@pretto/app/src/Capacity/views/ResultPage/lib/formatBenefit'
import { Benefit } from '@pretto/app/src/Capacity/views/ResultPage/types/benefit'

import styled from 'styled-components'

export interface SuggestionSlimProps {
  disclaimer?: React.ReactNode | null
  benefit: Benefit
  title: string
  value: number
}

export const SuggestionSlim: React.FC<SuggestionSlimProps & React.HTMLAttributes<HTMLDivElement>> = ({
  benefit,
  disclaimer,
  title,
  value,
  ...props
}) => (
  <Component {...props}>
    <Title>{title}</Title>

    <Content>
      <Value>{value.toLocaleString('fr')} €</Value>
      <Chip>{formatBenefit(benefit)}</Chip>
    </Content>

    {disclaimer && (
      <Footer>
        <Divider />
        <Disclaimer>{disclaimer}</Disclaimer>
      </Footer>
    )}
  </Component>
)

const Chip = styled(ChipComponent)``

const Component = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${g(2)};
  padding: ${g(3)} ${g(2)};
`

const Content = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};
`

const Disclaimer = styled.p`
  ${({ theme }) => theme.typos.body4};
  color: ${({ theme }) => theme.colors.neutral1};
  padding: ${g(1)} ${g(2)};
  text-align: center;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading6};
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
`

const Value = styled.div`
  ${({ theme }) => theme.typos.body2};
  color: ${({ theme }) => theme.colors.primary1};
`
