import { REDUCE_AMBITION_TYPES } from '@pretto/app/src/Simulation/Containers/OptionsPage/config/reduceAmbition'

export const getDefaultOption = options => {
  const typeEntry = Object.entries(REDUCE_AMBITION_TYPES).find(([, value]) => options[value] !== null)

  if (!typeEntry) {
    throw new Error('All options matching REDUCE_AMBITION_TYPES are null. At least one must be provided.')
  }

  return typeEntry[1]
}
