import { DashboardNewProjectCard } from '@pretto/zen/dashboard/sections/DashboardNewProjectCard/DashboardNewProjectCard'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { SIMULATION_DURATION } from '@pretto/app/src/config/reassurance'
import { ProjectModalContext } from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { trackAction } from '@pretto/app/src/lib/tracking'
import { StatusEnum } from '@pretto/app/src/types/gateway/enums'
import { Clock, House } from '@pretto/picto'

import { useContext } from 'react'

export const NewProject = () => {
  const { setToNewProject } = useContext(ProjectModalContext)

  const { lostReason, status } = useUser()

  const handleOpenNewProjectModal = () => {
    if (status === 'end') {
      trackAction('WIN_CTA_NEW_PROJECT_CLICKED')
    }

    if (lostReason) {
      trackAction('LOST_CTA_NEW_PROJECT_CLICKED', {
        lostReason,
        dashboardOptions: status,
      })
    }

    setToNewProject()
  }

  const props = {
    buttonProps: {
      children: 'Nouveau projet',
      onClick: handleOpenNewProjectModal,
    },
    description:
      status !== StatusEnum.LostOneChoice ? (
        `Simulez en ${SIMULATION_DURATION.toString()} minutes votre prochain projet avec Pretto !`
      ) : (
        <>
          Vous souhaitez commencer un nouveau projet ?
          <br />
          Simulez en {SIMULATION_DURATION.toString()} minutes votre prochain projet avec Pretto !
        </>
      ),
    projectTypes: [
      {
        picto: House,
        label: 'Projet d’achat immobilier',
      },
      {
        picto: Clock,
        label: 'Renégocier un prêt immobilier',
        caption: '(Attention, nous ne renégocions pas les prêts contractés via Pretto)',
      },
    ],
    get title() {
      switch (status) {
        case StatusEnum.LostOneChoice:
          return 'Heureux de vous revoir'

        case StatusEnum.LostDoubleChoice:
          return 'Votre projet a changé ?'

        case 'end':
        default:
          return 'Un nouveau projet ?'
      }
    },
  }

  return (
    <>
      <DashboardNewProjectCard {...props} />
    </>
  )
}

export const config: Config = {
  component: NewProject,
  name: 'newProject',
}
