import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled from 'styled-components'

export const MortgagorsInfos = styled.div`
  margin-top: ${g(3)};
`

export const VerifyInfos = styled(BaseTypo).attrs({ typo: 'heading16' })`
  font-weight: 900;
  margin-bottom: ${g(2)};
`

export const Mortgagor = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, $color }) => theme.colors[$color]};
  fill: ${({ theme, $color }) => theme.colors[$color]};
  padding: ${g(3)} 0;
`

export const Email = styled.span.attrs(({ $tag }) => ({ as: $tag }))`
  margin-left: ${g(2)};
`

export const Picto = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  margin-left: auto;
`
