import { createElement } from 'react'

import { PROGRESS, PROJECT_KIND } from './config'

const initProjects = (projects, projectKind, progress, propertySearch) => {
  const [BudgetProjectCard, PropertySearchProjectCard, PurchaseProjectCard, RenegotiationProjectCard] = projects

  const purchaseCards =
    progress === PROGRESS.found ? [PurchaseProjectCard] : [BudgetProjectCard, PropertySearchProjectCard]

  const renegotiationCards = [RenegotiationProjectCard]

  switch (projectKind) {
    case PROJECT_KIND.purchase:
      return purchaseCards
    case PROJECT_KIND.renegotiation:
      return renegotiationCards
    default:
      if (propertySearch.data) {
        return [PropertySearchProjectCard]
      }

      return []
  }
}

export const initProjectCards = (projects, projectKind, progress, propertySearch) => {
  const projectCards = initProjects(projects, projectKind, progress, propertySearch)

  return projectCards.map(projectCard => {
    const isObjectComponent = !!projectCard.component

    if (isObjectComponent) {
      const { component, props } = projectCard
      return createElement(component, props)
    }

    return createElement(projectCard)
  })
}
