import { breakpoints, g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'
import styled, { css } from 'styled-components'

export const Button = styled.button.attrs(({ href }) => ({
  as: href ? Link : 'button',
}))`
  background-color: ${({ theme, variant }) => theme.legacy.colors[variant]};
  border: 2px solid ${({ borderVariant, theme }) => theme.legacy.colors[borderVariant] || 'transparent'};
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 14px;
  font-weight: bold;
  line-height: ${g(5.5)};
  letter-spacing: 0.5px;
  padding: 0 ${g(2)};
  text-transform: uppercase;
  transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme, variant }) => theme.legacy.colors[`${variant}-d`] || theme.legacy.colors[variant]};
    box-shadow: 0 2px 10px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
  }

  &:focus {
    outline: 1px dashed ${({ theme, variant }) => theme.legacy.colors[variant]};
  }

  span {
    color: ${({ textVariant, theme, isLoading }) => (isLoading ? 'transparent' : theme.legacy.colors[textVariant])};
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ disabled, theme, variant }) =>
    disabled &&
    css`
      position: relative;
      &:hover {
        background-color: ${theme.legacy.colors[variant]};
        box-shadow: none;
      }
      &:after {
        border-radius: 3px;
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        content: '';
        cursor: not-allowed;
        background: ${theme.legacy.colors.white.default};
        opacity: 0.8;
      }
    `}
  
  ${({ isLoading, theme, variant }) =>
    isLoading &&
    css`
      cursor: not-allowed;
      position: relative;

      &:hover {
        background-color: ${theme.legacy.colors[variant]};
        box-shadow: none;
      }
    `}

  ${({ checked }) =>
    checked &&
    css`
      ${Content} {
        opacity: 0;
      }

      ${Icon} {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin-right: 0;
      }

      ${IconWrapper} {
        position: relative;
      }
    `}

  ${({ icon }) =>
    icon &&
    css`
      position: relative;
    `}


  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: ${g(2)};
          letter-spacing: 0;
          line-height: ${g(5)};
        `

      case 'large':
        return css`
          border-radius: ${g(1)};
          height: ${g(7)};
          line-height: ${g(7)};

          @media screen and (min-width: ${breakpoints.tablet}) {
            height: ${g(6)};
            line-height: ${g(6)};
          }
        `
      default:
    }
  }}
`
export const Content = styled.span``
export const Loading = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Icon = styled.span`
  margin-right: ${g(1)};
`
