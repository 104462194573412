import { GOOGLE_ANALYTICS_SLUG } from '@pretto/app/src/lib/tracking'

export const formatGAUserID = (): Promise<string> =>
  new Promise(resolve => {
    if (!window.ga) {
      return resolve('')
    }

    window.ga(tracker => {
      resolve(`${GOOGLE_ANALYTICS_SLUG}=${tracker.get(GOOGLE_ANALYTICS_SLUG)}`)
    })
  })
