import { useBreakpoint } from '@pretto/bricks/assets/utility'

import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { createElement } from 'react'

import * as S from './styles'

const PERCENT_FACTOR = 100

const MainSectionFolder = ({
  actionLabel,
  actionUrl,
  alertMessage,
  alertType,
  blockInfos,
  children,
  description,
  onActionClick,
  picto,
  progressValue,
  tag,
  title,
}) => {
  const { isMobile } = useBreakpoint()

  return (
    <S.Card>
      <S.Header $reverse={tag}>
        <S.FolderTitle>{title}</S.FolderTitle>
        {tag ? (
          <S.TagLabel>{tag}</S.TagLabel>
        ) : !isNil(progressValue) ? (
          <S.Progress>
            {createElement(progressValue === PERCENT_FACTOR ? 'strong' : 'span', null, `${progressValue} %`)}
            <S.Bar max={PERCENT_FACTOR} value={progressValue} />
          </S.Progress>
        ) : null}
      </S.Header>
      {description && <S.Description>{description}</S.Description>}
      {actionLabel && actionUrl && (
        <S.CTA href={actionUrl} onClick={onActionClick}>
          {actionLabel}
        </S.CTA>
      )}
      {!!blockInfos && <S.Block {...blockInfos} />}
      {children}
      {alertMessage && <S.AlertMessage picto={picto} alertMessage={alertMessage} alertType={alertType} />}
    </S.Card>
  )
}

MainSectionFolder.propTypes = {
  actionLabel: PropTypes.string,
  /** Url to follow when the card main content is being pressed. */
  actionUrl: PropTypes.string,
  /** Message to be displayed in the alert. Defines whether or not an alert should be displayed. */
  alertMessage: PropTypes.node,
  /** Defines the theme of the alert. Ignored if alertMessage is null. */
  alertType: PropTypes.oneOf(['error', 'info', 'success']),
  blockInfos: PropTypes.shape({
    content: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  /** children prop (react) */
  children: PropTypes.node,
  description: PropTypes.string,
  /** Triggered whenever an action link is being pressed. */
  onActionClick: PropTypes.func,
  /** picto to use in alert messages. */
  picto: PropTypes.elementType,
  /** Value of the progress. Defines whether or not the progress bar should be displayed. */
  progressValue: PropTypes.number,
  /** tag to display (top-right corner) */
  tag: PropTypes.string,
  /** title of the component */
  title: PropTypes.string,
}

export default MainSectionFolder
