import { getDurationFromResults } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/results'
import filterResultsByDuration from '@pretto/app/src/Simulation/lib/filterResultsByDuration'
import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'

import get from 'lodash/get'

export const validateSimulationPageProps = ({
  newData,
  currentData,
  projectChanges,
  handleValidationCancel,
  handleValidationUpdate,
}) => {
  const rows = [
    'purchase.property_price',
    'purchase.land_price',
    'purchase.build_price',
    'purchase.works_price',
    'contribution',
  ]

  const rowsWithValues = [
    ...rows.filter(row => {
      const currentValue = get(currentData.project, row)
      const newValue = get(newData.project, row)

      return currentValue || newValue
    }),
    'payment',
    'duration',
  ]

  const computeRows =
    ({ project, results }, projectChanges = {}) =>
    (previous, path) => {
      const duration = getDurationFromResults(results, project, projectChanges)

      switch (path) {
        case 'duration':
          return [...previous, { path, value: duration / MONTHS_IN_YEAR }]

        case 'payment': {
          const bestResult = filterResultsByDuration(results, duration)[0]

          if (!bestResult) {
            return previous
          }

          return [...previous, { path, value: bestResult.financing.payment }]
        }

        default:
          return [...previous, { path, value: get(project, path) ?? 0 }]
      }
    }

  const currentValues = rowsWithValues.reduce(computeRows(currentData), [])
  const newValues = rowsWithValues.reduce(computeRows(newData, projectChanges), [])

  return {
    currentProject: {
      onClick: handleValidationCancel,
      values: currentValues,
    },
    newProject: {
      onClick: handleValidationUpdate,
      values: newValues,
    },
  }
}
