export const getArrangedMarks = (marks, min, max) => {
  const arrangedMarks = [
    min,
    ...marks.reduce((previous, mark) => {
      if (mark <= min || mark >= max) {
        return previous
      }

      return [...previous, mark]
    }, []),
    max,
  ].sort((markA, markB) => markA - markB)

  return arrangedMarks
}
