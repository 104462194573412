export interface Matrice {
  1: string
  2: string
  3: string
  4: string
  5: string
}

export const matriceIra: Matrice = {
  1: 'rembourser votre prêt en anticipé sous conditions',
  2: 'rembourser votre prêt en anticipé sous conditions',
  3: 'rembourser votre prêt en anticipé',
  4: 'rembourser votre prêt en anticipé',
  5: 'rembourser facilement votre prêt en anticipé',
}

export const matriceInsurance: Matrice = {
  1: 'ne propose pas l’assurance groupe la plus avantageuse',
  2: 'ne propose pas l’assurance groupe la plus avantageuse',
  3: 'a une bonne assurance groupe',
  4: 'a une très bonne assurance groupe',
  5: 'a l’une des meilleurs assurance groupe',
}

export const matriceModularity: Matrice = {
  1: 'moduler vos mensualités sous conditions strictes',
  2: 'moduler vos mensualités sous conditions',
  3: 'moduler vos mensualités',
  4: 'moduler vos mensualités',
  5: 'moduler facilement vos mensualités',
}

export const matriceReportDeadline: Matrice = {
  1: 'suspendre vos remboursements sous conditions strictes',
  2: 'suspendre vos remboursements sous conditions',
  3: 'suspendre vos remboursements',
  4: 'suspendre vos remboursements',
  5: 'suspendre facilement vos remboursements',
}

export const matriceAverageResponseTime: Matrice = {
  1: 'prend un certain temps pour traiter les dossiers de prêt',
  2: 'prend un certain temps pour traiter les dossiers de prêt',
  3: 'traite les dossiers de prêt dans les temps',
  4: 'traite vite les dossiers de prêt',
  5: 'traite rapidement les dossiers de prêt',
}

export const matriceRse: Matrice = {
  1: 'devrait faire plus en faveur du climat',
  2: 'devrait faire plus en faveur du climat',
  3: 'pourrait faire plus en faveur climat',
  4: 'agit en faveur du climat',
  5: 'agit en faveur du climat',
}

export const matriceDigitization: Matrice = {
  1: 'doit s’améliorer sur son app mobile',
  2: 'peut s’améliorer sur son app mobile',
  3: 'a une app mobile efficace',
  4: 'propose une excellente app mobile pour vos opérations',
  5: 'a la meilleure app mobile pour vos opérations',
}
