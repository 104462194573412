import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Flag = styled.div`
  & + & {
    margin-top: ${g(4)};
  }
`

export const Section = styled.div`
  & + & {
    margin-top: ${g(4)};
  }
`
export const Button = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(2)};
`
