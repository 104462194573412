import { PROGRESS, PROJECT_KIND } from './config'

export const validProject = ({
  isFundable,
  isProjectAvailable,
  isPropertyFound,
  isSimulationOK,
  progress,
  projectKind,
  RenegotiationProjectCard,
}) => {
  if (!isProjectAvailable) {
    return false
  }

  if (projectKind === PROJECT_KIND.renegotiation) {
    return !!RenegotiationProjectCard?.data?.simulation
  }

  if (progress === PROGRESS.searching) {
    return isFundable
  }

  return !isPropertyFound || (isPropertyFound && isSimulationOK)
}
