import { EVENT_SEE_CAPACITY } from '@pretto/app/src/dashboard/containers/cards/capacity'
import { Coins, Files, Folder, House, Pen, QuestionMarkCircle, Tune } from '@pretto/picto'
import { t } from '@pretto/app/src/lib/i18n'

export const NAV_ITEMS = {
  dashboard: {
    href: '/',
    id: 'dashboard',
    label: t('navigation.dashboard'),
    picto: House,
  },
  documents: {
    href: '/application/documents',
    id: 'documents',
    label: t('navigation.documents'),
    picto: Files,
  },
  faq: {
    href: 'https://faq.pretto.fr/',
    id: 'faq',
    picto: QuestionMarkCircle,
  },
  folder: {
    href: '/application',
    id: 'folder',
    label: t('navigation.folder'),
    picto: Folder,
  },
  home: {
    href: '/',
    id: 'home',
  },
  logIn: {
    href: '/login',
    id: 'logIn',
  },
  modifySimulatorCapacity: {
    href: '/project/capacity/introduction',
    id: 'modifySimulatorCapacity',
    label: t('navigation.modifySimulation'),
    picto: Pen,
  },
  modifySimulatorPurchase: {
    href: '/project/purchase/introduction',
    id: 'modifySimulatorPurchase',
    label: t('navigation.modifySimulation'),
    picto: Pen,
  },
  modifySimulatorRenegociaton: {
    href: '/project/renegotiation/introduction',
    id: 'modifySimulatorRenegociaton',
    label: t('navigation.modifySimulation'),
    picto: Pen,
  },
  simulator: {
    href: '/simulation',
    id: 'simulator',
    label: t('navigation.simulator'),
    picto: Tune,
  },
  capacity: {
    href: '/capacity',
    id: 'capacity',
    label: t('navigation.capacity'),
    picto: Coins,
    tracking: {
      label: EVENT_SEE_CAPACITY,
      params: {
        origin: 'nav bar',
      },
    },
  },
}

export const GO_BACK = {
  capacity: {
    href: '/capacity',
    label: t('navigation.result'),
  },
  dashboard: {
    href: '/',
    label: t('navigation.dashboard'),
  },
  documents: {
    href: '/application/documents',
    label: t('navigation.documents'),
  },
  folder: {
    href: '/application',
    label: t('navigation.folder'),
  },
  home: {
    href: '/',
    label: t('navigation.goBack'),
  },
  simulator: {
    href: '/simulation',
    label: t('navigation.result'),
  },
}
