import { LOCAL_STORAGE_IMMO } from '@pretto/app/src/Sentences/v2/config/storage/storage'
import { FrequencyPropertySearch } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'

export const mapFrequencyValues: MapToChoices<FrequencyPropertySearch[]> = (values, context) => {
  if (!context) return []
  const { propertySearchFrequency, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected: propertySearchFrequency === value,
    onClick: () => {
      setItem(LOCAL_STORAGE_IMMO, 'true')
      setContext({ propertySearchFrequency: value, hasAnsweredPropertySearch: value === FrequencyPropertySearch.Never })
    },
    label: t(`sentences.propertySearch.frequency.values.${value}`),
  }))
}
