import AccountField from '@pretto/bricks/app/account/components/AccountField'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { useApolloClient, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { UPDATE_ACCOUNT } from '../../apollo'
import { useTracking } from '../../lib/tracking'
import { DEFAULT_VALUE, MATCHING_NAMES } from '../config/labels'

export const useField = ({ accountId, isEditDisabled, name, onCancel, onEdit, section, ...fieldProps }) => {
  const Component = (
    <Field
      {...fieldProps}
      accountId={accountId}
      isEditDisabled={isEditDisabled}
      name={name}
      onCancel={onCancel}
      onEdit={onEdit}
      section={section}
    />
  )

  return { Component, fieldProps }
}

export const Field = ({
  accountId,
  caption,
  component,
  error,
  initialValue,
  isEditDisabled,
  label,
  name,
  onCancel,
  onEdit,
  section,
  setValue,
  value,
}) => {
  const client = useApolloClient()

  const [updateAccount] = useMutation(UPDATE_ACCOUNT)

  const { notifyLegacy } = useNotifications()

  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [persistedInitialValue, setPersistedInitialValue] = useState(initialValue)

  const trackAction = useTracking()

  useEffect(() => {
    if (isActive) {
      onEdit()
      return
    }

    onCancel()
  }, [isActive])

  const handleCancel = () => {
    setIsActive(false)
    setValue(persistedInitialValue)
  }

  const handleEdit = () => {
    setIsActive(true)
  }

  const handleSave = async () => {
    setIsLoading(true)

    if (value === persistedInitialValue) {
      onCancel()
    }

    const variables = {
      id: accountId,
      payload: {
        [name]: value,
      },
    }

    try {
      await updateAccount({ variables })

      trackAction('ACCOUNT_INFORMATIONS_UPDATED', {
        account_informations_action: !persistedInitialValue ? 'create' : 'update',
        account_informations_owner: section,
        account_informations_type: MATCHING_NAMES[name] ?? name,
      })

      notifyLegacy('Compte mis à jour', `${label} modifié avec succès.`)

      await invalidateCache(client)

      setIsActive(false)
      setPersistedInitialValue(value)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const props = {
    caption,
    fieldComponent: component,
    isEditDisabled,
    isEditing: isActive,
    isLoading,
    isSubmitDisabled: error || value === '',
    label,
    onCancel: handleCancel,
    onEdit: handleEdit,
    onSave: handleSave,
    value: value === '' ? DEFAULT_VALUE : value,
  }

  return <AccountField {...props} />
}

Field.propTypes = {
  accountId: PropTypes.string.isRequired,
  caption: PropTypes.string,
  component: PropTypes.node,
  error: PropTypes.bool,
  initialValue: PropTypes.string,
  isEditDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  section: PropTypes.oneOf(['mortgagor', 'comortgagor']).isRequired,
  setValue: PropTypes.func,
  value: PropTypes.string,
}
