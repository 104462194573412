import get from 'lodash/get'

import { ErrorInvalidType } from '@pretto/bricks/core/utility/error/types'

const DEFAULT_TRANSFORMER = value => value

export const compileString = (string, context, transformer = DEFAULT_TRANSFORMER) => {
  if (typeof string !== 'string') {
    throw new ErrorInvalidType(string, 1, 'string')
  }

  if (typeof context !== 'object') {
    throw new ErrorInvalidType(context, 2, 'object')
  }

  const compiledString = string.replace(/{{([\w.[\]]+)}}/g, (match, key) => {
    const value = get(context, key, '')

    return transformer(value, key)
  })

  return compiledString
}
