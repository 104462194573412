import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Delete = styled.div`
  margin-top: ${g(10)};
`

export const DeleteButton = styled(ButtonInline)``

export const DeleteLegals = styled.p`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-top: ${g(1)};
  width: 100%;

  a {
    text-decoration: underline;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 50%;
  }
`

export const Field = styled.div`
  & + &:before {
    background-color: ${({ theme }) => theme.colors.neutral4};
    content: '';
    display: block;
    height: 1px;
    margin: ${g(2)} 0;
  }
`
export const Page = styled.div`
  padding-bottom: ${g(5)};
  max-width: ${breakpoints.tablet};
`
export const Header = styled.div`
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  margin-bottom: ${g(4)};
  padding-bottom: ${g(4)};
  padding-top: ${g(5)};
`
export const Invite = styled.div`
  margin-top: ${g(5)};
`
export const InviteContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const Section = styled.div`
  & + & {
    margin-top: ${g(10)};
  }
`
export const SectionTitle = styled.div`
  margin: ${g(2)} 0 ${g(1)};

  &:first-child {
    margin-top: 0;
  }
`
export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${g(1)};
`
