import Link from '@pretto/bricks/website/utility/Link'

import {
  NegotiationModal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const CheckBankModal: React.FC<NegotiationModalProps> = ({ onClickLink, ...props }) => (
  <NegotiationModal {...props}>
    <p>
      <strong>Cette étape marque l’envoi de votre dossier en prescription 🏦</strong>
    </p>

    <p>
      La prescription d’une banque est un pôle spécial pour les échanges entre les courtiers et la banque. Elle fait le
      lien avec le siège administratif de la banque, les conseillers de l’agence bancaire et votre Expert Pretto.
    </p>

    <p>Elle a pour rôles :</p>

    <p>
      <ul>
        <li>🔎 D’étudier votre profil et vos documents</li>
        <li>📊 De valider les taux</li>
        <li>✅ De délivrer un pré-accord</li>
      </ul>
    </p>

    <p>
      Le délai de retour de la banque varie beaucoup en fonction du nombre de dossiers à traiter et de la saisonnalité.
      On estime en général une première réponse sous <strong>10 jours</strong> (mais ce délai peut varier en période
      estivale, vacances scolaires et fêtes de fin d’année).
      <br />
      Votre expert se charge de relancer la banque pour accélérer les démarches et vous tient informé dès qu’il y a une
      avancée 😉 !
    </p>

    <p>
      En savoir plus sur la prescription :{' '}
      <Link
        href="https://www.pretto.fr/courtier-credit/courtier-immobilier-comment-ca-marche/prescription-definition-et-role/"
        onClick={onClickLink}
      >
        lire notre article
      </Link>
    </p>
  </NegotiationModal>
)
