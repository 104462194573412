import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { CheckHandwrite } from '@pretto/picto'

import styled from 'styled-components'

interface IlluProps {
  $path: string
}

interface CardProps {
  $bgColor: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${g(2)};
  background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    flex-direction: row;
  }
`

export const Image = styled(Illustration).attrs<IlluProps>(({ $path }: IlluProps ) => ({
  options: {
    height: ng(10),
    width: ng(10),
  },
  path: $path,
}))`
  margin-right: ${g(1)};
`

export const Text = styled.div`
  ${typo.caption12};
`

export const Title = styled.div`
  ${typo.bodyBold16};
`

export const Description = styled.div`
  ${typo.caption12};
  margin-top: ${g(1)};
`

export const CheckList = styled.div`
  display: flex;
  margin-top: ${g(2)};
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  ${typo.caption12};

  & + & {
    margin-left: ${g(2)};
  }
`

export const Valid = styled(CheckHandwrite)`
  margin-right: ${g(0.5)};
`

export const HeaderMobile = styled.div`
  display: flex;
`

export const Cta = styled(Button)`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: auto;
  }
`