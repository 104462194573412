import { createContext, PropsWithChildren, useContext } from 'react'

const PREFIX = 'experiment-'

interface ExperimentsContextInterface {
  experiments: Record<string, string>
  getExperiment: (key: string) => string | null
}

const getExperiments = (): Record<string, string> => {
  const list: Record<string, string> = {}
  for (const key in window.localStorage) {
    const value = window.localStorage.getItem(key)
    if (key.startsWith(PREFIX) && value) {
      list[key.replace(PREFIX, '')] = value
    }
  }
  return list
}

const getExperiment = (key: string): string | null => {
  return window.localStorage.getItem(`${PREFIX}${key}`)
}

export const ExperimentsContext = createContext<ExperimentsContextInterface>({} as ExperimentsContextInterface)

export const ExperimentsContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <ExperimentsContext.Provider
      value={{
        experiments: getExperiments(),
        getExperiment,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  )
}

export const useExperiments = () => useContext(ExperimentsContext)
