import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import path from 'path'
import qs from 'qs'

export const getNextUrl = (jumps, params) => {
  const nextSchema = funcToItem(jumps, params)

  if (typeof nextSchema === 'string') {
    return path.resolve('/onboarding', nextSchema)
  }

  if (Array.isArray(nextSchema)) {
    const [pathname, params = {}] = nextSchema

    return path.resolve('/onboarding', pathname + qs.stringify(params, { addQueryPrefix: true }))
  }

  return ''
}
