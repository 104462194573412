import NumberField from '@pretto/bricks/components/form/NumberField'
import Icon from '@pretto/bricks/components/iconography/Icon'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'
import { Component } from 'react'

import * as C from './EditableInput.module.css'

export default class EditableInput extends Component {
  static propTypes = {
    icon: PropTypes.string,
    variant: PropTypes.string,
    numberFieldProps: PropTypes.object,
  }

  static defaultProps = {
    variant: 'primary-1',
    icon: 'pen',
  }

  state = {
    isFocused: false,
  }

  handleBlur = () => {
    const {
      numberFieldProps: { onBlur },
    } = this.props
    if (onBlur) onBlur()
    this.setState({ isFocused: false })
  }
  handleFocus = () => {
    const {
      numberFieldProps: { onFocus },
    } = this.props
    if (onFocus) onFocus()
    this.setState({ isFocused: true })
  }

  handleFocusField = () => {
    this.numberfield.focus()
  }

  render() {
    const { icon, variant, numberFieldProps } = this.props
    const iconVariant = this.state.isFocused ? 'neutral-1' : 'inherit'

    return (
      <div className={classnames(C[`editableInput${pascalcase(variant ?? '')}`])}>
        <div className={C.editableInputField}>
          <div className={C.editableInputFieldNumberfield}>
            <NumberField
              {...numberFieldProps}
              format="editable-input"
              ref={numberfield => {
                this.numberfield = numberfield
              }}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              variant={variant}
            />
          </div>
          <div className={C.editableInputFieldCurrency} onClick={this.handleFocusField}>
            <SubHeading>€</SubHeading>
          </div>
          <div className={C.editableInputFieldIcon} onClick={this.handleFocusField}>
            <Icon name={icon} variant={iconVariant} />
          </div>
        </div>
      </div>
    )
  }
}
