import { COMMON_LABEL } from '@pretto/app-core/application/config/constants'

export const withOwner = decoratedFunction => (owner, mortgagors) => {
  const content = decoratedFunction()

  if (mortgagors.length === 1) {
    return content
  }

  switch (owner) {
    case 'both':
      return `${content} • ${COMMON_LABEL}`

    case 'comortgagor':
      return `${content} • ${mortgagors[1].name}`

    case 'mortgagor':
      return `${content} • ${mortgagors[0].name}`

    default:
      return content
  }
}
