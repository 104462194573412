// -----------------
// NoAccountPage ---
// -----------------
export const noAccountPage = {
  title: 'Votre espace client',
  alert:
    "Nous n'avons pas trouvé de compte associé à cette adresse email. Si vous êtes déjà client, veuillez ré-essayer avec une autre adresse, ou contactez votre conseiller.",
}

// -------------
// LoginPage ---
// -------------
export const loginPage = {
  noAccountProps: {
    signupUrl: '/signup',
    text: "Je n'ai pas encore de compte",
  },
  title: 'Ravis de vous revoir !',
}

export const connectionCard = link => ({
  buttonText: 'Se connecter',
  googleText: 'Se connecter avec Google',
  information: (
    <p>
      Pour votre sécurité, Pretto n’utilise pas de mot de passe. Vous recevrez un lien de connexion par email ({link}).
    </p>
  ),
  label: 'Connectez-vous avec votre email',
  placeholder: 'Adresse email',
  textConnect: '— Ou, avec votre adresse email —',
})
