import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import CardComponent from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

interface PictoProps {
  $picto: PictoType
}

export const Card = styled(CardComponent)`
  padding: ${g(3)} ${g(2.5)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }
`

export const Title = styled.h1`
  ${typo.heading32};
  margin-bottom: ${g(1)};
`

export const Description = styled.h2`
  ${typo.bodyBook16};
`

export const ProjectItem = styled.li`
  display: flex;
`

export const ProjectTypes = styled.ul`
  margin: ${g(3)} 0;
  display: flex;
  flex-direction: column;

  ${ProjectItem} + ${ProjectItem} {
    margin-top: ${g(2)};
  }
`

export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  flex-shrink: 0;
  margin-right: ${g(2)};
`

export const ItemDescription = styled.div`
`

export const Label = styled.p``

export const Caption = styled.p`
  ${typo.caption12};
`

export const Cta = styled(ButtonComponent).attrs({ isArrowShown: true })`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`
