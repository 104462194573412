import SectionProject from '@pretto/bricks/app/pre-lead/components/SectionProject'
import { SliderSection } from '@pretto/bricks/app/pre-lead/components/SliderSection'
import BannerNextStep from '@pretto/bricks/app/shared/components/BannerNextStep'
import { NextStep as NextStepContainer } from '@pretto/bricks/app/shared/components/BannerNextStep/styles'
import Button from '@pretto/bricks/components/buttons/Button'
import TextDivider from '@pretto/bricks/components/dividers/TextDivider'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Column, column, Grid, grid } from '@pretto/bricks/components/layout/Grid'
import styled, { keyframes } from 'styled-components'

const editIconAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  40% {
    transform: rotateZ(-10deg);
  }
  60% {
    transform: rotateZ(0deg);
  }
`
export const Container = styled.div`
  padding: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(5)};
  }
`
export const Sidebar = styled.div`
  padding: ${g(5)} ${g(2)} ${g(2)};
`
export const SidebarButton = styled(Button)`
  width: 100%;
  justify-content: center;
`
export const MortgagorInformation = styled.div`
  text-align: center;
`
export const SidebarAvatar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(1)};
`
export const EditIcon = styled.div``

export const Profile = styled.div`
  display: grid;
  grid-gap: ${g(1)};
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: center;

  &:hover {
    ${EditIcon} {
      animation: ${editIconAnimation} 1500ms ease-in-out infinite alternate;
    }
  }
`
export const MortgagorEmail = styled.div`
  margin-bottom: ${g(1)};
`
export const SidebarSeparator = styled.div`
  margin-top: ${g(2)};
  margin-bottom: ${g(3)};
`
export const CardContainer = styled.div`
  & + & {
    margin-top: ${g(4)};
  }
`
export const Content = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.neutral4};
`
export const Progress = styled.div`
  margin-top: ${g(2)};
  margin-bottom: ${g(3)};
`
export const ValidateProjectCard = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(1)};
    margin-bottom: ${g(3)};
  }
`
export const ValidateProjectCardTitle = styled.div`
  align-items: center;
  display: flex;
`
export const TitleTag = styled.div`
  margin-left: ${g(1)};
`
export const ValidateProjectCardContent = styled.div`
  color: ${({ theme }) => theme.colors.neutral1};
  margin-top: ${g(1)};
  margin-bottom: ${g(2)};
`
export const ProjectSection = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`
export const ProjectCards = styled.div`
  margin-bottom: ${g(2)};
  margin-top: ${g(2)};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
    margin-top: ${g(3)};
  }
`
export const ServiceCards = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    padding-bottom: ${g(8)};
    padding-top: ${g(2)};
    grid-gap: ${g(3)};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const MobileServiceCards = styled(SliderSection)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const Disconnect = styled.div`
  cursor: pointer;
`
export const Divider = styled(TextDivider)`
  background: ${({ theme }) => theme.colors.neutral4};
  padding-top: ${g(6)};
  padding-bottom: ${g(6)};
`
export const Project = styled(SectionProject)``

export const Main = styled(Column).attrs({ position: [1, 6] })`
  padding-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    padding-top: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`
export const NextStep = styled(BannerNextStep)`
  ${column([1, -1])};
  ${grid()};

  ${NextStepContainer} {
    ${column([2, 4])};

    @media screen and (min-width: ${breakpoints.tablet}) {
      ${column([2, 8])};
    }

    @media screen and (min-width: ${breakpoints.laptop}) {
      ${column([3, 10])};
    }
  }
`
