
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { TimesBold } from '@pretto/picto'

import styled from 'styled-components'

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
`

export const Content = styled.div`
  display: flex;
  padding: ${g(1.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: center;
    padding: ${g(1)};
  }
`

export const Icon = styled.div`
  margin-right: ${g(1)};
  padding-top: 3px;
`

export const Close = styled(TimesBold)`
  position: absolute;
  right: ${g(2)};
  fill: ${({ theme, $fill }) => theme.colors[$fill]};

  &:hover {
    cursor: pointer;
  }
`

export const Text = styled(BaseTypo).attrs({ forwardedAs: 'span' })`
  color: ${({ theme, $color }) => theme.legacy.colors[$color]};
  margin-right: ${g(4)};
`