import { ng } from '@pretto/bricks/components/layout'

export const NUMBER_OF_COLUMNS = {
  default: 4,
  laptop: 12,
  tablet: 8,
}

export const COLUMN_GAP = {
  default: 20,
  tablet: ng(3),
}
