import styled, { css } from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Scale = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
  border-radius: ${g(1)};
  height: ${g(6)};
  overflow: hidden;
  line-height: ${g(6)};
`
export const Rate = styled.div`
     position: relative;
     flex-grow: 1;
     margin-right: 1px;
     cursor: pointer;
     text-align: center;

     &:after {
       position: absolute;
       top: 0;
       right: -1px;
       bottom: 0;
       background: ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
       width: 1px;
       content: '';
     }

     &:last-child {
       margin-right: 0;
       border-right: 0;

       &:after {
         display: none;
       }
     }

     &:hover {
       background: ${({ theme }) => theme.legacy.colors.neutral1.light};
     }

     ${({ isSelected, theme }) =>
       isSelected &&
       css`
         &,
         &:hover {
           background: ${theme.legacy.colors.primary1.default};
           color: ${theme.legacy.colors.white.default};
         }
       `}

   }
`
