import Responsive from '@pretto/bricks/components/utility/Responsive'

import { getOffTimezone } from '@pretto/app/src/Booking/lib/getOffTimezone'
import { t } from '@pretto/app/src/lib/i18n'

import { Trans } from 'react-i18next'

import * as S from './Footer.styles'

interface FooterProps {
  isVisioEnabled?: boolean
}

export const Footer: React.FC<FooterProps> = ({ isVisioEnabled, ...props }) => {
  const timezone = getOffTimezone()

  return (
    <S.Footer {...props}>
      <div>{t('mutualizedAgenda.appointmentDuration')}</div>
      <Responsive min="tablet">
        <S.VerticalBar />
      </Responsive>
      {isVisioEnabled && (
        <S.VisioInfo>
          <S.FooterDot />
          {t('mutualizedAgenda.videoCallAvailable')}
        </S.VisioInfo>
      )}

      {timezone && (
        <>
          <Responsive min="tablet">
            <S.VerticalBar />
          </Responsive>

          <S.Timezone>
            <Trans i18nKey="mutualizedAgenda.localTime" values={{ timezone }} />
          </S.Timezone>
        </>
      )}
    </S.Footer>
  )
}
