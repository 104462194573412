import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
    border: solid 1px ${({ theme }) => theme.colors.neutral3};
  }
`
export const Content = styled.div`
  width: ${g(25)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`
export const Title = styled.div`
  ${typo.heading32};
  margin-bottom: ${g(2)};
`
export const Description = styled.div`
  ${typo.bodyBook16};
  margin-bottom: ${g(2)};
`

export const Illustration = styled(IllustrationComponent).attrs({
  height: ng(19),
  width: ng(19),
})`
  object-fit: contain;
  width: 100%;
  max-width: ${g(19)};
  max-height: ${g(19)};
  margin-left: ${g(3)};
`
