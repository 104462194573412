import { renderFields } from '@pretto/app-core/lib/renderFields'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

export const renderSection = (section, handleChange, values, data, ...args) => {
  const fields = renderFields(section.fields, handleChange, values, data.project, ...args)

  if (fields.length === 0) {
    return null
  }

  return {
    ...section,
    description: funcToItem(section.description, values, data, ...args),
    fields,
    title: funcToItem(section.title, values, data, ...args),
  }
}
