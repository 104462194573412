import Content from '@pretto/bricks/components/layout/Content'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const DashboardCardAlert = ({ picto, alertMessage, alertType, ...props }) => (
  <S.Alert type={alertType} {...props}>
    <Content>
      <S.Content>
        {picto && <S.Picto $picto={picto} />}
        <BaseTypo typo="bodyBook16">{alertMessage}</BaseTypo>
      </S.Content>
    </Content>
  </S.Alert>
)

DashboardCardAlert.defaultProps = {
  alertType: 'info',
}

DashboardCardAlert.propTypes = {
  /** Message to be displayed in the alert. Defines whether or not an alert should be displayed. */
  alertMessage: PropTypes.node,
  /** Defines the theme of the alert. Ignored if alertMessage is null. */
  alertType: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  /** picto to be diplayed in the alert. Ignored if alertMessage is null. */
  picto: PropTypes.string,
}

export default memo(DashboardCardAlert)
