import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export type ButtonInlineProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  disabled?: boolean
  href?: string
}

export const ButtonInline: React.FC<ButtonInlineProps> = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
)

interface ContainerProps {
  disabled?: boolean
  href?: string
  tabIndex?: number
}

export const Button = styled.span.attrs<ContainerProps>(({ disabled, href, tabIndex }) => ({
  as: !href ? 'button' : Link,
  disabled: !href ? disabled : undefined,
  tabIndex: disabled ? -1 : tabIndex,
}))<ContainerProps>`
  ${({ theme }) => theme.typos.linkUnderline};
  align-self: flex-start;
  appearance: none;
  border: none;
  color: inherit;
  display: inline-flex;
  padding: 0;

  &:not(:disabled) {
    cursor: pointer;
  }
`
