import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'

import * as S from './ServiceCardStore.styles'

export interface ServiceCardStoreProps {
  mobileOs: string
  onAppStoreClick: (event: MouseEvent) => void
  onGooglePlayClick: (event: MouseEvent) => void
}

export const ServiceCardStore = ({ onAppStoreClick, onGooglePlayClick, mobileOs }: ServiceCardStoreProps) => (
  <>
    <ResponsiveSSR min="laptop">
      <S.Container>
        <S.ButtonAppStore onClick={onAppStoreClick} />
        <S.ButtonGooglePlayStore onClick={onGooglePlayClick} />
      </S.Container>
    </ResponsiveSSR>
    <ResponsiveSSR max="laptop">
      <S.Container>
        {mobileOs === 'iOS' ? (
          <S.ButtonAppStore onClick={onAppStoreClick} />
        ) : (
          <S.ButtonGooglePlayStore onClick={onGooglePlayClick} />
        )}
      </S.Container>
    </ResponsiveSSR>
  </>
)
