import styled from 'styled-components'

const getIcon = ({ $name }) => {
  switch ($name) {
    case 'cross':
      return '\\61'

    case 'cross-circle':
      return '\\62'

    case 'arrow-left':
      return '\\65'

    case 'arrow-right':
      return '\\66'

    case 'calendar':
      return '\\67'

    case 'chat':
      return '\\68'

    case 'mail':
      return '\\69'

    case 'phone':
      return '\\6b'

    case 'check':
      return '\\63'

    case 'facebook':
      return '\\6a'

    case 'linkedin':
      return '\\6c'

    case 'file':
      return '\\6f'

    case 'exclamation-mark':
      return '\\70'

    case 'more':
      return '\\72'

    case 'chevron-left':
      return '\\73'

    case 'chevron-right':
      return '\\64'

    case 'pen':
      return '\\74'

    case 'menu':
      return '\\75'

    case 'info':
      return '\\76'

    case 'user':
      return '\\77'

    case 'locker-opened':
      return '\\78'

    case 'locker-closed':
      return '\\79'

    case 'arrow-full-bottom':
      return '\\7a'

    case 'arrow-full-top':
      return '\\41'

    case 'star':
      return '\\42'

    case 'clock':
      return '\\44'

    case 'check-2':
      return '\\43'

    case 'upload':
      return '\\6e'

    case 'home':
      return '\\71'

    case 'help':
      return '\\45'

    case '3-dots':
      return '\\46'

    case 'cross-circle-2':
      return '\\47'

    case 'check-circle':
      return '\\48'

    case 'add':
      return '\\49'

    case 'operation-equal':
      return '\\4a'

    case 'operation-minus':
      return '\\4b'

    case 'operation-plus':
      return '\\4c'

    case 'thumb-up':
      return '\\4e'

    case 'user-account':
      return '\\50'

    case 'share':
      return '\\51'

    case 'trash':
      return '\\52'

    case 'save':
      return '\\53'

    case 'whatsapp':
      return '\\54'

    case 'twitter':
      return '\\55'

    case 'folder':
      return '\\56'

    case 'folder-lines':
      return '\\57'

    case 'dash':
      return '\\4d'

    case 'filter':
      return '\\58'

    case 'text':
      return '\\59'

    case 'p-logo':
      return '\\5a'

    case 'trustpilot-star':
      return '\\30'

    case 'article':
      return '\\31'

    case 'link':
      return '\\32'

    case 'search':
      return '\\6d'

    case 'bookmark':
      return '\\4f'

    case 'graphs':
      return '\\33'

    case 'exclamation-mark-outline':
      return '\\34'

    case 'legal':
      return '\\35'

    case 'localisation':
      return '\\36'

    case 'user-2':
      return '\\37'

    case 'euro':
      return '\\38'

    case 'notification':
      return '\\39'

    case 'link-2':
      return '\\21'

    case 'mail-2':
      return '\\22'

    case 'share-2':
      return '\\23'

    case 'instagram':
      return '\\24'

    case 'youtube':
      return '\\25'

    case 'user-line':
      return '\\26'

    case 'bars':
      return '\\27'

    case 'toast':
      return '\\28'

    case 'cloud':
      return '\\29'

    case 'calendar-line':
      return '\\2a'

    case 'flash':
      return '\\2b'

    case 'bubble':
      return '\\2c'

    case 'folder-line':
      return '\\2d'

    case 'shield':
      return '\\2e'

    case 'check-handwrite':
      return '\\2f'

    case 'user-stars':
      return '\\3a'

    case 'star-line':
      return '\\3b'

    case 'pencil':
      return '\\3c'

    case 'search-line':
      return '\\3d'

    case 'check-line':
      return '\\3e'

    case 'menu-line':
      return '\\3f'

    case 'quote':
      return '\\40'

    case 'chevron-right-2':
      return '\\5b'

    case 'chevron-left-2':
      return '\\5d'

    default:
      return ''
  }
}

export const Icon = styled.span`
  align-items: center;
  color: ${({ theme, variant }) => theme.legacy.colors[variant]};
  display: flex;
  font-family: ${({ theme }) => theme.legacy.fonts.bricks} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  justify-content: center;
  line-height: 1;
  speak: none;
  text-transform: none !important;
  transition: color 150ms linear;

  &:before {
    content: '${getIcon}';
  }
`
