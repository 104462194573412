import deburr from 'lodash/deburr'

export const OTHER_VALUE = 'Autre'

export const sortBanks =
  (shouldSortOther = true) =>
  ({ value: valueA }, { value: valueB }) => {
    const formattedValueA = deburr(valueA).toLowerCase()
    const formattedValueB = deburr(valueB).toLowerCase()

    if (formattedValueA < formattedValueB || (!shouldSortOther && valueB === OTHER_VALUE)) {
      return -1
    }

    if (formattedValueA > formattedValueB) {
      return 1
    }

    return 0
  }
