import { MouseEvent } from 'react'

import * as S from './SwitchToggle.styles'

export interface SwitchToggleProps {
  id: string
  isToggled: boolean
  label?: string
  onToggle: (event: MouseEvent) => void
}

export const SwitchToggle = ({ id, isToggled = false, label, onToggle, ...props }: SwitchToggleProps) => {
  const handleToggle = (event: MouseEvent) => {
    onToggle(event)
  }

  return (
    <S.SwitchToggleContainer {...props}>
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
      <S.ButtonWrapper $isToggled={isToggled} aria-checked={isToggled} id={id} onClick={handleToggle} role="switch">
        <S.Button $isToggled={isToggled} />
      </S.ButtonWrapper>
    </S.SwitchToggleContainer>
  )
}
