import { OptionsEdit, PropertySearchCard } from '@pretto/app/src/SharedContainers/Dashboard/PropertySearchCard'

import { useContext } from 'react'

import { PreLeadContext } from '../PreLeadPage'

const CARD_KIND = 'property_search'

export const PropertySearch = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { onTrackProjectCard } = useContext<any>(PreLeadContext)

  const handleTrackEdit = ({ status }: OptionsEdit) => {
    onTrackProjectCard?.(CARD_KIND, status === 'alert' ? 'success' : 'default')
  }

  return <PropertySearchCard onTrackEdit={handleTrackEdit} />
}
