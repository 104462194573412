import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const showOnDesktop = css`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`

export const DialogContent = styled.div`
  padding: ${g(1.5)} ${g(1.5)} 0 ${g(1.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(3)} 0 ${g(3)};
  }

  a {
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
  }
`

export const Content = styled.div`
  max-height: calc(var(--vh, 1vh) * 100 - ${g(20)});
  overflow-y: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-height: calc(var(--vh, 1vh) * 100 - ${g(27.5)});
  }
`

export const TableScroll = styled.div`
  margin-top: ${g(2)};
  overflow-x: auto;
`

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 12px;
`

export const ColumnHeading = styled.th`
  background: ${({ theme }) => theme.legacy.colors.white.default};
  border-width: 2px;
  color: ${({ theme }) => theme.legacy.colors.neutral1.default};
  font-weight: 600;
  text-align: left;

  &:first-child {
    min-width: ${g(14)};
  }

  ${props =>
    props.showOnDesktop &&
    css`
      display: none;
      @media screen and (min-width: ${breakpoints.tablet}) {
        display: table-cell;
      }
    `}
`

export const Column = styled.td`
  display: none;
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: table-cell;
  }
`

export const RowHeading = styled.th`
  font-weight: normal;
  text-align: left;
`

export const Row = styled.tr`
  th,
  td {
    border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
    vertical-align: top;
    padding: ${g(1)} ${g(1.5)};
  }

  td {
    border-top: none;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${g(2)} 0;
  align-items: center;

  button {
    &:last-child {
      margin-right: ${g(10)};

      @media only screen and (min-width: ${breakpoints.tablet}) {
        margin-right: 0;
      }
    }
  }
`

export const CancelButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: ${g(1.5)};
  margin: ${g(2.5)} ${g(2.5)} ${g(2.5)} 0;
  text-decoration: underline;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${ng(2) - 2}px;
    margin: 0 ${g(3)} 0 0;
  }
`
