import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { memo } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const FormList = ({ fields, ...props }) => (
  <TransitionGroup {...props}>
    {fields.map(({ component, key }) => (
      <CollapsibleRow childComponent={S.FieldChild} key={key} parentComponent={S.FieldParent}>
        {component}
      </CollapsibleRow>
    ))}
  </TransitionGroup>
)

FormList.propTypes = {
  fields: PropTypes.array.isRequired,
}

export default memo(FormList)
