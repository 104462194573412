import PropTypes from 'prop-types'

import * as S from './styles'

const InputCheckbox = ({ id, inputProps, isChecked, isError, isFocusable, label, onChange, ...props }) => (
  <S.InputCheckbox {...props}>
    <S.Checkbox $isChecked={isChecked} $isError={isError}>
      <S.Input
        checked={isChecked}
        id={id}
        onChange={onChange}
        tabIndex={isFocusable ? '0' : '-1'}
        type="checkbox"
        {...inputProps}
      />
      <S.Picto />
    </S.Checkbox>

    {label && <S.Label htmlFor={id}>{label}</S.Label>}
  </S.InputCheckbox>
)

InputCheckbox.propTypes = {
  /** ID of the input - Is Required when there is a label */
  id: PropTypes.string,
  /** Props of the input component : onBlur, value, others... */
  inputProps: PropTypes.object,
  /** Checkbox is checked if true. */
  isChecked: PropTypes.bool.isRequired,
  /** Is checkbox has error state. */
  isError: PropTypes.bool,
  /** Whether or not the checkbox can be focused via keyboard navigation. */
  isFocusable: PropTypes.bool,
  /** Checkbox label. */
  label: PropTypes.node,
  /** Triggered when the checkbox is checked / unchecked. */
  onChange: PropTypes.func,
}

InputCheckbox.defaultProps = {
  isChecked: false,
  isError: false,
  isFocusable: true,
}

export default InputCheckbox
