import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './styles'

const ListItem = ({ fields, onDelete, title }) => (
  <S.ListItem>
    <Card format="line" variant="neutral-1-20">
      <S.ListItemHeader>
        <Content size="x-small" variant="neutral-1">
          <S.ListItemHeaderContentActions>
            <SubHeading size="small">{title}</SubHeading>

            <S.ListItemHeaderContentAction onClick={onDelete}>
              <Icon name="cross" />
            </S.ListItemHeaderContentAction>
          </S.ListItemHeaderContentActions>
        </Content>
      </S.ListItemHeader>

      <Content>
        {fields.map(({ component, key }) => (
          <S.Field key={key}>{component}</S.Field>
        ))}
      </Content>
    </Card>
  </S.ListItem>
)

const ApplicationCompleteListField = ({ items }) => (
  <div>
    <TransitionGroup component={Fragment}>
      {items.map(({ id, ...item }) => (
        <CollapsibleRow key={id} parentComponent={S.ListItemParent}>
          <ListItem {...item} />
        </CollapsibleRow>
      ))}
    </TransitionGroup>
  </div>
)

ApplicationCompleteListField.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default ApplicationCompleteListField
