import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const Text = styled(BaseTypo)`
  margin-top: ${g(8)};
  max-width: 90%;
  text-align: center;
`
