import * as filters from '@pretto/app-core/lib/filters'

export const propertySection = {
  fields: [
    {
      fieldProps: {
        options: [
          { label: 'un logement ancien', value: 'old' },
          { label: 'un logement neuf achevé', value: 'new' },
          { label: 'une VEFA (vente sur plan)', value: 'VEFA' },
          { label: 'une construction', value: 'construction' },
          { label: 'un terrain et une construction', value: 'land_construction' },
        ],
        placeholder: 'Type de bien',
      },
      label: "De quel type de bien s'agit-il ?",
      name: 'good.property_kind',
      required: true,
      type: 'select',
    },
    {
      condition: values => ['new', 'VEFA', 'old'].includes(values['good.property_kind']),
      fieldProps: {
        placeholder: '200 000',
      },
      label: 'Prix du bien',
      name: 'purchase.property_price',
      required: true,
      type: 'numberfield',
    },
    {
      condition: values => values['good.property_kind'] === 'old',
      fieldProps: {
        options: [
          { id: 'w1t', label: 'Oui', value: 'true' },
          { id: 'w1f', label: 'Non', value: 'false' },
        ],
      },
      label: 'Souhaitez-vous financer des travaux ?',
      name: 'including_works_price',
      required: true,
      type: 'radio',
    },
    {
      condition: ({ including_works_price }) => including_works_price === 'true',
      fieldProps: {
        placeholder: '50 000',
      },
      label: 'Montant des travaux',
      name: 'purchase.works_price',
      required: true,
      type: 'numberfield',
    },
    {
      condition: values => ['construction', 'land_construction'].includes(values['good.property_kind']),
      fieldProps: {
        placeholder: '200 000',
      },
      label: 'Coût de la construction',
      name: 'purchase.build_price',
      required: true,
      type: 'numberfield',
    },
    {
      condition: values => values['good.property_kind'] === 'land_construction',
      fieldProps: {
        placeholder: '100 000',
      },
      label: 'Prix du terrain (frais d’agence compris)',
      name: 'purchase.land_price',
      required: true,
      type: 'numberfield',
    },
  ],
  title: 'Informations sur le bien',
}

const agreementSignDateField = {
  fieldProps: {
    placeholder: 'JJ / MM / AAAA',
  },
  label: 'Date de signature du compromis',
  name: 'purchase.agreement_sign_date',
  required: true,
  type: 'date',
}

const decoder = filters.applyFilters([
  filters.applyFilter(
    'future_agreement_sign_date',
    (value, values) => (values['purchase.agreement_sign_date'] ? 'true' : null),
    false
  ),
  filters.applyFilter(
    'including_works_price',
    (value, values) => (values['purchase.works_price'] ? 'true' : null),
    false
  ),
  filters.applyFilter('purchase.agreement_sign_date', filters.formatDecodeDate),
])

const encoder = filters.applyFilters([
  filters.applyOmitFilter('future_agreement_sign_date', 'including_works_price'),
  filters.applyFilter('purchase.agreement_sign_date', filters.formatEncodeDate),
])

export const pages = [
  {
    decoder,
    encoder,
    sections: [propertySection],
    value: 'offer_made',
  },
  {
    decoder,
    encoder,
    sections: [
      {
        fields: [
          {
            fieldProps: {
              options: [
                { id: 'a1t', label: 'Oui', value: 'true' },
                { id: 'a1f', label: 'Non', value: 'false' },
              ],
            },
            label: 'Connaissez-vous la date de signature du compromis ?',
            name: 'future_agreement_sign_date',
            required: true,
            type: 'radio',
          },
          {
            condition: ({ future_agreement_sign_date }) => future_agreement_sign_date === 'true',
            ...agreementSignDateField,
          },
        ],
        title: 'Compromis de vente',
      },
      propertySection,
    ],
    value: 'offer_accepted',
  },
  {
    decoder,
    encoder,
    sections: [
      {
        fields: [agreementSignDateField],
        title: 'Compromis de vente',
      },
      propertySection,
    ],
    value: 'sale_agreement_signed',
  },
]
