import { FEES } from '@pretto/app/src/overview/config/rows'
import type { Row } from '@pretto/app/src/overview/types/row'

import groupBy from 'lodash/groupBy'

export const groupRows = (rows: Row[]): Row[][] =>
  Object.values(
    groupBy(rows, ({ path }) => {
      if (FEES.some(fee => fee === path)) {
        return 'fees'
      }

      if (['best_result.financing.loanAmount', 'best_result.financing.ptz.amount'].includes(path)) {
        return 'loanAmount'
      }

      return path
    })
  )
