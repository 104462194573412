import styled from 'styled-components'

import { column, grid } from './'

export const Column = styled.div`
  ${({ $isEndAbsolute, $position }) => column($position, $isEndAbsolute)}
`

export const Grid = styled.div`
  ${({ $numberOfColumns, $columnSpan: columnSpan, $isLeftAligned: isLeftAligned, $isRightAligned: isRightAligned }) =>
    grid($numberOfColumns, { columnSpan, isLeftAligned, isRightAligned })}
`
