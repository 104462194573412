import fuzzysort from 'fuzzysort'
import latinize from 'latinize'

export const searchArray = (value, array, key) => {
  const latinizedArray = key
    ? array.map(item => ({ ...item, [key]: latinize(item[key]) }))
    : array.map(value => latinize(value))

  const sorted = fuzzysort.go(latinize(value), latinizedArray, { key })

  return sorted.map(({ obj: { value } }) => array.find(elem => elem.value === value))
}
