import { useClickOutside } from '@pretto/bricks/assets/utility/useClickOutside'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { TimesBold } from '@pretto/picto'

import { memo } from 'react'
import { createPortal } from 'react-dom'

import * as S from './styles'

interface BookingVisioProps {
  advisorPicture?: string
  appointmentDate: string
  isOpen: boolean
  isVisioMandatory: boolean
  onClickPhone: () => void
  onClickVisio: () => void
  onClose: () => void
}

export const BookingVisioModal = memo(
  ({
    advisorPicture,
    appointmentDate,
    isOpen,
    isVisioMandatory,
    onClickPhone,
    onClickVisio,
    onClose,
    ...props
  }: BookingVisioProps) => {
    const popUpRef = useClickOutside(() => {
      onClose()
    })

    return createPortal(
      <ThemeProvider designSystem="reveal">
        <S.Overlay $isOpen={isOpen} {...props}>
          <S.PopUp $isOpen={isOpen} ref={popUpRef}>
            <S.TitleContainer>
              <S.CloseButton onClick={onClose}>
                <TimesBold />
              </S.CloseButton>

              <S.TitleRow>
                <S.Title>Votre rendez-vous</S.Title>
              </S.TitleRow>
            </S.TitleContainer>

            <S.ExpertContainer>
              {advisorPicture && <S.AdvisorPicture path={advisorPicture} />}

              <S.DescriptionContainer>
                <S.Description>
                  Confirmez votre RDV pour le <S.BoldDescription>{appointmentDate}.</S.BoldDescription>
                </S.Description>

                <S.Description>Profitez d’une expérience optimisée grâce à la visio !</S.Description>
              </S.DescriptionContainer>
            </S.ExpertContainer>

            <S.ActionsContainer>
              <S.SecondaryButton scheme="black" onClick={onClickVisio}>
                Prendre RDV en visio
              </S.SecondaryButton>

              {!isVisioMandatory && <S.PhoneButton onClick={onClickPhone}>Je préfère par téléphone</S.PhoneButton>}
            </S.ActionsContainer>
          </S.PopUp>
        </S.Overlay>
      </ThemeProvider>,
      document.body
    )
  }
)

BookingVisioModal.displayName = 'BookingVisioModal'
