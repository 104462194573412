import { g, tablet } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'
import styled from 'styled-components'

export const Alert = styled.div`
  margin-bottom: ${g(4)};

  a {
    text-decoration: underline;

    &:focus,
    &:hover {
      outline: 0px transparent;
      text-decoration: none;
    }
  }
`

export const Button = styled.div`
  padding-top: ${g(4)};
  text-align: center;

  @media only screen and (min-width: ${tablet.min}) {
    padding-right: 40%;
    padding-top: ${g(6)};
  }
`

export const Title = styled.div`
  margin-bottom: ${g(4)};
`

export const Wrapper = styled(W)`
  padding-top: ${g(2)};
  padding-bottom: ${g(5)};

  @media only screen and (min-width: ${tablet.min}) {
    padding-top: ${g(4)};
    padding-bottom: ${g(7)};
  }
`
