import isNil from 'lodash/isNil'
import { MouseEvent } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './Button.styles'

export interface ButtonProps {
  children: React.ReactNode
  href?: string
  isArrowShown?: boolean
  isLoading?: boolean
  isSecondary?: boolean
  onClick?: (event: MouseEvent) => void
}

export const Button = ({
  children,
  isArrowShown = false,
  isLoading = false,
  href,
  isSecondary = false,
  onClick,
  ...props
}: ButtonProps) => {
  const _isLink = !isNil(href)
  const _isLoading = !_isLink && isLoading

  return (
    <S.Button $isSecondary={isSecondary} onClick={onClick} href={href} {...props}>
      <S.Label $isLoading={_isLoading}>{children}</S.Label>

      {(_isLink || isArrowShown) && <S.Arrow $isLoading={_isLoading} />}

      <Transition
        in={_isLoading}
        timeout={{ enter: S.TRANSITION_DELAY, exit: S.TRANSITION_DURATION_OUT }}
        unmountOnExit
      >
        <S.Spinner $isLoading={_isLoading} $isSecondary={isSecondary} />
      </Transition>
    </S.Button>
  )
}
