import { g, ng } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Icon = styled.span`
  position: absolute;
`

export const Tag = styled.span`
  background-color: ${({ theme, variant }) => theme.colors[variant] ?? theme.legacy.colors[variant].default};
  color: ${({ textVariant, theme, variant }) =>
    theme.colors[textVariant] ??
    theme.legacy.colors[textVariant]?.default ??
    theme.legacy.colors[variant].opposite.default};
  display: inline-block;
  font: inherit;
  line-height: inherit;
  max-width: 100%;
  overflow: hidden;
  padding: 0 ${g(2)};
  text-overflow: ellipsis;
  white-space: nowrap;

  button& {
    cursor: pointer;
    position: relative;

    &:focus {
      outline: 1px dashed ${({ theme }) => theme.legacy.colors.primary1.default};
    }
  }

  ${({ size }) => {
    switch (size) {
      case 'x-small':
        return css`
          border-radius: ${ng(2) - 4}px;
          padding: 0 ${g(1)};
          line-height: ${g(2)};
          font-size: ${ng(2) - 4}px;
        `

      case 'small':
        return css`
          border-radius: ${ng(2) - 2}px;
          line-height: ${g(3)};
          font-size: ${ng(2) - 2}px;

          button& {
            padding-right: ${g(7 / 2)};
          }

          ${Icon} {
            font-size: ${g(2)};
            right: ${g(1 / 2)};
            top: ${g(1 / 2)};
          }
        `

      case 'medium':
        return css`
          border-radius: ${g(2)};
          line-height: ${g(3)};
          font-size: ${g(2)};

          button& {
            padding-right: ${g(7 / 2)};
          }

          ${Icon} {
            right: ${g(1 / 2)};
            top: ${g(1 / 2)};
          }
        `

      case 'large':
        return css`
          border-radius: ${g(2)};
          font-size: ${g(2)};
          height: ${g(4)};
          line-height: ${g(4)};

          button& {
            padding-right: ${g(4)};
          }

          ${Icon} {
            right: ${g(1)};
            top: ${g(1)};
          }
        `

      default:
        return null
    }
  }}
`
