import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { useEmailField } from '@pretto/app/src/Form/lib/useField'
import Front from '@pretto/app/src/Helpers/frontchat'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { CREATE_ACCOUNT } from '@pretto/app/src/apollo'
import { EMPTY_FUNCTION } from '@pretto/app/src/lib/constants'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { emailField } from '@pretto/app/src/purchaseOffer/config/email'
import { FieldsValues } from '@pretto/app/src/purchaseOffer/config/types'
import { encodeData } from '@pretto/app/src/purchaseOffer/lib/encoder'
import { getMessage } from '@pretto/app/src/purchaseOffer/lib/getMessage'
import { prefill } from '@pretto/app/src/purchaseOffer/lib/prefill'
import { useGoodInformation } from '@pretto/app/src/purchaseOffer/lib/useGoodInformation'
import { useOffer } from '@pretto/app/src/purchaseOffer/lib/useOffer'
import { usePersonnalInformation } from '@pretto/app/src/purchaseOffer/lib/usePersonnalInformation'
import { PurchaseOffer, PurchaseOfferProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/PurchaseOffer'

import { useApolloClient, useMutation } from '@apollo/client'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

const TIMEOUT = 250

export const PurchaseOfferPage = ({ history, location }: RouteComponentProps) => {
  const client = useApolloClient()
  const { isLoggedIn, signIn, signOut } = useAuth()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { email: queryEmail, authorized, signup_kind } = params
  const { user } = useUser()
  const trackAction = useTracking()
  const [createAccount] = useMutation(CREATE_ACCOUNT)
  const frontStatus = useBreakpointToValue('hide', { desktop: 'show' })

  const isModalDefaultOpen = !(authorized || (isLoggedIn && queryEmail === user.email))
  const suggestedEmail = isLoggedIn ? user.email : ''

  const [isModalOpen, setIsModalOpen] = useState(isModalDefaultOpen)
  const [isModalButtonLoading, setIsModalButtonLoading] = useState(false)
  const [isFormButtonLoading, setIsFormButtonLoading] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  useEffect(() => {
    if (isSnackbarOpen) {
      Front(frontStatus)
    } else {
      Front('show')
    }
  }, [frontStatus, isSnackbarOpen])

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
  }

  const handleClickSnackbarButton = () => {
    const query = prefill(
      // fields here should have non-null value since this code is executed after data validation
      // but because it's still inheriting from fields initial value type, it does not match the prefill signature
      fields as FieldsValues
    )

    history.push({ pathname: '/project/purchase/introduction', search: query })
  }

  const handleSuggestionClick = () => {
    redirect(suggestedEmail)
    setIsModalOpen(false)
  }

  const trackLegalLink = (source: string) => {
    trackAction('PURCHASE_OFFER_LEGAL_LINKS_CLICKED', { purchase_offer_legal_link: source })
  }
  const handleClickCgu = () => {
    trackLegalLink('cgu')
  }
  const handleClickPrivacy = () => {
    trackLegalLink('privacy')
  }

  const defaultEmailProps = {
    ...emailField,
    defaultValue: typeof queryEmail === 'string' ? queryEmail : '',
  }

  const emailProps = isLoggedIn
    ? {
        ...defaultEmailProps,
        message: getMessage({ email: suggestedEmail, onClick: handleSuggestionClick }),
        state: 'warning',
      }
    : defaultEmailProps

  const { component: emailComponent, error: emailError, value: email } = useEmailField(emailProps)

  const { fields: personnalInformationFields, section: personnalInformationSection } = usePersonnalInformation()
  const { fields: goodInformationFields, section: goodInformationSection } = useGoodInformation()
  const { fields: offerFields, section: offerSection } = useOffer()

  const fields = {
    ...personnalInformationFields,
    ...goodInformationFields,
    ...offerFields,
  }

  const redirect = (paramEmail = email, authorized = false) => {
    history.replace(`/purchase-offer?email=${encodeURIComponent(paramEmail)}${authorized ? '&authorized=true' : ''}`)
  }

  const handleValidateEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsModalButtonLoading(true)

    if (email === suggestedEmail) {
      setIsModalOpen(false)
      return
    }

    const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
    const attributionCookieLastClick = getAttributionCookieLastClickValue()
    const visitorId = await getVisitorId()

    const {
      data: {
        create_account: { token: accessToken, error: createAccountError },
      },
    } = await createAccount({
      variables: {
        attributionCookieFirstClick,
        attributionCookieLastClick,
        email,
        source: 'purchase_offer',
        visitorId,
      },
    })

    if (isLoggedIn && createAccountError === 'account_already_exists') {
      return signOut(() => {
        redirect(email, true)
      })
    }

    if (accessToken) {
      trackAction('SIGNED_UP', {
        signup_assign_project: false,
        signup_kind: `purchase_offer_website_${signup_kind}`,
        signup_newsletter_immo: false,
        signup_newsletter_taux: false,
        signup_property_search_alert: false,
      })

      signIn({ accessToken }, EMPTY_FUNCTION, [], false)
      redirect()
      invalidateCache(client)
    }

    setIsModalButtonLoading(false)
    setIsModalOpen(false)
  }

  const handleClickBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    trackAction('PURCHASE_OFFER_RETURN_CTA_CLICKED')
    window.close()

    if (!window.closed) {
      history.goBack()
    }
  }

  const handleDownloadPDF = async () => {
    trackAction('PURCHASE_OFFER_PDF_CLICKED')
    setIsFormButtonLoading(true)
    const url = 'https://pdf.pretto.fr/purchase-offer'

    const data = encodeData({
      email: isLoggedIn ? suggestedEmail : email,
      // fields here should have non-null value since this code is executed after data validation
      // but because it's still inheriting from fields initial value type, it does not match the prefill signature
      fields: fields as FieldsValues,
    })

    const body = {
      data,
    }
    const headers = {
      Accept: 'application/pdf',
      'Content-Type': 'application/json',
    }
    const params = {
      body: JSON.stringify(body),
      headers,
      method: 'POST',
    }
    const { lastName, cobuyerLastName } = fields
    const buyers = [lastName.value, cobuyerLastName?.value].reduce((previous, name, i) => {
      if (!name) return previous

      const uppercasedName = name.replace(/[\s']/g, '_').toUpperCase()

      if (i === 0) return uppercasedName

      return `${previous}_${uppercasedName}`
    }, '')
    const response = await fetch(url, params)
    const filename = `offre_achat_${buyers}.pdf`
    const blob = await response.blob()

    const objUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = objUrl
    link.download = filename
    link.click()
    setIsFormButtonLoading(false)
    setIsSnackbarOpen(true)

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl)
    }, TIMEOUT)
  }

  const handleDownloadWord = () => {
    trackAction('PURCHASE_OFFER_WORD_CLICKED')
    setIsSnackbarOpen(true)
  }

  const isButtonDisabled = Object.values(fields).reduce((previous, field) => false || (field?.error ?? false), false)

  const props: PurchaseOfferProps = {
    isButtonDisabled,
    isButtonLoading: isFormButtonLoading,
    isModalOpen,
    isSnackbarOpen,
    onCloseSnackbar: handleCloseSnackbar,
    onClickSnackbarButton: handleClickSnackbarButton,
    modalProps: {
      emailComponent,
      isButtonDisabled: emailError || email === '',
      isButtonLoading: isModalButtonLoading,
      onClickBack: handleClickBack,
      onClickCgu: handleClickCgu,
      onClickContinue: handleValidateEmail,
      onClickPrivacy: handleClickPrivacy,
    },
    onDownloadPDF: handleDownloadPDF,
    onDownloadWord: handleDownloadWord,
    sections: [personnalInformationSection, goodInformationSection, offerSection],
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <PurchaseOffer {...props} />
    </>
  )
}
