import { ApolloClient, createHttpLink, fromPromise, gql, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { sha256 } from 'crypto-hash'

import * as helpers from '../Auth/lib/helpers'
import { HTTP_DEFAULT_RETRY_DELAY, HTTP_STATUS_RATE_LIMITED, HTTP_STATUS_UNAUTHORIZED } from '../lib/constants'

class ErrorUnauthorized extends Error {}

let isRefreshing = false
let pendingRequests = []

const errorLink = onError(({ networkError, forward, operation }) => {
  if (!helpers.isLoggedIn() || networkError?.statusCode !== HTTP_STATUS_UNAUTHORIZED) {
    return
  }

  if (isRefreshing) {
    return fromPromise(
      new Promise(resolve => {
        pendingRequests.push(resolve)
      })
    ).flatMap(() => forward(operation))
  }

  isRefreshing = true

  return fromPromise(
    client
      .query({
        context: { headers: null },
        fetchPolicy: 'network-only',
        query: gql`
          query RefreshToken($refreshToken: String!) {
            refreshJWTToken(refreshToken: $refreshToken) {
              accessToken
              error
              refreshToken
            }
          }
        `,
        variables: {
          refreshToken: helpers.getRefreshToken() ?? '',
        },
      })
      .then(
        ({
          data: {
            refreshJWTToken: { accessToken, error, refreshToken },
          },
        }) => {
          if (error) {
            throw new ErrorUnauthorized()
          }

          helpers.persistTokens({ accessToken, refreshToken })

          operation.setContext(({ headers }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${accessToken}`,
            },
          }))

          pendingRequests.forEach(resolve => {
            resolve()
          })
        }
      )
      .catch(error => {
        if (error instanceof ErrorUnauthorized) {
          helpers.flushTokens()
          window.location.href = '/login?expired=true'

          // eslint-disable-next-line no-empty-function
          return new Promise(function () {})
        }
      })
      .finally(() => {
        isRefreshing = false
        pendingRequests = []
      })
  ).flatMap(() => forward(operation))
})

const retryLink = new RetryLink({
  attempts: {
    retryIf: error => error?.statusCode === HTTP_STATUS_RATE_LIMITED,
  },
  delay: (_count, _operation, error) =>
    Number(error.response.headers.get('retry-after')) * 1000 || HTTP_DEFAULT_RETRY_DELAY,
})

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.API_GATEWAY_GRAPHQL_URL,
})

const middlewareLink = setContext(
  (
    request,
    {
      headers = {
        authorization: helpers.isLoggedIn()
          ? `Bearer ${helpers.getAuthToken()}`
          : `Visitor ${helpers.getVisitorUUID()}`,
      },
    }
  ) => ({
    headers,
  })
)

const link = createPersistedQueryLink({ sha256, useGETForHashedQueries: true })
  .concat(middlewareLink)
  .concat(errorLink)
  .concat(retryLink)
  .concat(httpLink)

const cache = new InMemoryCache({
  typePolicies: {
    Availabilities: {
      fields: {
        specificDayAndTime: {
          merge: true,
        },
      },
    },
    Bridge: {
      fields: {
        localisation: {
          merge: true,
        },
      },
    },
    Deal: {
      fields: {
        application: {
          merge: true,
        },
        mandateStatus: {
          merge: true,
        },
        sepaStatus: {
          merge: true,
        },
      },
    },
    DealMandateStatus: {
      fields: {
        status: {
          merge: true,
        },
      },
    },
    DocsList: {
      fields: {
        meta: {
          merge: true,
        },
      },
    },
    Facts: {
      fields: {
        bridge_credit: {
          merge: true,
        },
        detailed_charges: {
          merge: true,
        },
        max_indebtedness_rate: {
          merge: true,
        },
        max_payment: {
          merge: true,
        },
        ptz: {
          merge: true,
        },
      },
    },
    Financing: {
      fields: {
        bridge: {
          merge: true,
        },
        monthlyDetailedWeightedIncomes: {
          merge: true,
        },
        ptz: {
          merge: true,
        },
        weightedIncomes: {
          merge: true,
        },
      },
    },
    Good: {
      fields: {
        localisation: {
          merge: true,
        },
      },
    },
    Job: {
      fields: {
        address: {
          merge: true,
        },
      },
    },
    Mortgagor: {
      fields: {
        address: {
          merge: true,
        },
        birthplace: {
          merge: true,
        },
        bonus: {
          merge: true,
        },
        facts: {
          merge: true,
        },
        job: {
          merge: true,
        },
        salary: {
          merge: true,
        },
      },
    },
    Profile: {
      fields: {
        facts: {
          merge: true,
        },
      },
    },
    Project: {
      fields: {
        facts: {
          merge: true,
        },
        good: {
          merge: true,
        },
        profile: {
          merge: true,
        },
        purchase: {
          merge: true,
        },
        renegotiation: {
          merge: true,
        },
        request: {
          merge: true,
        },
      },
    },
    Property: {
      fields: {
        localisation: {
          merge: true,
        },
      },
    },
    Purchase: {
      fields: {
        bridge: {
          merge: true,
        },
        notary: {
          merge: true,
        },
      },
    },
    Renegotiation: {
      fields: {
        facts: {
          merge: true,
        },
      },
    },
    Request: {
      fields: {
        contribution: {
          merge: true,
        },
        synthesis: {
          merge: true,
        },
      },
    },
    Result: {
      fields: {
        scoreIndebtednessRate: {
          merge: true,
        },
      },
    },
    ScoreFlags: {
      fields: {
        appointmentAllowed: {
          merge: true,
        },
        pushy: {
          merge: true,
        },
        callBackNowAllowed: {
          merge: true,
        },
      },
    },
  },
})

const client = new ApolloClient({
  cache,
  link,
  name: 'pretto-app-web-client',
  queryDeduplication: false,
  version: '1.0',
})

export default client
