import { numeralBreakpoints } from '@pretto/bricks/components/layout'

import { useEffect, useState } from 'react'

import { getBreakpointForWindowWidth } from './getBreakpointForWindowWidth'

type Breakpoint = keyof typeof numeralBreakpoints

export const useBreakpoint = (
  breakpoints: Breakpoint[] = Object.keys(numeralBreakpoints) as Breakpoint[]
): Breakpoint | null => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(
    typeof window === 'undefined' ? null : getBreakpointForWindowWidth(window.innerWidth, breakpoints)
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize, false)

    resize()

    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [])

  const handleResize = () => {
    resize()
  }

  const resize = () => {
    const breakpoint = getBreakpointForWindowWidth(window.innerWidth, breakpoints)

    setBreakpoint(breakpoint)
  }

  return breakpoint
}
