import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import { Project, SolicitedBank } from '@pretto/app/src/types/gateway/schema'

import isEmpty from 'lodash/isEmpty'

export const mapCompetition: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)

  const solicitedBank: SolicitedBank = {}

  if (context.competitionBank) solicitedBank.bank_slug = context.competitionBank
  if (context.competitionDuration) solicitedBank.duration = context.competitionDuration
  if (context.competitionId) solicitedBank.id = context.competitionId
  if (context.competitionRate) solicitedBank.rate = context.competitionRate

  if (!isEmpty(solicitedBank)) updatedPayload.solicited_banks = [solicitedBank]

  return {
    context,
    payload: updatedPayload,
  }
}
