import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral2};
  text-transform: uppercase;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  }

  &:not(:empty)::before {
    margin-right: ${g(2.5)};
  }
  
  &:not(:empty)::after {
    margin-left: ${g(2.5)};
  }
`