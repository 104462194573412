import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'
import { Status as StatusComponent } from '@pretto/zen/atoms/infos/Status/Status'

import {
  Placeholder,
  PlaceholderSmall,
} from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocResultat/components/Placeholder/Placeholder'
import { Details as DetailsComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/Details/Details'
import { Payment as PaymentComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/Payment/Payment'

import styled, { css } from 'styled-components'

interface ResultDetails {
  label: string
  value?: string
}

export interface ResultProps {
  bankIndex: number
  buttonLabel?: string
  details: ResultDetails[]
  isDisclaimed: boolean
  isSecondary: boolean
  onButtonClick?: () => void
  onDetailsButtonClick?: () => void
  payment?: string
}

export const Result: React.FC<ResultProps> = ({
  bankIndex,
  buttonLabel,
  details,
  isDisclaimed,
  isSecondary,
  onButtonClick,
  onDetailsButtonClick,
  payment,
}) => {
  const isDetailsMissing = details.some(({ value }) => !value)

  const formattedDetails = details.map(({ value, ...details }, index) => ({
    ...details,
    value: isDetailsMissing ? <DetailsPlaceholder $w={[7, 12, 10][index % 3]} /> : value,
  }))

  return (
    <Component $isSecondary={isSecondary}>
      {!isSecondary && <Status>Le choix Pretto</Status>}

      <Bank>
        Banque {bankIndex}
        {isDisclaimed && <sup>*</sup>} :
      </Bank>

      <Payment value={payment || <Placeholder $w={15} />} />

      <Details $isSpaced={isDetailsMissing} details={formattedDetails} />

      {onDetailsButtonClick && <MoreDetailsButton label="Détails de l'offre" onClick={onDetailsButtonClick} />}

      {buttonLabel && onButtonClick && (
        <Button isArrowShown onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </Component>
  )
}

interface ComponentProps {
  $isSecondary: boolean
}

const Component = styled.div<ComponentProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.primary2};
  border-radius: ${g(1)};
  flex-grow: 1;
  padding: ${g(3)};
  position: relative;

  ${({ $isSecondary }) =>
    $isSecondary &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.neutral3};
    `}
`

const Bank = styled.div`
  ${typography.bodyMedium16};
  margin-bottom: ${g(1)};
`

const Button = styled(ButtonComponent)`
  margin-top: ${g(2)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`

interface DetailsProps {
  $isSpaced: boolean
}

const Details = styled(DetailsComponent)<DetailsProps>`
  ${({ $isSpaced }) =>
    $isSpaced &&
    css`
      gap: ${g(1 / 2)};
    `}
`

const DetailsPlaceholder = styled(PlaceholderSmall)`
  align-self: center;
`

const MoreDetailsButton = styled(ButtonFeature)`
  margin-top: ${g(2)};
`

const Payment = styled(PaymentComponent)`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }
`

const Status = styled(StatusComponent)`
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: ${g(3)};
  top: ${g(-2, -4)};
`
