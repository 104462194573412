import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)} ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
    padding: ${g(4)};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${g(1.5)};
`

export const TitleText = styled.div`
  ${typo.heading32};
`

export const Description = styled.div`
  margin-bottom: ${g(3)};
`

export const SeeMore = styled(ButtonInline)`
  margin-top: ${g(3)};
`
