import { g } from '@pretto/bricks/components/layout'
import Spinner from '@pretto/bricks/components/loading/Spinner'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { ChevronRightBold, ExclamationMarkTriangleBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Container = styled.div``
export const Textarea = styled(BaseTypo).attrs({ forwardedAs: 'textarea', typo: 'bodyMedium16' })`
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  color: ${({ theme }) => theme.colors.neutral1};
  width: 100%;
  max-width: 100%;
  padding: ${g(2)} ${g(3)};
  transition: border-color 150ms ease-in-out;

  ${({ $isBackgroundTransparent, theme }) =>
    !$isBackgroundTransparent &&
    css`
      background-color: ${theme.colors.neutral4}; ;
    `}

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral2};
  }

  ${({ $isLimitExceeded }) =>
    $isLimitExceeded &&
    css`
      &,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.error1};
      }
    `}

  ${({ $state }) =>
    $state &&
    css`
      padding-right: ${g(9)};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }

  &:focus {
    outline: 0;
  }
`
export const Limit = styled(BaseTypo).attrs({ typo: 'caption12' })`
  margin-top: ${g(1)};
  text-align: right;
  position: relative;
`
export const Char = styled.div`
  transform: translateX(0);
  transition: transform 150ms ease-in-out;

  ${({ $isLimitExceeded }) =>
    $isLimitExceeded &&
    css`
      transform: translateX(${g(-3)});
      color: ${({ theme }) => theme.colors.error1};
    `}
`
export const AlertPicto = styled(ExclamationMarkTriangleBold)`
  color: ${({ theme }) => theme.colors.error1};
  width: ${g(2)};
  height: ${g(2)};
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

  ${({ $isEntered }) =>
    $isEntered &&
    css`
      opacity: 1;
      transform: translateX(0);
    `};
`

const backgroundStyle = ({ disabled, theme }) => {
  if (disabled) {
    return css`
      cursor: not-allowed;
      background-color: ${theme.colors.accent1Disabled};
    `
  }

  return css`
    cursor: pointer;
    background-color: ${theme.colors.accent1};

    &:hover {
      background-color: ${theme.colors.accent1Hover};
    }
  `
}
export const Picto = styled.svg`
  margin-right: ${g(2)};
`
export const Button = styled(BaseTypo).attrs({ forwardedAs: 'button' })`
  ${backgroundStyle};
  width: ${g(6)};
  height: ${g(6)};
  flex: 0 0 ${g(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(0.5)};
  margin: ${g(1)};
`
const SpinnerComponent = styled(Spinner)`
  width: ${g(3)};
  height: ${g(3)};
`
export const ButtonPicto = styled(ChevronRightBold).attrs(
  ({ $isLoading }) =>
    $isLoading && {
      as: SpinnerComponent,
    }
)``
