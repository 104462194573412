import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import defaultTo from 'lodash/defaultTo'

export const isFormDisabled = (fields, values, errors, ...args) =>
  fields.reduce((previous, field) => {
    const condition = funcToItem(defaultTo(field.condition, true), values, ...args)

    if (!condition) {
      return previous || false
    }

    return previous || (!isFieldValid(values[field.name]) && field.optional !== true)
  }, false) || Object.values(errors).some(error => error === true)
