import { BlockCheckbox, InputDefault } from '@pretto/app/src/purchaseOffer/config/types'

// -- FIELDS --
export const GENDER: InputDefault = {
  props: {
    options: [
      { label: 'M.', value: 'male' },
      { label: 'Mme.', value: 'female' },
    ],
    placeholder: 'M.',
  },
}

export const FIRST_NAME: InputDefault = {
  props: {
    placeholder: 'Hubert',
  },
}

export const LAST_NAME: InputDefault = {
  props: {
    placeholder: 'Bonisseur de La Bath',
  },
}

export const COBUYER_GENDER: InputDefault = {
  props: {
    options: [
      { label: 'M.', value: 'male' },
      { label: 'Mme.', value: 'female' },
    ],
    placeholder: 'Mme.',
  },
}

export const COBUYER_FIRST_NAME: InputDefault = {
  props: {
    placeholder: 'Dolorès',
  },
}

export const COBUYER_LAST_NAME: InputDefault = {
  props: {
    placeholder: 'Koulechov',
  },
}

export const ADDRESS: InputDefault = {
  props: {
    placeholder: '10 rue des Messageries',
  },
}

export const ZIPCODE: InputDefault = {
  props: {
    placeholder: '75010',
  },
}

export const CITY: InputDefault = {
  props: {
    placeholder: 'Paris',
  },
}

// -- BLOCKS --
export const CHECKBOX_BLOCK: BlockCheckbox = {
  props: {
    id: 'cobuyer',
    inputProps: {
      value: 'cobuyer',
    },
    label: 'Vous achetez à deux.',
  },
}
