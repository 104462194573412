import { breakpoints, g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'
import styled from 'styled-components'

export const AdvisorAvatar = styled.div`
  margin-right: ${g(2)};
  margin-top: ${g(1 / 2)};
`

export const Details = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
  }
`

export const Detail = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: 50%;
    max-width: 50%;
    padding-right: ${g(3)};
  }
`

export const DetailAvatar = styled(Detail)`
  display: flex;
`

export const DetailDuration = styled(Detail)`
  margin-top: ${g(1)};
  padding-left: ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
    padding-left: 0;
  }
`

export const Summary = styled.div`
  margin-bottom: ${g(3)};
  margin-top: ${g(-1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(-2)};
  }
`

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }
`

export const TitleIcon = styled.div`
  margin-right: ${g(2)};
  margin-top: ${g(1 / 2)};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > span {
    margin-right: ${g(0.5)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`
export const VisioDetails = styled.div`
  margin-top: ${g(3)};
  flex: 0 0 100%;
`
export const VisioLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary1};
  text-decoration: underline;
`
