import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

type FractionOrPercent = 'fraction' | 'percent'

export interface ProgressBarProps {
  value: number
  isIndicatorShown?: boolean
  indicatorType?: FractionOrPercent
  fractionValue?: number
  fractionDenominator?: number
  onClick?: () => void
}

export const ProgressBar = ({
  fractionValue,
  fractionDenominator,
  isIndicatorShown,
  indicatorType,
  onClick,
  value,
  ...props
}: ProgressBarProps) => {
  const indicator = {
    fraction: `${fractionValue}/${fractionDenominator}`,
    percent: `${value}%`,
  }

  return (
    <Container onClick={onClick} {...props}>
      {isIndicatorShown && indicatorType && <Indicator>{indicator[indicatorType]}</Indicator>}

      <ProgressBackground>
        <Bar $value={value} />
      </ProgressBackground>
    </Container>
  )
}

export const ProgressBackground = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral3};
  height: ${g(1)};
  border-radius: ${g(1)};
  flex: 1 1 100%;
  margin-top: ${g(1)};
  margin-bottom: ${g(1)};
`
interface BarProps {
  $value: ProgressBarProps['value']
}
export const Bar = styled.div<BarProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.primary2};
  transform: translateX(-${({ $value }) => 100 - $value}%);
  transition: transform 300ms ease-in-out;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
`
export const Indicator = styled.div`
  margin-right: ${g(1)};
`
