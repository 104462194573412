import { SentenceObject } from '@pretto/app/bricksTypes/SentencesGroup.types'
import { Sentence, Sentences } from '@pretto/app/src/purchaseOffer/config/types'

export const formatSentences = (sentences: Sentences): SentenceObject[] =>
  sentences.map((sentence: Sentence) => ({
    props: {
      children: sentence,
    },
    type: 'sentence',
  }))
