import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { getUserEmailVerified } from '@pretto/app/src/Auth/lib/helpers'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { SEPA } from '@pretto/app/src/apollo'
import ConfirmationPage from '@pretto/app/src/mandate/containers/confirmation'
import IntroductionPage from '@pretto/app/src/mandate/containers/introduction'
import SepaPage from '@pretto/app/src/mandate/containers/sepa'
import SignaturePage from '@pretto/app/src/mandate/containers/signature'
import SuccessPage from '@pretto/app/src/mandate/containers/success'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

const STEPS = ['introduction', 'confirmation', 'signature', 'sepa', 'success']

const TIMELINE_STEPS = {
  confirmation: 'Vérifiez votre projet',
  sepa: 'Mandat SEPA',
  signature: 'Signez votre mandat',
}

const MandateController = ({ match, history }) => {
  const { loading, data } = useQuery(SEPA)

  useEffect(() => {
    const isEmailVerified = getUserEmailVerified()

    if (!isEmailVerified) {
      history.replace('/')
    }
  }, [])

  if (loading) return <SpinnerLegacy overlay />

  const { sepaStatus } = data.deal
  const isSepaSigned = sepaStatus?.signed ?? false

  const { step } = match.params
  if (!STEPS.includes(step)) {
    return <Redirect to="/mandate" />
  }

  const steps = STEPS.reduce((previous, step) => {
    if (step === 'sepa' && isSepaSigned) return previous

    return [...previous, step]
  }, [])

  const currentIndex = steps.indexOf(step)

  const timelineSteps = steps.reduce((previous, step) => {
    const title = TIMELINE_STEPS[step]
    if (title) return [...previous, { children: title }]

    return previous
  }, [])

  const handleNextStep = () => {
    const isLast = currentIndex === steps.length - 1
    const nextStepIndex = isLast ? currentIndex : currentIndex + 1
    const nextStep = steps[nextStepIndex]

    if (isLast) {
      history.push('/')
      return
    }

    history.push(nextStep)
  }

  const pageProps = {
    next: handleNextStep,
    templateProps: {
      currentStep: currentIndex - 1,
      steps: timelineSteps,
    },
  }

  switch (step) {
    case 'introduction':
      return <IntroductionPage {...pageProps} />

    case 'confirmation':
      return <ConfirmationPage {...pageProps} />

    case 'signature':
      return <SignaturePage {...pageProps} />

    case 'sepa':
      return <SepaPage {...pageProps} />

    case 'success':
      return <SuccessPage {...pageProps} />

    default:
      return <Redirect to="/mandate" />
  }
}
MandateController.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

const MandateDispatcher = () => {
  const { loading, data } = useQuery(SEPA)
  const {
    user: { activeIndex },
  } = useUser()

  if (loading) return <SpinnerLegacy overlay />

  const { mandateStatus, sepaStatus } = data.deal
  const isMandateSigned = mandateStatus?.signatures[activeIndex].signedAt ?? false
  const isSepaSigned = sepaStatus?.signed ?? false

  if (!isMandateSigned) return <Redirect to="/mandate/introduction" />

  if (!isSepaSigned) return <Redirect to="/mandate/sepa" />

  return <Redirect to="/" />
}

MandateDispatcher.propTypes = {
  history: PropTypes.object,
}

MandateController.Dispatcher = MandateDispatcher

export default MandateController
