import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PrivacyActions = ({ onValidate, onCancel }) => (
  <Card variant="white" zDepth={2} noRadius={['bottom-right', 'bottom-left']}>
    <Content>
      <S.Buttons>
        <S.Cancel>
          <ButtonLegacy onClick={onCancel} variant="white">
            Refuser
          </ButtonLegacy>
        </S.Cancel>
        <ButtonLegacy onClick={onValidate} variant="accent-1">
          Accepter
        </ButtonLegacy>
      </S.Buttons>
    </Content>
  </Card>
)

PrivacyActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}
export default memo(PrivacyActions)
