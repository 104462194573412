import { breakpoints, g } from '@pretto/bricks/components/layout'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { linkUnderline12 } from '@pretto/bricks/core/typography'

import { StepCurrent } from '@pretto/zen/dashboard/negotiationSteps/StepCurrent/StepCurrent'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import * as P from '@pretto/picto'

import styled, { css } from 'styled-components'

export enum Status {
  Done = 'done',
  Current = 'current',
  Next = 'next',
  Failed = 'failed',
  Canceled = 'canceled',
}

export interface NegotiationStepBankProps {
  title: string
  status: Status
  onClickInfo: () => void
  label?: string
  isLineShown?: boolean
}

const Picto = styled.svg`
  width: ${g(3)};
  height: ${g(3)};
  padding: ${g(0.5)};
  flex: 0 0 ${g(3)};
  grid-column-start: 1;
  grid-row-start: 1;
`
const PictoDone = styled(Picto).attrs({ as: P.CheckBold })`
  color: ${({ theme }) => theme.colors.primary2};
`
const PictoCurrent = styled(StepCurrent)`
  flex: 0 0 ${g(3)};
  grid-column-start: 1;
  grid-row-start: 1;
`
const PictoNext = styled(Picto).attrs({ as: P.ClockBold })`
  color: ${({ theme }) => theme.colors.neutral2};
`
const PictoFailed = styled(Picto).attrs({ as: P.TimesBold })`
  color: ${({ theme }) => theme.colors.error1};
`
const PictoCanceled = styled(Picto).attrs({ as: P.TimesBold })`
  color: ${({ theme }) => theme.colors.neutral2};
`

const PICTO: Record<Status, React.ReactElement> = {
  [Status.Done]: <PictoDone />,
  [Status.Current]: <PictoCurrent />,
  [Status.Next]: <PictoNext />,
  [Status.Failed]: <PictoFailed />,
  [Status.Canceled]: <PictoCanceled />,
}

export const NegotiationStepBank: React.FC<NegotiationStepBankProps> = ({
  status,
  title,
  label,
  onClickInfo,
  isLineShown = true,
  ...props
}) => {
  const InfoPicto = useBreakpointToValue(P.QuestionMarkCircleBold, { tablet: P.QuestionMarkCircle })

  return (
    <Step {...props}>
      {PICTO[status]}

      <Content $status={status} $isLineShown={isLineShown} aria-disabled={status === Status.Canceled}>
        {label && <>{label} : </>}
        {title}
      </Content>

      <InfoContainer onClick={onClickInfo}>
        <Info as={InfoPicto} />

        <Responsive min="tablet">
          <InfoText>infos</InfoText>
        </Responsive>
      </InfoContainer>

      {isLineShown && <Line $isFull={status === Status.Done} />}
    </Step>
  )
}

const Content = styled.div<{ $status: Status; $isLineShown: boolean }>`
  flex: 1 1 auto;
  grid-column-start: 2;
  grid-row-start: 1;
  padding-bottom: ${g(3)};

  ${({
    theme: {
      colors: { error1, neutral2 },
    },
    $status,
  }) => {
    if ($status === Status.Failed)
      return css`
        color: ${error1};
      `

    if ($status === Status.Canceled)
      return css`
        color: ${neutral2};
      `
  }}

  ${({ $isLineShown }) =>
    !$isLineShown &&
    css`
      padding-bottom: 0;
    `}
`
const Step = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: ${g(1)};
  align-items: flex-start;
  position: relative;
`
const Line = styled.div<{ $isFull: boolean }>`
  grid-row: 1 / span 2;
  grid-column-start: 1;
  margin-left: ${g(1.5)};
  margin-top: ${g(3)};
  height: calc(100% - ${g(3)});
  width: 0;
  border-right: 1px dashed ${({ theme }) => theme.colors.neutral3};
  transform: translateX(-0.5px);

  ${({ $isFull }) =>
    $isFull &&
    css`
      border-style: solid;
    `}
`
const InfoContainer = styled.div`
  grid-column-start: 3;
  grid-row-start: 1;
  display: flex;
  align-items: center;
  gap: ${g(1)};
  cursor: pointer;
`
const Info = styled.svg`
  color: ${({ theme }) => theme.colors.neutral2};
  width: ${g(3)};
  height: ${g(3)};
  padding: ${g(0.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
    color: ${({ theme }) => theme.colors.neutral1};
  }
`
const InfoText = styled.div`
  ${linkUnderline12};
`
