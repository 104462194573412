import PropTypes from 'prop-types'

import * as S from './styles'

const ButtonGoogleConnect = ({ children, color, onClick, ...props }) => {
  return (
    <S.Button onClick={onClick} $color={color} {...props}>
      <S.Icon $color={color} />
      {children}
    </S.Button>
  )
}

ButtonGoogleConnect.propTypes = {
  /** text to display */
  children: PropTypes.string,
  color: PropTypes.oneOf(['white', 'blue']),
  /** onClick Function triggered. */
  onClick: PropTypes.func.isRequired,
}

ButtonGoogleConnect.defaultProps = {
  children: 'Se connecter avec Google',
  color: 'white',
}

export default ButtonGoogleConnect
