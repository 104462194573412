import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import { OptionsPage } from '@pretto/app/src/Simulation/Containers/OptionsPage/OptionsPage'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { t } from '@pretto/app/src/lib/i18n'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

export const ArrangeCredits = ({ options, project, onProjectChanges, onSimulationMedium }) => {
  const history = useHistory()
  const { typology } = useUser()
  const trackAction = useTracking()

  const handleModify = () => {
    trackOption('modify_project')

    history.push(typology === 'client' ? '/application' : `/project/${project.project_kind}/introduction`)
  }

  const handleView = () => {
    trackAction('SIMULATION_ARRANGE_CREDITS_PAGE_VIEWED')
  }

  const trackOption = key => {
    onSimulationMedium('arrange_credits')

    trackAction('SIMULATION_ARRANGE_CREDITS_OPTION_CLICKED', { simulation_arrange_credits_option_value: key })
  }

  const handleAction = (key, changes) => {
    trackOption(key)
    onProjectChanges(changes)
  }

  const formattedOptions = Object.entries(options).reduce((previous, [key, result]) => {
    if (!result) {
      return previous
    }

    switch (key) {
      case 'pay_off_credits_simulation': {
        const {
          project: {
            profile: { credits },
            contribution,
          },
        } = result

        const paidCredits = credits.filter(({ becoming }) => becoming === 'paid')

        return [
          ...previous,
          {
            action: handleAction.bind(null, key, { contribution, profile: { credits } }),
            children: t('arrangeCreditsPage.repayWithContribution', { count: paidCredits.length }),
            data: [
              ...paidCredits.map(({ payment }, index) => ({
                label: index === 0 ? t('arrangeCreditsPage.repayedLoans', { count: paidCredits.length }) : '',
                value: formatNumber(payment, { suffix: t('arrangeCreditsPage.amountPerMonthSuffix') }),
              })),
              {
                label: t('arrangeCreditsPage.newContribution'),
                value: formatNumber(contribution, { suffix: t('arrangeCreditsPage.amountSuffix') }),
              },
            ],
            title: t('arrangeCreditsPage.repayLoansTitle'),
          },
        ]
      }

      case 'smooth_credits_simulation': {
        const {
          project: {
            profile: { credits },
          },
        } = result

        const smoothCredits = credits.filter(({ becoming }) => becoming === 'kept')

        return [
          ...previous,
          {
            action: handleAction.bind(null, key, { profile: { credits } }),
            children: t('arrangeCreditsPage.consolidateDebt', { count: smoothCredits.length }),
            data: [
              ...smoothCredits.map(({ payment }, index) => ({
                label: index === 0 ? t('arrangeCreditsPage.consolidatedLoans', { count: smoothCredits.length }) : '',
                value: formatNumber(payment, { suffix: t('arrangeCreditsPage.amountPerMonthSuffix') }),
              })),
            ],
            title: t('arrangeCreditsPage.consolidateDebtTitle'),
          },
        ]
      }

      default:
        return previous
    }
  }, [])

  const props = {
    onModify: handleModify,
    onView: handleView,
    options: formattedOptions,
  }

  return <OptionsPage {...props} />
}

ArrangeCredits.propTypes = {
  onProjectChanges: PropTypes.func.isRequired,
  onSimulationMedium: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}
