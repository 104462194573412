import Icon from '@pretto/bricks/components/iconography/Icon'

import PropTypes from 'prop-types'

import * as S from './styles'

const Badge = ({ backgroundVariant, children, icon, format, shape, ...props }) => {
  const { className, as, forwardedAs, ...restProps } = props
  const styledProps = {
    as,
    className,
    forwardedAs,
  }
  return (
    <S.Badge
      {...styledProps}
      {...restProps}
      backgroundVariant={backgroundVariant}
      formatType={format}
      shapeType={shape}
    >
      <S.Content {...restProps} backgroundVariant={backgroundVariant} formatType={format} shapeType={shape}>
        {icon ? <Icon name={icon} /> : children}
      </S.Content>
    </S.Badge>
  )
}

Badge.defaultProps = {
  format: 'default',
  isGlowing: false,
  shape: 'circle',
  size: 'medium',
  variant: 'neutral-1-l',
}

Badge.propTypes = {
  /** @ignore used for styled components. */
  as: PropTypes.any,
  /** Background variant applied to badge when format is `line`. */
  backgroundVariant: PropTypes.string,
  /** Can be used instead of icon */
  children: PropTypes.node,
  /** @ignore used for styled components. */
  className: PropTypes.string,
  /** Name of icon used for badge. */
  format: PropTypes.oneOf(['default', 'line']),
  /** @ignore used for styled components. */
  forwardedAs: PropTypes.any,
  /** What shape the badge should take. */
  icon: PropTypes.node,
  /** Special glowing effect */
  isGlowing: PropTypes.bool,
  /** Format of badge. */
  shape: PropTypes.oneOf(['circle', 'square', 'triangle']),
  /** Size of badge. */
  size: PropTypes.string,
  /** Background color of badge. */
  variant: PropTypes.string,
}

export default Badge
