import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'
import LinkComponent from '@pretto/bricks/website/utility/Link'

import { ButtonNext } from '@pretto/app/src/Sentences/v2/components/ButtonNext/ButtonNext'

import styled from 'styled-components'

const Card = styled.div`
  padding: ${g(3)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  border-radius: ${g(1)};

  @media only screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(3)} ${g(4)} ${g(4)} ${g(4)};
  }
`

const SubTitle = styled.h2`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3, 2)};
`

const Content = styled.div`
  display: flex;
`

const Text = styled.div``

const PharmacyCross = styled.img.attrs({
  src: getCloudinaryUrl('v1702478809/reveal/static-asssets/illustrations/cropped/croppedmedicalngif_360.gif', {
    height: ng(19),
    width: ng(19),
  }),
  alt: 'pharmacy cross',
})`
  margin-top: ${g(-1)};
`

const PharmacyCrossDesktop = styled(PharmacyCross)`
  padding-top: ${g(2)};
  padding-left: ${g(4)};
  margin-top: 0;
`

const Description = styled.p`
  margin-bottom: ${g(2)};
`

const SeeMoreContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const SeeMore = styled(LinkComponent)`
  ${({ theme }) => theme.typos.body4Underline};
  cursor: pointer;
`

interface MedicalAdProps {
  onNextClick: () => void
}

export const MedicalAd: React.FC<MedicalAdProps> = ({ onNextClick }) => {
  return (
    <>
      <Card>
        <SubTitle>Professionnels de santé,</SubTitle>
        <Title>Vous êtes entre de bonnes mains !</Title>
        <Content>
          <Text>
            <Description>
              Pretto accompagne toutes les professions médicales dans leur projet d’acquisition immobilière. À la fin de
              la simulation, prenez <strong>rendez-vous</strong> avec l’un de nos courtiers <strong>spécialisés</strong>{' '}
              en professions médicales.
            </Description>
            <SeeMoreContent>
              <SeeMore href="https://www.pretto.fr/pret-immobilier/sans-cdi/pret-immobilier-medecin/">
                En savoir plus
              </SeeMore>
              <Responsive max="desktop">
                <PharmacyCross />
              </Responsive>
            </SeeMoreContent>
          </Text>
          <Responsive min="desktop">
            <PharmacyCrossDesktop />
          </Responsive>
        </Content>
      </Card>
      <ButtonNext onClick={onNextClick}>Suivant</ButtonNext>
    </>
  )
}
