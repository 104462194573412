import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronLeftBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ButtonBack = ({ children, isArrowVisible, isDisabled, onClick, ...props }) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
    }

    onClick(event, ...args)
  }

  return (
    <Button onClick={handleClick} $isDisabled={isDisabled} {...props}>
      {isArrowVisible && <Arrow />}
      <Label>{children}</Label>
    </Button>
  )
}

ButtonBack.defaultProps = {
  onClick: () => {},
}

ButtonBack.propTypes = {
  /** Text of button */
  children: PropTypes.string.isRequired,
  /** @ignore. Used for styling purpose. */
  href: PropTypes.string,
  /** Whether arrow is shown */
  isArrowVisible: PropTypes.bool,
  /** Disabled state of button. */
  isDisabled: PropTypes.bool,
  /** onClick Function triggered. */
  onClick: PropTypes.func,
}

const TRANSITION_DELAY = 175
const TRANSITION_DURATION_IN = 200
const TRANSITION_DURATION_OUT = 150

const getBackground = ({ theme, $isDisabled }) => {
  if ($isDisabled) {
    return css`
      cursor: not-allowed;
      background-color: ${theme.colors.neutral4};
      outline: none;
    `
  }
  return css`
    cursor: pointer;
    background-color: ${theme.colors.neutral4};
    box-shadow: 0;

    &:hover {
      background-color: ${theme.colors.neutral4Hover};
    }
  `
}

const Arrow = styled(ChevronLeftBold)`
  align-self: center;
  flex-shrink: 0;
  margin-right: ${g(2)};
  transform: translateX(0);
  transition: transform 0.5s ease;
`

const Button = styled(BaseTypo).attrs(({ $isDisabled, href }) => ({
  disabled: $isDisabled,
  forwardedAs: !href ? 'button' : Link,
  tabIndex: $isDisabled ? -1 : null,
  typo: 'button',
}))`
  ${getBackground}
  border-radius: ${g(1)};
  color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.colors.neutral1Disabled : theme.colors.neutral1)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 ${g(3)};
  height: ${g(8)};
  transition:
    background-color ${TRANSITION_DURATION_OUT}ms ease-out,
    box-shadow ${TRANSITION_DURATION_OUT}ms ease-out;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover ${Arrow} {
        transform: translateX(${g(-1, 2)});
      }

      &:focus {
        border: 1px dashed ${({ theme }) => theme.colors.accent1};
      }
    `};
`

const Label = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${g(3)} 0;
  transition: opacity ${TRANSITION_DURATION_IN}ms ease-out ${TRANSITION_DELAY}ms;
  white-space: nowrap;
`

export default ButtonBack
