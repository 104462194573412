import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

interface ButtonLinkProps {
  href?: string
}

interface PictoProps {
  $picto: PictoType
}

export const ButtonLink = styled.div.attrs(({ href }: ButtonLinkProps) => ({
  as: href ? Link : 'button',
}))<ButtonLinkProps>`
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
`
export const Picto = styled.div.attrs(({ $picto }: PictoProps) => ({ as: $picto }))<PictoProps>`
  width: ${g(2)};
  height: ${g(2)};
  margin-right: ${g(1)};
`
export const Anchor = styled.div`
  ${typo.linkUnderline12};
`
