import ApplicationSideBar from '@pretto/bricks/app/application/components/ApplicationSideBar'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { cardsByStatus } from '@pretto/app/src/dashboard/config/cardsByStatus'

import { Route, Switch, useLocation } from 'react-router'

import { useUser } from '../../../User/Containers/UserProvider'
import { isValueIndexAfter } from '../../../lib/helpers'
import { useApplication } from '../ApplicationContext'
import ApplicationDocumentsPage from '../ApplicationDocumentsPage'
import ApplicationFormPage from '../ApplicationFormPage'

import * as S from './styles'

const ApplicationNavigation = () => {
  const { navigationItems } = useApplication()

  const { pathname } = useLocation()

  const { status } = useUser()

  const isSideBarHidden =
    pathname.indexOf('/introduction') !== -1 || isValueIndexAfter(Object.keys(cardsByStatus), status, 'validation')

  return (
    <>
      {!isSideBarHidden && (
        <Responsive max="laptop">
          <S.Nav items={navigationItems} isNavigationHidden />
        </Responsive>
      )}

      <S.MainContainer $isSplited={!isSideBarHidden}>
        {!isSideBarHidden && (
          <Responsive min="laptop">
            <ApplicationSideBar items={navigationItems} />
          </Responsive>
        )}

        <div>
          <Switch>
            <Route exact path="/application/documents/:document?" component={ApplicationDocumentsPage} />
            <Route path="/application/:step/:page?" component={ApplicationFormPage} />
          </Switch>
        </div>
      </S.MainContainer>
    </>
  )
}

export default ApplicationNavigation
