import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'

export const mapContribution: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  if (payload.contribution) updatedContext.contribution = payload.contribution

  return {
    payload,
    context: updatedContext,
  }
}
