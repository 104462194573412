import { uploadFileRemote } from '@pretto/app-core/application/lib/uploadFileRemote'

import { useReducer } from 'react'

enum Action {
  CLEAR = 'CLEAR',
  FILE_ADD = 'FILE_ADD',
  FILE_COMPLETE = 'FILE_COMPLETE',
  FILE_DELETE = 'FILE_DELETE',
  FILE_INCOMPLETE = 'FILE_INCOMPLETE',
  FILE_REMOVE = 'FILE_REMOVE',
  FILE_UPDATE = 'FILE_UPDATE',
}

export enum UploadFileStatus {
  Error,
  Removing,
  Stale,
  Uploaded,
  Uploading,
}

interface UploadFileInterface {
  fileName: string
  localId: string
  progress: number
  remoteId?: string
  slug: string
  status: UploadFileStatus
}

export interface UploadFileError extends UploadFileInterface {
  remoteId?: string
  retry: () => void
  status: UploadFileStatus.Error
  uploadedAt?: string
}

export interface UploadFileRemoving extends UploadFileInterface {
  remoteId: string
  status: UploadFileStatus.Removing
  uploadedAt: string
}

export interface UploadFileStale extends UploadFileInterface {
  remoteId: string
  status: UploadFileStatus.Stale
  uploadedAt: string
}

export interface UploadFileUploaded extends UploadFileInterface {
  remoteId: string
  status: UploadFileStatus.Uploaded
  uploadedAt: string
}

export interface UploadFileUploading extends UploadFileInterface {
  status: UploadFileStatus.Uploading
}

export type UploadFile =
  | UploadFileError
  | UploadFileRemoving
  | UploadFileStale
  | UploadFileUploaded
  | UploadFileUploading

interface UploadActionInterface {
  payload?: object
  type: Action
}

interface UploadActionClear extends UploadActionInterface {
  type: Action.CLEAR
}

interface UploadActionFileAdd extends UploadActionInterface {
  payload: {
    fileName: string
    localId: string
    slug: string
  }
  type: Action.FILE_ADD
}

interface UploadActionFileComplete extends UploadActionInterface {
  payload: {
    localId: string
    remoteId: string
    uploadedAt: string
  }
  type: Action.FILE_COMPLETE
}

interface UploadActionFileDelete extends UploadActionInterface {
  payload: {
    localId: string
  }
  type: Action.FILE_DELETE
}

interface UploadActionFileIncomplete extends UploadActionInterface {
  payload: {
    localId: string
    retry: () => void
  }
  type: Action.FILE_INCOMPLETE
}

interface UploadActionFileRemove extends UploadActionInterface {
  payload: {
    localId: string
  }
  type: Action.FILE_REMOVE
}

interface UploadActionFileUpdate extends UploadActionInterface {
  payload: {
    localId: string
    progress: number
  }
  type: Action.FILE_UPDATE
}

type UploadAction =
  | UploadActionClear
  | UploadActionFileAdd
  | UploadActionFileComplete
  | UploadActionFileDelete
  | UploadActionFileIncomplete
  | UploadActionFileRemove
  | UploadActionFileUpdate

export type InitialState = Array<UploadFileStale | UploadFileUploaded>

type UploadState = UploadFile[]

const reducer = (state: UploadState, { payload, type }: UploadAction): UploadFile[] => {
  switch (type) {
    case Action.CLEAR:
      return []

    case Action.FILE_ADD:
      if (state.some(uploadFile => uploadFile.localId === payload.localId)) {
        return reducer(state, {
          payload: { localId: payload.localId, progress: 0 },
          type: Action.FILE_UPDATE,
        })
      }

      return [
        ...state,
        {
          fileName: payload.fileName,
          localId: payload.localId,
          progress: 0,
          slug: payload.slug,
          status: UploadFileStatus.Uploading,
        },
      ]

    case Action.FILE_COMPLETE:
      return state.map(uploadFile => {
        if (uploadFile.localId !== payload.localId) {
          return uploadFile
        }

        return {
          ...uploadFile,
          localId: payload.localId,
          progress: 1,
          remoteId: payload.remoteId,
          status: UploadFileStatus.Uploaded,
          uploadedAt: payload.uploadedAt,
        }
      })

    case Action.FILE_DELETE:
      return state.filter(uploadFile => uploadFile.localId !== payload.localId)

    case Action.FILE_INCOMPLETE:
      return state.map(uploadFile => {
        if (uploadFile.localId !== payload.localId) {
          return uploadFile
        }

        return {
          ...uploadFile,
          retry: payload.retry,
          status: UploadFileStatus.Error,
        }
      })

    case Action.FILE_REMOVE:
      return state.map(uploadFile => {
        if (uploadFile.status !== UploadFileStatus.Uploaded || uploadFile.localId !== payload.localId) {
          return uploadFile
        }

        return {
          ...uploadFile,
          status: UploadFileStatus.Removing,
        }
      })

    case Action.FILE_UPDATE:
      return state.map(uploadFile => {
        if (uploadFile.localId !== payload.localId) {
          return uploadFile
        }

        return {
          ...uploadFile,
          localId: payload.localId,
          progress: payload.progress,
          status: UploadFileStatus.Uploading,
        }
      })

    default:
      return state
  }
}

export const useUpload = (initialFiles: InitialState) => {
  const [files, dispatch] = useReducer(reducer, initialFiles)

  const handleProgress = (localId: string, progress: number) => {
    dispatch({ payload: { localId, progress }, type: Action.FILE_UPDATE })
  }

  const addFile = (fileName: string, localId: string, slug: string) => {
    dispatch({
      payload: { fileName, localId, slug },
      type: Action.FILE_ADD,
    })
  }

  const completeFile = (localId: string, remoteId: string, uploadedAt: string) => {
    dispatch({ payload: { localId, remoteId, uploadedAt }, type: Action.FILE_COMPLETE })
  }

  const deleteFile = (localId: string) => {
    dispatch({ payload: { localId }, type: Action.FILE_DELETE })
  }

  const incompleteFile = (localId: string, retry: () => void) => {
    dispatch({ payload: { localId, retry }, type: Action.FILE_INCOMPLETE })
  }

  const removeFile = (localId: string) => {
    dispatch({ payload: { localId }, type: Action.FILE_REMOVE })
  }

  const uploadFile = (file: File, localId: string, projectId: string) =>
    uploadFileRemote(file, localId, projectId, handleProgress)

  return {
    files,
    addFile,
    completeFile,
    deleteFile,
    incompleteFile,
    removeFile,
    uploadFile,
  }
}
