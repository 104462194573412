import { funcToItem } from '@pretto/bricks/core/utility/funcToItem'

import { getValue } from '@pretto/app/src/lib/object'
import { DESCRIPTION, DETAILS } from '@pretto/app/src/overview/config/labels'
import { PURCHASE, RENEGOTIATION } from '@pretto/app/src/overview/config/rows'
import { VARIANTS } from '@pretto/app/src/overview/config/variants'
import { formatLocaleValues } from '@pretto/app/src/overview/lib/formatLocaleValues'
import type { Row } from '@pretto/app/src/overview/types/row'

import get from 'lodash/get'

export const formatProjectRows = (
  rows: typeof PURCHASE | typeof RENEGOTIATION,
  data: unknown,
  hasContribution: boolean,
  onEdit: (path: string) => void
): Row[] =>
  rows.reduce<Row[]>((previous, path) => {
    const value = get(data, path)

    if (!value) {
      return previous
    }

    const description = funcToItem<React.ReactNode, Array<(path: string) => unknown>>(
      getValue(DESCRIPTION, path),
      get.bind(null, data)
    )

    const rawValue = value
    const slug = path.split('.').pop()
    const title = getValue(DETAILS, path)
    const variant = funcToItem(getValue(VARIANTS, path), hasContribution)

    const defaultRow = {
      description,
      path,
      rawValue,
      title,
      variant,
    }

    switch (path) {
      case 'best_result.financing.contribution':
        return [
          ...previous,
          {
            ...defaultRow,
            onAction() {
              if (slug) {
                onEdit(slug)
              }
            },
            sign: '-',
            value: formatLocaleValues(value),
          },
        ]

      case 'project.purchase.build_price':
      case 'project.purchase.property_price':
      case 'project.renegotiation.remaining_principal':
        return [
          ...previous,
          {
            ...defaultRow,
            onAction() {
              if (slug) {
                onEdit(slug)
              }
            },
            value: formatLocaleValues(value),
          },
        ]

      case 'project.purchase.land_price':
      case 'project.purchase.works_price':
        return [
          ...previous,
          {
            ...defaultRow,
            onAction() {
              if (slug) {
                onEdit(slug)
              }
            },
            sign: '+',
            value: formatLocaleValues(value),
          },
        ]

      case 'best_result.financing.bridge.value':
        return [...previous, { ...defaultRow, sign: '-', value: formatLocaleValues(value) }]

      case 'best_result.financing.guarantyFees':
      case 'brokerage_fees.amount':
        return [...previous, { ...defaultRow, sign: '+', value: formatLocaleValues(value) }]

      case 'project.facts.notary_fees':
      case 'project.facts.agency_fees':
        return [...previous, { ...defaultRow, sign: '+', value: formatLocaleValues(Math.floor(value).toString()) }]

      case 'best_result.financing.loanAmount':
        return [...previous, { ...defaultRow, rawValue: value, sign: '=', value: formatLocaleValues(value) }]

      case 'best_result.financing.ptz.amount':
        return [...previous, { ...defaultRow, value: formatLocaleValues(value) }]

      default:
        return [...previous, { ...defaultRow, sign: '+', value: formatLocaleValues(value) }]
    }
  }, [])
