import { DataContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import type { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'
import { Unit } from '@pretto/app/src/Capacity/views/ResultPage/types/benefit'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext } from 'react'

import { BlocSuggestionsLayoutProps } from '../../views/ResultPage/components/BlocSuggestionsLayout/BlocSuggestionsLayout'
import { SuggestionLargeProps } from '../../views/ResultPage/components/SuggestionLarge/SuggestionLarge'

import { getCalcTableProps } from './getCalcTableProps'
import { getLoadingLabel } from './getLoadingLabel'
import { getSuggestionLarge } from './getSuggestion'
import { useSuggestions } from './useSuggestions'

export type SuggestionLarge = SuggestionLargeProps & {
  id: string
}

export const useBlocSuggestionsLoggedInProps = ({
  onContinue,
  ...props
}: ResultPageProps): BlocSuggestionsLayoutProps => {
  const { data: capacityData, loadingResults: isCapacityLoading, selectedLoanDuration } = useContext(DataContext)

  const {
    data: suggestionsData,
    isLoading: isSuggestionsLoading,
    numberOfEligibleSuggestionsName,
    numberOfLoadedSuggestions,
  } = useSuggestions()

  const trackAction = useTracking()

  const duration = ensureNotNil(selectedLoanDuration)
  const capacityResult = capacityData.capacity.find(({ financing }) => duration === financing.loanDuration)

  return {
    ...props,
    ctaTitle: 'Je complète mon dossier',
    description:
      'Bonne nouvelle ! Nous avons identifié pour vous un ou plusieurs leviers qui vous permettraient d’augmenter votre capacité d’achat. Les équipes de Pretto sont là pour vous guider à travers ces recommandations.',
    isLoading: isCapacityLoading || isSuggestionsLoading,
    isSlimSuggestions: false,
    loadingLabel: isCapacityLoading
      ? getLoadingLabel(0, 0)
      : getLoadingLabel(numberOfEligibleSuggestionsName, numberOfLoadedSuggestions),
    get suggestions() {
      return (
        suggestionsData?.reduce<SuggestionLarge[]>((previous, { result: suggestionResult, type }) => {
          const suggestion = getSuggestionLarge(type)

          if (
            !suggestion ||
            !capacityResult ||
            suggestionResult.project.purchase.property_price < capacityResult.project.purchase.property_price + 2000
          ) {
            return previous
          }

          const bonus =
            suggestionResult.project.purchase.property_price - capacityResult.project.purchase.property_price

          return [
            ...previous,
            {
              dialogContentProps: {
                calcTableProps: { rows: getCalcTableProps(suggestionResult, bonus, type) },
                disclaimer: suggestion.disclaimer,
              },
              benefits: {
                ...suggestion.benefits,
                unit: Unit.Price,
                value: bonus,
              },
              hat: suggestion.hat,
              id: type.toString(),
              onSeeMore() {
                trackAction('CAPACITY_RESULT_SUGGESTION_OPENED', {
                  capacity_result_suggestion_type: type,
                })
              },
              title: suggestion.title,
              value: suggestionResult.project.purchase.property_price,
            },
          ]
        }, []) ?? []
      )
    },
    onContinue(event) {
      onContinue?.(event, 'suggestions')
    },
    title: 'Augmentez votre pouvoir d’achat !',
  }
}
