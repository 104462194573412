import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import Slider from '@pretto/bricks/components/utility/Slider'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import Row from '@pretto/bricks/shared/components/Row'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const LABEL = {
  currency: '€',
  duration: 'ans',
}

const SliderRange = ({ title, type, format, sliderProps }) => {
  const { max, min, value, variant } = sliderProps

  return (
    <S.Slider format={format}>
      <Row
        label={<SubHeading>{title}</SubHeading>}
        value={<SubHeading variant={variant}>{formatNumber(value, { suffix: LABEL[type] })}</SubHeading>}
      />

      <Slider {...sliderProps} />
      <Row
        label={
          <Text size="x-small" variant="neutral-1-60">
            {formatNumber(min, { suffix: LABEL[type] })}
          </Text>
        }
        value={
          <Text size="x-small" variant="neutral-1-60">
            {formatNumber(max, { suffix: LABEL[type] })}
          </Text>
        }
      />
    </S.Slider>
  )
}

SliderRange.defaultProps = {
  format: 'default',
}

SliderRange.propTypes = {
  format: PropTypes.string,
  sliderProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default memo(SliderRange)
