import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BookingAppointmentPage = ({ children, duration, isRescheduling }) => (
  <S.Page>
    <S.Header>
      <Wrapper>
        <Responsive min="tablet">
          <S.Title>
            <Heading>Choisissez votre créneau</Heading>
          </S.Title>
        </Responsive>

        <div>
          {isRescheduling
            ? 'Pour déplacer votre rendez-vous, choisissez une nouvelle date et un nouvel horaire.'
            : `Pour prendre rendez-vous, choisissez une date et un horaire. Le rendez-vous durera au plus ${duration}.`}
        </div>
      </Wrapper>
    </S.Header>

    <Wrapper>{children}</Wrapper>
  </S.Page>
)

BookingAppointmentPage.defaultProps = {
  isRescheduling: false,
}

BookingAppointmentPage.propTypes = {
  /** Descendants. */
  children: PropTypes.node,
  /** Human-written duration of the appointment. */
  duration: PropTypes.string.isRequired,
  /** Whether or not the appointment is being rescheduled. */
  isRescheduling: PropTypes.bool,
}

export default memo(BookingAppointmentPage)
