import { formatDecodePhone } from '@pretto/app-core/lib/filters'

import { getIsAdvisorUnavailable } from '@pretto/app/src/lib/getIsAdvisorUnavailable'
import { BubbleDouble, Bulb, Calendar, Envelope, Smartphone } from '@pretto/picto'

export const advisor = {
  items: [
    ({ advisor: { email, holidays_start_date, holidays_end_date } }) => {
      const isUnavailable = getIsAdvisorUnavailable({ startDate: holidays_start_date, endDate: holidays_end_date })

      return {
        component: holidays_start_date ? 'div' : null,
        href: `mailto:${email}`,
        icon: {
          name: Envelope,
        },
        isUnavailable,
        title: email,
      }
    },
    ({ advisor: { holidays_start_date, holidays_end_date, phone } }) => {
      const isUnavailable = getIsAdvisorUnavailable({ startDate: holidays_start_date, endDate: holidays_end_date })

      return {
        component: holidays_start_date ? 'div' : null,
        href: `sms://${phone}`,
        icon: {
          name: Smartphone,
        },
        isUnavailable,
        subTitle: 'Pour une réponse rapide, le sms est le bon réflexe',
        title: formatDecodePhone(phone),
      }
    },
    ({ hasFeatureAccess }) =>
      !hasFeatureAccess(['NO_APPOINTMENT']) && {
        href: '/booking',
        icon: {
          name: Calendar,
        },
        subTitle: 'Pour faire un point sur votre projet',
        title: 'Programmer un rendez-vous',
      },
  ],
}

export const help = {
  items: [
    (userContext, propertySearchContext, onChat) => ({
      component: 'div',
      icon: {
        name: BubbleDouble,
      },
      itemProps: { onClick: onChat },
      title: 'Contacter notre support',
    }),
    {
      href: `mailto:hello@pretto.fr`,
      icon: {
        name: Envelope,
      },
      title: 'hello@pretto.fr',
    },
    {
      href: 'https://faq.pretto.fr/',
      icon: {
        name: Bulb,
      },
      title: 'Consulter la FAQ',
    },
  ],
  title: "Besoin d'aide ?",
  variant: 'neutral4',
}
