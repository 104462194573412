export const getVariant = ({ isRepresentative, hasComortgagor, hasNextBooking, comortgagorSignDate }) => {
  if (isRepresentative) {
    return 'representative'
  }

  if (!hasComortgagor && hasNextBooking) {
    return 'bookedAndSign'
  }

  if (hasComortgagor && !comortgagorSignDate) {
    return 'waitingComortgagor'
  }

  if (hasComortgagor && comortgagorSignDate && hasNextBooking) {
    return 'bookedAndSign'
  }

  return 'completed'
}
