import type { StepProps } from '@pretto/bricks/app/dashboard/pages/DashboardPage/components/Steps/components/Step/Step'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import * as S from './Steps.styles'

export type StepState = 'done' | 'idle' | 'pending'

interface StepsProps {
  activeIndex: number
  steps: StepProps[]
}

export const Steps: React.FC<StepsProps> = ({ activeIndex, steps }) => (
  <ThemeProvider designSystem="reveal">
    <S.Container>
      <S.Title>Vos prochaines étapes</S.Title>

      <S.Timeline>
        {steps.map((step, index, steps) => {
          const stepState = activeIndex === index ? 'pending' : activeIndex < index ? 'idle' : 'done'
          const iconState = activeIndex >= steps.length - 1 ? 'done' : stepState

          return <S.Step key={index} {...step} $stepsCount={steps.length} iconState={iconState} stepState={stepState} />
        })}
      </S.Timeline>
    </S.Container>
  </ThemeProvider>
)
