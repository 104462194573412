import { BlockInfoProps } from '@pretto/bricks/app/simulator/components/ResultCardMain/children/BlockInfo'

import {
  getBlockInfoParams,
  getBookedBlockProps,
  LoadingProps,
} from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoByMaturity'

export const getBlockInfoAppointment = ({
  bookingInfos: { isLoading, name, start },
  historyPush,
}: Omit<getBlockInfoParams, 'maturity' | 'typology' | 'onOpenMutualizedAgenda'>): BlockInfoProps | LoadingProps => {
  if (isLoading) {
    return {
      isLoading: true,
    }
  }

  return name && start
    ? getBookedBlockProps({ name, start }, historyPush)
    : {
        imgPath: 'v1637684805/zen/static-assets/illustrations/660x660/folderBook.svg',
        title: 'Nous prenons votre crédit en main !',
        description:
          'Constituez dès maintenant votre dossier de prêt dans votre espace client. Nous vous simplifions les démarches et trouvons le meilleur prêt.',
        cta: {
          text: 'Accéder à mon espace client',
          onClick: () => historyPush('/'),
        },
      }
}
