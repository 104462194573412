import { encodeEmail, run } from 'mailcheck'

const DOMAINS = [
  'aol.com',
  'facebook.com',
  'free.fr',
  'gmail.com',
  'gmx.fr',
  'hotmail.com',
  'hotmail.fr',
  'icloud.com',
  'laposte.net',
  'live.com',
  'live.fr',
  'me.com',
  'msn.com',
  'orange.fr',
  'outlook.com',
  'outlook.fr',
  'sfr.fr',
  'wanadoo.fr',
  'yahoo.com',
  'yahoo.fr',
]

export const suggestEmail = (value: string): string | null => {
  const suggestion = run({
    domains: DOMAINS,
    email: encodeEmail(value),
  })

  if (suggestion?.full) {
    return suggestion.full
  }

  // strip leading dots
  // strip middle dots
  // strip trailing dots
  const suggestedValue = value.replaceAll(/(?:^\.+)|\.+(@|\.)/g, '$1')

  if (suggestedValue !== value) {
    return suggestedValue
  }

  return null
}
