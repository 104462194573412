import { Maturity } from '@pretto/app/src/config/maturity'

interface OptionsParams {
  id: Maturity
  label: string
  value: Maturity
}

export const OPTIONS: OptionsParams[] = [
  { id: Maturity.under_study, label: 'Je suis en réflexion', value: Maturity.under_study },
  { id: Maturity.search_started, label: 'Je suis en recherche de bien', value: Maturity.search_started },
  { id: Maturity.offer_made, label: 'J’ai fait une offre d’achat pour un bien', value: Maturity.offer_made },
  { id: Maturity.offer_accepted, label: 'J’ai une offre d’achat acceptée', value: Maturity.offer_accepted },
  { id: Maturity.sale_agreement_signed, label: 'J’ai signé un compromis de vente', value: Maturity.sale_agreement_signed },
  { id: Maturity.project_lost, label: 'J’ai trouvé un prêt immobilier', value: Maturity.project_lost }
]
