import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import { formatNumber, funcToItem, roundNumber } from '@pretto/bricks/core/utility/formatters'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import AdvisorAvatar from '@pretto/app/src/SharedContainers/AdvisorAvatar'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { CONFIRM } from '@pretto/app/src/apollo'
import * as AMOUNTS from '@pretto/app/src/config/operation'
import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { PAYMENT_RATIO, PAYMENT_ROUND, PROJECT_RESULTS, PROJECT_VALUES } from '@pretto/app/src/mandate/lib/config'
import { MandateConfirmation } from '@pretto/app/src/mandate/views/MandateConfirmation/MandateConfirmation'

import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'

const VALUES = { results: PROJECT_RESULTS, values: PROJECT_VALUES }

const ConfirmationPage = ({ next, templateProps }) => {
  const { loading, data } = useQuery(CONFIRM)

  const { isImpersonated } = useAuth()

  const trackAction = useTracking()

  const getValues = (type, projectKind, data) => {
    const values = VALUES[type][projectKind]

    return values.reduce((previous, [path, slug, options]) => {
      const value = get(data, path)

      if (!value) {
        return previous
      }

      const config = AMOUNTS[slug]

      const currentOptions = {
        ...config,
        ...options,
      }

      switch (slug) {
        case 'agencyFees':
        case 'notaryFees':
          return [...previous, { ...currentOptions, value: formatNumber(Math.floor(value), { suffix: '€' }) }]

        case 'ptzAmount': {
          const { duration, franchise } = data.analysis.ptz

          const description = funcToItem(
            config.description,
            Math.max(1, Math.round(duration / MONTHS_IN_YEAR)),
            Math.max(1, Math.round(franchise / MONTHS_IN_YEAR) + 1)
          )

          return [
            ...previous,
            {
              ...currentOptions,
              description,
              leftSpaced: true,
              value: formatNumber(value, { suffix: '€' }),
            },
          ]
        }

        default:
          return [
            ...previous,
            {
              ...currentOptions,
              leftSpaced: !currentOptions.sign && !currentOptions.emoji,
              value: formatNumber(value, { suffix: '€' }),
            },
          ]
      }
    }, [])
  }

  const handleConfirm = () => {
    trackAction('MANDATE_CONFIRM_BUTTON_CLICKED')
    next()
  }

  const mandatePageProps = () => {
    const {
      analysis: { creditCosts, payment },
      project: {
        good: { usage },
        project_kind: projectKind,
        request: { duration },
      },
    } = data

    const paymentMax = roundNumber(payment * PAYMENT_RATIO, PAYMENT_ROUND, Math.ceil)
    const paymentMin = roundNumber(payment, PAYMENT_ROUND, Math.floor)
    const warningMessage = 'Si certaines de ces informations ne vous semblent pas conformes, veuillez nous en avertir.'

    const props = {
      avatarProps: { src: <AdvisorAvatar size="small" /> },
      creditCost: creditCosts,
      duration: duration / MONTHS_IN_YEAR,
      isSubmittable: !isImpersonated,
      onConfirm: handleConfirm,
      paymentMax,
      paymentMin,
      projectResults: getValues('results', projectKind, data),
      projectValues: getValues('values', projectKind, data),
      showConditions: usage !== 'rental_investment',
      templateProps,
      warningMessage,
    }
    return props
  }

  if (loading) return <SpinnerLegacy overlay />

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <MandateConfirmation {...mandatePageProps()} />
    </>
  )
}
ConfirmationPage.propTypes = {
  next: PropTypes.func.isRequired,
  templateProps: PropTypes.object.isRequired,
}

export default ConfirmationPage
