export const NUMBER_ROUND = 100
export const PAYMENT_RATIO = 1.02
export const PAYMENT_ROUND = 10

const loanAmountOptions = { variant: 'accent-1' }
const firstLineOptions = { emoji: ':house_with_garden:' }

export const PROJECT_VALUES = {
  purchase: [
    ['project.purchase.property_price', 'propertyPrice', firstLineOptions],
    ['project.purchase.works_price', 'worksPrice'],
    ['project.purchase.land_price', 'landPrice'],
    ['project.purchase.build_price', 'buildPrice'],
    ['project.purchase.furniture_price', 'furniturePrice'],
    ['analysis.bridge.remaining', 'bridgeRemaining'],
    ['project.facts.notary_fees', 'notaryFees'],
    ['analysis.guarantyFees', 'guarantyFees'],
    ['project.facts.agency_fees', 'agencyFees'],
    ['analysis.applicationFees', 'applicationFees'],
    ['brokerage_fees.amount', 'brokerageFees'],
    ['analysis.contribution', 'contribution'],
    ['analysis.bridge.value', 'bridgeValue'],
  ],
  renegotiation: [
    ['project.renegotiation.remaining_principal', 'remainingPrincipal', firstLineOptions],
    ['project.renegotiation.works_amount', 'worksAmount'],
    ['analysis.penaltyFees', 'penaltyFees'],
    ['analysis.applicationFees', 'applicationFees'],
    ['brokerage_fees.amount', 'brokerageFees'],
    ['analysis.guarantyFees', 'guarantyFees'],
    ['analysis.contribution', 'contribution'],
  ],
}

export const PROJECT_RESULTS = {
  purchase: [
    ['analysis.loanAmount', 'loanAmount', loanAmountOptions],
    ['analysis.ptz.amount', 'ptzAmount'],
  ],
  renegotiation: [['analysis.loanAmount', 'loanAmount', loanAmountOptions]],
}
