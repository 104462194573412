import { Options } from '@pretto/app/src/onboarding/components/fields/Options/Options'

import PropTypes from 'prop-types'

export const BlocOptions = ({ name, options, columns, value, onSelect }) => {
  const props = {
    name,
    options,
    columns,
    value,
    onSelect,
  }

  return <Options {...props} />
}

BlocOptions.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  columns: PropTypes.number,
}
