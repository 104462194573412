import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const ACTION_URL = '/application/submit'

const Submit: React.FC<CardProps> = ({ defaultComponent: Component, onActionClick }) => {
  const handleActionClick = () => {
    onActionClick(ACTION_URL)
  }

  return (
    <Component
      actionLabel="Valider mon projet"
      actionUrl={ACTION_URL}
      description="Confirmez votre cahier des charges pour lancer la préparation de votre demande de prêt par Pretto."
      onActionClick={handleActionClick}
      tag="Étape en cours"
      title="Validation de votre projet"
    />
  )
}

export const config: Config = {
  component: Submit,
  name: 'submit',
}
