import ButtonCardComponent from '@pretto/bricks/components/buttons/ButtonCard'
import { g } from '@pretto/bricks/components/layout'
import { headline16 } from '@pretto/bricks/core/typography'

import omit from 'lodash/omit'
import styled from 'styled-components'

export enum BlockRateDoesntCountType {
  better = 'better_rate',
  worst = 'worst_rate',
}

interface BlockRateDoesntCountBaseProps {
  onChallengeButtonClick: () => void
  type: BlockRateDoesntCountType
}

interface BlockRateDoesntCountBetterRateProps extends BlockRateDoesntCountBaseProps {
  onInsuranceButtonClick: () => void
  type: BlockRateDoesntCountType.better
}

interface BlockRateDoesntCountWorstRateProps extends BlockRateDoesntCountBaseProps {
  type: BlockRateDoesntCountType.worst
}

export type BlockRateDoesntCountProps = BlockRateDoesntCountBetterRateProps | BlockRateDoesntCountWorstRateProps

export const BlockRateDoesntCount: React.FC<BlockRateDoesntCountProps> = ({ onChallengeButtonClick, ...props }) => (
  <Block {...omit(props, ['onInsuranceButtonClick', 'type'])}>
    <Title>Chez Pretto, il n’y a pas que le taux qui compte !</Title>

    {props.type === BlockRateDoesntCountType.better && (
      <p>
        Chaque projet est unique, notre accompagnement doit donc s’adapter. Votre offre est déjà très intéressante,
        allons plus loin :
      </p>
    )}

    {props.type === BlockRateDoesntCountType.worst && (
      <p>
        Vous avez déjà une offre mais allons plus loin. Échangez avec un courtier Pretto pour la comparer avec les
        autres offres du marché.
      </p>
    )}

    <Actions>
      <ButtonCard isArrowShown label="Challenger votre offre" onClick={onChallengeButtonClick} />

      {props.type === BlockRateDoesntCountType.better && (
        <ButtonCard
          isArrowShown
          href="https://pretto-fr.typeform.com/to/gNaE9ohR?utm_source=pretto&utm_medium=referral&utm_campaign=cocon"
          label="Trouver votre assurance sur mesure"
          onClick={props.onInsuranceButtonClick}
        />
      )}
    </Actions>
  </Block>
)

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-top: ${g(3)};
`

export const Block = styled.div`
  background-color: ${({ theme }) => theme.colors.primary4};
  border-radius: ${g(1)};
  padding: ${g(3)};
`

const ButtonCard = styled(ButtonCardComponent)`
  text-align: left;
`

const Title = styled.div`
  ${headline16};
  margin-bottom: ${g(2)};
`
