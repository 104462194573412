import { useBreakpoint } from '@pretto/bricks/assets/utility'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as S from './styles'

const RegroupCredits = ({ onClick, projectId }) => {
  const { isMobile } = useBreakpoint()
  return (
    <>
      <S.Title>
        <SubHeading>
          <Emoji>:money_with_wings:</Emoji> Nous avons peut-être une solution à vous proposer
        </SubHeading>
      </S.Title>
      <Card variant="accent-1-l">
        <Content>
          <Text>
            Savez-vous que vous pouvez regrouper vos crédits et faire baisser vos mensualités jusqu’à 60 % ? Cela peut
            vous permettre de concrétiser vos futurs projets de financement.
          </Text>

          <S.Button>
            <ButtonLegacy
              onClick={onClick}
              href={`https://pretto-fr.typeform.com/to/ykTcuF3s#project_id=${projectId}`}
              variant="accent-1"
              format={isMobile ? 'full' : null}
            >
              Réduire mes mensualités
            </ButtonLegacy>
          </S.Button>
        </Content>
      </Card>
    </>
  )
}

RegroupCredits.propTypes = {
  /** Triggered when button is clicked. */
  onClick: PropTypes.func,
  /** ID of current project to identify the typeform request. */
  projectId: PropTypes.string.isRequired,
}

export default RegroupCredits
