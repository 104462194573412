import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'

import { BlockProps } from '@pretto/app/src/purchaseOffer/config/types'
import { BlockSentences } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/BlockSentences/BlockSentences'
import { BlockTextarea } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/BlockTextarea/BlockTextarea'

import * as S from './Block.styles'

const renderBlock = ({ type, props }: BlockProps): JSX.Element => {
  switch (type) {
    case 'checkbox':
      return <InputCheckbox {...props} />

    case 'sentences':
      return <BlockSentences {...props} />

    case 'textarea':
      return <BlockTextarea {...props} />

    default:
      return <div>Unknown type</div>
  }
}

export const Block = (props: BlockProps): JSX.Element => <S.Block $type={props.type}>{renderBlock(props)}</S.Block>

Block.displayName = 'PurchaseOfferBlock'
