import { LOCAL_STORAGE_ALERT, LOCAL_STORAGE_IMMO } from '@pretto/app/src/Sentences/v2/config/storage/storage'
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import {
  FrequencyPropertySearch,
  InsuranceType,
  MaturityType,
  OccupationContractType,
  OccupationOthersStatus,
  OccupationPublicStatus,
  OccupationType,
  ProjectType,
  SciAssociation,
  SciType,
  SimulationType,
  SituationType,
  Structure,
  Usage,
} from '@pretto/app/src/Sentences/v2/types/enums'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'
import type { ContextValidator } from '@pretto/app/src/Sentences/v2/types/validators'
import { getItem } from '@pretto/app/src/config/itemStorage'
import { getUtmFlagsLastClicked } from '@pretto/app/src/lib/helpers'
import { isPartner as isProjectChoicePartner } from '@pretto/app/src/projectChoice/lib/isPartner'

import all from 'ramda/src/all'
import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'
import either from 'ramda/src/either'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import values from 'ramda/src/values'

import excludedPtzZipCodes from './excludedPtzZipCodes'

const isNotNil = (value: unknown) => !isNil(value)

export const isProspect: ContextValidator = context => context.isLoggedIn !== true

export const isLoggedIn: ContextValidator = context => context.isLoggedIn === true

export const isBuyout: ContextValidator = context => context.simulationType === SimulationType.Buyout

export const isRenegotiation: ContextValidator = context => context.simulationType === SimulationType.Renegotiation

export const isNotRenegotiation: ContextValidator = context => context.simulationType !== SimulationType.Renegotiation

export const hasSimulationType: ContextValidator = context => isNotNil(context.simulationType)

export const hasMaturityType: ContextValidator = context => isNotNil(context.maturityType)

export const hasMaturityPropertyPrice: ContextValidator = context => isNotNil(context.hasPropertyPrice)

export const isMaturityTypeUnderStudy: ContextValidator = context => context.maturityType === MaturityType.UnderStudy

export const isMaturityTypeSearchStarted: ContextValidator = context =>
  context.maturityType === MaturityType.SearchStarted

export const isMaturityTypeOfferMade: ContextValidator = context => context.maturityType === MaturityType.OfferMade

export const isMaturityTypeOfferAccepted: ContextValidator = context =>
  context.maturityType === MaturityType.OfferAccepted

export const isMaturityTypeSaleAgreementSigned: ContextValidator = context =>
  context.maturityType === MaturityType.SaleAgreementSigned

export const isMaturityComplete: ContextValidator = context =>
  hasMaturityType(context) &&
  (!isMaturityTypeSaleAgreementSigned(context) ||
    (isMaturityTypeSaleAgreementSigned(context) && hasAgreementSignedDate(context)))

export const hasAgreementSignedDate: ContextValidator = context => isNotNil(context.agreementSignedDate)

export const hasStructure: ContextValidator = context => isNotNil(context.structure)

export const isSCI: ContextValidator = context => context.structure === Structure.SCI

export const isNotSCI: ContextValidator = context => context.structure !== Structure.SCI

export const isIR: ContextValidator = context => context.sciType === SciType.IR

export const hasPropertyUsage: ContextValidator = context => isNotNil(context.usage)

export const hasPropertyType: ContextValidator = context => isNotNil(context.projectType)

export const isRentalUsage: ContextValidator = context => context.usage === Usage.Rental

export const isNotRentalUsage: ContextValidator = context => context.usage !== Usage.Rental

export const isExpectedRentalIncomeFilled: ContextValidator = context => isNotNil(context.expectedRentalIncome)

export const isSolo: ContextValidator = context => context.structure === Structure.Solo

export const isDuo: ContextValidator = context => context.structure === Structure.Duo

export const isNotSolo: ContextValidator = context => !isNil(context.structure) && context.structure !== Structure.Solo

export const isAssociationSimple: ContextValidator = context => context.sciAssociation === SciAssociation.Simple

export const hasAge: ContextValidator = context => isNotNil(context.mortgagors?.[0]?.age)

export const hasCoMortgagorAge: ContextValidator = context => isNotNil(context.mortgagors?.[1]?.age)

export const hasChildrenNumber: ContextValidator = context => isNotNil(context.children)

export const hasLiveTogetherCompleted: ContextValidator = context => isNotNil(context.liveTogether)

const hasMetropolitanAddress = (mortgagor: Mortgagor) =>
  mortgagor.country === 'fr' && isNotNil(mortgagor.city) && isNotNil(mortgagor.zipcode)

const hasInternationalAddress = (mortgagor: Mortgagor) => isNotNil(mortgagor.country) && mortgagor.country !== 'fr'

export const hasAddress: ContextValidator = context =>
  anyPass([hasMetropolitanAddress, hasInternationalAddress])(context.mortgagors?.[0] ?? {})

export const isInternational: ContextValidator = context => context.mortgagors?.[0].country !== 'fr'

export const isNotInternational: ContextValidator = context => context.mortgagors?.[0].country === 'fr'

export const hasComortgagorAddress: ContextValidator = context =>
  isSolo(context) || anyPass([hasMetropolitanAddress, hasInternationalAddress])(context.mortgagors?.[1] ?? {})

export const isComortgagorInternational: ContextValidator = context => context.mortgagors?.[1]?.country !== 'fr'

export const isComortgagorNotInternational: ContextValidator = context => context.mortgagors?.[1]?.country === 'fr'

export const isExpatriateFilled: ContextValidator = context => isNotNil(context.mortgagors?.[0].expatriate)

export const isCoMortgagorExpatriateFilled: ContextValidator = context => isNotNil(context.mortgagors?.[1]?.expatriate)

export const hasSituationType: ContextValidator = context => isNotNil(context.mortgagors?.[0].situationType)

const shouldHaveRent = (mortgagor: Mortgagor) =>
  mortgagor.situationType === SituationType.StaffHoused || mortgagor.situationType === SituationType.Tenant

export const hasRent: ContextValidator = context =>
  shouldHaveRent(context.mortgagors?.[0] ?? {}) ? isNotNil(context.mortgagors?.[0].rent) : true

const isOwner = (mortgagor: Mortgagor) => mortgagor.situationType === SituationType.Owner

export const hasForSale: ContextValidator = context =>
  context.usage === Usage.Primary && isOwner(context.mortgagors?.[0] ?? {})
    ? isNotNil(context.ownPropertyForSale)
    : true

const isOccupationPrivate = (mortgagor: Mortgagor) => mortgagor.occupationType === OccupationType.Private
const isExecutiveRoleFilled = (mortgagor: Mortgagor) => isNotNil(mortgagor.executiveRole)
const isTrialPeriodFilled = (mortgagor: Mortgagor) => isNotNil(mortgagor.trialPeriod)
const isLongTerm = (mortgagor: Mortgagor) => mortgagor.contractType === OccupationContractType.LongTerm
const isShortTerm = (mortgagor: Mortgagor) => mortgagor.contractType === OccupationContractType.ShortTerm
const isStartDateFilled = (mortgagor: Mortgagor) => isNotNil(mortgagor.startDate)

const isPrivateFilled = (mortgagor: Mortgagor) =>
  allPass([
    isOccupationPrivate,
    anyPass([
      allPass([isExecutiveRoleFilled, isTrialPeriodFilled, isLongTerm]),
      allPass([isShortTerm, isStartDateFilled]),
    ]),
  ])(mortgagor)

const isOccupationPublic = (mortgagor: Mortgagor) => mortgagor.occupationType === OccupationType.Public
const isOccupationMedical = (mortgagor: Mortgagor) => mortgagor.occupationType === OccupationType.Medical

const isTenure = (mortgagor: Mortgagor) => mortgagor.status === OccupationPublicStatus.Tenure
const isIntern = (mortgagor: Mortgagor) => mortgagor.status === OccupationPublicStatus.Intern
const isContractor = (mortgagor: Mortgagor) => mortgagor.status === OccupationPublicStatus.Contractor

const isPublicFilled = (mortgagor: Mortgagor) =>
  anyPass([
    allPass([isOccupationPublic, isTenure]),
    isIntern,
    allPass([isContractor, anyPass([isLongTerm, allPass([isShortTerm, isStartDateFilled])])]),
  ])(mortgagor)

const isOccupationOthers = (mortgagor: Mortgagor) => mortgagor.occupationType === OccupationType.Others
const hasStatus = (mortgagor: Mortgagor) => isNotNil(mortgagor.status)
const isRetired = (mortgagor: Mortgagor) => mortgagor.status === OccupationOthersStatus.Retired
const hasStartDate = (mortgagor: Mortgagor) => isNotNil(mortgagor.startMonth) && isNotNil(mortgagor.startYear)

const isOthersFilled = (mortgagor: Mortgagor) =>
  allPass([isOccupationOthers, hasStatus, either(isRetired, hasStartDate)])(mortgagor)

const isMedicalFilled = (mortgagor: Mortgagor) => allPass([isOccupationMedical, hasStartDate])(mortgagor)

export const isOccupationFilled: ContextValidator = context =>
  anyPass([isPrivateFilled, isPublicFilled, isOthersFilled, isMedicalFilled])(context.mortgagors?.[0] ?? {})

export const isCoMortgagorOccupationFilled: ContextValidator = context =>
  anyPass([isPrivateFilled, isPublicFilled, isOthersFilled, isMedicalFilled])(context.mortgagors?.[1] ?? {})

export const isNotOthersSector: ContextValidator = context =>
  context.mortgagors?.[0].occupationType !== OccupationType.Others

export const isOthersSector: ContextValidator = context =>
  context.mortgagors?.[0].occupationType === OccupationType.Others

export const isCoMortgagorOthersSector: ContextValidator = context =>
  context.mortgagors?.[1]?.occupationType === OccupationType.Others

export const isCoMortgagorNotOthersSector: ContextValidator = context =>
  context.mortgagors?.[1]?.occupationType !== OccupationType.Others

export const isSalaryFilled: ContextValidator = context =>
  isNotNil(context.mortgagors?.[0].income) &&
  !isEmpty(context.mortgagors?.[0].income) &&
  all(isNotNil)(values(context.mortgagors?.[0].income ?? {}))

export const isCoMortgagorSalaryFilled: ContextValidator = context =>
  isNotNil(context.mortgagors?.[1]?.income) &&
  !isEmpty(context.mortgagors?.[1]?.income) &&
  all(isNotNil)(values(context.mortgagors?.[1]?.income ?? {}))

export const isBonusFilled: ContextValidator = context =>
  isNotNil(context.mortgagors?.[0].hasBonus) &&
  (context.mortgagors?.[0].hasBonus === false ||
    (context.mortgagors?.[0].hasBonus === true && !isEmpty(context.mortgagors?.[0].bonus)))

export const isCoMortgagorBonusFilled: ContextValidator = context =>
  isNotNil(context.mortgagors?.[1]?.hasBonus) &&
  (context.mortgagors?.[1]?.hasBonus === false ||
    (context.mortgagors?.[1]?.hasBonus === true && !isEmpty(context.mortgagors?.[1]?.bonus)))

export const doesNotLiveTogether: ContextValidator = context => context.liveTogether === false

export const doesLiveTogether: ContextValidator = context => context.liveTogether === true

export const isRevenueFilled: ContextValidator = context => Array.isArray(context.mortgagors?.[0].revenues)

export const isCoMortgagorRevenueFilled: ContextValidator = context => Array.isArray(context.mortgagors?.[1]?.revenues)

export const hasComortgagorSituationType: ContextValidator = context => isNotNil(context.mortgagors?.[1]?.situationType)

export const hasComortgagorRent: ContextValidator = context =>
  shouldHaveRent(context.mortgagors?.[1] ?? {}) ? isNotNil(context.mortgagors?.[1]?.rent) : true

export const hasComortgagorForSale: ContextValidator = context =>
  context.usage === Usage.Primary && isOwner(context.mortgagors?.[1] ?? {})
    ? isNotNil(context.ownPropertyForSale)
    : true

export const hasAnyForSale: ContextValidator = context => anyPass([hasForSale, hasComortgagorForSale])(context)

export const hasOneMedical: ContextValidator = context =>
  isOccupationMedical(context.mortgagors?.[0] ?? {}) || isOccupationMedical(context.mortgagors?.[1] ?? {})

export const isForSale: ContextValidator = context =>
  context.usage === Usage.Primary &&
  !!context.ownPropertyForSale &&
  (context.mortgagors?.[0]?.situationType === SituationType.Owner ||
    context.mortgagors?.[1]?.situationType === SituationType.Owner)

export const isNotForSale: ContextValidator = context =>
  !context.ownPropertyForSale ||
  (context.mortgagors?.[0]?.situationType !== SituationType.Owner &&
    context.mortgagors?.[1]?.situationType !== SituationType.Owner)

export const isPrimaryUsage: ContextValidator = context => context.usage === Usage.Primary

export const isNotPrimaryUsage: ContextValidator = context => context.usage !== Usage.Primary

export const hasEstimatedPrice: ContextValidator = context => isNotNil(context.ownPropertyEstimatedPrice)

export const hasBank: ContextValidator = context => isNotNil(context.bank)

export const hasInsuranceType: ContextValidator = context => isNotNil(context.insuranceType)

export const isBankGroupInsurance: ContextValidator = context =>
  context.insuranceType === InsuranceType.BankGroupInsurance

export const isDelegatedInsurance: ContextValidator = context =>
  context.insuranceType === InsuranceType.DelegatedInsurance

export const hasRenegotiationPayment: ContextValidator = context => isNotNil(context.renegotiationPayment)

export const hasRenegotiationPaymentWithoutInsurance: ContextValidator = context =>
  isNotNil(context.renegotiationPaymentWithoutInsurance)

export const hasRenegotiationInsurancePayment: ContextValidator = context =>
  isNotNil(context.renegotiationInsurancePayment)

export const hasRate: ContextValidator = context => isNotNil(context.rate)

export const hasStartYear: ContextValidator = context => isNotNil(context.startYear)

export const hasEndYear: ContextValidator = context => isNotNil(context.endYear)

export const hasRenegotiationEstimatedValue: ContextValidator = context => isNotNil(context.estimatedValue)

export const isPaidOff: ContextValidator = context => context.ownPropertyPaidOff === true

export const isNotPaidOff: ContextValidator = context => context.ownPropertyPaidOff === false

export const hasRemainingMortgage: ContextValidator = context => isNotNil(context.ownPropertyRemainingPrincipal)

export const isBuyoutFilled: ContextValidator = context =>
  isNotNil(context.buyoutAmount) &&
  (context.hasBuyoutCredit === false ||
    (context.hasBuyoutCredit === true && isNotNil(context.buyoutCreditRemainingPrincipal)))

export const isCreditsFilled: ContextValidator = context => Array.isArray(context.credits)

export const isChildSupportFilled: ContextValidator = context => isNotNil(context.mortgagors?.[0].paysChildSupport)

export const isCoMortgagorChildSupportFilled: ContextValidator = context =>
  isNotNil(context.mortgagors?.[1]?.paysChildSupport)

export const hasGood: ContextValidator = context =>
  context.projectType === ProjectType.Old ||
  context.projectType === ProjectType.New ||
  context.projectType === ProjectType.VEFA

export const hasPropertyPrice: ContextValidator = context => isNotNil(context.propertyPrice)

export const isPropertyOld: ContextValidator = context => context.projectType === ProjectType.Old

export const isPropertyNew: ContextValidator = context => context.projectType === ProjectType.New

export const isPropertyVEFA: ContextValidator = context => context.projectType === ProjectType.VEFA

export const isPropertyNewOrVEFA: ContextValidator = context =>
  context.projectType === ProjectType.New || context.projectType === ProjectType.VEFA

export const isOldNewOrVEFA: ContextValidator = context =>
  context.projectType === ProjectType.Old ||
  context.projectType === ProjectType.New ||
  context.projectType === ProjectType.VEFA

export const isPropertyConstruction: ContextValidator = context => context.projectType === ProjectType.Construction

export const isPropertyLandConstruction: ContextValidator = context =>
  context.projectType === ProjectType.LandConstruction

export const hasWorks: ContextValidator = context => context.hasWorks === true

export const hasNoWorks: ContextValidator = context => context.hasWorks === false

export const hasWorksPrice: ContextValidator = context => isNotNil(context.worksPrice)

export const isWorksFilled: ContextValidator = context =>
  (hasWorks(context) && hasWorksPrice(context)) || hasNoWorks(context)

export const hasConstructionPrice: ContextValidator = context => isNotNil(context.constructionPrice)

export const hasLandPrice: ContextValidator = context => isNotNil(context.landPrice)

export const isCapacity: ContextValidator = context => context.simulationType === SimulationType.Capacity

export const isPurchase: ContextValidator = context => context.simulationType === SimulationType.Purchase

const calculatePrice = (context: ProjectContext): number => {
  const price = (context.propertyPrice || 0) + (context.worksPrice || 0)
  return price
}

const isExcludedZipCode = (excludedPtzZipcodes: string[], zipcode: string): boolean =>
  !excludedPtzZipcodes.find(code => code === zipcode)

const isSpecificPtzOld: ContextValidator = context => {
  const price = calculatePrice(context)
  return (
    context.projectType === ProjectType.Old &&
    isExcludedZipCode(excludedPtzZipCodes, context.propertyZipcode || '') &&
    !!context.hasWorks &&
    !!context.worksPrice &&
    context.worksPrice >= price * 0.25
  )
}

export const hasPTZ: ContextValidator = context =>
  (context.projectType !== ProjectType.Old || isSpecificPtzOld(context)) &&
  context.usage === Usage.Primary &&
  context.mortgagors?.[0].situationType !== SituationType.Owner

export const hasNotPTZ: ContextValidator = context => !hasPTZ(context)

export const isEligibleToEcoPtz: ContextValidator = context =>
  context.projectType === ProjectType.Old &&
  // Zou only accepts EcoPtz for primary residence
  context.usage === Usage.Primary /** || context.usage === Usage.Rental**/ &&
  hasWorks(context)

export const isNotEligibleToEcoPtz: ContextValidator = context => !isEligibleToEcoPtz(context)

export const isEcoPtzFilled: ContextValidator = context => isNotNil(context.hasEcoPtz)

export const isFirstTimeBuyer: ContextValidator = context => context.firstTimeBuyer === true

export const isNotFirstTimeBuyer: ContextValidator = context => context.firstTimeBuyer === false

export const hasFiscalIncomeNM2: ContextValidator = context => isNotNil(context.fiscalIncomeNM2)

export const isContributionFilled: ContextValidator = context => isNotNil(context.contribution)

export const isSavingsFilled: ContextValidator = context => isNotNil(context.savings)

export const hasEmailRateAlert: ContextValidator = context => isNotNil(context.emailRateAlert)

export const hasNotEmailRateAlert: ContextValidator = context => isNil(context.emailRateAlert)

export const isRateAlertFilled: ContextValidator = () => Boolean(getItem(LOCAL_STORAGE_ALERT))

export const isVisibleRateAlert: ContextValidator = context => context.isNotVisibleRateAlert !== true

export const isNotVisibleRateAlert: ContextValidator = context => context.isNotVisibleRateAlert === true

export const doesWantRateAlert: ContextValidator = context => context.wantsRateAlert === true

export const isPropertySearchFilled: ContextValidator = () => Boolean(getItem(LOCAL_STORAGE_IMMO))

export const isVisiblePropertySearch: ContextValidator = context => context.isNotVisiblePropertySearch !== true

export const isNotPropertySearchFilled: ContextValidator = context => !context.hasAnsweredPropertySearch

export const hasPropertySearchLocalisations: ContextValidator = context =>
  context.propertySearchLocalisations.length > 0

export const isPropertySearchAlertWanted: ContextValidator = context =>
  isNotNil(context.propertySearchFrequency) && context.propertySearchFrequency !== FrequencyPropertySearch.Never

export const hasPropertySearchEmail: ContextValidator = context => isNotNil(context.propertySearchEmail)

export const hasCompetition: ContextValidator = context => context.hasCompetition === true

export const isCompetitionFilled: ContextValidator = context => isNotNil(context.hasCompetition)

export const hasNotCompetition: ContextValidator = context => context.hasCompetition === false

export const hasCompetitionBank: ContextValidator = context => isNotNil(context.competitionBank)

export const hasCompetitionRate: ContextValidator = context => isNotNil(context.competitionRate)

export const hasCompetitionDuration: ContextValidator = context => isNotNil(context.competitionDuration)

export const hasDpe: ContextValidator = context => isNotNil(context.dpe)

export const isPartner: ContextValidator = () => isProjectChoicePartner(getUtmFlagsLastClicked().utm_campaign)

export const isNotPartner: ContextValidator = () => !isProjectChoicePartner(getUtmFlagsLastClicked().utm_campaign)
