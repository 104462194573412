import Bubble from '@pretto/bricks/shared/components/BubbleChat'

import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import AdvisorAvatar from '../../SharedContainers/AdvisorAvatar'

class BubbleChat extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object,
  }
  render() {
    const { children, ...props } = this.props
    return (
      <Bubble {...{ avatarProps: { src: <AdvisorAvatar size="small" /> } }} {...props}>
        {children}
      </Bubble>
    )
  }
}

export default BubbleChat
