import { DividerVertical } from '@pretto/zen/atoms/dividers/DividerVertical/DividerVertical'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { CheckBoldMini } from '@pretto/picto'

import * as S from './PartnerBlock.styles'

export interface PartnerBlockProps {
  logo: string
  description: React.ReactElement
  onCTAClick: () => void
  rtb: string[]
  cta: {
    label: string
    labelMobile: string
    href: string
  }
}

export const PartnerBlock = ({ logo, description, onCTAClick, rtb, cta, ...props }: PartnerBlockProps) => {
  const label = useBreakpointToValue(cta.labelMobile, { mobileL: cta.label })
  
  return (
    <S.PartnerContainer {...props}>
      <S.Logo>
        <S.PartnerLogo path={logo} />
        <DividerVertical />
        <S.PrettoLogotype />
      </S.Logo>
      
      <S.PartnerDescription>{description}</S.PartnerDescription>
      
      <S.Cta onClick={onCTAClick} href={cta.href}>
        {label}
      </S.Cta>
      
      <S.PartnerRTBContainer>
        {rtb.map(reason => (
          <S.PartnerRTBItem key={reason}>
            <S.PartnerRTBPicto>
              <CheckBoldMini />
            </S.PartnerRTBPicto>
            
            {reason}
          </S.PartnerRTBItem>
        ))}
      </S.PartnerRTBContainer>
    </S.PartnerContainer>
  )
}
