import PropTypes from 'prop-types'

const scaleRatio = 96 / 660 / 100

export const ShapeMask = ({ id, ...props }) => (
  <svg viewBox="0 0 660 660" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <clipPath id={id} clipPathUnits="objectBoundingBox" transform={`scale(${scaleRatio})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M660 0H0V399.5L0.180259 399.48C4.83065 503.737 99.2923 626.999 264 626.999C432.96 626.999 660 481.947 660 327.562C660 326.706 659.994 325.853 659.982 325.002L660 325V0Z"
        />
      </clipPath>
    </defs>
  </svg>
)

ShapeMask.propTypes = {
  id: PropTypes.string.isRequired,
}
