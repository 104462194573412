import { g } from '@pretto/bricks/components/layout'

import { ButtonChoice } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Options = ({ name, options, columns, value, onSelect }) => (
  <Container $columns={columns}>
    {options.map(({ label, value: optionValue, picto }) => {
      const isSelected = value === optionValue

      return (
        <StyledButtonChoice
          key={label}
          isSelected={isSelected}
          onClick={onSelect.bind(null, { name, value: optionValue })}
        >
          {picto && <Icon $picto={picto} />}
          <span>{label}</span>
        </StyledButtonChoice>
      )
    })}
  </Container>
)

Options.defaultProps = {
  columns: 1,
}

Options.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      picto: PropTypes.element,
    })
  ).isRequired,
  columns: PropTypes.number,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${g(2)};
`

const StyledButtonChoice = styled(ButtonChoice)`
  text-align: left;
`

const Icon = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  display: block;
  margin: 0 auto ${g(2)};
`
