import ServiceAlertImmo from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceAlertImmo'
import ServiceAppointment from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceAppointment'
import ServiceFAQ from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceFAQ'
import ServiceGuide from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceGuide'
import ServiceInsurance from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceInsurance'
import ServiceNewsletter from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceNewsletter'
import ServicePrettoSearch from '@pretto/app/src/PreLead/Containers/ServiceCards/ServicePrettoSearch'
import ServicePurchaseOffer from '@pretto/app/src/PreLead/Containers/ServiceCards/ServicePurchaseOffer'
import ServiceRate from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceRate'

import { createElement } from 'react'

import { PROGRESS, PROJECT_KIND } from './config'

const initServicesFound = ({ isPropertyFound, isValidProject, propertySearch }) => {
  if (!isPropertyFound) {
    return [ServicePurchaseOffer, ServiceNewsletter, ServiceAppointment, ServiceGuide, ServiceFAQ]
  }

  const projects = isValidProject
    ? [ServicePurchaseOffer, ServiceNewsletter, ServiceRate, ServiceGuide, ServiceFAQ]
    : [ServicePurchaseOffer, ServiceNewsletter, ServiceGuide, ServiceFAQ]

  if (propertySearch.data) {
    projects.unshift(ServiceAlertImmo)
  }

  return projects
}

const initServices = ({ isPropertyFound, isValidProject, progress, projectKind, propertySearch }) => {
  switch (projectKind) {
    case PROJECT_KIND.purchase:
      switch (progress) {
        case PROGRESS.found:
          return initServicesFound({ isPropertyFound, isValidProject, propertySearch })
        case PROGRESS.searching:
          return isValidProject
            ? [ServicePurchaseOffer, ServiceNewsletter, ServiceAppointment, ServiceGuide, ServiceFAQ]
            : [ServicePurchaseOffer, ServiceNewsletter, ServiceGuide, ServiceFAQ]
        default:
          return []
      }
    case PROJECT_KIND.renegotiation:
      if (isValidProject) {
        return [ServiceNewsletter, ServiceRate, ServiceInsurance, ServiceFAQ]
      }

      return [ServiceNewsletter, ServiceInsurance, ServiceFAQ]
    default:
      if (!propertySearch.data) {
        return [ServicePurchaseOffer, ServiceNewsletter, ServicePrettoSearch, ServiceGuide, ServiceFAQ]
      }

      return [ServicePurchaseOffer, ServiceNewsletter, ServiceGuide, ServiceFAQ]
  }
}

export const initServiceCards = ({ isPropertyFound, isValidProject, progress, projectKind, propertySearch }) => {
  const serviceCards = initServices({ isPropertyFound, isValidProject, progress, projectKind, propertySearch })

  return serviceCards.reduce((previous, element, i) => {
    if (!element) return previous

    return [...previous, createElement(element, { key: i, projectKind })]
  }, [])
}
