export const creditTypeOptions = [
  { label: 'Crédit immobilier', value: 'mortgage' },
  { label: 'Crédit conso', value: 'consumer' },
  { label: 'Crédit voiture', value: 'car' },
  { label: 'Crédit étudiant', value: 'student' },
  { label: "Location avec Option d'Achat (LOA, ou leasing)", value: 'LOA' },
  { label: 'Autre type de crédit', value: 'other' },
]

export const maturityOptions = [
  { value: 'under_study', label: 'Je suis en réflexion' },
  { value: 'search_started', label: 'Je suis en recherche active de bien' },
  { value: 'offer_made', label: 'J’ai fait une offre d’achat pour un bien' },
  { value: 'offer_accepted', label: "J'ai une offre d'achat acceptée" },
  { value: 'sale_agreement_signed', label: "J'ai signé un compromis de vente" },
]
