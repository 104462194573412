import { getProgressForPage } from '@pretto/app-core/application/lib/getProgressForPage'

export const getFormProgressForStep = (step, ...args) =>
  step.pages.reduce(
    ({ current, target }, page) => {
      const pageProgress = getProgressForPage(page, ...args)

      return { current: current + pageProgress.current, target: target + pageProgress.target }
    },
    { current: 0, target: 0 }
  )
