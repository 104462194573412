import { getProgressForStep } from '@pretto/app-core/application/lib/getProgressForStep'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import mapValues from 'lodash/mapValues'

export const getNavigationItems = (forms, currentPathname, ...args) =>
  Object.values(forms)
    .reduce((previous, step) => {
      const stepCondition = funcToItem(step.condition ?? true, ...args)

      if (!stepCondition) {
        return previous
      }

      const props = mapValues(step.sideBarParameters, (value, key) => {
        if (key === 'picto') {
          return value
        }

        return funcToItem(value, ...args)
      })

      const progress = getProgressForStep(step, ...args)

      return [
        ...previous,
        {
          ...props,
          isActive: currentPathname === props.url,
          isComplete: progress.target === 0 ? null : progress.current === progress.target,
        },
      ]
    }, [])
    .sort(({ sideBarOrder: sideBarOrderA }, { sideBarOrder: sideBarOrderB }) => sideBarOrderA - sideBarOrderB)
