import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

export const getDefaultValues = (sections, data, context) =>
  sections.reduce((previous, currentSection) => {
    const section = funcToItem(currentSection, {})

    return {
      ...previous,
      ...section.fields.reduce((previous, { name, type }) => {
        const value = get(data, name, get(context, name))

        if (typeof value === 'boolean') {
          return { ...previous, [name]: value.toString() }
        }

        if (type === 'textfield' && isNil(value)) {
          return { ...previous, [name]: '' }
        }

        return { ...previous, [name]: value }
      }, {}),
    }
  }, {})
