import { CriteriaEmailPage } from '@pretto/app/src/Sentences/v2/PropertySearch/CriteriaEmail/Containers/CriteriaEmailPage'
import { FrequencyPage } from '@pretto/app/src/Sentences/v2/PropertySearch/Frequency/Containers/FrequencyPage'
import { IntroductionPage } from '@pretto/app/src/Sentences/v2/PropertySearch/Introduction/Containers/IntroductionPage'
import { LocalisationsPage } from '@pretto/app/src/Sentences/v2/PropertySearch/Localisations/Containers/LocalisationsPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const PropertySearchController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/capacity/property-search/introduction')} component={IntroductionPage} />
      <Route path={graph.matchRoute('/project/capacity/property-search/localisations')} component={LocalisationsPage} />
      <Route path={graph.matchRoute('/project/capacity/property-search/frequency')} component={FrequencyPage} />
      <Route path={graph.matchRoute('/project/capacity/property-search/criteriaEmail')} component={CriteriaEmailPage} />
      <Redirect to="/" />
    </Switch>
  )
}
