import BlockInfoCTA from '@pretto/bricks/app/pre-lead/components/BlockInfoCTA'
import PreLeadCardRows from '@pretto/bricks/app/pre-lead/components/PreLeadCardRows'
import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { useMemo } from 'react'

import * as S from './styles'
import { parseDestination } from './utils'

const SectionRate = ({
  amount,
  buildPrice,
  contribution,
  isAppointmentAvailable,
  href,
  duration,
  landPrice,
  onEdit,
  payment,
  propertyPrice,
  rate,
  usage,
  worksPrice,
}) => {
  const rateItems = useMemo(
    () => [
      { label: 'Montant emprunté', value: formatNumber(amount, { suffix: '€' }) },
      { label: 'Mensualités', value: formatNumber(payment, { suffix: '€ /mois' }) },
      { label: 'Durée d’emprunt', value: formatNumber(duration, { suffix: 'ans' }) },
    ],
    [amount, payment, duration]
  )

  const projectItems = useMemo(
    () =>
      [
        { label: 'Destination', value: parseDestination(usage) },
        buildPrice && { label: 'Prix de la construction', value: formatNumber(buildPrice, { suffix: '€' }) },
        landPrice && { label: 'Prix du terrain', value: formatNumber(landPrice, { suffix: '€' }) },
        propertyPrice && { label: 'Prix du bien', value: formatNumber(propertyPrice, { suffix: '€' }) },
        worksPrice && { label: 'Montant des travaux', value: formatNumber(worksPrice, { suffix: '€' }) },
        { label: 'Votre apport', value: formatNumber(contribution, { suffix: '€' }) },
      ].reduce((previous, entry) => {
        if (!entry) {
          return previous
        }

        return [...previous, entry]
      }, []),
    [buildPrice, contribution, landPrice, propertyPrice, worksPrice, usage]
  )

  return (
    <PreLeadProjectCardTemplate href={href} isEditable onEdit={onEdit} type="simulation">
      <S.Infos>
        <S.RateCard>
          <S.Header>
            <S.Chart />
            Meilleur taux
          </S.Header>
          <S.Rate>{formatNumber(rate, { suffix: '%' })}</S.Rate>
          <PreLeadCardRows items={rateItems} />
        </S.RateCard>
        <Responsive min="mobileL">
          <S.Project>
            <S.Container>
              <S.Header>
                <S.House />
                Votre projet
              </S.Header>
              <PreLeadCardRows items={projectItems} />
              <S.SeeMore isSecondary href="/simulation">
                Lire plus
              </S.SeeMore>
            </S.Container>
          </S.Project>
        </Responsive>
      </S.Infos>
      {isAppointmentAvailable && (
        <BlockInfoCTA status="appointment" href="/simulation/subscribe?kind=dashboard_appointment&origin=dashboard" />
      )}
    </PreLeadProjectCardTemplate>
  )
}

SectionRate.propTypes = {
  /** Amount of loan. */
  amount: PropTypes.number.isRequired,
  /** Price of the build. */
  buildPrice: PropTypes.number,
  /** Amount of contribution */
  contribution: PropTypes.number,
  /** Duration of loan. */
  duration: PropTypes.number.isRequired,
  /** purchase simulation has error */
  error: PropTypes.bool,
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  isAppointmentAvailable: PropTypes.bool.isRequired,
  /** Price of the land. */
  landPrice: PropTypes.number,
  /** Edit function triggered on card click. */
  onEdit: PropTypes.func.isRequired,
  /** Payment of loan. */
  payment: PropTypes.number.isRequired,
  /** Price of property */
  propertyPrice: PropTypes.number,
  /** Rate showed for the project. */
  rate: PropTypes.number.isRequired,
  /** Type of buying */
  usage: PropTypes.string.isRequired,
  /** Price of works. */
  worksPrice: PropTypes.number,
}

export default SectionRate
