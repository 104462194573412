import { whiteLabels } from './whiteLabel'

const ROOT_URL = window.location.hostname.split('.').slice(-3).join('.')
const DEFAULT_URL = 'app.pretto.fr'
const partnersSubdomains = Object.keys(whiteLabels)

const getIfIsDevelopmentEnv = () => {
  if (process.env.NODE_ENV === 'development') return true

  if (window.location.hostname !== DEFAULT_URL && ROOT_URL !== DEFAULT_URL) return true

  return false
}

const redirect = (url = DEFAULT_URL) => {
  return (window.location.href = `https://${url}`)
}

export const getNextUrl = currentDomain => {
  const currentSubdomain = currentDomain.replace('.app.pretto.fr', '')

  if (currentDomain === DEFAULT_URL || [...partnersSubdomains].includes(currentSubdomain)) {
    return null
  }

  return DEFAULT_URL
}

export const controlSubdomain = () => {
  const url = getNextUrl(window.location.hostname)

  if (getIfIsDevelopmentEnv()) return

  if (url) {
    redirect(url)
  }
}

export const isEmbedded = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}
