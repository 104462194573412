import * as LABELS from '@pretto/app/src/config/operation'
import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'

export const DESCRIPTION = {
  'best_result.financing.applicationFees': LABELS.applicationFees.description,
  'best_result.financing.bridge.remaining': LABELS.bridgeRemaining.description,
  'best_result.financing.bridge.value': LABELS.bridgeValue.description,
  'best_result.financing.contribution': LABELS.contribution.description,
  'best_result.financing.guarantyFees': LABELS.guarantyFees.description,
  'best_result.financing.loanAmount': LABELS.loanAmount.description,
  'best_result.financing.penaltyFees': LABELS.penaltyFees.description,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'best_result.financing.ptz.amount': (valueFromPath: (path: string) => any) =>
    LABELS.ptzAmount.description(
      Math.max(1, Math.round(valueFromPath('best_result.financing.ptz.duration') / MONTHS_IN_YEAR)),
      Math.max(1, Math.round(valueFromPath('best_result.financing.ptz.franchise') / MONTHS_IN_YEAR) + 1)
    ),
  'brokerage_fees.amount': LABELS.brokerageFees.description,
  'project.facts.agency_fees': LABELS.agencyFees.description,
  'project.facts.notary_fees': LABELS.notaryFees.description,
  'project.purchase.furniture_price': LABELS.furniturePrice.description,
  'project.renegotiation.remaining_principal': LABELS.remainingPrincipal.description,
}

export const DETAILS = {
  'best_result.financing.applicationFees': LABELS.applicationFees.title,
  'best_result.financing.bridge.remaining': LABELS.bridgeRemaining.title,
  'best_result.financing.bridge.value': LABELS.bridgeValue.title,
  'best_result.financing.contribution': LABELS.contribution.title,
  'best_result.financing.creditCosts': LABELS.creditCosts.title,
  'best_result.financing.guarantyFees': LABELS.guarantyFees.title,
  'best_result.financing.loanAmount': LABELS.loanAmount.title,
  'best_result.financing.loanDuration': LABELS.loanDuration.title,
  'best_result.financing.payment': LABELS.payment.title,
  'best_result.financing.penaltyFees': LABELS.penaltyFees.title,
  'best_result.financing.ptz.amount': LABELS.ptzAmount.title,
  'best_result.financing.savings': LABELS.savings.title,
  'brokerage_fees.amount': LABELS.brokerageFees.title,
  'project.facts.agency_fees': LABELS.agencyFees.title,
  'project.facts.notary_fees': LABELS.notaryFees.title,
  'project.purchase.build_price': LABELS.buildPrice.title,
  'project.purchase.furniture_price': LABELS.furniturePrice.title,
  'project.purchase.land_price': LABELS.landPrice.title,
  'project.purchase.property_price': LABELS.propertyPrice.title,
  'project.purchase.works_price': LABELS.worksPrice.title,
  'project.renegotiation.remaining_principal': LABELS.remainingPrincipal.title,
  'project.renegotiation.works_amount': LABELS.worksAmount.title,
}

export const LOAN_TITLE = {
  purchase: 'Votre prêt',
  renegotiation: 'Votre nouveau prêt',
}

export const PROJECT_TITLE = {
  purchase: 'Votre projet',
  renegotiation: 'Votre rachat',
}

export const SHORT_DETAILS = {
  'best_result.financing.applicationFees': 'Dossier',
  'best_result.financing.guarantyFees': 'Garantie',
  'brokerage_fees.amount': 'Pretto',
  'project.facts.agency_fees': 'Agence',
  'project.facts.notary_fees': 'Notaire',
}
