export const getDropdownLabel = filters =>
  filters.reduce((previous, items) => {
    if (previous) {
      return previous
    }

    const item = items.find(({ isSelected }) => isSelected)

    if (!item) {
      return previous
    }

    return item.label
  }, null) ?? 'Filtrer'
