import { breakpoints, g } from '@pretto/bricks/components/layout'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'

import { Fragment, useMemo } from 'react'
import styled from 'styled-components'

import { RowChoices as RowChoicesComponent, RowChoicesProps } from './components/RowChoices/RowChoices'
import { RowNumber as RowNumberComponent, RowNumberProps } from './components/RowNumber/RowNumber'
import { RowSlider as RowSliderComponent, RowSliderProps } from './components/RowSlider/RowSlider'

export interface SimulatorProps {
  contributionValue: number
  durationValue: number
  durationValues: number[]
  onChangeContribution: (contribution: number) => void
  onChangeDuration: (duration: number) => void
  onChangePayment: (payment: number) => void
  paymentValue: number
  paymentValueMax: number
  paymentValueMin: number
}

enum RowType {
  choices,
  number,
  slider,
}

interface Row {
  type: RowType
}

interface RowChoices extends Row {
  props: RowChoicesProps<number>
  type: RowType.choices
}

interface RowNumber extends Row {
  props: RowNumberProps
  type: RowType.number
}

interface RowSlider extends Row {
  props: RowSliderProps
  type: RowType.slider
}

type UnionRow = RowChoices | RowNumber | RowSlider

export const Simulator: React.FC<SimulatorProps & React.HTMLAttributes<HTMLDivElement>> = ({
  contributionValue,
  durationValue,
  durationValues,
  onChangeContribution,
  onChangeDuration,
  onChangePayment,
  paymentValue,
  paymentValueMax,
  paymentValueMin,
  ...props
}) => {
  const rows = useMemo<UnionRow[]>(() => {
    const rows: UnionRow[] = [
      {
        props: {
          label: 'Votre apport :',
          onChange(value: number) {
            onChangeContribution(value)
          },
          value: contributionValue,
        },
        type: RowType.number,
      },
      {
        props: {
          label: 'Votre mensualité estimée :',
          onChange(value: number) {
            onChangePayment(value)
          },
          step: 25,
          value: paymentValue,
          valueMax: paymentValueMax,
          valueMaxLabel: `${paymentValueMax} € / mois max.`,
          valueMaxTooltip:
            'Votre future mensualité de crédit est prise en compte comme une charge dans le calcul de votre taux d’endettement. Au-delà d’une certaine mensualité, vous pouvez dépasser le taux d’endettement maximum autorisé.',
          valueMin: paymentValueMin,
          valueUnit: '€',
        },
        type: RowType.slider,
      },
    ]

    if (durationValues.length > 0) {
      return [
        ...rows,
        {
          props: {
            label: 'Durée de votre emprunt :',
            onChange(value: number) {
              onChangeDuration(value)
            },
            value: durationValue,
            values: durationValues.map(value => ({ label: `${Math.floor(value / 12)} ans`, value })),
          },
          type: RowType.choices,
        },
      ]
    }

    return rows
  }, [
    contributionValue,
    durationValue,
    durationValues,
    onChangeContribution,
    onChangeDuration,
    onChangePayment,
    paymentValue,
    paymentValueMax,
    paymentValueMin,
  ])

  return (
    <Component {...props}>
      {rows.map((row, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          {row.type === RowType.choices && <RowChoicesComponent {...row.props} />}
          {row.type === RowType.number && <RowNumberComponent {...row.props} />}
          {row.type === RowType.slider && <RowSliderComponent {...row.props} />}
        </Fragment>
      ))}
    </Component>
  )
}

const Component = styled.div`
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: ${g(2)} ${g(2)} ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} ${g(3)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(3)} ${g(4)};
  }
`
