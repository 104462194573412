import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { ProjectType } from '@pretto/app/src/Sentences/v2/types/enums'

import isNil from 'lodash/isNil'
import qs from 'qs'

type CalcPriceBeforeFeesProps = Pick<
  ProjectContext,
  'constructionPrice' | 'landPrice' | 'propertyPrice' | 'worksPrice' | 'projectType'
>

const calcPriceBeforeFees = ({
  constructionPrice = 0,
  landPrice = 0,
  propertyPrice = 0,
  worksPrice = 0,
  projectType,
}: CalcPriceBeforeFeesProps): number | null => {
  if (!projectType) return null

  switch (projectType) {
    case ProjectType.Construction:
      if (constructionPrice === 0) return null
      return constructionPrice + worksPrice

    case ProjectType.LandConstruction:
      if (constructionPrice === 0 || landPrice === 0) return null
      return constructionPrice + landPrice + worksPrice

    case ProjectType.New:
    case ProjectType.Old:
    case ProjectType.VEFA:
    default:
      if (propertyPrice === 0) return null
      return propertyPrice + worksPrice
  }
}

export type MinimumContribution = {
  contribution: number
  savings?: number
}

interface GetMinimumContribution {
  (context: ProjectContext): Promise<MinimumContribution | null>
}

export const getMinimumContribution: GetMinimumContribution = async context => {
  const { projectType, propertyZipcode: zipcode } = context

  const mortgagorNonResident = !isNil(context.mortgagors?.[0].country) && context.mortgagors?.[0].country !== 'fr'
  const coMortgagorNonResident = !isNil(context.mortgagors?.[1]?.country) && context.mortgagors?.[1]?.country !== 'fr'

  const priceBeforeFees = calcPriceBeforeFees(context)
  if (priceBeforeFees === null || !zipcode) return null

  const queryString = qs.stringify(
    { purchase_price: priceBeforeFees, property_kind: projectType, purchase_department: zipcode },
    { addQueryPrefix: true }
  )

  const response = await fetch(`https://api.pretto.fr/api/v0/public/contribution${queryString}`)
  if (!response.ok) return null
  const { fees } = await response.json()

  const hasNonResident = mortgagorNonResident || coMortgagorNonResident
  const hasConstruction = projectType === ProjectType.Construction || projectType === ProjectType.LandConstruction

  const contributionRate = hasNonResident ? 0.2 : hasConstruction ? 0.1 : 1
  const additionalAmount = hasConstruction || hasNonResident ? priceBeforeFees * contributionRate : 0

  return {
    contribution: roundNumber(fees + additionalAmount, 1000),
    savings: hasNonResident ? roundNumber(priceBeforeFees * 0.1, 1000) : undefined,
  }
}
