import Optimize from '@pretto/bricks/app/simulator/pages/Optimize'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'

import Header from '../../../SharedContainers/Header'
import { MONTHS_IN_YEAR } from '../../../lib/constants'
import { formatDisplayNumber } from '../../../lib/formats'

const optimizationToCard = (
  type,
  { financing: { loanDuration, mainLoansRate, payment }, project: { contribution } }
) => {
  switch (type) {
    case 'optimize_rate':
      return {
        children: 'En augmentant votre apport ou en réduisant la durée du prêt vous pouvez obtenir un meilleur taux.',
        data: [
          { label: 'Mensualité', value: formatDisplayNumber(payment) },
          { label: 'Durée', value: formatDisplayNumber(loanDuration / MONTHS_IN_YEAR, 'ans') },
          { label: 'Apport', value: formatDisplayNumber(contribution) },
        ],
        emoji: ':tada:',
        pushedContent: formatDisplayNumber(mainLoansRate, '%'),
        title: 'Le meilleur taux',
      }

    case 'optimize_payment':
      return {
        children: 'En ajustant la durée de votre prêt vous pouvez réduire votre mensualité au minimum.',
        data: [
          { label: 'Taux', value: formatDisplayNumber(mainLoansRate, '%') },
          { label: 'Durée', value: formatDisplayNumber(loanDuration / MONTHS_IN_YEAR, 'ans') },
          { label: 'Apport', value: formatDisplayNumber(contribution) },
        ],
        emoji: ':calendar:',
        pushedContent: formatDisplayNumber(payment),
        title: 'La mensualité minimum',
      }

    case 'optimize_contribution':
      return {
        children: 'Pour votre projet il est possible de réduire votre apport à un montant moins important.',
        data: [
          { label: 'Taux', value: formatDisplayNumber(mainLoansRate, '%') },
          { label: 'Mensualité', value: formatDisplayNumber(payment) },
          { label: 'Durée', value: formatDisplayNumber(loanDuration / MONTHS_IN_YEAR, 'ans') },
        ],
        emoji: ':moneybag:',
        pushedContent: formatDisplayNumber(contribution),
        title: "L'apport minimum",
      }

    default:
      throw new Error('Unrecognized type')
  }
}

class OptimizePage extends Component {
  static propTypes = {
    fetchOptimizations: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      optimizations: null,
    }
  }

  async componentDidMount() {
    const optimizations = await this.props.fetchOptimizations()

    this.setState({ loading: false, optimizations })
  }

  choiceHandler = key => () => {
    const {
      financing: { loanDuration },
      project: { contribution },
    } = this.state.optimizations[key]

    const type = key.replace('optimize', 'best')

    this.props.onChoose({ contribution, duration: loanDuration, type })
  }

  render() {
    const { loading, optimizations } = this.state

    if (loading) {
      return <SpinnerLegacy overlay />
    }

    const data = Object.keys(optimizations)
      .filter(key => Boolean(optimizations[key]))
      .map(key => ({
        ...optimizationToCard(key, optimizations[key]),
        action: this.choiceHandler(key),
        childrenPosition: 'bottom',
      }))

    return (
      <Fragment>
        <Header
          goBackProps={{ label: GO_BACK.simulator.label, onGoBackClick: this.props.onCancel }}
          navigationItemsList={[NAV_ITEMS.faq]}
        />
        <Optimize data={data} />
      </Fragment>
    )
  }
}

export default OptimizePage
