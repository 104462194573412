import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { createContext, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export const FOOTER_HEIGHT = g(14, 1)
export const HEADER_HEIGHT = g(9)
export const HEADER_HEIGHT_DESKTOP = g(11)

interface ScrollIntersectionContext {
  isGlobalOverflowing: boolean
  isLocalOverflowing: boolean
}

const scrollIntersectionContext = createContext<ScrollIntersectionContext>({
  isGlobalOverflowing: false,
  isLocalOverflowing: false,
})

export const useScrollIntersection = () => useContext(scrollIntersectionContext)

export const Layout: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const scrollIntersectionRef = useRef<HTMLDivElement>(null)

  const [isGlobalOverflowing, setIsGlobalOverflowing] = useState(false)
  const [isLocalOverflowing, setIsLocalOverflowing] = useState(false)

  useEffect(() => {
    const globalObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        setIsGlobalOverflowing(!isIntersecting)
      },
      {
        threshold: 0.5,
      }
    )

    const localObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        setIsLocalOverflowing(!isIntersecting)
      },
      {
        root: scrollContainerRef.current,
        threshold: 0.5,
      }
    )

    if (scrollIntersectionRef.current) {
      globalObserver.observe(scrollIntersectionRef.current)
      localObserver.observe(scrollIntersectionRef.current)
    }

    return () => {
      globalObserver.disconnect()
      localObserver.disconnect()
    }
  }, [])

  return (
    <scrollIntersectionContext.Provider value={{ isGlobalOverflowing, isLocalOverflowing }}>
      <Container>
        <Content {...props} ref={scrollContainerRef}>
          {children}

          <ScrollIntersection ref={scrollIntersectionRef} />
        </Content>
      </Container>
    </scrollIntersectionContext.Provider>
  )
}

const BACKGROUND_WIDTH = 1224
const BACKGROUND_HEIGHT = 319

const Container = styled.div`
  ${grid()};
  background-image: linear-gradient(${({ theme }) => theme.colors.overlay}, ${({ theme }) => theme.colors.overlay}),
    url('${getCloudinaryUrl('v1654775307/zen/static-assets/illustrations/scenery/city-wall-lineal-neutral3.svg', {
      width: BACKGROUND_WIDTH,
      height: BACKGROUND_HEIGHT,
    })}');
  background-position:
    left top,
    center calc(50% - ${Math.round(BACKGROUND_HEIGHT / 2)}px);
  background-repeat: no-repeat;
  background-size:
    100% 100%,
    ${BACKGROUND_WIDTH}px ${BACKGROUND_HEIGHT}px;
  height: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    height: calc(100vh - ${HEADER_HEIGHT_DESKTOP});
    padding-top: ${g(3)};
    padding-bottom: ${g(6)};
  }
`

const Content = styled.div`
  ${column([1, 6])};
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, -3])};
    border-radius: ${g(1)};
    height: 100%;
    overflow: auto;
  }
`

/* Might seem useless but it makes the footer scroll reveal effect
   work using a trick where this non-visible component
   is watched by an IntersectionObserver. */
const ScrollIntersection = styled.div`
  ${column([1, 6])};
  background-color: transparent;
  height: 1px;
  margin-top: -1px;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 10])};
  }
`
