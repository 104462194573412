import styled from 'styled-components'

import { tablet } from '@pretto/bricks/components/layout'

export const Backdrop = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(70)};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  @media screen and (min-width: ${tablet.min}) {
    backdrop-filter: blur(14px);
  }
`
