import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const Chips = ({ children, onClick }) => {
  return (
    <S.Container as={'button'} onClick={onClick}>
      <BaseTypo typo={'bodyMedium16'}>{children}</BaseTypo>
      <TimesBold />
    </S.Container>
  )
}

Chips.propTypes = {
  /**  children */
  children: PropTypes.node.isRequired,
  /**  Function when is clicked.  */
  onClick: PropTypes.func.isRequired,
}

export default Chips
