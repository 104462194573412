import Link from '@pretto/bricks/website/utility/Link'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { Loader } from '@pretto/zen/reveal/atoms/loader/Loader'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'
import type { CalcTableProps } from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'
import type { SimulatorProps } from '@pretto/app/src/Capacity/views/ResultPage/components/Simulator/Simulator'
import { ChevronDownBold } from '@pretto/picto'

import { useEffect, useRef, useState } from 'react'

import * as S from './BlocSimulation.styles'

export interface BlocSimulationProps extends NavigationProps {
  calcTableProps?: CalcTableProps | null
  isLast: boolean
  isLoading?: boolean
  isNotClient?: boolean
  isOnboardingDone: boolean
  onResultExpanded?: () => void
  price?: number | null
  simulatorProps: SimulatorProps
}

export const BlocSimulation: React.FC<BlocSimulationProps & React.HTMLAttributes<HTMLDivElement>> = ({
  calcTableProps,
  continueHref,
  isLast,
  isLoading = false,
  isNotClient = true,
  isOnboardingDone,
  onContinue,
  onResultExpanded,
  price,
  simulatorProps,
  ...props
}) => {
  const isResultExpandable = useBreakpointToValue(true, { laptop: false })

  const refResultContainer = useRef<HTMLDivElement>(null)

  const [isResultExpanded, setIsResultExpanded] = useState(false)
  const [isDismiss, setIsDismiss] = useState(false)

  useEffect(() => {
    if (isResultExpanded) {
      onResultExpanded?.()
    }
  }, [isResultExpanded])

  const handleResultExpandClick = () => {
    setIsResultExpanded(isResultExpanded => !isResultExpanded)
  }

  const handleSimulatorAnchorClick = () => {
    setIsResultExpanded(true)
    refResultContainer.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleDismiss = () => {
    setIsDismiss(true)
  }

  const isDisabled = !isLoading && (!price || !calcTableProps)

  return (
    <S.Component {...props}>
      <S.Container $isLast={isLast}>
        {!isOnboardingDone && !isNotClient && !isDismiss && (
          <S.Snackbar type="success" isDismissible onDismiss={handleDismiss}>
            Votre{' '}
            <Link href={continueHref()} onClick={onContinue}>
              espace client
            </Link>{' '}
            a bien été créé. Laissez-vous guider pour le personnaliser grâce à quelques questions.{' '}
            <Link href={continueHref()} onClick={onContinue}>
              Découvrir
            </Link>
          </S.Snackbar>
        )}

        <S.Title>{isDisabled ? 'Oups...' : isLoading ? '...' : 'Félicitations !'}</S.Title>

        <S.Hero $isDisabled={isDisabled}>
          <S.HeroLabel>
            <p>
              Vous pouvez
              <br />
              acheter un bien à :
            </p>

            {!isDisabled && (
              <S.HeroAnchor type="button" onClick={handleSimulatorAnchorClick}>
                Voir le calcul
              </S.HeroAnchor>
            )}
          </S.HeroLabel>

          {!isLoading && <S.Price>{(price ?? 0).toLocaleString('fr')} €</S.Price>}
          {isLoading && <Loader color="white" />}
        </S.Hero>

        <S.Simulator {...simulatorProps} />

        {isDisabled && (
          <S.Error>
            <S.ErrorTitle>Nous n’avons aucun résultat pour ces critères</S.ErrorTitle>
            <S.ErrorMessage>Nous ne pouvons pas vous proposer de budget pour ces critères.</S.ErrorMessage>
          </S.Error>
        )}

        {!isDisabled && (
          <S.Result ref={refResultContainer}>
            <S.ResultHeader $isExpanded={isResultExpanded}>
              <S.ResultTitle>
                Détails du calcul
                <S.ResultExpandButton $isExpanded={isResultExpanded} onClick={handleResultExpandClick} type="button">
                  <ChevronDownBold />
                </S.ResultExpandButton>
              </S.ResultTitle>

              <Divider />
            </S.ResultHeader>

            <S.Button href={continueHref(true)} onClick={onContinue}>
              Suivant
            </S.Button>

            <S.CalcTableAccordeon
              appear
              childComponent={S.CalcTableAccordeonChild}
              in={!isResultExpandable || isResultExpanded}
            >
              {!isLoading && calcTableProps && <S.CalcTable {...calcTableProps} />}

              {isLoading && (
                <S.CalcTableLoadingArea>
                  <Loader color="primary1" />
                </S.CalcTableLoadingArea>
              )}
            </S.CalcTableAccordeon>
          </S.Result>
        )}
      </S.Container>
    </S.Component>
  )
}
