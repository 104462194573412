import { g } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { useRef } from 'react'
import styled, { keyframes } from 'styled-components'

type ColorsAllowed = 'primary1' | 'primary3' | 'white'

type LoaderProps = {
  size?: keyof typeof SIZE_TO_PX
  color?: ColorsAllowed
}

type LoaderSCProps = {
  $color: ColorsAllowed
  $id: string
  $size: keyof typeof SIZE_TO_PX
}

const SIZE_TO_PX = {
  small: { radius: g(2 / 2), size: g(2) },
  medium: { radius: g(3 / 2), size: g(3) },
  large: { radius: g(4 / 2), size: g(4) },
}

export const Loader: React.FC<LoaderProps> = ({ size = 'large', color = 'primary3', ...props }) => {
  const { current: id } = useRef(random())

  return (
    <LoaderSC $color={color} $id={id} $size={size} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" height="0" width="0">
        <defs>
          <clipPath id={id} clipPathUnits="objectBoundingBox">
            <path d="m0,1V0h1v.46s-.01.04-.04.04-.04-.01-.05-.04c-.02-.23-.22-.4-.45-.38S.06.31.09.54c.02.23.22.4.45.38.2-.02.35-.17.38-.37,0-.02.02-.04.05-.04.02,0,.04.02.04.05v.44s-1,0-1,0Z" />
          </clipPath>
        </defs>
      </svg>
    </LoaderSC>
  )
}

const infiniteRotation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`

const LoaderSC = styled.span<LoaderSCProps>`
  animation: ${infiniteRotation} 1s infinite linear;
  background-image: conic-gradient(from 90deg, transparent, ${({ $color, theme }) => theme.colors[$color]});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ $size }) => SIZE_TO_PX[$size].size};
  border-radius: ${({ $size }) => SIZE_TO_PX[$size].radius};
  clip-path: url('#${({ $id }) => $id}');
  display: inline-flex;
  height: ${({ $size }) => SIZE_TO_PX[$size].size};
  width: ${({ $size }) => SIZE_TO_PX[$size].size};
`
