import Button from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ $backgroundColor, theme }) => theme.colors[$backgroundColor]};
  padding: ${g(3)} 0;
`

export const NextStep = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-wrap: nowrap;
  }
`

export const NextStepText = styled.div`
  color: ${({ $textColor, theme }) => theme.colors[$textColor]};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-right: ${g(4)};
  }
`

export const NextStepButton = styled(Button)`
  flex-shrink: 0;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: auto;
    margin-top: 0;
  }
`

export const Picto = styled.svg`
  flex-shrink: 0;
  fill: ${({ $textColor, theme }) => theme.colors[$textColor || 'primary1']};
  margin-right: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(-4)};
  }
`

export const Headline = styled(BaseTypo).attrs({ typo: 'headline' })`
  margin-bottom: ${g(1)};
  display: flex;
  align-items: center;
`
