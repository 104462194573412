import scoringFlagsFR from '@pretto/config/scoring_flags.json'
import scoringFlagsEN from '@pretto/config/scoring_flags_en.json'

import { uniqBy } from 'lodash'

import compileComments from '../Errors/lib/compileComments'
import getMalusFromFlags from '../Errors/lib/getMalusFromFlags'

import { MONTHS_IN_YEAR } from './constants'

const TWENTY_YEARS = 20

const getDefaultFlag = scoringFlags => ({
  ...scoringFlags.find(({ code }) => code === 'product_doesnt_exist'),
  severity: 'block',
})

const getScoringFlagsFromLocale = locale => (locale === 'fr' ? scoringFlagsFR : scoringFlagsEN)

export const getMalusFromEligibilityResults = (eligibility, project, locale = 'fr') => {
  const scoringFlags = getScoringFlagsFromLocale(locale)

  return eligibility.reduce((previous, code) => {
    const flag = scoringFlags.find(flag => code === flag.code)

    if (!flag) {
      return previous
    }

    return [...previous, { ...flag, comments: compileComments(flag.comments, { project }, locale), severity: 'block' }]
  }, [])
}

export const getMalusFromSimulationResults = (simulation, project, locale = 'fr') => {
  const scoringFlags = getScoringFlagsFromLocale(locale)
  // Retrieve all results for which the loan duration is 20 years
  const results = simulation.filter(({ financing }) => financing.loanDuration === TWENTY_YEARS * MONTHS_IN_YEAR)

  const defaultFlag = getDefaultFlag(scoringFlags)

  // Extract the first result out of those results for which there aren't any flags
  // with the code product_doesnt_exist
  const result = results.find(({ flags }) => flags.every(({ code }) => code !== defaultFlag.code))

  // If there is indeed one, return it
  if (result) {
    return getMalusFromFlags(uniqBy(result.flags, 'code'), { ...result, project }, { scoringFlags, locale })
  }

  // Otherwise, return the first result out of thoses "20 years" results
  // not matter if it contains a product_doesnt_exist flag
  if (results.length > 0) {
    const result = results[0]

    return getMalusFromFlags(uniqBy(result.flags, 'code'), { ...result, project }, { scoringFlags, locale })
  }

  // Otherwise, fallback to default
  return [defaultFlag]
}
