import { breakpoints, g } from '@pretto/bricks/components/layout'

import { ButtonChoice as ButtonChoiceComponent } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import styled, { css } from 'styled-components'

export interface RowChoicesProps<V> {
  label: string
  onChange: (value: V) => void
  value?: V | null
  values: Array<{
    label: string
    value: V
  }>
}

export const RowChoices = <V,>({
  label,
  onChange,
  value: selectedValue,
  values,
  ...props
}: RowChoicesProps<V> & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>) => (
  <Row {...props}>
    <Label>{label}</Label>

    <Choices>
      {values.map(({ label, value }, index) => {
        const handleClick = () => {
          onChange(value)
        }

        return (
          <ButtonChoice key={index} onClick={handleClick} isSelected={value === selectedValue}>
            {label}
          </ButtonChoice>
        )
      })}
    </Choices>
  </Row>
)

const ButtonChoice = styled(ButtonChoiceComponent)`
  ${({ isSelected, theme }) =>
    !isSelected &&
    css`
      border-color: ${theme.colors.neutral3};
      box-shadow: 0px 2px 0px 0px ${theme.colors.neutral3};
    `}
`

const Choices = styled.div`
  column-gap: ${g(1)};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  @media screen and (min-width: ${breakpoints.laptop}) {
    column-gap: ${g(2)};
  }
`

const Label = styled.span`
  ${({ theme }) => theme.typos.body4};
`

const Row = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`
