import { breakpoints, g } from '@pretto/bricks/components/layout'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import styled, { css } from 'styled-components'

export const Box = styled.div`
  cursor: pointer;
`

export const Title = styled(SubHeading)`
  display: flex;
  align-items: center;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const ToggleIcon = styled.div`
  font-size: ${g(1)};
  margin-left: ${g(2)};
  padding: ${g(1)};
  transform: rotateZ(0);
  transition: transform 200ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotateZ(180deg);
    `}
`
export const Content = styled.div`
  padding: 0 ${g(2)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)} ${g(3)};
  }
`
