import { mapContractTypeValues } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/mappers/mapContractType'
import { mapExecutiveValues } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/mappers/mapExecutiveValues'
import { mapMonths } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/mappers/mapMonths'
import { mapTrialValues } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/mappers/mapTrialValues'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import {
  Months,
  OccupationContractType,
  OccupationPrivateStatus,
  OccupationPrivateTrial,
} from '@pretto/app/src/Sentences/v2/types/enums'

interface PrivateChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PrivateChoices: React.FC<PrivateChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { goToNextRoute } = useRoutesGraph()
  const { mortgagors } = context
  const isOnTrial = mortgagors?.[0].trialPeriod === true

  const handleChoiceClick = () => {
    if (!isOnTrial) {
      goToNextRoute()
    }
  }

  return (
    <>
      {activeField === 1 && (
        <Choices
          choices={mapContractTypeValues(Object.values(OccupationContractType), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'occupationPrivateContractType' },
          }}
          onChoiceClick={() => onSetActive(2)}
        />
      )}
      {activeField === 2 && mortgagors?.[0].contractType === 'longTerm' && (
        <Choices
          choices={mapExecutiveValues(Object.values(OccupationPrivateStatus), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'occupationPrivateStatus' } }}
          onChoiceClick={() => {
            onSetActive(3)
          }}
        />
      )}
      {activeField === 3 && mortgagors?.[0].contractType === 'longTerm' && (
        <Choices
          choices={mapTrialValues(Object.values(OccupationPrivateTrial), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'occupationPrivateTrial' } }}
          onChoiceClick={handleChoiceClick}
        />
      )}
      {activeField === 2 && mortgagors?.[0].contractType === 'shortTerm' && (
        <Choices
          choices={mapMonths(Object.values(Months), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'occupationPrivateShortTerm' },
          }}
          onChoiceClick={() => {
            onSetActive(4)
          }}
        />
      )}
    </>
  )
}
