import { countrySearch as countryApi } from '@pretto/places'

import countries from 'i18n-iso-countries'
import enCountries from 'i18n-iso-countries/langs/en.json'
import frCountries from 'i18n-iso-countries/langs/fr.json'

countries.registerLocale(frCountries)
countries.registerLocale(enCountries)

export type { CountriesSearchResult } from '@pretto/places/dist/countrySearch'

export const countrySearch = countryApi.init(
  process.env.ALGOLIA_COUNTRIES_APP_ID,
  process.env.ALGOLIA_COUNTRIES_API_KEY
)

export const getCountryLabelFromCode = (
  countryCode?: string | null,
  localeIsoCode: 'fr' | 'en' = 'fr'
): string | undefined => {
  if (!countryCode) return undefined

  return countries.getName(countryCode.toUpperCase(), localeIsoCode)
}
