import Emoji from '@pretto/bricks/components/iconography/Emoji'

import { ExclamationMarkTriangleBold, InfoCircleBold } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const SepaSidebar = ({ avatarProps, informationText, hasComortgagor }) => (
  <>
    <S.BubbleChat avatarProps={avatarProps}>
      <S.Paragraph>
        L’autorisation de prélèvement SEPA vous est demandée pour le règlement des frais Pretto.
      </S.Paragraph>
      <S.Paragraph>
        {informationText} <Emoji verticalAlign="top">:ok_hand:</Emoji>.
      </S.Paragraph>
    </S.BubbleChat>

    <S.BubbleChat avatarProps={avatarProps} cardProps={{ variant: 'warning2' }} hideAvatar>
      <S.BubbleContent $state="warning">
        <S.Picto as={ExclamationMarkTriangleBold} />

        <p>
          Si la devise de votre compte bancaire n’est pas l’euro, des frais de change peuvent s’appliquer au moment du
          prélèvement des frais Pretto.
        </p>
      </S.BubbleContent>
    </S.BubbleChat>

    {hasComortgagor && (
      <S.BubbleChat avatarProps={avatarProps} cardProps={{ variant: 'neutral4' }} hideAvatar>
        <S.BubbleContent $state="info">
          <S.Picto as={InfoCircleBold} />

          <p>
            Vous préférez que votre co-emprunteur remplisse cette partie ? Nous avons besoin d’un seul compte bancaire
            renseigné par projet.
          </p>
        </S.BubbleContent>
      </S.BubbleChat>
    )}
  </>
)
SepaSidebar.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Whether there is a comortgagor or not. */
  hasComortgagor: PropTypes.bool,
  /** Information about payment. */
  informationText: PropTypes.string.isRequired,
}

export default SepaSidebar
