import { breakpoints, g } from '@pretto/bricks/components/layout'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import styled from 'styled-components'

export const ButtonNext = styled(PrimaryButtonFix)`
  margin-top: auto;
  margin-bottom: ${g(2)};
  display: flex;

  @media only screen and (min-width: ${breakpoints.laptop}) {
    align-self: flex-start;
    margin-top: ${g(5)};
  }
`
