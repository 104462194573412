import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Control = styled.div`
  & + & {
    margin-top: ${g(4)};
  }
`

export const Controls = styled.div`
  & + & {
    margin-top: ${g(2)};
  }
`

export const Label = styled.div`
  margin-bottom: ${g(1)};
`
