import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'
import nl2br from 'react-nl2br'

import * as S from './styles'

const MaturityPageTemplate = ({ children, description, title }) => (
  <Wrapper>
    <S.Page>
      <S.Content>
        <S.Headline>
          <SubHeading size="large">{title}</SubHeading>
        </S.Headline>

        <S.Headline>{nl2br(description)}</S.Headline>
      </S.Content>

      <S.Content>
        <div>{children}</div>
      </S.Content>
    </S.Page>
  </Wrapper>
)

MaturityPageTemplate.propTypes = {
  /** Descendants. */
  children: PropTypes.node,
  /** Description that goes below the title. */
  description: PropTypes.string.isRequired,
  /** Title of the page. */
  title: PropTypes.string.isRequired,
}

export default memo(MaturityPageTemplate)
