import { numeralBreakpoints } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'

import * as S from './styles'

const ResponsiveSSR = forwardRef(({ children, min, max, ...props }, ref) => {
  const responsiveProps = {}
  if (min) Object.assign(responsiveProps, { minWidth: `${numeralBreakpoints[min]}px` })
  if (max) Object.assign(responsiveProps, { maxWidth: `${numeralBreakpoints[max] - 1}px` })

  return (
    <S.Responsive ref={ref} {...props} {...responsiveProps}>
      {children}
    </S.Responsive>
  )
})

ResponsiveSSR.propTypes = {
  children: PropTypes.node,
  min: PropTypes.oneOf(Object.keys(numeralBreakpoints)),
  max: PropTypes.oneOf(Object.keys(numeralBreakpoints)),
}

ResponsiveSSR.displayName = 'ResponsiveSSR'

export default memo(ResponsiveSSR)
