import { getTrackingId } from '@pretto/app/src/lib/helpers'

export const formatTrackingId = (name: string) => {
  const value = getTrackingId(name)

  if (!value) {
    return ''
  }

  return `${name}=${value}`
}
