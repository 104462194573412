import TimelineMobile from '@pretto/bricks/app/simulator/components/TimelineMobile'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Column, column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import { ExclamationMarkTriangleBold, Times } from '@pretto/picto'

import styled from 'styled-components'

export const Sidebar = styled(Column)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(4)};
    ${grid(3, { isRightAligned: true })};
    background-color: ${({ theme }) => theme.colors.neutral4};
    grid-template-rows: max-content;
    row-gap: ${g(2)};
    padding-bottom: ${g(7)};
  }
`

export const Main = styled.div`
  ${column([2, 4])};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([6, 6])};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(7)} 0;
  }
`

export const Modal = styled(Dialog)`
  padding: ${g(2)} ${g(3)};
  position: relative;
`

export const Timeline = styled(TimelineMobile)`
  margin-bottom: ${g(4)};
`

export const CloseIcon = styled(Times)`
  position: absolute;
  right: ${g(3)};
  top: ${g(2)};
  width: ${g(3)};
  height: ${g(3)};
  z-index: 5;
`

export const Disclaimer = styled.div`
  margin-top: ${g(3)};

  a {
    text-decoration: underline;
  }
`

export const Error = styled.div`
  margin-top: ${g(2)};

  a {
    text-decoration: underline;
  }
`

export const Sentence = styled.div`
  line-height: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    line-height: ${g(7)};
  }
`

export const TextField = styled.div`
  display: inline-block;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
  ${typo.heading32};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
  }
`

export const TitleEmoji = styled.div`
  margin-right: ${g(2)};
`

export const ErrorSentenceIcon = styled(ExclamationMarkTriangleBold)`
  vertical-align: middle;
  fill: ${({ theme }) => theme.colors.error1};
  margin-left: ${g(1)};
`
