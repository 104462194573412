import ApplicationFormPageComponent from '@pretto/bricks/app/application/pages/ApplicationFormPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { decodeValuesForStep } from '@pretto/app-core/application/lib/decodeValuesForStep'
import { encodeSectionsValues } from '@pretto/app-core/application/lib/encodeSectionsValues'
import { getCurrentPageForId } from '@pretto/app-core/application/lib/getCurrentPageForId'
import { getCurrentStepForId } from '@pretto/app-core/application/lib/getCurrentStepForId'
import { getDocumentsParametersForStep } from '@pretto/app-core/application/lib/getDocumentsParametersForStep'
import { getDocumentsStatusForStep } from '@pretto/app-core/application/lib/getDocumentsStatusForStep'
import { reduceField } from '@pretto/app-core/application/lib/reduceField'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { renderSections } from '@pretto/app-core/lib/renderSections'
import { resetValuesForDisabledFields } from '@pretto/app-core/lib/resetValuesForDisabledFields'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import * as forms from '@pretto/app/src/Application/config'
import NotFoundPage from '@pretto/app/src/Errors/Containers/NotFoundPage'
import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { NavigationPrompt } from '@pretto/app/src/components/NavigationPrompt'
import { trackAction } from '@pretto/app/src/lib/tracking'

import { useApolloClient } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import path from 'path'
import PropTypes from 'prop-types'
import qs from 'qs'
import { createElement, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

const isSubmitDisabled = (errors, sections, values, data, userContext, optional = true) =>
  reduceField(
    sections,
    (previous, value) => previous || (!optional && !isFieldValid(value)),
    Object.values(errors).some(error => error === true),
    values,
    data,
    userContext
  )

const ApplicationFormPage = ({
  currentHref,
  data,
  documentsHref,
  documentsStatusBySection,
  isOnboarding,
  hasNextPage,
  notifySuccess,
  onTerminate,
  schema,
  title,
}) => {
  const defaultValues = useRef(null)
  const hasBeenSubmittedRef = useRef(false)

  const client = useApolloClient()
  const userContext = useUser()

  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [mutationLoading, setMutationLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [values, setValues] = useState(null)
  const [isPageErrored, setPageErrored] = useState(false)

  const isToSave = !isEqual(defaultValues.current, values)

  useEffect(() => {
    const initializeValues = async () => {
      const decodedValues = await decodeValuesForStep(schema.decoder, sections, data, userContext)
      const resetValues = resetValuesForDisabledFields(decodedValues, sections, data.project, userContext)

      defaultValues.current = resetValues
      setError(null)
      setErrors({})
      setMutationLoading(false)
      setValues(resetValues)
    }

    initializeValues()
  }, [schema.id])

  const sections = funcToItem(schema.sections, data, userContext)

  const handleSave = async () => {
    setUpdateLoading(true)
    const encodedSections = await encodeSectionsValues(schema.encoder, sections, values, defaultValues.current, data)

    setError(null)

    const mutations = encodedSections.map(encodedSection => {
      if (!encodedSection) {
        return Promise.resolve()
      }

      const { mutation, values } = encodedSection

      if (!mutation) {
        const clonedValues = cloneDeep(values)
        const mortgagorID = get(data.project, 'profile.mortgagors[0].id')
        const comortgagorID = get(data.project, 'profile.mortgagors[1].id')

        set(clonedValues, 'profile.mortgagors[0].id', mortgagorID)
        if (userContext.hasComortgagor) set(clonedValues, 'profile.mortgagors[1].id', comortgagorID)

        return new Promise(resolve =>
          client.mutate({
            mutation: UPDATE_PROJECT,
            update: resolve,
            variables: { project: JSON.stringify(clonedValues) },
          })
        )
      }

      const formattedMutation = funcToItem(mutation, values, data, userContext)

      return new Promise((resolve, reject) => {
        client.mutate({
          ...formattedMutation,
          update: (cache, response) => {
            const error = formattedMutation.error?.(response)

            if (error) {
              setError(error)
              setMutationLoading(false)
              reject()
              return
            }

            resolve()
          },
        })
      })
    })

    if (mutations.length === 0) {
      setUpdateLoading(false)
      return onTerminate()
    }

    await Promise.all(mutations)
    await invalidateCache(client)

    defaultValues.current = values
    setUpdateLoading(false)
    notifySuccess()
  }

  const handleSubmit = async () => {
    hasBeenSubmittedRef.current = true

    if (isSubmitDisabled(errors, sections, values, data, userContext, schema.optional)) {
      return setPageErrored(true)
    }

    const encodedSections = await encodeSectionsValues(schema.encoder, sections, values, defaultValues.current, data)

    await handleSave()
    onTerminate(encodedSections)
  }

  const handleChange = (name, value, error) => {
    setPageErrored(false)

    setErrors(errors => ({ ...errors, [name]: funcToItem(error, data.project, userContext) }))
    setValues(values => resetValuesForDisabledFields({ ...values, [name]: value }, sections, data.project, userContext))
  }

  if (!values || mutationLoading) {
    return <SpinnerLegacy overlay />
  }

  const handleGifClick = () => trackAction('APPLICATION_INTRODUCTION_GIF_CLICKED')

  return (
    <Container>
      <ApplicationFormPageComponent
        currentHref={currentHref}
        documentsHref={documentsHref}
        documentsStatusBySection={documentsStatusBySection}
        error={error}
        hasNextPage={hasNextPage}
        isButtonVisible={isToSave}
        isLoading={updateLoading}
        isNewIntroUI={isOnboarding}
        isOnboarding={isOnboarding}
        onGifClick={handleGifClick}
        onSubmit={handleSubmit}
        sections={renderSections(
          sections,
          handleChange,
          values,
          data,
          userContext,
          hasBeenSubmittedRef.current,
          isPageErrored
        )}
        title={title}
      />
      {!isOnboarding && <NavigationPrompt when={isToSave} onContinueClick={handleSave} />}
    </Container>
  )
}

ApplicationFormPage.propTypes = {
  currentHref: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  documentsHref: PropTypes.string.isRequired,
  documentsStatusBySection: PropTypes.oneOf(['idle', 'invalid', 'complete']),
  hasNextPage: PropTypes.bool,
  isOnboarding: PropTypes.bool.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  onTerminate: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    decoder: PropTypes.func,
    encoder: PropTypes.func,
    id: PropTypes.string,
    optional: PropTypes.bool,
    sections: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

const ApplicationFormPageController = ({ history, location, match }) => {
  const { data, introductionUrl, isBlocked } = useApplication()

  const { notify } = useNotifications()

  const userContext = useUser()

  useEffect(() => {
    if (location?.state?.isContinueAction) {
      notifySuccess()
    }
  }, [])

  const step = getCurrentStepForId(forms, match.params.step)
  const page = getCurrentPageForId(step, match.params.page)

  if (!page || !step) {
    return <NotFoundPage />
  }

  if (!step.ignoreAccessRestriction && (isBlocked || !userContext.isProjectEditable)) {
    return <Redirect to={introductionUrl} />
  }

  const title = funcToItem(page.title, data, userContext)

  const handleTerminate = (values = []) => {
    const { step } = match.params

    const href = funcToItem(page.jumps, data.project, userContext, location.search ?? '', values) ?? ''
    const absolutePath = path.resolve(`/application/${step}`, href)

    const redirect = qs.parse(location.search, { ignoreQueryPrefix: true })?.redirect
    history.push(redirect ?? absolutePath)
  }

  const notifySuccess = () => {
    notify('Vos informations ont bien été enregistrées !')
  }

  const headerProps = {
    goBackProps: GO_BACK[match.params.step === 'introduction' ? 'dashboard' : 'folder'],
    navigationItemsList: [NAV_ITEMS.faq],
  }

  if (page.component) {
    return (
      <>
        <Header {...headerProps} />
        {createElement(page.component, {
          onTerminate: handleTerminate,
        })}
      </>
    )
  }

  const documentsParameters = getDocumentsParametersForStep(step, data, userContext)
  const hasNextPage = funcToItem(page.hasNextPage, data, userContext)

  const getHref = () => {
    const queryParams = funcToItem(step?.queryParams, data, userContext) || qs.stringify(documentsParameters)

    return `/application/documents?${queryParams}`
  }

  const documentsHref = getHref()

  return (
    <>
      <Header {...headerProps} />
      <ApplicationFormPage
        key={location.pathname}
        currentHref={location.pathname}
        data={data}
        documentsHref={documentsHref}
        documentsStatusBySection={getDocumentsStatusForStep(step, data, userContext)}
        isOnboarding={match.params.step === 'introduction'}
        hasNextPage={hasNextPage}
        history={history}
        notifySuccess={notifySuccess}
        onTerminate={handleTerminate}
        schema={page}
        title={title}
      />
    </>
  )
}

ApplicationFormPageController.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      isContinueAction: PropTypes.bool,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ page: PropTypes.string, step: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
}

const Container = styled.div`
  position: relative;
`

export default ApplicationFormPageController
