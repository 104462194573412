import { g } from '@pretto/bricks/components/layout'

import { css } from 'styled-components'

const WEIGHT = {
  book: 400,
  medium: 500,
  bold: 700,
  black: 900,
}

const FONT_FAMILY = css`
  font-family: ${({ theme }) => theme.font}, sans-serif;
`

export const isNumber = css`
  font-variant-numeric: tabular-nums;
`

export const button18 = css`
  ${FONT_FAMILY}
  font-size: ${g(2, 2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.bold};
`

export const buttonUnderline18 = css`
  ${button18};
  text-decoration-line: underline;
`

export const link12 = css`
  ${FONT_FAMILY}
  font-size: ${g(1.5)};
  line-height: ${g(2)};
  font-weight: ${WEIGHT.medium};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

export const linkUnderline12 = css`
  ${FONT_FAMILY}
  font-size: ${g(1.5)};
  line-height: ${g(2)};
  font-weight: ${WEIGHT.medium};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration-line: underline;
`

export const inputBook16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(4)};
  font-weight: ${WEIGHT.book};
`

export const heading80 = css`
  ${FONT_FAMILY}
  font-size: ${g(10)};
  line-height: ${g(10)};
  font-weight: ${WEIGHT.black};
`

export const heading64 = css`
  ${FONT_FAMILY}
  font-size: ${g(8)};
  line-height: ${g(9)};
  font-weight: ${WEIGHT.black};
`

export const heading48 = css`
  ${FONT_FAMILY}
  font-size: ${g(6)};
  line-height: ${g(7)};
  font-weight: ${WEIGHT.black};
`

export const heading32 = css`
  ${FONT_FAMILY}
  font-size: ${g(4)};
  line-height: ${g(5)};
  font-weight: ${WEIGHT.black};
  letter-spacing: -0.025em;
`

export const heading16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.black};
  letter-spacing: -0.025em;
`

export const headline16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.medium};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

export const numbers16 = css`
  ${FONT_FAMILY}
  ${isNumber}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.book};
`

export const numbers40 = css`
  ${FONT_FAMILY}
  ${isNumber}
  font-size: ${g(5)};
  line-height: ${g(7)};
  font-weight: ${WEIGHT.book};
`

export const numbers48 = css`
  ${FONT_FAMILY}
  ${isNumber}
  font-size: ${g(6)};
  line-height: ${g(7)};
  font-weight: ${WEIGHT.black};
`

export const numbersBold16 = css`
  ${numbers16};
  font-weight: ${WEIGHT.bold};
`

export const numbers32 = css`
  ${FONT_FAMILY}
  font-size: ${g(4)};
  line-height: ${g(5)};
  font-weight: ${WEIGHT.black};
`

export const textBig40 = css`
  ${FONT_FAMILY}
  font-size: ${g(5)};
  line-height: ${g(7)};
  font-weight: ${WEIGHT.medium};
`

export const textLarge24 = css`
  ${FONT_FAMILY}
  font-size: ${g(3)};
  line-height: ${g(4)};
  font-weight: ${WEIGHT.medium};
`

export const bodyBlack = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.black};
`

export const bodyBold16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.bold};
`

export const bodyMedium16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.medium};
  b,
  strong {
    font-weight: ${WEIGHT.bold};
  }
`

export const bodyBook16 = css`
  ${FONT_FAMILY}
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-weight: ${WEIGHT.book};
  b,
  strong {
    font-weight: ${WEIGHT.bold};
  }
`

export const bodyBookUnderline16 = css`
  ${bodyBook16};
  text-decoration: underline;
`

export const caption12 = css`
  ${FONT_FAMILY}
  font-size: ${g(1.5)};
  line-height: ${g(2)};
  font-weight: ${WEIGHT.book};
  b,
  strong {
    font-weight: ${WEIGHT.medium};
  }
`

export const captionUnderline12 = css`
  ${caption12};
  text-decoration-line: underline;
`
