export const ARROW_DOWN = 40
export const ARROW_LEFT = 37
export const ARROW_RIGHT = 39
export const ARROW_UP = 38
export const CTRL = 17
export const DELETE = 46
export const END = 35
export const ENTER = 13
export const ESCAPE = 27
export const HOME = 36
export const SHIFT = 16
export const SPACE = 32
export const TAB = 9
export const NAV_KEYS = [ARROW_LEFT, ARROW_UP, ARROW_RIGHT, ARROW_DOWN]
