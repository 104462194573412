import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import PaginationDotsComponent from '@pretto/bricks/components/utility/PaginationDots'

import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { SuggestionLarge as SuggestionLargeComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/SuggestionLarge/SuggestionLarge'
import { SuggestionSlim as SuggestionSlimComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/SuggestionSlim/SuggestionSlim'

import range from 'lodash/range'
import styled, { css } from 'styled-components'

interface LayoutProps {
  $isSingle: boolean
  $numberOfSlides: number
}

export const Action = styled.div<LayoutProps>`
  ${column([2, 4])};
  display: block;
  justify-self: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};

    ${({ $isSingle }) =>
      $isSingle &&
      css`
        ${column([9, 5])};
        justify-self: flex-start;
        margin-right: ${g(5)};
      `};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 6])};

    ${({ $isSingle }) =>
      $isSingle &&
      css`
        ${column([9, 4])};
        margin-right: 0;
      `};
  }
`

export const Button = styled(PrimaryButtonFixComponent).attrs({ scheme: 'black' })``

export const Component = styled.section`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: ${g(4)} 0 ${g(5)};
  row-gap: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(6)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(10)} 0 ${g(10)};
    row-gap: ${g(6)};
  }
`

export const Content = styled.main<LayoutProps>`
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
    align-self: flex-end;
    text-align: center;

    ${({ $isSingle }) =>
      $isSingle &&
      css`
        ${column([9, 5])};
        margin-right: ${g(5)};
        text-align: left;
      `};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 6])};

    ${({ $isSingle }) =>
      $isSingle &&
      css`
        ${column([9, 4])};
        margin-right: 0;
      `};
  }
`

export const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
`

export const LoadingComponent = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${g(1)};
  padding: ${g(12)} 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(25)} 0;
  }
`

export const LoadingLabel = styled.p`
  ${({ theme }) => theme.typos.body4};
`

export const Navigation = styled.nav<LayoutProps>`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    display: flex;
    gap: ${g(7)};
    justify-content: center;

    ${({ $numberOfSlides }) =>
      $numberOfSlides <= 3 &&
      css`
        display: none;
      `}
  }
`

export const PaginationDots = styled(PaginationDotsComponent).attrs(({ theme }) => ({
  activeVariant: theme.colors.primary1,
  inactiveVariant: theme.colors.neutral3,
}))<LayoutProps>`
  ${column([1, 6])};
  height: ${g(2)};
  margin-top: ${g(-1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 10])};

    ${({ $numberOfSlides }) =>
      $numberOfSlides <= 2 &&
      css`
        display: none;
      `}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const Scroll = styled.div<LayoutProps>`
  ${column([2, 4])};

  ${({ $isSingle }) =>
    !$isSingle &&
    css`
      ${column([1, 6])};
      ${grid()};
      overflow-x: scroll;
      scroll-behavior: smooth;
      scroll-padding: ${g(2, 4)};
      scroll-snap-type: x mandatory;
      will-change: scroll-position;

      &::-webkit-scrollbar {
        display: none;
        width: 0px !important;
      }
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 10])};

    ${({ $isSingle, $numberOfSlides }) => {
      if ($isSingle) {
        return css`
          ${column([3, 6])};
        `
      }

      if ($numberOfSlides <= 2) {
        return css`
          overflow: visible;
        `
      }

      return css`
        scroll-padding: ${g(3)};
      `
    }};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 14])};

    ${({ $isSingle, $numberOfSlides }) => {
      if ($isSingle) {
        return css`
          ${column([2, 6])};
          grid-row: 1 / span 2;
          margin-left: ${g(5)};
        `
      }

      if ($numberOfSlides <= 2) {
        return css`
          margin: 0 ${g(5)};
        `
      }

      if ($numberOfSlides <= 3) {
        return css`
          overflow: visible;
        `
      }

      return null
    }};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([1, 14])};
    ${({ $isSingle }) => $isSingle && column([3, 5])};
    padding: 0;
  }
`

interface SuggestionProps extends LayoutProps {
  $isOutOfBounds: boolean
}

const SuggestionStyle = css<SuggestionProps>`
  ${({ $isOutOfBounds, $isSingle, $numberOfSlides }) =>
    !$isSingle &&
    range($numberOfSlides).map(
      index => css`
        ${$isOutOfBounds &&
        css`
          cursor: pointer;
        `}

        &:nth-child(${index + 1}) {
          scroll-snap-align: ${() => {
            if (index === $numberOfSlides - 1) {
              return 'end'
            }

            return 'start'
          }};
        }

        @media screen and (min-width: ${breakpoints.laptop}) {
          ${transition({ propertyName: 'opacity' })};

          ${$isOutOfBounds &&
          css`
            opacity: 0.5;
          `}

          &:nth-child(${index + 1}) {
            scroll-snap-align: ${() => {
              if ($numberOfSlides <= 2 || index === $numberOfSlides - 2) {
                return 'unset'
              }

              if (index === $numberOfSlides - 1) {
                return 'end'
              }

              return 'start'
            }};
          }
        }

        @media screen and (min-width: ${breakpoints.desktop}) {
          &:nth-child(${index + 1}) {
            scroll-snap-align: ${() => {
              if ($numberOfSlides <= 3 || index === 0 || index === $numberOfSlides - 1) {
                return 'unset'
              }

              return 'center'
            }};
          }
        }
      `
    )}
`

export const SuggestionSlim = styled(SuggestionSlimComponent)`
  ${SuggestionStyle}
`

export const SuggestionLarge = styled(SuggestionLargeComponent)`
  ${SuggestionStyle}
`

const setSuggestionsGrid = (
  numberOfGridColumns: number,
  numberOfColumnPerSlide: number,
  numberOfRepeatColumns: number,
  gutterWidth: string = g(3)
): string => `grid-template-columns: 100vw repeat(
  ${numberOfRepeatColumns},
  calc(((100% - (${gutterWidth}) * ${
    numberOfGridColumns - 1
  }) / ${numberOfGridColumns}) * ${numberOfColumnPerSlide} + (${gutterWidth} * ${numberOfColumnPerSlide - 1}))
) 100vw;`

const resetExternalSlides = css`
  &:after,
  &:before {
    content: none;
  }
`

const setExternalSlides = css`
  &:after,
  &:before {
    content: '';
    min-width: 1px;
    scroll-snap-align: none;
  }
`

export const Suggestions = styled.div<LayoutProps>`
  ${({ $isSingle, $numberOfSlides }) =>
    !$isSingle &&
    css`
      ${column([2, 4])};
      ${setExternalSlides};
      display: grid;
      gap: ${g(2, 4)};
      grid-template-columns: 100vw repeat(${$numberOfSlides}, calc(100% - ${g(2, 4)} * 2)) 100vw;

      @media screen and (min-width: ${breakpoints.tablet}) {
        ${column([2, 8])};
        ${resetExternalSlides};
        gap: ${g(3)};
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        ${$numberOfSlides > 2 && [setExternalSlides, setSuggestionsGrid(8, 5, $numberOfSlides)]};
      }

      @media screen and (min-width: ${breakpoints.laptop}) {
        ${column([2, 12])};
        ${resetExternalSlides};
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        ${$numberOfSlides > 3 && [setExternalSlides, setSuggestionsGrid(12, 5, $numberOfSlides)]};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        ${column([3, 10])};
        ${resetExternalSlides};
        ${$numberOfSlides > 2 && column([2, 12])};
        ${$numberOfSlides > 3 && [setExternalSlides, setSuggestionsGrid(3, 1, $numberOfSlides)]};
      }
    `}
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
  }
`
