import { EqualBold, MinusBold, Picto, PlusBold } from '@pretto/picto'

import { createElement } from 'react'

import type { EntryType } from '../ModaleResult'

export const formatPicto = (entry: EntryType): Picto | JSX.Element | null => {
  if ('picto' in entry) {
    return createElement(entry.picto)
  }

  if ('type' in entry) {
    switch (entry.type) {
      case 'equal':
        return <EqualBold />

      case 'minus':
        return <MinusBold />

      case 'plus':
      default:
        return <PlusBold />
    }
  }

  return null
}
