import { gql } from '@apollo/client'

export const USER = gql`
  fragment User on Query {
    advisor {
      id
      bio
      email
      holidays_end_date
      holidays_start_date
      largePicturePath
      mediumPicturePath
      name
      pair_name
      pair_email
      phone
      role
      visioUrl
      visio
    }
    project_id
    project {
      id
      project_kind
      purchase {
        property_price
        build_price
        maturity
      }
    }
    next_booking {
      email
      end
      id
      isVisio
      start
    }
  }
`
