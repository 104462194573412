// extracted by mini-css-extract-plugin
export var sentence = "pBMohh3IXhj03LjAIeVC";
export var sentenceAction = "zIX6USfhRRgRn9WvaTyD";
export var sentenceDelete = "FzvIyii8WZhfLrisCqFw";
export var sentenceError = "qTmlYu58GxoEJr8fTZdM";
export var sentenceHelper = "coC3I6MF1eWEos5PNmhj";
export var sentenceLabel = "Y83nzUjr5B86nWDr8zz5";
export var sentenceLast = "gi6vkSz1A1AgjFZa8jpB";
export var sentenceList = "JCKol1wAVEVpFX7U0HvD";
export var sentenceListContent = "UVH5Z7eE4VHwK6mkupgC";
export var sentenceListPosition = "xVx7vtg3tW4Ahhe5XdPm";
export var sentenceListPositionNumber = "qlI_ssHTPeTmjG3LO416";
export var sentenceWithLabel = "uNeQBUiTFR2KO5PQOVVb";
export var sentencesDeleteWrapper = "kGbyA6egenmKm1u6ETEQ";