import { BlockInfoProps } from '@pretto/bricks/app/simulator/components/ResultCardMain/children/BlockInfo'
import type { BlockRateDoesntCountProps } from '@pretto/bricks/app/simulator/components/SimulationContent/children/BlockRateDoesntCount/BlockRateDoesntCount'
import TimelineSidebar from '@pretto/bricks/app/timeline/components/TimelineSidebar'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import temporal from '@pretto/bricks/core/utility/temporal'

import type { BlocCacheProps } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocCache/BlocCache'
import type { BlocResultatProps } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocResultat/BlocResultat'
import type { ModaleResultProps } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/ModaleResult/ModaleResult'
import { House, Times, TimesBold, Tune, UserCircle } from '@pretto/picto'

import * as S from './ResultsPageContent.styles'

interface Step {
  href: string
  onClick?: () => void
  title: string
}

interface ResultsPageContentProps {
  blocCacheProps: BlocCacheProps
  blocResultatProps: BlocResultatProps
  blockRateDoesntCountProps: BlockRateDoesntCountProps | null
  blockInfoProps: BlockInfoProps | null
  date: temporal
  onEdit: () => void
  financingBanksCount: number
  footerInfosProps: { count: number; rate: number }
  hasAppointment: boolean
  isDetailsOpen: boolean
  isLoggedIn: boolean
  isOnboardingBlockVisible: boolean
  isTimelineOpen: boolean
  isTimelineVisible: boolean
  modaleResultProps: ModaleResultProps
  onCloseTimeline: () => void
  onDetailsClose: () => void
  onOpenTimeline: () => void
  steps: Step[]
}

// temporary hide the introduction while the market is struggling, should
// be put back when the banks are back to business
const isIntroductionVisible = false

export const ResultsPageContent: React.FC<ResultsPageContentProps> = ({
  blocCacheProps,
  blockRateDoesntCountProps,
  blocResultatProps,
  blockInfoProps,
  date,
  footerInfosProps,
  hasAppointment,
  isDetailsOpen,
  isLoggedIn,
  isOnboardingBlockVisible,
  isTimelineOpen,
  isTimelineVisible,
  modaleResultProps,
  onEdit,
  onCloseTimeline,
  onDetailsClose,
  onOpenTimeline,
  financingBanksCount,
  steps,
}) => (
  <S.View>
    <Responsive max="laptop">
      {isTimelineVisible && <S.Timeline onClick={onOpenTimeline} progress={0.95} />}

      <Dialog isOpen={isTimelineOpen} variant="neutral-1">
        <S.DialogContainer>
          <S.DialogClose>
            <Times onClick={onCloseTimeline} />
          </S.DialogClose>

          <TimelineSidebar steps={steps} />
        </S.DialogContainer>

        <S.DialogFooter>
          {isLoggedIn ? (
            <S.ButtonLogin href="/" label="Tableau de Bord" picto={House} />
          ) : (
            <S.ButtonLogin href="/login" label="Se connecter" picto={UserCircle} />
          )}
        </S.DialogFooter>
      </Dialog>
    </Responsive>

    <S.Header>
      <S.Title>
        Félicitations,
        <br />
        vous êtes finançable !
      </S.Title>

      <S.Status>taux au {date.format('DD/MM/YYYY')}</S.Status>
    </S.Header>

    <S.EditButton label="Modifier" onClick={onEdit} picto={Tune} />

    {isIntroductionVisible && (
      <S.Introduction>
        <strong>{financingBanksCount}</strong> {financingBanksCount > 1 ? 'banques sont prêtes' : 'banque est prête'} à
        vous financer !
      </S.Introduction>
    )}

    {blockRateDoesntCountProps && <S.BlockRateDoesntCount {...blockRateDoesntCountProps} />}
    {blockInfoProps && <S.BlockInfo {...blockInfoProps} />}
    {isOnboardingBlockVisible && <S.BlockOnboarding />}

    <S.BlocResultat {...blocResultatProps} />
    <S.Trustpilot {...footerInfosProps} />
    {!hasAppointment && <S.BlocCache {...blocCacheProps} />}

    <S.Dialog isOpen={isDetailsOpen} onRequestClose={onDetailsClose}>
      <S.CloseButton onClick={onDetailsClose}>
        <TimesBold />
      </S.CloseButton>
      <S.DialogContent>
        <S.ModaleResult {...modaleResultProps} />
      </S.DialogContent>
    </S.Dialog>
  </S.View>
)
