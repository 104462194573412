import { cloneElement } from 'react'

import { useTextField } from '../../Form/lib/useField'

import { useField } from './Field'

export const EmailField = props => {
  const { Component, fieldProps } = useField(props)
  const { value, ...textFieldProps } = useTextField(fieldProps)

  return cloneElement(Component, { ...textFieldProps, initialValue: value, value })
}
