import clamp from 'lodash/clamp'

/*
Polymonial expression of the second degree that resolves as follow:
f(-3) = 0
f(0) = 1
f(x0) = 1
f(x0 + 3) = 0
https://www.wolframalpha.com/input/?i=plot+y+%3D+%28%281+%2F+9%29+*+%28x1+%2F+%283+%2B+x1%29+-+1%29%29x0%5E2+%2B+%28x1+%2F+%289+%2B+3+*+x1%29%29x0+%2B+1%2C+x0%3D-3..10%2C+x1%3D0..7
*/
const scaleValueForIndex = (x0, x1) => {
  const a = (1 / 9) * (x1 / (3 + x1) - 1)
  const b = x1 / (9 + 3 * x1)
  const c = 1

  const y = a * x0 * x0 + b * x0 + c

  return clamp(y, 0, 1)
}

export default scaleValueForIndex
