import TipBubble from '@pretto/bricks/app/sentences/components/TipBubble'
import TimelineMobile from '@pretto/bricks/app/simulator/components/TimelineMobile'
import WhiteLabelPartners from '@pretto/bricks/app/subscribe/components/WhiteLabelPartners'
import Button from '@pretto/bricks/components/buttons/Button'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import ButtonGoogleConnect from '@pretto/bricks/components/buttons/ButtonGoogleConnect'
import InputCheckboxComponent from '@pretto/bricks/components/form/InputCheckbox'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as G from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'

import { ExclamationMarkTriangle } from '@pretto/picto'

import styled from 'styled-components'

export const Sidebar = styled.div`
  background: ${({ theme }) => theme.colors.neutral4};
  ${G.column(5)};
  ${G.grid(4, { isRightAligned: true })};
  grid-template-rows: auto 1fr;
`

export const SidebarContent = styled.div`
  padding-top: ${g(7)};
  ${G.column([2, 3])};
`

export const Main = styled.div`
  ${G.column([2, 4])};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${G.column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${G.column([7, 6])};
  }
`

export const TitleContainer = styled.div`
  margin-bottom: ${g(3)};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(7)};
  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })``

export const Bubble = styled(TipBubble)`
  margin-top: ${g(2)};
  margin-bottom: 0;
`

export const Sentence = styled.div`
  line-height: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    line-height: ${g(7)};
  }
`

export const Consent = styled.div``

export const Divider = styled(DividerComponent)`
  margin: ${g(2)} 0;
`

export const CheckBoxContainer = styled.div`
  display: flex;
`

export const AgreementCheckbox = styled(InputCheckboxComponent)`
  margin-right: ${g(2)};
`

export const Label = styled.label`
  ${typo.caption12};
  cursor: pointer;
`

export const Disclaimer = styled(BaseTypo).attrs({ typo: 'caption' })`
  margin-top: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-bottom: ${g(7)};

  a {
    text-decoration: underline;
    margin-bottom: 0;
  }
`

export const NextButton = styled(Button).attrs({ isArrowShown: true })`
  width: 100%;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: fit-content;
  }
`

export const GoogleConnect = styled(ButtonGoogleConnect).attrs({ color: 'blue' })`
  width: 100%;
  margin-left: ${g(1)};
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.error2};
  color: ${({ theme }) => theme.colors.error1};
  padding: ${g(2)};
  border-radius: ${g(1)};
  margin-bottom: ${g(2)};

  a {
    text-decoration: underline;
  }
`

export const FieldErrors = styled(BaseTypo).attrs({ typo: 'caption12' })`
  color: ${({ theme }) => theme.colors.error1};
`

export const Warning = styled(ExclamationMarkTriangle)`
  fill: ${({ theme }) => theme.colors.error1};
  margin-right: ${g(2)};
  flex-shrink: 0;
`

export const DialogContainer = styled.div`
  padding: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 35vh;
  }
`

export const DialogClose = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DialogFooter = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.neutral4};
  height: ${g(11)};
  width: 100%;
`

export const ButtonLogin = styled(ButtonFeature)`
  margin: ${g(4)} ${g(2.5)};
`

export const ProgressBar = styled(TimelineMobile)`
  margin: ${g(2)} 0;
  margin-bottom: ${g(4)};
`

export const Delivery = styled(Image).attrs({
  path: 'v1632403827/bricks/static-assets/illustrations/DelieveryFull.svg',
})`
  width: 100%;
  max-width: ${g(49)};
  margin: auto;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${G.column([2, 4])};
  }
`

export const DeliveryBottom = styled(Delivery)`
  display: block;
  margin: auto;
  margin-bottom: 0;
`

export const Partners = styled(WhiteLabelPartners)`
  margin-top: ${g(2)};
`
