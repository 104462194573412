import ListOptions from '@pretto/bricks/shared/components/ListOptions'
import MaturityChoice from '@pretto/bricks/shared/components/MaturityChoice'

import PropTypes from 'prop-types'
import { memo } from 'react'

const MaturityChoices = ({ options }) => (
  <ListOptions>
    {options.map(({ onClick, option, ...props }, index) => (
      <MaturityChoice {...props} key={index} onClick={onClick.bind(option)} option={option} />
    ))}
  </ListOptions>
)

MaturityChoices.propTypes = {
  /** Array of <code>[MaturityChoice](/#!/MaturityChoice)</code> props. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      option: PropTypes.string,
    })
  ).isRequired,
}

export default memo(MaturityChoices)
