import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { FileOwnershipLabel } from '@pretto/zen/dashboard/files/FileOwnershipLabel/FileOwnershipLabel'

import { CloudUpload, ExclamationMarkCircle, Picto as PictoType, Plane } from '@pretto/picto'

import styled, { css } from 'styled-components'

type StatusType = 'error' | 'sent' | 'empty'
export interface FileContentProps {
  label: React.ReactNode
  userName: string
  status: StatusType
}

interface IStatus {
  message: string
  picto: PictoType
}

const STATUS: Record<StatusType, IStatus> = {
  empty: {
    message: 'Manquant',
    picto: CloudUpload,
  },
  error: {
    message: 'Invalide',
    picto: ExclamationMarkCircle,
  },
  sent: {
    message: 'Envoyé !',
    picto: Plane,
  },
}

export const FileContent = ({ label, status, userName, ...props }: FileContentProps) => {
  const { message, picto } = STATUS[status]

  return (
    <Row {...props}>
      <RowLabel>
        <Label>{label}</Label>
        <FileOwnershipLabel label={userName} />
      </RowLabel>

      <RowValue $status={status}>
        <Message>{message}</Message>
        <Badge>
          <Picto as={picto} />
        </Badge>
      </RowValue>
    </Row>
  )
}

/* eslint-disable no-magic-numbers */
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const RowLabel = styled.div`
  flex: 1 1 0%;
  overflow: hidden;
`
interface RowValueProps {
  $status: FileContentProps['status']
}
const Badge = styled.div`
  width: ${g(6)};
  height: ${g(6)};
  border-radius: ${g(1)};
  display: flex;
  align-items: center;
  justify-content: center;
`
const RowValue = styled.div<RowValueProps>`
  display: flex;
  align-items: center;
  margin-left: ${g(2)};

  ${({ $status, theme }) => {
    switch ($status) {
      case 'empty':
        return css`
          color: ${theme.colors.neutral2};

          ${Badge} {
            border: 2px dashed ${theme.colors.neutral3};
            background-color: ${theme.colors.neutral4Hover};
          }
        `
      case 'error':
        return css`
          color: ${theme.colors.error1};

          ${Badge} {
            background-color: ${theme.colors.error2};
          }
        `
      case 'sent':
        return css`
          color: ${theme.colors.primary1};

          ${Badge} {
            background-color: ${theme.colors.primary4};
          }
        `

      default:
        break
    }
  }}
`
const Label = styled.div`
  margin-bottom: ${g(0.5)};
`
const Message = styled.div`
  ${typo.caption12};
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    margin-right: ${g(3)};
  }
`
const Picto = styled.svg``
