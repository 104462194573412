import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Emoji = styled.span`
  display: inline-block;
  height: auto;

  img {
    display: block;
    height: auto;
    vertical-align: inherit;
    width: inherit;
  }

  ${({ size }) => {
    switch (size) {
      case 'x-small':
        return css`
          width: ${g(2)};
          line-height: ${g(2)};
        `

      case 'small':
        return css`
          width: ${g(3)};
          line-height: ${g(3)};
        `

      case 'medium':
        return css`
          width: ${g(4)};
          line-height: ${g(4)};
        `

      case 'large':
        return css`
          width: ${g(6)};
          line-height: ${g(6)};
        `

      case 'x-large':
        return css`
          width: ${g(7)};
          line-height: ${g(7)};
        `

      case 'display':
        return css`
          width: ${g(10)};
          line-height: ${g(10)};

          @media screen and (min-width: ${breakpoints.tablet}) {
            width: ${g(14)};
            line-height: ${g(14)};
          }
        `

      default:
        return ''
    }
  }}
`
