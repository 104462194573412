import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { LogoPretto as LogoPrettoComponent } from '@pretto/zen/images/LogoPretto/LogoPretto'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: ${g(2)};
`

export const Image = styled(ImageComponent).attrs({
  path: 'v1639586223/zen/static-assets/illustrations/scenery/PrettoSearchMockup2.png',
  sizes: `(min-width: ${breakpoints.desktop}) ${g(88)}, (min-width: ${breakpoints.laptop}) 50vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  display: block;
  height: ${g(31)};
  object-fit: cover;
  width: 100%;
  z-index: 0;

  ${({ $version }) => {
    switch ($version) {
      case '1':
        return css`
          border-radius: ${g(1)};
        `

      case '2':
      default:
        return css`
          @media screen and (min-width: ${breakpoints.laptop}) {
            border-radius: ${g(1)};
          }
        `
    }
  }}
`

const version1 = css`
  ${helpers.boxShadow('card')};
  border-radius: ${g(1)};
  padding: ${g(2)};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${g(-4)} ${g(3)} 0;
`

const version2 = css`
  border-radius: ${g(1)};
  padding: ${g(2)};
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin: ${g(2, 4)} ${g(2, 4)} 0;
`

export const Bloc = styled.div`
  position: relative;
  z-index: 1;
  ${({ $version }) => {
    switch ($version) {
      case '1':
        return version1

      case '2':
      default:
        return css`
          ${version2};

          @media screen and (min-width: ${breakpoints.laptop}) {
            ${version1};
          }
        `
    }
  }}
`

export const Text = styled.div`
  ${typo.bodyBook16};

  strong {
    ${typo.bodyBold16};
  }
`

export const LogoPretto = styled(LogoPrettoComponent).attrs({ type: 'favicon' })`
  margin-right: ${g(2)};
  flex-shrink: 0;
`
