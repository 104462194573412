import ButtonGoogleConnect from '@pretto/bricks/components/buttons/ButtonGoogleConnect'
import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'
import styled from 'styled-components'

export const GoogleConnect = styled(ButtonGoogleConnect)`
  margin-top: ${g(3)};
  width: 100%;
`

export const Divider = styled.div`
  margin: ${g(1)} auto;
  text-align: center;
`

export const CGU = styled(BaseTypo).attrs({ typo: 'caption' })`
  color: ${({ theme }) => theme.colors.neutral2};
  margin-top: ${g(2)};
`

export const CGULink = styled(Link)`
  text-decoration: underline;
`
