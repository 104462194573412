import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import { createProjectContext } from '@pretto/app/src/Sentences/v2/lib/factories/context'
import { getContextFromUrlParams } from '@pretto/app/src/Sentences/v2/lib/getContextFromUrlParams'
import { getProject } from '@pretto/app/src/Sentences/v2/lib/getProject'
import { getStoredContext } from '@pretto/app/src/Sentences/v2/lib/localStorage'
import { mapPayloadToContext } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/mapPayloadToContext/mapPayloadToContext'
import { getSentencesContextFromLocation } from '@pretto/app/src/Sentences/v2/lib/prefill'
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'

// Define a unique SetStateAction with partials values from ProjectContext
// Remove the need to have a useState for each prop, keep the type
type SetContextProps = React.Dispatch<React.SetStateAction<Partial<ProjectContext>>>

// Combine both for the complete context type
export interface SentencesContextInterface extends ProjectContext {
  setContext: SetContextProps
  waitForOngoingOperations: () => Promise<ProjectContext>
}

export const SentencesContext = createContext<SentencesContextInterface>({} as SentencesContextInterface)

const getInitialContext = (projectIdUser: string): ProjectContext =>
  createProjectContext({
    ...(getStoredContext(projectIdUser) ?? {}),
    ...getSentencesContextFromLocation(),
    ...getContextFromUrlParams(),
  })

export const SentencesContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isLoggedIn } = useAuth()

  const user = useUser()

  const isOperationInProgress = useRef(false)
  const nextOperation = useRef<((value: ProjectContext) => void) | null>(null)

  const projectId: string | undefined = user?.projectID

  const [projectContext, setProjectContext] = useState<ProjectContext>(getInitialContext(projectId || ''))

  const isLoading = useLoading(projectContext.isReady !== true)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (projectId) {
        const token = getAuthToken() ?? undefined
        const data = await getProject(projectId, token)
        const project = data?.data?.project
        if (project) setContext(mapPayloadToContext(project))
      }

      setContext({ isReady: true })
    })()
  }, [projectId])

  useEffect(() => {
    isOperationInProgress.current = false
    nextOperation.current?.(projectContext)
    nextOperation.current = null
  }, [projectContext])

  // Create a partial setState for any values from the context
  const setContext: SetContextProps = (
    stateAction: Partial<ProjectContext> | React.SetStateAction<Partial<ProjectContext>>
  ) => {
    isOperationInProgress.current = true
    setProjectContext(projectContext => ({
      ...projectContext,
      ...(typeof stateAction === 'function' ? stateAction(projectContext) : stateAction),
    }))
  }

  if (isLoading) {
    return null
  }

  const waitForOngoingOperations = () => {
    if (!isOperationInProgress.current) {
      return Promise.resolve(projectContext)
    }

    return new Promise<ProjectContext>(resolve => {
      nextOperation.current = resolve
    })
  }

  return (
    <SentencesContext.Provider value={{ ...projectContext, isLoggedIn, setContext, waitForOngoingOperations }}>
      {children}
    </SentencesContext.Provider>
  )
}

export const useSentences = () => useContext(SentencesContext)
