import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const DividerVertical = styled.div`
  border: 0;
  height: ${g(2)};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.neutral3};
  margin: 0;
`
