import type { Revenue } from '@pretto/app/src/Sentences/v2/types/compensation'

import { v4 as uuidv4 } from 'uuid'

/**
 * @description Create a revenue with default values
 * @param {Partial<Revenue>} revenue
 * @returns {Revenue}
 * @example
 * createRevenue()
 * createRevenue({ amount: 1000 })
 * createRevenue({ type: 'salary' })
 * createRevenue({ amount: 1000, type: 'salary' })
 */
export const createRevenue = (revenue?: Partial<Revenue>): Revenue => ({
  id: uuidv4(),
  amount: null,
  type: null,
  ...revenue,
})
