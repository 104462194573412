import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as C from './AlertTemplate.module.css'

const COLORS = {
  error: {
    background: 'error2',
    text: 'error1',
  },
  information: {
    background: 'neutral4',
    text: 'neutral1',
  },
}

const AlertTemplate = ({ children, title, description, alert, alertKind }) => {
  const { background, text } = COLORS[alertKind]
  return (
    <div className={C.alertTemplate}>
      <div className={C.alertTemplateHeader}>
        <div className={C.alertTemplateHeaderTitle}>
          <Heading size="large">{title}</Heading>
        </div>
        {description && <div className={C.alertTemplateHeaderDescription}>{description}</div>}
        {alert && (
          <Wrapper full>
            <div className={C.alertTemplateHeaderAlert}>
              <Card variant={background}>
                <Content>
                  <Text variant={text}>{alert}</Text>
                </Content>
              </Card>
            </div>
          </Wrapper>
        )}
      </div>
      <div className={C.alertTemplateWrapper}>
        <Wrapper full>
          <div>{children}</div>
        </Wrapper>
      </div>
    </div>
  )
}

AlertTemplate.propTypes = {
  children: PropTypes.node,
  description: PropTypes.node,
  title: PropTypes.string,
  alert: PropTypes.node,
  alertKind: PropTypes.oneOf(Object.keys(COLORS)),
}
AlertTemplate.defaultProps = {
  alertKind: 'error',
}

export default AlertTemplate
