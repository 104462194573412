import NavBarComponent from '@pretto/bricks/components/bars/NavBar'
import { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE, SPACE, TAB } from '@pretto/bricks/core/constants/keycodes'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { formatNavigationItem } from '@pretto/app/src/SharedContainers/Header/lib/formatNavigationItem'
import { getUserData } from '@pretto/app/src/SharedContainers/Header/lib/getUserData'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import whiteLabel from '@pretto/app/src/config/whiteLabel'
import { t } from '@pretto/app/src/lib/i18n'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

const headerRoot = document.getElementById('root__header')

const NavBar = ({
  accountVariant,
  backgroundColor,
  focusProps,
  goBackProps,
  isMobileHidden,
  isPartnerLogoDisabled,
  menuProps,
  mode,
  navigationItemsList,
}) => {
  const { isLoggedIn } = useAuth()
  const { pathname } = useLocation()
  const trackAction = useTracking()
  const { user, typology } = useUser()
  const { isOpen, onOpen, onClose } = menuProps
  const { defineAccountKeyDown, defineMenuFocusIndex, isAccountFocused } = focusProps

  const defaultAccountVariant = isLoggedIn ? 'loggedIn' : pathname === '/login' ? 'signUp' : 'signIn'

  const userData = getUserData({ isLoggedIn, typology, user })

  const navigationItemsListFormatted = formatNavigationItem({ navigationItemsList, pathname, trackAction })

  const handleAccountKeyDown = e => {
    defineAccountKeyDown(e.keyCode)
    switch (e.keyCode) {
      case SPACE:
      case ENTER:
      case ARROW_DOWN:
      case ARROW_UP:
        e.preventDefault()
        onOpen()
        break

      case ESCAPE:
        e.preventDefault()
        defineAccountKeyDown(null)
        defineMenuFocusIndex(null)
        onClose()
        break

      case TAB:
        break

      default:
        e.preventDefault()
        break
    }
  }

  const handleAccountClick = () => {
    defineAccountKeyDown(null)
    onOpen()
  }

  const loginButtonLabel = t('navigation.login')

  if (mode === 'compact') {
    return (
      <NavBarComponent
        backgroundColor={backgroundColor}
        accountVariant={accountVariant || defaultAccountVariant}
        goBackProps={goBackProps}
        isAccountFocused={isAccountFocused}
        isMenuOpen={isOpen}
        isMobileHidden={isMobileHidden}
        loginButtonLabel={loginButtonLabel}
        navigationItemsList={navigationItemsListFormatted}
        onAccountClick={handleAccountClick}
        onAccountKeyDown={handleAccountKeyDown}
        partnerData={!isPartnerLogoDisabled ? whiteLabel() : null}
        user={userData}
        mode={mode}
      />
    )
  }

  return (
    headerRoot &&
    createPortal(
      <NavBarComponent
        backgroundColor={backgroundColor}
        accountVariant={accountVariant || defaultAccountVariant}
        goBackProps={goBackProps}
        isAccountFocused={isAccountFocused}
        isMenuOpen={isOpen}
        isMobileHidden={isMobileHidden}
        loginButtonLabel={loginButtonLabel}
        navigationItemsList={navigationItemsListFormatted}
        onAccountClick={handleAccountClick}
        onAccountKeyDown={handleAccountKeyDown}
        partnerData={!isPartnerLogoDisabled ? whiteLabel() : null}
        user={userData}
        mode={mode}
      />,
      headerRoot
    )
  )
}

NavBar.propTypes = {
  /** Color of the background */
  backgroundColor: PropTypes.string,
  /** Variant of the account bloc */
  accountVariant: PropTypes.oneOf(['loggedIn', 'signIn', 'signUp', 'onlyInitial']),
  focusProps: PropTypes.object.isRequired,
  /** Optionnal props of Go Back button */
  goBackProps: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
  mode: PropTypes.oneOf(['default', 'compact']),
  /** Is the nav bar hidden on mobiles ? */
  isMobileHidden: PropTypes.bool,
  /** Is partner logo disabled on the page, when it can be ? */
  isPartnerLogoDisabled: PropTypes.bool,
  /** List of the navigation items */
  menuProps: PropTypes.object.isRequired,
  navigationItemsList: PropTypes.array,
  /** Override the login button label for English version */
  loginButtonLabel: PropTypes.string,
}

NavBar.defaultProps = {
  navigationItemsList: [],
}

export default NavBar
