import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'
import NoFinancingPage from '@pretto/bricks/shared/pages/NoFinancingPage'
import { FAQCard } from '@pretto/bricks/shared/pages/NoFinancingPage/components/FAQCard/FAQCard'
import { GuideCard } from '@pretto/bricks/shared/pages/NoFinancingPage/components/GuideCard/GuideCard'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import { useEffect } from 'react'

import Header from '../../../SharedContainers/Header'
import { useTracking } from '../../../lib/tracking'
import { PartnerCard } from '../../components/PartnerCard/PartnerCard'
import regroupCredits from '../../lib/regroupCredits'

import { getPartnerCardProps } from './utils/getPartnerCardProps'

const ErrorPage = ({ project, projectId, ...props }) => {
  const trackAction = useTracking()

  const isRegroupCreditsActive = regroupCredits(project)

  useEffect(() => {
    const trackingName = 'SIMULATION_ERROR_PAGE_VIEWED'

    const trackingOptions = {
      simulation_error_flags: props.malus,
      simulation_error_regroup_credits_shown: isRegroupCreditsActive,
      simulation_kind: project.project_kind,
    }

    trackAction(trackingName, trackingOptions)
  }, [])

  const onCardClick = slug => {
    trackAction('SIMULATION_ERROR_CARD_CLICKED', { simulation_error_card_slug: slug })
  }

  const handleFaqClick = () => {
    onCardClick('faq')
  }

  const handleGuideClick = () => {
    onCardClick('guide')
  }

  const handlePartnerCtaClick = slug => {
    onCardClick(slug)
  }

  const handleRegroupCreditsClick = () => {
    trackAction('SIMULATION_ERROR_REGROUP_CREDITS_BUTTON_CLICKED')
  }

  const isSelfEmployment = project.profile.mortgagors.some(({ contract }) => contract === 'self_employed')
  const isLowAmount = props.malus.some(({ code }) => code === 'low_amount')

  const footerComponents = [
    isSelfEmployment && (
      <ThemeProvider designSystem="reveal" key={0}>
        <PartnerCard {...getPartnerCardProps('selfemployment', handlePartnerCtaClick)} />
      </ThemeProvider>
    ),
    isLowAmount && (
      <ThemeProvider designSystem="reveal" key={0}>
        <PartnerCard {...getPartnerCardProps('lowamount', handlePartnerCtaClick)} />
      </ThemeProvider>
    ),
    <GuideCard key={1} onGuideClick={handleGuideClick} />,
    <FAQCard key={2} onFaqClick={handleFaqClick} />,
  ]

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <NoFinancingPage
        {...props}
        footerComponents={footerComponents}
        onRegroupCreditsClick={handleRegroupCreditsClick}
        projectId={projectId}
        regroupCredits={isRegroupCreditsActive}
      />
    </>
  )
}

ErrorPage.propTypes = {
  malus: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default ErrorPage
