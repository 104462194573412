import SimulationContent from '@pretto/bricks/app/simulator/components/SimulationContent'
import SimulationSidebar from '@pretto/bricks/app/simulator/components/SimulationSidebar'
import Button from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, Column, grid, Grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Estimations from '@pretto/bricks/shared/components/Estimations'

import styled, { css } from 'styled-components'

export const Page = styled(Grid)`
  min-height: 100%;
`
const leftGrid = css`
  ${column([2, 4])};
  margin-right: ${g(3)};
`

export const Sidebar = styled(Column)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(5)};
    ${grid(4, { isRightAligned: true })};
    background-color: ${({ theme }) => theme.colors.neutral4};
    grid-template-rows: auto auto 1fr;
    row-gap: ${g(2)};
    padding-bottom: ${g(7)};
  }
`

export const SidebarInputs = styled(SimulationSidebar)`
  margin-bottom: ${g(2)};
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
    margin-bottom: 0;
  }
`

export const Content = styled(SimulationContent)`
  ${column([2, 4])};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(7)} 0;
  }
`

export const SideContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
  }
`

export const Headline = styled.div`
  ${typo.headline16}
`

export const CTA = styled(Button)`
  margin-top: ${g(2)};
  width: 100%;
`

export const LegalText = styled(Estimations)`
  margin-top: 0;
  margin-right: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
  }
`
