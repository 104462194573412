export const TYPES = [
  'button18',
  'link12',
  'inputBook16',
  'heading64',
  'heading48',
  'heading32',
  'heading16',
  'headline16',
  'textBig40',
  'textLarge24',
  'bodyBold16',
  'bodyMedium16',
  'bodyBook16',
  'caption12',
  'numbers32',
  // Legacy --------------
  'button',
  'link',
  'input',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'headline',
  'textBig',
  'textLarge',
  'bodyBlack',
  'bodyBold',
  'bodyMedium',
  'bodyBook',
  'caption',
]
