import temporal from '@pretto/bricks/core/utility/temporal'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { FileSigned } from '@pretto/app-core/application/lib/getFiles'
import {
  InitialState,
  UploadFile,
  UploadFileStatus,
  UploadFileUploaded,
  useUpload,
} from '@pretto/app-core/application/lib/useUpload'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { SupportingFileFragmentDoc } from '@pretto/app/src/Application/Containers/ApplicationContext/application.gateway.graphql'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { CONFIRM_UPLOAD, REMOVE_DOCUMENT } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { DocsList, DocsListFiles } from '@pretto/app/src/types/gateway/schema'

import { useApolloClient, useMutation } from '@apollo/client'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'

interface ApplicationUploadContextInterface {
  files: UploadFile[]
  onDelete: (currentDocument: DocsList, file: UploadFileUploaded) => void
  onUpload: (currentDocument: DocsList, files: FileSigned[]) => void
}

const ApplicationUploadContext = createContext<ApplicationUploadContextInterface>({
  files: [],
  onDelete() {
    // nothing
  },
  onUpload() {
    // nothing
  },
})

export const ApplicationUploadProvider: React.FC = ({ children }) => {
  const client = useApolloClient()

  const { data, completionPercentage } = useApplication()

  const { notify } = useNotifications()

  const previousCompletionPercentage = useRef<number | null>(null)

  const [confirmUpload] = useMutation(CONFIRM_UPLOAD)
  const [removeDocument] = useMutation(REMOVE_DOCUMENT)

  const trackAction = useTracking()

  const initialFiles =
    data.docs?.reduce<InitialState>((previous, doc) => {
      // TODO type data: docs should not have null
      // TODO type supporting_files: id, original_filename, treated, uploaded_at should not me null
      const { slug, supporting_files } = doc as {
        slug: string
        supporting_files: Array<{ id: string; original_filename: string; treated: boolean; uploaded_at: string }>
      }

      return supporting_files.reduce<InitialState>((previous, supportingFile) => {
        return [
          ...previous,
          {
            fileName: supportingFile.original_filename,
            localId: supportingFile.id,
            progress: 100,
            remoteId: supportingFile.id,
            slug,
            status: supportingFile.treated ? UploadFileStatus.Stale : UploadFileStatus.Uploaded,
            uploadedAt: supportingFile.uploaded_at,
          },
        ]
      }, previous)
    }, []) ?? []

  const { files, addFile, completeFile, deleteFile, incompleteFile, removeFile, uploadFile } = useUpload(initialFiles)

  const { projectID } = useUser() as { projectID: string }

  useEffect(() => {
    if (
      previousCompletionPercentage.current !== null &&
      previousCompletionPercentage.current !== completionPercentage &&
      (previousCompletionPercentage.current === 100 || completionPercentage === 100)
    ) {
      invalidateCache(client)
    }

    previousCompletionPercentage.current = completionPercentage
  }, [completionPercentage])

  const uploadingFilesLength = useMemo(
    () => files.filter(({ status }) => status === UploadFileStatus.Uploading).length,
    [files]
  )

  useUpdateEffect(() => {
    if (uploadingFilesLength === 0) {
      notify('Nous avons bien reçu votre/vos document(s) !')
    }
  }, [uploadingFilesLength])

  const handleDelete = async (currentDocument: DocsList, uploadFile: UploadFileUploaded) => {
    try {
      removeFile(uploadFile.localId)

      const {
        data: {
          delete_upload: { success },
        },
      } = await removeDocument({
        update: (
          cache,
          {
            data: {
              delete_upload: { success },
            },
          }
        ) => {
          if (!success) {
            return
          }

          cache.modify({
            id: cache.identify(currentDocument),
            fields: {
              document_status(documentStatus: DocsList['document_status'], { readField }) {
                const supportingFiles = readField<DocsListFiles[]>('supporting_files') ?? []
                const newSupportingFiles = supportingFiles.filter(
                  supportingFileRef => uploadFile.remoteId !== readField('id', supportingFileRef)
                )

                if (newSupportingFiles.length > 0) {
                  return documentStatus
                }

                return 'empty'
              },
              supporting_files(supportingFiles: DocsListFiles[] = [], { readField }) {
                return supportingFiles.filter(
                  supportingFileRef => uploadFile.remoteId !== readField('id', supportingFileRef)
                )
              },
            },
          })
        },
        variables: { id: uploadFile.remoteId },
      })

      if (!success) {
        throw new Error()
      }

      deleteFile(uploadFile.localId)

      notify(`Votre fichier ${uploadFile.fileName} a bien été supprimé !`)

      trackAction('DOCUMENTS_DOCUMENT_DELETED', { kind: currentDocument.slug })
    } catch (error) {
      const notification = notify(
        notification => {
          const handleRetry = () => {
            notification.pop()
            handleDelete(currentDocument, uploadFile)
          }

          return (
            <>
              Une erreur est survenue dans la suppression du fichier {uploadFile.fileName}.{' '}
              <button onClick={handleRetry} type="button">
                Réessayez
              </button>
              .
            </>
          )
        },
        {
          delay: null,
          type: 'error',
        }
      )

      incompleteFile(uploadFile.localId, () => {
        notification.pop()
        handleDelete(currentDocument, uploadFile)
      })
    }
  }

  const handleUpload = (currentDocument: DocsList, signedFiles: FileSigned[]) => {
    signedFiles.forEach(async signedFile => {
      addFile(signedFile.file.name, signedFile.id, currentDocument.slug ?? '')

      try {
        const key = await uploadFile(signedFile.file, signedFile.id, projectID)

        const uploadedAt = temporal().toISOString()

        const {
          data: {
            confirm_upload: { fileId, success },
          },
        } = await confirmUpload({
          update(
            cache,
            {
              data: {
                confirm_upload: { fileId, success },
              },
            }
          ) {
            if (!success) {
              return
            }

            const __typename = 'DocsListFiles'

            cache.modify({
              id: cache.identify(currentDocument),
              fields: {
                document_status() {
                  return 'pending'
                },
                supporting_files(supportingFiles: DocsListFiles[] = []) {
                  const supportingFile = cache.writeFragment({
                    id: cache.identify({ __typename, id: fileId }),
                    data: {
                      __typename,
                      id: fileId,
                      original_filename: signedFile.file.name,
                      treated: false,
                      uploaded_at: uploadedAt,
                    },
                    fragment: SupportingFileFragmentDoc,
                  })

                  return [...supportingFiles, supportingFile]
                },
              },
            })
          },
          variables: {
            key,
            kindHint: currentDocument.kind,
            meta: {
              bank: currentDocument?.meta?.bank,
              month: currentDocument?.meta?.month,
              mortgagor: currentDocument?.meta?.mortgagor,
              year: currentDocument?.meta?.year,
            },
            originalFilename: signedFile.file.name,
          },
        })

        if (!success) {
          throw new Error()
        }

        completeFile(signedFile.id, fileId, uploadedAt)

        trackAction('DOCUMENTS_DOCUMENT_UPLOADED', { kind: currentDocument.slug })
      } catch (error) {
        const notification = notify(
          notification => {
            const handleRetry = () => {
              notification.pop()
              handleUpload(currentDocument, [signedFile])
            }

            return (
              <>
                Une erreur est survenue dans l’envoi du fichier {signedFile.file.name}.{' '}
                <button onClick={handleRetry} type="button">
                  Réessayez
                </button>
                .
              </>
            )
          },
          {
            delay: null,
            type: 'error',
          }
        )

        incompleteFile(signedFile.id, () => {
          notification.pop()
          handleUpload(currentDocument, [signedFile])
        })
      }
    })
  }

  const context = useMemo(
    () => ({
      files,
      onDelete: handleDelete,
      onUpload: handleUpload,
    }),
    [files]
  )

  return <ApplicationUploadContext.Provider value={context}>{children}</ApplicationUploadContext.Provider>
}

export const useApplicationUpload = () => useContext(ApplicationUploadContext)
