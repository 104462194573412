import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { Introduction } from '@pretto/app/src/onboarding/views/Introduction/Introduction'

import { useHistory } from 'react-router'

export const IntroductionPage = () => {
  const {
    advisor,
    user: { name },
  } = useUser()

  const history = useHistory()

  const onCtaClick = () => {
    if (advisor.role === 'expert') {
      history.push('/onboarding/sollicited_broker')
      return
    }

    history.push('/onboarding/project_horizon')
  }

  const props = {
    advisorName: advisor?.name,
    advisorLabel: advisor?.label,
    avatarSrc: advisor?.largePicturePath,
    bio: advisor?.bio,
    name,
    onCtaClick,
  }

  return <Introduction {...props} />
}
