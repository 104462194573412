import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const Content = ({ mainInfo, infos, ...props }) => (
  <S.Main {...props}>
    <Responsive min="tablet">
      <S.BestOffer>{mainInfo.title}</S.BestOffer>
    </Responsive>
    <S.Content>
      <S.MainValue>{mainInfo.value}</S.MainValue>
      {infos.length && (
        <S.Infos>
          {infos.map(({ isBold, label, value, color }, index) => (
            <S.Info key={index}>
              <S.Label>{label} :</S.Label>
              <S.InfoValue $color={color} $isBold={isBold}>
                {value}
              </S.InfoValue>
            </S.Info>
          ))}
        </S.Infos>
      )}
    </S.Content>
  </S.Main>
)

Content.propTypes = {
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      isBold: PropTypes.bool,
      color: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.node,
    })
  ),
  mainInfo: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }),
}

Content.defaultProps = {
  infos: [],
  mainInfo: {},
}

export default Content
