import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { TimesBold } from '@pretto/picto'
import styled from 'styled-components'

export interface ChipsProps {
  children: string
  isRemovable?: boolean
  onRemove?: React.MouseEventHandler<SVGElement>
}
export const Chips = ({ children, isRemovable, onRemove, ...props }: ChipsProps) => (
  <Container {...props}>
    <Label>{children}</Label>

    {isRemovable && <Picto onClick={onRemove} />}
  </Container>
)

const Container = styled.div`
  ${typo.bodyMedium16};
  display: inline-flex;
  height: ${g(5)};
  padding: ${g(1)} ${g(2)};
  align-items: center;
  border-radius: ${g(3)};
  color: ${({ theme }) => theme.colors.primary1};
  background-color: ${({ theme }) => theme.colors.neutral4};
  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
`
const Label = styled.span``
const Picto = styled(TimesBold)`
  cursor: pointer;
  margin-left: ${g(1)};
`
