import SponsorshipSocialButton from '@pretto/bricks/app/sponsorship/components/SponsorshipSocialButton'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as C from './SponsorshipSocialLinks.module.css'

const SponsorshipSocialLinks = ({ links, size }) => (
  <div className={C.sponsorshipSocialLinks}>
    {links.map(buttonProps => (
      <div className={C.sponsorshipSocialLinksButton} key={buttonProps.kind}>
        <SponsorshipSocialButton {...buttonProps} size={size} />
      </div>
    ))}
  </div>
)
SponsorshipSocialLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.string,
}
export default memo(SponsorshipSocialLinks)
