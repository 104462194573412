import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import ProjectRow from '@pretto/bricks/shared/components/ProjectRow'

import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './Summary.module.css'

export default class Summary extends PureComponent {
  static propTypes = {
    /** Component title. */
    title: PropTypes.string.isRequired,
    /** Array of ProjectRow props  */
    values: PropTypes.array.isRequired,
  }

  render() {
    const { title, values } = this.props

    return (
      <div>
        <Card format="line" variant="neutral-1-20">
          <Content>
            <div className={C.summary__title}>
              <SubHeading size="large">{title}</SubHeading>
            </div>

            <div>
              {values.map((rowProps, index) => (
                <div key={index} className={C.summary__row}>
                  <ProjectRow {...rowProps} />
                </div>
              ))}
            </div>
          </Content>
        </Card>
      </div>
    )
  }
}
