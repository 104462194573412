import Icon from '@pretto/bricks/components/iconography/Icon'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import PropTypes from 'prop-types'
import { createElement, Fragment } from 'react'

import * as S from './styles'

const ButtonSquared = ({ checked, children, icon, isLoading, textVariant, ...props }) => (
  <S.Button {...props} icon={icon} isLoading={isLoading} textVariant={textVariant} checked={checked}>
    {createElement(
      icon || checked ? S.IconWrapper : Fragment,
      null,
      <>
        {icon && !checked && <S.Icon>{typeof icon === 'string' ? <Icon name={icon} /> : icon}</S.Icon>}
        {checked && (
          <S.Icon>
            <Icon name="check-2" />
          </S.Icon>
        )}
        <S.Content>{children}</S.Content>
      </>
    )}
    {isLoading && (
      <S.Loading>
        <SpinnerLegacy variant={textVariant} size="small" />
      </S.Loading>
    )}
  </S.Button>
)

ButtonSquared.defaultProps = {
  textVariant: 'white',
  variant: 'primary-1',
}

ButtonSquared.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isLoading: PropTypes.bool,
  textVariant: PropTypes.string,
  variant: PropTypes.string,
}

export default ButtonSquared
