import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ApplicationPage = ({ cards, documentsProgress }) => {
  const splitCards = cards.reduce(
    (acc, curr) => {
      if (curr.cardType === 'bannerFolder') {
        return { bannerFolder: [...acc.bannerFolder, curr], cards: acc.cards, notice: acc.notice }
      }

      if (curr.cardType === 'notice') {
        return { bannerFolder: acc.bannerFolder, cards: acc.cards, notice: [...acc.notice, curr] }
      }

      return { bannerFolder: acc.bannerFolder, cards: [...acc.cards, curr], notice: acc.notice }
    },
    { bannerFolder: [], cards: [], notice: [] }
  )

  return (
    <S.Page>
      <Responsive min={'tablet'}>
        <S.Header>
          <S.ImageContent>
            <S.Image />
          </S.ImageContent>

          <S.HeaderContent>
            <div>
              <S.HeaderTitle>Votre dossier</S.HeaderTitle>
              <S.HeaderDescription>
                Constituez votre dossier pas à pas, en ligne et en toute sécurité !
              </S.HeaderDescription>
            </div>
          </S.HeaderContent>
        </S.Header>
      </Responsive>

      <S.Main>
        <S.MainContent>
          <Responsive max={'tablet'}>
            <S.PageTitle>Votre dossier</S.PageTitle>
            <S.PageDescription>Constituez votre dossier pas à pas, en ligne et en toute sécurité !</S.PageDescription>
          </Responsive>

          {splitCards.notice.map((bannerProps, key) => (
            <S.StyledBanner key={key} {...bannerProps} />
          ))}

          {splitCards.bannerFolder.map((bannerFolderProps, key) => (
            <S.StyledBannerFolder key={key} {...bannerFolderProps} />
          ))}

          <S.CardSectionTitle>Informations et documents</S.CardSectionTitle>

          <S.Cards>
            {splitCards.cards.map(
              (
                { description, cardType, href, isActive = true, isSwitched, onClick, onToggle, picto, progress, title },
                index
              ) => {
                switch (cardType) {
                  case 'block':
                    return (
                      <S.StyledFolderDocumentsCard
                        description={description}
                        documentProgress={documentsProgress}
                        href={href}
                        key={index}
                        onClick={onClick}
                        picto={picto}
                        title={title}
                        $cardNumber={cards.length}
                      />
                    )

                  default:
                    return (
                      <S.StyledFolderCard
                        description={description}
                        href={href}
                        isActive={isActive}
                        isSwitched={isSwitched}
                        key={index}
                        onClick={isActive || isSwitched ? onClick : undefined}
                        onToggle={onToggle}
                        picto={picto}
                        progress={progress}
                        title={title}
                      />
                    )
                }
              }
            )}
          </S.Cards>
        </S.MainContent>
      </S.Main>
    </S.Page>
  )
}

ApplicationPage.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      cardType: PropTypes.string,
    })
  ).isRequired,
  documentsProgress: PropTypes.shape({
    current: PropTypes.number.isRequired,
    target: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(ApplicationPage)
