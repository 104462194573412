import { mapRoomsValues } from '@pretto/app/src/Sentences/v2/PropertySearch/CriteriaEmail/lib/mappers/mapRoomsValues'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { LOCAL_STORAGE_IMMO } from '@pretto/app/src/Sentences/v2/config/storage/storage'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { tooltipEmail } from '@pretto/app/src/Sentences/v2/lib/email'
import { useTracking } from '@pretto/app/src/Sentences/v2/lib/tracking/useTracking'
import { PropertySearchRooms } from '@pretto/app/src/Sentences/v2/types/enums'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'
import qs from 'qs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

const Disclaimer = styled.div`
  ${({ theme }) => theme.typos.caption};
`

export const CriteriaEmailPage: React.FC = () => {
  const { goToNextRoute } = useRoutesGraph()
  const context = useSentences()
  const {
    propertySearchAreaMin,
    propertySearchAreaMax,
    propertySearchRooms,
    propertySearchEmail,
    propertySearchFrequency,
    propertySearchLocalisations,
    setContext,
  } = context
  const trackAction = useTracking()

  const getDefaultActiveField = () => {
    if (!propertySearchAreaMin) {
      return 0
    }
    if (!propertySearchAreaMax) {
      return 1
    }
    if (!propertySearchRooms) {
      return 2
    }
    return 3
  }
  const [activeField, setActiveField] = useState(getDefaultActiveField())
  const [debouncedEmail] = useDebounce(propertySearchEmail, 1200)

  const handleChangeAreaMin = (value: string) => {
    const areaMin = parseInt(value)
    setContext({ propertySearchAreaMin: areaMin })
    setActiveField(1)
  }

  const handleChangeAreaMax = (value: string) => {
    const areaMax = parseInt(value)
    setContext({ propertySearchAreaMax: areaMax })
    setActiveField(2)
  }

  const propertySearch = {
    frequency: propertySearchFrequency,
    area_min: propertySearchAreaMin,
    area_max: propertySearchAreaMax,
    rooms: propertySearchRooms,
    localisations: propertySearchLocalisations,
    email: propertySearchEmail,
  }

  return (
    <SubscribeConsumer>
      {subscribeContext => (
        <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'criteriaEmail' } }}>
          <Title>{t('sentences.propertySearch.title')}</Title>
          <div>
            {t('sentences.propertySearch.criteriaEmail.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertySearchAreaMin' } }}
              inputProps={{ placeholder: '60', type: 'integer' }}
              onChange={handleChangeAreaMin}
              onClick={() => {
                setActiveField(0)
              }}
              value={propertySearchAreaMin?.toString() || ''}
            />
            {t('sentences.propertySearch.criteriaEmail.sentences.1')}
            {activeField > 0 && (
              <>
                {t('sentences.propertySearch.criteriaEmail.sentences.2')}
                <TrackedResizableField
                  clearable
                  events={{
                    eventName: EventName.SimulationFieldChanged,
                    eventPayload: { field: 'propertySearchAreaMax' },
                  }}
                  inputProps={{ placeholder: '80', type: 'integer' }}
                  onChange={handleChangeAreaMax}
                  onClick={() => {
                    setActiveField(1)
                  }}
                  value={propertySearchAreaMax?.toString() || ''}
                />
                {t('sentences.propertySearch.criteriaEmail.sentences.3')}
              </>
            )}
            {activeField > 1 && (
              <>
                {t('sentences.propertySearch.criteriaEmail.sentences.4')}
                <Prompt isFilled={!isNil(propertySearchRooms)} onClick={() => setActiveField(2)}>
                  {t(
                    `sentences.propertySearch.criteriaEmail.values.${
                      (propertySearchRooms?.toString() || '1') as PropertySearchRooms
                    }`
                  )}
                </Prompt>
                {t('sentences.propertySearch.criteriaEmail.sentences.5')}
              </>
            )}
            {activeField > 2 && (
              <>
                <br />
                {t('sentences.propertySearch.criteriaEmail.sentences.6')}
                <TrackedResizableField
                  {...tooltipEmail(debouncedEmail || '')}
                  clearable
                  events={{
                    eventName: EventName.SimulationFieldChanged,
                    eventPayload: { field: 'propertySearchEmail' },
                  }}
                  inputProps={{ placeholder: 'bruce.wayne@gmail.com', spellCheck: false, type: 'email' }}
                  onChange={value => {
                    setContext({ propertySearchEmail: value, hasAnsweredPropertySearch: true })
                  }}
                  onClick={() => {
                    setActiveField(3)
                  }}
                  value={propertySearchEmail || ''}
                />
                {t('sentences.propertySearch.criteriaEmail.sentences.7')}
                {!!propertySearchEmail && (
                  <Disclaimer>
                    {t('sentences.propertySearch.criteriaEmail.disclaimer.0')}{' '}
                    <Link to={'https://www.pretto.fr/cgu'}>
                      {t('sentences.propertySearch.criteriaEmail.disclaimer.1')}
                    </Link>
                  </Disclaimer>
                )}
              </>
            )}
          </div>
          {activeField === 2 && (
            <Choices
              choices={mapRoomsValues(Object.values(PropertySearchRooms), context)}
              events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertySearchRooms' } }}
              onChoiceClick={() => setActiveField(3)}
            />
          )}
          {propertySearchEmail && (
            <ButtonNext
              onClick={async (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault()

                trackAction([
                  {
                    eventName: EventName.SimulationPropertySearchDeclared,
                    eventPayload: {
                      property_search_frequency: propertySearchFrequency && propertySearchFrequency !== 'never',
                      ...(propertySearchFrequency && propertySearchFrequency !== 'never'
                        ? Object.entries(propertySearch).reduce(
                            (previous, [key, value]) => ({ ...previous, [`property_search_criteria_${key}`]: value }),
                            {}
                          )
                        : {}),
                    },
                  },
                ])

                if (propertySearchFrequency && propertySearchFrequency !== 'never') {
                  setContext({ hasAnsweredPropertySearch: true })
                  setItem(LOCAL_STORAGE_IMMO, 'true')

                  await subscribeContext.onSignUp({
                    assignment: 'project',
                    email: propertySearchEmail,
                    kind: 'property_search_alert',
                    source: 'sentences_property_search_alert',
                    subscribePropertySearchAlert: true,
                  })

                  goToNextRoute(
                    qs.stringify(
                      { propertySearch: { ...propertySearch } },
                      { addQueryPrefix: true, encodeValuesOnly: true }
                    )
                  )
                }
              }}
            >
              {t('sentences.next')}
            </ButtonNext>
          )}
        </TrackedView>
      )}
    </SubscribeConsumer>
  )
}
