import Text from '@pretto/bricks/components/typography/Text'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'

import classnames from 'classnames'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import sum from 'lodash/sum'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'
import { Fragment, PureComponent } from 'react'

import * as C from './Graph.module.css'

const formatValue = value => ({
  ...value,
  rawValue: value.value,
  testTitle: title => value.title === title,
  value: `${value.value.toLocaleString('fr')} €`,
})

const formatValues = (values, fromRight = false) => {
  if (values.length > 2) {
    const firstValue = values[0]
    const lastValue = values[values.length - 1]

    if (fromRight) {
      return [
        formatValue(firstValue),
        values.slice(2).reduce(
          (previous, value) => ({
            ...previous,
            rawValue: previous.rawValue + value.value,
            testTitle: title => [...previous.title.split(' / '), value.title].includes(title),
            title: `${previous.title} / ${value.title}`,
            value: `${previous.value} / ${value.value.toLocaleString('fr')} €`,
          }),
          formatValue(values[1])
        ),
      ]
    }

    return [
      values.slice(1, -1).reduce(
        (previous, value) => ({
          ...previous,
          rawValue: previous.rawValue + value.value,
          testTitle: title => [...previous.title.split(' / '), value.title].includes(title),
          title: `${previous.title} / ${value.title}`,
          value: `${previous.value} / ${value.value.toLocaleString('fr')} €`,
        }),
        formatValue(firstValue)
      ),
      formatValue(lastValue),
    ]
  }

  return values.map(formatValue)
}

const renderCaption = (values, selectedValue) =>
  values.map(({ color, title, testTitle, value, variant }, index) => {
    return (
      <div
        className={classnames(C.graph__caption, C[`graph__caption${pascalcase(variant ?? '')}`], {
          [C.graph__captionDimmed]: selectedValue !== null && !testTitle(selectedValue.title),
        })}
        key={index}
      >
        <div className={C.graph__caption__title}>
          <Text size="small">{title}</Text>
        </div>

        <div className={C.graph__caption__price}>
          <Text size="small" variant="neutral-1-60">
            {value}
          </Text>
        </div>
      </div>
    )
  })

const renderParts = (values, selectedValue, onSelectValue = () => {}, onUnselectValue = () => {}) => (
  <div>
    {values.map((subValues, index) => {
      const total = sum(map(subValues, 'value'))

      return (
        <div className={C.graph__parts__container} key={index}>
          {subValues.map(subValue => {
            const { title, value, variant } = subValue
            const percentage = (value / total) * 100

            return (
              <div
                className={classnames(C.graph__parts__part, C[`graph__parts__part${pascalcase(variant ?? '')}`])}
                key={title}
                onMouseEnter={() => onSelectValue(subValue)}
                onMouseLeave={() => onUnselectValue(subValue)}
                style={{ width: `${percentage}%` }}
              />
            )
          })}
        </div>
      )
    })}
  </div>
)

export default class Graph extends PureComponent {
  static propTypes = {
    /** Event handler triggered when bar is hovered on desktop on when a caption is clicked on desktop */
    onSelectValue: PropTypes.func,
    /** Event handler when a value is not selected anymore */
    onUnselectValue: PropTypes.func,
    /** Value to hightlight */
    selectedValue: PropTypes.object,
    /** Data values */
    values: PropTypes.array.isRequired,
  }

  static defaultProps = {
    onSelectValue: () => {},
    onUnselectValue: () => {},
    selectedValue: null,
  }

  componentDidMount() {
    window.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleDocumentClick)
  }

  handleCaptionClick = caption => event => {
    event.stopPropagation()

    this.props.onSelectValue(caption)
  }

  handleDocumentClick = () => {
    if (window.innerWidth < 768) {
      this.props.onUnselectValue()
    }
  }

  render() {
    const { onSelectValue, onUnselectValue, selectedValue, values } = this.props

    const bottomValues = formatValues(values[1], true)
    const topValues = formatValues(values[0])

    const bottomTotal = sum(map(values[1], 'value'))
    const topTotal = sum(map(values[0], 'value'))

    return (
      <Fragment>
        <Desktop>
          <div>
            <div className={C.graph__captionsTop}>{renderCaption(topValues, selectedValue, topTotal)}</div>

            <div>{renderParts(values, selectedValue, onSelectValue, onUnselectValue)}</div>

            <div className={classnames(C.graph__captionsBottom, C.graph__captionsTop)}>
              {renderCaption(bottomValues, selectedValue, bottomTotal)}
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div>
            <div className={C.graph__partsMobile}>{renderParts(values, selectedValue)}</div>

            <div>
              {flatten(values).map(caption => {
                const { expandedTitle, title, value, variant } = caption

                return (
                  <div
                    className={classnames(C.graph__caption, {
                      [C.graph__captionDimmed]: selectedValue !== null && selectedValue.title !== title,
                    })}
                    key={title}
                    onClick={this.handleCaptionClick(caption)}
                  >
                    <div className={C.graph__caption__title}>
                      <span
                        className={classnames(
                          C.graph__caption__bullet,
                          C[`graph__caption__bullet${pascalcase(variant ?? '')}`]
                        )}
                      />
                      <Text size="small">{expandedTitle}</Text>
                    </div>

                    <div className={C.graph__caption__price}>
                      <Text size="small" variant="neutral-1-60">
                        {value.toLocaleString('fr')} €
                      </Text>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Mobile>
      </Fragment>
    )
  }
}
