import { useRef } from 'react'
import shorthash from 'shorthash'

import * as S from './styles'

const Spinner = props => {
  const { current: id } = useRef(shorthash.random())
  const { current: id2 } = useRef(shorthash.random())

  return (
    <S.Spinner height="36" width="36" {...props} viewBox="0 0 36 36">
      <defs>
        <linearGradient gradientTransform="rotate(90)" id={id}>
          <stop offset="0%" stopColor="currentColor" stopOpacity="0.5" />
          <stop offset="15%" stopColor="currentColor" stopOpacity="0.5" />
          <stop offset="100%" stopColor="currentColor" stopOpacity="0" />
        </linearGradient>

        <linearGradient gradientTransform="rotate(90)" id={id2}>
          <stop offset="0%" stopColor="currentColor" stopOpacity="0.5" />
          <stop offset="15%" stopColor="currentColor" stopOpacity="0.5" />
          <stop offset="100%" stopColor="currentColor" stopOpacity="1" />
        </linearGradient>

        <clipPath id="spinner_clippath">
          <path
            d="M22.5,35.36a2,2,0,0,1-.56-3.92,14,14,0,1,0-8.32-.14,2,2,0,1,1-1.24,3.8,18,18,0,1,1,10.68.18A2.23,2.23,0,0,1,22.5,35.36Z"
            fill="black"
            stroke="none"
          />
        </clipPath>
      </defs>

      <rect
        clipPath="url('#spinner_clippath')"
        fill={`url(#${id})`}
        height="36"
        shapeRendering="crispEdges"
        width="18"
        x="0"
        y="0"
      />

      <rect
        clipPath="url('#spinner_clippath')"
        fill={`url(#${id2})`}
        height="36"
        shapeRendering="crispEdges"
        width="18"
        x="18"
        y="0"
      />
    </S.Spinner>
  )
}

export default Spinner
