export const convertStatusSlug = <T extends string>(status: T): 'idle' | 'invalid' | 'sent' | T => {
  if (['pending', 'valid'].includes(status)) {
    return 'sent'
  }

  if (status === 'invalid') {
    return status
  }

  return 'idle'
}
