import {
  NegotiationModal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const ComplianceCheckSelfModal: React.FC<NegotiationModalProps> = props => (
  <NegotiationModal {...props}>
    <p>
      <strong>
        Cette étape marque la finalisation de votre dossier de crédit par votre expert pour être présenté en banque :
      </strong>
    </p>

    <p>
      ✅ Votre expert vérifie l’exactitude et la complétude de l’ensemble de vos documents.
      <br />✨ Votre expert travaille un argumentaire solide pour mettre en avant les atouts de votre dossier.
    </p>

    <p>
      Ensuite, pour que la demande soit parfaitement conforme aux conditions bancaires et à nos conventions, le service
      conformité de Pretto s’occupe de donner son feu vert final pour l’envoi.
    </p>

    <p>
      Cela peut vous sembler long, mais prendre <strong>ces quelques heures (environ 36-48h)</strong> pour optimiser
      tous les documents fait en réalité gagner plusieurs jours dans l’instruction du dossier !
    </p>
  </NegotiationModal>
)
