import Button from '@pretto/bricks/components/buttons/Button'
import Card from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'


import styled from 'styled-components'

export const Container = styled(Card)`
  background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
  background-image: url(${({ $illustration }) =>
    getCloudinaryUrl(`bricks/static-assets/illustrations/${$illustration}.svg`)});
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  padding: ${g(3)};
  padding-bottom: ${g(28)};
  flex-basis: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-position: center right ${g(4)};
    padding: ${g(4)};
  }
`

export const CTA = styled(Button)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`

export const Text = styled(BaseTypo).attrs({ typo: 'bodyBook' })`
  margin-bottom: ${g(2)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: calc(100% - ${g(30)});
  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'headline' })`
  margin-bottom: ${g(2)};
`

export const Free = styled.span`
  font-weight: 700;
`
