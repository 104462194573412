import { Step as StepComponent } from '@pretto/bricks/app/dashboard/pages/DashboardPage/components/Steps/components/Step/Step'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { range } from 'lodash'
import styled, { css } from 'styled-components'

import { getStyleForStepIndex } from './lib/getStyleForStepIndex'

export const Container = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
  padding: ${g(6)} 0 ${g(8)};
  row-gap: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(6)} 0 ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(7)} 0 ${g(9)};
    row-gap: ${g(6)};
  }
`

interface StepComponentProps {
  $stepsCount: number
}

export const Step = styled(StepComponent)<StepComponentProps>`
  &:before,
  &:after {
    box-sizing: border-box;
    pointer-events: none;
    position: absolute;
  }

  &:not(:last-child):after {
    background-color: ${({ theme }) => theme.colors.neutral4};
    border-color: ${({ theme }) => theme.colors.neutral3};
    border-style: dashed;
    border-width: 0;
    border-right-width: ${g(1 / 4)};
    content: '';
    height: calc(100% + ${g(2)});
    left: ${g(1, 6)};
    top: ${g(1, 5)};

    ${({ stepState, theme }) =>
      stepState === 'done' &&
      css`
        border-color: ${theme.colors.neutral2};
        border-style: solid;
      `}
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $stepsCount, stepState }) =>
      range(1, 1 + $stepsCount).map(index => getStyleForStepIndex(index, $stepsCount, stepState, 4, 6, 3, 8))}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ $stepsCount, stepState }) =>
      range(1, 1 + $stepsCount).map(index => getStyleForStepIndex(index, $stepsCount, stepState, 6, 9, 5, 12))}
  }
`

export const Timeline = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading6};
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral1};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`
