import * as S from './TrustpilotRate.styles'

export type Alignment = 'inline' | 'stack' | 'twolines'

export interface TrustpilotRateProps {
  alignment?: Alignment
  isShort?: boolean
  numberOfReviews: string | number
  score: number
}

export const TrustpilotRate: React.FC<TrustpilotRateProps> = ({
  alignment = 'inline',
  isShort = false,
  numberOfReviews,
  score,
  ...props
}) => (
  <S.Component $alignment={alignment} itemScope itemType="https://schema.org/Product" {...props}>
    <S.SchemaName itemProp="name">Pretto</S.SchemaName>

    <S.Logo $alignment={alignment} variant="neutral1" />
    <S.TrustpilotStars score={5} size="medium" />

    {!isShort && (
      <S.ScoreContainer itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
        <S.Score itemProp="ratingValue">{typeof score === 'string' ? score : score.toLocaleString('fr')}</S.Score> ⏐ 
        <S.Count href="https://fr.trustpilot.com/review/pretto.fr">
          <span itemProp="reviewCount">
            {typeof numberOfReviews === 'string' ? numberOfReviews : numberOfReviews.toLocaleString('fr')}
          </span>{' '}
          avis
        </S.Count>
      </S.ScoreContainer>
    )}
  </S.Component>
)
