import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const AccountField = ({
  caption,
  fieldComponent,
  isEditDisabled,
  isEditing,
  isLoading,
  isSubmitDisabled,
  label,
  onCancel,
  onEdit,
  onSave,
  value,
}) => {
  const handleSubmit = event => {
    event.preventDefault()

    onSave()
  }

  return (
    <S.Field>
      <SubHeading>{label}</SubHeading>

      {isEditing ? (
        <form onSubmit={handleSubmit}>
          {fieldComponent}
          <S.FieldValidation>
            <ButtonSquared variant="accent-1" disabled={isSubmitDisabled} isLoading={isLoading}>
              Enregistrer
            </ButtonSquared>
          </S.FieldValidation>
        </form>
      ) : (
        <Text>{value}</Text>
      )}

      {!isEditing ? (
        <S.FieldButton disabled={isEditDisabled} onClick={onEdit}>
          {value === '' ? 'Ajouter' : 'Modifier'}
        </S.FieldButton>
      ) : (
        <S.FieldButton onClick={onCancel} disabled={isLoading}>
          Annuler
        </S.FieldButton>
      )}
      {caption && <S.Caption>{caption}</S.Caption>}
    </S.Field>
  )
}

AccountField.propTypes = {
  /** Caption of the field*/
  caption: PropTypes.string,
  /** Input component. */
  fieldComponent: PropTypes.element.isRequired,
  /** Disabled state of edit button. */
  isEditDisabled: PropTypes.bool,
  /** Whether a field is being edited or not. */
  isEditing: PropTypes.bool,
  /** Loading state when validating a change. */
  isLoading: PropTypes.bool,
  /** Disabled state of save button. */
  isSubmitDisabled: PropTypes.bool,
  /** Field label. */
  label: PropTypes.string.isRequired,
  /** Function triggered by the cancel button. */
  onCancel: PropTypes.func.isRequired,
  /** Function triggered by the edit button. */
  onEdit: PropTypes.func.isRequired,
  /** Function triggered by the save button. */
  onSave: PropTypes.func.isRequired,
  /** Value of input OR text. Input must be a string, text can be any node. */
  value: PropTypes.node.isRequired,
}

export default memo(AccountField)
