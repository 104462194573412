import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const ScoreBanner = ({ details, label, onClickScoreSeeMore, ...props }) => (
  <S.ScoreCard $bgColor={details.bgColor} $color={details.color} {...props}>
    <S.ScoreIcon />
    <S.ScoreDescription>
      <BaseTypo typo="bodyMedium16">{`Financement ${label}.`}</BaseTypo>
      <Responsive min="mobileL"> </Responsive>
      <S.SeeMore onClick={onClickScoreSeeMore}>En savoir plus</S.SeeMore>
    </S.ScoreDescription>
  </S.ScoreCard>
)

ScoreBanner.propTypes = {
  details: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  onClickScoreSeeMore: PropTypes.func.isRequired,
}

export default ScoreBanner
