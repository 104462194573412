import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Check = styled.div`
  margin-left: auto;
  padding-left: ${g(2)};
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: ${g(4)};
`

export const Edit = styled.div`
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  line-height: ${g(2)};
  margin-top: ${g(2)};
`

export const ListOption = styled.div`
  cursor: pointer;
`
