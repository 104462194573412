import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { SciAssociation, SciType, Structure } from '@pretto/app/src/Sentences/v2/types/enums'
import { MortgageArrangement } from '@pretto/app/src/types/gateway/enums'

export const mapStructure: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  switch (true) {
    case payload.mortgagors_arrangement === MortgageArrangement.SciIr:
      updatedContext.structure = Structure.SCI
      updatedContext.sciType = SciType.IR
      updatedContext.sciAssociation = SciAssociation.Simple
      break
    case payload.mortgagors_arrangement === MortgageArrangement.SciIs:
      updatedContext.structure = Structure.SCI
      updatedContext.sciType = SciType.IS
      break
    case payload.profile?.mortgagors?.length === 1:
      updatedContext.structure = Structure.Solo
      break
    case payload.profile?.mortgagors?.length && payload.profile.mortgagors.length > 1:
      updatedContext.structure = Structure.Duo
      break
    default:
      updatedContext.structure = null
  }

  return {
    payload,
    context: updatedContext,
  }
}
