import { CityInfo, IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'
import { GenericObject } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'

export const serializeInput = (input: IPropertySearchData): GenericObject => ({
  conditions: serializeToValue('conditions', input.property_condition),
  kinds: serializeToValue('kinds', input.property_kind),
  livingArea: {
    min: input.area_min || 1,
    max: input.area_max,
  },
  locations: input.localisations.map(({ city, zipcode }) => ({ city, zipcode })),
  numberOfRooms: input.rooms || 1,
  plotArea: {
    min: input.min_land_surface,
  },
  price: {
    min: input.price_min,
    max: input.price_max || 200000,
  },
  sellers: serializeToValue('sellers', input.vendor),
  title: input.title || generateTitle(input.localisations),
  wantedFeatures: serializeToValue('wantedFeatures', {
    balcony: input.balcony,
    basement: input.basement,
    elevator: input.elevator,
    has_furniture: input.has_furniture,
    not_on_ground_floor: input.not_on_ground_floor,
    parking_space: input.parking_space,
  }),
})

const generateTitle = (locations: CityInfo[]): string => {
  if (!locations || locations.length === 0) return 'Alerte Immo Pretto'

  const str = locations.map(v => v.city).join(', ')
  return str.length < 25 ? str : str.slice(0, length - 3) + '...'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeToValue = (user: GenericObject, alert: GenericObject, targetValue: string): any => {
  switch (targetValue) {
    case 'frequency': {
      const mapping: Record<string, string> = {
        NEVER: 'none',
        EVERY_TEN_MINUTES: 'real_time',
        DAILY: 'daily',
        WEEKLY: 'weekly',
      }

      return mapping[user.preferences.emailSummaryFrequency]
    }
    case 'property_condition': {
      if (alert.conditions.length !== 1) return 'both'

      return alert.conditions[0] === 'OLD_PROPERTY' ? 'old' : 'new'
    }
    case 'property_kind': {
      if (alert.kinds.length !== 1) return 'both'

      return alert.kinds[0] === 'HOUSE' ? 'house' : 'flat'
    }
    case 'vendor': {
      if (alert.sellers.length !== 1) return 'both'

      return alert.sellers[0] === 'PRIVATE_INDIVIDUAL' ? 'individual' : 'agency'
    }
    default:
      return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeToValue = (targetValue: string | string[], sourceValue: any): any => {
  switch (targetValue) {
    case 'conditions': {
      return sourceValue === 'old' ? ['OLD_PROPERTY'] : sourceValue === 'new' ? ['NEW_BUILD'] : []
    }
    case 'kinds': {
      return sourceValue === 'house' ? ['HOUSE'] : sourceValue === 'flat' ? ['APPARTMENT'] : []
    }
    case 'sellers': {
      return sourceValue === 'individual'
        ? ['PRIVATE_INDIVIDUAL']
        : sourceValue === 'agency'
        ? ['REAL_ESTATE_AGENCY']
        : []
    }
    case 'wantedFeatures': {
      const res = []
      if (sourceValue.balcony) res.push('BALCONY')
      if (sourceValue.basement) res.push('BASEMENT')
      if (sourceValue.has_furniture) res.push('HAS_FURNITURE')
      if (sourceValue.elevator) res.push('ELEVATOR')
      if (sourceValue.not_on_ground_floor) res.push('NOT_ON_GROUND_FLOOR')
      if (sourceValue.parking_space) res.push('PARKING_SPACE')
      return res
    }
    case 'emailSummaryFrequency': {
      const mapping: Record<string, string> = {
        none: 'NEVER',
        real_time: 'EVERY_TEN_MINUTES',
        daily: 'DAILY',
        weekly: 'WEEKLY',
      }

      return mapping[sourceValue]
    }
    default:
      return null
  }
}
