import { breakpoints, g } from '@pretto/bricks/components/layout'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'

import styled from 'styled-components'

import { Navbar as NavbarComponent, NavbarProps } from './components/Navbar/Navbar'

interface ResultPageProps {
  isLoggedIn: boolean
  isNavbarActive: boolean
}

export const ResultPage: React.FC<ResultPageProps & NavbarProps & NavigationProps> = ({
  isLoggedIn,
  isNavbarActive,
  children,
  continueHref,
  onBack,
  onContinue,
  onEdit,
}) => (
  <ThemeProvider designSystem="reveal">
    {isNavbarActive && <Navbar isLoggedIn={isLoggedIn} onBack={onBack} onEdit={onEdit} />}

    <View>
      {children}

      <Footer>
        <Button href={continueHref()} onClick={onContinue}>
          Suivant
        </Button>
      </Footer>
    </View>
  </ThemeProvider>
)

const Button = styled(PrimaryButtonFix)`
  width: 100%;
`

const Footer = styled.footer`
  backdrop-filter: blur(${g(3)});
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
  bottom: 0;
  padding: ${g(2)} ${g(2, 4)};
  position: sticky;
  z-index: 2;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const Navbar = styled(NavbarComponent)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const View = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    min-height: 100%;
  }
`
