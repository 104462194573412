import * as S from './Tooltip.styles'

export interface TooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  position?: 'top' | 'right' | 'bottom' | 'left'
  maxWidth?: string
}

export const Tooltip = ({ content, children, position = 'top', maxWidth = '226px', ...props }: TooltipProps) => (
  <S.TooltipContainer {...props}>
    {children}
    <S.Tooltip $position={position} $maxWidth={maxWidth} role="tooltip">
      {content}
    </S.Tooltip>
  </S.TooltipContainer>
)
