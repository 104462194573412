import { ERROR_TYPES, getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'
import { shorthash } from '@pretto/bricks/core/utility/hash'
import temporal from '@pretto/bricks/core/utility/temporal'

import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { useEmailField, usePhoneField, useTextField } from '@pretto/app/src/Form/lib/useField'
import { BookingInfosParams } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import { Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Calendar/Calendar'
import { Subscribe as SubscribeComponent } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Subscribe/Subscribe'
import * as F from '@pretto/app/src/Simulation/config/fields'
import { getUtms } from '@pretto/app/src/Subscribe/lib/getUtms'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR, APPLY_WITH_APPOINTMENT, REASSIGN_WITH_APPOINTMENT } from '@pretto/app/src/apollo'
import { DefaultAlertMessage } from '@pretto/app/src/components/DefaultAlertMessage/DefaultAlertMessage'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'

interface SubscribeProps {
  onAlert: (alert: ReactNode) => void
  onBookingInfos?: (bookingInfos: BookingInfosParams) => void
  onClose: () => void
  onModify: () => void
  selectedSlot?: Slot
}

export const Subscribe: React.FC<SubscribeProps> = ({ onAlert, onBookingInfos, onClose, onModify, selectedSlot }) => {
  const [applyWithAppointment] = useMutation(APPLY_WITH_APPOINTMENT)
  const [reassignWithAppointment] = useMutation(REASSIGN_WITH_APPOINTMENT, {
    refetchQueries: [
      {
        query: ADVISOR,
      },
      'Advisor',
    ],
  })
  const { typology, user } = useUser()

  const {
    check: firstNameFieldCheck,
    component: firstNameFieldComponent,
    value: firstName,
  } = useTextField({ ...F.firstName })
  const {
    check: lastNameFieldCheck,
    component: lastNameFieldComponent,
    value: lastName,
  } = useTextField({ ...F.lastName })
  const {
    check: emailFieldCheck,
    component: emailComponent,
    invalidate,
    value: email,
  } = useEmailField({
    defaultValue: user?.email ?? '',
    ...F.email(typology),
    placeholderAsLabel: typology !== 'prospect',
    validators: [
      (value: string, isCheckRequested: boolean, inputProps: unknown, reason: string) => ({
        condition: ERROR_TYPES.includes(reason),
        message: getAuthErrorMessage(reason, <Link to="/login">ici</Link>),
        state: 'warning',
      }),
    ],
  })
  const {
    check: phoneFieldCheck,
    component: phoneFieldComponent,
    value: phone,
  } = usePhoneField({
    inputProps: {
      placeholder: 'N° de téléphone *',
      required: true,
    },
    size: 'small',
  })

  const { signIn } = useAuth()
  const trackAction = useTracking()

  const [isVisio, setIsVisio] = useState<boolean>(!!selectedSlot?.advisor.isVisio)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAgreed, setIsAgreed] = useState<boolean>(false)
  const [isAgreementRequired, setIsAgreementRequired] = useState<boolean>(false)

  const handleToggleVisio = () => {
    setIsVisio(previousVisio => !previousVisio)
  }

  const switchVisio = {
    isToggled: isVisio,
    onToggle: handleToggleVisio,
  }

  const agreementCheck = () => {
    if (isAgreed) {
      return true
    }

    setIsAgreementRequired(true)
    setIsLoading(false)

    return false
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
    const attributionCookieLastClick = getAttributionCookieLastClickValue()
    const utm = await getUtms()
    const visitorId = (await getVisitorId()) ?? undefined

    if (typology !== 'client') {
      const checkFields = [emailFieldCheck(), firstNameFieldCheck(), lastNameFieldCheck(), phoneFieldCheck()]

      if (!checkFields.every(Boolean) || !agreementCheck()) {
        setIsLoading(false)
        return
      }

      trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CONFIRMED')

      applyWithAppointment({
        variables: {
          advisorId: selectedSlot?.advisor.id,
          email,
          firstName,
          lastName,
          phone,
          startTime: selectedSlot?.utcDate,
          utm,
          abTest: 'clients_flow_202211_maturity',
          attributionCookieFirstClick,
          attributionCookieLastClick,
          isVisio,
          visitorId,
          kind: selectedSlot?.kind,
        },
      })
        .then(
          ({
            data: {
              applyWithAppointment: { accessToken, error },
            },
          }) => {
            if (!error) {
              trackAction('BOOKING_MUTUALIZED_APPOINTMENT_BOOKED')
              onClose()
              signIn({ accessToken })
              return
            }

            if (ERROR_TYPES.includes(error)) {
              invalidate(error)
              return
            }

            onAlert(DefaultAlertMessage())
          }
        )
        .finally(() => {
          setIsLoading(false)
        })

      return
    }

    reassignWithAppointment({
      variables: {
        advisorId: selectedSlot?.advisor.id,
        startTime: selectedSlot?.utcDate,
        isVisio,
        kind: selectedSlot?.kind,
      },
    })
      .then(() => {
        trackAction('BOOKING_MUTUALIZED_APPOINTMENT_BOOKED')
        onClose()
        if (onBookingInfos) {
          onBookingInfos({
            isLoading: false,
            name: `${selectedSlot?.advisor.firstName} ${selectedSlot?.advisor.lastName}`,
            start: temporal(selectedSlot?.utcDate).format('dddd LL [à] HH[h]mm'),
          })
        }
      })
      .catch((error: string) => {
        invalidate(error)
        onAlert(DefaultAlertMessage())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const consentProps = {
    id: shorthash('mutualized-consent'),
    isChecked: isAgreed,
    isError: isAgreementRequired,
    label: 'J’accepte que Pretto et ses partenaires de confiance me contactent pour étudier mon projet immobilier.',
    onChange: () => {
      setIsAgreementRequired(false)
      setIsAgreed(previousAgreement => !previousAgreement)
    },
  }

  return (
    <SubscribeComponent
      advisorLabel={ADVISOR_LABELS[selectedSlot?.advisor.role || 'sdr']}
      consentProps={consentProps}
      hasForm={typology !== 'client'}
      selectedSlot={selectedSlot}
      onModify={onModify}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      switchVisio={switchVisio}
      firstNameFieldComponent={firstNameFieldComponent}
      lastNameFieldComponent={lastNameFieldComponent}
      emailComponent={emailComponent}
      phoneFieldComponent={phoneFieldComponent}
    />
  )
}
