import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import * as S from './styles'

const ButtonFeature = forwardRef(({ href, isDisabled, label, onClick, picto, ...props }, ref) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    onClick(event, ...args)
  }

  return (
    <S.Button $isDisabled={isDisabled} href={href} onClick={handleClick} ref={ref} {...props}>
      {picto && <S.Picto as={picto} />}
      {label}
    </S.Button>
  )
})

ButtonFeature.propTypes = {
  /** url to redirect to */
  href: PropTypes.string,
  /** Disabled state. */
  isDisabled: PropTypes.bool,
  /** text to display */
  label: PropTypes.string.isRequired,
  /** function called on click (usually for tracking) */
  onClick: PropTypes.func,
  /** picto to show */
  picto: PropTypes.func,
}

ButtonFeature.defaultProps = {
  onClick: () => {},
}

ButtonFeature.displayName = 'ButtonFeature'

export default ButtonFeature
