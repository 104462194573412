import { breakpoints, g } from '@pretto/bricks/components/layout'
import { FileContent, FileContentProps } from '@pretto/zen/dashboard/files/FileContent/FileContent'
import styled from 'styled-components'
import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'
import Link from '@pretto/bricks/website/utility/Link'

interface FileItem extends FileContentProps {
  href: string
}
export interface FileListProps {
  items: FileItem[]
}

export const FileList = ({ items, ...props }: FileListProps) => (
  <List {...props}>
    {items.map(({ href, ...item }, i) => (
      <li key={i}>
        {i > 0 && <ItemDivider />}
        <Item {...item} href={href} />
      </li>
    ))}
  </List>
)

/* eslint-disable no-magic-numbers */
const List = styled.ul`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(1)} 0;
  }
`
const Item = styled(FileContent).attrs({ forwardedAs: Link })<FileItem>`
  padding: ${g(1)} ${g(2)};
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral4Hover};
  }
`
const ItemDivider = styled(Divider)`
  margin-left: ${g(2)};
`
