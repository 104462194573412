import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { ENTER } from '@pretto/bricks/core/constants/keycodes'

import { formatEncodeEmail } from '@pretto/app-core/lib/filters'

import { ErrorEmailNotFound, useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { connectionCard, loginPage } from '@pretto/app/src/Auth/config'
import { getQueryParameter } from '@pretto/app/src/Auth/lib/getQueryParameters'
import LoginExpiredPage from '@pretto/app/src/Auth/views/LoginExpiredPage'
import LoginPageComponent from '@pretto/app/src/Auth/views/LoginPage'
import { useEmailField } from '@pretto/app/src/Form/lib/useField'
import Front from '@pretto/app/src/Helpers/frontchat'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { DefaultAlertMessage } from '@pretto/app/src/components/DefaultAlertMessage/DefaultAlertMessage'
import { KnowMore } from '@pretto/app/src/components/KnowMore/KnowMore'
import { trackAction } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'

const LoginPage = ({ history }) => {
  const { isLoggedIn, requestGoogleConnect, requestSignIn, signIn } = useAuth()

  const { isMobile } = useBreakpoint()

  const [alert, setAlert] = useState('')
  const [isMutating, setIsMutating] = useState(false)

  const {
    check: emailFieldCheck,
    component: emailComponent,
    error,
    value: emailValue,
  } = useEmailField({
    defaultValue: getQueryParameter('email', ''),
    inputProps: {
      autoFocus: !isMobile,
      placeholder: 'Adresse email',
      required: true,
    },
    onKeyDown: e => {
      if (error || !emailValue) {
        return
      }

      if (e.keyCode === ENTER) {
        handleSubmit(e)
      }
    },
  })

  const { projectID } = useUser()

  useEffect(() => {
    const parsedHash = qs.parse(window.location.hash?.replace(/^#/, ''))

    const { access_token: accessToken, refresh_token: refreshToken } = parsedHash

    const redirectUri = getQueryParameter('redirect', '/')

    if (accessToken) {
      signIn({ accessToken, refreshToken }, () => {
        history.replace(redirectUri)
      })

      return
    }

    if (isLoggedIn) {
      history.push(redirectUri)
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (!emailFieldCheck()) {
      return
    }

    setAlert('')
    setIsMutating(true)

    try {
      const formattedEmail = formatEncodeEmail(emailValue)
      await requestSignIn(formattedEmail, {
        redirectUri: `${process.env.CLIENT_HOST}/login?redirect=${encodeURI(getQueryParameter('redirect', '/'))}`,
      })

      history.push(
        `/login-confirmed?email=${encodeURIComponent(formattedEmail)}&redirect=${encodeURI(
          getQueryParameter('redirect', '/')
        )}`
      )
    } catch (error) {
      if (error instanceof ErrorEmailNotFound) {
        history.push('/login/no-account')

        return
      }

      setAlert(DefaultAlertMessage())
      setIsMutating(false)
      Front('open')
    }
  }

  const handleGoogleConnect = async () => {
    setAlert('')

    try {
      await requestGoogleConnect({ projectID, redirectUri: encodeURI(getQueryParameter('redirect', '/')) })

      trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'login' })
    } catch (error) {
      setAlert(DefaultAlertMessage())
    }
  }

  const props = {
    ...loginPage,
    alert,
    connectionCardProps: {
      ...connectionCard(KnowMore()),
      emailComponent,
      isButtonLoading: isMutating,
      onGoogleConnect: handleGoogleConnect,
      onSubmit: handleSubmit,
    },
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} menu="login" />
      {getQueryParameter('expired') === 'true' ? <LoginExpiredPage href="/login" /> : <LoginPageComponent {...props} />}
    </>
  )
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }),
}

export default LoginPage
