export const prettoSearch = {
  appStore: {
    badgeSlug: 'v1587373065/bricks/static-assets/store_badge-ios.svg',
    label: ['App Store', "l'App Store"],
    slug: 'AppStore',
    url: 'https://apps.apple.com/fr/app/pretto-search-achat-immo/id1515505388',
  },
  googlePlayStore: {
    badgeSlug: 'v1587373063/bricks/static-assets/store_badge-android.svg',
    label: ['Google Play Store', 'le Google Play Store'],
    slug: 'GooglePlayStore',
    url: 'https://play.google.com/store/apps/details?id=fr.pretto.propertysearch',
  },
}
