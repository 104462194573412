import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ONBOARDING, UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { pages } from '@pretto/app/src/onboarding/config'
import { checkAllFields } from '@pretto/app/src/onboarding/lib/checkAllFields'
import { decodeValuesForStep } from '@pretto/app/src/onboarding/lib/decodeValuesForStep'
import { encodeDataForStep } from '@pretto/app/src/onboarding/lib/encodeDataForStep'
import { encodeValuesForStep } from '@pretto/app/src/onboarding/lib/encodeValuesForStep'
import { filterMessages } from '@pretto/app/src/onboarding/lib/filterMessages'
import { getNextUrl } from '@pretto/app/src/onboarding/lib/getNextUrl'
import { Step } from '@pretto/app/src/onboarding/views/Step/Step'

import { useApolloClient, useMutation } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import mergeWith from 'lodash/mergeWith'
import set from 'lodash/set'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const StepPage = ({ data, onSave, params, schema }) => {
  const { mutate } = useApolloClient()

  const { push, goBack } = useHistory()

  const [initialValues, setInitialValues] = useState(null)
  const [isErrorActive, setIsErrorActive] = useState(false)
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState(null)

  const userContext = useUser()

  const trackAction = useTracking()

  useEffect(() => {
    let effective = true

    ;(async () => {
      const defaultValues = await decodeValuesForStep({ schema, data, params }, userContext)

      if (!effective) {
        return
      }

      setInitialValues(defaultValues)
      setValues(defaultValues)
    })()

    return () => (effective = false)
  }, [data])

  const fields = useMemo(() => funcToItem(schema.fields, { data, params }, userContext), [values])

  if (!values) {
    return <Step isEmpty />
  }

  const handleChange = (name, value, error) => {
    setIsErrorActive(false)
    setValues(values => ({ ...values, [name]: value }))

    if (!isNil(error)) {
      setErrors(errors => ({ ...errors, [name]: error }))
    }
  }

  const handlePrevious = () => {
    goBack()
  }

  const handleSelect = ({ name, value }) => {
    const currentField = fields.find(field => field.name === name)

    const allFieldsJumpable = fields.every(field => ['boolean', 'options', 'message'].includes(field.type))
    const isLastFieldJumpable = ['boolean', 'options'].includes(fields[fields.length - 1].type)

    const shouldJumpToNext = funcToItem(defaultTo(currentField.jumpToNext, allFieldsJumpable && isLastFieldJumpable), {
      value,
    })

    const newValues = {
      ...values,
      [name]: value,
    }

    const track = funcToItem(currentField.track, { data, value, values: newValues })

    if (track) {
      trackAction(...track)
    }

    if (!shouldJumpToNext) {
      handleChange(name, value)
      return
    }

    next({ data, values: newValues })
  }

  const handleNext = () => {
    const error = Object.values(errors).reduce((previous, error) => {
      if (previous === true) return previous

      return error
    }, false)

    if (error) {
      setIsErrorActive(true)
      return
    }

    next({ data, values })
  }

  const next = async ({ data, values }) => {
    const mutation = funcToItem(schema.mutation, { data, values, userContext })
    const nextUrl = getNextUrl(schema.jumps, { data, values, userContext })
    const track = funcToItem(schema.track, { data, values })

    if (track) {
      trackAction(...track)
    }

    if (mutation) {
      mutate(mutation)
      push(nextUrl)
      return
    }

    const encodedValues = encodeValuesForStep(schema)(values, data)

    if (Object.keys(encodedValues).length === 0) {
      push(nextUrl)
      return
    }

    const valuesToPayload = Object.entries(encodedValues).reduce(
      (previous, [key, value]) => set(previous, key, value),
      cloneDeep(data)
    )

    const encodedData = await encodeDataForStep(valuesToPayload, data)

    onSave(valuesToPayload, encodedData)
    setInitialValues(values)
    push(nextUrl)
  }

  const activeFields = fields.reduce((previous, current) => {
    const { condition, description, getDefaultValue, name, label, labelSuffix, type, options } = current
    const isActive = !condition || condition({ values, data, userContext })

    if (isActive) {
      const baseProps = {
        data,
        onSelect: handleSelect,
        onUpdateValue: handleChange,
        next,
        values,
      }

      const renderedLabel = funcToItem(label, { data })
      const renderedOptions = options ? funcToItem(options, { data }) : null

      const element = {
        description,
        type,
        label: renderedLabel,
        labelSuffix,
        name,
        componentProps: {
          ...current,
          ...baseProps,
          options: renderedOptions,
          defaultValue: getDefaultValue?.({ data }) ?? get(data, name),
          value: values[name],
          error: isErrorActive && errors[name],
          data,
          name,
        },
      }

      return [...previous, element]
    }

    return previous
  }, [])

  const actionFields = activeFields.filter(filterMessages)

  const isValuesEmpty = Object.keys(values).length === 0

  const isNextActive = isValuesEmpty
    ? false
    : checkAllFields({ fields: actionFields, oldValues: initialValues, values })

  const props = {
    activeFields,
    advisorName: userContext.advisor.name,
    advisorPicturePath: userContext.advisor.mediumPicturePath,
    advisorRole: userContext.advisor.role,
    id: schema.id,
    isLowPotential: userContext.isLowPotential,
    isNextActive,
    onNextClick: handleNext,
    onPreviousClick: handlePrevious,
  }

  return <Step {...props} />
}

StepPage.propTypes = {
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  params: PropTypes.object,
  schema: PropTypes.object.isRequired,
}

export const StepPageController = ({
  match: {
    params: { step },
  },
  data: onboardingData,
  ...props
}) => {
  const [updateProject] = useMutation(UPDATE_PROJECT)
  const [data, setData] = useState(onboardingData)
  const userContext = useUser()

  useEffect(() => {
    setData(onboardingData)
  }, [onboardingData])

  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const schema = pages.find(({ id }) => id === step)

  const handleSave = (payload, optimisticData) => {
    const merge = (...objects) =>
      mergeWith(...objects, (source, target) => {
        if (isPlainObject(source) && isPlainObject(target)) {
          return merge(source, target)
        }

        return target
      })

    const mergedData = merge(cloneDeep(data), optimisticData)

    setData(mergedData)
    updateProject({
      refetchQueries: [{ query: ONBOARDING }],
      variables: { project: JSON.stringify(payload.project) },
    })
  }

  if (!schema) {
    return <Redirect to="/404" />
  }

  const redirect = funcToItem(defaultTo(schema.redirect, false), { data, params, userContext })

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <StepPage
      {...props}
      data={data}
      key={`form${location.key || location.pathname}`}
      onSave={handleSave}
      params={params}
      schema={schema}
    />
  )
}

StepPageController.propTypes = {
  data: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }).isRequired,
  }),
}
