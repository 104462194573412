import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { MandateIntroduction } from '@pretto/app/src/mandate/views/MandateIntroduction/MandateIntroduction'

import PropTypes from 'prop-types'

const IntroductionPage = ({ next }) => {
  const { avatarProps } = useAdvisor()

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <MandateIntroduction onContinue={next} avatarProps={avatarProps} />
    </>
  )
}

IntroductionPage.propTypes = {
  next: PropTypes.func.isRequired,
}

export default IntroductionPage
