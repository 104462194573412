import { ExclamationMarkCircleBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const SideBarMenu = ({ isActive, isComplete, isErrored = false, label, picto, url }) => (
  <S.SideBarMenuContainer href={url}>
    <S.Picto as={picto} $isActive={isActive} />

    <S.Label $isActive={isActive}>{label}</S.Label>

    {isErrored && <S.Error as={ExclamationMarkCircleBold} />}
    {isComplete !== null && <S.Status $isComplete={isComplete}>{isComplete ? 'Complet' : 'Incomplet'}</S.Status>}
  </S.SideBarMenuContainer>
)

SideBarMenu.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool,
  isErrored: PropTypes.bool,
  label: PropTypes.string.isRequired,
  picto: PropTypes.elementType.isRequired,
  url: PropTypes.string.isRequired,
}

const ApplicationSideBar = ({ items }) => (
  <S.Sidebar>
    {items.map((item, index) => (
      <Fragment key={index}>
        {index === items.length - 1 && <S.Divider />}

        <SideBarMenu {...item} />
      </Fragment>
    ))}
  </S.Sidebar>
)

ApplicationSideBar.propTypes = {
  items: PropTypes.array.isRequired,
}

export default ApplicationSideBar
