import Divider from '@pretto/bricks/components/dividers/Divider'
import Text from '@pretto/bricks/components/typography/Text'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import Transition from '@pretto/bricks/components/utility/Transition'

import defaultTo from 'lodash/defaultTo'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import * as S from './styles'

const KEY_TAB = 9
const KEY_ARROW_UP = 38
const KEY_ARROW_DOWN = 40
const KEY_ENTER = 13
const KEY_ESC = 27
const KEY_SPACE = 32
const OPEN_KEYS = [KEY_ARROW_DOWN, KEY_ARROW_UP, KEY_ENTER, KEY_SPACE]

const SelectField = ({
  autoFocus,
  format,
  onBlur,
  onChange,
  options,
  placeholder,
  prefixIcon,
  scrollOnOpen,
  size,
  textVariant,
  value,
  variant,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [isMenuActive, setIsMenuActive] = useState(false)

  const node = useRef(null)
  const tabbables = useRef([])
  const timeout = useRef()

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    if (isFocused) node.current.focus()

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      clearTimeout(timeout.current)
    }
  }, [])

  const handleClickOutside = event => {
    const domNode = node.current

    if (!domNode || !domNode.contains(event.target)) {
      setIsFocused(false)
      setIsMenuActive(false)
    }
  }
  const handleClick = option => {
    onChange(option)
    handleCloseMenu()
  }
  const handleOpenMenu = () => {
    setIsMenuActive(true)
  }
  const handleCloseMenu = () => {
    setIsMenuActive(false)
  }
  const toggleMenu = () => {
    if (scrollOnOpen) {
      node.current.scrollIntoView({ behavior: 'smooth' })
    }
    setIsMenuActive(isActive => !isActive)
  }

  const focusTab = (direction, index = 0) => {
    let action = null

    switch (direction) {
      case 'first':
        action = () => tabbables.current[0]?.focus()
        break

      case 'next':
        if (index === tabbables.current.length - 1) action = () => tabbables.current[0]?.focus()
        else action = () => tabbables.current[index + 1]?.focus()
        break

      case 'previous':
        if (index === 0) action = () => tabbables.current[tabbables.current.length - 1]?.focus()
        else action = () => tabbables.current[index - 1]?.focus()
        break
      default:
    }

    if (action) {
      timeout.current = setTimeout(() => {
        setIsFocused(true)
        action()
      }, 1)
    }
  }

  const handleKeyDown = e => {
    const key = e.keyCode
    const shouldOpen = OPEN_KEYS.includes(key)
    if (shouldOpen) {
      e.preventDefault()
      if (!isMenuActive) {
        handleOpenMenu()
        focusTab('first')
      }
    }
  }

  const handleItemKeyDown = (option, e) => {
    const key = e.keyCode
    const currentIndex = findIndex(options, option)
    switch (key) {
      case KEY_TAB:
      case KEY_ARROW_DOWN:
        focusTab('next', currentIndex)
        break
      case KEY_ARROW_UP:
        focusTab('previous', currentIndex)
        break
      case KEY_ESC:
        handleCloseMenu()
        node.current.focus()
        break
      case KEY_ENTER:
        setIsFocused(false)
        timeout.current = setTimeout(() => {
          handleClick(option)
        }, 1)
        break
      default:
    }
  }

  const selected = find(options, ['value', value])
  const label = selected ? selected.selectedLabel ?? selected.label : placeholder

  const contentList = options.map((option, i) => {
    const isSelected = value === option.value

    const handleRef = node => {
      tabbables.current[i] = node
    }

    return (
      <S.ListItem
        key={i}
        onClick={() => handleClick(option)}
        ref={handleRef}
        tabIndex={0}
        onKeyDown={e => handleItemKeyDown(option, e)}
      >
        {i > 0 && <Divider />}
        <S.Item isSelected={isSelected} variant={variant} value={defaultTo(option.value, option)}>
          <span>{defaultTo(option.label, option)}</span>
          <S.Icon>{isSelected && <S.IconLegacy name="check" />}</S.Icon>
        </S.Item>
      </S.ListItem>
    )
  })

  const transitionProps = {
    duration: 300,
    in: isMenuActive,
    unmountOnExit: true,
  }

  return (
    <S.SelectField
      format={format}
      isValid={selected}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      ref={node}
      size={size}
      tabIndex={0}
      variant={variant}
      {...props}
    >
      <S.Label onClick={toggleMenu} isPlaceholder={!selected}>
        {format === 'block' && prefixIcon && (
          <S.PrefixIcon>
            <S.IconLegacy name={prefixIcon} variant="primary-1" />
          </S.PrefixIcon>
        )}

        <S.LabelText variant={textVariant}>
          {format === 'filter' && (
            <S.LabelFilter>
              <Text size="small">Trier</Text>
            </S.LabelFilter>
          )}
          {label}
        </S.LabelText>

        <S.LabelIcon>
          <S.IconLegacy name="arrow-full-bottom" variant={variant} />
        </S.LabelIcon>
      </S.Label>
      {typeof document !== 'undefined' &&
        createPortal(
          <Responsive max="tablet">
            <Transition {...transitionProps} kind="opacity">
              <S.Overlay onClick={handleCloseMenu}>
                <S.List>{contentList}</S.List>
              </S.Overlay>
            </Transition>
          </Responsive>,
          document.body
        )}
      <Responsive min="tablet">
        <Transition {...transitionProps} element={S.List} kind="dropdown">
          {contentList}
        </Transition>
      </Responsive>
    </S.SelectField>
  )
}

SelectField.propTypes = {
  /** Whether if SelectField is focused on mount or not. */
  autoFocus: PropTypes.bool,
  /** Format style of SelectField */
  format: PropTypes.string,
  /** Event triggered whenever the select field is being blurred. */
  onBlur: PropTypes.func,
  /** Callback function fired when a menu item is selected. The function has 2 props: <code>label</code> and <code>value</code> */
  onChange: PropTypes.func,
  /** Array of options. Each option must be an object. Format: <code>[[{label: 'label 1', value: 'value 1'},{label: 'label 2', value: 'value 2'}]]</code> */
  options: PropTypes.array.isRequired,
  /** Text for placeholder*/
  placeholder: PropTypes.node,
  /** Icon that goes before the label, only for block format. */
  prefixIcon: PropTypes.string,
  /** Size of component. */
  size: PropTypes.string,
  /** Variant of SelectField (text) */
  textVariant: PropTypes.string,
  /** Selected <code>value</code> of SelectField */
  value: PropTypes.any,
  /** Variant of SelectField (decoration) */
  variant: PropTypes.string,
  /** If set to `true`, opening the selectfield will trigger a scroll. */
  scrollOnOpen: PropTypes.bool,
}

SelectField.defaultProps = {
  autoFocus: false,
  format: 'default',
  placeholder: 'Choisissez',
  variant: 'primary-1',
}

export default memo(SelectField)
