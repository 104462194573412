import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Highlights = styled.div`
  display: flex;
  margin: 0 ${g(-2)};
  overflow: auto;
  padding-left: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
  }
`

export const Highlight = styled.div`
  flex-shrink: 0;
  min-width: ${g(14)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: ${g(20)};
  }

  & + & {
    margin-left: ${g(1)};
  }

  &:last-child {
    box-sizing: content-box;
    padding-right: ${g(2)};
  }
`

export const HighlightContent = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-right: ${g(2)};
  }
`

export const PlaceholderTitle = styled.div`
  height: ${g(2, 2)};
  margin-bottom: ${g(1)};
  width: 75%;
`

export const PlaceholderContent = styled.div`
  height: ${g(3)};
  width: 50%;
`
