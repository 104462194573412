import { ERROR_TYPES, getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'
import CenterGridTemplate from '@pretto/bricks/shared/templates/CenterGridTemplate'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const SignUpPage = ({
  connectionError,
  link,
  hrefConnect,
  isNewsletterChecked,
  onNewsletterToggle,
  title,
  connectionCardProps,
}) => {
  const error = connectionError && getAuthErrorMessage(connectionError, link)

  return (
    <CenterGridTemplate alert={error}>
      <S.Title>{title}</S.Title>
      <S.Content>
        <S.GoogleOrEmail {...connectionCardProps} />
        <S.NewsletterCheckbox
          id="newsletter"
          isChecked={isNewsletterChecked}
          label={<S.Newsletter>Je souhaite recevoir la newsletter Pretto.</S.Newsletter>}
          onChange={onNewsletterToggle}
        />
        <S.ButtonConnect href={hrefConnect}>J’ai déjà un compte</S.ButtonConnect>
        <S.Image />
      </S.Content>
    </CenterGridTemplate>
  )
}

SignUpPage.propTypes = {
  connectionCardProps: PropTypes.object,
  connectionError: PropTypes.oneOf(ERROR_TYPES),
  hrefConnect: PropTypes.string,
  isNewsletterChecked: PropTypes.bool,
  link: PropTypes.node,
  onNewsletterToggle: PropTypes.func,
  title: PropTypes.string,
}

export default memo(SignUpPage)
