import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} 0;
  }
`
export const Check = styled.div`
  margin-top: ${g(5)};
`
export const Detail = styled.div`
  margin-bottom: ${g(2)};
`
export const Title = styled.div`
  margin-bottom: ${g(2)};
`
export const Disclaimer = styled.div`
  margin-bottom: ${g(1)};

  a {
    text-decoration: underline;
  }
`
export const Header = styled.div`
  margin-bottom: ${g(5)};
`
export const Message = styled.div`
  margin-bottom: ${g(1)};
`
export const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(7)};
  }
`
export const ButtonContainer = styled.div`
  margin-top: ${g(5)};
`
export const ButtonText = styled(({ loading, ...props }) => <span {...props} />)`
  ${({ loading }) =>
    loading &&
    css`
      color: transparent;
    `}
`
export const ButtonSpinner = styled.div`
  position: absolute;
`
export const Alert = styled.div`
  margin-top: ${g(5)};
`
