import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import clamp from 'lodash/clamp'
import styled from 'styled-components'

type Type = 'fraction' | 'percentage'

export type ProgressBarProps = {
  isIndicatorVisible?: boolean
  max?: number
  progress: number
  type?: Type
}

interface IndicatorProps {
  max: number
  progress: number
  type: Type
}

const IndicatorComponent: React.FC<IndicatorProps> = ({ max, progress, type, ...props }) => {
  switch (type) {
    case 'fraction':
      return (
        <span {...props}>
          {Math.floor(progress)}/{max}
        </span>
      )

    case 'percentage':
    default:
      return <span {...props}>{Math.round((progress / max) * 100)} %</span>
  }
}

export const ProgressBar: React.FC<React.HTMLAttributes<HTMLDivElement> & ProgressBarProps> = ({
  isIndicatorVisible = false,
  max = 100,
  progress,
  type = 'fraction',
  ...props
}) => {
  const clampedProgress = clamp(progress, 0, max)

  return (
    <Component {...props}>
      {isIndicatorVisible && <Indicator max={max} progress={clampedProgress} type={type} />}

      <Bar>
        <Progress style={{ transform: `translateX(-${100 * (1 - clampedProgress / max)}%)` }} />
      </Bar>
    </Component>
  )
}

const Bar = styled.span`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1 / 2)};
  display: inline-flex;
  flex-grow: 1;
  height: ${g(1)};
  overflow: hidden;
`

const Progress = styled.span`
  display: inline-flex;
  background-color: ${({ theme }) => theme.colors.primary2};
  border-bottom-right-radius: ${g(1 / 2)};
  border-top-right-radius: ${g(1 / 2)};
  height: 100%;
  ${transition({ propertyName: 'transform' })};
  width: 100%;
  will-change: transform;
`

const Component = styled.div`
  align-items: center;
  display: flex;
  gap: ${g(1)};
  height: ${g(3)};
`

const Indicator = styled(IndicatorComponent)`
  ${({ theme }) => theme.typos.body4};
  ${({ theme }) => theme.typos.isNumber};
  color: ${({ theme }) => theme.colors.neutral1};
  flex-shrink: 0;
`
