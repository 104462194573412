import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SpinnerContent from '@pretto/bricks/shared/components/SpinnerContent'

import SpinnerOverlay from '@pretto/app/src/SharedContainers/SpinnerOverlay'

interface LoaderProps {
  type?: LoaderType
}

export enum LoaderType {
  Default = 'default',
  Simulation = 'simulation',
  Subscribe = 'subscribe',
}

export const Loader: React.FC<LoaderProps> = ({ type } = { type: LoaderType.Default }) => {
  switch (type) {
    case LoaderType.Simulation:
      return <SpinnerOverlay />

    case LoaderType.Subscribe:
      return (
        <SpinnerLegacy overlay>
          <SpinnerContent slug="subscribe" />
        </SpinnerLegacy>
      )

    case LoaderType.Default:
    default:
      return <SpinnerLegacy overlay />
  }
}
