import { getSignDate } from '@pretto/app/src/dashboard/lib/getSignDate'
import type { Account, Signature } from '@pretto/app/src/dashboard/types/signature'

export const signStatus = ({
  accounts,
  hasComortgagor,
  mandateStatus,
}: {
  accounts: Account[]
  hasComortgagor: boolean
  mandateStatus?: { signatures?: Signature[] | null } | null
}) => {
  // No status
  if (!mandateStatus) {
    return 'NO_MANDATE'
  }

  const { signatures } = mandateStatus

  // Cardinality mismatch
  if (!Array.isArray(signatures) || signatures.length !== accounts.length) {
    return 'INVALID_MANDATE'
  }

  // Solo
  if (!hasComortgagor) {
    return signatures[0].signedAt ? 'SOLO_SIGNED' : 'SOLO_AWAITING'
  }

  // Duo
  const mineSigned = !!getSignDate(accounts, signatures)
  const otherSigned = !!getSignDate(accounts, signatures, false)

  if (mineSigned && otherSigned) {
    return 'DUO_SIGNED'
  }

  if (mineSigned) {
    return 'DUO_AWAITING_OTHER'
  }

  if (otherSigned) {
    return 'DUO_AWAITING_ME'
  }

  return 'DUO_AWAITING_BOTH'
}
