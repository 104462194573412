import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { SEPA } from '@pretto/app/src/apollo'
import { getSignDate } from '@pretto/app/src/dashboard/lib/getSignDate'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { getMandateSuccessVariant } from '@pretto/app/src/mandate/lib/getMandateSuccessVariant'
import { MandateSuccess, MandateSuccessProps } from '@pretto/app/src/mandate/views/MandateSuccess/MandateSuccess'
import { MandateSuccessBooking } from '@pretto/app/src/mandate/views/MandateSuccessBooking/MandateSuccessBooking'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'

interface SuccessPageProps {
  next: () => void
}

const SuccessPage = ({ next }: SuccessPageProps) => {
  const { loading, data, client } = useQuery(SEPA)
  const {
    accounts,
    advisor,
    hasComortgagor,
    user: { activeIndex },
  } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const trackAction = useTracking()

  if (loading || isLoading) return <SpinnerLegacy overlay />

  const handleContinue = async () => {
    setIsLoading(true)
    await client.clearStore()
    next()
  }

  const { next_booking } = data
  const isRepresentative = advisor.role !== 'expert'
  const comortgagorSignDate = hasComortgagor && getSignDate(accounts, data.deal.mandateStatus?.signatures, false)
  const isAwaitingForComortgagor = hasComortgagor && !comortgagorSignDate

  const isBookingAvailable = !(isRepresentative || isAwaitingForComortgagor || next_booking)

  if (isBookingAvailable) {
    const handleSkip = () => {
      trackAction('MANDATE_SUCCESS_APPOINTMENT_SKIPPED')
    }

    const props = {
      onSkip: handleSkip,
      bookingWidgetProps: {
        delayBeforeAppointment: [7, 'day'],
        onBooked: next,
      },
    }

    return (
      <>
        <Header navigationItemsList={[NAV_ITEMS.faq]} />

        <MandateSuccessBooking {...props} />
      </>
    )
  }

  const props: MandateSuccessProps = getMandateSuccessVariant({
    accounts,
    activeIndex,
    advisor,
    data,
    onContinue: handleContinue,
    hasComortgagor,
  })

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />

      <MandateSuccess {...props} />
    </>
  )
}

SuccessPage.propTypes = {
  next: PropTypes.func.isRequired,
}

export default SuccessPage
