import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import * as S from './FileMissing.styles'

type Type = 'document' | 'information'

export interface FileMissingProps {
  href: string
  type?: Type
}

const getContent = (type: Type) => {
  const content = {
    document: {
      buttonTitle: 'Compléter mes documents',
      description: 'Il manque des documents pour compléter cette catégorie.',
      image: 'v1639745718/zen/static-assets/illustrations/660x660/frame-notepad.svg',
      shortButtonTitle: 'Compléter',
    },
    information: {
      buttonTitle: 'Compléter mes informations',
      description: 'Il manque des informations pour compléter cette catégorie.',
      image: 'v1637685710/zen/static-assets/illustrations/660x660/face-woman-doubt.svg',
      shortButtonTitle: 'Compléter',
    },
  }

  return content[type]
}

export const FileMissing = ({ href, type = 'document', ...fileMissingprops }: FileMissingProps) => {
  const { buttonTitle, description, image, shortButtonTitle } = getContent(type)
  const buttonText = useBreakpointToValue(shortButtonTitle, { tablet: buttonTitle })

  return (
    <S.Container {...fileMissingprops}>
      <S.Illustraton path={image} />
      <div>
        <S.Description>{description}</S.Description>
        <ButtonInline href={href} isSecondary>
          {buttonText}
        </ButtonInline>
      </div>
    </S.Container>
  )
}
