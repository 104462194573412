import { useBreakpoint } from '@pretto/bricks/assets/utility'

import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import Front from '@pretto/app/src/Helpers/frontchat'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { ONBOARDING } from '@pretto/app/src/apollo'
import { CongratulationsPage } from '@pretto/app/src/onboarding/containers/congratulations'
import { IntroductionPage } from '@pretto/app/src/onboarding/containers/introduction'
import { StepPageController } from '@pretto/app/src/onboarding/containers/step'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'

const OnboardingPageDispatcher = () => {
  const { data, loading } = useQuery(ONBOARDING)
  const { isMobile } = useBreakpoint()

  useLoading(loading)

  useEffect(() => {
    return () => {
      Front('activate')
    }
  }, [])

  useEffect(() => {
    const frontStatus = isMobile ? 'sleep' : 'activate'

    Front(frontStatus)
  }, [isMobile])

  if (loading) {
    return null
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />

      <Switch>
        <Route exact path={['/onboarding', '/onboarding/introduction']} component={IntroductionPage} />

        <Route exact path="/onboarding/congratulations" component={CongratulationsPage} />
        <Route
          exact
          path="/onboarding/:step"
          render={routeProps => <StepPageController {...routeProps} data={data} />}
        />
      </Switch>
    </>
  )
}

OnboardingPageDispatcher.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }).isRequired,
  }),
}

export default OnboardingPageDispatcher
