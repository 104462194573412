import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'

import { BulletBook as BulletBookComponent } from '@pretto/picto'

import styled from 'styled-components'

interface CriteriasCriteria {
  label: string
  state: 'error' | 'success' | 'warning'
}

export interface CriteriasProps {
  isDisclaimed: boolean
  bankCriterias?: CriteriasCriteria[]
  offerCriterias: CriteriasCriteria[]
}

export const Criterias: React.FC<CriteriasProps> = ({ isDisclaimed, bankCriterias, offerCriterias }) => (
  <Component>
    <Header>L’offre de cette banque{isDisclaimed && <sup>*</sup>} vous permet de :</Header>

    <List>
      {offerCriterias.map(({ label }, index) => (
        <ListItem key={index}>
          <BulletBook />
          <span>{label}</span>
        </ListItem>
      ))}
    </List>

    {bankCriterias && (
      <>
        <Divider />

        <Header>Cette banque{isDisclaimed && <sup>*</sup>} :</Header>

        <List>
          {bankCriterias.map(({ label }, index) => (
            <ListItem key={index}>
              <BulletBook />
              <span>{label}</span>
            </ListItem>
          ))}
        </List>
      </>
    )}

    {isDisclaimed && (
      <Disclaimer>
        <sup>*</sup>Pour découvrir votre future banque, échangez avec votre Expert Pretto.
      </Disclaimer>
    )}
  </Component>
)

const Component = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-bottom-left-radius: ${g(1)};
  border-bottom-right-radius: ${g(1)};
  padding: ${g(2)} ${g(3)};
  width: calc(100% - ${g(4)});

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    flex-basis: calc(47% + ${g(2)});
    padding: ${g(3)} ${g(3)} ${g(3)} ${g(5)};
    margin-left: ${g(-2)};
    width: auto;
  }
`

const BulletBook = styled(BulletBookComponent)`
  fill: ${({ theme }) => theme.colors.neutral2};
  flex-shrink: 0;
  height: ${g(2)};
  width: ${g(2)};
`

const Disclaimer = styled.div`
  ${typography.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-top: ${g(2)};
`

const Divider = styled(DividerComponent)`
  margin: ${g(2)} 0;
`

const Header = styled.header`
  ${typography.link12};
  margin-bottom: ${g(2)};
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};
`

const ListItem = styled.li`
  ${typography.caption12};
  display: flex;
  gap: ${g(1 / 2)};
`
