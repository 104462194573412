import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.svg`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  animation-timing-function: linear;
`
