import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as C from './DirectionsSimulationCard.module.css'

const DirectionsSimulationCard = ({ emoji, title, description, buttonText, onClick }) => (
  <div className={C.directionsSimulationCard}>
    <Card variant="primary-1" zDepth={2} format="full-height">
      <Content format="full-height">
        <div className={C.directionsSimulationCardTitle}>
          <div className={C.directionsSimulationCardTitleImg}>
            <Emoji size="medium">{emoji}</Emoji>
          </div>
          <div>
            <SubHeading size="large" variant="white">
              {title}
            </SubHeading>
          </div>
        </div>
        <div className={C.directionsSimulationCardContent}>{description}</div>
        <div className={C.directionsSimulationCardButton}>
          <Content>
            <ButtonLegacy format="full" variant="white" textVariant="primary-1" onClick={onClick}>
              {buttonText}
            </ButtonLegacy>
          </Content>
        </div>
      </Content>
    </Card>
  </div>
)

DirectionsSimulationCard.propTypes = {
  emoji: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(DirectionsSimulationCard)
