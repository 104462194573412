import { breakpoints, g } from '@pretto/bricks/components/layout'
import { bodyBook16, bodyMedium16, heading16, linkUnderline12 } from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Advisor = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: ${g(2)} 0;
  height: ${g(10)};
`

export const Infos = styled.div`
  margin-right: ${g(3)};
`
export const Actions = styled.div`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  align-items: center;

  & > * + * {
    margin-left: ${g(2)};
  }
`
export const Icon = styled.div`
  padding: ${g(1)};
  padding-bottom: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    cursor: pointer;
  }
`

export const Button = styled.a`
  display: block;
  padding: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    cursor: pointer;
  }
`

export const TimelineAdvisorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const AvatarContainer = styled.div`
  margin-right: ${g(2)};
`

export const HolidaysContainer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(4)};
    border-left: 1px solid ${({ theme }) => theme.colors.neutral3};
    padding-left: ${g(4)};
  }
`

export const DateContainer = styled.div`
  ${bodyMedium16}
  margin-bottom: ${g(1 / 2)};
`

export const MoreContainer = styled.div`
  ${linkUnderline12}
  cursor: pointer;
`

export const InfoTitle = styled.p`
  ${bodyBook16};
  color: ${({ theme }) => theme.colors.neutral2};
`

export const ExpertName = styled.p`
  ${heading16};
`
