interface SanitizeNumberString {
  type: 'integer' | 'number'
  value: string
  contextValue: string
  allowedDecimalSeparators: string[]
}

export const sanitizeNumberString = ({
  type,
  value,
  contextValue,
  allowedDecimalSeparators,
}: SanitizeNumberString): string => {
  const match = value.match(new RegExp(`[\\d${(type === 'number' ? allowedDecimalSeparators : []).join('')}]`, 'g'))

  if (!match) {
    return ''
  }

  return match.reduce((previous, character) => {
    if (/^\d$/.test(character)) {
      return `${previous}${character}`
    }

    if (previous.includes('.') || contextValue.includes('.')) {
      return previous
    }

    return `${previous}.`
  }, '')
}
