import PropTypes from 'prop-types'

import * as S from './styles'

const ENTER_KEY_CODE = 13
const SPACE_KEY_CODE = 32

const Avatar = ({
  onClick,
  size,
  src,
  backgroundColorVariant,
  colorVariant,
  isBackgroundTransparent,
  isWithWarningIcon,
  isWithOverlay,
  ...props
}) => {
  const handleKeyDown = event => {
    if ([ENTER_KEY_CODE, SPACE_KEY_CODE].includes(event.keyCode)) {
      event.preventDefault()
      onClick(event)
    }
  }

  return (
    <S.AvatarContainer>
      <S.Avatar
        {...props}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        $isClickable={!!onClick}
        $size={size}
        $backgroundColorVariant={backgroundColorVariant}
        $colorVariant={colorVariant}
        $isBackgroundTransparent={isBackgroundTransparent}
        $isWithOverlay={true}
      >
        {src && (src.length === 1 || typeof src !== 'string') ? (
          src
        ) : (
          <S.Image
            $isClickable={!!onClick}
            $size={size}
            alt="avatar"
            path={src || 'v1637684805/zen/static-assets/illustrations/660x660/headBulbe.svg'}
          />
        )}
        {onClick && (
          <S.PictoContainer>
            <S.Picto />
          </S.PictoContainer>
        )}

        {isWithOverlay && <S.Overlay />}
      </S.Avatar>
      {isWithWarningIcon && <S.ActivityIcon $size={size} />}
    </S.AvatarContainer>
  )
}

Avatar.propTypes = {
  /** Background color of the variant, when it is a letter. */
  backgroundColorVariant: PropTypes.string,
  /** Color of the variant, when it is a letter. */
  colorVariant: PropTypes.string,
  /** Is background transparent ? It overrides the background color variant */
  isBackgroundTransparent: PropTypes.bool,
  /** Add an overlay (for expert vacation for example) */
  isWithWarningIcon: PropTypes.bool,
  /** Add an overlay (for expert vacation for example) */
  isWithOverlay: PropTypes.bool,
  /** On click fuction on Avatar. Creates a hover effect. */
  onClick: PropTypes.func,
  /** Avatar iage size: x-small, small, medium or large. */
  size: PropTypes.string,
  /** Avatar image source. Can be one letter too. */
  src: PropTypes.node,
}

Avatar.defaultProps = {
  backgroundColorVariant: 'neutral4',
  colorVariant: 'neutral1',
  isBackgroundTransparent: false,
  size: 'medium',
}

export default Avatar
