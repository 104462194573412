import EditableFields from '@pretto/bricks/app/simulator/components/EditableFields'
import SimulationControls from '@pretto/bricks/app/simulator/components/SimulationControls'

import { MagicWand } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CONTENT = (type, props) => {
  switch (type) {
    case 'controls':
      return <SimulationControls {...props} />

    case 'fields':
      return <EditableFields {...props} />

    default:
      return 'Type is not defined'
  }
}

const SimulationDetails = ({ onOptimize, editableFieldsProps, controls, ...props }) => {
  const cards = [{ props: { controls }, type: 'controls' }]

  if (editableFieldsProps?.fields?.length > 0) {
    cards.push({ props: editableFieldsProps, type: 'fields' })
  }

  return (
    <S.Container {...props}>
      {cards.map((card, i) => (
        <div key={i}>{CONTENT(card.type, card.props)}</div>
      ))}
      {onOptimize && (
        <div>
          <S.Button label="Optimisation automatique" onClick={onOptimize} picto={MagicWand} />
        </div>
      )}
    </S.Container>
  )
}

SimulationDetails.propTypes = {
  /** Props of <code>[EditableFields](/#!/EditableFields)</code> component. */
  editableFieldsProps: PropTypes.object,
  onOptimize: PropTypes.func,
  /** Props of <code>[SimulationControls](/#!/SimulationControls)</code> component. */
  controls: PropTypes.object,
}

export default memo(SimulationDetails)
