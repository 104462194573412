import { g } from '@pretto/bricks/components/layout'
import Spinner from '@pretto/bricks/components/loading/Spinner'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { ChevronRightBold, ExclamationMarkCircle, ExclamationMarkTriangle } from '@pretto/picto'

import styled, { css } from 'styled-components'

const getColor = ({ theme: { colors }, $state }) => {
  switch ($state) {
    case 'error':
      return colors.error1
    case 'warning':
      return colors.warning1
    default:
      return colors.neutral3
  }
}
export const InputContainer = styled.div.attrs(
  ({ $isSingle, $onSubmit }) => $isSingle && { as: 'form', onSubmit: $onSubmit }
)`
  > input[type='date']::-webkit-calendar-picker-indicator {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: ${({ $size }) => ($size === 'big' ? g(8) : g(6))};
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    background: ${({ $error }) =>
      $error
        ? 'transparent'
        : `url(${getCloudinaryUrl('v1625139290/bricks/static-assets/icons/calendar.svg')}) no-repeat`};
    background-position: center right ${g(3)};
    justify-content: flex-end;
  }

  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  transition: box-shadow 150ms ease-in-out;
  position: relative;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutral3};

  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutral2};
  }

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme, $state }) => ($state ? getColor : theme.colors.neutral2)};
  }

  ${({ $state }) =>
    $state &&
    css`
      box-shadow: 0 0 0 1px ${getColor};
    `}
`

export const Input = styled(BaseTypo).attrs({ forwardedAs: 'input', typo: 'input' })`
  border: 0;
  color: ${({ theme }) => theme.colors.neutral1};
  width: 100%;
  flex-grow: 1;
  padding: ${({ $size }) => ($size === 'big' ? g(2) : `${g(1)} ${g(2)}`)};
  background-color: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ theme, $placeholderAsLabel }) =>
    $placeholderAsLabel &&
    css`
      color: ${theme.colors.neutral2};
    `}

  ${({ $state }) =>
    $state &&
    css`
      padding-right: ${g(9)};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral2};
  }

  &:focus {
    outline: 0;
  }
`

const PICTOS = {
  error: ExclamationMarkCircle,
  warning: ExclamationMarkTriangle,
}

export const AlertPicto = styled.svg.attrs(({ $state }) => ({
  as: PICTOS[$state],
}))`
  color: ${getColor};
  height: 100%;
  opacity: 0;
  position: absolute;
  right: ${g(3)};
  top: 0;
  transform: translateX(50%);
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;

  ${({ $isEntered }) =>
    $isEntered &&
    css`
      opacity: 1;
      transform: translateX(0);
    `};

  ${({ $isSingle }) =>
    $isSingle &&
    css`
      right: ${g(10)};
    `}

  ${({ $isWithPicto }) =>
    $isWithPicto &&
    css`
      right: ${g(7)};
    `}
`

export const Message = styled(BaseTypo).attrs({ typo: 'caption' })`
  color: ${getColor};
  padding-top: ${g(1)};
  flex-grow: 1;
  flex-basis: 100%;
  text-align: left;

  a,
  button {
    appearance: none;
    color: inherit;
    cursor: pointer;
    background: initial;
    font: inherit;
    text-decoration: underline;
  }
`

const backgroundStyle = ({ disabled, theme }) => {
  if (disabled) {
    return css`
      cursor: not-allowed;
      background-color: ${theme.designSystem === 'zen' ? theme.colors.accent1Disabled : theme.colors.neutral2};
    `
  }

  return css`
    cursor: pointer;
    background-color: ${theme.designSystem === 'zen' ? theme.colors.accent1 : theme.colors.neutral1};
  `
}
export const Picto = styled.svg`
  margin-right: ${g(2)};
`
export const Button = styled(BaseTypo).attrs({ forwardedAs: 'button' })`
  ${backgroundStyle}
  width: ${g(6)};
  height: ${g(6)};
  flex: 0 0 ${g(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(0.5)};
  margin: ${g(1)};

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      width: ${g(4)};
      height: ${g(4)};
      flex: 0 0 ${g(4)};
    `}
`
const SpinnerComponent = styled(Spinner)`
  width: ${g(3)};
  height: ${g(3)};
`
export const ButtonPicto = styled.svg.attrs(
  ({ $isLoading }) =>
    $isLoading && {
      as: SpinnerComponent,
    }
)``
export const ErrorBubble = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${g(1, 1)};
  position: relative;

  &:before {
    content: '';
    border: ${g(1)} solid transparent;
    border-top: 0;
    border-bottom-color: ${getColor};
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
  }
`
export const ErrorMessage = styled(BaseTypo).attrs({ typo: 'bodyBold' })`
  padding: ${g(2)};
  background-color: ${getColor};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  text-align: center;
`
