import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { SaveContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/SaveContext'
import { DialogSave as DialogSaveComponent } from '@pretto/app/src/Capacity/components/modals/DialogSave'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext } from 'react'

export const DialogSave: React.FC = () => {
  const trackAction = useTracking()
  const { requestGoogleConnect } = useAuth()
  const { emailFieldComponent, isSaveOpen, onCloseSave } = useContext(SaveContext)

  const { projectID } = useUser()

  const trackLegalLink = (source: string) => () => {
    trackAction('CAPACITY_RESULTS_LEGAL_LINKS_CLICKED', { capacity_results_legal_link: source })
  }

  const handleGoogleConnect = () => {
    requestGoogleConnect({ projectID })
    trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'capacity_result_page' })
  }

  return (
    <DialogSaveComponent
      emailFieldComponent={emailFieldComponent}
      isSaveOpen={isSaveOpen}
      onCguClick={trackLegalLink('cgu')}
      onGoogleConnect={handleGoogleConnect}
      onCloseSave={onCloseSave}
      onPrivacyClick={trackLegalLink('privacy')}
    />
  )
}
