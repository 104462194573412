import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { MANDATE_SUCCESS_VARIANTS } from '@pretto/app/src/mandate/config/mandateSuccessVariants'

export const getMandateSuccessData = ({ comortgagor, onContinue, variant }) => {
  const { firstButtonProps, illustration, secondButtonProps, texts, title } = MANDATE_SUCCESS_VARIANTS[variant]
  const customizedText = funcToItem(texts, { comortgagor })
  const customisedFirstButtonProps = funcToItem(firstButtonProps, { onContinue })

  return { firstButtonProps: customisedFirstButtonProps, illustration, secondButtonProps, texts: customizedText, title }
}
