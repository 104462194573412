import classnames from 'classnames'
import PropTypes from 'prop-types'
import { memo } from 'react'
import AutosizeInput from 'react-input-autosize'

import * as C from './AutosizeField.module.css'

const AutosizeField = ({ error, onBlur, onChange, placeholder, type, value }) => {
  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <AutosizeInput
      type={type}
      value={value}
      className={classnames(C.autosizeInput, {
        [C.autosizeInputError]: error,
        [C.autosizeInputIsValid]: value !== '',
      })}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  )
}

AutosizeField.defaultProps = {
  error: false,
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  type: 'text',
}

AutosizeField.propTypes = {
  /** Whether or not the field is in error. */
  error: PropTypes.bool,
  /** Triggered when exiting focus. */
  onBlur: PropTypes.func,
  /** Triggered when value changes. */
  onChange: PropTypes.func,
  /** Placeholder of AutosizeField. */
  placeholder: PropTypes.string,
  /** Types of field: <code>tel</code>, <code>email</code>.*/
  type: PropTypes.string,
  /** Value of AutosizeField. */
  value: PropTypes.string,
}

export default memo(AutosizeField)
