import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'
import { getValue } from '@pretto/app/src/lib/object'
import { DESCRIPTION, DETAILS } from '@pretto/app/src/overview/config/labels'
import { LOAN } from '@pretto/app/src/overview/config/rows'
import { VARIANTS } from '@pretto/app/src/overview/config/variants'
import { formatLocaleValues } from '@pretto/app/src/overview/lib/formatLocaleValues'
import type { Row } from '@pretto/app/src/overview/types/row'

import get from 'lodash/get'

const MAX_PAYMENT_FACTOR = 1.02

export const formatLoanRows = (data: unknown, onEdit: (path: string) => void): Row[] =>
  LOAN.reduce<Row[]>((previous, path) => {
    const value = get(data, path)

    if (!value) {
      return previous
    }

    const description = getValue(DESCRIPTION, path)
    const slug = path.split('.').pop()
    const title = getValue(DETAILS, path)
    const variant = getValue(VARIANTS, path)

    const defaultRow = {
      description,
      path,
      title,
      variant,
    }

    switch (path) {
      case 'best_result.financing.loanDuration': {
        const duration = value / MONTHS_IN_YEAR

        return [
          ...previous,
          {
            ...defaultRow,
            onAction() {
              onEdit('loan_duration')
            },
            get value() {
              if (Math.floor(duration) === duration) {
                return `${duration} ans`
              }

              return `${value} mois`
            },
          },
        ]
      }

      case 'best_result.financing.payment': {
        const fromValue = Math.floor(value / 10) * 10
        const toValue = Math.ceil((value * MAX_PAYMENT_FACTOR) / 10) * 10

        return [
          ...previous,
          {
            ...defaultRow,
            onAction() {
              if (slug) {
                onEdit(slug)
              }
            },
            value: formatLocaleValues(fromValue.toString(), toValue.toString()),
          },
        ]
      }

      default:
        return [
          ...previous,
          {
            ...defaultRow,
            value: formatLocaleValues(value),
          },
        ]
    }
  }, [])
