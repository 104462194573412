import { TextField } from '@pretto/zen/atoms/textFields/TextField/TextField'

import PropTypes from 'prop-types'

export const BlocNumberField = ({ name, type, value, onUpdateValue, fieldProps: { inputProps, ...fieldProps } }) => {
  const handleChange = value => {
    onUpdateValue(name, value)
  }

  return (
    <>
      <TextField
        {...fieldProps}
        inputProps={{ ...inputProps, type }}
        onChange={handleChange}
        value={value?.toString() ?? ''}
        size="large"
      />
    </>
  )
}

BlocNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['integer', 'number']).isRequired,
  value: PropTypes.string,
  onUpdateValue: PropTypes.func.isRequired,
  fieldProps: PropTypes.object.isRequired,
}
