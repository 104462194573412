import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { CheckHandwrite, ExclamationMarkCircleBold, Picto } from '@pretto/picto'

import { ReactNode } from 'react'

import * as S from './Snackbar.styles'

export interface SnackbarProps {
  children: ReactNode
  icon?: Picto
  in: boolean
  status?: 'success' | 'alert'
}

const VARIANTS = {
  alert: {
    backgroundColor: 'error4',
    color: 'error1',
    picto: ExclamationMarkCircleBold,
  },
  success: {
    backgroundColor: 'primary4',
    color: 'primary1',
    picto: CheckHandwrite,
  },
}

export const Snackbar = ({ children, icon, in: inProp, status = 'success', ...props }: SnackbarProps) => {
  const { backgroundColor, color, picto } = VARIANTS[status]

  return (
    <S.Container {...props}>
      <CollapsibleRow animateIn in={inProp}>
        <S.Content $color={color} $backgroundColor={backgroundColor}>
          <S.Picto $status={status} $picto={icon || picto} />
          <S.Text>{children}</S.Text>
        </S.Content>
      </CollapsibleRow>
    </S.Container>
  )
}
