import Link from '@pretto/bricks/website/utility/Link'

import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'

import { getIsCertificateActive } from '@pretto/app/src/Application/lib/getIsCertificateActive'
import DOCS from '@pretto/config/docs.json'
import {
  Bank,
  Burden,
  Check,
  Clock,
  Crown,
  ExclamationMarkTriangle,
  Folder,
  House,
  TwoPeople,
  UserCircle,
} from '@pretto/picto'

import url from 'url'

import { getDocs } from '../Containers/ApplicationPage/utils'

const cards = [
  ({ docs }, userContext, advisorContext, themeContext, trackAction) => {
    const handleClick = () => {
      trackAction('APPLICATION_NOTICE_EVENT', {
        application_notice_event_origin: 'inner_link',
        application_notice_event_type: 'click',
        application_notice_type: 'invalid_documents',
      })
    }

    return (
      docs.some(document => convertStatusSlug(document.document_status) === 'invalid') && {
        cardType: 'notice',
        children: (
          <>
            Certains de vos documents sont invalides.{' '}
            <Link href="/application/documents?status=invalid" onClick={handleClick}>
              Consulter
            </Link>
          </>
        ),
        picto: () => ExclamationMarkTriangle,
        type: 'error',
      }
    )
  },
  ({ deal: { certificate }, docs, project }, { hasFeatureAccess }) => {
    const filteredDocs = docs.filter(({ kind }) => DOCS[kind].certificate === true && kind !== 'other')
    const isCertificateDocumentPending = filteredDocs.some(({ document_status }) => document_status !== 'valid')
    const isCertificateDocumentInvalid = filteredDocs.some(
      ({ document_status }) => document_status === 'invalid' || document_status === 'empty'
    )

    return (
      project.project_kind !== 'renegotiation' &&
      project.purchase.maturity !== 'sale_agreement_signed' &&
      !certificate &&
      !isCertificateDocumentInvalid &&
      isCertificateDocumentPending &&
      !hasFeatureAccess(['BUYOUT']) && {
        cardType: 'notice',
        children: <>Vos documents de demande d’attestation de financement sont en cours de vérification.</>,
        picto: () => Clock,
        type: 'info',
      }
    )
  },
  ({ deal: { certificate }, docs, project }, { hasFeatureAccess, role }, { onOpen }, themeContext, trackAction) => {
    const filteredDocs = docs.filter(({ kind }) => DOCS[kind].certificate === true && kind !== 'other')
    const isCertificateDocumentValid = filteredDocs.every(({ document_status }) => document_status === 'valid')

    const handleClick = () => {
      onOpen()

      trackAction('APPLICATION_NOTICE_EVENT', {
        application_notice_event_origin: 'inner_button',
        application_notice_event_type: 'click',
        application_notice_type: 'certificate_documents_approved',
      })
    }

    return (
      project.project_kind !== 'renegotiation' &&
      project.purchase.maturity !== 'sale_agreement_signed' &&
      !certificate &&
      isCertificateDocumentValid &&
      !hasFeatureAccess(['BUYOUT']) && {
        cardType: 'notice',
        children: (
          <>
            Vos documents de demande d’attestation sont validés.
            <br />
            <button onClick={handleClick}>
              Demander l’attestation à mon {role === 'expert' ? 'expert' : 'conseiller'} Pretto
            </button>
          </>
        ),
        picto: () => Check,
        type: 'success',
      }
    )
  },
  ({ deal: { certificate }, project }, { hasFeatureAccess }, advisorContext, { colors }) =>
    project.project_kind !== 'renegotiation' &&
    project.purchase.maturity !== 'sale_agreement_signed' &&
    certificate &&
    !hasFeatureAccess(['BUYOUT']) && {
      cardType: 'bannerFolder',
      description:
        'Votre attestation de financement est disponible ! Ce document vous permet d’attester de votre capacité à financer le bien que vous convoitez.',
      href: url.resolve('https://api.pretto.fr', certificate ?? ''),
      imageUrl: 'v1637684805/zen/static-assets/illustrations/660x660/label.svg',
      onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
        trackAction('APPLICATION_BANNER_FOLDER_CLICKED', {
          application_banner_folder_type: 'download_certificate',
        })
      },
      style: { backgroundColor: colors.primary4 },
      title: 'Téléchargez votre attestation de financement',
    },
  (data, { hasFeatureAccess }) => {
    const { docs } = data
    const { numberUploaded: current, maxDocs: target } = getDocs({
      docs,
      type: 'certificate',
    })

    const isCertificateActive = getIsCertificateActive(data)

    return (
      isCertificateActive &&
      !hasFeatureAccess(['BUYOUT']) && {
        cardType: 'bannerFolder',
        description:
          'Pour certifier votre capacité d’emprunt, nous avons besoin de certains documents. Cette attestation permet de certifier aux vendeurs que vous êtes bien finançable. De quoi mettre toutes les chances de votre côté pour vos visites !',
        documentProgress: { current, target },
        href: '/application/documents?category=certificate',
        imageUrl: 'v1637683136/zen/static-assets/illustrations/660x660/womanHoldingUpAsign.svg',
        onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
          trackAction('APPLICATION_BANNER_FOLDER_CLICKED', {
            application_banner_folder_type: 'certificate_documents_requested',
          })
        },
        title: 'Obtenez une attestation de financement',
      }
    )
  },
  {
    description: 'Vos informations personnelles et professionnelles',
    href: '/application/mortgagor',
    id: 'mortgagor',
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'mortgagor' })
    },
    picto: () => UserCircle,
    title: (props, step, data, { accounts }) => accounts[0].name,
  },
  ({
    project: {
      profile: { mortgagors },
    },
  }) =>
    mortgagors.length > 1 && {
      description: 'Ses informations personnelles et professionnelles',
      href: '/application/comortgagor',
      id: 'comortgagor',
      onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
        trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'comortgagor' })
      },
      picto: () => UserCircle,
      title: (props, step, data, { accounts }) => accounts[1].name,
    },
  {
    description: 'Informations sur votre situation familiale et maritale',
    href: '/application/general',
    id: 'general',
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'general' })
    },
    picto: () => TwoPeople,
    title: 'Foyer',
  },
  {
    description: (props, step, { project: { project_kind } }) =>
      project_kind === 'purchase'
        ? 'Remplissez les infos concernant votre futur bien.'
        : 'Remplissez les infos concernant le crédit à renégocier',
    href: (props, step, { project: { project_kind } }) => `/application/${project_kind}`,
    id: (props, step, { project: { project_kind } }) => project_kind,
    onClick:
      (props, step, { project: { project_kind } }, userContext, advisorContext, themeContext, trackAction) =>
      () => {
        trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: project_kind })
      },
    picto: () => House,
    title: 'Projet',
  },
  {
    description: 'Relevé de comptes et banques dont vous êtes déjà client',
    href: '/application/banks',
    id: 'banks',
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'banks' })
    },
    picto: () => Bank,
    title: 'Comptes',
  },
  {
    description: 'Vos comptes d’épargne, actions, PEL, assurance vie etc.',
    href: '/application/estate',
    id: 'estate',
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'estate' })
    },
    picto: () => Crown,
    title: 'Épargne & Patrimoine',
  },
  {
    description: (props, step, { project: { project_kind } }) =>
      `${
        project_kind === 'renegotiation'
          ? "Avez-vous d'autres crédits que vous continuerez à rembourser ?"
          : 'Avez-vous des crédits que vous continuerez à rembourser ?'
      }`,
    href: '/application/credits',
    id: 'credits',
    isActive: (
      props,
      step,
      {
        project: {
          facts: { bridge_credit },
          profile: { credits },
        },
      }
    ) => bridge_credit !== null || credits.length > 0,
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'credits' })
    },
    picto: () => Burden,
    title: (props, step, { project: { project_kind } }) =>
      `${project_kind === 'renegotiation' ? 'Autres crédits en cours' : 'Crédits en cours'}`,
  },
  {
    cardType: 'block',
    description: (
      <>
        Envoyez ici <u>vos documents justificatifs</u> en toute sécurité.
      </>
    ),
    href: '/application/documents',
    id: 'documents',
    onClick: (props, step, data, userContext, advisorContext, themeContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'documents' })
    },
    picto: () => Folder,
    title: 'Documents',
  },
]

export default cards
