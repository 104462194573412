import { ARROW_DOWN, ARROW_UP, END, ENTER, ESCAPE, HOME, SPACE, TAB } from '@pretto/bricks/core/constants/keycodes'

import { MenuHeadProps } from '@pretto/zen/main/menus/MenuHead/MenuHead'
import { ButtonOrLinkProps, MenuItemProps } from '@pretto/zen/main/menus/MenuItem/MenuItem'
import { MenuItemExpertProps } from '@pretto/zen/main/menus/MenuItemExpert/MenuItemExpert'

import { Fragment, KeyboardEvent, MouseEvent, useEffect, useRef } from 'react'

import * as S from './Menu.styles'

type MenuItemsProps = { type?: string; items: Array<MenuItemProps & ButtonOrLinkProps> }

type FormattedMenuItemsProps = { baseIndex: number; type?: string; items: Array<MenuItemProps & ButtonOrLinkProps> }

type FormattedItemsList = { menuItems: FormattedMenuItemsProps[]; itemsCount: number }

type FocusProps = {
  accountKeyDown: number
  accountKeyDownChecker: number
  defineAccountKeyDown: (value: number | null) => void
  defineMenuFocusIndex: (value: number | null) => void
  focusAccount: () => void
  menuFocusIndex: number
}

export interface MenuProps {
  focusProps: FocusProps
  isOpen: boolean
  menuHeadProps: MenuHeadProps
  menuItemExpertProps: MenuItemExpertProps
  menuItems: MenuItemsProps[]
  onClose: (event: MouseEvent | KeyboardEvent) => void
}

export const Menu = ({ focusProps, isOpen, menuHeadProps, menuItemExpertProps, menuItems, onClose }: MenuProps) => {
  const {
    accountKeyDown,
    accountKeyDownChecker,
    defineAccountKeyDown,
    defineMenuFocusIndex,
    focusAccount,
    menuFocusIndex,
  } = focusProps
  const itemsList = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    if (menuFocusIndex === null) return
    if (menuFocusIndex >= 0 && itemsList.current[menuFocusIndex]) {
      itemsList.current[menuFocusIndex].focus()
    }
  }, [menuFocusIndex])

  useEffect(() => {
    if (isOpen && accountKeyDown && itemsList.current.length > 0) {
      switch (accountKeyDown) {
        case SPACE:
        case ENTER:
        case ARROW_DOWN:
        case TAB:
          defineMenuFocusIndex(indexMin)
          break
        case ARROW_UP:
          defineMenuFocusIndex(indexMax)
          break
        default:
          break
      }
    }
  }, [accountKeyDownChecker])

  const indexMin = 0
  const indexMax =
    menuItems.reduce((previous: number, current: MenuItemsProps) => previous + current.items.length, 0) - 1

  const nextTab = () => {
    const nextIndex = menuFocusIndex + 1
    if (nextIndex > indexMax) {
      defineMenuFocusIndex(indexMin)
      return
    }
    defineMenuFocusIndex(nextIndex)
  }

  const previousTab = () => {
    const previousIndex = menuFocusIndex - 1
    if (previousIndex < indexMin) {
      defineMenuFocusIndex(indexMax)
      return
    }
    defineMenuFocusIndex(previousIndex)
  }

  const handleItemRef = (itemsCount: number, node: HTMLInputElement) => {
    itemsList.current[itemsCount] = node
  }

  const handleItemKeyDown = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case TAB:
        e.preventDefault()
        if (e.shiftKey) {
          previousTab()
          break
        }
        nextTab()
        break

      case ARROW_DOWN:
        nextTab()
        break

      case ARROW_UP:
        previousTab()
        break

      case ESCAPE:
        defineAccountKeyDown(null)
        defineMenuFocusIndex(null)
        focusAccount()
        onClose(e)
        break

      case HOME:
        defineMenuFocusIndex(indexMin)
        break

      case END:
        onClose(e)
        defineMenuFocusIndex(indexMax)
        break

      default:
    }
  }

  const formattedMenuItems = menuItems.reduce(
    (previous: FormattedItemsList, current: MenuItemsProps) => {
      const itemsCount = previous.itemsCount + current.items.length
      return {
        menuItems: [...previous.menuItems, { ...current, baseIndex: previous.itemsCount }],
        itemsCount,
      }
    },
    { menuItems: [], itemsCount: 0 }
  )

  return (
    <S.MenuContainer from="right" hasOverlay isOpen={isOpen} onClickOutside={onClose}>
      <S.Drawer>
        <S.MenuHead {...menuHeadProps} />
        <ul aria-labelledby="menubutton" id="menu" role="menu">
          {formattedMenuItems.menuItems.map(({ items, type, baseIndex }, i) => (
            <Fragment key={i}>
              {menuItemExpertProps && type === 'expert' && <S.MenuItemExpert {...menuItemExpertProps} />}

              {items.map((item, j) => (
                <li key={j} role="none">
                  <S.MenuItem
                    onKeyDown={handleItemKeyDown}
                    ref={handleItemRef.bind(null, baseIndex + j)}
                    role="menuitem"
                    {...item}
                  />
                </li>
              ))}

              {i < menuItems.length - 1 && <S.Divider />}
            </Fragment>
          ))}
        </ul>
      </S.Drawer>
    </S.MenuContainer>
  )
}
