import { g } from '@pretto/bricks/components/layout'

import { Bell, ExclamationMarkCircle, ExclamationMarkTriangle, Picto as PictoType, Toast } from '@pretto/picto'

import styled, { css } from 'styled-components'

type Type = 'success' | 'error' | 'warning' | 'simple' | 'neutral'

const pictos: Record<Type, PictoType | null> = {
  error: ExclamationMarkCircle,
  warning: ExclamationMarkTriangle,
  success: Toast,
  simple: null,
  neutral: Bell,
}

export interface BannerProps {
  type: Type
}

export const Banner: React.FC<BannerProps & React.HTMLAttributes<HTMLDivElement>> = ({ children, type, ...props }) => {
  const pictoComponent = pictos[type]

  return (
    <Container {...props} $type={type}>
      {pictoComponent && <Picto as={pictoComponent} />}
      <span>{children}</span>
    </Container>
  )
}

interface ContainerProps {
  $type: Type
}

const Container = styled.div<ContainerProps>`
  ${({ theme }) => theme.typos.body4};
  border-radius: ${g(1)};
  display: flex;
  gap: ${g(2)};
  padding: ${g(2)} ${g(3)} ${g(2)} ${({ $type }) => g($type === 'simple' ? 3 : 2)};

  a,
  button {
    appearance: none;
    border: none;
    color: inherit;
    font: inherit;
    text-decoration: underline;
  }

  button:enabled {
    cursor: pointer;
  }

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }

  ${({ $type, theme }) => {
    switch ($type) {
      case 'error':
        return css`
          background-color: ${theme.colors.error2};
          color: ${theme.colors.error1};
        `

      case 'warning':
        return css`
          background-color: ${theme.colors.warning2};
          color: ${theme.colors.warning1};
        `

      case 'success':
        return css`
          background-color: ${theme.colors.primary4};
          color: ${theme.colors.primary1};
        `

      default:
        return css`
          background-color: ${theme.colors.neutral5};
          color: ${theme.colors.neutral1};
        `
    }
  }}
`

const Picto = styled.svg`
  flex-shrink: 0;
  height: ${g(3)};
`
