import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Cancel = styled.div`
  margin-right: ${g(1)};
`
