// extracted by mini-css-extract-plugin
export var swipe = "FqUuDiwQvWG8GuN9Vgfn";
export var swipeDots = "qURmlNcpcZhBTNEa_hXQ";
export var swipeDotsCenter = "X4Yus94FhivvjocW9RLy";
export var swipeDotsLeft = "WH7sak_G2ak8fPw62o2k";
export var swipeDotsRight = "iu4vsLZtTix8a8w0m65z";
export var swipeFull = "ZTT58QeifK8PUbNBgZAi";
export var swipeFullCenter = "kGzLYeOP69aQnD7j2RQG";
export var swipeFullLeft = "_AtkV9gHJwH9lGCQ8hZ4";
export var swipeFullRight = "Sp4n8YXGlXg1vG1gH_Kl";
export var swipeView = "hvzD4I5VPKirh6NuE1pk";
export var swipe__slide = "FkBI9JBxfYEyylC8APpq";
export var view = "SYKkkGGPUZ8qkFDBLn1w";
export var viewContent = "KJhChqZT3ukuR27e5vsA";