import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as S from './styles'

const COLOR = {
  error: 'error1',
  success: 'primary1',
}

const Label = ({ children, isHighlighted, isValue }) => {
  const variant = isHighlighted ? 'accent-1' : isValue ? 'neutral-1' : 'neutral-1-60'
  if (isHighlighted) {
    return (
      <SubHeading size="small" variant={variant}>
        {children}
      </SubHeading>
    )
  }

  return <Text variant={variant}>{children}</Text>
}
Label.propTypes = {
  children: PropTypes.node.isRequired,
  isHighlighted: PropTypes.bool,
  isValue: PropTypes.bool,
}

const PreLeadCardRows = ({ items }) => {
  return (
    <div>
      {items.map(({ label, value, status }, i) => {
        const color = COLOR[status] || 'primary1'
        return (
          <S.Row key={i}>
            {label} : <S.Value $valueColor={color}>{value}</S.Value>
          </S.Row>
        )
      })}
    </div>
  )
}

PreLeadCardRows.propTypes = {
  /** List of rows. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.node,
      valueColor: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default PreLeadCardRows
