import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Footer = styled.footer`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
  }
`

export const Progress = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-end;
    flex-basis: ${100 / 3}%;
    margin-left: auto;
    margin-top: 0;
    max-width: ${100 / 3}%;
  }
`

export const Bar = styled.div`
  flex-grow: 1;
  margin-left: ${g(2)};
`

export const Icon = styled.div`
  margin-right: ${g(1)};
`
