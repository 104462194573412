import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import { linkUnderline12 } from '@pretto/bricks/core/typography'

import { ButtonAdd } from '@pretto/zen/atoms/buttons/ButtonAdd/ButtonAdd'
import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { Chips } from '@pretto/zen/atoms/infos/Chips/Chips'
import { TextField } from '@pretto/zen/atoms/textFields/TextField/TextField'

import PropTypes from 'prop-types'
import { Fragment } from 'react'
import styled from 'styled-components'

export const Banks = ({
  banks,
  isOpen,
  onChangeSearch,
  onClickBank,
  onClear,
  onClose,
  onOpen,
  onRemove,
  search,
  selectedBanks,
}) => (
  <div>
    <Button onClick={onOpen}>Ajouter une banque</Button>

    {selectedBanks.map(({ label, value }) => (
      <BankChips key={value} onRemove={onRemove.bind(null, value)} isRemovable>
        {label}
      </BankChips>
    ))}

    <DialogModal isOpen={isOpen} onRequestClose={onClose}>
      <DialogContainer>
        <DialogHead>
          <Search
            onChange={onChangeSearch}
            value={search}
            onClear={onClear}
            inputProps={{
              autoFocus: true,
              placeholder: 'Chercher une banque...',
            }}
          />
          <Cancel onClick={onClose}>Annuler</Cancel>
        </DialogHead>

        <BanksList>
          {banks.map(({ label, value }, i) => {
            return (
              <Fragment key={value}>
                {i > 0 && <Divider />}
                <Bank onClick={onClickBank.bind(null, value)}>{label}</Bank>
              </Fragment>
            )
          })}
        </BanksList>
      </DialogContainer>
    </DialogModal>
  </div>
)

Banks.propTypes = {
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onClickBank: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  selectedBanks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const Button = styled(ButtonAdd)`
  margin-bottom: ${g(2)};
`
const BankChips = styled(Chips)`
  display: inline-flex;
  margin-right: ${g(1)};
  margin-bottom: ${g(1)};
`
const DialogModal = styled(Dialog)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 80vh;
  }
`
const DialogContainer = styled.div`
  ${grid()};
`
const DialogHead = styled.div`
  ${column([2, -2])};
  padding: ${g(2)} 0;
  display: flex;
  align-items: center;
`
const Cancel = styled.div`
  ${linkUnderline12};
  margin-left: ${g(3)};
`
const BanksList = styled.ul`
  ${column([2, -2])};
`
const Bank = styled.li`
  padding: ${g(2)} 0;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary1};
  }
`
const Search = styled(TextField)`
  flex-grow: 1;
`
