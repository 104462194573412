import { g } from '@pretto/bricks/components/layout'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'

import styled from 'styled-components'

export const Divider = styled(DividerComponent)`
  margin-top: ${g(3)};
`

export const DividerWithMarginBottom = styled(Divider)`
  margin-bottom: ${g(3)};
`
