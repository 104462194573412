import PropTypes from 'prop-types'

import { getPicturePath } from '../../Helpers/pictureUrl'
import AdvisorAvatarComponent from '../../SharedComponents/AdvisorAvatar'
import { useUser } from '../../User/Containers/UserProvider'

const AdvisorAvatar = props => {
  const { advisor } = useUser()

  return <AdvisorAvatarComponent {...props} avatarSrc={getPicturePath(advisor)} />
}

AdvisorAvatar.propTypes = {
  size: PropTypes.string,
}

export default AdvisorAvatar
