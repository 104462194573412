import * as S from './SecondaryButton.styles'

export type Scheme = 'green' | 'black'

export type SecondaryButtonProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  disabled?: boolean
  href?: string
  loading?: boolean
  scheme?: Scheme
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  onClick,
  scheme = 'green',
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    onClick?.(event)
  }

  const stateProps = {
    $isDisabled: disabled || loading,
    $isLoading: loading,
  }

  return (
    <S.Container $scheme={scheme} {...stateProps} {...props} onClick={handleClick}>
      <S.Label {...stateProps}>{children}</S.Label>
      <S.Loader {...stateProps} />
    </S.Container>
  )
}
