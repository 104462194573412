import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const SliderSection = styled.div`
  background-color: ${({ theme }) => theme.colors.primary2};
  position: relative;
  padding-top: ${g(4)};
`
export const Title = styled.div`
  ${typo.headline16};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${g(2)};
  margin-left: ${g(2, 4)};
`
export const Cards = styled.ul`
  display: flex;
  overflow-x: scroll;
  padding: 0px ${g(2, 4)} ${g(4)} ${g(2, 4)};

  & > * + * {
    margin-left: ${g(3)};
  }
`
