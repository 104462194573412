import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const removeCSS = css`
  margin-bottom: ${g(2)};
`

export const Container = styled.div`
  ${({ $type }) => $type === 'remove' && removeCSS};
  ${({ $type }) =>
    ($type === 'remove' || $type === 'add') &&
    css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        margin-left: ${g(3)};
        margin-right: ${g(3)};
      }

      @media screen and (min-width: ${breakpoints.laptop}) {
        margin-left: ${g(-4)};
        margin-right: 0;
      }
    `}

  ${({ $previousType, $type }) =>
    $previousType === 'sentence' &&
    $type === 'validation' &&
    css`
      margin-top: ${g(1)};
    `}
`
