import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

const getBackgroundColor = ({ $activeVariant, $inactiveVariant, $isActive, theme }) => {
  if ($isActive) {
    return theme.colors[$activeVariant]
  }

  return theme.colors[$inactiveVariant]
}

const getPosition = ({ $position }) => {
  switch ($position) {
    case 'right':
      return 'flex-end'

    case 'left':
      return 'flex-start'

    default:
      return $position
  }
}

export const Dots = styled.div`
  display: flex;
  justify-content: ${getPosition};
`

export const Dot = styled.div`
  background-color: ${getBackgroundColor};
  border-radius: 50%;
  cursor: pointer;
  height: ${g(1)};
  margin: 0 ${g(0.5)};
  width: ${g(1)};
`
