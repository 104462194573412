import { useBreakpoint } from '@pretto/bricks/assets/utility'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import Link from '@pretto/bricks/website/utility/Link'

import { REDUCE_AMBITION_TYPES } from '@pretto/app/src/Simulation/Containers/OptionsPage/config/reduceAmbition'
import { t } from '@pretto/app/src/lib/i18n'

import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import * as S from './Main.styles'
import { TIP_BUBBLE } from './config/tipBubble'

export const Main = ({ optionButtonsGroupProps: { options, selected }, onHelpClick, onOptionClick, onResultClick }) => {
  const { isMobile } = useBreakpoint()

  return (
    <S.Container>
      <S.Title>{t('reduceAmbitionPage.title')}</S.Title>
      <S.Description>
        <Trans
          i18nKey="reduceAmbitionPage.description"
          components={{
            CapacityLink: <LinkInline href="/capacity" />,
          }}
        />
      </S.Description>
      <S.SectionChoices>
        <S.SectionTitle>{t('reduceAmbitionPage.tweakProject')}</S.SectionTitle>
        {options.map((option, index) => (
          <S.Options
            key={index}
            {...option}
            inputLabel={isMobile ? option.inputLabelMobile : option.inputLabel}
            onClick={onOptionClick}
            isSelected={selected === option.inputValue}
          />
        ))}
        <S.Separator />
        <S.Bubble>{TIP_BUBBLE[selected]}</S.Bubble>
      </S.SectionChoices>
      <Responsive max="laptop">
        <S.Help onClick={onHelpClick} />
      </Responsive>
      <Responsive min="laptop">
        <S.Results onClick={onResultClick}>{t('reduceAmbitionPage.resultsButton')}</S.Results>
      </Responsive>
    </S.Container>
  )
}

const LinkInline = styled(Link)`
  text-decoration: underline;
`

Main.propTypes = {
  contactProps: PropTypes.object,
  onHelpClick: PropTypes.func,
  onOptionClick: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  optionButtonsGroupProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        firstValueProps: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        inputLabel: PropTypes.string.isRequired,
        inputLabelMobile: PropTypes.string.isRequired,
        inputValue: PropTypes.string.isRequired,
        secondValueProps: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
    selected: PropTypes.oneOf(Object.values(REDUCE_AMBITION_TYPES)),
  }),
}
