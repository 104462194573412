import { OccupationPublicStatus } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapPublicStatus: MapToChoices<OccupationPublicStatus[]> = (values, context, onSetActive) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]

  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[0].status === value,
    onClick: () => {
      clonedMortgagors[0].status = value
      setContext({ mortgagors: clonedMortgagors })
      if (value === OccupationPublicStatus.Contractor && onSetActive) {
        onSetActive(2)
      }
    },
    label: t(`sentences.situation.public.values.${value}`),
  }))
}
