import erf from 'math-erf'
import erfinv from 'math-erfinv'
import rebound from 'rebound'

// A remainder operator that also works for negative values, which
// is true in most programming language
export const mod = (n, m) => ((n % m) + m) % m

// https://en.wikipedia.org/wiki/Log-normal_distribution
export const logNormalDistribution = (mean, std) => ({
  cdf(x) {
    return (1 / 2) * (1 + erf((Math.log(x) - Math.log(this.mean)) / (Math.sqrt(2) * this.std)))
  },

  icdf(x) {
    return Math.exp(this.std * Math.sqrt(2) * erfinv(2 * x - 1) + Math.log(this.mean))
  },

  mean,
  std,
})

const approx = (fn, value, decimals = 0.1) => fn(value * 10 ** decimals) / 10 ** decimals

export const ceil = approx.bind(null, Math.ceil)
export const floor = approx.bind(null, Math.floor)
export const round = approx.bind(null, Math.round)

export const mapValueInRange = rebound.MathUtil.mapValueInRange
