import { ResizableField } from '@pretto/app/src/Sentences/v2/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/v2/lib/useCityField'
import { Result as CountryResult, useCountryField } from '@pretto/app/src/Sentences/v2/lib/useCountryField'
import { LocationType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useState } from 'react'

export const AddressPage: React.FC = () => {
  const context = useSentences()
  const { mortgagors, setContext, usage } = context
  const clonedMortgagors = structuredClone(mortgagors)
  const comortgagor = clonedMortgagors?.[1] ?? {}

  const { nextRoute } = useRoutesGraph()
  const defaultValue = comortgagor.city && comortgagor.zipcode ? `${comortgagor.city} (${comortgagor.zipcode})` : ''
  const { fieldProps: cityFieldProps, results: cityResults } = useCityField(defaultValue)
  const { fieldProps: countryFieldProps, results: countryResults } = useCountryField(comortgagor.country)
  const [visibleCities, setVisibleCities] = useState(false)
  const [visibleCountries, setVisibleCountries] = useState(false)
  const livesInFrance = comortgagor.country === 'fr'
  const isRentalInvestment = usage === Usage.Rental

  const getDefaultActiveField = () => {
    if (isNil(comortgagor.country)) {
      return 0
    }
    if (livesInFrance) {
      return 1
    }
    return 2
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(comortgagor.country)) return false
    if (livesInFrance && !comortgagor.city) return false
    if (!livesInFrance && !comortgagor.country) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const handleClickOnCity = (value: CityResult['value']) => {
    comortgagor.city = value.city
    comortgagor.zipcode = value.zipcode
    cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
    setVisibleCities(false)
    setContext({ mortgagors: clonedMortgagors })
  }

  const handleCountryOnClick = (value: CountryResult) => {
    comortgagor.country = value.value
    const label = value.label.replace(/\s\(.*\)/, '')
    comortgagor.countryLabel = label
    countryFieldProps.onChange(label)
    setVisibleCountries(false)
    setContext({ mortgagors: clonedMortgagors })
  }

  const mapResidentValues: MapToChoices<LocationType[]> = values =>
    values.map(value => ({
      key: value,
      isSelected:
        !isNil(comortgagor.country) &&
        ((value === 'france' && comortgagor.country === 'fr') || (value === 'abroad' && comortgagor.country !== 'fr')),
      onClick: () => {
        setVisibleCities(true)
        comortgagor.country = value === 'france' ? 'fr' : 'abroad'
        setContext({ mortgagors: clonedMortgagors })
        setActiveField(value === 'france' ? 1 : 2)
      },
      label: t(`sentences.lodging.address.values.${value}`),
    }))

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected: comortgagor.city === value.city && comortgagor.zipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  const mapCountryValues: MapToChoices<CountryResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: comortgagor.country === value,
      onClick: () => handleCountryOnClick({ label, value }),
      label,
    }))

  const fields = [
    {
      name: 'resident',
      value: comortgagor.country,
      values: mapResidentValues(Object.values(LocationType)),
    },
    {
      name: 'city',
      value: comortgagor.city,
      values: mapCityValues(cityResults),
    },
    {
      name: 'country',
      value: comortgagor.country,
      values: mapCountryValues(countryResults),
    },
  ]

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'address-comortgagor' } }}>
      <Title>{t('sentences.lodging.comortgagor.title')}</Title>
      <div>
        {t('sentences.lodging.comortgagor.address.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!comortgagor.country}
        >
          {t(`sentences.lodging.address.values.${!comortgagor.country || livesInFrance ? 'france' : 'abroad'}`)}
        </Prompt>
        {activeField >= 1 && (
          <>
            {t(`sentences.lodging.address.sentence.${livesInFrance ? '1' : '2'}`)}
            {livesInFrance ? (
              <ResizableField
                {...cityFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: 'Paris' }}
                onClick={() => {
                  setVisibleCities(true)
                  setActiveField(1)
                }}
              />
            ) : (
              <ResizableField
                {...countryFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: t('sentences.lodging.countryPlaceholder') }}
                onClick={() => {
                  setVisibleCountries(true)
                  setActiveField(2)
                }}
              />
            )}
          </>
        )}
      </div>
      {(activeField === 0 || (visibleCities && activeField === 1) || (visibleCountries && activeField === 2)) && (
        <Choices
          choices={fields[activeField].values}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'comortgagorLodgingAddress' },
          }}
        />
      )}
      {!isNil(comortgagor.country) && !livesInFrance && <Message>{t('sentences.tips.nonResident.currency')}</Message>}
      {!isNil(comortgagor.country) && !livesInFrance && isRentalInvestment && (
        <Message>{t('sentences.tips.nonResident.rentalInvestment')}</Message>
      )}
      {((activeField >= 1 && livesInFrance && comortgagor.city) ||
        (activeField >= 2 && !livesInFrance && comortgagor.country)) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
