import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const RatingScale = ({ value, min, max, options, onChange }) => {
  const scale = options || []
  if (!options)
    for (let i = min; i <= max; i++) {
      scale.push(i)
    }

  return (
    <S.Scale>
      {scale.map(rate => {
        const handleChange = () => onChange(rate)
        const selected = !options ? rate : rate.value
        const label = !options ? rate : rate.label
        return (
          <S.Rate key={selected} onClick={handleChange} isSelected={selected === value}>
            {label}
          </S.Rate>
        )
      })}
    </S.Scale>
  )
}
RatingScale.defaultProps = {
  max: 5,
  min: 1,
  onClick: () => {},
}

RatingScale.propTypes = {
  /** Maximum value of scale. */
  max: PropTypes.number,
  /** Minimum value of scale. */
  min: PropTypes.number,
  /** RatingScale onChange function. */
  onChange: PropTypes.func,
  /** Custom options for scale */
  options: PropTypes.array,
  /** RatingScale value. */
  value: PropTypes.node,
}

export default memo(RatingScale)
