import { mapContractTypeValues } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/lib/mappers/mapContractType'
import { mapMonths } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/lib/mappers/mapMonths'
import { mapPublicStatus } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/lib/mappers/mapPublicStatus'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { Months, OccupationContractType, OccupationPublicStatus } from '@pretto/app/src/Sentences/v2/types/enums'

interface PublicChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicChoices: React.FC<PublicChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors } = context

  return (
    <>
      {activeField === 1 && (
        <Choices
          choices={mapPublicStatus(Object.values(OccupationPublicStatus), context, onSetActive)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPublicStatus' },
          }}
        />
      )}
      {activeField === 2 && (
        <Choices
          choices={mapContractTypeValues(Object.values(OccupationContractType), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPublicContractType' },
          }}
          onChoiceClick={() => {
            onSetActive(3)
          }}
        />
      )}
      {activeField === 3 && mortgagors?.[1].contractType === 'shortTerm' && (
        <Choices
          choices={mapMonths(Object.values(Months), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPublicShortTerm' },
          }}
          onChoiceClick={() => {
            onSetActive(4)
          }}
        />
      )}
    </>
  )
}
