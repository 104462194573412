import SponsorshipHighlight from '@pretto/bricks/app/sponsorship/components/SponsorshipHighlight'
import SponsorshipInvite from '@pretto/bricks/app/sponsorship/components/SponsorshipInvite'
import SponsorshipShareOptions from '@pretto/bricks/app/sponsorship/components/SponsorshipShareOptions'
import SponsorshipSponsorCard from '@pretto/bricks/app/sponsorship/components/SponsorshipSponsorCard'
import SponsorshipConditionsPage from '@pretto/bricks/app/sponsorship/pages/SponsorshipConditionsPage'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import Responsive, { Desktop } from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import * as S from './styles'

const SponsorshipPage = ({
  fields,
  hasError,
  hasMore,
  headline,
  highlights,
  invitations,
  isConditionsDialogOpen,
  isDisabled,
  isInviteDialogOpen,
  isMutating,
  onConditionsDialogClose,
  onConditionsDialogOpen,
  onInviteDialogClose,
  onInviteDialogOpen,
  onMore,
  onSubmit,
  sponsorshipShareProps,
}) => (
  <div>
    <S.Header>
      <Wrapper>
        <Desktop>
          <S.HeaderTitle>
            <S.HeaderTitleEmoji>
              <Emoji size="medium">:sunglasses:</Emoji>
            </S.HeaderTitleEmoji>

            <Heading>Parrainez vos proches</Heading>
          </S.HeaderTitle>
        </Desktop>

        <S.HeaderDescription>
          {nl2br(headline)} Voir les <span onClick={onConditionsDialogOpen}>conditions générales</span>.
        </S.HeaderDescription>

        <S.Highlights>
          {highlights.map((highlight, index) => (
            <S.Highlight key={index}>
              <SponsorshipHighlight {...highlight} />
            </S.Highlight>
          ))}
        </S.Highlights>
      </Wrapper>
    </S.Header>

    <Wrapper>
      <S.Content>
        <S.Invitation>
          <CollapsibleRow appear in={hasError}>
            <S.Error>
              <Card variant="error-l">
                <Content>
                  <Text variant="error">Désolé cette personne a déjà un dossier chez Pretto.</Text>
                </Content>
              </Card>
            </S.Error>
          </CollapsibleRow>

          <Responsive max="tablet">
            <S.Form>
              {fields.map(({ component, key }) => (
                <S.Field key={key}>{component}</S.Field>
              ))}

              <S.FormButton>
                <ButtonLegacy
                  disabled={isDisabled || isMutating}
                  iconProps={isMutating ? { loading: isMutating } : null}
                  onClick={onSubmit}
                >
                  {!isMutating && 'Inviter'}
                </ButtonLegacy>
              </S.FormButton>
            </S.Form>
          </Responsive>

          <Responsive min="tablet">
            <S.InviteTrigger>
              <SponsorshipInvite onClick={onInviteDialogOpen} />
            </S.InviteTrigger>

            <Dialog isOpen={isInviteDialogOpen} onRequestClose={onInviteDialogClose}>
              <DialogTemplate
                buttonProps={{
                  children: 'Inviter',
                  isDisabled: isDisabled || isMutating,
                  isLoading: isMutating,
                  onClick: onSubmit,
                }}
                navbarProps={{
                  iconLeft: TimesBold,
                  iconLeftAction: onInviteDialogClose,
                  title: 'Inviter un proche',
                }}
              >
                <Content>
                  {fields.map(({ component, key }) => (
                    <S.Field key={key}>{component}</S.Field>
                  ))}
                </Content>
              </DialogTemplate>
            </Dialog>
          </Responsive>

          <SponsorshipShareOptions {...sponsorshipShareProps} version="b" />
        </S.Invitation>

        {invitations.length > 0 && (
          <S.Invited>
            <S.InvitedTitle>
              <SubHeading>Vous avez déjà invité...</SubHeading>
            </S.InvitedTitle>

            <Card format="line" variant="neutral-1-20">
              {invitations.map((invitation, index) => (
                <div key={index}>
                  <Content>
                    <SponsorshipSponsorCard {...invitation} key={index} version="b" />
                  </Content>

                  {index < invitations.length - 1 && <Divider />}
                </div>
              ))}
            </Card>

            {hasMore && (
              <S.More>
                <S.MoreButton onClick={onMore}>
                  <Text size="small" variant="accent-1">
                    Voir plus
                  </Text>
                </S.MoreButton>
              </S.More>
            )}
          </S.Invited>
        )}
      </S.Content>
    </Wrapper>

    <Dialog isOpen={isConditionsDialogOpen} onRequestClose={onConditionsDialogClose}>
      <DialogTemplate
        navbarProps={{
          iconLeft: TimesBold,
          iconLeftAction: onConditionsDialogClose,
          title: 'Conditions générales',
        }}
      >
        <SponsorshipConditionsPage />
      </DialogTemplate>
    </Dialog>
  </div>
)

SponsorshipPage.propTypes = {
  fields: PropTypes.array.isRequired,
  hasError: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  highlights: PropTypes.array.isRequired,
  invitations: PropTypes.array.isRequired,
  isConditionsDialogOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isInviteDialogOpen: PropTypes.bool.isRequired,
  isMutating: PropTypes.bool.isRequired,
  onConditionsDialogClose: PropTypes.func.isRequired,
  onConditionsDialogOpen: PropTypes.func.isRequired,
  onInviteDialogClose: PropTypes.func.isRequired,
  onInviteDialogOpen: PropTypes.func.isRequired,
  onMore: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sponsorshipShareProps: PropTypes.object.isRequired,
}

export default SponsorshipPage
