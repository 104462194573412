import { useBreakpoint } from '@pretto/bricks/assets/utility'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { BlockTextareaProps } from '@pretto/app/src/purchaseOffer/config/types'

import * as S from './BlockTextarea.styles'

export const BlockTextarea = ({
  isOptionnal,
  label,
  picto,
  textareaProps,
  tooltip,
}: BlockTextareaProps): JSX.Element => {
  const { breakpoint } = useBreakpoint()
  // FIXME: Type possibly undefined by hook definition
  const isLarge = ['laptop', 'desktop'].includes(breakpoint as string)

  return (
    <S.Block>
      <S.Label>
        <Responsive min="tablet">
          <S.Picto $picto={picto} />
        </Responsive>
        <S.LabelText>{label}</S.LabelText>
        {isOptionnal && (
          <>
            <Responsive max="tablet">
              <S.TagSmall $tooltip={tooltip}>facultatif</S.TagSmall>
            </Responsive>
            <Responsive min="tablet">
              <S.Tag $tooltip={tooltip}>Facultatif</S.Tag>
            </Responsive>
          </>
        )}
        {tooltip && (
          <S.Tooltip content={tooltip} position={isLarge ? 'right' : 'left'}>
            <S.ToolTipPicto />
          </S.Tooltip>
        )}
      </S.Label>
      <S.Textarea {...textareaProps} />
    </S.Block>
  )
}
