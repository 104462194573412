import CardComponent from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const Card = styled(CardComponent).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(3)};
`

export const Description = styled(BaseTypo)`
  margin-bottom: ${g(3)};
`

export const Infos = styled.div`
  display: grid;
  grid-gap: ${g(3)};
  grid-template-columns: 35% 65%;
  margin-bottom: ${g(3)};

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`

export const Localisation = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`

export const Icon = styled.svg`
  margin-right: ${g(1)};
`

export const Filters = styled(Localisation)`
  margin-bottom: ${g(5)};
`
