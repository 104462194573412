import Divider from '@pretto/bricks/components/dividers/Divider'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'

import * as C from './Timeline.module.css'

const TimelineLegacy = ({ steps, current, active }) => {
  return (
    <div>
      {steps.map((step, index) => {
        const stepPosition = index === current ? 'current' : index < current ? 'before' : 'after'

        return (
          <div
            key={index}
            className={classnames(
              C.timelineStep,
              C[`timelineStep${pascalcase(stepPosition ?? '')}`],
              C[`timelineStep${pascalcase(step.format ?? '')}`],
              {
                [C.timelineStepActive]: active === index,
              }
            )}
          >
            {step.format !== 'title' && (
              <div className={classnames(C.timelineStepDot, { [C.timelineStepDotActive]: index - 1 < active })} />
            )}
            <div className={C.timelineStepContent}>{step.children}</div>
            {step.format === 'title' && (
              <div className={C.timelineStepDivider}>
                <Divider />
              </div>
            )}
            <div className={classnames(C.timelineStepLine, { [C.timelineStepLineActive]: index < active })} />
          </div>
        )
      })}
    </div>
  )
}

TimelineLegacy.propTypes = {
  /** Timeline steps. Must be an array with object children, as follow: */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node,
      format: PropTypes.string,
      variant: PropTypes.string,
    })
  ).isRequired,
  /** Current step of timeline. */
  current: PropTypes.number,
  /** Active step of timeline. A step can be both active and current, but active can't be higher. */
  active: PropTypes.number,
}

TimelineLegacy.defaultProps = {
  current: 0,
}

export default TimelineLegacy
