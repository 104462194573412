interface AddSeparatorToNumberString {
  value: string
  decimalSeparator: string
  thousandSeparator: string
}

export const addSeparatorToNumberString = ({
  value,
  decimalSeparator,
  thousandSeparator,
}: AddSeparatorToNumberString): string =>
  value.replace(
    new RegExp(`^([\\d]+)(\\.[\\d]*)?$`),
    (match, integer, decimal = '') =>
      `${integer
        .replace(new RegExp(thousandSeparator, 'g'), '')
        .split('')
        .reduceRight((previous: string, digit: string, index: number, digits: string) => {
          const absoluteIndex = digits.length - index - 1

          if (absoluteIndex > 0 && absoluteIndex % 3 === 0) {
            return `${digit}${thousandSeparator}${previous}`
          }

          return `${digit}${previous}`
        }, '')}${decimal.replace(/^\./, decimalSeparator)}`
  )
