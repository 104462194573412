import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'

import DOCS from '@pretto/config/docs.json'

export const getDocumentsForFilters = <
  T extends {
    document_status: string
    kind: keyof typeof DOCS
    meta?: {
      bank?: string | null
      month?: number | null
      mortgagor?: number | null
      year?: number | null
    } | null
    slug: string
    title: string
  },
  F extends {
    [K: string]: string
  },
  P extends {
    profile: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mortgagors: any[]
    }
  },
>(
  filters: F,
  { docs, project }: { docs: T[]; project: P }
) =>
  Object.entries(filters).reduce((previous, [filterKey, filterValue]) => {
    if (!filterValue || filterValue === 'all') {
      return previous
    }

    switch (filterKey) {
      case 'category':
        switch (filterValue) {
          case 'certificate':
          case 'appointment':
            return previous.filter(({ kind }) => {
              const doc = { appointment: false, ...DOCS[kind] }

              return doc[filterValue]
            })

          case 'general_0':
          case 'general_1': {
            const [, mortgagorIndex] = filterValue.split('_')

            return previous.filter(
              ({ meta, kind }) =>
                DOCS[kind].category === 'general' &&
                (meta?.mortgagor === null || meta?.mortgagor?.toString() === mortgagorIndex)
            )
          }

          default:
            return previous.filter(({ kind }) => DOCS[kind].category === filterValue)
        }

      case 'mortgagor':
        if (!project.profile.mortgagors[1]) {
          return previous
        }

        switch (filterValue) {
          case 'none':
            return []

          case 'both':
            return previous

          default: {
            return previous.filter(
              ({ meta }) =>
                meta?.mortgagor === null ||
                (filterValue === 'comortgagor' && meta?.mortgagor === 1) ||
                (filterValue === 'mortgagor' && meta?.mortgagor === 0)
            )
          }
        }

      case 'status':
        return previous.filter(({ document_status }) => convertStatusSlug(document_status) === filterValue)

      case 'missing':
        if (filterValue === '0') {
          return previous
        }

        return previous.filter(({ document_status }) =>
          ['idle', 'invalid'].includes(convertStatusSlug(document_status))
        )

      default:
        return previous
    }
  }, docs)
