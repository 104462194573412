import { mapBonusPeriodValues } from '@pretto/app/src/Sentences/v2/ComortgagorIncome/Bonus/lib/mappers/mapBonusPeriod'
import { mapBonusTypeValues } from '@pretto/app/src/Sentences/v2/ComortgagorIncome/Bonus/lib/mappers/mapBonusType'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { IncomePeriod, IncomeType, YesNo } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const BonusPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}, {}]

  const { nextRoute, goToNextRoute } = useRoutesGraph()

  const hasBonus = clonedMortgagors[1].hasBonus

  const setHasBonus = (value: boolean) => {
    clonedMortgagors[1].hasBonus = value
    setContext({ mortgagors: clonedMortgagors })
  }

  const getDefaultActiveField = () => {
    if (!hasBonus) return 0
    if (!mortgagors?.[1].bonus?.period) return 1
    if (!mortgagors?.[1].bonus?.type) return 2
    return 3
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(hasBonus)) return false
    if (hasBonus && (!mortgagors?.[0].bonus?.period || !mortgagors?.[0].bonus?.type)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  if (!mortgagors || !mortgagors.length) {
    return null
  }

  const saveAmount = (value: string) => {
    if (!clonedMortgagors[1].bonus) {
      clonedMortgagors[1].bonus = { amount: parseInt(value) }
    } else {
      clonedMortgagors[1].bonus.amount = parseInt(value)
    }
    setContext({
      mortgagors: clonedMortgagors,
    })
  }

  const mapHasBonus = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: (value === 'yes' && hasBonus) || (value === 'no' && !hasBonus && !isNil(hasBonus)),
        onClick: () => {
          setHasBonus(value === 'yes')
          setActiveField(value === 'yes' ? 1 : 0)
          if (value === 'no') goToNextRoute()
        },
        label: t(`sentences.income.comortgagor.bonus.values.${value as 'yes' | 'no'}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'bonus-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.title')}</Title>
      <div>
        {t('sentences.income.comortgagor.bonus.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(hasBonus)}>
          {t(`sentences.income.comortgagor.bonus.values.${hasBonus || isNil(hasBonus) ? 'yes' : 'no'}`)}
        </Prompt>
        {hasBonus && activeField > 0 && (
          <Prompt onClick={() => setActiveField(1)} isFilled={!!mortgagors[1].bonus?.period}>
            {t(`sentences.income.bonus.period.${mortgagors[1].bonus?.period || 'year'}`)}
          </Prompt>
        )}
        {hasBonus && activeField > 1 && (
          <Prompt onClick={() => setActiveField(2)} isFilled={!!mortgagors[1].bonus?.type}>
            {t(`sentences.income.bonus.type.${mortgagors[1].bonus?.type || 'net'}`)}
          </Prompt>
        )}
        {hasBonus && activeField > 2 && (
          <>
            {t('sentences.income.comortgagor.bonus.sentence.1')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorIncomeBonusAmount' },
              }}
              inputProps={{ autoFocus: activeField === 3, placeholder: '500', type: 'integer' }}
              onChange={saveAmount}
              value={mortgagors[1].bonus?.amount?.toString() || ''}
            />
            {t('sentences.income.comortgagor.bonus.sentence.2')}
          </>
        )}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasBonus(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeBonus' } }}
        />
      )}
      {hasBonus && activeField === 1 && (
        <Choices
          choices={mapBonusPeriodValues(Object.values(IncomePeriod), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomePeriod' } }}
          onChoiceClick={() => setActiveField(2)}
        />
      )}
      {hasBonus && activeField === 2 && (
        <Choices
          choices={mapBonusTypeValues(Object.values(IncomeType), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeType' } }}
          onChoiceClick={() => setActiveField(3)}
        />
      )}
      {hasBonus && <Message>{t('sentences.tips.income.bonus.mortgagor')}</Message>}
      {!isNil(hasBonus) && ((hasBonus && activeField >= 3) || !hasBonus) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
