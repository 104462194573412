import DashboardSecondaryCard from '@pretto/bricks/app/dashboard/components/DashboardSecondaryCard'
import MainSectionFolder from '@pretto/bricks/app/dashboard/components/MainSectionFolder'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import temporal from '@pretto/bricks/core/utility/temporal'

import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getUserEmailVerified } from '@pretto/app/src/Auth/lib/helpers'
import Front from '@pretto/app/src/Helpers/frontchat'
import { getPicturePath } from '@pretto/app/src/Helpers/pictureUrl'
import ServiceFAQ from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceFAQ'
import ServiceGuide from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceGuide'
import ServiceNewsletter from '@pretto/app/src/PreLead/Containers/ServiceCards/ServiceNewsletter'
import { getIsPriceSubmitted } from '@pretto/app/src/SharedContainers/Header/lib/getIsPriceSubmitted'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { cardsByStatus } from '@pretto/app/src/dashboard/config/cardsByStatus'
import { Step, timeline } from '@pretto/app/src/dashboard/config/timeline'
import { AdvisorModal } from '@pretto/app/src/dashboard/containers/advisor/AdvisorModal'
import { MaturityModal } from '@pretto/app/src/dashboard/containers/maturity/MaturityModal'
import { ProjectModalProvider } from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import { signStatus } from '@pretto/app/src/dashboard/lib/signStatus'
import type { DialogOptions } from '@pretto/app/src/dashboard/types/card'
import { Dashboard } from '@pretto/app/src/dashboard/views/Dashboard'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import { StatusEnum } from '@pretto/app/src/types/gateway/enums'

import { createElement, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { useDashboardQuery } from './dashboard.gateway.graphql'

export const DashboardPage = () => {
  const { requestSignIn, signOut } = useAuth()

  const { replace } = useHistory()

  const { data, loading } = useDashboardQuery()

  const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [hasConnectionError, setHasConnectionError] = useState(false)

  const trackAction = useTracking()

  const trustpilotData = useTrustpilotData()

  const userContext = useUser()

  useLoading(loading)

  useEffect(() => {
    const { status, lostReason } = userContext

    if (status === 'end') {
      trackAction('WIN_DASHBOARD_PAGE_VIEWED')
    }

    if (lostReason) {
      trackAction('LOST_DASHBOARD_PAGE_VIEWED', {
        lostReason,
        dashboardOptions: status,
      })
    }
  }, [])

  if (loading) {
    return null
  }

  if (!data) {
    throw new Error('Missing data')
  }

  const handleCheckEmail = async () => {
    const { email } = userContext.user

    setIsConnecting(true)

    try {
      await requestSignIn(email, {
        redirectUri: `${process.env.CLIENT_HOST}/login?redirect=${encodeURI('/mandate')}`,
        template: 'espace_client',
      })

      signOut(() => replace(`/email-verification-sent?email=${encodeURI(email)}`))
    } catch (error) {
      setHasConnectionError(true)
      Front('open')
    } finally {
      setIsConnecting(false)
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const { accounts, hasComortgagor } = userContext

  const emailVerified = getUserEmailVerified()
  const status = signStatus({ accounts, hasComortgagor, mandateStatus: data?.deal?.mandateStatus })
  const isSepaSigned = data.deal?.sepaStatus?.signed
  const isMandateSigned = ['SOLO_SIGNED', 'DUO_SIGNED', 'DUO_AWAITING_OTHER'].includes(status)
  const isSepaSignable = !isSepaSigned && isMandateSigned

  if (emailVerified && isSepaSignable) return <Redirect to="/mandate/sepa" />

  const tStatus = ensureNotNil(data?.deal?.status)
  const cards = cardsByStatus[tStatus]

  const cardsComponents = cards.reduce<React.ReactNode[]>((previous, card, cardIndex) => {
    const { component: Component, condition, name } = card

    const cardCondition = condition?.(data, userContext) ?? true

    const handleActionClick = (actionUrl: string) => {
      trackAction(`DASHBOARD_${name.toUpperCase()}_CARD_CLICKED`, {
        step_url_destination: actionUrl,
      })
    }

    const handleDialogRequest = (dialogOptions: DialogOptions) => {
      if (dialogOptions) {
        setDialogOptions(dialogOptions)
      }

      setIsDialogOpen(true)
    }

    if (!cardCondition) {
      return previous
    }

    const defaultComponent = previous.length === 0 ? MainSectionFolder : DashboardSecondaryCard

    return [
      ...previous,
      <div key={cardIndex}>
        <Component
          defaultComponent={defaultComponent}
          dashboardData={data}
          onActionClick={handleActionClick}
          onDialogRequest={handleDialogRequest}
        />
      </div>,
    ]
  }, [])

  const advisor = {
    name: userContext.advisor.name,
    picturePath: getPicturePath(userContext.advisor),
  }
  const showStepsAndReviews = ![
    StatusEnum.End,
    StatusEnum.LostHardClient,
    StatusEnum.LostOneChoice,
    StatusEnum.LostDoubleChoice,
  ].includes(userContext.status)

  const numberOfReviewsWhereKindIsExpert =
    data?.advisor?.reviews?.filter(review => review.kind === 'expert').length || 0

  const reviewsProps = showStepsAndReviews && {
    advisor:
      userContext.advisor.role === 'expert'
        ? {
            name: userContext.advisor.name,
            imagePath: userContext.advisor.largePicturePath,
          }
        : undefined,
    description:
      userContext.advisor.role === 'expert' && numberOfReviewsWhereKindIsExpert >= 5 ? (
        <>
          Voici les avis des clients accompagnés par <strong>{userContext.advisor.name}</strong>.
        </>
      ) : (
        <>Voici les avis des clients accompagnés par Pretto</>
      ),
    reviews: data?.advisor?.reviews?.map(({ consumerName, description: text, postedDate, stars, title }) => ({
      consumer: { displayName: consumerName },
      createdAt: temporal(postedDate, 'YYYY-MM-DD'),
      text,
      stars,
      title,
    })),
    title: 'Votre dossier est entre de bonnes mains !',
    trustpilotRateProps: trustpilotData,
  }

  const steps = showStepsAndReviews
    ? timeline.reduce<Partial<Step>[]>((previous, step) => {
        const { condition, ...properties } = Object.entries(step).reduce<Partial<Step>>(
          (previous, [property, value]) => ({
            ...previous,
            [property]: funcToItem(value, userContext, data.deal?.mandateStatus),
          }),
          {}
        )

        if (condition === false) {
          return previous
        }

        return [...previous, properties]
      }, [])
    : []

  const servicesCards = showStepsAndReviews ? [ServiceGuide, ServiceFAQ] : [ServiceNewsletter, ServiceFAQ]

  const pageProps = {
    cards: cardsComponents,
    reviewsProps,
    username: userContext.user.firstName,
    steps: userContext.hasFeatureAccess(['BUYOUT']) ? [] : steps,
    services: servicesCards.map((serviceCard, i) => {
      return createElement(serviceCard, { key: i })
    }),
    activeIndex: steps.findIndex(({ isCurrent }) => isCurrent),
  }

  const isPriceSubmitted = getIsPriceSubmitted({ data })

  const viewProps = {
    isPriceSubmitted,
    pageProps,
    isDialogOpen,
    dialogOptions,
    hasConnectionError,
    isConnecting,
    onCheckEmail: handleCheckEmail,
    onCloseDialog: handleCloseDialog,
  }

  return (
    <ProjectModalProvider>
      <MaturityModal />
      {userContext.advisor.role === 'expert' && !userContext.next_booking && (
        <AdvisorModal
          isOpen={userContext.isAdvisorUnlocked}
          onClose={userContext.handleCloseAdvisorUnlocked}
          advisor={advisor}
          href="/booking"
        />
      )}
      <Dashboard {...viewProps} />
    </ProjectModalProvider>
  )
}
