import { useEffect, useRef } from 'react'

export const useClickOutside = callback => {
  const ref = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const handleClickOutside = e => {
    const domNode = ref.current

    if (!domNode || !domNode.contains(e.target)) {
      callback()
    }
  }

  return ref
}
