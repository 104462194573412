import { g, layoutBase } from '@pretto/bricks/components/layout'
import { btoa } from '@pretto/bricks/core/utility/btoa'

import uniqueId from 'lodash/uniqueId'
import styled, { css, keyframes } from 'styled-components'

const LAYOUT_FACTORS = {
  large: 4,
  medium: 3,
  small: 2,
  'x-large': 5,
}

const REFERENCE_SIZE = LAYOUT_FACTORS.medium * layoutBase

const getSVGForType = (type, ID) => {
  switch (type) {
    case 'triangle':
      return `<path d="M10.3026304,2.03400233 C11.0858611,0.655332556 12.910941,0.655332556 13.6941717,2.03400233 L23.6928396,19.6340023 C24.5252887,21.0993079 23.5677308,23 21.9970689,23 L1.99973315,23 C0.429071328,23 -0.528486633,21.0993079 0.30396247,19.6340023 L10.3026304,2.03400233 Z" id="${ID}" />`

    case 'circle':
      return `<circle cx="12" cy="12" id="${ID}" r="12" />`

    case 'square':
      return `<rect height="24" id="${ID}" width="24" x="0" y="0" rx="2" ry="2" />`

    default:
      return ''
  }
}

const applyBackgroundForShape =
  ({
    theme: {
      colors,
      legacy: { colors: legacyColors },
    },
  }) =>
  ({ backgroundVariant, formatType, shapeType, size, variant }) => {
    const ID = uniqueId(shapeType.slice(0, 1))

    const layoutFactor = LAYOUT_FACTORS[size]
    const computedSize = layoutFactor * layoutBase

    const color = colors[variant] || legacyColors[variant]
    const fill = formatType === 'line' ? backgroundVariant ?? colors.white : color

    const reference = getSVGForType(shapeType, ID)

    return css`
      background-image: url('data:image/svg+xml;base64,${btoa(
        `<svg height="${computedSize}" viewBox="0 0 24 24" width="${computedSize}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs>${reference}<clipPath id="${ID}_c"><use xlink:href="#${ID}"/></clipPath></defs><use xlink:href="#${ID}" stroke="${color}" stroke-width="${
          (REFERENCE_SIZE * 2) / computedSize
        }" fill="${fill}" clip-path="url('#${ID}_c')"/></svg>`
      )}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: ${g(layoutFactor)} ${g(layoutFactor)};
    `
  }

const glow = keyframes`
  from {
    opacity: 0.5;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(1.5);
  }
`

export const Badge = styled.div`
  height: ${({ size }) => g(LAYOUT_FACTORS[size])};
  position: relative;
  width: ${({ size }) => g(LAYOUT_FACTORS[size])};
  z-index: 0;

  ${({ isGlowing, shapeType, ...props }) =>
    isGlowing &&
    css`
      &:after {
        animation: ${glow} 2s infinite;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.3;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: center ${shapeType === 'triangle' ? `${(2 / 3) * 100}%` : 'center'};
        z-index: -2;

        ${applyBackgroundForShape({ ...props, formatType: 'default', shapeType })}
      }
    `}
`

export const Content = styled.div`
  align-items: center;
  color: ${({ formatType, theme, variant }) => {
    const colorKey = formatType === 'line' ? variant : 'white'
    const color = theme.colors[colorKey] || theme.legacy.colors[colorKey]
    return color
  }};
  display: flex;
  font-size: ${g(1.5)};
  height: ${({ size }) => g(LAYOUT_FACTORS[size])};
  justify-content: center;
  line-height: ${g(3)};
  width: ${({ size }) => g(LAYOUT_FACTORS[size])};

  ${applyBackgroundForShape}

  ${({ shapeType, size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: ${g(1)};
          line-height: ${g(2)};
        `

      case 'large':
        return css`
          line-height: ${g(4)};

          ${shapeType === 'triangle' &&
          css`
            padding-top: 3px;
          `}
        `

      case 'medium':
        return (
          shapeType === 'triangle' &&
          css`
            padding-top: 1px;
          `
        )

      default:
        return ''
    }
  }}
`
