import TextDivider from '@pretto/bricks/components/dividers/TextDivider'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import { ButtonLink } from '@pretto/zen/atoms/buttons/ButtonLink/ButtonLink'

import BookingWidget from '@pretto/app/src/Booking/Containers/BookingWidget'

import styled from 'styled-components'

const Container = styled.div`
  margin-top: ${g(3)};
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

const Divider = styled(TextDivider)`
  margin: ${g(2)} 0;
`

const SimulatorCTA = styled(ButtonLink)`
  background-color: transparent;
  margin: auto;
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }
`

interface CalendarProps {
  onBooked: (duration: unknown, date: string) => void
  onBooking: () => void
  onSkipIntoSimulation: () => void
}

export const Calendar: React.FC<CalendarProps> = ({ onBooked, onBooking, onSkipIntoSimulation }) => {
  return (
    <Container>
      <BookingWidget onBooked={onBooked} onBooking={onBooking} />
      <Divider label="ou" />
      <SimulatorCTA onClick={onSkipIntoSimulation}>Démarrer une simulation</SimulatorCTA>
    </Container>
  )
}
