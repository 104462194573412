import { SliderSection } from '@pretto/bricks/app/pre-lead/components/SliderSection'
import { Title as SliderServiceTitle } from '@pretto/bricks/app/pre-lead/components/SliderSection/styles'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import * as typo from '@pretto/bricks/core/typography'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { Card as ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard.styles'
import { AvisBloc as AvisBlocComponent } from '@pretto/zen/reveal/main/avisBloc/AvisBloc/AvisBloc'

import styled, { css } from 'styled-components'

export const HeaderCard = styled(Wrapper).attrs({ full: 'tablet' })`
  margin-top: ${g(2)};
`

export const Card = styled.div`
  & + & {
    margin-top: ${g(1)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: ${g(3)};
    }
  }
`
export const Header = styled.header`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
    padding-top: ${g(5)};
  }
`

export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    ${({ $hasPaddingBottom }) =>
      $hasPaddingBottom &&
      css`
        padding-bottom: ${g(9)};
      `};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    background-image: url(${getCloudinaryUrl('v1628172668/bricks/static-assets/city.svg')}),
      linear-gradient(
        ${({ theme }) => theme.colors.neutral4} 0px,
        ${({ theme }) => theme.colors.neutral4} 400px,
        transparent 400px,
        transparent 100%
      );

    background-repeat: no-repeat;
    background-size:
      auto 215px,
      auto;
    background-position:
      center 185px,
      center top;
  }
`

export const TitleEmoji = styled.div`
  margin-right: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(3)};
  }
`

export const AvisBloc = styled(AvisBlocComponent)`
  margin-top: ${g(8)};
`

export const Main = styled.div`
  padding-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(3)};
  }
`

export const Services = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    gap: ${g(3)};

    & > * {
      min-height: ${g(39)};
      flex-basis: 50%;
    }
  }
`

export const ServicesTitle = styled.h2`
  text-align: center;
  ${typo.headline16};
  margin: ${g(6)} 0;
`

export const ServicesMobile = styled(SliderSection)`
  background-color: ${({ theme }) => theme.colors.white};

  ${ServiceCard} {
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
  }

  ${SliderServiceTitle} {
    color: ${({ theme }) => theme.colors.neutral1};
  }
`
