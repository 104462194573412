import ApplicationListField from '@pretto/bricks/app/application/components/ApplicationListField'

import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const DIALOG_MODE_ADD = 'DIALOG_MODE_ADD'
const DIALOG_MODE_EDIT = 'DIALOG_MODE_EDIT'
const DIALOG_MODE_CLOSE = 'DIALOG_MODE_CLOSE'

export const ListField = ({
  disclaimer,
  formatContent,
  formatTitle,
  isFormDisabled,
  onChange,
  renderFields,
  staticItems,
  isDeletable,
  isEditable,
  value,
}) => {
  const [changingValueIndex, setChangingValueIndex] = useState(null)
  const [dialogMode, setDialogMode] = useState(DIALOG_MODE_CLOSE)
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState(value)
  const [temporaryValues, setTemporaryValues] = useState(value)

  useEffect(() => onChange(values), [values])

  const handleAdd = () => {
    setTemporaryValues(cloneDeep(values))
    setChangingValueIndex(values.length)
    setDialogMode(DIALOG_MODE_ADD)
    setValues(values => [...values, { id: uuidv4() }])
  }

  const handleChange = (name, value, error) => {
    setErrors(errors => ({ ...errors, [name]: error }))
    setValues(values => [
      ...values.slice(0, changingValueIndex),
      { ...values[changingValueIndex], [name]: value },
      ...values.slice(changingValueIndex + 1),
    ])
  }

  const handleClose = () => {
    setDialogMode(DIALOG_MODE_CLOSE)
    setValues(temporaryValues)
  }

  const handleDelete = index => () => {
    const value = values[index]

    if (value.id) {
      setValues(values => [...values.slice(0, index), { ...value, _delete: true }, ...values.slice(index + 1)])
      return
    }

    setValues(values => [...values.slice(0, index), ...values.slice(index + 1)])
  }

  const handleEdit = index => () => {
    setErrors({})
    setTemporaryValues(cloneDeep(values))
    setChangingValueIndex(index)
    setDialogMode(DIALOG_MODE_EDIT)
  }

  const handleValidate = () => {
    setDialogMode(DIALOG_MODE_CLOSE)
  }

  const items = values.reduce((previous, { _delete, id, ...item }, index) => {
    if (_delete) {
      return previous
    }

    return [
      ...previous,
      {
        content: formatContent(item, index),
        id,
        isErrored: isFormDisabled(values[index] || {}, errors),
        onDelete: (!isEditable || (isDeletable && isDeletable(item))) && handleDelete(index),
        onEdit: (!isEditable || (isEditable && isEditable(item))) && handleEdit(index),
        title: formatTitle(item, index).toString(),
      },
    ]
  }, staticItems)

  return (
    <ApplicationListField
      addLabel="Ajouter"
      dialogTitle={dialogMode === DIALOG_MODE_ADD ? 'Ajouter' : 'Modifier'}
      disclaimer={disclaimer}
      fields={renderFields(values[changingValueIndex] || {}, handleChange)}
      isDialogOpen={dialogMode !== DIALOG_MODE_CLOSE}
      isDialogSubmitDisabled={isFormDisabled(values[changingValueIndex] || {}, errors)}
      items={items}
      onAdd={handleAdd}
      onDialogClose={handleClose}
      onDialogValidate={handleValidate}
    />
  )
}

ListField.defaultProps = {
  staticItems: [],
}

ListField.propTypes = {
  disclaimer: PropTypes.object,
  formatContent: PropTypes.func.isRequired,
  formatTitle: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderFields: PropTypes.func.isRequired,
  staticItems: PropTypes.array,
  value: PropTypes.array.isRequired,
  isEditable: PropTypes.func,
  isDeletable: PropTypes.func,
}
