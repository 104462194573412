import { MONTHS_IN_YEAR } from '../../lib/constants'

const MIN_AGE_OWNER = 25
const MAX_AGE_OWNER = 80
const MIN_AGE_DEFAULT = 25
const MAX_AGE_DEFAULT = 75
const MIN_INCOME = 1300
const MAX_ZIPCODE = 95

const checkEvery = (func, items) => items.every(func)
const checkSome = (func, items) => items.some(func)

const checkHousing = ({ housing }) => !['sheltered'].includes(housing)
const checkContract = ({ contract }) =>
  !['farmer', 'fixed_term', 'casual_show_business_worker', 'contractor', 'internship', 'jobless'].includes(contract)

const checkAge = ({ age, housing }) => {
  if (housing === 'owner' && age >= MIN_AGE_OWNER && age <= MAX_AGE_OWNER) return true
  return age >= MIN_AGE_DEFAULT && age <= MAX_AGE_DEFAULT
}
const checkIncome = income => income / MONTHS_IN_YEAR > MIN_INCOME
const checkAddress = ({ country, zipcode }) => {
  if (country !== 'fr') return false
  return parseInt(zipcode.slice(0, 2)) <= MAX_ZIPCODE
}
const checkLocation = mortgagor => checkAddress(mortgagor.address)
const locationEligibility = ({ project_kind, good, profile: { mortgagors } }) => {
  if (project_kind === 'renegotiation' && good.usage === 'primary_residence') {
    return checkAddress(good.localisation)
  }
  return checkEvery(checkLocation, mortgagors)
}

const creditsEligibility = project => {
  const { credits } = project.profile
  return credits.length >= 1
}
const housingEligibility = project => checkEvery(checkHousing, project.profile.mortgagors)
const contractEligibility = project => checkSome(checkContract, project.profile.mortgagors)
const ageEligibility = project => checkEvery(checkAge, project.profile.mortgagors)
const incomeEligibility = project => checkSome(checkIncome, project.facts.raw_incomes)

const regroupCredits = project =>
  [
    creditsEligibility,
    housingEligibility,
    contractEligibility,
    ageEligibility,
    incomeEligibility,
    locationEligibility,
  ].every(func => func(project))

export default regroupCredits
