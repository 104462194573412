import Button from '@pretto/bricks/components/buttons/Button'
import ButtonBack from '@pretto/bricks/components/buttons/ButtonBack'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Column, column, Grid as GridComponent } from '@pretto/bricks/components/layout/Grid'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { ChevronLeftBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styled from 'styled-components'

const PreviousNextNavBar = forwardRef(
  (
    {
      isNextDisabled,
      isNextLoading,
      isPreviousDisabled,
      btnNextComponent,
      nextLabel,
      onPreviousClick,
      onNextClick,
      btnNextProps,
      ...props
    },
    ref
  ) => (
    <Grid {...props} ref={ref}>
      <Container>
        {onPreviousClick && (
          <>
            <Responsive max="mobileM">
              <ButtonBackSmall isDisabled={isPreviousDisabled} onClick={onPreviousClick}>
                <ChevronLeftBold />
              </ButtonBackSmall>
            </Responsive>

            <Responsive min="mobileM">
              <ButtonBack isArrowVisible isDisabled={isPreviousDisabled} onClick={onPreviousClick}>
                Retour
              </ButtonBack>
            </Responsive>
          </>
        )}

        <NextContainer>
          {btnNextComponent ? (
            btnNextComponent
          ) : (
            <Button
              {...btnNextProps}
              isArrowShown
              isLoading={isNextLoading}
              isDisabled={isNextDisabled}
              onClick={onNextClick}
            >
              {nextLabel}
            </Button>
          )}
        </NextContainer>
      </Container>
    </Grid>
  )
)
PreviousNextNavBar.propTypes = {
  btnNextComponent: PropTypes.element,
  isNextDisabled: PropTypes.bool,
  isNextLoading: PropTypes.bool,
  isPreviousDisabled: PropTypes.bool,
  nextLabel: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  btnNextProps: PropTypes.object,
}

PreviousNextNavBar.defaultProps = {
  nextLabel: 'Suivant',
}

PreviousNextNavBar.displayName = 'PreviousNextNavBar'

const Grid = styled(GridComponent)`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: ${g(2)} 0;
`

const ButtonBackSmall = styled(Button)`
  background-color: ${({ theme }) => theme.colors.neutral4};
  color: ${({ theme }) => theme.colors.neutral1};
`

const Container = styled(Column).attrs({ position: [2, 4] })`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

const NextContainer = styled.div`
  margin-left: auto;
`

export default PreviousNextNavBar
