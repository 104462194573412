import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'

import PropTypes from 'prop-types'
import { Children } from 'react'

const CardList = ({ children, variant }) => {
  return (
    <Card format="line" variant={variant}>
      {Children.toArray(children).reduce((previous, child, index) => {
        if (index === children.length - 1) {
          return [...previous, child]
        }

        return [...previous, child, <Divider key={`divider-${index}`} variant={variant} />]
      }, [])}
    </Card>
  )
}

CardList.defaultProps = {
  variant: 'neutral-1-l',
}

CardList.propTypes = {
  /** Children as array. */
  children: PropTypes.array.isRequired,
  /** Color variant of the card and dividers. */
  variant: PropTypes.string,
}

export default CardList
