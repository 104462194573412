import { ADVISOR_LABELS, type AdvisorLabelsType } from '@pretto/app/src/config/advisor'
import { t } from '@pretto/app/src/lib/i18n'

import { upperFirst } from 'lodash'
import { Trans } from 'react-i18next'

import * as S from './Subscribe.styles'
import { Form, FormProps } from './components/Form/Form'
import { Hero, HeroProps } from './components/Hero/Hero'

interface SubscribeProps extends HeroProps, FormProps {
  advisorLabel: AdvisorLabelsType
  hasForm: boolean
  onSubmit: () => void
  isLoading: boolean
}

export const Subscribe: React.FC<SubscribeProps> = ({
  advisorLabel = ADVISOR_LABELS.expert,
  consentProps,
  hasForm,
  onModify,
  selectedSlot,
  switchVisio,
  firstNameFieldComponent,
  lastNameFieldComponent,
  emailComponent,
  phoneFieldComponent,
  onSubmit,
  isLoading,
}) => {
  const name = `${selectedSlot?.advisor.firstName} ${selectedSlot?.advisor.lastName}`
  return (
    <>
      <S.Header>
        <S.Picture
          src={selectedSlot?.advisor.pictureLink || 'v1637684805/zen/static-assets/illustrations/660x660/headBulbe.svg'}
        />
        <S.Description>
          <Trans
            i18nKey="mutualizedAgenda.subscribe.description"
            values={{ advisorLabel: upperFirst(advisorLabel), name }}
          />
        </S.Description>
      </S.Header>
      <S.Content>
        <Hero onModify={onModify} selectedSlot={selectedSlot} switchVisio={switchVisio} />
        {hasForm && (
          <Form
            firstNameFieldComponent={firstNameFieldComponent}
            lastNameFieldComponent={lastNameFieldComponent}
            emailComponent={emailComponent}
            phoneFieldComponent={phoneFieldComponent}
            consentProps={consentProps}
          />
        )}
        <S.Confirm isLoading={isLoading} onClick={onSubmit}>
          {t('mutualizedAgenda.subscribe.confirm')}
        </S.Confirm>
      </S.Content>
    </>
  )
}
