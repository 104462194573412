import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Badge = styled.div`
  margin-right: ${g(2)};
`

export const Content = styled.div`
  flex-grow: 1;
`

export const Flag = styled.div`
  display: flex;
`

export const Source = styled.div`
  margin-top: ${g(1)};
`

export const Tag = styled.div`
  margin-bottom: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-left: ${g(4)};
  }
`

export const Title = styled.div`
  margin-bottom: ${g(1)};
`
