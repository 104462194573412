import { createMortgagor } from '@pretto/app/src/Sentences/v2/lib/factories/mortgagor'
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'

export const createProjectContext = (context?: Partial<ProjectContext>): ProjectContext => {
  const { mortgagors: mortgagorsContext, ...restContext } = context || {}
  const mortgagors: Mortgagor[] = []
  if (mortgagorsContext?.length) {
    mortgagorsContext.forEach(mortgagor => {
      mortgagors.push(createMortgagor(mortgagor))
    })
  } else {
    mortgagors.push(createMortgagor())
  }

  if (mortgagors.length === 1) {
    mortgagors.push(createMortgagor())
  }

  return {
    competitionBank: null,
    competitionRate: null,
    competitionDuration: null,
    contribution: null,
    credits: [],
    maturityType: null,
    mortgagors,
    projectType: null,
    savings: null,
    sciAssociation: null,
    sciType: null,
    structure: null,
    simulationType: null,
    liveTogether: null,
    children: null,
    expectedRentalIncome: null,
    usage: null,
    id: null,
    wantsRateAlert: null,
    emailRateAlert: null,
    hasAnsweredPropertySearch: false,
    propertySearchLocalisations: [],
    hasEcoPtz: null,
    ...restContext,
  }
}
