import TipBubble from '@pretto/bricks/app/sentences/components/TipBubble'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Divider from '@pretto/bricks/components/dividers/Divider'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { OptionButtons } from '@pretto/zen/simulator/optionButtons/OptionButtons/OptionButtons'

import { QuestionMarkCircle } from '@pretto/picto'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${column([2, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
  }
`

export const Title = styled.h1`
  ${typo.heading32};
  margin-top: ${g(3)};
  margin-bottom: ${g(2)};

  @media only screen and (min-width: ${breakpoints.mobileL}) {
    margin-top: ${g(7)};
  }
`

export const Description = styled.div`
  ${typo.bodyMedium16};
`

export const SectionChoices = styled.div`
  margin-top: ${g(3)};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(3)};
    padding: ${g(4)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }
`

export const SectionTitle = styled.div`
  margin-bottom: ${g(2)};
`

export const Options = styled(OptionButtons)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const Separator = styled(Divider)`
  margin: ${g(3)} 0;
`

export const Bubble = styled(TipBubble)`
  margin-bottom: 0;
`

export const Results = styled(Button).attrs({ isArrowShown: true })`
  margin-left: auto;
`

export const Help = styled(ButtonFeature).attrs({ label: "Besoin d'aide ?", picto: QuestionMarkCircle })`
  margin: ${g(2.5)} 0 ${g(2.5)} auto;
`
