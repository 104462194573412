import { OccupationPrivateTrial } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapTrialValues: MapToChoices<OccupationPrivateTrial[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
  return values.map(value => ({
    key: value,
    isSelected:
      (mortgagors?.[0].trialPeriod && value === 'trial') ||
      (!isNil(mortgagors?.[0].trialPeriod) && !mortgagors?.[0].trialPeriod && value === 'nonTrial'),
    onClick: () => {
      clonedMortgagors[0].trialPeriod = value === 'trial'
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.situation.private.longTerm.trial.values.${value}`),
  }))
}
