import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Appointment: React.FC<CardProps> = ({ defaultComponent: Component }) => (
  <Component
    description="Vous rencontrez votre banquier. Le délai de traitement avant de recevoir votre offre de prêt peut varier selon les banques. Comptez en général 2 à 4 semaines."
    title="Rendez-vous à la banque"
  />
)

export const config: Config = {
  component: Appointment,
  name: 'appointment',
}
