import TrustpilotStars from '@pretto/bricks/components/iconography/TrustpilotStars'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const FooterInfo = ({ count, rate, ...props }) => {
  return (
    <S.TrustpilotRate {...props}>
      <S.TrustpilotLogo />
      <S.TrustpilotStars>
        <TrustpilotStars score={5} />
      </S.TrustpilotStars>
      <S.Infos>
        <S.Rate>{rate}</S.Rate>
        <S.TrustpilotCount href="https://fr.trustpilot.com/review/pretto.fr">{count} avis</S.TrustpilotCount>
      </S.Infos>
    </S.TrustpilotRate>
  )
}
FooterInfo.propTypes = {
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  count: PropTypes.string,
  rate: PropTypes.string,
}
export default memo(FooterInfo)
