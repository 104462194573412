import { getBlockInfoParams, LoadingProps } from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoByMaturity'
import { getBlockInfoAppointment } from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoAppointment'
import { BlockInfoProps } from '@pretto/bricks/app/simulator/components/ResultCardMain/children/BlockInfo'

import { Typology } from '@pretto/app/src/User/types/Typology'


export const getBlockInfoByTypology = ({ typology, bookingInfos, historyPush, onOpenMutualizedAgenda }: Omit<getBlockInfoParams, 'maturity'>): BlockInfoProps | LoadingProps => {
  switch(typology) {
    case Typology.preLead:
      return {
        imgPath: 'v1637684805/zen/static-assets/illustrations/660x660/folderBook.svg',
        title: 'Nous prenons votre crédit en main !',
        description:
          'Constituez dès maintenant votre dossier de prêt dans votre espace client. Nous vous simplifions les démarches et trouvons le meilleur prêt.',
        cta: {
          text: 'Accéder à mon espace client',
          onClick: () => historyPush('/'),
        },
      }
    case Typology.client: {
      return getBlockInfoAppointment({ bookingInfos, historyPush })
    }
    case Typology.prospect:
    default:
      return {
        imgPath: 'v1637685716/zen/static-assets/illustrations/660x660/frame-people-call.svg',
        title: 'Le prêt tôt sera le mieux !',
        description:
          'Le chrono pour obtenir votre prêt est lancé : parlez-en avec un Expert Crédit le plus vite possible pour qu’il vous aide à obtenir le meilleur prêt !',
        checkList: ['Gratuit', 'Rapide (30min)','Sans engagement'],
        cta: {
          text: 'Obtenir un rendez-vous',
          onClick: onOpenMutualizedAgenda,
        },
      }
  }
}