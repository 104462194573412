import Chips from '@pretto/bricks/components/chips/Chips'
import Divider from '@pretto/bricks/components/dividers/Divider'
import TextField from '@pretto/bricks/components/form/TextField'
import Icon from '@pretto/bricks/components/iconography/Icon'
import { numeralBreakpoints } from '@pretto/bricks/components/layout'
import Content from '@pretto/bricks/components/layout/Content'
import List from '@pretto/bricks/components/lists-tables/List'
import ListItem from '@pretto/bricks/components/lists-tables/ListItem'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'

import * as C from './AutoComplete.module.css'
import * as S from './styles'

const DOWN_KEY_CODE = 40
const ENTER_KEY_CODE = 13
const UP_KEY_CODE = 38

const useAutoComplete = props => {
  const {
    onAfterClose,
    onAfterOpen,
    onClear,
    onSearch,
    onSelect,
    results,
    searchfieldPlaceholder,
    searchValue,
    type,
    tags = [],
  } = { ...defaultProps, ...props }

  const textFieldElementRef = useRef(null)
  const dialogButtonElementRef = useRef(null)
  const itemsElements = useRef([])

  const [isOpen, setIsOpen] = useState(false)
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1)

  const handleKeyDown = useCallback(
    event => {
      if (!isOpen) {
        return
      }

      const isDownKey = event.keyCode === DOWN_KEY_CODE
      const isEnterKey = event.keyCode === ENTER_KEY_CODE
      const isUpKey = event.keyCode === UP_KEY_CODE

      if (
        isDownKey &&
        itemsElements.current[itemsElements.current.length - 1] === event.target &&
        dialogButtonElementRef.current
      ) {
        event.preventDefault()
        dialogButtonElementRef.current.focus()
        return
      }

      if (isDownKey && itemsElements.current.length && textFieldElementRef.current === event.target) {
        event.preventDefault()
        setFocusedItemIndex(0)
        return
      }

      if (
        isDownKey &&
        focusedItemIndex < itemsElements.current.length - 1 &&
        itemsElements.current.includes(event.target)
      ) {
        event.preventDefault()
        setFocusedItemIndex(itemsElements.current.indexOf(event.target) + 1)
        return
      }

      if (isUpKey && itemsElements.current[0] === event.target) {
        event.preventDefault()
        textFieldElementRef.current.focus()
        return
      }

      if (isUpKey && dialogButtonElementRef.current === event.target) {
        event.preventDefault()
        setFocusedItemIndex(itemsElements.current.length - 1)
        return
      }

      if (isUpKey && itemsElements.current.includes(event.target)) {
        event.preventDefault()
        setFocusedItemIndex(itemsElements.current.indexOf(event.target) - 1)
        return
      }

      if (isEnterKey && textFieldElementRef.current === event.target && results.length > 0) {
        select(results[0])
        return
      }

      if (isEnterKey && focusedItemIndex !== -1) {
        select(results[focusedItemIndex])
      }
    },
    [focusedItemIndex, isOpen, results]
  )

  useEffect(() => {
    itemsElements.current = itemsElements.current.slice(0, results.length)
  }, [results])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])

  useEffect(() => {
    if (!isOpen || focusedItemIndex === -1) {
      return
    }

    itemsElements.current[focusedItemIndex].focus()
  }, [focusedItemIndex])

  const close = () => {
    if (window.innerWidth < numeralBreakpoints.tablet) {
      window.scrollTo(0, document.body.scrollHeight)
    }

    setIsOpen(false)
    onAfterClose()
  }

  const open = () => {
    setFocusedItemIndex(-1)
    setIsOpen(true)
  }

  const select = result => {
    onSelect(result)

    if (type === 'default') {
      close()
    }
  }

  const handleAfterOpen = () => {
    onAfterOpen()

    if (window.innerWidth < numeralBreakpoints.tablet) {
      window.scrollTo(0, 0)
    }
  }

  const handleBlur = () => setFocusedItemIndex(-1)

  const handleClick = result => () => {
    setFocusedItemIndex(-1)
    select(result)
  }

  const handleClose = close
  const handleFocus = index => () => setFocusedItemIndex(index)
  const handleRef = index => node => (itemsElements.current[index] = node)

  const handleTextFieldBlur = () => {}
  const handleTextFieldChange = onSearch
  const handleTextFieldClear = onClear
  const handleTextFieldRef = ref => (textFieldElementRef.current = ref)
  const handleTextFieldFocus = () => setFocusedItemIndex(-1)

  const component = (
    <Dialog
      className={C.autocompleteDialogContent}
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleClose}
    >
      <DialogTemplate
        buttonProps={
          type !== 'default'
            ? {
                children: 'Valider',
                onClick: handleClose,
                ref: dialogButtonElementRef,
              }
            : null
        }
        isButtonSticky
      >
        <S.DialogHeader>
          <S.DialogHeaderClose onClick={handleClose}>
            <Icon name="cross" />
          </S.DialogHeaderClose>

          <S.DialogHeaderSearchField>
            <TextField
              autoFocus
              disableSuggestions
              format="search"
              onBlur={handleTextFieldBlur}
              onChange={handleTextFieldChange}
              onClear={handleTextFieldClear}
              onFocus={handleTextFieldFocus}
              ref={handleTextFieldRef}
              placeholder={searchfieldPlaceholder}
              type="search"
              value={searchValue}
            />
          </S.DialogHeaderSearchField>
        </S.DialogHeader>

        {type === 'tags' && tags.length > 0 && (
          <div>
            <Content size="small">
              <S.Tags>
                {tags.map((tag, index) => (
                  <S.Tag key={index}>
                    <Chips {...tag} />
                  </S.Tag>
                ))}
              </S.Tags>
            </Content>

            <Divider />
          </div>
        )}

        <List>
          {results.map((result, index) => {
            return (
              <ListItem dividerProps={type === 'check' ? { opacity: 0 } : {}} key={index} onClick={handleClick(result)}>
                <S.ListItem
                  isFocused={index === focusedItemIndex}
                  label={result.label}
                  onBlur={handleBlur}
                  onFocus={handleFocus(index)}
                  ref={handleRef(index)}
                  tabIndex={0}
                  value={result.value}
                >
                  {type === 'check' && (
                    <S.ListCheckbox id={result.value} isChecked={result.checked} isFocusable={false} />
                  )}
                  <span>{result.label}</span>
                </S.ListItem>
              </ListItem>
            )
          })}
        </List>
      </DialogTemplate>
    </Dialog>
  )

  return { component, open }
}

export const defaultProps = {
  onAfterClose: () => {},
  onAfterOpen: () => {},
  onClear: () => {},
  onSearch: () => {},
  onSelect: () => {},
  placeholder: 'Choisissez',
  results: [],
  searchfieldPlaceholder: 'Recherchez',
  type: 'default',
}

export const propTypes = {
  /** Event triggered right after the dialog is closed. */
  onAfterClose: PropTypes.func,
  /** Event triggered right after the dialog is opened. */
  onAfterOpen: PropTypes.func,
  /** Event triggered whenever the dialog search field clear button is being pressed. */
  onClear: PropTypes.func,
  /** Event triggered whenever a search is being made. */
  onSearch: PropTypes.func,
  /** Callback function fired when a menu item is selected. The function has 2 props: <code>label</code> and <code>value</code> */
  onSelect: PropTypes.func,
  /** Array of results. Each option must be an object. Format: <code>\[{label: 'label 1', value: 'value 1'},{label: 'label 2', value: 'value 2'}\]</code> */
  results: PropTypes.array,
  /** Value to be used as fallback value while there aren't any selected value yet.. */
  searchfieldPlaceholder: PropTypes.string,
  /** Value to be consumed by the dialog search field. */
  searchValue: PropTypes.string,
  /** Array of tags. Each option must be an object. Format: <code>\[{children: 'label 1', onClick: handler}\]</code> */
  tags: PropTypes.array,
  /** Type of list, either single choice in default mode or multiple choices in check mode. */
  type: PropTypes.oneOf(['check', 'default', 'tags']),
}

export default useAutoComplete
