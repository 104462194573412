import { BlockTextarea, BlockTextareaInput, FieldTextarea } from '@pretto/app/src/purchaseOffer/config/types'

import { useState } from 'react'

export const useInputTextarea = ({ props }: BlockTextarea): FieldTextarea => {
  const { isOptionnal, textareaProps } = props
  const [value, setValue] = useState('')
  const limit = 600

  const handleChange = (value: string) => {
    setValue(value)
  }

  const input: BlockTextareaInput = {
    props: {
      ...props,
      textareaProps: {
        ...textareaProps,
        isBackgroundTransparent: true,
        limit,
        onChange: handleChange,
        value,
      },
    },
    type: 'textarea',
  }

  return {
    error: (!isOptionnal && value.trim().length === 0) || value.length > limit,
    input,
    value,
  }
}
