import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import { useTracking } from '@pretto/app/src/lib/tracking'

import url from 'url'

const Certificate: React.FC<CardProps> = ({ dashboardData, defaultComponent: Component }) => {
  const trackAction = useTracking()

  const certificate = ensureNotNil(dashboardData?.deal?.certificate)

  const actionUrl = url.resolve(process.env.API_PRETTO_HOST, certificate)

  const handleActionClick = () => {
    trackAction('DASHBOARD_CERTIFICATE_DOWNLOADED', { downloaded_certificate_click_origin: 'dashboard' })
  }

  return (
    <Component
      actionLabel="Télécharger"
      actionUrl={actionUrl}
      description="L'attestation de financement Pretto vous permet de certifier aux vendeurs que vous pouvez obtenir les meilleures conditions pour votre achat."
      onActionClick={handleActionClick}
      tag="Nouveau !"
      title="Votre attestation de financement"
    />
  )
}

export const config: Config = {
  component: Certificate,
  condition: (dashboardData, { hasFeatureAccess }) =>
    dashboardData?.project?.purchase?.maturity !== 'sale_agreement_signed' &&
    !!dashboardData?.deal?.certificate &&
    !hasFeatureAccess(['BUYOUT']),
  name: 'certificate',
}
