import { SuggestionType } from '@pretto/app/src/types/gateway/enums'

interface SuggestionLargeConfig {
  benefits: { items: string[]; title?: string }
  disclaimer: string
  hat?: string
  title: string
}

interface SuggestionSlimConfig {
  title: string
}

const suggestionsLarge: Record<SuggestionType, SuggestionLargeConfig> = {
  [SuggestionType.BumpContribution]: {
    benefits: {
      items: [
        'Ayez accès à plus de banques susceptibles de vous financer',
        'Obtenez de meilleures conditions d’offres de prêt',
      ],
    },
    disclaimer:
      'Il n’y a pas qu’en puisant dans son épargne qu’on peut augmenter son apport. Sollicitez aussi votre entourage. Pretto peut vous y aider.',
    title: 'Si vous augmentiez votre apport',
  },
  [SuggestionType.BuyNew]: {
    benefits: {
      items: [
        'Économisez sur les frais de notaire',
        'Réduisez vos factures',
        'Bénéficiez de la garantie décennale sur votre logement',
      ],
    },
    disclaimer:
      'Acheter dans le neuf diminue les fais de notaire. Vous pourriez également être éligible à certains prêts aidés, du type PTZ.',
    title: 'Si vous achetiez dans le neuf',
  },
  [SuggestionType.BuyWithBroker]: {
    benefits: {
      items: [
        'Ayez accès à des conditions d’offres encore attractives',
        'Minimisez l’impact de la hausse des taux sur votre pouvoir d’achat',
      ],
    },
    disclaimer:
      'Chaque mois, les grilles de taux des banques augmentent. En faisant une offre rapidement, vous pouvez encore avoir accès à des conditions attractives de taux plus bas que la moyenne constatée.',
    hat: 'Vous pourriez visiter un bien affiché à :',
    title: 'En faisant une offre rapidement',
  },
  [SuggestionType.CoInvestment]: {
    benefits: {
      items: [
        'Accédez à des biens immobiliers plus onéreux sans augmenter vos mensualités',
        'Augmentez votre apport sans toucher à votre épargne',
        'Ne rémunérez le co-investisseur qu’à la revente du bien',
      ],
    },
    disclaimer:
      'L’apport du co-investisseur s’adapte à votre besoin, et peut aller jusqu’à 20 % du prix du bien. Il se rémunère lorsque vous décidez de revendre en percevant sa quote-part sur le prix de revente.',
    title: 'Si vous aviez recours au cofinancement',
  },
  [SuggestionType.Negotiate]: {
    benefits: {
      items: [
        'Savoir évaluer le prix juste du bien lors de la visite',
        'Rassurer votre vendeur sur la qualité de votre plan de financement',
      ],
      title: 'Pour bien négocier, il faut :',
    },
    disclaimer:
      'Avec moins d’acheteurs sur le marché, les vendeurs ont plus de mal à trouver des acquéreurs. On observe des négociations de 5 % à 10 % sur les prix affichés. Profitez-en pour vous lancer !',
    hat: 'Vous pourriez visiter un bien affiché à :',
    title: 'En négociant le prix du bien',
  },
  [SuggestionType.RepayDebts]: {
    benefits: {
      items: [
        'Augmentez votre mensualité maximale pour votre prêt immobilier',
        'Gagnez la confiance des banques',
        'Gérez plus facilement vos charges',
      ],
    },
    disclaimer:
      'En soldant vos crédits en cours, vous réduisez vos charges mensuelles et pouvez prétendre à emprunter plus !',
    title: 'En remboursant un / des crédit(s)',
  },
}

const suggestionsSlim: Record<SuggestionType, SuggestionSlimConfig> = {
  [SuggestionType.BumpContribution]: {
    title: 'En augmentant votre apport :',
  },
  [SuggestionType.BuyNew]: {
    title: 'En achetant dans le neuf :',
  },
  [SuggestionType.BuyWithBroker]: {
    title: 'En faisant une offre rapidement :',
  },
  [SuggestionType.CoInvestment]: {
    title: 'Avec une solution de cofinancement :',
  },
  [SuggestionType.Negotiate]: {
    title: 'En négociant le prix du bien :',
  },
  [SuggestionType.RepayDebts]: {
    title: 'En remboursant un / des crédit(s) :',
  },
}

export const getSuggestionLarge = (type: SuggestionType): SuggestionLargeConfig | null => suggestionsLarge[type] ?? null
export const getSuggestionSlim = (type: SuggestionType): SuggestionSlimConfig | null => suggestionsSlim[type] ?? null
