import { breakpoints, g } from '@pretto/bricks/components/layout'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { SecondaryButton } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'
import { Checkbox as CheckboxComponent } from '@pretto/zen/reveal/atoms/selectors/checkbox/Checkbox'
import { Banner as BannerComponent } from '@pretto/zen/reveal/main/banners/banner/Banner'

import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

export const Consent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding-bottom: ${g(1)};
`

export const ConsentBox = styled.div`
  gap: ${g(2)};
  display: flex;
`

export const ConsentCheck = styled(CheckboxComponent)`
  align-self: flex-start;
  flex-shrink: 0;
`

export const ConsentError = styled.p`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.error1};
`

export const ConsentLabel = styled.label`
  ${({ theme }) => theme.typos.body4};
  color: ${({ theme }) => theme.colors.neutral2};
  cursor: pointer;

  & > a {
    ${({ theme }) => theme.typos.body4Underline};
  }
`

export const ConsentLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConsentLabelFooter = styled.div`
  padding-top: ${g(1)};
`

export const Container = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(3)};
  padding: ${g(2)} ${g(3)} ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(4)};
  }
`

export const Divider = styled(DividerComponent)``

export const ErrorMessage = styled(BannerComponent).attrs({ type: 'error' })``

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

export const Field = styled.div`
  flex: 1;
`

export const FieldsetRow = styled.div`
  display: flex;
  gap: ${g(2)};
`

export const FieldsetRowPicto = styled.svg`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    margin-top: ${g(3 / 2)};
  }
`

export const Footer = styled(TransitionGroup)`
  margin-top: ${g(-3)};
`

export const FooterRow = styled.div`
  padding-top: ${g(2)};
`

export const Legend = styled.legend`
  ${({ theme }) => theme.typos.heading6}
  margin-bottom: ${g(2)};
`

export const SubmitButton = styled(SecondaryButton).attrs({ scheme: 'green' })`
  width: 100%;
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4}
`
