/* globals ALGOLIA_COUNTRIES_APP_ID, ALGOLIA_COUNTRIES_API_KEY */
import AutoComplete from '@pretto/bricks/components/form/AutoComplete'

import { addressSearch, countrySearch, municipalitySearch } from '@pretto/places'

import countries from 'i18n-iso-countries'
import PropTypes from 'prop-types'
import { useState } from 'react'

// @deprecated
// This is a bad pattern, there must not be any data fetching in a core library,
// it belongs to the parent app to pass the data down to the library.
// It requires too much effort to remove this but it should be done on the long run.
const countriesApi = countrySearch.init(ALGOLIA_COUNTRIES_APP_ID, ALGOLIA_COUNTRIES_API_KEY)
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

export const formatHitForType = (type, { city, country_code, locale_names, postcode }) => {
  switch (type) {
    case 'address':
      return {
        city: city?.[0],
        country: country_code,
        street: locale_names?.[0],
        zipcode: postcode?.[0],
      }

    case 'country':
      return country_code

    case 'city': {
      return {
        city: locale_names?.[0],
        country: country_code,
        zipcode: postcode?.[0],
      }
    }

    default:
  }
}

export const formatValueForType = (type, value) => {
  switch (type) {
    case 'address': {
      const { city, street, zipcode } = value

      if (!city || !street || !zipcode) {
        return null
      }

      return [street, zipcode, city].filter(Boolean).join(', ')
    }

    case 'city': {
      const { city, country, zipcode } = value

      if (!city || !country) {
        return null
      }

      if (country === 'fr' && zipcode) {
        return `${city} (${zipcode})`
      }

      return `${city}, ${countries.getName(country, 'fr')}`
    }

    case 'country':
      if (!value) {
        return null
      }

      return countries.getName(value, 'fr')

    default:
      return ''
  }
}

export const PlaceField = ({ onChange, type, value, ...props }) => {
  const [results, setResults] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const handleAfterOpen = value => {
    setSearchValue(value)
  }

  const handleClear = () => setSearchValue('')
  const handleSelect = ({ value }) => {
    return onChange(value)
  }

  const handleSearch = async value => {
    setSearchValue(value)

    if (type === 'city') {
      const results = await municipalitySearch.get(value, { limit: 21 })
      return setResults(results)
    }

    if (type === 'country') {
      const results = await countriesApi.get(value, { limit: 10 })
      return setResults(results)
    }

    try {
      const results = await addressSearch.get(value, { limit: 10 })
      setResults(results)
    } catch (error) {}
  }

  const selectedValue = value && formatValueForType(type, value)

  return (
    <AutoComplete
      {...props}
      format="form"
      icon="search"
      onClear={handleClear}
      onAfterOpen={handleAfterOpen}
      onSearch={handleSearch}
      onSelect={handleSelect}
      results={results}
      selectedValue={selectedValue}
      searchValue={searchValue}
    />
  )
}

PlaceField.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
