import ToggleDetails from '@pretto/bricks/app/simulator/components/ToggleDetails'
import ButtonSquare from '@pretto/bricks/components/buttons/ButtonSquare'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import Content from './children/Content'
import * as S from './styles'
import { getInfos, getScoreWithColors } from './utils'

const handleStopPropagation = e => {
  e.preventDefault()
  e.stopPropagation()
}

const ResultCardSecondary = ({ isOnboardingDone, cardData, onCardClick, onCardCTAClick, projectProps, typology }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const handleToggleDetails = e => {
    handleStopPropagation(e)
    setIsDetailsOpen(isDetailsOpen => !isDetailsOpen)
    if (!isDetailsOpen) {
      onCardClick()
    }
  }

  const handleCTAClick = e => {
    handleStopPropagation(e)
    onCardCTAClick()
  }

  const { loanAmount, propertyPrice, range, rate, savings, score, totalCost } = cardData
  const { projectKind } = projectProps

  const isPositive = savings >= 0

  const scoreFundability = getScoreWithColors(score)
  const { cta, detailsText, mainInfo, infos } = getInfos({
    isPositive,
    loanAmount,
    projectKind,
    propertyPrice,
    range,
    rate,
    savings,
    scoreFundability,
    totalCost,
    typology,
  })

  return (
    <S.Card onClick={handleToggleDetails} format="line" variant="neutral-1-20">
      <S.ContentCard>
        <Content value={mainInfo.value} infos={infos} />
        <Responsive max="mobileL">
          {!isOnboardingDone && <ButtonSquare variant="neutral" size="small" onClick={handleCTAClick} />}
        </Responsive>
        <Responsive min="mobileL">
          {!isOnboardingDone && <ButtonSquare variant="neutral" onClick={handleCTAClick} />}
        </Responsive>
      </S.ContentCard>
      <ToggleDetails
        cardData={cardData}
        cta={cta}
        detailsText={detailsText}
        isCtaVisible={!isOnboardingDone}
        isDetailsOpen={isDetailsOpen}
        projectProps={projectProps}
        scoreFundability={scoreFundability}
      />
    </S.Card>
  )
}

ResultCardSecondary.propTypes = {
  /** Information data passed to the card. */
  cardData: PropTypes.shape({
    applicationFees: PropTypes.number,
    bridge: PropTypes.number,
    brokerageFees: PropTypes.number,
    guarantyFees: PropTypes.number,
    interests: PropTypes.number,
    loanAmount: PropTypes.number,
    loanDuration: PropTypes.number,
    modularity: PropTypes.bool,
    notaryFees: PropTypes.number,
    payment: PropTypes.number,
    propertyPrice: PropTypes.number,
    ptz: PropTypes.object,
    range: PropTypes.array,
    rate: PropTypes.number,
    remainingInterests: PropTypes.number,
    repurchaseFees: PropTypes.number,
    savings: PropTypes.number,
    score: PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
    }),
    totalCost: PropTypes.number,
    transferability: PropTypes.bool,
  }),
  isOnboardingDone: PropTypes.bool,
  onCardClick: PropTypes.func,
  /** Function to call when click on CTA in card */
  onCardCTAClick: PropTypes.func,
  projectProps: PropTypes.object,
  typology: PropTypes.oneOf(['prospect', 'preLead', 'client']),
}

export default memo(ResultCardSecondary)
