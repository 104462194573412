import BaseInput from '@pretto/bricks/components/form/BaseInput'
import InputRadioGroup from '@pretto/bricks/components/form/InputRadioGroup'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'

import PropTypes from 'prop-types'
import { memo } from 'react'

import MaturityUpdateResult from './MaturityUpdateResult'
import * as S from './styles'

const DIALOG_MATURITY_DESCRIPTION =
  'Prenez le temps de mettre à jour l’avancement de votre projet pour que nous puissions vous donner des conseils adaptés à votre situation.'

const MaturityUpdatePageTemplate = ({
  description = DIALOG_MATURITY_DESCRIPTION,
  isFormSolid = false,
  inputProps,
  isErrored = false,
  onRetry,
  onSubmit,
  maturity,
  radioProps,
  resultPageProps,
  status = 'idle',
}) => {
  const maturityLabel = radioProps.options.find(el => el.value === maturity)?.label

  return (
    <Wrapper>
      <S.Page $isFormSolid={isFormSolid}>
        {isFormSolid && status === 'success' && (
          <MaturityUpdateResult
            maturityLabel={maturityLabel}
            onRetry={onRetry}
            retryButtonTitle={'Modifier'}
            src={'v1655991218/zen/static-assets/illustrations/scenery/people-and-map.svg'}
            title="Votre projet a bien été mis à jour !"
            {...resultPageProps}
          />
        )}

        {isFormSolid && status === 'error' && (
          <MaturityUpdateResult
            description={'Vos modifications n’ont peut-être pas été prises en compte'}
            onRetry={onRetry}
            retryButtonTitle={'Réessayer'}
            isError={true}
            src={'v1623225191/bricks/static-assets/illustrations/Girl_Bored.svg'}
            title="Oups ! Un problème est survenu"
          />
        )}

        {status === 'idle' && (
          <S.FormContent $isFormSolid={isFormSolid}>
            <S.Content>
              <S.Heading size="large">Où en est votre projet ?</S.Heading>
            </S.Content>

            <S.Content>
              <S.Text>{description}</S.Text>
            </S.Content>

            <S.Content>
              <InputRadioGroup {...radioProps} />
            </S.Content>

            {maturity === 'sale_agreement_signed' && (
              <S.Content>
                <S.Label>Date de signature du compromis :</S.Label>
                <BaseInput $error={isErrored} {...inputProps} />
              </S.Content>
            )}

            <S.Content>
              <S.StyledButton onClick={onSubmit}>Valider</S.StyledButton>
            </S.Content>
          </S.FormContent>
        )}
      </S.Page>
    </Wrapper>
  )
}

MaturityUpdatePageTemplate.propTypes = {
  description: PropTypes.string,
  inputProps: PropTypes.object,
  isErrored: PropTypes.bool,
  isFormSolid: PropTypes.bool,
  maturity: PropTypes.oneOf([
    'under_study',
    'search_started',
    'offer_made',
    'offer_accepted',
    'sale_agreement_signed',
    'project_lost',
  ]),
  onRetry: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  radioProps: PropTypes.object,
  resultPageProps: PropTypes.object,
  status: PropTypes.oneOf(['idle', 'success', 'error']),
}

export default memo(MaturityUpdatePageTemplate)
