import * as colorHelpers from '@pretto/bricks/components/colors'
import { compileColors } from '@pretto/bricks/components/colors2'
import { palette } from '@pretto/bricks/components/utility/ThemeProvider/palette'

import { colors } from '@pretto/zen/reveal/theme/colors'
import * as typosReveal from '@pretto/zen/reveal/theme/typography'
import * as typosZen from '@pretto/zen/theme/typographyZenForReveal'

import { ThemeProvider } from 'styled-components'

import 'reset-css/reset.css'

import '../../../core/fonts/BricksIcons.css'
import '../../../core/fonts/Freitag.css'
import '../../../core/fonts/MaisonNeue.css'
import '../../../core/fonts/Montserrat.css'
import '../../../core/fonts/PensumPro.css'
import '../../../core/fonts/Roboto.web.css'
import '../../theme.css'
import '../../theme.legacy.css'


export type ThemeProviderProps = {
  children: React.ReactNode
  customColors?: object
  customFont?: string
  designSystem?: 'reveal' | 'zen'
  theme?: object
}

const DESIGN_SYSTEMS = {
  reveal: { colors, typos: typosReveal },
  zen: { colors: palette, typos: typosZen },
}

const mainThemeVariants = {
  primary1: { dark: '#006855', default: '#0C806B', light: '#D2F8D1' },
  accent1: { default: '#FC5C63', light: '#FFF2F3', dark: '#EE040F' },
  accent2: { default: '#FC847C', light: '#FDB3AE', dark: '#FA2618' },
  accent3: { default: '#FFAA98', light: '#FFD4CB', dark: '#FF5632' },
  accent4: { default: '#FFE7D1', light: '#FFF4EB', dark: '#FFB26B' },
  accent5: { default: '#FFAA98', light: '#FFBFB2', dark: '#FF5632' },
  neutral1: { default: '#050505', light: '#F5F5F5', dark: '#000000' },
  warning: { default: '#E3631B', light: '#FFB190', dark: '#E3401B' },
  error: { default: '#C70808', light: '#FF9A9A', dark: '#E02424' },
}

const colorsMain = colorHelpers.createColors(colorHelpers.createPalette(mainThemeVariants))

const Theme: React.FC<ThemeProviderProps> = ({
  children,
  customColors = {},
  customFont,
  designSystem = 'zen',
  theme,
}) => {
  const currentTheme = {
    colors: { ...DESIGN_SYSTEMS[designSystem].colors, ...customColors },
    designSystem,
    typos: { ...DESIGN_SYSTEMS[designSystem].typos },
    font: customFont || 'Maison Neue',
    legacy: {
      colors: compileColors(colorsMain),
      fonts: {
        circular: 'Maison Neue',
        bricks: 'Bricks Icons',
        work: 'Maison Neue',
        headingsWeight: 900,
      },
    },
    ...theme,
  }

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}

export default Theme
