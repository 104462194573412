import TimelineProgressBar from '@pretto/bricks/app/simulator/components/TimelineProgressBar'

import { QuestionMarkCircle } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const TimelineMobile = ({ max, onClick, progress, ...props }) => (
  <S.ContainerProgress onClick={onClick} {...props}>
    <S.Timeline>
      <TimelineProgressBar max={max} value={progress} />
    </S.Timeline>
    <QuestionMarkCircle />
  </S.ContainerProgress>
)

TimelineMobile.propTypes = {
  /** max value */
  max: PropTypes.number,
  onClick: PropTypes.func,
  /** progress value (under max) */
  progress: PropTypes.number.isRequired,
}

TimelineMobile.defaultProps = {
  max: 1,
}

export default TimelineMobile
