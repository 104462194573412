import PropTypes from 'prop-types'
import { Children } from 'react'

import * as S from './styles'

const LayoutStickyFooter = ({ children, ...props }) => {
  const childrenArray = Children.toArray(children)

  if (childrenArray.length < 1) {
    throw new Error('LayoutStickyBottom must have at least one child')
  }

  const indexMax = Math.max(1, childrenArray.length - 1)

  const body = childrenArray.slice(0, indexMax)
  const footer = childrenArray.slice(indexMax)

  return (
    <S.Layout {...props}>
      {body}
      {footer.length > 0 && <S.Footer>{footer}</S.Footer>}
    </S.Layout>
  )
}

LayoutStickyFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutStickyFooter
