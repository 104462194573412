import SimulationSidebar from '@pretto/bricks/app/simulator/components/SimulationSidebar'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, Column, grid } from '@pretto/bricks/components/layout/Grid'
import Estimations from '@pretto/bricks/shared/components/Estimations'

import { BlockAppointment } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/Sidebar/BlockAppointment/BlockAppointment'
import { BlockSave } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/Sidebar/BlockSave/BlockSave'
import { ControlsContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/ControlsContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext } from 'react'
import styled, { css } from 'styled-components'

export const Sidebar: React.FC = () => {
  const { onKeyPressValidation, contributionRow, controls } = useContext(ControlsContext)
  const trackAction = useTracking()

  const { hasFeatureAccess, typology } = useUser()
  const editableRowsProps = {
    onValidate: onKeyPressValidation,
    rows: contributionRow,
  }

  const trackLegalLink = (source: string) => () => {
    trackAction('CAPACITY_RESULTS_LEGAL_LINKS_CLICKED', { capacity_results_legal_link: source })
  }

  const estimationsProps = {
    onCguClick: trackLegalLink('cgu'),
    onLegalMentionsClick: trackLegalLink('legal_mentions'),
    onPrivacyClick: trackLegalLink('privacy'),
  }

  const renderBlock = () => {
    if (typology === 'prospect') {
      return <BlockSave />
    }

    const isAppointmentAvailable = !hasFeatureAccess(['NO_APPOINTMENT'])
    if (typology === 'preLead' && isAppointmentAvailable) {
      return <BlockAppointment />
    }

    return null
  }

  return (
    <SidebarContainer>
      <SidebarInputsComponent editableRowsProps={editableRowsProps} controls={controls} />
      {renderBlock()}
      <LegalText {...estimationsProps} />
    </SidebarContainer>
  )
}

export const SidebarContainer = styled(Column)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(5)};
    ${grid(4, { isRightAligned: true })};
    background-color: ${({ theme }) => theme.colors.neutral4};
    grid-template-rows: max-content;
    row-gap: ${g(3)};
    padding-bottom: ${g(7)};
  }
`

const leftGrid = css`
  ${column([2, 4])};
  margin-right: ${g(3)};
`

const LegalText = styled(Estimations)`
  margin-top: 0;
  margin-right: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
  }
`

const SidebarInputsComponent = styled(SimulationSidebar)`
  margin-bottom: ${g(2)};
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
    margin-bottom: 0;
  }
`
