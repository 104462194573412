export const layoutBase = 8

export const g = (multiple, diff = 0) => `${Math.round(multiple * layoutBase + diff)}px`

export const ng = (multiple, diff = 0) => Math.round(multiple * layoutBase + diff)

export const mobile = {
  default: '600px',
  min: '320px',
  max: '425px',
}

export const tablet = {
  min: '768px',
  max: '1024px',
}

export const desktop = {
  default: '1240px',
  min: '1024px',
}

export const numeralBreakpoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  desktop: 1240,
}

const bp = {}
Object.entries(numeralBreakpoints).forEach(([key, val]) => {
  Object.assign(bp, { [key]: `${val}px` })
})
export const breakpoints = bp
