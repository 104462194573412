import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  min-height: 100%;
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    background-color: transparent;
    padding: ${g(6)};
  }
`

export const RescheduleTitle = styled.div`
  margin: ${g(4)} 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`
