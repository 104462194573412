import { mapIncomePeriodValues } from '@pretto/app/src/Sentences/v2/ComortgagorIncome/Salary/lib/mappers/mapIncomePeriod'
import { mapIncomeTypeValues } from '@pretto/app/src/Sentences/v2/ComortgagorIncome/Salary/lib/mappers/mapIncomeType'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import {
  IncomePeriod,
  IncomeType,
  OccupationOthersStatus,
  OccupationType,
} from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

export const SalaryPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}, {}]
  const { nextRoute } = useRoutesGraph()
  const isBusinessOwner = mortgagors?.[1].status === OccupationOthersStatus.BusinessOwner

  const getDefaultActiveField = () => {
    if (!mortgagors?.[1].income?.period) return 0
    if (!mortgagors?.[1].income?.type) return 1
    return 2
  }

  const getIsFilled = () =>
    Boolean(
      nextRoute && mortgagors?.[1].income?.period && mortgagors?.[1].income?.type && mortgagors?.[1].income?.amount
    )

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  if (!mortgagors || !mortgagors.length) {
    return null
  }

  const saveAmount = (value: string) => {
    if (!clonedMortgagors[1].income) {
      clonedMortgagors[1].income = { amount: parseInt(value) }
    } else {
      clonedMortgagors[1].income.amount = parseInt(value)
    }
    setContext({
      mortgagors: clonedMortgagors,
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'salary-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.title')}</Title>
      <div>
        {mortgagors[1].occupationType === OccupationType.Others ||
        mortgagors[1].occupationType === OccupationType.Medical
          ? t('sentences.income.comortgagor.otherIncome.sentence.0')
          : t('sentences.income.comortgagor.salary.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!mortgagors[1].income?.period}>
          {t(`sentences.income.period.${mortgagors[1].income?.period || 'year'}`)}
        </Prompt>
        {activeField > 0 && (
          <Prompt onClick={() => setActiveField(1)} isFilled={!!mortgagors[1].income?.type}>
            {t(`sentences.income.type.${mortgagors[1].income?.type || 'net'}`)}
          </Prompt>
        )}
        {activeField > 1 && (
          <>
            {mortgagors[1].occupationType === OccupationType.Others ||
            mortgagors[1].occupationType === OccupationType.Medical ? (
              <>
                {t('sentences.income.comortgagor.otherIncome.sentence.1')}
                {mortgagors[1].status === OccupationOthersStatus.BusinessOwner &&
                  t('sentences.income.comortgagor.otherIncome.sentence.2')}
              </>
            ) : (
              t('sentences.income.comortgagor.salary.sentence.1')
            )}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorIncomeSalaryAmount' },
              }}
              inputProps={{
                autoFocus: activeField === 2,
                placeholder: mortgagors[1].income?.period === 'year' ? '22000' : '1800',
                type: 'integer',
              }}
              onChange={saveAmount}
              value={mortgagors[1].income?.amount?.toString() || ''}
            />
            €
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapIncomePeriodValues(Object.values(IncomePeriod), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomePeriod' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}
      {activeField === 1 && (
        <Choices
          choices={mapIncomeTypeValues(Object.values(IncomeType), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeType' } }}
          onChoiceClick={() => setActiveField(2)}
        />
      )}

      {isBusinessOwner && <Message>{t('sentences.tips.income.businessOwner.comortgagor')}</Message>}
      {mortgagors[1].income?.type === IncomeType.Net && (
        <Message html={t('sentences.tips.income.salary.comortgagor')} />
      )}
      {activeField >= 2 && !!mortgagors[1].income?.amount && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
