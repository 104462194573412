import { breakpoints, g } from '@pretto/bricks/components/layout'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import {
  controllingClassName,
  DocumentIcon as DocumentIconComponent,
} from '@pretto/app-core/application/components/DocumentIcon/DocumentIcon'

import classnames from 'classnames'
import styled from 'styled-components'

export interface UploadAreaProps {
  allowedExtensions: string[]
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  isFirst?: boolean
  maxSize: string
}

const formatExtensions = (extensions: string[]) =>
  [extensions.slice(0, -1).join(', '), ...extensions.slice(-1)].filter(Boolean).join(' et ')

export const UploadArea: React.FC<React.HTMLAttributes<HTMLDivElement> & UploadAreaProps> = ({
  allowedExtensions,
  className,
  inputProps,
  isFirst = false,
  maxSize,
  ...props
}) => (
  <Area {...props} $isFirst={isFirst} className={classnames(controllingClassName, className)} tabIndex={-1}>
    <DocumentIcon />

    <Input {...inputProps} type="file" />

    <Content>
      {isFirst && (
        <>
          <Title>
            <strong>Glissez</strong> vos documents ici ou <em>cliquez pour choisir</em>.
          </Title>

          <Description>
            Fichiers acceptés : {formatExtensions(allowedExtensions)}.
            <br />
            Taille maximale {maxSize}
          </Description>
        </>
      )}

      {!isFirst && (
        <Title>
          <strong>Ajouter un ou des documents</strong>
        </Title>
      )}
    </Content>
  </Area>
)

interface AreaProps {
  $isFirst: boolean
}

const Area = styled.div<AreaProps>`
  ${transition({ propertyName: 'border-color' }, { propertyName: 'box-shadow' })};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border: 2px dashed ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: ${({ $isFirst }) => ($isFirst ? 'column' : 'row')};
  justify-content: ${({ $isFirst }) => ($isFirst ? 'flex-start' : 'center')};
  gap: ${g(2)};
  padding: ${({ $isFirst }) => ($isFirst ? `${g(2)} ${g(3)} ${g(3)};` : `${g(2)}`)};
  position: relative;
  text-align: ${({ $isFirst }) => ($isFirst ? 'center' : 'left')};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${({ $isFirst }) => ($isFirst ? `${g(2)} ${g(3)} ${g(3)};` : `${g(2)} ${g(3)}`)};
  }

  &:hover,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary1};
    box-shadow: 0 ${g(1 / 2)} ${g(2)} 0 ${({ theme }) => addAlpha(theme.colors.neutral1, 0.08)};
  }
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

const Description = styled.p`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
`

const DocumentIcon = styled(DocumentIconComponent)`
  flex-shrink: 0;
`

const Input = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const Title = styled.p`
  ${({ theme }) => theme.typos.body4};

  em {
    ${({ theme }) => theme.typos.body4Underline};
    color: ${({ theme }) => theme.colors.primary1};
  }

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`
