import DashboardHighlightsCard from '@pretto/bricks/app/dashboard/components/DashboardHighlightsCard'

import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'
import { formatDisplayNumber } from '@pretto/app/src/lib/formats'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useCapacityCardQuery } from './capacity.gateway.graphql'

const ACTION_URL = '/capacity'
export const EVENT_SEE_CAPACITY = 'SEE_CAPACITY_PAGE'

const Capacity: React.FC<CardProps> = ({ onActionClick }) => {
  const { data, loading } = useCapacityCardQuery()
  const trackAction = useTracking()

  const dashboardHighlightsProps = {
    highlights: data?.capacity?.map(({ financing, project }) => {
      const loanDuration = ensureNotNil(financing?.loanDuration)
      const propertyPrice = ensureNotNil(project?.purchase?.property_price)

      return {
        title: `${loanDuration / MONTHS_IN_YEAR} ans`,
        value: formatDisplayNumber(propertyPrice, '€'),
      }
    }),
    isLoading: loading,
  }

  const handleActionClick = () => {
    trackAction(EVENT_SEE_CAPACITY, {
      origin: 'dashboard card',
    })
    onActionClick(ACTION_URL)
  }

  return (
    <DashboardHighlightsCard
      actionLabel="Ajuster"
      actionUrl={ACTION_URL}
      dashboardHighlightsProps={dashboardHighlightsProps}
      description="Ajustez votre budget et le prix maximum de votre bien."
      onActionClick={handleActionClick}
      title="Votre budget"
    />
  )
}

export const config: Config = {
  component: Capacity,
  condition: (data, { hasFeatureAccess }) => !hasFeatureAccess(['BUYOUT']),
  name: 'capacity',
}
