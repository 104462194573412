import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { Picto } from '@pretto/picto'

import { useRef } from 'react'

import * as S from './DateField.styles'

export interface DateFieldProps {
  message: React.ReactNode
  value: string
  state?: 'default' | 'error'
  size?: 'medium' | 'large'
  label?: string
  picto?: Picto
  onClear?: () => void
  onChange: (value: string, date: string | null, event: React.ChangeEvent<HTMLInputElement>) => void
  inputAttributes?: React.HTMLAttributes<HTMLInputElement>
}

const DELIMITER = ' / '
const PLACEHOLDER = `JJ${DELIMITER}MM${DELIMITER}AAAA`
const CLEAVE_OPTIONS = { date: true, datePattern: ['d', 'm', 'Y'], delimiter: DELIMITER }

export const DateField = ({
  message,
  value,
  size = 'medium',
  state = 'default',
  label,
  picto,
  onChange,
  onClear,
  inputAttributes,
  ...props
}: DateFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    const dateISO = rawValue.split(DELIMITER).reverse().join('-')
    const dateValue = dateISO.length === 10 ? dateISO : null

    onChange(rawValue, dateValue, event)
  }

  const isClearActive = !!(state === 'default' && value && onClear && !picto)
  const pictoSlug = isClearActive ? 'clear' : state
  const isPictoActive = !!picto || pictoSlug !== 'default'
  const isMessageActive = !!message
  const isLabelActive = !!label

  const handleClear = () => {
    onClear?.()

    inputRef.current?.focus()
  }

  const handleClickLabel = () => {
    inputRef.current?.focus()
  }

  const handleClickPicto = () => {
    inputRef.current?.focus()
  }

  const handleRef = (ref: HTMLInputElement) => {
    inputRef.current = ref
  }

  return (
    <S.Container {...props}>
      <S.Input>
        <S.DateField
          htmlRef={handleRef}
          {...props}
          placeholder={PLACEHOLDER}
          {...inputAttributes}
          value={value}
          $size={size}
          $state={state}
          $pictoSlug={pictoSlug}
          onChange={handleChange}
          options={CLEAVE_OPTIONS}
          $isLabelActive={isLabelActive}
        />
        {isLabelActive && (
          <S.Label $size={size} $isLabel={Boolean(value)} $state={state} onClick={handleClickLabel}>
            {label}
            <S.LabelMask $isLabel={Boolean(value)} />
          </S.Label>
        )}
        {isPictoActive && (
          <S.Picto $picto={picto} $slug={pictoSlug} onClick={isClearActive ? handleClear : handleClickPicto} />
        )}
      </S.Input>

      <CollapsibleRow animateIn in={isMessageActive}>
        <S.Message $pictoSlug={pictoSlug}>{message}</S.Message>
      </CollapsibleRow>
    </S.Container>
  )
}
