export const getMortgagorsStatus = <T extends string>(mortgagorFilter?: T) => {
  switch (mortgagorFilter) {
    case 'mortgagor':
      return { mortgagor: true, comortgagor: false }

    case 'comortgagor':
      return { mortgagor: false, comortgagor: true }

    case 'none':
      return { mortgagor: false, comortgagor: false }

    case 'both':
    default:
      return { mortgagor: true, comortgagor: true }
  }
}
