let storage = localStorage
const profilesUsingSession = ['pro', 'internal']

export const setStorageType = userProfile => {
  storage = profilesUsingSession.includes(userProfile) ? sessionStorage : localStorage
}

export const setItem = (key, value) => {
  return storage.setItem(key, value)
}

export const getItem = key => {
  return storage.getItem(key)
}

export const removeItem = (key, clearAllStores = false) => {
  if (clearAllStores) {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  }
  return storage.removeItem(key)
}
