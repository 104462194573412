import { mapMonths } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/lib/mappers/mapMonths'
import { mapOthersStatus } from '@pretto/app/src/Sentences/v2/ComortgagorOccupation/Sector/lib/mappers/mapOthersStatus'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { Months, OccupationOthersStatus, OccupationType } from '@pretto/app/src/Sentences/v2/types/enums'

interface OthersChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const OthersChoices: React.FC<OthersChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors } = context

  return (
    <>
      {activeField === 1 && mortgagors?.[1].occupationType !== OccupationType.Medical && (
        <Choices
          choices={mapOthersStatus(Object.values(OccupationOthersStatus), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationOthersStatus' },
          }}
          onChoiceClick={() => {
            onSetActive(2)
          }}
        />
      )}
      {((activeField === 2 && mortgagors?.[1].status !== OccupationOthersStatus.Retired) ||
        (activeField === 1 && mortgagors?.[1].occupationType === OccupationType.Medical)) && (
        <Choices
          choices={mapMonths(Object.values(Months), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationNotRetired' },
          }}
          onChoiceClick={() => onSetActive(3)}
        />
      )}
    </>
  )
}
