import { breakpoints, g } from '@pretto/bricks/components/layout'
import SliderComponent from '@pretto/bricks/components/utility/Slider'

import {
  DefaultMessage,
  FollowTooltip as FollowTooltipComponent,
} from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'

import { InfoCircleBold } from '@pretto/picto'

import { useEffect, useState } from 'react'
import styled from 'styled-components'

export interface RowSliderProps {
  label: string
  onChange: (value: number) => void
  step: number
  value: number
  valueMax: number
  valueMaxLabel?: string
  valueMaxTooltip?: string
  valueMin: number
  valueUnit?: string
}

export const RowSlider: React.FC<RowSliderProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>> = ({
  label,
  onChange,
  step,
  value,
  valueMax,
  valueMaxLabel,
  valueMaxTooltip,
  valueMin,
  valueUnit,
  ...props
}) => {
  const [sliderValue, setSliderValue] = useState(value)

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const handleChange = (value: number) => {
    setSliderValue(value)
  }

  return (
    <Row {...props}>
      <Label>
        {label}
        <Value>
          {sliderValue}
          {valueUnit && <span> {valueUnit}</span>}
        </Value>
      </Label>

      <Content>
        <Slider
          max={valueMax}
          min={valueMin}
          onChange={handleChange}
          onAfterChange={onChange}
          step={step}
          value={sliderValue}
        />

        {valueMaxLabel && (
          <ValueMax>
            <ValueMaxLabel>{valueMaxLabel}</ValueMaxLabel>
            {valueMaxTooltip && (
              <FollowTooltip messageComponent={ResizedFollowTooltipComponent} message={valueMaxTooltip}>
                <Tooltip />
              </FollowTooltip>
            )}
          </ValueMax>
        )}
      </Content>
    </Row>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};
`

const FollowTooltip = styled(FollowTooltipComponent)``

const Label = styled.span`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  gap: ${g(1)};
`

const ResizedFollowTooltipComponent = styled(DefaultMessage)`
  z-index: 3;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(38)};
  }
`

const Row = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const Slider = styled(SliderComponent).attrs(({ theme }) => ({
  color: theme.colors.primary1,
  railVariant: 'neutral5',
}))``

const Tooltip = styled(InfoCircleBold)`
  color: ${({ theme }) => theme.colors.neutral2};
  display: block;
`

const Value = styled.span`
  ${({ theme }) => theme.typos.body4Bold};
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.primary1};
  margin-left: auto;
`

const ValueMax = styled.span`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: ${g(1)};
`

const ValueMaxLabel = styled.span`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
`
