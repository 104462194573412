import { g } from '@pretto/bricks/components/layout'

import { css } from 'styled-components'

const FONT_FAMILY = {
  pensum: 'Pensum Pro, sans-serif',
  roboto: 'Roboto, sans-serif',
}

export const isNumber = css`
  font-variant-numeric: tabular-nums;
`

export const heading1 = css`
  font-family: ${FONT_FAMILY.pensum};
  font-size: ${g(10)};
  line-height: ${g(10)};
  font-style: normal;
  font-variant-numeric: tabular-nums;
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: none;
`
export const heading2 = css`
  font-family: ${FONT_FAMILY.pensum};
  font-size: ${g(8)};
  line-height: ${g(8)};
  font-style: normal;
  font-variant-numeric: tabular-nums;
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: none;
`
export const heading3 = css`
  font-family: ${FONT_FAMILY.pensum};
  font-size: ${g(6)};
  line-height: ${g(6)};
  font-style: normal;
  font-variant-numeric: tabular-nums;
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: none;
`
export const heading4 = css`
  font-family: ${FONT_FAMILY.pensum};
  font-size: ${g(4)};
  line-height: ${g(4)};
  font-style: normal;
  font-variant-numeric: tabular-nums;
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: none;
`

export const heading5 = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(2, 2)};
  line-height: ${g(3)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 900;
  letter-spacing: normal;
  text-transform: none;
`

export const heading6 = css`
  font-family: ${FONT_FAMILY.pensum};
  font-size: ${g(2, 2)};
  line-height: ${g(3)};
  font-style: italic;
  font-variant-numeric: tabular-nums;
  font-weight: 450;
  letter-spacing: normal;
  text-transform: none;
`

export const body1 = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(6)};
  line-height: ${g(7)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 900;
  letter-spacing: normal;
  text-transform: none;
`

export const body2 = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(4)};
  line-height: ${g(5)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 900;
  letter-spacing: normal;
  text-transform: none;
`

export const body3 = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(3)};
  line-height: ${g(4)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
`

export const body4 = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(2)};
  line-height: ${g(3)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
`

export const body4Underline = css`
  ${body4};
  text-decoration: underline;
`

export const body4Medium = css`
  ${body4};
  font-weight: 600;
`

export const body4Bold = css`
  ${body4};
  font-weight: 900;
  letter-spacing: 0.02em;
`

export const button = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(2, 2)};
  line-height: ${g(3, 4)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
`

export const buttonUnderline = css`
  ${button};
  text-decoration: underline;
`

export const caption = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(1, 4)};
  line-height: ${g(2)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: none;
`

export const captionUnderline = css`
  ${caption};
  text-decoration: underline;
`

export const link = css`
  font-family: ${FONT_FAMILY.roboto};
  font-size: ${g(1, 4)};
  line-height: ${g(2)};
  font-style: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

export const linkUnderline = css`
  ${link};
  text-decoration: underline;
`
