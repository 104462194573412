import styled, { css } from 'styled-components'

import { BaseTypoStyles } from './typoStyles'

export const BaseTypo = styled.div`
  font-family: ${({ theme }) => theme.font}, sans-serif;
  ${BaseTypoStyles}
  ${({ $isNumber }) =>
    $isNumber &&
    css`
      font-variant-numeric: tabular-nums;
    `}
`
