import Card from '@pretto/bricks/components/cards/Card'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { forwardRef, useMemo, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './styles'
import useAutoComplete, { defaultProps, propTypes } from './useAutoComplete'

const DOWN_KEY_CODE = 40
const ENTER_KEY_CODE = 13
const SPACE_KEY_CODE = 32
const UP_KEY_CODE = 38

const AutoComplete = forwardRef(
  ({ autoFocus, checkboxes, format, icon, selectedValue, onAfterOpen, placeholder, size, type, ...props }, ref) => {
    const handleAfterOpen = () => {
      onAfterOpen(prefilledSearchValue)
      setPrefilledSearchValue('')
    }

    const { component, open } = useAutoComplete({ ...props, onAfterOpen: handleAfterOpen, type })

    if (ref) {
      ref.current = { open }
    }

    const [prefilledSearchValue, setPrefilledSearchValue] = useState('')

    const handleChange = event => {
      setPrefilledSearchValue(event.currentTarget.value)
      open()
    }

    const handleKeyDown = event => {
      if ([DOWN_KEY_CODE, ENTER_KEY_CODE, SPACE_KEY_CODE, UP_KEY_CODE].includes(event.keyCode)) {
        event.preventDefault()
        open()
      }
    }

    const handleOpenMenu = open

    const field = useMemo(
      () => (
        <S.FakeField format={format} isEmpty={!selectedValue} onClick={handleOpenMenu} size={size}>
          <S.FakeFieldValue>{selectedValue ? selectedValue : placeholder}</S.FakeFieldValue>
          {format === 'form' && (
            <S.FakeFieldIcon>
              <Icon name={icon} variant="neutral-1-30" />
            </S.FakeFieldIcon>
          )}
        </S.FakeField>
      ),
      [selectedValue, icon, placeholder, format, size, handleOpenMenu]
    )

    return (
      <S.Field>
        <>
          <S.FakeFieldInput
            autoFocus={autoFocus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="text"
            value=""
          />
          {field}
        </>

        {component}

        {type === 'check' && checkboxes.length > 0 && (
          <S.Checkboxes>
            <Card format="line" variant="neutral-1-20">
              <Content>
                <TransitionGroup>
                  {checkboxes.map(checkbox => (
                    <CollapsibleRow key={checkbox.id} parentComponent={S.Checkbox}>
                      <S.CheckboxContent>
                        <InputCheckbox {...checkbox} />
                      </S.CheckboxContent>
                    </CollapsibleRow>
                  ))}
                </TransitionGroup>
              </Content>
            </Card>
          </S.Checkboxes>
        )}
      </S.Field>
    )
  }
)

AutoComplete.defaultProps = {
  ...defaultProps,
  autoFocus: false,
  checkboxes: [],
  format: 'default',
  icon: 'search',
  placeholder: 'Choisissez',
}

AutoComplete.propTypes = {
  ...propTypes,
  /** Sets focus on mount if true. */
  autoFocus: PropTypes.bool,
  /** Array of props of <code>[Checkbox](/#!/Checkbox)</code> component. */
  checkboxes: PropTypes.array,
  /* Format to be used for styling.  */
  format: PropTypes.oneOf(['default', 'form']),
  /** Icon to be used along with the <code>form</code> format. */
  icon: PropTypes.string,
  /** Field placeholder. */
  placeholder: PropTypes.string,
  /** Current value. */
  selectedValue: PropTypes.string,
  /** Size of input. */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

AutoComplete.displayName = 'AutoComplete'

export default AutoComplete
