import ApplicationFormPage from '@pretto/bricks/app/application/pages/ApplicationFormPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { pages } from '@pretto/app/src/Project/config/leadingSource'
import usePropertyController, { defaultSchema } from '@pretto/app/src/Project/lib/usePropertyController'
import { UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import { useRef, useState } from 'react'

import { useLeadingSourceQuery } from './leadingSource.gateway.graphql'

interface PageProps {
  onTerminate: () => void
}

export const ApplicationPropertyLeadingSourcePage: React.FC<PageProps> = ({ onTerminate }) => {
  const { data, loading } = useLeadingSourceQuery({ fetchPolicy: 'network-only' })

  const hasBeenSubmittedRef = useRef(false)

  const [updateProject] = useMutation(UPDATE_PROJECT)
  const trackAction = useTracking()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPageErrored, setPageErrored] = useState(false)

  const schema = pages.find(({ value }) => value === data?.project?.purchase?.maturity) || defaultSchema

  const handleChange = () => {
    setPageErrored(false)
  }

  const { getPayload, isDisabled, sections, values } = usePropertyController(
    schema,
    data,
    handleChange,
    hasBeenSubmittedRef.current,
    isPageErrored
  )

  const handleSubmit = async () => {
    hasBeenSubmittedRef.current = true

    if (isDisabled) {
      setPageErrored(true)
      return
    }

    setIsSubmitting(true)

    const { lead, project } = getPayload()

    if (project) {
      await updateProject({ variables: { project: JSON.stringify(project) } })
    }

    trackAction('PROPERTY_LEADING_SOURCE_UPDATED', {
      ...lead,
      property_leading_source_maturity: data?.project?.purchase?.maturity,
      ...(data?.project?.purchase?.maturity === 'sale_agreement_signed'
        ? { property_leading_source_notary_declared: values.notary_known === 'true' }
        : {}),
    })

    onTerminate()
  }

  if (loading || isSubmitting) {
    return <SpinnerLegacy overlay />
  }

  const handleGifClick = () => trackAction('APPLICATION_INTRODUCTION_GIF_CLICKED')

  return (
    <ApplicationFormPage
      isNewIntroUI
      onGifClick={handleGifClick}
      hasNextPage
      sections={sections}
      onSubmit={handleSubmit}
      title="Notaire"
    />
  )
}
