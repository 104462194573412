import { breakpoints, g } from '@pretto/bricks/components/layout'

import { Status } from '@pretto/zen/atoms/infos/Status/Status'

import { ChevronRightBold } from '@pretto/picto'

import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
  }
`

export const ActionIcon = styled.div`
  margin-left: ${g(3)};
`

export const Icon = styled(ChevronRightBold)`
  height: ${g(3)};
  width: ${g(3)};
  fill: ${({ theme }) => theme.colors.neutral2};
`

export const Picto = styled(Icon).attrs(({ $picto }) => ({ as: $picto }))`
  fill: ${({ theme }) => theme.colors.neutral2};
`

export const Body = styled.div`
  flex-grow: 1;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${g(2)};
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Description = styled.div`
  margin-top: ${g(1)};
  max-width: 100%;

  a {
    text-decoration: underline;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 75%;
  }
`

export const Footer = styled.div`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`

export const AlertContainer = styled.div`
  padding-left: ${g(2)};
  padding-right: ${g(2)};
  padding-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(3)};
    padding-right: ${g(3)};
    padding-bottom: ${g(3)};
  }
`

export const MeetStatus = styled(Status)`
  background-color: ${({ theme }) => theme.colors.warning2};
  color: ${({ theme }) => theme.colors.warning1};
`
