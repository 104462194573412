import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

import { useEffect, useState } from 'react'

const useLanguageInfo = () => {
  const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(false)
  const [wasLanguageInfoShown, setWasLanguageInfoShown] = useState(
    localStorage.getItem('was_language_info_shown') === 'true'
  )

  const { isEnglishUser } = useUser()

  useEffect(() => {
    if (isEnglishUser && !wasLanguageInfoShown) {
      setIsLanguageInfoOpen(true)
    }
  }, [isEnglishUser])

  const closeLanguageInfo = () => {
    setIsLanguageInfoOpen(false)
    setWasLanguageInfoShown(true)
    localStorage.setItem('was_language_info_shown', 'true')
  }

  return {
    isLanguageInfoOpen,
    closeLanguageInfo,
  }
}

export default useLanguageInfo
