import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled, { css } from 'styled-components'

export const Action = styled.div`
  align-self: flex-end;
  align-items: center;
  display: flex;
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
    margin-top: 0;
  }
`

export const ActionIcon = styled.div`
  margin-left: ${g(2)};
`

export const Maturity = styled.div`
  align-items: center;
  display: flex;
`

export const MaturityEmoji = styled.div`
  margin-right: ${g(2)};
`

export const Update = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    flex-direction: row;
  }
`

export const Content = styled.div`
  z-index: 0;
  padding: ${g(3)};
  padding-right: 10%;
  position: relative;

  ${helpers.shape(
    css`
      background-position: center;
      background-repeat: no-repeat;
      background-size: 554px 633px;
      background-position: top -14px right -100px;
    `,
    { variant: 'primary3' }
  )}

  > img {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 39%;

    > img {
      display: block;
    }
  }
`

export const Description = styled.div`
  margin-bottom: ${g(3)};
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })`
  margin-bottom: ${g(2)};
`

export const Image = styled.img`
  position: absolute;
  right: ${g(15)};
  top: 0px;
  height: 100%;
`

export const ButtonIcon = styled.img`
  height: ${g(5)};
  width: ${g(5)};
`
