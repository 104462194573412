import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as C from './DirectionsClientCard.module.css'

const DirectionsClientCard = ({ title, description, buttonText, onClick }) => (
  <div className={C.directionsClientCard}>
    <Card variant="white" zDepth={2}>
      <Content format="full-height">
        <div className={C.directionsClientCardTitle}>
          <div>
            <SubHeading size="large">{title}</SubHeading>
          </div>
        </div>
        <div className={C.directionsClientCardContent}>{description}</div>
        <div className={C.directionsClientCardButton}>
          <Content>
            <ButtonLegacy format="full" onClick={onClick}>
              {buttonText}
            </ButtonLegacy>
          </Content>
        </div>
      </Content>
    </Card>
  </div>
)

DirectionsClientCard.propTypes = {
  emoji: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(DirectionsClientCard)
