import * as helpers from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Divider as DividerComponent } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { Status as StatusComponent } from '@pretto/zen/atoms/infos/Status/Status'

import styled, { css } from 'styled-components'

export const Error = styled.svg`
  color: ${({ theme }) => theme.colors.error1};
  grid-column: 3;
  grid-row: 1;
  justify-self: right;
`

export const Label = styled.span`
  overflow: hidden;
  padding-left: ${g(2)};
  padding-right: ${g(1)};
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      ${typo.bodyBold16};
      color: ${theme.colors.primary1};
    `}
`
const HEADER_HEIGHT = g(11)
export const Sidebar = styled.div`
  ${helpers.boxShadow('sidebar')};
  position: relative;
  z-index: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT});
  padding-top: ${g(5)};
`

export const SideBarMenuContainer = styled(Link)`
  align-items: center;
  display: grid;
  height: ${g(7)};
  padding-bottom: ${g(1)};
  padding-left: ${g(3)};
  padding-right: ${g(3)};
  padding-top: ${g(1)};
  margin-right: ${g(3)};
  border-radius: 0 ${g(1)} ${g(1)} 0;
  grid-template-columns: auto 1fr auto;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral4Hover};
  }
`

export const Picto = styled.svg`
  border-radius: ${g(1)};
  width: ${g(5)};
  height: ${g(5)};
  padding: ${g(1)};

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary1};
      background-color: ${({ theme }) => theme.colors.neutral4};
      transition: background-color 150ms ease-in-out;

      ${SideBarMenuContainer}:hover && {
        background-color: ${({ theme }) => theme.colors.neutral4Hover};
      }
    `}
`

export const Divider = styled(DividerComponent)`
  margin: ${g(1)} ${g(3)};
`
export const Status = styled(StatusComponent)`
  ${typo.caption12};
  display: none;
  grid-column: 3;
  grid-row: 1;
  height: ${g(2, 4)};
  padding-top: ${g(0, 2)};
  padding-bottom: ${g(0, 2)};
  padding-left: ${g(1)};
  padding-right: ${g(1)};

  ${SideBarMenuContainer}:hover & {
    display: block;
  }

  ${({ $isComplete }) =>
    !$isComplete &&
    css`
      background-color: ${({ theme }) => theme.colors.warning2};
      color: ${({ theme }) => theme.colors.warning1};
    `}
`
