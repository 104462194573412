import { ng } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { rotate } from '@pretto/zen/reveal/atoms/buttons/arrowButton/ArrowButton'

import { useRef } from 'react'

import * as S from './ArrowButtonLabel.styles'

export type Direction = 'right' | 'left'

export type ArrowButtonLabelProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  direction?: Direction
  disabled?: boolean
  href?: string
}

export const ArrowButtonLabel: React.FC<ArrowButtonLabelProps> = ({
  children,
  direction = 'right',
  disabled = false,
  onClick,
  ...props
}) => {
  const { current: id } = useRef(random())

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    onClick?.(event)
  }

  const stateProps = {
    $isDisabled: disabled,
  }

  return (
    <S.Container {...stateProps} {...props} $direction={direction} onClick={handleClick}>
      <S.ArrowButton {...stateProps}>
        <S.Arrow {...stateProps} viewBox={`0 0 ${ng(3)} ${ng(3)}`}>
          <defs>
            <S.ArrowSymbol forwardedAs="symbol" id={id} />
          </defs>

          <g {...rotate(direction)}>
            <S.ArrowInstance1 {...stateProps} xlinkHref={`#${id}`} x={ng(-2)} y="0" />
            <S.ArrowInstance2 {...stateProps} xlinkHref={`#${id}`} x="0" y="0" />
          </g>
        </S.Arrow>
      </S.ArrowButton>

      <span>{children}</span>
    </S.Container>
  )
}
