import { ADDRESS, CITY, DATE, DESIGNATION_BLOCK, ZIPCODE } from '@pretto/app/src/purchaseOffer/config/goodInformation'
import { BlockSentencesInput } from '@pretto/app/src/purchaseOffer/config/types'
import { formatSentences } from '@pretto/app/src/purchaseOffer/lib/formatSentences'
import { useInputDefault } from '@pretto/app/src/purchaseOffer/lib/useInputDefault'
import { useInputTextarea } from '@pretto/app/src/purchaseOffer/lib/useInputTextarea'
import { SectionProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/Section/Section'
import { House } from '@pretto/picto'

export const useGoodInformation = () => {
  const { input: dateInput, ...visitDate } = useInputDefault(DATE)
  const { input: addressInput, ...goodAddress } = useInputDefault(ADDRESS)
  const { input: zipcodeInput, ...goodZipcode } = useInputDefault(ZIPCODE)
  const { input: cityInput, ...goodCity } = useInputDefault(CITY)
  const { input: designationBlock, ...designation } = useInputTextarea(DESIGNATION_BLOCK)

  const sentences: BlockSentencesInput = {
    props: {
      picto: House,
      sentences: formatSentences([
        ['Vous avez visité le bien le ', dateInput, '.'],
        ['Ce bien est situé au ', addressInput, ', ', zipcodeInput, ' ', cityInput, '.'],
      ]),
    },
    type: 'sentences',
  }

  const section: SectionProps = {
    blocks: [sentences, designationBlock],
    title: 'Informations sur le bien',
  }

  const fields = {
    designation,
    goodAddress,
    goodCity,
    goodZipcode,
    visitDate,
  }

  return { fields, section }
}
