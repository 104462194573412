import Responsive from '@pretto/bricks/components/utility/Responsive'

import { Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Calendar/Calendar'
import { t } from '@pretto/app/src/lib/i18n'
import { Camera, Pen, Phone } from '@pretto/picto'

import { Trans } from 'react-i18next'

import * as S from './Hero.styles'

export interface HeroProps {
  onModify: () => void
  selectedSlot?: Slot
  switchVisio: {
    isToggled: boolean
    onToggle: () => void
  }
}
export const Hero: React.FC<HeroProps> = ({ selectedSlot, switchVisio, onModify }) => {
  const name = `${selectedSlot?.advisor.firstName} ${selectedSlot?.advisor.lastName}`

  const isVisioEnabled = selectedSlot?.advisor.isVisio

  return (
    <S.HeroConfirm>
      <Responsive min="laptop">
        <S.IconContainer>
          <S.Icon $picto={isVisioEnabled && switchVisio.isToggled ? Camera : Phone} />
        </S.IconContainer>
      </Responsive>
      <S.AppointmentInfo>
        <S.Appointment>
          <Responsive max="laptop">
            <S.AppointmentSlot>
              <Trans
                i18nKey="mutualizedAgenda.subscribe.appointmentInfo"
                values={{ appointmentDate: selectedSlot?.label }}
              />
            </S.AppointmentSlot>
          </Responsive>
          <Responsive min="laptop">
            <span>
              <Trans
                i18nKey="mutualizedAgenda.subscribe.appointmentInfo"
                values={{ appointmentDate: selectedSlot?.label }}
              />
            </span>
            <S.Check />
          </Responsive>
          <S.Modify onClick={onModify} label={t('mutualizedAgenda.subscribe.edit')} picto={Pen} />
        </S.Appointment>
        {isVisioEnabled && (
          <S.VisioContainer>
            <Responsive max="laptop">
              <div>
                {t('mutualizedAgenda.subscribe.videoCallBook')}
                <br />
                <Trans
                  i18nKey="mutualizedAgenda.subscribe.videoCallName"
                  values={{ name }}
                />
              </div>
            </Responsive>
            <Responsive min="laptop">
              <span>
                {t('mutualizedAgenda.subscribe.videoCallBook')}
                <Trans
                  i18nKey="mutualizedAgenda.subscribe.videoCallName"
                  values={{ name }}
                />
              </span>
            </Responsive>
            <S.SwitchVisio id="visio-subscribe" {...switchVisio} />
          </S.VisioContainer>
        )}
      </S.AppointmentInfo>
    </S.HeroConfirm>
  )
}
