import { useEffect, useState } from 'react'

interface TrustpilotData {
  numberOfReviews: string | number
  score: number
}

const DEFAULT_TRUSTPILOT_DATA: TrustpilotData = {
  numberOfReviews: `+ de ${(9500).toLocaleString('fr')}`,
  score: 4.8,
}

const createTrustpilot = (): {
  abort: () => void
  load: () => Promise<TrustpilotData>
} => {
  const controller = new AbortController()
  const signal = controller.signal

  const load = async () => {
    try {
      const promise = await fetch(
        `https://api.trustpilot.com/v1/business-units/${process.env.TRUSTPILOT_BUSINESS_UNIT_ID}?apikey=${process.env.TRUSTPILOT_API_KEY}`,
        { signal }
      )

      const { numberOfReviews, score } = await promise.json()

      if (typeof numberOfReviews?.total !== 'number') {
        throw new Error('TrustpilotData numberOfReviews is not a number')
      }

      if (typeof score?.trustScore !== 'number') {
        throw new Error('TrustpilotData score is not a number')
      }

      return {
        numberOfReviews: numberOfReviews.total,
        score: score.trustScore,
      }
    } catch (error) {
      return DEFAULT_TRUSTPILOT_DATA
    }
  }

  const abort = () => {
    controller.abort()
  }

  return { abort, load }
}

export const useTrustpilotData = () => {
  const [trustpilotData, setTrustpilotData] = useState<TrustpilotData>(DEFAULT_TRUSTPILOT_DATA)

  useEffect(() => {
    const { abort, load } = createTrustpilot()

    load().then(setTrustpilotData)

    return () => {
      abort()
    }
  }, [])

  return trustpilotData
}
