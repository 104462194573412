import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const getProps = (status, props) => {
  return {
    ...TEMPLATES[status],
    ...props,
  }
}

export const TEMPLATES = {
  appointment: {
    backgroundColor: 'neutral4',
    buttonText: 'Prendre RDV avec un expert',
    buttonTextMobile: 'Prendre RDV',
    illustration: 'sensei_key',
    text: (
      <>
        Affinez <S.Free>gratuitement</S.Free> votre profil emprunteur avec un Expert crédit dédié. Bénéficiez de
        conseils personnalisés pour préparer votre projet en toute sérénité.
      </>
    ),
    title: 'Un expert vous accompagne',
  },
  certificate: {
    backgroundColor: 'neutral4',
    buttonText: 'Complétez vos informations',
    buttonTextMobile: 'Complétez vos infos',
    illustration: 'woman_with_certificate',
    text: "L'arme secrète pour vos visites. Rassurez vendeur ou agent sur votre capacité à trouver un financement. Délivrée par Pretto, elle est gratuite et sans engagement !",
    title: 'votre attestation de financement',
  },
}

const BlockInfoCTA = ({ href, status, ...props }) => {
  const { backgroundColor, buttonText, buttonTextMobile, illustration, text, title } = getProps(status, props)

  return (
    <S.Container $backgroundColor={backgroundColor} $illustration={illustration} {...props}>
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
      <Responsive min="mobileL">
        <S.CTA isSecondary href={href}>
          {buttonText}
        </S.CTA>
      </Responsive>
      <Responsive max="mobileL">
        <S.CTA isSecondary href={href}>
          {buttonTextMobile ?? buttonText}
        </S.CTA>
      </Responsive>
    </S.Container>
  )
}

BlockInfoCTA.propTypes = {
  href: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.keys(TEMPLATES)).isRequired,
}

export default BlockInfoCTA
