import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const Information = styled(BaseTypo).attrs({ typo: 'caption' })`
  margin-top: ${g(3)};
  color: ${({ theme }) => theme.colors.neutral2};

  a {
    text-decoration: underline;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`
