import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Placeholder from '@pretto/bricks/components/loading/Placeholder'

import * as S from './styles'

const PreLeadPlaceholderCard = () => (
  <Card format={['line', 'full-height']} variant="neutral-1-20">
    <Content>
      <S.Grid>
        <S.Title>
          <Placeholder color="neutral1" />
        </S.Title>
        <S.Button>
          <Placeholder color="accent1" />
        </S.Button>
      </S.Grid>
    </Content>
  </Card>
)

export default PreLeadPlaceholderCard
