import { breakpoints, g } from '@pretto/bricks/components/layout'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { TextArea } from '@pretto/zen/atoms/textFields/TextArea/TextArea'
import { FeedbackInput } from '@pretto/zen/dashboard/feedback/FeedbackInput/FeedbackInput'

import { useState } from 'react'
import styled from 'styled-components'

export interface BlockFeedbackProps {
  onSubmit: (comment: string) => void
  onClickInput: (index: number) => void
  defaultActiveIndex?: number
}

const feedbackInputs = [<>&#128534;</>, <>&#128577;</>, <>&#128578;</>, <>&#128516;</>]

export const BlockFeedback: React.FC<BlockFeedbackProps> = ({
  onSubmit,
  onClickInput,
  defaultActiveIndex = null,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(defaultActiveIndex)
  const [comment, setComment] = useState<string>('')

  const handleChangeComment = (value: string) => {
    setComment(value)
  }

  const handleClickInput = (index: number) => {
    onClickInput(index)
    setActiveIndex(index)
  }

  const isCommentOpen = typeof activeIndex === 'number'

  return (
    <Container {...props}>
      <Main>
        <p>
          <strong>
            Aidez-nous à nous améliorer : indiquez si vous avez assez de visibilité sur l’avancée de votre projet.
          </strong>{' '}
          <Indication>(Vous pourrez changer votre note à votre prochaine visite)</Indication>
        </p>

        <Inputs>
          {feedbackInputs.map((input, index) => (
            <Input key={index} isActive={index === activeIndex} onClick={handleClickInput.bind(null, index)}>
              {input}
            </Input>
          ))}
        </Inputs>
      </Main>

      <CollapsibleRow in={isCommentOpen} appear>
        <Comment
          value={comment}
          onChange={handleChangeComment}
          label=""
          theme="color"
          isCharacterCountActive={false}
          placeholder="Comment pouvons-nous nous améliorer ? (facultatif)"
        />

        <Submit onClick={onSubmit.bind(null, comment)}>Envoyer</Submit>
      </CollapsibleRow>
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${g(1)};
  padding: ${g(3)};
  background-color: ${({ theme }) => theme.colors.neutral4};
`
const Main = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    gap: ${g(2)};
  }
`
const Indication = styled.span``
const Inputs = styled.div`
  display: flex;
  margin-top: ${g(2)};
  gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
    margin-top: 0;
  }
`
const Input = styled(FeedbackInput)`
  flex-grow: 1;
  padding: ${g(2)} ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)};
  }
`
const Comment = styled(TextArea)`
  margin-top: ${g(3)};
`
const Submit = styled(Button)`
  margin-top: ${g(2)};
`
