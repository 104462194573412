import { ng } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { useRef } from 'react'

import * as S from './ArrowButton.styles'

type Direction = 'up' | 'right' | 'bottom' | 'left'

export type ArrowButtonProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  direction?: Direction
  disabled?: boolean
  href?: string
}

export const rotate = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return { transform: `rotate(270 ${ng(3 / 2)} ${ng(3 / 2)})` }

    case 'right':
      return undefined

    case 'bottom':
      return { transform: `rotate(90 ${ng(3 / 2)} ${ng(3 / 2)})` }

    case 'left':
    default:
      return { transform: `rotate(180 ${ng(3 / 2)} ${ng(3 / 2)})` }
  }
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  direction = 'right',
  disabled = false,
  onClick,
  ...props
}) => {
  const { current: id } = useRef(random())

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    onClick?.(event)
  }

  const stateProps = {
    $isDisabled: disabled,
  }

  return (
    <S.Container {...stateProps} {...props} onClick={handleClick}>
      <S.Arrow {...stateProps} viewBox={`0 0 ${ng(3)} ${ng(3)}`}>
        <defs>
          <S.ArrowSymbol forwardedAs="symbol" id={id} />
        </defs>

        <g {...rotate(direction)}>
          <S.ArrowInstance1 {...stateProps} xlinkHref={`#${id}`} x={ng(-2)} y="0" />
          <S.ArrowInstance2 {...stateProps} xlinkHref={`#${id}`} x="0" y="0" />
        </g>
      </S.Arrow>
    </S.Container>
  )
}
