import groupBy from 'lodash/groupBy'

export const getDocumentsGroups = documents =>
  Object.values(groupBy(documents, ({ groupIndex, groupTitle }) => groupIndex + groupTitle)).map(documents => {
    const subtitle = documents[0].groupSubtitle
    const title = documents[0].groupTitle

    return {
      documents: documents.map(({ href, mortgagorName, status, title }) => ({
        href,
        mortgagor: mortgagorName,
        status,
        title,
      })),
      subtitle,
      title,
    }
  })
