import PropTypes from 'prop-types'

import * as S from './styles.js'

const Heading = ({ children, size, variant, format, type, ...props }) => (
  <S.HeadingBridge $color={variant} $format={format} as={type} size={size} {...props}>
    {children}
  </S.HeadingBridge>
)

Heading.propTypes = {
  /** Children of heading */
  children: PropTypes.node.isRequired,
  /** Format style of Heading */
  format: PropTypes.string,
  /** Font-size of heading: <code>small</code>, <code>medium</code>, <code>large</code> */
  size: PropTypes.string,
  /** DOM type to be rendered */
  type: PropTypes.string,
  /** Variant color of Heading */
  variant: PropTypes.string,
}

Heading.defaultProps = {
  format: 'default',
  size: 'medium',
  type: 'div',
  variant: 'inherit',
}

export default Heading
