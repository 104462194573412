// Fields
const firstname = {
  fieldProps: {
    placeholder: 'Simone',
  },
  label: 'Son prénom',
  name: 'firstName',
  type: 'textfield',
}

const lastname = {
  fieldProps: {
    placeholder: 'de Beauvoir',
  },
  label: 'Son nom',
  name: 'lastName',
  type: 'textfield',
}

const email = {
  fieldProps: {
    error: (value, { user: { email } }) =>
      value.toLowerCase() === email.toLowerCase() && 'Les emails des emprunteurs ne peuvent pas être identiques',
    icon: 'mail',
    placeholder: 'simone.debeauvoir@gmail.com',
  },
  label: 'Son mail',
  name: 'email',
  type: 'email',
}

const phone = {
  fieldProps: {
    icon: 'phone',
    placeholder: '07 12 34 56 78',
  },
  label: 'Son numéro de téléphone',
  name: 'phone',
  type: 'tel',
}

// Sections
const invitationSection = {
  description:
    'Invitez votre co-emprunteur pour lui permettre de remplir ses informations et de modifier votre projet.\n\nUne invitation sera envoyée par mail pour lui permettre de se connecter à son compte Pretto et rejoindre votre projet immobilier.',
  fields: [firstname, lastname, email, phone],
  helper: 'Consultez notre article de FAQ concernant le partage de votre projet.',
  helperCta: {
    href: 'https://faq.pretto.fr/fr/articles/833730',
    label: 'Consulter',
  },
}

export default invitationSection
