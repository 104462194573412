import { pathJoin } from '@pretto/bricks/core/utility/path/pathJoin'

const BASE_URL = 'https://res.cloudinary.com/pretto-fr/image/upload'

const DEFAULT_OPTIONS = {
  quality: 'auto',
}

const MATCHING_OPTIONS_ARGUMENT = {
  aspectRatio: 'ar',
  crop: 'c',
  effect: 'e',
  format: 'f',
  flag: 'fl',
  gravity: 'g',
  height: 'h',
  quality: 'q',
  radius: 'r',
  width: 'w',
}

export const getCloudinaryUrl = (path, options = {}) => {
  const flattenOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  }

  const inlineOptions = Object.entries(flattenOptions)
    .map(([option, value]) => {
      const argument = MATCHING_OPTIONS_ARGUMENT[option] ?? option

      return `${argument}_${value}`
    })
    .join(',')

  const url = pathJoin(BASE_URL, inlineOptions, path)

  return url
}
