import { roundNumber } from '@pretto/bricks/core/utility/formatters'
import { MONTHS_IN_YEAR } from '../../lib/constants'
const RATE_DIVIDER = 100
const ROUND_TO = 100

export const calcSavings = (insuranceRate, delegationInsuranceRate, remainingPrincipal, remainingDuration) => {
  const savings =
    ((insuranceRate - delegationInsuranceRate) / RATE_DIVIDER) *
    ((remainingPrincipal * remainingDuration) / MONTHS_IN_YEAR)
  return Math.round(savings / ROUND_TO) * ROUND_TO
}

export const calcInterests = (amount, rate, duration) => {
  const monthlyRate = rate / MONTHS_IN_YEAR / RATE_DIVIDER
  return roundNumber(amount * ((monthlyRate * duration) / (1 - (1 + monthlyRate) ** -duration) - 1))
}
