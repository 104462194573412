import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import temporal from '@pretto/bricks/core/utility/temporal'

import { FieldsValues, FormattedValues, Gender, Values } from '@pretto/app/src/purchaseOffer/config/types'

import writtenNumber from 'written-number'

const date = temporal().format('DD/MM/YYYY')
writtenNumber.defaults.lang = 'fr'

const formatName = (firstName: string, lastName: string): string => `${firstName} ${lastName}`

const pluralItems = {
  engage: { 1: 'm’engage', 2: 'nous engageons' },
  personnal: { 1: 'mes', 2: 'nos' },
  meOrUs: { 1: 'me', 2: 'nous' },
  pray: { 1: 'prie', 2: 'prions' },
  subject: { 1: 'je', 2: 'nous' },
  undersigned: {
    1: (gender: Gender): string => `soussigné${gender === 'female' ? 'e' : ''}`,
    2: (gender: Gender, cobuyerGender: Gender): string =>
      `soussigné${[gender, cobuyerGender].every(gender => gender === 'female') ? 'es' : 's'}`,
  },
  wish: { 1: 'souhaite', 2: 'souhaitons' },
}

const generateContent = ({
  buyers,
  cobuyerGender,
  condition,
  currentAddress,
  currentCity,
  currentZipcode,
  designation,
  email,
  gender,
  goodAddress,
  goodCity,
  goodZipcode,
  hasCobuyer,
  price,
  visitDate,
}: FormattedValues): string => {
  // not the most optimal approach for type safe execution
  const n = hasCobuyer ? 2 : 1
  const subject = pluralItems.subject[n]
  const wish = pluralItems.wish[n]
  const engage = pluralItems.engage[n]
  const undersigned =
    hasCobuyer && cobuyerGender ? pluralItems.undersigned[2](gender, cobuyerGender) : pluralItems.undersigned[1](gender)
  const personnal = pluralItems.personnal[n]
  const meOrUs = pluralItems.meOrUs[n]
  const pray = pluralItems.pray[n]

  const messages = [
    `Madame, Monsieur,`,
    `Suite à la visite du bien immobilier situé ${goodAddress}, ${goodZipcode} ${goodCity} le ${visitDate}, ${subject} ${wish} vous faire une offre d’achat. \nCette offre d’achat s’élève au prix de **${formatNumber(
      price,
      { suffix: '€' }
    )}** (${writtenNumber(
      price
    )} euros). Montant qui sera payé intégralement le jour de la signature de l’acte authentique chez le notaire.`,
    `${subject} ${undersigned} ${buyers.join(
      ' et '
    )}, ${engage} à acquérir ce bien immobilier dont les caractéristiques sont les suivantes :`,
    `${designation}`,
    condition &&
      `Cette offre est soumise aux conditions suspensives ci-dessous dont les modalités seront précisées dans le compromis ou la promesse :`,
    condition,
    `Cette offre est effective dès la réception de celle-ci pour une durée de 10 jours. En l’absence d’une réponse après ce délai, cette proposition d’achat sera considérée comme caduque.`,
    `En cas d’acceptation de cette offre, vous pouvez ${meOrUs} contacter par email ou par voie postale : \n${email}\n${currentAddress}, ${currentZipcode} ${currentCity}`,
    `Dans l’attente de votre retour, ${subject} vous ${pray} d’agréer, Madame, Monsieur, l’expression de ${personnal} salutations distinguées.`,
  ]
  return messages.filter(Boolean).join('\n\n')
}

const formatFieldsObject = (fields: FieldsValues): Values =>
  Object.entries(fields).reduce((previous, [key, field]) => {
    if (field === undefined) {
      return previous
    }

    return {
      ...previous,
      [key]: field.value,
    }
  }, {} as Values)

export function encodeData({ email, fields }: { email: string; fields: FieldsValues }) {
  const fieldsObject = formatFieldsObject(fields)

  const {
    cobuyerFirstName,
    cobuyerLastName,
    currentAddress,
    currentCity,
    currentZipcode,
    firstName,
    hasCobuyer,
    lastName,
  } = fieldsObject

  const buyer = formatName(firstName, lastName)
  const buyers =
    hasCobuyer && cobuyerFirstName && cobuyerLastName ? [buyer, formatName(cobuyerFirstName, cobuyerLastName)] : [buyer]

  const contentData = { buyers, email, ...fieldsObject }
  const content = generateContent(contentData)

  const data = {
    address: currentAddress,
    buyers,
    city: `${currentZipcode} ${currentCity}`,
    content,
    date,
    email,
  }

  return data
}
