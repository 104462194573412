/* eslint-disable no-bitwise */
export const random = (): string => shorthash(Math.random().toString())

export const shorthash = (text: string): string => {
  let hash = 5381
  let index = text.length

  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index)
  }

  return (hash >>> 0).toString(16)
}
