/* globals analytics */
import { getUserEmail, getUserProfile, getUserVia } from '@pretto/app/src/Auth/lib/helpers'
import Front from '@pretto/app/src/Helpers/frontchat'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { getItem } from '@pretto/app/src/config/itemStorage'
import { debug } from '@pretto/app/src/lib/logger'

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const GOOGLE_ADS_SLUG = 'gclid'
export const GOOGLE_ANALYTICS_SLUG = 'clientId'
export const BING_ADS_SLUG = 'msclkid'

export const isDebugMode =
  (process.env.NODE_ENV === 'development' || Boolean(getItem('playwright')) === true) &&
  Boolean(process.env.ENABLE_TRACKING) === false

export const debugTracking = (context: string, ...trackingArgs: unknown[]) => {
  debug(`[debug] Tracking: (${context})`, ...trackingArgs)
}

const useTrackingContext = () => {
  const { projectID, isEnglishUser } = useUser()

  return {
    projectId: projectID,
    source: getUserVia(),
    userEmail: getUserEmail(),
    displayLang: isEnglishUser ? 'en' : 'fr',
  }
}

interface TrackAction {
  (name: string, payload?: Record<string, unknown>, options?: Record<string, unknown>): void
}

// Context may not be present in case of legacy non-hooked components. Migrate them to functional first
export const trackAction: TrackAction = (name, payload = {}, options) => {
  const trackingArgs = [name, { ...payload, source: getUserProfile() }, options]

  if (isDebugMode) {
    debugTracking('trackAction', ...trackingArgs)
  } else {
    analytics.track(...trackingArgs)
  }
}

export const useTracking = () => {
  const traits = useTrackingContext()
  const track: TrackAction = (name, properties = {}) => {
    trackAction(name, properties, { traits })
  }
  return track
}

export const TrackPageView = () => {
  const traits = useTrackingContext()
  const location = useLocation()

  useEffect(() => {
    analytics.page({}, { traits })
  }, [location])

  return null
}

export const invalidateTracking = () => {
  const { mixpanel } = window

  if (mixpanel) mixpanel.reset()
  analytics.reset()
  Front('reset')
}

if (isDebugMode) {
  analytics.debug()
}
