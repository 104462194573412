import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import translation from '@pretto/app/src/Sentences/v2/config/translations/fr'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'ramda'

export const EcoPtzPage: React.FC = () => {
  const context = useSentences()
  const { setContext, hasEcoPtz } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    hasEcoPtz ? 1 : 0,
    Boolean(nextRoute && !isNil(hasEcoPtz) && hasEcoPtz)
  )

  const mapEcoPtzValues = (values: [key: string, value: string][]) => {
    return values.map(([key, value]) => {
      return {
        key,
        value,
        isSelected: (key === 'yes' && hasEcoPtz) || (key === 'no' && !hasEcoPtz && !isNil(hasEcoPtz)),
        onClick: () => {
          setContext({ hasEcoPtz: key === 'yes' })
          setActiveField(key === 'yes' ? 1 : 0)
          if (key === 'no') goToNextRoute()
        },
        label: t(`sentences.property.ecoPtz.values.${key as 'yes' | 'no'}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'ecoPtz' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.property.ecoPtz.sentences.0')}
        <Prompt isFilled={!isNil(hasEcoPtz)} onClick={() => setActiveField(0)}>
          {t(`sentences.property.ecoPtz.values.${hasEcoPtz || isNil(hasEcoPtz) ? 'yes' : 'no'}`)}
        </Prompt>
        {t('sentences.property.ecoPtz.sentences.1')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapEcoPtzValues(Object.entries(translation.sentences.property.ecoPtz.values))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'ecoPtz' } }}
        />
      )}
      <Message>{t('sentences.tips.ecoPtz')}</Message>
      {!isNil(hasEcoPtz) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
