import { ErrorInvalidType } from '@pretto/bricks/core/utility/error/types'

const filterEmptyString = string => string !== ''
const stripEdgeSlashes = string => string.replace(/^[\s]*\/+|\/+[\s]*$/g, '')

export const pathJoin = (...args) => {
  const invalidArgumentIndex = args.findIndex(argument => typeof argument !== 'string')

  if (invalidArgumentIndex > -1) {
    throw new ErrorInvalidType(args[invalidArgumentIndex], invalidArgumentIndex + 1, 'string')
  }

  const joinedPath = args.map(stripEdgeSlashes).filter(filterEmptyString).join('/')

  return joinedPath
}
