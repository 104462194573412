import DashboardMaturityCard from '@pretto/bricks/app/dashboard/components/DashboardMaturityCard'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import type { Config } from '@pretto/app/src/dashboard/types/card'

const Maturity = () => {
  const { maturity } = useUser()

  return (
    <DashboardMaturityCard
      description="Prenez le temps de mettre à jour l’avancement de votre projet pour que nous puissions vous donner des conseils adaptés à votre situation."
      maturity={maturity}
    />
  )
}

export const config: Config = {
  component: Maturity,
  condition: (data, { hasFeatureAccess }) => !hasFeatureAccess(['BUYOUT']),
  name: 'maturity',
}
