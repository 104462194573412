import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled from 'styled-components'

export interface ButtonChoiceProps {
  children: React.ReactNode
  isSelected?: boolean
}

interface ButtonProps {
  $isSelected: boolean
}

export const ButtonChoice: React.FC<ButtonChoiceProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  isSelected = false,
  ...props
}) => (
  <Button $isSelected={isSelected} {...props}>
    {children}
  </Button>
)

const Button = styled.button<ButtonProps>`
  ${({ theme }) => theme.typos.body4};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.white : theme.colors.neutral1)};
  padding: ${g(1, 3)} ${g(2)};
  border-radius: ${g(1)};
  border: 1px solid ${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary1 : theme.colors.neutral2)};
  box-shadow: 0px 2px 0px 0px
    ${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary1 : theme.colors.neutral2)};
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary2 : theme.colors.white)};
  ${transition({ propertyName: 'all' })};

  &:not(:disabled) {
    cursor: pointer;
  }
`
