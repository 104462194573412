import { boxShadow } from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { ArrowRight } from '@pretto/picto'

import styled, { css } from 'styled-components'

import type { Direction } from './ArrowButtonLabel'

interface DirectionProps {
  $direction: Direction
}

interface StateProps {
  $isDisabled: boolean
}

interface ContainerProps extends DirectionProps, StateProps {
  href?: string
  tabIndex?: number
}

export const Container = styled.span.attrs<ContainerProps>(({ $isDisabled, href, tabIndex }) => ({
  as: !href ? 'button' : Link,
  disabled: !href ? $isDisabled : undefined,
  tabIndex: $isDisabled ? -1 : tabIndex,
}))<ContainerProps>`
  ${({ theme }) => theme.typos.body4};
  appearance: none;
  color: ${({ $isDisabled, theme }) => ($isDisabled ? theme.colors.neutral1Disabled : theme.colors.neutral1)};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  border: none;
  display: inline-flex;
  flex-direction: ${({ $direction }) => ($direction === 'right' ? 'row-reverse' : 'row')};
  gap: ${g(2)};
  line-height: ${g(6)};
  white-space: nowrap;
  ${transition({ propertyName: 'color' })};

  ${({ $isDisabled, theme }) =>
    !$isDisabled &&
    css`
      &&:hover,
      &&:focus {
        color: ${theme.colors.neutral1Hover};
      }
    `}
`

export const Arrow = styled.svg<StateProps>`
  flex-shrink: 0;
  height: ${g(3)};
  overflow: visible;
  width: ${g(3)};
`

export const ArrowButton = styled.span<StateProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  border-radius: ${g(1)};
  display: inline-flex;
  height: ${g(6)};
  width: ${g(6)};
  ${transition({ propertyName: 'box-shadow' })};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${boxShadow('card')};
    `}
`

export const ArrowInstance = styled.svg.attrs({ as: 'use' })<StateProps>`
  ${transition(
    { propertyName: 'opacity' },
    { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'transform' }
  )};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        transform: translateX(${g(2)});
      }
    `}
`

export const ArrowInstance1 = styled(ArrowInstance)<StateProps>`
  opacity: 0;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 1;
      }
    `}
`

export const ArrowInstance2 = styled(ArrowInstance)<StateProps>`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 0;
      }
    `};
`

export const ArrowSymbol = styled(ArrowRight)``
