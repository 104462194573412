import { gql } from '@apollo/client'

export const POSTPONE_APPOINTMENT = gql`
  mutation PostponeAppointment($reason: NoCallReasonType!) {
    postpone_appointment(reason: $reason) {
      error
      success
    }
  }
`

export const BOOK_APPOINTMENT = gql`
  mutation BookAppointment($startTime: DateAndTime!, $kind: String!, $isVisio: Boolean) {
    book_appointment(start_time: $startTime, kind: $kind, is_visio: $isVisio) {
      success
    }
  }
`

export const REFERRAL_BOOK = gql`
  mutation ReferralBook($startTime: DateTimeType!) {
    referral_book(start_time: $startTime) {
      error
      success
    }
  }
`

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment {
    cancel_appointment {
      success
    }
  }
`

export const CALL_BACK_NOW = gql`
  mutation CallBackNow {
    call_back_now {
      success
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($project: String!) {
    update_project(project: $project) {
      success
      error
    }
  }
`

export const CONFIRM_UPLOAD = gql`
  mutation ConfirmUpload($key: String!, $kindHint: String!, $meta: DocsMeta!, $originalFilename: String!) {
    confirm_upload(key: $key, kind_hint: $kindHint, meta: $meta, original_filename: $originalFilename) {
      success
      error
      fileId
    }
  }
`
export const REMOVE_DOCUMENT = gql`
  mutation DeleteUpload($id: String!) {
    delete_upload(id: $id) {
      success
      error
    }
  }
`

export const UPDATE_OFFER_ACCEPTED = gql`
  mutation UpdateOfferAccepted($project: String!) {
    offer_accepted(project: $project) {
      success
      error
    }
  }
`

export const INVITE_COMORTGAGOR = gql`
  mutation inviteComortgagor($firstName: String!, $lastName: String!, $phone: String!, $email: String!) {
    invite_mortgagor(first_name: $firstName, last_name: $lastName, phone: $phone, email: $email) {
      error
      success
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: String!, $payload: UpdateAccountParams) {
    update_account(id: $id, payload: $payload) {
      success
    }
  }
`

export const GENERATE_SIGN_URL = gql`
  mutation GenerateSignUrl {
    generate_sign_url {
      url
      error
    }
  }
`

export const SUBMIT_PROJECT = gql`
  mutation SubmitProject {
    submit_project {
      error
      success
    }
  }
`

export const SIGN_SEPA = gql`
  mutation SignSepa($firstName: String!, $lastName: String!, $email: String!, $iban: String!) {
    sign_sepa(first_name: $firstName, last_name: $lastName, email: $email, iban: $iban) {
      error
      reference
      success
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $attributionCookieFirstClick: String
    $attributionCookieLastClick: String
    $email: String!
    $source: String
    $visitorId: String
  ) {
    create_account(
      attribution_cookie_first_click: $attributionCookieFirstClick
      attribution_cookie_last_click: $attributionCookieLastClick
      email: $email
      source: $source
      visitor_id: $visitorId
    ) {
      error
      token
      userId
    }
  }
`

export const ASSIGN_PROJECT = gql`
  mutation AssignProject($id: String!) {
    assign_project(project_id: $id) {
      error
    }
  }
`

export const SUBSCRIBE_RATE_ALERT = gql`
  mutation SubscribeRateAlert {
    subscribe_rate_alert {
      error
    }
  }
`
export const UNSUBSCRIBE_RATE_ALERT = gql`
  mutation UnsubscribeRateAlert {
    unsubscribe_rate_alert {
      success
    }
  }
`
export const REFERRAL_SIGNUP = gql`
  mutation ReferralSignup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $idReferral: String!
  ) {
    referral_signup(
      firstname: $firstName
      lastname: $lastName
      email: $email
      phone: $phone
      referrer_id: $idReferral
    ) {
      error
      token
    }
  }
`
export const REFER_A_FRIEND = gql`
  mutation ReferAFriend($firstname: String!, $lastname: String!, $email: String!, $phone: String!) {
    new_referFriend(firstname: $firstname, lastname: $lastname, email: $email, phone: $phone) {
      id
      totalGains
      totalReferees
      referees {
        addTime
        name
        status
      }
    }
  }
`

export const VALIDATE_ONBOARDING = gql`
  mutation HandleOnboardingDoneResponse {
    onboarding_done {
      success
    }
  }
`

export const UNLINK_ACCOUNT = gql`
  mutation UnlinkAccount {
    unlinkProject {
      id
      typology
    }
  }
`

export const REOPEN_OPPORTUNITY = gql`
  mutation ReopenOpportunity {
    reopenOpportunity {
      id
      status
    }
  }
`

export const APPLY_WITH_APPOINTMENT = gql`
  mutation ApplyWithAppointment(
    $advisorId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $startTime: DateTime!
    $utm: String!
    $abTest: String
    $attributionCookieFirstClick: String
    $attributionCookieLastClick: String
    $isVisio: Boolean
    $visitorId: String
    $kind: appointment_kind!
  ) {
    applyWithAppointment(
      advisorId: $advisorId
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      startTime: $startTime
      utm: $utm
      abTest: $abTest
      attributionCookieFirstClick: $attributionCookieFirstClick
      attributionCookieLastClick: $attributionCookieLastClick
      isVisio: $isVisio
      visitorId: $visitorId
      kind: $kind
    ) {
      accessToken
      error
    }
  }
`

export const REASSIGN_WITH_APPOINTMENT = gql`
  mutation ReassignWithAppointment(
    $advisorId: String!
    $startTime: DateTime!
    $isVisio: Boolean
    $kind: appointment_kind!
  ) {
    reassignWithAppointment(advisorId: $advisorId, startTime: $startTime, isVisio: $isVisio, kind: $kind) {
      success
      error
    }
  }
`

export const TRACK_EVENT = gql`
  mutation TrackEvent($eventName: String!, $projectId: String!, $payload: String) {
    trackEvent(eventName: $eventName, projectId: $projectId, payload: $payload)
  }
`

export const SET_PROJECT_HORIZON_DATE = gql`
  mutation SetProjectHorizonDate($input: SetProjectHorizonDateInput!) {
    setProjectHorizonDate(input: $input)
  }
`
