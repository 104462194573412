import PropTypes from 'prop-types'

import * as S from './styles'

const Text = ({ children, size, variant, format, type, ...props }) => {
  return (
    <S.Text type={type} size={size} variant={variant} format={format} {...props}>
      {children}
    </S.Text>
  )
}

Text.propTypes = {
  /** Children of text */
  children: PropTypes.node.isRequired,
  /** format to give to the text (bold, underline, center) */
  format: PropTypes.string,
  /** Font-size of text: <code>document</code>, <code>x-small</code>, <code>small</code>, <code>medium</code> */
  size: PropTypes.string,
  /** DOM type to be rendered */
  type: PropTypes.string,
  /** Variant colorof Text */
  variant: PropTypes.string,
}

Text.defaultProps = {
  format: 'default',
  size: 'medium',
  variant: 'inherit',
}

export default Text
