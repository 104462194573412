import { MessageParams } from '@pretto/app/src/purchaseOffer/config/types'

import styled from 'styled-components'

export const getMessage = ({ email, onClick }: MessageParams) => (
  <p>
    Cette adresse est différente de celle utilisée pour votre compte Pretto. <br /> Pour utiliser {email} :{' '}
    <ClickHere onClick={onClick}>cliquez ici</ClickHere>.
  </p>
)

const ClickHere = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
