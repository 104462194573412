import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const WhiteLabelPartners = ({ children, logo, style, ...props }) => {
  const variantProps = !style && { variant: 'accent-1' }
  return (
    <Card {...variantProps} {...props}>
      <div style={style}>
        <Content>
          <S.Content>
            {logo && (
              <S.LogoContainer>
                <S.Logo path={logo} />
              </S.LogoContainer>
            )}
            <S.Text>{children}</S.Text>
          </S.Content>
        </Content>
      </div>
    </Card>
  )
}

WhiteLabelPartners.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.string,
  style: PropTypes.object,
}

export default memo(WhiteLabelPartners)
