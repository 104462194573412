import { getAttributionCookieLastClickValue } from '@pretto/app-core/lib/attributionCookie'

import apiClient from '@pretto/app/src/api/index.js'
import { SCORE_FLAGS } from '@pretto/app/src/apollo'

export const SCORE_ZERO_MINUTE_STORE_KEY = 'scoreZeroMinute'

export const useScoreZeroMinute = async () => {
  const variables = { attributionCookieLastClick: getAttributionCookieLastClickValue() }

  try {
    const {
      data: {
        scoreFlags: { appointmentAllowed, pushy, callBackNowAllowed },
      },
    } = await apiClient.query({ query: SCORE_FLAGS, variables })

    return { appointmentAllowed, pushy, callBackNowAllowed }
  } catch (error) {
    return { appointmentAllowed: false, pushy: false, callBackNowAllowed: false }
  }
}
