import { ReactElement } from 'react'

import * as S from './Avatar.styles'

export interface AvatarProps {
  backgroundColor?: string
  color?: string
  diameter?: number
  isBackgroundTransparent?: boolean
  isWithOverlay?: boolean
  isWithWarningIcon?: boolean
  padding?: number
  src: string | ReactElement
}

export const Avatar = ({
  backgroundColor,
  color,
  diameter = 40,
  isBackgroundTransparent,
  isWithOverlay,
  isWithWarningIcon,
  padding,
  src,
  ...props
}: AvatarProps) => {
  const isSrcInitial = typeof src === 'string' && src.length === 1
  const isSrcReactElement = typeof src !== 'string'

  return (
    <S.AvatarContainer>
      <S.Avatar
        $backgroundColor={backgroundColor}
        $color={color}
        $diameter={diameter}
        $isBackgroundTransparent={!!isBackgroundTransparent}
        {...props}
      >
        {src && (isSrcInitial || isSrcReactElement) ? (
          src
        ) : (
          <S.Image $color={color} $diameter={diameter} $padding={padding} alt="avatar" path={src} />
        )}
        {isWithOverlay && <S.Overlay />}
      </S.Avatar>
      {isWithWarningIcon && <S.ActivityIcon $diameter={diameter} />}
    </S.AvatarContainer>
  )
}
