import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Page = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(5)};
  }
`
export const BubbleContainer = styled.div`
  max-width: ${breakpoints.tablet};
`
export const Paragraph = styled.p`
  margin-bottom: ${g(1)};

  &:last-child {
    margin-bottom: 0;
  }
`
export const ButtonContainer = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(7)};
    margin-top: ${g(3)};
  }
`
