import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { CardTypeProperty } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard.types'

import { ServiceCardLink } from './components/ServiceCardLink/ServiceCardLink'
import { ServiceCardStore, ServiceCardStoreProps } from './components/ServiceCardStore/ServiceCardStore'
import { ServiceCardSwitch, ServiceCardSwitchProps } from './components/ServiceCardSwitch/ServiceCardSwitch'
import { CARDS } from './ServiceCard.config'
import * as S from './ServiceCard.styles'

export type DataType = {
  onClick?: (event: MouseEvent) => void
}

export interface ServiceCardProps {
  slug: CardTypeProperty
  data: Omit<ServiceCardSwitchProps, 'label'> | ServiceCardStoreProps | DataType
}

export const ServiceCard = ({ slug, data, ...props }: ServiceCardProps) => {
  const { action, description, image, title } = CARDS[slug]

  const renderCard = () => {
    switch (action.type) {
      case 'link':
        return <ServiceCardLink links={action.links} {...(data as DataType)} />
      case 'switch':
        return <ServiceCardSwitch label={action.label} {...(data as Omit<ServiceCardSwitchProps, 'label'>)} />
      case 'store':
      default:
        return <ServiceCardStore {...(data as ServiceCardStoreProps)} />
    }
  }

  return (
    <S.Card {...props}>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        {renderCard()}
      </S.Content>
      {image && (
        <ResponsiveSSR min="tablet">
          <S.Illustration path={image} />
        </ResponsiveSSR>
      )}
    </S.Card>
  )
}
