import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { body4Bold, caption, heading6 } from '@pretto/zen/reveal/theme/typography'

import styled from 'styled-components'

export const Bullet = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(5 / 2)};
  display: flex;
  flex-shrink: 0;
  height: ${g(5)};
  justify-content: center;
  width: ${g(5)};
`

export const Component = styled.div`
  color: ${({ theme }) => theme.colors.neutral1};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${g(32)} auto;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: ${g(45)} 1fr;
    grid-template-rows: auto;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: ${g(39)} 1fr;
    grid-template-rows: auto;
  }
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  margin: ${g(-9)} ${g(2, 4)} 0;
  padding: ${g(3)} ${g(3)} ${g(4)};
  text-align: center;
  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin: 0;
    text-align: left;
  }
`

export const Image = styled(ImageComponent).attrs({
  sizes: `(min-width: ${breakpoints.laptop}) ${g(39)}, (min-width: ${breakpoints.tablet}) ${g(42)}, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-bottom-left-radius: ${g(1)};
    border-top-left-radius: ${g(1)};
  }
`

export const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
`

export const Item = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
    flex-direction: row;
    gap: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(2)};
  }
`

export const Items = styled.ul`
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
`

export const Title = styled.div`
  ${heading6};
  margin-bottom: ${g(3)};
`

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    gap: ${g(1)};
  }
`

export const ValueDescription = styled.div`
  ${caption};
`

export const ValueTitle = styled.div`
  ${body4Bold};
`
