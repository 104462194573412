import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'

import * as C from './SpinnerLegacy.module.css'

const SpinnerLegacy = ({ size, overlay, children, variant }) => (
  <div className={classnames(C[`spinner${pascalcase(size ?? '')}`], { [C.spinnerOverlay]: overlay })}>
    <div className={C.spinnerWrapper}>
      <div className={classnames(C.spinner, C.spinnerPrism, { [C.spinnerWhite]: variant === 'white' })}>
        <div className={C.verticalCenteredBox}>
          <div className={C.content}>
            <div className={C.loaderCircle} />
            <div className={C.loaderLineMask}>
              <div className={C.loaderLine} />
            </div>
          </div>
        </div>
      </div>

      {children && <div className={C.spinnerContent}>{children}</div>}
    </div>
  </div>
)

SpinnerLegacy.propTypes = {
  /** Content below Spinner. */
  children: PropTypes.node,
  /** Size of Spinner. Can be <code>small</code>, <code>medium</code> or <code>large</code>*/
  size: PropTypes.string,
  /** Should the spinner be overlayed. */
  overlay: PropTypes.bool,
  /** Variant. */
  variant: PropTypes.string,
}

SpinnerLegacy.defaultProps = {
  size: 'medium',
  overlay: false,
}

export default SpinnerLegacy
