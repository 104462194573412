import ApplicationIncomeField from '@pretto/bricks/app/application/components/ApplicationIncomeField'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const defaultValue = {
  amount: null,
  period: 'monthly',
  taxes: 'after',
}

export const IncomeField = props => {
  const [values, setValues] = useState({ ...defaultValue, ...props.value })

  useEffect(() => props.onChange(values), [values])

  const handleNumberFieldChange = name => value => setValues(values => ({ ...values, [name]: value }))
  const handleSelectFieldChange =
    name =>
    ({ value }) =>
      setValues(values => ({ ...values, [name]: value }))

  return (
    <ApplicationIncomeField
      amountProps={{
        onChange: handleNumberFieldChange('amount'),
        value: values.amount,
      }}
      periodProps={{
        onChange: handleSelectFieldChange('period'),
        value: values.period,
      }}
      taxesProps={{
        onChange: handleSelectFieldChange('taxes'),
        value: values.taxes,
      }}
    />
  )
}

IncomeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    amount: PropTypes.number,
    period: PropTypes.oneOf(['monthly', 'yearly']),
    taxes: PropTypes.oneOf(['after', 'before']),
  }),
}
