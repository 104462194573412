export const ERROR_TYPES = ['account_already_exists', 'account_exists', 'server_error']

export const getAuthErrorMessage = (type, link) => {
  switch (type) {
    case ERROR_TYPES[0]:
    case ERROR_TYPES[1]:
      return <p>Il semblerait que vous ayez déjà un compte, vous pouvez vous connecter {link}.</p>

    default:
      return (
        <p>
          Une erreur est survenue, veuillez réessayer avec une autre adresse email. Si vous avez déjà un compte, vous
          pouvez vous connecter {link}.
        </p>
      )
  }
}
