import { g } from '@pretto/bricks/components/layout'

import { Unit as UnitType } from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'

import styled from 'styled-components'

interface UnitProps {
  unit: UnitType
}

export const Unit: React.FC<UnitProps> = ({ unit }) => {
  switch (unit) {
    case UnitType.Payment:
      return (
        <Component>
          <Symbol>€</Symbol>
          <Suffix>/ mois</Suffix>
        </Component>
      )

    case UnitType.Price:
      return <Symbol>€</Symbol>

    case UnitType.None:
    default:
      return null
  }
}

const Component = styled.span`
  align-items: center;
  display: flex;
  gap: ${g(1 / 2)};
`

const Symbol = styled.span`
  ${({ theme }) => theme.typos.body4Bold};
`

const Suffix = styled.span`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral1};
`
