import * as easings from 'easings-css'
import { keyframes } from 'styled-components'

export const orbitAnimationEntering = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }

  45% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const planetAnimationEntering = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`

export const planetAnimationRevolution = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const sunAnimationEntering = keyframes`
  0% {
    animation-timing-function: ${easings.easeInOutCubic};
    transform: perspective(1px) scale(0);
  }

  58% {
    animation-timing-function: ${easings.easeInOutSine};
    transform: perspective(1px) scale(1);
  }

  100% {
    transform: perspective(1px) scale(0.54);
  }
`
