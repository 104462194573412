import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 60%;
  }
`

export const Headline = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
  }
`

export const Page = styled.div`
  padding-bottom: ${g(5)};
  padding-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(7)};
    padding-top: ${g(5)};
  }
`
