import type { CapacityData } from '@pretto/app/src/types/Capacity'

import { createContext } from 'react'

export const ROUND_TO = 25

interface DataContextProps {
  loadingResults: boolean
  data: CapacityData
  payment: number
  contribution: number
  maxPayment: number
  selectedLoanDuration: number | null
  error: boolean
  capacityMedium: string
  initCapacity: () => Promise<CapacityData | undefined>
  refreshCapacity: (medium: string, overrides?: { contribution?: number; payment?: number }) => Promise<void>
  handleChangePayment: (value: number) => void
  handleChangeContribution: (value: number) => void
  handleChangeDuration: (value: number) => void
}

const defaultContext: DataContextProps = {
  loadingResults: false,
  data: {} as CapacityData,
  payment: 0,
  contribution: 0,
  maxPayment: 0,
  selectedLoanDuration: null,
  error: false,
  capacityMedium: '',
  initCapacity: () => Promise.resolve({} as CapacityData),
  refreshCapacity: () => Promise.resolve(),
  handleChangePayment: () => undefined,
  handleChangeContribution: () => undefined,
  handleChangeDuration: () => undefined,
}

export const DataContext = createContext<DataContextProps>(defaultContext)
