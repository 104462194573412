import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Category = styled.div`
  margin-bottom: ${g(2)};
`

export const Services = styled.div`
  margin-bottom: ${g(1)};
`
