import { setItem } from '@pretto/app/src/config/itemStorage'
import { getContextPartner } from '@pretto/app/src/config/whiteLabel'
import {
  DESCRIPTION_TYPE_1,
  DESCRIPTION_TYPE_2,
  DESCRIPTION_TYPE_3,
  DescriptionProps,
} from '@pretto/app/src/projectChoice/config/descriptions'

type Partner = {
  descriptionComponent: React.FC<DescriptionProps>
  descriptionProps?: DescriptionProps
}

export const PARTNERS = {
  lbc: {
    descriptionComponent: DESCRIPTION_TYPE_1,
    descriptionProps: {
      partnerName: 'Leboncoin',
    },
  },
  seloger: {
    descriptionComponent: DESCRIPTION_TYPE_2,
    descriptionProps: {
      partnerName: 'Se Loger',
    },
  },
  bienici: {
    descriptionComponent: DESCRIPTION_TYPE_2,
    descriptionProps: {
      partnerName: 'Bien Ici',
    },
  },
  paruvendu: {
    descriptionComponent: DESCRIPTION_TYPE_2,
    descriptionProps: {
      partnerName: 'Paru Vendu',
    },
  },
  lesfurets: {
    descriptionComponent: DESCRIPTION_TYPE_3,
    descriptionProps: {
      isSingular: false,
      partnerName: 'Les Furets',
    },
  },
  'reassurez-moi': {
    descriptionComponent: DESCRIPTION_TYPE_3,
    descriptionProps: {
      partnerName: 'Réassurez-moi',
    },
  },
  'younited-credit': {
    descriptionComponent: DESCRIPTION_TYPE_3,
    descriptionProps: {
      partnerName: 'Younited',
    },
  },
  'immobilier-danger': {
    descriptionComponent: DESCRIPTION_TYPE_3,
    descriptionProps: {
      partnerName: 'Immobilier Danger',
    },
  },
  immoneuf: {
    descriptionComponent: DESCRIPTION_TYPE_3,
    descriptionProps: {
      partnerName: 'Immoneuf',
    },
  },
} as const satisfies { [K: string]: Partner }

export const isPartner = (partnerSlug: string | undefined): partnerSlug is keyof typeof PARTNERS => {
  const contextPartner = getContextPartner()?.subdomain

  if (partnerSlug === 'lbc') {
    setItem('isPushyTest', 'true')
  }

  return (
    (typeof contextPartner === 'string' && Object.keys(PARTNERS).includes(contextPartner)) ||
    (typeof partnerSlug === 'string' && Object.keys(PARTNERS).includes(partnerSlug))
  )
}
