import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import styled from 'styled-components'

interface PartnerCardProps {
  ctaLink: string
  ctaTitle: string
  description: string
  imgPath: string
  title: string
  onPartnerClick?: () => void
}

export const PartnerCard: React.FC<React.HTMLAttributes<HTMLDivElement> & PartnerCardProps> = ({
  ctaLink,
  ctaTitle,
  description,
  imgPath,
  title,
  onPartnerClick,
  ...props
}) => (
  <Component {...props}>
    <ImageContainer>
      <Image path={imgPath} />
    </ImageContainer>

    <Content>
      <Title>{title}</Title>

      <p dangerouslySetInnerHTML={{ __html: description }} />
    </Content>

    <Button scheme="black" href={ctaLink} onClick={onPartnerClick}>
      {ctaTitle}
    </Button>
  </Component>
)

const Button = styled(PrimaryButtonFix)`
  justify-self: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-self: flex-start;
  }
`

const Component = styled.div`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  column-gap: ${g(3)};
  display: grid;
  padding: ${g(2)};
  row-gap: ${g(3)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    padding: ${g(3)};
    text-align: left;
  }
`

const Content = styled.p`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  flex-direction: column;
  gap: ${g(1)};

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`

const ImageContainer = styled.div`
  align-self: center;
  justify-self: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-end;
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`

const Image = styled(ImageComponent).attrs({ options: { flag: 'apng', height: ng(19), width: ng(19) } })`
  display: block;
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
`
