import { getNavNotClient } from '@pretto/app/src/SharedContainers/Header/lib/simulatorResultsNav'
import { REDUCE_AMBITION_TYPES } from '@pretto/app/src/Simulation/Containers/OptionsPage/config/reduceAmbition'
import { getDefaultOption } from '@pretto/app/src/Simulation/Containers/OptionsPage/lib/getDefaultOption'
import { getInfos } from '@pretto/app/src/Simulation/Containers/OptionsPage/lib/getInfos'
import { getOptions } from '@pretto/app/src/Simulation/Containers/OptionsPage/lib/getOptions'
import { ReduceAmbitionPage } from '@pretto/app/src/Simulation/views/reduceAmbition/ReduceAmbition'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export const ReduceAmbition = ({
  options,
  project,
  onProjectChanges,
  onProjectChangesWithPurchase,
  onSimulationMedium,
}) => {
  const [selected, setSelected] = useState(getDefaultOption(options))
  const history = useHistory()
  const { typology } = useUser()
  const trackAction = useTracking()

  useEffect(() => {
    trackAction('SIMULATION_REDUCE_AMBITION_PAGE_VIEWED', {
      options: Object.values(options).length,
    })
  }, [])

  const handleSelect = choice => {
    setSelected(choice)
  }

  const headerProps = {
    accountVariant: typology === 'preLead' ? 'onlyInitial' : null,
    isMobileHidden: typology === 'prospect',
  }

  const navigationItemsObject = getNavNotClient('purchase')

  const handleModify = () => {
    trackAction('SIMULATION_REDUCE_AMBITION_MODIFY_PROJECT_CLICKED')

    // TODO: check url when land, construction and good routes are merged
    history.push(typology === 'client' ? '/application' : `/project/purchase/property/good`)
  }

  const trackOption = key => {
    onSimulationMedium('reduce_ambition')

    trackAction('SIMULATION_REDUCE_AMBITION_RESULT_CLICKED', { simulation_reduce_ambition_option_value: key })
  }

  const getPreviousClick = () => {
    history.push('/project/purchase/contribution/savings')
  }

  const handleRedirectClick = () => {
    trackOption(selected)
    switch (selected) {
      case REDUCE_AMBITION_TYPES.contribution: {
        const {
          project: { contribution },
        } = options[REDUCE_AMBITION_TYPES.contribution]
        return onProjectChanges({ contribution })
      }

      case REDUCE_AMBITION_TYPES.price: {
        const {
          project: { purchase: newPurchase },
        } = options[REDUCE_AMBITION_TYPES.price]

        return onProjectChangesWithPurchase(newPurchase)
      }
      default:
        break
    }
  }

  const props = {
    headerProps,
    infos: getInfos(project),
    mainProps: {
      onOptionClick: handleSelect,
      onResultClick: handleRedirectClick,
      optionButtonsGroupProps: {
        options: getOptions(options, project),
        selected,
      },
    },
    navigationItemsObject,
    onModify: handleModify,
    previousNextNavBarProps: {
      onNextClick: handleRedirectClick,
      onPreviousClick: getPreviousClick,
    },
  }

  return <ReduceAmbitionPage {...props} />
}

ReduceAmbition.propTypes = {
  onProjectChanges: PropTypes.func.isRequired,
  onProjectChangesWithPurchase: PropTypes.func.isRequired,
  onSimulationMedium: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}
