import { g } from '@pretto/bricks/components/layout'
import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Chip as ChipComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/Chip/Chip'
import { formatBenefit } from '@pretto/app/src/Capacity/views/ResultPage/lib/formatBenefit'
import { Benefit } from '@pretto/app/src/Capacity/views/ResultPage/types/benefit'
import { BulletBold } from '@pretto/picto'

import { useState } from 'react'
import styled from 'styled-components'

import { DialogContent, DialogContentProps } from './components/DialogContent/DialogContent'

export interface Benefits extends Benefit {
  items: string[]
  title?: string
}

export interface SuggestionLargeProps {
  benefits: Benefits
  dialogContentProps: Omit<DialogContentProps, 'onClose'>
  hat?: string
  onSeeMore?: () => void
  title: string
  value: number
}

export const SuggestionLarge: React.FC<SuggestionLargeProps & React.HTMLAttributes<HTMLDivElement>> = ({
  benefits: { items, title: benefitTitle = 'Les avantages :', ...benefit },
  dialogContentProps,
  hat = 'Vous pourriez acheter un bien à :',
  onSeeMore,
  title,
  value,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const handleOpen = () => {
    setIsOpened(true)
    onSeeMore?.()
  }

  const handleClose = () => {
    setIsOpened(false)
  }

  return (
    <Component {...props}>
      <Title>{title}</Title>

      <Header>
        <HeaderTitle>{hat}</HeaderTitle>
        <Value>{value.toLocaleString('fr')} €</Value>
        <Chip>{formatBenefit(benefit)}</Chip>
      </Header>

      <Content>
        <ContentTitle>{benefitTitle}</ContentTitle>

        <List>
          {items.map(item => (
            <ListItem key={item}>
              <ListBullet />
              {item}
            </ListItem>
          ))}
        </List>
      </Content>

      <footer>
        <Button onClick={handleOpen} type="button">
          Voir le détail du calcul
        </Button>
      </footer>

      <Dialog isOpen={isOpened} onRequestClose={handleClose}>
        <DialogContent {...dialogContentProps} onClose={handleClose} />
      </Dialog>
    </Component>
  )
}

const Button = styled.button`
  ${({ theme }) => theme.typos.linkUnderline};
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
`

const Chip = styled(ChipComponent)``

const Component = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  padding: ${g(2)} ${g(2)} ${g(3)};
`

const Content = styled.main`
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};
  margin-top: ${g(-1)};
  padding: ${g(1, 4)} ${g(2)} ${g(2)};
`

const ContentTitle = styled.div`
  ${({ theme }) => theme.typos.body4Bold};
`

const Header = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary1};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.white};
  column-gap: ${g(2)};
  display: grid;
  grid-template-columns: 1fr minmax(0, auto);
  padding: ${g(2)};
  row-gap: ${g(1 / 2)};
`

const HeaderTitle = styled.div`
  ${({ theme }) => theme.typos.caption};
  grid-column: 1 / span 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};
`

const ListBullet = styled(BulletBold)`
  flex-shrink: 0;
  height: ${g(1)};
  margin-top: ${g(1)};
  width: ${g(1)};
`

const ListItem = styled.li`
  display: flex;
  gap: ${g(1)};
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading6};
  color: ${({ theme }) => theme.colors.neutral1};
`

const Value = styled.div`
  ${({ theme }) => theme.typos.body2};
`
