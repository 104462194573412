import { Lightbulb } from '@pretto/bricks/app/sentences/components/TipBubble'
import Card from '@pretto/bricks/components/cards/Card'
import Avatar from '@pretto/bricks/components/images/Avatar'
import Content from '@pretto/bricks/components/layout/Content'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './BubbleChat.module.css'

export default class BubbleChat extends PureComponent {
  static propTypes = {
    /** Content of BubbleChat */
    children: PropTypes.node.isRequired,
    /** Formats style of BubbleChat. Should be an array or a string. */
    format: PropTypes.any,
    /** props of atom component `Card` */
    cardProps: PropTypes.object,
    /** props of atom component `Avatar` */
    avatarProps: PropTypes.object,
    /** Hide avatar. There will be blank space instead. */
    hideAvatar: PropTypes.bool,
  }

  static defaultProps = {
    position: 'left',
    textVariant: 'neutral-1',
    format: 'default',
  }

  render() {
    const { children, format, cardProps, avatarProps, hideAvatar, ...props } = this.props
    const AvatarComponent = avatarProps.src ? Avatar : Lightbulb

    const mobileSize = format === 'splitted' ? 'splitted' : 'miniature'
    let formatClass = ''
    if (typeof format === 'string') formatClass = `bubble-chat--${format}`
    else {
      format.forEach(f => {
        formatClass += ` bubble-chat--${f}`
      })
    }
    return (
      <div {...props}>
        <div className={classnames(C.bubbleChat, C[formatClass])}>
          <div className={classnames(C.bubbleChatAvatar, { [C.bubbleChatAvatarHidden]: hideAvatar })}>
            {!hideAvatar && (
              <div className={C.bubbleChatAvatarContent}>
                <Mobile>
                  <AvatarComponent {...avatarProps} size={mobileSize} />
                </Mobile>
                <Desktop>
                  <AvatarComponent {...avatarProps} size="small" />
                </Desktop>
              </div>
            )}
          </div>
          <div>
            <Card {...cardProps}>
              <Content>{children}</Content>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}
