import Button from '@pretto/bricks/components/buttons/Button'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const FormTemplate = ({ children, isDisabled, onSubmit, submitLabel }) => (
  <div>
    {children}

    <S.Submit>
      <Button isDisabled={isDisabled} onClick={onSubmit}>
        {submitLabel}
      </Button>
    </S.Submit>
  </div>
)

FormTemplate.defaultProps = {
  isDisabled: false,
  submitLabel: 'Continuer',
}

FormTemplate.propTypes = {
  /** Descendants. */
  children: PropTypes.node.isRequired,
  /** Whether or not the submit button should be disabled. */
  isDisabled: PropTypes.bool,
  /** Triggered whenever the submit button is being pressed. */
  onSubmit: PropTypes.func.isRequired,
  /** Label of the submit button. */
  submitLabel: PropTypes.string,
}

export default memo(FormTemplate)
