import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Introduction = styled.header`
  margin-bottom: ${g(3)};
  padding-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
    padding-top: 0;
  }
`

export const Malus = styled.div`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
  }
`

export const More = styled.div`
  align-items: flex-start;
  display: grid;
  gap: ${g(1)};
  margin-top: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    gap: ${g(3)};
    margin-top: ${g(3)};
    padding: 0 ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: repeat(2, minmax(0, 50%));
    padding: 0;
  }
`
export const Page = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  padding-bottom: ${g(5)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    background-color: transparent;
    padding: ${g(4)} 0 ${g(7)};
  }
`
export const RegroupCredits = styled.div`
  padding-top: ${g(1)};
  margin-bottom: ${g(2)};
  background-color: ${({ theme }) => theme.legacy.colors.white.default};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: initial;
    margin: ${g(4)} ${g(3)} ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: 0;
    margin-right: 0;
  }
`
export const Restore = styled.div`
  margin-bottom: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
    padding: 0 ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
  }
`
export const MalusIntroduction = styled.div`
  padding: 0 ${g(2)};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
  }
`
export const RestoreDescription = styled.div`
  margin-bottom: ${g(3)};
`

export const Title = styled.div`
  margin-bottom: ${g(4)};
`
