import { t } from '@pretto/app/src/lib/i18n'

export const email = {
  inputProps: {
    placeholder: t('signupForm.placeholders.email'),
    required: true,
  },
}

export const firstName = {
  inputProps: {
    autoFocus: true,
    placeholder: t('signupForm.placeholders.firstName'),
    required: true,
  },
}

export const lastName = {
  inputProps: {
    placeholder: t('signupForm.placeholders.lastName'),
    required: true,
  },
}

export const phone = {
  inputProps: {
    placeholder: t('signupForm.placeholders.phone'),
    required: true,
  },
}

export const maturity = {
  optionsList: [
    { label: t('signupForm.maturity.underStudy'), value: 'under_study' },
    { label: t('signupForm.maturity.searchStarted'), value: 'search_started' },
    { label: t('signupForm.maturity.offerMade'), value: 'offer_made' },
    { label: t('signupForm.maturity.offerAccepted'), value: 'offer_accepted' },
    { label: t('signupForm.maturity.saleAgreementSigned'), value: 'sale_agreement_signed' },
  ],
  inputProps: {
    required: true,
  },
  placeholder: t('signupForm.maturity.placeholder'),
}
