import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

import styled from 'styled-components'

const Signature: React.FC<CardProps> = ({ defaultComponent: Component }) => {
  const { onOpen } = useAdvisor()

  const handleContactExpert = () => {
    onOpen()
  }

  const description = (
    <p>
      Vous recevez l'offre de prêt et attendez 11 jours avant de la renvoyer signée.
      <br />
      Pensez à scanner le document et à l'envoyer dès maintenant par{' '}
      <ContactExpert onClick={handleContactExpert}>mail à votre expert</ContactExpert> afin qu’il vérifie qu'il ne
      comporte pas d'erreur. 
    </p>
  )

  return <Component description={description} title="Signez l'offre de prêt" />
}

export const config: Config = {
  component: Signature,
  name: 'signature',
}

const ContactExpert = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
