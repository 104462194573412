import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import type { Project } from '@pretto/app/src/types/gateway/schema'

import pipe from 'ramda/src/pipe'

import { mapCompetition } from './competition'
import { mapContribution } from './contribution'
import { mapId } from './id'
import { mapProfile } from './profile'
import { mapProjectKind } from './project'
import { mapProperty } from './property'
import { mapPTZ } from './ptz'
import { mapPurchase } from './purchase'
import { mapRenegotiation } from './renegotiation'

const createPayload: ComposableMapper = ({ context }) => ({
  context,
  // If some default payload values are necessary, add them here
  payload: {},
})

export const mapContextToPayload = (context: ProjectContext): Project =>
  pipe(
    createPayload,
    mapId,
    mapProjectKind,
    mapContribution,
    mapPurchase,
    mapProfile,
    mapProperty,
    mapPTZ,
    mapRenegotiation,
    mapCompetition
  )({ context, payload: {} }).payload
