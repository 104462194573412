import HeaderPrettoSearchComponent from '@pretto/bricks/app/sentences/components/HeaderPrettoSearch'
import { breakpoints, g } from '@pretto/bricks/components/layout'

import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { t } from '@pretto/app/src/lib/i18n'

import styled from 'styled-components'

export const IntroductionPage: React.FC = () => (
  <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'introductionPropertySearch' } }}>
    <HeaderPrettoSearch version="2" />

    <ButtonNext>{t('sentences.understood')}</ButtonNext>
  </TrackedView>
)

const HeaderPrettoSearch = styled(HeaderPrettoSearchComponent)`
  margin-left: -${g(2, 4)};
  margin-right: -${g(2, 4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: 0;
    margin-right: 0;
  }
`
