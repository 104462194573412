import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { InfoCircle } from '@pretto/picto'

import styled from 'styled-components'

export const ScoreCard = styled.div`
  background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  border-radius: ${g(1)};
  color: ${({ theme, $color }) => theme.colors[$color]};
  padding: ${g(2)};
  margin: ${g(3)} 0;
  display: flex;
  align-items: center;
  cursor: auto;
`

export const ScoreIcon = styled(InfoCircle)`
  margin-right: ${g(2)};
  flex-shrink: 0;
`

export const ScoreDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.mobileL}) {
    flex-direction: row;
  }
`

export const SeeMore = styled(BaseTypo)`
  text-decoration: underline;
  cursor: pointer;
`
