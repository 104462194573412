import { funcToItem } from '@pretto/bricks/core/utility/formatters'

const SCORE_EASY = 0
const SCORE_EASY_ENOUGH = 1
const SCORE_DIFFICULT = 2

const SCORES = [
  {
    description: `Pretto estime sur la base de vos informations que votre projet ne présente pas de difficulté.\n\nCette première estimation doit être affinée par un expert Pretto qui négociera pour vous les meilleures conditions de taux et de flexibilité.`,
    label: 'facile',
    variant: 'success',
  },
  count => ({
    description: `Pretto estime sur la base des informations indiquées que votre projet est finançable. Cette première estimation doit être affinée par un expert Pretto.
      ${
        count === 1
          ? "\n\nEn effet, nous obtenons la plupart du temps des accords bancaires pour des projets similaires. Le point d'attention présenté ci-dessous peut soulever des difficultés dans certaines banques : Pretto présente au mieux votre demande de prêt pour garantir votre financement"
          : ''
      }`,
    label: 'assez facile',
    variant: 'success',
  }),
  count => ({
    description: `Pretto estime sur la base des informations indiquées qu'il sera relativement difficile de trouver un financement pour votre projet. Cette première estimation doit être affinée par un expert Pretto.\n\nEn effet, il arrive que nous n'obtenions pas d'accord bancaire pour des projets similaires. ${
      count > 1
        ? "Les points d'attention présentés ci-dessous peuvent"
        : "Le point d'attention présenté ci-dessous peut"
    } soulever des difficultés dans les banques : Pretto cible les banques qui ont le plus de chances d'accepter de financer votre projet.`,
    label: 'difficile',
    variant: 'warning',
  }),
]

export const getScoreIndex = (flags = [], numberBanks) => {
  if (numberBanks === 1) {
    if (flags.length === 0) {
      return SCORE_EASY_ENOUGH
    }

    return SCORE_DIFFICULT
  }

  if (flags.length > 1) {
    return SCORE_DIFFICULT
  }

  if (flags.length > 0) {
    return SCORE_EASY_ENOUGH
  }

  return SCORE_EASY
}

const getScoreDetails = (flags = [], numberBanks) => {
  const index = getScoreIndex(flags, numberBanks)
  const score = SCORES[index]

  return funcToItem(score, flags.length)
}

export default getScoreDetails
