import DashboardSecondaryCard from '@pretto/bricks/app/dashboard/components/DashboardSecondaryCard'

import PropTypes from 'prop-types'

import * as S from './styles'

const Section = ({ details, icon, title }) => (
  <S.InternalCard>
    <S.Row>
      <S.Picto $picto={icon} />
      <S.Title>{title}</S.Title>
    </S.Row>

    {details.map((item, k) => {
      return (
        <S.DetailsContainer key={k}>
          <S.PropertyTitle>{item.propertyTitle}</S.PropertyTitle>
          <S.ValueTitle>{item.valueTitle}</S.ValueTitle>
        </S.DetailsContainer>
      )
    })}
  </S.InternalCard>
)

Section.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      propertyTitle: PropTypes.string.isRequired,
      valueTitle: PropTypes.string.isRequired,
    })
  ),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const DashboardProjectDetailsCard = ({ data }) => {
  return (
    <DashboardSecondaryCard title={'Rappel de votre projet et de vos besoins'}>
      <S.Content>
        {data.map((item, k) => (
          <Section key={k} {...item} />
        ))}
      </S.Content>
    </DashboardSecondaryCard>
  )
}

DashboardProjectDetailsCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({
          propertyTitle: PropTypes.string.isRequired,
          valueTitle: PropTypes.string.isRequired,
        })
      ),
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}

export default DashboardProjectDetailsCard
