import { g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Container = styled.div`
  ${column(5)};
  ${grid(4, { isRightAligned: true })};
  background-color: ${({ theme }) => theme.colors.neutral4};
`

export const InformationBloc = styled.div`
  ${column([2, 4])};
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${g(4)};
  margin-right: ${g(3)};
`

export const Content = styled.div`
  padding: ${g(3)};
  padding-bottom: ${g(2)};
`

export const Title = styled.h1`
  ${typo.heading32};
`

export const Infos = styled.div`
  margin: ${g(3)} 0;
`

export const Info = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: ${g(2)};
  }
`

export const Label = styled.span`
  ${typo.headline16};
  margin-right: ${g(2)};
`

export const Value = styled.span`
  ${typo.numbersBold16};
`

export const ContainerIllustration = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${g(3)};
`

export const Illustration = styled(Image).attrs({
  options: { height: 160, width: 160 },
  path: 'v1646755645/zen/static-assets/illustrations/660x660/frame-expertAndClient.svg',
})``
