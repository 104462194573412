import { PropertyKind } from '@pretto/app/src/types/gateway/enums'
import { Picto, Plan, Tree, Wall } from '@pretto/picto'

type Labels = Record<PropertyKind | 'default', { picto: Picto; text: string }>

export const PROPERTY_KIND_LABEL: Labels = {
  old: {
    text: 'Logement ancien',
    picto: Wall,
  },
  new: {
    text: 'Logement neuf',
    picto: Wall,
  },
  get CCMI() {
    return this.default
  },
  VEFA: {
    text: 'VEFA',
    picto: Plan,
  },
  land_construction: {
    text: 'Terrain + construction',
    picto: Plan,
  },
  land: {
    text: 'Terrain',
    picto: Tree,
  },
  construction: {
    text: 'Construction',
    picto: Plan,
  },
  SCPI: {
    text: 'SCPI',
    picto: Wall,
  },
  default: {
    text: 'Type de bien inconnu',
    picto: Wall,
  },
}
