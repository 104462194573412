import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/checkSeniorityYearIsValid'
import {
  OccupationContractType,
  OccupationOthersStatus,
  OccupationPublicStatus,
  OccupationType,
} from '@pretto/app/src/Sentences/v2/types/enums'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'

export const checkSeniorityYearIsFilled = (mortgagor: Mortgagor): boolean => {
  const { occupationType, contractType, status, startYear } = mortgagor

  switch (occupationType) {
    case OccupationType.Private:
      if (contractType === OccupationContractType.ShortTerm) {
        return checkSeniorityYearIsValid(startYear)
      }
      return true

    case OccupationType.Public:
      if (status === OccupationPublicStatus.Contractor && contractType === OccupationContractType.ShortTerm) {
        return checkSeniorityYearIsValid(startYear)
      }
      return true

    case OccupationType.Others:
      if (status !== OccupationOthersStatus.Retired) {
        return checkSeniorityYearIsValid(startYear)
      }
      return true

    case OccupationType.Medical:
      return checkSeniorityYearIsValid(startYear)

    default:
      return true
  }
}
