import PreferenceDialog from '@pretto/bricks/shared/components/PreferenceDialog'

import { ConsentManagerBuilder } from '@segment/consent-manager'
import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'

const ADVERTISING_CATEGORIES = ['Advertising', 'Tag Managers']

const mapPreferences = (d, p = {}) => {
  return {
    customPreferences: p,
    destinationPreferences: d.reduce((obj, { id, category }) => {
      const value = ADVERTISING_CATEGORIES.includes(category) ? p.advertising : p.marketingAndAnalytics

      return {
        ...obj,
        [id]: typeof value === 'boolean' ? value : true,
      }
    }, {}),
  }
}

const normalizeDestinations = destinations =>
  destinations.reduce(
    (previous, destination) => {
      if (ADVERTISING_CATEGORIES.includes(destination.category)) {
        return {
          ...previous,
          advertisingDestinations: [...previous.advertisingDestinations, destination],
        }
      }
      return {
        ...previous,
        marketingDestinations: [...previous.marketingDestinations, destination],
      }
    },
    { advertisingDestinations: [], marketingDestinations: [] }
  )

const Preferences = ({
  destinations,
  getCookieBarProps,
  isCookieBannerActive,
  onClose,
  onCookieAccepted,
  onCookieCustomized,
  onCookieRefused,
  onShowCookieBanner,
  preferences,
  preferenceDialogOpened,
  resetPreferences,
  saveConsent,
  setPreferenceDialogOpened,
  setPreferences,
}) => {
  const { marketingDestinations, advertisingDestinations } = normalizeDestinations(destinations)

  const handleCategoryChange = (category, value) => {
    setPreferences({ [category]: value })
  }

  const openConsentManager = () => {
    onClose()
    setPreferences(Object.keys(preferences).length ? preferences : { advertising: false, marketingAndAnalytics: false })
    setPreferenceDialogOpened(true)
    onCookieCustomized()
  }

  const acceptAll = () => {
    setPreferences({ advertising: true, marketingAndAnalytics: true })
    saveConsent()
    onCookieAccepted()
    onClose()
  }

  const handleSave = () => {
    setPreferences(preferences)
    saveConsent()
    setPreferenceDialogOpened(false)
    onClose()
  }

  const handleCancel = () => {
    resetPreferences()
    setPreferenceDialogOpened(false)
    onShowCookieBanner()
  }

  const handleRefuseAll = () => {
    setPreferences({ advertising: false, marketingAndAnalytics: false })
    saveConsent()
    onCookieRefused()
    onClose()
  }

  const isDisplay = (Object.keys(preferences).length < 1 || isCookieBannerActive) && !preferenceDialogOpened

  useEffect(() => {
    getCookieBarProps({
      onAcceptAllClick: acceptAll,
      onParametersClick: openConsentManager,
      onRefuseAllClick: handleRefuseAll,
      isVisible: isDisplay,
    })
  }, [isDisplay])

  return (
    <>
      {preferenceDialogOpened && (
        <PreferenceDialog
          advertising={preferences.advertising}
          advertisingDestinations={advertisingDestinations}
          handleCancel={handleCancel}
          marketingAndAnalytics={preferences.marketingAndAnalytics}
          marketingDestinations={marketingDestinations}
          onSave={handleSave}
          onChange={handleCategoryChange}
        />
      )}
    </>
  )
}

Preferences.propTypes = {
  destinations: PropTypes.array,
  getCookieBarProps: PropTypes.func.isRequired,
  isCookieBannerActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCookieAccepted: PropTypes.func.isRequired,
  onCookieCustomized: PropTypes.func.isRequired,
  onCookieRefused: PropTypes.func.isRequired,
  onShowCookieBanner: PropTypes.func.isRequired,
  preferences: PropTypes.object,
  preferenceDialogOpened: PropTypes.bool.isRequired,
  resetPreferences: PropTypes.func.isRequired,
  saveConsent: PropTypes.func.isRequired,
  setPreferenceDialogOpened: PropTypes.func.isRequired,
  setPreferences: PropTypes.func.isRequired,
}

const ConsentManager = ({
  apiWriteKey,
  getCookieBarProps,
  isCookieBannerActive,
  onClose,
  onCookieAccepted,
  onCookieCustomized,
  onCookieRefused,
  onShowCookieBanner,
}) => {
  const [preferenceDialogOpened, setPreferenceDialogOpened] = useState(false)

  return (
    <ConsentManagerBuilder mapCustomPreferences={mapPreferences} writeKey={apiWriteKey}>
      {({ destinations, preferences, resetPreferences, saveConsent, setPreferences }) => (
        <Preferences
          destinations={destinations}
          getCookieBarProps={getCookieBarProps}
          isCookieBannerActive={isCookieBannerActive}
          onClose={onClose}
          onCookieAccepted={onCookieAccepted}
          onCookieCustomized={onCookieCustomized}
          onCookieRefused={onCookieRefused}
          onShowCookieBanner={onShowCookieBanner}
          preferences={preferences}
          preferenceDialogOpened={preferenceDialogOpened}
          resetPreferences={resetPreferences}
          saveConsent={saveConsent}
          setPreferenceDialogOpened={setPreferenceDialogOpened}
          setPreferences={setPreferences}
        />
      )}
    </ConsentManagerBuilder>
  )
}

ConsentManager.defaultProps = {
  onCookieAccepted: () => {},
  onCookieCustomized: () => {},
  onCookieRefused: () => {},
}

ConsentManager.propTypes = {
  apiWriteKey: PropTypes.string.isRequired,
  isCookieBannerActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCookieAccepted: PropTypes.func,
  onCookieCustomized: PropTypes.func,
  onCookieRefused: PropTypes.func,
  onShowCookieBanner: PropTypes.func.isRequired,
  getCookieBarProps: PropTypes.func,
}

export default memo(ConsentManager)
