import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Heading from '@pretto/bricks/components/typography/Heading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const SponsorshipHighlight = ({ icon, label, value }) => (
  <S.Highlight>
    <Heading>{value}</Heading>
    <S.Label>
      {label}
      {icon && (
        <S.Emoji>
          <Emoji>{icon}</Emoji>
        </S.Emoji>
      )}
    </S.Label>
  </S.Highlight>
)

SponsorshipHighlight.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default memo(SponsorshipHighlight)
