import Card from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { ChartBar, House as PictoHouse } from '@pretto/picto'

import styled from 'styled-components'

export const Chart = styled(ChartBar)`
  margin-right: ${g(1)};
`

export const Header = styled(BaseTypo)`
  display: flex;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const House = styled(PictoHouse)`
  margin-right: ${g(1)};
`

export const Infos = styled.div`
  display: grid;
  grid-gap: ${g(3)};
  grid-template-columns: 1fr;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const RateCard = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(3)};
`

export const Rate = styled(BaseTypo).attrs({ typo: 'textBig' })`
  margin-bottom: ${g(2)};
`
