import Button from '@pretto/bricks/components/buttons/Button'
import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { PurchaseOfferProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/PurchaseOffer'
import { TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Page = styled.div`
  ${grid()};
  padding-bottom: ${g(5)};
`
export const Hero = styled.header`
  ${grid()};
  ${column([1, -1])};
  grid-row-gap: ${g(1)};
  padding-top: ${g(3)};
  padding-bottom: ${g(4)};
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(6)};
    padding-bottom: ${g(6)};
  }
`
export const Title = styled.div`
  ${typo.heading48};
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
  }
`
export const Headline = styled.p`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
  }
`
export const Main = styled.div`
  ${column([2, -2])};
  padding: ${g(2)} 0 ${g(6)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 7])};
    margin-top: ${g(4)};
    padding: ${g(4)} ${g(4)} ${g(6)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 7])};
  }
`
export const ButtonWrapper = styled.div`
  ${column([2, -2])};
  text-align: center;
`
export const ButtonPDF = styled(Button)`
  margin-top: ${g(4)};
`
export const ButtonWord = styled.a`
  ${typo.link12};
  display: block;
  margin-top: ${g(4)};
  text-decoration: underline;
`
interface SnackbarProps {
  $isOpen: PurchaseOfferProps['isSnackbarOpen']
}
export const Snackbar = styled.div<SnackbarProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  padding: ${g(2)};
  border-radius: ${g(1)} ${g(1)} 0 0;
  background-color: ${({ theme }) => theme.colors.primary1};
  opacity: 0;
  transform: translateY(${g(3)});
  transition: all 300ms ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  visibility: hidden;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    `};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([10, 4])};
    position: initial;
    border-radius: ${g(1)};
    position: sticky;
    grid-row: 2 / span 1;
    bottom: ${g(3)};
    align-self: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([10, 4])}
    padding: ${g(4)};
  }
`
export const SnackbarIllustration = styled(Illustration).attrs({
  options: {
    width: 148,
    height: 148,
  },
  path: 'v1650634826/zen/static-assets/illustrations/660x660/happy-shaped.svg',
})`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    width: 148px;
    height: 148px;
    margin: 0 auto ${g(3)};
  }
`
export const SnackbarTitle = styled.div`
  ${typo.heading32};
  margin-bottom: ${g(2)};
`
export const ButtonSnackbar = styled(Button)`
  margin-top: ${g(4)};
  width: 100%;
`
export const Close = styled(TimesBold)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: ${g(3)};
  height: ${g(3)};
  top: ${g(2)};
  right: ${g(2)};
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(3)};
    right: ${g(3)};
  }
`
