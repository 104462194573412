import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
  import styled from 'styled-components'

export const Rate = styled.div`
  text-align: center;
  margin-top: ${g(1)};
  margin-bottom: ${g(4)};
`
export const Row = styled.div`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const Value = styled(BaseTypo).attrs({  forwardedAs: 'span', typo: "bodyBold", })`
  color: ${({ theme, $valueColor }) => theme.colors[$valueColor || 'primary1']};
`