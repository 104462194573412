import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { ButtonBack } from '@pretto/zen/atoms/buttons/ButtonBack/ButtonBack'

import { ModalFooter } from '@pretto/app/src/onboarding/components/ModalFooter/ModalFooter'

interface NavigationButtonsProps {
  onNext?: () => void
  onPrevious: () => void
  isNextActive?: boolean
  isNextLoading?: boolean
}

export const NavigationButtons: React.FC<NavigationButtonsProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onNext,
  onPrevious,
  isNextActive,
  isNextLoading = false,
  ...props
}) => (
  <ModalFooter {...props}>
    <ButtonBack isArrowShown onClick={onPrevious}>
      Retour
    </ButtonBack>

    {isNextActive && (
      <Button isArrowShown onClick={onNext} isSecondary isLoading={isNextLoading}>
        Suivant
      </Button>
    )}
  </ModalFooter>
)
