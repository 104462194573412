import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'

import Front from '../../Helpers/frontchat'
import { usePropertySearch } from '../../PropertySearch/Containers/PropertySearchProvider'
import ListComponent from '../../SharedComponents/List'
import { useUser } from '../../User/Containers/UserProvider'

const List = ({ items, ...props }) => {
  const propertySearchContext = usePropertySearch()

  const userContext = useUser()

  const handleChat = () => {
    Front('open')
  }

  const formattedItems = items.reduce((previous, item) => {
    const itemProps = funcToItem(item, userContext, propertySearchContext, handleChat, true)

    if (!itemProps) {
      return previous
    }

    return [...previous, itemProps]
  }, [])

  return <ListComponent {...props} items={formattedItems} />
}

List.propTypes = {
  items: PropTypes.array.isRequired,
}

export default List
