import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'

import DOCS from '@pretto/config/docs.json'

export const getIsCertificateActive = ({ deal: { certificate }, docs, project }) => {
  const isCertificateDocumentPending =
    !certificate &&
    docs
      .filter(({ kind }) => DOCS[kind].certificate === true && kind !== 'other')
      .every(({ document_status }) => convertStatusSlug(document_status) === 'sent')

  return (
    project.project_kind !== 'renegotiation' &&
    project.purchase.maturity !== 'sale_agreement_signed' &&
    !certificate &&
    !isCertificateDocumentPending
  )
}
