import { ALLOWED_FILE_TYPES } from '@pretto/app-core/application/config/constants'

import escapeStringRegexp from 'escape-string-regexp'
import path from 'path'
import { v4 as uuidv4 } from 'uuid'

export interface FileSigned {
  id: string
  file: File
}

export const getFiles = <T extends { fileName: string }>(files: File[], existingFiles: T[]) =>
  files.reduce<FileSigned[]>((previous, file) => {
    if (file.size === 0 || !ALLOWED_FILE_TYPES.includes(file.type)) {
      return previous
    }

    const extname = path.extname(file.name)
    const basename = path.basename(file.name, extname)

    const id = uuidv4()

    const regex = new RegExp(`^${escapeStringRegexp(basename)}(?:\\(([\\d]+)\\))?${escapeStringRegexp(extname)}$`)

    const indexes = [...previous.map(({ file }) => file.name), ...existingFiles.map(({ fileName }) => fileName)].reduce<
      number[]
    >((previous, name) => {
      const match = name.match(regex)

      if (!match) {
        return previous
      }

      const [, index] = match

      if (index === undefined) {
        return [...previous, 0]
      }

      return [...previous, parseInt(index, 10)]
    }, [])

    if (indexes.length === 0 || !indexes.includes(0)) {
      return [...previous, { file, id }]
    }

    const max = indexes.reduce((previous, index) => Math.max(previous, index))

    const reconstructedIndexes = indexes.reduce<number[]>(
      (previous, index) => [...previous.slice(0, index - 1), index, ...previous.slice(index)],
      new Array(max)
    )

    const missingIndex = reconstructedIndexes.findIndex(index => index === undefined)

    const index = missingIndex !== -1 ? missingIndex : max

    return [
      ...previous,
      {
        file: new File([file], `${basename}(${index + 1})${extname}`, { type: file.type }),
        id,
      },
    ]
  }, [])
