import { g } from '@pretto/bricks/components/layout'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
`

export const Placeholder = styled.div`
  animation: ${pulse} 1.5s infinite ease-in-out;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  border-radius: 2px;
  height: 100%;
  min-height: ${g(2)};
  min-width: ${g(2)};
  width: 100%;
`
