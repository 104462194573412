import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid, Grid } from '@pretto/bricks/components/layout/Grid'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { FollowTooltip } from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'
import {
  TrustpilotRate as TrustpilotRateComponent,
  TrustpilotRateProps,
} from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import { List } from '@pretto/app/src/Refer/views/components/List'
import { Rtb } from '@pretto/app/src/Refer/views/components/Rtb'
import { Form as FormComponent, FormProps } from '@pretto/app/src/SignUp/components/Form/Form'

import { createRef, Fragment } from 'react'
import styled from 'styled-components'

interface ReferSignUpPageProps {
  formProps: FormProps
  trustpilotRateProps: TrustpilotRateProps
}

const Container = styled(Grid)`
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ theme }) => theme.colors.neutral4};
`

const Form = styled(FormComponent)`
  ${column([1, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([5, 4])};
    grid-row: 1 / span 2;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4}
  ${column([2, 4])};
  margin: ${g(1)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(2)};
    ${({ theme }) => theme.typos.heading3}
    ${column([2, 8])};
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(4)};
    margin-bottom: ${g(2)};
    ${({ theme }) => theme.typos.heading2}
    ${column([2, 12])};
  }
`

const TrustpilotRate = styled(TrustpilotRateComponent)`
  justify-content: center;
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

const SubTitle = styled.h2`
  ${({ theme }) => theme.typos.heading6}
  ${column([2, 4])};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    text-align: center;
    margin-bottom: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.body3};
    font-style: normal;
    ${column([2, 12])};
  }
`

const Content = styled.section`
  ${column([2, 4])};
  ${grid(4, { isLeftAligned: true, isRightAligned: true })};
  margin-bottom: ${g(10)};
  row-gap: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
    margin-bottom: ${g(6)};
    row-gap: ${g(6)};
    grid-template-rows: auto 1fr;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
    margin-bottom: ${g(12)};
    row-gap: ${g(5)};
  }
`

const Footer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${g(5)};
  padding-bottom: ${g(8)};
  row-gap: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(6)};
    padding-bottom: ${g(14)};
    row-gap: ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(10)};
    row-gap: ${g(10)};
  }
`

const FormLink = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.typos.body4Underline};
`

const Tooltip = styled(FollowTooltip)`
  cursor: initial;
`

const Tag = styled.strong`
  ${transition({ propertyName: 'all' })};
  padding: ${g(0.5)} ${g(1)};
  border-radius: ${g(0.5)};
  background-color: ${({ theme }) => theme.colors.accent3};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent2};
  }
`

const Benefits = styled(List)`
  ${column([1, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 5])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 4])};
  }
`

const HowTo = styled(List)`
  ${column([1, 4])};
  order: 3;

  @media screen and (min-width: ${breakpoints.tablet}) {
    order: 2;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 5])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 4])};
  }
`

export const ReferSignUpPage: React.FC<ReferSignUpPageProps> = ({ formProps, trustpilotRateProps, ...props }) => {
  const trustpilotRateAlignement = useBreakpointToValue('twolines', { tablet: 'inline' })

  const formRef = createRef<HTMLFormElement>()

  const handleHowToClick = () => {
    if (formRef.current) {
      formRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const howTo = [
    {
      key: 'fill',
      message: (
        <>
          Renseignez vos coordonnées
          <Responsive max="tablet">
            {' '}
            dans <FormLink onClick={handleHowToClick}>le formulaire</FormLink>
          </Responsive>
          .
        </>
      ),
    },
    {
      key: 'appointment',
      message: <>Prenez rendez-vous avec votre expert pour échanger sur votre projet.</>,
    },
    {
      key: 'offer',
      message: <>Vous payez Pretto seulement si vous obtenez votre prêt grâce à nous.</>,
    },
  ]

  const benefits = [
    {
      key: 'advisor',
      message: <>Son courtier est maintenant votre courtier.</>,
    },
    {
      key: 'savings',
      message: (
        <>
          On vous offre{' '}
          <Tooltip message="C’est toujours ça de pris ! 🚀">
            <Tag>200 €</Tag>
          </Tooltip>{' '}
          sur vos frais de courtage.
        </>
      ),
    },
  ]

  return (
    <Fragment {...props}>
      <Container>
        <Title>Bienvenue chez Pretto</Title>
        <SubTitle>Grâce à votre ami(e), votre projet est entre de bonnes mains !</SubTitle>
        <Content>
          <Benefits items={benefits} title="Grâce à son parrainage :" type="check" />
          <HowTo items={howTo} title="Comment ça marche ?" type="index" />
          <Form {...formProps} ref={formRef} />
        </Content>
      </Container>
      <Footer>
        <Rtb />
        <TrustpilotRate {...trustpilotRateProps} alignment={trustpilotRateAlignement} />
      </Footer>
    </Fragment>
  )
}
