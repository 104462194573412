import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'

const ServiceInsurance = () => {
  const { trackFeature } = useTrackFeatureCard()

  const handleClick = () => {
    trackFeature('renegotiation')
  }

  const props = {
    data: {
      onClick: handleClick,
    },
    slug: 'renegotiation',
  }

  return <ServiceCard {...props} />
}

export default ServiceInsurance
