import React, { createContext } from 'react'

interface SaveContextProps {
  emailFieldComponent: React.ReactElement
  isSaveOpen: boolean
  onOpenSave: () => unknown
  onCloseSave: () => unknown
}

const defaultContext: SaveContextProps = {
  emailFieldComponent: <></>,
  isSaveOpen: false,
  onOpenSave: () => undefined,
  onCloseSave: () => undefined,
}

export const SaveContext = createContext<SaveContextProps>(defaultContext)
