import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import styled, { css } from 'styled-components'

import { ButtonOrLinkProps, MenuItemProps } from './MenuItem'

interface UnvailableProps {
  $isUnavailable: boolean
}
interface LabelProps extends UnvailableProps {
  $isActive: NonNullable<MenuItemProps['isActive']>
}

interface StyledMenuItemProps extends UnvailableProps {
  $color: string
  $isActive: NonNullable<MenuItemProps['isActive']>
  href: ButtonOrLinkProps['href']
}

export const Picto = styled.div<UnvailableProps>`
  flex-shrink: 0;
  color: ${({ $isUnavailable, theme }) => ($isUnavailable ? theme.colors.neutral2 : 'inherit')};
`
export const Label = styled.div<LabelProps>`
  ${typo.bodyBook16};
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.white};
  transition: border-color 250ms ease-in-out;
  color: ${({ $isUnavailable, theme }) => ($isUnavailable ? theme.colors.neutral2 : 'inherit')};
  align-self: flex-start;

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral1};
    `};
`
export const MenuItem = styled.div.attrs(({ href }: StyledMenuItemProps) => ({
  as: href ? Link : 'button',
}))<StyledMenuItemProps>`
  color: ${({ $color, theme }) => theme.colors[$color]};
  height: ${g(7)};
  display: flex;
  align-items: center;
  gap: ${g(2)};
  padding: ${g(2)} 0 ${g(2)} ${g(2, 4)};
  cursor: ${({ $isUnavailable }) => ($isUnavailable ? 'default' : 'pointer')};
  flex-shrink: 0;
  background-color: inherit;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  &:hover {
    ${({ $isUnavailable }) =>
      !$isUnavailable &&
      css`
        background-color: ${({ theme }) => theme.colors.neutral4};
      `}
  }

  &:hover ${Label} {
    border-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.neutral1 : theme.colors.neutral4)};
  }
`
