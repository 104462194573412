import ApplicationFormSection from '@pretto/bricks/app/application/components/ApplicationFormSection'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from './styles'

const InviteFormPage = ({ error, isSubmitDisabled, onSubmit, sectionProps }) => (
  <div>
    <S.Wrapper>
      {error && (
        <S.Alert>
          <Card format="small" variant="error">
            <Content size="small">
              Désolé, un compte existe déjà avec cette adresse mail. Essayez un autre email, ou consultez cet{' '}
              <Link href="https://faq.pretto.fr/fr/articles/833730">article de notre FAQ.</Link>
            </Content>
          </Card>
        </S.Alert>
      )}

      <S.Title>
        <Heading>Invitez votre co-emprunteur</Heading>
      </S.Title>

      <ApplicationFormSection {...sectionProps} />

      <S.Button>
        <ButtonLegacy disabled={isSubmitDisabled} onClick={onSubmit}>
          Inviter
        </ButtonLegacy>
      </S.Button>
    </S.Wrapper>
  </div>
)

InviteFormPage.defaultProps = {
  error: false,
}

InviteFormPage.propTypes = {
  error: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sectionProps: PropTypes.object.isRequired,
}

export default InviteFormPage
