import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

const CollapsibleBox = ({ children, defaultOpen, icon, title, zDepth }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const toggleBox = () => setIsOpen(previousIsOpen => !previousIsOpen)

  return (
    <Card format="small-radius" variant="white" zDepth={zDepth}>
      <S.Box onClick={toggleBox}>
        <Content>
          <S.TitleContainer>
            <S.Title>
              {icon} {title}
            </S.Title>
            <S.ToggleIcon isOpen={isOpen}>
              <Icon name="arrow-full-bottom" variant="neutral-1-60" />
            </S.ToggleIcon>
          </S.TitleContainer>
        </Content>
      </S.Box>
      <CollapsibleRow in={isOpen}>
        <S.Content>{children}</S.Content>
      </CollapsibleRow>
    </Card>
  )
}

CollapsibleBox.defaultProps = {
  defaultOpen: false,
  zDepth: 1,
}

CollapsibleBox.propTypes = {
  /** Content of box. */
  children: PropTypes.node.isRequired,
  /** Default opened state of box. Opened if `true` */
  defaultOpen: PropTypes.bool,
  /** Title of box. */
  title: PropTypes.string.isRequired,
  /** card property */
  zDepth: PropTypes.number,
}

export default CollapsibleBox
