import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { Snackbar } from '@pretto/zen/main/banners/Snackbar/Snackbar'

import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  ${column([2, 4])};
  padding-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid(6, { isLeftAligned: true, isRightAligned: true })};
    ${column([3, 6])};
    padding-top: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
    padding-top: ${g(6)};
  }
`

export const Alert = styled(Snackbar).attrs({ status: 'alert' })`
  ${typo.bodyMedium16};
  ${helpers.boxShadow('card')};
  position: absolute;
  z-index: 10;
  top: ${g(-2.5)};
  display: flex;
  align-items: center;
  margin: 0 ${g(0.5)};
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.error2};
  color: ${({ theme }) => theme.colors.error1};
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    top: ${g(1)};
  }
`
