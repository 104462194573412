import Responsive from '@pretto/bricks/components/utility/Responsive'

import { ChevronRightBold, Picto } from '@pretto/picto'

import * as S from './FolderDocumentsCard.styles'

export type DocumentProgress = { current: string; target: string }

export interface FolderDocumentsCardProps {
  description: string | JSX.Element
  documentProgress: DocumentProgress
  href: string
  picto: Picto
  title: string
}

interface DocumentStepProps {
  documentProgress: DocumentProgress
}

const DocumentStep = ({ documentProgress }: DocumentStepProps) => (
  <S.DocumentProgressContainer>
    <S.DocumentProgressCurrent>{documentProgress.current}/</S.DocumentProgressCurrent>
    <S.DocumentProgressTarget>{documentProgress.target}</S.DocumentProgressTarget>
    <ChevronRightBold />
  </S.DocumentProgressContainer>
)

export const FolderDocumentsCard = ({
  description,
  documentProgress,
  href,
  picto,
  title = 'Documents',
  ...props
}: FolderDocumentsCardProps) => (
  <S.MainContainer href={href} {...props}>
    <div>
      <Responsive max={'tablet'}>
        <S.Row>
          <S.Picto $picto={picto} />
          <S.Title>{title}</S.Title>
          <Responsive max={'tablet'}>
            <DocumentStep documentProgress={documentProgress} />
          </Responsive>
        </S.Row>
      </Responsive>

      <Responsive min={'tablet'}>
        <S.HeaderContainer>
          <S.Picto $picto={picto} />
        </S.HeaderContainer>
        <S.Title>{title}</S.Title>
      </Responsive>

      <S.Description>{description}</S.Description>
    </div>

    <S.Footer>
      <Responsive min={'tablet'}>
        <DocumentStep documentProgress={documentProgress} />
      </Responsive>
    </S.Footer>
  </S.MainContainer>
)
