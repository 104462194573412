import { g } from '@pretto/bricks/components/layout'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { Envelope } from '@pretto/picto'

import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Contact = ({ subject }) => {
  const { lostReason, status } = useUser()
  const trackAction = useTracking()

  const handleClick = () => {
    trackAction('LOST_CONTACT_SUPPORT', {
      lostReason,
      dashboardOptions: status,
    })
  }
  return (
    <Container onClick={handleClick} $subject={subject}>
      <Envelope />
      <Label>hello@pretto.fr</Label>
    </Container>
  )
}

Contact.propTypes = {
  subject: PropTypes.string.isRequired,
}

const Container = styled.a.attrs(({ $subject }) => ({
  href: `mailto:hello@pretto.fr?subject=${$subject}`,
}))`
  margin-top: ${g(3)};
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-left: ${g(2)};
`
