import Emoji from '@pretto/bricks/components/iconography/Emoji'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { CONFIRM, GENERATE_SIGN_URL } from '@pretto/app/src/apollo'
import { MandateSignature } from '@pretto/app/src/mandate/views/MandateSignature/MandateSignature'

import { useMutation, useQuery } from '@apollo/client'
import HelloSign from 'hellosign-embedded'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const SignaturePage = ({ next, templateProps }) => {
  const { loading, data } = useQuery(CONFIRM)
  const { avatarProps } = useAdvisor()
  const {
    user: { activeIndex },
  } = useUser()
  const { replace } = useHistory()

  const [errorMessage, setErrorMessage] = useState(null)
  const [generateSignUrl] = useMutation(GENERATE_SIGN_URL)

  const MESSAGES = [
    <>
      <Emoji verticalAlign="center">:raised_hands:</Emoji> Tout est prêt, il ne reste plus qu’à signer votre mandat,
      pour autoriser Pretto à négocier auprès des banques en votre nom.
    </>,
  ]

  useEffect(() => {
    ;(async () => {
      if (!loading) {
        if (get(data, `deal.mandateStatus.signatures[${activeIndex}].signedAt`)) {
          replace('/mandate')
          return
        }

        const container = document.getElementById('helloSignModal')

        const client = new HelloSign({
          clientId: process.env.HELLOSIGN_CLIENT_ID,
          container,
          locale: 'fr_FR',
          skipDomainVerification: process.env.NODE_ENV !== 'production',
          testMode: process.env.NODE_ENV !== 'production',
        })

        const {
          data: {
            generate_sign_url: { url, error },
          },
        } = await generateSignUrl()
        if (url) client.open(url)
        if (error && error === 'NO_EMBEDDED_SIGNING')
          setErrorMessage(
            'Votre mandat vous a été envoyé par email. Vous y trouverez toutes les informations nécessaires pour le signer.'
          )

        client.open(url)

        client.once('finish', () => {
          next()
        })
      }
    })()
  }, [loading])

  if (loading) return <SpinnerLegacy overlay />

  const props = {
    avatarProps,
    errorMessage,
    messages: MESSAGES,
    templateProps,
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <MandateSignature {...props} />
    </>
  )
}
SignaturePage.propTypes = {
  next: PropTypes.func.isRequired,
  templateProps: PropTypes.object.isRequired,
}

export default SignaturePage
