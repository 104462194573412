import { CalendarSlot } from '@pretto/zen/dashboard/calendar/CalendarSlot/CalendarSlot'

import { ChevronLeftBold, ChevronRightBold } from '@pretto/picto'

import max from 'lodash/max'
import range from 'lodash/range'
import { useEffect, useRef, useState } from 'react'

import * as S from './Calendar.styles'

export interface CalendarProps {
  days: Day[]
  duration?: string
  isMoreButtonDisabled?: boolean
  isNavigationDisabled?: boolean
  isNextDisabled?: boolean
  isPreviousDisabled?: boolean
  isHeaderRowSticky?: boolean
  minimumRows?: number
  mutatingSlotValue?: string
  onNext: React.MouseEventHandler<HTMLButtonElement>
  onPrevious: React.MouseEventHandler<HTMLButtonElement>
  onSelect: (slot: Slot) => void
  timezone?: string
  truncatedRows?: number
  overlayVariant?: string
  headerVariant?: string
}

export interface Day {
  day: string
  dayShort: string
  date: string
  slots: Slot[]
}

export interface Slot {
  label: string
  value: string
}

export const Calendar = ({
  days,
  duration,
  isMoreButtonDisabled = false,
  isNavigationDisabled = false,
  isNextDisabled = false,
  isPreviousDisabled = false,
  isHeaderRowSticky = true,
  minimumRows = 5,
  mutatingSlotValue,
  onNext,
  onPrevious,
  onSelect,
  timezone,
  truncatedRows = 0,
  overlayVariant = 'white',
  headerVariant = 'neutral4',
  ...props
}: CalendarProps) => {
  const refDays = useRef(null)
  const refMask = useRef(null)

  const [isContainerOverflowingMask, setIsContainerOverflowingMask] = useState(false)
  const [shouldTruncate, setShouldTruncate] = useState(truncatedRows !== 0)

  useEffect(() => {
    if (!refDays.current || days.length > 1) {
      return
    }

    const observer = new IntersectionObserver(handleObserve, {
      root: refMask.current,
      threshold: 0.99,
    })

    observer.observe(refDays.current)

    return () => {
      observer.disconnect()
    }
  }, [days])

  useEffect(() => {
    setShouldTruncate(truncatedRows !== 0)
  }, [truncatedRows])

  const handleMore = () => {
    setShouldTruncate(false)
  }

  const handleObserve = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
    setIsContainerOverflowingMask(!isIntersecting)
  }

  const isDisabled = !!mutatingSlotValue
  const isMonoDay = days.length === 1

  const maximumSlots = max(days.map(({ slots = [] }) => slots.length)) ?? 0

  return (
    <S.Container {...props}>
      <S.HeaderOffset $isHeaderRowSticky={isHeaderRowSticky} $overlayVariant={overlayVariant} />

      <S.Header
        $isMonoDay={isMonoDay}
        $isHeaderRowSticky={isHeaderRowSticky}
        $overlayVariant={overlayVariant}
        $headerVariant={headerVariant}
      >
        <S.Dates $isNavigationDisabled={isNavigationDisabled}>
          {!isNavigationDisabled && (
            <S.NavigationArrowLeft
              $isMonoDay={isMonoDay}
              disabled={isDisabled || isPreviousDisabled}
              onClick={onPrevious}
            >
              <ChevronLeftBold />
            </S.NavigationArrowLeft>
          )}

          {days.map(({ day, dayShort, date }) => {
            const label = `${day} ${date}`

            return (
              <S.Date key={label}>
                {isMonoDay ? (
                  label
                ) : (
                  <>
                    <S.DayShortLabel>{dayShort}</S.DayShortLabel> <S.DateLabel>{date}</S.DateLabel>
                  </>
                )}
              </S.Date>
            )
          })}

          {!isNavigationDisabled && (
            <S.NavigationArrowRight $isMonoDay={isMonoDay} disabled={isDisabled || isNextDisabled} onClick={onNext}>
              <ChevronRightBold />
            </S.NavigationArrowRight>
          )}
        </S.Dates>

        {duration && <S.HeaderRow>Durée du rendez-vous : {duration}</S.HeaderRow>}

        {timezone && <S.HeaderRow>Heure locale ({timezone})</S.HeaderRow>}
      </S.Header>

      <S.Body $isMonoDay={isMonoDay}>
        <S.Mask $shouldTruncate={shouldTruncate} $truncatedRows={truncatedRows} ref={refMask}>
          <S.Days ref={refDays}>
            {days.map(({ day, date, slots }) => (
              <S.Slots $isMonoDay={isMonoDay} key={`${day}-${date}`}>
                {range(
                  isMonoDay
                    ? slots.length
                    : Math.min(shouldTruncate ? truncatedRows : Infinity, Math.max(minimumRows, maximumSlots))
                ).map(index => {
                  const handleClick = () => {
                    if (!slot) {
                      return
                    }

                    onSelect(slot)
                  }

                  const slot = slots[index]

                  return (
                    <CalendarSlot
                      key={slot?.value ?? index}
                      isDisabled={isDisabled}
                      isLoading={!!mutatingSlotValue && mutatingSlotValue === slot?.value}
                      onClick={handleClick}
                    >
                      {slot?.label}
                    </CalendarSlot>
                  )
                })}
              </S.Slots>
            ))}
          </S.Days>
        </S.Mask>
      </S.Body>

      {shouldTruncate &&
        (isMonoDay ? isContainerOverflowingMask : maximumSlots > truncatedRows) &&
        !isMoreButtonDisabled && (
          <S.Footer>
            <S.More onClick={handleMore}>Voir plus de créneaux</S.More>
          </S.Footer>
        )}
    </S.Container>
  )
}
