import { g } from '@pretto/bricks/components/layout'

import { FieldsComponentProps } from '@pretto/app/src/Simulation/types/Pushy'

import styled, { css } from 'styled-components'

type FieldsProps = FieldsComponentProps & React.HTMLAttributes<HTMLFieldSetElement>

export const Fields: React.FC<FieldsProps> = ({ firstName, lastName, email, phone, ...props }) => (
  <Fieldset {...props}>
    <Field>{firstName}</Field>
    <Field>{lastName}</Field>
    <Field $colspan={2}>{email}</Field>
    {phone && <Field $colspan={2}>{phone}</Field>}
  </Fieldset>
)

const Fieldset = styled.fieldset`
  display: grid;
  grid-gap: ${g(2)};
  grid-template-columns: 1fr 1fr;
`

interface FieldProps {
  $colspan?: number
}

const Field = styled.div<FieldProps>`
  ${({ $colspan }) =>
    $colspan &&
    css`
      grid-column: span ${$colspan};
    `}
`
