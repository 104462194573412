import BaseDashboardHighlights from '@pretto/bricks/app/dashboard/components/DashboardHighlights'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const DashboardHighlights = styled(BaseDashboardHighlights)`
  display: flex;
  margin-bottom: ${g(3)};
  margin-top: ${g(-1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(-3)};
  }
`
