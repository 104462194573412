import { Field, InputDefault } from '@pretto/app/src/purchaseOffer/config/types'

import isNil from 'lodash/isNil'
import { useState } from 'react'

export const useInputDefault = <V extends number | string>({ props, type }: InputDefault<V>): Field<V | null> => {
  const [value, setValue] = useState<V | null>(null)

  const handleChange = (value: V) => {
    setValue(value)
  }

  const checkForError = () => {
    if (isNil(value)) {
      return true
    }

    if (typeof value === 'string') {
      return value.trim().length > 0
    }

    return false
  }

  return {
    error: checkForError(),
    input: {
      props: {
        ...props,
        onChange: handleChange,
        value: value?.toString() ?? '',
      },
      type: type ?? 'text',
    },
    value,
  }
}
