import Avatar from '@pretto/bricks/components/images/Avatar'

import PropTypes from 'prop-types'

import { useAdvisor } from '../../SharedContainers/AdvisorDialog'

const AdvisorAvatar = ({ avatarSrc, size, ...props }) => {
  const { onOpen } = useAdvisor()

  return <Avatar {...props} onClick={onOpen} size={size} src={avatarSrc} />
}

AdvisorAvatar.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default AdvisorAvatar
