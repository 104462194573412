import { g } from '@pretto/bricks/components/layout'
import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { ButtonLink } from '@pretto/zen/atoms/buttons/ButtonLink/ButtonLink'
import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { CheckBold } from '@pretto/picto'

import styled from 'styled-components'

const Modal = styled(Dialog)`
  padding: ${g(5)};
  padding-bottom: ${g(8)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h4`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(5)};
`

const Appointment = styled.div`
  margin-bottom: ${g(3)};
  display: flex;
  align-items: center;
`

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${g(4)};
  height: ${g(4)};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary4};
  margin-right: ${g(2)};
`

const Check = styled(CheckBold)`
  color: ${({ theme }) => theme.colors.primary1};
`

const Simulation = styled.div`
  margin-bottom: ${g(5)};
`

const ModalCTA = styled(PrimaryButtonFix)`
  margin-bottom: ${g(4)};
`

interface ModalConfirmationProps {
  isOpen: boolean
  date: string
  onCloseDialog: () => void
  advisorName: string
}

export const ModalConfirmation: React.FC<ModalConfirmationProps> = ({ isOpen, date, onCloseDialog, advisorName }) => {
  return (
    <Modal isOpen={isOpen}>
      <Title>Rendez-vous confirmé</Title>
      <Appointment>
        <CheckContainer>
          <Check />
        </CheckContainer>
        Votre rendez-vous : <strong>{date}</strong>
      </Appointment>
      <Simulation>
        Il ne vous reste plus qu’à faire <strong>votre simulation</strong> personnalisée pour préparer votre rendez-vous
        avec votre Expert <strong>{advisorName}</strong> !
      </Simulation>
      <ModalCTA href="/project/choice">Faire ma simulation</ModalCTA>
      <ButtonLink onClick={onCloseDialog}>Fermer cette fenêtre</ButtonLink>
    </Modal>
  )
}
