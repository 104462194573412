import EditableRow from '@pretto/bricks/app/simulator/components/EditableRow'

import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import * as C from './EditableFields.module.css'

export default class EditableFields extends PureComponent {
  static propTypes = {
    /** Array of <code>[EditableRow](/#!/EditableRow)</code> props coponents.*/
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { fields } = this.props
    return (
      <div>
        {fields.map((field, index) => {
          return (
            <div key={index} className={C.editableFieldsField}>
              <EditableRow {...field} />
            </div>
          )
        })}
      </div>
    )
  }
}
