import { mapLiveTogetherValues } from '@pretto/app/src/Sentences/v2/Lodging/LiveTogether/lib/mapper/mapLiveTogetherValues'
import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const LiveTogetherPage: React.FC = () => {
  const context = useSentences()
  const { liveTogether } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(liveTogether)))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'liveTogether' } }}>
      <Title>{t('sentences.lodging.title')}</Title>
      <div>
        {t('sentences.lodging.liveTogether.sentences.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(liveTogether)}>
          {t(`sentences.lodging.liveTogether.values.${liveTogether ? 'yes' : 'no'}`)}
        </Prompt>
        {t('sentences.lodging.liveTogether.sentences.1')}.
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapLiveTogetherValues(Object.values(YesNo), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingLiveTogether' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {!isNil(liveTogether) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
