import Emoji from '@pretto/bricks/components/iconography/Emoji'
import ListOption from '@pretto/bricks/shared/components/ListOption'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OPTIONS = {
  offer_accepted: {
    emoji: ':tada:',
    label: 'J’ai une offre d’achat acceptée',
  },
  offer_made: {
    emoji: ':house_with_garden:',
    label: 'J’ai fait une offre d’achat pour un bien',
  },
  sale_agreement_signed: {
    emoji: ':pen_ballpoint:',
    label: "J'ai signé un compromis de vente",
  },
  search_started: {
    emoji: ':mag_right:',
    label: 'Je suis en recherche active de bien',
  },
  under_study: {
    emoji: ':question:',
    label: 'Je suis en réflexion',
  },
}

const MaturityChoice = ({ onClick, option, isPlaceholder, ...props }) => {
  const { label, emoji } = OPTIONS[option] || {}

  return (
    <ListOption {...props} onClick={onClick} value={option}>
      {option && (
        <S.Content>
          <S.Emoji>
            <Emoji size="medium">{!isPlaceholder ? emoji : ':house:'}</Emoji>
          </S.Emoji>

          {!isPlaceholder ? label : <S.Placeholder />}
        </S.Content>
      )}

      {!onClick && <S.Edit>Modifier</S.Edit>}
    </ListOption>
  )
}

MaturityChoice.propTypes = {
  /** Whether or not the placeholder should be displayed instead of the option. */
  isPlaceholder: PropTypes.bool,
  /** Triggered when the option is pressed. */
  onClick: PropTypes.func,
  /** Option id to be displayed. */
  option: PropTypes.oneOf(Object.keys(OPTIONS)),
}

export default memo(MaturityChoice)
