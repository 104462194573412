import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const FieldParent = styled.div``

export const FieldChild = styled.div`
  ${FieldParent} + ${FieldParent} & {
    padding-top: ${g(3)};

    @media only screen and (min-width: ${breakpoints.tablet}) {
      padding-top: ${g(4)};
    }
  }
`
