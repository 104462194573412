import { temporal } from '@pretto/bricks/core/utility/temporal'

import type { ComposableMapper } from '@pretto/app/src/Sentences/v2/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { ProjectKind } from '@pretto/app/src/types/gateway/enums'

export const mapRenegotiation: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)
  const renegotiation = payload.renegotiation

  if (payload.project_kind === ProjectKind.Renegotiation && renegotiation) {
    if (renegotiation.bank) updatedContext.bank = renegotiation.bank
    if (renegotiation.end_date) updatedContext.endYear = temporal(renegotiation.end_date).year()
    if (renegotiation.estimated_value) updatedContext.estimatedValue = renegotiation.estimated_value
    if (renegotiation.remaining_principal) updatedContext.remainingPrincipal = renegotiation.remaining_principal
    if (renegotiation.rate) updatedContext.rate = renegotiation.rate
  }

  return {
    payload,
    context: updatedContext,
  }
}
