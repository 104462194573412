import { ng } from '@pretto/bricks/components/layout'

import range from 'lodash/range'
import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import scaleValueForIndex from './lib/scaleValueForIndex'
import * as S from './styles'

const SIDE_LENGTH = 2
const FIRST_DOT_INDEX = 0

const PaginationDots = ({
  activeVariant,
  currentIndex: controlledCurrentIndex,
  inactiveVariant,
  length,
  onClick,
  visibleLength = length,
  ...props
}) => {
  const offsetVisibleLength = visibleLength - 1

  const [[fromIndex, currentIndex, toIndex], setIndexes] = useState([
    FIRST_DOT_INDEX,
    controlledCurrentIndex,
    controlledCurrentIndex + offsetVisibleLength,
  ])

  useEffect(() => {
    const delta = controlledCurrentIndex - currentIndex

    if (delta < 0) {
      previous(delta)
    }

    if (delta > 0) {
      next(delta)
    }
  }, [controlledCurrentIndex])

  const theme = useTheme()

  const next = delta => {
    setIndexes(([fromIndex, currentIndex, toIndex]) => {
      if (currentIndex + delta - 1 >= toIndex) {
        return [currentIndex + delta - offsetVisibleLength, currentIndex + delta, currentIndex + delta]
      }

      return [fromIndex, currentIndex + delta, toIndex]
    })
  }

  const previous = delta => {
    setIndexes(([fromIndex, currentIndex, toIndex]) => {
      if (currentIndex + delta + 1 <= fromIndex) {
        return [currentIndex + delta, currentIndex + delta, currentIndex + delta + offsetVisibleLength]
      }

      return [fromIndex, currentIndex + delta, toIndex]
    })
  }

  const diameter = ng(1)

  const visibleRange = range(Math.max(0, fromIndex - SIDE_LENGTH), Math.min(toIndex + SIDE_LENGTH + 1, length))

  const visibleWidth =
    visibleRange.reduce(
      (previous, index) => previous + diameter * scaleValueForIndex(index - fromIndex, offsetVisibleLength),
      0
    ) +
    (visibleRange.length - 1) * diameter

  if (visibleLength < 1 || visibleLength > length) {
    throw new Error('visibleLength out of bounds')
  }

  return (
    <S.Dots {...props}>
      {range(length).map(index => {
        const handleClick = () => {
          onClick(index)
        }

        const scale = scaleValueForIndex(index - fromIndex, offsetVisibleLength)

        const translateX =
          range(0, index).reduce(
            (previous, index) =>
              previous + diameter * scaleValueForIndex(index - fromIndex, offsetVisibleLength) + diameter,
            0
          ) -
          Math.max(0, fromIndex - 2) * diameter -
          visibleWidth / 2

        return (
          <S.Dot
            key={index}
            onClick={handleClick}
            style={{
              backgroundColor:
                currentIndex === index
                  ? theme.colors[activeVariant] ?? activeVariant
                  : theme.colors[inactiveVariant] ?? inactiveVariant,
              transform: `translateX(${translateX}px) scale(${scale})`,
            }}
          />
        )
      })}
    </S.Dots>
  )
}

PaginationDots.defaultProps = {
  activeVariant: 'white',
  inactiveVariant: 'primary2',
  onClick: () => {},
}

PaginationDots.propTypes = {
  /** Variant color of active dots. */
  activeVariant: PropTypes.string,
  /** Index of current active dot. */
  currentIndex: PropTypes.number,
  /** Variant color of inactive dots. */
  inactiveVariant: PropTypes.string,
  /** Number of dots. */
  length: PropTypes.number.isRequired,
  /** Event triggered whenever a dot is being pressed. */
  onClick: PropTypes.func,
  /** Number of visible dots. */
  visibleLength: PropTypes.number,
}

export default memo(PaginationDots)
