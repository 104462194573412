import { Link } from 'react-router-dom'

export const simulationCard = {
  emoji: ':house_with_garden:',
  title: 'Réaliser une simulation',
  description: 'Simulez en quelques minutes votre prêt immobilier.',
  buttonText: 'Simuler mon prêt',
}
export const clientCard = {
  title: 'Vous avez déjà un espace Pretto ?',
  description: (
    <p>
      <Link to="/login">Connectez-vous</Link> avec l{"'"}email que vous aviez communiqué à Pretto, ou contactez votre
      expert si vous l{"'"}avez oublié.
    </p>
  ),
  buttonText: 'Contactez-nous',
}
