import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Action = styled.div`
  & + & {
    margin-left: ${g(1)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-left: ${g(2)};
    }
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Banner = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 300ms ease-out;
  z-index: 1000;

  ${({ animatingState }) => {
    switch (animatingState) {
      case 'entering':
      case 'entered':
        return css`
          transform: translateY(0);
        `

      default:
        return css`
          transform: translateY(-100%);
        `
    }
  }}

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const ContentBadge = styled.div`
  margin-right: ${g(2)};
`

export const Wrapper = styled.div`
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: ${g(1.5)} 0;
  }
`
