import { g } from '@pretto/bricks/components/layout'

import { ModalContent } from '@pretto/zen/main/modals/ModalContent/ModalContent'

import { Picto } from '@pretto/picto'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

interface PictoProps {
  $picto: Picto
}

export const Content = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > * + * {
    margin-top: ${g(2)};
  }
`

export const Description = styled.div``

export const Infos = styled.div`
  padding: ${g(3)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
`

export const InfoContainer = styled.div`
  & + & {
    margin-top: ${g(3)};
  }
`

export const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${g(2)};
`

export const InfoContent = styled.p`
  ${typo.bodyMedium16};
  color: ${({ theme }) => theme.colors.primary1};
`

export const Icon = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  width: ${g(3)};
  height: ${g(3)};
  margin-right: ${g(1)};
`