import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Tooltip as TooltipComponent } from '@pretto/zen/atoms/infos/Tooltip/Tooltip'

import btoa from 'btoa-lite'
import styled, { css } from 'styled-components'

import { SentenceFieldSimpleProps } from './SentenceFieldSimple'

type State = NonNullable<SentenceFieldSimpleProps['state']>

export interface IConfig {
  bgColor: keyof IPalette
  color: keyof IPalette
  fill: keyof IPalette
}

export const COLOR: Record<State, IConfig> = {
  default: {
    bgColor: 'neutral4',
    color: 'neutral3',
    fill: 'primary1',
  },
  error: {
    bgColor: 'error2',
    color: 'neutral3',
    fill: 'error1',
  },
  warning: {
    bgColor: 'neutral4',
    color: 'neutral3',
    fill: 'warning1',
  },
}

interface StateProps {
  $state: State
}

interface ComponentProps extends StateProps {
  $patternWidth: number
}

export const Suffix = styled.span`
  ${typo.bodyMedium16};
  white-space: nowrap;
`

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  z-index: 0;
`

export const Picto = styled.svg<StateProps>`
  display: block;
  color: ${({ theme, $state }) => theme.colors[COLOR[$state].fill]};
  margin-left: ${g(1)};
  ${({ $state }) =>
    $state !== 'default' &&
    css`
      cursor: help;
    `};
`

export const Component = styled.div<ComponentProps>`
  display: flex;
  padding: 0 ${g(0.5)};
  background-image: url('data:image/svg+xml;base64,${({ theme, $patternWidth, $state }) =>
    btoa(
      `<svg viewBox="0 0 ${$patternWidth} ${
        $patternWidth / 2
      }" xmlns="http://www.w3.org/2000/svg"><circle cx="1" cy="1" r="1" fill="${
        theme.colors[COLOR[$state].fill]
      }"/></svg>`
    )}');

  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 4px 2px;
  position: relative;
  &:focus-within::before,
  &:hover::before {
    position: absolute;
    content: '';
    top: 0;
    right: 3px;
    bottom: 1px;
    left: 1px;
    z-index: -1;
    background-color: ${({ theme, $state }) => theme.colors[COLOR[$state].bgColor]};
  }
`

export const Input = styled.input<StateProps>`
  ${typo.bodyMedium16};
  color: ${({ theme, $state }) => theme.colors[COLOR[$state].fill]};
  border: 0;
  padding: 0;
  background-color: transparent;
  caret-color: ${({ theme, $state }) => theme.colors[COLOR[$state].fill]};
  width: 100%;
  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: ${({ theme, $state }) => theme.colors[COLOR[$state].color]};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const HiddenComponent = styled(Component)`
  display: inline-flex;
  position: absolute;
  left: -99999px;
  visibility: hidden;
`

export const HiddenInput = styled(Input).attrs({ as: 'div' })`
  white-space: pre;
`

export const Tooltip = styled(TooltipComponent)`
  a,
  button {
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`
