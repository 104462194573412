import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import BubbleChat from '@pretto/app/src/SharedComponents/BubbleChat'

import React from 'react'
import styled from 'styled-components'

const Container = styled(BubbleChat).attrs({ cardProps: { variant: 'neutral5' } })`
  margin-bottom: ${g(1)};
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

const Hello = styled.h5`
  ${({ theme }) => theme.typos.heading5};
  color: ${({ theme }) => theme.colors.neutral1};
  margin-bottom: ${g(1)};
`

interface BubbleProps {
  advisor: {
    name: string
  }
  user: {
    firstName: string
  }
}

export const Bubble: React.FC<BubbleProps> = ({ advisor, user }) => {
  return (
    <Container>
      <Hello>Bonjour{` ${user.firstName}`} !</Hello>
      Je suis {advisor.name}, votre expert crédit attitré. Je suis là pour simplifier votre recherche de crédit et vous
      guider. Vous pouvez réserver dès maintenant un rendez-vous dans mon agenda.
    </Container>
  )
}
