import { boxShadow } from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { Loader as LoaderComponent } from '@pretto/zen/reveal/atoms/loader/Loader'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled, { css } from 'styled-components'

import type { Scheme } from './SecondaryButton'

interface StateProps {
  $isDisabled: boolean
  $isLoading: boolean
}

interface ContainerProps extends StateProps {
  $scheme: Scheme
  href?: string
  tabIndex?: number
}

export const Container = styled.span.attrs<ContainerProps>(({ $isDisabled, href, tabIndex }) => ({
  as: !href ? 'button' : Link,
  disabled: !href ? $isDisabled : undefined,
  tabIndex: $isDisabled ? -1 : tabIndex,
}))<ContainerProps>`
  ${({ theme }) => theme.typos.button};
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  appearance: none;
  border-radius: ${g(1)};
  border: none;
  display: inline-flex;
  gap: ${g(2)};
  line-height: ${g(8)};
  padding: 0 ${g(3)};
  position: relative;
  white-space: nowrap;
  ${transition({ propertyName: 'background-color' }, { propertyName: 'box-shadow' })};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${boxShadow('card')};
    `}

  ${({ $isDisabled, $isLoading, $scheme, theme }) => {
    switch ($scheme) {
      case 'black':
        return css`
          background-color: ${theme.colors.neutral1};

          ${!$isDisabled &&
          css`
            &&:hover,
            &&:focus {
              background-color: ${theme.colors.neutral1Hover};
            }
          `}

          ${$isDisabled &&
          !$isLoading &&
          css`
            background-color: ${theme.colors.neutral1Disabled};
          `}
        `
      default:
        return css`
          background-color: ${theme.colors.primary1};

          ${!$isDisabled &&
          css`
            &&:hover,
            &&:focus {
              background-color: ${theme.colors.primary2};
            }
          `}

          ${$isDisabled &&
          !$isLoading &&
          css`
            background-color: ${theme.colors.primary1Disabled};
          `}
        `
    }
  }}
`

const fadeOut = css<StateProps>`
  ${transition({ delay: '200ms', propertyName: 'opacity' })};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      ${transition({ propertyName: 'opacity' })};
    `}
`

const fadeIn = css<StateProps>`
  opacity: 0;
  ${transition({ propertyName: 'opacity' })};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 1;
      ${transition({ delay: '200ms', propertyName: 'opacity' })};
    `}
`

export const Label = styled.span<StateProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  ${fadeOut};
`

export const Loader = styled(LoaderComponent).attrs({ color: 'white' })`
  bottom: 0;
  display: flex;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  ${fadeIn};
`
