import { g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'

import styled from 'styled-components'

interface PaymentProps {
  value: React.ReactNode
}

export const Payment: React.FC<PaymentProps> = ({ value, ...props }) => (
  <Component {...props}>
    {typeof value === 'string' ? <Price>{value}</Price> : value}
    <Unit>/ mois</Unit>
  </Component>
)

const Component = styled.div`
  align-items: center;
  display: flex;
  gap: ${g(1)};
`

const Price = styled.span`
  ${typography.numbers48};
  color: ${({ theme }) => theme.colors.primary2};
`

const Unit = styled.span`
  ${typography.bodyMedium16};
`
