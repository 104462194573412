import SepaDialogContent from '@pretto/bricks/app/mandate/components/SepaDialogContent'
import SepaSidebar from '@pretto/bricks/app/mandate/components/SepaSidebar'
import MandateTemplate from '@pretto/bricks/app/mandate/templates/MandateTemplate'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Logo from '@pretto/bricks/components/images/Logo'
import Content from '@pretto/bricks/components/layout/Content'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Text from '@pretto/bricks/components/typography/Text'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'
import temporal from '@pretto/bricks/core/utility/temporal'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'
import Link from '@pretto/bricks/website/utility/Link'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

const REASSURE_PARAGRAPH =
  'Vous ne serez débité qu’en cas de succès de votre projet avec Pretto, c’est-à-dire si vous signez un prêt que nous vous aurons proposé et lorsque vous nous aurez confirmé le déblocage des fonds. Nous vous notifierons avant d’effectuer ce prélèvement'

const MandateSepaPage = ({
  avatarProps,
  creditorId,
  email,
  emailComponent,
  firstName,
  firstNameComponent,
  hasComortgagor,
  iban,
  ibanComponent,
  isSubmitDisabled,
  lastName,
  lastNameComponent,
  onError,
  onRedirect,
  onSubmit,
  onValidate,
  reference,
  sepaStatus,
  templateProps,
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { isMobile } = useBreakpoint()

  const date = temporal()

  const handleSubmit = e => {
    e.preventDefault()
    setIsConfirmOpen(true)
    onValidate()
  }

  const handleConfirm = () => {
    onSubmit()
  }

  const handleCancel = () => {
    if (sepaStatus === 'SUCCESS') {
      onRedirect()
      return
    }

    setIsConfirmOpen(false)
  }

  const sidebarProps = {
    avatarProps,
    hasComortgagor,
    informationText: REASSURE_PARAGRAPH,
  }

  const dialogProps = {
    creditorId,
    date: date.format('DD/MM/YYYY'),
    email,
    firstName,
    handleConfirm,
    iban,
    informationText: REASSURE_PARAGRAPH,
    lastName,
    onCancel: handleCancel,
    onError,
    onRedirect,
    reference,
  }

  return (
    <MandateTemplate
      {...templateProps}
      sidebar={<SepaSidebar {...sidebarProps} />}
      title={
        isMobile ? (
          'Autorisation SEPA'
        ) : (
          <>
            <Emoji size="medium">:credit_card:</Emoji> Autorisation de prélèvement SEPA
          </>
        )
      }
    >
      <S.Container>
        <S.Content>
          <Responsive min="laptop">
            <S.Logo>
              <Logo />
            </S.Logo>
          </Responsive>
          <S.Fields onSubmit={handleSubmit}>
            <S.FirstName>
              <label htmlFor="firstName">
                <Text size="small">Prénom</Text>
              </label>
              {firstNameComponent}
            </S.FirstName>

            <S.LastName>
              <label htmlFor="lastName">
                <Text size="small">Nom</Text>
              </label>
              {lastNameComponent}
            </S.LastName>

            <S.Field>
              <label htmlFor="email">
                <Text size="small">Email</Text>
              </label>
              {emailComponent}
            </S.Field>

            <S.Field>
              <label htmlFor="iban">
                <Text size="small">IBAN</Text>
              </label>
              {ibanComponent}
            </S.Field>

            <S.SubmitButton>
              <ButtonLegacy icon="locker-closed" iconPosition="right" type="submit" disabled={isSubmitDisabled}>
                Valider
              </ButtonLegacy>
            </S.SubmitButton>
          </S.Fields>
        </S.Content>
      </S.Container>

      <Responsive max="laptop">
        <S.Divider>
          <Divider />
        </S.Divider>
      </Responsive>

      <S.Container>
        <S.GoCardlessLogos>
          <img src={getCloudinaryUrl('v1587993141/bricks/static-assets/gocardless-logo.svg')} alt="" />
          <img src={getCloudinaryUrl('v1587993141/bricks/static-assets/direct-debit-logo.svg')} alt="" />
        </S.GoCardlessLogos>
        <Text size="x-small">
          Paiements effectués par GoCardless. Lisez{' '}
          <Link href="https://gocardless.com/fr-fr/legal/politique-de-confidentialite/">l'avis de confidentialité</Link>{' '}
          GoCardless.
        </Text>
      </S.Container>

      <Dialog isOpen={isConfirmOpen} onRequestClose={handleCancel}>
        <DialogTemplate
          buttonProps={
            !sepaStatus
              ? {
                  children: 'Confirmer',
                  onClick: handleConfirm,
                }
              : null
          }
          isButtonSticky
          navbarProps={{
            iconLeft: TimesBold,
            iconLeftAction: handleCancel,
            title: 'Mandat de prélèvement SEPA',
          }}
          size={!sepaStatus ? 'medium' : null}
        >
          <Content>
            <SepaDialogContent status={sepaStatus} {...dialogProps} />
          </Content>
        </DialogTemplate>
      </Dialog>
    </MandateTemplate>
  )
}

MandateSepaPage.propTypes = {
  /** Avatar source for bubble chat. */
  avatarProps: PropTypes.object.isRequired,
  /** Creditor used for ID for GoCardless. It must be shown to the user before confirmation. */
  creditorId: PropTypes.string.isRequired,
  /** Value of email input. */
  email: PropTypes.string.isRequired,
  /** Email input component. */
  emailComponent: PropTypes.element.isRequired,
  /** Value of first name input. */
  firstName: PropTypes.string.isRequired,
  /** First name input component. */
  firstNameComponent: PropTypes.element.isRequired,
  /** Whether there is a comortgagor or not. */
  hasComortgagor: PropTypes.bool,
  /** Value of IBAN input. */
  iban: PropTypes.string.isRequired,
  /** IBAN input component. */
  ibanComponent: PropTypes.element.isRequired,
  /** Whether form button is disabled. */
  isSubmitDisabled: PropTypes.bool,
  /** Value of last name input. */
  lastName: PropTypes.string.isRequired,
  /** Last name input component. */
  lastNameComponent: PropTypes.element.isRequired,
  /** Function called when error occured and assistance is requested. */
  onError: PropTypes.func.isRequired,
  /** Function called after SEPA signed with success. */
  onRedirect: PropTypes.func.isRequired,
  /** Submit form action. */
  onSubmit: PropTypes.func.isRequired,
  /** Triggerd on validate button, also used to open information modal. */
  onValidate: PropTypes.func,
  /** SEPA reference, used for success modal. */
  reference: PropTypes.string,
  /** Status of SEPA for the page. Used to show different content of modal. */
  sepaStatus: PropTypes.oneOf(['LOADING', 'SUCCESS', 'IBAN_INVALID', 'PAYMENT_ERROR']),
  /** Properties of `MandateTemplate` bricks component. */
  templateProps: PropTypes.object,
}

export default MandateSepaPage
