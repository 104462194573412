import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const SubHeading = styled.div`
  color: ${({ theme, variant }) => theme.legacy.colors[variant] || 'inherit'};
  text-overflow: ellipsis;

  ${({ format }) => {
    switch (format) {
      case 'title':
        return css`
          overflow: visible;
        `
      case 'card-title':
        return css`
          opacity: 0.6;
          text-transform: uppercase;
        `

      case 'inherit':
        return css`
          color: inherit;
        `

      default:
        return ''
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          ${({ theme }) => theme.typos.bodyBook}
        `

      case 'large':
        return css`
          ${({ theme }) => theme.typos.heading4}
        `

      default:
        return css`
          ${({ theme }) => theme.typos.heading5}
        `
    }
  }}
  
  &:target:before {
    content: '';
    display: block;
    height: ${g(12)};
    margin: ${g(-12)} 0 0;
  }
`
