import { cloneElement, createContext, useState } from 'react'

interface Loader {
  id: string
  type?: string
}

interface LoadingContext {
  del: (id: string) => void
  set: (id: string, isLoading: boolean, type?: string) => void
}

interface ProviderProps {
  loaderComponent: JSX.Element
}

export const Context = createContext<LoadingContext>({
  del() {
    // nothing
  },
  set() {
    // nothing
  },
})

export const Provider: React.FC<ProviderProps> = ({ children, loaderComponent }) => {
  const [loaders, setLoaders] = useState<Loader[]>([])

  const del = (id: string) => {
    setLoaders(loaders => loaders.filter(loader => loader.id !== id))
  }

  const set = (id: string, isLoading: boolean, type?: string) => {
    setLoaders(loaders => {
      if (!isLoading) {
        return loaders.filter(loader => loader.id !== id)
      }

      const loaderIndex = loaders.findIndex(loader => loader.id !== id)

      if (loaderIndex > -1) {
        return [...loaders.slice(0, loaderIndex), { id, type }, ...loaders.slice(loaderIndex + 1)]
      }

      return [...loaders, { id, type }]
    })
  }

  return (
    <Context.Provider value={{ del, set }}>
      <>
        {children}
        {loaders.length > 0 && cloneElement(loaderComponent, { type: loaders[loaders.length - 1]?.type })}
      </>
    </Context.Provider>
  )
}
