import FooterInfo from '@pretto/bricks/app/simulator/components/FooterInfo'
import TimelineMobile from '@pretto/bricks/app/simulator/components/TimelineMobile'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { heading32 } from '@pretto/bricks/core/typography'

import { Status } from '@pretto/zen/atoms/infos/Status/Status'

import styled from 'styled-components'

import { BlockOnboarding as BlockOnboardingComponent } from './children/BlockOnboarding'
import { BlockRateDoesntCount as BlockRateDoesntCountComponent } from './children/BlockRateDoesntCount/BlockRateDoesntCount'

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${g(3.5)};
`

export const Action = styled(BaseTypo).attrs({ typo: 'link' })`
  display: flex;
  align-items: center;
  text-decoration: underline;
`

export const BlockOnboarding = styled(BlockOnboardingComponent)`
  margin: ${g(2)} 0;
`

export const BlockRateDoesntCount = styled(BlockRateDoesntCountComponent)`
  margin: ${g(2)} 0;
`

export const Icon = styled.svg.attrs(({ picto }) => ({ as: picto }))`
  margin-right: ${g(1)};
`

export const InformationCards = styled.div`
  margin-bottom: ${g(2)};
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  padding-top: ${g(2)};
  padding-bottom: ${g(2)};
`
export const OptimizeButton = styled.div`
  margin: 0 ${g(2)} ${g(4)};
`
export const SubHeading = styled.div`
  margin-bottom: ${g(2)};
`
export const OptimizeChoice = styled.div`
  margin-bottom: ${g(5)};
`
export const OptimizeMessage = styled.div`
  margin-bottom: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`
export const Alert = styled.div`
  margin: ${g(2)} 0;
`

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  margin: ${g(2)};
  text-align: center;
`

export const AlertButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(2)};
`
export const AlertMessage = styled.div`
  margin: 0 ${g(2)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    margin-right: 0;
  }
`
export const RateAlert = styled.div`
  padding: 0 ${g(2)};
  margin-bottom: ${g(2)};
`

export const Timeline = styled(TimelineMobile)`
  margin-bottom: ${g(2)};
`

export const DialogContainer = styled.div`
  padding: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 35vh;
  }
`

export const DialogClose = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DialogFooter = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.neutral4};
  height: ${g(11)};
  width: 100%;
`

export const ButtonLogin = styled(ButtonFeature)`
  margin: ${g(4)} ${g(2.5)};
`

export const Error = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(3)};
  }
`

export const ErrorTitle = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(5)};
    margin-bottom: ${g(3)};
  }
`

export const ErrorDescription = styled.div`
  padding: 0 ${g(2)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(2)} ${g(5)};
  }
`

export const ErrorEmoji = styled.div`
  margin-right: ${g(1)};
`

export const PlaceholdingAmount = styled.div`
  align-items: center;
  display: flex;
  height: ${g(5)};
  justify-content: center;
`

export const TitleDate = styled.div`
  ${heading32};
  margin-bottom: ${g(2)};
  display: flex;
  flex-direction: column;
  gap: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(3)};
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const StyledStatus = styled(Status)`
  max-width: max-content;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-start;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    align-self: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    align-self: flex-start;
  }
`

export const Trustpilot = styled(FooterInfo)`
  margin-top: ${g(3)};
`
