import Responsive from '@pretto/bricks/components/utility/Responsive'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import * as S from './MandateSuccessBooking.styles'

export interface MandateSuccessBookingProps {
  onSkip: () => void
  bookingWidgetProps: object
}

export const MandateSuccessBooking = ({ onSkip, bookingWidgetProps }: MandateSuccessBookingProps) => {
  const isWidgetDurationHidden = useBreakpointToValue(true, { laptop: false })
  const maxRows = useBreakpointToValue(2, { tablet: 30 })
  const calendarHeaderVariant = useBreakpointToValue('white', { tablet: 'neutral4' })

  const SkipButton = () => (
    <S.ButtonSkip onClick={onSkip} href="/">
      Planifier plus tard
    </S.ButtonSkip>
  )

  return (
    <S.Wrapper>
      <S.MainSection>
        <S.MainContainer>
          <S.MainIllustration />
          <S.MainContent>
            <S.Title>
              Félicitations !
              <S.IllustrationHeart />
            </S.Title>

            <S.Paragraph>
              Votre expert peut commencer les démarches auprès des banques pour vous trouver{' '}
              <strong>la meilleure offre</strong>.
            </S.Paragraph>

            <S.Paragraph>
              Planifiez dès à présent votre <strong>prochain RDV</strong> ! Nous vous recommandons de faire un point
              avec votre expert <strong>dans une semaine</strong> !
            </S.Paragraph>

            <Responsive min="laptop">
              <SkipButton />
            </Responsive>
          </S.MainContent>
        </S.MainContainer>
      </S.MainSection>

      <S.MainBackgroundFill />

      <S.BookingSection>
        <S.BookingContainer>
          <S.BookingTitle>Planifiez votre prochain RDV !</S.BookingTitle>

          <S.BookingWidget
            {...bookingWidgetProps}
            offsetSizes={{ tablet: 4, laptop: 7 }}
            overlayVariant="neutral4"
            headerVariant={calendarHeaderVariant}
            maxRows={maxRows}
            hideDuration={isWidgetDurationHidden}
          />

          <Responsive max="laptop">
            <SkipButton />
          </Responsive>
        </S.BookingContainer>
      </S.BookingSection>
    </S.Wrapper>
  )
}
