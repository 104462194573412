import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getDocumentsProgress } from '@pretto/app-core/application/lib/getDocumentsProgress'
import { getNavigationItems } from '@pretto/app-core/application/lib/getNavigationItems'
import { getProgress } from '@pretto/app-core/application/lib/getProgress'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { FolderOpen } from '@pretto/picto'

import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'
import { useLocation } from 'react-router'

import { useUser } from '../../../User/Containers/UserProvider'
import * as forms from '../../config'

import { useApplicationQuery } from './application.gateway.graphql'

const shouldRedirectToIntroduction = (data, userContext) => {
  const mortgagors = data.project.profile.mortgagors
  const isMortgagoesBankIncidentFilled = mortgagors.some(mortgagor => mortgagor.banking_incident === null)

  return isMortgagoesBankIncidentFilled || (userContext.hasComortgagor && !userContext.comortgagorDeclared)
}

const ApplicationContext = createContext()

const ApplicationProvider = ({ children }) => {
  const location = useLocation()

  const { data, loading } = useApplicationQuery()

  const userContext = useUser()

  useLoading(loading)

  if (loading) {
    return null
  }

  const progress = getProgress(forms, data, userContext)
  const documentsProgress = getDocumentsProgress(data.docs)

  const value = {
    completionPercentage: Math.floor((progress.current / progress.target) * 100),
    data,
    documentsProgress,
    introductionUrl: '/application/introduction',
    isBlocked: shouldRedirectToIntroduction(data, userContext),
    navigationItems: [
      ...getNavigationItems(forms, location.pathname, data, userContext),
      {
        isActive: location.pathname === '/application/documents',
        isComplete: documentsProgress.current === documentsProgress.target,
        isErrored: data.docs.some(({ document_status }) => convertStatusSlug(document_status) === 'invalid'),
        label: 'Documents',
        picto: FolderOpen,
        url: '/application/documents',
      },
    ],
  }

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}

ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ApplicationProvider

export const useApplication = () => useContext(ApplicationContext)
