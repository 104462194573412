import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Avatar } from '@pretto/zen/atoms/avatars/Avatar/Avatar'
import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { CheckBold } from '@pretto/picto'

import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${g(2)};
  margin-bottom: ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
`

export const Picture = styled(Avatar)`
  margin-right: ${g(2)};
`

export const Description = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.laptop}) {
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)};
  }
`

export const CheckConfirm = styled(CheckBold)`
  color: ${({ theme }) => theme.colors.primary1};
  background-color: ${({ theme }) => theme.colors.primary4};
  border-radius: 100%;
  width: ${g(3)};
  height:${g(3)};
  padding: 6px;
`

export const Confirm = styled(Button).attrs({ isArrowShown: true })`
  width: 100%;

  @media only screen and (min-width: ${breakpoints.laptop}) {
    width: auto;
    margin-left: auto;
    margin-right: ${g(3)};
    margin-bottom: ${g(4)};
  }
`