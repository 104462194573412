import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/v2/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/v2/lib/placeholders'
import { Months, OccupationContractType, OccupationPublicStatus } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

interface PublicSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicSentence: React.FC<PublicSentenceProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors, setContext } = context
  const mainMortgagor = mortgagors?.[0] ?? {}
  const [debouncedStartYear] = useDebounce(mainMortgagor.startYear, 1000)
  const clonedMortgagors = structuredClone(mortgagors)

  if (!clonedMortgagors || !clonedMortgagors.length) {
    return null
  }

  useEffect(() => {
    if (!Object.values(OccupationPublicStatus).includes(mainMortgagor.status as OccupationPublicStatus)) {
      clonedMortgagors[0].status = undefined
    }
    clonedMortgagors[0].trialPeriod = undefined
    clonedMortgagors[0].executiveRole = undefined
    setContext({ mortgagors: clonedMortgagors })
  }, [])

  const handleYearChange = (value: string) => {
    const year = parseInt(value)
    const date = mainMortgagor.startDate || new Date()
    date.setFullYear(year)
    clonedMortgagors[0].startDate = date
    clonedMortgagors[0].startYear = value
    setContext({ mortgagors: clonedMortgagors })
  }

  return (
    <>
      {t('sentences.situation.public.sentence')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!mainMortgagor.status}
      >
        {t(
          `sentences.situation.public.values.${
            (mainMortgagor.status as OccupationPublicStatus) || OccupationPublicStatus.Tenure
          }`
        )}
      </Prompt>
      {(activeField > 1 || mainMortgagor.startDate) && mainMortgagor.status === OccupationPublicStatus.Contractor && (
        <>
          {t('sentences.situation.in')}
          <Prompt
            onClick={() => {
              onSetActive(2)
            }}
            isFilled={!!mainMortgagor.contractType}
          >
            {t(
              `sentences.situation.contractType.values.${mainMortgagor.contractType || OccupationContractType.LongTerm}`
            )}
          </Prompt>
        </>
      )}
      {(activeField > 2 || mainMortgagor.startDate) &&
        mainMortgagor.status === OccupationPublicStatus.Contractor &&
        mainMortgagor.contractType === OccupationContractType.ShortTerm && (
          <>
            {t('sentences.situation.since')}
            <Prompt isFilled={!!mainMortgagor.startMonth}>
              {t(`sentences.months.${(mainMortgagor.startMonth as Months) || '0'}`)}
            </Prompt>{' '}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'occupationSectorPublicSince' },
              }}
              inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(context), type: 'date' }}
              message={t('sentences.situation.error')}
              onChange={handleYearChange}
              state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
              value={mainMortgagor.startYear || ''}
            />
          </>
        )}
    </>
  )
}
