import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Section = styled.div`
  & + & {
    margin-top: ${g(4)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: ${g(5)};
    }
  }
`

export const Title = styled.div`
  margin-bottom: ${g(3)};
`
