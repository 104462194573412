import { getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import { IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'
import { deserializeToValue, serializeInput, serializeToValue } from '@pretto/app/src/lib/prettoSearch/serialization'

export type GenericObject = Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
type Payload = { query: string; variables: GenericObject }

interface Response {
  data: GenericObject
}

// Exported methods

export const fetchAlerts = async (): Promise<Array<IPropertySearchData>> => {
  const response: Response = await perform(userQuery, {})

  const user = response.data.user

  return user.alerts.map((alert: GenericObject): IPropertySearchData => {
    const { id, activityStatus, title, livingArea, locations, numberOfRooms, plotArea, price, wantedFeatures } = alert

    return {
      activity_status: activityStatus,
      area_max: livingArea?.max,
      area_min: livingArea?.min,
      balcony: wantedFeatures.includes('BALCONY'),
      basement: wantedFeatures.includes('BASEMENT'),
      bedrooms: 0,
      elevator: wantedFeatures.includes('ELEVATOR'),
      email: user.email,
      frequency: deserializeToValue(user, alert, 'frequency'),
      has_furniture: wantedFeatures.includes('HAS_FURNITURE'),
      id,
      localisations: locations,
      min_land_surface: plotArea?.min,
      not_on_ground_floor: wantedFeatures.includes('NOT_ON_GROUND_FLOOR'),
      parking_space: wantedFeatures.includes('PARKING_SPACE'),
      price_max: price?.max,
      price_min: price?.min,
      property_condition: deserializeToValue(user, alert, 'property_condition'),
      property_kind: deserializeToValue(user, alert, 'property_kind'),
      rooms: numberOfRooms,
      title,
      vendor: deserializeToValue(user, alert, 'vendor'),
    }
  })
}

export const createAlert = async (input: IPropertySearchData, authToken?: string): Promise<IPropertySearchData> => {
  const alertInput = serializeInput(input)

  const { query, variables } = createAlertMutation(alertInput)

  const response = await perform(query, variables, authToken)

  return response.data.createAlert
}

export const updateAlert = async (id: string, input: IPropertySearchData): Promise<IPropertySearchData> => {
  const alertInput = serializeInput(input)

  const { query, variables } = updateAlertMutation(id, alertInput)

  const response = await perform(query, variables)

  return response.data.updateAlert
}

export const deleteAlert = (id: string): Promise<Response> => {
  const { query, variables } = deleteAlertMutation(id)

  return perform(query, variables)
}

export const reactivateAlert = (id: string): Promise<Response> => {
  const { query, variables } = reactivateAlertMutation(id)

  return perform(query, variables)
}

export const updateEmailFrequency = (frequency: string, authToken?: string): Promise<Response> => {
  const { query, variables } = updateEmailFrequencyMutation(serializeToValue('emailSummaryFrequency', frequency))

  return perform(query, variables, authToken)
}

//

const perform = async (query: string, variables: GenericObject, authToken?: string): Promise<Response> => {
  const response = await fetch(process.env.API_PRETTOSEARCH_GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers: {
      Authorization: `Bearer ${authToken || getAuthToken()}`,
      'Content-Type': 'application/json',
      platformos: 'desktop',
    },
  })

  if (!response.ok) throw new Error(String(response.status))

  const parsedResponse = await response.json()
  if (parsedResponse.errors) throw new Error(parsedResponse.errors.map((e: { message: string }) => e.message))

  return parsedResponse
}

// Queries

const userQuery = `
  query {
    user {
      preferences {
        emailSummaryFrequency
      }
      alerts {
        id
        title
        activityStatus
        price {
          min
          max
        }
        livingArea {
          min
          max
        }
        plotArea {
          min
        }
        locations {
          city
          zipcode
        }
        numberOfRooms
        kinds
        sellers
        conditions
        wantedFeatures
      }
    }
  }`

// Mutations

const createAlertMutation = (input: GenericObject): Payload => ({
  query: `
    mutation($input: AlertInput!) {
      createAlert(input: $input) {
        id
      }
    }
  `,
  variables: {
    input,
  },
})

const updateAlertMutation = (id: string, input: GenericObject): Payload => ({
  query: `
    mutation($alertId: ID!, $input: AlertInput!) {
      updateAlert(alertId: $alertId, input: $input) {
        id
      }
    }
  `,
  variables: {
    alertId: id,
    input,
  },
})

const updateEmailFrequencyMutation = (frequency: string): Payload => ({
  query: `
    mutation($frequency: EmailSummaryFrequency!) {
      updateEmailFrequency(emailSummaryFrequency: $frequency)
    }
  `,
  variables: {
    frequency,
  },
})

const reactivateAlertMutation = (id: string): Payload => ({
  query: `
    mutation($alertId: ID!) {
      setAlertActivityStatus(alertId: $alertId, activityStatus: ACTIVE) {
        id
      }
    }
  `,
  variables: {
    alertId: id,
  },
})

const deleteAlertMutation = (id: string): Payload => ({
  query: `
    mutation($alertId: ID!) {
      deleteAlert(alertId: $alertId)
    }
  `,
  variables: {
    alertId: id,
  },
})
