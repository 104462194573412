// extracted by mini-css-extract-plugin
export var bubbleChat = "j2o3x7dgQ4XmhrZFZ56Q";
export var bubbleChatAvatar = "JdKFV54XVzQLwv6Wnrww";
export var bubbleChatAvatarContent = "scaqmqvlsa1OkbYZiPvK";
export var bubbleChatAvatarContentAccent1 = "FHtw0UW0GGR31eL0u0lM";
export var bubbleChatAvatarContentAccent1D = "vtTNZVnbSiOrBFSPNSEl";
export var bubbleChatAvatarContentAccent1L = "q7WIuW8fD6gbyXjnUUDV";
export var bubbleChatAvatarContentAccent2 = "jFqUY6_tDgzBFygxkM7l";
export var bubbleChatAvatarContentAccent2D = "yn4DPCojsKdTeQdRcYJc";
export var bubbleChatAvatarContentAccent2L = "GurNKvAsueA2mL260d9f";
export var bubbleChatAvatarContentAccent3 = "vZ6MK3UflfTFA1KITq7g";
export var bubbleChatAvatarContentAccent3D = "s0hWgu4Gl3_FGexPfYTd";
export var bubbleChatAvatarContentAccent3L = "NaYob6vnfnB1QFKIsWEA";
export var bubbleChatAvatarContentAccent4 = "Aul3D4kGPF0F8P9rEMFI";
export var bubbleChatAvatarContentAccent420 = "ywc0oVY2JFtYDWnJtRY5";
export var bubbleChatAvatarContentAccent430 = "jaKKMruHAQZDiozbwtGQ";
export var bubbleChatAvatarContentAccent440 = "QFW0rwnbUIZ5cV9t4wUc";
export var bubbleChatAvatarContentAccent460 = "_k3dDlJnVu39kW5I2DeB";
export var bubbleChatAvatarContentAccent480 = "H5aC3oX3C2qnD5w97gQh";
export var bubbleChatAvatarContentAccent4D = "yPGYdKWHh1SwX3ofQw6I";
export var bubbleChatAvatarContentAccent4L = "wAkLoyVD0k2Bk47MtSTg";
export var bubbleChatAvatarContentAccent5 = "RTdg8E6npLU6bZjnd333";
export var bubbleChatAvatarContentAccent5D = "GDXJlQnrTPNEhEbUhATq";
export var bubbleChatAvatarContentAccent5L = "SpPBqfum14bZvsKHsbcM";
export var bubbleChatAvatarContentError = "ReJZKVr6cYN6Hc4Mvb4k";
export var bubbleChatAvatarContentErrorD = "wdyEDQa4lLehJaJqGgKJ";
export var bubbleChatAvatarContentErrorL = "dSOcDGEkiXzL8c9XWhUJ";
export var bubbleChatAvatarContentFacebook = "_1bVz6LbPWmymB6fjjFw";
export var bubbleChatAvatarContentInherit = "TExNsRSzIAl_m7epMuzz";
export var bubbleChatAvatarContentNeutral1 = "brGC6KbMGYnOG2o3rmhO";
export var bubbleChatAvatarContentNeutral120 = "Q8QuSwSTWb7ZtHHJDMp7";
export var bubbleChatAvatarContentNeutral130 = "eXojajJErp7KkYe9R4q1";
export var bubbleChatAvatarContentNeutral140 = "QuIiZstSqVKkUD4F7IhH";
export var bubbleChatAvatarContentNeutral160 = "Lu1BwYTM6qZwkRW7UYuS";
export var bubbleChatAvatarContentNeutral180 = "vvbGkpKL8se6I70No2or";
export var bubbleChatAvatarContentNeutral1D = "NPvJ9HMm1H0CQ2li9pQM";
export var bubbleChatAvatarContentNeutral1L = "H6mr9zdgoK1YL4fbn4Fl";
export var bubbleChatAvatarContentPrimary1 = "N3E8Vbc9cL2Mgf5QIATj";
export var bubbleChatAvatarContentPrimary120 = "kGUhJ6OpPxk3kxAPHHKv";
export var bubbleChatAvatarContentPrimary130 = "r2ZxCIB8px8GQyLsWUn6";
export var bubbleChatAvatarContentPrimary140 = "mpaT5CmexgIShQ0qDsv8";
export var bubbleChatAvatarContentPrimary160 = "n7wqsIFoHtn7eDYjqG9E";
export var bubbleChatAvatarContentPrimary180 = "oAk2zmPfDTfaYVRo8LoL";
export var bubbleChatAvatarContentPrimary1D = "NBtvEk2Cfk2zsgZB0Yef";
export var bubbleChatAvatarContentPrimary1L = "DJz9nWF88plL52Gks2in";
export var bubbleChatAvatarContentPrimary2 = "iJZU1GEcww6bHK30FD0H";
export var bubbleChatAvatarContentPrimary2D = "pR5fotgG80KgHedZzb47";
export var bubbleChatAvatarContentPrimary2L = "EW9644O2LkRiYslld4AL";
export var bubbleChatAvatarContentSuccess = "vpJEWsjMD3Mk3MKRw8Lh";
export var bubbleChatAvatarContentSuccessD = "oqWra4rV1GVMajUuB9uz";
export var bubbleChatAvatarContentSuccessL = "JrgrIMD97RuJgD2iUO28";
export var bubbleChatAvatarContentTwitter = "PWx1Eo7WMcSNHHq86Com";
export var bubbleChatAvatarContentWarning = "loNs5_w3t1FYzS4LQvYi";
export var bubbleChatAvatarContentWarningD = "_qX1_Jp8mlEl_j601abc";
export var bubbleChatAvatarContentWarningL = "zSSARqyqJJ7qqEzsgl9B";
export var bubbleChatAvatarContentWhatsapp = "L5hAOuDXaHP9vCIt5Rwm";
export var bubbleChatAvatarContentWhite = "mrr9pLbFZcUZbE_xtfH_";
export var bubbleChatAvatarHidden = "XbQjKgsmQgg6Y_60tAkA";
export var bubbleChatAvatarString = "ugCGdxTfL6BknmND910z";
export var bubbleChatContainer = "bzQ99uJs4I081z5R8dku";
export var bubbleChatSplitted = "m5EduMn7VmkGKjyGey7N";