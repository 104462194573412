import PropTypes from 'prop-types'

import * as S from './styles'

const Placeholder = ({ color, ...props }) => <S.Placeholder $color={color} {...props} />

Placeholder.propTypes = {
  /** Placeholder color. */
  color: PropTypes.string,
}

Placeholder.defaultProps = {
  color: 'neutral4',
}

export default Placeholder
