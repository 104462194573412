import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Badge from '@pretto/bricks/components/information/Badge'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import { Desktop } from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'
import { memo } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const SimulationBanner = ({ actions, isVisible }) => (
  <Transition in={isVisible} timeout={500} unmountOnExit>
    {state => {
      return (
        <S.Banner animatingState={state}>
          <Card variant="accent-1">
            <Wrapper>
              <S.Wrapper>
                <S.Content>
                  <Desktop>
                    <S.ContentBadge>
                      <Badge format="line" icon="exclamation-mark" size="small" variant="accent-1" />
                    </S.ContentBadge>
                  </Desktop>
                  Enregistrer votre projet ?
                </S.Content>

                <S.Actions>
                  {actions.map(({ label, onClick }) => (
                    <S.Action key={label}>
                      <ButtonLegacy format="squared" onClick={onClick} variant="white">
                        {label}
                      </ButtonLegacy>
                    </S.Action>
                  ))}
                </S.Actions>
              </S.Wrapper>
            </Wrapper>
          </Card>
        </S.Banner>
      )
    }}
  </Transition>
)

SimulationBanner.propTypes = {
  /** List of actions to be displayed on the right side.*/
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Whether or not the banner should be visible. */
  isVisible: PropTypes.bool,
}

export default memo(SimulationBanner)
