import { States } from '@pretto/zen/reveal/atoms/message/Message'

import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { ProjectType, SimulationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'
import abTest from '@pretto/app/src/lib/abtest'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

interface ContributionProps {
  tooltip: {
    state: States
    html: string
  } | null
  nextText: string
}

type ContributionKeys =
  | 'nonResident'
  | 'nonResidentCapacity'
  | 'capacity'
  | 'construction'
  | 'landConstruction'
  | 'purchase'

export const calculateIncomesFromMortgagors = (mortgagors: Mortgagor[]): number => {
  return mortgagors.reduce((prev, { income }) => {
    let total = 0
    if (income && income.amount) {
      let formattedIncome = income.period === 'year' ? income.amount / 12 : income.amount
      if (income.type === 'gross') {
        formattedIncome = formattedIncome * 0.8
      }
      total += formattedIncome
    }

    return prev + total
  }, 0)
}

const getI18nContributionKey = (context: ProjectContext, minContribution: number): ContributionKeys => {
  switch (true) {
    case context.mortgagors?.[0].country !== 'fr' && minContribution > 0:
      return 'nonResident'
    case context.mortgagors?.[0].country !== 'fr' && minContribution === 0:
      return 'nonResidentCapacity'
    case context.simulationType === SimulationType.Capacity:
      return 'capacity'
    case context.projectType === ProjectType.Construction:
      return 'construction'
    case context.projectType === ProjectType.LandConstruction:
      return 'landConstruction'
    default:
      return 'purchase'
  }
}

export const getContributionProps = (
  context: ProjectContext,
  simulationType?: SimulationType | null,
  contribution?: number | null,
  minContribution?: number,
  mortgagors?: Mortgagor[]
): ContributionProps => {
  if (simulationType === SimulationType.Buyout) {
    return {
      tooltip: null,
      nextText: t('sentences.next'),
    }
  }

  const i18nContributionKey = getI18nContributionKey(context, minContribution || 0)

  if (abTest('contribution_wording_202310') === 'simple') {
    return {
      tooltip: {
        state: States.Info,
        html: t(`sentences.tips.contribution.${i18nContributionKey}`, {
          minContribution: minContribution?.toString() || '',
        }),
      },
      nextText: t('sentences.next'),
    }
  }

  if (contribution === 0) {
    return {
      tooltip: {
        state: States.Message,
        html: `${t('sentences.tips.contribution.withoutContribution.0')} ${t(
          `sentences.tips.contribution.${i18nContributionKey}`,
          {
            minContribution: minContribution?.toString() || '',
          }
        )} ${t('sentences.tips.contribution.withoutContribution.1')}`,
      },
      nextText: t('sentences.nextWithoutContribution'),
    }
  }

  if (
    !isNil(contribution) &&
    ((!isNil(minContribution) && contribution < minContribution) ||
      (simulationType === SimulationType.Capacity &&
        contribution < calculateIncomesFromMortgagors(mortgagors || []) * 4))
  ) {
    return {
      tooltip: {
        state: States.Message,
        html: `${t('sentences.tips.contribution.smallContribution')} ${t(
          `sentences.tips.contribution.${i18nContributionKey}`,
          {
            minContribution: minContribution?.toString() || '',
          }
        )}`,
      },
      nextText: t('sentences.nextWithSmallContribution'),
    }
  }

  return {
    tooltip: {
      state: States.Info,
      html: t(`sentences.tips.contribution.${i18nContributionKey}`, {
        minContribution: minContribution?.toString() || '',
      }),
    },
    nextText: t('sentences.next'),
  }
}
