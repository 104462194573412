import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { TrackingProps } from '@pretto/app/src/Sentences/v2/lib/tracking/types/event'
import { useTracking } from '@pretto/app/src/Sentences/v2/lib/tracking/useTracking'

import { useDebounce } from 'use-debounce'

interface ElementProps extends React.HTMLAttributes<HTMLElement> {}

export const withTrackingElement = <P extends ElementProps>(WrappedComponent: React.FC<P>) => {
  const WithTracking: React.FC<P & TrackingProps> = ({ defaultEventTrackingType, events, onClick, ...props }) => {
    const trackActions = useTracking(defaultEventTrackingType)

    const handleClick: React.MouseEventHandler<HTMLElement> = event => {
      trackActions(events)
      onClick?.(event)
    }

    return <WrappedComponent {...(props as P)} onClick={handleClick} />
  }

  return WithTracking
}

interface InputElementProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  onChange: (value: string) => void
  value: string
}

export const withTrackingInputElement = <P extends InputElementProps>(WrappedComponent: React.FC<P>) => {
  const WithTracking: React.FC<P & TrackingProps> = ({ defaultEventTrackingType, events, value, ...props }) => {
    const [debouncedValue] = useDebounce(value, 500)

    const trackActions = useTracking(defaultEventTrackingType)

    useUpdateEffect(() => {
      trackActions(events)
    }, [debouncedValue])

    return <WrappedComponent {...(props as P)} value={value} />
  }

  return WithTracking
}
