import type { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'

import { BlocSuggestionsLayout } from '../views/ResultPage/components/BlocSuggestionsLayout/BlocSuggestionsLayout'

import { useBlocSuggestionsLoggedInProps } from './lib/useBlocSuggestionsLoggedInProps'

export const BlocSuggestionsLoggedIn: React.FC<ResultPageProps> = props => {
  const blocSuggestionsLoggedInProps = useBlocSuggestionsLoggedInProps(props)

  return <BlocSuggestionsLayout {...blocSuggestionsLoggedInProps} />
}
