import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import PreviousNextNavBar from '@pretto/bricks/components/bars/PreviousNextNavBar'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import Front from '@pretto/app/src/Helpers/frontchat'
import Header from '@pretto/app/src/SharedContainers/Header'
import { t } from '@pretto/app/src/lib/i18n'

import PropTypes from 'prop-types'
import { useEffect } from 'react'

import * as S from './ReduceAmbition.styles'
import { Main } from './components/Main/Main'
import { Sidebar } from './components/Sidebar/Sidebar'

const handleHelpClick = () => {
  window.FrontChat('show')
}

export const ReduceAmbitionPage = ({
  headerProps,
  infos,
  mainProps,
  navigationItemsObject,
  onModify,
  previousNextNavBarProps,
}) => {
  const { breakpoint } = useBreakpoint()

  useEffect(() => {
    const isDefaultLauncher = breakpoint === 'laptop' || breakpoint === 'desktop'
    Front('init', {}, isDefaultLauncher)
  }, [breakpoint])

  return (
    <>
      <Responsive max="laptop">
        <Header {...headerProps} navigationItemsList={navigationItemsObject.tablet} />
      </Responsive>
      <Responsive min="laptop">
        <Header {...headerProps} navigationItemsList={navigationItemsObject.desktop} />
      </Responsive>
      <LayoutStickyFooter>
        <S.Container>
          <Responsive min="laptop">
            <Sidebar onModify={onModify} infos={infos} />
          </Responsive>
          <Main {...mainProps} onHelpClick={handleHelpClick} />
        </S.Container>
        <Responsive max="laptop">
          <PreviousNextNavBar nextLabel={t('reduceAmbitionPage.resultsButton')} {...previousNextNavBarProps} />
        </Responsive>
      </LayoutStickyFooter>
    </>
  )
}

ReduceAmbitionPage.propTypes = {
  contactProps: PropTypes.object,
  headerProps: PropTypes.object,
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  mainProps: PropTypes.object,
  navigationItemsObject: PropTypes.object,
  onModify: PropTypes.func.isRequired,
  previousNextNavBarProps: PropTypes.object,
}
