import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Description = styled.div`
  margin-bottom: ${g(2)};
`

export const Emoji = styled.div`
  margin-right: ${g(2)};
`

export const Header = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
`
