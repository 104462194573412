import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'

export const LandConstructionPage: React.FC = () => {
  const context = useSentences()
  const { constructionPrice, landPrice, setContext } = context
  const { nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && landPrice && constructionPrice))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'landConstruction' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.landConstruction.sentences.0')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertyLandPrice' } }}
          inputProps={{ placeholder: '200 000', type: 'integer' }}
          onChange={(value: string) => {
            setContext({ landPrice: parseInt(value) })
            setActiveField(1)
          }}
          onClick={() => {
            setActiveField(0)
          }}
          value={landPrice?.toString() || ''}
        />
        {t('sentences.property.landConstruction.sentences.2')}
        {activeField > 0 && (
          <>
            {t('sentences.property.landConstruction.sentences.1')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'propertyConstructionPrice' },
              }}
              inputProps={{ placeholder: '200 000', type: 'integer' }}
              onChange={(value: string) => {
                setContext({ constructionPrice: parseInt(value) })
              }}
              value={constructionPrice?.toString() || ''}
            />
          </>
        )}
        .
      </div>
      {Boolean(constructionPrice) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
