import { g } from '@pretto/bricks/components/layout'

import { ProgressBar as ProgressBarComponent } from '@pretto/zen/reveal/atoms/progressBars/progressBar/ProgressBar'

import { ArrowLeftBold, House, Pen } from '@pretto/picto'

import styled from 'styled-components'

export interface NavbarProps {
  isLoggedIn: boolean
  onBack: () => void
  onEdit: () => void
}

export const Navbar: React.FC<NavbarProps> = ({ isLoggedIn, onBack, onEdit, ...props }) => (
  <Component {...props}>
    <ButtonBack aria-label="Retour" onClick={onBack} type="button">
      {isLoggedIn ? <House /> : <ArrowLeftBold />}
    </ButtonBack>

    <ProgressBar progress={90} />

    <ButtonHelp aria-label="Modifier ma simulation" onClick={onEdit} type="button">
      <Pen />
    </ButtonHelp>
  </Component>
)

const Button = styled.button`
  align-items: center;
  appearance: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: ${g(4)};
  justify-content: center;
  width: ${g(4)};
  padding: 0;
`

const ButtonBack = styled(Button)``

const ButtonHelp = styled(Button)`
  margin: 0 ${g(-1 / 2)};
`

const Component = styled.nav`
  align-items: center;
  display: flex;
  gap: ${g(3)};
  padding: ${g(1)} ${g(2, 4)};
`

const ProgressBar = styled(ProgressBarComponent)`
  flex-grow: 1;
`
