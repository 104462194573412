import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'
import Button from '@pretto/bricks/components/buttons/Button'

import PropTypes from 'prop-types'

import * as S from './styles'

const SectionProjectEmpty = ({ cards, href, onEdit, type }) => {
  const { cta, description, isEditable } = cards

  return (
    <PreLeadProjectCardTemplate href={href} isEditable={isEditable} onEdit={onEdit} type={type}>
      <S.Description>{description}</S.Description>
      <Button href={href} onClick={onEdit}>
        {cta}
      </Button>
    </PreLeadProjectCardTemplate>
  )
}

SectionProjectEmpty.propTypes = {
  cards: PropTypes.object,
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  /** Triggered when card is clicked. */
  onEdit: PropTypes.func.isRequired,
  /** Type of card that defines its content. */
  type: PropTypes.string.isRequired,
}

export default SectionProjectEmpty
