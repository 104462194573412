import { DefaultPage } from '@pretto/app/src/Sentences/v2/Maturity/Default/Containers/DefaultPage'
import { PropertyPricePage } from '@pretto/app/src/Sentences/v2/Maturity/PropertyPrice/Containers/PropertyPricePage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const MaturityController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/maturity/propertyPrice')} component={PropertyPricePage} />
      <Route exact path={graph.matchRoute('/project/maturity')} component={DefaultPage} />
      <Redirect to="/" />
    </Switch>
  )
}
