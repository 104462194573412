import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { Loader as LoaderComponent } from '@pretto/zen/reveal/atoms/loader/Loader'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { ArrowRight } from '@pretto/picto'

import styled, { css } from 'styled-components'

import type { Scheme, Size } from './ButtonSquare'

interface SizeProps {
  $size: Size
}

interface StateProps {
  $isDisabled: boolean
  $isLoading: boolean
}

interface ContainerProps {
  $scheme: Scheme
  href?: string
  tabIndex?: number
}

export const Container = styled.span.attrs<ContainerProps & SizeProps & StateProps>(
  ({ $isDisabled, href, tabIndex }) => ({
    as: !href ? 'button' : Link,
    disabled: !href ? $isDisabled : undefined,
    tabIndex: $isDisabled ? -1 : tabIndex,
  })
)<ContainerProps & SizeProps & StateProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  appearance: none;
  border-radius: ${g(1)};
  border: none;
  display: inline-flex;
  position: relative;
  ${transition({ propertyName: 'background-color' }, { propertyName: 'box-shadow' })};

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: ${g(6)};
          width: ${g(6)};
        `

      case 'big':
      default:
        return css`
          height: ${g(8)};
          width: ${g(8)};
        `
    }
  }}

  ${({ $isDisabled, $isLoading, $scheme, theme }) => {
    switch ($scheme) {
      case 'black':
        return css`
          background-color: ${$isDisabled && !$isLoading ? theme.colors.neutral1Disabled : theme.colors.neutral1};

          ${!$isDisabled &&
          css`
            &&:hover,
            &&:focus {
              background-color: ${theme.colors.neutral1Hover};
            }
          `}
        `
      default:
        return css`
          background-color: ${$isDisabled && !$isLoading ? theme.colors.primary1Disabled : theme.colors.primary1};

          ${!$isDisabled &&
          css`
            &&:hover,
            &&:focus {
              background-color: ${theme.colors.primary2};
            }
          `}
        `
    }
  }}
`

const fadeOut = css<StateProps>`
  ${transition({ delay: '200ms', propertyName: 'opacity' })};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      ${transition({ propertyName: 'opacity' })};
    `}
`

const fadeIn = css<StateProps>`
  opacity: 0;
  ${transition({ propertyName: 'opacity' })};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 1;
      ${transition({ delay: '200ms', propertyName: 'opacity' })};
    `}
`

export const Arrow = styled.svg<SizeProps & StateProps>`
  flex-shrink: 0;
  overflow: visible;
  ${fadeOut};

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: ${g(3)};
          width: ${g(3)};
        `

      case 'big':
      default:
        return css`
          height: ${g(4)};
          width: ${g(4)};
        `
    }
  }}
`

export const ArrowInstance = styled.svg.attrs({ as: 'use' })<StateProps>`
  ${transition(
    { propertyName: 'opacity' },
    { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'transform' }
  )};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        transform: translateX(${g(2)});
      }
    `}
`

export const ArrowInstance1 = styled(ArrowInstance)<StateProps>`
  opacity: 0;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 1;
      }
    `}
`

export const ArrowInstance2 = styled(ArrowInstance)<StateProps>`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      ${Container}:hover &&,
      ${Container}:focus && {
        opacity: 0;
      }
    `};
`

export const ArrowSymbol = styled(ArrowRight)``

export const Loader = styled(LoaderComponent).attrs({ color: 'white' })`
  bottom: 0;
  display: flex;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  ${fadeIn};
`
