import ProgressLegacy from '@pretto/bricks/components/information/ProgressLegacy'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Status } from '@pretto/zen/atoms/infos/Status/Status'

import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

interface MainContainerProps {
  $isActive: boolean
}

interface PictoProps {
  $picto: PictoType
}

interface StyledStatusProps {
  $isCompleted: boolean
}

export const MainContainer = styled(Link).attrs<MainContainerProps>(({ $isActive, href }) => ({
  as: $isActive ? Link : 'div',
  href: $isActive ? href : undefined,
}))<MainContainerProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding-bottom: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
    background-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.neutral4)};
    border: 1px solid ${({ theme, $isActive }) => ($isActive ? theme.colors.neutral3 : theme.colors.neutral4)};
    border-radius: ${g(1)};
    min-height: ${g(27)};
    width: ${g(36)};
    justify-content: space-between;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderBodyContainer = styled.div`
  width: 100%;
`

export const SwitchToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  color: ${({ theme }) => theme.colors.neutral1};
  min-height: ${g(3)};
  min-width: ${g(3)};
`

export const Title = styled.h4`
  ${typo.bodyBold16};
  margin-left: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.headline16};
    margin-top: ${g(1)};
    margin-left: ${g(0)};
    overflow-wrap: break-word;
  }
`

export const Description = styled.p`
  ${typo.caption12};
  margin-top: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.bodyBook16};
    margin-top: ${g(1)};
    margin-left: ${g(0)};
  }
`

export const Footer = styled.div`
  margin-top: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(1)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    gap: ${g(3)};
  }
`

export const StyledStatus = styled(Status)<StyledStatusProps>`
  display: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    color: ${({ theme, $isCompleted }) => ($isCompleted ? theme.colors.primary1 : theme.colors.warning1)};
    background-color: ${({ theme, $isCompleted }) => ($isCompleted ? theme.colors.primary4 : theme.colors.warning2)};
  }
`

export const ProgressContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ProgressValue = styled.p`
  ${typo.bodyBook16};
  margin-right: ${g(1)};
`

export const StyledProgressLegacy = styled(ProgressLegacy)`
  height: ${g(1)};
  width: 100%;
`
