import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const ActionCards = styled.div`
  overflow: hidden;
  padding: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    padding: ${g(3)};
  }
`

export const Card = styled.div`
  display: flex;
  flex-grow: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: calc(${(1 / 3) * 100}% - ${g(2)});
  }

  &:not(:last-child) {
    margin-right: ${g(2)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-right: ${g(3)};
    }
  }
`
