import LinkComponent from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const DefaultAlertMessage = () => (
  <>
    Une erreur est survenue. Veuillez réessayer plus tard ou <Link href="mailto:hello@pretto.fr">nous contacter.</Link>
  </>
)

const Link = styled(LinkComponent)`
  text-decoration: underline;
`
