import type { Contribution, Control } from '@pretto/app/src/types/Capacity'
import { KeypressEventHandler } from '@pretto/app/src/types/Events'

import { createContext } from 'react'

interface ControlsContextProps {
  isDialogOpen: boolean
  onOpenDialog: UnknownFunction
  onCloseDialog: UnknownFunction
  controls: Control[]
  contributionRow: Contribution[]
  onKeyPressValidation: KeypressEventHandler
}

const defaultContext: ControlsContextProps = {
  isDialogOpen: false,
  onOpenDialog: () => undefined,
  onCloseDialog: () => undefined,
  controls: [],
  contributionRow: [],
  onKeyPressValidation: () => undefined,
}

export const ControlsContext = createContext<ControlsContextProps>(defaultContext)
