import { getMalusFromEligibilityResults, getMalusFromSimulationResults } from '@pretto/app/src/lib/simulationMalus'

export const getErrors = ({ eligibility, project, results }, locale = 'fr') => {
  const malus = eligibility
    ? getMalusFromEligibilityResults(eligibility, project, locale)
    : getMalusFromSimulationResults(results, project, locale)

  return {
    malus,
    project,
  }
}
