export const DAYS_PER_WEEK = 7
export const EMPTY_FUNCTION = () => {}
export const MAX_AGE = 80
export const MINUTES_PER_HOUR = 60
export const MONTHS_IN_YEAR = 12
export const PERCENT_FACTOR = 100
export const LOAN_AMOUNT_MINIMUM_FACTOR = 0.1

export const HTTP_STATUS_OK = 200
export const HTTP_STATUS_BAD_REQUEST = 400
export const HTTP_STATUS_UNAUTHORIZED = 401
export const HTTP_STATUS_RATE_LIMITED = 429
export const HTTP_DEFAULT_RETRY_DELAY = 500

export const PROPERTY_TYPES = {
  primary: 'primary_residence',
  rental: 'rental_investment',
  secondary: 'secondary_residence',
}
