import LogoTrustpilot from '@pretto/bricks/components/images/LogoTrustpilot'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import LinkComponent from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const TrustpilotLogo = styled(LogoTrustpilot)`
  margin-bottom: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(2)};
    margin-bottom: 0;
  }
`

export const TrustpilotRate = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`
export const TrustpilotStars = styled.div`
  margin-bottom: ${g(1)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(2)};
    margin-bottom: 0;
  }
`

export const Infos = styled(BaseTypo).attrs({ typo: 'bodyBook' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Rate = styled(BaseTypo).attrs({ typo: 'bodyBlack' })`
  display: inline;
`

export const TrustpilotCount = styled(BaseTypo).attrs({
  forwardedAs: LinkComponent,
  isNumber: true,
})`
  margin-left: ${g(1)};
  padding-left: ${g(1)};
  position: relative;
  text-decoration: underline;

  &:before {
    background-color: ${({ theme }) => theme.colors.neutral2};
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 1px;
    top: 0;
  }
`
