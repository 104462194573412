import EmojiComponent from '@pretto/bricks/components/iconography/Emoji'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.neutral1};
  opacity: 0.7;
  pointer-events: none;
  z-index: 999;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)};
  z-index: 9999999999;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    bottom: ${g(6)};
    left: ${g(6)};
    padding: ${g(4)};
    width: ${g(68)};
  }
`
export const Title = styled.h1`
  ${typo.bodyBook16}
  font-size: ${g(2, 2)};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${g(2)};
`
export const Emoji = styled(EmojiComponent).attrs({ size: 'large' })`
  margin-right: ${g(2)};
`
export const Text = styled.p`
  ${typo.bodyBook16}
  font-size: ${g(2, -2)};
  margin-bottom: ${g(2)};
`
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Link = styled.button.attrs({ tabIndex: 0 })`
  ${typo.linkUnderline12}
  margin-right: ${g(2)};
  cursor: pointer;
`
export const Button = styled.button`
  ${typo.button18}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${g(2)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accent1};
  border-radius: ${g(1)};
  cursor: pointer;
`
