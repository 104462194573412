import Link from '@pretto/bricks/website/utility/Link'

import {
  NegotiationModal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const CheckGarantyModal: React.FC<NegotiationModalProps> = ({ onClickLink, ...props }) => (
  <NegotiationModal {...props}>
    <p>
      <strong>Votre dossier passe à la loupe de l’organisme de garantie (caution bancaire) 🛡️</strong>
    </p>

    <p>
      La banque souhaite vous faire une proposition correspondant à votre besoin et à notre demande. C’est une première
      étape très encourageante !
    </p>

    <p>
      À présent, elle doit faire valider votre dossier par un organisme de garantie afin de se protéger en cas de défaut
      de paiement. C’est une sorte d’assurance que prend la banque, et qui diffère de l’assurance emprunteur que vous
      allez prendre pour vous protéger vous-même en cas d’incident.
      <br />
      Le délai de réponse de la garantie varie en fonction de la complexité de votre dossier. Il faudra compter{' '}
      <strong>entre 2 et 7 jours</strong>.
    </p>

    <p>
      En savoir plus sur la garantie de prêt :{' '}
      <Link href="https://www.pretto.fr/pret-immobilier/garantie/" onClick={onClickLink}>
        lire notre article
      </Link>
    </p>
  </NegotiationModal>
)
