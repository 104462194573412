import Responsive from '@pretto/bricks/components/utility/Responsive'

import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useTracking } from '@pretto/app/src/Sentences/v2/lib/tracking/useTracking'
import { MaturityType, SimulationType } from '@pretto/app/src/Sentences/v2/types/enums'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { isFrenchBrowser, t } from '@pretto/app/src/lib/i18n'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import { Bulb, FileCheck, House, HouseMagnifier, MagnifyingGlassLeft, Picto, ShieldCheck } from '@pretto/picto'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { IndexPage as IndexPageView } from '../../views/IndexPage/IndexPage'

const PICTOS: Record<MaturityType, Picto> = {
  [MaturityType.UnderStudy]: Bulb,
  [MaturityType.SearchStarted]: MagnifyingGlassLeft,
  [MaturityType.OfferMade]: HouseMagnifier,
  [MaturityType.OfferAccepted]: ShieldCheck,
  [MaturityType.SaleAgreementSigned]: FileCheck,
}

export const IndexPage: React.FC = () => {
  const [isLanguageSwitchDialogOpen, setIsLanguageSwitchDialogOpen] = useState(false)

  const { i18n } = useTranslation()
  const { search } = useLocation()
  const { goToNextRoute, goToRoute } = useRoutesGraph()
  const { setContext } = useSentences()
  const trackAction = useTracking()
  const trustpilotData = useTrustpilotData()
  const { isEnglishUser } = useUser()
  const history = useHistory()

  useEffect(() => {
    // User is configured as French but browser is not in French
    if (!isFrenchBrowser() && !isEnglishUser) {
      setIsLanguageSwitchDialogOpen(true)
    }
  }, [isEnglishUser])

  const handleClickMaturity = (type: MaturityType) => {
    trackAction({
      eventName: EventName.MaturityDeclared,
      eventPayload: { value: type, choice: 'maturity' },
    })
    setContext({
      maturityType: type,
    })
    if (type !== MaturityType.SearchStarted) {
      setContext({
        simulationType: type === MaturityType.UnderStudy ? SimulationType.Capacity : SimulationType.Purchase,
      })
    }
    goToNextRoute()
  }

  const handleConfirmLanguageSwitch = () => {
    const newLanguageCode = 'en'
    i18n.changeLanguage(newLanguageCode)

    const queryParams = new URLSearchParams(search)

    if (queryParams.has('lang')) {
      queryParams.set('lang', newLanguageCode)
    } else {
      queryParams.append('lang', newLanguageCode)
    }

    history.replace({ search: queryParams.toString() })

    setIsLanguageSwitchDialogOpen(false)

    // 2024-05 We have to do a full reload because we're not
    // using useTranslation() for all t() calls, so the i18n language is not
    // updated properly for some translations. If all calls to t() are
    // refactored to use the useTranslation() hook we can remove this reload.
    window.location.reload()
  }

  const handleCloseLanguageSwitchDialog = () => {
    setIsLanguageSwitchDialogOpen(false)
  }

  const mapMaturityValues = (values: MaturityType[]) =>
    values.map(value => ({
      description: t(`sentences.maturity.index.values.${value}.description`),
      key: value,
      onClick() {
        handleClickMaturity(value)
      },
      picto: PICTOS[value],
      title: t(`sentences.maturity.index.values.${value}.title`),
    }))

  const alternativeChoices = [
    {
      description: t('sentences.maturity.index.others.description'),
      key: 'alternativeChoices',
      onClick() {
        goToRoute('/project/introduction/others')
      },
      picto: House,
      title: t('sentences.maturity.index.others.title'),
    },
  ]

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'maturityIndex' } }}>
      <Responsive max="tablet">
        <Header mode="compact" navigationItemsList={[NAV_ITEMS.faq]} backgroundColor="neutral4" />
      </Responsive>
      <Responsive min="tablet">
        <Header navigationItemsList={[NAV_ITEMS.faq]} backgroundColor="neutral4" />
      </Responsive>
      <IndexPageView
        alternativeChoices={alternativeChoices}
        purchaseChoices={mapMaturityValues(Object.values(MaturityType))}
        trustpilotRateProps={trustpilotData}
        isLanguageSwitchDialogOpen={isLanguageSwitchDialogOpen}
        onConfirmLanguageSwitch={handleConfirmLanguageSwitch}
        onCloseLanguageSwitchDialog={handleCloseLanguageSwitchDialog}
      />
    </TrackedView>
  )
}
