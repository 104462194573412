import { g } from '@pretto/bricks/components/layout'

import { ChoiceProps, Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { Revenue } from '@pretto/app/src/Sentences/v2/types/compensation'
import { t } from '@pretto/app/src/lib/i18n'
import { TimesBold } from '@pretto/picto'

import isNil from 'lodash/isNil'
import styled, { css } from 'styled-components'

const Container = styled.div<{ $isEditable: boolean }>`
  padding: ${g(2)};
  border-radius: ${g(1)};
  ${({ $isEditable, theme }) =>
    $isEditable
      ? css`
          border: 1px solid ${theme.colors.neutral2};
        `
      : css`
          background-color: ${theme.colors.neutral5};
        `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${g(2)};
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.body4Bold}
`

const Cross = styled(TimesBold)`
  cursor: pointer;
`

interface RevenueBoxProps {
  activeField: number
  index: number
  isEditable: boolean
  onChangeAmount: (value: string) => void
  onPromptClick: () => void
  onChoiceClick: () => void
  onClick: () => void
  onRemove: () => void
  revenue: Revenue
  choices: ChoiceProps[]
}

export const RevenueBox: React.FC<RevenueBoxProps> = ({
  activeField,
  onPromptClick,
  onChoiceClick,
  index,
  isEditable,
  onChangeAmount,
  onClick,
  onRemove,
  revenue,
  choices,
  ...props
}) => {
  return (
    <Container $isEditable={isEditable} onClick={onClick} {...props}>
      <Header>
        <Title>
          {t('sentences.income.revenue.subtitle')} {index + 1} :
        </Title>
        <Cross onClick={onRemove} />
      </Header>
      {isEditable ? (
        <>
          <Prompt isFilled={!isNil(revenue.type)} onClick={onPromptClick}>
            {t(`sentences.income.revenue.values.${revenue.type || 'rent'}`)}
          </Prompt>
          {activeField === 1 && (
            <>
              {t('sentences.income.revenue.sentences.0')}
              <TrackedResizableField
                clearable
                events={{
                  eventName: EventName.SimulationFieldChanged,
                  eventPayload: { field: 'coMortgagorIncomeRevenueAmount' },
                }}
                inputProps={{ autoFocus: activeField === 1, placeholder: '500', type: 'integer' }}
                onChange={onChangeAmount}
                value={revenue.amount?.toString() || ''}
              />
              {t('sentences.income.revenue.sentences.1')}
            </>
          )}
        </>
      ) : !revenue.amount || !revenue.type ? null : (
        <>
          {revenue.type && t(`sentences.income.revenue.values.${revenue.type}`)}{' '}
          {t('sentences.income.revenue.sentences.0')} {revenue.amount} {t('sentences.income.revenue.sentences.1')}
        </>
      )}
      {activeField === 0 && isEditable && (
        <Choices
          choices={choices}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorIncomeRevenueBox' },
          }}
          onChoiceClick={onChoiceClick}
        />
      )}
    </Container>
  )
}
