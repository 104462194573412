import { sanitizeDateString } from './sanitizeDateString'

interface AddSeparatorToDateString {
  contextOffset?: number
  format: string
  value: string
}

export const addSeparatorToDateString = ({ contextOffset = 0, format, value }: AddSeparatorToDateString): string => {
  const sanitizedDateString = sanitizeDateString({ format, value })

  let index = 0

  return format
    .replace(/0/g, () => {
      const charIndex = index++

      const offset = charIndex - contextOffset
      const char = sanitizedDateString.substring(offset, offset + 1)

      if (charIndex < contextOffset || char.length === 0) {
        return 'x'
      }

      return char
    })
    .replace(/x+[^\d]*x*/g, '')
}
