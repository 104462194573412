import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { SimulationType } from '@pretto/app/src/Sentences/v2/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/v2/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

const otherSimulationTypes = [SimulationType.Buyout, SimulationType.Renegotiation] as const

export const OthersPage: React.FC = () => {
  const { goToNextRoute, nextRoute } = useRoutesGraph()

  const context = useSentences()

  const { setContext, simulationType } = context

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulationType))

  const mapOthersToValue: MapToChoices<typeof otherSimulationTypes> = values =>
    values.map(value => ({
      key: value,
      isSelected: simulationType === value,
      onClick() {
        setContext({ simulationType: value })
        goToNextRoute()
      },
      label: t(`sentences.introduction.others.values.${value}`),
    }))

  const value = otherSimulationTypes.find(value => value === simulationType)

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'introductionOthers' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.others.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!value}>
          {t(`sentences.introduction.others.values.${value || SimulationType.Renegotiation}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapOthersToValue(otherSimulationTypes, context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyType' } }}
        />
      )}
      {value && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
