import AutoComplete from '@pretto/bricks/components/form/AutoComplete'

import { searchArray } from '@pretto/app-core/lib/searchArray'
import { OTHER_VALUE, sortBanks } from '@pretto/app-core/lib/sortBanks'

import BANKS from '@pretto/config/banks.json'

import groupBy from 'lodash/groupBy'
import PropTypes from 'prop-types'
import { useState } from 'react'

const getAllBankGroupsWithGroup = group => {
  if (!group) {
    return groupBy(BANKS, bank => bank.group || bank.label)
  }

  return groupBy(
    BANKS.filter(bank => bank.group === group),
    'label'
  )
}

export const BanksField = ({ group, onChange, value }) => {
  const [searchValue, setSearchValue] = useState('')

  const handleCheck = value => toggleCheck(value)
  const handleClear = () => setSearchValue('')
  const handleSearch = value => setSearchValue(value)
  const handleSelect = checkbox => checkbox.onChange(checkbox.value)
  const handleUncheck = value => toggleCheck(value)

  const allBankGroups = getAllBankGroupsWithGroup(group)
  const allBankGroupKeys = Object.keys(allBankGroups)
  const allBanks = allBankGroupKeys
    .map(bank => ({
      checked: value.some(value => value === bank),
      label: bank,
      onChange: handleCheck,
      value: bank,
    }))
    .sort(sortBanks(false))

  const allSearchableBanks = allBanks.filter(({ value }) => value !== OTHER_VALUE)

  const checkboxes = allBankGroupKeys
    .filter(bank => value.includes(bank))
    .map(bank => ({
      id: bank,
      inputProps: {
        value: bank,
      },
      isChecked: true,
      label: bank,
      onChange: () => handleUncheck(bank),
    }))
    .sort(sortBanks())

  const searchResults = searchValue === '' ? allBanks : searchArray(searchValue, allSearchableBanks, 'label')

  const toggleCheck = bank => {
    if (!value.includes(bank)) {
      return onChange([...value, bank])
    }

    const group = allBankGroups[bank]
    const values = value.filter(bankValue => {
      if (!group) {
        return bank !== bankValue
      }

      return bank !== bankValue && !group.some(({ label }) => label === bankValue)
    })

    onChange(values)
  }

  return (
    <AutoComplete
      checkboxes={checkboxes}
      format="form"
      icon="search"
      onAfterOpen={handleSearch}
      onClear={handleClear}
      onSearch={handleSearch}
      onSelect={handleSelect}
      results={searchResults}
      searchValue={searchValue}
      type="check"
      value={value}
    />
  )
}

BanksField.propTypes = {
  group: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
}
