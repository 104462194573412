import { getRangeFromPrice } from '@pretto/app/src/Capacity/utils'
import type { CapacityData, Financing } from '@pretto/app/src/types/Capacity'

interface ResultCardProps {
  data: CapacityData
  selectedLoanDuration: number | null
  contribution: number
}
type CardData = Omit<Financing, 'mainLoansRate' | 'monthlyDetailedWeightedIncomes'> & {
  brokerageFees: number
  contribution: number
  notaryFees: number
  propertyPrice: number
  range: string[]
  rate: number
}

export const ResultCard = ({
  data,
  selectedLoanDuration,
  contribution,
}: ResultCardProps): Record<'cardData', CardData>[] | null => {
  if (!data) {
    return null
  }

  const { capacity } = data

  if (data.capacity.length === 0) {
    return null
  }

  const {
    financing: {
      applicationFees,
      bridge,
      brokerageFees,
      guarantyFees,
      interests,
      loanAmount,
      loanDuration,
      mainLoansRate: rate,
      payment,
      ptz,
    },
    project: {
      facts: { notary_fees: notaryFees },
      purchase: { property_price: propertyPrice },
    },
  } =
    capacity.find(({ financing: { loanDuration } }) => loanDuration === selectedLoanDuration) ||
    capacity[capacity.length - 1]

  return [
    {
      cardData: {
        applicationFees,
        bridge,
        brokerageFees,
        contribution,
        guarantyFees,
        interests,
        loanAmount,
        loanDuration,
        notaryFees,
        payment,
        propertyPrice,
        ptz,
        range: getRangeFromPrice(propertyPrice),
        rate,
      },
    },
  ]
}
