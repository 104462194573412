import ActionCards from '@pretto/bricks/shared/components/ActionCards'
import DefaultTemplate from '@pretto/bricks/shared/templates/DefaultTemplate'

import PropTypes from 'prop-types'

import * as C from './Optimize.module.css'

const Optimize = ({ data }) => (
  <DefaultTemplate
    title="Optimisez votre prêt"
    description="Choisissez une de nos trois propositions pour optimiser votre demande."
  >
    <div className={C.optimizeCards}>
      <ActionCards data={data} />
    </div>
  </DefaultTemplate>
)

Optimize.propTypes = {
  data: PropTypes.array,
  onGoBack: PropTypes.func,
}

export default Optimize
