import type { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'

import { v4 as uuidv4 } from 'uuid'

/**
 * @description Create a mortgagor with default values
 * @param {Partial<Mortgagor>} mortgagor
 * @returns {Mortgagor}
 * @example
 * createMortgagor({ age: 30 })
 * // => { age: 30, bonus: null, contractType: null, country: null, income: null, occupationType: null, locationType: null, revenues: [], situationType: null }
 * createMortgagor({ age: 30, bonus: 1000 })
 * // => { age: 30, bonus: 1000, ... }
 * createMortgagor({ age: 30, bonus: 1000, contractType: 'cdi' })
 * // => { age: 30, bonus: 1000, contractType: 'cdi', ... }
 * createMortgagor({ age: 30, bonus: 1000, contractType: 'cdi', country: 'fr' })
 * // => { age: 30, bonus: 1000, contractType: 'cdi', country: 'fr', ... }
 * createMortgagor({ age: 30, bonus: 1000, contractType: 'cdi', country: 'fr', income: 2000 })
 */
export const createMortgagor = (mortgagor?: Partial<Mortgagor>): Mortgagor => ({
  age: null,
  bonus: null,
  contractType: null,
  country: null,
  income: null,
  occupationType: null,
  locationType: null,
  revenues: [],
  situationType: null,
  expatriate: null,
  id: uuidv4(),
  ...mortgagor,
})
