import SimulationDetails from '@pretto/bricks/app/simulator/components/SimulationDetails'
import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 35vh;
  }
`

export const Header = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
`

export const Divider = styled.div`
  text-align: center;
  margin: ${g(2)} auto;
`

export const OptionCTA = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${g(6.5)};
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })`
  flex: 1 1 0;
`

export const Inputs = styled(SimulationDetails)`
  padding: 0;
`

export const Button = styled(ButtonComponent)`
  margin-top: ${g(4)};
  width: 100%;
`

export const Footer = styled.div`
  margin-top: auto;
`
