import { formatNumber } from '@pretto/app/src/Capacity/lib/formatNumber'
import {
  CellHighlight,
  CellType,
  TableRow,
  Unit,
} from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'
import type { Capacity } from '@pretto/app/src/types/Capacity'
import { SuggestionType } from '@pretto/app/src/types/gateway/enums'

import { isNumber } from 'lodash'

type TableConfigRow = Omit<TableRow, 'value'> & {
  value: number | null | undefined
}

export const getCalcTableProps = (data: Capacity, bonus?: number, type: SuggestionType | null = null): TableRow[] => {
  const rows: TableConfigRow[] = [
    {
      get highlight() {
        if (type === null) {
          return CellHighlight.Light
        }

        if (type === SuggestionType.RepayDebts) {
          return CellHighlight.Strong
        }

        return CellHighlight.None
      },
      label: 'Mensualité',
      tooltip:
        'Le montant remboursé à la banque tous les mois (intérêts + montant emprunté), assurance de prêt exclue.',
      type: CellType.Entry,
      unit: Unit.Payment,
      value: (data.financing.loanAmount + data.financing.interests) / data.financing.loanDuration,
    },
    {
      label: 'Nombre de mois',
      tooltip: 'Durée (en mois) pendant laquelle vous remboursez votre crédit.',
      type: CellType.Multiplication,
      unit: Unit.None,
      value: data.financing.loanDuration,
    },
    {
      highlight: type === SuggestionType.BuyWithBroker ? CellHighlight.Strong : CellHighlight.None,
      label: `Intérêts (taux : ${formatNumber(data.financing.mainLoansRate)} %)`,
      tooltip: 'Coût du crédit (% du montant emprunté) hors assurance.',
      type: CellType.Deduction,
      unit: Unit.Price,
      value: data.financing.interests,
    },
    {
      highlight: type === null ? CellHighlight.Light : CellHighlight.None,
      label: 'Montant emprunté',
      type: CellType.Subtotal,
      unit: Unit.Price,
      value: data.financing.loanAmount,
    },
    {
      label: 'Dont prêt à taux zéro',
      type: CellType.Neutral,
      unit: Unit.Price,
      value: data.financing.ptz?.amount,
    },
    {
      label: 'Rachat de CRD',
      tooltip:
        'Il s’agit du montant racheté correspondant au capital restant dû (CRD) de votre prêt immobilier actuel.',
      type: CellType.Deduction,
      unit: Unit.Price,
      get value() {
        const remaining = data.financing.bridge?.remaining

        if (isNumber(remaining) && remaining > 0) {
          return remaining
        }

        return null
      },
    },
    {
      highlight: type === SuggestionType.BuyNew ? CellHighlight.Strong : CellHighlight.None,
      label: 'Frais de notaire',
      tooltip: 'Taxes collectées par le notaire pour le compte de l’État (en fonction du projet).',
      type: CellType.Deduction,
      unit: Unit.Price,
      value: Math.round(data.project.facts.notary_fees),
    },
    {
      label: 'Frais de garantie',
      tooltip:
        'Montant dû à l’organisme de garantie qui assure la banque en cas de défaut de paiement (différent de l’assurance emprunteur qui vous protège vous).',
      type: CellType.Deduction,
      unit: Unit.Price,
      value: data.financing.guarantyFees,
    },
    {
      label: 'Frais de courtage Pretto',
      tooltip: 'Coût de l’accompagnement et du service de Pretto, selon le montant emprunté.',
      type: CellType.Deduction,
      unit: Unit.Price,
      value: Math.round(data.financing.brokerageFees),
    },
    {
      label: 'Frais de dossier',
      tooltip: 'Frais d’étude et d’édition de l’offre par la banque.',
      type: CellType.Deduction,
      unit: Unit.Price,
      value: data.financing.applicationFees,
    },
    {
      label: 'Prêt relais',
      tooltip:
        'Avance de trésorerie utilisée comme apport, remboursée à la vente de votre bien. Ce montant est calculé à partir de la valeur de votre bien actuel et du prêt qu’il vous reste à rembourser.',
      type: CellType.Addition,
      unit: Unit.Price,
      value: data.financing.bridge?.value,
    },
    {
      highlight: type === SuggestionType.BumpContribution ? CellHighlight.Strong : CellHighlight.None,
      label: 'Apport',
      type: CellType.Addition,
      unit: Unit.Price,
      value: data.project.contribution,
    },
    {
      highlight: CellHighlight.Strong,
      label: 'Cofinancement',
      type: CellType.Addition,
      unit: Unit.Price,
      value: type === SuggestionType.CoInvestment ? bonus : null,
    },
    {
      highlight: CellHighlight.Strong,
      label: 'Négociation de 8 %',
      type: CellType.Addition,
      unit: Unit.Price,
      value: type === SuggestionType.Negotiate ? bonus : null,
    },
    {
      highlight: type === null ? CellHighlight.Strong : CellHighlight.Light,
      info: type === SuggestionType.Negotiate ? null : '(travaux inclus)',
      label: type === SuggestionType.Negotiate ? 'Prix du bien à visiter' : 'Prix du bien',
      type: CellType.Total,
      unit: Unit.Price,
      value: data.project.purchase.property_price,
    },
  ]

  return rows.reduce<TableRow[]>((previous, { value, ...row }) => {
    if (typeof value !== 'number') {
      return previous
    }

    return [...previous, { ...row, value }]
  }, [])
}
