import DashboardSecondaryCard from '@pretto/bricks/app/dashboard/components/DashboardSecondaryCard'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Content from '@pretto/bricks/components/layout/Content'

import PropTypes from 'prop-types'

import * as S from './styles'

const DashboardHighlightsCard = ({ actionLabel, children, dashboardHighlightsProps, ...props }) => (
  <DashboardSecondaryCard {...props}>
    <Content>
      <S.DashboardHighlights {...dashboardHighlightsProps} />
      <ButtonInline>{actionLabel}</ButtonInline>
    </Content>

    {children}
  </DashboardSecondaryCard>
)

DashboardHighlightsCard.defaultProps = {
  actionLabel: 'Consulter',
}

DashboardHighlightsCard.propTypes = {
  /** Label of the action button. */
  actionLabel: PropTypes.string,
  /** Optional nodes to inject after the card content. */
  children: PropTypes.node,
  /** Props of <code>[DashboardHighlights](/#!/DashboardHighlights)</code> component. */
  dashboardHighlightsProps: PropTypes.object.isRequired,
}

export default DashboardHighlightsCard
