import { ng } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { useRef } from 'react'

import * as S from './ButtonBack.styles'

export type ButtonBackProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  disabled?: boolean
  href?: string
}

export const ButtonBack: React.FC<ButtonBackProps> = ({ children, disabled = false, onClick, ...props }) => {
  const { current: id } = useRef(random())

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    onClick?.(event)
  }

  const stateProps = {
    $isDisabled: disabled,
  }

  return (
    <S.Container {...stateProps} {...props} onClick={handleClick}>
      <S.Arrow viewBox={`0 0 ${ng(3)} ${ng(3)}`}>
        <defs>
          <S.ArrowSymbol forwardedAs="symbol" id={id} />
        </defs>

        <S.ArrowInstance1 {...stateProps} xlinkHref={`#${id}`} x={ng(2)} y="0" />
        <S.ArrowInstance2 {...stateProps} xlinkHref={`#${id}`} x="0" y="0" />
      </S.Arrow>
      <S.Label>{children}</S.Label>
    </S.Container>
  )
}
