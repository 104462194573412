import { getIsCertificateActive } from '@pretto/app/src/Application/lib/getIsCertificateActive'

export const schema = [
  (data, { hasFeatureAccess }) =>
    getIsCertificateActive(data) &&
    !hasFeatureAccess(['BUYOUT']) && {
      category: 'certificate',
      label: 'Documents pour attestation de financement',
    },
  (data, userContext) => ({
    category: 'general_0',
    label: `Situation de ${userContext.accounts[0].name}`,
    nextCategory: userContext.hasComortgagor ? 'general_1' : 'general',
  }),
  (data, userContext) =>
    userContext.hasComortgagor && {
      category: 'general_1',
      label: `Situation de ${userContext.accounts[1].name}`,
      nextCategory: 'general',
      previousCategory: 'general_0',
    },
  (data, userContext) => ({
    category: 'general',
    label: 'Foyer',
    nextCategory: 'property',
    previousCategory: userContext.hasComortgagor ? 'general_1' : 'general_0',
  }),
  {
    category: 'property',
    label: 'Projet',
    nextCategory: 'banks',
    previousCategory: 'general',
  },
  {
    category: 'banks',
    label: 'Comptes',
    nextCategory: 'estate',
    previousCategory: 'property',
  },
  {
    category: 'estate',
    label: 'Épargne & Patrimoine',
    nextCategory: 'credits',
    previousCategory: 'banks',
  },
  data => ({
    category: 'credits',
    label: `${data.project.project_kind === 'renegotiation' ? 'Autres crédits en cours' : 'Crédits en cours'}`,
    previousCategory: 'estate',
  }),
  {
    category: 'others',
    ignoreProgress: true,
    label: 'Autres documents',
  },
]
