import { ReactElement } from 'react'

import * as S from './Form.styles'

export interface FormProps {
  firstNameFieldComponent: ReactElement
  lastNameFieldComponent: ReactElement
  emailComponent: ReactElement
  phoneFieldComponent: ReactElement
  consentProps: {
    id: string
    isChecked: boolean
    isError: boolean
    label: string
    onChange: () => void
  }
}

export const Form: React.FC<FormProps> = ({
  firstNameFieldComponent,
  lastNameFieldComponent,
  emailComponent,
  phoneFieldComponent,
  consentProps: { label, ...consentCheckboxProps },
}) => {
  return (
    <S.Form>
      <S.Fields>
        <S.TwoColumns>Votre Expert pourra s’adresser à :</S.TwoColumns>
        <S.NameContainer>{firstNameFieldComponent}</S.NameContainer>
        <S.NameContainer>{lastNameFieldComponent}</S.NameContainer>
        <S.TwoColumns>{emailComponent}</S.TwoColumns>
        <S.ContactSentence>Et pourra vous contacter au :</S.ContactSentence>
        <S.TwoColumns>{phoneFieldComponent}</S.TwoColumns>
        <S.TwoColumns>
          <S.Divider />
          <S.CheckBoxContainer>
            <S.AgreementCheckbox {...consentCheckboxProps} />
            <S.Label htmlFor={consentCheckboxProps.id}>{label}</S.Label>
          </S.CheckBoxContainer>
        </S.TwoColumns>
        <S.LegalTerms>
          * Pas d’inquiétude ! Vos données personnelles sont protégées et utilisées seulement pour votre recherche de
          prêt.
        </S.LegalTerms>
      </S.Fields>
    </S.Form>
  )
}
