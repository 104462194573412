import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import Link from '@pretto/bricks/website/utility/Link'

import { t } from '@pretto/app/src/lib/i18n'
import { Envelope, Phone, UserCircle } from '@pretto/picto'

import { forwardRef } from 'react'
import { Trans } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import * as S from './Form.styles'

export interface FormProps {
  consentErrorMessage?: React.ReactNode
  consentErrorMessageKey?: string
  error: string | null
  fields: Record<string, React.ReactElement>
  isConsentChecked: boolean
  isLoading?: boolean
  onCguClick: () => void
  onConsentChange: () => void
  onPrivacyClick: () => void
  onSubmit: () => void
  title?: string
}

const getErrorMessage = (slug: string) => {
  switch (slug) {
    case 'account_already_exists':
    case 'user_already_exists':
      return t('signupForm.accountExistsError')

    default:
      return t('signupForm.error')
  }
}

export const Form: React.FC<React.ComponentPropsWithRef<'form'> & FormProps> = forwardRef(
  (
    {
      consentErrorMessage,
      consentErrorMessageKey = 'key',
      error,
      fields,
      isConsentChecked,
      isLoading = false,
      onCguClick,
      onConsentChange,
      onPrivacyClick,
      onSubmit,
      title = t('signupForm.defaultTitle'),
      ...props
    },
    ref
  ) => {
    const handleCguClick = (event: React.MouseEvent) => {
      event.stopPropagation()

      onCguClick()
    }

    const handlePrivacyClick = (event: React.MouseEvent) => {
      event.stopPropagation()

      onPrivacyClick()
    }

    const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault()

      onSubmit()
    }

    return (
      <S.Container {...props} noValidate onSubmit={handleSubmit} ref={ref}>
        <S.Title>{title}</S.Title>

        <S.Fieldset>
          <S.Legend>{t('signupForm.contactInfo')}</S.Legend>

          <S.FieldsetRow>
            <S.FieldsetRowPicto as={UserCircle} />
            <S.Field>{fields.firstName}</S.Field>
            <S.Field>{fields.lastName}</S.Field>
          </S.FieldsetRow>

          <S.FieldsetRow>
            <S.FieldsetRowPicto as={Envelope} />
            <S.Field>{fields.email}</S.Field>
          </S.FieldsetRow>

          <S.FieldsetRow>
            <S.FieldsetRowPicto as={Phone} />
            <S.Field>{fields.phone}</S.Field>
          </S.FieldsetRow>
        </S.Fieldset>

        <S.Consent>
          <S.Divider />

          <S.ConsentBox>
            <S.ConsentCheck id="consent" isChecked={isConsentChecked} onChange={onConsentChange} />

            <S.ConsentLabelContainer>
              <S.ConsentLabel htmlFor="consent">
                <Trans
                  i18nKey="signupForm.acceptTerms"
                  components={{
                    CguLink: <Link href="https://www.pretto.fr/cgu/" onClick={handleCguClick} />,
                    PrivacyLink: <Link href="/privacy" onClick={handlePrivacyClick} target="blank" />,
                  }}
                />
              </S.ConsentLabel>

              <TransitionGroup>
                {!!consentErrorMessage && (
                  <CollapsibleRow animateIn key={consentErrorMessageKey} childComponent={S.ConsentLabelFooter}>
                    <S.ConsentError>{consentErrorMessage}</S.ConsentError>
                  </CollapsibleRow>
                )}
              </TransitionGroup>
            </S.ConsentLabelContainer>
          </S.ConsentBox>
        </S.Consent>

        <S.Footer>
          {error && (
            <CollapsibleRow animateIn key={error} childComponent={S.FooterRow}>
              <S.ErrorMessage>{getErrorMessage(error)}</S.ErrorMessage>
            </CollapsibleRow>
          )}
        </S.Footer>

        <S.SubmitButton loading={isLoading}>{t('signupForm.bookAppointmentCTA')}</S.SubmitButton>
      </S.Container>
    )
  }
)

Form.displayName = 'Form'
