import BlocSave from '@pretto/bricks/app/simulator/components/BlocSave'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { SaveContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/SaveContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext } from 'react'
import styled, { css } from 'styled-components'

export const BlockSave = () => {
  const trackAction = useTracking()
  const { requestGoogleConnect } = useAuth()
  const { emailFieldComponent } = useContext(SaveContext)

  const { projectID } = useUser()

  const trackLegalLink = (source: string) => () => {
    trackAction('CAPACITY_RESULTS_LEGAL_LINKS_CLICKED', { capacity_results_legal_link: source })
  }

  const handleGoogleConnect = () => {
    requestGoogleConnect({ projectID })
    trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'capacity_result_page' })
  }

  return (
    <SideContainer>
      <BlocSave
        emailFieldComponent={emailFieldComponent}
        onCguClick={trackLegalLink('cgu')}
        onGoogleConnect={handleGoogleConnect}
        onPrivacyClick={trackLegalLink('privacy')}
      />
    </SideContainer>
  )
}

const leftGrid = css`
  ${column([2, 4])};
  margin-right: ${g(3)};
`

const SideContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${leftGrid};
    height: fit-content;
  }
`
