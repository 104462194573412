import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'

const ServiceFAQ = () => {
  const { trackFeature } = useTrackFeatureCard()

  const handleClick = () => {
    trackFeature('faq')
  }

  const props = {
    data: {
      onClick: handleClick,
    },
    slug: 'faq',
  }

  return <ServiceCard {...props} />
}

export default ServiceFAQ
