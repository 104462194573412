import { temporal } from '@pretto/bricks/core/utility/temporal'

const TODAY = temporal()

export interface ExpertVacation {
  startDate?: string
  endDate?: string
}
interface formattedExpertVacationData {
  formattedStartDate: string | null
  formattedEndDate: string | null
}

export const getExpertVacation = ({ startDate, endDate }: ExpertVacation): formattedExpertVacationData => {
  const isOnVacation = !!startDate && !!endDate && TODAY.isBetween(startDate, endDate, 'day', '[]')

  if (!isOnVacation) {
    return { formattedStartDate: null, formattedEndDate: null }
  }

  const formattedStartDate = (startDate && temporal(startDate).format('DD MMM')) || null
  const formattedEndDate = (endDate && temporal(endDate).format('DD MMM')) || null

  return { formattedStartDate, formattedEndDate }
}
