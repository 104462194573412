export const getMortgagorFilter = (mortgagorsSelected: { mortgagor: boolean; comortgagor: boolean }) => {
  const mortgagorChecked = mortgagorsSelected.mortgagor
  const comortgagorChecked = mortgagorsSelected.comortgagor

  if (mortgagorChecked && comortgagorChecked) return 'both'

  if (mortgagorChecked) return 'mortgagor'

  if (comortgagorChecked) return 'comortgagor'

  return 'none'
}
