import { g } from '@pretto/bricks/components/layout'
import { bodyMedium16 } from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

interface TooltipProps {
  $position: 'top' | 'right' | 'bottom' | 'left'
  $maxWidth: string
}

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
`

export const Tooltip = styled.div<TooltipProps>`
  ${bodyMedium16};
  background-color: ${({ theme }) => theme.colors.neutral1};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  line-height: 1;
  max-width: ${({ $maxWidth }): string => $maxWidth};
  opacity: 0;
  padding: ${g(2)};
  position: absolute;
  transition: all 200ms ease-in-out;
  visibility: hidden;
  width: max-content;
  z-index: 1000;

  &::after {
    border: ${g(1)} solid transparent;
    content: '';
    position: absolute;
    ${({ $position, theme }) => css`border-${$position}-color: ${theme.colors.neutral1}`}
  }

  ${({ $position }) => {
    switch ($position) {
      case 'bottom':
        return css`
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);

          &::after {
            left: 50%;
            bottom: 100%;
            transform: translateX(-50%);
          }

          ${TooltipContainer}:hover && {
            transform: translate(-50%, ${g(1.5)});
          }
        `
      case 'right':
        return css`
          top: ${g(-3)};
          left: 100%;

          &::after {
            top: ${g(3)};
            right: 100%;
          }

          ${TooltipContainer}:hover && {
            transform: translate(${g(1.5)}, 0);
          }
        `
      case 'left':
        return css`
          top: ${g(-3)};
          right: 100%;

          &::after {
            top: ${g(3)};
            left: 100%;
          }

          ${TooltipContainer}:hover && {
            transform: translate(${g(-1.5)}, 0);
          }
        `

      case 'top':
      default:
        return css`
          left: 50%;
          bottom: 100%;
          transform: translate(-50%, 0);

          &::after {
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
          }

          ${TooltipContainer}:hover && {
            transform: translate(-50%, ${g(-1.5)});
          }
        `
    }
  }}

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
`
