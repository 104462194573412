import { numeralBreakpoints } from '@pretto/bricks/components/layout'

import { useBreakpoint } from './useBreakpoint'

type Breakpoint = keyof typeof numeralBreakpoints

export const useBreakpointToValue = <const T>(defaultValue: T, values: { [K in Breakpoint]?: T }): T => {
  const breakpoint = useBreakpoint(Object.keys(values) as Breakpoint[])

  if (!breakpoint || !(breakpoint in values)) {
    return defaultValue
  }

  return values[breakpoint] as T
}
