import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Container = styled.div`
  padding: ${g(1)} ${g(2)};
  border-radius: 100px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;

  > svg {
    margin-left: ${g(1)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
`
