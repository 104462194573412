import { Boolean } from '@pretto/app/src/onboarding/components/fields/Boolean/Boolean'

import PropTypes from 'prop-types'

export const BlocBoolean = ({ value, onSelect, name, ...field }) => {
  const handleSelect = isSelected => {
    onSelect({ name, value: isSelected, ...field })
  }

  const props = {
    onSelect: handleSelect,
    isSelected: value,
  }

  return <Boolean {...props} />
}

BlocBoolean.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}
