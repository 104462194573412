import isNil from 'lodash/isNil'
import { isValidElement } from 'react'

import { funcToItem } from './funcToItem'

const DEFAULT_ROUND_NUMBER = 1

/**
 * @param {number} number number to round
 * @param {number} roundTo threshold number we want to round it to (can be an integer like 25 or 0.01 for decimals for example)
 * @param {func} fn usually Math.round, Math.ceil, Math.floor, Math.trunc
 * @returns rounded number
 */

// We use `/ (1 / roundTo)` instead of `* roundTo`, because JS, that's why
export const roundNumber = (number, roundTo = DEFAULT_ROUND_NUMBER, fn = Math.round) =>
  fn(number / roundTo) / (1 / roundTo)

const DEFAULT_OPTIONS = { localeOptions: {}, suffix: '' }

/**
 * @param {number} number number to format
 * @param {object} opt options (locale, suffix, decimals)
 * @returns string with formatted number
 */
export const formatNumber = (number, opt = DEFAULT_OPTIONS) => {
  const { decimals, suffix, localeOptions } = opt
  const options = !isNil(decimals)
    ? {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
        ...localeOptions,
      }
    : localeOptions
  return `${number.toLocaleString('fr', options)}${suffix ? ` ${suffix}` : ''}`.replace(/\s/g, ' ')
}

/**
 * @param {number} value
 * @returns formatted number with non-breaking space(eg: 1205.25 => 1 205,25)
 */
export const formatInputNumber = value => {
  if (!value) return value
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.').replace('.', ',')
}

export const formatPlural = (value, unit) => `${value} ${unit}${value > 1 ? 's' : ''}`

export const formatValuesWithData = (item, ...data) =>
  Object.entries(item).reduce(
    (previous, [key, value]) => ({
      ...previous,
      [key]: funcToItem(value, ...data),
    }),
    {}
  )

/**
 * @param {string} str
 * @returns slug from a string with or without special characters
 */
export const slugify = str =>
  str
    ?.normalize('NFD')
    .replace(/[^a-zA-Z0-9-\s]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase() ?? ''

export { funcToItem }
