import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { ButtonBack as ButtonBackCmpnt } from '@pretto/zen/reveal/atoms/buttons/buttonBack/ButtonBack'
import { DividerHorizontal } from '@pretto/zen/reveal/atoms/dividers/dividerHorizontal/DividerHorizontal'

import { FOOTER_HEIGHT, Layout } from '@pretto/app/src/onboarding/components/Layout/Layout'

import styled, { css } from 'styled-components'

// Todo : Replace all styles preceded by a comment with the mention "Reveal" with the appropriate style when the onboarding code part will be migrated.

export const Container = styled(Layout)`
  ${grid()};
  grid-template-rows: 1fr auto;
  padding-top: ${g(2)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: ${g(3)};
  }
`

export const Description = styled.p`
  margin-bottom: ${g(2)};
`

export const Header = styled.div`
  align-items: center;
  /* Reveal Neutral4 */
  background-color: #f1ebe2;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: initial;
    grid-column: 1 / span 3;
    grid-row: 1 / -1;
    justify-content: flex-start;
    padding-top: ${g(3)};
    padding-left: ${g(3)};
    padding-right: ${g(3)};
  }
`

export const Avatar = styled(Image)`
  display: block;
  margin: auto;
  width: ${g(5)};
  height: ${g(5)};
  border-radius: 50%;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(12)};
    height: ${g(12)};
  }
`
export const ExpertTitle = styled.div`
  /* Reveal Caption */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  /* Reveal Neutral2 */
  color: #847e74;

  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(2)};
  }
`
export const ExpertName = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  /* Reveal Neutral1 */
  color: #050505;
  margin-top: ${g(0.5)};
  margin-bottom: ${g(3)};
`

export const Divider = styled(DividerHorizontal)``

const HEADER_ADVISOR_HEIGHT = g(18)

export const Content = styled.div`
  ${column([2, 4])};
  padding-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)} ${g(5)} ${g(3)};
    min-height: calc(100% - ${FOOTER_HEIGHT} - ${HEADER_ADVISOR_HEIGHT});
    overflow: auto;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 7])};
    min-height: initial;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-left: ${g(7)};
    padding-right: ${g(10)};
  }
`

export const Field = styled.div`
  margin-top: ${g(4)};

  ${({ $isCondensed }) =>
    $isCondensed &&
    css`
      margin-top: ${g(2)};
    `}

  &:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2 / -2;
  }
`

export const Title = styled.div`
  /* Reveal Heading5 */
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;

  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $isMainTitle }) =>
      $isMainTitle &&
      css`
        /* Reveal Heading4 */
        font-family: 'Pensum Pro';
        font-size: 32px;
        font-weight: 900;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: ${g(4)};
      `}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    text-align: left;
  }

  strong {
    ${typo.heading16};
    color: ${({ theme }) => theme.colors.primary2};
  }
`

export const SuffixTitle = styled.span`
  /* Reveal Heading6 */
  font-family: 'Pensum Pro';
  font-size: 18px;
  font-style: italic;
  font-weight: 450;
  line-height: 24px;
`

export const Footer = styled.div`
  ${column([1, -1])};
  padding: ${g(2)};
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} ${g(3)} ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, -1])};
    padding: ${g(2)} ${g(5)} ${g(5)};
  }
`

export const ButtonBack = styled(ButtonBackCmpnt)`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    justify-content: flex-start;
    margin-top: ${g(3)};
    margin-bottom: ${g(2)};
    margin-left: -${g(6)};
  }
`

export const NextButton = styled(Button)`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`
