import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Field = styled.div`
  & + & {
    padding-top: ${g(3)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-top: ${g(4)};
    }
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: ${g(2)};
`
