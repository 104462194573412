import PropTypes from 'prop-types'

import * as S from './styles'

const EditableRows = ({ rows, onValidate, isChanging }) => (
  <>
    {rows.map((row, index) => (
      <S.Row key={index} {...row} isChanging={isChanging} />
    ))}
    {isChanging && (
      <S.Button onClick={onValidate} isSecondary>
        Simuler mon prêt
      </S.Button>
    )}
  </>
)
EditableRows.propTypes = {
  /** A button will appear when set to <code>true</code>. */
  isChanging: PropTypes.bool,
  /** onClick function. */
  onValidate: PropTypes.func,
  /** Array of <code>[EditableRow](/#!/EditableRow)</code> props coponents.*/
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EditableRows
