import { detectMobileOS } from '@pretto/bricks/core/utility/detectMobileOS'

import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'

const ServicePrettoSearch = () => {
  const { trackFeature } = useTrackFeatureCard()

  const handleClick = () => {
    trackFeature('pretto_search')
  }

  const props = {
    data: {
      mobileOs: detectMobileOS(),
      onAppStoreClick: handleClick,
      onGooglePlayClick: handleClick,
    },
    slug: 'prettoSearch',
  }

  return <ServiceCard {...props} />
}

export default ServicePrettoSearch
