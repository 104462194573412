import 'core-js/stable'
import React from 'react'
import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'

import App from './App'
import Front from './Helpers/frontchat'
import { getTrackingId, persistUtmFlagsLastClicked } from './lib/helpers'
import { BING_ADS_SLUG, GOOGLE_ADS_SLUG } from './lib/tracking'
import './root.css'

if (process.env.NODE_ENV === 'production') Front('init')

window.React = React

persistUtmFlagsLastClicked()
getTrackingId(GOOGLE_ADS_SLUG)
getTrackingId(BING_ADS_SLUG)

ReactDOM.render(<App />, document.getElementById('root__body'))
