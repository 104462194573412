import AutoComplete from '@pretto/bricks/components/form/AutoComplete'
import AutosizeField from '@pretto/bricks/components/form/AutosizeField'
import DateField from '@pretto/bricks/components/form/DateField'
import NumberField from '@pretto/bricks/components/form/NumberField'
import SelectField from '@pretto/bricks/components/form/SelectField'
import Text from '@pretto/bricks/components/typography/Text'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { isValidElement, PureComponent } from 'react'

import * as C from './Sentence.module.css'
import * as S from './styles'

const renderInput = (elem, isFocused) => {
  if (isValidElement(elem) || typeof elem === 'string') return elem

  const { props, type } = elem
  props.autoFocus = isFocused

  switch (type) {
    case 'block':
      return <S.Block>{props.blocks.map(renderInput)}</S.Block>
    case 'select':
      return <SelectField {...props} />
    case 'banks':
    case 'regional_banks':
    case 'cities':
    case 'city':
    case 'country': {
      return <AutoComplete {...props} />
    }
    case 'number':
    case 'decimal':
      return <NumberField {...props} />
    case 'text':
      return <AutosizeField {...props} />
    case 'date':
      return <DateField {...props} />
    case 'button':
      return <S.Button {...props} />
    default:
      return <span {...props} />
  }
}
export default class Sentence extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
    label: PropTypes.node,
  }

  static defaultProps = {
    hasHelper: false,
  }

  render() {
    const { children, autoFocus, label, className } = this.props

    return (
      <div className={classnames(className, C.sentence, { [C.sentenceWithLabel]: label })}>
        {label && (
          <div className={C.sentenceLabel}>
            <Text variant="primary-1" size="small">
              {label}
            </Text>
          </div>
        )}
        {children.map((elem, i) => {
          const first = children.find(child => typeof child === 'object')
          const isFocused = autoFocus && elem.path && elem.path === first.path
          const nextItem = children[i + 1]
          const inputComponent = renderInput(elem, isFocused)
          if (nextItem && nextItem === '.')
            return (
              <span key={i} className={C.sentenceLast}>
                {inputComponent}.
              </span>
            )
          if (elem !== '.') return <span key={i}>{inputComponent}</span>
          return null
        })}
      </div>
    )
  }
}
