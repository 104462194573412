import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Text from '@pretto/bricks/components/typography/Text'

import { memo } from 'react'

import * as S from './styles'

const DeprecatedBrowserBar = () => (
  <S.DeprecatedBrowserBar>
    <Wrapper>
      <S.DeprecatedBrowserBarContent>
        <Text size="small" type="p">
          <S.Emoji>
            <Emoji size="x-small" verticalAlign="sub">
              :grimacing:
            </Emoji>
          </S.Emoji>
          Pretto ne garantie pas une expérience optimale sur Internet Explorer.
        </Text>
      </S.DeprecatedBrowserBarContent>
    </Wrapper>
  </S.DeprecatedBrowserBar>
)

export default memo(DeprecatedBrowserBar)
