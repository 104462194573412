import PropTypes from 'prop-types'

import * as S from './styles'

const CookieBanner = ({ onAcceptAllClick, onParametersClick, onRefuseAllClick }) => (
  <>
    <S.Backdrop />
    <S.ContentContainer>
      <S.Title>
        <S.Emoji size="large">:cookie:</S.Emoji>
        Nous utilisons des cookies
      </S.Title>
      <S.Text>
        Chez Pretto on utilise seulement des cookies respectueux de vos données personnelles. Ils nous permettent de
        vous proposer du contenu personnalisé, d’analyser le trafic et d’échanger plus efficacement avec vous.
      </S.Text>
      <S.ButtonsContainer>
        <S.Link onClick={onRefuseAllClick}>Refuser</S.Link>
        <S.Link onClick={onParametersClick}>Paramétrer</S.Link>
        <S.Button onClick={onAcceptAllClick}>Accepter</S.Button>
      </S.ButtonsContainer>
    </S.ContentContainer>
  </>
)

CookieBanner.propTypes = {
  onAcceptAllClick: PropTypes.func,
  onParametersClick: PropTypes.func,
  onRefuseAllClick: PropTypes.func,
}

export default CookieBanner
