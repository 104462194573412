import StoreButton from '@pretto/bricks/components/buttons/StoreButton'
import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Button = styled(StoreButton)`
  & + & {
    margin-top: ${g(1)};
  }
`
export const ButtonAppStore = styled(Button).attrs({ slug: 'AppStore' })``

export const ButtonGooglePlayStore = styled(Button).attrs({ slug: 'GooglePlayStore' })``
