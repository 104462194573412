export const checkFieldFilled = ({ type, value }) => {
  if (['message', 'calendar'].includes(type)) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  switch (type) {
    case 'banks':
      return value.length > 0

    case 'date':
      return value.length === 14

    case 'boolean':
      return value === true || value === false

    case 'integer':
    case 'number':
      return value?.toString().length > 0 ?? false

    case 'textarea':
      return value.length > 0

    case 'options':
      return false

    default:
      return false
  }
}
