import InputRadio from '@pretto/bricks/components/form/InputRadio'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

import { OptionButtonsProps } from './OptionButtons'

interface ContainerProps {
  $isSelected: OptionButtonsProps['isSelected']
}

interface ValueLineThrough {
  $isLineThrough?: OptionButtonsProps['isDoubleline']
}

export const Container = styled.div<ContainerProps>`
  ${({ $isSelected, theme }) =>
    $isSelected
      ? css`
          box-shadow: 0 0 0 2px ${theme.colors.primary2};
        `
      : css`
          box-shadow: 0 0 0 1px ${theme.colors.neutral3};
        `}

  border-radius: ${g(1)};
  padding: ${g(2)} ${g(3)};
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: box-shadow 200ms ease-in-out;

  &:focus {
    outline: 0px transparent;
  }

  &:focus,
  &:focus-within,
  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary2};
  }
`

export const Input = styled(InputRadio)`
  margin-right: ${g(1)};
`

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${typo.bodyBold16};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    align-items: flex-end;
  }
`

export const FirstValue = styled(ValuesContainer)`
  ${typo.bodyBold16};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: ${g(2)};
    order: 3;
  }
`

export const SecondValue = styled(ValuesContainer)<ValueLineThrough>`
  ${({ $isLineThrough }) =>
    $isLineThrough &&
    css`
      margin-left: auto;
      text-decoration: line-through;
      ${typo.bodyBook16}
    `}

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-left: auto;
  }
`

export const Caption = styled.div`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
`
