import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import SubscribePageSentences from '@pretto/app/src/Subscribe/Containers/SubscribePage/Sentences'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { LoaderType } from '@pretto/app/src/config/loader'
import whiteLabel from '@pretto/app/src/config/whiteLabel'
import abTest from '@pretto/app/src/lib/abtest'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { ProjectKind } from '@pretto/app/src/types/gateway/enums'

import qs from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link, RouteComponentProps } from 'react-router-dom'

import { SubscribeQuery, useSubscribeQuery } from './subscribe.gateway.graphql'

const label = whiteLabel()

interface SignUpParams {
  assignment: string
  email: string
  kind: string
  source: string
}

interface ApplyParams {
  firstName: string
  lastName: string
  phone: string
}

type Flow = 'capacity' | 'purchase' | 'renegotiation'

interface PageProps extends RouteComponentProps {
  onApply: (fields: ApplyParams, flow: Flow) => void | string
  onGoogleConnect: ({ projectID }: { projectID: string }) => void
  onSignUp: ({ assignment, email, kind, source }: SignUpParams) => void | string
}

interface SubmitParams {
  email: string
  firstName?: string
  lastName?: string
  phone?: string
}

const getFlow = (data: SubscribeQuery | undefined): Flow => {
  switch (data?.project?.project_kind) {
    case ProjectKind.Capacity:
      return 'capacity'

    case ProjectKind.Renegotiation:
      return 'renegotiation'

    default:
    case ProjectKind.Purchase:
      if (
        !data?.project?.purchase?.property_price &&
        !data?.project?.purchase?.land_price &&
        !data?.project?.purchase?.build_price
      ) {
        return 'capacity'
      }

      return 'purchase'
  }
}

const SubscribePage: React.FC<PageProps> = ({ history, location, onApply, onGoogleConnect, onSignUp }) => {
  const { search } = useLocation()

  const [error, setError] = useState<string | null>(null)
  const [isMutating, setIsMutating] = useState(false)

  const { data, loading } = useSubscribeQuery()

  const trackAction = useTracking()

  const { projectID, typology, user } = useUser()

  const isLoading = useLoading(
    loading || isMutating,
    typology === 'preLead' && !loading ? LoaderType.Subscribe : LoaderType.Default
  )

  const userEmail = user?.email ?? ''
  const isSuggestionsVisible = abTest('capacity_result_202311') !== 'classic'

  const flow = getFlow(data)

  const handleSubmit = ({
    email,
    lastName = user?.lastName,
    firstName = user?.firstName,
    phone = user?.phone,
  }: SubmitParams) => {
    setIsMutating(true)

    if (typology === 'prospect') {
      signUp(email)
    }

    if (typology === 'preLead') {
      apply({ firstName, lastName, phone })
    }
  }

  useEffect(() => {
    trackAction('SIMULATION_SUBSCRIBE_PAGE_VIEWED')
    window.scrollTo(0, 0)
  }, [])

  const { kind, origin } = qs.parse(location.search, { ignoreQueryPrefix: true }) as { kind: string; origin: string }

  const signUp = async (email: string) => {
    try {
      const error = await onSignUp({
        assignment: 'project',
        email,
        kind,
        source: 'subscribe',
      })

      if (error) {
        throw new Error(error)
      }
    } catch (error) {
      const message = (error as Error).message
      setError(message)
      setIsMutating(false)
    }
  }

  const apply = async ({ firstName, lastName, phone }: ApplyParams) => {
    const fields = {
      firstName,
      lastName,
      phone,
    }

    try {
      const error = await onApply(fields, flow)

      if (error) {
        throw new Error(error)
      }
    } catch (error) {
      const message = (error as Error).message
      setError(message)
      setIsMutating(false)
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const trackLegalLink = (source: string) => () => {
    trackAction('SUBSCRIBE_LEGAL_LINKS_CLICKED', { subscribe_legal_link: source })
  }

  const handleGoogleConnect = () => {
    onGoogleConnect({
      projectID,
    })
    trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'subscribe' })
  }

  const handleResetError = () => {
    setError(null)
  }

  const props = {
    email: userEmail,
    error,
    flow,
    isSignedUp: typology !== 'prospect',
    isSuggestionsVisible,
    kind,
    link: <Link to="/login">ici</Link>,
    onCguClick: trackLegalLink('cgu'),
    onGoBack: handleGoBack,
    onGoogleConnect: handleGoogleConnect,
    onPrivacyClick: trackLegalLink('privacy'),
    onResetError: handleResetError,
    onSubmit: handleSubmit,
    origin,
    whiteLabel: label,
  }

  const NOT_FUNEL_PAGES_SEARCH = [
    '?kind=dashboard_next_step&origin=dashboard',
    '?kind=dashboard_appointment&origin=dashboard',
    '?kind=dashboard_certificate&origin=dashboard',
  ]

  const headerProps = {
    accountVariant: typology === 'preLead' ? 'onlyInitial' : null,
    goBackProps: NOT_FUNEL_PAGES_SEARCH.includes(search) ? GO_BACK.dashboard : null,
    isMobileHidden: !NOT_FUNEL_PAGES_SEARCH.includes(search),
    navigationItemsList: [NAV_ITEMS.faq],
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <Header {...headerProps} />
      <SubscribePageSentences {...props} />
    </>
  )
}

const Subscribe: React.FC = props => (
  <SubscribeConsumer>{context => <SubscribePage {...props} {...context} />}</SubscribeConsumer>
)

export default Subscribe
