import { useApolloClient, useMutation } from '@apollo/client'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { EMPTY_FUNCTION } from './constants'
import { SUBSCRIBE_RATE_ALERT, UNSUBSCRIBE_RATE_ALERT } from '../apollo'
import { useUser } from '../User/Containers/UserProvider'

const DEBOUNCE_RATE = 1000

export const useRateAlert = ({
  trackClick = EMPTY_FUNCTION,
  trackSubscribe = EMPTY_FUNCTION,
  trackUnsubscribe = EMPTY_FUNCTION,
} = {}) => {
  const client = useApolloClient()
  const { user: { project, rateAlert } = {} } = useUser()
  const [isSubscribed, setIsSubscribed] = useState(rateAlert)
  const [isSubscribedDebounced] = useDebounce(isSubscribed, DEBOUNCE_RATE)
  const [subscribe] = useMutation(SUBSCRIBE_RATE_ALERT)
  const [unsubscribe] = useMutation(UNSUBSCRIBE_RATE_ALERT)

  useUpdateEffect(() => {
    if (isSubscribedDebounced) {
      subscribe({ update: updateAfterSubscribed })
    }
    if (!isSubscribedDebounced) {
      unsubscribe({ update: updateAfterUnsubscribed })
    }
  }, [isSubscribedDebounced])

  const updateCache = () => {
    invalidateCache(client)
  }

  const updateAfterSubscribed = (cache, response) => {
    trackClick()
    const { error } = response.data.subscribe_rate_alert
    if (!error) {
      trackSubscribe()
      updateCache(cache, true)
    } else {
      // eslint-disable-next-line no-console
      console.error('Could not subscribe to rate alert', error)
    }
  }

  const updateAfterUnsubscribed = (cache, response) => {
    trackClick()
    const { success } = response.data.unsubscribe_rate_alert
    if (success) {
      trackUnsubscribe()
      updateCache(cache, false)
    }
  }

  const handleToggle = () => {
    setIsSubscribed(prevIsSubscribed => !prevIsSubscribed)
  }

  const handleSubscribe = () => {
    setIsSubscribed(true)
  }
  const handleUnsubscribe = () => {
    setIsSubscribed(false)
  }

  return {
    isSubscribed,
    isTogglable: !!project,
    onSubscribe: handleSubscribe,
    onToggle: handleToggle,
    onUnsubscribe: handleUnsubscribe,
  }
}
