import type { PropsWithAlertButton, PropsWithButton } from '@pretto/bricks/app/dashboard/components/MainSectionFolder'
import Link from '@pretto/bricks/website/utility/Link'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { withProps } from '@pretto/app/src/dashboard/lib/withProps'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import DOCS from '@pretto/config/docs.json'
import { Check, Clock, ExclamationMarkTriangle } from '@pretto/picto'

import styled from 'styled-components'

import { ContactController } from '../contactController'

import { useApplicationCardQuery } from './application.gateway.graphql'

const LinkInline = styled(Link)`
  text-decoration: underline;
`

const ACTION_URL = '/application'

const Application: React.FC<CardProps> = ({ dashboardData, defaultComponent: Component, onActionClick }) => {
  const { completionPercentage } = useApplication()

  const { data, loading } = useApplicationCardQuery()

  const {
    advisor,
    hasFeatureAccess,
    maturity,
    project: {
      purchase: { build_price },
    },
    status,
  } = useUser()

  if (loading) {
    return null
  }

  const defaultProps: PropsWithButton = {
    actionLabel: 'Remplir mon dossier',
    actionUrl: ACTION_URL,
    description:
      'C’est le moment de préparer votre dossier. Laissez vous guider pour remplir vos informations et envoyer vos documents dans votre espace sécurisé.',
    onActionClick() {
      onActionClick(ACTION_URL)
    },
    progressValue: completionPercentage,
    title: 'Complétez votre dossier',
  }

  const propsWithBlockInfos = withProps<PropsWithButton>(defaultProps, [
    {
      condition:
        !['offer_accepted', 'sale_agreement_signed'].includes(maturity) &&
        !build_price &&
        !hasFeatureAccess(['BUYOUT']),
      blockInfos: {
        content: (
          <>
            {advisor.role === 'sdr' ? (
              <>
                <LinkInline href="/booking">Prenez rendez-vous avec votre conseiller Pretto</LinkInline>, puis déposer{' '}
                <LinkInline href="/application/documents?category=certificate">vos documents</LinkInline>
              </>
            ) : (
              <>
                Complétez vos <LinkInline href="/application/documents?category=certificate">documents</LinkInline>
              </>
            )}{' '}
            pour obtenir une attestation Pretto. Cette attestation permet de certifier aux vendeurs que vous pouvez
            obtenir les meilleures conditions pour votre achat.{' '}
            <LinkInline href="https://faq.pretto.fr/fr/articles/834754">En savoir +</LinkInline>
          </>
        ),
        title: 'Attestation de financement',
      },
    },
  ])

  const props = withProps<PropsWithButton, PropsWithAlertButton>(propsWithBlockInfos, [
    {
      alertMessage: (
        <>
          Certains de vos documents sont invalides. Nous vous invitons à{' '}
          <LinkInline href="/application/documents?status=invalid">consulter votre dossier.</LinkInline>
        </>
      ),
      alertType: 'error',
      condition: data?.docs?.some(({ document_status }) => document_status === 'invalid') ?? false,
      picto: ExclamationMarkTriangle,
    },
    {
      alertMessage: 'Vos documents de demande d’attestation de financement sont en cours de vérification.',
      alertType: 'info',
      get condition() {
        const certificateDocs = data?.docs?.filter(doc => {
          const kind = doc.kind as keyof typeof DOCS

          return DOCS[kind].certificate === true && kind !== 'other'
        })

        const areCertificateDocsPending = certificateDocs?.some(({ document_status }) => document_status !== 'valid')
        const areCertificateDocsInvalid = certificateDocs?.some(
          ({ document_status }) => document_status === 'invalid' || document_status === 'empty'
        )

        return (
          data?.project?.project_kind !== 'renegotiation' &&
          maturity !== 'sale_agreement_signed' &&
          !dashboardData?.deal?.certificate &&
          !areCertificateDocsInvalid &&
          !!areCertificateDocsPending &&
          !hasFeatureAccess(['BUYOUT'])
        )
      },
      picto: Clock,
    },
    {
      alertMessage: (
        <>
          Vos documents de demande d’attestation sont validés.
          <br />
          <ContactController isLink />
        </>
      ),
      alertType: 'success',
      get condition() {
        const certificateDocs = data?.docs?.filter(doc => {
          const kind = doc.kind as keyof typeof DOCS

          return DOCS[kind].certificate === true && kind !== 'other'
        })

        const areCertificateDocsValid = certificateDocs?.every(({ document_status }) => document_status === 'valid')

        return (
          data?.project?.project_kind !== 'renegotiation' &&
          maturity !== 'sale_agreement_signed' &&
          ['search', 'constitution', 'validation', 'analysis'].includes(status) &&
          !dashboardData?.deal?.certificate &&
          !!areCertificateDocsValid &&
          !hasFeatureAccess(['BUYOUT'])
        )
      },
      picto: Check,
    },
  ])

  return <Component {...props} />
}

export const config: Config = {
  component: Application,
  name: 'application',
}
