import { breakpoints, g } from '@pretto/bricks/components/layout'
import { css } from 'styled-components'

export default css`
  display: inline;
  position: relative;
  border-bottom: 2px dashed ${({ theme }) => theme.legacy.colors.primary1.default};
  border-radius: 0;
  padding: 4px ${g(1)} 7px;
  min-width: ${g(1)};
  font-weight: 600;
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: inline-block;
    padding: 0 ${g(1)};
    height: ${g(4)};
    line-height: ${g(4)};
  }

  input {
    border: 0;
    padding: 0;
    text-align: center;
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
    font-family: inherit;
    font-size: ${g(2)};
    font-weight: 600;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.legacy.colors.neutral1.fade(30)};
      font-weight: 400;
    }
  }

  ${({ isValid }) =>
    isValid &&
    css`
      border-style: solid;
    `}
`
