import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Container = styled.div`
  padding: ${g(1)} ${g(2)} ${g(2)};

  & > div + div {
    margin-top: ${g(4)};
  }
`

export const Button = styled(ButtonFeature)`
  margin-top: ${g(2.5)};
  background-color: transparent;
`
