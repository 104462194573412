import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import * as typo from '@pretto/bricks/core/typography'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'

import styled, { css } from 'styled-components'

const solidFormCSS = css`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 600px;
    padding-top: ${g(4)};
    padding-left: ${g(4)};
    padding-right: ${g(4)};
    padding-bottom: ${g(2)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-radius: ${g(1)} ${g(1)} 0px 0px;
  }
`

const solidPaddingCSS = css`
  padding-top: ${g(6)};
  padding-bottom: ${g(0)};
`

export const Page = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: ${g(4)};

  ${({ $isFormSolid }) => $isFormSolid && solidPaddingCSS}
`

export const Wrapper = styled.div`
  align-items: center;
  width: 600px;
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  overflow: hidden;
  padding: 0;
`

export const Container = styled.div`
  padding: ${g(3)};
`

export const Image = styled.img`
  width: 600px;
`

export const FormContent = styled.div`
  ${({ $isFormSolid }) => $isFormSolid && solidFormCSS}
`

export const ImageContent = styled.div`
  margin-bottom: ${g(2)};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${g(2)};
`

export const DescriptionContent = styled.div`
  width: 100%;
  margin-bottom: ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }
`

export const Heading = styled(BaseTypo).attrs({ typo: 'heading48' })`
  white-space: 'nowrap';
`

export const Link = styled(BaseTypo).attrs({ typo: 'bodyBook' })`
  text-decoration: underline;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;
`

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-top: ${g(1)};
`

export const StyledButtonSecondary = styled(StyledButton).attrs({ isSecondary: true })``

export const SuccessTitle = styled(BaseTypo).attrs({ typo: 'heading32' })`
  text-align: center;
  margin-bottom: ${g(2)};
`

export const StepText = styled.p`
  ${({ $isBold }) =>
    $isBold
      ? css`
          ${typo.bodyBold16}
        `
      : css`
          ${typo.bodyBook16}
        `};
`

export const SuccessDescription = styled.p`
  ${typo.bodyBook16};
  text-align: center;

  > a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary1};
    cursor: pointer;
  }
`

export const Text = styled(BaseTypo).attrs({ typo: 'bodyBook' })`
  margin-top: ${g(2)};
  margin-bottom: ${g(2)};
`

export const Label = styled(BaseTypo).attrs({ typo: 'bodyBook' })`
  margin-top: ${g(3)};
  margin-bottom: ${g(2)};
`
