import styled, { css } from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Container = styled.div`
  margin: 0 ${g(2)};
  position: relative;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(10)};
    max-width: ${g(83)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    margin: 0 auto;

    ${({ align }) =>
      align &&
      css`
        margin-${align}: 0;
    `}
  }

  ${({ full }) =>
    !full &&
    css`
      @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 ${g(3)};
      }
    `}
`
