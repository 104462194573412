import ButtonCard from '@pretto/bricks/components/buttons/ButtonCard'
import Card from '@pretto/bricks/components/cards/Card'
import Text from '@pretto/bricks/components/typography/Text'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { File, FileCheck, MagnifyingGlassLeft, Pen, QuestionMarkCircle } from '@pretto/picto'

import PropTypes from 'prop-types'

import * as S from './styles'

const MATURITIES = {
  default: {
    label: 'Mettre à jour mon projet',
    picto: MagnifyingGlassLeft,
  },
  under_study: {
    label: 'Je suis en réflexion',
    picto: QuestionMarkCircle,
  },
  search_started: {
    label: 'Je suis en recherche active de bien',
    picto: MagnifyingGlassLeft,
  },
  offer_made: {
    label: 'J’ai fait une offre d’achat pour un bien',
    picto: File,
  },
  offer_accepted: {
    label: 'J’ai une offre d’achat acceptée',
    picto: FileCheck,
  },
  sale_agreement_signed: {
    label: 'J’ai signé un compromis de vente',
    picto: Pen,
  },
}

const DashboardMaturityCard = ({ description, maturity }) => {
  const { label, picto } = MATURITIES[maturity || 'default']

  return (
    <Card variant="primary4">
      <S.Content>
        <div>
          <S.Title>Où en est votre projet ?</S.Title>

          <S.Description>
            <Text variant="neutral-1">{description}</Text>
          </S.Description>
        </div>

        <ButtonCard href="/maturity" label={label} picto={picto} backgroundVariant="neutral4" />

        <S.Image
          src={getCloudinaryUrl('v1624962175/bricks/static-assets/illustrations/house_search.png')}
          alt="house search"
        />
      </S.Content>
    </Card>
  )
}

DashboardMaturityCard.propTypes = {
  description: PropTypes.string.isRequired,
  /** Purchase maturity. */
  maturity: PropTypes.string.isRequired,
}

export default DashboardMaturityCard
