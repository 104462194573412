import { useState } from 'react'

type UseActiveField = (
  defaultValue?: number,
  isFilled?: boolean
) => [activeField: number, setActiveField: React.Dispatch<React.SetStateAction<number>>]

export const useActiveField: UseActiveField = (defaultValue, isFilled) => {
  const [activeField, setActiveField] = useState<number>(isFilled ? Infinity : defaultValue || 0)

  return [activeField, setActiveField]
}
