import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const Button = ({ children, isArrowShown, isDisabled, isLoading, isObfucated, isSecondary, onClick, ...props }) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
    }

    onClick(event, ...args)
  }

  const _isLink = !isNil(props.href)
  const _isLoading = !_isLink && isLoading
  const _isDisabled = isDisabled || _isLoading

  return (
    <S.Button
      $isDisabled={_isDisabled}
      $isObfucated={isObfucated}
      $isSecondary={isSecondary}
      onClick={isObfucated ? onClick : handleClick}
      {...props}
    >
      <S.Label $isLoading={_isLoading}>{children}</S.Label>

      {(_isLink || isArrowShown) && <S.Arrow />}

      <Transition
        in={_isLoading}
        timeout={{ enter: S.TRANSITION_DELAY, exit: S.TRANSITION_DURATION_OUT }}
        unmountOnExit
      >
        {state => <S.Spinner $isLoading={state === 'entered'} />}
      </Transition>
    </S.Button>
  )
}

Button.defaultProps = {
  onClick: () => {},
}

Button.propTypes = {
  /** Text of button */
  children: PropTypes.node,
  /** If set, it button will be a link. An arrow will be shown as well. */
  href: PropTypes.string,
  /** Whether arrow is shown despite href */
  isArrowShown: PropTypes.bool,
  /** Disabled state of button. */
  isDisabled: PropTypes.bool,
  /** Whether the loading state is active or not. */
  isLoading: PropTypes.bool,
  /** Must the button be hidden from Search engines ? */
  isObfucated: PropTypes.bool,
  /** Whether it is a isSecondary button or not. Styles (colors) will be applied accordingly. */
  isSecondary: PropTypes.bool,
  /** onClick Function triggered. */
  onClick: PropTypes.func,
}

export default Button
