import { BuyoutPage } from '@pretto/app/src/Sentences/v2/Buyout/Buyout/Containers/BuyoutPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const BuyoutController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/buyout/buyout')} component={BuyoutPage} />
      <Redirect to="/" />
    </Switch>
  )
}
