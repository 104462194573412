import { g, ng } from '@pretto/bricks/components/layout'
import * as easings from 'easings-css'
import styled, { css } from 'styled-components'

import * as animations from './animations'
import { frames, meta, source } from './config/banks'
import * as constants from './config/constants'
import { normalizePosition } from './utility/normalizePosition'
import { normalizeSize } from './utility/normalizeSize'

const GravitionalObject = styled.div`
  border-radius: ${({ $r }) => g($r)};
  height: ${({ $r }) => g($r * 2)};
  left: ${({ $a = 0, $d = 0, $r }) => g(constants.ORBIT_LARGE_RADIUS - $r + Math.cos(($a * Math.PI) / 180) * $d)};
  position: absolute;
  top: ${({ $a = 0, $d = 0, $r }) => g(constants.ORBIT_LARGE_RADIUS - $r + Math.sin(($a * Math.PI) / 180) * $d)};
  width: ${({ $r }) => g($r * 2)};
`

export const Orbit = styled(GravitionalObject)`
  animation: ${animations.orbitAnimationEntering} 600ms 800ms ${easings.easeInOutSine} both;
  border: 1px solid ${({ theme }) => theme.colors.primary1};
`

const PlanetBase = styled.div`
  border-radius: inherit;
  height: inherit;
  transform-origin: inherit;
  width: inherit;
`

export const PlanetBody = styled(PlanetBase)`
  animation: ${animations.planetAnimationRevolution} 4s linear infinite
    ${({ $m = 'normal' }) => ($m === 'reverse' ? 'normal' : 'reverse')};
  background-image: url('${source}');
  background-position: ${({ $f }) =>
    normalizePosition($f, { height: ng(constants.PLANET_DIAMETER), width: ng(constants.PLANET_DIAMETER) })};
  background-repeat: no-repeat;
  background-size: ${({ $f }) =>
    normalizeSize($f, { height: ng(constants.PLANET_DIAMETER), width: ng(constants.PLANET_DIAMETER) }, meta.size)};
  box-shadow: 1px 3px 6px rgba(5, 5, 5, 0.1);
  transform-origin: center;
`

export const PlanetRevolution = styled(GravitionalObject).attrs({
  $r: constants.PLANET_DIAMETER / 2,
})`
  animation: ${animations.planetAnimationRevolution} 4s linear infinite ${({ $m = 'normal' }) => $m};
  transform-origin: ${({ $a = 0, $d = 0, $r }) =>
    css`
      ${g($r - Math.cos(($a * Math.PI) / 180) * $d)} ${g($r - Math.sin(($a * Math.PI) / 180) * $d)}
    `};
`

export const PlanetTransformation = styled(PlanetBase)`
  animation: ${animations.planetAnimationEntering} 600ms 800ms ${easings.easeInOutSine} both;
`

export const Stage = styled.div`
  height: ${g(constants.ORBIT_LARGE_DIAMETER)};
  position: relative;
  width: ${g(constants.ORBIT_LARGE_DIAMETER)};
`

export const Sun = styled(GravitionalObject).attrs({
  $r: constants.SUN_DIAMETER / 2,
})`
  animation: ${animations.sunAnimationEntering} 1400ms linear both;
  background-image: url('${source}');
  background-position: ${normalizePosition(frames.pretto.frame, {
    height: ng(constants.SUN_DIAMETER),
    width: ng(constants.SUN_DIAMETER),
  })};
  background-size: ${normalizeSize(
    frames.pretto.frame,
    { height: ng(constants.SUN_DIAMETER), width: ng(constants.SUN_DIAMETER) },
    meta.size
  )};
  background-repeat: no-repeat;
  transform-origin: center;
`
