import EmailSentPage from '@pretto/app/src/Auth/views/EmailSentPage'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import qs from 'qs'

import Header from '../../../SharedContainers/Header'

const TITLE = 'Vérifiez votre boite mail'
const MISSING_EMAIL_TEXT = 'votre adresse'
const MESSAGES = email => [
  <>
    Nous venons de vous envoyer un mail à <strong>{email || MISSING_EMAIL_TEXT}</strong> pour valider votre adresse
    mail.
  </>,
  <>
    Rendez-vous dans votre boite mail et <strong>cliquez sur le lien</strong> pour continuer.
  </>,
]

const EmailVerificationSentPage = ({ location }) => {
  const { email } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const props = {
    messages: MESSAGES(email),
    title: TITLE,
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <EmailSentPage {...props} />
    </>
  )
}

EmailVerificationSentPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default EmailVerificationSentPage
