import { g } from '@pretto/bricks/components/layout'
import { heading32 } from '@pretto/bricks/core/typography'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import { useAdvisor } from '@pretto/app/src/SharedContainers/AdvisorDialog'
import { NegotiationStateType } from '@pretto/app/src/types/gateway/enums'
import { ExclamationMarkCircle, Times } from '@pretto/picto'

import styled from 'styled-components'

export interface NegotiationModalProps {
  onClickAdvisor?: () => void
  onClickLink?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  onClose: () => void
  title: string
  isOnError?: boolean
  slug: NegotiationStateType
}

const ERROR: Partial<Record<NegotiationStateType, string>> = {
  [NegotiationStateType.Sent]:
    'Votre dossier n’a finalement pas été pris en charge par cette banque. Les raisons peuvent varier (les conditions ont changé et votre expert considère qu’il ne faut plus envoyer votre dossier dans cette banque, la banque gèle la prise de nouveaux dossiers pour le moment, etc) Si ce n’est pas déjà fait, vous pouvez contacter votre expert pour qu’il vous explique la nouvelle stratégie et les actions possibles.',
  [NegotiationStateType.CheckBank]:
    'À notre grand regret, la banque a refusé votre dossier. Les raisons peuvent varier (dossier trop complexe, risque de défaut de paiement, délais trop court). Si ce n’est pas déjà fait, vous pouvez contacter votre expert pour qu’il vous explique les suites à donner à ce refus et les actions possibles.',
  [NegotiationStateType.CheckGaranty]:
    'L’organisme de garantie a estimé que votre profil emprunteur pour ce projet présente un trop grand risque de défaut de paiement sur le long terme. Si ce n’est pas déjà fait, vous pouvez contacter votre expert pour qu’il vous explique les suites à donner à ce refus et les actions possibles.',
  [NegotiationStateType.Negotiation]:
    'Vous (ou votre expert) estimez que la proposition obtenue à la fin des négociations n’est pas assez satisfaisante pour poursuivre avec cette banque.',
}

export const NegotiationModal: React.FC<NegotiationModalProps & { error?: string }> = ({
  children,
  title,
  onClickAdvisor,
  onClose,
  isOnError,
  slug,
}) => {
  const { onOpen } = useAdvisor()

  const handleContactExpert = () => {
    onClickAdvisor?.()
    onClose()
    onOpen()
  }

  return (
    <Card>
      <Header>
        <Title>{title}</Title>
        <Close onClick={onClose} />
      </Header>

      {isOnError && (
        <Alert type="error" picto={ExclamationMarkCircle}>
          {ERROR[slug]} <ContactExpert onClick={handleContactExpert}>Contacter mon expert</ContactExpert>
        </Alert>
      )}

      <Content>{children}</Content>
    </Card>
  )
}

const Card = styled.div`
  padding: ${g(3)};
`
const Alert = styled(Banner)`
  margin-bottom: ${g(1)};
`
const Header = styled.div`
  display: flex;
  gap: ${g(1)};
  justify-content: space-between;
`
const Title = styled.div`
  ${heading32};
  margin-bottom: ${g(3)};
`
const ContactExpert = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
const Content = styled.div`
  p + p {
    margin-top: ${g(3)};
  }

  a {
    text-decoration: underline;
  }
`
const Close = styled(Times)`
  flex-shrink: 0;
  padding: 1px;
  cursor: pointer;
`
