import { ENTER, SPACE } from '@pretto/bricks/core/constants/keycodes'

import PropTypes from 'prop-types'
import { useRef } from 'react'

import * as S from './styles'

const InputRadio = ({ id, isChecked, name, label, onChange, value, ...props }) => {
  const radioButton = useRef()

  const handleKeyDown = e => {
    if ([ENTER, SPACE].includes(e.keyCode)) {
      e.preventDefault()
      onChange(radioButton.current.value)
    }
  }

  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <S.InputRadio {...props}>
      <S.InputContainer $isChecked={isChecked} onKeyDown={handleKeyDown}>
        <S.Input
          aria-checked={isChecked}
          checked={isChecked}
          id={id}
          name={name}
          onChange={handleChange}
          ref={radioButton}
          type="radio"
          value={value}
        />
      </S.InputContainer>
      <S.InputOverlay $isChecked={isChecked} />
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
    </S.InputRadio>
  )
}

InputRadio.propTypes = {
  /** Id of RadioButton - Is requiered when there is a label */
  id: PropTypes.string,
  /** RadioButton is isChecked if true. */
  isChecked: PropTypes.bool.isRequired,
  /** RadioButton label. */
  label: PropTypes.node,
  /** RadioButton name. */
  name: PropTypes.any,
  /** @ignore Do not use. Is used by InputRadioGroup */
  onChange: PropTypes.func,
  /** Value of RadioButton. */
  value: PropTypes.any.isRequired,
}

InputRadio.defaultProps = {
  isChecked: false,
  value: '',
}

export default InputRadio
