import { g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

interface DetailsItem {
  label: string
  value: React.ReactNode
}

interface DetailsProps {
  details: DetailsItem[]
}

export const Details: React.FC<DetailsProps> = ({ details, ...props }) => (
  <Items {...props}>
    {details.map(({ label, value }, index) => (
      <Item key={label}>
        {label} :
        {typeof value === 'string' ? <ItemValue $isHighlighted={index === 0}>{value}</ItemValue> : value}
      </Item>
    ))}
  </Items>
)

const Items = styled.ul`
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  ${typography.caption12};
  align-items: baseline;
  color: ${({ theme }) => theme.colors.neutral2};
  display: flex;
  gap: ${g(1)};
`

interface ItemValueProps {
  $isHighlighted: boolean
}

const ItemValue = styled.span<ItemValueProps>`
  ${({ $isHighlighted }) =>
    $isHighlighted
      ? css`
          ${typography.numbersBold16};
          color: ${({ theme }) => theme.colors.primary2};
        `
      : css`
          ${typography.numbers16};
        `};
`
