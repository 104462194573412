import PropTypes from 'prop-types'

import * as S from './styles'

const Tooltip = ({ content, children, isOneLiner, position, TooltipComponent, ...props }) => (
  <S.TooltipContainer {...props}>
    {children}
    <S.Tooltip as={TooltipComponent} $position={position} $isOneLiner={isOneLiner} role="tooltip">
      {content}
    </S.Tooltip>
  </S.TooltipContainer>
)

Tooltip.defaultProps = {
  isOneLiner: true,
  position: 'top',
  TooltipComponent: 'div',
}
Tooltip.propTypes = {
  /** Content on which you want the tooltip. */
  children: PropTypes.node.isRequired,
  /** Content of the tooltip. Doesn't show anything if empty. */
  content: PropTypes.node.isRequired,
  /** If the tooltip is on one line or not. Set to false for complex tooltips. */
  isOneLiner: PropTypes.bool,
  /** Where stands the tooltip. */
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Component used for content. */
  TooltipComponent: PropTypes.oneOfType(PropTypes.string, PropTypes.component),
}

export default Tooltip
