import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Content = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
`

export const Link = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: ${g(3)};
  }
`

export const Sharer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    display: flex;
  }
`

export const Title = styled.div`
  margin-bottom: ${g(1)};
`
