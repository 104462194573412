import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-listformat/polyfill'
import '@formatjs/intl-listformat/locale-data/fr'

interface Account {
  firstName: string
  lastName: string
}

export const truncateName = ({ firstName, lastName }: Account) => `${firstName} ${lastName.slice(0, 1)}.`

export const truncateNames = (accounts: Account[]) => {
  const formatter = new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' })

  if (accounts.every(({ lastName }) => lastName === accounts[0].lastName)) {
    return formatter.format([
      ...accounts.slice(0, -1).map(({ firstName }) => firstName),
      ...accounts.slice(-1).map(truncateName),
    ])
  }

  return formatter.format(accounts.map(truncateName))
}
