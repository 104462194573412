import { SentenceFieldSimple } from '@pretto/zen/atoms/sentenceFields/SentenceFieldSimple/SentenceFieldSimple'
import { TextField } from '@pretto/zen/atoms/textFields/TextField/TextField'
import { TextFieldDefault } from '@pretto/zen/reveal/atoms/textfields/TextFieldDefault/TextFieldDefault'

import { useMemo, useState } from 'react'

import { UseField, useFieldController } from './useFieldController'

export const useTextField: UseField = ({ defaultValue = '', inputProps, type = 'block', validators, ...props }) => {
  const [value, setValue] = useState(defaultValue)

  const { stateProps, ...fieldControls } = useFieldController(value, inputProps, validators)

  const handleChange = (value: string) => {
    setValue(value)
  }

  const fieldProps = {
    ...props,
    ...stateProps,
    inputProps,
    onChange: handleChange,
    value,
  }

  const component = useMemo(() => {
    switch (type) {
      case 'block_deprecated':
        return <TextField {...fieldProps} />

      case 'inline':
        return <SentenceFieldSimple {...fieldProps} />

      case 'block':
      default:
        return <TextFieldDefault {...fieldProps} />
    }
  }, [fieldProps])

  return { ...fieldControls, component, setValue, value }
}
