import Card from '@pretto/bricks/components/cards/Card'
import Avatar from '@pretto/bricks/components/images/Avatar'
import { g, ng } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const TipBubble = ({ children, avatarSrc, isStandalone, ...props }) => (
  <Bubble {...props}>
    {!isStandalone && <Lightbulb avatarSrc={avatarSrc} />}
    <BubbleText $isStandalone={isStandalone}>{children}</BubbleText>
  </Bubble>
)

TipBubble.defaultProps = {
  isStandalone: false,
}

TipBubble.propTypes = {
  avatarSrc: PropTypes.string,
  children: PropTypes.node,
  isStandalone: PropTypes.bool,
}

export const Bubble = styled.div`
  display: flex;
  margin-top: ${g(4)};
  margin-bottom: ${g(2)};
`

export const Lightbulb = styled(Avatar).attrs(({ avatarSrc, size }) => ({
  size: size || 'x-small',
  src: avatarSrc || 'v1621597625/bricks/static-assets/illustrations/lightbulb_man_close.svg',
}))`
  ${({ avatarSrc }) =>
    !avatarSrc &&
    css`
      padding: ${g(0.5)};
      background-color: ${({ theme }) => theme.colors.primary4};
    `}
  flex-shrink: 0;
`

export const BubbleText = styled(Card).attrs(({ $isStandalone }) =>
  !$isStandalone
    ? {
        arrow: 'left',
        arrowPosition: { left: ng(-3.5), top: ng(1) },
        arrowSize: 2,
      }
    : null
)`
  flex-basis: 100%;
  padding: ${g(2)};
  margin-left: ${({ $isStandalone }) => g($isStandalone ? 7 : 2)};
`

export default TipBubble
