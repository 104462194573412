import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Page = styled.div`
  margin-bottom: ${g(8)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(2)} auto ${g(12)};
    max-width: ${breakpoints.tablet};
  }
`
export const Points = styled.div`
  margin-bottom: ${g(7)};
`
export const Delete = styled.div`
  margin-bottom: ${g(7)};
`
export const Contact = styled.div`
  margin-top: ${g(5)};
  margin-bottom: ${g(2)};
`
export const Actions = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  @media screen and (min-width: ${breakpoints.laptop}) {
    right: calc(50vw - ${breakpoints.tablet} / 2 - ${g(3)});
    left: calc(50vw - ${breakpoints.tablet} / 2 - ${g(3)});
  }
`
