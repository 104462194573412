export const getCurrentPageForId = (step, id) => {
  const { pages } = step

  if (!id) {
    return pages[0]
  }

  const currentPage = pages.find(page => page.id === id)

  if (!currentPage) {
    return pages[0]
  }

  return currentPage
}
