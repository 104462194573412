import { HTTP_STATUS_OK } from '@pretto/app-core/config/constants'

import path from 'path'

export const uploadFileRemote = (
  file: File,
  localId: string,
  projectId: string,
  callbackProgress?: (localId: string, progress: number) => void
) => {
  const extname = path.extname(file.name)
  const basename = path.basename(file.name, extname)
  const filename = `${basename}-${localId}${extname}`

  // Remove non-ASCII chars from key
  // eslint-disable-next-line no-control-regex
  const key = `uploads/${projectId}/${filename}`.replace(/[^\x00-\x7F]/g, '')
  const request = new XMLHttpRequest()

  return new Promise<string>((resolve, reject) => {
    request.onerror = reject

    request.onreadystatechange = () => {
      if (request.readyState !== XMLHttpRequest.DONE) {
        return
      }

      if (request.status === HTTP_STATUS_OK) {
        return resolve(key)
      }

      reject()
    }

    request.upload.onprogress = event => {
      callbackProgress?.(localId, Math.min(event.loaded / event.total, 1))
    }

    request.open('PUT', `https://s3-eu-central-1.amazonaws.com/docs.pretto.fr/${encodeURIComponent(key)}`, true)
    request.setRequestHeader('x-amz-acl', 'bucket-owner-full-control')
    request.send(file)
  })
}
