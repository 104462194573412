import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Section = styled.section`
  & + & {
    margin-top: ${g(6)};
  }
`
export const Title = styled.div`
  ${typo.heading32};
`
export const Blocks = styled.div``
