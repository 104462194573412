import CheckEmailDialog from '@pretto/bricks/app/auth/components/CheckEmailDialog'
import DashboardMandateCard from '@pretto/bricks/app/dashboard/components/DashboardMandateCard'
import type * as Types from '@pretto/bricks/app/dashboard/components/MainSectionFolder'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getUserEmailVerified } from '@pretto/app/src/Auth/lib/helpers'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { signStatus } from '@pretto/app/src/dashboard/lib/signStatus'
import { withProps } from '@pretto/app/src/dashboard/lib/withProps'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import type { Account } from '@pretto/app/src/dashboard/types/signature'
import { CheckHandwrite, ExclamationMarkTriangle, InfoCircle } from '@pretto/picto'

import { useHistory } from 'react-router-dom'

import { useMandateCardQuery } from './mandate.gateway.graphql'

const getActionUrl = (
  isChargeable: boolean,
  isMandateActionable: boolean,
  isMandateSigned: boolean,
  isSepaSigned: boolean
) => {
  if (isChargeable && isMandateSigned) {
    if (!isSepaSigned) return '/mandate/sepa'

    return null
  }

  if (isMandateActionable) return '/mandate/introduction'

  return null
}

const MandateAwaitingOtherMessage = () => {
  const { signOut } = useAuth()

  const { push } = useHistory()

  const { accounts } = useUser()

  const comortgagor = accounts.find(({ isMine }: Account) => !isMine)

  const handleConnectComortgagor = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()

    signOut(() => push(`/login?email=${encodeURIComponent(comortgagor.email)}`))
  }

  return (
    <>
      Vous avez bien signé votre mandat. {comortgagor.name} doit signer le sien en{' '}
      <span onClick={handleConnectComortgagor}>se connectant à son compte</span>.
    </>
  )
}

const Mandate: React.FC<CardProps> = ({ onActionClick, onDialogRequest }) => {
  const { data, loading } = useMandateCardQuery()

  const { accounts, hasComortgagor } = useUser()

  if (loading) {
    return null
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    const isEmailVerified = getUserEmailVerified()

    if (!isEmailVerified) {
      event.preventDefault()

      onDialogRequest({
        component: CheckEmailDialog,
        title: 'Confirmez votre adresse email',
      })
    }

    if (actionUrl) {
      onActionClick(actionUrl)
    }
  }

  const mandateStatus = data?.deal?.mandateStatus

  const isChargeable = !!data?.brokerage_fees?.amount
  const isMandateSigned = data?.deal?.mandateStatus?.signed ?? false
  const isSepaSigned = data?.deal?.sepaStatus?.signed ?? false

  const mortgagors = accounts.map(({ email }: { email: string }, index: number) => {
    const signature = data?.deal?.mandateStatus?.signatures?.[index]?.signedAt

    return {
      email,
      href: signature ? null : '/account',
      status: !signature ? 'pending' : 'signed',
    }
  })

  const computedSignStatus = signStatus({ accounts, hasComortgagor, mandateStatus })

  const isMandateActionable = ['SOLO_AWAITING', 'DUO_AWAITING_ME', 'DUO_AWAITING_BOTH'].includes(computedSignStatus)

  const actionUrl = getActionUrl(isChargeable, isMandateActionable, isMandateSigned, isSepaSigned)

  const props = withProps<object, Types.PropsAlert>({}, [
    {
      alertMessage:
        'Vous ou votre co-emprunteur devez remplir l’autorisation de prélèvement SEPA afin de finaliser la signature de votre mandat.',
      condition: isMandateSigned && !isSepaSigned,
      picto: InfoCircle,
    },
    {
      alertMessage: (
        <>
          Si vous vous rendez compte que votre email de contact est incorrect, vous pouvez demander sa modification en
          contactant votre expert.
        </>
      ),
      condition: ['DUO_AWAITING_BOTH', 'SOLO_AWAITING'].includes(computedSignStatus),
      picto: InfoCircle,
    },
    {
      alertMessage: 'Votre co-emprunteur a signé, à vous de jouer !',
      alertType: 'success',
      condition: computedSignStatus === 'DUO_AWAITING_ME',
      picto: CheckHandwrite,
    },
    {
      alertMessage: <MandateAwaitingOtherMessage />,
      alertType: 'success',
      condition: computedSignStatus === 'DUO_AWAITING_OTHER',
      picto: CheckHandwrite,
    },
    {
      alertMessage: 'Il y a un problème avec votre mandat, veuillez contacter votre expert.',
      alertType: 'error',
      condition: computedSignStatus === 'INVALID_MANDATE',
      picto: ExclamationMarkTriangle,
    },
  ])

  return (
    <DashboardMandateCard
      {...props}
      {...(actionUrl
        ? {
            actionUrl,
            onActionClick: handleActionClick,
          }
        : {})}
      isMandateSigned={isMandateSigned}
      isSepaSigned={isSepaSigned}
      mortgagors={mortgagors}
    />
  )
}

export const config: Config = {
  component: Mandate,
  name: 'mandate',
}
