import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Action = styled.div`
  margin-top: ${g(3)};
`

export const Emoji = styled.div`
  margin-right: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(3)};
  }
`

export const Safety = styled.div`
  display: flex;
`
