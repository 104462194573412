import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: ${g(4)};
  background-color: ${({ theme }) => theme.colors.neutral4};
  color: ${({ theme }) => theme.colors.neutral1};
  border-radius: ${g(1)};
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Illustraton = styled(IllustrationComponent).attrs({
  options: { crop: 'scale', height: ng(9, 2), width: ng(9, 2) },
})`
  margin-right: ${g(2)};
`

export const Description = styled.div`
  ${typo.bodyBook16};
  margin-bottom: ${g(2)};
  text-align: start;
`
