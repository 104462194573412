import { breakpoints, g } from '@pretto/bricks/components/layout'
import temporal from '@pretto/bricks/core/utility/temporal'

import { ProgressBar as ProgressBarComponent } from '@pretto/zen/reveal/atoms/progressBars/progressBar/ProgressBar'

import styled from 'styled-components'

import { FileItemProps, FileItemState } from '../../FileItem'

export const Details: React.FC<React.HTMLAttributes<HTMLSpanElement> & FileItemProps> = props => {
  switch (props.state) {
    case FileItemState.Error:
      return <LabelError>Une erreur est survenue</LabelError>

    case FileItemState.Removing:
    case FileItemState.Stale:
    case FileItemState.Uploaded:
      return <LabelIdle>{temporal(props.date).format('D MMM, YYYY • HH:mm')}</LabelIdle>

    case FileItemState.Uploading:
      return <ProgressBar progress={props.progress} />

    default:
      return null
  }
}

const LabelIdle = styled.span`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
  white-space: nowrap;
`

const LabelError = styled(LabelIdle)`
  color: ${({ theme }) => theme.colors.error1};
`

const ProgressBar = styled(ProgressBarComponent)`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: ${g(27)};
  }
`
