import DashboardCardAlert from '@pretto/bricks/app/dashboard/components/DashboardCardAlert'
import DashboardCardFooter from '@pretto/bricks/app/dashboard/components/DashboardCardFooter'
import Content from '@pretto/bricks/components/layout/Content'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { createElement, Fragment, memo } from 'react'

import * as S from './styles'

const DashboardSecondaryCard = ({
  actionLabel,
  actionUrl,
  picto,
  alertMessage,
  alertType,
  children,
  description,
  icon,
  isInProgress,
  onActionClick,
  progressValue,
  title,
}) => (
  <S.Card>
    <>
      {createElement(
        actionUrl ? Link : Fragment,
        actionUrl ? { href: actionUrl, onClick: onActionClick } : null,
        <>
          <Content>
            <S.Content>
              <S.Body>
                <S.Title>
                  <BaseTypo typo="heading32">{title}</BaseTypo>
                  {isInProgress && <S.MeetStatus>En cours</S.MeetStatus>}
                </S.Title>

                {description && (
                  <S.Description>
                    <BaseTypo typo={'bodyBook16'}>{description}</BaseTypo>
                  </S.Description>
                )}
              </S.Body>

              {actionUrl && <S.ActionIcon>{icon ? <S.Picto $picto={icon} /> : <S.Icon />}</S.ActionIcon>}
            </S.Content>

            {!children && (actionLabel || progressValue) && (
              <S.Footer>
                <DashboardCardFooter actionLabel={actionLabel} isPrimary={false} progressValue={progressValue} />
              </S.Footer>
            )}
          </Content>

          {children}
        </>
      )}

      {alertMessage && (
        <S.AlertContainer>
          <DashboardCardAlert picto={picto} alertMessage={alertMessage} alertType={alertType} />
        </S.AlertContainer>
      )}
    </>
  </S.Card>
)

DashboardSecondaryCard.propTypes = {
  /** Label of the primary action button. */
  actionLabel: PropTypes.string,
  /** Url to follow when the card main content is being pressed. */
  actionUrl: PropTypes.string,
  /** picto to be diplayed in the alert. Ignored if alertMessage is null. */
  picto: PropTypes.string,
  /** Message to be displayed in the alert. Defines whether or not an alert should be displayed. */
  alertMessage: PropTypes.string,
  /** Defines the theme of the alert. Ignored if alertMessage is null. */
  alertType: PropTypes.oneOf(['error', 'info', 'success']),
  /** Optional nodes to inject after the card content. */
  children: PropTypes.node,
  /** Description message. */
  description: PropTypes.node,
  /** Icon name to override default arrow right icon. */
  icon: PropTypes.node,
  /** Defines whether or not the card is in progress. */
  isInProgress: PropTypes.bool,
  /** Triggered whenever an action link is being pressed. */
  onActionClick: PropTypes.func.isRequired,
  /** Value of the progress. Defines whether or not the progress bar should be displayed. */
  progressValue: PropTypes.number,
  /** Title. */
  title: PropTypes.string.isRequired,
}

export default memo(DashboardSecondaryCard)
