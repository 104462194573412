export const getPartnerCardProps = (partner: 'selfemployment' | 'lowamount', onPress: (slug: string) => void) => {
  const partnerCardProps = {
    selfemployment: {
      ctaLink: 'https://demo.join-jump.com/?utm_source=pretto&utm_medium=partner',
      ctaTitle: 'Découvrir',
      description: `Découvrez notre partenaire, expert du portage salarial pour${' '}<strong>optimiser votre situation professionnelle</strong> et maximiser vos chances de réaliser votre projet immobilier !`,
      imgPath: 'v1695912211/reveal/static-asssets/illustrations/cropped/House-1.png',
      onPartnerClick() {
        onPress('jump')
      },
      title: 'Consultant ou freelance ?',
    },
    lowamount: {
      ctaLink:
        'https://younited-credit.com/landingpage/pretto-vous-recommande-younited-credit?ead-publisher=Pretto&ead-name=Pretto&ead-location=PRETTO-1x1&ead-creative=clearpix&ead-creativetype=1x1&BusinessProviderCode=PRETTO&utm_source=Pretto&utm_medium=LeadGen&utm_campaign=Pretto',
      ctaTitle: 'Découvrir',
      description: `Découvrez notre partenaire, <strong>spécialiste du crédit instantané</strong>. Réalisez une demande en ligne et obtenez en quelques clics une réponse définitive immédiate.`,
      imgPath: 'v1695912211/reveal/static-asssets/illustrations/cropped/House-1.png',
      onPartnerClick() {
        onPress('younited')
      },
      title: 'Un projet inférieur à 50 000 € ?',
    },
  }

  return partnerCardProps[partner]
}
