import PropTypes from 'prop-types'

import * as S from './styles'

export const SliderSection = ({ children, title, ...props }) => (
  <S.SliderSection {...props}>
    <S.Title>{title}</S.Title>
    <S.Cards>{children}</S.Cards>
  </S.SliderSection>
)

SliderSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}
