import { g, ng } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

interface CalendarBaseComponentProps {
  $isMonoDay: boolean
}

export const Body = styled.main<CalendarBaseComponentProps>`
  border-left: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(2)} ${g(7 / 2)} ${g(1)};

  ${({ $isMonoDay }) =>
    $isMonoDay &&
    css`
      padding: ${g(2)} ${g(2)} ${g(1)};
    `}
`

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  @supports (contain: paint) {
    contain: paint;
    border-bottom-left-radius: ${g(1)};
    border-bottom-right-radius: ${g(1)};
  }

  &:after {
    display: block;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-left: 1px solid ${({ theme }) => theme.colors.neutral3};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral3};
    height: ${g(1)};
    position: relative;
    z-index: 1;
    transform: translateZ(1px); // fixes contain glitch on Safari

    @supports (contain: paint) {
      border-bottom-left-radius: ${g(1)};
      border-bottom-right-radius: ${g(1)};
    }
  }
`

export const Days = styled.div`
  display: flex;
  gap: ${g(1)};
`

export const Date = styled.div`
  ${typography.bodyBold16};
  flex: 1;
  padding: ${g(1)} ${g(0.5)};
  text-align: center;
`

interface DatesProps {
  $isNavigationDisabled: boolean
}

export const Dates = styled.div<DatesProps>`
  align-items: center;
  display: flex;
  min-height: ${g(7)};

  ${({ $isNavigationDisabled }) =>
    $isNavigationDisabled &&
    css`
      padding: 0px ${g(3)};
    `}
`

export const Footer = styled.footer`
  border-left: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(2)} 0px ${g(3)};
  text-align: center;
`

interface HeaderBaseProps {
  $isHeaderRowSticky: boolean
  $overlayVariant: string
}

export const HeaderBase = styled.div<HeaderBaseProps>`
  background-color: ${({ theme, $overlayVariant }) => theme.colors[$overlayVariant]};
  z-index: 1;

  ${({ $isHeaderRowSticky }) =>
    $isHeaderRowSticky &&
    css`
      position: sticky;
    `}
`

export const Header = styled(HeaderBase).attrs({ as: 'header' })<
  CalendarBaseComponentProps & { $headerVariant: string }
>`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-top-left-radius: ${g(1)};
  border-top-right-radius: ${g(1)};
  margin-top: ${g(-3)};
  top: ${g(2)};

  ${({ theme, $isMonoDay, $headerVariant }) => {
    if ($headerVariant) {
      return css`
        background-color: ${theme.colors[$headerVariant]};
      `
    }

    return (
      !$isMonoDay &&
      css`
        background-color: ${theme.colors.neutral4};
      `
    )
  }}
`

export const HeaderOffset = styled(HeaderBase)`
  height: ${g(3)};
  top: 0px;
`

export const HeaderRow = styled.div`
  ${typography.caption12};
  align-items: center;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(2)};
`

interface MaskProps {
  $shouldTruncate: boolean
  $truncatedRows: number
}

export const Mask = styled.div<MaskProps>`
  max-height: ${({ $shouldTruncate, $truncatedRows }) =>
    $shouldTruncate ? `${$truncatedRows * ng(5) + ($truncatedRows - 1) * ng(1)}px` : 'none'};
  overflow: hidden;
`

export const More = styled.button`
  ${typography.linkUnderline12};
  color: inherit;
  cursor: pointer;
`

export const NavigationArrowLeft = styled.button<CalendarBaseComponentProps>`
  color: inherit;
  height: ${g(7)};
  padding-left: ${g(1)};
  transition: opacity 200ms ease-out 0s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  ${({ $isMonoDay }) =>
    $isMonoDay &&
    css`
      padding-left: ${g(2)};
    `}

  svg {
    display: block;
  }
`

export const NavigationArrowRight = styled(NavigationArrowLeft)`
  padding-left: 0px;
  padding-right: ${g(1)};

  ${({ $isMonoDay }) =>
    $isMonoDay &&
    css`
      padding-right: ${g(2)};
    `}
`

const TABULAR_NUMS_WIDTH_RATIO = 2.90125

export const Slots = styled.div<CalendarBaseComponentProps>`
  display: grid;
  flex: 1;
  gap: ${g(1)};

  ${({ $isMonoDay }) =>
    $isMonoDay &&
    css`
      grid-template-columns: repeat(auto-fill, minmax(${TABULAR_NUMS_WIDTH_RATIO * ng(2) + ng(2)}px, 1fr));
    `}
`

export const DayShortLabel = styled.span`
  width: 35px;
  display: inline-block;
`
export const DateLabel = styled.span`
  ${typography.isNumber};
`
