import Responsive from '@pretto/bricks/components/utility/Responsive'

import { SentenceObject, SentencesGroupProps } from '@pretto/app/bricksTypes/SentencesGroup.types'
import { Picto } from '@pretto/picto'

import * as S from './BlockSentences.styles'

export interface BlockSentencesProps extends SentencesGroupProps {
  extraSentence?: SentenceObject[]
  picto?: Picto
}

export const SentencesLine = ({ picto, sentences }: Omit<BlockSentencesProps, 'extraSentence'>): JSX.Element => (
  <S.SentencesLine>
    <Responsive min="tablet">
      <S.Picto $picto={picto} />
    </Responsive>
    <S.Sentences sentences={sentences} />
  </S.SentencesLine>
)

export const BlockSentences = ({ extraSentence, picto, sentences }: BlockSentencesProps): JSX.Element => (
  <S.Block>
    <SentencesLine picto={picto} sentences={sentences} />
    {extraSentence && (
      <div>
        <S.Divider />
        <SentencesLine sentences={extraSentence} />
      </div>
    )}
  </S.Block>
)
