import TextArea from '@pretto/bricks/components/form/TextArea'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import TooltipComponent from '@pretto/bricks/components/utility/Tooltip'
import * as typo from '@pretto/bricks/core/typography'

import { BlockTextareaProps } from '@pretto/app/src/purchaseOffer/config/types'
import { InfoCircle } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Block = styled.div`
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(3)};
`
export const Label = styled.div`
  display: flex;
  align-items: center;
`
export const LabelText = styled.div`
  flex-grow: 1;
`
const tag = css`
  padding: ${g(1)} ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral4};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-left: ${g(2)};
`
interface TagProps {
  $tooltip: BlockTextareaProps['tooltip']
}
export const TagSmall = styled.div<TagProps>`
  ${typo.caption12};
  ${tag};
  border-radius: ${g(5)};

  ${({ $tooltip }) =>
    $tooltip &&
    css`
      margin-right: ${g(2)};
    `}
`
export const Tag = styled.div<TagProps>`
  ${tag};
  border-radius: 4px;

  ${({ $tooltip }) =>
    $tooltip &&
    css`
      margin-right: ${g(2)};
    `}
`
interface PictoProps {
  $picto: BlockTextareaProps['picto']
}
export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  width: ${g(3)};
  height: ${g(3)};
  margin-right: ${g(2)};
`
export const Textarea = styled(TextArea)`
  margin-top: ${g(2)};
  width: 100%;
`
export const ToolTipContent = styled.div`
  ${typo.bodyBook16};
  white-space: normal;
  max-width: ${g(45)};
  width: calc(100vw - ${g(18)});

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(30)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: ${g(45)};
  }
`
export const Tooltip = styled(TooltipComponent).attrs({ TooltipComponent: ToolTipContent })`
  display: flex;
  align-items: center;
`
export const ToolTipPicto = styled(InfoCircle)`
  width: ${g(2)};
  height: ${g(2)};
`
