import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import * as S from './styles'

const DECIMALS = 2

export const getInfos = ({
  isPositive,
  loanAmount,
  payment,
  projectKind,
  propertyPrice,
  range,
  rate,
  savings,
  totalCost,
}) => {
  switch (projectKind) {
    case 'capacity':
      return {
        detailsText: 'Détail du calcul',
        infos: [
          {
            label: 'Estimation basse',
            value: range[0],
          },
          {
            label: 'Estimation haute',
            value: range[1],
          },
        ],
        mainInfo: {
          title: 'Vous pouvez acheter un bien d’une valeur de :',
          value: formatNumber(propertyPrice, { suffix: '€' }),
        },
      }
    case 'purchase':
      return {
        detailsText: 'Détail de l’offre',
        get infos() {
          const baseInfos = [
            {
              label: 'Montant emprunté',
              value: formatNumber(loanAmount, { suffix: '€' }),
            },
            {
              label: 'Coût total du crédit',
              value: formatNumber(totalCost, { suffix: '€' }),
            },
          ]

          return [
            {
              color: 'primary2',
              isBold: true,
              label: 'Taux',
              value: formatNumber(rate, { decimals: DECIMALS, suffix: '%' }),
            },
            ...baseInfos,
          ]
        },
        mainInfo: {
          title: 'La meilleure offre',
          value: (
            <S.Payment>
              <span>{formatNumber(payment, { suffix: '€' })}</span>
              <S.PaymentUnit>/ mois</S.PaymentUnit>
            </S.Payment>
          ),
        },
      }
    case 'renegotiation':
      return {
        detailsText: 'Détail de l’offre',
        infos: [
          {
            label: 'Montant emprunté',
            value: formatNumber(loanAmount, { suffix: '€' }),
          },
          {
            color: isPositive ? 'primary1' : 'error1',
            label: isPositive ? 'Montant économisé' : 'Surcoût',
            value: formatNumber(Math.abs(savings), { suffix: '€' }),
          },
        ],
        mainInfo: {
          title: 'La meilleure offre',
          value: formatNumber(rate, { decimals: DECIMALS, suffix: '%' }),
        },
      }
    default:
      return null
  }
}

export const getScoreWithColors = ({ variant, ...score } = {}) => {
  switch (variant) {
    case 'success':
      return {
        ...score,
        color: 'primary2',
        details: {
          bgColor: 'primary4',
          color: 'primary1',
        },
      }
    case 'warning':
      return {
        ...score,
        color: 'error1',
        details: {
          bgColor: 'error2',
          color: 'error1',
        },
      }
    default:
      return {
        ...score,
        details: {},
      }
  }
}
