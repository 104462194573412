import { Edge, Graph, Route } from '@pretto/app/src/Sentences/v2/lib/graph/Graph'
import * as v from '@pretto/app/src/Sentences/v2/lib/validators/context'

import { allPass, anyPass } from 'ramda'

// Introduction
const structureRoute = new Route('structure', '/project/renegotiation/introduction/structure')
const propertyUsageRoute = new Route('propertyUsage', '/project/renegotiation/introduction/propertyUsage')

export const renegotiationGraph = new Graph('renegotiationGraph', structureRoute)

renegotiationGraph.addRoute(propertyUsageRoute)

renegotiationGraph.addEdge(new Edge(structureRoute, propertyUsageRoute, anyPass([v.isSolo, v.isDuo])))

// Lodging
const ageChildrenRoute = new Route('ageChildren', '/project/renegotiation/lodging/ageChildren')
const liveTogetherRoute = new Route('liveTogether', '/project/renegotiation/lodging/liveTogether')
const addressRoute = new Route('address', '/project/renegotiation/lodging/address')
const situationRoute = new Route('situation', '/project/renegotiation/lodging/situation')

renegotiationGraph.addRoute(ageChildrenRoute)
renegotiationGraph.addRoute(liveTogetherRoute)
renegotiationGraph.addRoute(addressRoute)
renegotiationGraph.addRoute(situationRoute)

renegotiationGraph.addEdge(new Edge(propertyUsageRoute, ageChildrenRoute, v.hasPropertyUsage))

renegotiationGraph.addEdge(
  new Edge(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

renegotiationGraph.addEdge(
  new Edge(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo, v.isNotPrimaryUsage]))
)

renegotiationGraph.addEdge(
  new Edge(liveTogetherRoute, addressRoute, allPass([v.hasLiveTogetherCompleted, v.isNotPrimaryUsage]))
)

renegotiationGraph.addEdge(new Edge(addressRoute, situationRoute, v.hasAddress))

// Loan
const bankRoute = new Route('bank', '/project/renegotiation/loan/bank')
const paymentRoute = new Route('payment', '/project/renegotiation/loan/payment')
const rateRoute = new Route('rate', '/project/renegotiation/loan/rate')
const dateDurationRoute = new Route('dateDuration', '/project/renegotiation/loan/dateDuration')

renegotiationGraph.addRoute(bankRoute)
renegotiationGraph.addRoute(paymentRoute)
renegotiationGraph.addRoute(rateRoute)
renegotiationGraph.addRoute(dateDurationRoute)

renegotiationGraph.addEdge(new Edge(situationRoute, bankRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale])))

renegotiationGraph.addEdge(
  new Edge(ageChildrenRoute, bankRoute, allPass([v.isSolo, v.hasAge, v.hasChildrenNumber, v.isPrimaryUsage]))
)

renegotiationGraph.addEdge(
  new Edge(liveTogetherRoute, bankRoute, allPass([v.hasLiveTogetherCompleted, v.isPrimaryUsage]))
)

renegotiationGraph.addEdge(new Edge(bankRoute, paymentRoute, allPass([v.hasBank, v.hasInsuranceType])))

renegotiationGraph.addEdge(
  new Edge(
    paymentRoute,
    rateRoute,
    anyPass([
      allPass([v.hasRenegotiationPayment, v.isBankGroupInsurance]),
      allPass([v.hasRenegotiationPaymentWithoutInsurance, v.isDelegatedInsurance, v.hasRenegotiationInsurancePayment]),
    ])
  )
)

renegotiationGraph.addEdge(new Edge(rateRoute, dateDurationRoute, v.hasRate))

// Occupation
const sectorRoute = new Route('sector', '/project/renegotiation/occupation/sector')

renegotiationGraph.addRoute(sectorRoute)

renegotiationGraph.addEdge(new Edge(dateDurationRoute, sectorRoute, allPass([v.hasStartYear, v.hasEndYear])))

// Income
const salaryRoute = new Route('salary', '/project/renegotiation/income/salary')
const bonusRoute = new Route('bonus', '/project/renegotiation/income/bonus')
const revenueRoute = new Route('revenue', '/project/renegotiation/income/revenue')

renegotiationGraph.addRoute(salaryRoute)
renegotiationGraph.addRoute(bonusRoute)
renegotiationGraph.addRoute(revenueRoute)

renegotiationGraph.addEdge(new Edge(sectorRoute, salaryRoute, v.isOccupationFilled))
renegotiationGraph.addEdge(new Edge(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
renegotiationGraph.addEdge(new Edge(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
renegotiationGraph.addEdge(new Edge(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route('comortgagorAddress', '/project/renegotiation/lodging-comortgagor/address')
const comortgagorSituationRoute = new Route(
  'comortgagorSituation',
  '/project/renegotiation/lodging-comortgagor/situation'
)

renegotiationGraph.addRoute(comortgagorAddressRoute)
renegotiationGraph.addRoute(comortgagorSituationRoute)

renegotiationGraph.addEdge(
  new Edge(revenueRoute, comortgagorAddressRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether]))
)

renegotiationGraph.addEdge(new Edge(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress))

// Comortgagor Occupation
const comortgagorSectorRoute = new Route('comortgagorSector', '/project/renegotiation/occupation-comortgagor/sector')

renegotiationGraph.addRoute(comortgagorSectorRoute)

renegotiationGraph.addEdge(
  new Edge(revenueRoute, comortgagorSectorRoute, allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether]))
)

renegotiationGraph.addEdge(
  new Edge(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route('comortgagorSalary', '/project/renegotiation/income-comortgagor/salary')
const comortgagorBonusRoute = new Route('comortgagorBonus', '/project/renegotiation/income-comortgagor/bonus')
const comortgagorRevenueRoute = new Route('comortgagorRevenue', '/project/renegotiation/income-comortgagor/revenue')

renegotiationGraph.addRoute(comortgagorSalaryRoute)
renegotiationGraph.addRoute(comortgagorBonusRoute)
renegotiationGraph.addRoute(comortgagorRevenueRoute)

renegotiationGraph.addEdge(new Edge(comortgagorSectorRoute, comortgagorSalaryRoute, v.isCoMortgagorOccupationFilled))

renegotiationGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

renegotiationGraph.addEdge(
  new Edge(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

renegotiationGraph.addEdge(new Edge(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled))

// Charges
const creditsRoute = new Route('credits', '/project/renegotiation/credits/credits')
const childSupportRoute = new Route('childSupport', '/project/renegotiation/credits/childSupport')

const comortgagorChildSupportRoute = new Route(
  'comortgagorChildSupport',
  '/project/renegotiation/credits/comortgagorChildSupport'
)

renegotiationGraph.addRoute(creditsRoute)
renegotiationGraph.addRoute(childSupportRoute)
renegotiationGraph.addRoute(comortgagorChildSupportRoute)

renegotiationGraph.addEdge(new Edge(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
renegotiationGraph.addEdge(new Edge(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo])))
renegotiationGraph.addEdge(new Edge(creditsRoute, childSupportRoute, v.isCreditsFilled))

renegotiationGraph.addEdge(
  new Edge(childSupportRoute, comortgagorChildSupportRoute, allPass([v.isNotSolo, v.isChildSupportFilled]))
)

// Renegotiation
const estimatedPriceRoute = new Route('renegotiation', '/project/renegotiation/property-renegotiation/estimatedPrice')

renegotiationGraph.addRoute(estimatedPriceRoute)

renegotiationGraph.addEdge(
  new Edge(childSupportRoute, estimatedPriceRoute, allPass([v.isSolo, v.isChildSupportFilled]))
)

renegotiationGraph.addEdge(
  new Edge(comortgagorChildSupportRoute, estimatedPriceRoute, v.isCoMortgagorChildSupportFilled)
)

// Rate alert
const wantsRateAlertRoute = new Route('wantsRateAlert', '/project/renegotiation/rate-alert/wantsRateAlert')

renegotiationGraph.addRoute(wantsRateAlertRoute)

renegotiationGraph.addEdge(
  new Edge(estimatedPriceRoute, wantsRateAlertRoute, allPass([v.hasRenegotiationEstimatedValue, v.isVisibleRateAlert]))
)

// Simulation
const simulationRoute = new Route('simulation', '/simulation')

renegotiationGraph.addRoute(simulationRoute)

renegotiationGraph.addEdge(
  new Edge(estimatedPriceRoute, simulationRoute, allPass([v.hasRenegotiationEstimatedValue, v.isNotVisibleRateAlert]))
)

renegotiationGraph.addEdge(new Edge(wantsRateAlertRoute, simulationRoute, v.isRateAlertFilled))
