import PropTypes from 'prop-types'
import { createElement } from 'react'

const BaseComponent = ({ type, ...props }) => createElement(type, props)

BaseComponent.propTypes = {
  type: PropTypes.string,
}

BaseComponent.defaultProps = {
  type: 'div',
}

export default BaseComponent
