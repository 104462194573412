import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import * as S from './styles'

const HeaderPrettoSearch = ({ version = '1', ...props }) => {
  return (
    <S.Container {...props}>
      <S.Image $version={version} />
      <S.Bloc $version={version}>
        <S.LogoPretto />
        <S.Text>{<Trans i18nKey="prettoSearchHeader.message" components={{ Strong: <strong /> }} />}</S.Text>
      </S.Bloc>
    </S.Container>
  )
}

HeaderPrettoSearch.propTypes = {
  version: PropTypes.oneOf(['1', '2']),
}

export default HeaderPrettoSearch
