import PreLeadPlaceholderCard from '@pretto/bricks/app/pre-lead/components/PreLeadPlaceholderCard'
import SectionProjectEmpty from '@pretto/bricks/app/pre-lead/components/SectionProjectEmpty'
import SectionProjectError from '@pretto/bricks/app/pre-lead/components/SectionProjectError'
import SectionRate from '@pretto/bricks/app/pre-lead/components/SectionRate'

import { CARDS } from '@pretto/app/src/PreLead/Containers/ProjectCards/config/cards'

import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useContext } from 'react'

import { useUser } from '../../../User/Containers/UserProvider'
import { PreLeadContext } from '../PreLeadPage'

const CARD_KIND = 'purchase'

export const QUERY = gql`
  query PreLeadPurchaseCard {
    simulation: best_result {
      financing {
        loanAmount
        loanDuration
        mainLoansRate
        payment
      }
      project {
        purchase {
          build_price
          land_price
          property_price
          works_price
        }
        good {
          property_kind
          usage
        }
        contribution
      }
    }
  }
`
const PurchaseCard = ({ data }) => {
  const { onTrackProjectCard } = useContext(PreLeadContext)
  const { hasFeatureAccess } = useUser()

  if (!data.simulation) {
    const handleClickPurchaseError = () => {
      onTrackProjectCard(CARD_KIND, 'error')
    }

    const props = {
      href: '/project/purchase/introduction',
      onEdit: handleClickPurchaseError,
      seeMoreHref: '/simulation',
      text: `Aucune offre de financement ne correspond à votre dernière simulation.
      Votre apport n’est pas assez élevé ou le bien que vous convoitez est trop cher par rapport à votre capacité d’achat.`,
      type: 'simulation',
    }

    return <SectionProjectError {...props} />
  }

  const {
    financing: { loanAmount, loanDuration, mainLoansRate, payment },
    project: {
      contribution,
      good: { usage },
      purchase: {
        build_price: buildPrice,
        land_price: landPrice,
        property_price: propertyPrice,
        works_price: worksPrice,
      },
    },
  } = data.simulation

  const handleClickPurchase = () => {
    onTrackProjectCard(CARD_KIND, 'success')
  }

  const props = {
    amount: loanAmount,
    buildPrice,
    contribution,
    duration: loanDuration / 12,
    isAppointmentAvailable: !hasFeatureAccess(['NO_APPOINTMENT']),
    href: '/project/purchase/introduction',
    landPrice,
    onEdit: handleClickPurchase,
    payment,
    propertyPrice,
    rate: mainLoansRate,
    usage,
    worksPrice,
  }

  return <SectionRate {...props} />
}
PurchaseCard.propTypes = {
  data: PropTypes.shape({
    simulation: PropTypes.object,
  }),
}

const usePurchaseProjectCard = props => {
  const { data, loading } = useQuery(QUERY)

  const {
    user: { isPropertyFound },
  } = useUser()
  const { onTrackProjectCard } = useContext(PreLeadContext)

  if (isPropertyFound) {
    if (loading) {
      return {
        component: PreLeadPlaceholderCard,
      }
    }

    const purchaseCardProps = {
      ...props,
      data,
    }

    return {
      component: PurchaseCard,
      data,
      isLoaded: true,
      props: purchaseCardProps,
    }
  }

  const handleClickPurchase = () => {
    onTrackProjectCard(CARD_KIND, 'default')
  }

  const type = 'calculateRate'

  return {
    component: SectionProjectEmpty,
    isLoaded: true,
    props: {
      ...props,
      // TODO: check url when land, construction and good routes are merged
      cards: CARDS[type],
      href: '/project/purchase/introduction',
      onEdit: handleClickPurchase,
      type,
    },
  }
}

export default usePurchaseProjectCard
