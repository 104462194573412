interface SanitizeDateString {
  format: string
  value: string
}

export const sanitizeDateString = ({ format, value }: SanitizeDateString): string => {
  const match = format.match(/0/g)

  if (!match) {
    throw new Error('Invalid format')
  }

  return value.match(/[\d]/g)?.slice(0, match.length).join('') ?? ''
}
