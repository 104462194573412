import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'

import { getAuthToken, getFrontHMAC, getUserEmail } from '../Auth/lib/helpers'
import { isEmbedded } from '../config/domain'
import { getItem } from '../config/itemStorage'

const FRONT_CHAT_TIMER = 2000
const frontChatBubbleElm = document.getElementById('front-chat-iframe')

const controller = {
  __identity: null,
  isHidden: isEmbedded(),

  close() {
    window.FrontChat('hide')
  },
  hide() {
    if (frontChatBubbleElm) {
      frontChatBubbleElm.style.display = 'none'
    }
  },
  init(properties = {}, useDefaultLauncher = true) {
    if (isEmbedded()) return
    const userHash = getFrontHMAC()
    const email = getUserEmail()
    this.isHidden = false

    window.FrontChat('init', {
      ...(userHash ? { userHash } : {}),
      ...(email ? { email } : {}),
      ...properties,
      chatId: process.env.FRONT_CHAT,
      useDefaultLauncher,
    })
  },
  open() {
    this.show()
    window.FrontChat('show')
  },
  reset() {
    this.close()

    Cookies.remove('fcaid')
    Cookies.remove('fccid')
    Cookies.remove('fcuid')
  },
  show() {
    if (frontChatBubbleElm) {
      frontChatBubbleElm.style.display = 'block'
    }
  },
  update(identity) {
    if (!!this.__identity && !!getAuthToken() && (!('userHash' in this.__identity) || !('name' in this.__identity))) {
      return setTimeout(() => {
        window.FrontChat('identity', identity)
      }, FRONT_CHAT_TIMER)
    }

    this.__identity = identity
    return this.init(identity)
  },
  sleep() {
    this.isHidden = true
    this.hide()
  },
  activate() {
    this.isHidden = false
    this.show()
  },
  navigate() {
    if (this.isHidden) {
      this.hide()
    } else {
      this.show()
    }
  },
  kill() {
    window.FrontChat('shutdown')
  },
}

const Front = (action, ...args) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`[debug] ${action}`, args)
  }

  // C'est degeu mais pas d'autre idée pour le moment
  if (getItem('HIDE_IMMO_ALERT_SENTENCE')) {
    return
  }

  try {
    controller[action]?.(...args)
  } catch (error) {
    Sentry.captureException(error)
  }
}

if (process.env.NODE_ENV === 'development') {
  window.__Front = Front
}

export default Front
