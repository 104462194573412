import Button from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Image from '@pretto/bricks/website/shared/components/Image'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.accent4};
  border-radius: ${g(1)};
  padding: ${g(3)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'headline16' })`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }
`

export const Cta = styled(Button)`
  margin-top: ${g(2)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`

export const Illu = styled(Image).attrs({ options: { height: ng(21), width: ng(21) } })`
  display: block;
  height: ${g(19)};
  width: ${g(19)};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
    margin-left: ${g(6)};
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(21)};
    width: ${g(21)};
  }
`
