import TextFieldComponent from '@pretto/bricks/components/form/TextField'
import { validateEmail } from '@pretto/bricks/core/utility/form/validateEmail'
import { validatePhone } from '@pretto/bricks/core/utility/form/validatePhone'

import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

const checkEmail = value => {
  if (!validateEmail(value)) {
    return "Cette adresse email n'est pas valide"
  }

  return ''
}

const checkPhone = value => {
  if (!validatePhone(value)) {
    return "Ce n° de téléphone n'est pas valide"
  }

  return ''
}

const checkError = (error, type, value, ...args) => {
  const errorText = error && error(value, ...args)

  if (errorText) {
    return errorText
  }

  switch (type) {
    case 'email':
      return checkEmail(value, ...args)

    case 'tel':
      return checkPhone(value, ...args)

    default:
      return ''
  }
}

export const TextField = ({ error, onChange, type, value, defaultErrorText = '', ...props }) => {
  const [errorText, setErrorText] = useState('')

  const [debouncedErrorText] = useDebounce(errorText, 1000)

  const handleChange = value => {
    onChange(value, (...args) => {
      const errorText = checkError(error, type, value, ...args)

      setErrorText(errorText)

      return errorText !== ''
    })
  }

  const handleCorrect = value => {
    onChange(value)
    setErrorText('')
  }

  return (
    <TextFieldComponent
      {...props}
      errorText={defaultErrorText || debouncedErrorText}
      onChange={handleChange}
      onClickHint={handleCorrect}
      type={type}
      value={value || ''}
      variant={defaultErrorText === '' && debouncedErrorText === '' ? 'primary-1' : 'error'}
    />
  )
}

TextField.propTypes = {
  defaultErrorText: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
}
