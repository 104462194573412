import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Documents: React.FC<CardProps> = ({ defaultComponent: Component, onActionClick }) => {
  const { status } = useUser()

  if (status === 'analysis') {
    const ACTION_URL = '/application/documents?missing=1'

    const handleActionClick = () => {
      onActionClick(ACTION_URL)
    }

    return (
      <Component
        actionLabel="Remplir"
        actionUrl={ACTION_URL}
        description="Notre équipe étudie votre dossier avant de l'envoyer à la banque. Vous pouvez à tout moment envoyer de nouveaux documents si nécessaire."
        onActionClick={handleActionClick}
        title="Vos documents"
      />
    )
  }

  const ACTION_URL = '/application/documents?category=others'

  const handleActionClick = () => {
    onActionClick(ACTION_URL)
  }

  return (
    <Component
      actionLabel="Compléter"
      actionUrl={ACTION_URL}
      description="Votre dossier est validé. Vous pouvez à tout moment envoyer de nouveaux documents si votre expert le demande."
      onActionClick={handleActionClick}
      title="Vos documents"
    />
  )
}

export const config: Config = {
  component: Documents,
  name: 'documents',
}
