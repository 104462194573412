import { breakpoints, g } from '@pretto/bricks/components/layout'

import { defaultDuration, defaultDurationInteger, transition } from '@pretto/zen/reveal/lib/transitionCss'
import { Snackbar as SnackbarComponent } from '@pretto/zen/reveal/main/banners/snackbar/Snackbar'

import type { Notification as NotificationType } from '@pretto/app-core/notifications/types/types'

import { createPortal } from 'react-dom'
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group'
import styled from 'styled-components'

interface NotificationsProps {
  notifications: NotificationType[]
}

export const Notifications: React.FC<NotificationsProps> = ({ notifications }) =>
  createPortal(
    <Container $isContaining={notifications.length > 0}>
      {[...notifications].reverse().map(({ id, message, ...props }) => (
        <Transition key={id} timeout={{ enter: 50, exit: defaultDurationInteger }} unmountOnExit>
          {state => {
            const stateProps = {
              $isAlone: notifications.length < 2,
              $state: state,
            }

            return (
              <Notification {...stateProps}>
                <NotificationContent {...stateProps}>
                  <Snackbar {...props}>{message}</Snackbar>
                </NotificationContent>
              </Notification>
            )
          }}
        </Transition>
      ))}
    </Container>,
    document.body
  )

interface ContainerProps {
  $isContaining: boolean
}

const Container = styled(TransitionGroup)<ContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999999;

  @media screen and (min-width: ${breakpoints.laptop}) {
    top: ${g(8)};
    transform: translateY(${({ $isContaining }) => ($isContaining ? 0 : g(-3))});
    ${({ $isContaining }) =>
      transition({
        propertyName: 'transform',
        duration: $isContaining ? defaultDuration : '0s',
        delay: $isContaining ? '0s' : defaultDuration,
      })};
  }
`

interface StateProps {
  $isAlone: boolean
  $state: TransitionStatus
}

const Notification = styled.div<StateProps>`
  display: grid;
  grid-template-rows: ${({ $state }) => ($state === 'entered' ? '1fr' : '0fr')};
  ${transition({ propertyName: 'grid-template-rows' })};
  width: 100%;
  z-index: ${({ $state }) => ($state === 'entered' ? 1 : 0)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: 80%;
    width: max-content;
  }
`

const NotificationContent = styled.div<StateProps>`
  min-height: 0;
  overflow: ${({ $isAlone, $state }) => ($isAlone && $state !== 'exiting' ? 'hidden' : 'visible')};
  opacity: ${({ $state }) => ($state === 'exiting' ? 0 : 1)};
  ${transition({ propertyName: 'opacity' })};

  @media screen and (min-width: ${breakpoints.laptop}) {
    opacity: ${({ $state }) => ($state === 'entered' ? 1 : 0)};
    overflow: visible;
  }
`

const Snackbar = styled(SnackbarComponent)`
  pointer-events: auto;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(2)};
  }
`
