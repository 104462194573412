// waiting for apollo-client 3+ to handle this properly but
// in the meantime, we delete all root queries to replicate
// an invalidation behaviour
export const invalidateCache = async ({ cache, reFetchObservableQueries }) => {
  Object.keys(cache.data.data).forEach(key => {
    if (/^\$?ROOT_QUERY/i.test(key)) {
      cache.data.delete(key)
    }
  })

  await reFetchObservableQueries()
}
