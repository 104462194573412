import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import { Fragment, memo, useState } from 'react'

import * as S from './styles'

const HOVER_ZDEPTH = 2

const BookingAppointmentSummary = ({
  advisorName,
  date,
  duration,
  editLabel,
  isCancelDisabled,
  isRescheduleDisabled,
  isEditable,
  isHoverable,
  isNow,
  isVisioEnabled,
  isVisio,
  onCancel,
  onReschedule,
  visioUrl,
  visioLabel,
}) => {
  const [isHover, setIsHover] = useState(false)

  const handleHover = isHover => {
    if (!isHoverable) {
      return
    }

    setIsHover(isHover)
  }

  const isLoading = !advisorName || !date || !duration

  return (
    <S.Summary isHoverable={isHoverable}>
      <Card
        format={isHoverable ? ['line', 'large-border'] : 'line'}
        onHover={handleHover}
        variant={isHover ? 'primary-1' : 'neutral-1-20'}
        zDepth={isHover ? HOVER_ZDEPTH : 0}
      >
        <Content>
          <S.Content>
            <S.Information>
              <S.Date>
                <S.Icon>
                  <Icon name="calendar" />
                </S.Icon>

                {isLoading && <S.DatePlaceholder />}
                {!isLoading && <SubHeading>{upperFirst(date)}</SubHeading>}
              </S.Date>

              <S.Details>
                {isLoading ? (
                  <Fragment>
                    <S.Placeholder />
                    <S.Placeholder />
                  </Fragment>
                ) : (
                  <Fragment>
                    Durée : {duration}
                    <br />
                    Par {isVisioEnabled && isVisio ? 'visio ' : 'téléphone '}
                    avec {advisorName}
                    <br />
                    {isVisioEnabled && isVisio && (
                      <>
                        Lien de votre rendez-vous visio : <S.VisioLink href={visioUrl}>{visioLabel}</S.VisioLink>
                      </>
                    )}
                  </Fragment>
                )}
              </S.Details>
            </S.Information>

            {isEditable && <S.Edit>{editLabel}</S.Edit>}
          </S.Content>
        </Content>

        {(onCancel || onReschedule) && (
          <Fragment>
            <Divider isLarge={isHoverable} variant="neutral-1-20" />

            {!isNow && (
              <Content>
                <S.Actions>
                  {onReschedule && (
                    <S.Action>
                      <ButtonSquared
                        disabled={isRescheduleDisabled}
                        href="/booking/appointment"
                        onClick={onReschedule}
                        variant="accent-1"
                      >
                        Déplacer
                      </ButtonSquared>
                    </S.Action>
                  )}

                  {onCancel && (
                    <S.Action>
                      <ButtonSquared disabled={isCancelDisabled} onClick={onCancel} variant="error">
                        Annuler
                      </ButtonSquared>
                    </S.Action>
                  )}
                </S.Actions>
              </Content>
            )}
          </Fragment>
        )}
      </Card>
    </S.Summary>
  )
}

BookingAppointmentSummary.defaultProps = {
  editLabel: 'Modifier',
  isCancelDisabled: false,
  isEditable: false,
  isHoverable: false,
  isRescheduleDisabled: false,
}

BookingAppointmentSummary.propTypes = {
  /** Advisor name to be used. */
  advisorName: PropTypes.string,
  /** Human-written date of the the appointment. */
  date: PropTypes.string,
  /** Human-written duration of the appointment. */
  duration: PropTypes.string,
  /** Label to be used when isEditable is true. */
  editLabel: PropTypes.string,
  /** Whether or not the cancel button is disabled. */
  isCancelDisabled: PropTypes.bool,
  /** Whether or not the summary has a edit label. */
  isEditable: PropTypes.bool,
  /** Whether or not the summary can be hover. */
  isHoverable: PropTypes.bool,
  /** google meet ongoing */
  isNow: PropTypes.bool,
  /** Whether or not the reschedule button is disabled. */
  isRescheduleDisabled: PropTypes.bool,
  /** Whether or not the appointment is available with visio */
  isVisioEnabled: PropTypes.bool,
  /** Whether or not the appointment is a google meet */
  isVisio: PropTypes.bool,
  /** Triggered whenever the cancel button is pressed. */
  onCancel: PropTypes.func,
  /** Triggered whenever the reschedule button is pressed. */
  onReschedule: PropTypes.func,
  /** User's name who initially booked the appointement. */
  userName: PropTypes.string,
  visioLabel: PropTypes.string,
  visioUrl: PropTypes.string,
}

export default memo(BookingAppointmentSummary)
