import { useEffect, useRef } from 'react'

const useUpdateEffect = (effect, ...args) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    effect()
  }, ...args)
}

export default useUpdateEffect
