import PreLeadPlaceholderCard from '@pretto/bricks/app/pre-lead/components/PreLeadPlaceholderCard'
import SectionBudget from '@pretto/bricks/app/pre-lead/components/SectionBudget'
import SectionBudgetError from '@pretto/bricks/app/pre-lead/components/SectionBudget/Error'
import SectionProjectEmpty from '@pretto/bricks/app/pre-lead/components/SectionProjectEmpty'

import { getNonFinancingData } from '@pretto/app/src/Capacity/Containers/lib/getNonFinancingData'
import { CARDS } from '@pretto/app/src/PreLead/Containers/ProjectCards/config/cards'

import { gql, useApolloClient, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import { getRangeFromPrice } from '../../../Capacity/utils'
import { useUser } from '../../../User/Containers/UserProvider'
import { PreLeadContext } from '../PreLeadPage'

const CARD_KIND = 'budget'

export const QUERY = gql`
  query PreLeadBudgetCard {
    capacity: new_capacity {
      flags {
        message
      }
      financing {
        loanDuration
        payment
      }
      project {
        contribution
        purchase {
          property_price
        }
      }
    }
  }
`
const BudgetCard = ({ data }) => {
  const { onTrackProjectCard } = useContext(PreLeadContext)

  const error = !data?.capacity?.length > 0

  if (error) {
    return <ErrorBudgetCard />
  }

  const { capacity } = data

  const result = capacity[capacity.length - 1]

  const {
    financing: { loanDuration, payment },
    project: {
      contribution,
      purchase: { property_price },
    },
  } = result

  const handleEdit = () => {
    onTrackProjectCard(CARD_KIND, 'success')
  }

  const props = {
    contribution: contribution ?? 0,
    duration: loanDuration / 12,
    href: '/project/capacity/introduction',
    onEdit: handleEdit,
    payment,
    price: property_price,
    range: getRangeFromPrice(property_price),
  }

  return <SectionBudget {...props} />
}

BudgetCard.propTypes = {
  data: PropTypes.shape({
    capacity: PropTypes.arrayOf(
      PropTypes.shape({
        financing: PropTypes.object,
        project: PropTypes.object,
        scoreIndebtednessRate: PropTypes.object,
      })
    ),
  }).isRequired,
}

const ErrorBudgetCard = () => {
  const client = useApolloClient()
  const { onTrackProjectCard } = useContext(PreLeadContext)
  const [malus, setMalus] = useState()
  const { isEnglishUser } = useUser()

  const compute = async () => {
    const { malus } = await getNonFinancingData(client, isEnglishUser)

    setMalus(malus)
  }

  const handleEditError = () => {
    onTrackProjectCard(CARD_KIND, 'error')
  }

  useEffect(() => {
    compute()
  }, [])

  const props = {
    error: true,
    href: '/project/capacity/introduction',
    malus,
    onEdit: handleEditError,
  }

  return <SectionBudgetError {...props} />
}

const useBudgetProjectCard = props => {
  const { data, loading } = useQuery(QUERY)

  const { isProjectAvailable } = useUser()
  const { onTrackProjectCard } = useContext(PreLeadContext)

  if (isProjectAvailable) {
    if (loading) {
      return {
        component: PreLeadPlaceholderCard,
      }
    }

    const budgetCardProps = {
      ...props,
      data,
    }

    return {
      component: BudgetCard,
      data,
      isLoaded: true,
      props: budgetCardProps,
    }
  }

  const handleClickBudget = () => {
    onTrackProjectCard(CARD_KIND, 'default')
  }

  const type = 'capacity'

  return {
    component: SectionProjectEmpty,
    isLoaded: true,
    props: {
      cards: CARDS[type],
      href: '/project/capacity/introduction',
      onEdit: handleClickBudget,
      type,
    },
  }
}

export default useBudgetProjectCard
