export const Favicon: React.FC = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="16" fill="#0C806B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7147 7.39588C15.4873 6.86804 16.5127 6.86804 17.2853 7.39588L22.9091 11.2377C23.5142 11.6511 23.875 12.3293 23.875 13.0533V19.4619C23.875 20.6833 22.8671 21.6734 21.6237 21.6734H16.5741V18.3784H20.5207V13.6196L16 10.5313L11.4793 13.6196V17.264C11.4793 18.0151 11.7225 19.1784 12.4028 20.1066C13.0215 20.9508 14.0675 21.7049 16 21.7049H16.5741V25H16C12.9742 25 10.9213 23.7246 9.68066 22.032C8.50161 20.4233 8.125 18.5424 8.125 17.264V13.0533C8.125 12.3293 8.48581 11.6511 9.09095 11.2377L14.7147 7.39588Z"
      fill="white"
    />
  </svg>
)
