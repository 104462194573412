import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEn from './translations/en'
import translationFr from './translations/fr'
import isFrenchBrowser from './utils/isFrenchBrowser'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: { translation: translationFr },
      en: { translation: translationEn },
    },
    fallbackLng: 'fr',

    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
  })

// Generate all paths from translation for t('') autocompletion
type Path<T, Prev = ''> = {
  [K in keyof T]: T[K] extends string
    ? `${string & Prev}${string & K}`
    : string & Path<T[K], `${string & Prev}${string & K}.`>
}[keyof T]

type I18NPath = Path<typeof translationFr & typeof translationEn>

const t = (key: I18NPath | string, options?: Record<string, string>) => {
  if (options) return i18n.t(key, options)
  return i18n.t(key)
}

export { t, I18NPath, isFrenchBrowser }
