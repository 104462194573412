import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'

export const decodeValuesForStep = ({ schema, data, params }, userContext) => {
  const decoder = defaultTo(schema.decoder, values => values)
  const fields = funcToItem(schema.fields, { data, params }, userContext)

  const values = fields.reduce((previous, { name, type }) => {
    if (type === 'message') {
      return previous
    }

    const value = get(data, name, null)

    return {
      ...previous,
      [name]: value,
    }
  }, {})

  return decoder(values, data)
}
