export const getPathExtname = path => {
  const match = path?.match(/[\s]*[^\s].*(\.[^/]+)$/)

  if (!match) {
    return ''
  }

  const [, extname] = match

  return extname
}
