import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import PaginationDotsComponent from '@pretto/bricks/components/utility/PaginationDots'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'
import { TrustpilotRate as TrustpilotRateComponent } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import { QuoteOpenPlain } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Bloc = styled.section`
  background-color: ${({ theme }) => theme.colors.neutral4};
  ${grid()};
  padding: ${g(6)} 0 ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(12)} 0 ${g(9)};
  }
`

export const BlocDescription = styled.p`
  ${({ theme }) => theme.typos.body4};

  strong {
    ${({ theme }) => theme.typos.body4Bold};
  }
`

export const BlocFooter = styled.footer`
  ${column([2, 4])};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    padding-top: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

export const BlocFooterDots = styled.nav`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const BlocFooterNavigation = styled.nav`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    gap: ${g(7)};
    margin-bottom: ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(10)};
  }
`

export const BlocHat = styled.aside`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

export const BlocHeader = styled.header`
  ${column([2, 4])};
  margin-bottom: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    margin-bottom: ${g(6)};
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
    margin-bottom: ${g(10)};
  }
`

export const BlocImage = styled(ImageComponent).attrs({ options: { height: ng(15), width: ng(15) } })`
  border-radius: ${g(9 / 2)};
  display: block;
  height: ${g(9)};
  margin: ${g(-2)} 0 ${g(3)};
  width: ${g(9)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(15 / 2)};
    height: ${g(15)};
    margin: ${g(-2)} auto ${g(3)};
    width: ${g(15)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: ${g(-5)} auto ${g(4)};
  }
`

export const BlocTitle = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

interface CarouselProps {
  $numberOfCards: number
}

export const Carousel = styled.div<CarouselProps>`
  ${column([2, 5])};
  display: grid;
  grid-column-gap: ${g(2, 4)};

  &:after,
  &:before {
    content: '';
    min-width: 1px;
    scroll-snap-align: none;
  }

  grid-template-columns: 100vw repeat(${({ $numberOfCards }) => $numberOfCards}, calc(100% - 48px)) 100vw;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 9])};
    grid-column-gap: ${g(3)};
    grid-template-columns: 100vw repeat(${({ $numberOfCards }) => $numberOfCards}, ${g(36)}) 100vw;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 13])};
    grid-template-columns: 100vw repeat(${({ $numberOfCards }) => $numberOfCards}, ${g(50)}) 100vw;
  }
`

export const Comment = styled.p`
  ${({ theme }) => theme.typos.body4};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  margin-bottom: ${g(3)};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Main = styled.div`
  padding: ${g(5)} 0 0 ${g(5)};
`

export const Quote = styled(QuoteOpenPlain)`
  color: ${({ theme }) => theme.colors.neutral4};
  display: block;
  height: ${g(9)};
  margin-bottom: ${g(-9)};
  width: ${g(9)};
`

export const PaginationDots = styled(PaginationDotsComponent).attrs(({ theme }) => ({
  activeVariant: theme.colors.primary1,
  inactiveVariant: theme.colors.neutral3,
}))``

interface ReviewProps {
  $isOutOfBounds: boolean
}

export const Review = styled.div<ReviewProps>`
  ${transition({ propertyName: 'opacity' })};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(1)} ${g(3)} ${g(4)} ${g(2)};
  scroll-snap-align: start;

  @media screen and (min-width: ${breakpoints.tablet}) {
    scroll-snap-align: center;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ $isOutOfBounds }) =>
      $isOutOfBounds &&
      css`
        opacity: 0.5;
      `}
  }
`

export const Reviewer = styled.div`
  ${({ theme }) => theme.typos.caption};
  display: flex;
  align-items: center;
`

export const ReviewerAuthor = styled.span`
  flex-shrink: 1;
  margin-left: ${g(2)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ReviewerDate = styled.span`
  white-space: nowrap;
`

export const Scroll = styled.div`
  ${grid()};
  ${column([1, 6])};
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-padding: ${g(2, 4)};
  scroll-snap-type: x mandatory;
  will-change: scroll-position;

  &::-webkit-scrollbar {
    display: none;
    width: 0px !important;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 10])};
    scroll-padding: unset;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 14])};
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(2)};
`

export const TrustpilotScore = styled(TrustpilotRateComponent)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
