import { BlockTextarea, InputDefault } from '@pretto/app/src/purchaseOffer/config/types'
import { Pen } from '@pretto/picto'

// -- FIELDS --
export const DATE: InputDefault = {
  props: {
    placeholder: '26 juillet 2021',
  },
}

export const ADDRESS: InputDefault = {
  props: {
    placeholder: '10 rue des Messageries',
  },
}

export const ZIPCODE: InputDefault = {
  props: {
    placeholder: '75010',
  },
}

export const CITY: InputDefault = {
  props: {
    placeholder: 'Paris',
  },
}

// -- BLOCKS --
export const DESIGNATION_BLOCK: BlockTextarea = {
  props: {
    label: 'Désignation : ',
    picto: Pen,
    textareaProps: {
      fieldProps: {
        placeholder:
          'Exemple : appartement de 60m², composé de 2 chambres, séjour, d’une cuisine équipée, SDB, avec une cave et une place de parking.',
      },
    },
    tooltip:
      'Décrivez les caractéristiques de l’appartement ou de la maison que vous souhaitez acheter. Vous pouvez reprendre les informations de l’annonce.',
  },
}
