import DashboardProjectDetailsCard from '@pretto/bricks/app/dashboard/components/DashboardProjectDetailsCard'
import { formatNumber, roundNumber } from '@pretto/bricks/core/utility/formatters'

import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { Bank, House } from '@pretto/picto'

import { PAYMENT_RATIO, PAYMENT_ROUND } from '../../../mandate/lib/config'

import { useProjectDetailsCardQuery } from './projectDetails.gateway.graphql'

const USAGE = {
  primary_residence: 'Résidence principale',
  rental_investment: 'Investissement locatif',
  secondary_residence: 'Résidence secondaire',
}

const ProjectDetails = () => {
  const { data, loading } = useProjectDetailsCardQuery({ fetchPolicy: 'network-only' })

  if (loading) {
    return null
  }

  const city = data?.project?.good?.localisation?.city
  const contribution = data?.project?.contribution
  const street = data?.project?.good?.localisation?.street
  const zipcode = data?.project?.good?.localisation?.zipcode

  const duration = ensureNotNil(data?.project?.request?.duration)
  const payment = ensureNotNil(data?.analysis?.payment)
  const priceBeforeFees = ensureNotNil(data?.project?.facts?.price_before_fees)
  const usage = ensureNotNil(data?.project?.good?.usage)

  const durationInMonths = duration / 12
  const projectUsage = USAGE[usage]

  const paymentMin = roundNumber(payment, PAYMENT_ROUND, Math.floor)
  const paymentMax = roundNumber(payment * PAYMENT_RATIO, PAYMENT_ROUND, Math.ceil)

  const props = {
    data: [
      {
        details: [
          {
            propertyTitle: 'Adresse :',
            valueTitle: `${street ?? ''}${street ? ',' : ''} ${zipcode ?? ''} ${city ?? ''}`,
          },
          { propertyTitle: 'Destination :', valueTitle: projectUsage },
        ],
        icon: House,
        title: 'Votre Projet',
      },
      {
        details: [
          { propertyTitle: 'Montant de l’opération :', valueTitle: `${formatNumber(priceBeforeFees)} €` },
          { propertyTitle: 'Apport :', valueTitle: `${formatNumber(contribution ?? 0)} €` },
          { propertyTitle: 'Durée :', valueTitle: `${durationInMonths} ans` },
          {
            propertyTitle: 'Mensualité hors assurance :',
            valueTitle: `${formatNumber(paymentMin)} - ${formatNumber(paymentMax)} € / mois`,
          },
        ],
        icon: Bank,
        title: 'Votre demande de prêt',
      },
    ],
  }

  return <DashboardProjectDetailsCard {...props} />
}

export const config: Config = {
  component: ProjectDetails,
  name: 'projectDetails',
}
