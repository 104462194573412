import { shape } from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Status } from '@pretto/zen/atoms/infos/Status/Status'

import { Picto as PictoType } from '@pretto/picto'

import styled, { css } from 'styled-components'

interface MainContainerProps {
  $isActive?: boolean
}

interface PictoProps {
  $picto: PictoType
}

export const MainContainer = styled(Link)<MainContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${g(3)};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.primary2};
  border: 1px solid ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 0;
  overflow: hidden;

  ${shape(
    css`
      background-size: 100% auto;
      background-position: -${g(15)} -${g(5)};
    `,
    { variant: 'primary1' }
  )}

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
    border-radius: 8px;
    min-height: 216px;
    width: ${g(36)};
    justify-content: space-between;

    ${shape(
      css`
        background-size: 100% auto;
        background-position: -${g(30)} -${g(15)};
      `,
      { variant: 'primary1' }
    )}
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${shape(
      css`
        background-size: 100% auto;
        background-position: -${g(19)} -${g(16)};
      `,
      { variant: 'primary1' }
    )}
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  min-height: ${g(3)};
  min-width: ${g(3)};
`

export const Title = styled.h4`
  ${typo.bodyBold16};
  margin-left: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.headline16};
    margin-top: ${g(2)};
    margin-left: ${g(0)};
  }
`

export const Description = styled.p`
  ${typo.caption12};
  margin-top: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${typo.bodyBook16};
  }
`

export const DescriptionUnderline = styled(Description)`
  text-decoration: underline;
`

export const Footer = styled.div`
  margin-top: ${g(1)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(1)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    gap: ${g(3)};
  }
`

export const StyledStatus = styled(Status)`
  display: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`

export const DocumentProgressContainer = styled(Row)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

export const DocumentProgressCurrent = styled.p`
  ${typo.bodyBold16};
`

export const DocumentProgressTarget = styled.p`
  ${typo.bodyMedium16};
  margin-right: ${g(1)};
`
