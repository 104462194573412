import * as types from '@pretto/app/src/Simulation/lib/types'
import { useScoreZeroMinute } from '@pretto/app/src/Simulation/lib/useScoreZeroMinute'
import { getTypeOfLead } from '@pretto/app/src/lib/getTypeOfLead'

export const getType = async typology => {
  const { appointmentAllowed, pushy } = await useScoreZeroMinute()

  const typeOfLead = getTypeOfLead({ appointmentAllowed, pushy })

  const typePage = pushy && typology !== 'client' ? types.INTERSTITIAL_SUBSCRIBE : types.SIMULATION

  return { typeOfLead, typePage }
}
