import { Star, StarFilled, StarHalf } from '@pretto/picto'

import styled from 'styled-components'

interface RateProps {
  rate: number
}

const formatStar = (value: number): JSX.Element => {
  const boundedValue = Math.min(Math.max(0, value), 3)

  if (boundedValue >= 0.5) {
    return <StarFilled />
  }

  if (boundedValue > 0) {
    return <StarHalf />
  }

  return <Star />
}

export const Rate: React.FC<RateProps> = ({ rate }) => (
  <Stars>{[formatStar(rate), formatStar(rate - 1), formatStar(rate - 2)]}</Stars>
)

const Stars = styled.div`
  display: flex;
  gap: 2px;
`
