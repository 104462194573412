import { useEffect, useState } from 'react'

import { getAccountId } from '../../Auth/lib/helpers'

const newsletter = {
  async get(accountId) {
    const response = await fetch(`${process.env.API_SUBSCRIPTIONS_HOST}/manager/${accountId}`)

    if (!response.ok) {
      throw new Error()
    }

    const data = await response.json()

    return data
  },

  subscribe(accountId) {
    this.update(accountId, true)
  },

  unsubscribe(accountId) {
    this.update(accountId, false)
  },

  async update(accountId, subscription) {
    const response = await fetch(`${process.env.API_SUBSCRIPTIONS_HOST}/manager/${accountId}/edit`, {
      body: JSON.stringify({ sub_newsletter: subscription }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })

    if (!response.ok) {
      throw new Error()
    }
  },
}

const defaultState = {
  isError: null,
  isLoading: true,
  isSubscribed: null,
}

const loadedState = {
  isError: null,
  isLoading: false,
}

const errorState = {
  isError: true,
  isLoading: false,
  isSubscribed: null,
}

export const useNewsletter = () => {
  const [state, setState] = useState(defaultState)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    // Unlogged : no info to fetch about the subscription status
    const accountId = getAccountId()
    if (!accountId) {
      return
    }

    try {
      const { sub_newsletter: isSubscribed = false } = await newsletter.get(accountId)

      setState({ ...loadedState, isSubscribed })
    } catch (error) {
      setState(errorState)
    }
  }

  return {
    ...state,
    async subscribe() {
      try {
        setState(defaultState)

        await newsletter.subscribe(getAccountId())

        setState({ ...loadedState, isSubscribed: true })

        return true
      } catch (error) {
        setState(errorState)

        return false
      }
    },
    async unsubscribe() {
      try {
        setState(defaultState)

        await newsletter.unsubscribe(getAccountId())

        setState({ ...loadedState, isSubscribed: false })

        return true
      } catch (error) {
        setState(errorState)

        return false
      }
    },
  }
}
