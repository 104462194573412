import Cookies from 'js-cookie'

interface AttributionCookie {
  date: Date
  referrer: string
  url: string
}

const COOKIE_NAME_FIRST_CLICK = 'attribution'
const COOKIE_NAME_LAST_CLICK = 'attribution_last_click'

const COOKIE_OPTIONS = {
  domain: '.pretto.fr',
  sameSite: 'None' as const,
  secure: true,
}

const createAttributionCookieFirstClick = (cookie: AttributionCookie) => {
  Cookies.set(COOKIE_NAME_FIRST_CLICK, JSON.stringify(cookie), { ...COOKIE_OPTIONS, expires: 182 })
}

const createAttributionCookieLastClick = (cookie: AttributionCookie) => {
  Cookies.set(COOKIE_NAME_LAST_CLICK, JSON.stringify(cookie), COOKIE_OPTIONS)
}

export const getAttributionCookieValue = () => {
  // eslint-disable-next-line no-console
  console.warn('Deprecated: use getAttributionCookieFirstClickValue() instead')
  return getAttributionCookieFirstClickValue()
}

export const getAttributionCookieFirstClickValue = () => {
  return Cookies.get(COOKIE_NAME_FIRST_CLICK)
}

export const getAttributionCookieLastClickValue = () => {
  return Cookies.get(COOKIE_NAME_LAST_CLICK)
}

export const handleAttributionCookie = ({ isClient }: { isClient: boolean | null }) => {
  const cookie: AttributionCookie = {
    date: new Date(),
    referrer: document.referrer,
    url: window.location.href,
  }

  const attributionCookieLastClickValue = getAttributionCookieLastClickValue()

  if (
    !isClient &&
    (!attributionCookieLastClickValue ||
      isCookieMalformed(attributionCookieLastClickValue) ||
      isCookieOlderThan(attributionCookieLastClickValue, 30))
  ) {
    createAttributionCookieLastClick(cookie)
  }

  if (!isClient && !getAttributionCookieFirstClickValue()) {
    createAttributionCookieFirstClick(cookie)
  }

  if (isClient) {
    Cookies.remove(COOKIE_NAME_FIRST_CLICK)
    Cookies.remove(COOKIE_NAME_LAST_CLICK)
  }
}

const isCookieMalformed = (cookie: string) => {
  try {
    const dateString = JSON.parse(cookie)?.date

    if (typeof dateString !== 'string') {
      return true
    }

    const date = new Date(dateString)

    return !(date instanceof Date) || isNaN(date.getTime())
  } catch (e) {
    return true
  }
}

const isCookieOlderThan = (cookie: string, delay: number) => {
  const cookieDate = new Date(JSON.parse(cookie).date)
  const now = new Date()

  const diff = now.getTime() - cookieDate.getTime()
  const diffMinutes = Math.ceil(diff / (1000 * 60))

  return diffMinutes > delay
}
