import SubscribeSentencesPage from '@pretto/bricks/app/subscribe/pages/SubscribeSentencesPage'
import TimelineSidebar from '@pretto/bricks/app/timeline/components/TimelineSidebar'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import ListTips from '@pretto/bricks/components/lists-tables/ListTips'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import { shorthash } from '@pretto/bricks/core/utility/hash'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import Front from '@pretto/app/src/Helpers/frontchat'
import { handleSentencesForm } from '@pretto/app/src/Simulation/Containers/BookingPage/utils'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import abTest from '@pretto/app/src/lib/abtest'

import PropTypes from 'prop-types'
import qs from 'qs'
import { is } from 'ramda'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { formPrelead, formProspect, getListSidebar } from './config'

const SubscribePageSentences = ({
  defaultValues = {},
  error,
  flow,
  isSuggestionsVisible,
  isSignedUp,
  onGoBack,
  onGoogleConnect,
  onSubmit,
  origin,
  onResetError,
  ...props
}) => {
  const [values, setValues] = useState(defaultValues)
  const [isTimelineOpen, setIsTimelineOpen] = useState(false)
  const [isAgreed, setIsAgreed] = useState(abTest('consent_wording_202310') !== 'robust')
  const [isAgreementRequired, setIsAgreementRequired] = useState(false)

  const { isMobile } = useBreakpoint()

  useEffect(() => {
    Front(isMobile && !isTimelineOpen ? 'hide' : 'show')
  }, [isMobile, isTimelineOpen])

  const authContext = useAuth()
  const { isLoggedIn } = authContext
  const userContext = useUser()

  const [debouncedValues] = useDebounce(values, 1000)

  const checkAgreement = !isLoggedIn || isAgreed
  const parsedQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  const getBubbleDescription = () => {
    if (parsedQuery?.classic === 'true' || (!isSuggestionsVisible && !isSignedUp)) {
      return (
        <span>
          Créez <strong>gratuitement</strong> votre espace personnel Pretto pour sauvegarder votre simulation et
          bénéficier de conseils personnalisés
        </span>
      )
    }

    if (isSuggestionsVisible) {
      return (
        <span>
          Pour voir le détail de toutes les optimisations pour votre budget, nous avons besoin de vous créer un compte
          personnel Pretto.
        </span>
      )
    }

    return null
  }

  const handleChange = (name, value) => {
    onResetError()
    setValues(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = () => {
    if (checkAgreement) {
      onSubmit(values)
      return
    }

    setIsAgreementRequired(true)
  }

  const handleToggleTimeline = () => {
    setIsTimelineOpen(previous => !previous)
  }

  const childContext = [authContext, userContext, values]

  const form = isLoggedIn ? formPrelead : formProspect
  const { fieldErrors, isCompleted, lines } = handleSentencesForm({
    debouncedValues,
    form,
    params: childContext,
    values,
  })

  const disclaimer = funcToItem(form.disclaimer, ...childContext)

  const resultPage = flow === 'capacity' ? 'capacity' : 'simulation'
  const steps = [
    { href: `/project/${flow}/introduction`, title: 'Votre projet' },
    { href: `/project/${flow}/lodging`, title: 'Vos informations' },
    { href: `/${resultPage}`, title: 'Résultats' },
  ]

  const { project } = userContext

  const showGoogle = values?.identify === 'google'

  const { footerProps, progress, sidebarContent, isTimelineVisible } = origin
    ? {
        progress: 1,
        sidebarContent: <ListTips tips={getListSidebar(project?.project_kind)} />,
      }
    : {
        footerProps: {
          isNextDisabled: !isCompleted || !checkAgreement,
          onNextClick: handleSubmit,
          onPreviousClick: onGoBack,
          showGoogle,
        },
        isTimelineVisible: true,
        progress: 0.95,
        sidebarContent: <TimelineSidebar steps={steps} />,
      }

  const isNextVisible = isCompleted && !!origin && checkAgreement

  const pageProps = {
    ...props,
    bubbleDescription: getBubbleDescription(),
    consentProps: {
      id: shorthash('consent'),
      isChecked: isAgreed,
      isError: !isAgreed && isAgreementRequired,
      label: 'J’accepte que Pretto et ses partenaires de confiance me contactent pour étudier mon projet immobilier.',
      onChange() {
        setIsAgreed(prev => !prev)
      },
      isShown: isLoggedIn && abTest('consent_wording_202310') === 'robust',
    },
    disclaimer,
    error,
    fieldErrors,
    footerProps,
    isBubbleVisible: !isMobile,
    isDeliveryVisible: !!origin,
    isNextVisible,
    isSignedUp,
    isTimelineOpen,
    isTimelineVisible,
    lines,
    link: <Link to="/login">ici</Link>,
    onChange: handleChange,
    onGoogleClick: onGoogleConnect,
    onSubmit: handleSubmit,
    onToggleTimeline: handleToggleTimeline,
    progress,
    sidebarContent,
    values,
  }

  return <SubscribeSentencesPage {...pageProps} />
}

SubscribePageSentences.propTypes = {
  defaultValues: PropTypes.object,
  error: PropTypes.string,
  flow: PropTypes.oneOf(['capacity', 'purchase', 'renegotiation']),
  isSignedUp: PropTypes.bool,
  isSuggestionsVisible: PropTypes.bool,
  onGoBack: PropTypes.func,
  onGoogleConnect: PropTypes.func,
  onResetError: PropTypes.func,
  onSubmit: PropTypes.func,
  origin: PropTypes.string,
}

SubscribePageSentences.defaultProps = {
  flow: 'capacity',
}

export default SubscribePageSentences
