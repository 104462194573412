import Responsive from '@pretto/bricks/components/utility/Responsive'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'
import { BlocBanks } from '@pretto/app/src/onboarding/containers/fields/banks'
import { BlocBoolean } from '@pretto/app/src/onboarding/containers/fields/boolean'
import { BlocCalendar } from '@pretto/app/src/onboarding/containers/fields/calendar'
import { BlocDateField } from '@pretto/app/src/onboarding/containers/fields/dateField'
import { BlocMessage } from '@pretto/app/src/onboarding/containers/fields/message'
import { BlocNumberField } from '@pretto/app/src/onboarding/containers/fields/numberField'
import { BlocOptions } from '@pretto/app/src/onboarding/containers/fields/options'
import { BlocTextArea } from '@pretto/app/src/onboarding/containers/fields/textArea'

import PropTypes from 'prop-types'
import { useRef } from 'react'

import * as S from './Step.styles'

const renderField = ({ type, props }) => {
  switch (type) {
    case 'banks':
      return <BlocBanks {...props} />

    case 'date':
      return <BlocDateField {...props} />

    case 'boolean':
      return <BlocBoolean {...props} />

    case 'options':
      return <BlocOptions {...props} />

    case 'integer':
    case 'number':
      return <BlocNumberField {...props} type={type} />

    case 'textarea':
      return <BlocTextArea {...props} />

    case 'message':
      return <BlocMessage {...props} />

    case 'calendar':
      return <BlocCalendar {...props} />

    default:
      return 'Unsupported field type'
  }
}

export const Step = ({
  id,
  isLowPotential,
  advisorName,
  advisorPicturePath,
  advisorRole,
  activeFields,
  onPreviousClick,
  onNextClick,
  isEmpty,
  isNextActive,
}) => {
  const fieldsRef = useRef(activeFields)
  const fieldRef = useRef()
  const idRef = useRef(id)

  const avatarPath = `w_160,h_160${
    advisorPicturePath || '/v1637684805/zen/static-assets/illustrations/660x660/headBulbe.svg'
  }`

  useUpdateEffect(() => {
    if (fieldRef.current) {
      if (idRef.current !== id) {
        idRef.current = id
        fieldsRef.current = activeFields
        return
      }

      if (fieldsRef.current.length !== activeFields.length) {
        fieldsRef.current = activeFields
        fieldRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [activeFields, id])

  return (
    <S.Container>
      <Responsive min="tablet">
        <S.Header>
          {!isLowPotential && (
            <>
              <S.Avatar path={avatarPath} />
              <S.ExpertTitle>Votre {ADVISOR_LABELS[advisorRole]}</S.ExpertTitle>
              <S.ExpertName>{advisorName}</S.ExpertName>

              <Responsive min="laptop">
                <S.Divider />
              </Responsive>
            </>
          )}
        </S.Header>
      </Responsive>

      <S.Content>
        <S.ButtonBack onClick={onPreviousClick}>Retour</S.ButtonBack>

        <div>
          {!isEmpty &&
            activeFields.map(({ description, name, type, componentProps, label, labelSuffix }, index) => (
              <S.Field key={name} $isCondensed={!label && type !== 'message'} ref={fieldRef}>
                {label && (
                  <S.Title $isMainTitle={index === 0}>
                    {label} {labelSuffix && <S.SuffixTitle>{labelSuffix}</S.SuffixTitle>}
                  </S.Title>
                )}
                {description && <S.Description>{description}</S.Description>}
                {renderField({
                  type,
                  props: componentProps,
                })}
              </S.Field>
            ))}
        </div>
      </S.Content>

      {isNextActive && (
        <S.Footer>
          <S.NextButton isArrowShown onClick={onNextClick} isSecondary>
            Suivant
          </S.NextButton>
        </S.Footer>
      )}
    </S.Container>
  )
}

Step.propTypes = {
  id: PropTypes.string,
  isLowPotential: PropTypes.bool,
  advisorName: PropTypes.string,
  advisorPicturePath: PropTypes.string,
  advisorRole: PropTypes.string,
  activeFields: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      type: PropTypes.oneOf(['banks', 'date', 'boolean', 'options', 'number', 'textarea', 'message', 'calendar'])
        .isRequired,
      componentProps: PropTypes.object.isRequired,
      label: PropTypes.string,
    })
  ),
  isNextActive: PropTypes.bool,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  isEmpty: PropTypes.bool,
}
