import SepaInformationSection from '@pretto/bricks/app/mandate/components/SepaInformationSection'
import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Divider from '@pretto/bricks/components/dividers/Divider'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import prettoInformations from '@pretto/config/pretto_informations.json'

import PropTypes from 'prop-types'

import * as S from './styles'

const CREDITOR = 'Pretto'

const SepaDialogContent = ({
  creditorId,
  date,
  email,
  firstName,
  onError,
  onCancel,
  iban,
  informationText,
  lastName,
  onRedirect,
  reference,
  status,
}) => {
  const addressPretto = [
    prettoInformations.address.number,
    prettoInformations.address.street,
    prettoInformations.address.zipcode,
    prettoInformations.address.city.toUpperCase(),
  ].join(' ')

  switch (status) {
    case 'SUCCESS':
      return (
        <S.DialogConainer>
          <div>
            <SubHeading>Merci ! Vos informations ont été enregistrées avec succès.</SubHeading>
            <S.SuccessParagraph>
              Votre référence de mandat est <strong>{reference}</strong>.
            </S.SuccessParagraph>
            <ButtonSquared onClick={onRedirect} variant="accent-1">
              Continuer
            </ButtonSquared>
          </div>
        </S.DialogConainer>
      )

    case 'LOADING':
      return (
        <S.DialogConainer>
          <SpinnerLegacy />
        </S.DialogConainer>
      )

    case 'IBAN_INVALID':
      return (
        <S.DialogConainer>
          <div>
            <SubHeading>Votre IBAN n’est pas valide</SubHeading>
            <S.Paragraph>
              Le numéro IBAN que vous avez renseigné ne semble pas valide. Veuillez vérifier son format et réessayer.
            </S.Paragraph>
            <ButtonSquared onClick={onCancel} variant="accent-1">
              Modifier
            </ButtonSquared>
          </div>
        </S.DialogConainer>
      )

    case 'PAYMENT_ERROR':
    case 'UNKNOWN_SERVER_ERROR':
      return (
        <S.DialogConainer>
          <div>
            <SubHeading>Une erreur est survenue</SubHeading>
            <div>
              Veuillez{' '}
              <S.ButtonInline onClick={onError} type="button">
                contacter le service client
              </S.ButtonInline>
              .
            </div>
          </div>
        </S.DialogConainer>
      )

    default:
      return (
        <>
          <SubHeading>
            Vérifiez que vos informations sont correctes <S.Cancel onClick={onCancel}>modifier</S.Cancel>
          </SubHeading>

          <Text size="small">{informationText}.</Text>

          <SepaInformationSection
            fields={[
              ['Nom', `${firstName} ${lastName}`],
              ['Email', email],
              ['IBAN', iban],
            ]}
            isEditable
            title="Informations du débiteur"
          />

          <SepaInformationSection
            fields={[
              ['Identifiant SEPA', creditorId],
              ['Nom', CREDITOR],
              ['Adresse', addressPretto],
            ]}
            title="Informations du créancier"
          />

          <SepaInformationSection
            fields={[
              ['Référence', 'Disponible après confirmation'],
              ['Date de signature', date],
            ]}
            title="Informations SEPA"
          />

          <Divider variant="neutral-1-l" />

          <S.Legal>
            <Text size="x-small">
              En signant ce formulaire de mandat, vous autorisez (A) {CREDITOR} à envoyer des instructions à votre
              banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux instructions
              de {CREDITOR}.
            </Text>
          </S.Legal>
          <S.Legal>
            <Text size="x-small">
              Vous bénéficiez d’un droit à remboursement par votre banque selon les conditions décrites dans la
              convention que vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8
              semaines suivant la date de débit de votre compte. Vos droits concernant le mandat ci-dessus sont
              expliqués dans un document que vous pouvez obtenir auprès de votre banque.
            </Text>
          </S.Legal>
        </>
      )
  }
}

SepaDialogContent.propTypes = {
  /** Creditor used for ID for GoCardless. */
  creditorId: PropTypes.string.isRequired,
  /** Date to be shown to the user. */
  date: PropTypes.string.isRequired,
  /** Email used for payment. */
  email: PropTypes.string.isRequired,
  /** First name used for payment. */
  firstName: PropTypes.string.isRequired,
  /** IBAN used for payment. */
  iban: PropTypes.any.isRequired,
  /** Information about payment. */
  informationText: PropTypes.string,
  /** Last name used for payment. */
  lastName: PropTypes.any.isRequired,
  /** Action triggered when user wants to edit information. */
  onCancel: PropTypes.func.isRequired,
  /** Function called when error occured and assistance is requested. */
  onError: PropTypes.func.isRequired,
  /** Action triggered after user validates his information. */
  onRedirect: PropTypes.func.isRequired,
  /** SEPA reference, used for success status. */
  reference: PropTypes.string,
  /** Status will determine content to be shown. */
  status: PropTypes.oneOf(['SUCCESS', 'LOADING', 'IBAN_INVALID', 'PAYMENT_ERROR']),
}

export default SepaDialogContent
