export const formatDisplayNumber = (number = 0, suffix = '€') => `${number?.toLocaleString('fr')} ${suffix}`

export const formatDepartment = zipcode => {
  if (!zipcode) return ''

  const zip = zipcode.toString()
  if (zip.startsWith('97')) {
    return zip.slice(0, 3)
  }

  return zip.slice(0, 2)
}
