import { t } from '@pretto/app/src/lib/i18n'

const computeYears = years => {
  const yearWordSingle = t('noFinancingPage.comments.contracts.seniority.year')
  const yearWordPlural = t('noFinancingPage.comments.contracts.seniority.years')
  const joinWord = t('noFinancingPage.comments.contracts.contractJoinWord')

  // Handle 'X and Y years' special case
  if (years.length > 1 && years.every(year => year > 0)) {
    return `${years[0]} ${joinWord} ${years[1]} ${yearWordPlural}`
  }

  return years
    .map(year => {
      if (year === 0) {
        return t('noFinancingPage.comments.contracts.seniority.lessThanOneYear')
      }

      return `${year} ${year > 1 ? yearWordPlural : yearWordSingle}`
    })
    .join(` ${joinWord} `)
}

export default computeYears
