import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

export const Field = styled.div`
  position: relative;
`
export const FieldValidation = styled.div`
  margin-top: ${g(2)};
`
export const FieldButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.legacy.colors.accent1.default};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: not-allowed;
    `}
`

export const Caption = styled.div`
  margin-top: ${g(1)};
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
`
