import * as filters from '@pretto/app-core/lib/filters'
import { cloneElement } from 'react'

import { useField } from './Field'
import { usePhoneField } from '../../Form/lib/useField'

export const PhoneField = props => {
  const { Component, fieldProps } = useField(props)
  const { value, ...phoneFieldProps } = usePhoneField(fieldProps)

  const encodedValue = !phoneFieldProps.error && value !== '' ? filters.formatEncodePhone(value) : ''

  return cloneElement(Component, { ...phoneFieldProps, initialValue: value, value: encodedValue })
}
