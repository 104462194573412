import { g } from '@pretto/bricks/components/layout'

import { FileIcon as FileIconComponent } from '@pretto/app-core/application/components/FileIcon/FileIcon'

import styled from 'styled-components'

import { Details } from './components/Details/Details'
import { Tail as TailComponent } from './components/Tail/Tail'

export enum FileItemState {
  Error = 'Error',
  Removing = 'Removing',
  Stale = 'Stale',
  Uploaded = 'Uploaded',
  Uploading = 'Uploading',
}

interface FileItemInterface {
  state: FileItemState
}

interface FileItemError extends FileItemInterface {
  onRetry: () => void
  state: FileItemState.Error
}

interface FileItemRemoving extends FileItemInterface {
  date: string
  state: FileItemState.Removing
}

interface FileItemStale extends FileItemInterface {
  date: string
  helpUrl?: string
  onHelp: () => void
  state: FileItemState.Stale
}

interface FileItemUploaded extends FileItemInterface {
  date: string
  onDelete: () => void
  state: FileItemState.Uploaded
}

interface FileItemUploading extends FileItemInterface {
  progress: number
  state: FileItemState.Uploading
}

type FileItemUnion = FileItemError | FileItemRemoving | FileItemStale | FileItemUploaded | FileItemUploading

export type FileItemProps = FileItemUnion & {
  extension: string
  fileName: string
}

export const FileItem: React.FC<React.HTMLAttributes<HTMLDivElement> & FileItemProps> = ({
  extension,
  fileName,
  ...props
}) => {
  return (
    <Component {...props}>
      <FileIcon isActive={props.state !== FileItemState.Stale} extension={extension} />

      <Content>
        <FileName title={fileName}>{fileName}</FileName>
        <Details {...(props as FileItemProps)} />
      </Content>

      <Tail {...(props as FileItemProps)} />
    </Component>
  )
}

const Component = styled.div`
  align-items: center;
  display: flex;
  gap: ${g(2)};
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow: hidden;
`

const FileName = styled.span`
  ${({ theme }) => theme.typos.body4Bold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FileIcon = styled(FileIconComponent)`
  flex-shrink: 0;
`

const Tail = styled(TailComponent)`
  flex-shrink: 0;
`
