import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import ErrorPage from '@pretto/app/src/Simulation/Containers/ErrorPage'
import { ArrangeCredits } from '@pretto/app/src/Simulation/Containers/OptionsPage/ArrangeCredits'
import { ReduceAmbition } from '@pretto/app/src/Simulation/Containers/OptionsPage/ReduceAmbition'
import ResultsPage from '@pretto/app/src/Simulation/Containers/ResultsPage'
import { getEditableFields } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/getEditableFields'
import { getErrors } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/getErrors'
import {
  getBestResultFromResults,
  getDurationFromResults,
} from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/results'
import { computeDealValue } from '@pretto/app/src/Simulation/Containers/SimulationPage/helpers/value'
import { validateSimulationPageProps } from '@pretto/app/src/Simulation/Containers/SimulationPage/propsFormatter/validateSimulationPageProps'
import ValidateSimulationPage from '@pretto/app/src/Simulation/Containers/ValidateSimulationPage'
import { NonFinancingSignup } from '@pretto/app/src/Simulation/Containers/nonFinancingSignup/NonFinancingSignup'
import { Pushy } from '@pretto/app/src/Simulation/Containers/pushy/Pushy'
import { SimulationContext, SimulationProvider } from '@pretto/app/src/Simulation/context/SimulationContext'
import * as types from '@pretto/app/src/Simulation/lib/types'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import * as queries from '@pretto/app/src/apollo'
import { LoaderType } from '@pretto/app/src/config/loader'
import { getUtmFlagsLastClicked } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useApolloClient } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const SimulationPage = () => {
  const client = useApolloClient()
  const history = useHistory()

  const { isLoggedIn } = useAuth()

  const {
    projectChanges,
    data,
    initialData,
    isLoading,
    isInteractive,
    onSimulationMedium,
    onTrackSimulationRequest,
    simulationMedium,
    resetProjectChanges,
    resultsPage: { onDurationChange, onProjectChange, goToResults },
    optionsPage: { onProjectChanges, onProjectChangesWithPurchase },
    optimizePage: { onOptimizeChoose },
    type,
  } = useContext(SimulationContext)

  const [chosenOptimization, setChosenOptimization] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isMutating, setIsMutating] = useState(false)
  const [isOptimizing, setIsOptimizing] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [shouldRefetchBooking] = useState(false)

  const isPageLoading = useLoading(isLoading || !isInteractive, LoaderType.Simulation)

  const trackAction = useTracking()

  const { isProjectEditable = true, projectID, typology, user, isEnglishUser } = useUser()

  useEffect(() => {
    if (!isProjectEditable) {
      history.replace('/')
    }
  }, [isProjectEditable])

  const isProjectChanging = typology === 'client' && Object.keys(projectChanges).length > 0

  useEffect(() => {
    if (!isProjectChanging) {
      return
    }

    trackAction('SIMULATION_RESULTS_BANNER_SHOWN')
  }, [isProjectChanging])

  const handleOptimizeCancel = () => {
    setIsOptimizing(false)
  }

  const handleOptimizeChoose = ({ contribution, duration, type }) => {
    trackAction('SIMULATION_OPTIMIZATION_OPTION_CLICKED', { simulation_optimization_option_slug: type })

    onSimulationMedium('optimize')

    onOptimizeChoose({ contribution, duration })

    setChosenOptimization(type)
    setIsOptimizing(false)
  }

  const handleOptimize = () => {
    setIsOptimizing(true)

    trackAction('SIMULATION_RESULTS_OPTIMIZE_CLICKED')
  }

  const fetchOptimizations = async () => {
    const variables = { override: JSON.stringify(projectChanges) }

    const { data } = await client.query({ query: queries.OPTIMIZE_SIMULATION, variables })

    return data
  }

  const updateProject = async redirectPath => {
    setIsMutating(true)

    const update = async () => {
      await invalidateCache(client)

      history.push(redirectPath)
      setIsMutating(false)
    }

    const variables = { project: JSON.stringify(projectChanges) }

    await client.mutate({
      mutation: queries.UPDATE_PROJECT,
      update,
      variables,
    })
  }

  // Page props

  const errorPageProps = ({ malus, project }, projectId) => ({
    isBlocked: isLoggedIn && Object.keys(projectChanges).length > 0,
    malus,
    onRestore: () => window.location.reload(),
    project,
    projectId,
  })

  const resultsPageProps = ({ brokerage_fees: { amount: brokerageFees }, results, project }) => {
    const handleDurationChange = (duration, medium, callback = () => {}) => {
      onSimulationMedium(medium)

      onDurationChange(duration, callback)
      setChosenOptimization(null)

      onTrackSimulationRequest(project, dealValue)
      callback(duration)
    }

    const handleProjectChange = (changes, medium) => {
      onSimulationMedium(medium)

      onProjectChange(changes)

      setChosenOptimization(null)
    }

    const handleProjectChangeCancel = () => {
      resetProjectChanges()
      setChosenOptimization(null)
    }

    const handleProjectChangeSave = () => {
      setIsValidating(true)
    }

    const handleProjectEdit = () => {
      const redirectPath = typology === 'client' ? '/application' : `/project/${projectKind}/introduction`

      if (isProjectChanging) {
        setIsEditing(true)
        return
      }

      history.push(redirectPath)
    }

    const handleProjectValidate = (path = '/simulation/subscribe') => {
      updateProject(path)
    }

    const {
      contribution,
      good: { property_kind },
      project_kind: projectKind,
      purchase,
    } = project

    const duration = getDurationFromResults(results, project, projectChanges)
    const bestResult = getBestResultFromResults(results, duration)
    const dealValue = computeDealValue(bestResult, brokerageFees)

    const handleValidateDefaultDuration = async () => {
      const variables = { project: JSON.stringify({ request: { duration } }) }

      await client.mutate({
        mutation: queries.UPDATE_PROJECT,
        variables,
      })
    }

    const editableFields = getEditableFields({
      contribution,
      isLoggedIn,
      projectChanges,
      projectKind,
      property_kind,
      purchase,
    })

    return {
      brokerageFees,
      chosenOptimization,
      dealValue,
      duration,
      editableFields,
      fetchOptimizations,

      isMutating,
      isOptimizing,
      isProjectChanging,
      onDurationChange: handleDurationChange,

      onOptimize: handleOptimize,
      onOptimizeCancel: handleOptimizeCancel,
      onOptimizeChoose: handleOptimizeChoose,

      onProjectChange: handleProjectChange,
      onProjectChangeCancel: handleProjectChangeCancel,
      onProjectChangeSave: handleProjectChangeSave,
      onProjectEdit: handleProjectEdit,
      onProjectValidate: handleProjectValidate,
      onUpdateProject: updateProject,

      onValidateDefaultDuration: handleValidateDefaultDuration,

      project,
      results,
      shouldRefetchBooking,
      simulationMedium,
    }
  }

  if (isPageLoading) {
    return null
  }

  if (isEditing || isValidating) {
    const handleValidationCancel = () => {
      trackAction('SIMULATION_VALIDATION_CHANGES_CANCELED')

      if (isEditing) {
        history.push('/application')
        return
      }

      resetProjectChanges()

      setChosenOptimization(null)
      setIsEditing(false)
      setIsValidating(false)
    }

    const handleValidationUpdate = () => {
      updateProject(isEditing ? '/application' : '/overview')

      trackAction('SIMULATION_VALIDATION_CHANGES_VALIDATED')
    }

    return (
      <ValidateSimulationPage
        {...validateSimulationPageProps({
          currentData: initialData,
          handleValidationCancel,
          handleValidationUpdate,
          newData: data,
          projectChanges,
        })}
      />
    )
  }

  switch (type) {
    case types.ARRANGE_CREDITS: {
      const { project, ...options } = data

      return (
        <ArrangeCredits
          project={project}
          options={options}
          onSimulationMedium={onSimulationMedium}
          onProjectChanges={onProjectChanges}
        />
      )
    }

    case types.INTERSTITIAL_SUBSCRIBE: {
      const {
        project: {
          purchase: { maturity },
        },
      } = data

      const { utm_campaign_last_clicked, utm_medium_last_clicked, utm_source_last_clicked } = getUtmFlagsLastClicked()
      const isForce =
        utm_campaign_last_clicked === 'courtier' &&
        utm_medium_last_clicked === 'cpc' &&
        utm_source_last_clicked === 'google'

      return <Pushy flow="purchase" goToResults={goToResults} isForce={isForce} maturity={maturity} />
    }

    case types.ERROR:
    case types.ERROR_SIGNUP: {
      const errorsData = getErrors(data, isEnglishUser ? 'en' : 'fr')

      if (typology !== 'client' && type === types.ERROR_SIGNUP) {
        const defaultValues = {
          email: user?.email,
        }

        return <NonFinancingSignup defaultValues={defaultValues} />
      }

      return <ErrorPage {...errorPageProps(errorsData, projectID)} />
    }

    case types.REDUCE_AMBITION: {
      const { project, ...options } = data
      return (
        <ReduceAmbition
          project={project}
          options={options}
          onSimulationMedium={onSimulationMedium}
          onProjectChanges={onProjectChanges}
          onProjectChangesWithPurchase={onProjectChangesWithPurchase}
        />
      )
    }

    case types.SIMULATION: {
      return <ResultsPage {...resultsPageProps(data)} />
    }

    default:
      throw new Error('Unrecognized data type')
  }
}

const Simulation = () => (
  <SimulationProvider>
    <SimulationPage />
  </SimulationProvider>
)

export default Simulation
