import { Field, Input } from '@pretto/app/src/purchaseOffer/config/types'

import { useState } from 'react'

export const useInputSelect = <T extends string>({ props }: Input): Field<T | null> => {
  const [value, setValue] = useState<T | null>(null)

  const handleSelect = ({ value }: { value: T }) => {
    setValue(value)
  }

  return {
    error: value === '',
    input: {
      props: {
        ...props,
        onChange: handleSelect,
        value,
      },
      type: 'select',
    },
    value,
  }
}
