import PreLeadProjectCardTemplate from '@pretto/bricks/app/pre-lead/templates/PreLeadProjectCardTemplate'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import * as S from './styles'

const PreLeadProjectCardError = ({ href, onEdit, seeMoreHref, text, type }) => {
  return (
    <PreLeadProjectCardTemplate href={href} isEditable onEdit={onEdit} type={type}>
      <S.ErrorBanner>
        <S.Container>
          <S.Icon>
            <S.Warning />
          </S.Icon>
          <div>
            <S.Description>{nl2br(text)}</S.Description>
            <S.SeeMore href={seeMoreHref}>En savoir plus</S.SeeMore>
          </div>
        </S.Container>
      </S.ErrorBanner>
    </PreLeadProjectCardTemplate>
  )
}

PreLeadProjectCardError.propTypes = {
  /** Where the card click should redirect. */
  href: PropTypes.string.isRequired,
  /** Different malus to display */
  malus: PropTypes.array,
  /** Function triggered when edit icon is clicked. */
  onEdit: PropTypes.func.isRequired,
  /** href to see details */
  seeMoreHref: PropTypes.string,
  /** description */
  text: PropTypes.string,
  /** type of project card */
  type: PropTypes.string,
}

export default PreLeadProjectCardError
