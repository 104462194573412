import PropTypes from 'prop-types'
import { createContext, useContext, useState } from 'react'

import { getPicturePath } from '../../Helpers/pictureUrl'
import AdvisorDialog from '../../SharedComponents/AdvisorDialog'
import AdvisorAvatar from '../../SharedContainers/AdvisorAvatar'
import { useUser } from '../../User/Containers/UserProvider'
import { useTracking } from '../../lib/tracking'
import * as L from '../config/lists'

const AdvisorDialogContext = createContext({
  onOpen() {
    // nothing
  },
})

export const ExpertAdvisorDialog = ({ advisor, isOpen, onClose }) => {
  return (
    <AdvisorDialog
      advisor={advisor}
      avatarSrc={getPicturePath(advisor)}
      isOpen={isOpen}
      lists={[L.advisor, L.help]}
      onClose={onClose}
    />
  )
}

const AdvisorDialogContainer = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const trackAction = useTracking()

  const { advisor, maturity } = useUser()

  const handleClose = () => setIsOpen(false)

  const handleOpen = (eventInfos = { actionFrom: null }) => {
    const { pathname } = window.location
    const eventName =
      eventInfos?.actionFrom === 'more_info' ? 'ADVISOR_PROFILE_MORE_INFO_OPENED' : 'ADVISOR_PROFILE_OPENED'

    trackAction(eventName, {
      advisor_profile_opened_origin: pathname,
      maturity,
      expert_on_holidays: !!advisor?.holidays_start_date,
    })

    setIsOpen(true)
  }

  return (
    <AdvisorDialogContext.Provider value={{ avatarProps: { src: <AdvisorAvatar size="small" /> }, onOpen: handleOpen }}>
      <>
        {children}

        <ExpertAdvisorDialog advisor={advisor} isOpen={isOpen} onClose={handleClose} />
      </>
    </AdvisorDialogContext.Provider>
  )
}

export const useAdvisor = () => useContext(AdvisorDialogContext)

AdvisorDialogContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdvisorDialogContainer
