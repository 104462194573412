import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-pluralrules/polyfill'

interface Operation {
  description: string
  sign?: '+' | '-' | '='
  title: string
}

export const agencyFees = {
  description: 'Ces frais sont ceux que vous payez à l’agence. Votre apport doit les couvrir.',
  sign: '+',
  title: 'Frais d’agence',
} satisfies Operation

export const applicationFees = {
  description: 'Ce sont les frais qui rémunèrent la banque pour la gestion de votre demande de prêt.',
  sign: '+',
  title: 'Frais de dossier bancaire estimés',
} satisfies Operation

export const brokerageFees = {
  description: "Ces frais couvrent notre accompagnement, de l'étude de votre projet jusqu'à la signature du prêt.",
  sign: '+',
  title: 'Frais Pretto',
} satisfies Operation

export const bridgeRemaining = {
  description:
    'Il s’agit du montant racheté correspondant au capital restant dû (CRD) de votre prêt immobilier actuel.',
  sign: '+',
  title: 'Rachat de CRD',
} satisfies Operation

export const bridgeValue = {
  description:
    'Avance de trésorerie utilisée comme apport, remboursée à la vente de votre bien. Ce montant est calculé à partir de la valeur de votre bien actuel et du prêt qu’il vous reste à rembourser.',
  sign: '-',
  title: 'Prêt relais',
} satisfies Operation

export const buildPrice = {
  description: '',
  title: 'Prix de la construction',
} satisfies Operation

export const contribution = {
  description: 'L’apport représente la somme que vous pouvez apporter pour cet achat.',
  sign: '-',
  title: 'Apport',
} satisfies Operation

export const creditCosts = {
  description: '',
  sign: '+',
  title: 'Coût du crédit estimé, hors assurance',
} satisfies Operation

export const furniturePrice = {
  description: 'Le mobilier hors électroménager que vous achetez avec ce bien.',
  sign: '+',
  title: 'Meubles',
} satisfies Operation

export const guarantyFees = {
  description: "Cette garantie est obligatoire et est exigée par l'organisme prêteur.",
  sign: '+',
  title: 'Frais de garantie estimés',
} satisfies Operation

export const landPrice = {
  description: '',
  title: 'Prix du terrain',
} satisfies Operation

export const loanAmount = {
  description: 'C’est le montant que vous empruntez à la banque pour financer votre projet.',
  sign: '=',
  title: 'Montant emprunté',
} satisfies Operation

export const loanDuration = {
  description: '',
  title: 'Durée',
} satisfies Operation

export const notaryFees = {
  description: 'Nous calculons automatiquement les frais de notaire pour votre achat.',
  sign: '+',
  title: 'Frais de notaire estimés',
} satisfies Operation

export const payment = {
  description: '',
  title: 'Mensualité, hors assurance',
} satisfies Operation

export const penaltyFees = {
  description: 'Ce sont les indemnités que vous devez payer afin de rembourser votre prêt.',
  sign: '+',
  title: 'Indemnités de remboursement anticipé',
} satisfies Operation

export const propertyPrice = {
  description: 'C’est le prix d’achat de votre futur bien immobilier.',
  title: 'Prix du bien',
} satisfies Operation

type OperationPtz = Omit<Operation, 'description'> & {
  description: (duration: number, franchise: number) => React.ReactNode
}

export const ptzAmount = {
  description(duration, franchise) {
    const pluralRules = new Intl.PluralRules('fr')

    const map = new Map([
      ['one', 'ère'],
      ['other', 'ème'],
    ])

    const suffix = map.get(pluralRules.select(franchise))

    return (
      <p>
        Vous rembourserez ce montant sur {duration} ans à partir de la {franchise}
        <sup>{suffix}</sup> année de votre emprunt, ceci sans changer votre mensualité.
      </p>
    )
  },
  title: 'Dont prêt à taux zéro',
} satisfies OperationPtz

export const remainingPrincipal = {
  description: 'C’est le montant restant que vous devez payer pour votre ancien prêt.',
  title: 'Capital restant dû',
} satisfies Operation

export const savings = {
  description: '',
  title: 'Économies réalisées',
} satisfies Operation

export const worksAmount = {
  description: '',
  sign: '+',
  title: 'Travaux',
} satisfies Operation

export const worksPrice = {
  description: '',
  sign: '+',
  title: 'Travaux',
} satisfies Operation
