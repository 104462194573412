import { breakpoints, g } from '@pretto/bricks/components/layout'
import PlaceholderComponent from '@pretto/bricks/components/loading/Placeholder'
import styled from 'styled-components'

export const Edit = styled.div`
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  flex: 0 0 100%;
  line-height: ${g(2)};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: initial;
    margin-left: auto;
    margin-top: 0;
    padding-left: ${g(2)};
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
`

export const Emoji = styled.div`
  margin-right: ${g(2)};
`

export const Placeholder = styled(PlaceholderComponent)`
  width: 200px;
`
