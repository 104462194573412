import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import styled, { css } from 'styled-components'

const CONFIG = theme => ({
  faq: {
    mobileUrl: getCloudinaryUrl('v1571245503/bricks/static-assets/help-thumbnail_mobile.png', {
      height: ng(28),
      width: ng(84),
    }),
    mobileVariant: theme.colors.neutral4,
    mobileWidth: g(42),
    url: getCloudinaryUrl('v1569916154/bricks/static-assets/help-thumbnail.png', { height: ng(38), width: ng(22) }),
    variant: theme.colors.neutral4,
    width: g(11),
  },
  guide: {
    url: getCloudinaryUrl('v1569916155/bricks/static-assets/book_cover-thumbnail.png', {
      height: ng(28),
      width: ng(28),
    }),
    variant: theme.colors.primary4,
    width: g(14),
  },
})
export const Decoration = styled.div`
  position: absolute;
  top: ${g(2)};
  right: 0;
  left: 0;
  height: ${g(20)};
`
export const CapacityMarketingCard = styled.div`
  position: relative;
  padding-top: ${g(18)};
  z-index: 1;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    height: ${g(10)};
    z-index: -1;
    opacity: 0.5;
  }

  ${({ slug, theme }) => {
    const { mobileUrl, mobileWidth, mobileVariant, variant, url, width } = CONFIG(theme)[slug]

    if (mobileVariant) {
      return css`
        &:after {
          background: ${mobileVariant};
          height: ${g(16)};
        }

        ${Decoration} {
          background: url('${mobileUrl}') no-repeat center top;
          background-size: ${mobileWidth} auto;
        }

        @media screen and (min-width: ${breakpoints.tablet}) {
          &:after {
            background: ${variant};
            height: ${g(10)};
          }

          ${Decoration} {
            background: url('${url}') no-repeat center top;
            background-size: ${width} auto;
          }
        }
      `
    }

    return css`
      &:after {
        background: ${variant};
      }

      ${Decoration} {
        background: url('${url}') no-repeat center top;
        background-size: ${width} auto;
      }
    `
  }}

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(20)};
  }
`

export const Content = styled.div`
  padding: 0 ${g(2)} ${g(2)};
`
export const Text = styled.div`
  margin: ${g(1)} 0;
`
