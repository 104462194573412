import { Bars, UserCircle } from '@pretto/picto'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import * as S from './styles'

const NavAccount = forwardRef(({ accountVariant, user, loginButtonLabel, ...props }, ref) => {
  const getLogInBlock = () => {
    switch (accountVariant) {
      case 'loggedIn':
        return {
          desktop: (
            <>
              <S.Initial>{user.initial}</S.Initial>
              <S.Name>{user.label}</S.Name>
              <S.Picto />
            </>
          ),
          mobile: <S.PictoMobile as={Bars} />,
        }
      case 'signIn':
        return {
          desktop: (
            <S.LogIn>
              <S.LoginPicto />
              {loginButtonLabel || 'Se connecter'}
            </S.LogIn>
          ),
          href: '/login',
          mobile: <S.PictoMobile as={UserCircle} />,
        }

      case 'signUp':
        return {
          desktop: (
            <S.LogIn>
              <S.LoginPicto />
              Pas encore de compte ?
            </S.LogIn>
          ),
          href: '/signup',
          mobile: <S.PictoMobile as={UserCircle} />,
        }
      default:
      case 'onlyInitial':
        return {
          desktop: <S.Initial>{user.initial}</S.Initial>,
          href: '/',
          mobile: <S.Initial>{user.initial}</S.Initial>,
        }
    }
  }

  return (
    <S.Session href={getLogInBlock().href} ref={ref} {...props}>
      <S.MobileContainer>{getLogInBlock().mobile}</S.MobileContainer>
      <S.DesktopContainer>{getLogInBlock().desktop}</S.DesktopContainer>
    </S.Session>
  )
})

NavAccount.propTypes = {
  /** What kind of account block is dislayed */
  accountVariant: PropTypes.oneOf(['loggedIn', 'signIn', 'signUp', 'onlyInitial']),
  /** Informations about the user */
  user: PropTypes.object,
  /** Override the login button label for English version */
  loginButtonLabel: PropTypes.string,
}

NavAccount.displayName = 'NavAccount'

export default NavAccount
