import { breakpoints } from '@pretto/bricks/components/layout'
import { btoa } from '@pretto/bricks/core/utility/btoa'

import flatten from 'lodash/flatten'
import { css } from 'styled-components'

import colors from './colors'

export const boxShadow = depth => {
  switch (depth) {
    case 0:
      return css`
        box-shadow: none;
      `

    case 2:
      return css`
        box-shadow: 0 4px 20px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(15)};
      `

    case 3:
      return css`
        box-shadow: 0 6px 30px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(15)};
      `

    case 'card':
      return css`
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      `
    case 'hover':
      return css`
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
      `
    case 'overlay':
      return css`
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      `
    case 'sidebar':
      return css`
        box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.neutral3};
      `

    case 1:
    default:
      return css`
        box-shadow: 0 2px 10px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
      `
  }
}

const DEFAULT_SHAPE_VARIANT = 'neutral1'
const defaultMediaQueries = Object.keys(breakpoints).reduce(
  (previous, breakpoint) => ({
    ...previous,
    [breakpoint]: css``,
  }),
  {}
)

export const shape = (
  styles = css``,
  { variant = DEFAULT_SHAPE_VARIANT, hover = defaultMediaQueries, mediaQueries = defaultMediaQueries } = {
    mediaQueries: defaultMediaQueries,
    hover: defaultMediaQueries,
  }
) => {
  return css`
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: transparent;
      background-image: url('data:image/svg+xml;base64,${({ theme }) =>
        btoa(
          `<svg width="152" height="174" viewBox="0 0 152 174" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M63.6,3.83a22,22,0,0,1,24.8,0L142.68,41A21.3,21.3,0,0,1,152,58.52v61.94a21.56,21.56,0,0,1-21.73,21.38l-48.73.31V174H76c-29.2,0-49-12.33-61-28.69C3.63,129.76,0,111.58,0,99.22V58.52A21.3,21.3,0,0,1,9.32,41Z" fill="${theme.colors[variant]}"/></svg>`
        )}');
      background-repeat: no-repeat;
      z-index: -1;
      ${styles}
    }

    ${Object.entries(mediaQueries).map(
      ([key, value]) => css`
        @media screen and (min-width: ${breakpoints[key]}) {
          &:before {
            ${value}
          }
        }
      `
    )}

    ${Object.entries(hover).map(
      ([key, value]) => css`
        @media screen and (min-width: ${breakpoints[key]}) {
          &:hover:before {
            ${value}
          }
        }
      `
    )}
  `
}

const lightness = variant => [
  `${variant}-l`,
  `${variant}-10`,
  `${variant}-20`,
  `${variant}-30`,
  `${variant}-40`,
  `${variant}-50`,
]
const darkness = variant => [`${variant}-d`, `${variant}-60`, `${variant}-70`, `${variant}-80`, `${variant}-90`]

const palette = [
  'primary-1',
  'primary-2',
  'neutral-1',
  'accent-1',
  'accent-2',
  'accent-3',
  'accent-4',
  'accent-5',
  'success',
  'error',
  'warning',
]
const light = flatten(palette.map(variant => lightness(variant).map(color => color)))
const dark = flatten(palette.map(variant => darkness(variant).map(color => color)))

export const opposite = variant => {
  if ([...light, 'white'].includes(variant)) return colors['neutral-1']
  return colors.white
}

export const fade = (variant, opacity) => colors[`${variant}-${opacity}`]
