import { IPropertySearchData } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider/types'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { createAlert, updateEmailFrequency } from '@pretto/app/src/lib/prettoSearch/prettoSearchGraphqlApi'
import { CapacityData } from '@pretto/app/src/types/Capacity'
import { TrackAction } from '@pretto/app/src/types/Tracking'

import qs from 'qs'
import { useHistory } from 'react-router'

interface HandlePropertySearchProps {
  propertySearch: string | qs.ParsedQs | string[] | qs.ParsedQs[]
  hasError: boolean
  newData: CapacityData
  trackAction: TrackAction
  flushCache: UnknownFunction
  history: ReturnType<typeof useHistory>
}

export const handlePropertySearch = async ({
  propertySearch,
  hasError,
  newData,
  trackAction,
  flushCache,
  history,
}: HandlePropertySearchProps): Promise<void> => {
  const price_max = hasError
    ? null
    : newData.capacity?.[newData.capacity.length - 1]?.project?.purchase?.property_price ?? null

  const payload = {
    ...Object.entries(propertySearch).reduce((previous, [key, value]) => {
      if (['area_max', 'area_min', 'price_min', 'rooms'].includes(key)) {
        return { ...previous, [key]: parseInt(value as string, 10) }
      }

      if (value === '') {
        return { ...previous, [key]: null }
      }

      return { ...previous, [key]: value }
    }, {}),
    price_max,
  }

  try {
    const { id } = await createAlert(payload as IPropertySearchData)

    await updateEmailFrequency((payload as IPropertySearchData).frequency)

    trackAction('CAPACITY_PROPERTY_SEARCH_ALERT_CREATED')

    flushCache()

    setItem('UUID_PSW', id)
  } catch (error) {
  } finally {
    history.replace('/capacity')
  }
}
