import { BlockFeedback } from '@pretto/zen/dashboard/feedback/BlockFeedback/BlockFeedback'

import { getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import { NpsSnackbar } from '@pretto/app/src/dashboard/components/NpsSnackbar/NpsSnackbar'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useState } from 'react'

enum State {
  Default = 'default',
  Empty = 'empty',
  Sent = 'sent',
}

export const Nps: React.FC = props => {
  const trackAction = useTracking()

  const [state, setState] = useState<State>(State.Default)
  const [nps, setNps] = useState<number>()

  const handleClickInput = (index: number) => {
    setNps(index)
    trackAction('DASHBOARD_NPS_GRADE_CLICKED', { nps: index })
  }

  const handleSubmit = (comment: string) => {
    const trackingOptions = {
      nps,
      comment,
    }

    setState(State.Sent)

    fetch('https://app.pretto.fr/api/negotiation-nps/', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trackingOptions),
    })
  }

  const handleClose = () => {
    setState(State.Empty)
    trackAction('DASHBOARD_NPS_SNACKBAR_CLOSED')
  }

  if (state === State.Empty) {
    return null
  }

  if (state === State.Sent) {
    return <NpsSnackbar onClose={handleClose} {...props} />
  }

  return <BlockFeedback onClickInput={handleClickInput} onSubmit={handleSubmit} {...props} />
}
