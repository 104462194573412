import { addSeparatorToDateString } from './addSeparatorToDateString'
import { sanitizeDateString } from './sanitizeDateString'

interface ComputeDateSelectionStart {
  format: string
  inputType: string
  rawValue: string
  selectionEnd: number
}

export const computeDateSelectionStart = ({
  format,
  inputType,
  rawValue,
  selectionEnd,
}: ComputeDateSelectionStart): number => {
  const valueBeforeSelection = rawValue.substring(0, selectionEnd)
  const contextOffset = sanitizeDateString({ format, value: valueBeforeSelection }).length

  const valueAfterSelection = rawValue.substring(selectionEnd)
  const dateStringAfterSelection = addSeparatorToDateString({
    contextOffset,
    format,
    value: sanitizeDateString({ format, value: valueAfterSelection }),
  })

  const stringValue = addSeparatorToDateString({ format, value: rawValue })
  const selectionStart = stringValue.length - dateStringAfterSelection.length
  const match = stringValue.substring(0, selectionStart).match(/[^\d]+$/)

  if (inputType === 'deleteContentBackward' && match) {
    return Math.max(0, selectionEnd)
  }

  if (inputType === 'deleteWordBackward' && match) {
    return Math.max(0, selectionStart - match[0].length)
  }

  return Math.max(0, selectionStart)
}
