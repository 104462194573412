import { g } from '@pretto/bricks/components/layout'

import { ReactNode } from 'react'
import styled from 'styled-components'

interface ModalContentProps {
  children: ReactNode
}

export const ModalContent = ({ children, ...props }: ModalContentProps) => {
  return <MainContainer {...props}>{children}</MainContainer>
}

const MainContainer = styled.div`
  width: 100%;
  padding: 0 ${g(4)} ${g(4)};
`
