import Responsive from '@pretto/bricks/components/utility/Responsive'
import Swipe from '@pretto/bricks/components/utility/Swipe'
import ActionCard from '@pretto/bricks/shared/components/ActionCard'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const ActionCards = ({ data, defaultIndex, hasOptions }) => {
  const cards = data.map((card, index) => (
    <S.Card key={card.title}>
      <ActionCard childrenPosition={card.childrenPosition} {...card} optionNumber={hasOptions ? index + 1 : null} />
    </S.Card>
  ))
  return (
    <Fragment>
      <Responsive max="tablet">
        <S.ActionCards>
          <Swipe index={defaultIndex} enableMouseEvents>
            {cards}
          </Swipe>
        </S.ActionCards>
      </Responsive>
      <Responsive min="tablet">
        <S.ActionCards>{cards}</S.ActionCards>
      </Responsive>
    </Fragment>
  )
}

ActionCards.propTypes = {
  data: PropTypes.array.isRequired,
  defaultIndex: PropTypes.number,
  hasOptions: PropTypes.bool,
}

ActionCards.defaultProps = {
  defaultIndex: 0,
  hasOptions: false,
}

export default ActionCards
