import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import { createElement } from 'react'
import styled from 'styled-components'

const DEFAULT_ERROR = {
  message: 'Quelque chose ne s’est pas passé comme prévu. Vous pouvez contacter votre expert pour plus d’informations.',
}

export const DEFAULT_VALUE = createElement(styled(BaseTypo).attrs({ children: 'non renseigné' })`
  color: ${({ theme }) => theme.colors.neutral2};
`)

export const ERRORS = {
  ACCOUNT_ALREADY_EXISTS: {
    hint: 'Un compte existe déjà avec cette adresse email',
    message: (
      <>
        Désolé, un compte existe déjà avec cette adresse mail. Essayez un autre email, ou consultez cet{' '}
        <Link href="https://faq.pretto.fr/fr/articles/833730">article de notre FAQ</Link>.
      </>
    ),
  },
  ACCOUNT_ALREADY_RESERVED: {
    hint: 'Un compte existe déjà avec cette adresse email',
    message: (
      <>
        Désolé, un compte existe déjà avec cette adresse mail. Essayez un autre email, ou consultez cet{' '}
        <Link href="https://faq.pretto.fr/fr/articles/833730">article de notre FAQ</Link>.
      </>
    ),
  },
  MORTGAGOR_ALREADY_INVITED: DEFAULT_ERROR,
  PROJECT_WITHOUT_COMORTGAGOR: DEFAULT_ERROR,
}

export const MATCHING_NAMES = {
  firstName: 'first_name',
  lastName: 'last_name',
}
