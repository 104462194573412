import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: block;
  overflow: hidden;
  position: relative;

  ${({ theme, variant }) =>
    variant &&
    css`
      background: ${theme.legacy.colors[variant]};
    `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: ${g(0.5)};
        `

      case 'large':
        return css`
          height: ${g(2)};
        `

      default:
        return css`
          height: ${g(1)};
        `
    }
  }}
`
export const Content = styled.div`
  border-radius: 0 ${g(1)} ${g(1)} 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 300ms ease-in-out;

  ${({ value, max }) =>
    css`
      width: ${(value / max) * 100}%;
    `}

  ${({ theme, variant }) =>
    variant &&
    css`
      background-color: ${theme.legacy.colors[variant]};
    `}
      
  ${({ gradient, theme }) =>
    gradient &&
    css`
      background: linear-gradient(90deg, ${gradient.map(variant => theme.legacy.colors[variant]).join(', ')});
    `}
`
export const Progress = styled.div`
  background: ${({ theme }) => theme.legacy.colors.white.default};

  ${({ format, theme }) => {
    switch (format) {
      case 'steps':
        return css`
          color: ${theme.legacy.colors.neutral1.fade(60)};
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 14px;
          margin-bottom: 0;
        `

      case 'document':
        return css`
          background: transparent;

          ${Container} {
            background: transparent;
            height: 2px;
          }
        `

      case 'rounded':
        return css`
          border-radius: ${g(1)};
          overflow: hidden;
        `

      default:
        break
    }
  }}

  ${({ completed }) =>
    completed &&
    css`
      ${Content} {
        border-radius: 0;
      }
    `}
`
