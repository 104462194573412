import { StatusEnum } from '@pretto/app/src/types/gateway/enums'

export const ACCEPTED_STATUS = ['analysis', 'confirmation', 'negotiation', 'agreement', 'appointment', 'signature']

export const menuSwitch = ({ hasExpert, isLowPotential, isOnboardingDone, status, typology }) => {
  switch (typology) {
    case 'client':
      if (ACCEPTED_STATUS.includes(status)) return 'leadAcceptedMenu'

      if (
        [StatusEnum.End, StatusEnum.LostHardClient, StatusEnum.LostOneChoice, StatusEnum.LostDoubleChoice].includes(
          status
        )
      )
        return 'leadSignatureMenu'

      if (isOnboardingDone) {
        if (isLowPotential) {
          return 'leadMenuLowPotential'
        }
        return hasExpert ? 'leadMenuHighPotential' : 'leadMenuNoExpert'
      }

      if (isLowPotential) {
        return 'leadNoOnboardingMenuNoExpert'
      }

      return hasExpert ? 'leadNoOnboardingMenuHighPotential' : 'leadNoOnboardingMenuNoExpert'

    case 'preLead':
    default:
      return 'preLeadMenu'
  }
}
