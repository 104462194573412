import { productionContext } from '@pretto/app/src/config/netlify'

import * as Sentry from '@sentry/react'

// eslint won't warn on this usage 🤷
const { log: consoleLog, debug: consoleDebug } = console

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (...args: any[]): void => {
  if (
    process.env.NODE_ENV === 'production' ||
    productionContext.includes(process.env.CONTEXT || 'dev') ||
    Boolean(process.env.ENABLE_SENTRY)
  ) {
    Sentry.captureMessage(args.join(' '))
  } else {
    consoleLog(...args)
  }
}

export const debug = consoleDebug
