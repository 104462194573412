import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Status = styled.div`
  ${typo.bodyMedium16};
  display: inline-block;
  min-width: min-content;
  border-radius: ${g(1 / 2)};
  overflow: hidden;
  padding: ${g(1)} ${g(2)};
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary1};
  background-color: ${({ theme }) => theme.colors.primary4};
`
