import type { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'

import { BlocSuggestionsLayout } from '../views/ResultPage/components/BlocSuggestionsLayout/BlocSuggestionsLayout'

import { useBlocSuggestionsLoggedOutProps } from './lib/useBlocSuggestionsLoggedOutProps'

export const BlocSuggestionsLoggedOut: React.FC<ResultPageProps> = props => {
  const blocSuggestionsLoggedOutProps = useBlocSuggestionsLoggedOutProps(props)

  return <BlocSuggestionsLayout {...blocSuggestionsLoggedOutProps} />
}
