import ButtonGoogleConnect from '@pretto/bricks/components/buttons/ButtonGoogleConnect'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 35vh;
  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })`
  flex: 1 1 0;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
`

export const GoogleConnect = styled(ButtonGoogleConnect)`
  margin-top: ${g(7)};
  width: 100%;
`
export const Divider = styled.div`
  text-align: center;
  margin: ${g(1)} auto;
`
export const CGU = styled(BaseTypo).attrs({ typo: 'caption' })`
  margin-top: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral2};
`

export const CGULink = styled(Link)`
  text-decoration: underline;
`
