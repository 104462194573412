import Dialog from '@pretto/bricks/components/overlays/Dialog'

import { Button } from '@pretto/zen/atoms/buttons/Button/Button'
import { ModalHeader } from '@pretto/zen/main/modals/ModalHeader/ModalHeader'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import {
  ProjectModalContext,
  ProjectModalContextType,
  STATUS,
} from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext, useState } from 'react'

import * as S from './NewProjectModal.styles'

interface NewProjectModalProps {
  onClose: () => void
}

export const NewProjectModal = ({ onClose }: NewProjectModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { setToPrettoSearch, status, unlinkAccount, propertySearchData } =
    useContext<ProjectModalContextType>(ProjectModalContext)
  const { status: userStatus, lostReason } = useUser()

  const trackAction = useTracking()

  const handleConfirm = async () => {
    if (userStatus === 'end') {
      trackAction('WIN_CONFIRMATION_NEW_PROJECT_CLICKED')
    }

    if (lostReason) {
      trackAction('LOST_CONFIRMATION_NEW_PROJECT_CLICKED', {
        lostReason,
        dashboardOptions: userStatus,
      })
    }

    if (!propertySearchData) {
      setIsLoading(true)
      await unlinkAccount()
      return
    }

    setToPrettoSearch()
  }

  const handleCancel = () => {
    if (userStatus === 'end') {
      trackAction('WIN_CANCEL_NEW_PROJECT_CLICKED')
    }

    if (lostReason) {
      trackAction('LOST_CANCEL_NEW_PROJECT_CLICKED', {
        lostReason,
        dashboardOptions: userStatus,
      })
    }
    onClose()
  }

  return (
    <Dialog isOpen={status === STATUS.newProject} onRequestClose={onClose}>
      <ModalHeader title="Attention" onClose={onClose} />
      <S.Content>
        <S.Description>
          Vous allez commencer un nouveau projet, les informations et les documents que vous aviez envoyés précédemment
          seront supprimés.
        </S.Description>
        <Button onClick={handleConfirm} isLoading={isLoading}>
          Confirmer
        </Button>
        <S.Cancel label="Annuler" onClick={handleCancel} />
      </S.Content>
    </Dialog>
  )
}
