import { Event, EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'

export type EventDefinition<E extends EventName, P = unknown> = P extends Record<string, unknown>
  ? {
      eventName: E
      eventPayload: P & Record<string, unknown>
      eventTrackingType?: EventTrackingType
    }
  : {
      eventName: E
      eventPayload?: Record<string, unknown>
      eventTrackingType?: EventTrackingType
    }

export type EventPayload = Record<string, string | boolean | number | null | undefined>

export enum EventTrackingType {
  ALL,
  FRONTEND,
  BACKEND,
  SEGMENT,
  NONE,
}

export interface TrackingProps {
  defaultEventTrackingType?: EventTrackingType
  events: Event | Event[]
}
