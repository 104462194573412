import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const TYPES = {
  action: {
    backgroundColor: 'neutral4',
    textColor: 'neutral1',
  },
  success: {
    backgroundColor: 'primary4',
    isSecondary: true,
    textColor: 'primary1',
  },
}

const BannerNextStep = ({ buttonText, description, picto, title, type, href, ...props }) => {
  const { backgroundColor, isSecondary, textColor } = TYPES[type]

  return (
    <S.Container $backgroundColor={backgroundColor} {...props}>
      <S.NextStep>
        <Responsive max="laptop">
          <S.NextStepText $textColor={textColor}>
            <S.Headline>
              <S.Picto $textColor={textColor} as={picto} />
              {title}
            </S.Headline>
            {description}
          </S.NextStepText>
        </Responsive>
        <Responsive min="laptop">
          <S.Picto $textColor={textColor} as={picto} />
          <S.NextStepText $textColor={textColor}>
            <BaseTypo typo="heading4">{title}</BaseTypo>
            {description}
          </S.NextStepText>
        </Responsive>
        <S.NextStepButton isSecondary={isSecondary} href={href}>
          {buttonText}
        </S.NextStepButton>
      </S.NextStep>
    </S.Container>
  )
}

BannerNextStep.defaultProps = {
  type: 'success',
}

BannerNextStep.propTypes = {
  /** color to apply to the background */
  backgroundColor: PropTypes.string,
  /** text to display on button */
  buttonText: PropTypes.string,
  /** description to show */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** url to redirect to */
  href: PropTypes.string,
  /** tells if button is in secondary mode */
  isSecondary: PropTypes.bool,
  /** picto to display on side */
  picto: PropTypes.any,
  /** text color */
  textColor: PropTypes.string,
  /** banner title */
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(TYPES)),
}

export default BannerNextStep
