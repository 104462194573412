import * as filters from '@pretto/app-core/lib/filters'

import { Percent } from '@pretto/picto'

export const solicitedBrokerRates = {
  id: 'sollicited_broker_rates',
  decoder: filters.applyFilters([
    filters.applyFilter('project.solicited_brokers[0].duration', filters.formatDecodeInteger),
    filters.applyFilter('project.solicited_brokers[0].rate', filters.formatDecodeNumber),
  ]),
  encoder: filters.applyFilters([
    filters.applyFilter('project.solicited_brokers[0].duration', filters.formatEncodeInteger),
    filters.applyFilter('project.solicited_brokers[0].rate', filters.formatEncodeNumber),
  ]),
  fields: [
    {
      type: 'number',
      name: 'project.solicited_brokers[0].rate',
      label: 'Quels termes votre courtier vous propose-t-il ?',
      fieldProps: {
        inputProps: {
          placeholder: 'Taux (en %)',
        },
        placeholderAsLabel: true,
        picto: Percent,
      },
    },
    {
      type: 'number',
      name: 'project.solicited_brokers[0].duration',
      fieldProps: {
        inputProps: {
          placeholder: 'Durée (en années)',
        },
        placeholderAsLabel: true,
      },
    },
  ],
  jumps: 'avoid_banks',
  track({ values }) {
    return [
      'ONBOARDING_SOLLICITED_BROKER_RATES',
      {
        onboarding_sollicited_broker_rates_duration: filters.formatEncodeInteger(
          values['project.solicited_brokers[0].duration']
        ),
        onboarding_sollicited_broker_rates_rate: filters.formatEncodeNumber(
          values['project.solicited_brokers[0].rate']
        ),
      },
    ]
  },
}
