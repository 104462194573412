import MainSectionFolder from '@pretto/bricks/app/dashboard/components/MainSectionFolder'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Link from '@pretto/bricks/website/utility/Link'

import { Check, ChevronRightBold, UserCircle } from '@pretto/picto'

import PropTypes from 'prop-types'
import { createElement, Fragment } from 'react'

import * as S from './styles'

const getCtaProps = status =>
  status === 'signed'
    ? {
        color: 'primary2',
        htmlTag: 'strong',
        picto: Check,
      }
    : {
        htmlTag: 'span',
        picto: ChevronRightBold,
      }

const getStatus = mortgagors => {
  if (mortgagors.every(({ status }) => status === 'pending')) return 'ALL_PENDING'
  if (mortgagors.every(({ status }) => status === 'signed')) return 'ALL_SIGNED'
  return 'DEFAULT'
}

const DashboardMandateCard = ({
  actionUrl,
  alertMessage,
  mortgagors,
  isMandateSigned,
  isSepaSigned,
  onActionClick,
  ...props
}) => {
  return (
    <MainSectionFolder
      actionLabel={isMandateSigned ? 'Remplir l’autorisation SEPA' : 'Consulter et Signer'}
      actionUrl={actionUrl}
      alertMessage={alertMessage}
      description={
        isSepaSigned && isMandateSigned
          ? 'Vous avez bien signé votre mandat. Nos équipes vont entamer la négociation avec les banques.'
          : `Nous avons préparé votre mandat. ${
              mortgagors.length > 1 ? 'Vous et votre coemprunteur' : 'Vous'
            } devez le signer électroniquement pour nous permettre de négocier en votre nom avec les banques.`
      }
      onActionClick={onActionClick}
      tag="Étape en cours"
      title={isSepaSigned && isMandateSigned ? 'Votre mandat est signé' : 'Signez votre mandat'}
      {...props}
    >
      <S.MortgagorsInfos>
        <S.VerifyInfos>Vérifiez vos coordonnées de contact :</S.VerifyInfos>

        {mortgagors.map(({ email, href, status }) => {
          const { htmlTag, color, picto } = getCtaProps(status)

          return (
            <Fragment key={email}>
              <Divider />

              {createElement(
                href ? Link : Fragment,
                href ? { href } : null,
                <S.Mortgagor $color={color} status={getStatus(mortgagors)}>
                  <UserCircle />
                  <S.Email $tag={htmlTag}>{email}</S.Email>
                  <S.Picto $picto={picto} />
                </S.Mortgagor>
              )}
            </Fragment>
          )
        })}
      </S.MortgagorsInfos>

      {!!alertMessage && <Divider />}
    </MainSectionFolder>
  )
}

DashboardMandateCard.propTypes = {
  /** Url to follow when the card main content is being pressed. */
  actionUrl: PropTypes.string.isRequired,
  /** Message to be displayed in the alert. Defines whether or not an alert should be displayed. */
  alertMessage: PropTypes.node,
  /** Whether or not the mandate is signed for all mortgagors. */
  isMandateSigned: PropTypes.bool.isRequired,
  /** Whether or not the sepa is signed. */
  isSepaSigned: PropTypes.bool.isRequired,
  /** List of mortgagors the mandate involve. */
  mortgagors: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      href: PropTypes.string,
      status: PropTypes.oneOf(['pending', 'signed']).isRequired,
    })
  ),
  /** Triggered whenever an action link is being pressed. */
  onActionClick: PropTypes.func.isRequired,
}

export default DashboardMandateCard
