import 'dayjs/locale/fr'
import 'dayjs/locale/en'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.locale('fr')

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)

export default dayjs

export const temporal = dayjs
