import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Contact = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const Item = styled.div`
  margin-bottom: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 1 1 50%;
    margin-right: ${g(4)};
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`
export const Title = styled.div`
  margin-bottom: ${g(2)};
`
export const Address = styled.div`
  margin-bottom: ${g(1)};
`
export const Link = styled.a`
  text-decoration: underline;

  &:link {
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
  }
`
