import { SmileyFrown, SmileyHappy, SmileyNeutral } from '@pretto/picto'

import * as S from './styles'

export const chooseCorrectSmiley = severity => {
  let iconProps = {
    backgroundColorVariant: 'primary',
    src: <SmileyHappy />,
  }

  switch (severity) {
    case 'block':
      iconProps = {
        backgroundColorVariant: 'error2',
        src: <SmileyFrown />,
      }
      break
    case 'warn':
      iconProps = {
        backgroundColorVariant: 'warning2',
        src: <SmileyNeutral />,
      }
      break
    default:
      break
  }

  return <S.Icon {...iconProps} />
}
