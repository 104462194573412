import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'
import { Snackbar as SnackbarComponent } from '@pretto/zen/reveal/main/banners/snackbar/Snackbar'

import { CalcTable as CalcTableComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/CalcTable/CalcTable'
import { Simulator as SimulatorComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/Simulator/Simulator'

import styled, { css } from 'styled-components'

export const Button = styled(PrimaryButtonFix)`
  align-self: center;
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    margin-top: ${g(4)};
    order: 3;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    align-self: flex-start;
  }
`

export const CalcTable = styled(CalcTableComponent)`
  padding-bottom: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: none;
    padding-bottom: 0;
  }
`

export const CalcTableAccordeon = styled(CollapsibleRow)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-grow: 1;

    div {
      height: 100%;
    }
  }
`

export const CalcTableAccordeonChild = styled.div`
  padding-top: ${g(3)};
`

export const CalcTableLoadingArea = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(1)};
  display: flex;
  justify-content: center;
  padding: ${g(4)} 0;
`

export const Component = styled.section`
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${grid()};
  }
`

interface ContainerProps {
  $isLast: boolean
}

export const Container = styled.div<ContainerProps>`
  ${grid()};
  position: relative;
  row-gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} 0 ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(5)} 0 ${g(10)};
    row-gap: ${g(3)};

    ${({ $isLast }) =>
      $isLast &&
      css`
        border-radius: ${g(1)};
        padding: ${g(7)} 0 ${g(10)};
      `};
  }
`

export const Snackbar = styled(SnackbarComponent)`
  ${column([1, 6])};
  margin-bottom: -16px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
  }
`

export const Error = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([8, 5])};
    align-self: center;
    grid-row: 1 / span 3;
    margin-right: ${g(5)};
    text-align: left;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([8, 4])};
    margin-right: 0;
  }
`

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typos.body4};
`

export const ErrorTitle = styled.h2`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
  }
`

interface HeroProps {
  $isDisabled: boolean
}

export const Hero = styled.header<HeroProps>`
  ${column([1, 6])};
  ${transition({ propertyName: 'color' })};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  gap: ${g(2)};
  justify-content: space-between;
  min-height: ${g(13)};
  padding: ${g(2)};
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.primary1Disabled};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    border-radius: ${g(1)};
    padding: ${g(2)} ${g(3)};
    position: static;
    z-index: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 6])};
    margin-left: ${g(5)};
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 5])};
    margin-left: 0;
  }
`

export const HeroAnchor = styled.button`
  ${({ theme }) => theme.typos.captionUnderline};
  appearance: none;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: left;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const HeroLabel = styled.span`
  ${({ theme }) => theme.typos.body4Medium};
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${g(1 / 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading5};
  }
`

export const Price = styled.span`
  ${({ theme }) => theme.typos.body2};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.body1};
  }
`

export const Result = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  margin-top: ${g(2)};
  scroll-margin: ${g(15)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([8, 5])};
    margin-top: ${g(1)};
    margin-right: ${g(5)};
    grid-row: 1 / span 3;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([8, 4])};
    margin-right: 0;
  }
`

interface ResultExpandButtonProps {
  $isExpanded: boolean
}

export const ResultExpandButton = styled.button<ResultExpandButtonProps>`
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  bottom: 0;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      transform: scaleY(-1);
    `}

  svg {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const ResultHeader = styled.header<ResultExpandButtonProps>`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-bottom: ${({ $isExpanded }) => ($isExpanded ? g(0) : g(4))};
`

export const ResultTitle = styled.p`
  ${({ theme }) => theme.typos.heading6};
  display: flex;
  padding-right: ${g(3)};
  position: relative;
`

export const Simulator = styled(SimulatorComponent)`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 6])};
    margin-left: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 5])};
    margin-left: 0;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading3};
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 6])};
    display: block;
    margin-left: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 5])};
    margin-left: 0;
`
