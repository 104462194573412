import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import TipBubble from '@pretto/bricks/app/sentences/components/TipBubble'
import PreviousNextNavBar from '@pretto/bricks/components/bars/PreviousNextNavBar'
import AutosizeField from '@pretto/bricks/components/form/AutosizeField'
import SelectField from '@pretto/bricks/components/form/SelectField'
import { Grid } from '@pretto/bricks/components/layout/Grid'
import Text from '@pretto/bricks/components/typography/Text'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import ModalAttribution from './children/ModalAttribution'
import Sidebar from './children/Sidebar'
import * as S from './styles'

export const renderSentence = ({ data }, onChange, values, errors, isMutating) => {
  return renderSentenceComponents(data.components, onChange, values, errors, isMutating)
}

export const renderSentenceComponents = (components, onChange, values, errors, isMutating) => {
  return components.reduce((previous, component, index) => {
    if (!component) {
      return previous
    }

    const spacer = index === 0 ? [] : [' ']

    if (typeof component === 'string') {
      if (/^(,|\.)/.test(component)) {
        return [...previous, component]
      }

      return [...previous, ...spacer, component]
    }

    if (Array.isArray(component)) {
      return [...previous, ...renderSentenceComponents(component, onChange, values, errors, isMutating)]
    }

    const handleChange = value => {
      onChange(component.name, value)
    }

    switch (component.type) {
      case 'select': {
        const handleChange = ({ value }) => {
          onChange(component.name, value)
        }

        return [
          ...previous,
          ...spacer,
          <SelectField
            {...component.props}
            disabled={isMutating}
            key={component.name}
            onChange={handleChange}
            value={values[component.name]}
          />,
        ]
      }

      case 'email':
      case 'phone':
      case 'text': {
        return [
          ...previous,
          ...spacer,
          <AutosizeField
            {...component.props}
            error={errors.some(({ data }) => data.name === component.name)}
            disabled={isMutating}
            format="sentence"
            key={component.name}
            onChange={handleChange}
            value={values[component.name] ?? ''}
          />,
          errors.some(({ data }) => data.name === component.name) && <S.ErrorSentenceIcon />,
        ]
      }

      default:
        return previous
    }
  }, [])
}

const SimulationBookingPage = ({
  disclaimer,
  errors,
  footerProps,
  isDisabled,
  isMutating,
  lines,
  isTimelineModalOpen,
  onChange,
  onToggleTimelineMobile,
  values,
  modalProps,
  timelineProps,
}) => {
  const fieldErrors = errors.filter(({ type }) => type === 'field').map(({ data }) => data.title)
  const plainErrors = errors.filter(({ type }) => type === 'plain')

  return (
    <LayoutStickyFooter>
      <Grid>
        <Responsive min="laptop">
          <Sidebar {...timelineProps} />
        </Responsive>

        <S.Main>
          <Responsive max="laptop">
            <S.Timeline onClick={onToggleTimelineMobile} progress={0.95} />
            <S.Modal isOpen={isTimelineModalOpen} variant="neutral-1">
              <S.CloseIcon onClick={onToggleTimelineMobile} />
              <Sidebar {...timelineProps} />
            </S.Modal>
          </Responsive>
          <S.Title>Empruntez bien accompagné !</S.Title>
          <TipBubble>
            Bénéficiez des conseils gratuits d'un expert crédit afin d'optimiser votre financement. C’est totalement
            optionnel !
          </TipBubble>
          {lines.map((line, index) => {
            switch (line.type) {
              case 'sentence':
                return <S.Sentence key={index}>{renderSentence(line, onChange, values, errors, isMutating)}</S.Sentence>

              default:
                return null
            }
          })}

          {fieldErrors.length > 0 && (
            <S.Error>
              <Text size="small" variant="error">
                Oops,{' '}
                {[...(fieldErrors.length > 1 ? [fieldErrors.slice(0, -1).join(', ')] : []), fieldErrors.slice(-1)].join(
                  ' et '
                )}{' '}
                ne semble{fieldErrors.length > 1 ? 'nt' : ''} pas valide{fieldErrors.length > 1 ? 's' : ''}. Veuillez
                vérifier votre saisie.
              </Text>
            </S.Error>
          )}

          {plainErrors.map(({ data: { message } }) => (
            <S.Error key={message}>
              <Text size="small" variant="error">
                {message}
              </Text>
            </S.Error>
          ))}

          {!isDisabled && disclaimer && (
            <S.Disclaimer>
              <Text size="x-small" variant="neutral-1-60">
                {disclaimer}
              </Text>
            </S.Disclaimer>
          )}
        </S.Main>
        <ModalAttribution {...modalProps} />
      </Grid>

      <PreviousNextNavBar {...footerProps} />
    </LayoutStickyFooter>
  )
}

SimulationBookingPage.propTypes = {
  /** Disclaimer who may be displayed below the action button. */
  disclaimer: PropTypes.node,
  /** List of error. */
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      type: PropTypes.oneOf(['field', 'plain']).isRequired,
    })
  ).isRequired,
  footerProps: PropTypes.object.isRequired,
  /** Whether or not the action button should be visible. */
  isDisabled: PropTypes.bool.isRequired,
  /** Whether or not the action button should be showing a loading state. */
  isMutating: PropTypes.bool.isRequired,
  isTimelineModalOpen: PropTypes.bool,
  /** Sentences lines. */
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.any.isRequired,
      type: PropTypes.oneOf(['messages', 'sentence']).isRequired,
    })
  ).isRequired,
  modalProps: PropTypes.object,
  /** Event to be triggered against each field interaction. */
  onChange: PropTypes.func.isRequired,
  onToggleTimelineMobile: PropTypes.func,
  timelineProps: PropTypes.object,
  /** Values to be used as fields values. */
  values: PropTypes.object.isRequired,
}

export default SimulationBookingPage
