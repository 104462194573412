import { g, tablet } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Content = styled.div`
  padding: ${g(2)} 0 ${g(5)};

  @media screen and (min-width: ${tablet.min}) {
    display: flex;
    padding: ${g(4)} 0 ${g(7)};
  }
`

export const Error = styled.div`
  padding-bottom: ${g(2)};

  @media screen and (min-width: ${tablet.min}) {
    padding-bottom: ${g(4)};
  }
`

export const Field = styled.div`
  & + & {
    margin-top: ${g(3)};

    @media screen and (min-width: ${tablet.min}) {
      margin-top: ${g(4)};
    }
  }
`

export const Form = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${tablet.min}) {
    margin-bottom: ${g(7)};
  }
`

export const FormButton = styled.div`
  margin-top: ${g(4)};
  text-align: center;

  @media screen and (min-width: ${tablet.min}) {
    margin-top: ${g(6)};
  }
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  padding: ${g(2)} 0 ${g(3)};

  @media screen and (min-width: ${tablet.min}) {
    padding: ${g(4)} 0 ${g(3)};
  }
`

export const HeaderDescription = styled.div`
  margin-bottom: ${g(3)};

  span {
    cursor: pointer;
    text-decoration: underline;
  }

  @media screen and (min-width: ${tablet.min}) {
    margin-bottom: ${g(6)};
  }
`

export const HeaderTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${g(3)};
`

export const HeaderTitleEmoji = styled.div`
  margin-right: ${g(2)};

  @media screen and (min-width: ${tablet.min}) {
    margin-right: ${g(3)};
  }
`

export const Highlight = styled.div`
  @media screen and (min-width: ${tablet.min}) {
    flex-basis: ${100 / 3}%;
    max-width: ${100 / 3}%;
  }
`

export const Highlights = styled.div`
  @media screen and (min-width: ${tablet.min}) {
    display: flex;
  }
`

export const Invitation = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${tablet.min}) {
    flex-basis: 60%;
    margin-bottom: 0;
    max-width: 60%;
  }
`

export const InvitationTitle = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${tablet.min}) {
    margin-bottom: ${g(6)};
  }
`

export const Invited = styled.div`
  @media screen and (min-width: ${tablet.min}) {
    flex-basis: ${100 / 3}%;
    margin-left: auto;
    max-width: ${100 / 3}%;
  }
`

export const InvitedTitle = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${tablet.min}) {
    margin-bottom: ${g(3)};
  }
`

export const InviteTrigger = styled.div`
  margin-bottom: ${g(4)};
`

export const More = styled.div`
  margin-top: ${g(2)};
  text-align: center;
`

export const MoreButton = styled.button`
  background-color: transparent;
  font: inherit;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: 0px transparent;
  }
`
