import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import { DividerVertical } from '@pretto/zen/atoms/dividers/DividerVertical/DividerVertical'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${typo.caption12};
  padding: ${g(2)} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
    ${column(3)};
  }
`

export const VerticalBar = styled(DividerVertical)`
  margin: 0 ${g(1, 2)};
`

export const VisioInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${g(0.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const GreenDot = styled.div`
  width: ${g(1)};
  height: ${g(1)};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary2};
`

export const FooterDot = styled(GreenDot)`
  margin-right: ${g(1)};
`

export const Timezone = styled.div``