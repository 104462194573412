import {
  ADDRESS,
  CHECKBOX_BLOCK,
  CITY,
  COBUYER_FIRST_NAME,
  COBUYER_GENDER,
  COBUYER_LAST_NAME,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  ZIPCODE,
} from '@pretto/app/src/purchaseOffer/config/personnalInformation'
import { BlockSentencesInput, Gender, Sentence } from '@pretto/app/src/purchaseOffer/config/types'
import { formatSentences } from '@pretto/app/src/purchaseOffer/lib/formatSentences'
import { useInputCheckbox } from '@pretto/app/src/purchaseOffer/lib/useInputCheckbox'
import { useInputDefault } from '@pretto/app/src/purchaseOffer/lib/useInputDefault'
import { useInputSelect } from '@pretto/app/src/purchaseOffer/lib/useInputSelect'
import { SectionProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/Section/Section'
import { UserCircle } from '@pretto/picto'

export const usePersonnalInformation = () => {
  const { input: cobuyerBlock, ...hasCobuyer } = useInputCheckbox(CHECKBOX_BLOCK)
  const { input: genderInput, ...gender } = useInputSelect<Gender>(GENDER)
  const { input: firstNameInput, ...firstName } = useInputDefault(FIRST_NAME)
  const { input: lastNameInput, ...lastName } = useInputDefault(LAST_NAME)
  const { input: addressInput, ...currentAddress } = useInputDefault(ADDRESS)
  const { input: zipcodeInput, ...currentZipcode } = useInputDefault(ZIPCODE)
  const { input: cityInput, ...currentCity } = useInputDefault(CITY)
  const { input: cobuyerGenderInput, ...cobuyerGender } = useInputSelect<Gender>(COBUYER_GENDER)
  const { input: cobuyerFirstNameInput, ...cobuyerFirstName } = useInputDefault(COBUYER_FIRST_NAME)
  const { input: cobuyerLastNameInput, ...cobuyerLastName } = useInputDefault(COBUYER_LAST_NAME)

  const sentences: BlockSentencesInput = {
    props: {
      picto: UserCircle,
      sentences: formatSentences([
        ['Vous vous appelez ', genderInput, ' ', firstNameInput, ' ', lastNameInput, '.'],
        ['Vous habitez actuellement au ', addressInput, ', ', zipcodeInput, ' ', cityInput, '.'],
      ]),
    },
    type: 'sentences',
  }

  const extraSentence: Sentence | false = hasCobuyer.value === true && [
    'Votre co-acheteur s’appelle ',
    cobuyerGenderInput,
    ' ',
    cobuyerFirstNameInput,
    ' ',
    cobuyerLastNameInput,
    '.',
  ]

  if (extraSentence) {
    sentences.props.extraSentence = formatSentences([extraSentence])
  }

  const section: SectionProps = {
    blocks: [cobuyerBlock, sentences].filter(Boolean),
    title: 'Vos informations personnelles',
  }

  const fields = {
    cobuyerFirstName: hasCobuyer.value === true ? cobuyerFirstName : undefined,
    cobuyerGender: hasCobuyer.value === true ? cobuyerGender : undefined,
    cobuyerLastName: hasCobuyer.value === true ? cobuyerLastName : undefined,
    currentAddress,
    currentCity,
    currentZipcode,
    firstName,
    gender,
    hasCobuyer,
    lastName,
  }

  return { fields, section }
}
