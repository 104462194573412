import FormCheckboxes from '@pretto/bricks/app/property-search/components/FormCheckboxes'
import FormSection from '@pretto/bricks/app/property-search/components/FormSection'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import InputRadioGroup from '@pretto/bricks/components/form/InputRadioGroup'
import SelectField from '@pretto/bricks/components/form/SelectField'
import SentenceFieldSimple from '@pretto/bricks/components/form/SentenceFieldSimple'
import TextField from '@pretto/bricks/components/form/TextField'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import Row from '@pretto/bricks/shared/components/Row'

import * as F from '../Fields'

export const renderField = (field, onChange, onVisit, values, error) => {
  const { fieldProps = {}, label, name, type } = field

  const handleBlur = () => {
    onVisit(name)
  }

  const handleNumberChange = value => {
    handleChange(value ? parseInt(value) : null)
  }

  const handleChange = value => {
    onChange(name, value)
    onVisit(name)
  }

  switch (type) {
    case 'checkbox': {
      const handleChange = () => {
        onChange(name, !values[name])
        onVisit(name)
      }

      return (
        <InputCheckbox
          id={name}
          inputProps={{ onBlur: handleBlur }}
          isChecked={values[name]}
          label={label}
          onChange={handleChange}
        />
      )
    }

    case 'checkboxes':
      return (
        <FormSection title={label}>
          <FormCheckboxes>
            {fieldProps.options.map(({ id, label, value }) => {
              const handleChange = () => {
                if (values[name].includes(value)) {
                  onChange(
                    name,
                    values[name].filter(currentValue => currentValue !== value)
                  )

                  return
                }

                onChange(name, [...values[name], value])
              }

              return (
                <InputCheckbox
                  id={id}
                  inputProps={{
                    onBlur: handleBlur,
                  }}
                  isChecked={values[name].includes(value)}
                  key={id}
                  label={label}
                  onChange={handleChange}
                />
              )
            })}
          </FormCheckboxes>
        </FormSection>
      )

    case 'cities':
      return <F.CitiesField {...fieldProps} onBlur={handleBlur} onChange={handleChange} value={values[name]} />

    case 'area':
    case 'price':
      return (
        <Row
          label={label}
          value={
            <SentenceFieldSimple
              inputProps={{ placeholder: fieldProps.placeholder }}
              onChange={handleNumberChange}
              picto={fieldProps.picto}
              state={error ? 'error' : 'default'}
              suffix={fieldProps.suffix}
              value={values[name]}
            />
          }
        ></Row>
      )

    case 'radio':
      return (
        <FormSection title={label}>
          <InputRadioGroup name="radio" onChange={handleChange} valueSelected={values[name]} {...fieldProps} />
        </FormSection>
      )

    case 'select': {
      const handleChange = ({ value }) => {
        onChange(name, value)
      }

      return (
        <Row
          label={label}
          value={
            <span>
              <SelectField autofocus {...fieldProps} onBlur={handleBlur} onChange={handleChange} value={values[name]} />
            </span>
          }
        />
      )
    }

    case 'text':
      return (
        <FormSection title={label}>
          <TextField {...fieldProps} onBlur={handleBlur} onChange={handleChange} value={values[name]} />
        </FormSection>
      )

    default:
      return null
  }
}

export const renderFields = (
  fields,
  onChange,
  onVisit,
  values,
  debouncedValues,
  isEditable,
  visitedFieldNames = [],
  ...args
) =>
  fields.reduce((previous, { condition = true, flags = [], ...field }, index) => {
    const fieldCondition = funcToItem(condition, values, isEditable, ...args)

    if (!fieldCondition) {
      return previous
    }

    const error =
      !isEditable && !visitedFieldNames.includes(field.name)
        ? false
        : flags.reduce((previous, { condition, type }) => {
            if (type !== 'error') {
              return previous
            }

            const flagCondition = funcToItem(condition, values[field.name], values, debouncedValues)

            if (!flagCondition) {
              return previous
            }

            return true
          }, false)

    const component = renderField(field, onChange, onVisit, values, error)
    const key = field.name ?? index

    return [...previous, { component, key }]
  }, [])

export const renderFlags = (fields, values, debouncedValues, isEditable, visitedFieldNames = [], props, ...args) =>
  fields.reduce((previous, { condition = true, flags = [], name }) => {
    const fieldCondition = funcToItem(condition, values, isEditable, ...args)

    if (!fieldCondition || (!isEditable && !visitedFieldNames.includes(name))) {
      return previous
    }

    return flags.reduce((previous, { condition, children, ...flag }) => {
      const flagCondition = funcToItem(condition, values[name], values, debouncedValues, props)

      if (!flagCondition) {
        return previous
      }

      return [...previous, { ...flag, children: funcToItem(children, props), key: name }]
    }, previous)
  }, [])

export const renderSections = (
  sections,
  onChange,
  onVisit,
  values,
  debouncedValues,
  isEditable,
  visitedFieldNames,
  props,
  ...args
) =>
  sections.map(section => ({
    ...section,
    fields: renderFields(
      section.fields,
      onChange,
      onVisit,
      values,
      debouncedValues,
      isEditable,
      visitedFieldNames,
      ...args
    ),
    flags: renderFlags(section.fields, values, debouncedValues, isEditable, visitedFieldNames, props, ...args),
    footer: funcToItem(section.footer, ...args),
    title: funcToItem(section.title, isEditable),
  }))
