import { AddressPage } from '@pretto/app/src/Sentences/v2/Lodging/Address/Containers/AddressPage'
import { AgeChildrenPage } from '@pretto/app/src/Sentences/v2/Lodging/AgeChildren/Containers/AgeChildrenPage'
import { LiveTogetherPage } from '@pretto/app/src/Sentences/v2/Lodging/LiveTogether/Containers/LiveTogetherPage'
import { SituationPage } from '@pretto/app/src/Sentences/v2/Lodging/Situation/Containers/SituationPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const LodgingController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/lodging/situation')}
        component={SituationPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/lodging/address')}
        component={AddressPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/lodging/liveTogether')}
        component={LiveTogetherPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/lodging/(ageChildren)?')}
        component={AgeChildrenPage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
