import temporal from '@pretto/bricks/core/utility/temporal'

import duration from '@pretto/app/src/lib/duration'
import type { Booking as BookingType } from '@pretto/app/src/types/gateway/schema'

import capitalize from 'lodash/capitalize'

export const getBookingDateTime = ({ end, start }: BookingType): { date: string; duration: string; isNow: boolean } => {
  const date = capitalize(temporal(start).format('dddd LL [à] HH[h]mm'))
  const offset = temporal(end).diff(start, 'minutes')
  const now = temporal()
  const isNow = now.diff(start, 'minutes') <= 10 && now.diff(start, 'minutes') >= 0

  return {
    date,
    duration: duration(offset).toString(),
    isNow,
  }
}
