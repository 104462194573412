// Computes an estimate of the deal value from the loan amount, via brackets on the loan amount
// From 50 to 250k : 1% (from 500 to 2500)
// From 250k to 600k : 0.5% (from 2500 to 4250)
// From 600k to 1M : 0.25% (from 4250 to 5250)
// More than 1M: 0% (capped at 5250)
// Finally, take into account the transformation probability (5%)
export default function dealValue(loanAmount, brokerageFees) {
  let valueIfConverted = 0

  if (loanAmount <= 250000) {
    valueIfConverted = loanAmount * 0.01
  } else if (loanAmount <= 600000) {
    valueIfConverted = 2500 + (loanAmount - 250000) * 0.005
  } else if (loanAmount <= 1000000) {
    valueIfConverted = 4250 + (loanAmount - 600000) * 0.0025
  } else {
    valueIfConverted = 5250
  }

  return (brokerageFees + valueIfConverted) * 0.05
}
