import MaturityUpdatePageTemplate from '@pretto/bricks/app/maturity/components/MaturityUpdateTemplate'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { MATURITY_EVENT } from '@pretto/app/src/Project/Containers/MaturityPage'
import { TimesBold } from '@pretto/picto'

import { withApollo } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import { useState } from 'react'

import useMaturityUpdateController from '../../../Form/lib/useMaturityUpdateController'
import { useUser } from '../../../User/Containers/UserProvider'
import { UPDATE_PROJECT } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'

const MaturityUpdateDialog = ({ client, description, onClose, onSubmit, isOpen, projectStatus, isLostHidden }) => {
  const trackAction = useTracking()
  const { refetchUser } = useUser()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (maturity, agreementSignDate) => {
    setLoading(true)
    const project = { purchase: { agreement_sign_date: agreementSignDate ? agreementSignDate : null, maturity } }

    await client.mutate({
      mutation: UPDATE_PROJECT,
      variables: { project: JSON.stringify(project) },
    })

    trackAction(MATURITY_EVENT, {
      origin: 'modal',
      value: maturity,
    })

    try {
      refetchUser()
    } catch (error) {}

    onClose()
    if (onSubmit) {
      onSubmit()
    }
    setLoading(false)
  }

  const {
    handleSubmit: onControllerSubmit,
    inputProps,
    isErrored,
    maturity,
    radioProps,
  } = useMaturityUpdateController({ isLoading: loading, onClick: handleSubmit, projectStatus, isLostHidden })

  return (
    <Dialog isOpen={isOpen} onRequestClose={onClose}>
      <DialogTemplate
        navbarProps={{
          iconRight: TimesBold,
          iconRightAction: onClose,
        }}
        hideDivider
      >
        <MaturityUpdatePageTemplate
          description={description}
          isErrored={isErrored}
          onSubmit={onControllerSubmit}
          maturity={maturity}
          projectStatus={projectStatus}
          isLoading={loading}
          inputProps={inputProps}
          radioProps={radioProps}
        />
      </DialogTemplate>
    </Dialog>
  )
}

MaturityUpdateDialog.propTypes = {
  client: PropTypes.object,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isLostHidden: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  projectStatus: PropTypes.oneOf([
    'under_study',
    'search_started',
    'offer_made',
    'offer_accepted',
    'sale_agreement_signed',
  ]),
}

export default withApollo(MaturityUpdateDialog)
