import { breakpoints, g } from '@pretto/bricks/components/layout'

import { BlockInfo } from '@pretto/zen/main/blockInfos/BlockInfo/BlockInfo'

import { sponsorshipBlockInfosProps } from '@pretto/app/src/dashboard/config/sponsorshipBlockInfosProps'
import type { Config } from '@pretto/app/src/dashboard/types/card'

import styled from 'styled-components'

const Sponsorship = () => (
  <Card>
    <BlockInfo {...sponsorshipBlockInfosProps} />
  </Card>
)

export const config: Config = {
  component: Sponsorship,
  condition: (data, { hasFeatureAccess }) => hasFeatureAccess(['SPONSORSHIP']),
  name: 'sponsorship',
}

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.mobileL}) {
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
  }
`
