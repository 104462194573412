import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Disclaimer = styled.div`
  margin-bottom: ${g(5)};
`
export const Paragraph = styled.p`
  margin-bottom: ${g(3)};
`
export const Reasons = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
`
export const Item = styled.div`
  margin-bottom: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 0 0 50%;
    margin-bottom: ${g(5)};
    padding-right: ${g(2)};

    &:nth-child(even) {
      padding-right: 0;
      padding-left: ${g(2)};
    }
  }
`
export const Links = styled.div`
  margin-bottom: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
  }

  a {
    text-decoration: underline;
  }
`
