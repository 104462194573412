import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { reduceField } from '@pretto/app-core/application/lib/reduceField'
import { diffValues } from '@pretto/app-core/lib/diffValues'
import { encodeValues } from '@pretto/app-core/lib/encodeValues'
import { getDefaultValues } from '@pretto/app-core/lib/getDefaultValues'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { renderFields } from '@pretto/app-core/lib/renderFields'
import { resetValuesForDisabledFields } from '@pretto/app-core/lib/resetValuesForDisabledFields'

import { useState } from 'react'

import { useUser } from '../../User/Containers/UserProvider'

const DEFAULT_DECODER = values => values
const DEFAULT_ENCODER = DEFAULT_DECODER

export const defaultSchema = { decoder: DEFAULT_DECODER, encoder: DEFAULT_ENCODER, sections: [] }

const renderSections = (sections, values, handleChange, context, hasBeenSubmitted, isPageErrored) =>
  sections.map(({ fields, title }) => ({
    fields: renderFields(fields, handleChange, values, {}, context, hasBeenSubmitted, isPageErrored),
    title,
  }))

const usePropertyController = (
  { decoder = DEFAULT_DECODER, encoder = DEFAULT_ENCODER, sections },
  data,
  onChange = () => {},
  hasBeenSubmitted = true,
  isPageErrored = false
) => {
  const userContext = useUser()
  const defaultValues = getDefaultValues(sections, data)
  const decodedValues = decoder(defaultValues)
  const resetValues = resetValuesForDisabledFields(decodedValues, sections)

  const [errors, setErrors] = useState({})
  const [values, setValues] = useState(resetValues)

  const handleChange = (name, value, error) => {
    if (onChange) {
      onChange()
    }

    setValues(values => resetValuesForDisabledFields({ ...values, [name]: value }, sections, data.project, userContext))

    if (name === 'notary_known') {
      return setErrors({})
    }

    setErrors(errors => ({ ...errors, [name]: funcToItem(error, data.project, userContext) }))
  }

  const getPayload = () => {
    const differedValues = diffValues(resetValues, values)
    const encodedValues = encoder(differedValues)
    const payload = encodeValues(encodedValues)

    return payload
  }

  const isDisabled = reduceField(
    sections,
    (previous, value) => previous || !isFieldValid(value),
    Object.values(errors).some(error => error === true),
    values,
    data,
    userContext
  )

  // TODO controller gets wrong type inference, waiting for file to be rewritten to .ts
  /** @type {{ getPayload: () => any; isDisabled: any; sections: any; values: any }} */
  const controller = {
    getPayload,
    isDisabled,
    sections: renderSections(sections, values, handleChange, userContext, hasBeenSubmitted, isPageErrored),
    values,
  }

  return controller
}

export default usePropertyController
