import { toShortISO } from '@pretto/app/src/Sentences/v2/lib/date'
import { Credit, CreditUpcomingPayload } from '@pretto/app/src/Sentences/v2/types/credit'
import { CreditType } from '@pretto/app/src/Sentences/v2/types/enums'
import { CreditKind } from '@pretto/app/src/types/gateway/enums'

const mapCreditType = (type: CreditType): CreditKind => {
  switch (type) {
    case CreditType.Car:
      return CreditKind.Car
    case CreditType.Consumer:
      return CreditKind.Consumer
    case CreditType.LOA:
      return CreditKind.Loa
    case CreditType.Mortgage:
      return CreditKind.Mortgage
    case CreditType.Student:
      return CreditKind.Student
    case CreditType.Other:
    default:
      return CreditKind.Other
  }
}

export const mapCredit = (credit: Credit): CreditUpcomingPayload => {
  const payloadCredit: CreditUpcomingPayload = {
    id: credit.id,
  }

  if (credit._delete) {
    payloadCredit._delete = true

    return payloadCredit
  }

  if (credit.endDate) payloadCredit.end_date = toShortISO(credit.endDate)
  if (credit.type) payloadCredit.kind = mapCreditType(credit.type)
  if (credit.amount) payloadCredit.payment = credit.amount

  return payloadCredit
}
