import { breakpoints, g } from '@pretto/bricks/components/layout'
import LinkComponent from '@pretto/bricks/website/utility/Link/index'

import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import styled from 'styled-components'

export const CloseButton = styled.button`
  ${({ theme }) => theme.typos.linkUnderline};
  align-items: center;
  appearance: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: ${g(-1)} ${g(-1)} 0 0;
  padding: 0;
  height: ${g(4)};
  width: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: auto;
    margin: 0;
    width: auto;
  }
`

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
  padding: 0 ${g(2, 4)} ${g(3)};

  & > :nth-last-child(2) {
    margin-bottom: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0 ${g(4)} ${g(4)};
  }
`

export const Header = styled.header`
  align-items: flex-start;
  backdrop-filter: blur(${g(3)});
  display: flex;
  gap: ${g(3)};
  justify-content: space-between;
  margin: 0 ${g(-2, -4)};
  padding: ${g(2)} ${g(2, 4)};
  position: sticky;
  top: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(-3)};
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: 0 ${g(-4)};
    padding: ${g(4)};
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin-bottom: ${g(1)};
  }
`

export const Button = styled(PrimaryButtonFixComponent).attrs({ scheme: 'black' })``

export const Link = styled(LinkComponent)`
  text-decoration: underline;
`
