import { MouseEvent } from 'react'

import * as S from './MenuHead.styles'

export interface MenuHeadProps {
  label: string
  onClose: (event: MouseEvent) => void
}

export const MenuHead = ({ label, onClose, ...props }: MenuHeadProps) => (
  <S.MenuHead {...props}>
    {label ? (
      <S.Container href="/">
        <S.Avatar>{label.slice(0, 1).toUpperCase()}</S.Avatar>
        <S.Label>{label}</S.Label>
      </S.Container>
    ) : (
      <S.Container $isLogin href="/login">
        <S.UserPicto />
        <S.Label>Se connecter</S.Label>
      </S.Container>
    )}
    <S.PictoContainer onClick={onClose}>
      <S.Picto />
    </S.PictoContainer>
  </S.MenuHead>
)
