import {
  NegotiationModal,
  NegotiationModalProps,
} from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'

export const AdpModal: React.FC<NegotiationModalProps> = props => (
  <NegotiationModal {...props}>
    <p>
      <strong>On y est presque 🎉 !</strong>
    </p>

    <p>
      Une offre de prêt indique qu’une banque accepte de vous financer. C’est une étape importante pour votre recherche
      de prêt immobilier, mais cela ne veut pas dire que tout est terminé !
    </p>

    <p>
      La banque vous contactera ensuite pour fixer un rendez-vous afin que votre conseiller bancaire vous fasse la
      présentation officielle de l’offre de prêt.
    </p>

    <p>
      Il faudra patienter entre <strong>3 et 6 semaines</strong> pour que la banque choisie édite l’offre de prêt
      officielle et définitive. C’est pendant ce laps de temps que vous allez démarrer les démarches pour vos assurances
      et votre réactivité permettra d’avoir votre offre plus rapidement.
    </p>
    <p>
      Quand vous recevez votre offre de prêt, <strong>vous devez respecter un délai obligatoire de 10 jours</strong>{' '}
      avant de pouvoir la renvoyer signée. Pendant ce délai de 10 jours, nous vous invitons à envoyer à votre expert
      votre offre de prêt pour qu’il la contrôle “le diable se cache dans les détails”.
    </p>
    <p>
      Une fois l’offre de prêt signée et renvoyée à la banque,{' '}
      <em>
        c’est à ce moment-là que vous pouvez affirmer que vous avez eu votre prêt ! Vous pourrez alors prendre
        rendez-vous chez le notaire pour signer l’acte de vente (pensez à envoyer l’offre de prêt à votre notaire en
        amont pour qu’il puisse préparer le rendez-vous). Les fonds seront débloqués par la banque quelques jours avant
        le rendez-vous de signature de l’acte de vente.
      </em>
    </p>
  </NegotiationModal>
)
