import { CONDITION_BLOCK, PRICE } from '@pretto/app/src/purchaseOffer/config/offer'
import { BlockSentencesInput } from '@pretto/app/src/purchaseOffer/config/types'
import { formatSentences } from '@pretto/app/src/purchaseOffer/lib/formatSentences'
import { useInputDefault } from '@pretto/app/src/purchaseOffer/lib/useInputDefault'
import { useInputTextarea } from '@pretto/app/src/purchaseOffer/lib/useInputTextarea'
import { SectionProps } from '@pretto/app/src/purchaseOffer/views/PurchaseOffer/components/Section/Section'
import { Burden } from '@pretto/picto'

export const useOffer = () => {
  const { input: priceInput, ...price } = useInputDefault(PRICE)
  const { input: conditionBlock, ...condition } = useInputTextarea(CONDITION_BLOCK)

  const sentences: BlockSentencesInput = {
    props: {
      picto: Burden,
      sentences: formatSentences([['Votre offre d’achat s’élève au prix de ', priceInput, ' €.']]),
    },
    type: 'sentences',
  }

  const section: SectionProps = {
    blocks: [sentences, conditionBlock],
    title: 'Votre offre',
  }

  const fields = {
    condition,
    price,
  }

  return { fields, section }
}
