/**
 * This is the heart of the graph library. It provides the basic building blocks for the graph traversal.
 * It is used to define the routes of the simulator architecture and to navigate between them.
 * It is built around the concepts of nodes and edges.
 * 2 types of nodes are defined: Route and Graph.
 * The Route is a leaf node and represents a single node of the simulator.
 * The Graph is a composite node and represents a group of nodes. A graph can contain both Route and Graph nodes.
 * The edges are used to connect the nodes. They are used to define the possible transitions between the nodes.
 */
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'

import { matchPath } from 'react-router'

/**
 * ProjectContext defines the way data is structured throughout the simulator.
 * It is very close to a project payload but we should move towards a more agnostic approach in the future.
 * The Graph should expose a generic context type used internally to define transitions between nodes
 * but it should NOT in any case, be tied to any data structure.
 * The idea behind that is that the Graph should tend to a more generic approach and be reusable
 * in other projects with different data layers.
 */
type Context = ProjectContext

interface NamedObject {
  whois(): string
}

export class Edge implements NamedObject {
  from: Node
  to: Node
  isAccessible: (context: Context) => boolean

  constructor(from: Node, to: Node, isAccessible: (context: Context) => boolean = () => true) {
    this.from = from
    this.to = to
    this.isAccessible = isAccessible
  }

  whois(): string {
    return `[edge:${this.from.whois()} → ${this.to.whois()}]`
  }
}

class Node implements NamedObject {
  private parentGraph: Graph | null

  id: string

  constructor(id: string) {
    this.id = id
    this.parentGraph = null
  }

  setParentGraph(graph: Graph): boolean {
    if (this.parentGraph) {
      throw new Error(`Node ${this.whois()} already has a parent graph`)
    }

    this.parentGraph = graph

    return true
  }

  getParentGraph(): Graph | null {
    return this.parentGraph
  }

  protected getNextEdges(context: Context, fromGraph: Graph): Edge[] {
    const parentGraph = this.getParentGraph()

    if (!parentGraph) {
      return []
    }

    const nextEdges = parentGraph.getEdgesFrom(this)

    if (nextEdges.length === 0 && fromGraph.hasDescendant(parentGraph)) {
      return parentGraph.getNextEdges(context, fromGraph)
    }

    return nextEdges.filter(edge => edge.isAccessible(context))
  }

  protected getPreviousEdges(context: Context, fromGraph: Graph): Edge[] {
    const parentGraph = this.getParentGraph()

    if (!parentGraph) {
      return []
    }

    const previousEdges = parentGraph.getEdgesTo(this)

    if (previousEdges.length === 0 && fromGraph.hasDescendant(parentGraph)) {
      return parentGraph.getPreviousEdges(context, fromGraph)
    }

    return previousEdges.filter(edge => edge.isAccessible(context))
  }

  whois(type = 'node') {
    return `[${type}:${this.id}]`
  }
}

export class Route extends Node {
  path: string

  constructor(id: string, path: string) {
    super(id)

    this.path = path
  }

  getParentGraph(): Graph {
    const parentGraph = super.getParentGraph()

    if (!parentGraph) {
      throw new Error(`${this.whois()} does not have a parent graph`)
    }

    return parentGraph
  }

  private getPreviousRouteFromGraph(context: Context, graph: Graph): Route | undefined {
    const previousEdges = this.getPreviousEdges(context, graph).filter(edge =>
      graph.isNodeAccessible(edge.from, context)
    )

    // If there are multiple possible routes, it's the first one that preveils
    const previousNode = previousEdges[0]?.from

    if (previousNode instanceof Route) {
      return previousNode
    }

    if (previousNode instanceof Graph) {
      return previousNode.getDeepRoute(context)
    }

    return undefined
  }

  getPreviousRoute(context: Context): Route | undefined {
    return this.getPreviousRouteFromGraph(context, this.getParentGraph().getRootParentGraph())
  }

  getPreviousRouteRelative(context: Context): Route | undefined {
    return this.getPreviousRouteFromGraph(context, this.getParentGraph())
  }

  private getNextRouteFromGraph(context: Context, graph: Graph): Route | undefined {
    const nextEdges = this.getNextEdges(context, graph).filter(edge => graph.isNodeAccessible(edge.to, context))

    // If there are multiple possible routes, it's the first one that preveils
    const nextNode = nextEdges[0]?.to

    if (nextNode instanceof Route) {
      return nextNode
    }

    if (nextNode instanceof Graph) {
      return nextNode.rootRoute
    }

    return undefined
  }

  getNextRoute(context: Context): Route | undefined {
    return this.getNextRouteFromGraph(context, this.getParentGraph().getRootParentGraph())
  }

  getNextRouteRelative(context: Context): Route | undefined {
    return this.getNextRouteFromGraph(context, this.getParentGraph())
  }

  private getRecursiveNextRouteFromGraph(context: Context, graph: Graph): Route | undefined {
    const nextRoute = this.getNextRouteFromGraph(context, graph)

    if (!nextRoute) {
      return this
    }

    return nextRoute.getRecursiveNextRouteFromGraph(context, graph)
  }

  getRecursiveNextRoute(context: Context): Route | undefined {
    return this.getRecursiveNextRouteFromGraph(context, this.getParentGraph().getRootParentGraph())
  }

  getRecursiveNextRouteRelative(context: Context): Route | undefined {
    return this.getRecursiveNextRouteFromGraph(context, this.getParentGraph())
  }

  private testRecursiveNextNodeFromGraph(context: Context, node: Node, graph: Graph): boolean {
    if (this === node) {
      return true
    }

    const nextEdges = this.getNextEdges(context, graph)

    return nextEdges.some(edge => {
      const nextNode = edge.to

      if (nextNode instanceof Route) {
        return nextNode.testRecursiveNextNodeFromGraph(context, node, graph)
      }

      if (nextNode instanceof Graph) {
        return nextNode === node || nextNode.rootRoute.testRecursiveNextNodeFromGraph(context, node, graph)
      }

      return false
    })
  }

  testRecursiveNextNode(context: Context, node: Node): boolean {
    return this.testRecursiveNextNodeFromGraph(context, node, this.getParentGraph())
  }

  whois() {
    return super.whois('route')
  }
}

export class Graph extends Node {
  rootRoute: Route

  private edges: Edge[] = []
  private nodes: Node[] = []

  constructor(id: string, rootRoute: Route) {
    super(id)

    this.rootRoute = rootRoute
    this.addNode(rootRoute)
  }

  private hasEdge(edge: Edge): boolean {
    return this.edges.some(
      childEdge => childEdge === edge || (edge.from === childEdge.from && edge.to === childEdge.to)
    )
  }

  private hasNode(node: Node): boolean {
    return this.nodes.some(childNode => childNode === node)
  }

  hasDescendant(node: Node): boolean {
    return this.nodes.some(childNode => {
      if (childNode === node) {
        return true
      }

      if (childNode instanceof Graph) {
        return childNode.hasDescendant(node)
      }

      return false
    })
  }

  getDeepRoute(context: Context): Route | undefined {
    return this.rootRoute.getRecursiveNextRouteRelative(context)
  }

  getEdgesFrom(node: Node): Edge[] {
    return this.edges.filter(({ from }) => from === node)
  }

  getEdgesTo(node: Node): Edge[] {
    return this.edges.filter(({ to }) => to === node)
  }

  getRootParentGraph(): Graph {
    const parentGraph = this.getParentGraph()

    if (parentGraph === null) {
      return this
    }

    return parentGraph.getRootParentGraph()
  }

  private addNode(node: Node): void {
    if (node.setParentGraph(this) && !this.hasNode(node)) {
      this.nodes.push(node)
    }
  }

  addEdge(edge: Edge): void {
    if (this.hasEdge(edge)) {
      throw new Error(`An edge ${edge.whois()} already exists in graph ${this.whois()}`)
    }

    if (!this.hasNode(edge.from)) {
      throw new Error(`Node ${edge.from.whois()} does not exist in graph ${this.whois()}`)
    }

    if (!this.hasNode(edge.to)) {
      throw new Error(`Node ${edge.to.whois()} does not exist in graph ${this.whois()}`)
    }

    this.edges.push(edge)
  }

  addGraph(graph: Graph): void {
    this.addNode(graph)
  }

  addRoute(route: Route): void {
    const existingRoute = this.getRootParentGraph().findRouteByPath(route.path)

    if (existingRoute) {
      throw new Error(
        `A route with path ${existingRoute.path} already exists in graph ${existingRoute.getParentGraph().whois()}`
      )
    }

    this.addNode(route)
  }

  endsBy(route?: Route): route is Route {
    if (!route) {
      return false
    }

    for (const node of this.nodes) {
      if (node instanceof Route && node === route) {
        return this.getEdgesFrom(route).length === 0
      }

      if (node instanceof Graph) {
        const endsBy = node.endsBy(route)

        if (endsBy) {
          return true
        }
      }
    }

    return false
  }

  private findRouteRecursive(path: string): Route | undefined {
    for (const node of this.nodes) {
      if (node instanceof Route && node.path === path) {
        return node
      }

      if (node instanceof Graph) {
        const route = node.findRouteRecursive(path)

        if (route) {
          return route
        }
      }
    }

    return undefined
  }

  findRouteByPath(path: string): Route | undefined {
    return this.findRouteRecursive(path)
  }

  findRoutesByMatch(path: string): Route[] {
    return this.matchRouteRecursive(path)
  }

  isNodeAccessible(node: Node, context: Context): boolean {
    return this.rootRoute.testRecursiveNextNode(context, node)
  }

  private matchRouteRecursive(path: string): Route[] {
    return this.nodes.reduce<Route[]>((previous, node) => {
      if (node instanceof Route && matchPath(node.path, { path }) !== null) {
        return [...previous, node]
      }

      if (node instanceof Graph) {
        return [...previous, ...node.matchRouteRecursive(path)]
      }

      return previous
    }, [])
  }

  matchRoute(path: string): string {
    if (this.matchRouteRecursive(path).length === 0) {
      throw new Error(`Path ${path} is not reachable from graph ${this.whois()}`)
    }

    return path
  }

  whois(): string {
    return super.whois('graph')
  }
}
