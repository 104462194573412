import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import DialogComponent from '@pretto/bricks/components/overlays/Dialog'
import { heading32 } from '@pretto/bricks/core/typography'

import { SwitchToggle } from '@pretto/zen/atoms/selectors/SwitchToggle/SwitchToggle'
import { FileSection } from '@pretto/zen/dashboard/files/FileSection/FileSection'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { DropdownFilter as DropdownFilterComponent } from './components/DropdownFilter/DropdownFilter'

const ELEMENT_TRANSLATION = ng(6)
const BOTTOM_SPACING = ng(3)

export const Documents = ({
  children,
  dropdownFilterProps,
  fileSections,
  isDialogOpen,
  isListEmpty,
  isToggled,
  onCloseDialog,
  onToggle,
  onToggleFilters,
  queryParams,
}) => {
  const timeout = useRef(null)

  const [gap, setGap] = useState(0)

  useEffect(() => {
    timeout.current = setTimeout(() => {
      const gap = document.body.scrollHeight - document.body.offsetHeight
      setGap(gap)
    }, 1)

    return () => {
      clearTimeout(timeout.current)
    }
  }, [queryParams])

  const extraHeight = gap > 0 ? gap + ELEMENT_TRANSLATION + BOTTOM_SPACING : gap

  return (
    <>
      <Dialog isOpen={isDialogOpen} onRequestClose={onCloseDialog}>
        {children}
      </Dialog>

      <Grid $extraHeight={extraHeight}>
        <Head>
          <Title>Documents</Title>

          <DropdownFilter {...dropdownFilterProps} />

          <Toggle
            id="documentsToggle"
            onToggle={onToggle}
            isToggled={isToggled}
            label="Documents manquants uniquement"
          />
        </Head>

        <Container>
          {isListEmpty ? (
            <EmptyListContainer>
              <EmptyListCard>
                <PictureContainer>
                  <Picture />
                </PictureContainer>

                <Message>
                  Aucun document ne correspond à votre sélection.{' '}
                  <MessageCta onClick={onToggleFilters}>Modifier les filtres</MessageCta>
                </Message>
              </EmptyListCard>
            </EmptyListContainer>
          ) : (
            fileSections.map((fileSectionProps, i) => <FileSectionItem key={i} {...fileSectionProps} />)
          )}
        </Container>
      </Grid>
    </>
  )
}

Documents.propTypes = {
  children: PropTypes.node.isRequired,
  dropdownFilterProps: PropTypes.object.isRequired,
  fileSections: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isListEmpty: PropTypes.bool.isRequired,
  isToggled: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
  queryParams: PropTypes.string,
}

const HEADER_HEIGHT = g(11)
const SUBHEADER_HEIGHT = g(8)

const Dialog = styled(DialogComponent)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: 704px;
  }
`
const Grid = styled.div`
  ${grid()};
  grid-auto-rows: auto 1fr;
  height: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT} - ${SUBHEADER_HEIGHT});
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${({ $extraHeight }) => $extraHeight}px;
  }
`
const Head = styled.div`
  ${column([1, -1])};
  ${grid()};
  padding: ${g(3)} 0 ${g(2)};
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: initial;
    padding-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(7)};
  }
`
const Container = styled.div`
  ${column([1, -1])};
  padding-top: ${g(2)};
  padding-bottom: ${g(13)};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
    border-top: 0;
    padding-top: 0;
    margin-top: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
  }
`
const EmptyListContainer = styled.div`
  ${grid()};
  margin-top: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    margin-top: 0;
  }
`
const EmptyListCard = styled.div`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${g(1)};
    grid-column: 1 / -1;
    padding: ${g(3)} ${g(3)} ${g(6)};
  }
`
const PictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Picture = styled(Illustration).attrs({
  options: { height: 160, width: 160 },
  path: 'v1646414705/zen/static-assets/illustrations/660x660/Cactus.svg',
})`
  width: 160px;
  height: 160px;
`
const Message = styled.p`
  text-align: center;
  margin-top: ${g(2)};
`
const MessageCta = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
const Title = styled.h1`
  ${column([2, -2])};
  ${heading32};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
  }
`

const FileSectionItem = styled(FileSection)`
  & + & {
    margin-top: ${g(2)};
  }
`
const DropdownFilter = styled(DropdownFilterComponent)`
  ${column([2, -2])};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 5])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 4])};
  }
`
const Toggle = styled(SwitchToggle)`
  ${column([2, -2])};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(4)};
    align-self: center;
    justify-self: right;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(5)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column(6)};
  }
`
