import { getSectionWithFieldName } from '@pretto/app-core/lib/getSectionWithFieldName'

export const getFieldWithName = (name, sections, values) => {
  const section = getSectionWithFieldName(name, sections, values)

  if (!section) {
    return null
  }

  const field = section.fields.find(field => field.name === name)

  if (!field) {
    return null
  }

  return field
}
