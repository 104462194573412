import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronRightBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

const getColor = ({ $isDisabled, $isSecondary, theme }) => {
  const variant = $isSecondary ? 'neutral1' : 'accent1'
  const defaultColor = theme.colors[variant]
  const disabledColor = theme.colors[`${variant}Disabled`]
  const hoverColor = theme.colors[`${variant}Hover`]

  if ($isDisabled) {
    return css`
      cursor: not-allowed;
      color: ${disabledColor};
      outline: none;

      &:before {
        border-bottom-color: ${disabledColor};
      }
    `
  }

  return css`
    cursor: pointer;
    color: ${defaultColor};

    &:before {
      border-bottom-color: ${defaultColor};
    }

    &:hover {
      color: ${hoverColor};

      &:before {
        border-bottom-color: ${hoverColor};
      }
    }
  `
}

export const Button = styled(BaseTypo).attrs(({ $isDisabled, href }) => ({
  disabled: !href && $isDisabled ? true : null,
  forwardedAs: href ? Link : 'button',
  tabIndex: $isDisabled ? -1 : null,
  typo: 'button',
}))`
  ${getColor}
  display: inline-block;
  background-color: transparent;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  padding-right: ${g(3)};
  line-height: ${g(3)};

  &:before {
    border-bottom: 0.15em solid;
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    right: ${g(3)};
    top: calc(50% + 0.5em);
  }
`
export const Arrow = styled(ChevronRightBold)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`
