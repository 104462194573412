import { calcRemainingPrincipal } from '@pretto/app/src/Sentences/v2/Loan/DateDuration/lib/calcRemainingPrincipal'
import { isYearAfterToday, isYearInLessThan } from '@pretto/app/src/Sentences/v2/Loan/DateDuration/lib/checkYear'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField2'
import { InsuranceType, Usage } from '@pretto/app/src/Sentences/v2/types/enums'
import { Mortgagor } from '@pretto/app/src/Sentences/v2/types/mortgagor'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

export const DateDurationPage: React.FC = () => {
  const context = useSentences()
  const { startYear, endYear, setContext } = context

  const { activeField, refreshField, isFilled } = useActiveField(({ startYear, endYear }) => [
    isNil(startYear),
    isNil(endYear),
  ])

  const getDefaultDuration = () => {
    if (endYear && startYear) {
      const duration = endYear - startYear

      if (duration > 0) {
        return duration
      }
    }

    return null
  }

  const [duration, setDuration] = useState<number | null>(getDefaultDuration())

  const isStartYearError = startYear && isYearAfterToday(startYear)
  const isEndYearError = endYear && isYearInLessThan(endYear, 5)

  const [debouncedIsStartYearError] = useDebounce(isStartYearError, 1200)
  const [debouncedIsEndYearError] = useDebounce(isEndYearError, 1200)

  useEffect(() => {
    if (duration && startYear) {
      const remaining = calcRemainingPrincipal({
        filledDuration: duration,
        filledInsurancePayment: Number(context.renegotiationInsurancePayment),
        filledPayment: Number(context.renegotiationPayment),
        filledPaymentWithoutInsurance: Number(context.renegotiationPaymentWithoutInsurance),
        filledRate: Number(context.rate),
        filledStartYear: startYear,
        mortgagors: context.mortgagors as Mortgagor[],
        type: context.insuranceType as InsuranceType,
        usage: context.usage as Usage,
      })

      setContext({
        endYear: startYear + duration,
        remainingPrincipal: remaining ? remaining.remainingPrincipal : 0,
        loanPayment: remaining ? remaining.loanPayment : 0,
      })
    }
  }, [duration, startYear])

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'dateDuration' } }}>
      <Title>{t('sentences.loan.title')}</Title>

      <div>
        {t(`sentences.loan.dateDuration.sentences.0`)}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'loanStartYear' } }}
          inputProps={{ autoFocus: true, format: '0000', placeholder: '2015', type: 'date' }}
          message={t('sentences.loan.dateDuration.date.error')}
          onChange={(value: string) => {
            const startYear = value === '' ? null : parseInt(value)
            setContext({ startYear })
            refreshField({ startYear })
          }}
          state={debouncedIsStartYearError ? 'error' : 'default'}
          value={startYear?.toString() ?? ''}
        />
        {activeField > 0 && (
          <>
            {t(`sentences.loan.dateDuration.sentences.1`)}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'loanDuration' } }}
              inputProps={{ maxLength: 2, placeholder: '20', type: 'integer' }}
              message={t('sentences.loan.dateDuration.duration.error')}
              onChange={(value: string) => {
                const duration = value === '' ? null : parseInt(value)
                setDuration(duration)
              }}
              state={debouncedIsEndYearError ? 'error' : 'default'}
              value={duration?.toString() ?? ''}
            />
            {t(`sentences.loan.dateDuration.sentences.2`)}
          </>
        )}
        .
      </div>

      {isFilled && !isStartYearError && !isEndYearError && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
