import Icon from '@pretto/bricks/components/iconography/Icon'
import TagComponent from '@pretto/bricks/components/information/Tag'
import Content from '@pretto/bricks/components/layout/Content'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import TimelineLegacy from '@pretto/bricks/components/steps/TimelineLegacy'
import Heading from '@pretto/bricks/components/typography/Heading'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { useState } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const Tag = ({ current, max, onToggle }) => (
  <S.TagContainer onClick={onToggle}>
    <TagComponent size="large">
      <S.TagContent>
        <div>
          {current} / {max}
        </div>
        <S.TagIcon>
          <Icon name="add" />
        </S.TagIcon>
      </S.TagContent>
    </TagComponent>
  </S.TagContainer>
)
Tag.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
}

const MandateTemplate = ({ children, currentStep, sidebar, steps, title }) => {
  const [isStepsOpen, setIsStepsOpen] = useState(false)
  const handleToggleSteps = () => setIsStepsOpen(isOpen => !isOpen)

  const tagProps = {
    current: currentStep + 1,
    max: steps.length,
    onToggle: handleToggleSteps,
  }

  return (
    <S.Page>
      <S.GutterLeft />
      <S.GutterRight />
      <S.Wrapper full="desktop">
        <S.Background />
        <S.Sidebar>
          <Responsive max="laptop">
            {sidebar}
            <Dialog isOpen={isStepsOpen} onRequestClose={handleToggleSteps}>
              <DialogTemplate
                navbarProps={{
                  iconLeft: TimesBold,
                  iconLeftAction: handleToggleSteps,
                  title: 'Étapes de votre mandat',
                }}
              >
                <Content>
                  <TimelineLegacy active={currentStep} current={currentStep} steps={steps} variant="accent-1" />
                </Content>
              </DialogTemplate>
            </Dialog>
          </Responsive>
          <Responsive min="laptop">
            <Transition in={isStepsOpen} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
              {state => (
                <S.TimelineTransition isOpen={state === 'entered'}>
                  <TimelineLegacy active={currentStep} current={currentStep} steps={steps} variant="accent-1" />
                </S.TimelineTransition>
              )}
            </Transition>

            <Transition in={!isStepsOpen} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
              {state => <S.SidebarTransition isOpen={state === 'entered'}>{sidebar}</S.SidebarTransition>}
            </Transition>
          </Responsive>
        </S.Sidebar>
        <Responsive min="laptop">
          <S.Title>
            <Heading>{title}</Heading>
          </S.Title>
        </Responsive>
        <S.Main>{children}</S.Main>
        <S.Steps isOpen={isStepsOpen}>
          <Responsive max="laptop">
            <Tag {...tagProps} />
          </Responsive>
          <Responsive min="laptop">
            <Transition in={isStepsOpen} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
              {state => (
                <S.CloseIcon onClick={handleToggleSteps} isOpen={state === 'entered'}>
                  <Icon name="cross" />
                </S.CloseIcon>
              )}
            </Transition>
            <Transition in={!isStepsOpen} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
              {state => (
                <S.TagTransition isOpen={state === 'entered'}>
                  <Tag {...tagProps} />
                </S.TagTransition>
              )}
            </Transition>
          </Responsive>
        </S.Steps>
      </S.Wrapper>
    </S.Page>
  )
}

MandateTemplate.propTypes = {
  /** Main content of page. */
  children: PropTypes.node.isRequired,
  /** Current step of timeline. */
  currentStep: PropTypes.number.isRequired,
  /** Content of Sidebar. */
  sidebar: PropTypes.node.isRequired,
  /** Array of steps. */
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Title of page. */
  title: PropTypes.node.isRequired,
}

export default MandateTemplate
