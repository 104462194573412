import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Analysis: React.FC<CardProps> = ({ defaultComponent: Component }) => (
  <Component
    description="Notre équipe étudie votre dossier et valide la conformité de vos documents. Il est important que votre dossier soit complet pour l'envoyer aux banques."
    title="En cours d'étude"
  />
)

export const config: Config = {
  component: Analysis,
  name: 'analysis',
}
