import ToggleDetails from '@pretto/bricks/app/simulator/components/ToggleDetails'

import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import RangeContent from './children/RangeContent'
import * as S from './styles'
import { getInfos, getScoreWithColors } from './utils'

const ResultCardMain = ({ blockInfoProps, cardData, onCardClick, projectProps, typology }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const handleToggleDetails = e => {
    e.stopPropagation()
    setIsDetailsOpen(isDetailsOpen => !isDetailsOpen)
    if (!isDetailsOpen) {
      onCardClick()
    }
  }

  const { loanAmount, payment, propertyPrice, range, rate, savings, score, totalCost } = cardData
  const { projectKind } = projectProps

  const isPositive = savings >= 0

  const scoreFundability = getScoreWithColors(score)
  const { detailsText, mainInfo, infos } = getInfos({
    isPositive,
    loanAmount,
    payment,
    projectKind,
    propertyPrice,
    range,
    rate,
    savings,
    totalCost,
    typology,
  })

  const renderBlockInfo = () => {
    if (isEmpty(blockInfoProps)) {
      return null
    }

    return blockInfoProps.isLoading ? <S.Loader /> : <S.BlockInfo {...blockInfoProps} />
  }

  return (
    <S.Card format={['line', 'large-border']} variant="primary-1">
      <S.ContentCard>
        {projectKind === 'capacity' ? <RangeContent range={range} /> : <S.Content mainInfo={mainInfo} infos={infos} />}
      </S.ContentCard>
      {projectKind === 'capacity' && (
        <S.Estimate>
          Cette fourchette représente une <strong>première estimation</strong> de votre capacité d'achat (budget
          maximum, travaux inclus).
        </S.Estimate>
      )}
      {renderBlockInfo()}
      <ToggleDetails
        onClick={handleToggleDetails}
        cardData={cardData}
        detailsText={detailsText}
        isDetailsOpen={isDetailsOpen}
        projectProps={projectProps}
        scoreFundability={scoreFundability}
      />
    </S.Card>
  )
}

ResultCardMain.propTypes = {
  blockInfoProps: PropTypes.shape({
    imgPath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isLoading: PropTypes.boolean,
  }),
  /** Information data passed to the card. */
  cardData: PropTypes.shape({
    applicationFees: PropTypes.number,
    bridge: PropTypes.number,
    brokerageFees: PropTypes.number,
    guarantyFees: PropTypes.number,
    interests: PropTypes.number,
    loanAmount: PropTypes.number,
    loanDuration: PropTypes.number,
    modularity: PropTypes.bool,
    notaryFees: PropTypes.number,
    payment: PropTypes.number,
    propertyPrice: PropTypes.number,
    ptz: PropTypes.object,
    range: PropTypes.array,
    rate: PropTypes.number,
    remainingInterests: PropTypes.number,
    repurchaseFees: PropTypes.number,
    savings: PropTypes.number,
    score: PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
    }),
    totalCost: PropTypes.number,
    transferability: PropTypes.bool,
  }),
  isFirst: PropTypes.bool,
  isOnboardingDone: PropTypes.bool,
  onCardClick: PropTypes.func,
  /** Function to call when click on CTA in card */
  onCardCTAClick: PropTypes.func,
  projectProps: PropTypes.object,
  typology: PropTypes.oneOf(['prospect', 'preLead', 'client']),
}

export default memo(ResultCardMain)
