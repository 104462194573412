import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Submit = styled.div`
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(5)};
  }
`
