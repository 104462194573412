import { parseDestination } from '@pretto/bricks/app/pre-lead/components/SectionRate/utils'
import * as typo from '@pretto/bricks/core/typography'

import {
  DashboardKeepProjectCard,
  ProjectCard,
} from '@pretto/zen/dashboard/sections/DashboardKeepProjectCard/DashboardKeepProjectCard'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { REOPEN_OPPORTUNITY } from '@pretto/app/src/apollo'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { PROPERTY_KIND_LABEL } from '@pretto/app/src/dashboard/config/propertyKindLabel'
import { MATURITY_LOCAL_STORAGE } from '@pretto/app/src/dashboard/containers/maturity/MaturityModal'
import { ProjectModalContext, ProjectModalContextType } from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { Flag, Key, UserCircle } from '@pretto/picto'

import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import styled from 'styled-components'

import { useKeepProjectCardQuery } from './keepProject.gateway.graphql'

const StartNew = styled.span`
  ${typo.bodyBookUnderline16};
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;
`

const KeepProject = () => {
  const { data: applicationData } = useApplication()

  const { setToNewProject } = useContext<ProjectModalContextType>(ProjectModalContext)

  const [reopenOpportunity] = useMutation(REOPEN_OPPORTUNITY)

  const [isLoading, setIsLoading] = useState(false)

  const { data: keepProjectData, loading } = useKeepProjectCardQuery()

  const trackAction = useTracking()

  const { hasComortgagor, lostReason, project, status } = useUser()

  if (loading) {
    return null
  }

  const reopen = async () => {
    trackAction('LOST_REOPEN_OPPORTUNITY', {
      lostReason,
      dashboardOptions: status,
    })

    setIsLoading(true)

    await reopenOpportunity({
      update: (_, { errors }) => {
        if (errors) {
          setIsLoading(false)
          return
        }

        setItem(MATURITY_LOCAL_STORAGE, 'reopen')
        window.location.reload()
      },
    })
  }

  const isPurchase = project.project_kind === 'purchase'
  const propertyKind = applicationData.project?.good?.property_kind ?? 'default'
  const usage = keepProjectData?.project?.good?.usage

  const props = {
    cards: [
      {
        picto: Flag,
        label: 'Projet',
        value: isPurchase ? 'Achat' : 'Renégociation',
      },
      isPurchase && {
        picto: Key,
        label: 'Destination',
        value: parseDestination(usage),
      },
      {
        picto: PROPERTY_KIND_LABEL[propertyKind].picto,
        label: 'Type',
        value: PROPERTY_KIND_LABEL[propertyKind].text,
      },
      {
        picto: UserCircle,
        label: 'Emprunteur(s) ',
        value: hasComortgagor ? 'À deux' : 'Seul',
      },
    ].filter<ProjectCard>((projectCard): projectCard is ProjectCard => typeof projectCard !== 'boolean'),
    ctaProps: {
      children: 'Reprendre ce projet à l’identique',
      isLoading,
      onClick: reopen,
    },
    description: (
      <>
        Vous aviez un projet chez Pretto. Vous pouvez le reprendre ou bien{' '}
        <StartNew onClick={setToNewProject}>commencer un nouveau projet</StartNew> immobilier si des informations ont
        changé.
      </>
    ),
    title: 'Heureux de vous revoir',
  }

  return (
    <>
      <DashboardKeepProjectCard {...props} />
    </>
  )
}

export const config: Config = {
  component: KeepProject,
  name: 'keepProject',
}
