import { Menu as MenuComponent } from '@pretto/zen/main/menus/Menu/Menu'

import Front from '@pretto/app/src/Helpers/frontchat'
import { usePropertySearch } from '@pretto/app/src/PropertySearch/Containers/PropertySearchProvider'
import { getIsPriceSubmitted } from '@pretto/app/src/SharedContainers/Header/lib/getIsPriceSubmitted'
import { getMenu } from '@pretto/app/src/SharedContainers/Header/lib/getMenu'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { PROJECT } from '@pretto/app/src/apollo'
import { getExpertVacation } from '@pretto/app/src/lib/getExpertVacation'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const Menu = ({ focusProps, menuProps }) => {
  const { advisor, hasFeatureAccess, isLowPotential, isOnboardingDone, status, typology, user } = useUser()
  const { pathname } = useLocation()
  const { data } = usePropertySearch()
  const { isOpen, onClose } = menuProps

  const projectQuery = useQuery(PROJECT)
  const isPriceSubmitted = getIsPriceSubmitted(projectQuery)

  const menuHeadProps = {
    label: user?.name || user?.email,
    onClose,
  }

  const hasExpert = advisor?.role === 'expert'

  const { formattedStartDate, formattedEndDate } = getExpertVacation({
    startDate: advisor?.holidays_start_date,
    endDate: advisor?.holidays_end_date,
  })

  const menuItemExpertProps = {
    vacation: {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    },
    name: advisor?.name,
    picture: advisor?.mediumPicturePath ?? 'v1637684805/zen/static-assets/illustrations/660x660/headBulbe.svg',
  }

  const onChat = () => {
    Front('open')
    onClose()
  }

  const menuItems = getMenu({
    advisor,
    data,
    hasExpert,
    hasFeatureAccess,
    isLowPotential,
    isOnboardingDone,
    isPriceSubmitted,
    onChat,
    pathname,
    status,
    typology,
  })

  return (
    <MenuComponent
      focusProps={focusProps}
      isOpen={isOpen}
      menuHeadProps={menuHeadProps}
      menuItemExpertProps={menuItemExpertProps}
      menuItems={menuItems}
      onClose={onClose}
    />
  )
}

Menu.propTypes = {
  focusProps: PropTypes.object.isRequired,
  menuProps: PropTypes.object.isRequired,
}

export default Menu
