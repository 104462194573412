import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Card from '@pretto/bricks/components/cards/Card'
import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const ButtonEdit = styled(ButtonInline)`
  align-self: flex-start;
`

export const Container = styled(Card).attrs({ format: 'line', variant: 'neutral-1-20' })`
  padding: ${g(4)};
`

export const Link = styled.a`
  display: flex;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${g(3)};
`
