import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { ServiceCard } from '@pretto/zen/dashboard/serviceCards/ServiceCard/ServiceCard'

import { useTrackFeatureCard } from '@pretto/app/src/PreLead/Containers/ServiceCards/hook/useTrackFeatureCard'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useNewsletter } from '@pretto/app/src/services/newsletter'

import { useState } from 'react'
import { useDebounce } from 'use-debounce'

const DEBOUNCE_RATE = 2000
const TRACKING_OPTIONS = {
  newsletter_subscribe_source: 'prelead_dashboard',
  newsletter_subscribe_type: 'immo',
}

const ServiceNewsletter = () => {
  const { trackFeature } = useTrackFeatureCard()

  const {
    isLoading,
    isSubscribed: isNewsletterSubscribed,
    subscribe: newsletterSubscribe,
    unsubscribe: newsletterUnsubscribe,
  } = useNewsletter()

  const [isSubscribed, setIsSubscribed] = useState(isNewsletterSubscribed)
  const [isSubscribedDebounced] = useDebounce(isSubscribed, DEBOUNCE_RATE)

  const trackAction = useTracking()

  useUpdateEffect(() => {
    if (isSubscribedDebounced === isNewsletterSubscribed) {
      return
    }

    if (isSubscribedDebounced) {
      subscribe()
    }

    if (!isSubscribedDebounced) {
      unsubscribe()
    }
  }, [isSubscribedDebounced])

  useUpdateEffect(() => {
    setIsSubscribed(isNewsletterSubscribed)
  }, [isNewsletterSubscribed])

  const subscribe = async () => {
    const isSubscribed = await newsletterSubscribe()

    if (isSubscribed) {
      trackAction('NEWSLETTER_SUBSCRIBED', TRACKING_OPTIONS)
    }

    trackFeature('newsletter_immo')
  }

  const unsubscribe = async () => {
    const isUnsubscribed = await newsletterUnsubscribe()

    if (isUnsubscribed) {
      trackAction('NEWSLETTER_UNSUBSCRIBED', TRACKING_OPTIONS)
    }

    trackFeature('newsletter_immo')
  }

  const handleToggle = () => {
    if (isLoading) {
      return
    }

    setIsSubscribed(prevIsSubscribed => !prevIsSubscribed)
  }

  const props = {
    data: {
      onToggle: handleToggle,
      value: isSubscribed,
    },
    slug: 'newsletter',
  }

  return <ServiceCard {...props} />
}

export default ServiceNewsletter
