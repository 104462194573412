import InputRadioGroup from '@pretto/bricks/components/form/InputRadioGroup'

import PropTypes from 'prop-types'

export const RadioField = ({ value, ...props }) => (
  <InputRadioGroup {...props} format="inline" name="radioField" valueSelected={value} />
)

RadioField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
