import Link from '@pretto/bricks/website/utility/Link'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'
import type { TrustpilotRateProps } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'

import * as S from './BlocPretto.styles'

export type LegalSource = 'cgu' | 'compulsory_mentions' | 'legal_mentions' | 'privacy'

export interface BlocPrettoProps extends NavigationProps {
  description: string
  isActionable: boolean
  onLegalClick?: (source: LegalSource) => void
  title: string
  trustpilotRateProps: TrustpilotRateProps
}

export const BlocPretto: React.FC<BlocPrettoProps & React.HTMLAttributes<HTMLDivElement>> = ({
  continueHref,
  description,
  isActionable,
  onContinue,
  onLegalClick,
  title,
  trustpilotRateProps,
  ...props
}) => {
  const trustpilotRateAlignment = useBreakpointToValue('twolines', { tablet: 'inline' })

  return (
    <S.Component {...props}>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Header>

      <S.TrustpilotRateContainer>
        <S.TrustpilotRate {...trustpilotRateProps} alignment={trustpilotRateAlignment} />
      </S.TrustpilotRateContainer>

      {isActionable && (
        <S.Action>
          <S.ButtonContainer>
            <S.Button href={continueHref(true)} onClick={onContinue} scheme="black">
              Je prends rendez-vous
            </S.Button>
          </S.ButtonContainer>

          <S.Promises>
            {['Gratuit', '100 % en ligne', 'Sans engagement'].map(promise => (
              <S.PromiseItem key={promise}>
                <S.PromiseCheck />
                <p>{promise}</p>
              </S.PromiseItem>
            ))}
          </S.Promises>
        </S.Action>
      )}

      <S.Legals>
        Offre non contractuelle : meilleures estimations Pretto sur la base des données déclaratives fournies, à
        confirmer par une étude complète des justificatifs. Un crédit vous engage et doit être remboursé. Vérifiez vos
        capacités de remboursement avant de vous engager. Pretto est une marque de la société FINSPOT. Retrouvez nos{' '}
        <Link href="https://www.pretto.fr/mentions-legales/" onClick={() => onLegalClick?.('legal_mentions')}>
          Mentions légales
        </Link>
        , nos{' '}
        <Link href="https://www.pretto.fr/mentions-obligatoires/" onClick={() => onLegalClick?.('compulsory_mentions')}>
          Mentions Obligatoires
        </Link>
        , nos{' '}
        <Link href="https://www.pretto.fr/cgu/" onClick={() => onLegalClick?.('cgu')}>
          Conditions Générales d’Utilisation
        </Link>{' '}
        et notre{' '}
        <Link href="https://www.pretto.fr/privacy/" onClick={() => onLegalClick?.('privacy')}>
          Politique de Confidentialité
        </Link>
        .
      </S.Legals>
    </S.Component>
  )
}
