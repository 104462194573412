import { getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'
import { shorthash } from '@pretto/bricks/core/utility/hash'
import Link from '@pretto/bricks/website/utility/Link'

import {
  getAttributionCookieFirstClickValue,
  getAttributionCookieLastClickValue,
} from '@pretto/app-core/lib/attributionCookie'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getAccountId, getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import { LOCAL_AGENDA_VISIBLE_NAME } from '@pretto/app/src/Simulation/config/agenda'
import { SIMULATION, SIMULATION_STATE_TYPE } from '@pretto/app/src/Simulation/lib/types'
import { useScoreZeroMinute } from '@pretto/app/src/Simulation/lib/useScoreZeroMinute'
import { Error, ErrorType } from '@pretto/app/src/Simulation/types/Error'
import type { PushyContainerProps, PushyViewProps } from '@pretto/app/src/Simulation/types/Pushy'
import { useSubscribe } from '@pretto/app/src/Subscribe/lib/useSubscribe'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import * as queries from '@pretto/app/src/apollo'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { getVisitorId } from '@pretto/app/src/lib/helpers'
import { t } from '@pretto/app/src/lib/i18n'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation } from '@apollo/client'
import pick from 'lodash/pick'
import { useEffect, useRef, useState } from 'react'

import { useSetDisplayLanguageMutation } from './displayLanguage.gateway.graphql'

const formatErrors = (errors: ErrorType[]): Error[] =>
  errors.map(key => {
    if (key === ErrorType.AgreementRequired) {
      return {
        key,
        message: 'Ces informations nous permettent de vous partager vos possibilités de financement',
      }
    }

    return { key, message: getAuthErrorMessage(key, <Link href="/login">ici</Link>) }
  })

interface Values {
  email: string
  firstName: string
  lastName: string
  phone: string
}

type UsePushy = (pushyOptions: PushyContainerProps) => {
  props: Omit<PushyViewProps, 'fields' | 'onSubmit'>
  register: (values: Values | null, callback?: () => void) => Promise<void>
}

export const usePushy: UsePushy = ({ flow, goToResults, isForce = false }) => {
  const { signIn } = useAuth()

  const [assignProject] = useMutation(queries.ASSIGN_PROJECT)
  const [createAccount] = useMutation(queries.CREATE_ACCOUNT)
  const [updateAccount] = useMutation(queries.UPDATE_ACCOUNT)
  const [setDisplayLanguage] = useSetDisplayLanguageMutation()

  const authToken = useRef(getAuthToken())

  const [errors, setErrors] = useState<ErrorType[]>([])
  const [isAgreed, setIsAgreed] = useState(false)
  const [isAgreementRequired, setIsAgreementRequired] = useState(false)
  const [isMutating, setIsMutating] = useState(false)

  const { apply } = useSubscribe()

  const trackAction = useTracking()

  const { projectID, typology, isEnglishUser } = useUser()

  useEffect(() => {
    trackAction('SENTENCES_SUBSCRIBE_PAGE_VIEWED')
  }, [])

  useEffect(() => {
    if (isAgreed || !isAgreementRequired) {
      return
    }

    addError(ErrorType.AgreementRequired)
  }, [errors, isAgreed, isAgreementRequired])

  const handleSkip = () => {
    trackAction('SENTENCES_SUBSCRIBE_SKIP')
    goToResults()
  }

  const agreementCheck = () => {
    if (isAgreed) {
      return true
    }

    setIsAgreementRequired(true)

    return false
  }

  const addError = (key: ErrorType) => {
    setErrors(errors => {
      if (errors.includes(key)) {
        return errors
      }

      return [...errors, key]
    })
  }

  const register = async (values: Values | null, callback?: () => void) => {
    reset()

    if (!agreementCheck() || !values) {
      return
    }

    setIsMutating(true)

    if (typology === 'prospect') {
      const attributionCookieFirstClick = getAttributionCookieFirstClickValue()
      const attributionCookieLastClick = getAttributionCookieLastClickValue()
      const visitorId = await getVisitorId()

      const {
        data: {
          create_account: { token: authorizationToken, error: createAccountError },
        },
      } = await createAccount({
        variables: {
          attributionCookieFirstClick,
          attributionCookieLastClick,
          email: values.email,
          source: 'interstitial',
          visitorId,
        },
      })

      authToken.current = authorizationToken

      if (createAccountError) {
        addError(createAccountError)
        setIsMutating(false)
        return
      }

      trackAction('SIGNED_UP', {
        signup_assign_project: typology === 'prospect',
        signup_kind: 'interstitial_kind',
        signup_newsletter_immo: false,
        signup_newsletter_taux: false,
        signup_property_search_alert: false,
      })
    }

    const context = { headers: { authorization: `Bearer ${authToken.current}` } }
    const accountId = getAccountId(authToken.current)

    await updateAccount({
      context,
      variables: {
        id: accountId,
        payload: {
          first_name: values.firstName,
          last_name: values.lastName,
        },
      },
    })

    await setDisplayLanguage({
      context,
      variables: {
        input: { displayLanguage: isEnglishUser ? 'en' : 'fr' },
      },
    })

    if (typology === 'prospect') {
      await assignProject({ context, variables: { id: projectID } })
    }

    await apply(pick(values, 'firstName', 'lastName', 'phone'), flow, context)

    const { appointmentAllowed } = await useScoreZeroMinute()

    if (appointmentAllowed) {
      setItem(LOCAL_AGENDA_VISIBLE_NAME, 'true')
    }

    setItem(SIMULATION_STATE_TYPE, SIMULATION)

    trackAction('SENTENCES_SUBSCRIBE_SUBMITTED', {
      sentences_subscribe_phone_accepted: true,
    })

    await signIn({ accessToken: authToken.current }, callback)
  }

  const reset = () => {
    setErrors([])
  }

  const props = {
    get agreements() {
      const defaultChoice = {
        id: shorthash('agreement'),
        isChecked: isAgreed,
        isError: !isAgreed && isAgreementRequired,
        label: t('pushy.agreement'),
        onChange(isChecked: boolean) {
          setIsAgreed(isChecked)
        },
      }
      return [defaultChoice]
    },
    errors: formatErrors(errors),
    isForce: isEnglishUser ? true : isForce,
    isMutating,
    isProspect: typology === 'prospect',
    onSkip: handleSkip,
  }

  return { props, register }
}
