import LanguageDetector from 'i18next-browser-languagedetector'

export const isFrenchBrowser = (): boolean => {
  const languageDetector = new LanguageDetector()

  const locale = languageDetector.detect(['navigator'])
  const localeIsoCode = Array.isArray(locale) ? locale[0] : locale

  return Boolean(localeIsoCode?.startsWith('fr'))
}

export default isFrenchBrowser
