import CardComponent from '@pretto/bricks/components/cards/Card'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Spinner from '@pretto/bricks/components/loading/Spinner'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

import { BlockInfo as BlockInfoComponent } from './children/BlockInfo'
import ContentComponent from './children/Content'

export const BlockInfo = styled(BlockInfoComponent)`
  margin-bottom: ${g(3)};
`

export const Card = styled(CardComponent)`
  padding: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
`

export const Content = styled(ContentComponent)`
  margin-bottom: ${g(2)};
`

export const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-wrap: initial;
  }
`

export const MainValue = styled.div`
  ${typo.heading32};
  align-self: center;
  color: ${({ theme }) => theme.colors.primary2};
`

export const Estimate = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  padding: ${g(2)};
  margin-bottom: ${g(3)};
  margin-top: ${g(1)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.mobileL}) {
    margin-top: ${g(2)};
  }
`

export const Loader = styled(Spinner)`
  width: 100%;
`

export const Payment = styled.span`
  align-items: center;
  display: flex;
  gap: ${g(1)};
  white-space: nowrap;
`

export const PaymentUnit = styled.span`
  ${typo.bodyMedium16};
  color: ${({ theme }) => theme.colors.neutral1};
`
