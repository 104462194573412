import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Label = styled(BaseTypo).attrs({ typo: 'headline16' })`
  flex: 1 1 50%;
  margin-right: ${g(1)};
`
export const Value = styled.span`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`
