import FooterInfo from '@pretto/bricks/app/simulator/components/FooterInfo'
import { BlockInfo as BlockInfoComponent } from '@pretto/bricks/app/simulator/components/ResultCardMain/children/BlockInfo'
import { BlockOnboarding as BlockOnboardingComponent } from '@pretto/bricks/app/simulator/components/SimulationContent/children/BlockOnboarding'
import { BlockRateDoesntCount as BlockRateDoesntCountComponent } from '@pretto/bricks/app/simulator/components/SimulationContent/children/BlockRateDoesntCount/BlockRateDoesntCount'
import TimelineComponent from '@pretto/bricks/app/simulator/components/TimelineMobile'
import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import DialogComponent from '@pretto/bricks/components/overlays/Dialog'
import * as typography from '@pretto/bricks/core/typography'

import { Status as StatusComponent } from '@pretto/zen/atoms/infos/Status/Status'

import { BlocCache as BlocCacheComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocCache/BlocCache'
import { BlocResultat as BlocResultatComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocResultat/BlocResultat'
import { ModaleResult as ModaleResultComponent } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/ModaleResult/ModaleResult'

import styled from 'styled-components'

export const BlocCache = styled(BlocCacheComponent)``

export const BlocResultat = styled(BlocResultatComponent)`
  margin-bottom: ${g(2)};
  margin-top: ${g(4)};
`

export const BlockRateDoesntCount = styled(BlockRateDoesntCountComponent)`
  margin-top: ${g(2)};
`

export const BlockInfo = styled(BlockInfoComponent)`
  margin-top: ${g(2)};
`

export const BlockOnboarding = styled(BlockOnboardingComponent)`
  margin-top: ${g(2)};
`

export const Dialog = styled(DialogComponent)`
  max-width: ${g(101)};
  position: relative;
`

export const CloseButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  position: absolute;
  top: ${g(2)};
  right: ${g(2)};
`

export const DialogClose = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DialogContainer = styled.div`
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 35vh;
  }
`

export const DialogContent = styled.div`
  padding: ${g(2, 4)} ${g(2, 4)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(6)} ${g(6)} 0;
  }
`

export const DialogFooter = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.neutral4};
  height: ${g(11)};
  width: 100%;
`

export const ButtonLogin = styled(ButtonFeature)`
  margin: ${g(4)} ${g(2.5)};
`

export const EditButton = styled(ButtonFeature)`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const Header = styled.header`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
  justify-content: space-between;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    gap: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const Introduction = styled.p`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    margin-bottom: ${g(4)};
  }
`

export const ModaleResult = styled(ModaleResultComponent)``

export const Status = styled(StatusComponent)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    align-self: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    align-self: flex-start;
  }
`

export const Timeline = styled(TimelineComponent)`
  margin-bottom: ${g(2)};
`

export const Title = styled.h1`
  ${typography.heading32};
`

export const Trustpilot = styled(FooterInfo)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
    display: flex;
  }
`

export const View = styled.div`
  ${column([2, 4])};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    padding: ${g(7)} 0;
  }
`
