import { numeralBreakpoints } from '@pretto/bricks/components/layout'

import { useResize } from '@pretto/zen/reveal/lib/useResize'

import { useEffect, useMemo, useState } from 'react'

export const getBreakpoint = () => {
  if (typeof window === 'undefined') return {}
  const { mobileM, mobileL, tablet, laptop, desktop } = numeralBreakpoints
  const windowWidth = window.innerWidth
  if (windowWidth < mobileM) return { breakpoint: 'mobileS', isMobile: true }
  if (windowWidth < mobileL) return { breakpoint: 'mobileM', isMobile: true }
  if (windowWidth < tablet) return { breakpoint: 'mobileL', isMobile: true }
  if (windowWidth < laptop) return { breakpoint: 'tablet', isMobile: false }
  if (windowWidth < desktop) return { breakpoint: 'laptop', isMobile: false }
  return { breakpoint: 'desktop', isMobile: false }
}

export const useBreakpoint = () => {
  const { width } = useResize()
  const breakpoint = useMemo(() => getBreakpoint(), [width])
  const [data, setData] = useState(breakpoint)

  useEffect(() => {
    const newBreakpoint = breakpoint

    if (newBreakpoint.breakpoint !== data.breakpoint) {
      setData(newBreakpoint)
    }
  }, [width])

  return data
}
