import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { heading32 } from '@pretto/bricks/core/typography'

import {
  NegotiationStepBankContainer,
  NegotiationStepBankContainerProps,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepBankContainer/NegotiationStepBankContainer'
import {
  NegotiationStepSimple,
  Status,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepSimple/NegotiationStepSimple'

import styled from 'styled-components'

export interface MainSectionNegotiationsProps {
  negotiationStepBankContainerProps: NegotiationStepBankContainerProps
  mandateSignatureDate: string
  conditionPrecedentLiftDate?: string
  npsComponent?: React.ElementType
}

export const MainSectionNegotiations: React.FC<MainSectionNegotiationsProps> = ({
  negotiationStepBankContainerProps,
  mandateSignatureDate,
  conditionPrecedentLiftDate,
  npsComponent,
  ...props
}) => (
  <Card {...props}>
    <Head>
      <Title>Suivi de votre dossier</Title>

      {conditionPrecedentLiftDate && (
        <ConditionPrecedentLift>
          Date de levée des conditions suspensives : <strong>{conditionPrecedentLiftDate}</strong>
        </ConditionPrecedentLift>
      )}
    </Head>

    <Step title="Signature du mandat de recherche" label={mandateSignatureDate} status={Status.Done} />

    <Line />

    <Banks {...negotiationStepBankContainerProps} />

    <Nps as={npsComponent} />
  </Card>
)
const Card = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${g(3)};
  padding-bottom: ${g(3)};

  > * {
    ${column([2, -2])};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.colors.neutral3};
    padding: ${g(4)};
  }
`
const Head = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${g(1)};
  }
`
const Title = styled.h1`
  ${heading32};
`
const ConditionPrecedentLift = styled.div`
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }
`
const Step = styled(NegotiationStepSimple)`
  margin-top: ${g(2)};
`
const Line = styled.div`
  margin-top: ${g(1)};
  height: ${g(3.5)};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.neutral3};
  transform: translateX(${g(3.5, -0.5)});
`
const Banks = styled(NegotiationStepBankContainer)`
  margin-top: ${g(1)};
`
const Nps = styled.div`
  margin-top: ${g(3)};
`
