import AddSentence from '@pretto/bricks/app/sentences/components/AddSentence'
import RemovableSentence from '@pretto/bricks/app/sentences/components/RemovableSentence'
import Sentence from '@pretto/bricks/app/sentences/components/Sentence'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import PropTypes from 'prop-types'

import * as S from './styles'

const SENTENCE = (sentence, autoFocus) => {
  const { props, type } = sentence
  switch (type) {
    case 'sentence':
      return <Sentence {...props} autoFocus={autoFocus} />
    case 'remove':
      return <RemovableSentence {...props} />
    case 'add':
      return <AddSentence {...props} />
    case 'validation':
      return <Banner {...props} />
    default:
      return <div {...props} />
  }
}
const SentencesGroup = ({ sentences, ...props }) => (
  <>
    {sentences.map((sentence, i) => (
      <S.Container key={i} $previousType={sentences[i - 1]?.type} $type={sentence.type} {...props}>
        {SENTENCE(sentence, i === 0)}
      </S.Container>
    ))}
  </>
)

SentencesGroup.propTypes = {
  sentences: PropTypes.arrayOf(PropTypes.object),
}

export default SentencesGroup
