import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { StatusEnum } from '@pretto/app/src/types/gateway/enums'

export const getNavigationItemsList = ({ hasFeatureAccess, status, typology, isPriceSubmitted }) => {
  switch (status) {
    case 'search':
    case 'constitution':
    case 'validation':
      if (typology === 'client') {
        if (hasFeatureAccess(['BUYOUT'])) {
          return {
            desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.folder, NAV_ITEMS.faq],
            tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.folder],
          }
        }

        return {
          desktop: [
            isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity,
            NAV_ITEMS.dashboard,
            NAV_ITEMS.folder,
            NAV_ITEMS.faq,
          ],
          tablet: [isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity, NAV_ITEMS.dashboard, NAV_ITEMS.folder],
        }
      }
      return {
        desktop: [NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.faq],
      }
    case 'analysis':
    case 'confirmation':
    case 'negotiation':
    case 'agreement':
    case 'appointment':
    case 'signature':
      return {
        desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.documents, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.documents, NAV_ITEMS.faq],
      }
    case StatusEnum.LostHardClient:
    case StatusEnum.LostOneChoice:
    case StatusEnum.LostDoubleChoice:
    case 'end':
      return {
        desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.faq],
      }
    default:
      return {
        desktop: [NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.faq],
      }
  }
}
