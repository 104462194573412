import type { FieldInputProps, FieldProps } from '@pretto/zen/reveal/types/Field'

import { useEffect, useImperativeHandle, useRef, useState } from 'react'

import { addSeparatorToDateString } from './addSeparatorToDateString'
import { addSeparatorToNumberString } from './addSeparatorToNumberString'
import { computeDateSelectionStart } from './computeDateSelectionStart'
import { computeNumberSelectionStart } from './computeNumberSelectionStart'
import { sanitizeNumberString } from './sanitizeNumberString'

const DATE_FORMAT = '00 / 00 / 0000'

const ALLOWED_DECIMAL_SEPARATORS = ['.', ',']
const DECIMAL_SEPARATOR = ','
const THOUSAND_SEPARATOR = ' '

interface ChangeEvent<T = Element, E = Event> extends React.SyntheticEvent<T, E> {
  target: EventTarget & T
}

type UseFieldFormat = (
  props: Required<Pick<FieldProps, 'inputProps' | 'onChange' | 'value'>>,
  ref?: React.ForwardedRef<unknown>
) => FieldInputProps & React.ComponentPropsWithRef<'input'>

export const useFieldFormat: UseFieldFormat = (
  {
    value,
    inputProps: { format: rawFormat, inputMode, maxLength, onKeyDown, onPaste, type: rawType = 'text' },
    onChange,
  },
  ref
) => {
  const innerRef = useRef<HTMLInputElement>(null)
  const selectionStartRef = useRef<number | null>(null)

  useImperativeHandle(ref, () => innerRef.current)

  const [selectionStart, setSelectionStart] = useState<number | null>(null)

  useEffect(() => {
    select(selectionStartRef.current, () => {
      selectionStartRef.current = null
    })
  }, [value])

  useEffect(() => {
    select(selectionStart, () => {
      setSelectionStart(null)
    })
  }, [selectionStart])

  const format = rawFormat ?? DATE_FORMAT
  const type: string = rawType

  const isDate = type === 'date'
  const isNumber = type === 'integer' || type === 'number'
  const isHybrid = isDate || isNumber

  const changeValue = (processedValue: string, selectionStart: number) => {
    if (processedValue === value) {
      setSelectionStart(selectionStart)
      return
    }

    selectionStartRef.current = selectionStart

    onChange(processedValue)
  }

  const select = (selectionStart: number | null, callback: () => void) => {
    if (selectionStart === null) {
      return
    }

    innerRef.current?.setSelectionRange(selectionStart, selectionStart)

    callback()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isHybrid) {
      onChange(event.currentTarget.value)
      return
    }

    event.preventDefault()

    const rawValue = event.currentTarget.value

    if (isDate) {
      const stringValue = addSeparatorToDateString({ format, value: rawValue })

      const selectionStart = computeDateSelectionStart({
        inputType: (event as ChangeEvent<HTMLInputElement, Event & { inputType: string }>).nativeEvent.inputType,
        rawValue,
        format,
        selectionEnd: event.currentTarget.selectionEnd ?? 0,
      })

      changeValue(stringValue, selectionStart)
    }

    if (isNumber) {
      const numberValue = sanitizeNumberString({
        type,
        value: rawValue,
        contextValue: '',
        allowedDecimalSeparators: ALLOWED_DECIMAL_SEPARATORS,
      })

      const selectionStart = computeNumberSelectionStart({
        inputType: (event as ChangeEvent<HTMLInputElement, Event & { inputType: string }>).nativeEvent.inputType,
        selectionEnd: event.currentTarget.selectionEnd ?? 0,
        rawValue,
        stringValue: addSeparatorToNumberString({
          value: numberValue,
          decimalSeparator: DECIMAL_SEPARATOR,
          thousandSeparator: THOUSAND_SEPARATOR,
        }),
        thousandSeparator: THOUSAND_SEPARATOR,
      })

      changeValue(numberValue, selectionStart)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event)

    if (type !== 'number') {
      return
    }

    if (
      !ALLOWED_DECIMAL_SEPARATORS.includes(event.key) ||
      !event.currentTarget.value.includes(DECIMAL_SEPARATOR) ||
      event.metaKey === true
    ) {
      return
    }

    event.preventDefault()
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    onPaste?.(event)

    if (type !== 'number') {
      return
    }

    event.preventDefault()

    const rawStringValue = event.currentTarget.value
    const clipboardValue = event.clipboardData.getData('text')

    const numberValueBeforeSelectionStart = sanitizeNumberString({
      type,
      value: rawStringValue.substring(0, event.currentTarget.selectionStart ?? 0),
      contextValue: '',
      allowedDecimalSeparators: ALLOWED_DECIMAL_SEPARATORS,
    })

    const numberValueAfterSelectionEnd = sanitizeNumberString({
      type,
      value: rawStringValue.substring(event.currentTarget.selectionEnd ?? 0),
      contextValue: '',
      allowedDecimalSeparators: ALLOWED_DECIMAL_SEPARATORS,
    })

    const numberValueOutsideSelection = numberValueBeforeSelectionStart + numberValueAfterSelectionEnd

    const numberValueInsertion = sanitizeNumberString({
      type,
      value: clipboardValue,
      contextValue: numberValueOutsideSelection,
      allowedDecimalSeparators: ALLOWED_DECIMAL_SEPARATORS,
    })

    const numberValue = numberValueBeforeSelectionStart + numberValueInsertion + numberValueAfterSelectionEnd

    const stringValue = addSeparatorToNumberString({
      value: numberValue,
      decimalSeparator: DECIMAL_SEPARATOR,
      thousandSeparator: THOUSAND_SEPARATOR,
    })

    const selectionStart = computeNumberSelectionStart({
      inputType: '',
      selectionEnd: event.currentTarget.selectionEnd ?? 0,
      rawValue: event.currentTarget.value,
      stringValue,
      thousandSeparator: THOUSAND_SEPARATOR,
    })

    changeValue(numberValue, selectionStart)
  }

  return {
    get inputMode() {
      if (isDate) {
        return 'numeric'
      }

      if (isNumber) {
        return type === 'number' ? 'decimal' : 'numeric'
      }

      return inputMode
    },
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onPaste: handlePaste,
    ref: innerRef,
    get maxLength() {
      if (isDate) {
        return format.length
      }

      return maxLength
    },
    get value() {
      if (isDate) {
        return addSeparatorToDateString({ format, value })
      }

      if (isNumber) {
        return addSeparatorToNumberString({
          value: sanitizeNumberString({
            type,
            value,
            contextValue: '',
            allowedDecimalSeparators: ALLOWED_DECIMAL_SEPARATORS,
          }),
          decimalSeparator: DECIMAL_SEPARATOR,
          thousandSeparator: THOUSAND_SEPARATOR,
        })
      }

      return value
    },
    type: isHybrid ? 'text' : type,
  }
}
