import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import * as S from './styles'

const Helper = ({ helper, helperCta }) => (
  <S.Helper>
    <Card variant="accent-1-l">
      <Content>
        <Text variant="accent-1">{nl2br(helper)}</Text>

        {helperCta && (
          <S.HelperButton>
            <ButtonSquared href={helperCta.href} variant="accent-1">
              {helperCta.label}
            </ButtonSquared>
          </S.HelperButton>
        )}
      </Content>
    </Card>
  </S.Helper>
)
Helper.propTypes = {
  helper: PropTypes.string,
  helperCta: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.node,
  }),
}
const ApplicationFormSection = ({ description, fields, helper, helperCta, pageTitle, title }) => {
  const helperProps = {
    helper,
    helperCta,
  }

  return (
    <S.Section>
      {title && pageTitle !== title && (
        <S.SectionTitle hasShortSpacing={description && !helper}>
          <SubHeading size="large">{title}</SubHeading>
        </S.SectionTitle>
      )}

      {helper && <Helper {...helperProps} />}

      {description && <S.Description>{nl2br(description)}</S.Description>}

      <S.StyledFormList fields={fields} />
    </S.Section>
  )
}

ApplicationFormSection.defaultProps = {
  fields: [],
}

ApplicationFormSection.propTypes = {
  /** Description of the section. */
  description: PropTypes.string,
  /** Array of FormField props <code>[FormField](/#!/FormField)</code> component. */
  fields: PropTypes.array,
  /** Helper to be displayed beside the section. */
  helper: PropTypes.string,
  /** Call to action of helper. Not visible when not set */
  helperCta: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.node,
  }),
  /** Page Title, use to prevent title repeat, will be deleted with new design */
  pageTitle: PropTypes.string.isRequired,
  /** Title of the section */
  title: PropTypes.string,
}

export default ApplicationFormSection
