import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import styled, { css } from 'styled-components'
import { ChevronLeftBold, ChevronRightBold } from '@pretto/picto'
import { Slots } from './components/Slots/Slots.styles'
import { column } from '@pretto/bricks/components/layout/Grid'
interface SlotsProps {
  $isDisabled: boolean
}

export const Header = styled.div`
  margin-bottom: ${g(2)};
`

export const Calendar = styled.div<SlotsProps>`
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  position: relative;
  grid-template-rows: 1fr auto auto 1fr;
  max-height: 65%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    max-height: 80%;
    
  }


  ${({ $isDisabled }) => $isDisabled && css`
    cursor: pointer;
    pointer-events:none;
  `}
`

export const Previous = styled(ChevronLeftBold)`
  position: absolute;
  top: ${g(2.5)};
  left: ${g(2.5)};
  cursor: pointer;
`

export const Next = styled(ChevronRightBold)`
  position: absolute;
  top: ${g(2.5)};
  right: ${g(2.5)};
  cursor: pointer;
`

export const ThreeColumns = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(3)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const HeroContainer = styled(ThreeColumns)`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
`

export const SlotsContainer = styled(ThreeColumns)`
  & > ${Slots} + ${Slots} {
    border-left: 1px solid ${({ theme }) => theme.colors.neutral3};
  } 

  overflow-y: scroll;
`

export const Hero = styled.div`
  ${typo.bodyBold16};
  text-align: center;
  padding: ${g(2)} ${g(3)};
  display: flex;
  align-items: center;
  justify-content: center;
`