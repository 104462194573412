import { EventDefinition, EventTrackingType } from '@pretto/app/src/Sentences/v2/lib/tracking/types/event'

// EventName value must follow object:action convention
// See https://www.notion.so/pretto/Tracking-client-des-applications-WIP-a81792ad045f417bb49d2f404b1a161e
export enum EventName {
  MaturityDeclared = 'maturity:declared',
  PageViewed = 'page:viewed',
  SignedUp = 'lead:signed-up',
  SimulationChoiceClicked = 'simulation:choice:clicked',
  SimulationCompleted = 'simulation:completed',
  SimulationElementClicked = 'simulation:element:clicked',
  SimulationFieldChanged = 'simulation:field:changed',
  SimulationPropertySearchDeclared = 'simulation:property-search:declared',
  SimulationRateAlertDeclared = 'simulation:rate-alert:declared',
  SimulationStarted = 'simulation:started',
}

export type Event =
  | EventDefinition<
      Exclude<
        EventName,
        EventName.SimulationChoiceClicked | EventName.SimulationElementClicked | EventName.SimulationFieldChanged
      >
    >
  | EventDefinition<EventName.SimulationChoiceClicked, { choice: string }>
  | EventDefinition<EventName.SimulationElementClicked, { element: string }>
  | EventDefinition<EventName.SimulationFieldChanged, { field: string }>

export const defaultEventsTrackingType: Record<EventName, EventTrackingType> = {
  [EventName.SimulationStarted]: EventTrackingType.NONE,
  [EventName.PageViewed]: EventTrackingType.NONE,
  [EventName.MaturityDeclared]: EventTrackingType.ALL,
  [EventName.SimulationCompleted]: EventTrackingType.NONE,
  // Don't send event for choice and field changes
  // Might be problematic with RGPD
  [EventName.SimulationChoiceClicked]: EventTrackingType.NONE,
  [EventName.SimulationElementClicked]: EventTrackingType.NONE,
  [EventName.SimulationFieldChanged]: EventTrackingType.NONE,
  [EventName.SignedUp]: EventTrackingType.BACKEND,
  [EventName.SimulationRateAlertDeclared]: EventTrackingType.FRONTEND,
  [EventName.SimulationPropertySearchDeclared]: EventTrackingType.FRONTEND,
}
