import { funcToItem } from '@pretto/bricks/core/utility/funcToItem'

import { getDocumentsForFilters } from '@pretto/app-core/application/lib/getDocumentsForFilters'
import { getDocumentsProgress } from '@pretto/app-core/application/lib/getDocumentsProgress'
import { getMortgagorFilter } from '@pretto/app-core/application/lib/getMortgagorFilter'
import { getMortgagorsStatus } from '@pretto/app-core/application/lib/getMortgagorsStatus'
import { getSortedDocuments } from '@pretto/app-core/application/lib/getSortedDocuments'

import { ExclamationMarkCircleBold, Picto } from '@pretto/picto'

import qs from 'qs'

import { INVALID_LABEL } from '../config/constants'

interface FilterSchemaItem {
  category: string
  ignoreProgress?: boolean
  label: string
  nextCategory?: string
  previousCategory?: string
}

interface Filter {
  isSelected: boolean
  label: string
  onSelect: () => void
  picto?: Picto | null
  progress?: number | [number, number] | null
  type: 'default' | 'checkbox' | 'error'
}

interface MainFilter extends Filter {
  nextCategoryFirstDocumentHref: string | null
  previousCategoryLastDocumentHref: string | null
}

type SelectedFilters = { [K: string]: string }

interface UserContext {
  accounts: Array<{ firstName: string; lastName: string; name: string }>
  hasComortgagor: boolean
}

export const getFilters = <S extends FilterSchemaItem[], E extends UserContext>(
  schema: S,
  selectedFilters: SelectedFilters,
  formatter: (slug: string, search?: string) => string,
  onSelect: (parameters: SelectedFilters, shouldReplace?: boolean) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  userContext: E
) => {
  const isInvalidActive = selectedFilters.status === 'invalid'
  const progressAll = getDocumentsProgress(data.docs)

  const mainFilters = schema.reduce<MainFilter[]>(
    (previous, currentFilter) => {
      const item = funcToItem(currentFilter, data, userContext)

      if (!item) {
        return previous
      }

      const { category, ignoreProgress = false, label, nextCategory = null, previousCategory = null } = item

      const { current, target } = getDocumentsProgress(getDocumentsForFilters({ category }, data))

      if (category !== 'others' && target === 0) {
        return previous
      }

      return [
        ...previous,
        {
          isSelected: selectedFilters.category === category,
          label,
          get nextCategoryFirstDocumentHref() {
            if (!nextCategory) {
              return null
            }

            const filters = { ...selectedFilters, category: nextCategory }

            const documents = getDocumentsForFilters(filters, data)

            const sortedDocuments = getSortedDocuments(
              documents,
              slug => formatter(slug, qs.stringify(filters, { addQueryPrefix: true })),
              userContext
            )

            return sortedDocuments[0]?.href ?? null
          },
          onSelect() {
            onSelect({ category })
          },
          get previousCategoryLastDocumentHref() {
            if (!previousCategory) {
              return null
            }

            const filters = { ...selectedFilters, category: previousCategory }

            const documents = getDocumentsForFilters(filters, data)

            const sortedDocuments = getSortedDocuments(
              documents,
              slug => formatter(slug, qs.stringify(filters, { addQueryPrefix: true })),
              userContext
            )

            return sortedDocuments[sortedDocuments.length - 1]?.href ?? null
          },
          progress: ignoreProgress ? null : [current, target],
          type: 'default',
        },
      ]
    },
    [
      {
        isSelected: !isInvalidActive && (!selectedFilters.category || selectedFilters.category === 'all'),
        label: 'Tous les documents',
        nextCategoryFirstDocumentHref: null,
        onSelect() {
          onSelect({ category: 'all' })
        },
        previousCategoryLastDocumentHref: null,
        progress: [progressAll.current, progressAll.target],
        type: 'default',
      },
    ]
  )

  const invalidDocuments = getDocumentsForFilters({ status: 'invalid' }, data)

  const invalidFilters: Filter[] =
    invalidDocuments.length > 0
      ? [
          {
            isSelected: isInvalidActive,
            label: INVALID_LABEL,
            onSelect() {
              onSelect({ status: 'invalid' }, true)
            },
            picto: ExclamationMarkCircleBold,
            progress: invalidDocuments.length,
            type: 'error',
          },
        ]
      : []

  const mortgagorFilters: Filter[] = userContext.hasComortgagor
    ? userContext.accounts.map(({ name }, index) => {
        const mortgagorKey = index === 0 ? 'mortgagor' : 'comortgagor'
        const status = getMortgagorsStatus(selectedFilters.mortgagor)

        return {
          isSelected: status[mortgagorKey],
          onSelect() {
            const mortgagor = getMortgagorFilter({ ...status, [mortgagorKey]: !status[mortgagorKey] })

            onSelect({ mortgagor })
          },
          label: `Afficher les documents de ${name}`,
          type: 'checkbox',
        }
      })
    : []

  return [mainFilters, invalidFilters, mortgagorFilters]
}
