import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import PropTypes from 'prop-types'

import * as S from './styles'

const MaturityUpdateResult = ({
  buttonTitle,
  description,
  maturityLabel,
  onClick,
  onRetry,
  retryButtonTitle,
  src,
  title,
  isError = false,
}) => {
  return (
    <S.Wrapper>
      <S.Image alt="" loading="lazy" src={getCloudinaryUrl(src)} />

      <S.Container>
        <S.SuccessTitle>{title}</S.SuccessTitle>

        <S.DescriptionContent>
          <S.StepText>Étape en cours : </S.StepText>
          <S.StepText $isBold>{maturityLabel}</S.StepText>
        </S.DescriptionContent>

        <S.DescriptionContent>
          <S.SuccessDescription>{description}</S.SuccessDescription>
        </S.DescriptionContent>

        <S.Content>
          <S.StyledButtonSecondary onClick={onRetry}>{retryButtonTitle}</S.StyledButtonSecondary>
          {!isError && <S.StyledButton onClick={onClick}>{buttonTitle}</S.StyledButton>}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

MaturityUpdateResult.propTypes = {
  buttonTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  maturityLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onRetry: PropTypes.func,
  retryButtonTitle: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default MaturityUpdateResult
