import { DEFAULT_INITIAL, DEFAULT_LABEL } from '@pretto/app/src/SharedContainers/Header/config/defaultValues'

interface User {
  name?: string
  email: string
}

interface GetUserDataArgs {
  isLoggedIn: boolean
  user: User
}

export const getUserData = ({ isLoggedIn, user }: GetUserDataArgs) => {
  if (!isLoggedIn) {
    return null
  }

  return {
    initial: user.name?.slice(0, 1).toUpperCase() || user.email.slice(0, 1).toUpperCase() || DEFAULT_INITIAL,
    label: user.name || user.email || DEFAULT_LABEL,
  }
}
