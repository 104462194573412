import { memo, MouseEvent } from 'react'

import * as S from './ButtonBack.styles'

export interface ButtonBackProps {
  children: string
  href?: string
  isArrowShown: boolean
  onClick?: (event: MouseEvent) => void
}

export const ButtonBack = memo(({ children, isArrowShown, href, onClick, ...props }: ButtonBackProps) => {
  return (
    <S.Button onClick={onClick} href={href} {...props}>
      {isArrowShown && <S.Arrow />}
      <S.Label>{children}</S.Label>
    </S.Button>
  )
})

ButtonBack.displayName = 'ButtonBack'
