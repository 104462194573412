import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typography from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'

import { BlocResultat } from '@pretto/app/src/Simulation/Containers/ResultsPage/components/BlocResultat/BlocResultat'
import { CheckHandwrite } from '@pretto/picto'

import styled from 'styled-components'

const blocResultatStaticProps = {
  details: [{ label: 'Taux' }, { label: 'Montant emprunté' }, { label: 'Coût total du crédit' }],
  isDisclaimed: false,
  isSecondary: true,
  offerCriterias: [
    {
      label: 'Rembourser votre prêt en anticipé',
      state: 'success' as const,
    },
    {
      label: 'Moduler vos mensualités',
      state: 'success' as const,
    },
    {
      label: 'Suspendre vos mensualités',
      state: 'error' as const,
    },
  ],
}

export interface BlocCacheProps {
  loginUrl?: string
  onClick: () => void
}

export const BlocCache: React.FC<BlocCacheProps> = ({ loginUrl, onClick, ...props }) => (
  <Blocs {...props}>
    <BlocResultat bankIndex={2} {...blocResultatStaticProps} />
    <BlocResultat bankIndex={3} {...blocResultatStaticProps} />

    <Overlay>
      <OverlayTitle>
        Trouvez la banque parfaite pour vous !
        <br />
        Un courtier Pretto vous présente l’ensemble des offres.
      </OverlayTitle>

      <OverlayButton isSecondary onClick={onClick}>
        Prendre un rendez-vous
      </OverlayButton>

      <OverlayPromises>
        {['Gratuit', 'Sans engagement'].map(label => (
          <OverlayPromise key={label}>
            <CheckHandwrite />
            {label}
          </OverlayPromise>
        ))}
      </OverlayPromises>

      {loginUrl && (
        <OverlayLogin>
          Vous avez déjà un RDV ? <Link href={loginUrl}>Accédez à votre compte</Link>
        </OverlayLogin>
      )}
    </Overlay>
  </Blocs>
)

const Blocs = styled.div`
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  overflow: hidden;
  padding: ${g(2)};
  position: relative;
`

const Overlay = styled.div`
  backdrop-filter: blur(2px);
  background-color: ${({ theme }) => theme.colors.overlay};
  bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  left: 0;
  padding: ${g(8)} ${g(3)} ${g(3)};
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} ${g(5)} ${g(5)};
  }
`

const OverlayButton = styled(ButtonComponent)`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
  }
`

const OverlayLogin = styled.div`
  margin-top: auto;

  a {
    text-decoration: underline;
  }
`

const OverlayPromise = styled.ul`
  align-items: center;
  display: flex;
  gap: ${g(1)};
`

const OverlayPromises = styled.ul`
  ${typography.bodyMedium16};
  align-items: center;
  display: flex;
  gap: ${g(2)};
  justify-content: center;
`

const OverlayTitle = styled.p`
  ${typography.textLarge24};
  margin-bottom: ${g(4)};
`
