import { checkFieldChanged } from '@pretto/app/src/onboarding/lib/checkFieldChanged'
import { checkFieldFilled } from '@pretto/app/src/onboarding/lib/checkFieldFilled'

export const checkAllFields = ({ fields, oldValues, values }) =>
  fields.reduce((previous, current) => {
    if (previous === false) {
      return false
    }

    const { name, type } = current
    if (!name) {
      return previous
    }

    return (
      checkFieldFilled({ type, value: values[name] }) ||
      checkFieldChanged({ type, oldValue: oldValues[name], value: values[name] })
    )
  }, true)
