import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Signed: React.FC<CardProps> = ({ defaultComponent: Component }) => {
  const {
    user: { projectKind },
  } = useUser()

  return (
    <Component
      description={
        projectKind === 'purchase'
          ? 'Félicitations, il ne vous reste plus qu’à officialiser votre achat devant le notaire en signant l’acte authentique de vente.'
          : null
      }
      title="Vous avez signé l’offre de prêt"
    />
  )
}

export const config: Config = {
  component: Signed,
  name: 'signed',
}
