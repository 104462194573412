export const SUMMARY_LABELS = {
  'analysis.bridge.remaining': 'Rachat de CRD',
  'analysis.bridge.value': 'Prêt relais',
  'analysis.guarantyFees': 'Frais de garantie estimés',
  'analysis.loanAmount': 'Montant emprunté',
  'project.facts.notary_fees': 'Frais de notaire',
  'analysis.payment': 'Mensualité, hors assurance',
  'analysis.penaltyFees': 'Indemnités de remboursement anticipé',
  'analysis.ptz.amount': 'Dont prêt à taux zéro',
  'project.contribution': 'Apport',
  'project.facts.agency_fees': "Frais d'agence",
  'project.purchase.build_price': 'Prix de la construction',
  'project.purchase.furniture_price': 'Meubles',
  'project.purchase.land_price': 'Prix du terrain',
  'project.purchase.property_price': 'Prix du bien',
  'project.purchase.works_price': 'Travaux',
  'project.renegotiation.remaining_principal': 'Capital restant dû',
  'project.renegotiation.works_amount': 'Travaux',
  'project.request.duration': 'Durée',
  'brokerage_fees.amount': 'Frais Pretto',
}

export const TITLES = {
  purchase: {
    loanTitle: 'Votre prêt',
    projectTitle: 'Votre projet',
  },
  renegotiation: {
    loanTitle: 'Votre nouveau prêt',
    projectTitle: 'Votre projet',
  },
}

export const NOTIFICATIONS = {
  content: {
    complete: 'Vous avez confirmé votre projet, votre expert revient vers vous rapidement.',
    incomplete: 'Envoyez les documents manquants pour finaliser votre demande de prêt.',
  },
  title: 'Votre projet est validé',
}

export const COMPLETE_PERCENTAGE = 100

export const DISCLAIMERS = completionPercentage =>
  [
    <>
      Si tout vous semble correct, il ne vous reste plus qu’à valider votre projet.
      <br />
      Nous reviendrons vers vous pour la signature de votre mandat de recherche de financement.
    </>,
    completionPercentage < COMPLETE_PERCENTAGE &&
      `Votre dossier est complet à ${completionPercentage}% : n’oubliez pas que vous devrez envoyer les documents manquants dès que possible !`,
  ].filter(Boolean)

export const CONFIG = {
  button: 'Valider mon projet',
  conditionsContent:
    'Vous devrez domicilier vos revenus dans la banque qui vous accordera le financement pour votre projet.',
  conditionsTitle: 'Conditions',
  messages: [
    'Voici le cahier des charges de votre projet. Veuillez le vérifier en détail. Une fois validé, les modifications ne seront plus possibles sans l’aide de votre expert.',
  ],
}
