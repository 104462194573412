import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import * as PropTypes from 'prop-types'

import * as S from './styles'

const FormSection = ({ children, title }) => (
  <div>
    {title && (
      <S.Title>
        <SubHeading>{title}</SubHeading>
      </S.Title>
    )}

    <div>{children}</div>
  </div>
)

FormSection.propTypes = {
  /** Section descendants. */
  children: PropTypes.node.isRequired,
  /** Optional title to be displayed on top. */
  title: PropTypes.string,
}

export default FormSection
