import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Content = styled.div`
  display: flex;
`
export const Emoji = styled.div`
  flex-grow: 0;
  margin-right: ${g(2)};
`
export const Paragraph = styled.p`
  margin-bottom: ${g(3)};

  &:last-child {
    margin-bottom: 0;
  }
`
export const Link = styled.a`
  text-decoration: underline;

  &:link {
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
  }
`
