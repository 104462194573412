import type { FieldProps } from '@pretto/zen/reveal/types/Field'

import {
  CountriesSearchResult,
  countrySearch,
  getCountryLabelFromCode,
} from '@pretto/app/src/Sentences/v2/lib/countrySearch'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'

import { useEffect, useState } from 'react'

export type Result = CountriesSearchResult

type UseCountryField = (defaultCountryCode?: string) => {
  fieldProps: FieldProps
  results: Result[]
}

export const useCountryField: UseCountryField = defaultCountryCode => {
  const [searchValue, setSearchValue] = useState('')
  const [results, setResults] = useState<Result[]>([])

  const { isEnglishUser } = useUser()
  const localeIsoCode = isEnglishUser ? 'en' : 'fr'

  useEffect(() => {
    setSearchValue(getCountryLabelFromCode(defaultCountryCode, localeIsoCode) ?? '')
  }, [isEnglishUser])

  useEffect(() => {
    if (searchValue === '') {
      setResults([])
      return
    }

    if (typeof countrySearch !== 'string') {
      countrySearch.get(searchValue, { limit: 10, language: localeIsoCode }).then(results => {
        if (!Array.isArray(results)) {
          return
        }

        setResults(results)
      })
    }
  }, [searchValue])

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  return {
    fieldProps: {
      onChange: handleChange,
      value: searchValue,
    },
    results,
  }
}
