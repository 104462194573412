import ValidatePage from '@pretto/bricks/app/simulator/pages/ValidateSimulationPage'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import BubbleChat from '../../../SharedComponents/BubbleChat'
import Header from '../../../SharedContainers/Header'

const ValidateSimulationPage = ({ currentProject, newProject }) => {
  const props = {
    bubbleChat: (
      <BubbleChat>
        Vous avez modifié votre projet. Souhaitez-vous conserver votre projet actuel ou enregistrer vos modifications ?
      </BubbleChat>
    ),
    currentProject,
    newProject,
  }

  return (
    <Fragment>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <ValidatePage {...props} />
    </Fragment>
  )
}

ValidateSimulationPage.propTypes = {
  currentProject: PropTypes.object,
  newProject: PropTypes.object,
}

export default ValidateSimulationPage
