import NotFound from '@pretto/bricks/shared/components/NotFound'

import { Component } from 'react'

import * as C from './NotFoundPage.module.css'

export default class NotFoundPage extends Component {
  render() {
    return (
      <div className={C.notFoundPage}>
        <NotFound />
      </div>
    )
  }
}
