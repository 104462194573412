import { useTracking } from '@pretto/app/src/lib/tracking'

export const useTrackFeatureCard = () => {
  const trackAction = useTracking()

  const trackFeature = kind => {
    trackAction('PRELEAD_FEATURE_CARD_CLICKED', { prelead_feature_card_kind: kind })
  }

  return { trackFeature }
}
