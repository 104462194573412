import set from 'lodash/set'

export const encodeValues = values =>
  Object.keys(values).reduce((previous, keyValue) => {
    const value = values[keyValue]

    if (value === 'true') return set(previous, keyValue, true)
    if (value === 'false') return set(previous, keyValue, false)

    return set(previous, keyValue, value)
  }, {})
