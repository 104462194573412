export const ALLOWED_FILE_TYPES = ['application/pdf', 'image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/tiff']

export const ALLOWED_EXTENSIONS = ['PDF', 'BMP', 'GIF', 'JPG', 'PNG', 'TIFF']

export const ALLOWED_MIME_TYPES = {
  'application/pdf': [],
  'image/bmp': [],
  'image/gif': [],
  'image/jpeg': [],
  'image/png': [],
  'image/tiff': [],
}

export const COMMON_LABEL = 'En commun'

export const INVALID_LABEL = 'Documents invalides'

export const MAX_SIZE = 25000000
