import { CALL_BACK_NOW } from '@pretto/app/src/apollo'

export const booking = {
  id: 'appointment_type',
  fields(data, userContext) {
    return [
      {
        type: 'calendar',
        name: 'calendar',
        label: 'Quand seriez-vous disponible ?',
        labelSuffix: '(30 min)',
      },
      {
        type: 'options',
        name: 'noSlot',
        label: 'Aucun créneau ne vous convient ?',
        get options() {
          const { scoreZeroMinute } = userContext
          const isCallBackNowAllowed = scoreZeroMinute?.callBackNowAllowed

          const options = [{ label: 'Accéder à mon Espace Client', value: 'delay_appointment' }]

          if (!isCallBackNowAllowed) {
            return options
          }

          return [{ label: 'Rappelez-moi dès que possible', value: 'call_now' }, ...options]
        },
        jumpToNext: true,
        track({ value }) {
          return ['ONBOARDING_CONTACT_OPTION_CLICKED', { onboarding_contact_option_value: value }]
        },
      },
    ]
  },
  jumps: '/',
  mutation({ values }) {
    return (
      values.noSlot === 'call_now' && {
        mutation: CALL_BACK_NOW,
      }
    )
  },
  redirect({ data: { next_booking } }) {
    return next_booking && '/'
  },
}
