import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'
import { BlocSimulation as BlocSimulationComponent } from '@pretto/app/src/Capacity/views/ResultPage/components/BlocSimulation/BlocSimulation'

import { useBlocSimulationProps } from './lib/useBlocSimulationProps'

interface BlocSimulationProps extends NavigationProps {
  isNotClient?: boolean
}

export const BlocSimulation: React.FC<BlocSimulationProps> = props => {
  const blocSimulationProps = useBlocSimulationProps(props)

  return <BlocSimulationComponent {...blocSimulationProps} />
}
