import { g } from '@pretto/bricks/components/layout'
import BaseSpinner from '@pretto/bricks/components/loading/Spinner'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import { Bridge } from '@pretto/bricks/website/utility/Bridge'
import Link from '@pretto/bricks/website/utility/Link'

import { ChevronRightBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const TRANSITION_DELAY = 175
export const TRANSITION_DURATION_IN = 200
export const TRANSITION_DURATION_OUT = 150

const getBackground = ({ $isDisabled, $isSecondary, theme }) => {
  const variant = $isSecondary ? 'neutral1' : theme.designSystem === 'zen' ? 'accent1' : 'primary1'

  if ($isDisabled) {
    return css`
      cursor: not-allowed;
      background-color: ${theme.colors[`${variant}Disabled`]};
      outline: none;
    `
  }

  return css`
    cursor: pointer;
    background-color: ${theme.colors[variant]};
    box-shadow: none;

    &:hover {
      background-color: ${theme.colors[`${variant}Hover`]};
    }
  `
}

export const Arrow = styled(ChevronRightBold)`
  align-self: center;
  flex-shrink: 0;
  margin-left: ${g(2)};
  transform: translateX(0);
  transition: transform 0.5s ease;
`
export const Button = styled(BaseTypo).attrs(({ $isDisabled, $isObfucated, href }) => ({
  disabled: !href && $isDisabled ? true : null,
  forwardedAs: $isObfucated ? Bridge : href ? Link : 'button',
  tabIndex: $isDisabled ? -1 : null,
  typo: 'button',
}))`
  ${getBackground}
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 ${g(3)};
  height: ${g(8)};
  transition:
    background-color ${TRANSITION_DURATION_OUT}ms ease-out,
    box-shadow ${TRANSITION_DURATION_OUT}ms ease-out;
  max-width: 100%;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover ${Arrow} {
        transform: translateX(${g(1, -2)});
      }
    `};
`
export const Label = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity ${TRANSITION_DURATION_IN}ms ease-out ${TRANSITION_DELAY}ms;
  white-space: nowrap;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;
    `}
`
export const Spinner = styled(BaseSpinner)`
  bottom: 0;
  position: absolute;
  left: 0;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  transition: opacity ${TRANSITION_DURATION_OUT}ms ease-out 0s;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 1;
      transition: opacity ${TRANSITION_DURATION_IN}ms ease-out 0s;
    `}
`
