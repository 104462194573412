import { g } from '@pretto/bricks/components/layout'

import styled, { css } from 'styled-components'

export const Alert = styled.div`
  border-radius: ${g(1)};

  ${({ theme, type }) => {
    switch (type) {
      case 'error':
        return css`
          background-color: ${theme.colors.error2};
          color: ${theme.colors.error1};
        `
      case 'success':
        return css`
          background-color: ${theme.colors.primary4};
          color: ${theme.colors.primary1};
        `

      case 'info':
        return css`
          background-color: ${theme.colors.neutral4};
          color: ${theme.colors.neutral1};
        `

      case 'warning':
      default:
        return css`
          background-color: ${theme.colors.warning2};
          color: ${theme.colors.warning1};
        `
    }
  }}
`

export const Content = styled.div`
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Picto = styled.svg.attrs(({ $picto }) => ({ as: $picto }))`
  margin-right: ${g(2)};
  flex-shrink: 0;
`
