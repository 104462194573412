import LogoDark from '@pretto/bricks/assets/img/logo-dark.svg'
import LogoGreen from '@pretto/bricks/assets/img/logo-green.svg'
import LogoWhite from '@pretto/bricks/assets/img/logo-white.svg'

import classnames from 'classnames'
import pascalcase from 'pascalcase'
import PropTypes from 'prop-types'

import * as C from './Logo.module.css'

const Logo = ({ className, variant, size }) => {
  let src
  switch (variant) {
    case 'green':
      src = LogoGreen
      break
    case 'white':
      src = LogoWhite
      break
    case 'dark':
      src = LogoDark
      break
    default:
      src = LogoGreen
  }
  return (
    <img
      src={src}
      className={classnames(className, C.prettoLogo, C[`prettoLogo${pascalcase(size ?? '')}`])}
      alt="logo Pretto"
    />
  )
}

Logo.propTypes = {
  /** @ignore Used for styled-components styling. */
  className: PropTypes.string,
  /** Logo image size: <code>small</code>, <code>medium</code> or <code>large</code>. */
  size: PropTypes.string,
  /** Logo variants colors: <code>green</code>, <code>white</code> or <code>dark</code>. */
  variant: PropTypes.string,
}

Logo.defaultProps = {
  className: '',
  size: 'medium',
  variant: 'green',
}

export default Logo
