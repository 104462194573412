import Responsive from '@pretto/bricks/components/utility/Responsive'

import * as S from './styles'

interface CtaProps {
  text: string
  onClick: () => void
}
export interface BlockInfoProps {
  bgColor?: string
  checkList?: string[]
  cta?: CtaProps
  imgPath: string
  title: string
  description: React.ReactNode
}

export const BlockInfo = ({
  bgColor = 'primary4',
  checkList,
  cta,
  imgPath,
  title,
  description,
  ...props
}: BlockInfoProps) => (
  <S.Container {...props}>
    <S.Card $bgColor={bgColor}>
      <Responsive min="mobileL">
        <S.Image $path={imgPath} />
        <S.Text>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          {checkList && (
            <S.CheckList>
              {checkList.map(
                (label: string): React.ReactNode => (
                  <S.Label key={label}>
                    <S.Valid />
                    {label}
                  </S.Label>
                )
              )}
            </S.CheckList>
          )}
        </S.Text>
      </Responsive>
      <Responsive max="mobileL">
        <S.HeaderMobile>
          <S.Image $path={imgPath} />
          <S.Title>
            <div>{title}</div>
          </S.Title>
        </S.HeaderMobile>
        <S.Description>{description}</S.Description>
        {checkList && (
          <S.CheckList>
            {checkList.map(
              (label: string): React.ReactNode => (
                <S.Label key={label}>
                  <S.Valid />
                  {label}
                </S.Label>
              )
            )}
          </S.CheckList>
        )}
      </Responsive>
    </S.Card>
    {cta && (
      <S.Cta onClick={cta.onClick} isArrowShown>
        {cta.text}
      </S.Cta>
    )}
  </S.Container>
)
