import NotFound from '@pretto/bricks/shared/pages/NotFoundPage'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { Component, Fragment } from 'react'

import Header from '../../../SharedContainers/Header'

export default class NotFoundPage extends Component {
  render() {
    return (
      <Fragment>
        <Header navigationItemsList={[NAV_ITEMS.faq]} />
        <NotFound />
      </Fragment>
    )
  }
}
