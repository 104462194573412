import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'

import { SwitchToggle } from '@pretto/zen/atoms/selectors/SwitchToggle/SwitchToggle'

import { CheckConfirm } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/Subscribe/Subscribe.styles'
import { Picto } from '@pretto/picto'

import styled from 'styled-components'

interface PictoProps {
  $picto: Picto
}

export const HeroConfirm = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${g(3)};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(3)};
  }
`

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: 100%;
  min-width: ${g(7)};
  width: ${g(7)};
  height: ${g(7)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${g(3)};
`

export const Icon = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  width: ${g(3)};
  height: ${g(3)};
`

export const AppointmentInfo = styled.div`
  flex-basis: 100%;
`

export const Appointment = styled.div`
  display: flex;
  align-items: center;
`

export const AppointmentSlot = styled.span`
  display: flex;
  flex-direction: column;
`

export const VisioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    justify-content: flex-start;
    margin-top: ${g(1)};
  }
`

export const Check = styled(CheckConfirm)`
  margin-left: ${g(2)};
`

export const SwitchVisio = styled(SwitchToggle)`
  margin-left: ${g(2)};
`

export const Modify = styled(ButtonFeature)`
  align-self: center;
  margin-left: auto;
`
